import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from '@fto/ui'
import TableBodyItem from '@root/pages/Dashboard/components/ProjectsTable/components/TableBodyItem'

import styles from '@root/pages/Dashboard/components/ProjectsTable/projects-table.module.scss'

const ProjectLoadingRow: FC = () => {
    return (
        <tr className={styles.tableBodyRow}>
            <td>
                <Flex direction={'column'} gap={2}>
                    <Skeleton height={16} width={80} />
                    <Skeleton height={16} width={200} />
                </Flex>
            </td>
            <TableBodyItem className={styles.onSmallTabletHide}>
                <Skeleton height={16} width={150} />
            </TableBodyItem>
            <TableBodyItem className={styles.onSmallTabletHide}>
                <Skeleton height={16} width={70} />
            </TableBodyItem>
            <TableBodyItem className={styles.onLargeTabletHide}>
                <Skeleton height={16} width={70} />
            </TableBodyItem>
            <TableBodyItem width={'170px'} className={styles.onMobileHide}>
                <Skeleton height={30} width={150} />
            </TableBodyItem>
            <TableBodyItem max-width={'100px'} className={styles.button}>
                <Skeleton width={90} />
            </TableBodyItem>
        </tr>
    )
}

export default ProjectLoadingRow
