import { FC, useMemo } from 'react'

import { TPtRiskReward } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { TPtAndrewsPitchfork } from '@fto/lib/charting/paint_tools/SpecificTools/ptAndrewsPitchfork'

import RiskRewardFillColor from './components/RiskRewardFillColor'
import FillColor from './components/FillColor'

type Props = {
    tools: any[]
}

const FillColorBlock: FC<Props> = ({ tools }) => {
    const fillColorParams = useMemo(() => {
        const filteredToolsList = tools.filter((tool) => tool.ClosedPolygon && !(tool instanceof TPtAndrewsPitchfork))

        return {
            toolsList: filteredToolsList,
            isAvailable: filteredToolsList.length > 0
        }
    }, [tools])

    const hasRiskRewardToolForFillColor = useMemo(() => {
        return fillColorParams.toolsList.some((tool) => tool instanceof TPtRiskReward)
    }, [fillColorParams.toolsList])

    const showOnlyRiskRewardFillColor = useMemo(() => {
        return fillColorParams.toolsList.every((tool) => tool instanceof TPtRiskReward)
    }, [hasRiskRewardToolForFillColor, fillColorParams.toolsList])

    const listOfTools = useMemo(() => {
        return fillColorParams.toolsList.reduce(
            (list, tool) => {
                if (tool instanceof TPtRiskReward) {
                    return {
                        ...list,
                        riskRewardList: [...list.riskRewardList, tool]
                    }
                }

                return {
                    ...list,
                    generalTools: [...list.generalTools, tool]
                }
            },
            { riskRewardList: [], generalTools: [] }
        )
    }, [fillColorParams.toolsList])

    if (!fillColorParams.isAvailable) {
        return null
    }

    return hasRiskRewardToolForFillColor && showOnlyRiskRewardFillColor ? (
        <RiskRewardFillColor tools={listOfTools.riskRewardList} />
    ) : (
        <FillColor tools={listOfTools.generalTools} />
    )
}

export default FillColorBlock
