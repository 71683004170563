import React, { PropsWithChildren, ReactElement, memo } from 'react'

import MUITooltip from '@mui/material/Tooltip'
import cx from 'classnames'

import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'

import styles from './index.module.scss'

export const placements = [
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
] as const

export const tooltipColors = {
    light: 'light',
    dark: 'dark'
} as const

export const tooltipPlacements = {
    bottom: 'bottom',
    top: 'top',
    left: 'left',
    right: 'right'
} as const

export type TooltipProps = PropsWithChildren<{
    content: boolean | string | ReactElement
    color?: keyof typeof tooltipColors
    placement?: (typeof placements)[number]
    type?: 'basic'
    open?: boolean
    interactive?: boolean
    arrow?: boolean
    classNames?: {
        root?: string
        content?: string
        tooltip?: string
        arrow?: string
    }
    disableFocusListener?: boolean
}>

export const Tooltip = memo<TooltipProps>(
    ({
        children,
        content,
        color = tooltipColors.dark,
        type = 'basic',
        open,
        interactive = false,
        placement = 'bottom',
        arrow = true,
        classNames,
        disableFocusListener = false
    }) => (
        <MUITooltip
            disableFocusListener={disableFocusListener}
            key={open === undefined ? 'inherit' : open ? 'open' : 'closed'}
            classes={{
                popper: cx(SELECTORS.TOOLTIP.className, styles.root, classNames?.root, {
                    [styles[`type-${type}`]]: type,
                    [styles[`color-${color}`]]: color
                }),
                tooltip: cx(styles.tooltip, classNames?.tooltip),
                arrow: cx(styles.arrow, classNames?.arrow)
            }}
            title={content}
            disableInteractive={!interactive}
            placement={placement}
            open={open}
            arrow={arrow}
            leaveDelay={50}
            enterNextDelay={50}
        >
            <span
                className={cx(
                    styles.anchor,
                    {
                        [styles.hasContent]: content
                    },
                    classNames?.content
                )}
            >
                {children}
            </span>
        </MUITooltip>
    )
)

Tooltip.displayName = 'Tooltip'
