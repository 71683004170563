import ChartSettingsStore from '@fto/lib/store/chartSettings'
import CreateOrderStore from '@fto/lib/store/createOrder'
import EducationStore from '@fto/lib/store/educationStore'
import GlobalChartsStore from '@fto/lib/store/globalChartsStore'
import OrdersStore from '@fto/lib/store/ordersStore'
import ProjectStore from '@fto/lib/store/projectStore'
import StatisticsStore from '@fto/lib/store/statistics'
import TimeframeStore from '@fto/lib/store/timeframe'
import ToolInfoStore from '@fto/lib/store/tools'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import NewsStore from '@fto/lib/store/news'
import IndicatorsStore from '@fto/lib/store/indicatorsStore'

export function resetStores() {
    const stores = [
        GraphToolPanelStore,
        NewsStore,
        ChartSettingsStore,
        CreateOrderStore,
        EducationStore,
        GlobalChartsStore,
        OrdersStore,
        ProjectStore,
        StatisticsStore,
        TimeframeStore,
        ToolInfoStore,
        IndicatorsStore
    ]
    stores.forEach((store) => store.resetStore())
}
