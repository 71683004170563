import { FC, ReactNode } from 'react'

import { Icon } from '@fto/icons'
import { Button, Tooltip, TriggerOverlay } from '@fto/ui'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import styles from '../../index.module.scss'

type Props = {
    tooltip: string
    label?: string
    before?: ReactNode
    className?: string
    children?: ReactNode
    withDropdownIcon?: boolean
    renderer: ReactNode | ((handleClose: () => void) => ReactNode)
}

const RibbonDropdown: FC<Props> = ({
    label,
    tooltip,
    className,
    before,
    children,
    withDropdownIcon = false,
    renderer
}) => {
    // (close) => (
    //                 <RibbonDropdownMenu
    //                     activeOption={activeOption}
    //                     close={close}
    //                     options={options}
    //                     name={name}
    //                     dropdowns={dropdowns}
    //                     additionalElements={additionalMenuElements}
    //                 />
    //             )
    return (
        <TriggerOverlay
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            optionsRenderer={renderer}
            onClose={() => GlobalChartsController.Instance.enableMouseEvents()}
            classNames={{ list: styles.dropdownContent, paper: styles.dropdownPaper }}
        >
            {children ? (
                <Tooltip content={tooltip}>{children}</Tooltip>
            ) : (
                <Button
                    withTooltip
                    tooltipText={tooltip}
                    classNames={{ root: styles.dropdownButton, content: className, label: styles.label }}
                    label={label ?? ''}
                    before={before}
                    after={withDropdownIcon && <Icon size={24} name='chevron-bottom' />}
                    block
                    size='compact'
                    type='tetriatry-white'
                />
            )}
        </TriggerOverlay>
    )
}

export default RibbonDropdown
