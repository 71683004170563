import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { Flex, Typography } from '@fto/ui'
import { GeneralSettingsProps, SettingsTabRef, OptionSettingRef, AvailableOptionsSettings } from '../../../types'
import { useTranslation } from 'react-i18next'
import getOptionSettingByConfig from '../../../utils/getOptionSettingByConfig'
import OptionsConfig from './config'

const GeneralSettings = forwardRef<SettingsTabRef, GeneralSettingsProps>(
    ({ chart, applyToAll, initialData, onDifferentSettingsUpdate }, ref) => {
        const { t } = useTranslation()
        const optionsRefs = useRef<OptionSettingRef[]>([])

        const loadOptionsSetting = useCallback((data: any, shouldUpdateConfig = true) => {
            optionsRefs.current?.forEach((ref) => {
                if (ref.name in data) ref.loadOptionSetting(data[ref.name], shouldUpdateConfig)
            })
        }, [])

        const addRef = useCallback((ref: OptionSettingRef) => {
            if (ref && !optionsRefs.current.includes(ref)) {
                optionsRefs.current.push(ref)
            }
        }, [])

        const optionSettingByKey = useCallback(
            (option: AvailableOptionsSettings) => {
                return getOptionSettingByConfig(
                    {
                        tab: 'canvas',
                        chart,
                        option: option,
                        applyToAll: applyToAll,
                        onDifferentSettingsUpdate,
                        initialData
                    },
                    addRef
                )
            },
            [chart, applyToAll, initialData, onDifferentSettingsUpdate, addRef]
        )

        useImperativeHandle(ref, () => ({ loadOptionsSetting }))

        return (
            <Flex block direction='column' gap={12}>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settingsModal.orders')}
                </Typography>

                {optionSettingByKey(OptionsConfig['ShowCloseOrderMarker'])}
                {optionSettingByKey(OptionsConfig['ShowOpenOrderMarker'])}
                {optionSettingByKey(OptionsConfig['ScrollingSpeed'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settingsModal.mouse')}
                </Typography>

                {optionSettingByKey(OptionsConfig['MouseSensitivity'])}
                {optionSettingByKey(OptionsConfig['MagnetSensitivity'])}
                {optionSettingByKey(OptionsConfig['MagnetCrossHair'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settingsModal.scale')}
                </Typography>

                {optionSettingByKey(OptionsConfig['LockTo'])}
                {optionSettingByKey(OptionsConfig['MultichartLockTo'])}
                {optionSettingByKey(OptionsConfig['AxisFontSize'])}
            </Flex>
        )
    }
)

export default GeneralSettings
