import { ChartControl, chartControlEvent, ChartControlId, ChartControlParams } from '@fto/chart_components/ChartControl'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import StrangeError from '../lib/common/common_errors/StrangeError'
import { TOscChart } from '@fto/lib/charting/chart_classes/OscChartUnit'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { IVisibleIndexBuffer } from '@fto/lib/extension_modules/indicators/api/ITVisibleIndexBuffer'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'

import VisibilityButton from './components/oscIndicator/VisibilityButton'
import EditButton from './components/oscIndicator/EditButton'
import RemoveButton from './components/oscIndicator/RemoveButton'

type AvailableControls = VisibilityButton | EditButton | RemoveButton

export class OscIndicatorConfigurationControl extends ChartControl {
    private readonly indicator!: TRuntimeIndicator
    private readonly ownerChart!: TChartWindow
    private readonly chart!: TChart
    private context: CanvasRenderingContext2D | null = null
    private indicatorNameStyle!: TMkFontStyle
    private readonly indicatorNameFont!: IGPFont
    private readonly nameBrush!: IGPSolidBrush
    private indicatorLastValue!: TMkFontStyle
    private readonly indicatorLastValueFont!: IGPFont
    private indicatorValuesMap: Map<string, { rect: TRect; buff: IVisibleIndexBuffer, valueStr: string }> = new Map()
    private static valuesWidthByContext: Map<number, number> = new Map<number, number>()

    private readonly lastValueBrush!: IGPSolidBrush
    private mouseInsideBrush: IGPSolidBrush = new IGPSolidBrush('#ffffff', 0.85)
    private visibilityControl: VisibilityButton | null = null
    private indicatorNameLocation = new TRect(0, 0, 0, 0)
    private indicatorControls: AvailableControls[] = []
    private initChartControlsParams: ChartControlParams

    constructor(
        indicator: TRuntimeIndicator,
        ownerChart: TChartWindow,
        chart: TChart,
        font: TMkFontStyle,
        tmkFontStyleLastValue: TMkFontStyle,
        chartControlParams: ChartControlParams
    ) {
        super(chartControlParams)
        this.initChartControlsParams = chartControlParams
        this.indicator = indicator
        this.ownerChart = ownerChart
        this.chart = chart
        this.indicatorNameStyle = font
        this.indicatorLastValue = tmkFontStyleLastValue
        this.nameBrush = new IGPSolidBrush(font.color)
        this.lastValueBrush = new IGPSolidBrush(tmkFontStyleLastValue.color)

        this.indicatorNameFont = new IGPFont(
            new TGPFontFamily(this.indicatorNameStyle.name),
            this.indicatorNameStyle.size,
            StylingHelper.ConvertFontStyle(this.indicatorNameStyle.style)
        )

        this.indicatorLastValueFont = new IGPFont(
            new TGPFontFamily(this.indicatorLastValue.name),
            this.indicatorLastValue.size,
            StylingHelper.ConvertFontStyle(this.indicatorLastValue.style)
        )

        this.createControls()

        if (!this.indicator.IsVisible()) {
            this.onOwnerIndicatorHide()
        }
    }

    private createControls(): void {
        this.visibilityControl = null
        this.indicatorControls = []

        const dpr = window.devicePixelRatio || 1

        const chartControlParamsImage = this.initChartControlsParams.clone()
        const chartControlParamsImageRect = chartControlParamsImage.getLocation()
        chartControlParamsImageRect.Right = chartControlParamsImageRect.Left + 24 * dpr
        chartControlParamsImageRect.Bottom = chartControlParamsImageRect.Top + 24 * dpr
        chartControlParamsImage.setLocation(chartControlParamsImageRect)

        if (GlobalImageManager.Instance.eyeImage) {
            const control = new VisibilityButton(chartControlParamsImage)
            control.controlId = ChartControlId.INDICATOR_VISIBILITY
            this.indicatorControls.push(control)
            this.visibilityControl = control
            this.visibilityControl.attachObserver(this.ownerChart)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.eyeImage is null')
        }

        if (GlobalImageManager.Instance.settingsImage) {
            const control = new EditButton(chartControlParamsImage.clone())
            control.controlId = ChartControlId.INDICATOR_SETTINGS
            this.indicatorControls.push(control)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.settingsImage is null')
        }

        if (GlobalImageManager.Instance.closeImage) {
            const control = new RemoveButton(chartControlParamsImage.clone())
            control.controlId = ChartControlId.INDICATOR_DELETE
            this.indicatorControls.push(control)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.closeImage is null')
        }

        if (!this.indicator.IsVisible()) {
            this.onOwnerIndicatorHide()
        }
    }

    public isOwnerOscChart(chart: TChart): boolean {
        return this.chart === chart
    }

    public get Indicator(): TRuntimeIndicator {
        return this.indicator
    }

    public adjustControlWidth(): void {
        if (!this.context) {
            return
        }

        try {
            const dpr = window.devicePixelRatio || 1
            const nameWidth =
                getTextWidth(this.context, this.indicator.GetNameWithParams(), this.indicatorNameFont.toString()) * dpr
            const indicatorNameWidth = nameWidth + 8 * dpr
            const step = 6 * dpr

            this.indicatorValuesMap.clear()

            const locationControl = this.getLocation()
            let rightEdge = locationControl.Left + indicatorNameWidth + step

            // update Control location
            this.indicatorNameLocation.Left = locationControl.Left
            this.indicatorNameLocation.Right = rightEdge - step
            this.indicatorNameLocation.Top = locationControl.Top
            this.indicatorNameLocation.Bottom = locationControl.Bottom

            locationControl.Right = rightEdge
            this.setLocation(locationControl)

            const buttonSize = locationControl.Bottom - locationControl.Top

            if (this.isMouseInside()) {
                for (let i = 0; i < this.indicatorControls.length; i++) {
                    const control = this.indicatorControls[i]
                    const controlLocation = control.getLocation()

                    controlLocation.Left = rightEdge
                    controlLocation.Right = controlLocation.Left + buttonSize
                    controlLocation.Top = this.getLocation().Top
                    controlLocation.Bottom = this.getLocation().Bottom

                    control.setLocation(controlLocation)
                    rightEdge += buttonSize + step
                }
            } else {
                if (this.indicator.IsVisible()) {
                    //
                } else {
                    if (this.visibilityControl) {
                        const visibilityControlLocation = this.visibilityControl.getLocation()
                        visibilityControlLocation.Left = rightEdge
                        visibilityControlLocation.Right = visibilityControlLocation.Left + buttonSize
                        visibilityControlLocation.Top = this.getLocation().Top
                        visibilityControlLocation.Bottom = this.getLocation().Bottom
                        this.visibilityControl.setLocation(visibilityControlLocation)
                        rightEdge += buttonSize + step
                    }
                }
            }

            locationControl.Right = rightEdge
            this.setLocation(locationControl)

            if (this.indicator.IsVisible()) {
                for (let j = 0; j < this.indicator.VisibleBuffers.length; j++) {
                    const buff: IVisibleIndexBuffer = this.indicator.VisibleBuffers[j]
                    if (buff && buff.buffer && buff.IsVisible()) {
                        if (!buff.buffer.HasSomeValues() || buff.buffer.LastItemInTestingIndex < 0) {
                            continue
                        }
                        const value: number = buff.buffer.GetValue(buff.buffer.LastItemInTestingIndex)
                        if (value && value !== buff.EmptyValue) {
                            const valueStr = value.toFixed(this.indicator.Digits)
                            let valueWidth =
                                getTextWidth(this.context, valueStr, this.indicatorLastValueFont.toString()) *
                                window.devicePixelRatio

                            if (!valueWidth) {
                                valueWidth = this.indicatorLastValueFont.getTextWidthByContext(
                                    valueStr.replaceAll(/./g, '0'),
                                    this.context
                                )
                                OscIndicatorConfigurationControl.valuesWidthByContext.set(valueStr.length, valueWidth)
                            }

                            const rect = new TRect(
                                rightEdge,
                                this.getLocation().Top,
                                rightEdge + valueWidth + step,
                                this.getLocation().Bottom
                            )
                            this.indicatorValuesMap.set(buff.name, { rect, buff, valueStr });

                            rightEdge += valueWidth + step
                        }
                    }
                }
            }

            // update Control location
            locationControl.Right = rightEdge
            locationControl.Bottom = locationControl.Top + 24 * dpr
            this.setLocation(locationControl)
        } catch (error) {
            // this.nameWidth = 0
            throw new StrangeError('IndicatorConfigurationControl.adjustControlWidth', error)
        }
    }

    public draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }

        if (!this.context) {
            this.context = canvas.graphics.Context
        }

        if (!this.indicator.IsVisible()) {
            this.updateCollapsedHeight()
        }

        this.adjustControlWidth()

        const dpr = window.devicePixelRatio || 1
        const _rect = this.getLocation()
        const indicatorName = this.indicator.GetNameWithParams()

        canvas.FillRectRounded(_rect, this.mouseInsideBrush, 5 * dpr)

        if (this.indicator.IsVisible()) {
            canvas.textOut(
                _rect.Left + 5 * dpr,
                _rect.Top + 16 * dpr,
                indicatorName,
                this.indicatorNameFont,
                this.nameBrush,
                true
            )
            if (this.Indicator.DisplayValuesOnIndicatorConfigurationControl()) {
                for (const [name, { rect, buff, valueStr }] of this.indicatorValuesMap.entries()) {
                    let colorValue = this.lastValueBrush

                    if (buff && buff.style && buff.style.color) {
                        colorValue = new IGPSolidBrush(buff.style.color)
                    }
                    canvas.textOut(rect.Left, rect.Top + 16 * dpr, valueStr, this.indicatorLastValueFont, colorValue, true);
                }
            }
        } else {
            canvas.textOut(
                _rect.Left + 5 * dpr,
                _rect.Top + 16 * dpr,
                indicatorName,
                this.indicatorNameFont,
                new IGPSolidBrush('#808080'),
                true
            )
        }

        if (this.isMouseInside()) {
            for (const control of this.indicatorControls) {
                control.draw(canvas, this.indicator.IsVisible())
            }
        } else {
            if (!this.indicator.IsVisible()) {
                this.visibilityControl && this.visibilityControl.draw(canvas, false)
            }
        }
    }

    private updateCollapsedHeight() {
        const OscWins = this.ownerChart.OscWins
        if (OscWins) {
            for (const oscWin of OscWins) {
                if (this.isOwnerOscChart(oscWin.chart)) {
                    oscWin.updateCollapsedHeight()
                    break
                }
            }
        }
    }

    private processVisibilityChange(): void {
        if (this.indicator.IsVisible()) {
            this.indicator.Hide()
        } else {
            this.indicator.Show()
        }
    }

    public onOwnerIndicatorHide(): void {
        if (this.visibilityControl && GlobalImageManager.Instance.eyeCloseImage) {
            this.visibilityControl.setIsMouseInside(false)
            this.setIsMouseInside(false)
        }
        const oscChart = this.chart as TOscChart
        if (oscChart) {
            oscChart.onIndicatorHide()
        }
        const OscWins = this.ownerChart.OscWins
        if (OscWins) {
            let needRedraw = false
            for (const oscWin of OscWins) {
                if (this.isOwnerOscChart(oscWin.chart)) {
                    oscWin.collapseOscWin()
                    needRedraw = true
                    break
                }
            }

            if (needRedraw) {
                this.adjustControlWidth()
                this.ownerChart.onBrowserWndSizing()
            }
        }
    }

    public onOwnerIndicatorShow(): void {
        if (this.visibilityControl && GlobalImageManager.Instance.eyeImage) {
            this.visibilityControl.setIsMouseInside(false)
            this.setIsMouseInside(false)
        }
        const oscChart = this.chart as TOscChart
        if (oscChart) {
            oscChart.onIndicatorShow()
        }
        const OscWins = this.ownerChart.OscWins
        if (OscWins) {
            for (const oscWin of OscWins) {
                if (this.isOwnerOscChart(oscWin.chart)) {
                    oscWin.expandOscWin()
                    this.adjustControlWidth()
                    this.ownerChart.onBrowserWndSizing()
                    break
                }
            }
        }
    }

    public onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        const eventLocal = this.eventToLocal(event)
        if (super.onMouseDown(eventLocal, sender) !== null) {
            for (let i = 0; i < this.indicatorControls.length; i++) {
                const on = this.indicatorControls[i].onMouseDown(eventLocal, sender)
                if (on) {
                    switch (on.controlId) {
                        case ChartControlId.INDICATOR_DELETE: {
                            this.notify(chartControlEvent.INDICATOR_DELETE)

                            break
                        }
                        case ChartControlId.INDICATOR_VISIBILITY: {
                            this.processVisibilityChange()
                            this.notify(chartControlEvent.INDICATOR_VISIBILITY_CHANGE)

                            break
                        }
                        case ChartControlId.INDICATOR_SETTINGS: {
                            this.notify(chartControlEvent.INDICATOR_SHOW_INDICATOR_SETTINGS)

                            break
                        }
                    }
                    this.notify(chartControlEvent.INDICATOR_SELECTED_BY_CONF_CONTROL)
                    return this
                }
            }
            this.notify(chartControlEvent.INDICATOR_SELECTED_BY_CONF_CONTROL)
            return this
        }
        return null
    }

    public onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        const eventLocal = this.eventToLocal(event)
        if (super.onMouseMove(eventLocal, sender)) {
            this.adjustControlWidth()
            for (let i = 0; i < this.indicatorControls.length; i++) {
                if (this.indicatorControls[i].onMouseMove(eventLocal, sender)) {
                    return this
                }
            }
            return this
        } else {
            this.onMouseLeave(event)
        }

        return null
    }

    public onMouseLeave(event: MouseEvent): ChartControl | null {
        this.onMouseLeaveControl()
        this.adjustControlWidth()
        for (const control of this.indicatorControls) {
            const eventLocal = this.eventToLocal(event)
            control.onMouseLeave(eventLocal)
        }
        return null
    }

    private eventToLocal(event: MouseEvent): MouseEvent {
        const eventLocal = this.chart.MouseToLocal()
        return new MouseEvent(event.type, {
            bubbles: event.bubbles,
            cancelable: event.cancelable,
            view: event.view,
            detail: event.detail,
            screenX: event.screenX,
            screenY: event.screenY,
            clientX: eventLocal.x,
            clientY: eventLocal.y,
            ctrlKey: event.ctrlKey,
            altKey: event.altKey,
            shiftKey: event.shiftKey,
            metaKey: event.metaKey,
            button: event.button,
            buttons: event.buttons,
            relatedTarget: event.relatedTarget
        })
    }

    protected onMouseEnterControl(): void {
        if (
            !this.isMouseInside() &&
            this.indicator &&
            this.indicator.OutputWindow === TOutputWindow.ow_SeparateWindow
        ) {
            this.notify(chartControlEvent.NEED_REDRAW_OSC_INDICATOR_CONTROL)
        }

        super.onMouseEnterControl()
    }

    protected onMouseLeaveControl(): void {
        if (this.isMouseInside() && this.indicator && this.indicator.OutputWindow === TOutputWindow.ow_SeparateWindow) {
            this.notify(chartControlEvent.NEED_REDRAW_OSC_INDICATOR_CONTROL)
        }

        super.onMouseLeaveControl()
    }
}
