import { FC, useMemo, useState, useCallback, SetStateAction, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal } from '@fto/ui'

import { TEXT_LOCALES } from '../../constants/texts'
import { REASONS } from '../../constants'
import { ReasonDataType, ReasonType } from '../../types'
import Header from '../Header'
import Controls from '../Controls'

import OptionRow from '../OptionRow'
import { fireMixpanelEvent } from '@root/utils/api'

type Props = {
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
}
const MainBlock: FC<Props> = ({ setSuspendData }) => {
    const { t } = useTranslation()

    const [step, setStep] = useState<ReasonType>(REASONS.priceToHigh)

    const options = useMemo(() => {
        return Object.entries(TEXT_LOCALES.mainBlock.options).map(([key, value]) => ({ key, label: value }))
    }, [])

    const onSubmit = useCallback(() => {
        setSuspendData((prev) => ({ ...prev, reason: step as ReasonType }))
        fireMixpanelEvent('subscription_suspend_feedback_provided', { feedback: step })
    }, [setSuspendData, step])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <Header />
                    <Flex direction='column' gap={8}>
                        {options.map((option) => (
                            <OptionRow
                                key={option.key}
                                idKey={option.key}
                                label={t(option.label)}
                                onClick={(key) => setStep(key as ReasonType)}
                                isActive={step === option.key}
                            />
                        ))}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Controls onSubmit={onSubmit} />
        </>
    )
}

export default MainBlock
