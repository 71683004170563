import { CSSProperties, FC, useMemo } from 'react'
import { t } from 'i18next'

import { FilenameTypes, Icon } from '@fto/icons'
import { News } from '@fto/lib/News/News'
import { Button, Flex, Typography, removeContextMenu, Separator } from '@fto/ui'
import { ALL_COUNTRIES, EURO_ZONE_ENTITY } from '@root/constants/countries'

import {
    getColorForNewsValues,
    getColorForPriority,
    getTimeAndDateFromUnix
} from '../NewsContextMenu/helpers/newsHelpers'

import styles from '../common.module.scss'
import { FixedSizeList } from 'react-window'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

type Props = {
    news: News[]
    setIsNewsOpen: (value: boolean) => void
}

type RowRendererProps = {
    data: {
        list: News[]
        setIsOpen: (value: boolean) => void
    }
    index: number
    style: CSSProperties
}

const RowRenderer: FC<RowRendererProps> = ({ data, index, style }) => {
    const { list, setIsOpen } = data
    const item = list[index]

    return (
        <div style={{ ...style, padding: '8px 12px' }}>
            <NewsItem
                key={item.Time + item.Title + item.Country}
                newsItem={item}
                index={index}
                total={list.length}
                setIsOpen={setIsOpen}
            />
        </div>
    )
}

const NewsMenuContent: FC<Props> = ({ news, setIsNewsOpen }) => {
    return (
        <Flex direction='column' className={styles.newsContainer}>
            <FixedSizeList
                height={news.length > 3 ? 450 : news.length * 157 + 48}
                width={260}
                itemSize={150}
                itemCount={news.length}
                itemData={{
                    list: news,
                    setIsOpen: setIsNewsOpen
                }}
                className={styles.newsList}
            >
                {RowRenderer}
            </FixedSizeList>
        </Flex>
    )
}

const NewsItem: FC<{ newsItem: News; index: number; total: number; setIsOpen: (value: boolean) => void }> = ({
    newsItem,
    index,
    total,
    setIsOpen
}) => {
    const { time, date } = useMemo(
        () =>
            getTimeAndDateFromUnix(
                GlobalTimezoneDSTController.Instance.convertFromInnerLibUnixSecondsByTimezoneAndDst(newsItem.Time)
            ),
        [newsItem.Time]
    )
    const actualEffectColor = useMemo(
        () => getColorForNewsValues(newsItem.Actual, newsItem.Previews),
        [newsItem.Actual, newsItem.Previews]
    )
    const isUpcoming =
        newsItem.Time > DateUtils.toUnixTimeSeconds(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))
    const iconName = useMemo(
        () =>
            ALL_COUNTRIES.concat(EURO_ZONE_ENTITY).find((country) => country.name === newsItem.Country)
                ?.iconName as (typeof FilenameTypes)[number],
        [newsItem.Country]
    )

    const handleOpenNews = () => {
        setIsOpen(true)
        removeContextMenu(CONTEXT_MENU_NAMES.news)
    }

    return (
        <Flex direction='column' gap={16} className={index !== 0 ? styles.newsMargin : ''}>
            <Flex direction='column' gap={8}>
                <Flex gap={8}>
                    <Flex direction='column' gap={4}>
                        <Typography className={styles.time} type='subtext-bold'>
                            {time}
                        </Typography>
                        <Flex gap={4}>
                            <Icon name={iconName} size={20} />
                            <Icon name='importance' color={getColorForPriority(newsItem.Priority)} size={16} />
                        </Flex>
                    </Flex>
                    <Flex direction='column' gap={4} alignItems='flex-start'>
                        <Typography type='interface-semibold' truncate title={newsItem.Title} className={styles.title}>
                            {newsItem.Title}
                        </Typography>
                        <Typography type='subtext-regular' color={'gray-600'}>
                            {date}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('news.actual')}</Typography>
                    <Typography type='interface-semibold' color={isUpcoming ? 'gray-1000' : actualEffectColor}>
                        {isUpcoming ? '-' : newsItem.Actual}
                    </Typography>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('news.forecast')}</Typography>
                    <Typography type='interface-regular'>{newsItem.Consensus}</Typography>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('news.previous')}</Typography>
                    <Typography type='interface-regular'>{newsItem.Previews}</Typography>
                </Flex>
            </Flex>
            {index === total - 1 ? (
                <Button
                    block
                    label={t('news.allNews')}
                    type='tetriatry-white'
                    size='small'
                    classNames={{ root: styles.newsButton }}
                    onClick={handleOpenNews}
                />
            ) : (
                <Separator width='100%' />
            )}
        </Flex>
    )
}

export default NewsMenuContent
