import { FC } from 'react'

import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { COLOR_NAMES, Flex, Typography } from '@fto/ui'
import SymbolPair from '@root/components/SymbolPair'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import { selectSymbol } from '../../../helpers'

import styles from './common.module.scss'

type Props = {
    symbol: string
    modalType: 'symbolChange' | 'addChart'
    chartWindow: TChartWindow | undefined
    addSymbolToProject: (symbol: string) => void
    setSelectedChartInfo: (chartInfo: any) => void
}

const ProjectSymbolsRow: FC<Props> = ({ symbol, modalType, chartWindow, addSymbolToProject, setSelectedChartInfo }) => {
    const symbolInfo = GlobalSymbolList.SymbolList.GetExistingSymbol(symbol)
    if (!symbolInfo) return <></>
    const isNoData = symbolInfo.isCurrentTestingDateInAvailableRange

    let lastPrice = 0
    let priceChange = 0
    let priceChangeColor = 'gray-800' as (typeof COLOR_NAMES)[number]
    if (!isNoData) {
        lastPrice = symbolInfo.LastPrice()
        priceChange = symbolInfo.DayPriceChange().value
        priceChangeColor = priceChange > 0 ? 'green-400' : priceChange === 0 ? 'gray-800' : 'red-400'
    }

    return (
        <Flex
            direction='row'
            gap={16}
            alignItems='center'
            className={styles.symbolRow}
            onClick={() => selectSymbol(symbol, modalType, chartWindow, addSymbolToProject, setSelectedChartInfo)}
        >
            <Flex gap={16} style={{ flex: 7 }} alignItems='center' className={styles.cellLabel}>
                <SymbolPair symbolSize={20} symbol={symbol} />
                <Typography type='interface-semibold' color={'gray-800'} block truncate>
                    {symbol}
                </Typography>
            </Flex>
            <Typography
                type='interface-regular'
                color={'gray-800'}
                block
                truncate
                className={styles.cellLabel}
                style={{ flex: 4 }}
            >
                {lastPrice.toFixed(symbolInfo.symbolInfo.decimals)}
            </Typography>
            <Typography
                type='interface-regular'
                color={priceChangeColor}
                block
                truncate
                className={styles.cellLabel}
                style={{ flex: 4 }}
            >
                {priceChange.toFixed(symbolInfo.symbolInfo.decimals)}
            </Typography>
        </Flex>
    )
}

export default ProjectSymbolsRow
