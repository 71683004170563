import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ColorPicker, Flex, SimpleDropdown, Tooltip, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { FontStylesType } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { FONT_SIZE_OPTIONS } from '@root/pages/ChartPage/components/GraphToolModal/components/FieldCreator/components/TextStyle/constants'

import HoverElement from '../../../HoverElement'
import TriggerElement from '../../../TriggerElement'
import { useTranslation } from 'react-i18next'

type Props = {
    tools: any[]
}

const initialFontStyles = {
    color: {
        value: '',
        hasDifferentValues: false
    },
    fontSize: 1
}

const TextBlock: FC<Props> = ({ tools }) => {
    const { t } = useTranslation()

    const [fontStyles, setFontStyles] = useState<FontStylesType>(initialFontStyles)

    const [singleTool] = tools

    const isMultiSelection = useMemo(() => tools.length > 1, [tools])

    useEffect(() => {
        if (tools.length > 0) {
            const toolParams = singleTool.getFontStyles()

            setFontStyles(
                isMultiSelection
                    ? {
                          ...initialFontStyles,
                          fontSize: toolParams.fontSize
                      }
                    : toolParams
            )
        }
    }, [tools, isMultiSelection])

    const isMultiColoring = useMemo(() => {
        const hasCalculatedDifferentValues = tools.some((tool) => {
            const fontStyles = tool.getFontStyles()

            return fontStyles.color.hasDifferentValues
        })

        const allToolsColors = new Set(
            tools.map((tool) => {
                const fontStyles = tool.getFontStyles()

                return fontStyles.color.value
            })
        )

        return hasCalculatedDifferentValues || (tools.length > 1 && Array.from(allToolsColors).length > 1)
    }, [tools, fontStyles])

    const isMultiSized = useMemo(() => {
        const allFontSizes = new Set(
            tools.map((tool) => {
                const fontStyles = tool.getFontStyles()

                return fontStyles.fontSize
            })
        )

        return tools.length > 1 && Array.from(allFontSizes).length > 1
    }, [tools, fontStyles])

    const color = useMemo(() => {
        if (tools.length > 1 && !isMultiColoring) {
            return singleTool.getFontStyles().color.value
        }

        return fontStyles.color.value
    }, [tools, fontStyles.color, isMultiColoring])

    const fontSize = useMemo(() => {
        if (tools.length > 1 && !isMultiColoring) {
            return singleTool.getFontStyles().fontSize
        }

        return fontStyles.fontSize
    }, [tools, fontStyles.fontSize, isMultiColoring])

    const handleColorChange = useCallback(
        (color: string) => {
            tools.forEach((tool) => {
                const fontStyles = tool.getFontStyles()

                if (tool.hasText) {
                    tool.setFontStyles(color, fontStyles.fontSize)
                }
            })

            setFontStyles((prevState) => ({
                ...prevState,
                color: {
                    ...prevState.color,
                    value: color
                }
            }))
        },
        [tools]
    )

    const handleFontSizeChange = useCallback(
        (size: number) => {
            tools.forEach((tool) => {
                const fontStyles = tool.getFontStyles()
                if (tool.hasText) {
                    tool.setFontStyles(fontStyles.color.value, size)
                }
            })

            setFontStyles((prevState) => ({
                ...prevState,
                fontSize: size
            }))
        },
        [tools]
    )

    return (
        <Flex alignItems='center' gap={4}>
            <ColorPicker
                triggerElement={
                    <TriggerElement
                        color={color}
                        tooltipLabel={t('graphToolPanel.tooltips.textColor')}
                        type='textColor'
                        isMultiColoring={isMultiColoring}
                    />
                }
                color={color}
                onColorChange={handleColorChange}
                withOpacity={false}
            />
            <SimpleDropdown
                options={FONT_SIZE_OPTIONS}
                value={fontSize}
                onChange={(v) => handleFontSizeChange(+v)}
                disableBackdropChartActivity
            >
                <Tooltip content={t('graphToolPanel.tooltips.textSize')} placement='top' color='dark'>
                    <HoverElement>
                        {isMultiSized ? (
                            <Icon size={18} name='text-size-select' color='var(--color-gray-1000)' />
                        ) : (
                            <Typography type='interface-regular' color='gray-1000'>
                                {fontSize}
                            </Typography>
                        )}
                    </HoverElement>
                </Tooltip>
            </SimpleDropdown>
        </Flex>
    )
}

export default TextBlock
