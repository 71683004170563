import { FC, useEffect } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'
import { fetchActiveSubscriptionInfo } from '@root/utils/api'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

export const ThankYouModal: FC = () => {
    const { t } = useTranslation()

    const { email } = useAppSelector($getUser)

    useEffect(() => {
        fetchActiveSubscriptionInfo().then((response) => {
            const currentOrder = response.data.Orders[response.data.Orders.length - 1]

            const evenData = {
                email: email,
                ecommerce: {
                    transaction_id: currentOrder.Id,
                    value: currentOrder.TotalBeforeTaxInUsd,
                    currency: 'USD',
                    coupon: currentOrder.CouponCode,
                    items: [
                        {
                            item_id: response.data.PayproProductId,
                            item_name: response.data.PayproProductName,
                            price: response.data.NextCharge.Amount,
                            quantity: 1
                        }
                    ]
                }
            }

            fireGoogleTagManagerEvent('purchase', evenData)
        })
    }, [])

    return (
        <Modal name={MODAL_NAMES.payment.thankYou} size='xs'>
            <Modal.Header>
                <Flex direction='column' alignItems='center'>
                    <Icon name='success' size={72} />
                    <Typography type='h3-bold' align='center'>
                        {t('profile.modals.projects.thankYouCongratsHeader')}
                    </Typography>
                    <Typography type='h3-bold' align='center'>
                        {t('profile.modals.projects.thankYouStartedHeader')}
                    </Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.projects.successText')}
                </Typography>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.projects.emailText')}
                </Typography>
            </Modal.Content>
            <Modal.Controls>
                <Button
                    label={t('profile.modals.projects.getStarted')}
                    onClick={() => removeModal(MODAL_NAMES.payment.thankYou)}
                    type='primary'
                    block
                />
            </Modal.Controls>
        </Modal>
    )
}
