import { DateTransitionInfo, TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { TChunkStatus } from '@fto/lib/ft_types/data/chunks/ChunkEnums'

export abstract class DateTransitionStrategy {
    protected _ownerChunk: TBarChunk
    protected _yearTransitionItems: DateTransitionInfo[] = []
    protected _monthTransitionItems: DateTransitionInfo[] = []
    protected _dayTransitionItems: DateTransitionInfo[] = []
    protected _timeTransitionItems: DateTransitionInfo[] = []

    constructor(ownerChunk: TBarChunk) {
        this._ownerChunk = ownerChunk
    }
    public abstract updateTransitionsByPrevChunk(prevChunk: TBarChunk): void
    public abstract calculateTransitionItems(): void

    public onTimezoneOrDstChanged() {
        this._timeTransitionItems = []
        this._yearTransitionItems = []
        this._monthTransitionItems = []
        this._dayTransitionItems = []
        if (this._ownerChunk.Status === TChunkStatus.cs_Loaded) {
            this.calculateTransitionItems()
        }
    }

    public getYearTransitionItems(): DateTransitionInfo[] {
        return this._yearTransitionItems
    }
    public getMonthTransitionItems(): DateTransitionInfo[] {
        return this._monthTransitionItems
    }
    public getDayTransitionItems(): DateTransitionInfo[] {
        return this._dayTransitionItems
    }
    public getTimeTransitionItems(): DateTransitionInfo[] {
        return this._timeTransitionItems
    }
}
