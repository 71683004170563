import { useEffect } from 'react'

interface Config {
    enabled?: boolean
    ref: any
}

export const useAutofocus = ({ enabled = true, ref }: Config) => {
    useEffect(() => {
        if (enabled) {
            setTimeout(() => {
                ref.current?.focus?.()
            }, 0)
        }
    }, [enabled, ref.current])
}
