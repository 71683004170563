import { useCallback } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

const RestartProjectModal = () => {
    const { t } = useTranslation()

    const restart = useCallback(() => {
        const charts = GlobalChartsController.Instance.getAllCharts()
        if (!charts) return
        GlobalChartsController.Instance.RestartProject()
        charts.forEach((chart) => {
            chart.Repaint()
        })
        removeModal(MODAL_NAMES.chart.restartModal)
    }, [])

    return (
        <Modal name={MODAL_NAMES.chart.restartModal}>
            <Modal.Header withBorderBottom>
                <Typography>{t('restartModal.title')}</Typography>
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={8}>
                    <Typography type='interface-bold' color='red-500'>
                        {t('restartModal.warning')}
                    </Typography>
                    <Typography>{t('restartModal.headsUp')}</Typography>
                    <ul className={styles.list}>
                        <li className={styles.listEntry}>
                            <Typography type='interface-bold'>{t('restartModal.listTitleAnalytics')}</Typography>
                            <Typography>{t('restartModal.listTextAnalytics')}</Typography>
                        </li>
                        <li className={styles.listEntry}>
                            <Typography type='interface-bold'>{t('restartModal.listTitleTrades')}</Typography>
                            <Typography>{t('restartModal.listTextTrades')}</Typography>
                        </li>
                        <li className={styles.listEntry}>
                            <Typography type='interface-bold'>{t('restartModal.listTitleHistory')}</Typography>
                            <Typography>{t('restartModal.listTextHistory')}</Typography>
                        </li>
                        <li className={styles.listEntry}>
                            <Typography type='interface-bold'>{t('restartModal.listTitleBacktesting')}</Typography>
                            <Typography>{t('restartModal.listTextBacktesting')}</Typography>
                        </li>
                    </ul>
                    <Flex className={styles.warning} gap={8}>
                        <Icon size={16} name='info' color={'var(--color-orange-600)'} />
                        <Flex direction='column' gap={8}>
                            <Typography type='interface-regular' color='orange-700'>
                                {t('restartModal.warningUndone')}
                            </Typography>
                            <Typography type='interface-bold' color='orange-700'>
                                {t('restartModal.warningSure')}
                            </Typography>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls onSubmit={restart} submitText={t('restartModal.restart')} />
        </Modal>
    )
}

export default RestartProjectModal
