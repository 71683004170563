import * as Yup from 'yup'
import { useState, useCallback } from 'react'

type UseFormValidation = ({
    schema,
    onValid,
    onError
}: {
    schema: Yup.InferType<any>
    onValid?: () => void
    onError?: () => void
    formData: object
}) => {
    submit: () => void
    errors: FormErrors[]
    reset: () => void
    validate: () => void
}

export type FormErrors = {
    [key: string]: string
}

const useSubmitForm: UseFormValidation = ({ schema, formData, onValid, onError }) => {
    const [errors, setError] = useState<FormErrors[]>([])

    const parseError = useCallback((error: Yup.ValidationError) => {
        const errorList: FormErrors[] = []

        error.inner.forEach((e) => {
            errorList.push({
                [e.path as string]: e.message
            })
        })
        setError(errorList)
    }, [])

    const reset = useCallback(() => {
        setError([])
    }, [])

    const validate = useCallback(async () => {
        try {
            await schema.validate(formData, {
                abortEarly: false
            })
            setError([])
        } catch (e: unknown) {
            const err = e as Yup.ValidationError
            parseError(err)
        }
    }, [schema, parseError, formData])

    const submit = useCallback(async () => {
        setError([])
        try {
            await schema.validate(formData, {
                abortEarly: false
            })

            onValid?.()
            setError([])
        } catch (e: unknown) {
            const err = e as Yup.ValidationError
            parseError(err)

            onError?.()
        }
    }, [schema, formData, onValid, parseError, onError])

    return {
        submit,
        errors,
        reset,
        validate
    }
}

export default useSubmitForm
