import { CHECKOUT_PAGE, makeRoute } from '@root/constants/routes'
import { FC, useEffect } from 'react'

const meta = import.meta.env

const FailedPayment: FC = () => {
    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'REDIRECT',
                url: makeRoute(CHECKOUT_PAGE + '?action=payment&success=false')
            },
            meta.VITE_SERVER_PAYMENT_REDIRECT_URL
        )
    }, [])

    return null
}

export default FailedPayment
