import { AxiosError } from 'axios'

import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import AUTH_ACTIONS from '@root/store/store.enums'
import {
    LoginPayload,
    LoginResponse,
    RegisterPayload,
    RejectedAuthResponse,
    SetUserAuthPayload,
    SocialMediaPayload
} from '@root/store/auth/auth.types'
import {
    EMAIL_LOGIN_URL,
    EMAIL_REGISTER_URL,
    GOOGLE_LOGIN_URL,
    MICROSOFT_LOGIN_URL,
    REFRESH_TOKEN_URL
} from '@root/constants/endpoints'
import { fireMixpanelEvent, secureApi } from '@root/utils/api'
import { StatusMessages } from '@root/pages/Auth/constants/errorMessages'
import { User } from '@root/types/user'
import { LOGOUT, SET_USER_AUTH } from '@root/store/auth/auth.slice'
import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { SET_CROWDIN } from '@root/store/appSettings/slice'
import { hasAdminRole } from '@root/store/auth/constants'
import { $useCrowdin } from '@root/store/appSettings/selectors'
import { AppDispatch, RootState } from '@root/store'
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY, LANDING_PAGE_USER_PARAMS } from '@root/constants/localStorageKeys'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'
import { getLocalStorage } from '@root/utils/localStorage'

function saveTokens({ idToken, refreshToken }: LoginResponse) {
    localStorage.setItem(AUTH_TOKEN_KEY, idToken)
    localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refreshToken)
}

const LOGIN_MICROSOFT = createAsyncThunk<
    LoginResponse,
    SocialMediaPayload,
    {
        dispatch: AppDispatch
    }
>(AUTH_ACTIONS.LOGIN_MICROSOFT, async ({ IdToken }, { rejectWithValue, dispatch }) => {
    try {
        const landingPageUserParams = getLocalStorage(LANDING_PAGE_USER_PARAMS)
        const params = {
            IdToken,
            ...(landingPageUserParams ? { ...landingPageUserParams } : {})
        }

        const { data } = await secureApi.post<LoginResponse>(MICROSOFT_LOGIN_URL, params)

        if (landingPageUserParams) {
            localStorage.removeItem(LANDING_PAGE_USER_PARAMS)
        }

        saveTokens(data)
        await dispatch(LOOKUP())
        return data
    } catch (e: unknown) {
        const err = e as Error

        return rejectWithValue(err.message)
    }
})

const LOGIN_GOOGLE = createAsyncThunk<
    LoginResponse,
    SocialMediaPayload,
    {
        dispatch: AppDispatch
    }
>(AUTH_ACTIONS.LOGIN_GOOGLE, async ({ IdToken }, { rejectWithValue, dispatch }) => {
    try {
        const landingPageUserParams = getLocalStorage(LANDING_PAGE_USER_PARAMS)

        const params = {
            IdToken,
            ...(landingPageUserParams ? { ...landingPageUserParams } : {})
        }

        const { data } = await secureApi.post<LoginResponse>(GOOGLE_LOGIN_URL, params)

        if (landingPageUserParams) {
            localStorage.removeItem(LANDING_PAGE_USER_PARAMS)
        }

        if (data.isNewUser) {
            fireGoogleTagManagerEvent('sign_up', { login_method: 'Google' })
        }

        saveTokens(data)
        await dispatch(LOOKUP())
        return data
    } catch (e: unknown) {
        const err = e as Error

        return rejectWithValue(err.message as string)
    }
})

const LOGIN = createAsyncThunk<LoginResponse, LoginPayload, { rejectValue: StatusMessages; dispatch: AppDispatch }>(
    AUTH_ACTIONS.LOGIN,
    async (credentials, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await secureApi.post<LoginResponse>(EMAIL_LOGIN_URL, credentials)
            saveTokens(data)
            await dispatch(LOOKUP())

            fireMixpanelEvent('log_in', { login_method: 'Email' })

            return data
        } catch (e: unknown) {
            const err = e as RejectedAuthResponse
            return rejectWithValue(err.status || 'unknown')
        }
    }
)

const REGISTER_BY_EMAIL = createAsyncThunk<LoginResponse, RegisterPayload, { rejectValue: StatusMessages }>(
    AUTH_ACTIONS.REGISTER,
    async (credentials, { rejectWithValue }) => {
        try {
            const landingPageUserParams = getLocalStorage(LANDING_PAGE_USER_PARAMS)

            const params = {
                ...credentials,
                ...(landingPageUserParams ? { ...landingPageUserParams } : {})
            }

            const { data } = await secureApi.post<LoginResponse>(EMAIL_REGISTER_URL, params)

            if (landingPageUserParams) {
                localStorage.removeItem(LANDING_PAGE_USER_PARAMS)
            }

            saveTokens(data)
            return data
        } catch (e: unknown) {
            const err = e as RejectedAuthResponse
            return rejectWithValue(err.status || 'unknown')
        }
    }
)

const LOOKUP = createAsyncThunk<User, void, { state: RootState }>(
    AUTH_ACTIONS.LOOKUP,
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const { data } = await secureApi.get<User>('/identity/api/Account/Lookup')
            const payload: SetUserAuthPayload = { user: data, isAuthenticated: true, userIsLoaded: true }
            dispatch(SET_USER_AUTH(payload))

            const useCrowdin = $useCrowdin(getState())

            if (!hasAdminRole(data) && useCrowdin) {
                dispatch(SET_CROWDIN(false))
            }

            return data
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 401 && e.request.responseURL === REFRESH_TOKEN_URL) {
                    dispatch(LOGOUT())
                    window.location.href = makeRoute(LOGIN_ROUTE)
                }
            }
            const err = e as RejectedAuthResponse
            return rejectWithValue(err.status || 'unknown')
        }
    }
)

export const isAnyLoginActionFulfilled = isFulfilled(LOGIN_MICROSOFT, LOGIN_GOOGLE, LOGIN, REGISTER_BY_EMAIL)
export const isAnyLoginActionRejected = isRejected(LOGIN_MICROSOFT, LOGIN_GOOGLE, LOGIN)
export const isAnyLoginActionPending = isPending(LOGIN_MICROSOFT, LOGIN_GOOGLE, LOGIN, REGISTER_BY_EMAIL)

export { LOGIN_MICROSOFT, LOGIN_GOOGLE, LOGIN, REGISTER_BY_EMAIL, LOOKUP }
