import StrangeError from '../common/common_errors/StrangeError'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export type TColor = string // Simplified TColor

export enum DelphiColors {
    clBlack = '#000000',
    clMaroon = '#800000',
    clGreen = '#008000',
    clOlive = '#808000',
    clNavy = '#000080',
    clPurple = '#800080',
    clTeal = '#008080',
    clGray = '#808080',
    clSilver = '#C0C0C0',
    clRed = '#FF0000',
    clLime = '#00FF00',
    clYellow = '#FFFF00',
    clBlue = '#0000FF',
    clFuchsia = '#FF00FF',
    clAqua = '#00FFFF',
    clWhite = '#FFFFFF',
    clMoneyGreen = '#C0DCC0',
    clSkyBlue = '#87CEEB',
    clCream = '#FFFDD0',
    clMedGray = '#A0A0A4',
    clMediumAquamarine = '#66CDAA'
}

export type TCanvas = CanvasRenderingContext2D

export enum TFontStyle {
    fsNone = 0,
    fsBold = Math.trunc(1), // 1
    fsItalic = 1 << 1, // 2
    fsUnderline = 1 << 2, // 4
    fsStrikeout = 1 << 3 // 8
}

export type TFontStyles = number

export class TFontStylesOperations {
    static addFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles | style
    }

    static removeFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles & ~style
    }

    static toggleFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles ^ style
    }

    static hasFontStyle(styles: TFontStyles, style: TFontStyle): boolean {
        return (styles & style) !== 0
    }
}

export class TMessage {
    msg: number
    wParam: number | string | null
    lParam: number | string | null
    result: number

    constructor(msg: number, wParam?: number | string, lParam?: number | string, result = 0) {
        this.msg = msg
        this.wParam = wParam || null
        this.lParam = lParam || null
        this.result = result
    }
}

export class THashedStringList {
    private internalMap: Map<string, any> //TODO: use something more efficient here if needed

    constructor() {
        this.internalMap = new Map<string, any>()
    }

    add(key: string): void {
        this.internalMap.set(key, null)
    }

    addObject(key: string, obj: any): void {
        this.internalMap.set(key, obj)
    }

    getObject(key: string): any | undefined {
        return this.internalMap.get(key)
    }

    remove(key: string): boolean {
        return this.internalMap.delete(key)
    }

    containsKey(key: string): boolean {
        return this.internalMap.has(key)
    }

    clear(): void {
        this.internalMap.clear()
    }

    Delete(index: number): void {
        if (index < 0 || index >= this.internalMap.size) {
            throw new StrangeError(`THashedStringList.Delete - Index out of bounds ${index}`)
        }

        let currentIndex = 0
        for (const key of this.internalMap.keys()) {
            if (currentIndex === index) {
                this.internalMap.delete(key)
                return
            }
            currentIndex++
        }
    }

    IndexOf(key: string): number {
        let index = 0
        for (const currentKey of this.internalMap.keys()) {
            if (currentKey === key) {
                return index
            }
            index++
        }
        return -1 // Return -1 if the key is not found, similar to Array.indexOf behavior
    }

    get keys(): string[] {
        return [...this.internalMap.keys()]
    }

    get count(): number {
        return this.internalMap.size
    }
}

//TODO: get rid of these arrays and use normal arrays instead
export class DelphiLikeArray<T> extends Array<T> {
    constructor() {
        super()
    }

    // get items(): T[] {
    //     return this;
    // }

    // public item(index: number): T {
    //     return this[index];
    // }
    SetLength(newLength: number): void {
        if (newLength > this.length) {
            throw new StrangeError('DelphiLikeArray - Increasing the length of the array is not supported')
        }
        this.length = newLength
    }

    add(value: T): void {
        this.push(value)
    }

    insert(index: number, value: T): void {
        this.splice(index, 0, value)
    }

    remove(value: T): boolean {
        const index = this.indexOf(value)
        if (index >= 0) {
            this.splice(index, 1)
            return true
        }
        return false
    }

    removeByIndex(index: number): void {
        this.splice(index, 1)
    }

    extract(value: T): T | null {
        const index = this.indexOf(value)
        if (index >= 0) {
            return this.splice(index, 1)[0]
        }
        return null
    }

    Delete(index: number): void {
        if (index >= 0 && index < this.length) {
            this.splice(index, 1)
        } else {
            throw new StrangeError(`DelphiLikeArray.Delete Index out of bounds ${index}`)
        }
    }

    get LastItem(): T {
        return this[this.length - 1]
    }

    clear(): void {
        this.splice(0, this.length)
    }

    get count(): number {
        return this.length
    }

    get Count(): number {
        return this.length
    }

    public IndexValid(index: number): boolean {
        return this.length > 0 && index >= 0 && index < this.length
    }
}

export class TPngImage {
    protected image: HTMLImageElement
    private imageSourceURL: string

    constructor(imageSourceURL: string) {
        this.imageSourceURL = imageSourceURL
        this.image = new Image()
    }

    Load(): void {
        this.image.src = this.imageSourceURL
    }

    private LoadIfNecessary(): void {
        if (!this.image.src) {
            this.Load()
        }
    }

    public Draw(html_canvas: HTMLCanvasElement, x: number, y: number): void {
        this.LoadIfNecessary()

        const canvasContext = html_canvas.getContext('2d')
        if (!canvasContext) {
            return
        }

        // Drawing the specified image onto the canvas at the specified coordinates
        canvasContext.drawImage(this.image, x, y)
    }

    public Draw_inRect(html_canvas: HTMLCanvasElement, rect: TRect): void {
        this.LoadIfNecessary()

        const canvasContext = html_canvas.getContext('2d')
        if (!canvasContext) {
            return
        }

        // Drawing the specified image onto the canvas within the specified rectangle
        canvasContext.drawImage(this.image, rect.Left, rect.Top, rect.Width, rect.Height)
    }
}
