import { TutorialCheckBase } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TutorialCheckBase'
import { TCriteriaOperator } from '@fto/lib/Education/Types'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'

export class TotalPNL extends TutorialCheckBase {
    constructor(criteriaOperator: TCriteriaOperator, value: { valueStart: number; valueEnd: number } | number) {
        super(criteriaOperator, value)
    }

    checkEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() === (this.value as number)
    }

    checkMore(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() > (this.value as number)
    }

    checkLess(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() < (this.value as number)
    }

    checkNotEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() !== (this.value as number)
    }

    checkMoreOrEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() >= (this.value as number)
    }

    checkLessOrEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() <= (this.value as number)
    }

    checkRange(): boolean {
        return (
            GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() >=
                (this.value as { valueStart: number; valueEnd: number }).valueStart &&
            GlobalProcessingCore.ProcessingCore.getTotalProfitAndLoss() <=
                (this.value as { valueStart: number; valueEnd: number }).valueEnd
        )
    }

    public check(): boolean {
        return super.check()
    }
}
