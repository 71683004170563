import { FiboTimeZonesJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TNoExactMatchBehavior } from '../../../ft_types/data/chunks/ChunkEnums'
import { TBasicPaintTool } from '../BasicPaintTool'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IChart } from '../../chart_classes/IChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'

export class TPtFiboTimeZones extends TFiboPaintTool {
    fibo = [0, 1, 2, 5, 8, 13, 21, 34]

    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptFiboTimeZones)
        this.ShortName = 'Fibo Time Zones'
        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboTime
        this.icon = 51
        // SetLevels method and fLevels property need to be implemented
        this.SetLevels(this.fibo, 3)
        // this.fEditDialog = FiboTimeZonesFrm; // Replace with actual type when known
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboTimeZones)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboTimeZones)
        }

        if (!styles) throw new StrangeError('Default styles for FiboTimeZones are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboTimeZones {
        const cloneObj = new TPtFiboTimeZones(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fibo = this.fibo
        return cloneObj
    }

    public toJson(): FiboTimeZonesJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            fibo: this.fibo
        }
    }

    public fromJSON(json: FiboTimeZonesJSON): void {
        super.fromJSON(json)

        this.fibo = json.fibo
    }

    public Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }

        if (this.fPoints.Count < 2) {
            return
        }

        const canvas = this.fChart.GdiCanvas
        const index1 = this.fChart.GetGlobalIndexByDate(
            this.fPoints[0].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const index2 = this.fChart.GetGlobalIndexByDate(
            this.fPoints[1].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const R = this.chartPaintRect
        const x1 = this.fPoints[0].x
        const scale = this.fChart.ChartOptions.GetScaleInfo()
        const dx = Math.round((index2 - index1) * scale.PixBetweenBars)
        const dpr = window.devicePixelRatio

        for (let i = 0; i < this.fLevels.Count; i++) {
            const level = this.fLevels[i]
            const x = Math.round(x1 + dx * level.value)

            if (!level.isActive || x < R.Left || x > R.Right) {
                continue
            }

            canvas.MoveTo(x, R.Top)
            canvas.LineTo(x, R.Bottom, level.pen)

            canvas.setFont('Roboto Flex', 1, 12)
            let s = parseFloat(level.text)

            if (Number.isInteger(s)) {
                s.toString()
            } else {
                s.toFixed(20)
                    .replace(/\.?0+$/, '')
                    .toString()
            }
            let result = s.toString()
            canvas.textOut(
                x - canvas.TextWidth(result) - 5 * dpr,
                R.Bottom - 20 * dpr,
                s.toString(),
                this.font,
                this.fLevels[i].brush,
                true
            )
        }
        super.Paint()
    }

    public get isVisibleInChartFrame(): boolean {
        return true
    }

    public assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)
        if (tool instanceof TPtFiboTimeZones) {
            this.fLevels.Assign(tool.fLevels)
        } else {
            throw new StrangeError('Assigned tool must be an instance of TPtFiboTimeZones')
        }
    }

    public ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboTimeZones,
            toolName: 'fiboTimezones'
        })
    }

    public ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { resetToolSettings, getKeyValueData } = GraphToolStore
        const formattedToolSettings = getKeyValueData()
        const { description, lineStyle, levels } = formattedToolSettings

        this.description = description
        this.fLineStyle = lineStyle

        this.updateLevelsFromModal(levels)

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboTimeZones, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptFiboTimeZones,
            levels: this.fLevels.ExportToStr(5)
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboTimeZones, TPtFiboTimeZones)
