export const splitStringByLength = (str: string, key: string = 'key'): Record<string, string> => {
    const MAX_LENGTH = 255
    const result: Record<string, string> = {} // Ensure result is typed as Record<string, string>

    let partIndex = 1
    let remainingStr = str

    while (remainingStr.length > 0) {
        const part = remainingStr.substring(0, MAX_LENGTH)
        remainingStr = remainingStr.substring(MAX_LENGTH)

        // Use type assertion to avoid TS7053 error
        result[`${key}_p${partIndex}` as string] = part
        partIndex++
    }

    return result
}
