import { JumpToStrategy } from '@fto/lib/JumpTo/JumpToStrategies/JumpToStrategy'
import { JumpToSession } from '../JumpToSession'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'

export class ConcreteSessionJumpToClose extends JumpToStrategy {
    private _jumpToSession: JumpToSession
    private _lastProcessedTick: TDateTime

    constructor(jumpToSession: JumpToSession, lastProcessedTick: TDateTime) {
        super()
        this._jumpToSession = jumpToSession
        this._lastProcessedTick = lastProcessedTick
    }

    public jump(): void {
        const NextSessionDT = this._jumpToSession.getNextCloseSessionDateTimeFromTime(this._lastProcessedTick)
        import('@fto/lib/globals/GlobalTestingManager').then((module) => {
            const GlobalTestingManager = module.default
            GlobalTestingManager.TestingManager.RollTestingTo(NextSessionDT, true)
        })
    }
}
