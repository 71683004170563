export default class MathUtils {
    public static roundTo(value: number, precision: number): number {
        const multiplier = Math.pow(10, precision)
        return Math.round(value * multiplier) / multiplier
    }

    public static isEqualRounded(value1: number, value2: number, decimals = 0): boolean {
        return this.roundTo(value1, decimals) === this.roundTo(value2, decimals)
    }
}
