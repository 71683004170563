import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Flex, Checkbox, InputField, ColorPicker, SimpleDropdown, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { SessionType } from '../../types'

import styles from './index.module.scss'
import { TimeValues } from '@fto/lib/extension_modules/common/CommonTypes'

type Props = {
    session: SessionType
    onChange: (params: { value: any; id: number; key: string; isCustom: boolean; alias?: string }) => void
    onRemove?: (id: SessionType['id']) => void
    isCustom?: boolean
    hasCustomSessions?: boolean
}

const Row: FC<Props> = ({ session, onChange, onRemove, isCustom = false, hasCustomSessions = false }) => {
    const { t } = useTranslation()

    const handleIsActiveChange = useCallback(
        (value: SessionType['isEnabled']) => {
            onChange({ value, id: session.id, key: 'isEnabled', alias: session.alias, isCustom })
        },
        [session.id, isCustom]
    )

    const handleNameChange = useCallback(
        (value: SessionType['name']) => {
            onChange({ value, id: session.id, key: 'name', alias: session.alias, isCustom })
        },
        [session.id, isCustom]
    )

    const handleColorChange = useCallback(
        (value: SessionType['color']) => {
            onChange({ value, id: session.id, key: 'color', alias: session.alias, isCustom })
        },
        [session.id, isCustom]
    )

    const handleStartTimeChange = useCallback(
        (value: SessionType['startTime']) => {
            onChange({ value, id: session.id, key: 'startTime', alias: session.alias, isCustom })
        },
        [session.id, isCustom]
    )

    const handleEndTimeChange = useCallback(
        (value: SessionType['endTime']) => {
            onChange({ value, id: session.id, key: 'endTime', alias: session.alias, isCustom })
        },
        [session.id, isCustom]
    )

    return (
        <Flex gap={8} alignItems='center' className={cx({ [styles.WithPaddingRight]: !isCustom && hasCustomSessions })}>
            <Checkbox checked={session.isEnabled} onChange={handleIsActiveChange} />
            <InputField
                disabled={!isCustom}
                readOnly={!isCustom}
                value={session.name}
                className={styles.InputName}
                onChange={handleNameChange}
                placeholder={t('tradingSessionIndicator.inputPlaceholder')}
            />
            <ColorPicker color={session.color} onColorChange={handleColorChange} withOpacity={false} />
            <Flex gap={4} alignItems='center' className={styles.TimeRange}>
                <SimpleDropdown
                    title={t('tradingSessionIndicator.time')}
                    withArrow={false}
                    onChange={handleStartTimeChange}
                    value={session.startTime}
                    options={TimeValues.TIMES}
                    disabled={!isCustom}
                    classNames={{
                        root: styles.TimePick,
                        content: styles.TimePickContent,
                        header: styles.TimePickHeader
                    }}
                    headerIcon={<Icon name='time' size={18} />}
                />

                <Typography type='interface-medium'>—</Typography>

                <SimpleDropdown
                    withArrow={false}
                    title={t('tradingSessionIndicator.time')}
                    onChange={handleEndTimeChange}
                    value={session.endTime}
                    options={TimeValues.TIMES}
                    disabled={!isCustom}
                    headerIcon={<Icon name='time' size={18} />}
                    classNames={{
                        root: styles.TimePick,
                        content: styles.TimePickContent,
                        header: styles.TimePickHeader
                    }}
                />
            </Flex>
            {isCustom && (
                <div className={styles.IconButton} onClick={() => onRemove?.(session.id)}>
                    <Icon name='delete' size={14} />
                </div>
            )}
        </Flex>
    )
}

export default Row
