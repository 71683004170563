import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { Typography, Tooltip } from '@fto/ui'
import { FilenameTypes, Icon } from '@fto/icons'

import { TPenStyle } from '@fto/ui/lib/components/LineStyleSelector/constants'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    type: 'style' | 'width'
    isMultiLined?: boolean
    value: number
}

const ICON_BY_TYPE = {
    style: 'line-style-select',
    width: 'line-width-select'
}

const TriggerElement: FC<Props> = ({ type, isMultiLined = false, value }) => {
    const { t } = useTranslation()

    const isWidthType = useMemo(() => type === 'width', [type])

    const selectorValueParams = useMemo(() => {
        if (isWidthType) {
            return {
                name: 'solid-line' as (typeof FilenameTypes)[number],
                size: 32,
                height: value as number
            }
        }
        let lineStyleIcon = ''

        switch (value) {
            case TPenStyle.psSolid: {
                lineStyleIcon = 'solid-line'
                break
            }
            case TPenStyle.psDash: {
                lineStyleIcon = 'dashed-line'
                break
            }
            case TPenStyle.psDot: {
                lineStyleIcon = 'shortDashed-line'
                break
            }
            case TPenStyle.psDashDot: {
                lineStyleIcon = 'dotDashed-line'
                break
            }
            case TPenStyle.psDashDotDot: {
                lineStyleIcon = 'doubleDotDashed-line'
                break
            }
            default: {
                lineStyleIcon = 'solid-line'
            }
        }

        return {
            name: lineStyleIcon as (typeof FilenameTypes)[number],
            size: 32,
            height: 2
        }
    }, [isWidthType, value])

    if (isMultiLined) {
        return (
            <Tooltip
                content={t(`graphToolPanel.tooltips.${isWidthType ? 'lineWidth' : 'lineType'}`)}
                placement='top'
                color='dark'
            >
                <div className={cx(styles.TriggerElement, styles.MultiSelect)}>
                    <Icon
                        name={ICON_BY_TYPE[type] as (typeof FilenameTypes)[number]}
                        size={18}
                        color='var(--color-gray-1000)'
                    />
                </div>
            </Tooltip>
        )
    }

    if (type === 'style') {
        return (
            <Tooltip content={t(`graphToolPanel.tooltips.lineType`)} placement='top' color='dark'>
                <div className={styles.TriggerElement}>
                    <Icon {...selectorValueParams} color={'var(--color-gray-1000)'} />
                </div>
            </Tooltip>
        )
    }

    return (
        <Tooltip content={t(`graphToolPanel.tooltips.lineWidth`)} placement='top' color='dark'>
            <div className={cx(styles.TriggerElement, styles.WidthSelector)}>
                <Icon {...selectorValueParams} color={'var(--color-gray-1000)'} />
                <Typography type='subtext-regular' color='gray-1000'>
                    {value}px
                </Typography>
            </div>
        </Tooltip>
    )
}

export default TriggerElement
