import { FC, ReactNode, useCallback, useRef, useEffect, useState } from 'react'
import { Icon } from '@fto/icons'
import { checkIsThereScrollLeft, checkIsThereScrollRight } from './utils'
import styles from './index.module.scss'

type Props = {
    children: ReactNode
    dependencyListToCheckScroll?: any[]
}

const Slider: FC<Props> = ({ children, dependencyListToCheckScroll = [] }) => {
    const [hasScrollLeft, setHasScrollLeft] = useState(false)
    const [hasScrollRight, setHasScrollRight] = useState(false)
    const sliderRef = useRef<HTMLDivElement>(null)

    const checkScroll = useCallback(() => {
        if (sliderRef.current) {
            setHasScrollLeft(checkIsThereScrollLeft(sliderRef.current))
            setHasScrollRight(checkIsThereScrollRight(sliderRef.current))
        }
    }, [hasScrollLeft, hasScrollRight])

    useEffect(() => {
        // Initial scroll check
        checkScroll()

        // Add event listeners for window resize
        const handleResize = () => checkScroll()
        window.addEventListener('resize', handleResize)

        // Create a ResizeObserver to track changes in the size of the slider element
        const sliderElement = sliderRef.current
        let resizeObserver: ResizeObserver | null = null
        const handleScroll = () => checkScroll()

        if (sliderElement) {
            sliderElement.addEventListener('scroll', handleScroll)

            resizeObserver = new ResizeObserver(() => {
                checkScroll() // Update scroll controls when size changes
            })
            resizeObserver.observe(sliderElement)
        }

        // Cleanup listeners on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
            if (sliderElement) {
                sliderElement.removeEventListener('scroll', handleScroll)
            }
            if (resizeObserver && sliderElement) {
                resizeObserver.unobserve(sliderElement)
                resizeObserver.disconnect()
            }
        }
    }, [checkScroll, ...dependencyListToCheckScroll])

    const handleScrollButtonClick = (isLeft: boolean) => {
        const step = window.innerWidth - window.innerWidth * 0.25
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: isLeft ? -step : step,
                behavior: 'smooth'
            })

            // After scrolling, check the scroll state again
            setTimeout(checkScroll, 300) // Ensure the scroll state updates after the smooth scroll
        }
    }

    return (
        <div className={styles.Root}>
            {hasScrollLeft && (
                <div onClick={() => handleScrollButtonClick(true)} className={styles.Controller}>
                    <Icon name='arrow-left' size={15} color='var(--color-white)' />
                </div>
            )}
            <div className={styles.Slider} ref={sliderRef}>
                {children}
            </div>
            {hasScrollRight && (
                <div onClick={() => handleScrollButtonClick(false)} className={styles.ControllerRight}>
                    <Icon name='arrow-left' size={15} color='var(--color-white)' />
                </div>
            )}
        </div>
    )
}

export default Slider
