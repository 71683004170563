import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TCrossHair, TCursorMode } from '@fto/lib/charting/ChartBasicClasses'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { ChartEvent } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import { TPoint } from '../extension_modules/common/CommonExternalInterface'

class CrosshairManager {
    private initializedChart: TChartWindow | null = null
    private crosshairState: TCrossHair | null = null
    private shouldRestorePermanentCrosshair = false

    constructor() {}

    /**
     *
     * @param chart
     * @param rooler if undefined -> leave current state
     */
    public enableCrosshairMode(chart: TChartWindow, rooler?: boolean) {
        if (this.initializedChart === chart) {
            if (rooler !== undefined) {
                this.shouldRestorePermanentCrosshair = rooler
            }

            return
        }
        this.initializedChart = chart
        this.initializedChart.cursorMode = TCursorMode.cm_CrossHair
        this.initializeCrosshairState()
    }

    public restorePermCrosshairIfNeeded(chartWin: TChartWindow) {
        if (!this.shouldRestorePermanentCrosshair) {
            return
        }

        chartWin.notifyFromThisChartWindow(ChartEvent.CROSS_HAIR_MODE_START_PERMANENT)
        this.shouldRestorePermanentCrosshair = false
    }

    private initializeCrosshairState() {
        this.crosshairState = new TCrossHair()
        this.crosshairState.DateTime = -1
        this.crosshairState.roolerDateTime = -1
    }

    public resetToDefaults() {
        if (!this.crosshairState) {
            return
        }

        this.crosshairState.DateTime = -1
        this.crosshairState.roolerDateTime = -1
    }

    public resetCrosshairState() {
        this.initializedChart = null
        this.crosshairState = null
    }

    public updateCrosshairState(chart: TChartWindow, dateTime: TDateTime, price: number) {
        if (this.initializedChart !== chart || this.crosshairState === null) {
            return
        }
        this.crosshairState.DateTime = dateTime
        this.crosshairState.Price = price
    }

    public updateRulerDateTime(
        chart: TChartWindow,
        roolerDateTime: TDateTime,
        roolerPrice: number,
        roundRoolerV = false
    ) {
        if (this.initializedChart !== chart || this.crosshairState === null) {
            return
        }

        this.crosshairState.roolerDateTime = roolerDateTime
        this.crosshairState.roolerPrice = roolerPrice
        this.crosshairState.RoundRoolerV = roundRoolerV
    }

    public getCrosshairState(): TCrossHair | null {
        return this.crosshairState
    }

    public isCrosshairEnabled(): boolean {
        return this.crosshairState !== null
    }

    public isChartSource(chart: TChartWindow): boolean {
        return this.isCrosshairEnabled() && this.initializedChart === chart
    }

    public getProjectedPointOnChart(chart: TChartWindow): TPoint {
        if (!this.crosshairState || !this.initializedChart) {
            throw new Error('Crosshair info is not initialized')
        }
        const newY = chart.MainChart.GetY(this.crosshairState.Price)
        const newX = chart.MainChart.GetXFromDate(this.crosshairState.DateTime)

        return new TPoint(newX, newY)
    }
}

export const crosshairManager = new CrosshairManager()
