import { FC } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { Flex } from '@fto/ui'

import { ColumnsListType, OrdersListType, TabType } from '@root/pages/ChartPage/components/Terminal/types'
import { UserTerminalSettings } from '@root/utils/localStorage'
import { observer } from 'mobx-react-lite'

import RowRenderer from './components/RowRenderer'

import styles from './index.module.scss'

type Props = {
    cellsList: ColumnsListType
    positions: OrdersListType
    activeTab: TabType
    tableSettings: UserTerminalSettings
    selectedOrders: number[]
}

const Body: FC<Props> = observer(({ cellsList, positions, activeTab, tableSettings, selectedOrders }) => {
    return (
        <Flex className={styles.Body} direction='column' gap={4}>
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <FixedSizeList
                            className={styles.Content}
                            height={height}
                            width={width}
                            itemSize={24}
                            itemCount={positions.length}
                            itemData={{
                                list: positions,
                                cellsList,
                                tableSettings,
                                activeTab,
                                selectedOrders
                            }}
                        >
                            {RowRenderer}
                        </FixedSizeList>
                    )
                }}
            </AutoSizer>
        </Flex>
    )
})

export default Body
