import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TMyObjectList } from '@fto/lib/ft_types/common/Common'
import { DateIndex } from './DateIndex'

export class TDateIndexCache extends TMyObjectList<DateIndex> {
    constructor() {
        super()
    }

    public AddItem(DateTime: TDateTime, index: number): void {
        const item = new DateIndex(DateTime, index)
        this.push(item)
    }

    public GetIndexByDate(DateTime: TDateTime): {
        found: boolean
        index: number
    } {
        for (const item of this) {
            if (item.DateTime === DateTime) {
                return { found: true, index: item.index }
            }
        }
        return { found: false, index: -1 }
    }
}
