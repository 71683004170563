import React, { ReactNode, FC, useCallback, useMemo } from 'react'

import styles from './auth-container.module.scss'
import { Button, Flex, Typography } from '@fto/ui'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Icon } from '@fto/icons'
import { useLocation } from 'react-router-dom'
import { LOGIN_ROUTE, SIGN_UP_ROUTE } from '@root/constants/routes'
import { PUBLIC_SITE_URL } from '@root/constants/urls'

type Props = {
    children: ReactNode
    showContent?: boolean
}

const points = ['point1', 'point2', 'point3', 'point4', 'point5']

const AuthContainer: FC<Props> = ({ children, showContent }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const isExternal = useMemo(() => {
        const params = new URLSearchParams(window.location.search)

        return (
            params.get('external') === 'true' &&
            (location.pathname.includes(LOGIN_ROUTE) || location.pathname.includes(SIGN_UP_ROUTE))
        )
    }, [])

    const redirectBaseDomain = useCallback(() => {
        window.location.replace(PUBLIC_SITE_URL)
    }, [])
    return (
        <div className={styles.authContainer}>
            {isExternal && (
                <Button
                    type={'secondary'}
                    onClick={redirectBaseDomain}
                    label={t('global.back')}
                    classNames={{
                        root: styles.authBackButton,
                        content: styles.authBackButtonContainer,
                        label: styles.authBackButtonContainer
                    }}
                    before={<Icon name='arrow-left' size={14} color={'--color-gray-1000'} />}
                />
            )}

            <Flex
                block
                className={styles.authFlexContent}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
            >
                {showContent && (
                    <Flex direction={'column'} className={styles.authContent} gap={24}>
                        <span>
                            <h1 className={styles.authTitle}>{t('auth.authContentTitle')}</h1>
                            <h1 className={cx(styles.authTitle, styles.colored)}>
                                {t('auth.authContentColoredTitle')}
                            </h1>
                        </span>
                        <Typography type='text-medium'>{t('auth.authContentTopText')}</Typography>
                        <Flex direction='column' className={styles.pointsWrapper} gap={8}>
                            {points.map((point) => {
                                return (
                                    <Flex gap={8} key={point} alignItems='center'>
                                        <Icon name='colored-check' size={16} />
                                        <Typography type='text-medium'>{t('auth.' + point)}</Typography>
                                    </Flex>
                                )
                            })}
                        </Flex>
                        <Typography type='h3-medium'>{t('auth.authContentBottomText')}</Typography>
                    </Flex>
                )}
                <div className={styles.authWrapper}>{children}</div>
            </Flex>
        </div>
    )
}
export default AuthContainer
