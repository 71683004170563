import { FC, useCallback, useEffect } from 'react'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'

import TimeframeMenuContent from '../components/TimeframeMenuContent'

import styles from '../common.module.scss'
import { ChangeSymbolButton } from '@fto/chart_components/ChangeSymbolButton'
import { observer } from 'mobx-react-lite'
import ProjectStore from '@fto/lib/store/projectStore'
import TimeframeStore from '@fto/lib/store/timeframe'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        chartWindow: TChartWindow
    }
}

const TimeframeContextMenu: FC<Props> = observer(({ anchorX, anchorY, additionalProps }) => {
    const { data, updateData } = ProjectStore
    const store = TimeframeStore

    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.timeframeChange)
        GlobalChartsController.Instance.enableMouseEvents()
        updateData({ ...data, isTimeframeDropdownOpen: false })
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu
            name={CONTEXT_MENU_NAMES.timeframeChange}
            anchorX={anchorX}
            anchorY={anchorY}
            additionalClassNames={{ container: styles.timeframe }}
            stopPropagation
        >
            <TimeframeMenuContent removeMenu={removeMenu} chartWindow={additionalProps.chartWindow} store={store} />
        </ContextMenu>
    )
})

export default TimeframeContextMenu
