import { MarketValues } from '../../OrderWndStructs'
import { PriceValidationStrategy } from '../PriceValidationStrategy'
import { ValidationErrorType } from '../ValidationErrorType'

export class SellStopAtPriceValidation extends PriceValidationStrategy {
    private marketValues: MarketValues

    constructor(marketValues: MarketValues) {
        super()
        this.marketValues = marketValues
    }

    public isValid(value: string): boolean {
        let result = false

        if (super.isValid(value)) {
            const valueNum = parseFloat(value)

            if (valueNum >= this.marketValues.bid) {
                const errorText = 'atPriceSellStopShouldBeLowerThanBid'
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText
                }
            } else if (
                valueNum >
                this.marketValues.bid - this.marketValues.minimumDistanceToPrice * this.marketValues.minimumPriceChange
            ) {
                const errorText = `atPriceSellStopShouldBeLowerThanBidBy`
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText,
                    errorValue: this.marketValues.getMinimumDistanceToPriceStr()
                }
            } else {
                result = true
            }
        }

        return result
    }
}
