import { FC, useCallback, useMemo } from 'react'

import { Flex, Typography, Checkbox } from '@fto/ui'

import { TBufferStyle } from '@fto/lib/drawing_interface/VCLCanvas/TBufferStyle'
import LineStylePicker from '@root/pages/ChartPage/components/lineStylePicker'
import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

import { prepareBuffer } from './utils'

type BufferStyle = {
    color: TColor
    style: TPenStyle
    width: number
}

interface BufferStylePickerProps {
    data: { [key: string]: TBufferStyle }
    attribute: string
    name: string
    optionKey?: string | number
    setIndicatorOptions: (value: any, shouldTemplateReset: boolean) => void
}

const BufferStylePicker: FC<BufferStylePickerProps> = ({ data, attribute, name, optionKey, setIndicatorOptions }) => {
    const onSomeValueChanged = useCallback(
        (values: { [key: string]: any }) => {
            if (optionKey) {
                setIndicatorOptions(
                    (prevData: any) => ({
                        ...prevData,
                        [optionKey]: {
                            ...prevData[optionKey],
                            fvalue: prepareBuffer(prevData[optionKey].fvalue, values)
                        }
                    }),
                    true
                )
                return
            }

            setIndicatorOptions((prevData: any) => {
                return {
                    ...prevData,
                    ['fValue']: prepareBuffer(prevData.fvalue, values)
                }
            }, true)
        },
        [optionKey, setIndicatorOptions]
    )

    const setNewStyleValue = useCallback(
        (value: BufferStyle) => {
            onSomeValueChanged({ color: value.color, style: value.style, width: value.width })
        },
        [onSomeValueChanged]
    )

    const setIsVisibleValue = useCallback(
        (value: boolean) => {
            onSomeValueChanged({ isVisible: value })
        },
        [onSomeValueChanged]
    )

    const lineStylesData = useMemo(() => {
        return {
            [attribute]: {
                width: data[attribute].width,
                style: data[attribute].style,
                color: data[attribute].color
            }
        }
    }, [attribute, data])

    return (
        <Flex alignItems='center' justifyContent='space-between' gap={8}>
            <Flex gap={8} alignItems='center'>
                <Checkbox type='checkbox' checked={data[attribute].isVisible} onChange={setIsVisibleValue} />
                <Typography type='interface-regular' color='gray-1000'>
                    {name}
                </Typography>
            </Flex>
            <LineStylePicker data={lineStylesData} attribute={attribute} onChange={setNewStyleValue} />
        </Flex>
    )
}

export default BufferStylePicker
