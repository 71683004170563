import { useState, useEffect, useCallback, useMemo } from 'react'
import { FillColorParamsType } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'

const initialFillParams = {
    color: {
        value: '',
        hasDifferentValues: false
    },
    opacity: 1,
    shouldFillInside: false
}

type Params = {
    tools: any[]
    setParamsFunctionKey: string
    getParamsFunctionKey: string
}
const useFillColor = (params: Params) => {
    const { tools, setParamsFunctionKey, getParamsFunctionKey } = params

    const [fillColorParams, setFillParams] = useState<FillColorParamsType>(initialFillParams)

    const [singleTool] = tools

    const isMultiSelection = useMemo(() => tools.length > 1, [tools])

    useEffect(() => {
        if (tools.length > 0) {
            const toolParams = singleTool[getParamsFunctionKey]()

            setFillParams(
                isMultiSelection
                    ? {
                          ...initialFillParams,
                          opacity: toolParams.opacity
                      }
                    : toolParams
            )
        }
    }, [tools, isMultiSelection])

    const isMultiColoring = useMemo(() => {
        const hasCalculatedDifferentValues = tools.some((tool) => {
            const fillStyles = tool[getParamsFunctionKey]()

            return fillStyles.color.hasDifferentValues
        })

        const allToolsColors = new Set(
            tools.map((tool) => {
                const fillStyles = tool[getParamsFunctionKey]()

                return fillStyles.color.value
            })
        )

        return hasCalculatedDifferentValues || (tools.length > 1 && Array.from(allToolsColors).length > 1)
        // NOTE: fillColorParams added to dependency to trigger update such as tools list not trigger update if tools not changed
    }, [tools, fillColorParams])

    const color = useMemo(() => {
        if (tools.length > 1 && !isMultiColoring) {
            return singleTool[getParamsFunctionKey]().color.value
        }

        return fillColorParams.color.value
    }, [tools, fillColorParams.color, isMultiColoring])

    const opacity = useMemo(() => {
        if (tools.length > 1 && !isMultiColoring) {
            return singleTool[getParamsFunctionKey]().opacity
        }

        return fillColorParams.opacity
    }, [tools, fillColorParams.opacity, isMultiColoring])

    const handleColorChange = useCallback(
        (color: string) => {
            tools.forEach((tool) => {
                const fillStyles = tool[getParamsFunctionKey]()

                if (fillStyles.shouldFillInside) {
                    tool[setParamsFunctionKey](color, fillStyles.opacity)
                }
            })

            setFillParams((prevState) => ({
                ...prevState,
                color: {
                    ...prevState.color,
                    value: color
                }
            }))
        },
        [tools]
    )

    const handleOpacityChange = useCallback(
        (opacity: number) => {
            tools.forEach((tool) => {
                const fillStyles = tool[getParamsFunctionKey]()

                if (fillStyles.shouldFillInside && fillStyles.opacity !== null) {
                    tool[setParamsFunctionKey](fillStyles.color.value, opacity)
                }
            })

            setFillParams((prevState) => ({
                ...prevState,
                opacity
            }))
        },
        [tools]
    )

    const readyToChange = useMemo(() => {
        const thereAreAllowed = tools.find((tool) => tool[getParamsFunctionKey]?.().shouldFillInside)

        return !!thereAreAllowed
    }, [tools])

    const withOpacity = useMemo(() => {
        return tools.some((tool) => {
            const fillStyles = tool[getParamsFunctionKey]()
            return fillStyles.opacity !== null
        })
    }, [tools])

    return { color, opacity, handleColorChange, handleOpacityChange, readyToChange, isMultiColoring, withOpacity }
}

export default useFillColor
