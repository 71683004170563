import { FC, ReactNode } from 'react'
import cx from 'classnames'

import { Flex, Typography } from '..'

import styles from './index.module.scss'

type EmptyStateProps = {
    children?: ReactNode
    classNames?: {
        root?: string
    }
    title?: string
    description?: string
}

export const EmptyState: FC<EmptyStateProps> = ({ children, description, title, classNames = {} }) => (
    <Flex
        direction='column'
        gap={24}
        justifyContent='center'
        alignItems='center'
        className={cx(styles.EmptyState, classNames.root)}
    >
        {(title || description) && (
            <Flex direction='column' gap={12}>
                {title && (
                    <Typography block align='center' type='interface-semibold' color='gray-1000'>
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography block align='center' type='interface-regular' color='gray-800'>
                        {description}
                    </Typography>
                )}
            </Flex>
        )}
        {children}
    </Flex>
)
