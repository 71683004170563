import { FC, useCallback } from 'react'

import { Checkbox, Flex, Tooltip, Typography } from '@fto/ui'

import { IconButton } from '@fto/icons'

import { StoplossState, TakeprofitState } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import CreateOrderStore from '@fto/lib/store/createOrder'
import { orderModelType } from '@fto/lib/store/createOrder/types'

import InputCreator from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/InputCreator'

import styles from './index.module.scss'
import TextWithHint from '../TextWithHint'
import { useTranslation } from 'react-i18next'

type Props = {
    isStopLossActive: boolean
    isTakeProfitActive: boolean
    stoplossValues: orderModelType['stoplossValues']
    takeProfitValues: orderModelType['takeProfitValues']
    riskRewardRatio: orderModelType['m_riskRewardRatio']
    pseudoFocuses: orderModelType['pseudoFocuses']
    minimumPriceChange: orderModelType['minimumPriceChange']
    handleModalClose: (isPPricePick?: boolean) => void
}
const LossProfitCalculation: FC<Props> = ({
    isStopLossActive,
    isTakeProfitActive,
    stoplossValues,
    takeProfitValues,
    riskRewardRatio,
    pseudoFocuses,
    minimumPriceChange,
    handleModalClose
}) => {
    const { t } = useTranslation()

    const { onChange, onInputFocus, onInputBlur, onPricePick } = CreateOrderStore

    const handleStopLossToggle = useCallback(() => {
        onChange(isStopLossActive ? StoplossState.DISABLED : StoplossState.ENABLED, 'm_stoplossState')
    }, [isStopLossActive, onChange, isStopLossActive])

    const handleTakeProfitToggle = useCallback(() => {
        onChange(isTakeProfitActive ? TakeprofitState.DISABLED : TakeprofitState.ENABLED, 'm_takeprofitState')
    }, [isTakeProfitActive, onChange, isTakeProfitActive])

    return (
        <div className={styles.wrapper}>
            <Flex direction='column' gap={4}>
                <Flex onClick={handleStopLossToggle} className={styles.toggle} gap={8} alignItems='center'>
                    <Checkbox checked={isStopLossActive} />
                    <TextWithHint
                        label='orders.modal.profitLoss.loss.label'
                        tooltipText='orders.modal.tooltips.stopLossHint'
                        type='interface-regular'
                        isHintAfterText={true}
                        gap={24}
                        classNames={{ text: styles.hint }}
                    ></TextWithHint>
                </Flex>
                <Tooltip content={t('orders.modal.tooltips.stopLoss')} placement='left' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${stoplossValues.price.error.key}`, {
                            value: stoplossValues.price.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['stoplossValues']['price']}
                        hasPseudoError={!!stoplossValues.price.error.key}
                        className={styles.inputField}
                        step={minimumPriceChange}
                        value={stoplossValues.price.value}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'price', 'stoplossValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'price', 'stoplossValues')
                        }}
                        type='number'
                        disabled={!isStopLossActive}
                        onChange={(value) => {
                            onChange(Number(value), 'price', 'stoplossValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.pointsInput')} placement='left' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${stoplossValues.points.error.key}`, {
                            value: stoplossValues.points.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['stoplossValues']['points']}
                        hasPseudoError={!!stoplossValues.points.error.key}
                        className={styles.inputField}
                        value={stoplossValues.points.value}
                        error={!!stoplossValues.points.error.key}
                        step={1}
                        type='number'
                        disabled={!isStopLossActive}
                        onChange={(value) => {
                            onChange(Number(value), 'points', 'stoplossValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'points', 'stoplossValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'points', 'stoplossValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.currencyInput')} placement='left' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${stoplossValues.usd.error.key}`, {
                            value: stoplossValues.usd.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['stoplossValues']['usd']}
                        hasPseudoError={!!stoplossValues.usd.error.key}
                        className={styles.inputField}
                        step={1}
                        value={stoplossValues.usd.value}
                        type='number'
                        disabled={!isStopLossActive}
                        onChange={(value) => {
                            onChange(Number(value), 'usd', 'stoplossValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'usd', 'stoplossValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'usd', 'stoplossValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.percentInput')} placement='left' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${stoplossValues.percent.error.key}`, {
                            value: stoplossValues.percent.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['stoplossValues']['percent']}
                        hasPseudoError={!!stoplossValues.percent.error.key}
                        className={styles.inputField}
                        step={0.01}
                        value={stoplossValues.percent.value}
                        type='number'
                        disabled={!isStopLossActive}
                        onChange={(value) => {
                            onChange(Number(value), 'percent', 'stoplossValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'percent', 'stoplossValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'percent', 'stoplossValues')
                        }}
                    />
                </Tooltip>
            </Flex>

            <Flex direction='column' gap={4} alignItems='center' justifyContent='flex-end'>
                {isTakeProfitActive && isStopLossActive && !!riskRewardRatio && (
                    <Flex alignItems='center' justifyContent='center' className={styles.info}>
                        <Tooltip content={t('orders.modal.tooltips.riskRewardRatio')} placement='top'>
                            <Typography type='interface-regular' color='gray-900' block>
                                {riskRewardRatio}
                            </Typography>
                        </Tooltip>
                    </Flex>
                )}
                <Flex gap={4} alignItems='center' justifyContent='space-between' className={styles.info} block>
                    <Tooltip content={t('orders.modal.tooltips.stopLossPipette')} placement='top' arrow={false}>
                        <IconButton
                            name='picker'
                            size={18}
                            color='var(--color-gray-900)'
                            onClick={() => {
                                onPricePick('stoplossPricePick')
                                handleModalClose(true)
                            }}
                        />
                    </Tooltip>
                    <TextWithHint
                        type='interface-regular'
                        label='orders.modal.profitLoss.infoPanel.price'
                        tooltipText='orders.modal.tooltips.price'
                        classNames={{ container: styles.info }}
                    />
                    <Tooltip content={t('orders.modal.tooltips.takeProfitPipette')} placement='top' arrow={false}>
                        <IconButton
                            name='picker'
                            size={18}
                            color='var(--color-gray-900)'
                            onClick={() => {
                                onPricePick('takeProfitPricePick')
                                handleModalClose(true)
                            }}
                        />
                    </Tooltip>
                </Flex>
                <TextWithHint
                    type='interface-regular'
                    label='orders.modal.profitLoss.infoPanel.points'
                    tooltipText='orders.modal.tooltips.points'
                    classNames={{ container: styles.info }}
                />
                <TextWithHint
                    type='interface-regular'
                    label='orders.modal.profitLoss.infoPanel.currency'
                    tooltipText='orders.modal.tooltips.currency'
                    classNames={{ container: styles.info }}
                />
                <TextWithHint
                    type='interface-regular'
                    label='orders.modal.profitLoss.infoPanel.percent'
                    tooltipText='orders.modal.tooltips.percent'
                    classNames={{ container: styles.info }}
                />
            </Flex>

            <Flex direction='column' gap={4}>
                <Flex onClick={handleTakeProfitToggle} className={styles.toggle} gap={8} alignItems='center'>
                    <Checkbox checked={isTakeProfitActive} />
                    <TextWithHint
                        label='orders.modal.profitLoss.profit.label'
                        tooltipText='orders.modal.tooltips.takeProfitHint'
                        type='interface-regular'
                        isHintAfterText={true}
                        gap={20}
                        classNames={{ text: styles.hint }}
                    ></TextWithHint>
                </Flex>
                <Tooltip content={t('orders.modal.tooltips.takeProfit')} placement='right' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${takeProfitValues.price.error.key}`, {
                            value: takeProfitValues.price.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['takeProfitValues']['price']}
                        hasPseudoError={!!takeProfitValues.price.error.key}
                        className={styles.inputField}
                        step={minimumPriceChange}
                        value={takeProfitValues.price.value}
                        type='number'
                        disabled={!isTakeProfitActive}
                        onChange={(value) => {
                            onChange(Number(value), 'price', 'takeProfitValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'price', 'takeProfitValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'price', 'takeProfitValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.pointsInput')} placement='right' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${takeProfitValues.points.error.key}`, {
                            value: takeProfitValues.points.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['takeProfitValues']['points']}
                        hasPseudoError={!!takeProfitValues.points.error.key}
                        className={styles.inputField}
                        step={1}
                        value={takeProfitValues.points.value}
                        type='number'
                        disabled={!isTakeProfitActive}
                        onChange={(value) => {
                            onChange(Number(value), 'points', 'takeProfitValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'points', 'takeProfitValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'points', 'takeProfitValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.currencyInput')} placement='right' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${takeProfitValues.usd.error.key}`, {
                            value: takeProfitValues.usd.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['takeProfitValues']['usd']}
                        hasPseudoError={!!takeProfitValues.usd.error.key}
                        className={styles.inputField}
                        step={1}
                        value={takeProfitValues.usd.value}
                        type='number'
                        disabled={!isTakeProfitActive}
                        onChange={(value) => {
                            onChange(Number(value), 'usd', 'takeProfitValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'usd', 'takeProfitValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'usd', 'takeProfitValues')
                        }}
                    />
                </Tooltip>
                <Tooltip content={t('orders.modal.tooltips.percentInput')} placement='right' arrow={false}>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${takeProfitValues.percent.error.key}`, {
                            value: takeProfitValues.percent.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['takeProfitValues']['percent']}
                        hasPseudoError={!!takeProfitValues.percent.error.key}
                        className={styles.inputField}
                        step={0.01}
                        value={takeProfitValues.percent.value}
                        type='number'
                        disabled={!isTakeProfitActive}
                        onChange={(value) => {
                            onChange(Number(value), 'percent', 'takeProfitValues')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'percent', 'takeProfitValues')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'percent', 'takeProfitValues')
                        }}
                    />
                </Tooltip>
            </Flex>
        </div>
    )
}

export default LossProfitCalculation
