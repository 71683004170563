import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TLineStyleRec } from '@fto/lib/drawing_interface/GraphicObjects/TLineStyleRec'
import { TIndexPos } from '@fto/lib/drawing_interface/GraphicObjects/TIndexPos'
import { TIndexBufferProgramSide } from './IndexBuffers'
import { IVisibleIndexBuffer } from './api/ITVisibleIndexBuffer'
import { SelectionMarkers } from './SelectionMarkers'

export class TVisibleIndexBuffer implements IVisibleIndexBuffer {
    private _buffer: TIndexBufferProgramSide | null = null
    public get buffer(): TIndexBufferProgramSide {
        if (!this._buffer) {
            throw new StrangeError('Buffer is not assigned.')
        }
        return this._buffer
    }
    public set buffer(value: TIndexBufferProgramSide | null) {
        this._buffer = value
    }

    public style: TLineStyleRec
    private _name: string | null = null
    public get name(): string {
        if (!this._name) {
            throw new StrangeError('Name is not assigned.')
        }
        return this._name
    }
    public set name(value: string) {
        this._name = value
    }

    private _paintFrom: number | null = null
    public get PaintFrom(): number {
        if (this._paintFrom === null || this._paintFrom === undefined) {
            throw new StrangeError('PaintFrom is not assigned.')
        }
        return this._paintFrom
    }
    public set PaintFrom(value: number) {
        this._paintFrom = value
    }

    public selectionMarkers: SelectionMarkers
    private isSelected = false
    private isVisible = true

    constructor() {
        this.style = new TLineStyleRec()
        this.selectionMarkers = new SelectionMarkers(null, [])
    }

    public CheckIndex(index: number): TIndexPos {
        //TODO: refactor this with property checks
        if (this.buffer.shift) {
            index -= this.buffer.shift
        }

        if (!this.buffer.HasSomeValues()) {
            return TIndexPos.ip_Invalid
        }

        if (index < 0) {
            return TIndexPos.ip_InvBefore
        }

        if (index > this.buffer.LastItemInTestingIndex) {
            return TIndexPos.ip_InvAfter
        }

        return TIndexPos.ip_Valid
    }

    public GetValue(index: number): number {
        return this.buffer.GetValue(index - this.buffer.shift)
    }

    public show(): void {
        this.isVisible = true
    }

    public hide(): void {
        this.isVisible = false
    }

    public IsVisible(): boolean {
        return this.isVisible
    }

    public get EmptyValue(): number {
        return this.buffer.EmptyValue
    }

    public get Count(): number {
        if (!this._buffer) {
            return 0
        }

        return this.buffer.LastItemInTestingIndex
    }

    public onIndicatorSelected(): void {
        this.isSelected = true
    }

    public onIndicatorUnselected(): void {
        this.isSelected = false
    }

    public IsSelected(): boolean {
        return this.isSelected
    }
}
