import { FC, useEffect, useMemo } from 'react'

import Header from './components/Header'
import Table from './components/Table'
import { NEWS_FILTERS } from '@root/constants/localStorageKeys'
import NewsStore from '@fto/lib/store/news'

import { usePersistedState } from '@root/hooks/usePersistedState'
import { ParamsType } from '@fto/lib/store/news/types'

import styles from './index.module.scss'

type Props = {
    userId: string
}
const Filter: FC<Props> = ({ userId }) => {
    const localStorageKey = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')

        return `${userId}_${projectId}_${NEWS_FILTERS}`
    }, [userId])

    const [filters, setFilters] = usePersistedState<ParamsType['filters']>(localStorageKey, { ...NewsStore.allFilters })

    const headerFilters = useMemo(() => {
        return {
            interval: filters?.interval,
            priority: filters?.priority
        }
    }, [filters])

    useEffect(() => {
        NewsStore.setFilters(filters)
    }, [filters])

    return (
        <div className={styles.Filter}>
            <Header filters={headerFilters} setFilters={setFilters} />
            <Table selectedCountries={filters.selectedCountries} setFilters={setFilters} />
        </div>
    )
}

export default Filter
