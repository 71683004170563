import { FC, ReactNode } from 'react'

import cx from 'classnames'

import styles from '../index.module.scss'

export type FooterProps = {
    className?: string
    children?: ReactNode
}

const Footer: FC<FooterProps> = ({ children, className }) => (
    <div className={cx(styles.footer, className)}>{children}</div>
)

Footer.displayName = 'Modal.Footer'

export default Footer
