import { FC, useEffect, useRef, useMemo } from 'react'
import cx from 'classnames'

import useDraggablePanel from '@root/hooks/useDraggablePanel'

import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'
import { Flex, TriggerOverlay, Tooltip } from '@fto/ui'
import { Icon } from '@fto/icons'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'

import Actions from './components/Actions'
import Locker from './components/Locker'
import LineStyles from './components/LineStyles'
import FillColorBlock from './components/FillColorBlock'
import HoverElement from './components/HoverElement'
import TextBlockWrapper from './components/TextBlockWrapper'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    tools: any[]
    initialCoords: { x: number; y: number }
}

const GraphToolPanel: FC<Props> = ({ tools, initialCoords }) => {
    const { t } = useTranslation()

    const ref = useRef(null)

    const { onMouseDown, onMouseMove, onMouseUp, position, isDragging } = useDraggablePanel(ref, initialCoords)

    useEffect(() => {
        GraphToolPanelStore.updateParams((prevSettings) => ({
            ...prevSettings,
            initialCoords: position
        }))
    }, [position])

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mouseup', onMouseUp)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
                window.removeEventListener('mouseup', onMouseUp)
            }
        }
    }, [isDragging, onMouseMove, onMouseUp])

    const isMultiSelection = useMemo(() => {
        return tools.length > 1
    }, [tools])

    const activeToolNameParams = useMemo(() => {
        return {
            key: tools[0]?.name || '',
            count: tools.length
        }
    }, [tools])

    return (
        <Flex className={cx(SELECTORS.TESTING_PANEL.className, styles.wrapper)}>
            <Flex
                className={styles.panel}
                ref={ref}
                style={{
                    left: position.x,
                    top: position.y
                }}
                gap={4}
                alignItems='center'
            >
                <div className={styles.drag} onMouseDown={onMouseDown}>
                    <Icon size={12} name='drag-n-drop' />
                </div>

                <Flex gap={4} alignItems='center'>
                    <LineStyles tools={tools}>
                        <FillColorBlock tools={tools} />
                        <TextBlockWrapper tools={tools} />
                    </LineStyles>

                    <Flex alignItems='center' gap={8}>
                        {!isMultiSelection && (
                            <Tooltip content={t(`graphToolPanel.tooltips.settings`)} placement='top' color='dark'>
                                <HoverElement onClick={() => tools[0].ExportToDialog()}>
                                    <Icon color='var(--color-gray-900)' name='settings-lined' size={18} />
                                </HoverElement>
                            </Tooltip>
                        )}
                        <Locker tools={tools} />
                        <Tooltip content={t(`graphToolPanel.tooltips.remove`)} placement='top' color='dark'>
                            <HoverElement
                                onClick={() => {
                                    GlobalChartsController.Instance.getActiveChart()?.deleteSelectedPaintTools()
                                }}
                            >
                                <Icon color='var(--color-gray-900)' name='delete' size={18} />
                            </HoverElement>
                        </Tooltip>
                    </Flex>

                    <div className={styles.separator} />

                    <TriggerOverlay
                        optionsRenderer={(onClose) => (
                            <Actions onClose={onClose} activeToolNameParams={activeToolNameParams} />
                        )}
                        disableBackdropChartActivity
                    >
                        <Tooltip content={t(`graphToolPanel.tooltips.more`)} placement='top' color='dark'>
                            <HoverElement>
                                <Icon color='var(--color-gray-900)' name='ellipsis-vertical' size={18} />
                            </HoverElement>
                        </Tooltip>
                    </TriggerOverlay>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default GraphToolPanel
