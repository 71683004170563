import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TMockDownloadBehavior } from './MockDataDownloadEnums'
import MockDownloadTask from './MockDownloadTask'
import { TDataArrayEvents } from '@fto/lib/ft_types/data/data_downloading/DownloadRelatedEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDataRecordWithDate } from '@fto/lib/ft_types/data/DataClasses/TDataRecordWithDate'

export default class MockDownloadTaskQueue {
    private _mockDownloadBehavior: TMockDownloadBehavior = TMockDownloadBehavior.db_downloadImmediately
    private _timerFrequency_ms = 1
    private _downloadDelay_ms = 1
    private _tasks: MockDownloadTask[] = []
    private _timer: NodeJS.Timeout | null = null
    private _lastExecutionTime = 0

    public set MockDownloadBehavior(value: TMockDownloadBehavior) {
        this._mockDownloadBehavior = value
    }

    public set DownloadDelay(value: number) {
        this._downloadDelay_ms = value
    }

    public createTask(chunk: TDownloadableChunk<TDataRecordWithDate>): MockDownloadTask {
        const task = new MockDownloadTask(chunk)

        switch (this._mockDownloadBehavior) {
            case TMockDownloadBehavior.db_downloadImmediately: {
                //no need to add to queue
                task.executeDataImport()
                break
            }
            case TMockDownloadBehavior.db_downloadInRandomOrder:
            case TMockDownloadBehavior.db_downloadInOrder: {
                this.addTaskToQueue(task)
                this.makeSureTheTimerIsActivated()
                break
            }
            case TMockDownloadBehavior.db_downloadByManualCommand: {
                //no need to add to queue, we have to wait when someone calls the executeDataImport method for the task manually
                break
            }
            default: {
                throw new StrangeError('Unknown download behavior')
            }
        }

        return task
    }

    private addTaskToQueue(task: MockDownloadTask) {
        this._tasks.push(task)

        task.Chunk.Events.on(TDataArrayEvents.de_ChunkLoaded, () => {
            this.removeTask(task)
        })
    }

    public removeTask(task: MockDownloadTask) {
        this._tasks = this._tasks.filter((t) => t !== task)
    }

    public makeSureTheTimerIsActivated() {
        if (!this._timer) {
            this._timer = setInterval(() => this.timerTick(), this._timerFrequency_ms)
        }
    }

    private timerTick() {
        if (this._tasks.length === 0) {
            if (this._timer) {
                clearInterval(this._timer)
                this._timer = null
            }
            return
        }

        const currentTime = Date.now()
        if (currentTime - this._lastExecutionTime < this._downloadDelay_ms) {
            return
        }
        this._lastExecutionTime = currentTime

        switch (this._mockDownloadBehavior) {
            case TMockDownloadBehavior.db_downloadInOrder: {
                const task = this._tasks.shift()
                if (task) {
                    task.executeDataImport()
                }
                break
            }

            case TMockDownloadBehavior.db_downloadInRandomOrder: {
                const randomTaskIndex = Math.floor(Math.random() * this._tasks.length)
                const randomTask = this._tasks[randomTaskIndex]
                randomTask.executeDataImport()
                this.removeTask(randomTask)
                break
            }
        }
    }
}
