import { Fragment, useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { Flex, InputField, Modal, Typography } from '@fto/ui'
import useEmailField from '@root/hooks/useEmailField'
import { changeEmail } from '@root/utils/api'
import MessageInformer from '@root/components/MessageInformer'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getCode } from '@root/store/auth/selectors'
import { LOGOUT } from '@root/store/auth/auth.slice'

import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'

import { showSuccessToast } from '@root/utils/toasts'

import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'

type EnterEmailContentProps = {
    onClose: () => void
    code: string
}

export const EnterEmailContent: FC<EnterEmailContentProps> = ({ onClose, code }) => {
    const { t } = useTranslation()
    const [error, setError] = useState<string | null>(null)
    const {
        email,
        handleEmailChange,
        validationData: emailValidationData,
        validate
    } = useEmailField({ initialValue: '' })
    const confirmationCode = useAppSelector($getCode)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const changeEmailHandler = async () => {
        const isValid = await validate(email)
        if (!isValid) return

        changeEmail({ email: email, code: code || (confirmationCode as string) })
            .then(() => {
                onClose()
                dispatch(LOGOUT())
                navigate(makeRoute(LOGIN_ROUTE))
                showSuccessToast({
                    title: t('profile.toasts.changeEmail.emailChangedSuccessfully.title'),
                    message: t('profile.toasts.changeEmail.emailChangedSuccessfully.message')
                })
            })
            .catch((error) => {
                setError(errorHandlingForEmail(error))
            })
    }

    return (
        <Fragment>
            <Modal.Content className={styles.line}>
                <Flex direction='column' gap={24}>
                    <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                        {t('profile.modals.enterNewEmailText')}
                    </Typography>
                    <Flex direction='column' gap={8}>
                        <InputField
                            id='email'
                            helperText={emailValidationData.errorMessage}
                            error={!emailValidationData.isValid}
                            block
                            type={'email'}
                            value={email}
                            onChange={handleEmailChange}
                            label={t('profile.modals.newEmail')}
                            placeholder={t('auth.email')}
                        />
                        <MessageInformer type='error' isShown={error !== null}>
                            <Typography block type='interface-regular' align='center' color='red-500'>
                                {error}
                            </Typography>
                        </MessageInformer>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                submitText={t('profile.changeEmail')}
                cancelText={t('global.cancel')}
                disabled={!emailValidationData.isValid || email === ''}
                onClose={onClose}
                onSubmit={changeEmailHandler}
            />
        </Fragment>
    )
}

const errorHandlingForEmail = (response: { status: string; data: { status: string } }) => {
    if (response.status === 'code_expired') {
        return t('auth.errors.code.expired')
    }
    if (response.status === 'invalid_code') {
        return t('auth.errors.code.shouldBeValid')
    }
    if (response.status === 'invalid_email') {
        return t('profile.modals.email.shouldBeValid')
    }

    return t('auth.errors.somethingWentWrong')
}
