import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'
import { ToastContentProps } from 'react-toastify'

import cx from 'classnames'

import { Button, Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'

export const toastColors = ['green', 'red', 'orange']

export type OwnToastProps = {
    color: (typeof toastColors)[number]

    title?: string
    message?: string | ReactNode
    actions?: ReactNode
    icon?: ReactNode

    showCloseIcon?: boolean

    // INJECTED PROPS !!!! -> https://fkhadra.github.io/react-toastify/render-what-you-want
    closeToast?: ToastContentProps['closeToast']
}

type OtherNativeDivProps = Omit<ComponentPropsWithoutRef<'span'>, keyof OwnToastProps>

export type ToastProps = OwnToastProps & OtherNativeDivProps

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
    ({ color, title, message, actions, icon, showCloseIcon = true, closeToast }, ref) => {
        return (
            <div ref={ref} className={cx(styles.root, styles[`variant-${color}`])}>
                <Flex flexShrink={0}>{icon}</Flex>

                <Flex direction='column' flexGrow={1} gap={4}>
                    {title && (
                        <Typography type='h5-semibold' color='gray-1000'>
                            {title}
                        </Typography>
                    )}
                    {message && (
                        <Typography type='interface-regular' color='gray-1000'>
                            {message}
                        </Typography>
                    )}
                    {actions && <>{actions}</>}
                </Flex>
                {showCloseIcon && (
                    <Flex flexShrink={0} onClick={closeToast}>
                        <Button
                            type='tetriatry-white'
                            size='compact'
                            disableFixedSize
                            label={
                                <Icon name='close' color={'var(--color-gray-1000)'} onClick={closeToast} size={16} />
                            }
                        />
                    </Flex>
                )}
            </div>
        )
    }
)

Toast.displayName = 'Toast'
