export class TPoint {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    // Static method to create a TPoint from another point or similar object
    static from(point: { x: number; y: number }): TPoint {
        return new TPoint(point.x, point.y)
    }

    // Method to set the x and y coordinates
    SetPoint(x: number, y: number): void {
        this.x = x
        this.y = y
    }

    SetLocation(x: number, y: number): void {
        this.SetPoint(x, y)
    }

    // Method to check if two points are equal
    Equals(other: TPoint): boolean {
        return this.x === other.x && this.y === other.y
    }

    // Method to create a string representation of the point
    toString(): string {
        return `(${this.x}, ${this.y})`
    }

    // Additional methods can be added as needed, based on specific requirements
}

export class TRect {
    Left: number
    Top: number
    Right: number
    Bottom: number

    constructor(left: number, top: number, right: number, bottom: number) {
        this.Left = left
        this.Top = top
        this.Right = right
        this.Bottom = bottom
    }

    get Width(): number {
        return Math.abs(this.Right - this.Left)
    }

    get Height(): number {
        return Math.abs(this.Bottom - this.Top)
    }

    IsValidForDrawing(): boolean {
        return this.Width > 0 && this.Height > 0
    }

    // Method to set the rectangle's coordinates
    DoSetRect(left: number, top: number, right: number, bottom: number): void {
        this.Left = left
        this.Top = top
        this.Right = right
        this.Bottom = bottom
    }

    // Method to inflate (or deflate) the rectangle
    InflateRect(left: number, top: number, right: number, bottom: number): void {
        this.Left -= left
        this.Top -= top
        this.Right += right
        this.Bottom += bottom
    }

    PtInRect(point: TPoint): boolean {
        return point.x >= this.Left && point.x <= this.Right && point.y >= this.Top && point.y <= this.Bottom
    }

    // Method to check if a point is within the rectangle
    PtInRect_numbers(x: number, y: number): boolean {
        return x >= this.Left && x <= this.Right && y >= this.Top && y <= this.Bottom
    }

    // Method to check intersection of two rectangles
    Intersect(rect: TRect): TRect {
        const x1 = Math.max(this.Left, rect.Left)
        const y1 = Math.max(this.Top, rect.Top)
        const x2 = Math.min(this.Right, rect.Right)
        const y2 = Math.min(this.Bottom, rect.Bottom)

        if (x2 >= x1 && y2 >= y1) {
            return new TRect(x1, y1, x2, y2)
        }
        return new TRect(0, 0, 0, 0) // No intersection
    }

    // Method to calculate the union of two rectangles
    Union(rect: TRect): TRect {
        const x1 = Math.min(this.Left, rect.Left)
        const y1 = Math.min(this.Top, rect.Top)
        const x2 = Math.max(this.Right, rect.Right)
        const y2 = Math.max(this.Bottom, rect.Bottom)
        return new TRect(x1, y1, x2, y2)
    }

    // Method to check if the rectangle is empty
    IsEmpty(): boolean {
        return this.Width <= 0 || this.Height <= 0
    }

    // Method to check if two rectangles are equal
    Equals(rect: TRect): boolean {
        return (
            this.Left === rect.Left && this.Top === rect.Top && this.Right === rect.Right && this.Bottom === rect.Bottom
        )
    }

    // Method to check if this rectangle contains another rectangle
    Contains(rect: TRect): boolean {
        return this.Left <= rect.Left && this.Top <= rect.Top && this.Right >= rect.Right && this.Bottom >= rect.Bottom
    }

    public static SetRect(left: number, top: number, right: number, bottom: number): TRect {
        return new TRect(left, top, right, bottom)
    }

    // Method to inflate (or deflate) the rectangle
    Inflate(dx: number, dy: number): void {
        this.Left -= dx
        this.Top -= dy
        this.Right += dx
        this.Bottom += dy
    }

    Copy(): TRect {
        return new TRect(this.Left, this.Top, this.Right, this.Bottom)
    }
}

export enum TPenStyle {
    psSolid = 0,
    psDash = 1,
    psDot = 2,
    psDashDot = 3,
    psDashDotDot = 4,
    psClear = 5,
    psInsideFrame = 6,
    psUserStyle = 7,
    psAlternate = 8
}
export enum TBrushStyle {
    bsSolid,
    bsClear,
    bsHorizontal,
    bsVertical,
    bsFDiagonal,
    bsBDiagonal,
    bsCross,
    bsDiagCross
    // Add any other styles as needed
}
export function GetPenStyle(value: number): TPenStyle {
    if (value in TPenStyle) {
        return value as TPenStyle
    }
    throw new Error('Invalid value for TPenStyle')
}
export function GetBrushStyleByNumber(value: number): TBrushStyle {
    if (TBrushStyle[value] === undefined) {
        throw new Error('Invalid value for TBrushStyle')
    }
    return value as TBrushStyle
}
export class TChartInfo {
    public FirstIndex!: number // index of left bar on the chart
    public LastIndex!: number // index or right bar on the chart
    public PaintRect!: TRect // paint rect
    public BarWidth!: number // width of bar in pixels
    public currZoom!: number // current zoom
}

export enum TDrawStyle {
    ds_Line,
    ds_Histogram,
    ds_Fill,
    ds_Symbol,
    ds_Section,
    ds_HistogramFill,
    ds_ColoredCandles,
    ds_None
}
export enum TTradePositionType {
    tp_Buy,
    tp_Sell,
    tp_BuyLimit,
    tp_SellLimit,
    tp_BuyStop,
    tp_SellStop,
    tp_Deposit,
    tp_Withdrawal,
    tp_Credit,
    tp_Cancelled
}
