import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TCriteriaOperator, TCriteriaType } from '@fto/lib/Education/Types'
import { TutorialCheckBase } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TutorialCheckBase'

export class StatisticsChecks extends TutorialCheckBase {
    public statisticField: TCriteriaType

    constructor(
        criteriaOperator: TCriteriaOperator,
        value: number | { valueStart: number; valueEnd: number },
        statisticField: TCriteriaType
    ) {
        super(criteriaOperator, value)
        this.statisticField = statisticField
    }

    checkEqual(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance === (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity === (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin === (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin === (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed === (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss === (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit === (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss === (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit === (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability === (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades === (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently === (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth === (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown === (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade === (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed === (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade === (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed === (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit === (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor === (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability === (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades === (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently === (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth === (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth === (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor === (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor === (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents === (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades === (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay === (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth === (this.value as number)
            default:
                return false
        }
    }

    checkLess(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance < (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity < (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin < (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin < (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed < (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss < (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit < (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss < (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit < (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability < (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades < (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently < (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth < (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown < (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade < (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed < (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade < (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed < (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit < (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor < (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability < (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades < (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently < (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth < (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth < (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor < (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor < (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents < (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades < (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay < (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth < (this.value as number)
            default:
                return false
        }
    }

    checkMore(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance > (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity > (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin > (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin > (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed > (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss > (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit > (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss > (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit > (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability > (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades > (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently > (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth > (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown > (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade > (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed > (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade > (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed > (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit > (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor > (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability > (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades > (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently > (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth > (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth > (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor > (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor > (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents > (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades > (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay > (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth > (this.value as number)
            default:
                return false
        }
    }

    checkLessOrEqual(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance <= (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity <= (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin <= (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin <= (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed <= (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss <= (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit <= (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss <= (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit <= (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability <= (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades <= (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently <= (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth <= (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown <= (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade <= (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed <= (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade <= (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed <= (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit <= (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor <= (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability <= (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades <= (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently <= (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth <= (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth <= (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor <= (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor <= (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents <= (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades <= (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay <= (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth <= (this.value as number)
            default:
                return false
        }
    }

    checkNotEqual(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance !== (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity !== (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin !== (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin !== (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed !== (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss !== (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit !== (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss !== (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit !== (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability !== (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades !== (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently !== (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth !== (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown !== (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade !== (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed !== (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade !== (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed !== (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit !== (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor !== (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability !== (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades !== (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently !== (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth !== (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth !== (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor !== (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor !== (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents !== (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades !== (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay !== (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth !== (this.value as number)
            default:
                return false
        }
    }

    checkMoreOrEqual(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return statistics.balance >= (this.value as number)
            case TCriteriaType.EQUITY:
                return statistics.equity >= (this.value as number)
            case TCriteriaType.MARGIN:
                return statistics.margin >= (this.value as number)
            case TCriteriaType.FREE_MARGIN:
                return statistics.FreeMargin >= (this.value as number)
            case TCriteriaType.DAY_PROCESSED:
                return statistics.DaysProcessed >= (this.value as number)
            case TCriteriaType.AVERAGE_LOSS:
                return statistics.AverageLoss >= (this.value as number)
            case TCriteriaType.AVERAGE_PROFIT:
                return statistics.AverageProfit >= (this.value as number)
            case TCriteriaType.GROSS_LOSS:
                return statistics.GrossLoss >= (this.value as number)
            case TCriteriaType.GROSS_PROFIT:
                return statistics.GrossProfit >= (this.value as number)
            case TCriteriaType.LOSS_PROBABILITY:
                return statistics.LossProbability >= (this.value as number)
            case TCriteriaType.LOSS_TRADES:
                return statistics.LossTrades >= (this.value as number)
            case TCriteriaType.LOSS_TRADES_CONS:
                return statistics.LossTradesConsequently >= (this.value as number)
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return statistics.LossTradesPerMonth >= (this.value as number)
            case TCriteriaType.MAX_DRAWDOWN:
                return statistics.MaxDrawdown >= (this.value as number)
            case TCriteriaType.MAX_LOSS_TRADE:
                return statistics.MaxLossTrade >= (this.value as number)
            case TCriteriaType.MAX_LOT_USED:
                return statistics.MaxLotUsed >= (this.value as number)
            case TCriteriaType.MAX_PROFIT_TRADE:
                return statistics.MaxProfitTrade >= (this.value as number)
            case TCriteriaType.MONTH_PROCESSED:
                return statistics.MonthsProcessed >= (this.value as number)
            case TCriteriaType.NET_PROFIT:
                return statistics.NetProfit >= (this.value as number)
            case TCriteriaType.PROFIT_FACTOR:
                return statistics.ProfitFactor >= (this.value as number)
            case TCriteriaType.PROFIT_PROBABILITY:
                return statistics.ProfitProbability >= (this.value as number)
            case TCriteriaType.PROFIT_TRADES:
                return statistics.ProfitTrades >= (this.value as number)
            case TCriteriaType.PROFIT_TRADES_CONS:
                return statistics.ProfitTradesConsequently >= (this.value as number)
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return statistics.ProfitTradesPerMonth >= (this.value as number)
            case TCriteriaType.PROFIT_PER_MONTH:
                return statistics.ProfitPerMonth >= (this.value as number)
            case TCriteriaType.RELIABILITY_FACTOR:
                return statistics.ReliabilityFactor >= (this.value as number)
            case TCriteriaType.RESTORATION_FACTOR:
                return statistics.RestorationFactor >= (this.value as number)
            case TCriteriaType.RETURN_PERCENTS:
                return statistics.ReturnPercents >= (this.value as number)
            case TCriteriaType.TOTAL_TRADES:
                return statistics.TotalTrades >= (this.value as number)
            case TCriteriaType.TRADES_PER_DAY:
                return statistics.TradesPerDay >= (this.value as number)
            case TCriteriaType.TRADES_PER_MONTH:
                return statistics.TradesPerMonth >= (this.value as number)
            default:
                return false
        }
    }

    checkRange(): boolean {
        const statistics = GlobalProcessingCore.ProcessingCore.GetStatistics()

        switch (this.statisticField) {
            case TCriteriaType.BALANCE:
                return (
                    statistics.balance >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.balance <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.EQUITY:
                return (
                    statistics.equity >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.equity <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MARGIN:
                return (
                    statistics.margin >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.margin <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.FREE_MARGIN:
                return (
                    statistics.FreeMargin >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.FreeMargin <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.DAY_PROCESSED:
                return (
                    statistics.DaysProcessed >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.DaysProcessed <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.AVERAGE_LOSS:
                return (
                    statistics.AverageLoss >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.AverageLoss <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.AVERAGE_PROFIT:
                return (
                    statistics.AverageProfit >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.AverageProfit <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.GROSS_LOSS:
                return (
                    statistics.GrossLoss >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.GrossLoss <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.GROSS_PROFIT:
                return (
                    statistics.GrossProfit >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.GrossProfit <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.LOSS_PROBABILITY:
                return (
                    statistics.LossProbability >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.LossProbability <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.LOSS_TRADES:
                return (
                    statistics.LossTrades >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.LossTrades <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.LOSS_TRADES_CONS:
                return (
                    statistics.LossTradesConsequently >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.LossTradesConsequently <=
                        (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
                return (
                    statistics.LossTradesPerMonth >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.LossTradesPerMonth <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MAX_DRAWDOWN:
                return (
                    statistics.MaxDrawdown >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.MaxDrawdown <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MAX_LOSS_TRADE:
                return (
                    statistics.MaxLossTrade >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.MaxLossTrade <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MAX_LOT_USED:
                return (
                    statistics.MaxLotUsed >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.MaxLotUsed <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MAX_PROFIT_TRADE:
                return (
                    statistics.MaxProfitTrade >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.MaxProfitTrade <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.MONTH_PROCESSED:
                return (
                    statistics.MonthsProcessed >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.MonthsProcessed <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.NET_PROFIT:
                return (
                    statistics.NetProfit >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.NetProfit <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_FACTOR:
                return (
                    statistics.ProfitFactor >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitFactor <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_PROBABILITY:
                return (
                    statistics.ProfitProbability >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitProbability <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_TRADES:
                return (
                    statistics.ProfitTrades >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitTrades <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_TRADES_CONS:
                return (
                    statistics.ProfitTradesConsequently >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitTradesConsequently <=
                        (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
                return (
                    statistics.ProfitTradesPerMonth >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitTradesPerMonth <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.PROFIT_PER_MONTH:
                return (
                    statistics.ProfitPerMonth >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ProfitPerMonth <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.RELIABILITY_FACTOR:
                return (
                    statistics.ReliabilityFactor >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ReliabilityFactor <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.RESTORATION_FACTOR:
                return (
                    statistics.RestorationFactor >=
                        (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.RestorationFactor <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.RETURN_PERCENTS:
                return (
                    statistics.ReturnPercents >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.ReturnPercents <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.TOTAL_TRADES:
                return (
                    statistics.TotalTrades >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.TotalTrades <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.TRADES_PER_DAY:
                return (
                    statistics.TradesPerDay >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.TradesPerDay <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            case TCriteriaType.TRADES_PER_MONTH:
                return (
                    statistics.TradesPerMonth >= (this.value as { valueStart: number; valueEnd: number }).valueStart &&
                    statistics.TradesPerMonth <= (this.value as { valueStart: number; valueEnd: number }).valueEnd
                )
            default:
                return false
        }
    }

    check(): boolean {
        return super.check()
    }
}
