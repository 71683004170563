import { User } from '@root/types/user'
import { initQueryParams, QueryParams } from '@root/utils/query-params.util'
import {
    ColumnsListType,
    TabType,
    TerminalCellSortingState,
    TerminalSortingCellSettings,
    TerminalTableColumnSettings
} from '@root/pages/ChartPage/components/Terminal/types'
import { TerminalColumnsNames } from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'

/**
 * NOTICE: JSON.parse, JSON.stringify, LocalStorage and SessionStorage may throw an error in following cases
 * -- JSON.parse --
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#exceptions
 *
 * -- JSON.stringify --
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#exceptions
 *
 * -- LocalStorage + SessionStorage --
 * 1) User uses "Private Browsing" in Safari (Chrome and FF works fine)
 * 2) User prevent setting any data in browser's "Privacy Content Settings"
 * 3) User uses < IE8
 *  */
export type ProjectQueryStorage = {
    userId: User['userId']
    query: QueryParams
}

export type UserTerminalSettings = {
    [key in TabType]: {
        settings: TerminalTableColumnSettings
        sorting: TerminalSortingCellSettings
    }
}

export type UserTerminalHeight = number | null

export const PROJECT_QUERY_STORAGE_KEY = 'projectQuery'

export const setLocalStorage = (key: string, obj: any): void => {
    try {
        localStorage.setItem(key, JSON.stringify(obj))
    } catch (e) {
        console.error(e)
    }
}

export const getLocalStorage = <T>(key: string): T | null => {
    try {
        return JSON.parse(localStorage.getItem(key) as any)
    } catch (e) {
        return null
    }
}

export const setSessionStorage = (key: string, obj: any): void => {
    try {
        sessionStorage.setItem(key, JSON.stringify(obj))
    } catch (e) {
        console.error(e)
    }
}

export const getSessionStorage = (key: string): any => {
    try {
        return JSON.parse(sessionStorage.getItem(key) as any)
    } catch (e) {
        console.error(e)
    }
}

export const setProjectQueryStorage = ({ userId, query = initQueryParams }: ProjectQueryStorage): void => {
    try {
        const data = {
            userId,
            query: {
                ...query,
                SortBy: query?.SortBy || initQueryParams.SortBy,
                OrderBy: query?.OrderBy || initQueryParams.OrderBy
            } as QueryParams
        }

        localStorage.setItem(`${PROJECT_QUERY_STORAGE_KEY}_` + userId, JSON.stringify(data))
    } catch (e) {
        console.error(e)
    }
}

export const getProjectQueryStorage = (userId: User['userId']): ProjectQueryStorage | null => {
    try {
        const data = localStorage.getItem(`${PROJECT_QUERY_STORAGE_KEY}_` + userId)
        if (!data) {
            return null
        }
        return JSON.parse(data) as ProjectQueryStorage
    } catch (e) {
        console.error(e)
        return null
    }
}

export const removeProjectQueryStorage = (userId: User['userId']): void => {
    try {
        localStorage.removeItem(`${PROJECT_QUERY_STORAGE_KEY}_` + userId)
    } catch (e) {
        console.error(e)
    }
}

export const getAllLocalStorageKeys = (): string[] => {
    const keys: string[] = []
    const numberOfKeys = localStorage.length

    for (let i = 0; i < numberOfKeys; i++) {
        const key = localStorage.key(i)
        if (key) {
            keys.push(key)
        }
    }

    return keys
}
