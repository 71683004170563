export type WavePatterns = {
    Impulse: ImpulseWaveForms
    SimpleCorrection: LetterWaveForms
    ComplexCorrection: LetterWaveForms
    IndefiniteStart: IndefiniteStartForms
}

export type ImpulseWaveForms = {
    RomanUpper: string[]
    RomanLower: string[]
    UpperCaseRomanNumerals: string[]
    SquareBrackets: string[]
    Parentheses: string[]
    SimpleNumbers: string[]
    LowerCaseRomanNumerals: string[]
}

export type LetterWaveForms = {
    UpperCaseLetters: string[]
    LowerCaseLetters: string[]
    SquareBrackets: string[]
    Parentheses: string[]
}

export type IndefiniteStartForms = {
    MixedWithParentheses: string[]
    MixedSimple: string[]
}

export const getImpulseWaveOptions = () => [
    { localeKey: 'wavePatterns.impulse.romanUpper', value: 'RomanUpper' },
    { localeKey: 'wavePatterns.impulse.romanLower', value: 'RomanLower' },
    { localeKey: 'wavePatterns.impulse.upperCaseRomanNumerals', value: 'UpperCaseRomanNumerals' },
    { localeKey: 'wavePatterns.impulse.squareBrackets', value: 'SquareBrackets' },
    { localeKey: 'wavePatterns.impulse.parentheses', value: 'Parentheses' },
    { localeKey: 'wavePatterns.impulse.simpleNumbers', value: 'SimpleNumbers' },
    { localeKey: 'wavePatterns.impulse.lowerCaseRomanNumerals', value: 'LowerCaseRomanNumerals' }
]

export const getSimpleCorrectionWaveOptions = () => [
    { localeKey: 'wavePatterns.simpleCorrection.upperCaseLetters', value: 'UpperCaseLetters' },
    { localeKey: 'wavePatterns.simpleCorrection.lowerCaseLetters', value: 'LowerCaseLetters' },
    { localeKey: 'wavePatterns.simpleCorrection.squareBrackets', value: 'SquareBrackets' },
    { localeKey: 'wavePatterns.simpleCorrection.parentheses', value: 'Parentheses' }
]

export const getComplexCorrectionWaveOptions = () => [
    { localeKey: 'wavePatterns.complexCorrection.upperCaseLetters', value: 'UpperCaseLetters' },
    { localeKey: 'wavePatterns.complexCorrection.lowerCaseLetters', value: 'LowerCaseLetters' },
    { localeKey: 'wavePatterns.complexCorrection.squareBrackets', value: 'SquareBrackets' },
    { localeKey: 'wavePatterns.complexCorrection.parentheses', value: 'Parentheses' }
]

export const getIndefiniteStartWaveOptions = () => [
    { localeKey: 'wavePatterns.indefiniteStart.mixedWithParentheses', value: 'MixedWithParentheses' },
    { localeKey: 'wavePatterns.indefiniteStart.mixedSimple', value: 'MixedSimple' }
]

export const createOptionsByType = (type: string) => {
    switch (type) {
        case 'Impulse':
            return getImpulseWaveOptions()
        case 'SimpleCorrection':
            return getSimpleCorrectionWaveOptions()
        case 'ComplexCorrection':
            return getComplexCorrectionWaveOptions()
        case 'IndefiniteStart':
            return getIndefiniteStartWaveOptions()
        default:
            return []
    }
}

export type FormTypes = keyof ImpulseWaveForms | keyof LetterWaveForms | keyof IndefiniteStartForms

const elliottWaves: WavePatterns = {
    Impulse: {
        RomanUpper: ['(I)', '(II)', '(III)', '(IV)', '(V)'],
        RomanLower: ['(i)', '(ii)', '(iii)', '(iv)', '(v)'],
        UpperCaseRomanNumerals: ['I', 'II', 'III', 'IV', 'V'],
        SquareBrackets: ['[1]', '[2]', '[3]', '[4]', '[5]'],
        Parentheses: ['(1)', '(2)', '(3)', '(4)', '(5)'],
        SimpleNumbers: ['1', '2', '3', '4', '5'],
        LowerCaseRomanNumerals: ['i', 'ii', 'iii', 'iv', 'v']
    },
    SimpleCorrection: {
        UpperCaseLetters: ['A', 'B', 'C', 'D', 'E'],
        LowerCaseLetters: ['a', 'b', 'c', 'd', 'e'],
        SquareBrackets: ['[A]', '[B]', '[C]', '[D]', '[E]'],
        Parentheses: ['(A)', '(B)', '(C)', '(D)', '(E)']
    },
    ComplexCorrection: {
        UpperCaseLetters: ['W', 'X', 'Y', 'Z'],
        LowerCaseLetters: ['w', 'x', 'y', 'z'],
        SquareBrackets: ['[W]', '[X]', '[Y]', '[Z]'],
        Parentheses: ['(W)', '(X)', '(Y)', '(Z)']
    },
    IndefiniteStart: {
        MixedWithParentheses: ['(1) or (A)', '(2) or (B)', '(3) or (C)'],
        MixedSimple: ['1/A', '2/B', '3/C']
    }
}

export default elliottWaves
