import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { ITestingProcRec } from '../CommonProcRecInterfaces/ITestingProcRec'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class TestingProcRecImplementation extends BasicProcRecImplementation {
    public GetImplementation(): ITestingProcRec {
        return {
            IsTesting: this.IsTesting.bind(this),
            IsPaused: this.IsPaused.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_Testing_${super.generateDName()}`
    }

    public IsTesting(): boolean {
        return GlobalOptions.Options.TestStarted
    }

    public IsPaused(): boolean {
        return GlobalOptions.Options.Paused
    }
}
