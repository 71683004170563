import { FC, useCallback } from 'react'

import cx from 'classnames'

import { Flex } from '@fto/ui'

import { COLORS } from './constants/index'

import styles from './index.module.scss'

type ColorSelectorProps = {
    color: string
    isSelected: boolean
    setColor: (color: string) => void
    onClose?: () => void
}

const ColorSelector: FC<ColorSelectorProps> = ({ color, setColor, isSelected, onClose }) => {
    const handleSelection = useCallback(() => {
        setColor(color)
        onClose?.()
    }, [color, setColor, onClose])

    return (
        <Flex
            alignItems='center'
            justifyContent='center'
            onClick={handleSelection}
            className={cx(styles.ColorSelector, { [styles.Selected]: isSelected })}
        >
            <div style={{ backgroundColor: color }} className={styles.Block} />
        </Flex>
    )
}

type Props = {
    setColor: (color: string) => void
    onClose?: () => void
    selectedColor: string
}

const Palette: FC<Props> = ({ setColor, selectedColor, onClose }) => {
    return (
        <Flex className={styles.Palette} spacing={4}>
            {COLORS.map((palette, idx) => {
                return (
                    <Flex direction='column' key={idx} vspacing={4}>
                        {palette.map((color) => (
                            <ColorSelector
                                onClose={onClose}
                                color={color}
                                key={color}
                                setColor={setColor}
                                isSelected={selectedColor === color}
                            />
                        ))}
                    </Flex>
                )
            })}
        </Flex>
    )
}

export default Palette
