import { Modal, Typography } from '@fto/ui'
import { Fragment } from 'react'
import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'

type ChangeEmailContentProps = {
    onClose: () => void
    onSubmit: () => void
}

export const ChangeEmailContent = (props: ChangeEmailContentProps) => {
    const { t } = useTranslation()

    return (
        <Fragment>
            <Modal.Content className={styles.line}>
                <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                    {t('profile.modals.changeEmailText')}
                </Typography>
            </Modal.Content>
            <Modal.Controls
                submitText={t('profile.modals.getCode')}
                cancelText={t('global.cancel')}
                onClose={props.onClose}
                onSubmit={props.onSubmit}
            />
        </Fragment>
    )
}
