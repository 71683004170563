import { FC, useEffect, useMemo, useCallback } from 'react'

import KeyboardTracker from '@fto/lib/utils/KeyboardTracker'

//TODO: remvoe this if it was only needed for the charts controller that is now golbal
// interface KeyboardTrackerComponentProps {
//     controller: GlobalChartsController;
// }

const KeyboardTrackerComponent: FC = () => {
    const keyboardTracker = useMemo(() => KeyboardTracker.getInstance(), [KeyboardTracker.getInstance])

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            keyboardTracker.onKeyDown(event)
        },
        [keyboardTracker]
    )

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            keyboardTracker.onKeyUp(event)
        },
        [keyboardTracker]
    )

    const handleKeyBlur = useCallback(() => {
        keyboardTracker.onWindowBlur()
    }, [keyboardTracker])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('keyup', handleKeyUp)
        window.addEventListener('blur', handleKeyBlur)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('keyup', handleKeyUp)
            window.removeEventListener('blur', handleKeyBlur)
        }
    }, [])

    return null
}

export default KeyboardTrackerComponent
