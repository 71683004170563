import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from '@fto/ui'

type Props = {
    label: string
    className?: string
    children: ReactNode
}

const FieldWrapper: FC<Props> = ({ label, className, children }) => {
    const { t } = useTranslation()
    return (
        <Flex alignItems='center' justifyContent='space-between' gap={8}>
            <Typography type='interface-regular' color='gray-1000' className={className}>
                {label}
            </Typography>
            {children}
        </Flex>
    )
}

export default FieldWrapper
