import { TChartOptions, TChartScale, TCursorMode } from '../../charting/ChartBasicClasses'
import { TChart } from '../../charting/chart_classes/BasicChart'
import { TScrollInfo } from '../chart_classes/auxiliary_classes_charts/TScrollInfo'
import { TSelOrder } from '../chart_classes/auxiliary_classes_charts/TSelOrder'
import { TSelIndValue } from '../auxiliary_classes_charting/TSelIndValue'
import { TDateTimeBar } from '../../charting/chart_classes/DateTimeBarUnit'
import { TMainChart } from '../../charting/chart_classes/MainChartUnit'
import { TBasicChartWindow } from '../../charting/chart_windows/BasicChartWindow'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { TFontStyle } from '../../delphi_compatibility/DelphiBasicTypes'
import { DelphiFormsBuiltIns, TMouseButton, TShiftState } from '../../delphi_compatibility/DelphiFormsBuiltIns'
import { DelphiMathCompatibility } from '../../delphi_compatibility/DelphiMathCompatibility'
import { TOutputWindow } from '@fto/lib/extension_modules/indicators/IndicatorTypes'
import { TChartType } from '../../ft_types/common/BasicClasses/BasicEnums'
import CommonConstants from '../../ft_types/common/CommonConstants'
import { CustomCursorPointers, TCursor, TCustomCursor } from '../../ft_types/common/CursorPointers'
import { TimeframeUtils } from '../../ft_types/common/TimeframeUtils'
import { TOffsStringList, TVarList } from '../../ft_types/common/OffsStringList'
import { TTemplate, TTemplatesList } from '../../ft_types/common/TemplatesList'
import IBarsContainer from '../../ft_types/common/common_interfaces/IBarsContainer'
import { TSymbolData } from '../../ft_types/data/SymbolData'
import { TChunkStatus, TNoExactMatchBehavior } from '../../ft_types/data/chunks/ChunkEnums'
import { TDataDescriptor } from '../../ft_types/data/data_arrays/DataDescriptionTypes'
import IFMBarsArray from '../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TDataArrayEvents, TDataAvailability } from '../../ft_types/data/data_downloading/DownloadRelatedEnums'
import GlobalIndicatorDescriptors from '../../globals/GlobalIndicatorDescriptors'
import GlobalOptions from '../../globals/GlobalOptions'
import GlobalSymbolList from '../../globals/GlobalSymbolList'
import { TOscWindow, TOscWinsList, TOscWinSplitter } from '../OscWinsListUnit'
import { ChartEvent, TFocusType } from '../auxiliary_classes_charting/ChartingEnums'
import { TFocusedPaintTool } from '../auxiliary_classes_charting/FocusedPaintTool'
import { TOscChart } from '../chart_classes/OscChartUnit'
import PaintToolsListMemento from '../paint_tools/Memento/PaintToolsListMemento'
import { PaintToolManager } from '../paint_tools/PaintToolManager'
import { PaintToolNames } from '../paint_tools/PaintToolNames'
import { TPaintToolStatus, TPaintToolType } from '../paint_tools/PaintToolsAuxiliaryClasses'
import { TPtRiskReward, TRiskToolType } from '../paint_tools/SpecificTools/ptRiskReward'
import { TPtSign } from '../paint_tools/SpecificTools/ptSign'
import {
    ChartControl,
    chartControlEvent,
    ChartControlId,
    ChartControlParams,
    LocationParams
} from '@fto/chart_components/ChartControl'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { ChartJSON, OscIndicatorsJSON } from '@fto/lib/ProjectAdapter/Types'
import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { DownloadController } from '@fto/lib/ft_types/data/data_downloading/DownloadController'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import ToolInfoStore from '@fto/lib/store/tools'
import { addContextMenu, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'
import TimeframeStore from '@fto/lib/store/timeframe'
import { IndicatorConfigurationControl } from '@fto/chart_components/IndicatorConfigurationControl'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import { ControlsManager } from '@fto/chart_components/ControlsManager'
import { throttle } from 'lodash'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { ChartWindowLayers } from '@fto/lib/charting/auxiliary_classes_charting/Layers/ChartWindowLayers'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { showErrorToast } from '@root/utils/toasts'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { ELockToOption } from '@fto/lib/ft_types/common/OptionsEnums'
import INumberRange from '@fto/lib/common/UtilObjects/INumberRange'
import ChartUtils from '../chart_classes/ChartUtils'
import IDateAnchorPoint from '@fto/lib/globals/Definitions/IDateAnchorPoint'
import KeyboardTracker from '@fto/lib/utils/KeyboardTracker'
import oneClickTradingStore from '@fto/lib/store/oneClickTradingStore'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { t } from 'i18next'
import { EBlockingLayerState } from '../auxiliary_classes_charting/Layers/ChartLayersEnums'
import { crosshairManager } from '@fto/lib/globals/CrosshairManager'
import { CrosshairControl } from '@fto/chart_components/crosshair/CrosshairControl'
import { OscIndicatorConfigurationControl } from '@fto/chart_components/OscIndicatorConfigurationControl'
import { TPoint, TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IndicatorDescriptor } from '@fto/lib/extension_modules/indicators/IndicatorDescriptor'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { RuntimeIndicatorFactory } from '@fto/lib/extension_modules/indicators/RuntimeIndicatorFactory'
import { IVisibleIndexBuffer } from '@fto/lib/extension_modules/indicators/api/ITVisibleIndexBuffer'
import { GlobalCustomIndicatorsManager } from '@fto/lib/globals/GlobalCustomIndicatorsManager'
import store from '@root/store'
import IBasicPaintTool from '../paint_tools/IBasicPaintTool'
import { IChartWindow } from './IChartWindow'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'

export class TChartWindow
    extends TBasicChartWindow
    implements ObserverTemplate<chartControlEvent, ChartControl>, IBarsContainer, IChartWindow
{
    private static cmActiveTool: IBasicPaintTool | null = null
    private static cmWindow: TChartWindow | null = null
    private static prepearedMementoForUndoStack: PaintToolsListMemento
    private static reservedChartIds: number[] = []

    private fTFConfig: TTemplatesList
    private fChartLocked: boolean
    private fChartVLocked: boolean
    private fChartVHLocked: boolean
    private fChartXPos = 0
    private fChartYPos = 0
    private fChartScale: number
    private fChartPos = 0
    private fMousePos: TPoint
    private fBaseLevel: number
    private fPrepareToClone: boolean
    private fActiveTool: IBasicPaintTool | null
    private fFocusedTool: TFocusedPaintTool = new TFocusedPaintTool()
    private fSelOrder: TSelOrder = new TSelOrder()
    private fFocusedOrder: TSelOrder = new TSelOrder()
    private fModifyInfo: TSelOrder = new TSelOrder()
    private fSkipOneMouseDown: boolean
    private preparedToolsForCopy: IBasicPaintTool[] = []
    private fcurrentToolToCopy: IBasicPaintTool | null = null
    private fcurrentToolToDelete: IBasicPaintTool | null = null
    private fToolToEdit!: IBasicPaintTool | null
    private prevTimeframeBarArray: IFMBarsArray | undefined = undefined

    //TODO: change for ISymbolData
    private _symbolData: TSymbolData | undefined = undefined

    public get SymbolData(): TSymbolData {
        if (!this._symbolData) {
            this.setSymbolData()
            if (!this._symbolData) {
                throw new StrangeError('SymbolData is not initialized in ChartWindow')
            }
        }

        return this._symbolData
    }

    public get PrevTimeframeBarArray(): IFMBarsArray | undefined {
        return this.prevTimeframeBarArray
    }

    private _mainChart: TMainChart | null = null

    public get MainChart(): TMainChart {
        if (!this._mainChart) {
            throw new StrangeError('MainChart is not initialized in ChartWindow')
        }
        return this._mainChart
    }

    private _dateTimeBar: TDateTimeBar | null = null

    public get TimeBar(): TDateTimeBar {
        if (!this._dateTimeBar) {
            throw new StrangeError('DateTimeBar is not initialized in ChartWindow')
        }
        return this._dateTimeBar
    }

    public OscWins: TOscWinsList

    public SelectedSymbolName = ''

    protected _ChartUnderMouse: TChart | null = null

    private observableItem: ObservableTemplateItem<ChartEvent, TChartWindow, ObserverTemplate<ChartEvent, TChartWindow>>
    private undoStack: PaintToolsListMemento[]
    private redoStack: PaintToolsListMemento[]
    public chartID: number
    public lastBarIndexOnStartTesting!: number
    public isPaintToolsAndIndicatorsLoaded = false
    public isShown = false
    public isInitialized = false

    public mouseOverChart = false

    public activeFrame = false

    private chartWindowLayers: ChartWindowLayers

    public controlsManager: ControlsManager = new ControlsManager()
    private isNeedRepaintAfterEventsInsideControlsManager = false
    private isNeedRepaintAfterEventsInsideControlsManagerOnMouseDown = false
    public HiddenPaintTools: { tool: IBasicPaintTool; index: number }[]

    protected _cursorMode: TCursorMode = TCursorMode.cm_Default

    public _chartType = TChartType.ct_Normal //FIXME: this is a hack for now, use something else later

    public ChartOptions: TChartOptions

    public get cursorMode(): TCursorMode {
        return this._cursorMode
    }

    public set cursorMode(value: TCursorMode) {
        this._cursorMode = value
    }

    public get DName(): string {
        return `ChartWin #${this.chartID} ${this.SelectedSymbolName} ${TimeframeUtils.GetShortTimeframeName(
            this.ChartOptions.Timeframe
        )}`
    }

    private deferredAnchorToScroll: IDateAnchorPoint | null = null

    // private chunkForTimeFrameChange: TBarChunk | null = null

    constructor(symbolName: string, chartId?: number) {
        DebugUtils.log('TChartWindow constructor CREATING')

        super()
        this.observableItem = new ObservableTemplateItem<
            ChartEvent,
            TChartWindow,
            ObserverTemplate<ChartEvent, TChartWindow>
        >()
        this.undoStack = []
        this.redoStack = []
        this.preparedToolsForCopy = []
        this.HiddenPaintTools = []

        this.chartWindowLayers = new ChartWindowLayers(this)

        if (chartId === undefined) {
            this.chartID = TChartWindow.getNextID()
        } else {
            TChartWindow.reserveChartId(chartId)
            this.chartID = chartId
        }

        this.ChartOptions = new TChartOptions()

        //general options
        this.fChartScale = this.ChartOptions.VerticalScale
        this.fBaseLevel = this.ChartOptions.FixedScaleBaseLevel
        this.PosMarker.DateTime = -1
        this.fChartLocked = false
        this.fChartVLocked = false
        this.fChartVHLocked = false
        this.fMousePos = new TPoint(0, 0)
        this.fPrepareToClone = false
        this.fSkipOneMouseDown = false
        this.SelectedSymbolName = symbolName

        //tools
        this.fActiveTool = null
        this.fFocusedTool.clear()

        //orders
        this.fSelOrder.clear()
        this.fFocusedOrder.clear()
        this.fModifyInfo.clear()

        this._dateTimeBar = new TDateTimeBar(this)
        this._mainChart = new TMainChart(this)
        this._dateTimeBar.setMainChart(this._mainChart)
        this.OscWins = new TOscWinsList(this)
        this.fTFConfig = new TTemplatesList()
    }

    public toJson(): ChartJSON {
        const paintToolsJSON = this.MainChart.PaintTools.toJson()
        const indicatorsJSON = this.MainChart.indicators.toJSON()
        const oscWinsJSON = this.OscWins.toJSON()

        return {
            MatchPriceWithBarsColor: this.ChartOptions.MatchPriceWithBarsColor,
            CustomPriceGoesUp: this.ChartOptions.CustomPriceGoesUp,
            CustomPriceGoesDown: this.ChartOptions.CustomPriceGoesDown,
            HorzMagnifier: this.ChartOptions.HorzMagnifier,
            VerticalScale: this.ChartOptions.VerticalScale,
            FixedGridSize: this.ChartOptions.FixedGrid,
            GridSize: this.ChartOptions.GridSize,
            FixedScale: this.ChartOptions.FixedScale,
            FixedScaleBaseLevel: this.ChartOptions.FixedScaleBaseLevel,
            FixedVScale: this.ChartOptions.FixedVScale,
            Timeframe: this.ChartOptions.Timeframe,
            ChartStyle: this.ChartOptions.ChartStyle,
            ShowGrid: this.ChartOptions.ShowGrid,
            AutoScroll: this.ChartOptions.AutoScroll,
            RightOffset: this.ChartOptions.RightOffset,
            OffsetPercentage: this.ChartOptions.OffsetPercentage,
            ShowVolume: this.ChartOptions.ShowVolume,
            ShowPeriodSeparators: this.ChartOptions.ShowPeriodSeparators,
            ChartOnForeground: this.ChartOptions.ChartOnForeground,
            ShowBidLevel: this.ChartOptions.ShowBidLevel,
            ShowAskLevel: this.ChartOptions.ShowAskLevel,
            ShowIndicatorValues: this.ChartOptions.ShowIndicatorValues,
            ShowBalance: this.ChartOptions.ShowBalance,
            ShowMargin: this.ChartOptions.ShowMargin,
            ShowDrawdown: this.ChartOptions.ShowDrawdown,
            ProfitChartOldStyle: this.ChartOptions.ProfitChartOldStyle,
            ShowNotes: this.ChartOptions.ShowNotes,
            ShowNews: this.ChartOptions.ShowNews,
            ShowHistory: this.ChartOptions.ShowHistory,
            chartID: Number(this.chartID),
            symbolName: this.SelectedSymbolName,
            paintTools: paintToolsJSON,
            indicators: indicatorsJSON,
            oscWins: oscWinsJSON,
            isShown: this.isShown,
            PriceAxisFontSize: GlobalOptions.Options.PriceAxisFontSize,
            DateTimeScaleFontSize: GlobalOptions.Options.DateScaleFontSize,
            ColorScheme: this.ChartOptions.ColorScheme.SaveToStr(),
            OneClickTradingMode: this.ChartOptions.OneClickTradingMode,
            isSyncActionsWithGraphTools: GlobalOptions.Options.isSyncActionsWithGraphTools,
            Version: this.ChartOptions.Version
        }
    }

    static getNextID(): number {
        for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
            if (!TChartWindow.reservedChartIds.includes(i)) {
                TChartWindow.reserveChartId(i)
                return i
            }
        }

        throw new StrangeError('No available ID for chart')
    }

    static reserveChartId(id: number): void {
        if (TChartWindow.reservedChartIds.includes(id)) throw new StrangeError('Chart ID already exists')
        TChartWindow.reservedChartIds.push(id)
    }

    static clearReservedChartIds(): void {
        TChartWindow.reservedChartIds = []
    }

    static removeReservedChartId(id: number): void {
        TChartWindow.reservedChartIds = TChartWindow.reservedChartIds.filter((reservedId) => reservedId !== id)
    }

    public updateEverythingRelatedToNewSymbol(): void {
        this.setSymbolData()
        this.SetTimeframe(this.ChartOptions.Timeframe)
    }

    public fromJSON(jsonObj: ChartJSON): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'TChartWindow fromJSON')

        this.SetChartOptionsFromJSON(jsonObj)

        const changeSymbolControl = this.chartWindowLayers.getChangeSymbolControl()
        if (changeSymbolControl) {
            changeSymbolControl.getOwnerLayer()?.draw()
        }

        this.InitChartsAndPtrs()

        // this.ScrollRight()

        this.updateIndicatorsForCurrentTimeFrameAndSymbol()

        this.isPaintToolsAndIndicatorsLoaded = true
    }

    private SetChartOptionsFromJSON(jsonObj: ChartJSON) {
        this.ChartOptions.ColorScheme.LoadFromStr(jsonObj.ColorScheme)
        this.ChartOptions.HorzMagnifier = jsonObj.HorzMagnifier
        this.ChartOptions.VerticalScale = jsonObj.VerticalScale
        this.ChartOptions.FixedGrid = jsonObj.FixedGridSize
        this.ChartOptions.GridSize = jsonObj.GridSize
        this.ChartOptions.FixedScale = jsonObj.FixedScale
        this.ChartOptions.FixedScaleBaseLevel = jsonObj.FixedScaleBaseLevel
        this.ChartOptions.FixedVScale = jsonObj.FixedVScale
        this.ChartOptions.Timeframe = jsonObj.Timeframe
        this.ChartOptions.ChartStyle = jsonObj.ChartStyle
        this.ChartOptions.ShowGrid = jsonObj.ShowGrid
        this.ChartOptions.AutoScroll = jsonObj.AutoScroll
        this.ChartOptions.RightOffset = jsonObj.RightOffset
        this.ChartOptions.OffsetPercentage = jsonObj.OffsetPercentage
        this.ChartOptions.ShowVolume = jsonObj.ShowVolume
        this.ChartOptions.ShowPeriodSeparators = jsonObj.ShowPeriodSeparators
        this.ChartOptions.ChartOnForeground = jsonObj.ChartOnForeground
        this.ChartOptions.ShowBidLevel = jsonObj.ShowBidLevel
        this.ChartOptions.ShowAskLevel = jsonObj.ShowAskLevel
        this.ChartOptions.ShowIndicatorValues = jsonObj.ShowIndicatorValues
        this.ChartOptions.ShowBalance = jsonObj.ShowBalance
        this.ChartOptions.ShowMargin = jsonObj.ShowMargin
        this.ChartOptions.ShowDrawdown = jsonObj.ShowDrawdown
        this.ChartOptions.ProfitChartOldStyle = jsonObj.ProfitChartOldStyle
        this.ChartOptions.ShowNotes = jsonObj.ShowNotes
        this.ChartOptions.ShowNews = jsonObj.ShowNews
        this.isShown = jsonObj.isShown

        this.ChartOptions.OneClickTradingMode = jsonObj.OneClickTradingMode

        this.ChartOptions.MatchPriceWithBarsColor =
            jsonObj.MatchPriceWithBarsColor ?? this.ChartOptions.MatchPriceWithBarsColor
        this.ChartOptions.CustomPriceGoesUp = jsonObj.CustomPriceGoesUp ?? this.ChartOptions.CustomPriceGoesUp
        this.ChartOptions.CustomPriceGoesDown = jsonObj.CustomPriceGoesDown ?? this.ChartOptions.CustomPriceGoesDown

        this.ChartOptions.Version = jsonObj.Version
        this.processDefaultValuesByVersion(jsonObj.Version)
    }

    public processDefaultValuesByVersion(version: number | undefined): void {
        if (version === undefined) {
            this.ChartOptions.ShowPeriodSeparators = false
        }
    }

    public InitWithDefaultOptions(): void {
        //the options are already set as default
        this.InitChartsAndPtrs()
    }

    private InitChartsAndPtrs() {
        this.InitChartPtrs(this.MainChart)
        this.InitChartPtrs(this.TimeBar)

        GlobalChartsController.Instance.setNewSymbol(this.SelectedSymbolName, this)
    }

    private setSymbolData(): void {
        if (!this.SelectedSymbolName) {
            throw new StrangeError('No symbol name for initialization in ChartWindow')
        }
        const foundSymbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(this.SelectedSymbolName, true)
        if (foundSymbol) {
            this._symbolData = foundSymbol
            this._symbolData.EnsureTimeframeIsActive(this.ChartOptions.Timeframe)
        } else {
            //TODO: create a new symbol if it does not exist???
            throw new StrangeError(`Symbol ${this.SelectedSymbolName} not found in symbol list.`) //if we actually need to set null to symbol, we can change its type to | null
        }
    }

    public initLayers(): void {
        this.chartWindowLayers.init()
    }

    public oscWinsFromJSON(oscWins: OscIndicatorsJSON): void {
        this.deleteAllOscCanvas()
        this.OscWins.Clear()

        this.OscWins = new TOscWinsList(this)

        //TODO: refactor this, it looks weird
        for (const outerOscWin of oscWins) {
            for (const currentOscWin of outerOscWin) {
                const indicatorTimeframe = this.ChartOptions.Timeframe
                let indicatorDescriptor: IndicatorDescriptor | null = null
                const list1: TOffsStringList = new TOffsStringList()
                let runtimeIndicator: TRuntimeIndicator | null = null

                indicatorDescriptor = GlobalIndicatorDescriptors.BuiltInIndicators.findByLibName(currentOscWin.FileName)
                if (indicatorDescriptor === null) {
                    throw new StrangeError(`Indicator descriptor not found for ${currentOscWin.FileName}`)
                }

                // create indicator and load options
                //FIXME: change timeframe
                runtimeIndicator = this.CreateIndicator(indicatorDescriptor, indicatorTimeframe)
                list1.LoadFromString(currentOscWin.Options)

                const levels = list1.GetVarStr('Levels')
                runtimeIndicator.options.LoadFromList(list1)
                runtimeIndicator.levels.ImportFromStr(levels)

                if (!currentOscWin.IsVisible) runtimeIndicator.Hide() // currentOscWin.IsVisible is visible by default

                if (runtimeIndicator) {
                    runtimeIndicator = this.CreateIndicatorAndRecount(runtimeIndicator, indicatorTimeframe, true)
                }

                for (const [index, buffer] of runtimeIndicator.VisibleBuffers.entries()) {
                    currentOscWin.BufferVisibility[index] ? buffer.show() : buffer.hide()
                }

                runtimeIndicator.RefreshLevels() // must be before SaveOptions or levels will be lost
                runtimeIndicator.SaveOptions()
                runtimeIndicator.RefreshOptions()
            }
        }
    }

    ExportSettings(): void {
        const { setSettings } = ChartSettingsStore // Use the store/context

        setSettings((settings) => ({ ...settings, ChartStyle: this.ChartOptions.ChartStyle }))
    }

    ImportSettings(): void {
        const { settings, resetStore } = ChartSettingsStore // Use the store/context

        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'ImportSettings', settings.isPlaying)

        this.ChartOptions.ChartStyle = settings.ChartStyle

        if (settings.isPlaying) {
            GlobalTestingManager.TestingManager.StartTesting(settings.TestingSpeed)
        } else {
            GlobalTestingManager.TestingManager.stopTesting()
        }
        this.observableItem.notify(ChartEvent.UPDATE_SINGLE_CHART, this)
    }

    get DataDescriptor(): TDataDescriptor {
        return this.Bars.DataDescriptor
    }

    GetFirstAvailableDate(): number {
        return this.Bars.GetFirstAvailableDate()
    }

    GetLastAvailableDate(): number {
        return this.Bars.GetLastAvailableDate()
    }

    public get LastItemInTesting(): TBarRecord {
        return this.Bars.LastItemInTesting
    }

    public get totalBarsCount(): number {
        return this.Bars.totalBarsCount
    }

    public processEvent(event: chartControlEvent, item: ChartControl): void {
        switch (event) {
            case chartControlEvent.CONTROL_SELECTED: {
                import('@fto/lib/store/ordersStore')
                    .then(({ default: OrdersStore }) => {
                        const { selectPosition } = OrdersStore

                        const correspondingOpenPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find(
                            (openPos) => {
                                return openPos.isMyControl(item)
                            }
                        )
                        const ticketId = correspondingOpenPos?.tpos.ticket

                        if (ticketId !== undefined) {
                            selectPosition(ticketId)
                        }
                    })
                    .catch(() => {
                        throw new StrangeError('Error in TChartWindow processEvent CONTROL_SELECTED')
                    })
                this.handleControlSelection(item, true)
                break
            }

            case chartControlEvent.CONTROL_DESELECTED: {
                import('@fto/lib/store/ordersStore')
                    .then(({ default: OrdersStore }) => {
                        const { deselectPosition } = OrdersStore

                        const correspondingOpenPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find(
                            (openPos) => {
                                return openPos.isMyControl(item)
                            }
                        )

                        const ticketId = correspondingOpenPos?.tpos.ticket

                        if (ticketId !== undefined) {
                            deselectPosition(ticketId)
                        }
                    })
                    .catch(() => {
                        throw new StrangeError('Error in TChartWindow processEvent CONTROL_SELECTED')
                    })

                this.handleControlSelection(item, false)
                break
            }

            case chartControlEvent.SYMBOL_CHANGE_MODAL: {
                this.showSymbolChangeModal()
                break
            }

            case chartControlEvent.INDICATOR_VISIBILITY_CHANGE: {
                this.onIndicatorVisibilityChange()
                break
            }

            case chartControlEvent.INDICATOR_DELETE: {
                this.handleIndicatorDelete(item)
                break
            }

            case chartControlEvent.INDICATOR_SHOW_INDICATOR_SETTINGS: {
                this.showIndicatorSettings(item)
                break
            }

            case chartControlEvent.NEED_REDRAW: {
                this.redrawChartControls(item)
                break
            }
            case chartControlEvent.NEED_REDRAW_OSC_INDICATOR_CONTROL: {
                this.needsRedraw = true
                break
            }
            case chartControlEvent.INDICATOR_SELECTED_BY_CONF_CONTROL: {
                this.handleIndicatorSelectedByConfControl(item)
                break
            }

            case chartControlEvent.BUTTON_PRESSED: {
                this.observableItem.notify(ChartEvent.ACTIVATE, this)

                if (item) {
                    this.processEventButtonPressed(item)
                }
                break
            }

            default: {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(`Unknown event in TChartWindow ${event}`)
            }
        }
    }

    private processEventButtonPressed(item: ChartControl): void {
        switch (item.controlId) {
            case ChartControlId.ONE_CLICK_SELL: {
                const { data } = oneClickTradingStore
                GlobalProcessingCore.ProcessingCore.SendMarketOrder({
                    SymbolName: this.SelectedSymbolName,
                    OperationType: TTradePositionType.tp_Sell,
                    lot: data.lot,
                    StopLoss: 0,
                    TakeProfit: 0,
                    price: 0,
                    comment: '',
                    MagicNumber: 0,
                    AutoCloseTime: 0
                })
                break
            }
            case ChartControlId.ONE_CLICK_BUY: {
                const { data } = oneClickTradingStore
                GlobalProcessingCore.ProcessingCore.SendMarketOrder({
                    SymbolName: this.SelectedSymbolName,
                    OperationType: TTradePositionType.tp_Buy,
                    lot: data.lot,
                    StopLoss: 0,
                    TakeProfit: 0,
                    price: 0,
                    comment: '',
                    MagicNumber: 0,
                    AutoCloseTime: 0
                })
                break
            }
            case ChartControlId.INDICATOR_COLLAPSE_CONTROL: {
                this.chartWindowLayers.pressedCollapseControl()
                break
            }
            case ChartControlId.INDICATOR_MOVE_TO_LAST_BAR: {
                this.ScrollRight()
                this.observableItem.notify(ChartEvent.SCROLL_OTHER_CHARTS, this)
                this.observableItem.notify(ChartEvent.ENABLE_AUTOSCALE_ON_ALL_CHARTS, this)

                break
            }
            case ChartControlId.TRY_AGAIN: {
                GlobalChartsController.Instance.setNewSymbol(this.SelectedSymbolName, this)
                this.chartWindowLayers.getLayerBlockingChart()?.switchStateTo(EBlockingLayerState.loader)

                break
            }
            case ChartControlId.BUTTON_CHANGE_SYMBOL: {
                addModal(MODAL_NAMES.chart.changeSymbol, { modalType: 'symbolChange', chartWindow: this })

                break
            }
            // No default
        }
    }

    private handleControlSelection(item: ChartControl, isSelected: boolean): void {
        import('@fto/lib/store/ordersStore')
            .then(({ default: OrdersStore }) => {
                const { selectPosition, deselectPosition } = OrdersStore
                const action = isSelected ? selectPosition : deselectPosition

                const correspondingOpenPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find((openPos) =>
                    openPos.isMyControl(item)
                )
                const ticketId = correspondingOpenPos?.tpos.ticket

                if (ticketId !== undefined) {
                    action(ticketId)
                }
            })
            .catch(() => {
                const errorEvent = isSelected ? 'CONTROL_SELECTED' : 'CONTROL_DESELECTED'
                throw new StrangeError(`Error in TChartWindow processEvent ${errorEvent}`)
            })
    }

    private showSymbolChangeModal(): void {
        addModal(MODAL_NAMES.chart.changeSymbol, {
            modalType: 'symbolChange',
            chartWindow: this
        })
    }

    private handleIndicatorDelete(item: ChartControl): void {
        const indicatorConfControl = item as IndicatorConfigurationControl
        if (indicatorConfControl) {
            this.DeleteRuntimeIndicator(indicatorConfControl.Indicator)
        }
    }

    private showIndicatorSettings(item: ChartControl): void {
        const indicatorConfControl = item as IndicatorConfigurationControl
        if (indicatorConfControl) {
            indicatorConfControl.Indicator.ExportData(true)
        }
    }

    private redrawChartControls(item: ChartControl): void {
        if (item && this.chartWindowLayers.getLayerChartControls()) {
            this.chartWindowLayers.getLayerChartControls().draw()
        }
    }

    private handleIndicatorSelectedByConfControl(item: ChartControl): void {
        const indicatorConfControl = item as IndicatorConfigurationControl
        if (indicatorConfControl) {
            if (TChart.SelectedIndicator !== null) {
                const runTimeIndicator = TChart.SelectedIndicator as TRuntimeIndicator
                if (runTimeIndicator) {
                    const confControl = runTimeIndicator.configurationControl
                    if (confControl && confControl === indicatorConfControl) {
                        return
                    }

                    const { VisibleBuffers } = TChart.SelectedIndicator
                    for (const buff of VisibleBuffers) {
                        buff.onIndicatorUnselected()
                        buff.selectionMarkers.selectionMarkersIndexes = []
                    }

                    runTimeIndicator.configurationControl?.onIndicatorDeselect()
                    runTimeIndicator.configurationControl?.adjustControlWidth()
                    this.chartWindowLayers.getManagerLayers().reDrawAll()
                }
            }
            TChart.SelectedIndicator = null
            this.onIndicatorSelect(indicatorConfControl.Indicator)
        }
    }

    private handleButtonPressed(item: ChartControl): void {
        this.observableItem.notify(ChartEvent.ACTIVATE, this)

        if (item) {
            switch (item.controlId) {
                case ChartControlId.ONE_CLICK_SELL: {
                    this.executeOneClickTrade(TTradePositionType.tp_Sell)
                    break
                }

                case ChartControlId.ONE_CLICK_BUY: {
                    this.executeOneClickTrade(TTradePositionType.tp_Buy)
                    break
                }

                case ChartControlId.INDICATOR_COLLAPSE_CONTROL: {
                    this.chartWindowLayers.pressedCollapseControl()
                    break
                }

                case ChartControlId.INDICATOR_MOVE_TO_LAST_BAR: {
                    this.moveToLastBar()
                    break
                }

                case ChartControlId.TRY_AGAIN: {
                    this.retryLoadingSymbol()
                    break
                }

                case ChartControlId.BUTTON_CHANGE_SYMBOL: {
                    this.showSymbolChangeModal()
                    break
                }

                default: {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation("Unknown button's id in TChartWindow")
                }
            }
        }
    }

    private executeOneClickTrade(operationType: TTradePositionType): void {
        const { data } = oneClickTradingStore
        GlobalProcessingCore.ProcessingCore.SendMarketOrder({
            SymbolName: this.SelectedSymbolName,
            OperationType: operationType,
            lot: data.lot,
            StopLoss: 0,
            TakeProfit: 0,
            price: 0,
            comment: '',
            MagicNumber: 0,
            AutoCloseTime: 0
        })
    }

    private moveToLastBar(): void {
        this.ScrollRight()
        this.observableItem.notify(ChartEvent.SCROLL_OTHER_CHARTS, this)
        this.observableItem.notify(ChartEvent.ENABLE_AUTOSCALE_ON_ALL_CHARTS, this)
    }

    private retryLoadingSymbol(): void {
        GlobalChartsController.Instance.setNewSymbol(this.SelectedSymbolName, this)
        this.chartWindowLayers.getLayerBlockingChart()?.switchStateTo(EBlockingLayerState.loader)
    }

    public attachObserver(observer: ObserverTemplate<ChartEvent, TChartWindow>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<ChartEvent, TChartWindow>): void {
        this.observableItem.detachObserver(observer)
    }

    saveState(usePreparedMemento = false): void {
        if (usePreparedMemento) {
            this.undoStack.push(TChartWindow.prepearedMementoForUndoStack)
        } else {
            const memento = this.MainChart.PaintTools.save()
            this.undoStack.push(memento)
        }
        this.redoStack = []
        ChartSettingsStore.checkUndoRedoAvailability()
    }

    saveStateWithNotify(usePreparedMemento = false): void {
        this.saveState(usePreparedMemento)
        this.observableItem.notify(ChartEvent.SAVE_STATE_ON_CHART, this)
    }

    undoLastChange(isNeedNotifyOtherCharts = true): void {
        if (this.undoStack.length > 0) {
            this.redoStack.push(this.MainChart.PaintTools.save())
            const memento = this.undoStack.pop()
            if (memento) {
                this.MainChart.PaintTools.restore(memento)
            }
        } else {
            if (this.MainChart.PaintTools !== this.MainChart.initialPaintTools) {
                DebugUtils.log('save state before undo')
                this.redoStack.push(this.MainChart.PaintTools.save())
                this.MainChart.PaintTools = this.MainChart.initialPaintTools
            }
        }
        if (isNeedNotifyOtherCharts) {
            this.observableItem.notify(ChartEvent.UNDO_LAST_STATE_ON_CHART, this)
        }
    }

    redoLastChange(isNeedNotifyOtherCharts = true): void {
        if (this.redoStack.length > 0) {
            const memento = this.redoStack.pop()
            if (memento) {
                this.undoStack.push(this.MainChart.PaintTools.save())
                this.MainChart.PaintTools.restore(memento)
            }
        }
        if (isNeedNotifyOtherCharts) {
            this.observableItem.notify(ChartEvent.REDO_LAST_STATE_ON_CHART, this)
        }
    }

    public onBrowserWndSizing(): void {
        for (let i = 0; i < this.OscWins.length; i++) {
            this.OscWins[i].onBrowserWndSizing()
        }
        this.observableItem.notify(ChartEvent.UPDATE_CHARTS_WITHOUT_THROTTLE, this)

        this.chartWindowLayers.onBrowserWndSizing()

        if (!this.chartWindowLayers.getManagerLayers()?.getLayerBlockingChart()?.isActive) {
            this.chartWindowLayers.getManagerLayers().reDrawAll()
        }
    }

    deleteSelectedPaintTools(): void {
        const selectedTools = this.MainChart.PaintTools.getAllSelectedTools()
        const { updateParams } = GraphToolPanelStore
        const { setInfo } = ToolInfoStore

        if (selectedTools.length > 0) {
            this.saveStateWithNotify()
        }
        for (const selectedTool of selectedTools) {
            if (selectedTool.ToolType === TPaintToolType.tt_OrderMarker) {
                continue
            }

            this.fcurrentToolToDelete = selectedTool

            this.MainChart.PaintTools.DeleteTool(this.fcurrentToolToDelete)

            fireMixpanelEvent('graph_tool_deleted', { graph_tool_name: this.fcurrentToolToDelete.ShortName })

            updateParams((prevSettings) => {
                const updatedTools = prevSettings.tools.filter((tool) => tool.ToolName !== selectedTool.ToolName)
                return {
                    ...prevSettings,
                    tools: updatedTools,
                    isOpen: updatedTools.length > 0
                }
            })

            this.observableItem.notify(ChartEvent.DELETE_LINKED_PAINT_TOOLS, this)
            this.fcurrentToolToDelete = null
        }
        for (const oscWin of this.OscWins) {
            const selectedOscTools = oscWin.chart.PaintTools.getAllSelectedTools()

            for (const [i, selectedOscTool] of selectedOscTools.entries()) {
                this.fcurrentToolToDelete = selectedOscTool

                updateParams((prevSettings) => {
                    const updatedTools = prevSettings.tools.filter(
                        (tool) => tool.ToolName !== selectedTools[i].ToolName
                    )
                    return {
                        ...prevSettings,
                        tools: updatedTools,
                        isOpen: updatedTools.length > 0
                    }
                })
                fireMixpanelEvent('graph_tool_deleted', { graph_tool_name: this.fcurrentToolToDelete.ShortName })
                oscWin.chart.PaintTools.DeleteTool(this.fcurrentToolToDelete)
                this.observableItem.notify(ChartEvent.DELETE_LINKED_PAINT_TOOLS, this)
                this.fcurrentToolToDelete = null
            }
        }

        setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
    }

    selectedAllPaintTools(): void {
        this.MainChart.PaintTools.SelectAllTools()
        this.observableItem.notify(ChartEvent.CHARTS_NEED_REDRAW, this)
    }

    public UpdateLayers(): void {
        this.getChartWindowLayers().getManagerLayers().reDrawAll()
    }

    public IsVisibleBarChunk(barChunk: TBarChunk): boolean {
        let result = false
        if (CommonDataUtils.isDataDescriptorEqual(barChunk.DataDescriptor, this.Bars.DataDescriptor)) {
            result = this.MainChart.IsVisibleBarChunk(barChunk)
        }
        return result
    }

    public Update(): void {
        if (this.needsRedraw) {
            this.Repaint() //it will set needsRedraw to false
        }
    }

    private checkBarAndGetDateTime(bar: TBarRecord | null): TDateTime {
        if (!bar) {
            throw new StrangeError(
                'Abnormal behavior. Cannot find the bar, but it should be there because we got its index from GetVisibleIndexRange.'
            )
        }
        return bar.DateTime
    }

    public getChartBarTime(): TDateTime {
        if (this.Bars.LastItemInTestingIndex <= 0) {
            return DateUtils.EmptyDate
        }

        switch (GlobalOptions.Options.OnScrollLockTo) {
            case ELockToOption.lt_LeftBar: {
                const bar = this.Bars.GetItemByGlobalIndex(this.MainChart.GetFirstVisibleBarIndex())

                return this.checkBarAndGetDateTime(bar)
            }
            case ELockToOption.lt_RightBar: {
                return this.Bars.GetDateByGlobalIndex(this.MainChart.GetLastVisibleBarIndex(), true, true)
            }
            case ELockToOption.lt_Center: {
                const bar = this.Bars.GetItemByGlobalIndex(this.MainChart.GetCenterVisibleBarIndex())

                return this.checkBarAndGetDateTime(bar)
            }
            default: {
                throw new StrangeError('getChartBarTime - Unknown ELockToOption')
            }
        }
    }

    public SetTimeframe(TimeFrame: number): void {
        if (this.isDataLoaded) {
            this.prevTimeframeBarArray = this.Bars
        }

        this.SymbolData.EnsureTimeframeIsActive(TimeFrame)

        this.UpdateTemplate()

        if (this.fActiveTool !== null) {
            this.FreeActiveTool()
        }

        this.ChartOptions.Timeframe = TimeFrame

        this.setBars()

        this.updateIndicatorsForCurrentTimeFrameAndSymbol()

        if (this.chartWindowLayers.isReady()) {
            this.chartWindowLayers.getChangeSymbolControl()?.getOwnerLayer()?.draw()
            this.chartWindowLayers.updateBarInfoLabel()
        }

        if (!this.isDataLoaded) {
            DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'Cannot set timeframe, data is not loaded yet')
            return
        }

        this.invalidate()
    }

    private rememberScrollPosition(): void {
        if (this._mainChart && this._mainChart.isDataLoaded && this._mainChart.is_HTML_Canvas_initialized()) {
            const scrollAnchor = this.getHorizScrollAnchorDate()
            if (!scrollAnchor || DateUtils.IsEmpty(scrollAnchor.date)) {
                this.deferredAnchorToScroll = null
            } else {
                this.deferredAnchorToScroll = scrollAnchor
            }
        } else {
            this.deferredAnchorToScroll = null
        }
    }

    public restoreScrollPosition(): void {
        if (this.isDataLoaded && this._mainChart && this._mainChart.is_HTML_Canvas_initialized()) {
            if (this.deferredAnchorToScroll && !DateUtils.IsEmpty(this.deferredAnchorToScroll.date)) {
                DebugUtils.logTopic(
                    ELoggingTopics.lt_ScrollAndZoom,
                    `${this.DName}: Restoring scroll position to ${this.deferredAnchorToScroll}`
                )
                this.ScrollToDate(this.deferredAnchorToScroll)
            } else {
                if (this.ChartOptions.AutoScroll) {
                    this.ScrollRight()
                } else {
                    this.CorrectLeftPos()
                }
            }
        } else {
            DebugUtils.logTopic(
                ELoggingTopics.lt_ScrollAndZoom,
                `${this.DName}: Cannot restore scroll position, data is not loaded yet or canvas is not ready dataLoaded: ${this.isDataLoaded}`
            )
        }
    }

    public updateIndicatorsForCurrentTimeFrameAndSymbol(): void {
        if (this._mainChart) {
            for (const indicator of this.MainChart.indicators) {
                indicator.onChangeTimeframeOrSymbol(this.getCurrentTimeframe(), this.SymbolData)
            }
        }

        for (const oscWin of this.OscWins) {
            for (const indicator of oscWin.chart.indicators) {
                indicator.onChangeTimeframeOrSymbol(this.getCurrentTimeframe(), this.SymbolData)
            }
        }

        this.observableItem.notify(ChartEvent.UPDATE_SINGLE_CHART, this)
    }

    private setBars(): void {
        this.rememberScrollPosition()
        //just in case setting left pos to 0
        this.LeftPosition = 0
        const newBarsArray = this.SymbolData.EnsureTimeframeIsActive(this.ChartOptions.Timeframe)

        if (!newBarsArray) {
            throw new StrangeError('newBarsArray is null in SetBars.')
        }

        if (this._mainChart && this._mainChart.isDataLoaded) {
            //we get bars from the MainChart and we need to make sure that it has bars so it will make sense to release the events for them
            this.ReleaseEvents(this.Bars)
        }

        //DO NOT FORGET TO RELEASE EVENTS IN THE OLD BARS ARRAY
        newBarsArray.Events.on(TDataArrayEvents.de_MapDownloaded, this.onMapLoaded)
        newBarsArray.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundRepaint)
        newBarsArray.Events.on(TDataArrayEvents.de_SeekCompleted, this.onSeekCompleted)

        if (this._mainChart) {
            this.MainChart.Bars = newBarsArray
        }
        if (this._dateTimeBar) {
            this.TimeBar.Bars = newBarsArray
        }
        for (let i = 0; i < this.OscWins.length; i++) {
            this.OscWins[i].chart.Bars = newBarsArray
        }
        this.UpdateCaption()
        this.restoreScrollPosition()

        this.turnOnLoaderIfNecessary()

        DownloadController.Instance.onTimeframeChanged(this.ChartOptions.Timeframe)
    }

    private turnOnLoaderIfNecessary() {
        if (this.is_HTML_Canvas_initialized() && !this.isAllVisibleChunksLoaded()) {
            this.getChartWindowLayers().manageVisibilityControlsOnLayerByData(
                TDataAvailability.da_NoMapYet,
                this.ChartOptions
            )
            // this.chartWindowLayers.getLayerBlockingChart()?.switchStateTo('loader')
        }
    }

    private is_HTML_Canvas_initialized(): boolean {
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (this._mainChart && this.MainChart.is_HTML_Canvas_initialized()) {
            return true
        }
        return false
    }

    private isAllVisibleChunksLoaded(): boolean {
        if (!this.isDataLoaded) {
            return false
        }
        const range = this.GetVisibleIndexRange()
        const startVisibleIndex = range.start
        const endVisibleIndex = range.end
        const visibleChunks = this.Bars.GetChunksForRangeIndexes(startVisibleIndex, endVisibleIndex)
        for (const chunk of visibleChunks) {
            if (chunk.Status !== TChunkStatus.cs_Loaded) {
                return false
            }
        }
        return true
    }

    private ReleaseEvents(oldBarsArray: IFMBarsArray): void {
        if (oldBarsArray) {
            oldBarsArray.Events.releaseAllEvents()
        }
    }

    public ScrollRight(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `ScrollRight for ${this.DName}`)
        this.SetLeftPosition(Number.MAX_SAFE_INTEGER)
    }

    public getCurrentTimeframe(): number {
        return this.ChartOptions.Timeframe
    }

    protected UpdateCaption(): void {
        let caption = ''
        switch (this._chartType) {
            case TChartType.ct_Normal: {
                caption = `${this.SelectedSymbolName}, ${TimeframeUtils.GetShortTimeframeName(
                    this.ChartOptions.Timeframe
                )}`
                break
            }
            default: {
                throw new StrangeError('UpdateCaption - Unknown chart type')
            }
        }
        //TODO: implement tabs in one-chart layout and display the caption there
    }

    public get preparedToolsForCopyList(): IBasicPaintTool[] {
        return this.preparedToolsForCopy
    }

    public set preparedToolsForCopyList(tools: IBasicPaintTool[]) {
        this.preparedToolsForCopy = tools
    }

    public get currentToolToCopy(): IBasicPaintTool | null {
        return this.fcurrentToolToCopy
    }

    public get currentToolToDeleteLinkNumber(): number {
        if (!this.fcurrentToolToDelete || !this.fcurrentToolToDelete.isLinkNumberSpecified()) {
            return CommonConstants.EMPTY_INDEX
        } else {
            return this.fcurrentToolToDelete.LinkNumber
        }
    }

    public InitChartPtrs(chart: TChart): void {
        chart.InitVars(this.ChartOptions, this.LeftPosition, this.left_X_offset, this.PosMarker, this.fFocusedOrder)
    }

    private onSeekCompleted = this.OnSeekCompleted.bind(this)
    private boundRepaint = this.Repaint.bind(this)
    private onMapLoaded = this.OnMapLoaded.bind(this)

    private OnSeekCompleted() {
        this.restoreScrollPosition()

        GlobalProcessingCore.ProcessingCore.UpdateStatistics()

        this.observableItem.notify(ChartEvent.ON_SEEK_COMPLETED, this)
        this.Repaint()
        //TODO: do we need it here? maybe it will be updated in Repaint?
        this.chartWindowLayers.updateBarInfoLabel()
        this.updateOneClickTradingInfo()
    }

    private OnMapLoaded() {
        for (const indicator of this.MainChart.indicators) {
            indicator.OnMapLoaded()
        }
        this.Repaint()
    }

    public Repaint(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Repaint chart window')
        if (this.isDataLoaded && this.MainChart.is_HTML_Canvas_initialized()) {
            this._throttledRepaint()
        } else {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Painting,
                'Cannot repaint chart window, data is not loaded yet or canvas is not initialized'
            )
        }
    }

    public RepaintWithoutThrottle(): void {
        if (!this.MainChart) {
            DebugUtils.error('MainChart is undefined in Repaint')
            return
        }

        if (!this.TimeBar) {
            DebugUtils.error('TimeBar is undefined in Repaint')
            return
        }

        this.MainChart.Paint()
        this.TimeBar.Paint()
        for (let i = 0; i < this.OscWins.length; i++) {
            this.OscWins[i].chart.Paint()
            this.OscWins[i].splitter.Paint()
        }
        this.needsRedraw = false
    }

    private _throttledRepaint = throttle(() => {
        if (!this.MainChart) {
            DebugUtils.error('MainChart is undefined in Repaint')
            return
        }

        if (!this.TimeBar) {
            DebugUtils.error('TimeBar is undefined in Repaint')
            return
        }

        this.MainChart.Paint()
        this.TimeBar.Paint()
        for (let i = 0; i < this.OscWins.length; i++) {
            this.OscWins[i].chart.Paint()
            this.OscWins[i].splitter.Paint()
        }
        this.needsRedraw = false
    }, 30)

    public SetCursor(CursorStyle: TCursor | TCustomCursor): void {
        this.MainChart.Cursor = CursorStyle

        //TODO: Implement OscWin window SetCursor
        for (let i = 0; i < this.OscWins.length; i++) {
            this.OscWins[i].chart.Cursor = CursorStyle
        }
    }

    public MoveMouse(pt: TPoint): void {
        const fakeMouseEvent = new MouseEvent('mousemove', {
            clientX: pt.x,
            clientY: pt.y
        })
        this.OnMouseMove(fakeMouseEvent, this.MainChart)
    }

    public CreatePaintTool(
        ClassName: string,
        riskRewardType?: TRiskToolType,
        signName?: string,
        signSymbol?: number
    ): boolean {
        if (TChartWindow.cmActiveTool && TChartWindow.cmWindow !== this && TChartWindow.cmWindow) {
            TChartWindow.cmWindow.SetNormalMode()
        }

        this.SetNormalMode()

        const ToolClass = PaintToolManager.GetPaintToolClass(ClassName)
        if (ToolClass === null) {
            DebugUtils.error(`Tool class ${ClassName} not found.`)
            return false
        }

        this.fActiveTool = new ToolClass(this.MainChart)
        ;(this.fActiveTool as TBasicPaintTool).SymbolName = this.SymbolData.symbolInfo.SymbolName
        if (riskRewardType && this.fActiveTool instanceof TPtRiskReward) {
            this.fActiveTool.setRiskToolType = riskRewardType
        }
        if (signSymbol && this.fActiveTool instanceof TPtSign) {
            this.fActiveTool.symbol = signSymbol
        }

        this.fActiveTool.name = signName || ''

        TChartWindow.prepearedMementoForUndoStack = this.MainChart.PaintTools.save()

        this.MainChart.PaintTools.AddTool(this.fActiveTool)

        this.fActiveTool.SetAllTimeframes()

        if (this.fActiveTool.ShortName === PaintToolNames.ptText) {
            this.fActiveTool.ScreenCoords = false
        }

        this.SetCursor(this.fActiveTool.CursorStyle)
        this.fFocusedTool.clear()

        // to transfer tools between charts
        TChartWindow.cmActiveTool = this.fActiveTool
        TChartWindow.cmWindow = this

        if (this._ChartUnderMouse) {
            const result = this._ChartUnderMouse.MouseToLocal()
            this.MoveMouse(new TPoint(result.x, result.y))
        }

        return true
    }

    public pricePicker(tool: IBasicPaintTool, isInitialize = false): void {
        // Assuming cmActiveTool and cmWindow are global or static variables
        if (!isInitialize) {
            tool.status = TPaintToolStatus.ts_InProgress
            TChartWindow.cmActiveTool = tool
            TChartWindow.cmWindow = this
        }

        this.fActiveTool = tool

        this.fActiveTool.SetAllTimeframes()

        this.SetCursor(this.fActiveTool.CursorStyle)
        this.fFocusedTool.tool = this.fActiveTool
        this.fFocusedTool.clear()

        if (isInitialize) {
            this.fActiveTool = null
        }
        if (this._ChartUnderMouse && !isInitialize) {
            const result = this._ChartUnderMouse.MouseToLocal()
            this.MoveMouse(new TPoint(result.x, result.y))
        }
    }

    private CheckIndicatorType(indicator: TRuntimeIndicator): boolean {
        //TODO: check for RangeBars and RenkoBars here when they are implemented
        switch (this._chartType) {
            case TChartType.ct_Normal: {
                return true
            }
            default: {
                throw new StrangeError(`Chart type not supported in CheckIndicatorType ${this._chartType}`)
            }
        }
    }

    public getSplitterUnderMouse(event: MouseEvent): TOscWinSplitter | null {
        for (let i = 0; i < this.OscWins.length; i++) {
            if (this.OscWins[i].splitter.IsMouseInside(event)) {
                return this.OscWins[i].splitter
            }
        }
        return null
    }

    public ChartUnderMouse(): TChart | null {
        if (this.MainChart.IsMouseInside()) {
            this._ChartUnderMouse = this.MainChart
            return this.MainChart
        }

        for (let i = 0; i < this.OscWins.length; i++) {
            if (this.OscWins[i].chart.IsMouseInside()) {
                this._ChartUnderMouse = this.OscWins[i].chart
                return this.OscWins[i].chart
            }
        }

        this._ChartUnderMouse = null

        return null
    }

    private CheckStartMovingScaling(x: number, y: number, sender: TChart): void {
        if (x >= this.MainChart.GetPaintRect().Right) {
            if (sender instanceof TOscChart) {
                return
            }

            // Prepare to scale in vertical direction
            this.SetCursor(TCursor.crSizeNS)
            this.fChartVLocked = true
            this.fChartYPos = y
            this.fChartScale = this.ChartOptions.VerticalScale
        } else if (this.ChartOptions.FixedScale) {
            if (sender instanceof TOscChart) {
                return
            }
            // Prepare to move chart in both directions
            this.SetCursor(TCursor.crSize)
            this.fChartVHLocked = true
            this.fChartXPos = x
            this.fChartYPos = y
            this.fChartPos = this.LeftPosition
            this.fBaseLevel = this.ChartOptions.FixedScaleBaseLevel
        } else {
            // Prepare to move chart horizontally
            this.SetCursor(TCursor.crSizeWE)
            this.fChartLocked = true
            this.fChartXPos = x - this.left_X_offset
            this.fChartPos = this.LeftPosition
        }
    }

    public ProcessMovingScaling(x: number, y: number): void {
        let delta: number, offs: number, dist: number
        let scale: TChartScale

        if (this.fChartLocked || this.fChartVHLocked) {
            // if chart was locked in horizontal direction
            scale = this.MainChart.ChartOptions.GetScaleInfo()

            dist = (x - this.fChartXPos) * GlobalOptions.Options.ScrollingSpeed

            delta = Math.floor(dist / scale.PixBetweenBars)
            offs = DelphiMathCompatibility.Mod(dist, scale.PixBetweenBars)

            if (offs > 0) {
                delta++
                offs = offs - scale.PixBetweenBars
            }

            this.SetLeftPosition(this.fChartPos - delta, true, offs)
        }

        if (this.fChartVLocked) {
            // if chart was locked in vertical direction
            if (!this.ChartOptions.FixedScale) {
                this.SwitchToFixedScale()
            }

            this.ChartOptions.VerticalScale = Math.max(
                0.01,
                Math.min(1000, this.fChartScale - (y - this.fChartYPos) / 5 / 100)
            )
            this.invalidate()
        }

        if (this.fChartVHLocked) {
            // locked in both directions
            delta = y - this.fChartYPos
            this.ChartOptions.FixedScaleBaseLevel =
                this.fBaseLevel + this.MainChart.PixelToPriceDiff(delta) * Math.sign(delta)
            this.invalidate()
        }
    }

    calculateProximityPercentages(): { minProximity: number; maxProximity: number; lastPrice: number } {
        const { minValue, maxValue } = this.MainChart.GetMinMaxOnPriceBorder()
        const lastPrice = this.MainChart.GetLastBarPrice()

        const range = maxValue - minValue

        if (range === 0 || Number.isNaN(minValue) || Number.isNaN(maxValue)) {
            return { minProximity: 100, maxProximity: 100, lastPrice: lastPrice }
        }

        const deviationFromMin = Math.abs(lastPrice - minValue)
        const deviationFromMax = Math.abs(maxValue - lastPrice)

        const minProximity = (deviationFromMin / range) * 100
        const maxProximity = (deviationFromMax / range) * 100

        return { minProximity, maxProximity, lastPrice }
    }

    CenterBarsVertically(): void {
        const { minProximity, maxProximity, lastPrice } = this.calculateProximityPercentages()

        const percentageDifferenceThreshold = 82

        if (minProximity > percentageDifferenceThreshold || maxProximity > percentageDifferenceThreshold) {
            this.fBaseLevel = lastPrice
            this.ChartOptions.FixedScaleBaseLevel = this.fBaseLevel

            this.invalidate()
        }
    }

    public SwitchToNormalScale(): void {
        this.ChartOptions.FixedScale = false
        this.ChartOptions.VerticalScale = 1
        this.invalidate()
    }

    public SwitchToFixedScale(): void {
        this.ChartOptions.FixedScaleBaseLevel = this.MainChart.GetMediumPriceLevel()
        this.ChartOptions.FixedVScale = this.MainChart.VScale
        this.ChartOptions.VerticalScale = 1
        this.ChartOptions.FixedScale = true

        this.invalidate()
    }

    public FreeActiveTool(): void {
        if (this.fActiveTool !== null) {
            this.MainChart.PaintTools.DeleteTool(this.fActiveTool)
            this.fActiveTool = null
            TChartWindow.cmActiveTool = null
            TChartWindow.cmWindow = null
        }
    }

    public SetNormalMode(): void {
        const { setInfo } = ToolInfoStore
        setInfo((prevState) => ({ ...prevState, isCrosshairMode: false }))

        this.FreeActiveTool()

        this._cursorMode = TCursorMode.cm_Default
        this.SetCursor(TCursor.crArrow)
        this.invalidate()
    }

    public getMagnetPointLocal(ignoreStrongMagnetMode?: boolean): TPoint {
        const p = this.getPriceDatePointUnderMouse()
        return new TPoint(p.x, this.MainChart.MagnetPoint(p.x, p.y, ignoreStrongMagnetMode))
    }

    public GetBarDateUnderMouse(approximationAllowed = true): TDateTime {
        return this.MainChart.GetBarDateFromX(this.fMousePos.x, false, approximationAllowed)
    }

    public GetPriceUnderMouse(): number {
        return this.MainChart.GetPriceFromY(this.fMousePos.y)
    }

    public getPriceDatePointUnderMouse(): TPoint {
        return new TPoint(this.GetBarDateUnderMouse(), this.GetPriceUnderMouse())
    }

    private AdjustDegree(x0: number, y0: number, x1: number, y1: number): [number, number] {
        const dx = x1 - x0
        const dy = y1 - y0
        let angle: number
        let scale: number

        if (dx === 0) {
            x1 = x0
        } else {
            angle = Math.abs(dy / dx)
            if (angle > 2) {
                x1 = x0
            } else if (angle < 0.5) {
                y1 = y0
            } else {
                scale = Math.abs(dx)
                x1 = x0 + scale * Math.sign(dx)
                y1 = y0 + scale * Math.sign(dy)
            }
        }

        return [x1, y1]
    }

    private AdjustActiveToolDegree(
        chart: TChart,
        x: number,
        y: number,
        time: TDateTime,
        price: number
    ): [TDateTime, number] {
        if (
            GlobalOptions.Options.TempRotateMode &&
            this.fActiveTool &&
            (this.fActiveTool.ToolType === TPaintToolType.tt_Line ||
                this.fActiveTool.ToolType === TPaintToolType.tt_Ray) &&
            this.fActiveTool.points.Count === 2
        ) {
            x = chart.GetXFromDate(time)
            ;[x, y] = this.AdjustDegree(this.fActiveTool.points[0].x, this.fActiveTool.points[0].y, x, y)

            time = chart.GetBarDateFromX(x, false)
            price = chart.GetPriceFromY(y)
        }

        return [time, price]
    }

    public UpdateTemplate(): void {
        //TODO: implement Templates mechanism
        // const template: TTemplate = this.fTFConfig.GetTemplate(this.ChartOptions.Timeframe);
        // this.SaveTemplate(template);
    }

    protected ProcessFocusedToolMove(relativeX_param: number, relativeY: number, shiftState: TShiftState): void {
        if (!this.fFocusedTool.dragged) {
            this.saveStateWithNotify()
        }
        if (
            this.fPrepareToClone &&
            DelphiFormsBuiltIns.ShiftStateIncludes(shiftState, TShiftState.ssCtrl) &&
            !this.fFocusedTool.empty()
        ) {
            // copy tool(s) if ctrl pressed
            this.fPrepareToClone = false

            if (this.fFocusedTool.chart) {
                this.fFocusedTool.chart.PaintTools.DuplicateSelectedTools(this.fFocusedTool.tool)
                this.fFocusedTool.duplicate()

                if (this.fFocusedTool.chart instanceof TMainChart) {
                    this.preparedToolsForCopy = this.fFocusedTool.chart.PaintTools.GetSelectedTools()

                    this.observableItem.notify(ChartEvent.COPY_PAINT_TOOLS_TO_OTHER_CHARTS, this)
                    this.preparedToolsForCopy = []
                }
            }

            this.UpdateTemplate() // Assuming UpdateTemplate is implemented
            this.invalidate()
        }

        if (this.fFocusedTool.tool && !this.fFocusedTool.tool.fLocked) {
            // Conversion of the Delphi code block within with fFocusedTool do
            let dateTime_mouse = this.fFocusedTool.tool.chart.GetBarDateFromX(relativeX_param, false)
            let price_mouse = this.fFocusedTool.tool.chart.GetPriceFromY(relativeY)
            const differeceInIndexes =
                this.fFocusedTool.tool.chart.GetIndexFromX(relativeX_param, false) - this.fFocusedTool.start_index

            if (
                (this.fFocusedTool.tool.ShortName !== PaintToolNames.ptBrush &&
                    this.fFocusedTool.FocusType === TFocusType.ft_Point) ||
                this.fFocusedTool.tool.ShortName === PaintToolNames.ptHLine
            ) {
                // Magnet price
                if (GlobalOptions.Options.MagneticMode && this.fFocusedTool.tool.chart === this.MainChart) {
                    // drawn tool magnet
                    price_mouse = this.MainChart.MagnetPoint(dateTime_mouse, price_mouse)
                }

                // Adjust point to 0 - 45 - 90 degrees
                if (
                    GlobalOptions.Options.TempRotateMode &&
                    [TPaintToolType.tt_Line, TPaintToolType.tt_Ray].includes(this.fFocusedTool.tool.ToolType)
                ) {
                    const p_index = this.fFocusedTool.index === 1 ? 0 : 1

                    let relativeX = this.fFocusedTool.tool.chart.GetXFromDate(dateTime_mouse)
                    // Assuming AdjustDegree is implemented
                    ;[relativeX, relativeY] = this.AdjustDegree(
                        this.fFocusedTool.tool.points[p_index].x,
                        this.fFocusedTool.tool.points[p_index].y,
                        relativeX,
                        relativeY
                    )
                    dateTime_mouse = this.fFocusedTool.tool.chart.GetBarDateFromX(relativeX, false)
                    price_mouse = this.fFocusedTool.tool.chart.GetPriceFromY(relativeY)
                }

                // Handling movement of the tool
                if (this.fFocusedTool.tool.ShortName === PaintToolNames.ptHLine) {
                    this.fFocusedTool.tool.OnMovePoint(0, dateTime_mouse, price_mouse)
                } else {
                    this.fFocusedTool.tool.OnMovePoint(this.fFocusedTool.index, dateTime_mouse, price_mouse)
                }
            } else {
                // Moving selected tools
                const chart = this.fFocusedTool.tool.chart as TChart
                chart.PaintTools.MoveSelectedTools(differeceInIndexes, price_mouse - this.fFocusedTool.price)
            }

            // Update linked tools
            // FIXME: Assuming PostMessage and MainFormHandle are available
            // PostMessage(MainFormHandle, msg_UpdateLinkedTools, 0, this);
            this.observableItem.notify(ChartEvent.UPDATE_LINKED_TOOLS, this)

            this.fFocusedTool.dragged = true
        }
    }

    public notifyCopyPaintToolsToOtherCharts() {
        this.observableItem.notify(ChartEvent.COPY_PAINT_TOOLS_TO_OTHER_CHARTS, this)
    }

    //TODO: check if we use correct coordinates throughout the whole project. We need to use const relativeCoordinates = this.MainChart.MouseToLocal(event);
    private ProcessActiveToolMove(relativeX: number, relativeY: number): void {
        let time: TDateTime
        let price: number
        let chart: TChart | null = this.ChartUnderMouse() /*this.GetChartUnderMouseXY(relativeX, relativeY)*/

        if (chart) {
            if (TChartWindow.cmActiveTool && TChartWindow.cmWindow !== this) {
                if (TChartWindow.cmActiveTool.ToolType === TPaintToolType.tt_VLine) {
                    chart = this.MainChart
                }

                if (TChartWindow.cmWindow) {
                    TChartWindow.cmWindow.fActiveTool = null
                    TChartWindow.cmWindow.SetCursor(TCursor.crDefault)
                    TChartWindow.cmWindow.invalidate()
                }

                this.fActiveTool = TChartWindow.cmActiveTool
                this.SetCursor(this.fActiveTool.CursorStyle)
                TChartWindow.cmWindow = this
                this.ChangeActiveToolChart(chart)
                this.invalidate()
                this.observableItem.notify(ChartEvent.CHARTS_NEED_REDRAW, this)
            }

            if (this.fActiveTool && !this.fActiveTool.fLocked) {
                const customCursor = this.fActiveTool.CursorStyle as TCustomCursor
                if (customCursor.path === '') {
                    this.SetCursor(TCursor.crCross)
                } else {
                    this.SetCursor(this.fActiveTool.CursorStyle)
                }
                if (
                    this.fActiveTool.ToolType !== TPaintToolType.tt_VLine &&
                    this.fActiveTool.status === TPaintToolStatus.ts_FirstPoint &&
                    chart !== this.fActiveTool.chart
                ) {
                    this.ChangeActiveToolChart(chart)
                }

                if (chart !== this.fActiveTool.chart || !chart.CanBePainted) return

                time = chart.GetBarDateFromX(relativeX, false)
                price = chart.GetPriceFromY(relativeY)

                console.log('GlobalOptions.Options.MagneticMode', GlobalOptions.Options.MagneticMode)
                if (GlobalOptions.Options.MagneticMode && chart === this.MainChart) {
                    // drawn tool magnet
                    price = chart.MagnetPoint(time, price)
                }
                ;[time, price] = this.AdjustActiveToolDegree(chart, relativeX, relativeY, time, price)
                this.fActiveTool.OnMouseMove(time, price, chart)
                this.invalidate()
                this.observableItem.notify(ChartEvent.CHARTS_NEED_REDRAW, this)
            }
        } else {
            DebugUtils.error('ProcessActiveToolMove chart is null')
        }
    }

    public applyStrongMagnetMode(): void {
        const chart: TChart | null = this.ChartUnderMouse()
        if (!chart || chart instanceof TOscChart) {
            return
        }
        const time = this.MainChart.GetBarDateFromX(this.fMousePos.x)
        const price = this.MainChart.GetPriceFromY(this.fMousePos.y)
        const magneticPrice = chart.MagnetPoint(time, price)
        if (this.fActiveTool) {
            this.fActiveTool.OnMouseMove(time, magneticPrice, this.MainChart)
        }

        if (
            this.fFocusedTool.tool &&
            !this.fFocusedTool.tool.fLocked &&
            this.fFocusedTool.tool.HighlightedPoint !== -1 &&
            this.fFocusedTool.dragged
        ) {
            const endPrice = GlobalOptions.Options.StrongMagnetMode ? magneticPrice : price
            this.fFocusedTool.tool.OnMovePoint(this.fFocusedTool.index, time, endPrice)
        }
        this.invalidate()
    }

    public updateToolDeegree(): void {
        if (
            this.fActiveTool &&
            (this.fActiveTool.ToolType === TPaintToolType.tt_Line ||
                this.fActiveTool.ToolType === TPaintToolType.tt_Ray)
        ) {
            let time = this.MainChart.GetBarDateFromX(this.fMousePos.x, false)
            let price = this.MainChart.GetPriceFromY(this.fMousePos.y)

            ;[time, price] = this.AdjustActiveToolDegree(
                this.MainChart,
                this.fMousePos.x,
                this.fMousePos.y,
                time,
                price
            )
            this.fActiveTool.OnMouseMove(time, price, this.MainChart)
            this.invalidate()
        }

        if (
            this.fFocusedTool.tool &&
            (this.fFocusedTool.tool.ToolType === TPaintToolType.tt_Line ||
                this.fFocusedTool.tool.ToolType === TPaintToolType.tt_Ray) &&
            this.fFocusedTool.tool.HighlightedPoint !== -1
        ) {
            const p_index = this.fFocusedTool.index === 1 ? 0 : 1
            let relativeX = this.fMousePos.x
            let relativeY = this.fMousePos.y

            let dateTime_mouse = this.fFocusedTool.tool.chart.GetBarDateFromX(relativeX, false)
            let price_mouse = this.fFocusedTool.tool.chart.GetPriceFromY(relativeY)
            relativeX = this.fFocusedTool.tool.chart.GetXFromDate(dateTime_mouse)

            if (
                GlobalOptions.Options.TempRotateMode &&
                this.fFocusedTool &&
                (this.fFocusedTool.tool.ToolType === TPaintToolType.tt_Line ||
                    this.fFocusedTool.tool.ToolType === TPaintToolType.tt_Ray) &&
                this.fFocusedTool.tool.points.Count === 2
            ) {
                ;[relativeX, relativeY] = this.AdjustDegree(
                    this.fFocusedTool.tool.points[p_index].x,
                    this.fFocusedTool.tool.points[p_index].y,
                    relativeX,
                    relativeY
                )
                dateTime_mouse = this.fFocusedTool.tool.chart.GetBarDateFromX(relativeX, false)
                price_mouse = this.fFocusedTool.tool.chart.GetPriceFromY(relativeY)
            }

            this.fFocusedTool.tool.OnMovePoint(this.fFocusedTool.index, dateTime_mouse, price_mouse)
            this.invalidate()
        }
    }

    public getPriceOfCurrentMousePosition(): number {
        return this.MainChart.GetPriceFromY(this.fMousePos.y)
    }

    GetChartUnderMouse(event: MouseEvent): TChart | null {
        const relativeCoordinates = this.MainChart.MouseToLocal(event)

        const relativeX = relativeCoordinates.x // X relative to chartElement
        const relativeY = relativeCoordinates.y // Y relative to chartElement

        return this.GetChartUnderMouseXY(relativeX, relativeY)
    }

    GetChartUnderMouseXY(relativeX: number, relativeY: number): TChart | null {
        if (this.MainChart.IsMouseInsideXY(relativeX, relativeY)) {
            return this.MainChart
        }

        //TODO: Process OSC windows here

        return null
    }

    public ChangeActiveToolChart(NewChart: TChart): void {
        if (this.fActiveTool && this.fActiveTool.chart && this.fActiveTool.isNeedToTransferBetweenCharts) {
            if (this.fActiveTool.ToolType === TPaintToolType.tt_OrderMarker) {
                return
            }
            TChartWindow.prepearedMementoForUndoStack = NewChart.PaintTools.save()
            const activeChart = this.fActiveTool.chart as TChart
            activeChart.PaintTools.TransferTool(this.fActiveTool as TBasicPaintTool, NewChart)
            this.fActiveTool.chart = NewChart
        }
    }

    public OnMouseMove(m_event: MouseEvent, sender: TChart): void {
        if (!this.isDataLoaded) {
            return
        }

        if (!this.SymbolData.isCurrentTestingDateInAvailableRange) {
            this.processMouseMoveForLayers(m_event, sender)
            return
        }

        if (
            TChartWindow.cmActiveTool &&
            TChartWindow.cmActiveTool.ToolType === TPaintToolType.tt_OrderMarker &&
            TChartWindow.cmActiveTool.chart !== this.MainChart
        ) {
            TChartWindow.cmActiveTool.OnMouseMove(0, 0, this.MainChart)
            return
        }
        GlobalChartsController.Instance.enableTimeZone()

        const chartControlHandled = this.controlsManager.onMouseMove(m_event, sender as TMainChart)

        if (chartControlHandled) {
            GlobalChartsController.Instance.updateCharts()
            this.isNeedRepaintAfterEventsInsideControlsManager = true
        }

        if (this.isNeedRepaintAfterEventsInsideControlsManager) {
            GlobalChartsController.Instance.updateCharts()
            this.isNeedRepaintAfterEventsInsideControlsManager = false
        }

        this.chartWindowLayers.updateBarInfoLabel()

        const layersControlHandled = this.processMouseMoveForLayers(m_event, sender)

        if (
            (chartControlHandled && !(chartControlHandled instanceof CrosshairControl)) ||
            (layersControlHandled && !(layersControlHandled instanceof CrosshairControl))
        ) {
            this.chartWindowLayers.getCrosshairControl().hide()
        }

        if (!this.Bars.LastItemInTestingIndexAvailable) {
            return
        }

        if (!chartControlHandled && !layersControlHandled && !crosshairManager.isCrosshairEnabled()) {
            this.SetCursor(TCursor.crDefault)
        }

        const relativeCoordinates = sender.MouseToLocal(m_event)

        const relativeX = relativeCoordinates.x // X relative to chartElement
        const relativeY = relativeCoordinates.y // Y relative to chartElement

        this.processOscMouseMove(m_event, sender)

        this.fMousePos.SetLocation(relativeX, relativeY)

        const chart = sender // Assuming sender is of type TChart

        try {
            if (TChartWindow.cmActiveTool || this.fActiveTool) {
                this.ProcessActiveToolMove(relativeX, relativeY)
                return
            }

            if (this.fFocusedTool.focused) {
                const shiftState = DelphiFormsBuiltIns.ExtractTShiftState(m_event)
                const { x: localX, y: localY } = chart.MouseToLocal(m_event)
                this.ProcessFocusedToolMove(localX, localY, shiftState)
                return
            }

            // Check various mouse interactions
            this.processMouseInteractions(chart, m_event, relativeX, relativeY)

            // Process moving/scaling of chart
            this.ProcessMovingScaling(relativeX, relativeY)
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
        } finally {
            this.Update()
        }
    }

    private processMouseMoveForLayers(m_event: MouseEvent, sender: TChart): ChartControl | undefined {
        const manager = this.chartWindowLayers.getManagerLayers()

        const control = manager.onMouseMove(m_event, sender)
        return control ?? undefined
    }

    private processMouseInteractions(chart: TChart, m_event: MouseEvent, relativeX: number, relativeY: number): void {
        if (
            !(
                this.fChartLocked ||
                this.fChartVLocked ||
                this.fChartVHLocked ||
                this._cursorMode !== TCursorMode.cm_Default
            )
        ) {
            const toolProcessed = this.prcessPaintToolUnderMouse(chart, relativeX, relativeY)
            //do not process other things if the tools if found
            if (toolProcessed) {
                return
            }
            const indicatorProcessed = this.processIndicatorUnderMouse(chart, m_event, chart as TMainChart | TOscChart)
            if (indicatorProcessed) {
                return
            }
            //TODO: process Notes under mouse CheckNotesUnderMouse in Delphi
            this.processNewsUnderMouseOnMouseMove(chart, m_event)
        }
    }

    public processNewsUnderMouseOnMouseMove(chart: TChart, event: MouseEvent): boolean {
        if (chart instanceof TMainChart) {
            const point = this.MainChart.MouseToLocal(event)

            if (!GlobalNewsController.Instance.checkIfMouseOverNews(point.x, point.y)) {
                return false
            }

            const news = (chart as TMainChart).getNewsUnderMouse(event)

            if (news) {
                this.Repaint()
                return true
            }
        }

        return false
    }

    public processIndicatorUnderMouse(chart: TChart, event: MouseEvent, sender: TMainChart | TOscChart): boolean {
        let indicator: TRuntimeIndicator | null
        let buffer: IVisibleIndexBuffer | null
        let value: number | null
        let SelValue: TSelIndValue

        if (event.type === 'mousedown' || event.type === 'mouseleave') {
            TChart.SelIndValue = null
            return false
        }

        let doRepaint = false
        if (sender instanceof TOscChart) {
            this.processOscMouseMove(event, sender)
        }
        // find indicator under mouse
        ;[buffer, value, indicator] = chart.GetIndicatorUnderMouse(event)

        let result = false
        if (indicator !== null && buffer !== null && value !== null && buffer.IsVisible()) {
            SelValue = new TSelIndValue()
            SelValue.init(indicator, buffer, chart.GetMouseIndex(), value)
            if (!TChart.SelIndValue || !TChart.SelIndValue?.equalTo(SelValue)) {
                //TODO: Implement a mechanism to keep the hint visible if needed.
                TChart.SelIndValue = SelValue
                TChart.SelIndValue.sender = sender
                doRepaint = true
            } else {
                // do nothing
            }

            result = true
        } else {
            if (TChart.SelIndValue !== null) {
                TChart.SelIndValue = null
                doRepaint = true
            }
        }
        if (doRepaint && !this.needsRedraw) {
            this.Repaint()
        }
        return result
    }

    public GetVisibleIndexRange(): INumberRange {
        return this.MainChart.GetVisibleIndexRange_excludingEmptySpace()
    }

    public AddIndicatorToChart(
        chart: TChart,
        indicator: TRuntimeIndicator,
        oscControl: OscIndicatorConfigurationControl | null = null
    ): void {
        // Ensure that both chart and indicator are not null or undefined before proceeding.
        if (!chart || !indicator) {
            throw new StrangeError('Invalid arguments: chart and indicator must not be null or undefined.')
        }

        indicator.Chart = chart

        chart.indicators?.AddIndicator(indicator)

        if (oscControl) {
            indicator.oscConfigurationControl = oscControl
        }
    }

    public CreateIndicatorAndRecount(indicator: TRuntimeIndicator, tf?: number, recount?: boolean): TRuntimeIndicator {
        // Ensure that the indicator has the necessary methods before calling them.
        indicator.ImportData()

        indicator.SaveOptions()
        indicator.RefreshLevels()
        indicator.RefreshOptions()

        if (indicator.OutputWindow === TOutputWindow.ow_ChartWindow) {
            this.AddIndicatorToChart(this.MainChart, indicator)

            // create indicator configuration control
            if (this.chartWindowLayers.getLayerChartControls()) {
                this.chartWindowLayers.createIndicatorControl(this.chartWindowLayers.getLayerChartControls(), indicator)
                this.chartWindowLayers.getLayerChartControls().draw()
            } else {
                DebugUtils.error('Layer cannot create for indicatorConfigurationControl')
            }
        } else {
            const oscWindow = this.AddOscillatorWindow()
            const oscControl = this.createOscIndicatorControl(this, oscWindow, indicator)
            if (indicator.IsVisible()) {
                oscWindow.chart.onIndicatorShow()
                oscWindow.expandOscWin()
            } else {
                oscWindow.chart.onIndicatorHide()
                oscWindow.collapseOscWin()
            }
            this.AddIndicatorToChart(oscWindow.chart, indicator, oscControl)
        }

        this.Repaint()

        return indicator
    }

    RecountIndicator(indicator: TRuntimeIndicator): void {
        const range = this.MainChart.GetVisibleIndexRange_excludingEmptySpace()
        indicator.clearBuffers()
        indicator.RecountValuesForTheRange(range.start, range.end)
    }

    public DoEditIndicator(indicator: TRuntimeIndicator): void {
        indicator.ImportData()
        indicator.RefreshLevels()
        indicator.RefreshOptions()
        indicator.SaveOptions()
        this.RecountIndicator(indicator)
        this.Repaint()
        this.chartWindowLayers.getManagerLayers().reDrawAll()
    }

    public copySettingsToAllSameIndicators(indicator: TRuntimeIndicator): void {
        for (const indicatorUnit of this.MainChart.indicators) {
            if (indicator === indicatorUnit) {
                continue
            }
            if (!indicator.isSame(indicatorUnit)) {
                continue
            }

            this.DoEditIndicator(indicatorUnit)
        }

        for (const oscWin of this.OscWins) {
            for (const indicatorUnit of oscWin.chart.indicators) {
                if (indicator === indicatorUnit) {
                    continue
                }
                if (!indicator.isSame(indicatorUnit)) {
                    continue
                }

                this.DoEditIndicator(indicatorUnit)
            }
        }
    }

    public CreateIndicator(indicator_descriptor: IndicatorDescriptor, tf: number): TRuntimeIndicator {
        //TODO: change this (maybe use something else instead of the name?)
        let new_indicator_instance: TRuntimeIndicator | null = null

        if (indicator_descriptor.isCustom) {
            new_indicator_instance = GlobalCustomIndicatorsManager.getInstance(store).createRuntimeIndicator(
                indicator_descriptor,
                this.SymbolData,
                tf,
                this._chartType
            )
        } else {
            new_indicator_instance = RuntimeIndicatorFactory.CreateRuntimeIndicator(
                indicator_descriptor,
                this.SymbolData,
                tf,
                this._chartType
            )
        }

        if (new_indicator_instance) {
            //TODO: should we init indicator here or later???
            new_indicator_instance.InitializeTheIndicator()

            if (!this.SymbolData.Indicators) {
                throw new StrangeError('Symbol.Indicators is null or undefined.')
            }

            return new_indicator_instance
        } else {
            throw new StrangeError('Failed to create indicator.')
        }
    }

    public OnToolCompletedWork(): void {
        const { setInfo } = ToolInfoStore
        setInfo((prevState) => ({ ...prevState, isDrawing: false }))

        DebugUtils.log('OnToolCompletedWork')

        const tool: IBasicPaintTool | null = this.fActiveTool
        this.fActiveTool = null

        this.observableItem.notify(ChartEvent.PAINT_COMPLETE, this)

        this.DeselectAllTools()

        if (tool) {
            tool.Selected = true
        }

        this.invalidate()

        this.fToolToEdit = tool
        if (tool && tool.ShortName === PaintToolNames.ptText && tool.EditTool()) {
            // Assuming ptDefaults and its UpdateSettings method are implemented
            //TODO: ptDefaults.UpdateSettings(tool);
        }

        if (tool && tool.ShortName === PaintToolNames.ptMeasure) {
            this.fcurrentToolToDelete = tool
        }

        if (tool && tool.ShortName === PaintToolNames.ptNote) {
            ;(tool.chart as TChart).PaintTools.DeleteTool(tool)
        } else {
            if (tool && tool.isNeededToCopyToOtherCharts && tool.chart instanceof TMainChart) {
                //TODO: PostMessage(MainFormHandle, msg_CopyPaintTool, integer(tool), integer(self));
                this.saveStateWithNotify(true)

                this.fcurrentToolToCopy = tool
                this.observableItem.notify(ChartEvent.COPY_PAINT_CURRENT_TOOL, this)
                this.fcurrentToolToCopy = null
            }
        }

        const { updateParams } = GraphToolPanelStore
        if (tool?.ToolType !== TPaintToolType.tt_OrderMarker) {
            updateParams((prevState) => ({
                ...prevState,
                isOpen: prevState.isOpen || true,
                tools: [tool]
            }))
        }
    }

    public OnToolCancelledWork(): void {
        const { setInfo } = ToolInfoStore
        setInfo((prevState) => ({ ...prevState, isDrawing: false }))

        TChartWindow.cmActiveTool = null
        this.fToolToEdit = null
        this.fActiveTool = null
        this.fcurrentToolToDelete = null
        this.fcurrentToolToCopy = null
        this.fPrepareToClone = false
        this.fFocusedTool.clear()
        this.focused = false
        this.observableItem.notify(ChartEvent.PAINT_COMPLETE, this)

        this.invalidate()
    }

    public prcessPaintToolUnderMouse(chart: TChart, relativeX: number, relativeY: number): boolean {
        let s: string
        let flag: boolean
        let index: number

        // find tool under mouse

        const tool = chart.PaintTools.ToolUnderMouse(relativeX, relativeY)

        if (tool) {
            TChart.SelIndValue = null

            // if there is a tool under mouse
            flag = false

            if (this.fFocusedTool.tool !== tool) {
                // if this is new tool
                this.fFocusedTool.update(tool)

                // show new hint
                s = tool.ShortName
                if (tool.description !== '') {
                    s = `${s}\n${tool.description}`
                }

                flag = true
            }

            if (tool.ReadyToSize(relativeX, relativeY)) {
                // move by points
                index = tool.PointUnderMouse(relativeX, relativeY)
                flag = flag || this.fFocusedTool.UpdateHighlightedPoint(index)
                if (this.fFocusedTool.tool?.ShortName === PaintToolNames.ptBrush) {
                    this.SetCursor(TCursor.crMultiDrag)
                } else {
                    this.SetCursor(TCursor.crDefault)
                }
            } else {
                // move by edges
                if (!tool.fLocked) {
                    if (tool.ToolType === TPaintToolType.tt_OrderMarker) {
                        this.SetCursor(CustomCursorPointers.crPicker)
                    } else {
                        this.SetCursor(TCursor.crMultiDrag) //todo change this cursor to custom for moving paint tool
                    }
                }
                flag = flag || this.fFocusedTool.UpdateHighlightedPoint(-1)
            }

            if (flag) {
                chart.invalidate()
            }

            return true
        } else {
            // if no paint tool under mouse
            if (!this.fFocusedTool.empty()) {
                this.fFocusedTool.clear()
                chart.invalidate()
                this.SetCursor(TCursor.crDefault)
            }

            return false
        }
    }

    public onIndicatorDeselect(): void {
        if (TChart.SelectedIndicator !== null) {
            const { VisibleBuffers } = TChart.SelectedIndicator // Equivalent to Delphi's 'with indicator do'
            const runTimeIndicator = TChart.SelectedIndicator as TRuntimeIndicator
            if (runTimeIndicator) {
                runTimeIndicator.configurationControl?.onIndicatorDeselect()
                runTimeIndicator.configurationControl?.adjustControlWidth()
                this.observableItem.notify(ChartEvent.UPDATE_ALL_LAYERS, this)
            }

            for (const buff of VisibleBuffers) {
                // skip invisible buffers
                // if (!buff.buffer || !buff.buffer.HasSomeValues()) {
                //     continue
                // }
                buff.onIndicatorUnselected()
                buff.selectionMarkers.selectionMarkersIndexes = []
            }
        }
        TChart.SelectedIndicator = null
    }

    public onIndicatorSelect(indicator: TRuntimeIndicator): void {
        TChart.SelectedIndicator = indicator
        const { VisibleBuffers } = indicator // Equivalent to Delphi's 'with indicator do'
        const runTimeIndicator = TChart.SelectedIndicator as TRuntimeIndicator
        if (runTimeIndicator) {
            runTimeIndicator.configurationControl?.onIndicatorSelect()
            runTimeIndicator.configurationControl?.adjustControlWidth(true)
            this.observableItem.notify(ChartEvent.UPDATE_ALL_LAYERS, this)
        }

        for (const buff of VisibleBuffers) {
            // skip invisible buffers
            // if (!buff.buffer || !buff.buffer.HasSomeValues()) {
            //     continue
            // }
            if (!buff.IsSelected()) {
                buff.selectionMarkers.step = this.GetStepForSelectionMarkers()
            }
            buff.onIndicatorSelected()
        }
    }

    private processMouseDownForChartControls(m_event: MouseEvent, sender: TMainChart): boolean {
        if (this.controlsManager.onMouseDown(m_event, sender)) {
            GlobalChartsController.Instance.updateCharts()
            this.isNeedRepaintAfterEventsInsideControlsManagerOnMouseDown = true
            return true
        }

        if (this.isNeedRepaintAfterEventsInsideControlsManagerOnMouseDown) {
            GlobalChartsController.Instance.updateCharts()
            this.isNeedRepaintAfterEventsInsideControlsManagerOnMouseDown = false
        }
        return false
    }

    private processMouseDownForLayers(m_event: MouseEvent, sender: TChart): boolean {
        if (this.chartWindowLayers && this.chartWindowLayers.getManagerLayers()) {
            const control = this.chartWindowLayers.getManagerLayers().onMouseDown(m_event, sender)
            if (control) {
                control.getOwnerLayer()?.draw()
                return true
            }
        }
        return false
    }

    public OnMouseDown(m_event: MouseEvent, sender: TChart): void {
        if (!this.isDataLoaded) {
            DebugUtils.error('Data is not loaded, cannot process mouse event')
            return
        }

        let buffer: IVisibleIndexBuffer | null
        let value: number | null

        if (!this.SymbolData.isCurrentTestingDateInAvailableRange) {
            this.processMouseDownForLayers(m_event, sender)
            return
        }

        //TODO: can we do this after processing layers?
        if (this.processMouseDownForChartControls(m_event, sender as TMainChart)) {
            return
        }

        if (this.processMouseDownForLayers(m_event, sender)) {
            return
        }

        if (this.fSkipOneMouseDown) {
            this.fSkipOneMouseDown = false
            return
        }

        if (!this.focused) {
            this.observableItem.notify(ChartEvent.ACTIVATE, this)
        }

        const chart = this.ChartUnderMouse()
        if (!chart) {
            throw new StrangeError('chart is null in OnMouseDown')
        }

        const button = DelphiFormsBuiltIns.ExtractTMouseButton(m_event)
        const shiftState = DelphiFormsBuiltIns.ExtractTShiftState(m_event)
        const isLeftClick = button === TMouseButton.mbLeft
        const isMMBClick = button === TMouseButton.mbMiddle

        const relativeCoordinates = sender.MouseToLocal(m_event)

        const relativeX = relativeCoordinates.x
        const relativeY = relativeCoordinates.y

        const toolUnderMouse = this.MainChart.PaintTools.ToolUnderMouse(relativeX, relativeY, false)
        const isCtrlPressed = DelphiFormsBuiltIns.ShiftStateIncludes(shiftState, TShiftState.ssCtrl)

        this.fToolToEdit = toolUnderMouse

        if (isCtrlPressed && !toolUnderMouse?.Selected) {
            this.fFocusedTool.dragged = true
            const { updateParams } = GraphToolPanelStore

            if (toolUnderMouse?.ToolType !== TPaintToolType.tt_OrderMarker) {
                updateParams((prevState) => {
                    const isToolIncluded = prevState.tools.find((tool) => tool.fToolName === toolUnderMouse?.ToolName)

                    const toolsList =
                        !isToolIncluded && !!toolUnderMouse ? [...prevState.tools, toolUnderMouse] : prevState.tools

                    return {
                        ...prevState,
                        isOpen: toolsList.length > 0,
                        tools: toolsList
                    }
                })
            }

            this.onIndicatorDeselect()
            this.Repaint()
        }
        if (isCtrlPressed && toolUnderMouse?.Selected) {
            this.fFocusedTool.dragged = true
            const { updateParams } = GraphToolPanelStore

            if (toolUnderMouse?.ToolType !== TPaintToolType.tt_OrderMarker) {
                updateParams((prevState) => {
                    const toolsList = prevState.tools.filter((tool) => tool.ToolName !== toolUnderMouse?.ToolName)

                    return {
                        ...prevState,
                        isOpen: toolsList.length > 0,
                        tools: toolsList
                    }
                })
            }

            this.onIndicatorDeselect()
            this.Repaint()
        }

        TimeframeStore.setTimeframe(chart.ChartOptions.Timeframe)

        // process active tool first

        if (this.fcurrentToolToDelete !== null) {
            this.MainChart.PaintTools.DeleteTool(this.fcurrentToolToDelete)
            for (const oscWin of this.OscWins) {
                oscWin.chart.PaintTools.DeleteTool(this.fcurrentToolToDelete)
            }
            this.fcurrentToolToDelete = null
            this.observableItem.notify(ChartEvent.UPDATE_SINGLE_CHART, this)
        }

        if (this.fActiveTool !== null) {
            this.ProcessActiveToolMouseDown(relativeX, relativeY, button)
            return
        }

        if (this.processOscIndicatorControlMouseDown(m_event, sender)) {
            return
        }

        let indicator
        ;[buffer, value, indicator] = chart.GetIndicatorUnderMouse(m_event)
        if (indicator !== null && buffer !== null && value !== null) {
            if (TChart.SelectedIndicator === null) {
                this.onIndicatorSelect(indicator)
                this.Repaint()
            } else {
                if (TChart.SelectedIndicator === indicator) {
                    //do nothing
                } else {
                    this.onIndicatorDeselect()
                    this.onIndicatorSelect(indicator)
                    this.Repaint()
                }
            }
        } else {
            this.onIndicatorDeselect()
            this.Repaint()
        }
        if (this._cursorMode === TCursorMode.cm_Default && isLeftClick) {
            const { updateParams, resetStore } = GraphToolPanelStore
            // Check focused tool
            if (
                this.CheckFocusedTool(chart, relativeX, relativeY, shiftState) && // NOTE: tool selection with click
                !isCtrlPressed
            ) {
                const selectedTool = this.MainChart.PaintTools.ToolUnderMouse(relativeX, relativeY, false)

                if (selectedTool?.ToolType !== TPaintToolType.tt_OrderMarker) {
                    updateParams((prevState) => {
                        const isToolSelected = prevState.tools.find((tool) => tool.ToolName === selectedTool?.ToolName)

                        return {
                            ...prevState,
                            isOpen: true,
                            tools: isToolSelected ? prevState.tools : [selectedTool]
                        }
                    })
                }

                this.onIndicatorDeselect()
                this.Repaint()
                return
            }

            // If no focused tool and there are selections - remove selections
            if (this.fFocusedTool.empty() && chart.PaintTools.AreThereSelected()) {
                chart.PaintTools.DeselectAllTools()
                this.invalidate() // Assuming 'invalidate' is a method defined in the class or inherited
                resetStore()
            }
        }

        if (
            (this._cursorMode === TCursorMode.cm_Default || this._cursorMode === TCursorMode.cm_CrossHair) &&
            isLeftClick
        ) {
            this.CheckStartMovingScaling(relativeX, relativeY, sender)
        }

        if (this.processIndicatorUnderMouse(chart, m_event, sender as TMainChart | TOscChart)) {
            return
        }
        if (isLeftClick && GlobalNewsController.Instance.checkIfMouseOverNews(relativeX, relativeY)) {
            const news = (chart as TMainChart).getNewsUnderMouse(m_event)
            if (news.length > 0) {
                addContextMenu(CONTEXT_MENU_NAMES.news, {
                    anchorX: m_event.clientX,
                    anchorY: m_event.clientY,
                    additionalProps: {
                        news: news
                    }
                })
                GlobalChartsController.Instance.disableMouseEvents()
            }
        }
    }

    public notifyFromThisChartWindow(event: ChartEvent) {
        this.observableItem.notify(event, this)
    }

    public GetStepForSelectionMarkers(): number {
        let numberOfVisibleBars
        if (this.MainChart.IsPaintContextCacheInitialized) {
            numberOfVisibleBars = this.MainChart.PaintContextCache.NumberOfVisibleBars
        } else {
            numberOfVisibleBars = this.MainChart.NumberOfVisibleBars()
        }
        return Math.max(3, Math.floor(numberOfVisibleBars / 20))
    }

    public DeleteSelectedIndicator(): void {
        const { setInfo } = ToolInfoStore

        if (TChart.SelectedIndicator) {
            const runTimeIndicator = TChart.SelectedIndicator as TRuntimeIndicator
            if (runTimeIndicator) {
                this.DeleteRuntimeIndicator(runTimeIndicator)
            } else {
                DebugUtils.error('SelectedIndicator is not TRuntimeIndicator')
            }
        }

        setInfo((prevState) => ({
            ...prevState,
            anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
        }))
    }

    private DeleteRuntimeIndicator(runTimeIndicator: TRuntimeIndicator) {
        const { setInfo } = ToolInfoStore

        if (EducationProcessor.Instance.staticIndicators.includes(runTimeIndicator)) {
            showErrorToast({
                title: t('indicatorActions.sorry-but-you-cannot-delete-built-in-indicators')
            })
            return
        }
        runTimeIndicator.Done()

        if (runTimeIndicator.OutputWindow === TOutputWindow.ow_ChartWindow) {
            fireMixpanelEvent('indicator_deleted', { indicator_name: runTimeIndicator.ShortName })
            this.chartWindowLayers.deleteIndicatorConfigurationControl(runTimeIndicator)
            this.MainChart.indicators.DeleteIndicator(runTimeIndicator)
        } else {
            const oscWinsWithIndicator = this.OscWins.filter((oscWin) => {
                return oscWin.chart.indicators.includes(runTimeIndicator)
            })
            if (oscWinsWithIndicator.length === 1) {
                const oscId = oscWinsWithIndicator[0].getID()
                fireMixpanelEvent('indicator_deleted', { indicator_name: runTimeIndicator.ShortName })
                this.chartWindowLayers.deleteIndicatorConfigurationControl(runTimeIndicator)
                oscWinsWithIndicator[0].chart.indicators.DeleteIndicator(runTimeIndicator)
                this.OscWins.DeleteWindow(oscWinsWithIndicator[0])
                this.deleteOscCanvas(oscId)
            } else {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                    'Unusual behavior, more than 1 or 0 osc windows found, should not happen'
                )
            }
        }
        this.chartWindowLayers.repositionAllGroups()
        this.chartWindowLayers.getManagerLayers().reDrawAll()

        setInfo((prevState) => ({
            ...prevState,
            anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
        }))
    }

    public OnUserCloseOscWindow(oscId: number): void {
        const oscWin = this.OscWins.find((oscWinPredicate) => oscWinPredicate.getID() === oscId)
        if (oscWin) {
            const indicator = oscWin.chart.indicators[0]
            if (indicator) {
                this.chartWindowLayers.deleteIndicatorConfigurationControl(indicator)
                this.OscWins.DeleteWindow(oscWin)
                fireMixpanelEvent('indicator_deleted', { indicator_name: indicator.ShortName })

                this.deleteOscCanvas(oscId)
            } else {
                throw new StrangeError('Osc window has no indicator, should not happen, indicator value:', indicator)
            }
        } else {
            throw new StrangeError('Osc window not found, should not happen, oscId:', oscId)
        }
    }

    public DeleteSelectedItems(): void {
        this.DeleteSelectedIndicator()
        this.deleteSelectedPaintTools()
    }

    public DeleteAllTools(): void {
        if (this.fActiveTool !== null) {
            this.FreeActiveTool()
        }
        this.fFocusedTool.clear()
        this.deleteAllPaintTools()
    }

    public deleteAllPaintTools(): void {
        const allTools = this.MainChart.PaintTools.getAllTools()
        const { updateParams } = GraphToolPanelStore
        const { setInfo } = ToolInfoStore

        if (allTools.length > 0) {
            this.saveStateWithNotify()
        }
        for (const tool of allTools) {
            if (tool.ToolType === TPaintToolType.tt_OrderMarker) {
                continue
            }

            this.fcurrentToolToDelete = tool

            this.MainChart.PaintTools.DeleteTool(this.fcurrentToolToDelete)

            fireMixpanelEvent('graph_tool_deleted', { graph_tool_name: this.fcurrentToolToDelete.ShortName })

            updateParams((prevSettings) => {
                const updatedTools = prevSettings.tools.filter((updatedTool) => updatedTool.ToolName !== tool.ToolName)
                return {
                    ...prevSettings,
                    tools: updatedTools,
                    isOpen: updatedTools.length > 0
                }
            })

            this.observableItem.notify(ChartEvent.DELETE_LINKED_PAINT_TOOLS, this)
            this.fcurrentToolToDelete = null
        }
        for (const oscWin of this.OscWins) {
            const allOscTools = oscWin.chart.PaintTools.getAllTools()

            for (const [i, oscTool] of allOscTools.entries()) {
                this.fcurrentToolToDelete = oscTool

                updateParams((prevSettings) => {
                    const updatedTools = prevSettings.tools.filter((tool) => tool.ToolName !== allOscTools[i].ToolName)
                    return {
                        ...prevSettings,
                        tools: updatedTools,
                        isOpen: updatedTools.length > 0
                    }
                })
                fireMixpanelEvent('graph_tool_deleted', { graph_tool_name: this.fcurrentToolToDelete.ShortName })
                oscWin.chart.PaintTools.DeleteTool(this.fcurrentToolToDelete)
                this.observableItem.notify(ChartEvent.DELETE_LINKED_PAINT_TOOLS, this)
                this.fcurrentToolToDelete = null
            }
        }

        setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
    }

    public DeleteLastTool(): void {
        const { setInfo } = ToolInfoStore

        if (this.fFocusedTool.selected() && this.fFocusedTool.tool === this.MainChart.PaintTools.LastItem) {
            this.fFocusedTool.clear()
        }

        if (this.MainChart.PaintTools.length === 0) {
            return
        }

        this.fcurrentToolToDelete = this.MainChart.PaintTools.LastItem
        this.MainChart.PaintTools.DeleteLast()

        this.Repaint()
        this.invalidate()
        this.observableItem.notify(ChartEvent.DELETE_LINKED_PAINT_TOOLS, this)
        this.fcurrentToolToDelete = null

        setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
    }

    public CheckFocusedTool(chart: TChart, x: number, y: number, shiftState: TShiftState): boolean {
        if (this.fFocusedTool.empty()) {
            return false
        }

        if (!DelphiFormsBuiltIns.ShiftStateIncludes(shiftState, TShiftState.ssCtrl) && !this.fFocusedTool.selected()) {
            chart.PaintTools.DeselectAllTools()
            if (this.fFocusedTool.tool) this.fFocusedTool.tool.Selected = true
            this.invalidate()
        }

        if (DelphiFormsBuiltIns.ShiftStateIncludes(shiftState, TShiftState.ssCtrl) && this.fFocusedTool.tool) {
            this.fFocusedTool.tool.Selected = !this.fFocusedTool.selected()
            if (!this.fFocusedTool.tool.ReadyToSize(x, y)) {
                this.fPrepareToClone = true
            }

            this.invalidate()
        }

        if (this.fFocusedTool.tool?.ReadyToSize(x, y) && this.fFocusedTool.tool?.ShortName !== PaintToolNames.ptBrush) {
            this.fFocusedTool.SetFocus(TFocusType.ft_Point, x, y)
            //TODO: Implement CancelHints method
            // this.CancelHints(chart);
        } else {
            this.fFocusedTool.SetFocus(TFocusType.ft_Edge, x, y)
            this.fFocusedTool.tool?.PrepareToMove()
            this.fFocusedTool.chart?.PaintTools.PrepareToMoveSelectedTools()
            //TODO: Implement CancelHints method
            // this.CancelHints(chart);
        }

        this.invalidate()
        return true
    }

    private ProcessActiveToolMouseDown(x: number, y: number, button: TMouseButton): void {
        let time: TDateTime
        let price: number
        if (this.fActiveTool === null) {
            return
        }
        const chart: TChart = this.fActiveTool.chart as TChart
        if (chart === null || !chart.CanBePainted || !this.fActiveTool.CanBePlaced(chart, this._chartType)) {
            // PlayFailSound(); // Assuming PlayFailSound is implemented
            return
        }

        time = chart.GetBarDateFromX(x)
        price = chart.GetPriceFromY(y)

        // magnet price
        if (GlobalOptions.Options.MagneticMode && chart === this.MainChart) {
            price = chart.MagnetPoint(time, price)
        }

        // 0 - 45 - 90 degrees rotation for lines
        ;[time, price] = this.AdjustActiveToolDegree(chart, x, y, time, price) // Assuming AdjustActiveToolDegree is implemented

        this.fActiveTool.OnMouseDown(time, price, button)

        // clear tool reference if window selected
        if (TChartWindow.cmActiveTool !== null && TChartWindow.cmActiveTool.status !== TPaintToolStatus.ts_InProgress) {
            TChartWindow.cmActiveTool = null
            TChartWindow.cmWindow = null
            this.observableItem.notify(ChartEvent.PAINT_TOOL_WINDOW_SELECTED, this)
        }
    }

    public DeselectAllTools(ExceptChart: TChart | null = null): void {
        const { updateParams } = GraphToolPanelStore

        if (this.MainChart !== ExceptChart) {
            this.MainChart.PaintTools.DeselectAllTools()
        }

        for (let i = 0; i < this.OscWins.length; i++) {
            if (this.OscWins[i].chart !== ExceptChart) {
                this.OscWins[i].chart.PaintTools.DeselectAllTools()
            }
        }

        updateParams((prevState) => ({
            ...prevState,
            isOpen: false,
            tools: []
        }))

        this.invalidate()
    }

    public OnMouseUp(event: MouseEvent, sender: TChart): void {
        if (!this.isDataLoaded || !this.SymbolData.isCurrentTestingDateInAvailableRange) {
            return
        }

        const button = DelphiFormsBuiltIns.ExtractTMouseButton(event)
        const shiftState = DelphiFormsBuiltIns.ExtractTShiftState(event) // Assuming a method to extract TShiftState from MouseEvent
        const relativeCoordinates = sender.MouseToLocal(event)

        const relativeX = relativeCoordinates.x // X relative to chartElement
        const relativeY = relativeCoordinates.y // Y relative to chartElement

        this.fMousePos.SetLocation(relativeX, relativeY)
        this.controlsManager.onMouseUp(event, sender as TMainChart)

        if (this.chartWindowLayers && this.chartWindowLayers.getManagerLayers()) {
            this.chartWindowLayers.getManagerLayers().onMouseUp(event)
        }

        if (button !== TMouseButton.mbMiddle && this.fActiveTool === null) {
            // Set the cursor to default both for the document and internally if needed.
            this.SetCursor(TCursor.crDefault)
        }

        // Reset flags related to chart locking and cloning preparation.
        this.fChartLocked = false
        this.fChartVLocked = false
        this.fChartVHLocked = false
        this.fPrepareToClone = false

        // Exit early if the button is not the left mouse button.
        if (button !== TMouseButton.mbLeft) {
            return
        }

        const tool = this.fFocusedTool.tool
        if (tool && !this.fFocusedTool.empty()) {
            const chart = tool.chart
            tool.OnMouseUp(relativeX, relativeY, DelphiFormsBuiltIns.ExtractTMouseButton(event))
            if (this.fFocusedTool.dragged) {
                // Process the end of the drag event for the tool.
                //TODO: chart.indicators.ProcessChartEvent(CHARTEVENT_OBJECT_DRAG, 0, 0, tool.ToolName);
                this.fFocusedTool.dragged = false
            }

            if (this.fFocusedTool.focused) {
                this.fFocusedTool.FocusType = TFocusType.ft_None
            }
        }

        // Assuming invalidate is a method that refreshes the chart display.
        this.invalidate()
    }

    public OnMouseEnter(event: MouseEvent): void {
        if (!this.isDataLoaded) {
            return
        }
    }

    public OnMouseLeave(event: MouseEvent): void {
        if (!this.isDataLoaded) {
            return
        }

        this.getChartWindowLayers().onMouseLeave(event)

        for (const oscWin of this.OscWins) {
            if (oscWin.chart) {
                this.processOscIndicatorControlMouseLeave(event, oscWin.chart)
            }
        }

        if (this.controlsManager.HoveredControl) {
            this.controlsManager.HoverControl = null
            this.Repaint()
        }

        const chart = this.MainChart
        if (!TChart.SelIndValue?.empty()) {
            this.processIndicatorUnderMouse(chart, event, this.MainChart)

            for (const oscWin of this.OscWins) {
                if (oscWin.chart) {
                    this.processIndicatorUnderMouse(oscWin.chart, event, oscWin.chart)
                }
            }

            this.Repaint()
        }
    }

    private GetScrollParams(): TScrollInfo {
        if (!this._mainChart || !this.MainChart.Bars.LastItemInTestingIndexAvailable) {
            return new TScrollInfo()
        }

        return this.MainChart.GetScrollParams()
    }

    private boundOnChunkForScrollDownloaded = this.OnChunkForScrollDownloaded.bind(this)

    private OnChunkForScrollDownloaded(chunk: TBarChunk): void {
        if (this.deferredAnchorToScroll) {
            this.ScrollToDate(this.deferredAnchorToScroll)
            this.Repaint()
        }

        if (chunk) {
            chunk.Events.off(TDataArrayEvents.de_ChunkLoaded, this.boundOnChunkForScrollDownloaded)
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Chunk is null in OnChunkForScrollDownloaded')
        }
    }

    public ScrollLeftChartToTime(dateToScrollTo: TDateTime): void {
        if (!this.isDataLoaded) {
            return
        }
        DebugUtils.logTopic(
            ELoggingTopics.lt_ScrollAndZoom,
            `Scrolling ${this.DName} to scroll position`,
            dateToScrollTo,
            DateUtils.DF(dateToScrollTo)
        )

        const leftIndex = this.Bars.GetGlobalIndexByDate(
            dateToScrollTo + CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )

        let x_offset = -this.CalculateXOffsetForLeftEdgeAlignment(leftIndex, dateToScrollTo)

        // const ScrollInfo = this.GetScrollParams()

        // if (Math.abs(x_offset) > ScrollInfo.PixBetweenBars - 1) {
        //     leftIndex -= Math.floor(x_offset / ScrollInfo.PixBetweenBars)
        //     x_offset = x_offset % ScrollInfo.PixBetweenBars
        // }
        x_offset = Math.round(x_offset)

        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, 'left index:', leftIndex, 'x_offset:', x_offset)

        this.SetLeftPosition(leftIndex, false, x_offset)
    }

    public ScrollToDate(anchorToScrollTo: IDateAnchorPoint): void {
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `ScrollToDate for ${this.DName}: ${anchorToScrollTo}`)
        //TODO: refactor in a way that we will defer the whole method if the chunk is not loaded

        if (
            !this.isDataLoaded ||
            !this.SymbolData.isDateInAvailableRange(anchorToScrollTo.date) ||
            !this.SymbolData.isCurrentTestingDateInAvailableRange ||
            !this.MainChart.is_HTML_Canvas_initialized()
        ) {
            this.deferScrollToDate(anchorToScrollTo)
            return
        }

        if (DateUtils.IsEmpty(anchorToScrollTo.date)) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Empty date passed to ScrollToTime')
            return
        }

        const lockTo = this.resolveLockTo(anchorToScrollTo.lockTo)

        switch (lockTo) {
            case ELockToOption.lt_LeftBar: {
                this.ScrollLeftChartToTime(anchorToScrollTo.date)
                break
            }
            case ELockToOption.lt_RightBar: {
                this.ScrollRightEdgeToTime(anchorToScrollTo.date)
                break
            }
            case ELockToOption.lt_Center: {
                this.ScrollCenterToTime(anchorToScrollTo.date)
                break
            }
            case ELockToOption.lt_SpecificDateAndX: {
                this.ScrollToSpecificXAndDate(anchorToScrollTo)
                break
            }
            case ELockToOption.lt_GetFromSettings: {
                throw new StrangeError('lt_GetFromSettings should have been converted into one of the other options')
            }
            default: {
                throw new StrangeError('Unexpected lockTo value')
            }
        }

        this.deferredAnchorToScroll = null
    }

    private ScrollToSpecificXAndDate(anchorToScrollTo: IDateAnchorPoint) {
        if (anchorToScrollTo.x === undefined) {
            throw new StrangeError('x is undefined in ScrollToSpecificXAndDate')
        }

        const indexAtDateApprox = this.Bars.GetGlobalIndexByDate(
            anchorToScrollTo.date,
            TNoExactMatchBehavior.nemb_ReturnNearestLower, //for example we want 0:02 on H1 TF, then we need to find 0:00 H1 bar
            true
        )

        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'rightIndexApprox', rightIndexApprox)
        const paintRect = this.MainChart.GetPaintRect()

        const leftIndexApprox =
            indexAtDateApprox - Math.ceil((anchorToScrollTo.x - paintRect.Left) / this.MainChart.barSizeInPixels)

        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'leftIndexApprox', leftIndexApprox)

        //set preliminary position that will be corrected later
        this.SetLeftPosition(leftIndexApprox, false, 0)
        // this.MainChart.CalcDimensions()
        // //see where we landed

        const dateAtXWhereWeLanded = this.MainChart.GetPreciseDateFromX(anchorToScrollTo.x)
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'lastDateWhereWeLanded',
        //     lastDateWhereWeLanded,
        //     DateUtils.DF(lastDateWhereWeLanded)
        // )
        const adjustmentNeededInDays = DateUtils.GetDateDiff(anchorToScrollTo.date, dateAtXWhereWeLanded)
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'adjustmentNeeded, min:',
        //     adjustmentNeededInDays.getDifferenceInMinutes(),
        //     'sec',
        //     adjustmentNeededInDays.getDifferenceInSeconds()
        // )
        const pixelSizeInDateTimeUnits = this.MainChart.getPixelSizeInDateTimeUnits()
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'pixelSizeIn sec',
        //     pixelSizeInDateTimeUnits.getDifferenceInSeconds()
        // )
        let adjustmentsInPx =
            -adjustmentNeededInDays.getDifferenceInDays() / pixelSizeInDateTimeUnits.getDifferenceInDays()
        if (isNaN(adjustmentsInPx)) {
            adjustmentsInPx = 0
        }
        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'adjustmentsInPx', adjustmentsInPx)

        this.SetLeftPosition(leftIndexApprox, false, adjustmentsInPx)
    }

    private ScrollCenterToTime(dateToScrollTo: number) {
        const centerIndexApprox = this.Bars.GetGlobalIndexByDate(
            dateToScrollTo,
            TNoExactMatchBehavior.nemb_ReturnNearestLower, //for example we want 0:02 on H1 TF, then we need to find 0:00 H1 bar
            true
        )

        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'rightIndexApprox', rightIndexApprox)

        const leftIndexApprox = centerIndexApprox - Math.ceil(this.MainChart.NumberOfVisibleBars() / 2)

        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'leftIndexApprox', leftIndexApprox)

        //set preliminary position that will be corrected later
        this.SetLeftPosition(leftIndexApprox, false, 0)
        // this.MainChart.CalcDimensions()
        // //see where we landed
        const paintRect = this.MainChart.GetPaintRect()
        const centerX = (paintRect.Right + paintRect.Left) / 2
        //allow approximation because the chunk with precise data may not be loaded yet
        const centerDateWhereWeLanded = this.MainChart.GetPreciseDateFromX(centerX, true)
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'lastDateWhereWeLanded',
        //     lastDateWhereWeLanded,
        //     DateUtils.DF(lastDateWhereWeLanded)
        // )
        const adjustmentNeededInDays = DateUtils.GetDateDiff(dateToScrollTo, centerDateWhereWeLanded)
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'adjustmentNeeded, min:',
        //     adjustmentNeededInDays.getDifferenceInMinutes(),
        //     'sec',
        //     adjustmentNeededInDays.getDifferenceInSeconds()
        // )
        const pixelSizeInDateTimeUnits = this.MainChart.getPixelSizeInDateTimeUnits()
        // DebugUtils.logTopic(
        //     ELoggingTopics.lt_Temp,
        //     'pixelSizeIn sec',
        //     pixelSizeInDateTimeUnits.getDifferenceInSeconds()
        // )
        let adjustmentsInPx =
            -adjustmentNeededInDays.getDifferenceInDays() / pixelSizeInDateTimeUnits.getDifferenceInDays()
        if (isNaN(adjustmentsInPx)) {
            adjustmentsInPx = 0
        }
        // DebugUtils.logTopic(ELoggingTopics.lt_Temp, 'adjustmentsInPx', adjustmentsInPx)

        this.SetLeftPosition(leftIndexApprox, false, adjustmentsInPx)
    }

    private ScrollRightEdgeToTime(dateToScrollTo: TDateTime) {
        const rightIndexApprox = this.Bars.GetGlobalIndexByDate(
            dateToScrollTo,
            TNoExactMatchBehavior.nemb_ReturnNearestLower, //for example we want 0:02 on H1 TF, then we need to find 0:00 H1 bar
            true
        )

        const leftIndexApprox = ChartUtils.GetLeftMostIndexByRightMostIndex(
            rightIndexApprox,
            this.MainChart.NumberOfVisibleBars()
        ) //

        //set preliminary position that will be corrected later
        this.SetLeftPosition(leftIndexApprox, false, 0)
        this.MainChart.CalcDimensions()

        //see where we landed
        const paintRect = this.MainChart.GetPaintRect()
        //allow approximation because the chunk with precise data may not be loaded yet
        const lastDateWhereWeLanded = this.MainChart.GetPreciseDateFromX(paintRect.Right, true)

        const adjustmentNeededInDateTimeUnits = DateUtils.GetDateDiff(dateToScrollTo, lastDateWhereWeLanded)
        const pixelSizeInDateTimeUnits = this.MainChart.getPixelSizeInDateTimeUnits()
        let adjustmentsInPx =
            -adjustmentNeededInDateTimeUnits.getDifferenceInDays() / pixelSizeInDateTimeUnits.getDifferenceInDays()
        if (isNaN(adjustmentsInPx)) {
            adjustmentsInPx = 0
        }

        this.SetLeftPosition(leftIndexApprox, false, adjustmentsInPx)
    }

    private resolveLockTo(lockToParam: ELockToOption | undefined) {
        let lockTo = lockToParam
        if (!lockTo || lockTo === ELockToOption.lt_GetFromSettings) {
            lockTo = GlobalOptions.Options.OnScrollLockTo
        }
        return lockTo
    }

    private deferScrollToDate(anchorToScrollTo: IDateAnchorPoint) {
        // if (this.chunkForTimeFrameChange) {
        //     this.chunkForTimeFrameChange.Events.off(
        //         TDataArrayEvents.de_ChunkLoaded,
        //         this.boundOnChunkForScrollDownloaded
        //     )
        // }

        if (
            !this.isDataLoaded ||
            !this.SymbolData.isDateInAvailableRange(anchorToScrollTo.date) ||
            !this.SymbolData.isCurrentTestingDateInAvailableRange ||
            !this.MainChart.is_HTML_Canvas_initialized()
        ) {
            //in these cases we will call restoreScroll.. after the data is loaded
        }

        const chunk = this.Bars.GetChunkByDate(anchorToScrollTo.date)
        if (chunk && chunk.Status !== TChunkStatus.cs_Loaded) {
            chunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundOnChunkForScrollDownloaded)
        }
    }

    public SetLeftPosition(globalIndex_param: number, ScrollOtherCharts = false, x_offs_param = 0): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ScrollAndZoom,
            `SetLeftPosition for ${this.DName}}: ${globalIndex_param} x_offs: ${x_offs_param}`
        )

        this.MainChart.CalcDimensions()

        // let globalIndex = globalIndex_param
        // let x_offs = x_offs_param
        let [globalIndex, x_offs] = ChartUtils.Correct_X_offset(
            globalIndex_param,
            x_offs_param,
            this.MainChart.barSizeInPixels
        )

        const ScrollInfo = this.GetScrollParams()

        if (ScrollInfo) {
            if (globalIndex < 0) {
                globalIndex = 0
                x_offs = 0
            }

            if (globalIndex > ScrollInfo.MaxLeftBar) {
                globalIndex = ScrollInfo.MaxLeftBar
                x_offs = ScrollInfo.x_offset
            }

            if (globalIndex === ScrollInfo.MaxLeftBar) {
                x_offs = Math.max(x_offs, ScrollInfo.x_offset)
            }

            this.LeftPosition = globalIndex
            this.left_X_offset = x_offs

            if (this.focused && ScrollOtherCharts) {
                this.observableItem.notify(ChartEvent.SCROLL_OTHER_CHARTS, this)
            }

            this.invalidate()
        } else {
            this.LeftPosition = 0
            this.left_X_offset = 0
        }
    }

    public get BarCount(): number {
        return this.Bars.LastItemInTestingIndex
    }

    public get Bars(): IFMBarsArray {
        return this.MainChart.Bars
    }

    public get isDataLoaded(): boolean {
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (this._symbolData && this._mainChart && this._mainChart.isDataLoaded) {
            return true
        }
        return false
    }

    public setSymbol(symbol: TSymbolData): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, `Setting symbol of the chart to ${symbol.symbolInfo.SymbolName}`)
        this.SelectedSymbolName = symbol.symbolInfo.SymbolName

        if (this._mainChart) {
            this.MainChart.SetSymbolData(symbol)
        }

        this.chartWindowLayers.setSymbol(symbol.symbolInfo.SymbolName)

        this.updateEverythingRelatedToNewSymbol()
        this.updateIndicatorsForCurrentTimeFrameAndSymbol()
        if (this.isDataLoaded && this.MainChart && this.MainChart.is_HTML_Canvas_initialized()) {
            this.updateOneClickTradingInfo()
        }
    }

    public RestoreAllIndicators(): void {
        for (let i = 0; i < this.fTFConfig.length; i++) {
            this.RestoreTemplateIndicators(this.fTFConfig[i])
        }
    }

    public RestoreTemplateIndicators(template: TTemplate): void {
        const list1 = new TOffsStringList()
        const list2 = new TOffsStringList()

        template.StartSearch('Indicator')
        while (template.FindNextSection(list1)) {
            list2.Assign(list1)
            const OldName = list1.GetVarStr('LibName')
            let LibName: string
            try {
                // Assuming LoadIndicator returns a tuple with the indicator and the LibName as it's an out parameter in Delphi
                const [indicator, newLibName] = this.LoadIndicator(list2, template.timeframe)
                LibName = newLibName
                if (indicator !== null && OldName === '') {
                    list1.ReplaceVarStr('LibName', LibName)
                    template.ReplaceSection(list1)
                }
            } catch (error) {
                //TODO: Handle the error appropriately, possibly logging it or showing a message to the user.
                DebugUtils.error('Failed to load indicator:', error)
            }
        }
    }

    public LoadIndicator(list: TOffsStringList, tf: number): [TRuntimeIndicator | null, string] {
        let runtimeIndicator: TRuntimeIndicator | null = null
        let list1: TOffsStringList
        let LibName = ''

        // get info
        const vars = new TVarList()
        vars.LoadFromListSection(list, 'Info')
        LibName = vars.GetValue('LibName')
        const ShortName = vars.GetValue('ShortName')

        let indicatorDescriptor: IndicatorDescriptor | null = null

        if (LibName !== '') {
            // try to find indicator among existed and loaded
            if (!this.SymbolData.Indicators) {
                throw new StrangeError('Symbol.Indicators is null or undefined.')
            }
            const existingRuntimeIndicator = this.SymbolData.Indicators.GetByLibName(LibName)

            // check indicator for the same type with chart
            if (existingRuntimeIndicator !== null && !this.CheckIndicatorType(existingRuntimeIndicator)) {
                DebugUtils.error('Indicator type is not compatible with chart type.')
                indicatorDescriptor = existingRuntimeIndicator.indicatorDescriptor
            }
        }

        if (indicatorDescriptor === null) {
            try {
                // if not loaded - create and load new one
                indicatorDescriptor = GlobalIndicatorDescriptors.IndicatorDescriptors.GetIndicatorByShortName(ShortName)
                if (indicatorDescriptor === null) {
                    return [null, LibName]
                }

                // create indicator and load options
                runtimeIndicator = this.CreateIndicator(indicatorDescriptor, tf)
                list1 = new TOffsStringList()
                try {
                    list.GetSection('Options', list1)
                    runtimeIndicator.options.LoadFromList(list1)
                } catch (error) {
                    DebugUtils.error(error)
                }

                runtimeIndicator.RefreshLevels() // must be before SaveOptions or levels will be lost
                runtimeIndicator.SaveOptions()
                runtimeIndicator.RefreshOptions()

                // recount created indicator
                // ind.RecountIndicator(); // Uncomment if RecountIndicator method is implemented
            } catch (error) {
                DebugUtils.error(error)
                runtimeIndicator = null
            }
        }

        return [runtimeIndicator, LibName]
    }

    public ClearChart(): void {
        if (this._mainChart) {
            this.MainChart.ClearData()
        }
        // FIXME: temporary hack. We do that because at init of the ChartWindow we do not have a link to TimeBar yet, so we have to check if it exists.
        if (this.TimeBar) {
            this.TimeBar.ClearData()
        }
    }

    public AddOscillatorWindow(): TOscWindow {
        const result = new TOscWindow()
        const oscInfo = this.requestNewCanvas(result)
        if (oscInfo) {
            const splitter = new TOscWinSplitter(this, result, this.OscWins, oscInfo)
            const chart = new TOscChart(
                oscInfo.oscCanvas,
                this,
                splitter,
                this.MainChart,
                this.OscWins.nextOscChartID++
            )
            this.InitChartPtrs(chart)
            chart.Bars = this.MainChart.Bars

            result.splitter = splitter
            result.chart = chart
        } else {
            throw new StrangeError('requestNewCanvas fail')
        }
        this.OscWins.Add(result)
        return result
    }

    public CorrectLeftPos(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `CorrectLeftPos for ${this.DName}}`)
        const ScrollInfo = this.GetScrollParams()
        if (!ScrollInfo) {
            this.LeftPosition = 0
            this.left_X_offset = 0
            return
        }

        if (this.LeftPosition > ScrollInfo.MaxLeftBar) {
            this.LeftPosition = ScrollInfo.MaxLeftBar
            this.left_X_offset = ScrollInfo.x_offset
        }
    }

    public onDblClick(event: MouseEvent): void {
        if (!this.isDataLoaded) {
            return
        }

        const sender = GlobalChartsController.Instance.getActiveChart()?.MainChart
        if (this.controlsManager.onDblClick(event, this.MainChart)) {
            return
        }

        if (sender) {
            const relativeCoordinates = sender.MouseToLocal(event)
            const relativeX = relativeCoordinates.x
            this.AutoScaleIfDblClickOnPriceScale(relativeX, sender)
        }

        this.prepareForEdit(event, true)
    }

    private AutoScaleIfDblClickOnPriceScale(x: number, sender: TChart): void {
        if (x >= this.MainChart.GetPaintRect().Right) {
            if (sender instanceof TOscChart) {
                return
            }
            this.AutoScaleOn()
        }
    }

    public onContextMenu(event: MouseEvent): void {
        if (!this.isDataLoaded) {
            return
        }

        GlobalChartsController.Instance.disableMouseEvents()
        this.prepareForEdit(event, false)
    }

    private prepareForEdit(event: MouseEvent, openModal: boolean): void {
        const chart = this.ChartUnderMouse()

        if (chart) {
            const relativeCoordinates = chart.MouseToLocal(event)
            const { x: relativeX, y: relativeY } = relativeCoordinates

            const tool = chart.PaintTools.ToolUnderMouse(relativeX, relativeY, false)
            if (tool) {
                this.fToolToEdit = tool
                openModal && this.fToolToEdit.onDblClick(event)
                return
            }
        }

        if (chart instanceof TChart) {
            const [buffer, value, indicator] = chart.GetIndicatorUnderMouse(event)
            if (indicator) {
                openModal && indicator.ExportData(true)
                return
            }
        }
    }

    public copyPaintTools(): void {
        this.MainChart.copyPaintTools()
    }

    public pastePaintTools(): void {
        this.MainChart.pastePaintTools()
        this.Repaint()
    }

    public notifyCopyToOtherChartsGraphTool(tool: IBasicPaintTool): void {
        this.fcurrentToolToCopy = tool
        this.observableItem.notify(ChartEvent.COPY_PAINT_CURRENT_TOOL, this)
        this.fcurrentToolToCopy = null
    }

    public clearToolToEdit(): void {
        this.fToolToEdit = null
    }

    public applyNewSettingsOnSelectedPaintTool(): void {
        if (this.fToolToEdit) {
            this.fToolToEdit.applyNewSettings()
            this.observableItem.notify(ChartEvent.UPDATE_LINKED_TOOLS, this)
        }
    }

    public ClearDateCache(): void {
        this.MainChart.ClearDateCache()
        this.OscWins.ClearDateCache()
        this.TimeBar.ClearDateCache()
    }

    public AutoScaleOn(): void {
        this.ChartOptions.VerticalScale = 1
        this.ChartOptions.FixedScale = false
        this.ChartOptions.FixedScaleBaseLevel = 0
        this.ChartOptions.FixedVScale = 1
        this.Repaint()
    }

    public onIndicatorVisibilityChange(): void {
        const { setInfo } = ToolInfoStore
        setInfo((prevState) => ({
            ...prevState,
            anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
        }))
    }

    public HideSelectedTools(): void {
        const { setInfo } = ToolInfoStore
        const { updateParams } = GraphToolPanelStore
        const selectedTools = this.MainChart.PaintTools.getAllSelectedTools()
        if (selectedTools.length > 0) {
            this.saveStateWithNotify()
            for (const selectedTool of selectedTools) {
                selectedTool.Hide()
                GlobalChartsController.Instance.OnHideLinkedTool(selectedTool.LinkNumber)
                updateParams((prevSettings) => {
                    return {
                        ...prevSettings,
                        isOpen: false
                    }
                })
                selectedTool.Selected = false
            }

            setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
            this.Repaint()
        }
    }

    public ShowSelectedTools(): void {
        const { setInfo } = ToolInfoStore

        const selectedTools = this.MainChart.PaintTools.getAllSelectedTools()
        if (selectedTools.length > 0) {
            this.saveStateWithNotify()
            for (const tool of selectedTools) {
                tool.Show()
                GlobalChartsController.Instance.OnShowLinkedTool(tool.LinkNumber)
            }

            setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
            this.Repaint()
        }
    }

    public ShowAllHiddenTools(): void {
        const { setInfo } = ToolInfoStore

        for (const chart of GlobalChartsController.Instance.getAllCharts()) {
            for (const tool of chart.MainChart.PaintTools) {
                tool.Show()
            }
            for (const oscWin of chart.OscWins) {
                for (const tool of oscWin.chart.PaintTools) {
                    tool.Show()
                }
            }

            setInfo((prevState) => ({ ...prevState, anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools() }))
            chart.Repaint()
        }
    }

    public ShowAllHiddenIndicators(): void {
        const { setInfo } = ToolInfoStore

        for (const chart of GlobalChartsController.Instance.getAllCharts()) {
            for (const indicator of chart.MainChart.indicators) {
                indicator.Show()
            }
            for (const oscWin of chart.OscWins) {
                for (const indicator of oscWin.chart.indicators) {
                    indicator.Show()
                }
            }

            setInfo((prevState) => ({
                ...prevState,
                anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
            }))
            chart.Repaint()
        }
    }

    public HideAllTools(): void {
        const { setInfo } = ToolInfoStore
        const { updateParams } = GraphToolPanelStore

        for (const chart of GlobalChartsController.Instance.getAllCharts()) {
            let hiddenToolCount = 0
            for (const tool of chart.MainChart.PaintTools) {
                tool.Hide()
                hiddenToolCount++
            }
            for (const oscWin of chart.OscWins) {
                for (const tool of oscWin.chart.PaintTools) {
                    tool.Hide()
                    hiddenToolCount++
                }
            }

            if (hiddenToolCount > 0) {
                setInfo((prevState) => ({
                    ...prevState,
                    anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools()
                }))
            }
            updateParams((prevSettings) => {
                return {
                    ...prevSettings,
                    isOpen: false
                }
            })
            chart.Repaint()
        }
    }

    public HideAllIndicators(): void {
        const { setInfo } = ToolInfoStore

        for (const chart of GlobalChartsController.Instance.getAllCharts()) {
            let hiddenIndicatorCount = 0
            for (const indicator of chart.MainChart.indicators) {
                indicator.Hide()
                hiddenIndicatorCount++
            }
            for (const oscWin of chart.OscWins) {
                for (const indicator of oscWin.chart.indicators) {
                    indicator.Hide()
                    hiddenIndicatorCount++
                }
            }

            if (hiddenIndicatorCount > 0) {
                setInfo((prevState) => ({
                    ...prevState,
                    anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
                }))
            }
            chart.Repaint()
        }
    }

    private countHiddenIndicators(): number {
        const oscillators = this.OscWins.reduce(
            (acc: TRuntimeIndicator[], oscWin) => acc.concat(oscWin.chart.indicators),
            []
        )
        const indicators = [...oscillators, ...this.MainChart.indicators]

        return indicators.filter((indicator) => indicator.IsVisible() === false).length
    }

    public hasHiddenIndicators(): boolean {
        return this.countHiddenIndicators() > 0
    }

    private countHiddenTools(): number {
        const oscillators = this.OscWins.reduce(
            (acc: IBasicPaintTool[], oscWin) => acc.concat(oscWin.chart.PaintTools),
            []
        )
        const tools = [...oscillators, ...this.MainChart.PaintTools]

        return tools.filter((tool) => tool.IsVisible() === false).length
    }

    public hasHiddenTools(): boolean {
        return this.countHiddenTools() > 0
    }

    public EnableActiveFrame(): void {
        this.activeFrame = true
    }

    public DisableActiveFrame(): void {
        this.activeFrame = false
    }

    public scrollToNewsItem(unixSecondsDateTime: number): void {
        const timeInDT = DateUtils.fromUnixTimeSeconds(unixSecondsDateTime)
        this.ScrollToDate({ date: timeInDT, lockTo: ELockToOption.lt_Center })
        this.Repaint()
        GlobalTestingManager.TestingManager.stopTesting()
    }

    public onFontsLoaded(): void {
        if (this.chartWindowLayers.isReady()) {
            this.chartWindowLayers.getManagerLayers().reDrawAll()
        }
    }

    public updateLinkedTool(): void {
        this.observableItem.notify(ChartEvent.UPDATE_LINKED_TOOLS, this)
    }

    public getChartWindowLayers(): ChartWindowLayers {
        return this.chartWindowLayers
    }

    getToolToEdit(): IBasicPaintTool | null {
        return this.fToolToEdit
    }

    public ZoomOutExecute(anchorDate: IDateAnchorPoint, zoomSpeedCoefficient = 1): void {
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `ZoomOutExecute for ${this.DName}`)
        DebugUtils.logTopic(
            ELoggingTopics.lt_ScrollAndZoom,
            `Date range before zoom out: ${this.MainChart.GetPreciseVisibleDateRange().start}, ${
                this.MainChart.GetPreciseVisibleDateRange().end
            }`
        )
        this.ChartOptions.ZoomOut(zoomSpeedCoefficient)
        this.MainChart.CalcDimensions()
        this.ScrollToDate(anchorDate)
    }

    public ZoomInExecute(anchorDate: IDateAnchorPoint, zoomSpeedCoefficient = 1): void {
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `ZoomInExecute for ${this.DName}}`)
        this.ChartOptions.ZoomIn(zoomSpeedCoefficient)
        this.MainChart.CalcDimensions()
        this.ScrollToDate(anchorDate)
    }

    public getHorizScrollAnchorDate(allowSpecificDateForCtrlZoom = true): IDateAnchorPoint {
        const range = this.MainChart.GetPreciseVisibleDateRange()

        const keyboardTracker = KeyboardTracker.getInstance()
        if (allowSpecificDateForCtrlZoom && keyboardTracker.isCtrlPressed && this.MainChart.IsMouseInside()) {
            const localMouseCoords = this.MainChart.GetCurrentMousePositionInLocalCoordinates()
            const dateUnderMouse = this.MainChart.GetPreciseDateFromX(localMouseCoords.x)
            return { date: dateUnderMouse, x: localMouseCoords.x, lockTo: ELockToOption.lt_SpecificDateAndX }
        }

        const preciseStartDate = range.start
        const preciseEndDate = range.end

        switch (GlobalOptions.Options.OnScrollLockTo) {
            case ELockToOption.lt_LeftBar: {
                return { date: preciseStartDate, x: NaN, lockTo: ELockToOption.lt_LeftBar }
            }
            case ELockToOption.lt_RightBar: {
                return { date: preciseEndDate, x: NaN, lockTo: ELockToOption.lt_RightBar }
            }
            case ELockToOption.lt_Center: {
                const centerDate = preciseStartDate + (preciseEndDate - preciseStartDate) / 2
                return { date: centerDate, x: NaN, lockTo: ELockToOption.lt_RightBar }
            }
            default: {
                throw new StrangeError('Unknown value of OnScrollLockTo')
            }
        }
    }

    private CalculateXOffsetForLeftEdgeAlignment(targetBarGlobalIndex: number, dateToScrollTo: number) {
        const bar = this.Bars.GetItemByGlobalIndex(targetBarGlobalIndex)
        if (!bar) {
            return 0
        }
        const s_info = this.MainChart.GetScrollParams()

        const timeDiff = dateToScrollTo - bar.DateTime
        const barSizeInDateTime = this.MainChart.getBarSizeInDateTime().diffInTDateTimeUnits
        const barSizeInPixels = s_info.PixBetweenBars

        return Math.round((timeDiff / barSizeInDateTime) * barSizeInPixels) % barSizeInPixels
    }

    public setPreparingChartBlockingLayer(): void {
        const layerBlock = this.getChartWindowLayers()?.getLayerBlockingChart()
        if (layerBlock) {
            layerBlock.switchStateTo(EBlockingLayerState.loader)
        }
    }

    public getActiveTool(): IBasicPaintTool | null {
        return this.fActiveTool
    }

    public getFocusedTool(): TFocusedPaintTool {
        return this.fFocusedTool
    }

    public updateOneClickTradingInfo(): void {
        if (!this.SymbolData) {
            return
        }
        const chwl = this.getChartWindowLayers()
        if (chwl) {
            const oneClickTradingControl = chwl.getOneClickTradingControl()
            if (oneClickTradingControl) {
                oneClickTradingControl.updateLabels(this.SymbolData)
            } else {
                throw new StrangeError('OneClickTradingControl is not initialized')
            }
        } else {
            throw new StrangeError('ChartWindowLayers is not initialized')
        }
    }

    public static GetActiveTool(): IBasicPaintTool | null {
        return TChartWindow.cmActiveTool
    }

    public createOscIndicatorControl(
        chartWindow: TChartWindow,
        oscWindow: TOscWindow,
        indicator: TRuntimeIndicator
    ): OscIndicatorConfigurationControl {
        const indicatorFontStyle = new TMkFontStyle('Roboto Flex', TFontStyle.fsNone, 12, '#101828')
        const indicatorLastValueFont = indicatorFontStyle.clone()
        indicatorLastValueFont.color = '#2F80ED'

        const locationParam = new LocationParams(4, 4, 100, 24)
        const chartControlParams = new ChartControlParams(null, locationParam)

        const indicatorConfigurationControl = new OscIndicatorConfigurationControl(
            indicator,
            chartWindow,
            oscWindow.chart,
            indicatorFontStyle,
            indicatorLastValueFont,
            chartControlParams
        )
        indicatorConfigurationControl.adjustControlWidth()
        indicatorConfigurationControl.attachObserver(this)
        indicator.oscConfigurationControl = indicatorConfigurationControl
        return indicatorConfigurationControl
    }

    private processOscIndicatorControlMouseDown(event: MouseEvent, sender: TChart): boolean {
        if (sender instanceof TOscChart) {
            for (const indicator of sender.indicators) {
                if (indicator.oscConfigurationControl) {
                    const res = indicator.oscConfigurationControl.onMouseDown(event, sender)
                    if (res) {
                        return true
                    }
                }
            }
        }
        return false
    }

    private processOscIndicatorControlMouseLeave(event: MouseEvent, chart: TOscChart): void {
        if (chart) {
            for (const indicator of chart.indicators) {
                if (indicator.oscConfigurationControl) {
                    indicator.oscConfigurationControl.onMouseLeave(event)
                }
            }
        }
    }

    private processOscMouseMove(event: MouseEvent, sender: TChart): void {
        if (sender instanceof TOscChart) {
            for (const oscWin of this.OscWins) {
                for (const indicator of oscWin.chart.indicators) {
                    if (indicator && indicator.oscConfigurationControl?.onMouseMove(event, sender)) {
                        this.needsRedraw = true
                    }
                }
            }
        }
    }

    public hasUndoTasks(): boolean {
        return this.undoStack.length > 0
    }

    public hasRedoTasks(): boolean {
        return this.redoStack.length > 0
    }
}
