import { showErrorToast } from '@root/utils/toasts'
import StrangeSituationNotifier from '../StrangeSituationNotifier'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'

//we can make this error to show message in debug or do something to notify the developers that something is strange
export default class StrangeError extends Error {
    constructor(message: string, ...args: any[]) {
        //combine all args into single string and add to message
        if (args.length > 0) {
            message += ' '
            for (const arg of args) {
                message += `${arg} `
            }
        }
        super(message)
        this.name = 'StrangeError'

        if (DebugUtils.Instance.EnableStrangeErrorToasts) {
            showErrorToast({ title: 'Unexpected error', message: message }) //translation-ignore
            //we can show toast with this message
        }

        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(message)
    }
}
