import { DelphiLikeArray } from '../../delphi_compatibility/DelphiBasicTypes'
import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { NotImplementedError } from '../../utils/common_utils'
import { TPoint } from '../../extension_modules/common/CommonExternalInterface'
import { IChart } from '../chart_classes/IChart'

export enum TPaintToolType {
    tt_VLine,
    tt_HLine,
    tt_Line,
    tt_Ray,
    tt_Polyline,
    tt_Polygon,
    tt_Text,
    tt_WaveSymbols,
    tt_OrderMarker
}

export enum TPaintToolStatus {
    ts_FirstPoint,
    ts_InProgress,
    ts_Completed
}

export enum ObjProp {
    OBJPROP_TIME1,
    OBJPROP_PRICE1,
    OBJPROP_FILLCOLOR,
    OBJPROP_COLOR,
    OBJPROP_STYLE,
    OBJPROP_WIDTH,
    OBJPROP_TIME2,
    OBJPROP_PRICE2,
    OBJPROP_TIME3,
    OBJPROP_PRICE3,
    OBJPROP_LEVELVALUE,
    OBJPROP_LEVELCOLOR,
    OBJPROP_LEVELSTYLE,
    OBJPROP_LEVELWIDTH,
    OBJPROP_FONTNAME,
    OBJPROP_FONTSIZE,
    OBJPROP_NAME,
    OBJPROP_FILLINSIDE,
    OBJPROP_ELLIPSE,
    OBJPROP_FIBOENDWIDTH,
    OBJPROP_FIBOCLOSEDENDS,
    OBJPROP_FIBOSHOWPRICE,
    OBJPROP_FIBOLEVELS,
    OBJPROP_SCREENCOORDS,
    OBJPROP_TEXT,
    OBJPROP_SCRHALIGNMENT,
    OBJPROP_SCRVALIGNMENT,
    OBJPROP_HALIGNMENT,
    OBJPROP_YDISTANCE,
    OBJPROP_VALIGNMENT,
    OBJPROP_XDISTANCE,
    OBJPROP_FIBOLEVELN,
    BORDER,
    OBJPROP_TEXT_PARAMS
}

export enum ObjPropNumber {
    OBJPROP_TIME1,
    OBJPROP_PRICE1,
    OBJPROP_STYLE,
    OBJPROP_WIDTH,
    OBJPROP_TIME2,
    OBJPROP_PRICE2,
    OBJPROP_TIME3,
    OBJPROP_PRICE3,
    OBJPROP_LEVELVALUE,
    OBJPROP_LEVELCOLOR,
    OBJPROP_LEVELSTYLE,
    OBJPROP_LEVELWIDTH,
    OBJPROP_FONTNAME,
    OBJPROP_FONTSIZE,
    OBJPROP_NAME,
    OBJPROP_FILLINSIDE,
    OBJPROP_ELLIPSE,
    OBJPROP_FIBOENDWIDTH,
    OBJPROP_FIBOCLOSEDENDS,
    OBJPROP_FIBOSHOWPRICE,
    OBJPROP_FIBOLEVELS,
    OBJPROP_SCREENCOORDS,
    OBJPROP_SCRHALIGNMENT,
    OBJPROP_SCRVALIGNMENT,
    OBJPROP_HALIGNMENT,
    OBJPROP_YDISTANCE,
    OBJPROP_VALIGNMENT,
    OBJPROP_XDISTANCE,
    OBJPROP_FIBOLEVELN,
    BORDER,
    OBJPROP_TEXT_PARAMS
}

export function ObjPropNumberInObjProp(value: number): ObjProp {
    const objString = ObjPropNumber[value]

    for (let key in Object.keys(ObjProp)) {
        if (ObjProp[key] === objString) return +key
    }
    return 0
}

export enum ObjPropString {
    OBJPROP_COLOR,
    OBJPROP_FILLCOLOR,
    OBJPROP_TEXT
}

export function ObjPropStringInObjProp(value: number): ObjProp {
    const objString = ObjPropString[value]

    for (let key in Object.keys(ObjProp)) {
        if (ObjProp[key] === objString) return +key
    }
    return 0
}

export type TPointsArray = TPoint[]

export class TCoordsRect {
    x1!: TDateTime
    x2!: TDateTime
    y1!: number
    y2!: number
}

export class TPointRec {
    time!: TDateTime
    price!: number
}

export class TPointInfo {
    time!: TDateTime
    price!: number
    s_index!: number
    s_price!: number
    x!: number
    y!: number

    constructor(aTime: TDateTime, aPrice: number)
    constructor(ax: number, ay: number)
    constructor(aTimeOrX: TDateTime | number, aPriceOrY: number) {
        if (typeof aTimeOrX === 'number' && typeof aPriceOrY === 'number') {
            this.x = aTimeOrX
            this.y = aPriceOrY
        } else {
            this.time = aTimeOrX as TDateTime
            this.price = aPriceOrY
        }
    }

    clone(): TPointInfo {
        const cloned = new TPointInfo(this.x, this.y)
        cloned.time = this.time
        cloned.price = this.price
        cloned.s_index = this.s_index
        cloned.s_price = this.s_price
        cloned.x = this.x
        cloned.y = this.y
        return cloned
    }

    point(): TPoint {
        throw new NotImplementedError('TPointInfo.point')
    }

    assign(p: TPointInfo): void {
        this.time = p.time
        this.price = p.price
        this.x = p.x
        this.y = p.y
    }
}

export class TRayCoords {
    x1!: number
    y1!: number
    x2!: number
    y2!: number
    price1!: number
    price2!: number

    init(ax1: number, ay1: number, ax2: number, ay2: number, aPrice1: number, aPrice2: number): void {
        this.x1 = ax1
        this.y1 = ay1
        this.x2 = ax2
        this.y2 = ay2
        this.price1 = aPrice1
        this.price2 = aPrice2
    }

    public GetCoords(chart: IChart): void {
        const coords = chart.GetRayCoords(this.x1, this.y1, this.x2, this.y2, this.price1, this.price2)
        if (Array.isArray(coords) && coords.length === 4) {
            ;[this.x1, this.y1, this.x2, this.y2] = coords
        }
    }

    clone(): TRayCoords {
        const clonedCoords = new TRayCoords()
        clonedCoords.init(this.x1, this.y1, this.x2, this.y2, this.price1, this.price2)
        return clonedCoords
    }
}

export class TPointsArr extends DelphiLikeArray<TPointInfo> {
    clone(): TPointsArr {
        const clonedArray = new TPointsArr()
        this.forEach((point) => {
            clonedArray.push(point.clone())
        })
        return clonedArray
    }

    Item(index: number): TPointInfo {
        return this[index]
    }
}
