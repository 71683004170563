import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslate from './locales/en/common.json'
import achTranslate from './locales/ach/common.json'
import jaTranslate from './locales/ja/common.json'
import ukTranslate from './locales/uk/common.json'
import ruTranslate from './locales/ru/common.json'
import esESTranslate from './locales/es-ES/common.json'
import es419Translate from './locales/es-419/common.json'
import zhCNTranslate from './locales/zh-CN/common.json'
import zhTWTranslate from './locales/zh-TW/common.json'
import zhHKTranslate from './locales/zh-HK/common.json'
import frFRTranslate from './locales/fr-FR/common.json'
import frCATranslate from './locales/fr-CA/common.json'
import hiTranslate from './locales/hi/common.json'
import bnINTranslate from './locales/bn-IN/common.json'
import bnBDTranslate from './locales/bn-BD/common.json'
import viTranslate from './locales/vi/common.json'
import deTranslate from './locales/de/common.json'
import koTranslate from './locales/ko/common.json'
import trTranslate from './locales/tr/common.json'
import plTranslate from './locales/pl/common.json'
import idIDTranslate from './locales/id-ID/common.json'
import msMYTranslate from './locales/ms-MY/common.json'
import itTranslate from './locales/it/common.json'
import urTranslate from './locales/ur/common.json'
import teTranslate from './locales/te/common.json'
import ptPTTranslate from './locales/pt-PT/common.json'
import ptBRTranslate from './locales/pt-BR/common.json'
import taINTranslate from './locales/ta-IN/common.json'
import taLKTranslate from './locales/ta-LK/common.json'
import haTranslate from './locales/ha/common.json'
import thTranslate from './locales/th/common.json'
import roTranslate from './locales/ro/common.json'
import yoTranslate from './locales/yo/common.json'
import igTranslate from './locales/ig/common.json'
import nlNLTranslate from './locales/nl-NL/common.json'
import nlBETranslate from './locales/nl-BE/common.json'
import kkCyrlTranslate from './locales/kk-Cyrl/common.json'
import kkLatnTranslate from './locales/kk-Latn/common.json'
import azTranslate from './locales/az/common.json'
import myTranslate from './locales/my/common.json'
import swKETranslate from './locales/sw-KE/common.json'
import swTZTranslate from './locales/sw-TZ/common.json'
import huTranslate from './locales/hu/common.json'
import uzCyrlTranslate from './locales/uz-Cyrl/common.json'
import uzLatnTranslate from './locales/uz-Latn/common.json'
import elTranslate from './locales/el/common.json'
import heTranslate from './locales/he/common.json'
import zuTranslate from './locales/zu/common.json'
import svTranslate from './locales/sv/common.json'
import csTranslate from './locales/cs/common.json'
import srCyrlTranslate from './locales/sr-Cyrl/common.json'
import srLatnTranslate from './locales/sr-Latn/common.json'
import kaTranslate from './locales/ka/common.json'
import hyTranslate from './locales/hy/common.json'
import siTranslate from './locales/si/common.json'
import kmTranslate from './locales/km/common.json'
import bgTranslate from './locales/bg/common.json'
import fiTranslate from './locales/fi/common.json'
import noTranslate from './locales/no/common.json'
import daTranslate from './locales/da/common.json'
import skTranslate from './locales/sk/common.json'
import hrTranslate from './locales/hr/common.json'
import tkTranslate from './locales/tk/common.json'
import tgTranslate from './locales/tg/common.json'
import mnTranslate from './locales/mn/common.json'
import sqTranslate from './locales/sq/common.json'
import lvTranslate from './locales/lv/common.json'
import bsTranslate from './locales/bs/common.json'
import kyTranslate from './locales/ky/common.json'
import ltTranslate from './locales/lt/common.json'
import etTranslate from './locales/et/common.json'
import slTranslate from './locales/sl/common.json'
import mkTranslate from './locales/mk/common.json'
import isTranslate from './locales/is/common.json'
import arEGTranslate from './locales/ar-EG/common.json'
import arSATranslate from './locales/ar-SA/common.json'
import arMATranslate from './locales/ar-MA/common.json'
import { CURRENT_LANGUAGE_KEY } from '@root/constants/localStorageKeys'
import { LanguageKey, LANGUAGES } from '@root/constants/langs'

const CROWDIN_PSEUDO_LANG = 'ach'
const DEFAULT_LANG = 'en'

const resources = {
    [LANGUAGES['en'].value]: {
        translation: enTranslate
    },
    [LANGUAGES['ach'].value]: {
        translation: achTranslate
    },
    [LANGUAGES['ja'].value]: {
        translation: jaTranslate
    },
    [LANGUAGES['uk'].value]: {
        translation: ukTranslate
    },
    [LANGUAGES['ru'].value]: {
        translation: ruTranslate
    },
    [LANGUAGES['es-ES'].value]: {
        translation: esESTranslate
    },
    [LANGUAGES['es-419'].value]: {
        translation: es419Translate
    },
    [LANGUAGES['zh-CN'].value]: {
        translation: zhCNTranslate
    },
    [LANGUAGES['zh-TW'].value]: {
        translation: zhTWTranslate
    },
    [LANGUAGES['zh-HK'].value]: {
        translation: zhHKTranslate
    },
    [LANGUAGES['fr-FR'].value]: {
        translation: frFRTranslate
    },
    [LANGUAGES['fr-CA'].value]: {
        translation: frCATranslate
    },
    [LANGUAGES['hi'].value]: {
        translation: hiTranslate
    },
    [LANGUAGES['bn-IN'].value]: {
        translation: bnINTranslate
    },
    [LANGUAGES['bn-BD'].value]: {
        translation: bnBDTranslate
    },
    [LANGUAGES['vi'].value]: {
        translation: viTranslate
    },
    [LANGUAGES['de'].value]: {
        translation: deTranslate
    },
    [LANGUAGES['ko'].value]: {
        translation: koTranslate
    },
    [LANGUAGES['tr'].value]: {
        translation: trTranslate
    },
    [LANGUAGES['pl'].value]: {
        translation: plTranslate
    },
    [LANGUAGES['id-ID'].value]: {
        translation: idIDTranslate
    },
    [LANGUAGES['ms-MY'].value]: {
        translation: msMYTranslate
    },
    [LANGUAGES['it'].value]: {
        translation: itTranslate
    },
    [LANGUAGES['ur'].value]: {
        translation: urTranslate
    },
    [LANGUAGES['te'].value]: {
        translation: teTranslate
    },
    [LANGUAGES['pt-PT'].value]: {
        translation: ptPTTranslate
    },
    [LANGUAGES['pt-BR'].value]: {
        translation: ptBRTranslate
    },
    [LANGUAGES['ta-IN'].value]: {
        translation: taINTranslate
    },
    [LANGUAGES['ta-LK'].value]: {
        translation: taLKTranslate
    },
    [LANGUAGES['ha'].value]: {
        translation: haTranslate
    },
    [LANGUAGES['th'].value]: {
        translation: thTranslate
    },
    [LANGUAGES['ro'].value]: {
        translation: roTranslate
    },
    [LANGUAGES['yo'].value]: {
        translation: yoTranslate
    },
    [LANGUAGES['ig'].value]: {
        translation: igTranslate
    },
    [LANGUAGES['nl-NL'].value]: {
        translation: nlNLTranslate
    },
    [LANGUAGES['nl-BE'].value]: {
        translation: nlBETranslate
    },
    [LANGUAGES['kk-Cyrl'].value]: {
        translation: kkCyrlTranslate
    },
    [LANGUAGES['kk-Latn'].value]: {
        translation: kkLatnTranslate
    },
    [LANGUAGES['az'].value]: {
        translation: azTranslate
    },
    [LANGUAGES['my'].value]: {
        translation: myTranslate
    },
    [LANGUAGES['sw-KE'].value]: {
        translation: swKETranslate
    },
    [LANGUAGES['sw-TZ'].value]: {
        translation: swTZTranslate
    },
    [LANGUAGES['hu'].value]: {
        translation: huTranslate
    },
    [LANGUAGES['uz-Cyrl'].value]: {
        translation: uzCyrlTranslate
    },
    [LANGUAGES['uz-Latn'].value]: {
        translation: uzLatnTranslate
    },
    [LANGUAGES['el'].value]: {
        translation: elTranslate
    },
    [LANGUAGES['he'].value]: {
        translation: heTranslate
    },
    [LANGUAGES['zu'].value]: {
        translation: zuTranslate
    },
    [LANGUAGES['sv'].value]: {
        translation: svTranslate
    },
    [LANGUAGES['cs'].value]: {
        translation: csTranslate
    },
    [LANGUAGES['sr-Cyrl'].value]: {
        translation: srCyrlTranslate
    },
    [LANGUAGES['sr-Latn'].value]: {
        translation: srLatnTranslate
    },
    [LANGUAGES['ka'].value]: {
        translation: kaTranslate
    },
    [LANGUAGES['hy'].value]: {
        translation: hyTranslate
    },
    [LANGUAGES['si'].value]: {
        translation: siTranslate
    },
    [LANGUAGES['km'].value]: {
        translation: kmTranslate
    },
    [LANGUAGES['bg'].value]: {
        translation: bgTranslate
    },
    [LANGUAGES['fi'].value]: {
        translation: fiTranslate
    },
    [LANGUAGES['no'].value]: {
        translation: noTranslate
    },
    [LANGUAGES['da'].value]: {
        translation: daTranslate
    },
    [LANGUAGES['sk'].value]: {
        translation: skTranslate
    },
    [LANGUAGES['hr'].value]: {
        translation: hrTranslate
    },
    [LANGUAGES['tk'].value]: {
        translation: tkTranslate
    },
    [LANGUAGES['tg'].value]: {
        translation: tgTranslate
    },
    [LANGUAGES['mn'].value]: {
        translation: mnTranslate
    },
    [LANGUAGES['sq'].value]: {
        translation: sqTranslate
    },
    [LANGUAGES['lv'].value]: {
        translation: lvTranslate
    },
    [LANGUAGES['bs'].value]: {
        translation: bsTranslate
    },
    [LANGUAGES['ky'].value]: {
        translation: kyTranslate
    },
    [LANGUAGES['lt'].value]: {
        translation: ltTranslate
    },
    [LANGUAGES['et'].value]: {
        translation: etTranslate
    },
    [LANGUAGES['sl'].value]: {
        translation: slTranslate
    },
    [LANGUAGES['mk'].value]: {
        translation: mkTranslate
    },
    [LANGUAGES['is'].value]: {
        translation: isTranslate
    },
    [LANGUAGES['ar-EG'].value]: {
        translation: arEGTranslate
    },
    [LANGUAGES['ar-SA'].value]: {
        translation: arSATranslate
    },
    [LANGUAGES['ar-MA'].value]: {
        translation: arMATranslate
    }
}

function getCurrentLanguage(): string {
    const storedLanguage = localStorage.getItem(CURRENT_LANGUAGE_KEY) as LanguageKey | null
    if (storedLanguage && LANGUAGES[storedLanguage]) {
        return storedLanguage
    }

    // Get the browser's preferred language
    const browserLanguage = navigator.language as LanguageKey

    // Check if the browser language is supported by your application
    if (LANGUAGES[browserLanguage]?.value) {
        return browserLanguage
    }

    // Fallback to default language
    return DEFAULT_LANG
}

export async function setI18nLang(lang: string) {
    localStorage.setItem(CURRENT_LANGUAGE_KEY, lang)
    await i18n.changeLanguage(lang)
}

export async function setDefaultLang() {
    await setI18nLang(DEFAULT_LANG)
}

export async function setPseudoLangForCrowdin() {
    await setI18nLang(CROWDIN_PSEUDO_LANG)
}

i18n.use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        lng: getCurrentLanguage(),
        fallbackLng: DEFAULT_LANG, // Use default language as fallback
        interpolation: {
            escapeValue: false // React already safeguards against XSS
        },
        pluralSeparator: '_'
    })

export default i18n
