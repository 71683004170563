export type ErrorOffsetDataType = { value: number; unit: string; milliseconds: number } // unit is 's', 'm', 'h'

function convertToAdjustedUnit(timeUnit: string): ErrorOffsetDataType {
    const value = Math.ceil(parseInt(timeUnit, 10) / 2) // Extract the numeric part (we take a half as approximate value)
    const unit = timeUnit.slice(-1) // Extract the last character (s, m, h)

    // Initial conversion based on input unit
    let seconds = 0
    if (unit === 's') seconds = value
    if (unit === 'm') seconds = value * 60
    if (unit === 'h') seconds = value * 3600

    const milliseconds = seconds * 1000

    // Convert to the most appropriate unit
    if (seconds >= 3600) {
        const hours = seconds / 3600
        return { value: hours, unit: 'h', milliseconds }
    } else if (seconds >= 60) {
        const minutes = seconds / 60
        return { value: minutes, unit: 'm', milliseconds }
    } else {
        return { value: seconds, unit: 's', milliseconds }
    }
}

export function extractAndConvertTime(str: string): ErrorOffsetDataType {
    // Use a regex to capture the value following "per"
    const match = str.match(/per (\d+[smh])/)

    if (!match) {
        throw new Error('No time unit found in the string')
    }

    const timeUnit = match[1]

    // Adjust time unit based on the converted value
    const timeInAdjustedUnit = convertToAdjustedUnit(timeUnit)
    return timeInAdjustedUnit
}
