/* eslint-disable no-console */
import IEventEmitter from '@fto/lib/common/IEventEmitter'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import CommonConstants from '../../ft_types/common/CommonConstants'
import { ChunkBuilder } from '../../ft_types/data/BarBuilding/chunk_building/ChunkBuilder'
import { TChunkMapStatus } from '@fto/lib/ft_types/data/TChunkMapStatus'
import { TBarChunk } from '../../ft_types/data/chunks/BarChunk'
import { TChunkStatus } from '../../ft_types/data/chunks/ChunkEnums'
import { TFMBarsArray } from '../../ft_types/data/data_arrays/chunked_arrays/BarsArray/BarsArray'
import { DownloadController } from '../../ft_types/data/data_downloading/DownloadController'
import { TDataArrayEvents } from '../../ft_types/data/data_downloading/DownloadRelatedEnums'
import TEventsFunctionality from '../../utils/EventsFunctionality'
import { EComparisonEvent, EComparisonStatus } from './ComparisonEnums'
import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'
import MathUtils from '@fto/lib/utils/MathUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { INamed } from '@fto/lib/utils/INamed'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

export default class BarArrayComparator implements IEventEmitter, INamed {
    public Events: TEventsFunctionality = new TEventsFunctionality('BarArrayComparator')

    private barsArray_downloaded: IFMBarsArray | undefined = undefined
    private barsArray_built: IFMBarsArray | undefined = undefined
    private lastChunkIndex = 0
    private readonly broker = 'Advanced'

    private _symbol: string
    private _biggerTF: number
    private _smallerTF: number

    private _startDate: TDateTime
    private _startChunkNumber = 0

    private _comparisonStarted = false
    private _report = ''

    private _status: EComparisonStatus
    private _symbolDecimals = 5

    private _deferralEnabled = false

    public get Status(): EComparisonStatus {
        return this._status
    }

    public set Status(value: EComparisonStatus) {
        this._status = value
        this.Events.EmitEvent(EComparisonEvent.StatusUpdated, value)
    }

    public get DName(): string {
        return `${this._symbol} build ${this._biggerTF} from ${this._smallerTF}`
    }

    public toString(): string {
        return this.DName
    }

    public get Report(): string {
        return this._report
    }

    public constructor(
        symbol: string,
        biggerTF: number,
        smallerTF: number,
        startDate = DateUtils.EmptyDate,
        startChunkNumber = 0
    ) {
        this._symbol = symbol
        this._biggerTF = biggerTF
        this._smallerTF = smallerTF
        this._startDate = startDate
        this._startChunkNumber = startChunkNumber
        this._status = EComparisonStatus.NotStarted

        if (this.SymbolData) {
            this._symbolDecimals = this.SymbolData.symbolInfo.decimals + 1
            console.log('Setting more precise symbol decimals for', this._symbol, 'to', this._symbolDecimals)
        }

        this.Events.on(EComparisonEvent.ErrorDetected, (message: string) => {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(message)
            this._report += `${message}\n`
            this.Status = EComparisonStatus.Failed
        })
    }

    public GetShortMessage(): string {
        //return first 200 symbols from report
        return this._report.slice(0, 200)
    }

    private GetNewBarsArray(symbol: string, timeframeToCompare: number): TFMBarsArray {
        const barsArray = new TFMBarsArray(timeframeToCompare, symbol, this.broker)
        barsArray.IgnoreBarCountComparison = true
        return barsArray
    }

    public InitiateComparison(): void {
        console.log('restoring a list of downloadable timeframes')
        CommonConstants.TIMEFRAMES_DOWNLOADABLE_FROM_SERVER = [1, 5, 15, 30, 60]

        const dateText = DateUtils.IsEmpty(this._startDate) ? 'veryBeginning' : DateUtils.DF(this._startDate)
        console.warn(
            'Comparing case:',
            this._symbol,
            'build',
            this._biggerTF,
            'from',
            this._smallerTF,
            'date:',
            dateText,
            'chunk number:',
            this._startChunkNumber
        )
        this.Status = EComparisonStatus.InProgress

        //better to get new data arrays for each test so they will be destroyed after the test and no memory leaks will be possible
        this.barsArray_downloaded = this.GetNewBarsArray(this._symbol, this._biggerTF)
        this.barsArray_built = this.GetNewBarsArray(this._symbol, this._biggerTF)

        this.barsArray_downloaded.SetName('Downloaded bars')
        this.barsArray_built.SetName('Built bars')

        this.CompareBarsArraysWhenMapsAreReady()
    }

    private get SymbolData() {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(this._symbol)
    }

    private GetSmallerBarsArray() {
        this.SymbolData.EnsureTimeframeIsActive(this._smallerTF)
        return this.SymbolData.GetOrCreateBarArray(this._smallerTF)
    }

    private CompareBarsArraysWhenMapsAreReady() {
        this._deferralEnabled = false

        if (!this.barsArray_downloaded || !this.barsArray_built) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            this.Events.EmitEvent(EComparisonEvent.ErrorDetected, 'Bars arrays are not initialized')
            return
        }

        const smallerTFBarsArray = this.GetSmallerBarsArray()

        console.log(
            'trying to start comparison, seek status D:',
            this.barsArray_downloaded.IsSeeked,
            'B:',
            this.barsArray_built.IsSeeked,
            'S:',
            smallerTFBarsArray.IsSeeked,
            'symbol:',
            this._symbol,
            'bigger TF:',
            this._biggerTF,
            'smaller TF:',
            this._smallerTF
        )

        //wait till all 3 bar array maps are loaded
        if (
            this.barsArray_downloaded.IsSeeked &&
            this.barsArray_built.IsSeeked &&
            smallerTFBarsArray.IsSeeked &&
            !this._comparisonStarted
        ) {
            //since these bar arrays are unattached to SymbolData we need to correct first and last dates manually:
            this.barsArray_downloaded.CorrectFirstAndLastDates()
            this.barsArray_built.CorrectFirstAndLastDates()

            this.lastChunkIndex = this.GetFirstChunkIndexToCompare()
            console.warn(
                `Maps are ready, starting to compare from chunk # ${this.lastChunkIndex} bigger TF: ${this._biggerTF} smaller TF: ${this._smallerTF}`
            )
            this._comparisonStarted = true

            //set smaller timeframes that can be used as a source for building this bigger TF
            CommonConstants.TIMEFRAMES_DOWNLOADABLE_FROM_SERVER = [this._smallerTF]

            this.DownloadAndCompareNextChunk()
        } else {
            //we need this because these are not internal bar arrays so they need additional push to be seeked

            // it is hard to achieve this with just events because when we call Seek here for barsArray_downloaded and barsArray_built we can start DOWNLOADING the same chunk
            // it will not be the same-same (because the chunk object will belong to different arrays), but the url will be the same, so the DownloadController may not start downloading chunk for the second array
            // therefore we may have a situation when the first array is seeked and the second is not. In this case one of the arrays will be seeked later and it again can interfere with downloading chunks
            // so, let's just defer seek and make sure everything is ready to go
            this.TryToSeekAll3Arrays()
            this.TryAgainInASecond()
        }
    }

    private TryToSeekAll3Arrays() {
        if (!this.barsArray_downloaded || !this.barsArray_built) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            this.Events.EmitEvent(EComparisonEvent.ErrorDetected, 'Bars arrays are not initialized')
            return
        }

        const smallerTFBarsArray = this.GetSmallerBarsArray()

        try {
            const someDate = DateUtils.EncodeDate(2020, 1, 1)

            if (this.barsArray_downloaded.IsSeeked) {
                this.UnstuckIfNecessary(this.barsArray_built, this.barsArray_downloaded)
            } else {
                this.barsArray_downloaded.InitMapIfNecessary()
                this.barsArray_downloaded.Seek(someDate)
            }
            if (this.barsArray_built.IsSeeked) {
                this.UnstuckIfNecessary(this.barsArray_downloaded, this.barsArray_built)
            } else {
                this.barsArray_built.InitMapIfNecessary()
                this.barsArray_built.Seek(someDate)
            }
            if (!smallerTFBarsArray.IsSeeked) {
                smallerTFBarsArray.InitMapIfNecessary()
                smallerTFBarsArray.Seek(someDate)
            }
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                //let's wait, the seek will be deferred inside SymbolData
            } else {
                throw error
            }
        }
    }

    private UnstuckIfNecessary(barsArrayToUnstuck: IFMBarsArray, alreadySeekedBarsArray: IFMBarsArray): void {
        if (barsArrayToUnstuck.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
            const seekDate = alreadySeekedBarsArray.LastItemInTesting.DateTime
            const stuckChunk = barsArrayToUnstuck.GetChunkByDate(seekDate)
            if (stuckChunk && stuckChunk.Status !== TChunkStatus.cs_Loaded) {
                stuckChunk.Status = TChunkStatus.cs_Empty
                DownloadController.Instance.loadHistoryIfNecessary(stuckChunk)
            }
        }
    }

    private TryAgainInASecond() {
        this._deferralEnabled = true

        setTimeout(() => {
            if (this._deferralEnabled) {
                console.log('Deferral of the comparison happened, trying again')
                this.CompareBarsArraysWhenMapsAreReady()
            }
        }, 1000)
    }

    private GetFirstChunkIndexToCompare(): number {
        if (!this.barsArray_downloaded) {
            throw new StrangeError('Bars array downloaded is not initialized')
        }

        if (this._startChunkNumber > 0) {
            return this._startChunkNumber
        }

        this._startDate = Math.max(this._startDate, this.SymbolData.VeryFirstDateInHistory)

        const chunkIndexByDate = this.barsArray_downloaded.GetChunkIndexByDate(this._startDate)

        if (chunkIndexByDate >= 0) {
            return chunkIndexByDate
        } else {
            return 0
        }
    }

    private DownloadAndCompareNextChunk() {
        if (!this.barsArray_downloaded || !this.barsArray_built) {
            throw new StrangeError('Bars arrays are not initialized')
            return
        }

        //this.lastChunkIndex >= this.barsArray_downloaded.Chunks.length
        //this.lastChunkIndex >= 100 || this.lastChunkIndex
        if (this.lastChunkIndex >= this.barsArray_downloaded.Chunks.length) {
            if (this._status !== EComparisonStatus.Failed) {
                this._report = 'Comparison successful'
                this._status = EComparisonStatus.Successful
            }
            this.Events.EmitEvent(EComparisonEvent.Finished)
            return
        }

        const chunkToDownload = this.barsArray_downloaded.Chunks[this.lastChunkIndex]
        const chunkToBuild = this.barsArray_built.Chunks[this.lastChunkIndex]

        if (chunkToBuild.Status === TChunkStatus.cs_Loaded && chunkToDownload.Status === TChunkStatus.cs_Loaded) {
            this.CompareChunksAtAndDownloadNext()
        } else {
            this.downloadAndBuildChunks(chunkToDownload, chunkToBuild)
        }
    }

    private downloadAndBuildChunks(chunkToDownload: TBarChunk, chunkToBuild: TBarChunk) {
        if (chunkToDownload.Status !== TChunkStatus.cs_Loaded) {
            this.SubscribeToChunkEvents(chunkToDownload)
            DownloadController.Instance.loadHistoryIfNecessary(chunkToDownload)
        }
        if (chunkToBuild.Status !== TChunkStatus.cs_Loaded) {
            this.SubscribeToChunkEvents(chunkToBuild)
            ChunkBuilder.Instance.BuildChunk(chunkToBuild, false)
        }
    }

    private SubscribeToChunkEvents(chunk: TBarChunk): void {
        chunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundCompareChunksAtAndDownloadNext)
        chunk.Events.on(TDataArrayEvents.de_LoadingErrorHappened, this.boundHandleChunkLoadingError)
    }

    private boundHandleChunkLoadingError = this.HandleChunkLoadingError.bind(this)
    private HandleChunkLoadingError(chunk: TBarChunk, error?: Error) {
        if (!chunk) {
            throw new StrangeError('Chunk is not defined in HandleChunkLoadingError')
        }

        let errorText = ''
        if (error instanceof Error) {
            errorText = error.message
        }
        this.Events.EmitEvent(
            EComparisonEvent.ErrorDetected,
            `Error during chunk loading, chunk#${this.lastChunkIndex}, ${chunk.DName} error text: ${errorText}`
        )
        //let's continue checking other chunks
        this.lastChunkIndex++
        this.DownloadAndCompareNextChunk()
    }

    private boundCompareChunksAtAndDownloadNext = this.CompareChunksAtAndDownloadNext.bind(this)
    private CompareChunksAtAndDownloadNext() {
        if (!this.barsArray_downloaded || !this.barsArray_built) {
            this.Events.EmitEvent(EComparisonEvent.ErrorDetected, 'Bars arrays are not initialized')
            return
        }

        const chunkToDownload = this.barsArray_downloaded.Chunks[this.lastChunkIndex]
        const chunkToBuild = this.barsArray_built.Chunks[this.lastChunkIndex]

        try {
            if (chunkToDownload.Status === TChunkStatus.cs_Loaded && chunkToBuild.Status === TChunkStatus.cs_Loaded) {
                const comparisonStatus = this.CompareChunks(chunkToDownload, chunkToBuild)
                // uncomment this if you want to stop after first error
                // if (comparisonStatus === EComparisonStatus.Successful) {
                // } else {
                //     console.error('Comparison failed, stopping the test for this case')
                //     return
                // }

                //continue with the next chunk even if the comparison failed
                this.lastChunkIndex++
                this.DownloadAndCompareNextChunk()
            }
        } catch (error) {
            this.Events.EmitEvent(
                EComparisonEvent.ErrorDetected,
                `Error during comparison: ${(error as Error).message} at chunk ${this.lastChunkIndex}`
            )
            this.lastChunkIndex++
            this.DownloadAndCompareNextChunk()
        }
    }

    private CompareChunks(downloadedChunk: TBarChunk, builtChunk: TBarChunk): EComparisonStatus {
        const downloadedChunkCount = (downloadedChunk as any)._data.length
        const builtChunkCount = (builtChunk as any)._data.length

        if (downloadedChunkCount !== builtChunkCount) {
            this.Events.EmitEvent(
                EComparisonEvent.ErrorDetected,
                `Chunk bar counts are different for ${DateUtils.DF(
                    builtChunk.FirstDate
                )}. Downloaded: ${downloadedChunkCount} Built: ${builtChunkCount}`
            )
            return EComparisonStatus.Failed
        }

        const areBarsDifferent = this.AreBarsDifferentInsideChunks(downloadedChunk, builtChunk)

        if (areBarsDifferent) {
            console.log(
                `Different chunk # ${this.lastChunkIndex} at ${DateUtils.DF(builtChunk.FirstDate)} for TF: ${
                    this._biggerTF
                }`
            )
            //the event will come from the AreBarsDifferentInsideChunks method
            return EComparisonStatus.Failed
        } else {
            console.log(
                `Same chunk # ${this.lastChunkIndex} at ${DateUtils.DF(builtChunk.FirstDate)} bigger TF: ${
                    this._biggerTF
                } smaller TF: ${this._smallerTF}`
            )
            return EComparisonStatus.Successful
        }
    }

    AreBarsDifferentInsideChunks(downloadedChunk: TBarChunk, builtChunk: TBarChunk): boolean {
        let currentBarGloablIndex = downloadedChunk.FirstGlobalIndex
        const maxGlobalIndexInChunk = downloadedChunk.FirstGlobalIndex + downloadedChunk.Count - 1

        for (currentBarGloablIndex; currentBarGloablIndex <= maxGlobalIndexInChunk; currentBarGloablIndex++) {
            const downloadedBar = downloadedChunk.GetItemByGlobalIndex(currentBarGloablIndex)
            const builtBar = builtChunk.GetItemByGlobalIndex(currentBarGloablIndex)

            if (!downloadedBar && !builtBar) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Both bars are missing at index ${currentBarGloablIndex}`
                )
                return true
            }

            if (!downloadedBar) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Downloaded bar is missing at index ${currentBarGloablIndex} date:${DateUtils.DF(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        builtBar!.DateTime
                    )}`
                )
                return true
            }

            if (!builtBar) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Built bar is missing at index ${currentBarGloablIndex} date:${DateUtils.DF(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        downloadedBar!.DateTime
                    )}`
                )
                return true
            }

            if (!DateUtils.AreEqual(downloadedBar.DateTime, builtBar.DateTime)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Bars are different at index ${currentBarGloablIndex} Downloaded bar date: ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} Built bar date:${DateUtils.DF(builtBar.DateTime)}`
                )
                return true
            }

            if (!MathUtils.isEqualRounded(downloadedBar.open, builtBar.open, this._symbolDecimals)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Open is different at date ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} bar index: ${currentBarGloablIndex} downloaded: ${MathUtils.roundTo(
                        downloadedBar.open,
                        this._symbolDecimals
                    )} built: ${MathUtils.roundTo(builtBar.open, this._symbolDecimals)}`
                )
                return true
            }

            if (!MathUtils.isEqualRounded(downloadedBar.high, builtBar.high, this._symbolDecimals)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `High is different at date ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} bar index: ${currentBarGloablIndex} downloaded: ${MathUtils.roundTo(
                        downloadedBar.high,
                        this._symbolDecimals
                    )} built: ${MathUtils.roundTo(builtBar.high, this._symbolDecimals)}`
                )
                return true
            }

            if (!MathUtils.isEqualRounded(downloadedBar.low, builtBar.low, this._symbolDecimals)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Low is different at date ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} bar index: ${currentBarGloablIndex} downloaded: ${MathUtils.roundTo(
                        downloadedBar.low,
                        this._symbolDecimals
                    )} built: ${MathUtils.roundTo(builtBar.low, this._symbolDecimals)}`
                )
                return true
            }

            if (!MathUtils.isEqualRounded(downloadedBar.close, builtBar.close, this._symbolDecimals)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Close is different at date ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} bar index: ${currentBarGloablIndex} downloaded: ${MathUtils.roundTo(
                        downloadedBar.close,
                        this._symbolDecimals
                    )} built: ${MathUtils.roundTo(builtBar.close, this._symbolDecimals)}`
                )
                return true
            }

            if (!MathUtils.isEqualRounded(downloadedBar.volume, builtBar.volume, 0)) {
                this.Events.EmitEvent(
                    EComparisonEvent.ErrorDetected,
                    `Volume is different at date ${DateUtils.DF(
                        downloadedBar.DateTime
                    )} bar index: ${currentBarGloablIndex} downloaded: ${MathUtils.roundTo(
                        downloadedBar.volume,
                        0
                    )} built: ${MathUtils.roundTo(builtBar.volume, 0)}`
                )
                return true
            }

            currentBarGloablIndex++
        }
        return false
    }

    public releaseEverything(): void {
        this.releaseEvents()
        if (this.barsArray_downloaded) {
            this.barsArray_downloaded.ClearDataInChunks()
            for (const chunk of this.barsArray_downloaded.Chunks) {
                chunk.Events.releaseAllEvents()
            }
        }
        if (this.barsArray_built) {
            this.barsArray_built.ClearDataInChunks()
            for (const chunk of this.barsArray_built.Chunks) {
                chunk.Events.releaseAllEvents()
            }
        }
        this.barsArray_downloaded = undefined

        this.barsArray_built = undefined
    }

    private releaseEvents() {
        this.Events.releaseAllEvents()
    }
}
