import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { IAccountInfoProcRec } from '../CommonProcRecInterfaces/IAccountInfoProcRec'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class AccountInfoProcRecImplementation extends BasicProcRecImplementation {
    public GetImplementation(): IAccountInfoProcRec {
        return {
            AccountBalance: this.AccountBalance.bind(this),
            AccountEquity: this.AccountEquity.bind(this),
            AccountMargin: this.AccountMargin.bind(this),
            AccountFreeMargin: this.AccountFreeMargin.bind(this),
            AccountLeverage: this.AccountLeverage.bind(this),
            AccountProfit: this.AccountProfit.bind(this)
        }
    }

    protected override generateDName(): string {
        return `AccountInfoProcRec_${super.generateDName()}`
    }

    public AccountBalance(): number {
        return GlobalProcessingCore.ProcessingCore.Balance
    }

    public AccountEquity(): number {
        return GlobalProcessingCore.ProcessingCore.Equity
    }

    public AccountMargin(): number {
        return GlobalProcessingCore.ProcessingCore.Margin
    }

    public AccountFreeMargin(): number {
        return GlobalProcessingCore.ProcessingCore.FreeMargin
    }

    public AccountLeverage(): number {
        throw new NotImplementedError('AccountLeverage is not implemented yet')
    }

    public AccountProfit(): number {
        return GlobalProcessingCore.ProcessingCore.Equity - GlobalProcessingCore.ProcessingCore.Deposit
    }
}
