export const initialCells = [
    {
        title: 'symbol',
        flex: 7,
        active: true
    },
    {
        title: 'last',
        flex: 4,
        active: true
    },
    {
        title: 'change',
        flex: 4,
        active: true
    },
    {
        title: 'changePercent',
        flex: 4,
        active: true
    }
]
