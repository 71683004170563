import { useDebounceHook } from '@root/hooks/useDebounce'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getLocalStorage, setLocalStorage, UserTerminalSettings } from '@root/utils/localStorage'
import { useSearchParams } from 'react-router-dom'
import { TERMINAL_COLUMNS_SETTINGS } from '@root/constants/localStorageKeys'
import { defaultTableColumnsSettings } from '@root/pages/ChartPage/components/Terminal/components/Table/utils'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

type TableSettingsHookType = () => {
    tableSettings: UserTerminalSettings
    setTableSettings: Dispatch<SetStateAction<UserTerminalSettings>>
}

const useTableSettingsHook: TableSettingsHookType = () => {
    const [tableSettings, setTableSettings] = useState<UserTerminalSettings>(defaultTableColumnsSettings)
    const [keyName, setKeyName] = useState('')
    const [searchParams] = useSearchParams()
    const { userId } = useAppSelector($getUser)
    const debounce = useDebounceHook(tableSettings, 300)

    useEffect(() => {
        const projectId = searchParams.get('projectId')
        if (!projectId) return
        setKeyName(userId ? `${userId}_${projectId}_${TERMINAL_COLUMNS_SETTINGS}` : '')
    }, [userId])

    useEffect(() => {
        if (keyName) {
            setTableSettings(getLocalStorage<UserTerminalSettings>(keyName) || defaultTableColumnsSettings)
        }
    }, [keyName])

    useEffect(() => {
        if (keyName) {
            setLocalStorage(keyName, tableSettings)
        }
    }, [debounce, keyName])

    return {
        tableSettings,
        setTableSettings
    }
}

export default useTableSettingsHook
