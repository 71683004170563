import { useMemo, useCallback, FC, Dispatch, SetStateAction } from 'react'

import { LineSelectorValueType, LineStyleSelector, LineStyleSelectorProps } from '@fto/ui'

import { LineStylesType } from '../../../../types'
import { mapPrevStyles } from './utils/index'
import TriggerElement from './components/TriggerElement'

import styles from './index.module.scss'

type Props = {
    tools: any[]
    setLineStyles: Dispatch<SetStateAction<LineStylesType>>
    lineStyleValue: number
    type: 'style' | 'width'
    selectorType: LineStyleSelectorProps['type']
}

const LineStyleBlock: FC<Props> = ({ tools, selectorType, setLineStyles, lineStyleValue, type }) => {
    const [singleTool] = tools

    const isMultiStyled = useMemo(() => {
        const hasCalculatedDifferentValues = tools.some((tool) => {
            const toolLineStyles = tool.getLineStyleParams()

            return toolLineStyles[type].hasDifferentValues
        })

        const allLineStylesByType = new Set(
            tools.map((tool) => {
                const toolLineStyles = tool.getLineStyleParams()

                return toolLineStyles[type].value
            })
        )

        return hasCalculatedDifferentValues || (tools.length > 1 && Array.from(allLineStylesByType).length > 1)
    }, [tools, lineStyleValue, type])

    const value = useMemo(() => {
        if (tools.length > 1 && !isMultiStyled) {
            return singleTool.getLineStyleParams()[type].value
        }

        if (isMultiStyled) {
            // NOTE: this is MAGIC value to make selector unselected
            return 999
        }

        return lineStyleValue
    }, [tools, lineStyleValue, type, isMultiStyled, singleTool])

    const handleLineStyleChange = useCallback(
        (value: LineSelectorValueType) => {
            tools.forEach((tool) => {
                const toolLineStyles = tool.getLineStyleParams()
                tool.setLineStylesParams({ ...mapPrevStyles(toolLineStyles), [type]: value, byKey: type })
            })

            setLineStyles((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState[type],
                    value: value
                }
            }))
        },
        [tools, setLineStyles]
    )

    const rootClassName = useMemo(() => {
        if (isMultiStyled) {
            return styles.MultiState
        }

        if (type === 'width') {
            return styles.WidthLineSelector
        }

        return styles.StyleLineSelector
    }, [isMultiStyled, type])

    return (
        <LineStyleSelector
            withArrow={false}
            onChange={handleLineStyleChange}
            value={value}
            type={selectorType}
            previewLineWidth={32}
            classNames={{ root: rootClassName }}
            disableBackdropChartActivity
        >
            <TriggerElement value={value} isMultiLined={isMultiStyled} type={type} />
        </LineStyleSelector>
    )
}

export default LineStyleBlock
