import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'

import { Checkbox, Flex, Typography } from '@fto/ui'

import {
    EXCLUDED_HISTORY_COLUMNS,
    EXCLUDED_OPEN_POSITION_COLUMNS,
    EXCLUDED_PENDING_ORDERS_COLUMNS,
    HISTORY_CELLS_LIST,
    OPEN_POSITION_CELLS_LIST,
    PENDING_ORDERS_CELLS_LIST
} from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'
import { AllAvailableColumnsType, ColumnsListType, TabType } from '@root/pages/ChartPage/components/Terminal/types'

import styles from './index.module.scss'
import { UserTerminalSettings } from '@root/utils/localStorage'
import { useTranslation } from 'react-i18next'

type Props = {
    cellsList: ColumnsListType
    setCellsList: Dispatch<SetStateAction<ColumnsListType>>
    activeTab: TabType
    tableColumns: UserTerminalSettings
    setTableColumns: Dispatch<SetStateAction<UserTerminalSettings>>
}

const CellsSelector: FC<Props> = ({ cellsList = [], activeTab, tableColumns, setTableColumns }) => {
    const { t } = useTranslation()
    const handleColumnToggle = useCallback(
        (cellData: ColumnsListType[number]) => {
            const { width, isHidden } = tableColumns[activeTab].settings[cellData.value]

            const newTablesData: UserTerminalSettings = {
                ...tableColumns,
                [activeTab]: {
                    ...tableColumns[activeTab],
                    settings: {
                        ...tableColumns[activeTab].settings,
                        [cellData.value]: {
                            width,
                            isHidden: !isHidden
                        }
                    }
                }
            }
            setTableColumns(newTablesData)
        },
        [activeTab, tableColumns]
    )

    const cellsToHideOrShow = useMemo(() => {
        const listParams = {
            open: {
                list: OPEN_POSITION_CELLS_LIST,
                excluded: EXCLUDED_OPEN_POSITION_COLUMNS
            },
            pending: {
                list: PENDING_ORDERS_CELLS_LIST,
                excluded: EXCLUDED_PENDING_ORDERS_COLUMNS
            },
            history: {
                list: HISTORY_CELLS_LIST,
                excluded: EXCLUDED_HISTORY_COLUMNS
            }
        }

        const { list, excluded } = listParams[activeTab]

        return list.filter((column) => !excluded.includes(column.value))
    }, [activeTab])

    return (
        <Flex className={styles.CellsSelector} direction='column' gap={4}>
            <Flex className={styles.Header} alignItems='center'>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('terminal.cellsSelectionHeader')}
                </Typography>
            </Flex>

            {cellsToHideOrShow.map((cell) => (
                <Flex
                    gap={8}
                    alignItems='center'
                    key={cell.value}
                    className={styles.Option}
                    onClick={(event) => {
                        event.stopPropagation()
                        handleColumnToggle(cell)
                    }}
                >
                    <Checkbox
                        checked={!tableColumns[activeTab]?.settings[cell.value]?.isHidden}
                        onChange={(_, event) => {
                            event.stopPropagation()
                            handleColumnToggle(cell)
                        }}
                    />
                    <Typography type='interface-medium' color='gray-1000'>
                        {t(`terminal.tableCells.${cell.value}`)}
                    </Typography>
                </Flex>
            ))}
        </Flex>
    )
}

export default CellsSelector
