import styles from '../../../../common.module.scss'
import { DropdownIcon } from '../DropdownIcon'
import { DropdownMenu } from '../DropdownMenu'
import { FilenameTypes } from '@fto/icons'
import { Tooltip } from '@fto/ui'
import {
    OptionType,
    TooltipTrigger
} from 'packages/fto/root/src/chart_components/ProjectInterface/components/ToolsSidebar/types'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

type DropdownProps = {
    collection: {
        list: () => OptionType[]
        getName: () => string
    }
    activeToolName: string | null
    setActiveToolName: (name: string | null) => void
    registerPaintTool: (buttonName: string) => void
    additionalCategory?: {
        list: () => OptionType[]
        getName: () => string
    }
}

const ToolsDropdown: FC<DropdownProps> = ({
    collection,
    activeToolName,
    setActiveToolName,
    registerPaintTool,
    additionalCategory
}) => {
    const [toolIcon, setToolIcon] = useState(collection.list()[0].icon)

    const selectedTool = useMemo(
        () =>
            collection.list().find((tool) => tool.icon === toolIcon) ??
            additionalCategory?.list().find((tool) => tool.icon === toolIcon),
        [collection, activeToolName, toolIcon]
    )

    const [tooltipTrigger, setTooltipTrigger] = useState<TooltipTrigger>({
        isOpen: undefined,
        closedFromMenu: false
    })

    const action = useCallback(
        (option: { name: string; icon: (typeof FilenameTypes)[number] | null; toolKey: string }) => {
            setToolIcon(option.icon)
            setActiveToolName(option.toolKey)
            registerPaintTool(option.toolKey)

            setTooltipTrigger({ isOpen: false, closedFromMenu: true })
        },
        [setActiveToolName, registerPaintTool]
    )

    const resetTooltipState = useCallback(() => {
        setTooltipTrigger({ isOpen: undefined, closedFromMenu: false })
    }, [])

    useEffect(() => {
        if (tooltipTrigger.isOpen === false && tooltipTrigger.closedFromMenu) {
            resetTooltipState()
        }
    }, [tooltipTrigger])

    return (
        <Tooltip
            content={
                <>
                    <DropdownMenu
                        name={collection.getName()}
                        options={collection.list()}
                        action={action}
                        activeToolName={activeToolName}
                    />
                    {additionalCategory && (
                        <DropdownMenu
                            name={additionalCategory.getName()}
                            options={additionalCategory.list()}
                            action={action}
                            activeToolName={activeToolName}
                        />
                    )}
                </>
            }
            interactive
            arrow={false}
            placement='right-start'
            classNames={{ tooltip: styles.dropdownTooltip }}
            color='light'
            open={tooltipTrigger.isOpen}
        >
            <DropdownIcon
                onClick={() => {
                    registerPaintTool(selectedTool?.toolKey || '')
                    setActiveToolName(selectedTool?.toolKey || '')
                    setTooltipTrigger({ isOpen: false, closedFromMenu: false })
                }}
                active={activeToolName === selectedTool?.toolKey}
                icon={toolIcon!}
                resetTooltipState={resetTooltipState}
            />
        </Tooltip>
    )
}

export default ToolsDropdown
