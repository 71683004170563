import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { IDateTimeProcRec } from '../CommonProcRecInterfaces/IDateTimeProcRec'
import { TDateTime } from '../CommonTypes'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class DateTimeProcRecImplementation extends BasicProcRecImplementation implements IDateTimeProcRec {
    public GetImplementation(): IDateTimeProcRec {
        return {
            GetDayOfMonthByDateTime: this.GetDayOfMonthByDateTime.bind(this),
            GetWeekByDateTime: this.GetWeekByDateTime.bind(this),
            GetMonthByDateTime: this.GetMonthByDateTime.bind(this),
            GetYearByDateTime: this.GetYearByDateTime.bind(this),
            TimeCurrent: this.TimeCurrent.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_DateTime_${super.generateDName()}`
    }

    public GetDayOfMonthByDateTime(time: TDateTime): number {
        return DateUtils.DayOfMonth(time)
    }

    public GetMonthByDateTime(time: TDateTime): number {
        return DateUtils.MonthOf(time)
    }

    public GetWeekByDateTime(time: TDateTime): number {
        return DateUtils.WeekOf(time)
    }

    public GetYearByDateTime(time: TDateTime): number {
        return DateUtils.YearOf(time)
    }

    public TimeCurrent(): TDateTime {
        return GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
    }
}
