//string keys because we need "-"" symbol in them, "_" symbol will create problems downstream
export const LANGUAGES = {
    en: { value: 'en' },
    ach: { value: 'ach' },
    ja: { value: 'ja' },
    uk: { value: 'uk' },
    ru: { value: 'ru' },
    'es-ES': { value: 'es-ES' },
    'es-419': { value: 'es-419' },
    'zh-CN': { value: 'zh-CN' },
    'zh-TW': { value: 'zh-TW' },
    'zh-HK': { value: 'zh-HK' },
    'fr-FR': { value: 'fr-FR' },
    'fr-CA': { value: 'fr-CA' },
    hi: { value: 'hi' },
    'bn-IN': { value: 'bn-IN' },
    'bn-BD': { value: 'bn-BD' },
    vi: { value: 'vi' },
    de: { value: 'de' },
    ko: { value: 'ko' },
    tr: { value: 'tr' },
    pl: { value: 'pl' },
    'id-ID': { value: 'id-ID' },
    'ms-MY': { value: 'ms-MY' },
    it: { value: 'it' },
    ur: { value: 'ur' },
    te: { value: 'te' },
    'pt-PT': { value: 'pt-PT' },
    'pt-BR': { value: 'pt-BR' },
    tl: { value: 'tl' },
    'ta-IN': { value: 'ta-IN' },
    'ta-LK': { value: 'ta-LK' },
    ha: { value: 'ha' },
    th: { value: 'th' },
    ro: { value: 'ro' },
    yo: { value: 'yo' },
    ig: { value: 'ig' },
    'nl-NL': { value: 'nl-NL' },
    'nl-BE': { value: 'nl-BE' },
    'kk-Cyrl': { value: 'kk-Cyrl' },
    'kk-Latn': { value: 'kk-Latn' },
    am: { value: 'am' },
    az: { value: 'az' },
    my: { value: 'my' },
    'sw-KE': { value: 'sw-KE' },
    'sw-TZ': { value: 'sw-TZ' },
    hu: { value: 'hu' },
    'uz-Cyrl': { value: 'uz-Cyrl' },
    'uz-Latn': { value: 'uz-Latn' },
    el: { value: 'el' },
    he: { value: 'he' },
    zu: { value: 'zu' },
    sv: { value: 'sv' },
    cs: { value: 'cs' },
    'sr-Cyrl': { value: 'sr-Cyrl' },
    'sr-Latn': { value: 'sr-Latn' },
    ka: { value: 'ka' },
    hy: { value: 'hy' },
    si: { value: 'si' },
    km: { value: 'km' },
    bg: { value: 'bg' },
    fi: { value: 'fi' },
    no: { value: 'no' },
    da: { value: 'da' },
    sk: { value: 'sk' },
    hr: { value: 'hr' },
    tk: { value: 'tk' },
    tg: { value: 'tg' },
    mn: { value: 'mn' },
    sq: { value: 'sq' },
    lv: { value: 'lv' },
    bs: { value: 'bs' },
    ky: { value: 'ky' },
    lt: { value: 'lt' },
    et: { value: 'et' },
    sl: { value: 'sl' },
    mk: { value: 'mk' },
    is: { value: 'is' },
    'ar-EG': { value: 'ar-EG' },
    'ar-SA': { value: 'ar-SA' },
    'ar-MA': { value: 'ar-MA' }
}

export type LanguageKey = keyof typeof LANGUAGES
