export class TPointPair {
    x: number
    y1: number
    y2: number

    constructor(x: number, y1: number, y2: number) {
        this.x = x
        this.y1 = y1
        this.y2 = y2
    }
}
