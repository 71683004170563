import { TOperationType, TTrailingStopType } from './BasicEnums'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export default class BasicClasses_CommonFunctions {
    static ConvertOp(PosType: TTradePositionType): TOperationType {
        // Translated from Delphi
        if (
            PosType === TTradePositionType.tp_Buy ||
            PosType === TTradePositionType.tp_BuyLimit ||
            PosType === TTradePositionType.tp_BuyStop
        ) {
            return TOperationType.ot_Buy
        } else {
            return TOperationType.ot_Sell
        }
    }

    static InvertOp(OpType: TOperationType): TOperationType {
        return OpType === TOperationType.ot_Sell ? TOperationType.ot_Buy : TOperationType.ot_Sell
    }

    static StrTrailingStopType(tsType: TTrailingStopType): string {
        switch (tsType) {
            case TTrailingStopType.ts_Immediate:
                return 'immediate'
            default:
                return 'on profit'
        }
    }

    static GetTrailingStopType(s: string): TTrailingStopType {
        // Preserving the logic from Delphi code
        if (s.trim() === 'immediate') {
            return TTrailingStopType.ts_Immediate
        } else {
            return TTrailingStopType.ts_OnProfit
        }
    }

    static GetPosType(s: string): TTradePositionType {
        s = s.trim().toLowerCase()

        switch (s) {
            case 'sell':
                return TTradePositionType.tp_Sell
            case 'buy':
                return TTradePositionType.tp_Buy
            case 'buy limit':
                return TTradePositionType.tp_BuyLimit
            case 'sell limit':
                return TTradePositionType.tp_SellLimit
            case 'buy stop':
                return TTradePositionType.tp_BuyStop
            case 'sell stop':
                return TTradePositionType.tp_SellStop
            case 'deposit':
                return TTradePositionType.tp_Deposit
            case 'withdrawal':
                return TTradePositionType.tp_Withdrawal
            default:
                return TTradePositionType.tp_Credit
        }
    }
}
