import { useState, useEffect, RefObject, useLayoutEffect } from 'react'

const useAdjustContextMenuPosition = (
    open: boolean,
    anchorX: number,
    anchorY: number,
    contextMenuRef: RefObject<HTMLDivElement>,
    direction: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'bottom-right'
) => {
    const [adjustedPosition, setAdjustedPosition] = useState<{ x: number; y: number }>({ x: anchorX, y: anchorY })

    useLayoutEffect(() => {
        if (open) {
            setAdjustedPosition(getCoordinatesForDirection(anchorX, anchorY, direction, contextMenuRef))
        }
    }, [open, anchorX, anchorY])

    return adjustedPosition
}

const getCoordinatesForDirection = (
    anchorX: number,
    anchorY: number,
    direction: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'bottom-right',
    contextMenuRef: React.RefObject<HTMLDivElement>
) => {
    const { width, height } = contextMenuRef.current!.getBoundingClientRect()
    const { clientWidth, clientHeight } = contextMenuRef.current!
    const { innerWidth, innerHeight } = window

    let x = direction === 'top-left' || direction === 'bottom-left' ? anchorX - width : anchorX
    let y = direction === 'top-left' || direction === 'top-right' ? anchorY - height : anchorY

    if (x + clientWidth > innerWidth) {
        x = innerWidth - clientWidth
    }

    if (y + clientHeight > innerHeight) {
        y = innerHeight - clientHeight
    }

    if (y < 0) {
        y = 0
    }

    return { x, y }
}

export default useAdjustContextMenuPosition
