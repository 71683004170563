// NOTE: This is list of controlled nodes where we should not fire graph events

export const SELECTORS = {
    DIALOG: {
        className: '',
        selector: 'dialog'
    },
    TRIGGER_OVERLAY: {
        className: 'GLOBAL_TriggerOverlay',
        selector: '.GLOBAL_TriggerOverlay'
    },
    TRIGGER_OVERLAY_BACK_DROP: {
        className: 'GLOBAL_TriggerOverlay_BackDrop',
        selector: '.GLOBAL_TriggerOverlay_BackDrop'
    },
    TOOLTIP: {
        className: 'GLOBAL_Tooltip',
        selector: '.GLOBAL_Tooltip'
    },
    TESTING_PANEL: {
        className: 'GLOBAL_TestingPanel',
        selector: '.GLOBAL_TestingPanel'
    },
    CONTEXT_MENU: {
        className: 'GLOBAL_ContextMenu',
        selector: '.GLOBAL_ContextMenu'
    },
    TERMINAL: {
        className: 'GLOBAL_Terminal',
        selector: '.GLOBAL_Terminal'
    }
}
export const NODES_TO_PREVENT_GRAPH_EVENTS = [
    SELECTORS.DIALOG.selector,
    SELECTORS.TRIGGER_OVERLAY.selector,
    SELECTORS.TOOLTIP.selector,
    SELECTORS.TESTING_PANEL.selector,
    SELECTORS.TRIGGER_OVERLAY_BACK_DROP.selector,
    SELECTORS.CONTEXT_MENU.selector,
    SELECTORS.TERMINAL.selector
]
