import { TCriteriaOperator } from '@fto/lib/Education/Types'

export class TutorialCheckBase {
    public criteriaOperator: TCriteriaOperator
    public value: number | { valueStart: number; valueEnd: number }
    constructor(criteriaOperator: TCriteriaOperator, value: number | { valueStart: number; valueEnd: number }) {
        this.criteriaOperator = criteriaOperator
        this.value = value
    }

    checkEqual(): boolean {
        return true
    }

    checkMore(): boolean {
        return true
    }

    checkLess(): boolean {
        return true
    }

    checkNotEqual(): boolean {
        return true
    }

    checkMoreOrEqual(): boolean {
        return true
    }

    checkLessOrEqual(): boolean {
        return true
    }

    checkRange(): boolean {
        return true
    }

    public check(): boolean {
        switch (this.criteriaOperator) {
            case TCriteriaOperator.EQUAL:
                return this.checkEqual()
            case TCriteriaOperator.MORE:
                return this.checkMore()
            case TCriteriaOperator.LESS:
                return this.checkLess()
            case TCriteriaOperator.NOT_EQUAL:
                return this.checkNotEqual()
            case TCriteriaOperator.MORE_OR_EQUAL:
                return this.checkMoreOrEqual()
            case TCriteriaOperator.LESS_OR_EQUAL:
                return this.checkLessOrEqual()
            case TCriteriaOperator.IN_RANGE:
                return this.checkRange()
            default:
                return false
        }
    }
}
