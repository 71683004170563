import { TMyObjectList } from '../../common/Common'
import CommonConstants from '../../common/CommonConstants'
import { TBasicChunk } from './BasicChunk'

export class TListOfChunks<T extends TBasicChunk> extends TMyObjectList<T> {
    //TODO: optimize this for binary search
    GetChunkIndexByFirstDate(FirstDate: number) {
        for (let i = 0; i < this.Count; i++) {
            if (this[i].FirstDate === FirstDate) {
                return i
            }
        }
        return CommonConstants.EMPTY_INDEX
    }

    GetChunkByFirstDate(FirstDate: number) {
        const chunkIndex = this.GetChunkIndexByFirstDate(FirstDate)
        if (chunkIndex !== CommonConstants.EMPTY_INDEX) {
            return this[chunkIndex]
        }
        return null
    }

    public ChunkInList(chunk: T): boolean {
        return this.indexOf(chunk) !== -1
    }

    //TODO: optimize this for binary search
    public InsertChunkByDate(chunk: T): void {
        let i = 0
        while (i < this.Count) {
            if (chunk.FirstDate < this[i].FirstDate) {
                break
            }
            i++
        }
        this.Insert(i, chunk)
    }

    //TODO: optimize this for binary search
    InsertChunkByGlobalIndex(chunk: T): void {
        let i = 0
        while (i < this.Count) {
            if (chunk.FirstGlobalIndex < this[i].FirstGlobalIndex) {
                break
            }
            i++
        }
        this.Insert(i, chunk)
    }
}
