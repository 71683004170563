import { FC } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Flex, Tooltip, Typography, Separator, Counter } from '@fto/ui'
import { IconButton } from '@fto/icons'

import { TabType } from '../../types'

import styles from './index.module.scss'

type Props = {
    activeTab: TabType
    setActiveTab: (tab: TabType) => void
    isOpen: boolean
    handleTerminalToggle: () => void
    openOrdersLength: number
    pendingOrdersLength: number
}

const Tabs: FC<Props> = ({
    activeTab,
    setActiveTab,
    isOpen,
    handleTerminalToggle,
    openOrdersLength,
    pendingOrdersLength
}) => {
    const { t } = useTranslation()

    const onTabClick = (tab: TabType) => {
        setActiveTab(tab)

        if (activeTab === tab || !isOpen) {
            handleTerminalToggle()
        }
    }

    return (
        <Flex alignItems='center' gap={4} className={styles.tabsWrapper}>
            <Tooltip content={t('terminal.tooltips.openPositions')} placement='top'>
                <Flex
                    gap={4}
                    alignItems='center'
                    className={styles.tab}
                    justifyContent='center'
                    onClick={() => onTabClick('open')}
                >
                    <Typography type='interface-medium' color={activeTab === 'open' ? 'primary-500' : 'gray-1000'}>
                        {t('terminal.tabs.open')}
                    </Typography>
                    <Counter value={openOrdersLength} />
                </Flex>
            </Tooltip>

            <Separator height='24px' />

            <Tooltip content={t('terminal.tooltips.pendingOrders')} placement='top'>
                <Flex
                    gap={4}
                    alignItems='center'
                    className={styles.tab}
                    justifyContent='center'
                    onClick={() => onTabClick('pending')}
                >
                    <Typography type='interface-medium' color={activeTab === 'pending' ? 'primary-500' : 'gray-1000'}>
                        {t('terminal.tabs.pending')}
                    </Typography>
                    <Counter value={pendingOrdersLength} />
                </Flex>
            </Tooltip>

            <Separator height='24px' />

            <Tooltip content={t('terminal.tooltips.history')} placement='top'>
                <Flex
                    gap={4}
                    alignItems='center'
                    className={styles.tab}
                    justifyContent='center'
                    onClick={() => onTabClick('history')}
                >
                    <Typography type='interface-medium' color={activeTab === 'history' ? 'primary-500' : 'gray-1000'}>
                        {t('terminal.tabs.history')}
                    </Typography>
                </Flex>
            </Tooltip>

            <Separator height='24px' />
            <Tooltip
                content={isOpen ? t('terminal.tooltips.hideTerminal') : t('terminal.tooltips.showTerminal')}
                placement='top'
            >
                <Flex alignItems='center' justifyContent='center'>
                    <IconButton
                        name='chevron-down'
                        size={12}
                        color='var(--color-gray-900)'
                        className={cx(styles.arrow, { [styles.arrowUp]: isOpen })}
                        onClick={handleTerminalToggle}
                    />
                </Flex>
            </Tooltip>
        </Flex>
    )
}

export default Tabs
