import { FC } from 'react'

import { Button, TriggerOverlay } from '@fto/ui'

import { Icon } from '@fto/icons'
import SymbolOptionsRenderer from '@root/pages/Dashboard/components/SymbolsContextMenu/components/OptionsRenderer/SymbolOptionsRenderer'
import style from './components/OptionsRenderer/options-symbol-group.module.scss'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjectQueryParams } from '@root/store/projects/projects.selector'
import { Trans, useTranslation } from 'react-i18next'

type Props = {}

const SymbolsContextMenu: FC<Props> = () => {
    const { t } = useTranslation()

    const query = useAppSelector($getProjectQueryParams)

    const labelKey = () => {
        const symbolsQuantity = query?.Symbols?.split(',')?.length

        if (symbolsQuantity && symbolsQuantity > 1) {
            return 'dashboard.toolbar.symbol.labelMany'
        } else {
            return 'dashboard.toolbar.symbol.labelOne'
        }
    }
    return (
        <TriggerOverlay
            classNames={{ paper: style.overlay }}
            outStyledList
            optionsRenderer={<SymbolOptionsRenderer query={query} />}
        >
            <Button
                label={
                    query.Symbols ? (
                        <Trans
                            i18nKey={labelKey()}
                            components={{
                                value: <span className={style.value}>{query.Symbols?.split(',')?.length}</span>
                            }}
                        />
                    ) : (
                        t('dashboard.toolbar.symbol.label')
                    )
                }
                type='tetriatry-gray'
                after={<Icon name='chevron-down' size={15} />}
            />
        </TriggerOverlay>
    )
}

export default SymbolsContextMenu
