import { FC, useCallback } from 'react'
import { Icon } from '@fto/icons'
import { TriggerOverlay, Flex, Typography } from '@fto/ui'

import styles from './index.module.scss'
import { t } from 'i18next'

import { Content } from './components/Content'
import { Footer } from './components/Footer'

export type ValueType = string

type Props = {
    onChange: (value: any) => void
    value: ValueType
    templates: string[]
    onDeleteTemplate: (templateName: string) => void
    onApplyDefault?: () => void
    onSavingTemplate: () => void
}
export const TemplatesDropdown: FC<Props> = ({
    value,
    templates = [],
    onChange,
    onDeleteTemplate,
    onApplyDefault,
    onSavingTemplate
}) => {
    const optionRenderer = useCallback(
        (onClose: () => void) => {
            return (
                <Flex direction='column' className={styles.Dropdown} gap={8}>
                    <Flex direction='column' gap={4}>
                        <Typography color='gray-600' type='tiny-regular' className={styles.Title}>
                            {t('templates.dropdown.title')}
                        </Typography>

                        <Content
                            selectedTemplate={value}
                            templates={templates}
                            onSelect={onChange}
                            onDelete={onDeleteTemplate}
                            closeHandler={onClose}
                        />
                    </Flex>

                    <Footer
                        onSavingTemplate={onSavingTemplate}
                        onApplyDefault={onApplyDefault}
                        closeHandler={onClose}
                    />
                </Flex>
            )
        },
        [value, templates, onChange, onDeleteTemplate, onApplyDefault, onSavingTemplate]
    )

    return (
        <div className={styles.TemplatesDropdown}>
            <TriggerOverlay
                optionsRenderer={optionRenderer}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                disableBackdropChartActivity
            >
                <Flex alignItems='center' justifyContent='center' gap={8} className={styles.Header}>
                    <Typography color='gray-1000'>{t('templates.dropdown.template')}</Typography>
                    <Icon name='chevron-down' size={16} color={'var(--color-gray-1000)'} />
                </Flex>
            </TriggerOverlay>
        </div>
    )
}
