import { makeObservable, action, observable, runInAction } from 'mobx'
import debounce from 'lodash/debounce'

import { FavoriteIndicatorListType } from './types'
import { getFavoritesIndicators, setFavoritesIndicators } from '@root/utils/api'

class IndicatorsStore {
    public favoriteIndicators: FavoriteIndicatorListType = []

    constructor() {
        makeObservable(this, {
            favoriteIndicators: observable,
            updateFavoriteIndicators: action,
            resetStore: action,
            initializeFavoriteIndicators: action
        })
        this.updateFavoriteIndicators = this.updateFavoriteIndicators.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.initializeFavoriteIndicators = this.initializeFavoriteIndicators.bind(this)
        this.debouncedSetFavoriteIndicatorsTdDB = this.debouncedSetFavoriteIndicatorsTdDB.bind(this)
    }

    public updateFavoriteIndicators(
        updater: ((prevSettings: FavoriteIndicatorListType) => FavoriteIndicatorListType) | FavoriteIndicatorListType
    ) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.favoriteIndicators = updater(this.favoriteIndicators)
        } else {
            // If updater is a value, directly apply the updates
            this.favoriteIndicators = updater
        }
    }

    public initializeFavoriteIndicators() {
        getFavoritesIndicators()
            .then((response) => {
                runInAction(() => {
                    this.favoriteIndicators = response.data.indicators
                })
            })
            .catch(() => {
                runInAction(() => {
                    this.favoriteIndicators = []
                })
            })
    }

    private putFavoritesIndicatorsIntoDB() {
        setFavoritesIndicators(this.favoriteIndicators)
    }

    public debouncedSetFavoriteIndicatorsTdDB = debounce(this.putFavoritesIndicatorsIntoDB, 1000)

    public resetStore() {
        this.favoriteIndicators = []
    }
}

export default new IndicatorsStore()
