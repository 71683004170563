/* eslint-disable no-console */
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils } from '../../delphi_compatibility/DateUtils'
import BarArrayComparator from './BarArrayComparator'
import { EComparisonEvent, EComparisonStatus } from './ComparisonEnums'
import { IBiggerTF, ISmallerSourceTF, ISymbolComparison } from './ComparisonInterfaces'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import LongTermTestsStore from '@fto/lib/store/longTermTest'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'

interface ICurrentPosition {
    symbolIndex: number
    biggerTFIndex: number
    smallerTFIndex: number
}

export default class BuildTimeframesChecker {
    //singleton
    private static instance: BuildTimeframesChecker

    private get IsPostionAfterEnd(): boolean {
        return (
            this._currentPosition.symbolIndex >= this._comparisons.length ||
            (this._currentPosition.symbolIndex === this._comparisons.length &&
                this._currentPosition.biggerTFIndex >=
                    this._comparisons[this._currentPosition.symbolIndex].timeframes.length) ||
            (this._currentPosition.symbolIndex === this._comparisons.length &&
                this._currentPosition.biggerTFIndex ===
                    this._comparisons[this._currentPosition.symbolIndex].timeframes.length &&
                this._currentPosition.smallerTFIndex >=
                    this._comparisons[this._currentPosition.symbolIndex].timeframes[this._currentPosition.biggerTFIndex]
                        .sourceTimeframes.length)
        )
    }

    private constructor() {}

    public static get Instance(): BuildTimeframesChecker {
        if (!BuildTimeframesChecker.instance) {
            BuildTimeframesChecker.instance = new BuildTimeframesChecker()
        }
        return BuildTimeframesChecker.instance
    }

    //We won't even try to build weekly from something because we will eventually have to build them from H1 since D1 will not be accessible on server anymore
    private standardTFs = [1, 5, 15, 30, 60, 240, 1440, TimeframeUtils.W1_Timeframe, TimeframeUtils.MonthlyTimeframe] //always include 1.
    private symbols = [
        //---------staging-------------
        //---------good
        //'EURUSD',
        //'GBPUSD',
        // 'DJI',
        // 'NG',
        // 'GBPJPY',
        // 'AUDUSD',
        // 'AUDNZD',
        // 'EURJPY',
        // 'XAGUSD',
        //  'EURCAD',
        //  'NZDUSD'
        //-------------have problems
        // 'XAUUSD',
        // 'USDJPY',
        // 'USDCHF',
        //---------for investigation:
        // 'EURGBP',
        // 'USDCAD',
        // 'AUDJPY',
        // 'BTCUSD' // error at GetTickAtDate
        // -------------------------beta------------------
        //----have problems
        //'NZDUSD'
        'USDJPY',
        'EURGBP',
        'USDCAD',
        'AUDJPY',
        'BTCUSD',
        'EURUSD',
        'GBPUSD',
        'DJI',
        'NG',
        'GBPJPY',
        'AUDUSD',
        'AUDNZD',
        'EURJPY',
        'XAGUSD',
        'EURCAD',
        'XAUUSD',
        'USDCHF'
    ]
    private startDate = DateUtils.EmptyDate
    private chunkNumber = 0

    private _currentPosition: ICurrentPosition = { symbolIndex: 0, biggerTFIndex: 0, smallerTFIndex: 0 }

    private _comparisons: ISymbolComparison[] = []

    private _currentBarArrayComparator: BarArrayComparator | undefined = undefined

    private _fullReport = ''

    public Reset(): void {
        this._fullReport = ''
        this._comparisons = []
        this._currentBarArrayComparator?.releaseEverything()
        this._currentBarArrayComparator = undefined
        this._currentPosition = { symbolIndex: 0, biggerTFIndex: 0, smallerTFIndex: 0 }
        this.UpdateEverythingInModal()
    }

    public CompareSpecific(): void {
        this.Reset()

        DebugUtils.Instance.AllowEventsToThrowErrors = true
        // const symbol = 'NZDUSD'
        // const biggerTF = 10080
        // const smallerTF = 1440

        // check this as well:
        const symbol = 'EURGBP'
        const biggerTF = 30
        const smallerTF = 5

        // this.startDate = DateUtils.EncodeDate(2003, 7, 3, 0, 0, 0)
        // this.chunkNumber = 0

        this._comparisons = [
            {
                symbol: symbol,
                timeframes: [
                    {
                        timeframe: biggerTF,
                        sourceTimeframes: [{ timeframe: smallerTF, status: EComparisonStatus.NotStarted }],
                        status: EComparisonStatus.NotStarted
                    }
                ],
                status: EComparisonStatus.NotStarted
            }
        ]

        console.warn(
            `Comparing specific case ${symbol} ${biggerTF} ${smallerTF} ${DateUtils.DF(this.startDate)} ${
                this.chunkNumber
            }`
        )

        this.PerformComparisons()
    }

    public CompareEverything(): void {
        this.Reset()

        DebugUtils.Instance.AllowEventsToThrowErrors = true
        DebugUtils.Instance.EnableStrangeErrorBreakpoints = false
        this._comparisons = BuildTimeframesChecker.PrepareCombinations(this.symbols, this.standardTFs)
        DebugUtils.Instance.EnableStrangeErrorBreakpoints = true

        this.PerformComparisons()
    }

    private PerformComparisons() {
        this.UpdateGeneralStatusInModal(true)

        console.warn('combinations:', this._comparisons)
        this.UpdateDataInModal()
        this.CompareCurrent()
    }

    private CompareCurrent() {
        if (this.IsPostionAfterEnd) {
            console.warn('All comparisons are done')
            this.UpdateGeneralStatusInModal(false)
            DebugUtils.Instance.AllowEventsToThrowErrors = false
            return
        }
        this.InitNewCurrentComparator()
        this.LaunchComparator()
    }

    private LaunchComparator() {
        //clear memory
        GlobalSymbolList.SymbolList.ClearInvisibleBars()

        if (!this._currentBarArrayComparator) {
            throw new StrangeError('No comparator to launch')
        }

        this._currentBarArrayComparator.Events.on(EComparisonEvent.Finished, this.boundOnFinishCurrentComparison)
        this._currentBarArrayComparator.Events.on(
            EComparisonEvent.StatusUpdated,
            this.boundUpdateCurrentComparisonStatus
        )
        // comparator.Events.on(EComparisonEvent.Failed, (message: string) => {
        //     this.boundOnFinishCurrentComparison(EComparisonEvent.Failed, message)
        // })

        this._currentBarArrayComparator.InitiateComparison()
    }

    private boundOnFinishCurrentComparison = this.onFinishCurrentComparison.bind(this)
    private onFinishCurrentComparison() {
        const comparator = this._currentBarArrayComparator
        if (!comparator) {
            throw new StrangeError('No comparator to finish')
        }
        const shortMessage = comparator.GetShortMessage()
        const fullReport = comparator.Report
        this._fullReport += `${comparator.DName}:   ${fullReport}\n`
        const statusText = this.GetStatusText(comparator.Status)
        if (comparator.Status === EComparisonStatus.InProgress || comparator.Status === EComparisonStatus.NotStarted) {
            throw new StrangeError('Unexpected comparator status onFinishCurrentComparison')
        }

        console.warn(`Finished current comparison, result: ${statusText}`, `message: ${shortMessage}`)

        this.UpdateCurrentComparisonStatus()
        this.CompareNext()
    }

    private CompareNext() {
        console.log('Comparing next')
        this.IncPosition()
        this.CompareCurrent()
    }

    private GetStatusText(status: EComparisonStatus): string {
        switch (status) {
            case EComparisonStatus.NotStarted: {
                return 'Not started' //translation-ignore
            }
            case EComparisonStatus.InProgress: {
                return 'In progress' //translation-ignore
            }
            case EComparisonStatus.Failed: {
                return 'Failed' //translation-ignore
            }
            case EComparisonStatus.Successful: {
                return 'Success' //translation-ignore
            }
            default: {
                throw new StrangeError('Unknown EComparisonStatus')
            }
        }
    }

    private boundUpdateCurrentComparisonStatus = this.UpdateCurrentComparisonStatus.bind(this)
    private UpdateCurrentComparisonStatus() {
        const comparator = this._currentBarArrayComparator
        if (!comparator) {
            throw new StrangeError('No comparator to update')
        }

        const status = comparator.Status
        const message = comparator.GetShortMessage()

        this.SetCurrentComparisonStatus(status, message)

        this.UpdateDataInModal()
    }

    private UpdateGeneralStatusInModal(isProcessing: boolean) {
        LongTermTestsStore.updateData((prevSettings) => ({
            ...prevSettings,
            isProcessing: isProcessing
        }))
    }

    private UpdateDataInModal() {
        LongTermTestsStore.updateData((prevSettings) => ({
            ...prevSettings,
            symbolsTests: this._comparisons,
            fullReport: this._fullReport
        }))
    }

    private UpdateEverythingInModal() {
        this.UpdateGeneralStatusInModal(true)
        this.UpdateDataInModal()
    }

    private SetCurrentComparisonStatus(status: EComparisonStatus, message?: string) {
        const symbolIndex = this._currentPosition.symbolIndex
        const biggerTFIndex = this._currentPosition.biggerTFIndex
        const smallerTFIndex = this._currentPosition.smallerTFIndex

        this._comparisons[symbolIndex].timeframes[biggerTFIndex].sourceTimeframes[smallerTFIndex].status = status
        if (message) {
            this._comparisons[symbolIndex].timeframes[biggerTFIndex].sourceTimeframes[smallerTFIndex].message = message
        }

        this.PropagateStatusToBiggerTF(symbolIndex, biggerTFIndex)
    }

    private PropagateStatusToBiggerTF(symbolIndex: number, biggerTFIndex: number) {
        const comparisonSymbol = this._comparisons[symbolIndex]
        const comparisonBiggerTF = comparisonSymbol.timeframes[biggerTFIndex]
        const allSmallerTFSuccessful = comparisonBiggerTF.sourceTimeframes.every(
            (tf) => tf.status === EComparisonStatus.Successful
        )

        if (allSmallerTFSuccessful) {
            comparisonBiggerTF.status = EComparisonStatus.Successful
        } else {
            const atLeastOneFailed = comparisonBiggerTF.sourceTimeframes.some(
                (tf) => tf.status === EComparisonStatus.Failed
            )
            if (atLeastOneFailed) {
                comparisonBiggerTF.status = EComparisonStatus.Failed
            } else {
                const atLeastOneInProgress = comparisonBiggerTF.sourceTimeframes.some(
                    (tf) => tf.status === EComparisonStatus.InProgress
                )
                if (atLeastOneInProgress) {
                    comparisonBiggerTF.status = EComparisonStatus.InProgress
                } else comparisonBiggerTF.status = EComparisonStatus.NotStarted
            }
        }

        this.PropagateStatusToSymbol(symbolIndex)
    }

    private PropagateStatusToSymbol(symbolIndex: number) {
        const comparisonSymbol = this._comparisons[symbolIndex]
        const allBiggerTFSuccessful = comparisonSymbol.timeframes.every(
            (tf) => tf.status === EComparisonStatus.Successful
        )

        if (allBiggerTFSuccessful) {
            comparisonSymbol.status = EComparisonStatus.Successful
        } else {
            const atLeastOneFailed = comparisonSymbol.timeframes.some((tf) => tf.status === EComparisonStatus.Failed)
            if (atLeastOneFailed) {
                comparisonSymbol.status = EComparisonStatus.Failed
            } else {
                const atLeastOneInProgress = comparisonSymbol.timeframes.some(
                    (tf) => tf.status === EComparisonStatus.InProgress
                )
                if (atLeastOneInProgress) {
                    comparisonSymbol.status = EComparisonStatus.InProgress
                } else comparisonSymbol.status = EComparisonStatus.NotStarted
            }
        }
    }

    private InitNewCurrentComparator(): void {
        //release old comparator
        if (this._currentBarArrayComparator) {
            this._currentBarArrayComparator.releaseEverything()
        }

        const { symbol, biggerTF, smallerTF } = this.GetCurrentCombination()

        this._currentBarArrayComparator = new BarArrayComparator(
            symbol,
            biggerTF,
            smallerTF,
            this.startDate,
            this.chunkNumber
        )
    }

    private GetCurrentCombination() {
        const symbol = this._comparisons[this._currentPosition.symbolIndex].symbol
        const biggerTF =
            this._comparisons[this._currentPosition.symbolIndex].timeframes[this._currentPosition.biggerTFIndex]
                .timeframe
        const smallerTF =
            this._comparisons[this._currentPosition.symbolIndex].timeframes[this._currentPosition.biggerTFIndex]
                .sourceTimeframes[this._currentPosition.smallerTFIndex].timeframe
        return { symbol, biggerTF, smallerTF }
    }

    private IncPosition() {
        this._currentPosition.smallerTFIndex++
        if (
            this._currentPosition.smallerTFIndex >=
            this._comparisons[this._currentPosition.symbolIndex].timeframes[this._currentPosition.biggerTFIndex]
                .sourceTimeframes.length
        ) {
            this._currentPosition.smallerTFIndex = 0
            this._currentPosition.biggerTFIndex++
            if (
                this._currentPosition.biggerTFIndex >=
                this._comparisons[this._currentPosition.symbolIndex].timeframes.length
            ) {
                this._currentPosition.biggerTFIndex = 0
                this._currentPosition.symbolIndex++
            }
        }
    }

    private static PrepareCombinations(symbols: string[], standardTFs: number[]): ISymbolComparison[] {
        const combinations: ISymbolComparison[] = []

        for (const symbol of symbols) {
            const timeframes: IBiggerTF[] = []
            for (const biggerTF of standardTFs) {
                if (biggerTF === 1) {
                    continue
                }
                const smallerTFs = standardTFs.filter((tf) => tf < biggerTF)
                const sourceTimeframes: ISmallerSourceTF[] = []
                //sort smaller timeframes so for will go from larger to smaller
                const sortedSmallerTFs = smallerTFs.sort((a, b) => b - a)

                for (const smallerTF of sortedSmallerTFs) {
                    if (TimeframeUtils.CanBeBuiltFrom(biggerTF, smallerTF)) {
                        sourceTimeframes.push({ timeframe: smallerTF, status: EComparisonStatus.NotStarted })
                        //break
                    }
                }
                if (sourceTimeframes.length > 0) {
                    timeframes.push({
                        timeframe: biggerTF,
                        sourceTimeframes: sourceTimeframes,
                        status: EComparisonStatus.NotStarted
                    })
                }
            }
            combinations.push({ symbol, timeframes, status: EComparisonStatus.NotStarted })
        }

        return combinations
    }
}
