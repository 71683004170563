import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import CommonConstants from '../../../common/CommonConstants'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import { TSymbolData } from '../../SymbolData'
import { TBarChunk } from '../../chunks/BarChunk'
import { TChunkStatus, TNoExactMatchBehavior } from '../../chunks/ChunkEnums'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import IBarUnderConstruction from './IBarUnderConstruction'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import { TTickRecord } from '../../DataClasses/TTickRecord'
import { TBaseTickChunk } from '../../chunks/TickChunks/BaseTickChunk'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import IFMBarsArray from '../../data_arrays/chunked_arrays/IFMBarsArray'
import { DateIsAfterChunkEnd } from '../../chunks/DateOutOfChunkBoundsErrors'

export default class BarBuilderUtils {
    public static AddTicksToBarUnderConstruction(
        symbolData: TSymbolData,
        lastProcessedTickTimeForThisSymbol: TDateTime,
        barUnderConstruction: IBarUnderConstruction
    ): void {
        let currTickChunk = symbolData.TickData.fTicks.GetOrCreateChunkByDate(
            barUnderConstruction.barAlreadyBuiltUpToDate
        )

        while (DateUtils.LessOrEqual(currTickChunk.FirstDate, lastProcessedTickTimeForThisSymbol)) {
            BarBuilderUtils.addTickChunkDataToBarUnderConstruction(
                currTickChunk,
                barUnderConstruction,
                lastProcessedTickTimeForThisSymbol
            )
            currTickChunk = symbolData.TickData.fTicks.GetOrCreateNextChunk(currTickChunk.FirstDate)
        }
    }

    private static addTickChunkDataToBarUnderConstruction(
        currTickChunk: TBaseTickChunk,
        resultBarUnderConstruction: IBarUnderConstruction,
        lastProcessedTickTimeForThisSymbol: TDateTime
    ) {
        if (currTickChunk.Status === TChunkStatus.cs_Loaded) {
            const firstLocalIndex = currTickChunk.GetLocalIndexByDateBin(
                resultBarUnderConstruction.barAlreadyBuiltUpToDate,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher
            )
            const lastLocalIndex = currTickChunk.GetLocalIndexByDateBin(
                lastProcessedTickTimeForThisSymbol,
                TNoExactMatchBehavior.nemb_ReturnNearestLower
            )
            for (let i = firstLocalIndex; i <= lastLocalIndex; i++) {
                const tick = currTickChunk.GetItemByLocalIndex(i)
                if (tick) {
                    BarBuilderUtils.addTickToBarUnderConstruction(resultBarUnderConstruction, tick)
                }
            }
            resultBarUnderConstruction.barAlreadyBuiltUpToDate = Math.min(
                currTickChunk.LastPossibleDate,
                lastProcessedTickTimeForThisSymbol
            )
        } else {
            throw new DataNotDownloadedYetError('AddTicksToBarUnderConstruction - Tick data is not loaded yet')
        }
    }

    private static addTickToBarUnderConstruction(
        resultBarUnderConstruction: IBarUnderConstruction,
        tick: TTickRecord
    ): void {
        if (resultBarUnderConstruction.barOpen === CommonConstants.EMPTY_DATA_VALUE) {
            resultBarUnderConstruction.barOpen = tick.bid
        }
        resultBarUnderConstruction.barClose = tick.bid
        resultBarUnderConstruction.barHigh =
            resultBarUnderConstruction.barHigh === CommonConstants.EMPTY_DATA_VALUE
                ? tick.bid
                : Math.max(resultBarUnderConstruction.barHigh, tick.bid)
        resultBarUnderConstruction.barLow =
            resultBarUnderConstruction.barLow === CommonConstants.EMPTY_DATA_VALUE
                ? tick.bid
                : Math.min(resultBarUnderConstruction.barLow, tick.bid)
        resultBarUnderConstruction.barVolume += tick.volume
    }

    public static AddBarsFromLowerTimeframesToBarUnderConstruction(
        symbolData: TSymbolData,
        descriptorOfBarUnderConstructionTF: TDataDescriptor,
        lastProcessedTickTimeForThisSymbol: TDateTime,
        barUnderConstruction: IBarUnderConstruction
    ): void {
        const sortedActiveBarArraysDesc = [...symbolData.GetActiveBarArrays()] // Create a shallow copy of the array to avoid changing the original array
            .sort((a, b) => b.DataDescriptor.timeframe - a.DataDescriptor.timeframe)

        for (const barArray of sortedActiveBarArraysDesc) {
            const smallerBarArrayTF = barArray.DataDescriptor.timeframe

            //skip irrelevant or empty timeframes
            if (
                smallerBarArrayTF >= descriptorOfBarUnderConstructionTF.timeframe ||
                barArray.ChunkMapStatus !== TChunkMapStatus.cms_Loaded ||
                !TimeframeUtils.CanBeBuiltFrom(descriptorOfBarUnderConstructionTF.timeframe, smallerBarArrayTF)
            ) {
                continue
            }

            BarBuilderUtils.addSmallerTFDataToBarUnderConstruction(
                barArray,
                barUnderConstruction,
                lastProcessedTickTimeForThisSymbol
            )
        }
    }

    private static addSmallerTFDataToBarUnderConstruction(
        barArray: IFMBarsArray,
        barUnderConstruction: IBarUnderConstruction,
        lastProcessedTickTimeForThisSymbol: number
    ) {
        const relevantChunks = barArray.GetChunksForRangeDates(
            barUnderConstruction.barAlreadyBuiltUpToDate,
            lastProcessedTickTimeForThisSymbol
        )
        const sortedChunks = relevantChunks.sort((a, b) => a.FirstDate - b.FirstDate)

        for (const chunk of sortedChunks) {
            if (chunk.Status === TChunkStatus.cs_Loaded) {
                if (chunk.Count === 0) {
                    //there is an empty chunk, so it is likely that there is a gap in data here, let's move on to the next chunk
                    barUnderConstruction.barAlreadyBuiltUpToDate = chunk.LastPossibleDate
                    continue
                }
                this.addBARChunkDataToBarUnderConstruction(
                    chunk,
                    barUnderConstruction,
                    lastProcessedTickTimeForThisSymbol
                )
            } else {
                //we found a chunk that is not loaded, so we cannot build the bar and we have to stop here even if the next chunks are loaded
                //we will try to build the bar from other TFs or from ticks
                break
            }
        }
    }

    private static addBARChunkDataToBarUnderConstruction(
        barChunk: TBarChunk,
        barUnderConstruction: IBarUnderConstruction,
        lastProcessedTickTimeForThisSymbol: TDateTime
    ): void {
        let firstRelevantBarIndex
        try {
            firstRelevantBarIndex = barChunk.GetGlobalIndexByDate(
                barUnderConstruction.barAlreadyBuiltUpToDate,
                false,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher //if there is a gap, then let's find one of the next bars
            )

            if (firstRelevantBarIndex < 0) {
                throw new StrangeError('firstRelevantBarIndex < 0, unexpected behavior')
            }

            for (let barIndex = firstRelevantBarIndex; barIndex <= barChunk.LastGlobalIndex; barIndex++) {
                const bar = barChunk.GetItemByGlobalIndex(barIndex)
                if (bar) {
                    BarBuilderUtils.__validateBarDate(bar, barUnderConstruction)

                    if (
                        TBarRecord.GetSafeEndDateForBar(bar, barChunk.DataDescriptor.timeframe) >
                        lastProcessedTickTimeForThisSymbol
                    ) {
                        //we found a bar that ends later than the current testing date. We do not need to process this bar or next ones, we have already built our bar
                        break
                    }
                    BarBuilderUtils.addBarToBarUnderConstruction(barUnderConstruction, bar)
                } else {
                    throw new StrangeError(
                        `addBARChunkDataToBarUnderConstruction - bar is null ${barChunk.DName}, ${barIndex}`
                    )
                }
            }
        } catch (error) {
            if (error instanceof DateIsAfterChunkEnd) {
                //this is ok because we can try to find a first bar at a gap at the end of the chunk. That still means that we have processed this chunk for building a bar
            } else {
                throw error
            }
        }

        barUnderConstruction.barAlreadyBuiltUpToDate = Math.min(
            barChunk.LastPossibleDate,
            lastProcessedTickTimeForThisSymbol
        )
    }

    private static __validateBarDate(bar: TBarRecord, barUnderConstruction: IBarUnderConstruction) {
        if (bar.DateTime < barUnderConstruction.barStartDate) {
            //we found a bar that starts before the current bar, this is unexpected
            throw new StrangeError(
                'addBARChunkDataToBarUnderConstruction - bar.DateTime < barUnderConstruction.barStartDate, unexpected behavior'
            )
        }
    }

    private static addBarToBarUnderConstruction(barUnderConstruction: IBarUnderConstruction, bar: TBarRecord) {
        if (barUnderConstruction.barOpen === CommonConstants.EMPTY_DATA_VALUE) {
            barUnderConstruction.barOpen = bar.open
        }
        barUnderConstruction.barHigh = Math.max(bar.high, barUnderConstruction.barHigh)

        if (barUnderConstruction.barLow === CommonConstants.EMPTY_DATA_VALUE) {
            barUnderConstruction.barLow = bar.low
        } else {
            barUnderConstruction.barLow = Math.min(bar.low, barUnderConstruction.barLow)
        }

        barUnderConstruction.barClose = bar.close
        barUnderConstruction.barVolume += bar.volume
    }

    public static IsBarBuiltToDate(
        barUnderConstruction: IBarUnderConstruction,
        lastProcessedTickTimeForThisSymbol: TDateTime
    ): boolean {
        if (barUnderConstruction.barAlreadyBuiltUpToDate > lastProcessedTickTimeForThisSymbol) {
            throw new StrangeError('barAlreadyBuiltUpToDate > lastProcessedTickTimeForThisSymbol')
        }

        return (
            DateUtils.AreEqual(barUnderConstruction.barAlreadyBuiltUpToDate, lastProcessedTickTimeForThisSymbol) &&
            barUnderConstruction.barOpen !== CommonConstants.EMPTY_DATA_VALUE &&
            barUnderConstruction.barHigh !== CommonConstants.EMPTY_DATA_VALUE &&
            barUnderConstruction.barLow !== CommonConstants.EMPTY_DATA_VALUE
        )
    }

    public static InitializeEmptyBarUnderConstruction(barStartDate: TDateTime): IBarUnderConstruction {
        return {
            barOpen: CommonConstants.EMPTY_DATA_VALUE,
            barHigh: CommonConstants.EMPTY_DATA_VALUE,
            barLow: CommonConstants.EMPTY_DATA_VALUE,
            barClose: CommonConstants.EMPTY_DATA_VALUE,
            barVolume: 0,
            barAlreadyBuiltUpToDate: barStartDate,
            barStartDate: barStartDate
        }
    }
}
