import { FC, useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Flex, Typography, Separator } from '@fto/ui'

import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import styles from './index.module.scss'

type Props = {
    onClose: () => void
    activeToolNameParams: {
        key: string
        count: number
    }
}

const Actions: FC<Props> = ({ onClose, activeToolNameParams }) => {
    const { t } = useTranslation()

    const handleDuplicate = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        GraphToolPanelStore.duplicateTools(chart)
        onClose()
    }, [onClose])

    const handleCopy = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        GraphToolPanelStore.copyTools(chart)
        onClose()
    }, [onClose])

    const handleHide = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        GraphToolPanelStore.hideTools(chart)
        onClose()
    }, [onClose])

    const bringToFront = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        if (!chart) return
        GraphToolPanelStore.bringToFront(chart)
        onClose()
    }, [onClose])

    const sendToBack = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        if (!chart) return
        GraphToolPanelStore.sendToBack(chart)
        onClose()
    }, [onClose])

    return (
        <Flex direction='column' alignItems='center' gap={4}>
            <Typography type='tiny-regular' color='gray-600' align='left' block className={styles.Title}>
                {activeToolNameParams.count > 1
                    ? t('graphToolPanel.selectedToolsTitle', { count: activeToolNameParams.count })
                    : t(`graphicTools.toolNames.${activeToolNameParams.key}`)}
            </Typography>

            <Flex alignItems='center' gap={12} className={cx(styles.Option, styles.WithIcon)} onClick={bringToFront}>
                <Icon name='bring-to-front' size={18} />
                <Typography type='interface-regular' color='gray-1000'>
                    {t('graphToolPanel.bringToFront')}
                </Typography>
            </Flex>

            <Flex alignItems='center' gap={12} className={cx(styles.Option, styles.WithIcon)} onClick={sendToBack}>
                <Icon name='send-to-back' size={18} />
                <Typography type='interface-regular' color='gray-1000'>
                    {t('graphToolPanel.sendToBack')}
                </Typography>
            </Flex>

            <Separator width='144px' />

            <Flex alignItems='center' gap={12} className={cx(styles.Option, styles.WithIcon)} onClick={handleDuplicate}>
                <Icon name='duplicate' size={18} />
                <Typography type='interface-regular' color='gray-1000'>
                    {t('graphToolPanel.duplicate')}
                </Typography>
            </Flex>

            <Flex alignItems='center' gap={12} className={cx(styles.Option, styles.WithIcon)} onClick={handleCopy}>
                <Icon name='copy' size={18} />
                <Typography type='interface-regular' color='gray-1000'>
                    {t('graphToolPanel.copy')}
                </Typography>
            </Flex>

            <Separator width='144px' />

            <Flex alignItems='center' gap={12} className={cx(styles.Option, styles.WithIcon)} onClick={handleHide}>
                <Icon name='eye-off' size={18} />

                <Typography type='interface-regular' color='gray-1000'>
                    {t('graphToolPanel.hide')}
                </Typography>
            </Flex>
        </Flex>
    )
}

export default Actions
