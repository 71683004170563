export default class TDateDiff {
    //in TDateTime the units are days
    constructor(diffInDays: number) {
        this.diffInTDateTimeUnits = diffInDays
    }

    public diffInTDateTimeUnits: number

    public getDifferenceInDays(): number {
        return this.diffInTDateTimeUnits
    }
    public getDifferenceInHours(): number {
        return this.diffInTDateTimeUnits * 24
    }
    public getDifferenceInMinutes(): number {
        return this.diffInTDateTimeUnits * 24 * 60
    }
    public getDifferenceInSeconds(): number {
        return this.diffInTDateTimeUnits * 24 * 60 * 60
    }
    public getDifferenceInMilliseconds(): number {
        return this.diffInTDateTimeUnits * 24 * 60 * 60 * 1000
    }
}
