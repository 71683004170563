import { TSignAlign } from '@fto/lib/charting/paint_tools/PaintToolEnums'
import { TCalcFrom } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'

export const getBasePointsOptions = (t: (v: string) => string) => [
    { label: t('toolsModal.componentText.basePoints.left'), value: TSignAlign.sa_Left },
    { label: t('toolsModal.componentText.basePoints.right'), value: TSignAlign.sa_Right },
    { label: t('toolsModal.componentText.basePoints.top'), value: TSignAlign.sa_Top },
    { label: t('toolsModal.componentText.basePoints.bottom'), value: TSignAlign.sa_Bottom }
]

export const getLotOptions = (t: (v: string) => string) => [
    { label: t('toolsModal.componentText.lot.balance'), value: TCalcFrom.cf_Balance },
    { label: t('toolsModal.componentText.lot.equity'), value: TCalcFrom.cf_Equity },
    { label: t('toolsModal.componentText.lot.custom'), value: TCalcFrom.cf_Custom }
]
