import { Icon, IconButton } from '@fto/icons'
import { Flex, Typography } from '@fto/ui'
import { FC, useMemo, useState } from 'react'

import styles from '../index.module.scss'

type Props = {
    text: string
    completed: boolean | undefined
    description: string
}

const Task: FC<Props> = ({ text, completed, description }) => {
    const [toggle, setToggle] = useState(false)

    const iconName = useMemo(() => {
        if (completed) return 'check-rounded'
        if (completed === undefined) return 'ellipse'
        return 'close-rounded'
    }, [completed])

    return (
        <Flex direction='column' className={styles.task}>
            <Flex justifyContent='space-between'>
                <Flex alignItems='center' gap={8}>
                    <Icon color={iconName === 'ellipse' ? 'var(--color-gray-500)' : ''} size={16} name={iconName} />
                    <Typography>{text}</Typography>
                </Flex>
                <IconButton
                    name='chevron-bottom'
                    style={{ transform: toggle ? 'rotate(180deg)' : '' }}
                    onClick={() => setToggle(!toggle)}
                />
            </Flex>
            {toggle && <Typography className={styles.taskText}>{description}</Typography>}
        </Flex>
    )
}

export default Task
