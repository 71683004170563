import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Separator, Button } from '@fto/ui'

import { Icon } from '@fto/icons'
import ExportSpreadsheetDropdown from '@root/pages/ChartPage/components/Common/ExportSpreadsheetDropdown'

import { AnalyticsInfoRow } from '../AnalyticsInfoRow'
import { Props } from './types'

import styles from './index.module.scss'

const AnalyticsContent: FC<Props> = ({ closeTabs, statistics }) => {
    const { t } = useTranslation()
    const generateFields = useCallback((data: object) => {
        return Object.entries(data).map(([key, value], i) => (
            <Flex key={key} direction='column'>
                <AnalyticsInfoRow
                    title={t('analytics.' + key)}
                    value={value.toFixed(2)}
                    tooltip={t('analytics.tooltips.' + key)}
                />
                {Object.entries(data).length - 1 !== i && <Separator width='100%' />}
            </Flex>
        ))
    }, [])

    const generateTableData = useCallback(
        (titleKey: string, data: object) => ({
            name: t(`analytics.${titleKey}`),
            data: Object.entries(data).map(([key, value]) => ({
                name: t(`analytics.${key}`),
                value: value.toFixed(2)
            }))
        }),
        [t]
    )

    const tables = useMemo(
        () => [
            generateTableData('time', statistics.TimeStatistics),
            generateTableData('trades', statistics.TradeStatistics),
            generateTableData('income', statistics.IncomeStatistics),
            generateTableData('other', statistics.OtherStatistics)
        ],
        [statistics, generateTableData]
    )

    return (
        <Flex className={styles.analytics} direction='column'>
            <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                <Typography type='h4-semibold'>{t('analytics.projectAnalytics')}</Typography>
                <Flex gap={8}>
                    <ExportSpreadsheetDropdown data={tables} filename='Analytics' iconButton />
                    <Button
                        onClick={closeTabs}
                        type='tetriatry-white'
                        size='compact'
                        disableFixedSize
                        label={<Icon name='close' color={'var(--color-gray-1000)'} className={styles.icon} size={16} />}
                    />
                </Flex>
            </Flex>
            <Separator width='100%' />
            <Flex direction='column' className={styles.info} gap={16}>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.time')}</Typography>
                    {generateFields(statistics.TimeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.trades')}</Typography>
                    {generateFields(statistics.TradeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.income')}</Typography>
                    {generateFields(statistics.IncomeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.other')}</Typography>
                    {generateFields(statistics.OtherStatistics)}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default AnalyticsContent
