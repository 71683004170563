import { Button, Flex } from '@fto/ui'
import React, { FC } from 'react'
import { FormErrors } from '@root/hooks/useSubmitForm'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjectsStatus } from '@root/store/projects/projects.selector'
import { useTranslation } from 'react-i18next'

type Props = {
    closeModal: () => void
    submit: () => void
    errors: FormErrors[]
}

const Controls: FC<Props> = ({ closeModal, errors, submit }) => {
    const { t } = useTranslation()

    const projectStatus = useAppSelector($getProjectsStatus)

    return (
        <Flex alignItems='center' gap={8}>
            <Button label={t('global.cancel')} onClick={closeModal} type='secondary' />
            <Button
                disabled={projectStatus === 'pending' || errors.length > 0}
                onClick={submit}
                label={t('dashboard.modals.projects.createButton')}
                loading={projectStatus === 'pending'}
                type='primary'
            />
        </Flex>
    )
}

export default Controls
