import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChunkStatus } from '../../chunks/ChunkEnums'
import ChunkBuilderTask from './ChunkBuilderTask'
import { ChunkBuilderTaskQueue } from './ChunkBuilderTaskQueue'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import { TBarChunk } from '../../chunks/BarChunk'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TDataFormat } from '../../DataEnums'
import { DownloadController } from '../../data_downloading/DownloadController'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

export class ChunkBuilder {
    //it seems that we need the queue just for monitoring purposes
    private _tasksQueue: ChunkBuilderTaskQueue

    private static instance: ChunkBuilder

    public static get Instance(): ChunkBuilder {
        if (!ChunkBuilder.instance) {
            ChunkBuilder.instance = new ChunkBuilder()
        }

        return ChunkBuilder.instance
    }

    private constructor() {
        this._tasksQueue = new ChunkBuilderTaskQueue()
        //it seems no timer is needed here, everything is event-driven
        // this.startTaskBuildingTimer()
    }

    public BuildChunk(chunkToBuild: TBarChunk, downloadAllowed = true): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Building queue length:',
            this._tasksQueue.length,
            'Building chunk',
            chunkToBuild.FirstDate,
            chunkToBuild.DName,
            chunkToBuild.DataDescriptor
        )
        if (chunkToBuild.Status === TChunkStatus.cs_Loaded) {
            throw new StrangeError(
                `Trying to build already loaded chunk ${chunkToBuild.FirstDate} ${DateUtils.DF(chunkToBuild.FirstDate)}; ${chunkToBuild.DataDescriptor}`
            )
        }

        //the chunk is empty according to the map, no need to build it
        if (chunkToBuild.Count === 0) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Trying to build chunk with 0 bars ${chunkToBuild.FirstDate}; ${chunkToBuild.DataDescriptor}`
            )
            chunkToBuild.ImportChunkData([], TDataFormat.df_ActualRecords)
            return
        }

        if (chunkToBuild.DataDescriptor.timeframe === 1) {
            //no need to build M1 chunks, they are always loaded
            DownloadController.Instance.loadHistoryIfNecessary(chunkToBuild)
            return
        }

        const chunkBuilderTask = this.GetOrCreateTask(chunkToBuild, downloadAllowed)

        //no need to do anything if the chunk is already loading, the events will finish its loading
        //note that we can come here for a chunk that is being built or downloaded, in this case we just need to wait for the events to trigger the completion
        if (chunkToBuild.Status === TChunkStatus.cs_Empty) {
            chunkToBuild.Status = TChunkStatus.cs_Building
            chunkBuilderTask.ExecuteBuildingTask()
        }
    }

    private boundProcessLoadingError = this.processLoadingError.bind(this)
    private processLoadingError(chunkToBuild: TBarChunk, error?: Error): void {
        DebugUtils.warnTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Processing loading error for chunk',
            chunkToBuild.FirstDate,
            chunkToBuild.DName,
            chunkToBuild.Status
        )
        this.removeTaskAndReleaseEvents(chunkToBuild)
    }

    private boundRemoveTaskAndReleaseEvents = this.removeTaskAndReleaseEvents.bind(this)
    private removeTaskAndReleaseEvents(chunkToBuild: TBarChunk, error?: Error): void {
        if (!chunkToBuild) {
            throw new StrangeError('Chunk is not defined in removeTaskAndReleaseEvents')
        }
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Removing task and releasing events for chunk',
            chunkToBuild.FirstDate,
            chunkToBuild.DName,
            chunkToBuild.Status
        )

        const task = this._tasksQueue.GetTaskForChunk(chunkToBuild)

        if (task) {
            task.releaseEverything()
            this._tasksQueue.RemoveTask(task)
        }
        chunkToBuild.Events.off(TDataArrayEvents.de_ChunkLoaded, this.boundRemoveTaskAndReleaseEvents)
        chunkToBuild.Events.off(TDataArrayEvents.de_LoadingErrorHappened, this.boundProcessLoadingError)
    }

    private GetOrCreateTask(chunkToBuild: TBarChunk, downloadAllowed = true): ChunkBuilderTask {
        let chunkBuilderTask = this._tasksQueue.GetTaskForChunk(chunkToBuild)
        if (!chunkBuilderTask) {
            chunkBuilderTask = new ChunkBuilderTask(chunkToBuild, downloadAllowed)

            chunkToBuild.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundRemoveTaskAndReleaseEvents)
            chunkToBuild.Events.on(TDataArrayEvents.de_LoadingErrorHappened, this.boundProcessLoadingError)

            this._tasksQueue.AddTask(chunkBuilderTask)
        }
        return chunkBuilderTask
    }
}
