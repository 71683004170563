import { IndicatorConfigurationControl } from '@fto/chart_components/IndicatorConfigurationControl'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { Level } from '@fto/lib/drawing_interface/GraphicObjects/Level'
import { TLevelData } from '@fto/lib/drawing_interface/GraphicObjects/TLevelData'
import { TLevelsList } from '@fto/lib/drawing_interface/GraphicObjects/TLevelsList'
import { TBufferStyle } from '@fto/lib/drawing_interface/VCLCanvas/TBufferStyle'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { TChartType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { IndicatorJSON } from '@fto/lib/ProjectAdapter/Types'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPenStyle, TDrawStyle } from '../common/CommonExternalInterface'
import {
    TOptValue_str,
    TOptionType,
    TColor,
    TOptValue_Session,
    TOptValue_SessionsArray,
    TCalcType,
    TOptValue_bool
} from '../common/CommonTypes'
import { BasicRuntimeLib } from '../common/BasicRuntimeLib'
import { IIndexBuffer } from './api/IIndexBuffer'
import IIndicatorsProcRec from './api/IIndicatorsProcRec'
import { IndicatorImplementation } from './api/IndicatorImplementation'
import { TIndexBufferProgramSide, TCommonIndexBuffer } from './IndexBuffers'
import { IndicatorDescriptor } from './IndicatorDescriptor'
import { TOutputWindow } from './IndicatorTypes'
import { TIndexBuffersList } from './TIndexBuffersList'
import { TVisibleIndexBuffer } from './VisibleIndexBuffer'
import VisibleBuffersList from './VisibleIndexBufferList'
import IndicatorOptionsStore from '@fto/lib/charting/tool_storages/indicators'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { TDLLOptionRec } from '../common/options/TDLLOptionRec'
import {
    TInitProc,
    TDoneProc,
    TParamsChangeProc,
    TStartProc,
    TOnChartEvent,
    TOnPaintProc,
    TRecalculateBarProc
} from '../common/ExternalModulesProcs'
import { TIndexBuffer, TVisibleBufferInfo } from './api/IIndicatorApi'
import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { OscIndicatorConfigurationControl } from '@fto/chart_components/OscIndicatorConfigurationControl'
import { IIndicatorOptionsStore } from '@fto/lib/charting/tool_storages/indicators/Interfaces/IIndicatorOptionsStore'
import { t } from 'i18next'

export class TRuntimeIndicator extends BasicRuntimeLib {
    private _indicatorImplementation: IndicatorImplementation
    private fLevelsStr: TOptValue_str // level in sting (registered in options)
    private fCalcType: TCalcType
    private fBarsCounted: number
    private fBuffers!: TIndexBuffersList
    private fInitProc!: TInitProc
    private fDoneProc!: TDoneProc

    // private fShowPropsDialogProc!: TShowPropsDialogProc;
    private fOnParamsChangeProc!: TParamsChangeProc
    private fStartProc!: TStartProc
    private fOnChartEvent!: TOnChartEvent

    private _isNeedRecalculateAlways = false
    private _backOffsetForCalculation = 0

    private fOnPaintProc!: TOnPaintProc
    private fRecalculateBarProc!: TRecalculateBarProc
    private indicatorConfigurationControl: IndicatorConfigurationControl | null = null
    private oscIndicatorConfigurationControl: OscIndicatorConfigurationControl | null = null
    private isVisible = true
    public indicatorKey = ''

    protected fOutputWindow!: TOutputWindow
    protected fMinValue!: number
    protected fMaxValue!: number
    protected fDecimals!: number

    protected fVisibleBuffers!: VisibleBuffersList
    protected fLevels!: TLevelsList

    public DeleteFilesOnDestroy!: boolean
    public ReferenceCount!: number
    public EmptyValue!: number
    public IsSelected = false

    private displayParamsOnIndicatorConfControl: TOptValue_bool = new TOptValue_bool(true)
    private displayValuesOnIndicatorConfControl: TOptValue_bool = new TOptValue_bool(true)

    Init(): void {
        this._indicatorImplementation.Init()

        this.options.RegOption(
            t('indicatorModal.general.showParameters'),
            TOptionType.ot_Boolean,
            this.displayParamsOnIndicatorConfControl,
            false
        )
        this.options.RegOption(
            t('indicatorModal.general.showValues'),
            TOptionType.ot_Boolean,
            this.displayValuesOnIndicatorConfControl,
            false
        )
    }

    public get Digits(): number {
        return this.currentSymbolAndTFInfoProcRecImplementation.Digits()
    }

    public getIndicator(): IndicatorImplementation {
        return this._indicatorImplementation
    }

    private ReverseFrom_firstItem0_to_lastItem0(firstItem0_index: number): number {
        return this.getBars().LastItemInTestingIndex - firstItem0_index
    }

    public Reverse_from_lastItem0_to_firstItem0(lastItem0_index: number): number {
        return this.getBars().LastItemInTestingIndex - lastItem0_index
    }

    public Calculate(index: number): void {
        if (this.isVisible) {
            this._indicatorImplementation.Calculate(index)
            if (this.indicatorConfigurationControl) {
                if (this.indicatorConfigurationControl.isMouseInside()) {
                    this.indicatorConfigurationControl.adjustControlWidth(true)
                } else {
                    this.indicatorConfigurationControl.adjustControlWidth(false)
                }
            }
            if (this.oscIndicatorConfigurationControl) {
                if (this.oscIndicatorConfigurationControl.isMouseInside()) {
                    this.oscIndicatorConfigurationControl.adjustControlWidth()
                } else {
                    this.oscIndicatorConfigurationControl.adjustControlWidth()
                }
            }
        }
    }

    public OnMapLoaded(): void {
        this.indicatorConfigurationControl?.OnIndicatorParamsChange()
    }

    private OnParamsChange(): void {
        this._indicatorImplementation.OnParamsChange()
        if (this.indicatorConfigurationControl) {
            this.indicatorConfigurationControl.OnIndicatorParamsChange()
            if (this.indicatorConfigurationControl.isMouseInside()) {
                this.indicatorConfigurationControl.adjustControlWidth(true)
            } else {
                this.indicatorConfigurationControl.adjustControlWidth(false)
            }
        }
        if (this.oscIndicatorConfigurationControl) {
            if (this.oscIndicatorConfigurationControl.isMouseInside()) {
                this.oscIndicatorConfigurationControl.adjustControlWidth()
            } else {
                this.oscIndicatorConfigurationControl.adjustControlWidth()
            }
        }
    }

    public Done(): void {
        this._indicatorImplementation.Done()
    }

    set oscConfigurationControl(indicatorConfigurationControl: OscIndicatorConfigurationControl) {
        this.oscIndicatorConfigurationControl = indicatorConfigurationControl
    }

    get oscConfigurationControl(): OscIndicatorConfigurationControl | null {
        return this.oscIndicatorConfigurationControl
    }

    public get indicatorDescriptor(): IndicatorDescriptor {
        if (!(this.descriptor instanceof IndicatorDescriptor)) {
            throw new StrangeError('Indicator descriptor is not initialized correctly')
        }
        return this.descriptor
    }

    constructor(
        indicatorDescriptor: IndicatorDescriptor,
        indicatorImplementation: IndicatorImplementation,
        aSymbol: ISymbolData,
        aTimeframe: number,
        ChartType: TChartType
    ) {
        super(indicatorDescriptor)

        this.fLevels = new TLevelsList()

        this.fVisibleBuffers = new VisibleBuffersList()

        this.DeleteFilesOnDestroy = false
        this.fOutputWindow = TOutputWindow.ow_ChartWindow
        this.EmptyValue = 0
        this.fMinValue = 0
        this.fMaxValue = 0
        this.ReferenceCount = 0
        this.fDecimals = 4

        //super(aLibName, isCustomIndicator, fileName)
        this.descriptor = indicatorDescriptor.clone()
        this._indicatorImplementation = indicatorImplementation

        // Initialize member variables to their default values

        this.fBuffers = new TIndexBuffersList()

        this.fCalcType = TCalcType.ct_FT3

        this.setSymbolData(aSymbol)
        this.timeframe = aTimeframe
        this.fBarsCounted = 0

        let theBars
        // Set bars based on the ChartType
        if (aSymbol) {
            switch (ChartType) {
                case TChartType.ct_Normal: {
                    theBars = aSymbol.GetOrCreateBarArray(this.timeframe)
                    break
                }
                default: {
                    throw new StrangeError('Unknown chart type in TRuntimeIndicator')
                }
            }

            if (theBars) {
                this.setBars(theBars)
            } else {
                throw new StrangeError('Error initializing indicator. Bars not found')
            }
        } else {
            throw new StrangeError('Error initializing indicator. Symbol not provided')
        }

        this.fLevelsStr = new TOptValue_str('')
        this.options.RegOption('Levels', TOptionType.at_Levels, this.fLevelsStr, false)

        this.fInitProc = this.Init
        this.fRecalculateBarProc = this.Calculate
        this.fOnParamsChangeProc = this.OnParamsChange

        this.onLoad()
    }

    set configurationControl(indicatorConfigurationControl: IndicatorConfigurationControl | null) {
        this.indicatorConfigurationControl = indicatorConfigurationControl
    }

    get configurationControl(): IndicatorConfigurationControl | null {
        return this.indicatorConfigurationControl
    }

    public DisplayParamsOnIndicatorConfigurationControl(): boolean {
        return this.displayParamsOnIndicatorConfControl.value
    }

    public DisplayValuesOnIndicatorConfigurationControl(): boolean {
        return this.displayValuesOnIndicatorConfControl.value
    }

    private onLoad() {
        const realIndicatorApiImplementation: IIndicatorsProcRec = {
            ...super.GetImplementation(),

            //TODO: divide this among different classes

            //general settings:
            SetFixedMinMaxValues: (minValue: number, maxValue: number) => {
                this.SetFixedMinMaxValues(minValue, maxValue)
            },
            RecalculateMeAlways: () => {
                this.RecalculateMeAlways()
            },
            SetBackOffsetForCalculation: (offset: number) => {
                this.SetBackOffsetForCalculation(offset)
            },
            IndicatorDigits: (digits: number) => {
                this.fDecimals = digits
            },
            IndicatorShortName: (shortName: string) => {
                this.SetIndicatorShortName(shortName)
            },
            SetOutputWindow: (tOutputWindow: TOutputWindow) => {
                this.SetOutputWindow(tOutputWindow)
            },
            SetEmptyValue: (emptyValue: number) => {
                this.SetEmptyValue(emptyValue)
            },
            mql4_Counted_bars: () => {
                return this.fBarsCounted
            },
            setIndicatorIdKey: (key: string) => {
                this.indicatorKey = key
            },

            // Levels
            AddLevel: (value: number, style: TPenStyle, width: number, color: TColor, opacity: number) => {
                this.AddLevel(value, style, width, color, opacity)
            },
            SetLevelValue: (index: number, value: number) => {
                this.SetLevelValue(index, value)
            },

            // Buffers
            IndicatorBuffers: (bufferCount: number) => {
                this.IndicatorBuffers(bufferCount)
            },
            CreateIndexBuffer: () => {
                const programSideBuffer = this.CreateIndexBuffer()
                return new TIndexBuffer(programSideBuffer)
            },
            CreateIndexBufferWithArgs: (
                index: number,
                aLabel: string,
                drawStyle: TDrawStyle,
                style: TPenStyle,
                width: number,
                color: TColor
            ): TIndexBuffer => {
                return this.CreateIndexBufferWithArgs(index, aLabel, drawStyle, style, width, color)
            },
            SetIndexBuffer: (bufferIndex: number, buffer: TIndexBuffer) => {
                this.SetIndexBuffer(bufferIndex, buffer.getBuffer())
            },
            SetIndexStyle: (bufferIndex: number, type: TDrawStyle, style: TPenStyle, width: number, clr: string) => {
                this.SetIndexStyle(bufferIndex, type, style, width, clr)
            },
            SetIndexSymbol: (bufferIndex: number, symbol: number, xoffs: number, yoffs: number) => {
                this.SetIndexSymbol(bufferIndex, symbol, xoffs, yoffs)
            },
            SetIndexLabel: (bufferIndex: number, bufferName: string) => {
                this.SetIndexLabel(bufferIndex, bufferName)
            },
            GetBufferValue: (buffer: number, index: number) => {
                return this.GetBufferValue(buffer, index)
            },
            SetBufferValue: (buffer: number, index: number, value: number) => {
                this.SetBufferValue(buffer, index, value)
            },
            GetBufferCount: (buffer: number) => {
                return this.GetBufferCount(buffer)
            },
            GetBufferMax: (buffer: number, index1: number, index2: number) => {
                return this.GetBufferMax(buffer, index1, index2)
            },
            GetBufferMin: (buffer: number, index1: number, index2: number) => {
                return this.GetBufferMin(buffer, index1, index2)
            },
            GetBufferInfo: (index: number) => {
                return new TVisibleBufferInfo(this.fVisibleBuffers[index])
            },
            SetIndexDrawBegin: (bufferIndex: number, PaintFrom: number) => {
                this.SetIndexDrawBegin(bufferIndex, PaintFrom)
            },
            SetBufferShift: (bufferIndex: number, shift: number) => {
                this.SetBufferShift(bufferIndex, shift)
            }
        }

        this._indicatorImplementation.OnAttach(realIndicatorApiImplementation)
    }

    public GetBufferValue(buffer: number, index_firstItem0_based: number): number {
        const buff = this.fBuffers[buffer]
        if (buff) {
            // console.log('value', buff.GetValue(index))
            return buff.GetValue(index_firstItem0_based)
        }
        return 0
    }

    public getBackOffsetForCalculation(): number {
        return this._backOffsetForCalculation
    }

    public get Chart(): IChart {
        if (!this._chart) {
            throw new StrangeError('Chart not initialized in the indicator')
        }
        return this._chart
    }

    public set Chart(value: IChart) {
        this._chart = value
    }

    public get buffers(): TIndexBuffersList {
        return this.fBuffers
    }

    public RecountValuesForTheRange(
        start_visibleIndex_firstItem0based: number,
        end_VisibleIndex_firstItem0based: number
    ): void {
        try {
            for (let i = start_visibleIndex_firstItem0based; i <= end_VisibleIndex_firstItem0based; i++) {
                if (this._isNeedRecalculateAlways || this.fBuffers.IsEmpty(i)) {
                    const reversedIndex_lastItem0based = this.ReverseFrom_firstItem0_to_lastItem0(i)
                    this.fRecalculateBarProc(reversedIndex_lastItem0based)
                }
            }
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                DebugUtils.warn(`Cannot calculate indicators yet, data is not available. Error: ${error.message}`)
            } else {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            }

            for (let i = start_visibleIndex_firstItem0based; i <= end_VisibleIndex_firstItem0based; i++) {
                //just in case let's reclculate all values
                this.fBuffers.ClearValueAtGlobalIndex(i)
            }
        }
    }

    //do not call this in the constructor, somehow it does not work because some class members of derived classes are not initialized yet
    public InitializeTheIndicator(): void {
        this.fInitProc()

        if (this.fOnParamsChangeProc) {
            this.fOnParamsChangeProc()
        }
    }

    public get OnPaintProc(): TOnPaintProc {
        return this.fOnPaintProc
    }

    // Helper method to check if the index is within the range of the array.
    private isIndexInRange(index: number, array: any[]): boolean {
        return index >= 0 && index < array.length
    }

    private isValidOutputWindow(value: number): boolean {
        return Object.values(TOutputWindow).includes(value)
    }

    public ProcessChartEvent(id: number, lparam: number, dparam: number, sparam: string): void {
        if (this.fOnChartEvent) {
            this.fOnChartEvent(id, lparam, dparam, sparam)
        }
    }

    public ReassignSymbolData(symbolData: TSymbolData): void {
        this.setSymbolData(symbolData)
        const bars = symbolData.GetOrCreateBarArray(this.timeframe)
        this.setBars(bars)
    }

    public RefreshLevels(): void {
        this.fLevels.ImportFromStr(this.fLevelsStr.value)
    }

    public SaveOptions(): void {
        this.UpdateLevelsInOptions()
    }

    public UpdateLevelsInOptions(): void {
        const levelsOption = this.options.GetOptionRec('Levels')
        if (levelsOption) {
            levelsOption.Value = this.fLevels.ExportToStr(this.fDecimals)
        } else {
            throw new StrangeError('Levels option not found.')
        }
    }

    public RefreshOptions(): void {
        this.fOnParamsChangeProc?.()
    }

    public Show(): void {
        this.isVisible = true
        this._indicatorImplementation.OnShow()
        this.indicatorConfigurationControl?.onOwnerIndicatorShow()
        this.oscConfigurationControl?.onOwnerIndicatorShow()
    }

    public Hide(): void {
        this.isVisible = false
        this._indicatorImplementation.OnHide()
        this.indicatorConfigurationControl?.onOwnerIndicatorHide()
        this.oscConfigurationControl?.onOwnerIndicatorHide()
    }

    public IsVisible(): boolean {
        return this.isVisible
    }

    public ExportData(edit?: boolean): void {
        const { setIndicatorOptions, resetIndicatorOptions } = IndicatorOptionsStore

        resetIndicatorOptions()
        this.RefreshOptions()
        this.SaveOptions()

        const decimals = this.fDecimals

        const indicatorOptions = Object.entries(this.options).reduce<Record<string, any>>((acc, [key, value]) => {
            const { alias, Value: valueTemp, OptionType: type, values, HighValue, LowValue } = value
            const finalValue = this.getFinalValue(alias, type, valueTemp)

            acc[key] = { alias, fvalue: finalValue, type, values, HighValue, LowValue, decimals }
            return acc
        }, {})

        const finalIndicatorOptions: IIndicatorOptionsStore = {
            ...indicatorOptions,
            indicatorInstance: this
        }
        setIndicatorOptions(finalIndicatorOptions)

        addModal(MODAL_NAMES.chart.indicatorModal, { isEdit: !!edit })
    }

    private getFinalValue(alias: string, type: TOptionType, valueTemp: any): string | Level[] | TBufferStyle | null {
        if (alias === 'Levels') {
            return this.fOutputWindow === TOutputWindow.ow_SeparateWindow
                ? this.parseOptionValue(type, valueTemp)
                : null
        }

        if (type === TOptionType.ot_LineStyle) {
            const lineStyleOptions = this.parseOptionValue(type, valueTemp)
            if (lineStyleOptions instanceof TLineStyle) {
                const buffer = this.VisibleBuffers.find((buff) => buff.name === alias)
                if (buffer) {
                    return new TBufferStyle(
                        lineStyleOptions.color,
                        lineStyleOptions.style,
                        lineStyleOptions.width,
                        buffer.IsVisible()
                    )
                }
                return lineStyleOptions.SaveToStr()
            }
        }

        return this.parseOptionValue(type, valueTemp)
    }

    public ImportData(): void {
        const { indicatorOptions } = IndicatorOptionsStore
        const { indicatorInstance, ...options } = indicatorOptions

        for (const [key, option] of Object.entries(options)) {
            // Now, `option` is of type `TIndicatorOption`

            const optionKey = Number(key)
            if (isNaN(optionKey)) {
                throw new StrangeError(`Invalid option key: ${key}`)
            }

            const currentOption = this.options[optionKey]
            if (!currentOption) {
                throw new StrangeError(`Option not found for key: ${optionKey}`)
            }

            const { OptionType } = currentOption
            const { fvalue, alias } = option

            const fvalueString = this.formatOptionValue(OptionType, fvalue)
            currentOption.SetValue(fvalueString)

            if (fvalue instanceof TBufferStyle) {
                this.processBufferStyleChange(alias, fvalue.isVisible)
            }

            if (alias === 'Levels') {
                this.processLevelChange(fvalueString)
            }
        }
    }

    private processBufferStyleChange(optionName: string, isVisible: boolean): void {
        const buffer = this.VisibleBuffers.find((_buff) => _buff.name === optionName)
        if (buffer) {
            if (isVisible) {
                buffer.show()
            } else {
                buffer.hide()
            }
        }
    }

    private processLevelChange(newValue: string) {
        this.fLevels.Clear()
        if (!newValue) return

        // Trim leading and trailing braces and split into level strings
        const levels = newValue
            .slice(1, -1)
            .split('}{')
            .map((level) => level.trim())
        for (const [index, level] of levels.entries()) {
            const [value, text, color, style, width, id, isActive] = level
                .split(' ')
                .map((comp) => comp.replaceAll(/^"|"$/g, ''))
            const fLevel = this.fLevels[index]

            if (fLevel) {
                fLevel.SetLevelFromStr(level)
            } else {
                this.AddLevel(
                    parseFloat(value.replace(',', '.')),
                    parseInt(style),
                    parseInt(width),
                    color,
                    1,
                    parseInt(isActive)
                )
            }
        }
    }

    private parseOptionValue(type: TOptionType, value: any): any {
        switch (type) {
            case TOptionType.ot_Session: {
                return TOptValue_Session.copyFromString(value)
            }
            case TOptionType.ot_SessionsArray: {
                return TOptValue_SessionsArray.copyFromString(value)
            }
            case TOptionType.at_Levels: {
                const level = new Level()
                return level.LevelFromString(value)
            }
            case TOptionType.ot_LineStyle: {
                const [color, style, width] = value.split(',')
                return new TLineStyle(color, parseInt(style), parseInt(width))
            }
            case TOptionType.ot_double: {
                return parseFloat(value.replace(',', '.'))
            }
            case TOptionType.ot_Integer:
            case TOptionType.ot_Longword: {
                return parseInt(value.replace(',', '.'))
            }
            case TOptionType.ot_Boolean: {
                return value === 'Yes'
            }
            default: {
                return value
            }
        }
    }

    private formatOptionValue(
        type: TOptionType,
        value: TLineStyle | Level[] | string | TOptValue_Session | TOptValue_SessionsArray
    ): string {
        if (type === TOptionType.ot_LineStyle && value instanceof TLineStyle) {
            return `${value.color},${value.style},${value.width}`
        }
        if (type === TOptionType.at_Levels && Array.isArray(value)) {
            const level = new Level()
            if (Array.isArray(value) && value.length === 0) {
                return ''
            }
            return level.LevelToString(value)
        }
        if (type === TOptionType.ot_Boolean) {
            return value ? 'Yes' : 'No'
        }
        if (value === null || value === undefined) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                'It is weird to have a null value in formatOptionValue'
            )
            return ''
        } else {
            return value.toString()
        }
    }

    public clearBuffers(): void {
        this.fBuffers.ClearValues()
    }

    //# api Implementation {

    private AddLevel(value: number, style: TPenStyle, width: number, clr: TColor, opacity: number, isActive = 1) {
        if (opacity < 0 || opacity > 1) {
            throw new StrangeError(
                'Invalid opacity value for the indicator level! The opacity was',
                opacity,
                ', but valid values range from 0 to 1.'
            )
        }
        const tLevelData = new TLevelData(
            '0',
            new TLineStyle(ColorHelperFunctions.MakeColor(clr, opacity * 255), style, width)
        )
        tLevelData.text = '0'
        tLevelData.value = value
        tLevelData.isActive = isActive
        this.fLevels.Add(tLevelData)
    }

    private CreateIndexBuffer(): TIndexBufferProgramSide {
        // Create a new TIndexBuffer instance
        const buffer = new TCommonIndexBuffer(this.getBars(), this.EmptyValue)

        // Add the buffer to the buffers collection
        this.fBuffers.push(buffer)

        // Return the newly created buffer
        return buffer
    }

    private IndicatorBuffers(count: number): void {
        if (count > this.VisibleBuffers.Count) {
            //add new default buffers, we will init them below
            for (let i = this.VisibleBuffers.Count; i < count; i++) {
                this.fVisibleBuffers.push(new TVisibleIndexBuffer())
            }
        } else if (count < this.VisibleBuffers.Count) {
            //decrease the number of visible buffers
            this.fVisibleBuffers.SetLength(count)
        }

        if (count === 0) {
            return
        }

        this.AddSeparator('Styles')

        // Initialize the fVisibleBuffers array with default values for each buffer
        for (let i = 0; i < this.fVisibleBuffers.length; i++) {
            const thisBuffer = this.fVisibleBuffers[i]

            thisBuffer.buffer = null
            thisBuffer.style.color = '#FF0000' // clRed
            thisBuffer.style.style = TPenStyle.psSolid // psSolid
            thisBuffer.style.width = 1
            thisBuffer.PaintFrom = 0
        }
    }

    private SetIndicatorShortName(shortName: string): void {
        this.descriptor.shortName = shortName
    }

    private RecalculateMeAlways() {
        this._isNeedRecalculateAlways = true
    }

    private SetBackOffsetForCalculation(offsetForCalculation: number) {
        this._backOffsetForCalculation = offsetForCalculation
    }

    private SetEmptyValue(value: number): void {
        this.EmptyValue = value

        for (const buffer of this.fBuffers) {
            buffer.EmptyValue = value
        }
    }

    private SetFixedMinMaxValues(minValue: number, maxValue: number) {
        this.fMinValue = minValue
        this.fMaxValue = maxValue
    }

    // Function Overloading Declarations
    private SetIndexBuffer(index: number, buffer: IIndexBuffer): void
    private SetIndexBuffer(index: number, buffer: number): void
    // Unified Implementation
    private SetIndexBuffer(index: number, buffer: any): void {
        let bufferIndex: number
        if (buffer instanceof TIndexBufferProgramSide) {
            bufferIndex = this.buffers.IndexOf(buffer)
        } else {
            bufferIndex = buffer
        }
        if (
            this.options &&
            this.fVisibleBuffers &&
            this.fVisibleBuffers.IndexValid(index) &&
            this.buffers.IndexValid(bufferIndex)
        ) {
            const name = `BufferStyle${index.toString().padStart(2, '0')}` // Use const for immutable variable
            this.options.DeleteByMask(name)
            this.options.RegOption(name, TOptionType.ot_LineStyle, this.fVisibleBuffers[index].style)
            this.fVisibleBuffers[index].buffer = this.buffers[bufferIndex] // Use the correct case for buffers
            this.fVisibleBuffers[index].name = `Value ${index}`
        }
    }

    private SetIndexDrawBegin(bufferIndex: number, PaintFrom: number): void {
        if (this.VisibleBuffers.IndexValid(bufferIndex)) {
            this.VisibleBuffers[bufferIndex].PaintFrom = Math.max(0, PaintFrom)
        }
    }

    // Improved TypeScript implementation of the SetIndexLabel method
    private SetIndexLabel(index: number, text: string | null): void {
        // Use the exact format as in Delphi, including leading zeros for the index
        const name = `BufferStyle${index.toString().padStart(2, '0')}`

        // Convert text to a string, handling null as an empty string
        // In Delphi, PAnsiChar is a pointer to an ANSI string, so when it's null,
        // it should be converted to an empty string. The original TypeScript code
        // correctly uses the nullish coalescing operator (??) to handle this.
        const s = text ?? ''

        // Retrieve the option record using the formatted name
        const rec = this.options.GetOptionRec(name)
        if (rec) {
            // Assign the alias in the option record
            rec.alias = s

            // Check if the index is within the range of fVisibleBuffers
            // The original TypeScript code correctly translates the InRange check
            // from Delphi to a simple range check in TypeScript.
            if (index >= 0 && index < this.fVisibleBuffers.length) {
                // Update the name in the visible buffer at the specified index
                this.fVisibleBuffers[index].name = s
            }
        }
    }

    private SetIndexStyle(index: number, _type: number, _style: number, width: number, clr: TColor): void {
        // Check if the index is within the range of the fVisibleBuffers array.
        if (this.isIndexInRange(index, this.fVisibleBuffers)) {
            // Using a local variable for the buffer style to mimic the 'with' statement from Delphi.
            const bufferStyle = this.fVisibleBuffers[index].style

            clr = ColorHelperFunctions.FixColor(clr)

            if (_type === TDrawStyle.ds_Fill) {
                clr = ColorHelperFunctions.MakeColor(clr, 100)
                // Check if the previous buffer exists before trying to access it.
                if (index > 0 && this.fVisibleBuffers[index - 1]) {
                    this.fVisibleBuffers[index - 1].style.color = ColorHelperFunctions.MakeColor(
                        this.fVisibleBuffers[index - 1].style.color,
                        100
                    )
                }
            }

            // Apply the styles to the buffer.
            bufferStyle.color = clr
            bufferStyle.style = _style as TPenStyle
            bufferStyle.width = width

            // Only set the DrawingStyle if _type is not -1.
            if (_type !== -1) {
                bufferStyle.DrawingStyle = _type as TDrawStyle
            }
        } else {
            throw new StrangeError('Index out of range')
        }
    }

    private SetOutputWindow(aWindow: number): void {
        if (this.isValidOutputWindow(aWindow)) {
            this.fOutputWindow = aWindow as TOutputWindow
        } else {
            throw new StrangeError('Invalid TOutputWindow value')
        }
    }

    private SetBufferShift(BuffIndex: number, shift: number): void {
        if (BuffIndex >= 0 && BuffIndex < this.fVisibleBuffers.length) {
            const bufferItem = this.fVisibleBuffers[BuffIndex]
            if (bufferItem && bufferItem.buffer) {
                bufferItem.buffer.shift = shift
            }
        }
    }

    private SetIndexSymbol(index: number, symbol: number, xoffs: number, yoffs: number): void {
        if (index >= 0 && index < this.fVisibleBuffers.length) {
            this.fVisibleBuffers[index].style.Symbol = symbol
            this.fVisibleBuffers[index].style.xoffs = xoffs
            this.fVisibleBuffers[index].style.yoffs = yoffs
        }
    }

    private CreateIndexBufferWithArgs(
        index: number,
        aLabel: string,
        drawStyle: TDrawStyle,
        style: TPenStyle,
        width: number,
        color: TColor
    ): TIndexBuffer {
        const programSideBuffer = this.CreateIndexBuffer()

        this.SetIndexBuffer(index, programSideBuffer)
        this.SetIndexStyle(index, drawStyle, style, width, color)
        this.SetIndexLabel(index, aLabel)

        return new TIndexBuffer(programSideBuffer)
    }

    private SetLevelValue(index: number, value: number) {
        if (index < this.fLevels.Count) {
            this.fLevels[index].value = value
        }
    }

    private SetBufferValue(buffer: number, index: number, value: number) {
        const buff = this.fBuffers[buffer]
        if (buff) buff.SetValue(index, value)
    }

    private GetBufferCount(buffer: number): number {
        const buff = this.fBuffers[buffer]

        if (buff) return buff.Count()
        return 0
    }

    private GetBufferMax(buffer: number, index1: number, index2: number): number {
        const buff = this.fBuffers[buffer]

        if (buff) return buff.GetMax(index1, index2)
        return 0
    }

    private GetBufferMin(buffer: number, index1: number, index2: number): number {
        const buff = this.fBuffers[buffer]

        if (buff) return buff.GetMin(index1, index2)
        return 0
    }

    public onChangeTimeframeOrSymbol(timeframe: number, symbol: TSymbolData): void {
        this.buffers.ClearValues()

        this.timeframe = timeframe

        this.ReassignSymbolData(symbol)

        for (const [index, buffer] of this.buffers.entries()) {
            if (buffer instanceof TCommonIndexBuffer) {
                buffer.bars = this.getBars()
                buffer.buff.setDataDescriptor = this.getBars().DataDescriptor
                buffer.buff.setBarsArray = this.getBars()
            }
        }

        this.SaveOptions()
        this.RefreshLevels()
        this.RefreshOptions()
    }

    public isSame(indicator: TRuntimeIndicator): boolean {
        return this.descriptor.FileName === indicator.descriptor.FileName
    }

    public toJSON(): IndicatorJSON {
        const listForOptions = new TOffsStringList()
        this.options.SaveToList(listForOptions)
        return {
            ShortName: this.descriptor.shortName,
            IsVisible: this.isVisible,
            Levels: this.fLevels.ExportToStr(this.fDecimals),
            Decimals: this.fDecimals,
            Symbol: this.getSymbolData().symbolInfo.SymbolName,
            Options: listForOptions.SaveToString(),
            BufferVisibility: this.fVisibleBuffers.map((buffer) => {
                return buffer.IsVisible()
            }),
            IsCustom: this.isCustom(),
            FileName: this.descriptor.FileName
        }
    }

    public isCustom(): boolean {
        return this.indicatorDescriptor.isCustom
    }

    public isEquals(ind: IndicatorDescriptor): boolean {
        return this.indicatorDescriptor.IsEqual(ind)
    }

    public get OutputWindow(): TOutputWindow {
        return this.fOutputWindow
    }

    public get VisibleBuffers(): VisibleBuffersList {
        return this.fVisibleBuffers
    }

    public get levels(): TLevelsList {
        return this.fLevels
    }

    public get MinValue(): number {
        return this.fMinValue
    }

    public get MaxValue(): number {
        return this.fMaxValue
    }

    public get decimals(): number {
        return this.fDecimals
    }

    // IndicatorShortName(name: string): void {
    //   this.fShortName = name;
    // }

    public GetImportantOptions(): string {
        let result = ''
        for (let i = 0; i < this.options.count; i++) {
            const rec: TDLLOptionRec = this.options[i]
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.isvisible) {
                result += `${rec.Value};`
            }
        }
        result = StrsConv.DelSymbolsRight(result, 1)
        return result
    }

    public SetImportantOptions(params: string): void {
        let rec: TDLLOptionRec
        let s: string
        let i: number

        for (i = 0; i < this.options.count; i++) {
            rec = this.options[i]
            // In TypeScript, 'in' operator is used to check if a property exists on an object, not to check if a value is in an array.
            // Therefore, we need to use Array.includes() method for the correct behavior.
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.isvisible) {
                ;[s, params] = StrsConv.GetSubStr(params, ';', false)
                try {
                    rec.SetValue(s)
                } catch (error) {
                    if (DebugUtils.DebugMode) {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                    }
                    this.Print(`Can not assign value "${s}" to option "${rec.name}" in indicator "${this.ShortName}"`)
                    return
                }
            }
        }
    }

    private VisibleBuffersInDataWindow(): number {
        let result = 0
        for (let i = 0; i < this.VisibleBuffers.length; i++) {
            if (this.VisibleBuffers[i].name !== '') {
                result++
            }
        }
        return result
    }

    public GetNameWithParams(): string {
        let result = this.ShortName
        let params = ''

        for (let i = 0; i < this.options.length; i++) {
            const rec: TDLLOptionRec = this.options[i]
            if (
                ![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) &&
                rec.isvisible &&
                rec.useInNameWithParams
            ) {
                switch (rec.OptionType) {
                    case TOptionType.ot_Session: {
                        const sessionObj = rec.GetValueObject() as TOptValue_Session
                        if (sessionObj.isEnabled) {
                            params += `${sessionObj.name}: ${sessionObj.startTime}-${sessionObj.endTime}; `
                        }
                        break
                    }
                    case TOptionType.ot_SessionsArray: {
                        let sessionsArray = rec.GetValueObject().sessions as TOptValue_Session[]
                        for (let j = 0; j < sessionsArray.length; j++) {
                            const sessionObj = sessionsArray[j]
                            if (sessionObj.isEnabled) {
                                params += `${sessionObj.name}: ${sessionObj.startTime}-${sessionObj.endTime}; `
                            }
                        }
                        break
                    }
                    default: {
                        params += `${rec.Value}, `
                        break
                    }
                }
            }
        }

        params = StrsConv.DelSymbolsRight(params, 2)
        if (params) {
            result = result + ' (' + params + ')'
        }

        return result
    }
}
