import { t } from 'i18next'
import { TIndexBuffer } from '../api/IIndicatorApi'
import { DelphiColors } from '../../../delphi_compatibility/DelphiBasicTypes'
import { TOptionType, TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'
import { IndicatorImplementation } from '../api/IndicatorImplementation'

export default class ATR extends IndicatorImplementation {
    Period: TOptValue_number = new TOptValue_number(14)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    ATRBuffer!: TIndexBuffer
    TempBuffer!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.SetBackOffsetForCalculation(200)
        this.api.IndicatorShortName(t('indicatorModal.atr.name'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.Period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, INT_MAX)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.ATRBuffer = this.api.CreateIndexBuffer()
        this.TempBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.ATRBuffer)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, DelphiColors.clRed)
        this.api.SetIndexLabel(0, t('indicatorModal.atr.fields.atr'))
    }

    Calculate(index: number): void {
        const P_High = this.api.High(index)
        const P_Low = this.api.Low(index)
        let prevclose,
            sum = 0

        if (index === this.api.Bars() - 1) {
            this.TempBuffer.setValue(index, P_High - P_Low)
        } else {
            prevclose = this.api.GetPrice(index + 1, this.ApplyToPrice.value as TPriceType)
            this.TempBuffer.setValue(index, Math.max(P_High, prevclose) - Math.min(P_Low, prevclose))
        }

        for (let i = 0; i < this.Period.value; i++) {
            sum += this.TempBuffer.getValue(index + i)
        }

        this.ATRBuffer.setValue(index, sum / this.Period.value)
    }
}
