import {
    forwardRef,
    useCallback,
    createContext,
    useMemo,
    ReactNode,
    useContext,
    useSyncExternalStore,
    useEffect,
    useRef,
    Ref
} from 'react'

import ReactDOM from 'react-dom'

import cx from 'classnames'

import { createObserver } from './utils/createObserver'

import useDraggable from './hooks/useDraggable'

import Content, { ContentProps } from './components/Content'
import Controls, { ControlsProps } from './components/Controls'
import Footer, { FooterProps } from './components/Footer'
import Header, { HeaderProps } from './components/Header'

import styles from './index.module.scss'

export type { ControlsProps, ContentProps, HeaderProps, FooterProps }

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fullScreen'

export interface ModalContextProps {
    modalName: string
    withCloseIcon: boolean
    onClose: () => void
    onSubmit?: () => void
    size?: SizeType
    onDrag?: (e: any) => void
    onDragStart?: (e: any) => void
    onDragEnd?: () => void
    isDraggable?: boolean
    inlineRows?: boolean
}

const ModalContext = createContext<ModalContextProps | null>(null)

export const useModalContext = (): ModalContextProps | null => useContext(ModalContext)

export type ModalProps = {
    name: string
    size?: SizeType

    onClose?: () => void
    onSubmit?: () => void
    withCloseIcon?: boolean

    children: ((props: any) => JSX.Element) | JSX.Element | JSX.Element[] | ReactNode
    additionalClassNames?: {
        root?: string
        container?: string
    }
    ref?: Ref<HTMLDivElement>
    metadata?: { [key: string]: any }
    isDraggable?: boolean
    withClickOutside?: boolean
    withEnterSubmit?: boolean
}

const observer = createObserver()
const modalsState = new Map()

const generateKey = (key: string, name: string): string => `${key}_${name}`

const Dialog = forwardRef<HTMLDivElement, ModalProps>(
    (
        {
            isDraggable = false,
            name,
            additionalClassNames,
            size,
            children,
            onClose,
            onSubmit,
            withCloseIcon = false,
            withClickOutside = true,
            withEnterSubmit = false,
            metadata
        },
        ref
    ) => {
        const { open, props } = useModalState(name)
        const modalRef = useRef<HTMLDivElement>(null)

        const { onMouseDown, onMouseMove, onMouseUp, position, isDragging } = useDraggable(modalRef)

        useEffect(() => {
            if (isDraggable && isDragging) {
                window.addEventListener('mousemove', onMouseMove)
                window.addEventListener('mouseup', onMouseUp)

                return () => {
                    window.removeEventListener('mousemove', onMouseMove)
                    window.removeEventListener('mouseup', onMouseUp)
                }
            }
        }, [isDragging, onMouseMove, onMouseUp])

        useEffect(() => {
            if (withEnterSubmit) {
                const handleKeyDown = (event: KeyboardEvent) => {
                    if (event.key === 'Enter') {
                        event.preventDefault()
                        onSubmit?.()
                    }
                }

                if (open) {
                    window.addEventListener('keydown', handleKeyDown)
                }

                return () => {
                    window.removeEventListener('keydown', handleKeyDown)
                }
            }
        }, [open, onSubmit])

        const closeModal = useCallback(() => {
            onClose?.()
            removeModal(name)
        }, [name, onClose])

        const modalContextValue = useMemo(() => {
            if (isDraggable) {
                return {
                    modalName: name,
                    onSubmit,
                    onClose: closeModal,
                    withCloseIcon: true,
                    props,
                    size,
                    onDrag: onMouseMove,
                    onDragStart: onMouseDown,
                    onDragEnd: onMouseUp,
                    isDraggable
                }
            }

            return {
                modalName: name,
                onSubmit,
                onClose: closeModal,
                withCloseIcon,
                props,
                size,
                isDraggable
            }
        }, [
            name,
            onSubmit,
            closeModal,
            withCloseIcon,
            props,
            size,
            onMouseMove,
            onMouseUp,
            onMouseDown,
            isDraggable,
            isDragging
        ])

        useEffect(() => {
            modalRef.current?.focus()
        }, [])

        return ReactDOM.createPortal(
            open ? (
                <ModalContext.Provider value={modalContextValue}>
                    <dialog
                        data-test-id={name}
                        className={cx(styles.root, additionalClassNames?.root, {
                            [styles.TransparentBackDrop]: isDraggable
                        })}
                        onClick={({ target, currentTarget }) =>
                            !isDraggable && withClickOutside && target === currentTarget && closeModal()
                        }
                    >
                        <div
                            style={{
                                transform: `translate(${position.x}px, ${position.y}px)`
                            }}
                            className={cx(
                                styles.container,
                                {
                                    [styles[`size-${size}`]]: size,
                                    [styles.draggableContainer]: isDraggable
                                },
                                additionalClassNames?.container
                            )}
                            ref={modalRef}
                            tabIndex={0}
                        >
                            {typeof children === 'function' ? children(props) : children}
                        </div>
                    </dialog>
                </ModalContext.Provider>
            ) : null,
            document.querySelector('body') as Element
        )
    }
)

export const addModal = <T extends {}>(name: string, props?: T) => {
    modalsState.set(name, {
        open: true,
        props: props
    })
    observer.dispatch(generateKey('add', name), props)
}
export const removeModal = (name: string) => {
    modalsState.delete(name)
    observer.dispatch(generateKey('remove', name))
}

const defaultState = {
    open: false,
    props: null
}

export const useModalState = (name: string) => {
    const subscribe = useCallback(
        (callback: any) => {
            observer.subscribe(generateKey('add', name), callback)
            observer.subscribe(generateKey('remove', name), callback)

            return () => {
                observer.unsubscribe(generateKey('add', name), callback)
                observer.unsubscribe(generateKey('remove', name), callback)
            }
        },
        [name]
    )

    return useSyncExternalStore(
        subscribe,
        () => modalsState.get(name) ?? defaultState,
        () => undefined
    )
}

Dialog.displayName = 'Modal'

export const Modal = Object.assign(Dialog, {
    Header,
    Content,
    Footer,
    Controls
})
