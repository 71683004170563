import { useState, FC, ReactNode, useEffect, useCallback } from 'react'

import { ColorPicker, FontStylePicker, Flex, Typography, SimpleDropdown } from '@fto/ui'

import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { FONT_SIZE_OPTIONS } from '@root/pages/ChartPage/components/GraphToolModal/components/FieldCreator/components/TextStyle/constants'
import { useTranslation } from 'react-i18next'

type LabelBoxProps = {
    label: string
    children: ReactNode
}

const LabelBox: FC<LabelBoxProps> = ({ label, children }) => (
    <Flex direction='column' gap={8} alignItems='center'>
        <Typography type='subtext-regular' color='gray-600'>
            {label}
        </Typography>
        {children}
    </Flex>
)

type DataType = { color: string; weight: string; style: string; size: number }

interface FontStyleProps {
    data: DataType
    optionKey?: string
    onChange: ({ color, style, weight }: DataType) => void
}

const TextStyle: FC<FontStyleProps> = ({ data, optionKey, onChange }) => {
    const { t } = useTranslation()

    const [selectedParams, setSelectedParams] = useState({
        color: data.color,
        weight: data.weight,
        style: data.style,
        size: data.size
    })

    const updateSize = useCallback(
        (value: number | string) => {
            onChange({ ...selectedParams, size: Number(value) })
        },
        [selectedParams]
    )

    const updateColor = useCallback(
        (value: string) => {
            onChange({ ...selectedParams, color: value })
        },
        [selectedParams]
    )

    const updateWeight = useCallback(() => {
        onChange({ ...selectedParams, weight: selectedParams.weight === 'bold' ? 'regular' : 'bold' })
    }, [selectedParams])

    const updateStyle = useCallback(() => {
        onChange({ ...selectedParams, style: selectedParams.style === 'italic' ? 'normal' : 'italic' })
    }, [selectedParams])

    useEffect(() => {
        setSelectedParams({ color: data.color, weight: data.weight, style: data.style, size: data.size })
    }, [data])

    return (
        <Flex alignItems='center' gap={20}>
            <LabelBox label={t('modalFieldsCreator.size')}>
                <SimpleDropdown onChange={updateSize} value={selectedParams.size} options={FONT_SIZE_OPTIONS} />
            </LabelBox>
            <LabelBox label={t('modalFieldsCreator.color')}>
                <ColorPicker color={selectedParams.color} onColorChange={updateColor} withOpacity={false} />
            </LabelBox>
            <LabelBox label={t('modalFieldsCreator.style')}>
                <FontStylePicker isActive={selectedParams.style === 'italic'} type='fontStyle' onSelect={updateStyle} />
            </LabelBox>

            <LabelBox label={t('modalFieldsCreator.weight')}>
                <FontStylePicker
                    isActive={selectedParams.weight === 'bold'}
                    type='fontWeight'
                    onSelect={updateWeight}
                />
            </LabelBox>
        </Flex>
    )
}

export default TextStyle
