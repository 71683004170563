import React, { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, Flex, Typography, SimpleDropdown } from '@fto/ui'
// import { Icon } from '@fto/icons'

import classNames from 'classnames'
import TimezoneRange from '@root/pages/Dashboard/components/Modals/CreateProjectModal/TimezoneRange/TimezoneRange'
// import useScrollToElem from '@root/hooks/useScrollToElem'
// import InputsRadioGroup from '@root/components/Common/RadioGroup/InputsRadioGroup'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

// import CreateProjectCalendar, {
//     CalendarPositionOptions
// } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectCalendar/CreateProjectCalendar'
import {
    // dataCheckboxes,
    dstOptions,
    SESSION_CLOSE_TIME_PARAMS_BY_ZONE,
    sessionCloseTime,
    SESSION_ZONES
    // startDateCheckboxes
} from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectAdvancedForm/advanced-form.schema'
import Leverage from './components/Leverage'

import styles from './create-project-advantages.module.scss'

type Props = {
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
}

const CreateProjectAdvancedForm: FC<Props> = ({ formData, setFormData }) => {
    const { t } = useTranslation()

    const [isOpened, setIsOpened] = useState(true)
    // const [isCalendarActive, setIsCalendarActive] = useState(false)
    const scrollElemRef = useRef<HTMLElement>(null)
    // const { scrollToElement } = useScrollToElem(scrollElemRef)

    const setTimeZone = (value: number) => {
        // NOTE: we have to match TZ with session close time options. IF it matches we need to change session close time value

        const matchedValue = SESSION_CLOSE_TIME_PARAMS_BY_ZONE.find(({ timeZone }) => timeZone === value)

        if (matchedValue) {
            return setFormData((prev) => ({ ...prev, TimeZone: value, SessionCloseTime: matchedValue.session }))
        }

        return setFormData((prev) => ({ ...prev, TimeZone: value, SessionCloseTime: SESSION_ZONES.custom.value }))
    }

    // const triggerOpenAdvanced = () => {
    //     setIsOpened(!isOpened)
    //     if (isOpened) {
    //         return
    //     }
    //     setTimeout(() => {
    //         scrollToElement()
    //     }, 200)
    // }

    const onSessionCloseTimeChange = useCallback(
        (value: string) => {
            const matchedValue = SESSION_CLOSE_TIME_PARAMS_BY_ZONE.find(({ session }) => session === value)

            return setFormData((prev) => ({
                ...prev,
                ['SessionCloseTime']: value,
                TimeZone: matchedValue && value !== SESSION_ZONES.custom.value ? matchedValue.timeZone : prev.TimeZone
            }))
        },
        [setFormData]
    )

    const onDaylightSavingTimeChange = useCallback(
        (value: string) => {
            setFormData((prev) => ({ ...prev, ['DaylightSavingTime']: value }))
        },
        [setFormData]
    )

    // const onDataCheckboxesChange = (value: { [name: string]: string }) => {
    //     setFormData((prev) => ({ ...prev, ...value }))
    // }
    //
    // const onStartDateCheckboxesChange = (value: { [name: string]: string }) => {
    //     setIsCalendarActive(value.StartDate === 'manualDate')
    // }
    //
    // const calendarSettings: {
    //     transformOrigin: CalendarPositionOptions
    //     anchorOrigin: CalendarPositionOptions
    // } = {
    //     transformOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'left'
    //     },
    //     anchorOrigin: {
    //         vertical: 'top',
    //         horizontal: 'left'
    //     }
    // }

    return (
        <div className={styles.wrapper}>
            {/* @TODO: we have some types problem here
           @ts-ignore*/}
            {/*<Flex ref={scrollElemRef} className={styles.trigger} onClick={triggerOpenAdvanced} gap={16}>*/}
            {/*    <Icon color={COLORS['orange-500']} name={isOpened ? 'minus' : 'plus'} />*/}
            {/*    <Typography color={'orange-500'} type='text-semibold'>*/}
            {/*        {t('dashboard.modals.projects.advanced.advancedTitle')}*/}
            {/*    </Typography>*/}
            {/*</Flex>*/}

            <div className={classNames(styles.content, { [styles.opened]: isOpened })}>
                {/*<Flex className={styles.formBlock} direction={'column'} gap={16}>*/}
                {/*    <Typography type='text-semibold'>{t('dashboard.modals.projects.advanced.dataTitle')}</Typography>*/}
                {/*    <InputsRadioGroup*/}
                {/*        name={'DataType'}*/}
                {/*        onChange={onDataCheckboxesChange}*/}
                {/*        dataCheckboxes={dataCheckboxes}*/}
                {/*    />*/}
                {/*</Flex>*/}
                {/*<Flex className={styles.formBlock} direction={'column'} gap={16}>*/}
                {/*    <Typography type='text-semibold'>*/}
                {/*        {t('dashboard.modals.projects.advanced.datestartTitle')}*/}
                {/*    </Typography>*/}
                {/*    <InputsRadioGroup*/}
                {/*        name={'StartDate'}*/}
                {/*        onChange={onStartDateCheckboxesChange}*/}
                {/*        dataCheckboxes={startDateCheckboxes}*/}
                {/*    />*/}
                {/*</Flex>*/}
                {/*<div className={styles.formBlock}>*/}
                {/*    <CreateProjectCalendar*/}
                {/*        {...calendarSettings}*/}
                {/*        disabled={!isCalendarActive}*/}
                {/*        initialDate={formData.StartDate}*/}
                {/*        maxDate={formData.EndDate}*/}
                {/*        minDate={formData.StartDate}*/}
                {/*        name={'StartFrom'}*/}
                {/*        setFormData={setFormData}*/}
                {/*    />*/}
                {/*</div>*/}

                <Flex className={styles.formBlock} direction={'column'} gap={16}>
                    <Typography type='text-semibold'>
                        {t('dashboard.modals.projects.advanced.timezoneTitle')}
                    </Typography>

                    <TimezoneRange setTimezone={setTimeZone} value={formData.TimeZone} />

                    <Flex direction='column' gap={4}>
                        <Typography type='interface-medium'>
                            {t('dashboard.modals.projects.advanced.timezoneInput1')}
                        </Typography>
                        <SimpleDropdown
                            options={sessionCloseTime}
                            value={formData.SessionCloseTime || ''}
                            onChange={onSessionCloseTimeChange}
                            classNames={{
                                header: styles.SelectPreview,
                                root: styles.SelectRoot,
                                content: styles.SelectContent
                            }}
                        />
                    </Flex>

                    <Flex direction='column' gap={4}>
                        <Typography type='interface-medium'>
                            {t('dashboard.modals.projects.advanced.timezoneInput2')}
                        </Typography>
                        <SimpleDropdown
                            options={dstOptions}
                            value={formData.DaylightSavingTime || ''}
                            onChange={onDaylightSavingTimeChange}
                            classNames={{
                                header: styles.SelectPreview,
                                root: styles.SelectRoot,
                                content: styles.SelectContent
                            }}
                        />
                    </Flex>

                    <Leverage setFormData={setFormData} value={formData.Leverage} />
                </Flex>
            </div>
        </div>
    )
}

export default CreateProjectAdvancedForm
