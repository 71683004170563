import { FC, useCallback } from 'react'
import { t } from 'i18next'

import { FilenameTypes, Icon } from '@fto/icons'
import { Flex, Typography, Checkbox } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    localeKey: string
    iconName: string
    countryName: string
    isSelected: boolean
    toggleCountry: (name: string) => void
}

const Row: FC<Props> = ({ localeKey, iconName, isSelected, toggleCountry, countryName }) => {
    const toggleSelectedState = useCallback(() => {
        toggleCountry(countryName)
    }, [countryName, toggleCountry])

    return (
        <Flex alignItems='center' gap={8} className={styles.Row} onClick={toggleSelectedState}>
            <Checkbox checked={isSelected} />
            <Icon name={iconName as (typeof FilenameTypes)[number]} size={18} />
            <Typography type='interface-medium' color='gray-1000'>
                {t(localeKey)}
            </Typography>
        </Flex>
    )
}

export default Row
