import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import styles from './index.module.scss'

type Props = {
    size?: number
}
const AppLoader: FC<Props> = ({ size = 64 }) => {
    return (
        <div className={styles.Wrapper}>
            <CircularProgress size={size} />
        </div>
    )
}
export default AppLoader
