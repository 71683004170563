import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { NotImplementedError } from '../../utils/common_utils'
import { TList } from '../../delphi_compatibility/BasicLists'

export enum TLoadStage {
    ls_Reset,
    ls_Update,
    ls_UpdateText,
    ls_ActivateWaitIcon,
    ls_DeactivateWaitIcon
}

export type TLoadProgressProc = (stage: TLoadStage, StageName: string, progress: number) => void
export type TProcessFileProc = (path: string, FileName: string) => void

export type TVerInfo = {
    v1: number
    v2: number
    v3: number
    v4: number
}

export class TMyObjectList<T> extends TList<T> {
    constructor() {
        super()
    }

    public get LastItem(): T {
        return this[this.length - 1]
    }
}

export type PIntArray = TIntArray
export type TIntArray = number[]

export class Common {
    public static HelpFileName: string = ''
    public static DebugFileName: string = 'debug.log'
    public static UserDir: string = ''
    public static AppDir: string = ''
    public static MainFormHandle: number = 0
    public static MainFormClientHandle: number = 0
    public static AddMessageProc: TLoadProgressProc | null = null
    public static AppVersion: number = 0.0
    public static AppBuildTime: TDateTime
    public static MutexName: string = ''

    public static GetRandomValueBetween(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    public static isClientSide = () => {
        return typeof window !== 'undefined'
    }

    public static RndTrunc(value: number, module: number): number {
        return Math.floor(value / module)
    }

    public static fitNumberIntoRange(value: number, _min: number, _max: number): number {
        return Math.max(_min, Math.min(_max, value))
    }

    public static DistancePtoLine(x0: number, y0: number, x1: number, y1: number, x: number, y: number): number {
        let dx1: number, dy1: number, dx2: number, dy2: number, dv: number

        dx1 = x1 - x0
        dy1 = y1 - y0
        dx2 = x - x0
        dy2 = y - y0
        dv = Math.abs(dx1 * dx1 + dy1 * dy1)
        if (dv > 0) {
            dv = Math.sqrt(dv)
        }

        if (dv === 0) {
            return 1000 // Arbitrary large number for the degenerate case where the line length is 0
        } else {
            return Math.abs(dx1 * dy2 - dx2 * dy1) / dv
        }
    }

    public static SelfReplaceStrProc(dest: string, source: string): void {
        throw new NotImplementedError('Common.SelfReplaceStrProc')

        //source implementation:
        // FreeMem(dest);
        // GetMem(dest, AnsiStrings.StrLen(source) + 1);
        // AnsiStrings.StrCopy(dest, source);
    }

    //generates a unique id
    static GenerateUID(): string {
        return Math.random().toString(36).substring(2) + Date.now().toString(36)
    }
}
