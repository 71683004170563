import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Flex, Typography } from '@fto/ui'
import SymbolPair from '@root/components/SymbolPair'
import { fireMixpanelEvent } from '@root/utils/api'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'

import { getChangedDigitsLength } from '../../utils/helpers'

import styles from './index.module.scss'

type Props = {
    symbol: string
    lastBar: number | null
    cells: { title: string; flex: number; active: boolean }[]
}

const WatchlistSymbolRow: FC<Props> = ({ symbol, lastBar, cells }) => {
    const [lastPrice, setLastPrice] = useState(0)
    const [priceChange, setPriceChange] = useState(0)
    const [priceChangeColor, setPriceChangeColor] = useState('gray-800' as 'gray-800' | 'green-400' | 'red-400')
    const symbolInfo = useMemo(() => GlobalSymbolList.SymbolList.GetExistingSymbol(symbol), [symbol])

    useEffect(() => {
        if (!symbolInfo?.IsSeeked) return
        const dayPriceChange = symbolInfo.DayPriceChange()
        const lastPrice = symbolInfo.LastPrice()
        if (!dayPriceChange || !lastPrice) return

        setLastPrice(lastPrice)
        setPriceChange(dayPriceChange.value)
        setPriceChangeColor(() => {
            if (symbolInfo.DayPriceChange().value > 0) {
                return 'green-400'
            }

            return symbolInfo.DayPriceChange().value === 0 ? 'gray-800' : 'red-400'
        })
    }, [lastBar, symbolInfo?.IsSeeked, symbolInfo?.DayPriceChange, symbolInfo?.LastPrice])

    const cellTitles = useMemo(() => cells.map((cell) => cell.title), [cells])
    const decimals = useMemo(() => symbolInfo?.symbolInfo.decimals ?? 2, [symbolInfo?.symbolInfo.decimals])
    const formattedLastPrice = useMemo(() => lastPrice.toFixed(decimals), [lastPrice, decimals])
    const formattedPriceChange = useMemo(() => priceChange.toFixed(decimals), [priceChange, decimals])
    const changedDigitsLength = useMemo(
        () => getChangedDigitsLength(priceChange.toFixed(decimals)),
        [priceChange, decimals]
    )
    const unchangedDigits = useMemo(
        () => formattedLastPrice.slice(0, -changedDigitsLength),
        [formattedLastPrice, changedDigitsLength]
    )
    const changedDigits = useMemo(
        () => formattedLastPrice.slice(-changedDigitsLength),
        [formattedPriceChange, changedDigitsLength]
    )

    const changeSymbol = useCallback(() => {
        const chartWindow = GlobalChartsController.Instance.getActiveChart()
        if (!chartWindow) return

        fireMixpanelEvent('currency_changed', {
            from: chartWindow.SelectedSymbolName,
            to: symbol
        })

        GlobalChartsController.Instance.setNewSymbol(symbol, chartWindow)
    }, [symbol])

    return (
        <Flex direction='row' gap={16} alignItems='center' className={styles.symbolRow} onClick={changeSymbol}>
            <Flex gap={8} style={{ flex: 7 }} alignItems='center' justifyContent={'normal'}>
                <SymbolPair symbolSize={12} symbol={symbol} />
                <Typography type='interface-semibold' color={'gray-800'} block truncate>
                    {symbol}
                </Typography>
            </Flex>
            {cellTitles.includes('last') && (
                <Flex gap={8} style={{ flex: 4 }} alignItems='center' justifyContent={'flex-end'}>
                    <Typography
                        type='interface-regular'
                        color={'gray-800'}
                        block
                        align='right'
                        className={styles.priceLabel}
                    >
                        {priceChange ? unchangedDigits : '-'}
                    </Typography>
                    <Typography
                        type='interface-regular'
                        color={priceChangeColor}
                        block
                        truncate
                        align='right'
                        className={styles.priceLabel}
                    >
                        {priceChange ? changedDigits : '-'}
                    </Typography>
                </Flex>
            )}
            {cellTitles.includes('change') && (
                <Flex gap={8} style={{ flex: 4 }} alignItems='center' justifyContent={'flex-end'}>
                    <Typography
                        type='interface-regular'
                        color={priceChangeColor}
                        block
                        truncate
                        style={{ flex: 4 }}
                        align='right'
                    >
                        {priceChange ? formattedPriceChange : '-'}
                    </Typography>
                </Flex>
            )}
            {cellTitles.includes('changePercent') && (
                <Flex gap={8} style={{ flex: 4 }} alignItems='center' justifyContent={'flex-end'}>
                    <Typography
                        type='interface-regular'
                        color={priceChangeColor}
                        block
                        truncate
                        style={{ flex: 4 }}
                        align='right'
                    >
                        {priceChange ? ((priceChange / lastPrice) * 100).toFixed(2) : '-'}%
                    </Typography>
                </Flex>
            )}
        </Flex>
    )
}

export default WatchlistSymbolRow
