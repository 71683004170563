import { useState, useEffect } from 'react'

const useDevicePixelRatio = (callback: () => void) => {
    const [devicePixelRatio, setDevicePixelRatio] = useState(() => window.devicePixelRatio)

    useEffect(() => {
        const updateDevicePixelRatio = () => {
            const newRatio = window.devicePixelRatio

            if (newRatio !== devicePixelRatio) {
                setDevicePixelRatio(newRatio)
                if (callback) {
                    callback()
                }
            }
        }

        const mediaQuery = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`)
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener('change', updateDevicePixelRatio)
        } else {
            mediaQuery.addListener(updateDevicePixelRatio)
        }

        return () => {
            if (mediaQuery.removeEventListener) {
                mediaQuery.removeEventListener('change', updateDevicePixelRatio)
            } else {
                mediaQuery.removeListener(updateDevicePixelRatio)
            }
        }
    }, [callback, devicePixelRatio])

    return devicePixelRatio
}

export default useDevicePixelRatio
