import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Checkbox, Flex, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    label: string
    value: boolean
    onOptionChange: (value: string) => void
    time?: string
}

const SettingsOption: FC<Props> = ({ label, value, onOptionChange, time }) => {
    const { t } = useTranslation()

    const handleChange = useCallback(() => {
        onOptionChange(label)
    }, [onOptionChange])

    return (
        <Flex justifyContent='space-between'>
            <Flex gap={8} alignItems='center' onClick={handleChange}>
                <Checkbox checked={value} />
                <Typography>{t(`jumpToSettingsModal.settings.${label}`)}</Typography>
            </Flex>
            {time && (
                <Flex gap={4} alignItems='center' className={styles.time}>
                    <Icon name='time' size={16} />
                    <Typography type='interface-regular'>{time}</Typography>
                </Flex>
            )}
        </Flex>
    )
}

export default SettingsOption
