import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, InputRadio, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    idKey: string
    label: string
    onClick: (key: string) => void
    isActive?: boolean
}

const OptionRow: FC<Props> = ({ idKey, label, onClick, isActive }) => {
    const { t } = useTranslation()

    const selectOption = useCallback(() => {
        onClick(idKey)
    }, [onClick, idKey])

    return (
        <Flex className={styles.Row} alignItems='center' gap={12} key={idKey} onClick={selectOption} block>
            <InputRadio checked={isActive} />
            <Typography type='interface-regular'>{t(label)}</Typography>
        </Flex>
    )
}

export default OptionRow
