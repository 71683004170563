import { TakeprofitValues, MarketValues, OrderValues } from '../OrderWndStructs'
import { TakeprofitCalculationStrategy } from './TakeprofitCalculationStrategy'

export class SellLimitTakeprofitCalculation extends TakeprofitCalculationStrategy {
    onChangeTakeprofitPrice(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...takeprofitValues }
        const scale = Math.pow(10, marketValues.postDecimalDigits)
        updatedValues.price = Math.round(updatedValues.price * scale) / scale

        const newPoints = Math.round((orderValues.atPrice - updatedValues.price) / marketValues.minimumPriceChange)
        if (newPoints !== takeprofitValues.points) {
            updatedValues.points = newPoints
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    onChangeTakeprofitPoints(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...takeprofitValues }
        updatedValues.points = Math.round(takeprofitValues.points)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    onChangeTakeprofitInUsd(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...takeprofitValues }
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        if (updatedPoints !== takeprofitValues.points) {
            updatedValues.points = updatedPoints
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    onChangeTakeprofitInPercent(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...takeprofitValues }
        updatedValues.usd = marketValues.equity * (updatedValues.percent / 100)
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        if (updatedPoints !== takeprofitValues.points) {
            updatedValues.points = updatedPoints
            updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    recalculateTakeprofitValuesByPoints(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...currTakeprofitValues }

        updatedValues.points = Math.round(currTakeprofitValues.points)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateTakeprofitValuesByPrice(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...currTakeprofitValues }

        const scale = Math.pow(10, marketValues.postDecimalDigits)
        updatedValues.price = Math.round(updatedValues.price * scale) / scale

        updatedValues.points = Math.round((orderValues.atPrice - updatedValues.price) / marketValues.minimumPriceChange)
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateTakeprofitValuesByUsd(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...currTakeprofitValues }

        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        updatedValues.points = updatedPoints
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateTakeprofitValuesByPercent(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null {
        const updatedValues = { ...currTakeprofitValues }

        updatedValues.usd = marketValues.equity * (updatedValues.percent / 100)
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        updatedValues.points = updatedPoints
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }
}
