import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export default class CCI extends IndicatorImplementation {
    CCIPeriod: TOptValue_number = new TOptValue_number(14)

    // Index buffers
    CCIBuffer!: TIndexBuffer
    MAbuff!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.cci'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)

        this.api.AddLevel(-100, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.AddLevel(100, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.SetEmptyValue(0.0001234)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.CCIPeriod)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Number.MAX_SAFE_INTEGER)

        this.MAbuff = this.api.CreateIndexBuffer()
        this.CCIBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.CCIBuffer)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#1E90FF')
        this.api.SetIndexLabel(0, t('indicatorModal.cci.fields.cci'))
    }

    //TODO:IN Is not calculating CCIPeriod 2 properly compared to FT6, rest of the test cases with different periods are working fine
    //TODO:EX For some reason, FT6 skips drawing some bars and sets buffer CCI value as 0,
    // examples:period 2, bar 2003.05.07 15:09; period 14 bar 2003.05.07 19:50
    Calculate(index: number): void {
        if (index === 530) {
            const temp = 0
        }
        const low = this.api.GetPrice(index, TPriceType.pt_Low)
        const high = this.api.GetPrice(index, TPriceType.pt_High)
        const close = this.api.GetPrice(index, TPriceType.pt_Close)
        const lowplus1 = this.api.GetPrice(index + 1, TPriceType.pt_Low)
        const highplus1 = this.api.GetPrice(index + 1, TPriceType.pt_High)
        const closeplus1 = this.api.GetPrice(index + 1, TPriceType.pt_Close)
        const hlc3 = this.api.GetPrice(index, TPriceType.pt_HLC3)
        const hlc3plus1 = this.api.GetPrice(index + 1, TPriceType.pt_HLC3)

        let sum = 0

        for (let i = 0; i < this.CCIPeriod.value; i++) {
            sum += this.api.GetPrice(index + i, TPriceType.pt_HLC3)
        }

        let ma = sum / this.CCIPeriod.value
        this.MAbuff.setValue(index, ma)

        if (index + this.CCIPeriod.value >= this.api.Bars()) {
            return
        }

        sum = 0
        for (let i = 0; i < this.CCIPeriod.value; i++) {
            const price = this.api.GetPrice(index + i, TPriceType.pt_HLC3)
            const ma_value = this.MAbuff.getValue(index)
            sum += Math.abs(this.api.GetPrice(index + i, TPriceType.pt_HLC3) - this.MAbuff.getValue(index))
        }

        if (sum === 0) {
            const variable = this.CCIBuffer.getValue(index + 1)
            this.CCIBuffer.setValue(index, this.CCIBuffer.getValue(index + 1))
        } else {
            const price = this.api.GetPrice(index, TPriceType.pt_HLC3)
            const ma_value = this.MAbuff.getValue(index)
            const temp =
                (this.api.GetPrice(index, TPriceType.pt_HLC3) - this.MAbuff.getValue(index)) /
                0.015 /
                (sum / this.CCIPeriod.value)

            this.CCIBuffer.setValue(
                index,
                (this.api.GetPrice(index, TPriceType.pt_HLC3) - this.MAbuff.getValue(index)) /
                    0.015 /
                    (sum / this.CCIPeriod.value)
            )
        }
    }
}
