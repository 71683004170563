import { forwardRef, ReactNode } from 'react'

import cx from 'classnames'

import { FlexProps, Flex } from '@fto/ui'

import HelperText, { HelperTextProps } from './components/HelperText'
import Label from './components/Label'

import styles from './index.module.scss'

const getHelperTextVariant = (success?: boolean, error?: boolean): HelperTextProps['variant'] => {
    if (success) {
        return 'success'
    } else if (error) {
        return 'error'
    } else {
        return 'default'
    }
}

export type InputControlProps = {
    id?: string
    variant?: 'column' | 'row' | 'row-reverse'
    label?: ReactNode
    required?: boolean
    helperText?: ReactNode
    success?: boolean
    error?: boolean
    block?: boolean
    isAbsoluteHelperText?: boolean
    className?: string
}

export const InputControl = forwardRef<HTMLDivElement, FlexProps & InputControlProps>(
    (
        {
            variant = 'column',
            id,
            label,
            helperText,
            success,
            error,
            required,
            block,
            children,
            className,
            isAbsoluteHelperText = false,
            ...rest
        },
        ref
    ) => {
        return (
            <Flex
                flexGrow={1}
                flexShrink={1}
                {...rest}
                ref={ref}
                direction={variant}
                gap={4}
                className={cx(styles.container, { [styles.block]: block }, className)}
            >
                {label && (
                    <Label htmlFor={id} required={required}>
                        {label}
                    </Label>
                )}
                <Flex
                    direction='column'
                    gap={isAbsoluteHelperText ? 0 : 4}
                    block
                    className={cx({ [styles.absolute]: isAbsoluteHelperText })}
                >
                    {children}
                    {helperText && (
                        <HelperText isAbsolute={isAbsoluteHelperText} variant={getHelperTextVariant(success, error)}>
                            {helperText}
                        </HelperText>
                    )}
                </Flex>
            </Flex>
        )
    }
)

InputControl.displayName = 'InputControl'

export default InputControl
