import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { useAppDispatch } from '@root/hooks/useStoreHook'
import { LOGIN_GOOGLE } from '@root/store/auth/auth.actions'
import { fireMixpanelEvent } from '@root/utils/api'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const GoogleRedirectPage: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const token = new URLSearchParams(window.location.hash.replace('#', '')).get('id_token')
        if (token) {
            dispatch(LOGIN_GOOGLE({ IdToken: token })).then(() => {
                fireMixpanelEvent('log_in', { login_method: 'Google' })
                navigate(0)
            })
        }
        if (window.location.href.includes('error')) {
            navigate(makeRoute(LOGIN_ROUTE))
        }
    }, [window.location.href])

    return <></>
}
