import { t } from 'i18next'
import { TutorialTask } from '@fto/lib/Education/TutorialTasks/TutorialTask'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { TTutorialTask } from '@fto/lib/Education/Types'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { addModal } from '@fto/ui'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'

export class TutorialTasksProcessor {
    public TutorialTasks: TutorialTask[]
    public isEndCheck = false

    constructor(TutorialTasks: TutorialTask[]) {
        this.TutorialTasks = TutorialTasks
    }

    public process(): void {
        if (this.isEndCheck) {
            return
        }

        this.TutorialTasks.forEach((thisTask) => {
            const result = thisTask.processCriteriaStrategies()
            if (this.isAllTasksPassed()) {
                // show user that all task passed successfully
                addModal(MODAL_NAMES.chart.education.success)
                showSuccessToast({ message: t('tutorial.toasts.allTasksPassed') })
                GlobalTestingManager.TestingManager.stopTesting()
                this.isEndCheck = true
            } else {
                // check if user in timeRange for task completion, if not - show user that he is out of time range and failed the task
                if ((result as [boolean, string])[1] === 'timeRange') {
                    showErrorToast({ message: t('tutorial.toasts.outOfTimeRage') })
                    if (this.isAllTasksFailed()) {
                        addModal(MODAL_NAMES.chart.education.fail, {
                            tasks: this.TutorialTasks.filter((task) => !task.isCheckPassed())
                        })
                        GlobalTestingManager.TestingManager.stopTesting()
                        this.isEndCheck = true
                    }
                }
            }
        })
    }

    private isAllTasksPassed(): boolean {
        return this.TutorialTasks.every((thisTask) => thisTask.isCheckPassed())
    }

    private isAllTasksFailed(): boolean {
        return this.TutorialTasks.every((thisTask) => !thisTask.isCheckPassed())
    }

    public getJSONArray(): TTutorialTask[] {
        const TutorialTasksJSON: TTutorialTask[] = []
        for (const thisTask of this.TutorialTasks) {
            TutorialTasksJSON.push(thisTask.toJSON())
        }

        return TutorialTasksJSON
    }
}
