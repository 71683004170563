import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export class IndexSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(operationType: TTradePositionType): number {
        return this.symbolData.symbolInfo.lot * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(operationType: TTradePositionType, openPrice: number | null): number {
        const symbolContractSize = this.symbolData.symbolInfo.lot
        const leverage = GlobalProjectInfo.ProjectInfo.leverage
        const price = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        if (!leverage || leverage <= 0) {
            throw new StrangeError('Leverage is not set or invalid.')
        }

        return (symbolContractSize * price) / leverage
    }
}
