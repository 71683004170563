import { FC } from 'react'
import { Toggle, Modal, Flex, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getIsMixpanelEventEnabled, $useCrowdin } from '@root/store/appSettings/selectors'
import { SET_CROWDIN, UPDATE_MIXPANEL_EVENTS_ACCESS } from '@root/store/appSettings/slice'

const meta = import.meta.env

import styles from './index.module.scss'

export const AdminSettingsModal: FC = () => {
    const useCrowdin = useAppSelector($useCrowdin)
    const IsMixpanelEventEnabled = useAppSelector($getIsMixpanelEventEnabled)
    const dispatch = useAppDispatch()

    function setShowCrowdin(v: boolean) {
        dispatch(SET_CROWDIN(v))
    }

    function handleToggleChange(v: boolean) {
        setShowCrowdin(v)
    }

    const toggleMixpanelEvents = (value: boolean) => {
        dispatch(UPDATE_MIXPANEL_EVENTS_ACCESS(value))
    }

    return (
        <Modal size={'sm'} name={MODAL_NAMES.dashboard.adminSettings} withCloseIcon>
            <Modal.Header> Admin settings </Modal.Header>
            <Modal.Content className={styles.Content}>
                <Flex direction='column' gap={8}>
                    <Flex gap={20} alignItems={'center'} justifyContent='space-between'>
                        <Typography>Show translation popups</Typography>
                        <Toggle checked={useCrowdin} onChange={handleToggleChange} />
                    </Flex>
                    {meta.VITE_ENV_TYPE === 'staging' && (
                        <Flex gap={20} alignItems={'center'} justifyContent='space-between'>
                            <Typography>Disable MixpanelEvents (Staging Only)</Typography>
                            <Toggle checked={IsMixpanelEventEnabled} onChange={toggleMixpanelEvents} />
                        </Flex>
                    )}
                </Flex>
            </Modal.Content>
        </Modal>
    )
}
