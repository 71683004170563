interface Position {
    x: number
    y: number
}

export const calculatePosition = (
    mouseX: number,
    mouseY: number,
    startPosition: { x: number; y: number },
    defaultElemX: number,
    defaultElemY: number
) => {
    let newX = mouseX - startPosition.x
    let newY = mouseY - startPosition.y

    const isMovingLeft = newX < 0
    const isMovingUp = newY < 0

    if (Math.abs(newX) >= defaultElemX) {
        newX = isMovingLeft ? -defaultElemX : defaultElemX
    }

    if (Math.abs(newY) >= defaultElemY) {
        newY = isMovingUp ? -defaultElemY : defaultElemY
    }

    return { x: newX, y: newY }
}
