import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'

import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class MACD extends IndicatorImplementation {
    FastEMAPeriod: TOptValue_number = new TOptValue_number(5)
    SlowEMAPeriod: TOptValue_number = new TOptValue_number(13)
    SMAPeriod: TOptValue_number = new TOptValue_number(3)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    // Buffers
    FastEMA!: TIndexBuffer
    SlowEMA!: TIndexBuffer
    _MACD!: TIndexBuffer
    SMA!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.SetBackOffsetForCalculation(
            Math.max(this.FastEMAPeriod.value, this.SlowEMAPeriod.value, this.SMAPeriod.value) * 40
        )
        this.api.IndicatorShortName(t('indicators.macd'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.SetEmptyValue(0)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.macd.fields.fastEMAPeriod'), TOptionType.ot_Integer, this.FastEMAPeriod)
        this.api.SetOptionRange(t('indicatorModal.macd.fields.fastEMAPeriod'), 1, INT_MAX)

        this.api.RegOption(t('indicatorModal.macd.fields.slowEMAPeriod'), TOptionType.ot_Integer, this.SlowEMAPeriod)
        this.api.SetOptionRange(t('indicatorModal.macd.fields.slowEMAPeriod'), 1, INT_MAX)

        this.api.RegOption(t('indicatorModal.macd.fields.smaPeriod'), TOptionType.ot_Integer, this.SMAPeriod)
        this.api.SetOptionRange(t('indicatorModal.macd.fields.smaPeriod'), 1, INT_MAX)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.SMA = this.api.CreateIndexBuffer()
        this.FastEMA = this.api.CreateIndexBuffer()
        this.SlowEMA = this.api.CreateIndexBuffer()
        this._MACD = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(2)
        this.api.SetIndexBuffer(0, this._MACD)
        this.api.SetIndexBuffer(1, this.SMA)

        this.api.SetIndexStyle(0, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, '#66CDAA')
        this.api.SetIndexLabel(0, t('indicatorModal.macd.fields.macd'))
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psDot, 1, '#FF0000')
        this.api.SetIndexLabel(1, t('indicatorModal.macd.fields.signalLine'))

        this.api.SetIndexDrawBegin(0, 0)
    }

    Calculate(index: number): void {
        let i = 0
        let k = 0
        let sum = 0
        const currPrice = this.api.GetPrice(index, this.ApplyToPrice.value)

        k = 2 / (this.FastEMAPeriod.value + 1)
        if (index === this.api.Bars() - 1) {
            this.FastEMA.setValue(index, currPrice)
        } else {
            this.FastEMA.setValue(
                index,
                this.FastEMA.getValue(index + 1) + k * (currPrice - this.FastEMA.getValue(index + 1))
            )
        }

        k = 2 / (this.SlowEMAPeriod.value + 1)
        if (index === this.api.Bars() - 1) {
            this.SlowEMA.setValue(index, currPrice)
        } else {
            this.SlowEMA.setValue(
                index,
                this.SlowEMA.getValue(index + 1) + k * (currPrice - this.SlowEMA.getValue(index + 1))
            )
        }
        // calculate MACD
        this._MACD.setValue(index, this.FastEMA.getValue(index) - this.SlowEMA.getValue(index))
        // calculate SMA
        sum = 0
        for (i = index; i < index + this.SMAPeriod.value - 1; i++) {
            sum += this._MACD.getValue(i)
        }
        this.SMA.setValue(index, sum / this.SMAPeriod.value)
    }
}
