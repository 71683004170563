import { CSSProperties, FC } from 'react'

import { ColumnsListType, OrdersListType, TabType } from '@root/pages/ChartPage/components/Terminal/types'
import { UserTerminalSettings } from '@root/utils/localStorage'

import Row from '../Row'

import styles from './index.module.scss'
import { observer } from 'mobx-react-lite'

type RowRendererProps = {
    data: {
        list: OrdersListType
        cellsList: ColumnsListType
        tableSettings: UserTerminalSettings
        activeTab: TabType
        selectedOrders: number[]
    }
    index: number
    style: CSSProperties
}

const RowRenderer: FC<RowRendererProps> = observer(({ data, index, style }) => {
    const { list, tableSettings, cellsList, activeTab, selectedOrders } = data

    const item = list[index]

    return (
        <div className={styles.RowRenderer} style={{ ...style }}>
            <Row
                tableSettings={tableSettings}
                key={item.id}
                cellsList={cellsList}
                posData={item}
                activeTab={activeTab}
                isSelected={selectedOrders.includes(item.id)}
            />
        </div>
    )
})

export default RowRenderer
