type OptionsType = { label: string; value: string }

export type TypeAvailableTypes = 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'range'

export const getOptions = (t: (v: string) => string): OptionsType[] => [
    { label: t('customTimeframes.modal.options.minutes.label'), value: 'minutes' },
    { label: t('customTimeframes.modal.options.hours.label'), value: 'hours' },
    { label: t('customTimeframes.modal.options.days.label'), value: 'days' },
    { label: t('customTimeframes.modal.options.weeks.label'), value: 'weeks' },
    { label: t('customTimeframes.modal.options.months.label'), value: 'months' }
    // { label: t('customTimeframes.modal.options.range.label'), value: 'range' }
]
