import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '../../../../delphi_compatibility/DateUtils'
import CommonConstants from '../../../../ft_types/common/CommonConstants'
import { TChunkStatus, TNoExactMatchBehavior } from '../../chunks/ChunkEnums'
import TChunkInformation from '../../chunks/ChunkInfo'
import { TDataChunk } from '../../chunks/DataChunk/DataChunk'
import TDownloadableChunk from '../../chunks/DownloadableChunk/DownloadableChunk'
import { TListOfChunks } from '../../chunks/ListOfChunks'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import { TDataRecordWithDate } from '../../DataClasses/TDataRecordWithDate'
import TBasicDataArray from '../BasicDataArray'
import { TDataDescriptor } from '../DataDescriptionTypes'

// Generic base class for handling arrays of different data types
export abstract class TBasicChunkedArray<
    T extends TDataRecordWithDate,
    C extends TDataChunk<T>
> extends TBasicDataArray<T> {
    protected fChunks: TListOfChunks<C>
    protected fLastAccChunkIndex: number = CommonConstants.EMPTY_INDEX

    protected get LastAccChunk(): C | null {
        if (this.fLastAccChunkIndex === CommonConstants.EMPTY_INDEX) {
            return null
        }
        return this.fChunks[this.fLastAccChunkIndex]
    }

    constructor(aDataDescriptor: TDataDescriptor) {
        super(aDataDescriptor)
        this.fChunks = new TListOfChunks<C>()
    }

    public get Chunks(): TListOfChunks<C> {
        return this.fChunks
    }

    public GetItemByGlobalIndex(aGlobalIndex: number, downloadChunkIfEmpty = true, allowOutOfBound = false): T | null {
        this.__validateGlobalIndex(aGlobalIndex, allowOutOfBound)
        //TODO: do we really need to fit index here?
        const fittedIndex = this.FitIndex(aGlobalIndex)
        // this creates infinite loop and call stack overflow. This is only relevant in Bars, not here in base class
        // if (fittedIndex === this.LastItemInTestingIndex) {
        //   return this.LastItemInTesting;
        // }

        const chunk = this.GetChunkByGlobalIndex(fittedIndex)
        if (chunk === null) {
            return null
        }

        if (chunk instanceof TDownloadableChunk) {
            return chunk.GetItemByGlobalIndex(fittedIndex, downloadChunkIfEmpty)
        } else {
            return chunk.GetItemByGlobalIndex(fittedIndex)
        }
    }
    private __validateGlobalIndex(globalIndex: number, allowOutOfBound: boolean) {
        if (!allowOutOfBound) {
            if (globalIndex < 0) {
                throw new StrangeError(`GetItemByGlobalIndex - Index (${globalIndex}) is less than 0 in ${this.DName}`)
            }
            if (globalIndex > this.LastPossibleIndexInHistory) {
                throw new StrangeError(
                    `GetItemByGlobalIndex - Index (${globalIndex}) is more than max possible index in ${this.DName}`
                )
            }
        }
    }

    public GetChunkByDate(DateTime: TDateTime, allowGapsBetweenChunks = false): C | null {
        if (this.fChunks.Count === 0) {
            throw new DataNotDownloadedYetError('GetChunkByDate - No chunks available, cannot get chunk by date')
        }
        const chunkIndex = this.GetChunkIndexByDate(DateTime, allowGapsBetweenChunks)
        if (chunkIndex === null || chunkIndex === CommonConstants.EMPTY_INDEX) {
            return null
        }

        return this.fChunks[chunkIndex]
    }

    public GetChunkIndexByDate(dateTime: TDateTime, allowGapsBetweenChunks = false): number {
        let currentChunkIndex: number
        let chunkIndexSearchFrom: number
        let chunkIndexSearchTo: number

        // optimization
        if (
            this.fLastAccChunkIndex !== CommonConstants.EMPTY_INDEX &&
            this.fChunks[this.fLastAccChunkIndex].DateInside(dateTime)
        ) {
            return this.fLastAccChunkIndex
        }

        if (this.fChunks.Count === 0) {
            throw new DataNotDownloadedYetError('GetChunkIndexByDate - No chunks available, cannot get chunk by date')
        }
        if (DateUtils.IsEmpty(dateTime)) {
            throw new StrangeError('Date is empty - GetChunkIndexByDate')
        }

        if (dateTime < this.fChunks[0].FirstDate || dateTime > this.fChunks[this.fChunks.length - 1].LastPossibleDate) {
            //do not throw an error here. We will be here when drawing the grid and this is not an error. console.error('Date is out of range - GetChunkIndexByDate. Date: ' + DateUtils.DF(dateTime));
            return CommonConstants.EMPTY_INDEX
        }

        chunkIndexSearchFrom = 0
        chunkIndexSearchTo = this.fChunks.length - 1

        // check ends
        if (this.DateInChunk(chunkIndexSearchFrom, dateTime)) {
            this.fLastAccChunkIndex = chunkIndexSearchFrom
            return chunkIndexSearchFrom
        }

        if (this.DateInChunk(chunkIndexSearchTo, dateTime)) {
            this.fLastAccChunkIndex = chunkIndexSearchTo
            return chunkIndexSearchTo
        }

        // main cycle
        while (chunkIndexSearchFrom <= chunkIndexSearchTo) {
            currentChunkIndex = chunkIndexSearchFrom + Math.floor((chunkIndexSearchTo - chunkIndexSearchFrom) / 2)
            if (this.DateInChunk(currentChunkIndex, dateTime)) {
                this.fLastAccChunkIndex = currentChunkIndex
                return currentChunkIndex
            }

            if (dateTime <= this.fChunks[currentChunkIndex].FirstDate) {
                if (dateTime === this.fChunks[currentChunkIndex].FirstDate) {
                    this.fLastAccChunkIndex = currentChunkIndex
                    return currentChunkIndex
                }
                chunkIndexSearchTo = currentChunkIndex - 1
            } else {
                chunkIndexSearchFrom = currentChunkIndex + 1
            }
        }

        if (allowGapsBetweenChunks) {
            return CommonConstants.EMPTY_INDEX
        } else {
            throw new StrangeError('Chunk not found, but this should not happen to linked chunks')
        }
    }

    protected DateInChunk(chunkIndex: number, DateTime: TDateTime): boolean {
        if (chunkIndex < 0 || chunkIndex >= this.fChunks.length) {
            throw new RangeError(`DateInChunk Index out of bounds ${chunkIndex}`)
        }
        return this.fChunks[chunkIndex].DateInside(DateTime)
    }

    private IndexInChunk(chunkIndex: number, dataGlobalIndex: number): boolean {
        return this.fChunks[chunkIndex].GlobalIndexInside(dataGlobalIndex)
    }

    public ApproximateDateByGlobalIndex(globalIndex: number): TDateTime {
        if (this.fChunks.Count === 0) {
            throw new StrangeError('No chunks available, cannot approximate date')
        }

        const chunk = this.GetClosestChunk(globalIndex)
        if (!chunk) {
            throw new StrangeError('No chunk available, cannot approximate date')
        }

        return chunk.ApproximateDateByGlobalIndex(globalIndex)
    }

    private GetClosestLoadedChunk(globalIndex: number): C {
        const closestChunkIndex = this.GetClosestChunkIndex(globalIndex)
        const chunkCandidate = this.fChunks[closestChunkIndex]
        if (chunkCandidate.Status === TChunkStatus.cs_Loaded) {
            return chunkCandidate
        } else {
            let searchLeftIndex = closestChunkIndex - 1
            let searchRightIndex = closestChunkIndex + 1
            while (searchLeftIndex >= 0 || searchRightIndex < this.fChunks.Count) {
                if (searchLeftIndex >= 0) {
                    const leftChunk = this.fChunks[searchLeftIndex]
                    if (leftChunk.Status === TChunkStatus.cs_Loaded) {
                        return leftChunk
                    }
                    searchLeftIndex--
                }

                if (searchRightIndex < this.fChunks.Count) {
                    const rightChunk = this.fChunks[searchRightIndex]
                    if (rightChunk.Status === TChunkStatus.cs_Loaded) {
                        return rightChunk
                    }
                    searchRightIndex++
                }
            }
        }
        throw new DataNotDownloadedYetError('No loaded chunks available, cannot approximate date')
    }

    private GetClosestChunk(globalIndex: number): C {
        const closestChunkIndex = this.GetClosestChunkIndex(globalIndex)

        if (closestChunkIndex < 0) {
            return this.fChunks[0]
        } else if (closestChunkIndex > this.fChunks.Count) {
            return this.fChunks[this.fChunks.Count - 1]
        }

        return this.fChunks[closestChunkIndex]
    }

    private GetClosestChunkIndex(position: number): number {
        let index1 = 0
        let index2 = this.fChunks.Count - 1

        // check ends
        if (this.IndexInChunk(index1, position)) {
            return index1
        }

        if (this.IndexInChunk(index2, position)) {
            return index2
        }

        // main cycle
        while (index1 < index2) {
            const index = index1 + Math.floor((index2 - index1) / 2)
            if (this.IndexInChunk(index, position)) {
                return index
            }

            if (position < this.fChunks[index].FirstGlobalIndex) {
                index2 = index
            } else {
                index1 = index + 1
            }
        }

        return index1
    }

    public GetGlobalIndexByDate(
        DateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        approximationAllowed = false
    ): number {
        if (DateUtils.IsEmpty(DateTime)) {
            throw new StrangeError('Date is empty - GetGlobalIndexByDate')
        }
        if (!this.LastItemInTestingIndexAvailable || this.fChunks.Count === 0) {
            throw new DataNotDownloadedYetError('Data is not available yet - GetGlobalIndexByDate')
        }

        const chunk = this.GetChunkByDate(DateTime)
        if (!chunk) {
            //since we have at least one chunk, let's approximate the index
            const firstChunk = this.fChunks[0]
            const lastChunk = this.fChunks[this.fChunks.Count - 1]

            if (approximationAllowed) {
                if (DateTime < firstChunk.FirstDate) {
                    return firstChunk.ApproximateGlobalIndexByDate(DateTime)
                } else {
                    return lastChunk.ApproximateGlobalIndexByDate(DateTime)
                }
            } else {
                throw new DataNotDownloadedYetError(
                    `Data is not available yet for the requested date - GetGlobalIndexByDate. Date: ${DateTime}`
                )
            }
        }

        //we do have a specific chunk, let's get the index from it
        return chunk.GetGlobalIndexByDate(DateTime, approximationAllowed, noExactMatchBehavior)
    }

    public GetGlobalIndexByDateApprox(DateTime: TDateTime): number {
        const chunk = this.GetChunkByDate(DateTime)
        if (!chunk) {
            return -1
        }

        return chunk.GetGlobalIndexByDate(DateTime)
    }

    public GetDateByGlobalIndex(globalIndex: number, approximationAllowed = true): TDateTime {
        if (!this.LastItemInTestingIndexAvailable) {
            //TODO: is this like an equivalent of Seek?
            throw new DataNotDownloadedYetError('GetDateByGlobalIndex - Data is not available yet')
        }

        globalIndex = this.FitIndex(globalIndex)

        const chunk = this.GetChunkByGlobalIndex(globalIndex)
        if (!chunk) {
            if (approximationAllowed) {
                return this.ApproximateDateByGlobalIndex(globalIndex)
            } else {
                throw new StrangeError(
                    'GetDateByGlobalIndex - Chunk is not available and approximation is not allowed, cannot get date by global index'
                )
            }
        }

        return chunk.GetDateByGlobalIndex(globalIndex, approximationAllowed)
    }

    private IsGlobalIndexInChunk(chunkIndex: number, dataGlobalIndex: number): boolean {
        if (chunkIndex < 0 || chunkIndex >= this.fChunks.length) {
            throw new RangeError(`Index out of bounds. GlobalIndexInChunk ${chunkIndex}`)
        }
        return this.fChunks[chunkIndex].GlobalIndexInside(dataGlobalIndex)
    }

    public GetChunkIndexByDataIndex(dataGlobalIndex: number): number {
        let currentChunkIndex: number
        let chunkIndexSearchFrom: number
        let chunkIndexSearchTo: number

        if (this.fChunks.Count === 0) {
            return CommonConstants.EMPTY_INDEX
        }

        if (
            dataGlobalIndex < this.fChunks[0].FirstGlobalIndex ||
            dataGlobalIndex > this.fChunks[this.fChunks.length - 1].LastGlobalIndex
        ) {
            return CommonConstants.EMPTY_INDEX
        }

        // optimization
        if (
            this.fLastAccChunkIndex !== CommonConstants.EMPTY_INDEX &&
            this.fChunks[this.fLastAccChunkIndex].GlobalIndexInside(dataGlobalIndex)
        ) {
            return this.fLastAccChunkIndex
        }

        chunkIndexSearchFrom = 0
        chunkIndexSearchTo = this.fChunks.length - 1

        // check ends
        if (this.IsGlobalIndexInChunk(chunkIndexSearchFrom, dataGlobalIndex)) {
            this.fLastAccChunkIndex = chunkIndexSearchFrom
            return chunkIndexSearchFrom
        }

        if (this.IsGlobalIndexInChunk(chunkIndexSearchTo, dataGlobalIndex)) {
            this.fLastAccChunkIndex = chunkIndexSearchTo
            return chunkIndexSearchTo
        }

        // main cycle
        while (chunkIndexSearchFrom <= chunkIndexSearchTo) {
            currentChunkIndex = chunkIndexSearchFrom + Math.floor((chunkIndexSearchTo - chunkIndexSearchFrom) / 2)
            if (this.IsGlobalIndexInChunk(currentChunkIndex, dataGlobalIndex)) {
                this.fLastAccChunkIndex = currentChunkIndex
                return currentChunkIndex
            }

            if (dataGlobalIndex <= this.fChunks[currentChunkIndex].FirstGlobalIndex) {
                if (dataGlobalIndex === this.fChunks[currentChunkIndex].FirstGlobalIndex) {
                    this.fLastAccChunkIndex = currentChunkIndex
                    return currentChunkIndex
                }
                chunkIndexSearchTo = currentChunkIndex - 1
            } else {
                chunkIndexSearchFrom = currentChunkIndex + 1
            }
        }

        // If no chunk is found, update the last accessed chunk to null
        this.fLastAccChunkIndex = CommonConstants.EMPTY_INDEX
        throw new StrangeError('Chunk not found, but this should not happen to linked chunks. GetChunkIndexByDataIndex')
    }

    public GetChunkInfoByGlobalIndex(globalIndex: number): TChunkInformation | null {
        const chunk = this.GetChunkByGlobalIndex(globalIndex)
        if (!chunk) {
            return null
        }

        return chunk.GetChunkInfo()
    }

    protected GetChunkByGlobalIndex(DataIndex: number): C | null {
        if (!this.fChunks || this.fChunks.Count === 0) {
            return null
        }

        if (
            DataIndex < this.fChunks[0].FirstGlobalIndex ||
            DataIndex > this.fChunks[this.fChunks.Count - 1].LastGlobalIndex
        ) {
            return null
        }

        // optimization
        if (this.LastAccChunk && this.LastAccChunk.GlobalIndexInside(DataIndex)) {
            return this.LastAccChunk
        }

        let index1 = 0
        let index2 = this.fChunks.Count - 1

        // check ends
        if (this.IndexInChunk(index1, DataIndex)) {
            this.fLastAccChunkIndex = index1
            return this.fChunks[index1]
        }

        if (this.IndexInChunk(index2, DataIndex)) {
            this.fLastAccChunkIndex = index2
            return this.fChunks[index2]
        }

        // main cycle
        while (index1 <= index2) {
            const index = index1 + Math.floor((index2 - index1) / 2)
            if (this.IndexInChunk(index, DataIndex)) {
                this.fLastAccChunkIndex = index
                return this.fChunks[index]
            }

            if (DataIndex < this.fChunks[index].FirstGlobalIndex) {
                index2 = index - 1
            } else {
                index1 = index + 1
            }
        }

        // If the data index is not found, update the last accessed chunk to null
        this.fLastAccChunkIndex = CommonConstants.EMPTY_INDEX
        return null
    }

    public ClearDataInChunks(): void {
        for (const chunk of this.fChunks) {
            chunk.ClearDataAndResetStatus()
        }
    }
}
