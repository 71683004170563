import { FC, Dispatch, SetStateAction } from 'react'

import MainBlock from './components/MainBlock'
import SwitchBlock from './components/SwitchBlock'
import PriceToHighBlock from './components/PriceToHighBlock'
import TellUsMoreBlock from './components/TellUsMoreBlock'
import NotSatisfiedBlock from './components/NotSatisfiedBlock'
import { REASONS } from './constants'
import { ReasonDataType } from './types'

type Props = {
    suspendData: ReasonDataType
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
    setStep: (n: number) => void
    subscriptionStatus: 'monthly' | 'annual' | 'lifetime' | 'Unknown'
}

const ReasonDefinition: FC<Props> = ({ suspendData, setSuspendData, setStep, subscriptionStatus }) => {
    const { reason } = suspendData

    const isOtherStep = reason === REASONS.other

    return (
        <>
            {!reason && <MainBlock setSuspendData={setSuspendData} />}
            {reason === REASONS.priceToHigh && (
                <PriceToHighBlock
                    setSuspendData={setSuspendData}
                    period={suspendData.data[REASONS.priceToHigh].period}
                    price={suspendData.data[REASONS.priceToHigh].price}
                    setStep={setStep}
                    subscriptionStatus={subscriptionStatus}
                />
            )}
            {(isOtherStep || reason === REASONS.noNeed) && (
                <TellUsMoreBlock
                    setStep={setStep}
                    setSuspendData={setSuspendData}
                    step={reason}
                    comment={
                        isOtherStep ? suspendData.data[REASONS.other].comment : suspendData.data[REASONS.noNeed].comment
                    }
                />
            )}
            {reason === REASONS.notSatisfied && (
                <NotSatisfiedBlock
                    setStep={setStep}
                    setSuspendData={setSuspendData}
                    reason={suspendData.data[REASONS.notSatisfied].reason}
                    comment={suspendData.data[REASONS.notSatisfied].comment}
                />
            )}
            {reason === REASONS.switch && (
                <SwitchBlock
                    setStep={setStep}
                    productName={suspendData.data[REASONS.switch].productName}
                    setSuspendData={setSuspendData}
                />
            )}
        </>
    )
}

export default ReasonDefinition
