import { FC, useCallback, useEffect, useState } from 'react'

import { FilenameTypes } from '@fto/icons'
import { Tooltip } from '@fto/ui'
import ToolInfoStore from '@fto/lib/store/tools'

import { OptionType, TooltipTrigger } from '../../types'
import { DropdownIcon } from '../DropdownIcon'
import { DropdownMenu } from '../DropdownMenu'

import styles from '../../../../common.module.scss'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

type Props = {
    options: { option: OptionType; action: () => void }[]
    header: string
    isCursorDropdown?: boolean
}

const CustomActionsDropdown: FC<Props> = observer(({ options, header, isCursorDropdown }) => {
    const { t } = useTranslation()

    const { info } = ToolInfoStore

    const [activeOption, setActiveOption] = useState<OptionType>(options[0].option)
    const [tooltipTrigger, setTooltipTrigger] = useState<TooltipTrigger>({
        isOpen: undefined,
        closedFromMenu: false
    })

    const action = useCallback(
        (option: { name: string; icon: (typeof FilenameTypes)[number] | null; toolKey: string }) => {
            options.find((customOption) => customOption.option === option)?.action()
            setActiveOption(option)
            setTooltipTrigger({ isOpen: false, closedFromMenu: true })
        },
        [options]
    )

    const resetTooltipState = useCallback(() => {
        setTooltipTrigger({ isOpen: undefined, closedFromMenu: false })
    }, [])

    useEffect(() => {
        if (tooltipTrigger.isOpen === false && tooltipTrigger.closedFromMenu) {
            resetTooltipState()
        }
    }, [tooltipTrigger])

    useEffect(() => {
        if (info.isCrosshairMode && isCursorDropdown) {
            setActiveOption(options[1].option)
        } else if (!info.isCrosshairMode && isCursorDropdown) {
            setActiveOption(options[0].option)
        }
    }, [info.isCrosshairMode, isCursorDropdown])

    return (
        <Tooltip
            content={
                <DropdownMenu
                    name={t(`graphicTools.groupNames.${header}`)}
                    options={options.map((option) => {
                        return option.option
                    })}
                    action={action}
                    activeToolName={''}
                />
            }
            interactive
            arrow={false}
            placement='right-start'
            classNames={{ tooltip: styles.dropdownTooltip }}
            color='light'
            open={tooltipTrigger.isOpen}
        >
            <DropdownIcon
                onClick={() => action(activeOption)}
                icon={activeOption.icon!}
                active={isCursorDropdown}
                resetTooltipState={resetTooltipState}
            />
        </Tooltip>
    )
})

export default CustomActionsDropdown
