import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Command } from './Command'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import ChartSettingsStore from '@fto/lib/store/chartSettings'

class UndoCommand extends Command {
    public execute(): void {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (activeChart) {
            activeChart.undoLastChange()
            ChartSettingsStore.checkUndoRedoAvailability()
            activeChart.Repaint()
        } else {
            throw new StrangeError('No active chart')
        }
    }
}

export { UndoCommand }
