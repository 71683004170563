import { FC, useState, CSSProperties, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import dayjs from 'dayjs'

import { Icon, IconButton } from '@fto/icons'
import { Flex, ModalTabs, TabValue, InputField, Typography, EmptyState } from '@fto/ui'

import { News } from '@fto/lib/News/News'
import { TABS } from '@fto/chart_components/ProjectInterface/components/News/components/List/constants/tabs'

import NewsStore from '@fto/lib/store/news'

import NewItem from './components/NewItem'

import styles from './index.module.scss'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

type RowRendererProps = {
    data: {
        list: News[]
        isUpcoming: boolean
        lastBarTime: number | null
    }
    index: number
    style: CSSProperties
}

const RowRenderer: FC<RowRendererProps> = ({ data, index, style }) => {
    const { list, isUpcoming, lastBarTime } = data

    const item = list[index]

    return (
        <div className={styles.Content} style={{ ...style, padding: '0 16px' }}>
            <NewItem
                isUpcoming={isUpcoming}
                actual={item.Actual}
                country={item.Country}
                previews={item.Previews}
                consensus={item.Consensus}
                priority={item.Priority}
                title={item.Title}
                time={GlobalTimezoneDSTController.Instance.convertFromInnerLibUnixSecondsByTimezoneAndDst(item.Time)}
                jumpTo={() => NewsStore.jumpToNew(item)}
                lastBarTime={lastBarTime}
            />
        </div>
    )
}

const List: FC = observer(() => {
    const [activeTab, setActiveTab] = useState<TabValue>(TABS[0].value)
    const [searchValue, setSearchValue] = useState('')

    const { news, lastBarTime } = NewsStore

    const isUpcomingTab = useMemo(() => activeTab === 'upcoming', [activeTab])

    const newsList = useMemo(() => {
        const list = isUpcomingTab ? news.upcoming : news.previous

        return list.filter((item) => item.Title.toLowerCase().includes(searchValue.toLowerCase()))
    }, [isUpcomingTab, news, searchValue])

    const areNewsAvailable = useMemo(() => {
        if (lastBarTime) {
            const timeUTC = dayjs.utc(+new Date(lastBarTime))
            const year = timeUTC.year()

            return year >= 2007
        }

        return false
    }, [lastBarTime])

    return (
        <Flex direction='column' gap={8} className={styles.root}>
            <div className={styles.Wrapper}>
                <InputField
                    value={searchValue}
                    onChange={setSearchValue}
                    block
                    prefix={<Icon name='search' size={13} />}
                    suffix={
                        searchValue ? <IconButton name='close' size={10} onClick={() => setSearchValue('')} /> : null
                    }
                    placeholder={t('general.search')}
                />
            </div>

            <div className={styles.Wrapper}>
                <ModalTabs tabsList={TABS} handleTabChange={setActiveTab} activeTab={activeTab} />
            </div>

            <Flex direction='column' gap={8} className={styles.List}>
                {newsList.length > 0 ? (
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <FixedSizeList
                                    className={styles.Content}
                                    height={height}
                                    width={width}
                                    itemSize={isUpcomingTab ? 112 : 92}
                                    itemCount={newsList.length}
                                    itemData={{
                                        list: newsList,
                                        isUpcoming: isUpcomingTab,
                                        lastBarTime: lastBarTime
                                    }}
                                >
                                    {RowRenderer}
                                </FixedSizeList>
                            )
                        }}
                    </AutoSizer>
                ) : (
                    <EmptyState>
                        <Typography type={'text-regular'} color='gray-600'>
                            {t(areNewsAvailable ? 'news.list.noNewsFound' : 'news.list.newsStartFrom2007')}
                        </Typography>
                    </EmptyState>
                )}
            </Flex>
        </Flex>
    )
})

export default List
