import { UserTerminalSettings } from '@root/utils/localStorage'
import {
    ColumnsListType,
    OrdersListType,
    TerminalSortingCellSettings,
    TerminalTableColumnSettings
} from '@root/pages/ChartPage/components/Terminal/types'
import {
    HISTORY_CELLS_LIST,
    OPEN_POSITION_CELLS_LIST,
    PENDING_ORDERS_CELLS_LIST,
    TerminalColumnsNames
} from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'
import { OrderDataType } from '@fto/lib/store/ordersStore/types'
import orderBy from 'lodash/orderBy'

export const TerminalSortingFunction: (
    orders: OrdersListType,
    sortingCell: TerminalSortingCellSettings
) => OrdersListType = (orders, sortingCell) => {
    if (sortingCell?.direction === 'unactive') return orders
    const key = sortingCell?.key as keyof OrderDataType
    const direction = sortingCell?.direction === 'asc' ? 'asc' : 'desc'

    return orderBy(orders, [key], [direction])
}

export const defaultTableColumnsSettings: () => UserTerminalSettings = () => {
    function reduceTables(columnList: ColumnsListType) {
        return columnList.reduce((acc: TerminalTableColumnSettings, item) => {
            acc[item.value] = {
                width: null,
                isHidden: false
            }
            return acc
        }, {})
    }

    return {
        history: {
            settings: reduceTables(HISTORY_CELLS_LIST),
            sorting: {
                direction: 'desc',
                key: 'closeTime'
            }
        },
        open: {
            settings: reduceTables(OPEN_POSITION_CELLS_LIST),
            sorting: {
                direction: 'asc',
                key: 'openTime'
            }
        },
        pending: {
            settings: reduceTables(PENDING_ORDERS_CELLS_LIST),
            sorting: {
                direction: 'asc',
                key: 'openTime'
            }
        }
    }
}
