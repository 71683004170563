import { FC } from 'react'
import { t } from 'i18next'

import { Flex, Typography, TriggerOverlay, Button, HoverElement } from '@fto/ui'
import { Icon } from '@fto/icons'

import Filter from './components/Filter'

import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

import styles from './index.module.scss'

type Props = {
    onClose: () => void
}

const Header: FC<Props> = ({ onClose }) => {
    const { userId } = useAppSelector($getUser)

    return (
        <Flex alignItems='center' gap={8} justifyContent='space-between' className={styles.Header}>
            <Typography type='h4-semibold' color='gray-1000' block>
                {t('news.header.title')}
            </Typography>
            <Flex alignItems='center'>
                <TriggerOverlay optionsRenderer={<Filter userId={userId} />}>
                    <HoverElement size={32}>
                        <Icon name='filter' size={18} color='var(--color-gray-1000)' />
                    </HoverElement>
                </TriggerOverlay>

                <Button
                    onClick={onClose}
                    type='tetriatry-white'
                    size='compact'
                    disableFixedSize
                    label={<Icon name='close' color={'var(--color-gray-1000)'} className={styles.icon} size={16} />}
                />
            </Flex>
        </Flex>
    )
}

export default Header
