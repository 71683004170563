import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, addModal, useModalState } from '@fto/ui'

import { Icon } from '@fto/icons'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { USER_PROFILE_ROUTE, makeRoute } from '@root/constants/routes'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $isRegularUser } from '@root/store/auth/selectors'

import { AppHeader } from '../../components/AppHeader'
import { RenewSubscriptionModal } from '../../components/Modals/RenewSubscriptionModal'
import { AccountInfo } from './components/AccountInfo'
import { ChangeEmailModal } from './components/ChangeEmailModal'
import { ChangePasswordModal } from './components/ChangePasswordModal'
import { SuspendSubscriptionModal } from './components/SuspendSubscriptionModal'
import { SubscriptionInfoActive } from './components/components/SubscriptionInfoActive'
import { SubscriptionInfoSuspended } from './components/components/SubscriptionInfoSuspended'
import { fetchActiveSubscriptionInfo, fireMixpanelEvent } from '@root/utils/api'
import { useTranslation } from 'react-i18next'
import commonStyles from './profile.module.scss'

type ChangeParams = {
    code: string
    type: string | null
}

type SubscriptionPeriodType = 'monthly' | 'annual' | 'lifetime' | 'Unknown'

const UserProfile: FC = () => {
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionPeriodType>('Unknown')
    const [chargeDate, setChargeDate] = useState<Date | null>(null)
    const { t } = useTranslation()
    const userInfo = useAppSelector($getUser)

    const { open: isSuspendModalOpen } = useModalState(MODAL_NAMES.userProfile.suspendSubscription)

    if (!userInfo) return <></>

    useEffect(() => {
        fetchActiveSubscriptionInfo().then((res) => {
            setSubscriptionStatus(res.data.PeriodType as SubscriptionPeriodType)
            setChargeDate(res.data.NextCharge.Date ? new Date(res.data.NextCharge.Date) : null)
        })
    }, [])

    const [changeDataParams, setChangeDataParams] = useState<ChangeParams>({
        code: '',
        type: null
    })

    const navigate = useNavigate()

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'profile' })

        const params = new URLSearchParams(window.location.search)

        const actionType = params.get('action')

        if (actionType) {
            setChangeDataParams({
                type: actionType,
                code: params.get('code') || ''
            })

            if (actionType === 'changeemail') {
                addModal(MODAL_NAMES.userProfile.changeEmail, params.get('code') || '')
            }
            if (actionType === 'changepassword') {
                addModal(MODAL_NAMES.userProfile.changePassword, params.get('code') || '')
            }

            navigate(makeRoute(USER_PROFILE_ROUTE))
        }
    }, [])

    return (
        <Flex direction='column' className={commonStyles.container}>
            <AppHeader />
            <Flex justifyContent='center' className={commonStyles.profileBody}>
                <Flex direction='column' gap={16} className={commonStyles.content}>
                    <Button
                        type='tetriatry-gray'
                        label={t('global.back')}
                        before={<Icon name='arrow-long-left' />}
                        onClick={() => navigate(-1)}
                    />
                    <Flex direction='column' gap={16}>
                        <AccountInfo
                            name={userInfo.displayName}
                            email={userInfo.email}
                            customAuth={userInfo.customAuth}
                        />
                        {userInfo.subscriptionStatus === 'Active' && (
                            <SubscriptionInfoActive chargeDate={chargeDate} subscriptionStatus={subscriptionStatus} />
                        )}
                        {userInfo.subscriptionStatus === 'Canceled' && (
                            <SubscriptionInfoSuspended chargeDate={chargeDate} />
                        )}
                    </Flex>
                </Flex>
            </Flex>
            <ChangeEmailModal code={changeDataParams.code} />
            <ChangePasswordModal code={changeDataParams.code} />
            <RenewSubscriptionModal subscriptionId={userInfo.subscriptionId} />
            {isSuspendModalOpen && (
                <SuspendSubscriptionModal
                    subscriptionId={userInfo.subscriptionId}
                    chargeDate={chargeDate}
                    subscriptionStatus={subscriptionStatus}
                />
            )}
        </Flex>
    )
}
export default UserProfile
