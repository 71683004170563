type OptionType = {
    [id: string]: {
        value: any
        key: string
    }
}
export const collectGraphToolValues = (options: OptionType): { [id: string]: any } => {
    const values = Object.values(options)

    return values.reduce((result, option) => {
        return { ...result, [option.key]: option.value }
    }, {})
}
