import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { Command } from './Command'

class BarForwardCommand extends Command {
    public execute(): void {
        GlobalTestingManager.TestingManager.StepForwardBy1Bar()
    }
}

export { BarForwardCommand }
