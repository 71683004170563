export const checkIsThereScrollLeft = (element: HTMLDivElement | null) => {
    if (!element) return false
    return element.scrollLeft > 0
}

export const checkIsThereScrollRight = (element: HTMLDivElement | null) => {
    if (!element) return false

    return Math.ceil(element.scrollLeft + element.offsetWidth) < element.scrollWidth
}
