import { MarketValues } from '../../OrderWndStructs'
import { PriceValidationStrategy } from '../PriceValidationStrategy'
import { ValidationErrorType } from '../ValidationErrorType'

export class TakeprofitValidationMarketBuy extends PriceValidationStrategy {
    private m_pMarketValues: MarketValues

    constructor(marketValues: MarketValues) {
        super()
        this.m_pMarketValues = marketValues
    }

    public isValid(value: string): boolean {
        let result = false

        if (super.isValid(value)) {
            const valueNum = parseFloat(value)

            if (valueNum <= this.m_pMarketValues.ask) {
                const errorText = 'takeprofitPriceMustBeHigherForMarketBuy'
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText
                }
            } else if (
                valueNum <
                this.m_pMarketValues.ask +
                    this.m_pMarketValues.minimumDistanceToPrice * this.m_pMarketValues.minimumPriceChange
            ) {
                const errorText = 'takeprofitPriceMustBeHigherForMarketBuyMinimumBy'
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText,
                    errorValue: this.m_pMarketValues.getMinimumDistanceToPriceStr()
                }
            } else {
                result = true
            }
        }

        return result
    }
}
