interface TCurrInfo {
    name: string
    bid: number
    ask: number
}

export default class FixedDataValues {
    //FIXME: change this, we need real data, not dummy values. Maybe we should get the data from server instead of using this dummy data
    public static readonly CurrDataCount = 15

    public static readonly CurrData: TCurrInfo[] = [
        { name: 'USDCHF', bid: 1.2201, ask: 1.2205 },
        { name: 'USDJPY', bid: 92.82, ask: 92.85 },
        { name: 'USDCAD', bid: 1.2705, ask: 1.2709 },
        { name: 'AUDUSD', bid: 0.6461, ask: 0.6466 },
        { name: 'GBPUSD', bid: 1.4685, ask: 1.4688 },
        { name: 'EURUSD', bid: 1.2718, ask: 1.272 },
        { name: 'EURAUD', bid: 1.9677, ask: 1.9691 },
        { name: 'NZDUSD', bid: 0.5328, ask: 0.5338 },
        { name: 'EURGBP', bid: 0.8659, ask: 0.8664 },
        { name: 'EURCHF', bid: 1.5528, ask: 1.5531 },
        { name: 'EURJPY', bid: 118.16, ask: 118.2 },
        { name: 'GBPJPY', bid: 136.48, ask: 136.6 },
        { name: 'GBPCHF', bid: 1.7914, ask: 1.7926 },
        { name: 'BTCUSD', bid: 21976.5, ask: 21976.51 },
        { name: 'ETHUSD', bid: 1642.78, ask: 1642.79 }
    ]
}
