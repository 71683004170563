import { TNews } from '@fto/lib/News/Types'
import { NewsGroup } from '@fto/lib/News/NewsGroup'
import { News, NewsPriority } from '@fto/lib/News/News'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { EURO_ZONE_COUNTRIES, EURO_ZONE_ENTITY } from '@root/constants/countries'
import { throttle } from 'lodash'
import NewsStore from '@fto/lib/store/news'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import StrangeError from '../common/common_errors/StrangeError'
import { DebugUtils } from '../utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import { TDataArrayEvents } from '../ft_types/data/data_downloading/DownloadRelatedEnums'

export class GlobalNewsController {
    private newsGroups: NewsGroup[] = []
    private static instance: GlobalNewsController
    private infoAboutShownNews: {
        news: News[][]
        horizontalMagnifier: number
    } | null = null
    private bottomTopCoordinatesOfNews: { bottom: number; top: number } | null = null
    private filters: {
        importance: string[] | null
        countries: string[] | null
        dateRange: string | null
    } = {
        importance: [NewsPriority.High],
        countries: null,
        dateRange: 'month'
    }
    private newsWithFilters: News[] = []
    private originalNews: News[] = []
    private allVisibleFiltered = false // Flag to avoid redundant filtering
    private _throttledUpdateNewsTab = throttle(() => {
        NewsStore.updateNews({
            news: this.getNewsGroupByPreviousUpcoming(),
            lastBarTime: DateUtils.toUnixTimeMilliseconds(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTimeWithTimezoneAndDST()
            )
        })
    }, 500)

    constructor() {
        GlobalSymbolList.SymbolList.Events.on(TDataArrayEvents.de_SeekCompleted, this.boundHandleGlobalSeekCompleted)
    }

    private boundHandleGlobalSeekCompleted = this.handleGlobalSeekCompleted.bind(this)
    private handleGlobalSeekCompleted(): void {
        this.filterNews(true)
    }

    private _throttledFilterNews = throttle((isNeedRepaint: boolean) => {
        this.filterNews(isNeedRepaint)
    }, 500)

    public static get Instance(): GlobalNewsController {
        if (!GlobalNewsController.instance) {
            GlobalNewsController.instance = new GlobalNewsController()
        }

        return GlobalNewsController.instance
    }

    public parseNewsGroup(news: TNews): void {
        for (const newsItem of news) {
            const newsGroup = this.getOrCreateNewsGroup(newsItem.Symbol)
            newsGroup.addNews(newsItem)
        }
        this.originalNews = this.newsGroups.flatMap((group) => group.getNews())

        this.originalNews = this.removeDuplicates(this.originalNews)

        this.originalNews.sort((a, b) => a.Time - b.Time)

        this.newsWithFilters = this.originalNews
        this.allVisibleFiltered = false
        this.throttledFilterNews(true)
    }

    private removeDuplicates(news: News[]): News[] {
        const seen = new Set()
        return news.filter((newsItem) => {
            const key = `${newsItem.Time}-${newsItem.Country}-${newsItem.Priority}-${newsItem.Previews}-${newsItem.Consensus}` // Adjust key as needed
            if (seen.has(key)) {
                return false
            }
            seen.add(key)
            return true
        })
    }

    private getOrCreateNewsGroup(currency: string): NewsGroup {
        let newsGroup = this.newsGroups.find((group) => group.currency === currency)

        if (!newsGroup) {
            newsGroup = new NewsGroup(currency)
            this.newsGroups.push(newsGroup)
        }

        return newsGroup
    }

    public getNewsGroupByCurrency(currency: string): NewsGroup | null {
        return this.newsGroups.find((group) => group.currency === currency) || null
    }

    public getNewsGroups(): NewsGroup[] {
        return this.newsGroups
    }

    public getAllNewsByDateRange(start: number, end: number): News[] {
        if (this.isNotFilters()) {
            return this.newsGroups.flatMap((group) => group.getNewsByDateRange(start, end))
        }

        const startDateInSeconds = DateUtils.toUnixTimeSeconds(start)
        const endDateInSeconds = DateUtils.toUnixTimeSeconds(end)

        return this.binarySearchByDateRange(this.newsWithFilters, startDateInSeconds, endDateInSeconds)
    }

    set InfoAboutShownNews(info: { news: News[][]; horizontalMagnifier: number }) {
        this.infoAboutShownNews = info
    }

    public get InfoAboutShownNews(): { news: News[][]; horizontalMagnifier: number } | null {
        return this.infoAboutShownNews
    }

    public SetBottomTopCoordinatesOfNews(coordinates: { bottom: number; top: number }): void {
        this.bottomTopCoordinatesOfNews = coordinates
    }

    public checkIfMouseOverNews(x: number, y: number): boolean {
        if (!this.bottomTopCoordinatesOfNews) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Loading,
                'bottomTopCoordinatesOfNews is not initialized yet, this is ok only if the chart is not initialized yet'
            )
            //we have not finished initialization yet, so this is ok
            return false
        }
        return y > this.bottomTopCoordinatesOfNews.top && y < this.bottomTopCoordinatesOfNews.bottom
    }

    public setFilterByImportance(importance: string[] | null): void {
        if (importance?.length === 3) {
            // importance.length === 3 it means all importance are selected so we can remove filter
            importance = null
            this.filters.importance = importance
            return
        }

        this.filters.importance = importance
            ?.map((item) => {
                switch (item) {
                    case 'low': {
                        return NewsPriority.Low
                    }
                    case 'medium': {
                        return NewsPriority.Medium
                    }
                    case 'high': {
                        return NewsPriority.High
                    }
                    default: {
                        throw new StrangeError('Invalid importance in setFilterByImportance')
                    }
                }
            })
            .filter((item) => item !== undefined) as string[] | null
    }

    public setFilterByCountries(countries: string[] | null): void {
        let copiedCountries: string[] | null = countries ? [...countries] : null
        if (copiedCountries?.length === 0) {
            copiedCountries = []
        }

        if (copiedCountries && this.checkIfItEuroZone(copiedCountries)) {
            copiedCountries.push(EURO_ZONE_ENTITY.name)
        }

        this.filters.countries = copiedCountries?.flat() || null
    }

    public setFilterByInterval(dateRange: string | null): void {
        this.filters.dateRange = dateRange === 'all_visible' ? null : dateRange
        this.allVisibleFiltered = false
    }

    public filterNews(isNeedRepaint = true): void {
        if (
            !this.filters ||
            this.allVisibleFiltered ||
            !GlobalProjectInfo.IsProjectInfoInitialized ||
            !GlobalSymbolList.SymbolList.IsSeeked
        ) {
            return
        }

        const lastTickTimeInSeconds = DateUtils.toUnixTimeSeconds(
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
        )
        let startDateInSeconds = 0
        let endDateInSeconds = 0

        switch (this.filters.dateRange) {
            case 'week': {
                startDateInSeconds = lastTickTimeInSeconds - 7 * 24 * 60 * 60
                endDateInSeconds = lastTickTimeInSeconds + 7 * 24 * 60 * 60
                break
            }
            case 'two_weeks': {
                startDateInSeconds = lastTickTimeInSeconds - 14 * 24 * 60 * 60
                endDateInSeconds = lastTickTimeInSeconds + 14 * 24 * 60 * 60
                break
            }
            case 'month': {
                startDateInSeconds = lastTickTimeInSeconds - 30 * 24 * 60 * 60
                endDateInSeconds = lastTickTimeInSeconds + 30 * 24 * 60 * 60
                break
            }

            //TODO: refactor this to include default case
            // default: {
            //     throw new StrangeError('Invalid date range in filterNews')
            // }
        }

        let filteredAndUniqueNews: News[] = []
        let newsInDateRange: News[] = []

        if (!this.filters.dateRange || this.filters.dateRange === 'all_visible') {
            newsInDateRange = [...this.originalNews]
            this.allVisibleFiltered = true
        } else {
            newsInDateRange = this.binarySearchByDateRange(this.originalNews, startDateInSeconds, endDateInSeconds)
        }

        filteredAndUniqueNews = newsInDateRange.filter((news: News) => {
            const countryValid = this.filters.countries?.includes(news.Country)

            const importanceValid = !this.filters.importance || this.filters.importance.includes(news.Priority)

            return countryValid && importanceValid
        })

        this.newsWithFilters = filteredAndUniqueNews

        if (isNeedRepaint) {
            GlobalChartsController.Instance.updateCharts()
        }
    }

    private checkIfItEuroZone(countries: string[]): boolean {
        return EURO_ZONE_COUNTRIES.every((item) => countries.includes(item.name))
    }

    public isNotFilters(): boolean {
        return !this.filters.importance && !this.filters.countries && !this.filters.dateRange
    }

    public binarySearchByDateRange(news: News[], startDate: number, endDate: number): News[] {
        const copiedNews = [...news]

        const binarySearch = (arr: News[], target: number, compare: (a: number, b: number) => boolean): number => {
            let low = 0,
                high = arr.length - 1
            while (low <= high) {
                const mid = Math.floor((low + high) / 2)
                if (compare(arr[mid].Time, target)) {
                    low = mid + 1
                } else {
                    high = mid - 1
                }
            }
            return low
        }

        const startIndex = binarySearch(copiedNews, startDate, (a, b) => a < b)
        const endIndex = binarySearch(copiedNews, endDate, (a, b) => a <= b)

        return copiedNews.slice(startIndex, endIndex)
    }

    public getFirstUpcomingNews(): News | null {
        const { previous, upcoming } = this.getNewsGroupByPreviousUpcoming()
        return upcoming[0] || null
    }

    public getNewsGroupByPreviousUpcoming(): { previous: News[]; upcoming: News[] } {
        let news

        if (this.allVisibleFiltered) {
            news = this.getAllNewsVisibleOnCharts()
        } else {
            news = this.newsWithFilters
        }

        const lastTickTimeInSeconds = DateUtils.toUnixTimeSeconds(
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
        )

        const result = news.reduce(
            (acc: { previous: News[]; upcoming: News[] }, item: News) => {
                if (item.Time <= lastTickTimeInSeconds) {
                    acc.previous.push(item)
                } else {
                    acc.upcoming.push(item)
                }
                return acc
            },
            { previous: [], upcoming: [] }
        )

        result.previous.sort((a, b) => b.Time - a.Time)
        result.upcoming.sort((a, b) => a.Time - b.Time)

        return result
    }

    private getAllNewsVisibleOnCharts(): News[] {
        const seen = new Set<string>()
        const result: News[] = []

        const allCharts = GlobalChartsController.Instance.getAllCharts()

        const allShownNews = new Set<News>()
        for (const chart of allCharts) {
            for (const news of chart.MainChart.shownNews) {
                allShownNews.add(news)
            }
        }

        for (const news of allShownNews) {
            const key = `${news.Time}-${news.Country}-${news.Priority}-${news.Previews}-${news.Consensus}`
            if (!seen.has(key)) {
                seen.add(key)
                result.push(news)
            }
        }

        return result
    }

    public throttledUpdateNewsTab(): void {
        if (NewsStore.isNewsTabOpen) {
            this._throttledUpdateNewsTab()
        }
    }

    public throttledFilterNews(forced: boolean = false, isNeedRepaint: boolean = true) {
        if (forced) {
            this.filterNews()

            return
        }

        this._throttledFilterNews(isNeedRepaint)
    }

    public jumpToNewsItem(entity: News): void {
        const activeChart = GlobalChartsController.Instance.getActiveChart()

        if (activeChart) {
            activeChart.MainChart.getNewsUnderMouse(new TPoint(entity.coordinates.centerX, entity.coordinates.centerY))
            activeChart.scrollToNewsItem(entity.Time)
            entity.inFocusedState = true
            entity.isSearched = true
        }
    }

    public resetFilters(): void {
        this.filters = {
            importance: [NewsPriority.High],
            countries: null,
            dateRange: 'month'
        }
        this.newsWithFilters = this.originalNews
        this.allVisibleFiltered = false
        this.throttledFilterNews(true)
    }
}
