import { FC, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button, Flex, Tooltip, addModal, Separator, HoverElement } from '@fto/ui'
import { Icon, IconButton } from '@fto/icons'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import TimeframeStore from '@fto/lib/store/timeframe'
// import Notifications from '@root/components/AppHeader/components/Notifications'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { showErrorToast } from '@root/utils/toasts'
import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import educationStore from '@fto/lib/store/educationStore'

import ChartModeDropdown from './components/Dropdowns/ChartModeDropdown'
import IndicatorDropdown from './components/Dropdowns/components/IndicatorDropdown/IndicatorDropdown'
import ProjectOptionsDropdown from './components/Dropdowns/ProjectOptionsDropdown'
import TasksButton from './components/TasksButton'
import SaveProjectButton from './components/SaveProjectButton'
import TimeFramesBlock from './components/TimeFramesBlock'
import ShareProjectButton from './components/ShareProjectButton'
import Slider from './components/Slider'
import LayoutDropdown from './components/Dropdowns/LayoutDropdown'
import HelpDropdown from './components/Dropdowns/HelpDropdown'
import ProfileDropdown from './components/Dropdowns/ProfileDropdown'
import JumpToDropdown from './components/Dropdowns/JumpToDropdown'

import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'
import TAB_NAMES from '@fto/chart_components/ProjectInterface/constants/tabsNames'

import styles from './index.module.scss'

type RibbonProps = {
    setTabsSettings: (value: TabsSettingsType) => void
    selectedChartInfo: ChartInfoType
    setSelectedChartInfo: (value: ChartInfoType) => void
}

const WithSeparator: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Flex alignItems='center'>
            {children}
            <Separator margin='4px' height='24px' color='gray-500' />
        </Flex>
    )
}

const Ribbon: FC<RibbonProps> = observer(({ setTabsSettings, selectedChartInfo, setSelectedChartInfo }) => {
    const { t } = useTranslation()

    const { timeframe, preselectedTimeFrames, customTimeframes } = TimeframeStore
    const { data: educationData } = educationStore
    const {
        data: { activeChartSymbol }
    } = globalChartsStore
    const navigate = useNavigate()

    const redirectToDashboard = useCallback(() => {
        navigate(makeRoute(DASHBOARD_ROUTE))
    }, [])

    const handleJumpTo = useCallback(() => {
        addModal(MODAL_NAMES.chart.jumpToDate)
    }, [])

    const handleAutoScale = useCallback(() => {
        const chart = GlobalChartsController.Instance.getActiveChart()
        if (!chart) return
        chart.AutoScaleOn()
    }, [])

    const openKeyboardShortcutsTab = useCallback(() => {
        setTabsSettings({ isOpen: true, tabName: TAB_NAMES.KEYBOARD_SHORTCUTS })
    }, [setTabsSettings])

    return (
        <Flex className={styles.root}>
            <WithSeparator>
                <Tooltip content={t('ribbon.tooltips.fto')}>
                    <IconButton
                        className={styles.logoButton}
                        size={28}
                        name='logo-small'
                        onClick={redirectToDashboard}
                    />
                </Tooltip>
            </WithSeparator>
            <Slider dependencyListToCheckScroll={[customTimeframes]}>
                <Flex gap={4} className={styles.ribbon} justifyContent='space-between' flexGrow={1} block>
                    <Flex alignItems='center'>
                        <WithSeparator>
                            <ProjectOptionsDropdown />
                        </WithSeparator>
                        <WithSeparator>
                            <ChartModeDropdown />
                        </WithSeparator>
                        <WithSeparator>
                            <TimeFramesBlock
                                activeChartSymbol={activeChartSymbol}
                                timeframe={timeframe}
                                preselectedTimeFrames={preselectedTimeFrames}
                                customTimeframes={customTimeframes}
                            />
                        </WithSeparator>
                        <WithSeparator>
                            <IndicatorDropdown />
                        </WithSeparator>
                        {!educationData.isShown && (
                            <WithSeparator>
                                <JumpToDropdown />
                            </WithSeparator>
                        )}
                        <Flex gap={8}>
                            <LayoutDropdown
                                selectedChartInfo={selectedChartInfo}
                                setSelectedChartInfo={setSelectedChartInfo}
                            />
                            <Button
                                withTooltip
                                tooltipText={t('ribbon.tooltips.newOrder')}
                                classNames={{ content: cx(styles.orderButton, styles.smallButton) }}
                                before={<Icon color='var(--color-white)' name='menu-new-order' size={18} />}
                                onClick={() => addModal(MODAL_NAMES.chart.orderModal, { source: 'ribbon' })}
                                label={t('ribbon.newOrder')}
                                size='compact'
                            />
                        </Flex>
                    </Flex>
                    <Flex alignItems='center'>
                        <TasksButton />
                        <Button
                            before={<Icon name='contact' size={18} color='var(--color-white)' />}
                            data-featurebase-feedback-portal='true'
                            classNames={{ content: cx(styles.opinionButton, styles.ShareOpinion) }}
                            label={t('ribbon.shareOpinion')}
                            size='compact'
                            type='gold'
                        ></Button>
                        <WithSeparator>
                            <Tooltip content={t('ribbon.tooltips.autoScale')}>
                                <HoverElement onClick={handleAutoScale} size={32}>
                                    <Icon size={18} name='autoscale' />
                                </HoverElement>
                            </Tooltip>
                        </WithSeparator>
                        <WithSeparator>
                            <SaveProjectButton />
                        </WithSeparator>
                        <WithSeparator>
                            <ShareProjectButton />
                        </WithSeparator>
                        <WithSeparator>
                            <Tooltip content={t('ribbon.tooltips.chartSettings')}>
                                <HoverElement
                                    size={32}
                                    onClick={() => setTabsSettings({ isOpen: true, tabName: TAB_NAMES.SETTINGS })}
                                >
                                    <Icon className={styles.iconButton} size={20} name='settings' />
                                </HoverElement>
                            </Tooltip>
                        </WithSeparator>
                        {/* <WithSeparator>
                    <TriggerOverlay optionsRenderer={<Notifications />} outStyledList>
                        <CounterBadge
                            classNames={{ root: styles.iconButton }}
                            value={1}
                            size={16}
                            backgroundColor='red-500'
                            color='white'
                        >
                            <Icon size={24} name='bell' color='var(--color-gray-1000)' />
                        </CounterBadge>
                    </TriggerOverlay>
                </WithSeparator> */}
                        <WithSeparator>
                            <HelpDropdown openKeyboardShortcutsTab={openKeyboardShortcutsTab} />
                        </WithSeparator>
                        <Flex alignItems='center'>
                            <ProfileDropdown />
                        </Flex>
                        {/* <Button
                    disabled
                    classNames={{ root: styles.upgradeButtonRoot, content: styles.upgradeButton }}
                    before={<Icon name='lightning' color='var(--color-orange-400)' />}
                    label={t('ribbon.upgrade')}
                    size='compact'
                /> */}
                    </Flex>
                </Flex>
            </Slider>
        </Flex>
    )
})

export default Ribbon
