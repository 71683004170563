export class TFormatSettings {
    DecimalSeparator: string
    ThousandSeparator: string
    DateSeparator: string
    TimeSeparator: string
    ShortDateFormat: string
    LongDateFormat: string
    ShortTimeFormat: string

    constructor() {
        // Initialize with default values
        this.DecimalSeparator = '.'
        this.ThousandSeparator = ','
        this.DateSeparator = '.'
        this.TimeSeparator = ':'
        this.ShortDateFormat = 'yyyy.mm.dd'
        this.LongDateFormat = 'yyyy.mm.dd HH:nn'
        this.ShortTimeFormat = 'HH:nn'
    }

    // Methods to format numbers, dates, etc., can be added as needed
}
