import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@root/store'

export const projectsStore = (state: RootState) => state.projects

export const $getProjects = (state: RootState) => projectsStore(state).projects?.Data || null
export const $getProject = (state: RootState, id: string) =>
    projectsStore(state).projects?.Data.find((project) => project.Id === id)
export const $getTotalProjects = (state: RootState) => projectsStore(state).projects?.Total || 0
export const $getProjectQueryParams = (state: RootState) => projectsStore(state).query
export const $getProjectsStatus = (state: RootState) => projectsStore(state).loading
export const $getProjectsError = (state: RootState) => projectsStore(state).error
