import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import { TDataDescriptor } from '../data_arrays/DataDescriptionTypes'
import TChunkInformation from './ChunkInfo'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import { INamed } from '@fto/lib/utils/INamed'
import { TChunkStatus } from './ChunkEnums'

export abstract class TBasicChunk implements INamed {
    protected _dataDescriptor: TDataDescriptor
    protected _firstDate: TDateTime

    public Events: TEventsFunctionality = new TEventsFunctionality('TBasicChunk')

    //for debugging purposes only
    private _name = ''
    public get DName(): string {
        return this._name
    }

    public SetName(name: string): void {
        this._name = name
        this.Events.SetName(name)
    }

    public toString(): string {
        return this.DName
    }

    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: TDateTime) {
        this._dataDescriptor = aDataDescriptor
        this._firstDate = aFirstDate
    }

    public abstract get LastPossibleDate(): TDateTime
    public abstract get FirstGlobalIndex(): number
    public abstract get Count(): number

    public GetChunkInfo(): TChunkInformation {
        return {
            FirstDate: this.FirstDate,
            LastDate: this.LastPossibleDate,
            DataDescriptor: this.DataDescriptor,
            Count: this.Count,
            FirstIndex: this.FirstGlobalIndex
        }
    }

    public isEqual(otherChunk: TBasicChunk): boolean {
        return (
            CommonDataUtils.isDataDescriptorEqual(this.DataDescriptor, otherChunk.DataDescriptor) &&
            DateUtils.AreEqual(this._firstDate, otherChunk._firstDate)
        )
    }

    public get DataDescriptor(): TDataDescriptor {
        return this._dataDescriptor
    }

    public get FirstDate(): TDateTime {
        return this._firstDate
    }

    public CorrectFirstDate(newFirstDate: TDateTime): void {
        this._firstDate = Math.max(this._firstDate, newFirstDate)
    }

    public get LastGlobalIndex(): number {
        return this.GetLastGlobalIndexInThisChunk()
    }

    public GetLastGlobalIndexInThisChunk(): number {
        return this.FirstGlobalIndex + this.Count - 1
    }

    public IsVisibleInRange(globalIndexStart: number, globalIndexEnd: number): boolean {
        return !(globalIndexEnd < this.FirstGlobalIndex || globalIndexStart > this.LastGlobalIndex)
    }

    public DateInside(DateTime: TDateTime): boolean {
        return DateUtils.MoreOrEqual(DateTime, this.FirstDate) && DateTime < this.LastPossibleDate
    }

    public GlobalIndexInside(globalIndex: number): boolean {
        return globalIndex - this.FirstGlobalIndex >= 0 && globalIndex - this.FirstGlobalIndex < this.Count
    }

    public abstract GetDateByGlobalIndex(globalIndex: number): TDateTime
}
