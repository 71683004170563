import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Flex, Link, Typography, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { SUPPORT_LINK } from '@root/constants/urls'

import styles from '../../profile.module.scss'

type Props = {
    subscriptionStatus: string
    chargeDate: Date | null
}

export const SubscriptionInfoActive: FC<Props> = ({ subscriptionStatus, chargeDate }) => {
    const { trialPeriodEnd, isTrialExpired, AccountType } = useAppSelector($getUser)
    const { t } = useTranslation()

    const date = useMemo(() => {
        return dayjs(isTrialExpired ? chargeDate : trialPeriodEnd, 'MM/DD/YYYY h:mm A')
            .locale('en')
            .format('MMMM DD, YYYY')
    }, [trialPeriodEnd, chargeDate, isTrialExpired])

    const activeLifetime = useMemo(
        () => subscriptionStatus === 'lifetime' && isTrialExpired,
        [subscriptionStatus, isTrialExpired]
    )

    if (subscriptionStatus === 'Unknown' || AccountType === 'BetaTester') return null

    return (
        <Flex direction='column' className={styles.subscriptionInfo}>
            <Flex justifyContent='space-between' className={styles.infoContent}>
                <Flex alignItems='flex-start' direction='column' gap={4} block>
                    <Typography type='h4-bold'>
                        {isTrialExpired ? t(`profile.subscriptionPlans.${subscriptionStatus}`) : t('profile.trial')}
                    </Typography>
                    <Flex alignItems='center' justifyContent='space-between' block>
                        {activeLifetime ? (
                            <Flex gap={12} direction='column'>
                                <Typography type='interface-regular'>
                                    {t('profile.subscriptionInfoLifetime1')}
                                </Typography>
                                <Typography type='interface-regular'>
                                    {t('profile.subscriptionInfoLifetime2')}
                                </Typography>
                                <Typography type='interface-regular'>
                                    <Trans
                                        i18nKey='profile.subscriptionInfoLifetime3'
                                        components={{
                                            supportEmailLink: (
                                                <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                                            )
                                        }}
                                    />
                                </Typography>
                            </Flex>
                        ) : (
                            <span>
                                {chargeDate !== null && (
                                    <Typography type='interface-regular'>
                                        <Trans
                                            i18nKey='profile.subscriptionInfoTrial'
                                            components={{
                                                date: <Typography type='interface-regular'>{date}</Typography>
                                            }}
                                        />
                                    </Typography>
                                )}
                            </span>
                        )}
                        {!activeLifetime && (
                            <Button
                                classNames={{ root: styles.infoButton }}
                                label={isTrialExpired ? t('profile.cancelSubscription') : t('profile.suspend')}
                                type='tetriatry-gray'
                                size='compact'
                                onClick={() => {
                                    addModal(MODAL_NAMES.userProfile.suspendSubscription)
                                }}
                            />
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
