export const COLORS = [
    ['#FFFFFF', '#F23645', '#FCCBCD', '#FAA1A4', '#F77C80', '#F7525F', '#B22833', '#801922'],
    ['#D1D4DC', '#FF9800', '#FFE0B2', '#FFCC80', '#FFB74D', '#FFA726', '#F57C00', '#E65100'],
    ['#B2B5BE', '#FFEB3B', '#FFF9C4', '#FFF59D', '#FFF176', '#FFEE58', '#FBC02D', '#F57F17'],
    ['#9598A1', '#4CAF50', '#C8E6C9', '#A5D6A7', '#81C784', '#66BB6A', '#388E3C', '#1B5E20'],
    ['#787B86', '#089981', '#ACE5DC', '#70CCBD', '#42BDA8', '#22AB94', '#056656', '#00332A'],
    ['#5D606B', '#00BCD4', '#B2EBF2', '#80DEEA', '#4DD0E1', '#26C6DA', '#0097A7', '#006064'],
    ['#434651', '#2962FF', '#BBD9FB', '#90BFF9', '#5B9CF6', '#3179F5', '#1848CC', '#0C3299'],
    ['#2A2E39', '#673AB7', '#D1C4E9', '#AF9ED7', '#9575CD', '#7E57C2', '#512DA8', '#311B92'],
    ['#131722', '#9C27B0', '#E1BEE7', '#CE93D8', '#BA68C8', '#AB47BC', '#7B1FA2', '#4A148C'],
    ['#000000', '#E91E63', '#F8BBD0', '#F48FB1', '#F06292', '#EC407A', '#C2185B', '#880E4F']
]
