import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TPenStyle, GetPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { TLineStyle } from '../VCLCanvas/TLineStyle'
import { LevelActiveType } from './LevelActiveType'

export class TLevelData {
    public value!: number
    public text!: string
    public style!: TLineStyle
    public pen: IGPPen
    public brush: IGPSolidBrush
    public id: string | number = TLevelData.getNextId()
    public isActive: LevelActiveType = 1 // 1 - drawing, 0 - not drawing
    private static currentId = 0

    constructor(s: string, _style?: TLineStyle, opacity = 1) {
        if (_style) {
            this.style = _style
        } else {
            this.style = new TLineStyle('#000000', TPenStyle.psSolid, 1)
            this.SetLevelFromStr(s)
        }
        this.pen = new IGPPen(this.style.color, this.style.width, opacity)
        this.pen.setPenStyle(this.style.style)
        this.brush = new IGPSolidBrush(this.style.color)
    }

    clone(): TLevelData {
        const styleClone = this.style.clone()
        const levelDataClone = new TLevelData(this.text, styleClone)
        levelDataClone.value = this.value
        levelDataClone.text = this.text
        levelDataClone.id = this.id
        levelDataClone.isActive = this.isActive
        return levelDataClone
    }

    private static getNextId(): number {
        return this.currentId++
    }

    // New static methods for TLevelData creation
    public static Create_TLevelData(
        aValue: number,
        s: string,
        _style: TPenStyle,
        width: number,
        clr: TColor
    ): TLevelData {
        const res = new TLevelData(s)
        res.value = aValue
        res.text = s
        res.style = new TLineStyle(clr, _style, width) // Assuming TLineStyle has these properties
        return res
    }

    public static Create_TLevelData_Style(
        aValue: number,
        s: string,
        _style: TLineStyle,
        isActive = LevelActiveType.active,
        opacity = 1
    ): TLevelData {
        const res = new TLevelData(s, _style, opacity)
        res.value = aValue
        res.text = s
        res.isActive = isActive
        return res
    }

    GetLevelStr(precision: number): string {
        // Check if the necessary functions and properties exist
        if (this.value === undefined) {
            throw new StrangeError('Value is undefined')
        }

        if (!this.text) {
            throw new StrangeError('Text is not defined or empty')
        }

        if (!this.style) {
            throw new StrangeError('Style is not defined')
        }

        if (this.style?.color === undefined) {
            throw new TypeError('Style color is undefined')
        }

        if (this.style?.style === undefined) {
            throw new TypeError('Style style is undefined')
        }

        if (this.style?.width === undefined) {
            throw new TypeError('Style width is undefined')
        }

        const rgbColor = StrsConv.StrColor(this.style.color)

        // Return the formatted string using template literals for better readability
        return `${StrsConv.StrDouble(this.value, precision)} ${StrsConv.QuoteStr(this.text)} ${rgbColor} ${this.style.style} ${this.style.width} ${this.id} ${this.isActive}`
    }

    // Improved the implementation by removing the need for a 'remainingStr' variable.
    // Instead, we directly pass the updated string to the next parsing function.
    // This makes the code cleaner and easier to follow.
    SetLevelFromStr(s: string): void {
        let remainingStr: string
        ;[this.value, remainingStr] = StrsConv.GetStrDouble(s, ' ')

        // Extract the quoted text.
        let textStr: string
        ;[textStr, remainingStr] = StrsConv.GetSubStr(remainingStr, ' ')
        this.text = StrsConv.GetQuotedStr(textStr, '"', '"')
        ;[this.style.color, remainingStr] = StrsConv.GetStrColor(remainingStr, ' ')

        // Extract the pen style as an integer and cast it to TPenStyle.
        // Update the string for the next parsing step.
        let styleNumber: number
        ;[styleNumber, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        this.style.style = GetPenStyle(styleNumber)
        ;[this.style.width, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        ;[this.id, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        ;[this.isActive, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
    }
}
