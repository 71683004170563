export type TemplateMap<T> = Map<string, Map<string, T>>

export class Template {
    public id: string | null = null

    constructor(
        public templateName: string,
        public templateJSON: string,
        templateId?: string
    ) {
        if (templateId) {
            this.id = templateId
        }
    }
}

export class ToolTemplate extends Template {}

export class IndicatorTemplate extends Template {}

export class ChartTemplate extends Template {}

export type ChartTemplateServerResponse = {
    id: string
    name: string
    canvas: string
    views: string
    other: string
}

export type ChartTemplateReadyToServer = {
    name: string
    canvas: string
    views: string
    general: string
}

type TemplateItem = {
    id: string
    templateName: string
}

type IndicatorToolTemplate = {
    id: string
    name: string
    template: string
}

export type AllTemplates = {
    defaultTemplates: {
        chart: TemplateItem
        indicators: {
            [key: string]: TemplateItem
        }
        tools: {
            [key: string]: TemplateItem
        }
    }
    charts: ChartTemplateServerResponse[]
    indicators: {
        [key: string]: IndicatorToolTemplate[]
    }
    tools: {
        [key: string]: IndicatorToolTemplate[]
    }
}
