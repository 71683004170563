import { StrategyImplementation } from '@fto/lib/extension_modules/startegies/api/UserStrategy'
import { TInstantOrderType, EOrderSelectMode } from '@fto/lib/extension_modules/startegies/api/StrategyInterfaceUnit'
import { IndicatorReference } from '../../common/IndicatorReference'
import { BuiltInIndicatorFiles } from '../../indicators/built_in_indicators/list/BuiltInIndicatorsList'
import { TTradePositionType } from '../../common/CommonExternalInterface'
import { TOptionType, TOptValue_number, TOptValue_str } from '../../common/CommonTypes'

export class TestStrategy_MACross extends StrategyImplementation {
    private symbol: TOptValue_str = new TOptValue_str('EURUSD')
    private timeframe: TOptValue_number = new TOptValue_number(15)
    private lotSize: TOptValue_number = new TOptValue_number(0.1)
    private fastMA: TOptValue_number = new TOptValue_number(7)
    private slowMA: TOptValue_number = new TOptValue_number(25)
    // private maMethod: TOptValue_MA_METHOD = new TOptValue_MA_METHOD('EMA')
    // private priceMethod: TOptValue_PRICE_METHOD = new TOptValue_PRICE_METHOD('Close')
    private magicNumber = 55555

    private fastMAIndicator: IndicatorReference = new IndicatorReference('Empty')
    private slowMAIndicator: IndicatorReference = new IndicatorReference('Empty')

    public Init(): void {
        this.api.StrategyShortName('MovingAverageCrossoverStrategy')
        this.api.StrategyDescription('Moving Average Crossover Strategy')
        this.api.RegOption('Symbol', TOptionType.ot_Currency, this.symbol)
        this.api.RegOption('Timeframe', TOptionType.ot_Timeframe, this.timeframe)
        this.api.RegOption('Lot size', TOptionType.ot_double, this.lotSize)
        this.api.RegOption('Fast MA', TOptionType.ot_Integer, this.fastMA)
        this.api.RegOption('Slow MA', TOptionType.ot_Integer, this.slowMA)
        // this.api.RegMATypeOption(this.maMethod, 'MA Method')
        // this.api.RegApplyToPriceOption(this.priceMethod, 'Price Method')

        this.fastMAIndicator = this.api.CreateIndicator(
            this.symbol.value,
            this.timeframe.value,
            BuiltInIndicatorFiles.MovingAverage,
            `${this.fastMA.value};0;0;Simple (SMA);Close`
            // `${this.fastMA.value};0;${this.maMethod.value};${this.priceMethod.value}`
        )

        this.slowMAIndicator = this.api.CreateIndicator(
            this.symbol.value,
            this.timeframe.value,
            BuiltInIndicatorFiles.MovingAverage,
            `${this.slowMA.value};0;0;Simple (SMA);Close`
            // `${this.slowMA.value};0;${this.maMethod.value};${this.priceMethod.value}`
        )
    }

    public OnTick(): void {
        // if (!this.checkIfOpenOrdersByMagicNumber(this.magicNumber)) {
        if (this.shouldSell()) {
            this.closeBuyOrders()
            this.api.SendInstantOrder(
                this.symbol.value,
                TInstantOrderType.op_Sell,
                this.lotSize.value,
                0,
                0,
                '',
                this.magicNumber
            )
        }

        if (this.shouldBuy()) {
            this.closeSellOrders()
            this.api.SendInstantOrder(
                this.symbol.value,
                TInstantOrderType.op_Buy,
                this.lotSize.value,
                0,
                0,
                '',
                this.magicNumber
            )
        }
        // }
    }

    // private checkIfOpenOrdersByMagicNumber(magicNumber: number): boolean {
    //     return false
    //     const totalOrders = this.api.OrdersTotal()
    //     for (let i = 0; i < totalOrders; i++) {
    //         if (this.api.OrderSelect(i) && this.api.OrderMagicNumber() === magicNumber) {
    //             return true
    //         }
    //     }
    //     return false
    // }

    private shouldBuy(): boolean {
        const fastMAPrev = this.api.GetIndicatorValue(this.fastMAIndicator, 1, 0)
        const slowMAPrev = this.api.GetIndicatorValue(this.slowMAIndicator, 1, 0)
        const fastMACurr = this.api.GetIndicatorValue(this.fastMAIndicator, 0, 0)
        const slowMACurr = this.api.GetIndicatorValue(this.slowMAIndicator, 0, 0)

        return slowMAPrev > fastMAPrev && fastMACurr > slowMACurr && Math.abs(fastMACurr - slowMACurr) > 0.0000001
    }

    private shouldSell(): boolean {
        const fastMAPrev = this.api.GetIndicatorValue(this.fastMAIndicator, 1, 0)
        const slowMAPrev = this.api.GetIndicatorValue(this.slowMAIndicator, 1, 0)
        const fastMACurr = this.api.GetIndicatorValue(this.fastMAIndicator, 0, 0)
        const slowMACurr = this.api.GetIndicatorValue(this.slowMAIndicator, 0, 0)

        return slowMAPrev < fastMAPrev && slowMACurr > fastMACurr && Math.abs(slowMACurr - fastMACurr) > 0.0000001
    }

    private closeSellOrders() {
        const totalOrders = this.api.OrdersTotal()
        for (let i = totalOrders - 1; i >= 0; i--) {
            if (
                this.api.OrderSelect(i, EOrderSelectMode.osm_SELECT_BY_POS) &&
                this.api.OrderSymbol() === this.symbol.value &&
                this.api.OrderMagicNumber() === this.magicNumber &&
                this.api.OrderType() === TTradePositionType.tp_Sell
            ) {
                this.api.CloseOrder(this.api.OrderTicket())
            }
        }
    }

    private closeBuyOrders() {
        const totalOrders = this.api.OrdersTotal()
        for (let i = totalOrders - 1; i >= 0; i--) {
            if (
                this.api.OrderSelect(i, EOrderSelectMode.osm_SELECT_BY_POS) &&
                this.api.OrderSymbol() === this.symbol.value &&
                this.api.OrderMagicNumber() === this.magicNumber &&
                this.api.OrderType() === TTradePositionType.tp_Buy
            ) {
                this.api.CloseOrder(this.api.OrderTicket())
            }
        }
    }
}
