import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TTickRec } from './TickRec'

export abstract class TTicksSource {
    fQueuedTick: TTickRec
    StartDateTime!: TDateTime

    abstract GetQueuedTick(): TTickRec
    // abstract ExtractNewTick(): TTickRec
    abstract Seek(DateTime: TDateTime): void
    abstract RollToDate(DateTime: TDateTime): void

    constructor() {
        this.fQueuedTick = new TTickRec()
    }

    ClearQueuedTick(): void {
        this.fQueuedTick = new TTickRec()
    }
}
