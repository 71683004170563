import IEventEmitter from '../common/IEventEmitter'
import TEventsFunctionality from '../utils/EventsFunctionality'
import { INamed } from '../utils/INamed'

export class TPaintBox implements INamed, IEventEmitter {
    private name: string
    public align: string
    public Height: number
    public heightInPercent: number | null

    //will be overridden in Charts
    public get DName(): string {
        return this.name
    }

    public Events: TEventsFunctionality = new TEventsFunctionality('TPaintBox')

    protected SetName(value: string): void {
        this.name = value
        this.Events.SetName(value)
    }

    public toString(): string {
        return this.DName
    }

    constructor() {
        this.name = ''
        this.align = ''
        this.Height = 0
        this.heightInPercent = null
    }
}
