import { TOffsStringList } from '../../ft_types/common/OffsStringList'
import { TMyObjectList } from '../../ft_types/common/Common'
import { StrsConv } from './StrsConv'
import GlobalTimeframes from '../../globals/GlobalTimeframes'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TTemplate extends TOffsStringList {
    public timeframe: number

    constructor(aTimeFrame: number) {
        super()
        this.timeframe = aTimeFrame
    }

    public static CreateFromList(list: TOffsStringList): TTemplate {
        const result = new TTemplate(0)
        result.Assign(list)

        let s: string = result.GetString()
        var unusedString: string
        var remainingString: string
        ;[unusedString, remainingString] = StrsConv.GetSubStr(s, '=')
        ;[result.timeframe, remainingString] = StrsConv.GetStrInt(remainingString)

        return result
    }

    public Empty(): boolean {
        return this.Count === 0
    }

    public ClearAll(): void {
        this.Clear()
    }
}

export class TTemplatesList extends TMyObjectList<TTemplate> {
    constructor() {
        super()
        for (let i = 0; i < GlobalTimeframes.Timeframes.Count; i++) {
            this.Add(new TTemplate(GlobalTimeframes.Timeframes.GetTimeframeByIndex(i)))
        }
    }

    public ClearAllExceptOneMinute(): void {
        for (let i = this.Count - 1; i >= 1; i--) {
            this.Delete(i)
        }
    }

    public GetTemplateCopy(timeframe: number): TTemplate {
        const template = this.GetTemplate(timeframe)
        if (!template) {
            throw new StrangeError('Template with specified timeframe not found.')
        }
        const result = new TTemplate(timeframe)
        result.Assign(template)
        return result
    }

    public GetTemplate(timeframe: number): TTemplate {
        for (let i = 0; i < this.count; i++) {
            const item = this[i]
            if (item.timeframe === timeframe) {
                return item
            }
        }
        const result = new TTemplate(timeframe)
        this.Add(result)
        return result
    }
}
