import { toast, ToastOptions, ToastPosition } from 'react-toastify'

import { Icon } from '@fto/icons'
import { Toast, OwnToastProps } from '@fto/ui'

const DEFAULT_CONFIG = {
    position: 'bottom-left' as ToastPosition,
    autoClose: 5000,
    hideProgressBar: true,
    closeButton: false,
    draggable: false,
    closeOnClick: false
}

type UtilToastConfig = Pick<OwnToastProps, 'message' | 'title' | 'actions' | 'icon' | 'showCloseIcon'> &
    Omit<ToastOptions, 'icon'>
export function showSuccessToast({
    title = 'Success',
    message,
    actions,
    icon = <Icon name='check-rounded' size={24} />,
    showCloseIcon,
    ...options
}: UtilToastConfig) {
    return toast(
        <Toast
            color='green'
            title={title}
            message={message}
            actions={actions}
            icon={icon}
            showCloseIcon={showCloseIcon}
        />,
        {
            ...DEFAULT_CONFIG,
            ...options
        }
    )
}

export function showWarningToast({
    title = 'Warning',
    message,
    actions,
    icon = <Icon name='error' size={24} color='var(--color-orange-500)' />,
    showCloseIcon,
    ...options
}: UtilToastConfig) {
    return toast(
        <Toast
            color='orange'
            title={title}
            message={message}
            actions={actions}
            icon={icon}
            showCloseIcon={showCloseIcon}
        />,
        {
            ...DEFAULT_CONFIG,
            ...options
        }
    )
}

export function showErrorToast({
    title = 'Error',
    message,
    actions,
    icon = <Icon name='error' size={24} color='var(--color-red-500)' />,
    showCloseIcon,
    ...options
}: UtilToastConfig) {
    return toast(
        <Toast
            color='red'
            title={title}
            message={message}
            actions={actions}
            icon={icon}
            showCloseIcon={showCloseIcon}
        />,
        {
            ...DEFAULT_CONFIG,
            ...options
        }
    )
}
