import { FC, useCallback, useEffect, ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import dayjs from 'dayjs'

import { Flex, Tooltip, Typography, addModal, Separator, HoverElement } from '@fto/ui'

import { Icon } from '@fto/icons'

import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'

import { MODAL_NAMES } from '@root/constants/modalNames'

import Slider from './components/Slider'
import useDraggablePanel from '@root/hooks/useDraggablePanel'
import ChartSettingsStore from '@fto/lib/store/chartSettings'

import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'
import { observer } from 'mobx-react-lite'
import { fireMixpanelEvent } from '@root/utils/api'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import styles from './index.module.scss'

const TooltipWithHotkeyContent: FC<{ hotkey: string | ReactNode; content: string }> = ({ hotkey, content }) => {
    return (
        <Flex gap={4}>
            <Typography color='white'>{content}</Typography>
            {typeof hotkey === 'string' ? (
                <Typography type='subtext-regular' color='white' className={styles.hotkey}>
                    {hotkey}
                </Typography>
            ) : (
                hotkey
            )}
        </Flex>
    )
}

const TestingPanel: FC = observer(() => {
    const { t } = useTranslation()

    const { settings, setSettings, updateChartSettings } = ChartSettingsStore
    const modalRef = useRef(null)
    const { onMouseDown, onMouseMove, onMouseUp, position, isDragging } = useDraggablePanel(modalRef)

    const [backTestingTimeStarted, setBackTestingTimeStarted] = useState<Date | null>(null)
    const [showAnimation, setShowAnimation] = useState(true)

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mouseup', onMouseUp)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
                window.removeEventListener('mouseup', onMouseUp)
            }
        }
    }, [isDragging, onMouseMove, onMouseUp])

    const handlePlay = useCallback(() => {
        const endTime = dayjs(new Date())
        const startTime = dayjs(backTestingTimeStarted || 0)

        fireMixpanelEvent(settings.isPlaying ? 'backtesting_stopped' : 'backtesting_started', {
            backtesting_speed: settings.TestingSpeed,
            ...(settings.isPlaying && {
                time_backtested: Math.abs(endTime.diff(startTime, 'second')),
                bars_backtested: GlobalChartsController.Instance.onTestingStop()?.barsTested || 0
            })
        })
        setSettings((settings_internalVar) => ({
            ...settings_internalVar,
            isPlaying: !settings_internalVar.isPlaying,
            TestingSpeed: GlobalProjectJSONAdapter.Instance.TestingsSettings.SliderPosition
        }))

        setShowAnimation(false)
        setBackTestingTimeStarted(settings.isPlaying ? null : new Date())
        updateChartSettings()
    }, [settings.TestingSpeed, settings.isPlaying])

    const handleBarBack = useCallback(() => {
        GlobalTestingManager.TestingManager.StepBackBy1Bar()
    }, [])

    // const handleTick = useCallback(() => {
    //     GlobalTestingManager.TestingManager.StepForwardBy1Tick()
    // }, [])

    const handleBarForward = useCallback(() => {
        GlobalTestingManager.TestingManager.StepForwardBy1Bar()
    }, [])

    const handleNewOrder = useCallback(() => {
        addModal(MODAL_NAMES.chart.orderModal, { source: 'back_testing_panel' })
    }, [])

    return (
        <Flex className={cx(SELECTORS.TESTING_PANEL.className, styles.wrapper)}>
            <Flex
                className={styles.panel}
                ref={modalRef}
                style={{
                    left: position.x,
                    top: position.y
                }}
                gap={4}
                alignItems='center'
            >
                <div className={styles.drag} onMouseDown={onMouseDown}>
                    <Icon name='drag-n-drop' color='var(--color-gray-600)' size={12} />
                </div>
                <Tooltip
                    placement='top'
                    content={<TooltipWithHotkeyContent content={t('testingPanel.startPause')} hotkey='Enter' />}
                >
                    <HoverElement
                        onClick={handlePlay}
                        classNames={{
                            root: cx(settings.isPlaying ? styles.pauseButton : styles.playButton, {
                                [styles.animation]: showAnimation
                            })
                        }}
                        size={32}
                    >
                        <Icon size={18} color={'var(--color-white)'} name={settings.isPlaying ? 'pause' : 'play'} />
                    </HoverElement>
                </Tooltip>
                <Slider />
                <Separator height='24px' color='gray-500' />
                <Tooltip
                    placement='top'
                    content={
                        <TooltipWithHotkeyContent
                            content={t('testingPanel.barBack')}
                            hotkey={
                                <Flex alignItems='center' gap={4}>
                                    <Typography type='subtext-regular' color='white' className={styles.hotkey}>
                                        Backspace
                                    </Typography>
                                    <Typography color='white'>or</Typography>
                                    <Typography type='subtext-regular' color='white' className={styles.hotkey}>
                                        ←
                                    </Typography>
                                </Flex>
                            }
                        />
                    }
                >
                    <HoverElement
                        disabled={settings.isPlaying}
                        size={32}
                        onClick={handleBarBack}
                        classNames={{ root: styles.Icon }}
                    >
                        <Icon name='barback' size={18} className={styles.Icon} />
                    </HoverElement>
                </Tooltip>
                {/*<Tooltip*/}
                {/*    placement='top'*/}
                {/*    content={<TooltipWithHotkeyContent content={t('testingPanel.tickForward')} hotkey='Ctrl + Space' />}*/}
                {/*>*/}
                {/*    <HoverElement disabled={settings.isPlaying} size={32} onClick={handleTick}>*/}
                {/*        <Icon name='tick' size={18} />*/}
                {/*    </HoverElement>*/}
                {/*</Tooltip>*/}
                <Tooltip
                    placement='top'
                    content={
                        <TooltipWithHotkeyContent
                            content={t('testingPanel.barForward')}
                            hotkey={
                                <Flex alignItems='center' gap={4}>
                                    <Typography type='subtext-regular' color='white' className={styles.hotkey}>
                                        Space
                                    </Typography>
                                    <Typography color='white'>or</Typography>
                                    <Typography type='subtext-regular' color='white' className={styles.hotkey}>
                                        →
                                    </Typography>
                                </Flex>
                            }
                        />
                    }
                >
                    <HoverElement disabled={settings.isPlaying} size={32} onClick={handleBarForward}>
                        <Icon name='barforward' size={18} />
                    </HoverElement>
                </Tooltip>
                <Separator height='24px' color='gray-500' />
                <Tooltip placement='top' content={t('ribbon.newOrder')}>
                    <HoverElement disabled={settings.isPlaying} size={32} onClick={handleNewOrder}>
                        <Icon name='menu-new-order' size={18} />
                    </HoverElement>
                </Tooltip>
            </Flex>
        </Flex>
    )
})

export default TestingPanel
