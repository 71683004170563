import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, EmptyState, Flex, InputField, Separator, Typography } from '@fto/ui'
import watchlistStore from '@fto/lib/store/watchlistStore'
import { usePersistedState } from '@root/hooks/usePersistedState'
import { WATCHLIST_COLUMNS } from '@root/constants/localStorageKeys'
import useLocalStorageKey from '@root/hooks/useLocalStorageKey'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

import { initialCells } from './constants'
import WatchlistTableHead from './components/WatchlistTableHead'
import WatchlistSymbolRow from './components/WatchlistSymbolRow'
import CellsDropdown from './components/CellsDropdown'

import styles from './index.module.scss'

type Props = {
    closeTabs: () => void
}
const WatchlistTab: FC<Props> = observer(({ closeTabs }) => {
    const key = useLocalStorageKey(WATCHLIST_COLUMNS)
    const [cells, setCells] = usePersistedState(key, initialCells)
    const [searchValue, setSearchValue] = useState('')

    const { t } = useTranslation()
    const { lastBarTime, symbols: projectSymbols, updateIsWatchlistOpen, updateSymbols } = watchlistStore

    const filteredSymbols = useMemo(
        () =>
            searchValue !== ''
                ? projectSymbols.filter((symbol) => symbol.toLowerCase().includes(searchValue.toLowerCase()))
                : projectSymbols,
        [searchValue, projectSymbols]
    )

    useEffect(() => {
        updateSymbols(GlobalProjectJSONAdapter.Instance.getSymbols())
    }, [updateSymbols])

    const activeCells = useMemo(() => cells.filter((cell) => cell.active), [cells])

    const handleClose = useCallback(() => {
        updateIsWatchlistOpen(false)
        closeTabs()
    }, [closeTabs])

    return (
        <Flex direction='column' className={styles.container} gap={12}>
            <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                <Typography type='h4-semibold'>{t('watchlist.watchlist')}</Typography>
                <Flex gap={8}>
                    <CellsDropdown cells={cells} setCells={setCells} />
                    <Button
                        onClick={handleClose}
                        type='tetriatry-white'
                        size='compact'
                        disableFixedSize
                        label={<Icon name='close' color={'var(--color-gray-1000)'} className={styles.icon} size={16} />}
                    />
                </Flex>
            </Flex>
            <Separator width='100%' />

            <Flex direction='column' className={styles.body} gap={8}>
                <div className={styles.wrapper}>
                    <InputField
                        id='dd-search-input'
                        value={searchValue}
                        onChange={setSearchValue}
                        reset
                        suffix={
                            <Flex alignItems='center' gap={8}>
                                {searchValue && <Separator width='1px' height='24px' color='gray-500' />}
                                <Icon name='search' size={18} />
                            </Flex>
                        }
                        placeholder={t('general.search')}
                        block
                    />
                </div>
                <WatchlistTableHead cells={activeCells} />
                {filteredSymbols.length > 0 ? (
                    <Flex
                        justifyContent='space-between'
                        direction='column'
                        className={styles.rowsContainer}
                        gap={8}
                        block
                    >
                        {filteredSymbols.map((symbol, index) => (
                            <WatchlistSymbolRow
                                symbol={symbol}
                                lastBar={lastBarTime}
                                key={symbol}
                                cells={activeCells}
                            />
                        ))}
                    </Flex>
                ) : (
                    <EmptyState
                        classNames={{ root: styles.empty }}
                        title={t('watchlist.emptyTitle')}
                        description={t('watchlist.emptyDescription')}
                    />
                )}
            </Flex>
        </Flex>
    )
})

export default WatchlistTab
