import { FC, useMemo } from 'react'
import cx from 'classnames'

import { Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    type?: 'fontStyle' | 'fontWeight'
    isActive?: boolean
    onSelect?: () => void
}
export const FontStylePicker: FC<Props> = ({ type = 'fontStyle', isActive, onSelect }) => {
    const isFontStyleType = useMemo(() => type === 'fontStyle', [type])
    const label = useMemo(() => (isFontStyleType ? 'I' : 'B'), [isFontStyleType])

    return (
        <div
            onClick={onSelect}
            className={cx(styles.FontStylePicker, { [styles.Active]: isActive, [styles.Italic]: isFontStyleType })}
        >
            <Typography
                type='text-bold'
                fontStyle={isFontStyleType ? 'italic' : 'normal'}
                color={isActive ? 'primary-500' : 'gray-1000'}
            >
                {label}
            </Typography>
        </div>
    )
}
