import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'

import { Flex, Modal, removeModal, Typography, Toggle } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'
import CreateProjectModalSymbols from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectModalSymbols/CreateProjectModalSymbols'
import CreateProjectModalForm from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectModalForm'

import ProjectMapper from '@root/mapper/project.mapper'
import useSubmitForm from '@root/hooks/useSubmitForm'
import {
    createProjectFormSchema,
    initCreateProjectFormData
} from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'
import CreateProjectAdvancedForm from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectAdvancedForm/CreateProjectAdvancedForm'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { CREATE_PROJECT_ACTION } from '@root/store/projects/project.actions'
import { $getSymbols } from '@root/store/symbols/symbol.selectors'
import { GET_SYMBOLS_ACTION } from '@root/store/symbols/symbols.actions'
import { ProjectType } from '@root/types/projects'

import Controls from '@root/pages/Dashboard/components/Modals/CreateProjectModal/components/Controls'

import styles from './create-project-modal.module.scss'
import { redirectToNewProject } from './helpers/RedirectToNewProject'
import { useTranslation } from 'react-i18next'

export const CreateProjectModal: FC = () => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const symbols = useAppSelector($getSymbols)

    const [formData, setFormData] = useState(initCreateProjectFormData)

    const onValid = useCallback(async () => {
        const createdProject = ProjectMapper.toCreateDto(formData)
        dispatch(CREATE_PROJECT_ACTION(createdProject)).then((responce) => {
            if (responce.meta.requestStatus === 'rejected') {
                return
            }
            redirectToNewProject((responce.payload as ProjectType).Id)
            closeModal()
        })
    }, [formData])

    const { submit, errors, reset, validate } = useSubmitForm({ schema: createProjectFormSchema, formData, onValid })

    const closeModal = useCallback(() => {
        reset()
        setFormData(initCreateProjectFormData)
        removeModal(MODAL_NAMES.projects.create)
    }, [reset])

    useEffect(() => {
        if (errors.length > 0) validate()
    }, [formData])

    useEffect(() => {
        if (symbols.length === 0) {
            dispatch(GET_SYMBOLS_ACTION())
        }
    }, [])

    return (
        <Modal size='xl' name={MODAL_NAMES.projects.create} onClose={closeModal} withCloseIcon>
            <Modal.Header className={styles.modalHeader}>
                <Typography type='h4-semibold'>{t('dashboard.modals.projects.title')}</Typography>
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <div className={styles.content}>
                    <CreateProjectModalSymbols
                        className={styles.modalBLock}
                        symbols={symbols}
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                    />
                    <div className={styles.modalBLock}>
                        <CreateProjectModalForm formData={formData} setFormData={setFormData} errors={errors} />
                        <CreateProjectAdvancedForm formData={formData} setFormData={setFormData} />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Footer>
                <Flex gap={8} alignItems='center'>
                    <Toggle
                        checked={formData.ForwardMode}
                        onChange={(isChecked) => setFormData((prev) => ({ ...prev, ForwardMode: isChecked }))}
                    />
                    <Typography type='interface-medium'>{t('dashboard.modals.projects.forward')}</Typography>
                </Flex>
            </Modal.Footer>
            <Modal.Controls className={styles.controls}>
                <Controls closeModal={closeModal} submit={submit} errors={errors} />
            </Modal.Controls>
        </Modal>
    )
}
