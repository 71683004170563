export const COUNTRY_FLAG_BY_CURRENCY = {
    ars: 'country-argentina',
    aud: 'country-australia',
    brl: 'country-brazil',
    byn: 'country-belarus',
    cad: 'country-canada',
    chf: 'country-switzerland',
    cnh: 'country-china',
    czk: 'country-czech',
    dkk: 'country-denmark',
    egp: 'country-egypt',
    eur: 'country-europe',
    gbp: 'country-united-kingdom',
    hkd: 'country-hong-kong',
    huf: 'country-hungary',
    ils: 'country-israel',
    inr: 'country-india',
    jpy: 'country-japan',
    krw: 'country-south-korea',
    nok: 'country-norway',
    mxn: 'country-mexico',
    nzd: 'country-new-zealand',
    php: 'country-philippines',
    pln: 'country-poland',
    ron: 'country-romania',
    rub: 'country-russia',
    sek: 'country-sweden',
    sgd: 'country-singapore',
    thb: 'country-thailand',
    thy: 'country-turkey',
    twd: 'country-taiwan',
    usd: 'country-usa',
    zar: 'country-south-africa'
}
