import { TValueType } from '@fto/lib/extension_modules/common/CommonTypes'
import { OffsetLevel } from '@fto/lib/globals/HighestLowestCache/OffsetLevel'

export class ValueTypeLevel {
    private map: Map<TValueType, OffsetLevel>

    constructor() {
        this.map = new Map<TValueType, OffsetLevel>()
    }

    set(valueType: TValueType, offset: number, value: number): void {
        if (!this.map.has(valueType)) {
            this.map.set(valueType, new OffsetLevel())
        }
        this.map.get(valueType)?.set(offset, value)
    }

    get(valueType: TValueType, offset: number): number | undefined {
        return this.map.get(valueType)?.get(offset)
    }

    has(valueType: TValueType): boolean {
        return this.map.has(valueType) ?? false
    }
}
