import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Typography, Link } from '@fto/ui'
import { Icon } from '@fto/icons'
import { SUPPORT_LINK } from '@root/constants/urls'

import styles from './index.module.scss'

const localeKey = 'errorBoundary.requestProblem.toManyRequests'

type Props = {
    value: number
    unit: string
}

const getTimeToReplyLabel = (value: number, unit: string, t: (key: string, p: { count: number }) => string) => {
    if (unit === 's') {
        return t('timeFormat.secondsLong', { count: value })
    }

    if (unit === 'm') {
        return t('timeFormat.minutesLong', { count: value })
    }

    if (unit === 'h') {
        return t('timeFormat.hoursLong', { count: value })
    }

    return ''
}

const ApiRequestBoundary: FC<Props> = ({ value, unit }) => {
    const { t } = useTranslation()

    const label = getTimeToReplyLabel(value, unit, t)

    return (
        <div className={styles.root}>
            <Flex direction='column' gap={24} className={styles.content} alignItems='center'>
                <Icon name='ozzy-3' size={140} />

                <Flex direction='column' gap={8}>
                    <Typography type='h4-semibold' color='gray-1000' align='center'>
                        {t(`${localeKey}.title`)}
                    </Typography>
                    <Flex direction='column'>
                        <Typography type='interface-regular' color='gray-1000' align='center'>
                            {t(`${localeKey}.subtitle1`)}
                        </Typography>
                        {label && (
                            <Typography type='interface-regular' color='gray-1000' align='center'>
                                {t(`${localeKey}.subtitle2`, { label })}
                            </Typography>
                        )}
                    </Flex>
                </Flex>

                <Flex gap={4} alignItems='center'>
                    <Typography>{t(`${localeKey}.helpTitle`)}</Typography>
                    <Link className={styles.Link} href={`mailto:${SUPPORT_LINK}`}>
                        {SUPPORT_LINK}
                    </Link>
                </Flex>
            </Flex>
        </div>
    )
}

export default ApiRequestBoundary
