import { ChartControl } from '@fto/chart_components/ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { ChartEvent } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export enum LayerType {
    MAIN_CHART_ONLY = 1,
    ENTIRE_CHART_BLOCKING,
    ENTIRE_CHART
}

export class CanvasLayer {
    private _isActive = true
    private chartWindow!: TChartWindow
    size: LayerType
    parentNode: HTMLElement | null = null
    parentCanvas: HTMLCanvasElement
    canvas: HTMLCanvasElement
    context: CanvasRenderingContext2D
    tGdiPlusCanvas!: TGdiPlusCanvas
    name: string
    protected chartControls: ChartControl[] = []
    protected hoveredControl: ChartControl | null = null

    private observableItem: ObservableTemplateItem<ChartEvent, TChartWindow, ObserverTemplate<ChartEvent, TChartWindow>>

    get isActive(): boolean {
        return this._isActive
    }

    set isActive(value: boolean) {
        this._isActive = value
        if (this.tGdiPlusCanvas) {
            this.tGdiPlusCanvas.graphics.Context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        }
    }

    addControl(control: ChartControl): void {
        this.chartControls.push(control)
        control.attachObserver(this.chartWindow)
    }

    attachControlToObserver(control: ChartControl): void {
        control.attachObserver(this.chartWindow)
    }

    getControls(): ChartControl[] {
        return this.chartControls
    }

    getChartWindow(): TChartWindow {
        return this.chartWindow
    }

    constructor(canvas: HTMLCanvasElement, chartWindow: TChartWindow, size: LayerType, name: string) {
        const parentElement = canvas.parentElement
        if (parentElement) {
            this.parentNode = parentElement
        }

        this.observableItem = new ObservableTemplateItem<
            ChartEvent,
            TChartWindow,
            ObserverTemplate<ChartEvent, TChartWindow>
        >()

        this.chartWindow = chartWindow
        this.parentCanvas = canvas
        this.size = size

        this.canvas = document.createElement('canvas')
        this.canvas.style.position = 'absolute'
        this.canvas.style.pointerEvents = 'none'

        const newContext = this.canvas.getContext('2d')
        if (newContext === null) {
            throw new StrangeError('Could not get 2D context')
        }
        this.context = newContext
        this.name = name || this.generateUniqueName()
        this.canvas.dataset.name = this.name

        const dpr = window.devicePixelRatio || 1

        switch (size) {
            case LayerType.MAIN_CHART_ONLY: {
                this.setMainChartCanvasSizeAndPosition(dpr)
                break
            }
            case LayerType.ENTIRE_CHART:
            case LayerType.ENTIRE_CHART_BLOCKING: {
                this.setFullCanvasSizeAndPosition(dpr)
                break
            }
            default:
        }

        const ctx = this.canvas.getContext('2d')
        if (ctx) {
            ctx.scale(dpr, dpr)
            this.draw()
        }
    }

    private setFullCanvasSizeAndPosition(dpr: number): void {
        if (!this.parentNode) {
            return
        }

        const rect = this.parentNode.getBoundingClientRect()
        const computedStyle = window.getComputedStyle(this.parentCanvas)

        const borderLeft = parseFloat(computedStyle.borderLeftWidth) || 0
        const borderTop = parseFloat(computedStyle.borderTopWidth) || 0

        const paddingLeft = parseFloat(computedStyle.paddingLeft) || 0
        const paddingTop = parseFloat(computedStyle.paddingTop) || 0

        const innerWidth = rect.width - borderLeft * 2 - paddingLeft * 2
        const innerHeight = rect.height - borderTop * 2 - paddingTop * 2

        this.canvas.width = Math.round(innerWidth * dpr)
        this.canvas.height = Math.round(innerHeight * dpr)

        this.canvas.style.width = `${innerWidth}px`
        this.canvas.style.height = `${innerHeight}px`
        this.canvas.style.left = `${this.parentNode.offsetLeft + borderLeft + paddingLeft}px`
        this.canvas.style.top = `${this.parentNode.offsetTop + borderTop + paddingTop}px`
    }

    private setMainChartCanvasSizeAndPosition(dpr: number): void {
        const rect = this.parentCanvas.getBoundingClientRect()
        const computedStyle = window.getComputedStyle(this.parentCanvas)

        const borderLeft = parseFloat(computedStyle.borderLeftWidth) || 0
        const borderTop = parseFloat(computedStyle.borderTopWidth) || 0

        const paddingLeft = parseFloat(computedStyle.paddingLeft) || 0
        const paddingTop = parseFloat(computedStyle.paddingTop) || 0

        const innerWidth = rect.width - borderLeft * 2 - paddingLeft * 2
        const innerHeight = rect.height - borderTop * 2 - paddingTop * 2

        this.canvas.width = Math.round(innerWidth * dpr)
        this.canvas.height = Math.round(innerHeight * dpr)

        this.canvas.style.width = `${rect.width}px`
        this.canvas.style.height = `${rect.height}px`

        this.canvas.style.left = `${this.parentCanvas.offsetLeft + borderLeft + paddingLeft}px`
        this.canvas.style.top = `${this.parentCanvas.offsetTop + borderTop + paddingTop}px`
    }

    draw(): void {
        if (!this.isActive) {
            return
        }

        if (this.context && this.canvas) {
            this.context.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight)

            if (!this.tGdiPlusCanvas) {
                this.tGdiPlusCanvas = new TGdiPlusCanvas(this.canvas)
            }

            this.context.save()

            for (const control of this.chartControls) {
                control.draw(this.tGdiPlusCanvas)
            }

            this.context.restore()
        }
    }

    resize(draw = true): void {
        if (this.size === LayerType.MAIN_CHART_ONLY) {
            this.resizeLayerBars(draw)
        }
        if (this.size === LayerType.ENTIRE_CHART_BLOCKING || this.size === LayerType.ENTIRE_CHART) {
            this.resizeLayerFull(draw)
        }
    }

    resizeLayerBars(draw = true): void {
        const dpr = window.devicePixelRatio || 1

        this.setMainChartCanvasSizeAndPosition(dpr)

        const ctx = this.canvas.getContext('2d')
        if (ctx) {
            ctx.scale(dpr, dpr)

            for (const control of this.getControls()) {
                control.onBrowserWndSizing()
            }

            if (draw) {
                this.draw()
            }
        }
    }

    resizeLayerFull(draw = true): void {
        const dpr = window.devicePixelRatio || 1

        this.setFullCanvasSizeAndPosition(dpr)

        const ctx = this.canvas.getContext('2d')
        if (ctx) {
            ctx.scale(dpr, dpr)

            for (const control of this.getControls()) {
                control.onBrowserWndSizing()
            }

            if (draw) {
                this.draw()
            }
        }
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (!this.isActive) {
            return null
        }
        let result: ChartControl | null = null
        for (let i = 0; i < this.chartControls.length; i++) {
            result = this.chartControls[i].onMouseDown(this.mouseToLocal(event), sender)
            if (result) {
                break
            }
        }
        return result
    }

    public onMouseUp(event: MouseEvent): void {
        if (!this.isActive) {
            return
        }
        for (let i = 0; i < this.chartControls.length; i++) {
            this.chartControls[i].onMouseUp(this.mouseToLocal(event), this.chartWindow.MainChart)
        }
    }

    private mouseToLocal(event: MouseEvent): MouseEvent {
        const rect = this.canvas.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        return new MouseEvent(event.type, {
            button: event.button,
            clientX: x,
            clientY: y
        })
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        if (!this.isActive) {
            return null
        }
        let result: ChartControl | null = null
        for (let i = 0; i < this.chartControls.length; i++) {
            result = this.chartControls[i].onMouseMove(this.mouseToLocal(event), sender)
            if (result) {
                if (this.hoveredControl && this.hoveredControl !== result) {
                    this.hoveredControl.onMouseLeave(this.mouseToLocal(event))
                }
                this.hoveredControl = result
                this.draw()
                return result
            }
        }

        if (!result && this.hoveredControl) {
            this.hoveredControl.onMouseLeave(this.mouseToLocal(event))
            this.hoveredControl = null
            this.draw()
        }

        return result
    }

    onMouseLeave(event: MouseEvent): ChartControl | null {
        this.hoveredControl = null
        if (!this.isActive) {
            return null
        }
        let result: ChartControl | null = null
        for (let i = 0; i < this.chartControls.length; i++) {
            result = this.chartControls[i].onMouseLeave(this.mouseToLocal(event))
            if (result) {
                break
            }
        }
        return result
    }

    deleteControl(control: ChartControl): void {
        const index = this.chartControls.indexOf(control)
        if (index > -1) {
            this.chartControls.splice(index, 1)
        }
    }

    clearCanvas(): void {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }
    private generateUniqueName(): string {
        return `layer-${Math.random().toString(36).slice(2, 11)}`
    }
}

export class LayerDrawOptions {
    _visible: boolean
    _isSuspended: boolean
    _onlyChangeSymbol: boolean

    constructor() {
        this._visible = true
        this._isSuspended = false
        this._onlyChangeSymbol = false
    }

    isVisible(): boolean {
        return this._visible
    }

    show(value: boolean): void {
        this._visible = value
    }

    isSuspended(): boolean {
        return this._isSuspended
    }

    suspend(): void {
        this._isSuspended = true
    }

    resume(): void {
        this._isSuspended = false
    }

    isOnlyChangeSymbol(): boolean {
        return this._onlyChangeSymbol
    }

    showOnlyChangeSymbol(value: boolean): void {
        this._onlyChangeSymbol = value
    }

    getAllOptions(): object {
        return {
            isSuspended: this._isSuspended,
            onlyChangeSymbol: this._onlyChangeSymbol
        }
    }
}
