import dayjs from 'dayjs'
import { NewsPriority } from '@fto/lib/News/News'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
export function getColorForPriority(priority: NewsPriority): string {
    switch (priority) {
        case NewsPriority.Low:
            return 'var(--color-green-500)'
        case NewsPriority.Medium:
            return 'var(--color-orange-500)'
        case NewsPriority.High:
            return 'var(--color-red-500)'
        default:
            return 'black'
    }
}

export function getTimeAndDateFromUnix(timestamp: number): { date: string; time: string } {
    const dateObj = +new Date(timestamp * 1000)

    return { date: dayjs.utc(dateObj).format('YYYY.MM.DD'), time: dayjs.utc(dateObj).format('HH:mm') }
}

export function getColorForNewsValues(actual: string, previews: string): 'green-400' | 'gray-800' | 'red-400' {
    if (actual === '-' || actual.split('-').length - 1 === 2) {
        return 'gray-800'
    }
    if (actual.includes('|')) {
        const actualValues = actual.split('|')
        const previewsValues = previews.split('|')
        const actualCoef = Number(actualValues[0]) / Number(actualValues[1])
        const previewsCoef = Number(previewsValues[0]) / Number(previewsValues[1])
        return actualCoef > previewsCoef ? 'green-400' : actualCoef === previewsCoef ? 'gray-800' : 'red-400'
    }
    const actualNumber = Number(actual.replace(/[a-zA-Z%]/g, ''))
    const previewsNumber = Number(previews.replace(/[a-zA-Z%]/g, ''))

    return actualNumber > previewsNumber ? 'green-400' : actualNumber === previewsNumber ? 'gray-800' : 'red-400'
}

type DateLabel = {
    numerical: number
    label: string
}

const emptyValue: DateLabel = { numerical: 0, label: '' }
export const EMPTY_STATE = {
    days: emptyValue,
    hours: emptyValue,
    minutes: emptyValue,
    seconds: emptyValue
}
export const getTimeTillUpcomingNew = (
    start: number,
    end: number
): {
    days: DateLabel
    hours: DateLabel
    minutes: DateLabel
    seconds: DateLabel
} => {
    if (!start || !end) {
        return EMPTY_STATE
    }
    const dateStartUTC = dayjs.utc(+new Date(start)).valueOf()
    const dateEndUTC = dayjs.utc(+new Date(end)).valueOf()

    const diffInMilliseconds = Math.abs(dateEndUTC - dateStartUTC)

    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

    const formattedDays = String(days).padStart(2, '0')
    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')

    const lessThanOneSecond =
        !Number(formattedDays) &&
        !Number(formattedHours) &&
        !Number(formattedMinutes) &&
        !Number(formattedSeconds) &&
        diffInMilliseconds > 0

    return {
        days: {
            numerical: Number(formattedDays) || 0,
            label: formattedDays
        },
        hours: {
            numerical: Number(formattedHours) || 0,
            label: formattedHours
        },
        minutes: {
            numerical: Number(formattedMinutes) || 0,
            label: formattedMinutes
        },
        seconds: lessThanOneSecond
            ? {
                  numerical: 1,
                  label: '01'
              }
            : {
                  numerical: Number(formattedSeconds) || 0,
                  label: formattedSeconds
              }
    }
}

export const generateDateLabel = (count: string = '', label: string = '') => {
    if (!Number(count)) {
        return ''
    }

    return `${count}${label}`
}
