import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export abstract class SymbolCalculationStrategy {
    protected symbolData: TSymbolData

    public abstract getPointCostForOneStandardLot(
        operationType: TTradePositionType | null,
        price: number | null
    ): number

    public abstract calculateMarginRequirementForSymbol(
        operationType: TTradePositionType | null,
        openPrice: number | null
    ): number

    constructor(symbolData: TSymbolData) {
        this.symbolData = symbolData
    }
}
