import { FC, ReactNode, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, Typography, Button, Flex, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import styles from './index.module.scss'
type Props = {
    message: string
}

const EducationMessage: FC<Props> = observer(({ message }) => {
    const handleModalClose = useCallback(() => {
        removeModal(MODAL_NAMES.messages.educationMessage)
    }, [])

    return (
        <Modal name={MODAL_NAMES.messages.educationMessage} size='sm' isDraggable onClose={handleModalClose}>
            <Modal.Header>
                <Typography>Task</Typography>
            </Modal.Header>
            <Modal.Content className={styles.Content}>
                <Flex direction='column' gap={8}>
                    <Typography>{message}</Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.ControlsWrapper}>
                <Button
                    onClick={handleModalClose}
                    label={'OK'}
                    classNames={{ root: styles.SubmitButton, content: styles.SubmitButton }}
                ></Button>
            </Modal.Controls>
        </Modal>
    )
})

export default EducationMessage
