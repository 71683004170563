import { FC } from 'react'

import { Flex, Tooltip, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    title: string
    value: string
    tooltip: string
}

export const AnalyticsInfoRow: FC<Props> = ({ title, value, tooltip }) => {
    return (
        <Flex justifyContent='space-between' className={styles.text}>
            <Flex gap={8} alignItems='center'>
                <Tooltip content={tooltip} placement='left'>
                    <Icon className={styles.icon} size={16} color='var(--color-gray-500)' name='info-filled' />
                </Tooltip>
                <Typography type='interface-regular'>{title}</Typography>
            </Flex>
            <Typography type='interface-regular'>{value}</Typography>
        </Flex>
    )
}
