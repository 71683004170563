class MouseTracker {
    private static instance: MouseTracker
    public x: number = 0
    public y: number = 0
    public prevX: number = 0
    public prevY: number = 0
    private constructor() {}

    public static getInstance(): MouseTracker {
        if (!MouseTracker.instance) {
            MouseTracker.instance = new MouseTracker()
        }
        return MouseTracker.instance
    }

    public updateMousePosition(x: number, y: number): void {
        this.prevX = this.x
        this.prevY = this.y
        this.x = x
        this.y = y
    }
    public getMouseDiffX(): number {
        return this.x - this.prevX
    }
    public getMouseDiffY(): number {
        return this.y - this.prevY
    }
}

export default MouseTracker
