import React, { Dispatch, FC, SetStateAction } from 'react'

import { Flex } from '@fto/ui'

import { ColumnsListType, OrdersListType, TabType } from '@root/pages/ChartPage/components/Terminal/types'
import { UserTerminalSettings } from '@root/utils/localStorage'

import THead from './components/THead'
import Body from './components/Body'
import EmptyStateBlock from './components/EmptyState'

import styles from './index.module.scss'

type Props = {
    positions: OrdersListType
    activeTab: TabType
    cellsList: ColumnsListType
    setCellsList: Dispatch<SetStateAction<ColumnsListType>>
    tableSettings: UserTerminalSettings
    setTableSettings: Dispatch<SetStateAction<UserTerminalSettings>>
    selectedOrders: number[]
}

const Table: FC<Props> = ({
    positions,
    activeTab,
    tableSettings,
    setTableSettings,
    cellsList,
    setCellsList,
    selectedOrders
}) => {
    return (
        <Flex className={styles.table}>
            <Flex direction='column' flexGrow={1}>
                <THead
                    setTableColumnsSettings={setTableSettings}
                    cellsList={cellsList}
                    setCellsList={setCellsList}
                    activeTab={activeTab}
                    tableColumns={tableSettings}
                />
                {positions.length === 0 ? (
                    <EmptyStateBlock activeTab={activeTab} />
                ) : (
                    <Body
                        tableSettings={tableSettings}
                        cellsList={cellsList}
                        positions={positions}
                        activeTab={activeTab}
                        selectedOrders={selectedOrders}
                    />
                )}
            </Flex>
        </Flex>
    )
}

export default Table
