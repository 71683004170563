import { makeAutoObservable } from 'mobx'

import { initialData } from './constants'
import { StoreDataType, UpdaterType } from './types'

class globalChartsStore {
    data: StoreDataType

    constructor() {
        makeAutoObservable(this)

        this.data = initialData

        this.updateData = this.updateData.bind(this)
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.data = initialData
    }
}

export default new globalChartsStore()
