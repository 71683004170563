import { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_FONT, FONT_MAP, SupportedLanguagesType } from './constants'
import { LANGUAGES } from '@root/constants/langs'

type Props = {
    children: ReactNode
}
const LocalizationProvider: FC<Props> = ({ children }) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        const lang = (
            i18n.language === null || i18n.language === LANGUAGES.ach.value ? LANGUAGES.en.value : i18n.language
        ) as SupportedLanguagesType

        document.documentElement.style.setProperty('--app-font', FONT_MAP[lang] || DEFAULT_FONT)
    }, [i18n.language])

    return children
}

export default LocalizationProvider
