import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TColor, DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle, TDrawStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { ColorHelperFunctions } from '../ColorHelperFunctions'

export class TLineStyleRec {
    color: TColor // line color
    style: TPenStyle // line style
    width: number // line width
    DrawingStyle: TDrawStyle // drawing style on chart
    Symbol!: number // symbol index for ds_Symbol
    xoffs!: number // x and y shift of symbol in pixels
    yoffs!: number

    constructor() {
        this.color = DelphiColors.clRed
        this.style = TPenStyle.psSolid
        this.width = 1
        this.DrawingStyle = TDrawStyle.ds_Line
    }

    GetShortStr(isNeedToShowLineProperties: boolean): string {
        if (isNeedToShowLineProperties) {
            return `${StrsConv.StrColor(this.color)},${this.style},${this.width}`
        }
        return `${StrsConv.StrColor(this.color)}`
    }

    SetShortStr(s: string): void {
        const parts = s.split(',')
        if (parts.length !== 3) {
            if (parts.length === 1) {
                this.color = ColorHelperFunctions.FixColor(parts[0])
                return
            } else {
                throw new StrangeError('SetShortStr - Invalid string format.')
            }
        }

        this.color = ColorHelperFunctions.FixColor(parts[0])

        // Converting string to integer for style
        this.style = parseInt(parts[1])

        // Converting string to integer for width
        this.width = parseInt(parts[2])
    }
}
