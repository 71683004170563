import { addModal } from '@fto/ui'
import { DebugUtils } from '../DebugUtils'
import { Command } from './Command'
import { MODAL_NAMES } from '@root/constants/modalNames'

class DebugCompareCommand extends Command {
    execute() {
        if (DebugUtils.DebugMode) {
            setTimeout(CompareEverything, 0)
        }
    }
}

function CompareEverything(): void {
    addModal(MODAL_NAMES.devEnv.longTermTests)
    // BuildTimeframesChecker.Instance.CompareEverything()
}

export { DebugCompareCommand }
