import { ValidationErrorType } from './ValidationErrorType'
import { ValidationStrategy } from './ValidationStrategy'

export class DoubleValidationStrategy extends ValidationStrategy {
    public isValid(value: string): boolean {
        let result = false

        if (value === '') {
            const errorText = 'empty'
            this.errorDescription = {
                type: ValidationErrorType.CRITICAL,
                description: errorText
            }
        } else {
            if (this.isNumber(value)) {
                const valueNum = parseFloat(value)

                if (valueNum > 9000000000000000) {
                    const errorText = 'maxAllowableValue'
                    this.errorDescription = {
                        type: ValidationErrorType.CRITICAL,
                        description: errorText
                    }
                } else {
                    result = true
                }
            } else {
                const errorText = 'invalidNumberFormat'
                this.errorDescription = {
                    type: ValidationErrorType.CRITICAL,
                    description: errorText
                }
            }
        }

        return result
    }
}
