import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { Command } from './Command'
import ChartSettingsStore from '@fto/lib/store/chartSettings'

class PlayPauseCommand extends Command {
    public execute(): void {
        const { settings, setSettings } = ChartSettingsStore
        if (settings.isPlaying) {
            GlobalTestingManager.TestingManager.stopTheTimer()
        } else {
            GlobalTestingManager.TestingManager.StartTesting(settings.TestingSpeed)
        }

        setSettings({ ...settings, isPlaying: !settings.isPlaying })
    }
}

export { PlayPauseCommand }
