import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

import cx from 'classnames'

import styles from './index.module.scss'

type AnchorProps = JSX.IntrinsicElements['a']

const hasHref = (props: LinkProps | AnchorProps): props is LinkProps => 'to' in props

export const Link = forwardRef<HTMLAnchorElement, LinkProps | AnchorProps>(({ children, className, ...props }, ref) => {
    return hasHref(props) ? (
        <RouterLink {...props} ref={ref} className={cx(styles.link, className)}>
            {children}
        </RouterLink>
    ) : (
        <a {...props} ref={ref} className={cx(styles.link, className)}>
            {children}
        </a>
    )
})

Link.displayName = 'Link'
