export default class BasicConstants {
    // Constants
    public static readonly ArrEmptyValue = 12481632
    public static readonly st_CurrencyPair = 0
    public static readonly st_Stock = 1
    public static readonly st_Crypto = 2
    public static readonly ds_Guest = 0
    public static readonly ds_Beginner = 1
    public static readonly ds_Trial = 2
    public static readonly ds_Standard = 3
    public static readonly ds_VIP = 4
    public static readonly ds_Tester = 9
}
