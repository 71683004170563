import React, { useEffect } from 'react'
import { fireMixpanelEvent } from '@root/utils/api'

const PageNotFound = () => {
    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'not_found' })
    }, [])

    return <>Page not found</>
}
export default PageNotFound
