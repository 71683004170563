import { FC } from 'react'

import styles from './index.module.scss'

type Props = {
    value: string
    onChange: (value: string) => void
    placeholder?: string
}

const TextArea: FC<Props> = ({ value, onChange, placeholder }) => {
    return (
        <textarea
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={styles.textArea}
        />
    )
}

export default TextArea
