import { makeAutoObservable } from 'mobx'

import { TTradeStatistics } from '@fto/lib/processing_core/TradeStatistics'

const initialStatistics: TTradeStatistics = {
    DaysProcessed: 0,
    MonthsProcessed: 0,
    TotalTrades: 0,
    ProfitTrades: 0,
    LossTrades: 0,
    ProfitTradesConsequently: 0,
    LossTradesConsequently: 0,
    TradesPerDay: 0,
    TradesPerMonth: 0,
    ProfitTradesPerMonth: 0,
    LossTradesPerMonth: 0,
    MaxProfitTrade: 0,
    MaxLossTrade: 0,
    NetProfit: 0,
    GrossProfit: 0,
    GrossLoss: 0,
    ProfitPerMonth: 0,
    AverageProfit: 0,
    AverageLoss: 0,
    MaxDrawdown: 0,
    ProfitFactor: 0,
    ReturnPercents: 0,
    MaxLotUsed: 0,
    RestorationFactor: 0,
    ReliabilityFactor: 0,
    ProfitProbability: 0,
    LossProbability: 0,
    balance: 0,
    equity: 0,
    margin: 0,
    FreeMargin: 0
}

class StatisticsStore {
    statistics: TTradeStatistics

    constructor() {
        makeAutoObservable(this)

        this.statistics = initialStatistics
        this.setStatistics = this.setStatistics.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.getGroupedStatistics = this.getGroupedStatistics.bind(this)
    }

    setStatistics(updater: ((prevStatistics: TTradeStatistics) => TTradeStatistics) | TTradeStatistics) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current info to get the updates
            this.statistics = {
                ...this.statistics,
                ...updater(this.statistics)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.statistics = {
                ...this.statistics,
                ...updater
            }
        }
    }

    resetStore() {
        this.statistics = initialStatistics
    }

    getGroupedStatistics() {
        return {
            TimeStatistics: {
                DaysProcessed: this.statistics.DaysProcessed,
                MonthsProcessed: this.statistics.MonthsProcessed
            },
            TradeStatistics: {
                TotalTrades: this.statistics.TotalTrades,
                ProfitTrades: this.statistics.ProfitTrades,
                LossTrades: this.statistics.LossTrades,
                ProfitTradesConsequently: this.statistics.ProfitTradesConsequently,
                LossTradesConsequently: this.statistics.LossTradesConsequently,
                TradesPerDay: this.statistics.TradesPerDay,
                TradesPerMonth: this.statistics.TradesPerMonth,
                ProfitTradesPerMonth: this.statistics.ProfitTradesPerMonth,
                LossTradesPerMonth: this.statistics.LossTradesPerMonth,
                MaxProfitTrade: this.statistics.MaxProfitTrade,
                MaxLossTrade: this.statistics.MaxLossTrade
            },
            IncomeStatistics: {
                NetProfit: this.statistics.NetProfit,
                GrossProfit: this.statistics.GrossProfit,
                GrossLoss: this.statistics.GrossLoss,
                ProfitPerMonth: this.statistics.ProfitPerMonth,
                AverageProfit: this.statistics.AverageProfit,
                AverageLoss: this.statistics.AverageLoss,
                MaxDrawdown: this.statistics.MaxDrawdown,
                ProfitFactor: this.statistics.ProfitFactor,
                ReturnPercents: this.statistics.ReturnPercents
            },
            OtherStatistics: {
                MaxLotUsed: this.statistics.MaxLotUsed,
                RestorationFactor: this.statistics.RestorationFactor,
                ReliabilityFactor: this.statistics.ReliabilityFactor,
                ProfitProbability: this.statistics.ProfitProbability,
                LossProbability: this.statistics.LossProbability
            }
        }
    }
}

export default new StatisticsStore()
