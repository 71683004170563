import { useAppSelector } from '@root/hooks/useStoreHook'
import { $useCrowdin } from '@root/store/appSettings/selectors'
import { useEffect } from 'react'

export function useCrowdinScriptLoading() {
    const useCrowdin = useAppSelector($useCrowdin)

    const scriptId = 'crowdin-script'
    const jiptScriptId = 'jipt-script'

    function removeScript() {
        const existingScript = document.getElementById(scriptId)
        const existingJiptScript = document.getElementById(jiptScriptId)
        if (existingScript) {
            document.body.removeChild(existingScript)
        }
        if (existingJiptScript) {
            document.body.removeChild(existingJiptScript)
        }
    }

    function appendScript() {
        const script = document.createElement('script')
        script.id = scriptId
        script.type = 'text/javascript'
        script.src = '//cdn.crowdin.com/jipt/jipt.js'
        script.async = true
        document.body.appendChild(script)

        const jiptScript = document.createElement('script')
        jiptScript.id = jiptScriptId
        jiptScript.type = 'text/javascript'
        jiptScript.innerHTML = "var _jipt = []; _jipt.push(['project', 'fto']);"
        document.body.appendChild(jiptScript)
    }

    useEffect(() => {
        if (useCrowdin) {
            appendScript()
        } else {
            removeScript()
        }

        return () => {
            removeScript()
        }
    }, [useCrowdin])
}
