import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, HoverElement, TriggerOverlay, Typography } from '@fto/ui'
import { downloadSpreadsheet, TableData } from '@root/pages/ChartPage/utils/exportIntoExcel'

import styles from './index.module.scss'

type Props = {
    data: TableData[]
    filename: string
    iconButton?: boolean
}

const ExportSpreadsheetDropdown: FC<Props> = ({ data, filename, iconButton }) => {
    const { t } = useTranslation()

    const downloadCSV = useCallback(
        (close: () => void) => {
            downloadSpreadsheet(data, filename, 'csv')
            close()
        },
        [data, filename]
    )

    const downloadXLSX = useCallback(
        (close: () => void) => {
            downloadSpreadsheet(data, filename, 'xlsx')
            close()
        },
        [data, filename]
    )

    return (
        <TriggerOverlay
            transformOrigin={{ vertical: iconButton ? 'top' : 'bottom', horizontal: 'right' }}
            anchorOrigin={{ vertical: iconButton ? 'bottom' : 'top', horizontal: 'right' }}
            optionsRenderer={(close) => (
                <Flex direction='column' gap={8}>
                    <Button
                        block
                        onClick={() => downloadCSV(close)}
                        before={<Icon name='csv' />}
                        classNames={{ content: styles.buttonContent }}
                        label={t('export.csv')}
                        type='tetriatry-white'
                        size='compact'
                    />
                    <Button
                        block
                        onClick={() => downloadXLSX(close)}
                        before={<Icon name='xlsx' />}
                        classNames={{ content: styles.buttonContent }}
                        label={t('export.xlsx')}
                        type='tetriatry-white'
                        size='compact'
                    />
                </Flex>
            )}
        >
            {iconButton ? (
                <HoverElement size={32}>
                    <Icon size={24} name='download' color={'var(--color-gray-900)'} />
                </HoverElement>
            ) : (
                <Button
                    block
                    before={<Icon size={16} name='download' />}
                    classNames={{ root: styles.exportButtonRoot, content: styles.exportButtonContent }}
                    label={<Typography type='subtext-regular'>{t('export.history')}</Typography>}
                    type='tetriatry-white'
                    size='tiny'
                />
            )}
        </TriggerOverlay>
    )
}

export default ExportSpreadsheetDropdown
