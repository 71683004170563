import { makeRoute, USER_PROFILE_ROUTE } from '@root/constants/routes'

export const redirectByAction = (actionType: string | null, searchParams: string, navigate: (url: string) => void) => {
    if (
        (actionType === 'changeemail' || actionType === 'changepassword') &&
        window.location.pathname !== makeRoute(USER_PROFILE_ROUTE)
    ) {
        return navigate(`${makeRoute(USER_PROFILE_ROUTE)}${searchParams}`)
    }
}
