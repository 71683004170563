import TDownloadableChunk from '../../chunks/DownloadableChunk/DownloadableChunk'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import ChunkBuilderTask from './ChunkBuilderTask'

export class ChunkBuilderTaskQueue {
    RemoveTask(chunkBuilderTask: ChunkBuilderTask): void {
        this._queue = this._queue.filter((task) => task !== chunkBuilderTask)
    }

    AddTask(chunkBuilderTask: ChunkBuilderTask): void {
        this._queue.push(chunkBuilderTask)
    }

    GetTaskForChunk(chunkToBuild: TDownloadableChunk<TBarRecord>): ChunkBuilderTask | undefined {
        return this._queue.find((task) => task.ChunkToBuild === chunkToBuild)
    }

    private _queue: ChunkBuilderTask[] = []

    public get queue(): ChunkBuilderTask[] {
        return this._queue
    }

    public get length(): number {
        return this._queue.length
    }
}
