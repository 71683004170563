import IEventEmitter from '@fto/lib/common/IEventEmitter'
import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import MockServerDataImporter from './MockServerDataImporter'
import { TDataRecordWithDate } from '@fto/lib/ft_types/data/DataClasses/TDataRecordWithDate'

export default class MockDownloadTask implements IEventEmitter {
    public Events: TEventsFunctionality = new TEventsFunctionality('MockDownloadTask')
    private _chunk: TDownloadableChunk<TDataRecordWithDate>

    constructor(chunk: TDownloadableChunk<TDataRecordWithDate>) {
        // do nothing
        this._chunk = chunk
    }

    public get Chunk() {
        return this._chunk
    }

    public executeDataImport() {
        MockServerDataImporter.MockDownloadChunk(this._chunk)
    }
}
