import { FC, useEffect, useCallback } from 'react'

import GlobalChartsController from '@fto/lib//globals/GlobalChartsController'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import MainChartComponent from './ChartComponent/MainChartComponent'

import styles from '@fto/chart_components/ChartStyle.module.scss'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

interface ChartWindowComponentProps {
    chartInstance: TChartWindow
    symbolName: string
    showBorderWhenSelected: boolean
}

const ChartWindowComponent: FC<ChartWindowComponentProps> = ({ chartInstance, symbolName, showBorderWhenSelected }) => {
    DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'Creating ChartWindowComponent')

    useEffect(() => {
        GlobalChartsController.Instance.addChart(chartInstance)
    }, [])

    const handleMouseEnter = useCallback((event: any) => {
        chartInstance.OnMouseEnter(event)
    }, [])

    const handleMouseLeave = useCallback((event: any) => {
        chartInstance.OnMouseLeave(event)
    }, [])

    return (
        <div
            className={styles.chartContainer}
            id={`chart-container-${chartInstance.getID()}`}
            tabIndex={0}
            onMouseEnter={(event) => handleMouseEnter(event)}
            onMouseLeave={(event) => handleMouseLeave(event)}
        >
            <MainChartComponent
                chartWindow={chartInstance}
                symbolName={symbolName}
                showBorderWhenSelected={showBorderWhenSelected}
            />
        </div>
    )
}

export default ChartWindowComponent
