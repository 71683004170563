import { FC } from 'react'
import cx from 'classnames'

import { Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    value?: number
    classNames?: {
        root?: string
    }
    disabled?: boolean
}

export const Counter: FC<Props> = ({ value = 0, classNames = {}, disabled = false }) => {
    return (
        <div className={cx(styles.Counter, classNames.root)}>
            <Typography type='subtext-regular' color={disabled ? 'gray-600' : 'gray-1000'}>
                {value}
            </Typography>
        </div>
    )
}
