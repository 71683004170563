import { FC, useCallback, useEffect } from 'react'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'

import ToolMenuContent from '../components/ToolMenuContent'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        chart: TChartWindow
        tools: TBasicPaintTool[]
    }
}

const ToolContextMenu: FC<Props> = ({ anchorX, anchorY, additionalProps }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.tool)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.tool} anchorX={anchorX} anchorY={anchorY}>
            <ToolMenuContent chart={additionalProps.chart} tools={additionalProps.tools} removeMenu={removeMenu} />
        </ContextMenu>
    )
}

export default ToolContextMenu
