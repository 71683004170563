import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal, Typography, Button, removeModal } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { showErrorToast } from '@root/utils/toasts'
import { claimExclusiveOffer, fireMixpanelEvent } from '@root/utils/api'

import styles from './index.module.scss'

const localeKey = 'profile.modals.suspendSubscriptionModal.exclusiveOffer'

type Props = {
    setStep: (step: number) => void
    subscriptionStatus: 'monthly' | 'annual' | 'lifetime' | 'Unknown'
    subscriptionId: number
}

const ExclusiveOffer: FC<Props> = ({ setStep, subscriptionStatus, subscriptionId }) => {
    const { t } = useTranslation()

    useEffect(() => {
        fireMixpanelEvent('subscription_offer_view')
    }, [])

    const acceptOffer = () => {
        removeModal(MODAL_NAMES.userProfile.suspendSubscription)

        claimExclusiveOffer(subscriptionId)
            .then((response) => {
                if (response.data.isDiscountApplied) {
                    fireMixpanelEvent('subscription_offer_accepted', {
                        offer: response.data.discount,
                        next_amount_to_be_billed: response.data.newPrice,
                        previous_billing_amount: response.data.oldPrice,
                        subscription_term: subscriptionStatus
                    })
                } else {
                    fireMixpanelEvent('subscription_offer_failed', {
                        offer: response.data.discount
                    })
                }
            })
            .catch(() => {
                showErrorToast({ title: t(`${localeKey}.errorToast`) })
            })
    }

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <div className={styles.Image}>
                        <img width={448} src='/img/suspendPromoGradient.png' alt='' />
                        <span className={styles.PromoText}>{t(`${localeKey}.promoText`)}</span>
                    </div>

                    <Typography type='h5-medium' color='gray-1000' align='center'>
                        {t(`${localeKey}.subTitle`)}
                    </Typography>

                    <span className={styles.Discount}>{t(`${localeKey}.discount`)}</span>

                    <Typography type='text-medium' color='gray-1000' align='center'>
                        {t(`${localeKey}.description`)}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.GridWrapper}>
                <Button type='secondary' onClick={() => setStep(3)} label={t(`${localeKey}.cancel`)} block />
                <Button type='primary' onClick={acceptOffer} label={t(`${localeKey}.cta`)} block />
            </Modal.Controls>
        </>
    )
}

export default ExclusiveOffer
