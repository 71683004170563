import { FC } from 'react'

import { Flex, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    title: string | number
}

const FilterBlock: FC<Props> = ({ title }) => {
    return (
        <Flex alignItems='center' gap={8} justifyContent='space-between' className={styles.FilterBlock}>
            <Typography type='interface-medium' color='gray-800'>
                {title}
            </Typography>
            <Icon size={12} color='var(--color-gray-800)' name='chevron-down' className={styles.icon} />
        </Flex>
    )
}

export default FilterBlock
