import { t } from 'i18next'

import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export type TimeZoneDesc = {
    id: number //index in array
    value: string
    offsetDT: number // Offset in delphi time from UTC
    offsetSeconds: number // Offset in seconds from UTC
    description: string // Description of the time zone, including countries and regions
    label: string // Label of the time zone like 'UTC+1'
    serverValue: number // value for BE saving as flat number
}

export class TimeZoneManager {
    private timeZones: TimeZoneDesc[] = []

    constructor() {
        this.initializeTimeZones()
    }

    // Method to initialize the list of time zones
    private initializeTimeZones() {
        this.timeZones = [
            {
                id: 0,
                value: 'UTC-12',
                offsetDT: -12 * DateUtils.OneHour,
                offsetSeconds: -12 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-12.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-12.label'),
                serverValue: -12
            },
            {
                id: 1,
                value: 'UTC-11',
                offsetDT: -11 * DateUtils.OneHour,
                offsetSeconds: -11 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-11.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-11.label'),
                serverValue: -11
            },
            {
                id: 2,
                value: 'UTC-10',
                offsetDT: -10 * DateUtils.OneHour,
                offsetSeconds: -10 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-10.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-10.label'),
                serverValue: -10
            },
            {
                id: 3,
                value: 'UTC-9:30',
                offsetDT: -9.5 * DateUtils.OneHour,
                offsetSeconds: -9.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-9-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-9-30.label'),
                serverValue: -9.5
            },
            {
                id: 4,
                value: 'UTC-9',
                offsetDT: -9 * DateUtils.OneHour,
                offsetSeconds: -9 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-9.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-9.label'),
                serverValue: -9
            },
            {
                id: 5,
                value: 'UTC-8',
                offsetDT: -8 * DateUtils.OneHour,
                offsetSeconds: -8 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-8.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-8.label'),
                serverValue: -8
            },
            {
                id: 6,
                value: 'UTC-7',
                offsetDT: -7 * DateUtils.OneHour,
                offsetSeconds: -7 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-7.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-7.label'),
                serverValue: -7
            },
            {
                id: 7,
                value: 'UTC-6',
                offsetDT: -6 * DateUtils.OneHour,
                offsetSeconds: -6 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-6.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-6.label'),
                serverValue: -6
            },
            {
                id: 8,
                value: 'UTC-5',
                offsetDT: -5 * DateUtils.OneHour,
                offsetSeconds: -5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-5.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-5.label'),
                serverValue: -5
            },
            {
                id: 9,
                value: 'UTC-4:30',
                offsetDT: -4.5 * DateUtils.OneHour,
                offsetSeconds: -4.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-4-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-4-30.label'),
                serverValue: -4.5
            },
            {
                id: 10,
                value: 'UTC-4',
                offsetDT: -4 * DateUtils.OneHour,
                offsetSeconds: -4 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-4.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-4.label'),
                serverValue: -4
            },
            {
                id: 11,
                value: 'UTC-3:30',
                offsetDT: -3.5 * DateUtils.OneHour,
                offsetSeconds: -3.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-3-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-3-30.label'),
                serverValue: -3.5
            },
            {
                id: 12,
                value: 'UTC-3',
                offsetDT: -3 * DateUtils.OneHour,
                offsetSeconds: -3 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-3.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-3.label'),
                serverValue: -3
            },
            {
                id: 13,
                value: 'UTC-2',
                offsetDT: -2 * DateUtils.OneHour,
                offsetSeconds: -2 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-2.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-2.label'),
                serverValue: -2
            },
            {
                id: 14,
                value: 'UTC-1',
                offsetDT: -1 * DateUtils.OneHour,
                offsetSeconds: -1 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC-1.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC-1.label'),
                serverValue: -1
            },
            {
                id: 15,
                value: 'UTC+0',
                offsetDT: 0,
                offsetSeconds: 0,
                description: t('contextMenu.timeZonesContextMenu.UTC+0.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+0.label'),
                serverValue: 0
            },
            {
                id: 16,
                value: 'UTC+1',
                offsetDT: 1 * DateUtils.OneHour,
                offsetSeconds: 1 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+1.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+1.label'),
                serverValue: 1
            },
            {
                id: 17,
                value: 'UTC+2',
                offsetDT: 2 * DateUtils.OneHour,
                offsetSeconds: 2 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+2.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+2.label'),
                serverValue: 2
            },
            {
                id: 18,
                value: 'UTC+3',
                offsetDT: 3 * DateUtils.OneHour,
                offsetSeconds: 3 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+3.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+3.label'),
                serverValue: 3
            },
            {
                id: 19,
                value: 'UTC+3:30',
                offsetDT: 3.5 * DateUtils.OneHour,
                offsetSeconds: 3.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+3-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+3-30.label'),
                serverValue: 3.5
            },
            {
                id: 20,
                value: 'UTC+4',
                offsetDT: 4 * DateUtils.OneHour,
                offsetSeconds: 4 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+4.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+4.label'),
                serverValue: 4
            },
            {
                id: 21,
                value: 'UTC+4:30',
                offsetDT: 4.5 * DateUtils.OneHour,
                offsetSeconds: 4.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+4-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+4-30.label'),
                serverValue: 4.5
            },
            {
                id: 22,
                value: 'UTC+5',
                offsetDT: 5 * DateUtils.OneHour,
                offsetSeconds: 5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+5.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+5.label'),
                serverValue: 5
            },
            {
                id: 23,
                value: 'UTC+5:30',
                offsetDT: 5.5 * DateUtils.OneHour,
                offsetSeconds: 5.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+5-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+5-30.label'),
                serverValue: 5.5
            },
            {
                id: 24,
                value: 'UTC+5:45',
                offsetDT: 5.75 * DateUtils.OneHour,
                offsetSeconds: 5.75 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+5-45.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+5-45.label'),
                serverValue: 5.75
            },
            {
                id: 25,
                value: 'UTC+6',
                offsetDT: 6 * DateUtils.OneHour,
                offsetSeconds: 6 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+6.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+6.label'),
                serverValue: 6
            },
            {
                id: 26,
                value: 'UTC+6:30',
                offsetDT: 6.5 * DateUtils.OneHour,
                offsetSeconds: 6.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+6-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+6-30.label'),
                serverValue: 6.5
            },
            {
                id: 27,
                value: 'UTC+7',
                offsetDT: 7 * DateUtils.OneHour,
                offsetSeconds: 7 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+7.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+7.label'),
                serverValue: 7
            },
            {
                id: 28,
                value: 'UTC+8',
                offsetDT: 8 * DateUtils.OneHour,
                offsetSeconds: 8 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+8.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+8.label'),
                serverValue: 8
            },
            {
                id: 29,
                value: 'UTC+8:45',
                offsetDT: 8.75 * DateUtils.OneHour,
                offsetSeconds: 8.75 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+8-45.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+8-45.label'),
                serverValue: 8.75
            },
            {
                id: 30,
                value: 'UTC+9',
                offsetDT: 9 * DateUtils.OneHour,
                offsetSeconds: 9 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+9.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+9.label'),
                serverValue: 9
            },
            {
                id: 31,
                value: 'UTC+9:30',
                offsetDT: 9.5 * DateUtils.OneHour,
                offsetSeconds: 9.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+9-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+9-30.label'),
                serverValue: 9.5
            },
            {
                id: 32,
                value: 'UTC+10',
                offsetDT: 10 * DateUtils.OneHour,
                offsetSeconds: 10 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+10.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+10.label'),
                serverValue: 10
            },
            {
                id: 33,
                value: 'UTC+10:30',
                offsetDT: 10.5 * DateUtils.OneHour,
                offsetSeconds: 10.5 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+10-30.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+10-30.label'),
                serverValue: 10.5
            },
            {
                id: 34,
                value: 'UTC+11',
                offsetDT: 11 * DateUtils.OneHour,
                offsetSeconds: 11 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+11.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+11.label'),
                serverValue: 11
            },
            {
                id: 35,
                value: 'UTC+12',
                offsetDT: 12 * DateUtils.OneHour,
                offsetSeconds: 12 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+12.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+12.label'),
                serverValue: 12
            },
            {
                id: 36,
                value: 'UTC+12:45',
                offsetDT: 12.75 * DateUtils.OneHour,
                offsetSeconds: 12.75 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+12-45.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+12-45.label'),
                serverValue: 12.75
            },
            {
                id: 37,
                value: 'UTC+13',
                offsetDT: 13 * DateUtils.OneHour,
                offsetSeconds: 13 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+13.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+13.label'),
                serverValue: 13
            },
            {
                id: 38,
                value: 'UTC+14',
                offsetDT: 14 * DateUtils.OneHour,
                offsetSeconds: 14 * 60 * 60,
                description: t('contextMenu.timeZonesContextMenu.UTC+14.description'),
                label: t('contextMenu.timeZonesContextMenu.UTC+14.label'),
                serverValue: 14
            }
        ]
    }

    public getTimezones() {
        return this.timeZones
    }

    public getTimezoneDescByTimezoneOffsetInHours(timezoneOffsetInHours: number): TimeZoneDesc {
        const secondsInHour = 60 * 60
        const index = this.timeZones.findIndex((tz) => tz.offsetSeconds === timezoneOffsetInHours * secondsInHour)
        if (index >= 0) {
            return this.timeZones[index]
        } else {
            throw new StrangeError('Timezone in hours not found', timezoneOffsetInHours)
        }
    }

    public getDefaulTimeZone(): TimeZoneDesc {
        return this.timeZones[15]
    }

    public zoneUp(currentTimeZoneDesc: TimeZoneDesc): TimeZoneDesc {
        const index = currentTimeZoneDesc.id + 1
        if (index < this.timeZones.length) {
            return this.timeZones[index]
        }
        return currentTimeZoneDesc
    }

    public zoneDown(currentTimeZoneDesc: TimeZoneDesc): TimeZoneDesc {
        const index = currentTimeZoneDesc.id - 1
        if (index >= 0) {
            return this.timeZones[index]
        }
        return currentTimeZoneDesc
    }

    public getAllTimeZones(): TimeZoneDesc[] {
        return this.timeZones
    }

    public getTimeZoneByName(name: string): TimeZoneDesc | undefined {
        return this.timeZones.find((tz) => tz.value === name)
    }

    // Method to get the current time in a specific time zone
    public getCurrentTimeInTimeZone(name: string): Date | undefined {
        const timeZone = this.getTimeZoneByName(name)
        if (!timeZone) {
            return undefined
        }
        const utcTime = new Date().getTime()
        const localTime = new Date(utcTime + timeZone.offsetDT)
        return localTime
    }
}

export default TimeZoneManager
