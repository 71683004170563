import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CustomIndicator {
    filename: string
    code: string
    name: string
}

interface IndicatorsState {
    list: CustomIndicator[]
}

const initialState: IndicatorsState = {
    list: []
}

const indicatorsSlice = createSlice({
    name: 'indicators',
    initialState,
    reducers: {
        ADD_NEW_INDICATOR: (state, action: PayloadAction<CustomIndicator>) => {
            const { filename, code, name } = action.payload
            const existingIndex = state.list.findIndex((indicator) => indicator.filename === filename)
            if (existingIndex === -1) {
                state.list.push({ filename, code, name })
            } else {
                state.list[existingIndex] = { filename, code, name }
            }
        }
    }
})

export const { ADD_NEW_INDICATOR } = indicatorsSlice.actions

export default indicatorsSlice.reducer
