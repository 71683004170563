import { TSymbolInfo } from '@fto/lib/ft_types/common/BasicClasses/SymbolInfo'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TSymbolList } from '@fto/lib/ft_types/data/SymbolList'
import { INamed } from '@fto/lib/utils/INamed'

export default class MockSymbolList extends TSymbolList {
    constructor() {
        super()
        this.DName = `MockSymbolList_${Math.random().toString()}`
    }

    DName: string

    public CreateSymbol(SymbolName: string, performSeek: boolean): TSymbolData {
        const symbolInfo = new TSymbolInfo(SymbolName)

        const result = new TSymbolData(symbolInfo)

        this.AddNewSymbol(result, performSeek)
        return result
    }
}
