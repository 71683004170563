import StrangeError from '../common/common_errors/StrangeError'

export class TList<T> extends Array<T> {
    public Add(item: T): void {
        this.push(item)
    }

    public Remove(item: T): boolean {
        const index = this.indexOf(item)
        if (index > -1) {
            this.splice(index, 1)
            return true
        }
        return false
    }

    //removes item at index
    private RemoveAt(index: number): void {
        this.throwIfIndexOutOfBounds(index)
        this.splice(index, 1)
    }

    private throwIfIndexOutOfBounds(index: number) {
        if (index < 0 || index >= this.length) {
            throw new StrangeError(`TList<T> Index out of bounds ${index}`)
        }
    }

    public Delete(index: number): void {
        this.throwIfIndexOutOfBounds(index)
        this.splice(index, 1)
    }

    public GetItem(index: number): T {
        this.throwIfIndexOutOfBounds(index)
        return this[index]
    }

    public SetItem(index: number, item: T): void {
        this.throwIfIndexOutOfBounds(index)
        this[index] = item
    }

    public Insert(index: number, item: T): void {
        if (index < 0 || index > this.length) {
            throw new StrangeError('TList<T>.Insert Index out of bounds')
        }
        this.splice(index, 0, item)
    }

    public IndexOf(item: T): number {
        return super.indexOf(item)
    }

    public IndexValid(index: number): boolean {
        return this.length > 0 && index >= 0 && index < this.length
    }

    public get Count(): number {
        return this.length
    }

    //syntax sugar
    public get count(): number {
        return this.Count
    }

    public Clear(): void {
        this.length = 0
    }

    public Contains(item: T): boolean {
        return this.indexOf(item) !== -1
    }

    public ToArray(): T[] {
        return this.slice()
    }

    public ForEach(callback: (item: T, index: number, array: T[]) => void): void {
        super.forEach(callback)
    }

    public Sort(compareFn?: (a: T, b: T) => number): this {
        super.sort(compareFn)
        return this
    }

    public Find(predicate: (value: T, index: number, obj: T[]) => boolean, theArg?: any): T | undefined {
        return super.find(predicate, theArg)
    }

    public FindIndex(predicate: (value: T, index: number, obj: T[]) => boolean, theArg?: any): number {
        return super.findIndex(predicate, theArg)
    }

    public Exchange(a: number, b: number): void {
        if (a < 0 || a >= this.length || b < 0 || b >= this.length) {
            throw new StrangeError(`TList<T>.Exchange, Index out of bounds ${a} ${b}`)
        }
        const temp = this[a]
        this[a] = this[b]
        this[b] = temp
    }

    public Extract(item: T): T | undefined {
        const index = this.indexOf(item)
        if (index > -1) {
            return this.splice(index, 1)[0]
        }
        return undefined
    }

    public AddAndGetIndex(item: T): number {
        this.push(item)
        return this.length - 1
    }
}
