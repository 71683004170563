import { FC } from 'react'

import cx from 'classnames'

import { Tooltip } from '@fto/ui'

import InnerDropdownButton from '../InnerDropdownButton'
import InnerDropdownMenu from '../InnerDropdownMenu'
import { RibbonInnerDropdownType } from '../types'

import styles from '../../../../common.module.scss'

type Props = {
    dropdown: RibbonInnerDropdownType
    close: () => void
    withDropdownsSearch?: boolean
}

const InnerDropdown: FC<Props> = ({ dropdown, close, withDropdownsSearch }) => {
    if (dropdown.options.length === 0) {
        return <InnerDropdownButton name={dropdown.name} icon={dropdown.icon} />
    }
    return (
        <Tooltip
            color='light'
            classNames={{ tooltip: cx(styles.dropdownTooltip, styles.tooltip) }}
            content={
                <InnerDropdownMenu
                    name={dropdown.name}
                    close={close}
                    options={dropdown.options}
                    withDropdownsSearch={withDropdownsSearch}
                />
            }
            interactive
            arrow={false}
            placement='right-start'
            disableFocusListener
        >
            <InnerDropdownButton name={dropdown.name} icon={dropdown.icon} />
        </Tooltip>
    )
}
export default InnerDropdown
