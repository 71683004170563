import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { JumpToStrategy } from '@fto/lib/JumpTo/JumpToStrategies/JumpToStrategy'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class NextNewsJumpTo extends JumpToStrategy {
    constructor() {
        super()
    }

    public jump(): void {
        const news = GlobalNewsController.Instance.getFirstUpcomingNews()
        if (news) {
            const newsTimeInDT = DateUtils.fromUnixTimeSeconds(news.Time)

            const activechart = GlobalChartsController.Instance.getActiveChart()
            if (activechart) {
                const tickAfter = activechart.SymbolData.fTickData.GetTickAtDate(
                    newsTimeInDT,
                    TNoExactMatchBehavior.nemb_ReturnNearestHigher
                )

                if (tickAfter) {
                    import('@fto/lib/globals/GlobalTestingManager').then((module) => {
                        const GlobalTestingManager = module.default
                        GlobalTestingManager.TestingManager.RollTestingTo(tickAfter.DateTime, true)
                    })
                } else {
                    throw new StrangeError('No tick after news')
                }
            } else {
                throw new StrangeError('No active chart')
            }
        }
    }
}
