import { makeAutoObservable } from 'mobx'
class WatchlistStore {
    lastBarTime: number | null = null
    isWatchlistOpen: boolean = false
    symbols: string[] = []

    constructor() {
        makeAutoObservable(this)

        this.updateLastBarTime = this.updateLastBarTime.bind(this)
        this.updateIsWatchlistOpen = this.updateIsWatchlistOpen.bind(this)
        this.updateSymbols = this.updateSymbols.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateLastBarTime(lastBarTime: number) {
        this.lastBarTime = lastBarTime
    }

    updateIsWatchlistOpen(isWatchlistOpen: boolean) {
        this.isWatchlistOpen = isWatchlistOpen
    }

    updateSymbols(symbols: string[]) {
        this.symbols = symbols
    }

    resetStore() {
        this.lastBarTime = null
        this.isWatchlistOpen = false
        this.symbols = []
    }
}

export default new WatchlistStore()
