import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { IndicatorReference } from '@fto/lib/extension_modules/common/IndicatorReference'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import GlobalTimeframes from '@fto/lib/globals/GlobalTimeframes'
import { CreateCustomIndicatorStrategy } from '@fto/lib/extension_modules/startegies/CreateIndicatorStrategies/CreateCustomIndicatorStrategy'
import { CreateBuiltInIndicatorStrategy } from '@fto/lib/extension_modules/startegies/CreateIndicatorStrategies/CreateBuiltInIndicatorStrategy'
import { Strategy } from '@fto/lib/extension_modules/startegies/Strategy'
import DelphiBuiltInFunctions from '@fto/lib/delphi_compatibility/DelphiBuiltInFunctions'

class StrategyIndicator {
    indicator: TRuntimeIndicator
    referenceStrategies: Strategy[] = []

    constructor(indicator: TRuntimeIndicator) {
        this.indicator = indicator
    }
}

export default class GlobalIndicatorsInStrategiesManager {
    private static _instance: GlobalIndicatorsInStrategiesManager
    private _indicators: Map<string, StrategyIndicator> = new Map()

    public static get Instance(): GlobalIndicatorsInStrategiesManager {
        if (!GlobalIndicatorsInStrategiesManager._instance) {
            GlobalIndicatorsInStrategiesManager._instance = new GlobalIndicatorsInStrategiesManager()
        }

        return GlobalIndicatorsInStrategiesManager._instance
    }

    createIndicatorForStrategy(
        strategy: Strategy,
        symbolName: string,
        timeframe: number,
        indicatorFile: string,
        indicatorParams: string
    ): IndicatorReference {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)
        if (!symbolData) {
            throw new StrangeError(`Symbol ${symbolName} not found`)
        }

        const existingTimeframeIndex = GlobalTimeframes.Timeframes.GetIndexByTimeframe(timeframe)
        if (existingTimeframeIndex === -1) {
            throw new StrangeError(`Timeframe ${timeframe} not found`)
        }

        const indicatorId = `${symbolName}_${timeframe}_${indicatorFile}_${indicatorParams}`
        const existingIndicator = this._indicators.get(indicatorId)
        if (existingIndicator) {
            this.addReferenceStrategyToIndicatorIfNeed(indicatorId, strategy)
            return new IndicatorReference(indicatorId)
        }

        const isCustomIndicator = indicatorFile.endsWith('.ts')
        let runtimeIndicator: TRuntimeIndicator | null = null
        if (isCustomIndicator) {
            runtimeIndicator = new CreateCustomIndicatorStrategy().createIndicatorForStrategy(
                symbolData,
                timeframe,
                indicatorFile,
                indicatorParams
            )
        } else {
            runtimeIndicator = new CreateBuiltInIndicatorStrategy().createIndicatorForStrategy(
                symbolData,
                timeframe,
                indicatorFile,
                indicatorParams
            )
        }

        if (!runtimeIndicator) {
            throw new StrangeError(`Indicator ${indicatorFile} not found`)
        }

        this._indicators.set(indicatorId, new StrategyIndicator(runtimeIndicator))
        this.addReferenceStrategyToIndicatorIfNeed(indicatorId, strategy)
        return new IndicatorReference(indicatorId)
    }

    private addReferenceStrategyToIndicatorIfNeed(indicatorId: string, strategy: Strategy): void {
        const strategyIndicator: StrategyIndicator | undefined = this._indicators.get(indicatorId)
        if (!strategyIndicator) {
            throw new StrangeError(`Indicator ${indicatorId} not found`)
        }

        const referenceStrategy: Strategy | undefined = strategyIndicator.referenceStrategies.find(
            (referenceStrategy_local) => referenceStrategy_local === strategy
        )

        if (!referenceStrategy) {
            strategyIndicator.referenceStrategies.push(strategy)
        }
    }

    getIndicatorValue(indicator: IndicatorReference, barIndex_lastItem0_based: number, bufferIndex: number): number {
        const strategyIndicator: StrategyIndicator | undefined = this._indicators.get(indicator.getId())
        if (!strategyIndicator) {
            throw new StrangeError(`Indicator ${indicator.getId()} not found`)
        }
        if (strategyIndicator.indicator.getBars().IsIndexWithinTestingRange(barIndex_lastItem0_based)) {
            if (DelphiBuiltInFunctions.InRange(bufferIndex, 0, strategyIndicator.indicator.buffers.Count - 1)) {
                const reversedIndex =
                    strategyIndicator.indicator.Reverse_from_lastItem0_to_firstItem0(barIndex_lastItem0_based)
                const startIndexWithOffset = Math.max(
                    0,
                    reversedIndex - strategyIndicator.indicator.getBackOffsetForCalculation()
                )
                strategyIndicator.indicator.RecountValuesForTheRange(startIndexWithOffset, reversedIndex)
                return strategyIndicator.indicator.GetBufferValue(bufferIndex, reversedIndex)
            } else {
                throw new StrangeError(`Wrong buffer index: ${bufferIndex} for indicator ${indicator.getId()}`)
            }
        } else {
            throw new StrangeError(`Wrong index of bar: ${barIndex_lastItem0_based} for indicator ${indicator.getId()}`)
        }
    }

    private constructor() {}
}
