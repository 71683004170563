import { InputField, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useEffect, useMemo, useState } from 'react'
import styles from './modals.module.scss'
import { RENAME_PROJECT_ACTION } from '@root/store/projects/project.actions'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjects } from '@root/store/projects/projects.selector'
import { useTranslation } from 'react-i18next'

type Props = {
    projectId: string
}

export const RenameProjectModal: FC<Props> = ({ projectId }) => {
    const { t } = useTranslation()

    const projects = useAppSelector($getProjects)

    const projectData = useMemo(() => projects?.find((project) => project.Id === projectId), [projectId, projects])
    const initialName = useMemo(() => projectData?.ProjectName || '', [projectData])

    const [name, setName] = useState(initialName)
    const dispatch = useAppDispatch()

    const handleRename = async () => {
        if (projectData) {
            const response = await dispatch(
                RENAME_PROJECT_ACTION({
                    projectName: name,
                    id: projectId
                })
            )
            if (response.meta.requestStatus === 'fulfilled') {
                setName('')
                removeModal(MODAL_NAMES.projects.rename)
            }
        }
    }

    useEffect(() => {
        setName(initialName)
    }, [projectId])

    return (
        <Modal name={MODAL_NAMES.projects.rename} size='sm' withCloseIcon>
            <Modal.Header className={styles.line}>
                <Typography type='h4-semibold'>{t('dashboard.modals.projects.renameHeader')}</Typography>
            </Modal.Header>
            <Modal.Content className={styles.inputWrapper}>
                <InputField
                    label={<Typography color='gray-900'>{t('dashboard.modals.projects.newName')}</Typography>}
                    block
                    value={name}
                    onChange={setName}
                />
            </Modal.Content>
            <Modal.Controls
                cancelText={t('global.cancel')}
                submitText={t('dashboard.modals.projects.rename')}
                onClose={() => removeModal(MODAL_NAMES.projects.rename)}
                onSubmit={handleRename}
                disabled={name.length === 0}
            />
        </Modal>
    )
}
