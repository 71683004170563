import { TMyObjectList } from '../../common/Common'
import { TBasicDownloadTask } from './DownloadTasks/BasicDownloadTask'
import { TTaskStatus } from './DownloadRelatedEnums'
import IDataDownloader from './IDataDownloader'

export class TDataDownloader extends TMyObjectList<TBasicDownloadTask> implements IDataDownloader {
    constructor() {
        super()
    }

    IsMock = false

    public AddTaskIfNotInQueue(task: TBasicDownloadTask): void {
        if (!this.IsTaskInQueue(task)) {
            this.Add(task)
            //TODO: remove this, implement task prioritization and call UpdateTasks() preiodically
            this.UpdateTasks()
        }
    }

    private IsTaskInQueue(task: TBasicDownloadTask): boolean {
        for (let i = 0; i < this.count; i++) {
            if (this[i].IsSameAs(task)) {
                return true
            }
        }
        return false
    }

    public UpdateTasks(): void {
        for (let i = this.count - 1; i >= 0; i--) {
            const task = this[i] as TBasicDownloadTask

            //TODO: probably we need to restart the tasks that were completed with errors instead of deleting them
            if (task.status === TTaskStatus.ts_Completed || task.status === TTaskStatus.ts_Completed_WithError) {
                this.Delete(i) // Delete the task from the queue
                continue
            }

            if (task.status === TTaskStatus.ts_WaitingToStart && task.ReadyToStart()) {
                task.start()
            }
        }
    }
}
