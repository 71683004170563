import { EOperationType, OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'

export const ORDER_TYPE_TO_LOCAL_KEY_MAP = {
    [OrderType.MARKET]: 'orders.modal.tabs.market',
    [OrderType.LIMIT]: 'orders.modal.tabs.limit',
    [OrderType.STOP]: 'orders.modal.tabs.stop'
}
export const TABS = [
    { value: OrderType.MARKET, localeKey: ORDER_TYPE_TO_LOCAL_KEY_MAP[OrderType.MARKET] },
    { value: OrderType.LIMIT, localeKey: ORDER_TYPE_TO_LOCAL_KEY_MAP[OrderType.LIMIT] },
    { value: OrderType.STOP, localeKey: ORDER_TYPE_TO_LOCAL_KEY_MAP[OrderType.STOP] }
]

export const ORDER_TYPE_TABS = [
    { value: EOperationType.SELL, localeKey: 'orders.modal.tabs.sell' },
    { value: EOperationType.BUY, localeKey: 'orders.modal.tabs.buy' }
]
