import { Button, Flex, Typography } from '@fto/ui'
import { AppHeader } from '@root/components/AppHeader'
import { FC, useEffect } from 'react'
import styles from './blocked.module.scss'
import { Icon } from '@fto/icons'
import { useNavigate } from 'react-router-dom'
import { CHECKOUT_PAGE, makeRoute } from '@root/constants/routes'
import { fireMixpanelEvent } from '@root/utils/api'
import { useTranslation } from 'react-i18next'

export const Blocked: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'blocked' })
    }, [])

    const redirect = () => {
        navigate(makeRoute(CHECKOUT_PAGE) + '?trial=false')
    }

    return (
        <>
            <Flex direction='column' className={styles.container}>
                <AppHeader />
                <Flex
                    direction='column'
                    gap={16}
                    alignItems='center'
                    justifyContent='center'
                    className={styles.profileBody}
                >
                    <Flex direction='column' gap={24} alignItems='center' className={styles.content}>
                        <Icon name='bernard-6' size={140} />
                        <Typography type='h3-bold' align='center'>
                            {t('blocked.header')}
                        </Typography>
                        <Typography type='text-regular' color='gray-900' align='center'>
                            {t('blocked.text')}
                        </Typography>
                        <Button
                            block
                            label={t('profile.modals.renewSubscription')}
                            type='marketing'
                            onClick={redirect}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
