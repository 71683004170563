import { ProjectJSON } from '@fto/lib/ProjectAdapter/Types'

export class WorkerWrapper {
    private worker: Worker
    public busy: boolean = false
    private callback!: (message: MessageEvent, worker: WorkerWrapper) => void

    constructor(workerScriptUrl: string) {
        this.worker = new Worker(workerScriptUrl)
        this.worker.onmessage = this.handleMessage.bind(this)
    }

    executeTask(
        url: string,
        callback: (message: MessageEvent, worker: WorkerWrapper) => void,
        data?: ProjectJSON
    ): void {
        this.busy = true
        this.callback = callback
        this.worker.postMessage({ url, data })
    }

    public free() {
        this.busy = false
    }

    private handleMessage(message: MessageEvent): void {
        if (this.callback) {
            this.callback(message, this)
        }
    }

    isBusy(): boolean {
        return this.busy
    }
}
