import { FC } from 'react'

import styles from './index.module.scss'
import classNames from 'classnames'

type Props = {
    color: string
    handleOpen: () => void
    disabled?: boolean
}
const Selector: FC<Props> = ({ color, handleOpen, disabled }) => {
    return (
        <div className={classNames(styles.Selector, { [styles.Disabled]: disabled })} onClick={handleOpen}>
            <div className={styles.ColorView} style={{ background: color }} />
        </div>
    )
}

export default Selector
