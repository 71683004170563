import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { addModal, Button, Flex, Separator } from '@fto/ui'
import { JumpToController } from '@fto/lib/JumpTo/JumpToController'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { HISTORICAL_MOMENTS_DATES } from '@fto/lib/JumpTo/constants'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { DROPDOWN_OPTIONS } from '@root/pages/ChartPage/components/JumpToSettingsModal/constants'

import { RibbonInnerDropdownType, RibbonOptionType } from '../../../../types'
import InnerDropdown from '../../../../InnerDropdown'

import styles from '../../../../../index.module.scss'

type Props = {
    close: () => void
    dropdownVisibleOptions: typeof DROPDOWN_OPTIONS
    setDropdownVisibleOptions: Dispatch<SetStateAction<typeof DROPDOWN_OPTIONS>>
}

const AdditionalJumpToDropdownOptions: FC<Props> = ({ close, dropdownVisibleOptions, setDropdownVisibleOptions }) => {
    const { t } = useTranslation()

    const jumptoController = useMemo(() => JumpToController.Instance(), [])

    const historicalMomentsVisible = useMemo(() => {
        const foundItem = dropdownVisibleOptions.general.find(
            (item: { value: string; isShown: boolean }) => item.value === 'historicalMoments'
        )
        return foundItem ? foundItem.isShown : false
    }, [dropdownVisibleOptions])

    const sessionOptions = useMemo(
        () =>
            [
                {
                    value: 'asianOpen',
                    name: t('ribbon.jumpTo.asianOpen'),
                    action: () => jumptoController.jumpToAsianSessionOpen()
                },
                {
                    value: 'asianClose',
                    name: t('ribbon.jumpTo.asianClose'),
                    action: () => jumptoController.jumpToAsianSessionClose()
                },
                {
                    value: 'NYOpen',
                    name: t('ribbon.jumpTo.NYOpen'),
                    action: () => jumptoController.jumpToNewYorkSessionOpen()
                },
                {
                    value: 'NYClose',
                    name: t('ribbon.jumpTo.NYClose'),
                    action: () => jumptoController.jumpToNewYorkSessionClose()
                },
                {
                    value: 'londonOpen',
                    name: t('ribbon.jumpTo.londonOpen'),
                    action: () => jumptoController.jumpToLondonSessionOpen()
                },
                {
                    value: 'londonClose',
                    name: t('ribbon.jumpTo.londonClose'),
                    action: () => jumptoController.jumpToLondonSessionClose()
                },
                {
                    value: 'silverBullet',
                    name: t('ribbon.jumpTo.silver'),
                    action: () => jumptoController.jumpToSilverBulletSessionOpen()
                }
            ] as RibbonOptionType[],
        [jumptoController]
    )

    const filteredSessionOptions = useMemo(() => {
        return sessionOptions.filter((option) => {
            const foundOption = dropdownVisibleOptions.sessions.find(
                (visible: { value: string; isShown: boolean }) => visible.value === option.value && visible.isShown
            )
            return !!foundOption
        })
    }, [sessionOptions, dropdownVisibleOptions.sessions])

    const historicalMomentsOptions = useMemo(
        () =>
            [
                {
                    value: 'covidCrash',
                    name: t('ribbon.jumpTo.covidCrash'),
                    action: () =>
                        GlobalTestingManager.TestingManager.RollTestingTo(HISTORICAL_MOMENTS_DATES['Covid-19 crash'])
                },
                {
                    value: '2008Crash',
                    name: t('ribbon.jumpTo.2008Crash'),
                    action: () =>
                        GlobalTestingManager.TestingManager.RollTestingTo(
                            HISTORICAL_MOMENTS_DATES['2008 financial crisis']
                        )
                },
                {
                    value: 'SVBBancruptcy',
                    name: t('ribbon.jumpTo.SVBBancruptcy'),
                    action: () =>
                        GlobalTestingManager.TestingManager.RollTestingTo(HISTORICAL_MOMENTS_DATES['SVB bancruptcy'])
                },
                {
                    value: '2022Rates',
                    name: t('ribbon.jumpTo.2022Rates'),
                    action: () =>
                        GlobalTestingManager.TestingManager.RollTestingTo(
                            HISTORICAL_MOMENTS_DATES['2022 rates policy change']
                        )
                }
            ] as RibbonOptionType[],
        []
    )

    const sessionOptionsDropdown: RibbonInnerDropdownType = useMemo(
        () => ({
            value: 'nextSessions',
            name: t('ribbon.jumpTo.nextSessions'),
            options: filteredSessionOptions
        }),
        [filteredSessionOptions]
    )

    const historicalMomentsDropdown: RibbonInnerDropdownType = useMemo(
        () => ({
            value: 'historicalMoments',
            name: t('ribbon.jumpTo.historicalMoments'),
            options: historicalMomentsOptions
        }),
        [historicalMomentsOptions]
    )

    const handleOpenSettings = useCallback(() => {
        addModal(MODAL_NAMES.chart.jumpToSettings, { setDropdownVisibleOptions: setDropdownVisibleOptions })
        close()
    }, [close, setDropdownVisibleOptions])

    return (
        <Flex direction='column' gap={4}>
            {(historicalMomentsVisible || filteredSessionOptions.length > 0) && (
                <Separator width='95%' color='gray-400' />
            )}
            {filteredSessionOptions.length > 0 && <InnerDropdown dropdown={sessionOptionsDropdown} close={close} />}
            {historicalMomentsVisible && <InnerDropdown dropdown={historicalMomentsDropdown} close={close} />}
            <Separator width='95%' color='gray-400' />
            <Button
                block
                classNames={{ content: styles.buttonContent }}
                type='tetriatry-white'
                size='compact'
                label={t('global.settings')}
                onClick={handleOpenSettings}
            />
        </Flex>
    )
}

export default AdditionalJumpToDropdownOptions
