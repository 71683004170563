import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { DateUtils, TDateTime } from '../../../../delphi_compatibility/DateUtils'
import DelphiBuiltInFunctions from '../../../../delphi_compatibility/DelphiBuiltInFunctions'
import CommonConstants from '../../../common/CommonConstants'
import NoExactMatchError from '../../../common/NoExactMatchError'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import { TBasicChunk } from '../BasicChunk'
import { TChunkStatus, TNoExactMatchBehavior } from '../ChunkEnums'
import { DateIsAfterChunkEnd, DateIsBeforeChunkStart } from '../DateOutOfChunkBoundsErrors'
import { TDataFormat } from '@fto/lib/ft_types/data/DataEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { TDataRecordWithDate } from '../../DataClasses/TDataRecordWithDate'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'

export abstract class TDataChunk<T extends TDataRecordWithDate> extends TBasicChunk {
    protected _data: T[]
    protected _status: TChunkStatus
    private _loadingDataFormat: TDataFormat = TDataFormat.df_MSGPPACK
    protected _isLocked = false

    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: TDateTime) {
        super(aDataDescriptor, aFirstDate)
        this._data = []
        this._status = TChunkStatus.cs_Empty
    }

    public get LoadingDataFormat(): TDataFormat {
        return this._loadingDataFormat
    }

    public set LoadingDataFormat(value: TDataFormat) {
        this._loadingDataFormat = value
    }

    public get LastItem(): T {
        return this._data[this._data.length - 1]
    }

    public get FirstItem(): T {
        return this._data[0]
    }

    public get Status(): TChunkStatus {
        return this._status
    }

    public set Status(value: TChunkStatus) {
        this._status = value
    }

    public get IsLoadedOrLoading(): boolean {
        return (
            this._status === TChunkStatus.cs_Loaded ||
            this._status === TChunkStatus.cs_Building ||
            this._status === TChunkStatus.cs_InQueue
        )
    }

    public GetItemByGlobalIndex(globalIndex: number): T | null {
        const localIndex = globalIndex - this.FirstGlobalIndex

        return this.GetItemByLocalIndex(localIndex)
    }

    public GetItemByLocalIndex(localIndex: number): T | null {
        if (localIndex < 0 || localIndex >= this.Count) {
            return null
        }

        return this._data[localIndex]
    }

    public ApproximateGlobalIndexByDate(DateTime: TDateTime): number {
        const localIndex = Math.trunc(
            ((DateTime - this.FirstDate) / (this.LastPossibleDate - this.FirstDate)) * this.Count
        )
        return this.FirstGlobalIndex + localIndex
    }

    public ApproximateDateByGlobalIndex(globalIndex: number): TDateTime {
        if (
            this.Status === TChunkStatus.cs_Loaded &&
            globalIndex >= this.FirstGlobalIndex &&
            globalIndex <= this.LastGlobalIndex
        ) {
            throw new StrangeError(
                `Trying to approximate date by global index when the chunk is loaded, in this case we should take the date directly ${this.DName}, ${globalIndex}`
            )
        }
        if (this.Count === 0) {
            throw new StrangeError(
                `Trying to approximate date by global index when the chunk is empty ${this.DName}, ${globalIndex}`
            )
        }

        let oneBarSizeInDates

        if (this.Count === 1) {
            oneBarSizeInDates = this.LastPossibleDate - this.FirstDate
        }
        //there are more than 1 bar in the chunk
        oneBarSizeInDates = (this.LastPossibleDate - this.FirstDate) / (this.Count - 1)
        const indexDiff = globalIndex - this.FirstGlobalIndex
        const approximatedDate = this.FirstDate + oneBarSizeInDates * indexDiff
        return TimeframeUtils.GetPeriodStart(approximatedDate, this.DataDescriptor.timeframe)
    }

    public GetDateByGlobalIndex(globalIndex: number, approximationAllowed = true): number {
        const localIndex = globalIndex - this.FirstGlobalIndex

        // Check if the index is within the valid range
        if (!DelphiBuiltInFunctions.InRange(localIndex, 0, this.Count - 1)) {
            throw new StrangeError('Index out of range in GetDateByGlobalIndex')
        }

        // If the chunk status is not loaded, use the approximate date method
        if (this._status !== TChunkStatus.cs_Loaded) {
            if (approximationAllowed) {
                return this.ApproximateDateByGlobalIndex(globalIndex)
            } else {
                throw new DataNotDownloadedYetError(
                    'chunk is not loaded yet, cannot get exact date by global index - GetDateByGlobalIndex'
                )
            }
        }

        // Return the date from the dates array corresponding to the index
        return this._data[localIndex].DateTime
    }

    // private DateCorrespondsToGlobalIndex = (globalIndex: number, thisDate: TDateTime): boolean => {
    //   const localIndex = globalIndex - this.FirstGlobalIndex;

    //   let dateStart: TDateTime = this.fData[localIndex].DateTime;
    //   let dateEnd: TDateTime;

    //   if (localIndex === this.Count - 1) {
    //     dateEnd = this.LastPossibleDate;
    //   } else {
    //     dateEnd = this.fData[localIndex + 1].DateTime;
    //   }

    //   return (thisDate >= dateStart) && (thisDate < dateEnd);
    // };

    public GetGlobalIndexByDate(
        dateTime: TDateTime,
        approximationAllowed = true,
        noExactMatchBehavior: TNoExactMatchBehavior = TNoExactMatchBehavior.nemb_ThrowError
    ): number {
        if (!this.DateInside(dateTime)) {
            //TODO: shouldn't we throw an error here?
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                "Trying to access a date the doesn't belong to the chunk"
            )
            return CommonConstants.EMPTY_INDEX
        }

        // if there is no data loaded approximate index
        if (this._status !== TChunkStatus.cs_Loaded) {
            if (approximationAllowed) {
                return this.ApproximateGlobalIndexByDate(dateTime)
            } else {
                throw new DataNotDownloadedYetError('Data is not loaded yet - GetGlobalIndexByDate')
            }
        }

        return this.GetGlobalIndexByLocal(this.GetLocalIndexByDateBin(dateTime, noExactMatchBehavior))
    }

    protected GetGlobalIndexByLocal(localIndex: number): number {
        return this.FirstGlobalIndex + localIndex
    }

    protected GetDateByLocalIndex(localIndex: number): TDateTime {
        return this._data[localIndex].DateTime
    }

    protected FitIndex(index: number): number {
        return Math.max(0, Math.min(this.Count, index))
    }

    public GetLocalIndexByDateBin(
        dateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior = TNoExactMatchBehavior.nemb_ReturnNearestLower
    ): number {
        if (this.Count === 0) return CommonConstants.EMPTY_INDEX

        let startIndex = 0
        let endIndex = this.Count - 1

        if (DateUtils.AreEqual(dateTime, this.GetDateByLocalIndex(startIndex))) {
            return startIndex
        }

        if (DateUtils.AreEqual(dateTime, this.GetDateByLocalIndex(endIndex))) {
            return endIndex
        }

        //TODO: write tests for these edge cases
        if (dateTime < this.GetDateByLocalIndex(startIndex)) {
            //in this case the date is before the first date in the chunk
            switch (noExactMatchBehavior) {
                case TNoExactMatchBehavior.nemb_ReturnNearestLower: {
                    //the value we are looking for is likely located in the previous chunk
                    //TODO: check all the places that call this method and see if they will handle this error
                    throw new DateIsBeforeChunkStart(
                        'Date is before the first date in the chunk and we are looking for the nearest lower index.'
                    )
                }
                case TNoExactMatchBehavior.nemb_ReturnNearestHigher: {
                    return startIndex
                }
                case TNoExactMatchBehavior.nemb_ThrowError: {
                    throw new NoExactMatchError(
                        `Cannot find index for date ${DateUtils.DF(dateTime)} it is before the first date in the chunk`
                    )
                }
                default: {
                    throw new StrangeError('Unknown TNoExactMatchBehavior')
                }
            }
        }

        if (dateTime > this.GetDateByLocalIndex(endIndex)) {
            //in this case the date is after the last date in the chunk
            switch (noExactMatchBehavior) {
                case TNoExactMatchBehavior.nemb_ReturnNearestLower: {
                    return endIndex
                }
                case TNoExactMatchBehavior.nemb_ReturnNearestHigher: {
                    //TODO: check all the places that call this method and see if they will handle this error
                    throw new DateIsAfterChunkEnd(
                        `Date is after the last date in the chunk and we are looking for the nearest higher index. Date to find: ${DateUtils.DF(
                            dateTime
                        )} End date: ${DateUtils.DF(this.GetDateByLocalIndex(endIndex))}`
                    )
                }
                case TNoExactMatchBehavior.nemb_ThrowError: {
                    throw new NoExactMatchError(
                        `Cannot find index for date ${DateUtils.DF(dateTime)} it is after the last date in the chunk`
                    )
                }
                default: {
                    throw new StrangeError('Unknown TNoExactMatchBehavior')
                }
            }
        }

        while (startIndex <= endIndex) {
            const midindex = startIndex + Math.floor((endIndex - startIndex) / 2)
            const midDate = this.GetDateByLocalIndex(midindex)

            if (dateTime < midDate) {
                endIndex = midindex - 1
            } else if (dateTime > midDate) {
                startIndex = midindex + 1
            } else {
                // Found the exact match
                return midindex
            }
        }

        // See if start index or end index can be qualified as exact match
        // first get the closest date to the requested date
        const startDate = this.GetDateByLocalIndex(startIndex)
        const endDate = this.GetDateByLocalIndex(endIndex)
        const startDiff = Math.abs(startDate - dateTime)
        const endDiff = Math.abs(endDate - dateTime)
        if (startDiff < endDiff) {
            // Start index is closer to the requested date
            if (startDiff <= CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME) {
                // Start index is an exact match
                return startIndex
            } else if (endDiff <= CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME) {
                // End index is an exact match
                return endIndex
            }
        }

        switch (noExactMatchBehavior) {
            case TNoExactMatchBehavior.nemb_ReturnNearestLower: {
                // Return the nearest lower index, or 0 if dateTime is before the first element
                return Math.max(endIndex, 0)
            }
            case TNoExactMatchBehavior.nemb_ReturnNearestHigher: {
                // Return the nearest higher index, or the last index if dateTime is after the last element
                return Math.min(startIndex, this.Count - 1)
            }
            case TNoExactMatchBehavior.nemb_ThrowError: {
                throw new NoExactMatchError(`Cannot find index for date ${DateUtils.DF(dateTime)}`)
            }
            default: {
                throw new StrangeError('Unknown TNoExactMatchBehavior')
            }
        }
    }

    public GetItemByDateTime(
        dateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior = TNoExactMatchBehavior.nemb_ReturnNearestLower
    ): T | null {
        const index = this.GetLocalIndexByDateBin(dateTime, noExactMatchBehavior)
        if (index === CommonConstants.EMPTY_INDEX) {
            return null
        }

        return this._data[index]
    }

    protected get IsLocked(): boolean {
        return this._isLocked
    }

    public Lock(): void {
        this._isLocked = true
    }

    public Unlock(): void {
        this._isLocked = false
    }

    public ClearDataAndResetStatus(): void {
        if (this._isLocked) {
            DebugUtils.log(`Chunk is locked. Cannot clear data ${this.DName}`)
        } else {
            this._data = []
            this.Status = TChunkStatus.cs_Empty
        }
    }
}
