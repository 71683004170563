import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import GlobalIndicatorDescriptors from '@fto/lib/globals/GlobalIndicatorDescriptors'
import { IIndicatorOptionsStore } from '@fto/lib/charting/tool_storages/indicators/Interfaces/IIndicatorOptionsStore'
import { TIndicatorOption } from '@fto/lib/charting/tool_storages/indicators/Interfaces/TIndicatorOption'
import { useCallback, useEffect, useState } from 'react'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { findOption, updateOption } from '../utils'
import { TOptionType, TOptValue_Session, TOptValue_SessionsArray } from '@fto/lib/extension_modules/common/CommonTypes'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { RuntimeIndicatorFactory } from '@fto/lib/extension_modules/indicators/RuntimeIndicatorFactory'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

type Props = {
    options: any
    indicatorOptions: IIndicatorOptionsStore
    setSelectedTemplate: (value: string) => void
    setIndicatorOptionsWrapper: (cb: any, shouldTemplateReset: boolean) => void
}

const useIndicatorTemplate = ({
    options,
    indicatorOptions,
    setSelectedTemplate,
    setIndicatorOptionsWrapper
}: Props) => {
    const [templates, setTemplates] = useState<string[]>([])

    const updateTemplates = useCallback(() => {
        if (!indicatorOptions.indicatorInstance) {
            throw new StrangeError('Indicator instance not found')
        }
        setTemplates(
            GlobalTemplatesManager.Instance.getIndicatorTemplatesNames(indicatorOptions.indicatorInstance.ShortName)
        )
    }, [indicatorOptions])

    const applyDefault = useCallback(() => {
        const { indicatorInstance } = indicatorOptions
        if (!indicatorInstance) throw new StrangeError('Indicator instance not found')

        const indicatorDescriptor = GlobalIndicatorDescriptors.BuiltInIndicators.findByName(indicatorInstance.ShortName)
        if (indicatorDescriptor === null) {
            throw new StrangeError(`Indicator descriptor not found for ${indicatorInstance.ShortName}`)
        }

        const runtimeIndicator = RuntimeIndicatorFactory.CreateRuntimeIndicator(
            indicatorDescriptor,
            indicatorInstance.getSymbolData(),
            indicatorInstance?.timeframe,
            TChartType.ct_Normal
        )

        runtimeIndicator.Init()
        runtimeIndicator.GetImportantOptions()

        const saveIndicator = indicatorInstance
        runtimeIndicator.ExportData(true)

        setIndicatorOptionsWrapper((prevData: IIndicatorOptionsStore) => {
            return {
                ...prevData,
                indicatorInstance: saveIndicator
            }
        }, true)
    }, [indicatorOptions, setIndicatorOptionsWrapper])

    const saveTemplate = useCallback(
        async (templateName = 'Template 1', isRewrite = false) => {
            const indicatorInstance = indicatorOptions.indicatorInstance?.ShortName
            if (!indicatorInstance) return

            const template: any = {
                style: {},
                levels: {},
                sessions: {
                    default: [],
                    custom: []
                }
            }

            if (options.levels) {
                const levels = Object.values(options.levels[1].fvalue) || {}
                for (const k in levels) {
                    const level: any = levels[k]
                    template.levels[k] = {
                        id: level.id,
                        checked: level.isActive,
                        color: level.color,
                        opacity: level.opacity,
                        style: level.style,
                        width: level.width,
                        value: level.value,
                        text: level.text
                    }
                }
            }

            if (options.sessions) {
                for (let sessionRow of options.sessions) {
                    const [_, sessionBody] = sessionRow as [string, any]
                    const isCustom = sessionBody.type === TOptionType.ot_SessionsArray
                    const pushTo = isCustom ? template.sessions.custom : template.sessions.default
                    let sessionsList: Array<{ [key: string]: any }> = isCustom
                        ? [...sessionBody.fvalue.sessions]
                        : [sessionBody.fvalue]

                    for (let session of sessionsList) {
                        pushTo.push({
                            name: session.name,
                            checked: session.isEnabled,
                            color: session.color,
                            startTime: session.startTime,
                            endTime: session.endTime
                        })
                    }
                }
            }

            for (let optionKey in options.style) {
                const [key, option] = options.style[optionKey]

                if (key === 'indicatorInstance') continue

                switch (parseInt(option.type)) {
                    case TOptionType.ot_Integer:
                    case TOptionType.ot_double: {
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue,
                            min: option.LowValue,
                            max: option.HighValue
                        }
                        break
                    }
                    case TOptionType.ot_String:
                    case TOptionType.ot_Longword:
                    case TOptionType.ot_Boolean:
                    case TOptionType.ot_Timeframe:
                    case TOptionType.ot_Currency:
                    case TOptionType.ot_Indicator:
                    case TOptionType.ot_DateTime: {
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue
                        }
                        break
                    }

                    case TOptionType.ot_EnumType: {
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue,
                            values: option.values.map((value: any) => value)
                        }
                        break
                    }
                    case TOptionType.ot_Color: {
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: {
                                color: option.fvalue
                            }
                        }
                        break
                    }
                    case TOptionType.ot_LineStyle: {
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: {
                                checked: option.fvalue.isVisible,
                                color: option.fvalue.color,
                                pen: option.fvalue.pen,
                                style: option.fvalue.style,
                                width: option.fvalue.width
                            }
                        }
                        break
                    }

                    default: {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Unknown option type')
                    }
                }
            }

            if (!indicatorOptions.indicatorInstance) {
                throw new StrangeError('Indicator instance not found')
            }

            await GlobalTemplatesManager.Instance.addIndicatorTemplate(
                templateName,
                indicatorOptions.indicatorInstance.ShortName,
                JSON.stringify(template),
                isRewrite
            )

            updateTemplates()
            setSelectedTemplate(templateName)
        },
        [indicatorOptions, options, updateTemplates, setSelectedTemplate]
    )

    const deleteTemplate = useCallback(
        async (templateName: string) => {
            if (!indicatorOptions.indicatorInstance) {
                throw new StrangeError('Indicator instance not found')
            }
            await GlobalTemplatesManager.Instance.removeIndicatorTemplate(
                indicatorOptions.indicatorInstance.ShortName,
                templateName
            )
            updateTemplates()
        },
        [indicatorOptions, updateTemplates]
    )

    const selectTemplate = useCallback(
        (templateName: string) => {
            if (!indicatorOptions.indicatorInstance) {
                throw new StrangeError('Indicator instance not found')
            }
            const template = GlobalTemplatesManager.Instance.getIndicatorTemplate(
                indicatorOptions.indicatorInstance.ShortName,
                templateName
            )
            if (!template) throw new StrangeError('Template not found')

            const parsedTemplate = JSON.parse(template.templateJSON)

            if (!parsedTemplate) throw new StrangeError('Template format is incorrect')

            setIndicatorOptionsWrapper((prevData: IIndicatorOptionsStore) => {
                let newOptions = { ...prevData }

                for (let dataKey in Object.entries(prevData)) {
                    const [key, value] = Object.entries(prevData)[dataKey]

                    if (key === 'indicatorInstance') continue

                    const newOption = findOption(parsedTemplate.style, (value as TIndicatorOption).alias)

                    if (!newOption) continue

                    newOptions = {
                        ...newOptions,
                        [key]: updateOption(value as TIndicatorOption, newOption)
                    }
                }

                for (let dataKey in Object.entries(newOptions)) {
                    const [key, value] = Object.entries(prevData)[dataKey]

                    if (value instanceof TRuntimeIndicator) continue
                    if (value.alias !== 'Levels') continue

                    const levels = Object.values(parsedTemplate.levels).reduce((acc: any, item: any, index: number) => {
                        acc.push({
                            id: item.id,
                            isActive: item.checked,
                            opacity: item.opacity,
                            color: item.color,
                            style: item.style,
                            width: item.width,
                            value: item.value,
                            text: item.text
                        })
                        return acc
                    }, [])

                    newOptions = {
                        ...newOptions,
                        [key]: {
                            ...value,
                            fvalue: levels
                        }
                    }
                }

                for (let dataKey in Object.entries(newOptions)) {
                    const [key, value] = Object.entries(prevData)[dataKey]

                    if (value instanceof TRuntimeIndicator) continue
                    if (value.type !== TOptionType.ot_Session) continue

                    const session = parsedTemplate.sessions.default.find((session: any) => session.name === value.alias)

                    newOptions = {
                        ...newOptions,
                        [key]: {
                            ...value,
                            fvalue: new TOptValue_Session(
                                session.checked,
                                session.name,
                                session.startTime,
                                session.endTime,
                                session.color
                            )
                        }
                    }
                }

                for (let dataKey in Object.entries(newOptions)) {
                    const [key, value] = Object.entries(prevData)[dataKey]

                    if (value instanceof TRuntimeIndicator) continue
                    if (value.type !== TOptionType.ot_SessionsArray) continue

                    const sessions = parsedTemplate.sessions.custom.reduce((acc: any, item: any, index: number) => {
                        acc.push(
                            new TOptValue_Session(item.checked, item.name, item.startTime, item.endTime, item.color)
                        )
                        return acc
                    }, [])

                    newOptions = {
                        ...newOptions,
                        [key]: {
                            ...value,
                            fvalue: new TOptValue_SessionsArray(sessions)
                        }
                    }
                }

                return newOptions
            }, false)

            setSelectedTemplate(templateName)
        },
        [indicatorOptions, setIndicatorOptionsWrapper, setSelectedTemplate]
    )

    useEffect(() => {
        updateTemplates()
    }, [])

    return {
        templates,
        applyDefault,
        saveTemplate,
        deleteTemplate,
        selectTemplate
    }
}

export default useIndicatorTemplate
