import { FC, useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Modal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppDispatch } from '@root/hooks/useStoreHook'

import { NOT_SATISFIED_REASONS, PAY_PRO_REASONS_IDS, PERIODS, REASONS } from './components/ReasonDefinition/constants'

import { fireMixpanelEvent, suspendSubscription } from '@root/utils/api'
import { LOOKUP } from '@root/store/auth/auth.actions'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'
import { showErrorToast } from '@root/utils/toasts'

import { ReasonDataType } from './components/ReasonDefinition/types'
import ReasonDefinition from './components/ReasonDefinition'
import ExclusiveOffer from './components/ExclusiveOffer'
import SuspendAnyway from './components/SuspendAnyway'
import { FeedbackType } from './types'

import styles from './index.module.scss'

type Props = {
    chargeDate: Date | null
    subscriptionId: number
    subscriptionStatus: 'monthly' | 'annual' | 'lifetime' | 'Unknown'
}

export const SuspendSubscriptionModal: FC<Props> = ({ chargeDate, subscriptionStatus, subscriptionId }) => {
    useEffect(() => {
        fireMixpanelEvent('subscription_suspend_modal_view', { subscription_type: subscriptionStatus })
    }, [])

    const [suspendData, setSuspendData] = useState<ReasonDataType>({
        reason: '',
        data: {
            [REASONS.priceToHigh]: { price: '', period: PERIODS.month },
            [REASONS.noNeed]: {
                comment: ''
            },
            [REASONS.notSatisfied]: { reason: NOT_SATISFIED_REASONS.toBaggy, comment: '' },
            [REASONS.switch]: { productName: '' },
            [REASONS.other]: {
                comment: ''
            }
        }
    })

    const [step, setStep] = useState(1)

    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const cancelSubscription = useCallback(() => {
        if (suspendData.reason) {
            suspendSubscription({
                subscriptionId: subscriptionId,
                cancellationReasonId: PAY_PRO_REASONS_IDS[suspendData.reason],
                feedback: suspendData.data[suspendData.reason] as FeedbackType
            })
                .then(() => {
                    dispatch(LOOKUP())
                    fireGoogleTagManagerEvent('cancel_subscription', {
                        reason: suspendData.reason
                    })
                })
                .catch((e) => showErrorToast({ message: t('auth.errors.somethingWentWrong') }))

            removeModal(MODAL_NAMES.userProfile.suspendSubscription)
        }
    }, [subscriptionId, suspendData])

    const isFirstStep = step === 1

    return (
        <Modal
            name={MODAL_NAMES.userProfile.suspendSubscription}
            size='sm'
            withClickOutside
            additionalClassNames={{ container: cx({ [styles.Container]: !isFirstStep }) }}
        >
            {isFirstStep && (
                <ReasonDefinition
                    suspendData={suspendData}
                    setSuspendData={setSuspendData}
                    setStep={setStep}
                    subscriptionStatus={subscriptionStatus}
                />
            )}
            {step === 2 && (
                <ExclusiveOffer
                    subscriptionId={subscriptionId}
                    setStep={setStep}
                    subscriptionStatus={subscriptionStatus}
                />
            )}
            {step === 3 && (
                <SuspendAnyway
                    chargeDate={chargeDate}
                    subscriptionStatus={subscriptionStatus}
                    cancelSubscription={cancelSubscription}
                />
            )}
        </Modal>
    )
}

// Contact Support = tawk to
// Exclusive Offer = BE api
