import { Button, Flex, InputField, Modal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback, useState } from 'react'

import styles from './index.module.scss'
import { t } from 'i18next'

type Props = {
    templatesList: string[]
    name?: string
    onSaved?: (name: string) => void
    onReplace?: (name: string) => void
    onCanceled?: () => void
}

export const SaveTemplateModal: FC<Props> = ({ templatesList, name = '', onSaved, onReplace, onCanceled }) => {
    const [nameState, setNameState] = useState<string>(name)

    const apply = useCallback(() => {
        if (!templatesList.includes(nameState)) {
            onSaved && onSaved(nameState)
            return
        }

        onReplace && onReplace(nameState)
    }, [templatesList, nameState, onSaved])

    return (
        <Modal
            size='sm'
            name={MODAL_NAMES.chart.saveTemplate}
            withCloseIcon
            additionalClassNames={{ container: styles.Container }}
            withClickOutside={false}
        >
            <Modal.Header withBorderBottom>
                <Typography>{t('templates.saveTemplateModal.title')}</Typography>
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <InputField
                        value={nameState}
                        label={t('templates.saveTemplateModal.inputTitle')}
                        onChange={setNameState}
                        block
                    />
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.Controls}>
                <div className={styles.Grid}>
                    <Button type='secondary' label={t('global.cancel')} onClick={onCanceled} block />
                    <Button type='primary' label={t('templates.saveTemplateModal.submit')} onClick={apply} block />
                </div>
            </Modal.Controls>
        </Modal>
    )
}
