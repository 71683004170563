import { StoplossValues, MarketValues, OrderValues } from '../OrderWndStructs'
import { StoplossCalculationStrategy } from './StoplossCalculationStrategy'

export class BuyLimitStoplossCalculation extends StoplossCalculationStrategy {
    onChangeStoplossPrice(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...stoplossValues }
        const scale = Math.pow(10, marketValues.postDecimalDigits)
        updatedValues.price = Math.round(updatedValues.price * scale) / scale

        const newPoints = Math.round((orderValues.atPrice - updatedValues.price) / marketValues.minimumPriceChange)
        if (newPoints !== stoplossValues.points) {
            updatedValues.points = newPoints
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    onChangeStoplossPoints(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...stoplossValues }
        updatedValues.points = Math.round(stoplossValues.points)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    onChangeStoplossInUsd(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...stoplossValues }
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        if (updatedPoints !== stoplossValues.points) {
            updatedValues.points = updatedPoints
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    onChangeStoplossInPercent(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...stoplossValues }
        updatedValues.usd = marketValues.equity * (updatedValues.percent / 100)
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        if (updatedPoints !== stoplossValues.points) {
            updatedValues.points = updatedPoints
            updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
            updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
            updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

            return updatedValues
        }

        return null
    }

    recalculateStoplossValuesByPoints(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...currStoplossValues }
        updatedValues.points = Math.round(currStoplossValues.points)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateStoplossValuesByPrice(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...currStoplossValues }
        const scale = Math.pow(10, marketValues.postDecimalDigits)
        updatedValues.price = Math.round(updatedValues.price * scale) / scale

        const newPoints = Math.round((orderValues.atPrice - updatedValues.price) / marketValues.minimumPriceChange)
        updatedValues.points = newPoints
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateStoplossValuesByUsd(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...currStoplossValues }
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        updatedValues.points = updatedPoints
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }

    recalculateStoplossValuesByPercent(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null {
        const updatedValues = { ...currStoplossValues }
        updatedValues.usd = marketValues.equity * (updatedValues.percent / 100)
        const updatedPoints = Math.floor(
            updatedValues.usd / (orderValues.lot * marketValues.pointValueForOneStandardLot)
        )
        updatedValues.points = updatedPoints
        updatedValues.price = orderValues.atPrice - updatedValues.points * marketValues.minimumPriceChange
        updatedValues.usd = updatedValues.points * (orderValues.lot * marketValues.pointValueForOneStandardLot)
        updatedValues.percent = (updatedValues.usd / marketValues.equity) * 100

        return updatedValues
    }
}
