import { StoreDataType } from '@fto/lib/store/ordersStore/types'

export const initialData: StoreDataType = {
    orders: {
        open: [],
        history: [],
        pending: []
    },
    balance: 0,
    equity: 0,
    pnl: 0,
    selectedPositionsIds: []
}
