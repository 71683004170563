import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { addModal, Button, Flex, Separator, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import styles from '../common.module.scss'
import { getMarketValuesFromChart } from '@fto/lib/store/createOrder/helper'
import { EOperationType, MarketValues, OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { PendingLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PendingLevel'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import CreateOrderStore from '@fto/lib/store/createOrder'
import { StopLossLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/StopLossLevel'
import { TakeProfitLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/TakeProfitLevel'
import { TTradePos } from '@fto/lib/processing_core/TradePositionClasses/TradePosition'
import OneClickTradingStore from '@fto/lib/store/oneClickTradingStore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'

type Props = {
    chart: TChartWindow
    removeMenu: () => void
    setShowSettings: (value: boolean) => void
}

const ChartMenuContent: FC<Props> = observer((props) => {
    const { chart, removeMenu, setShowSettings } = props
    const { t } = useTranslation()

    const [autoFollow, setAutoFollow] = useState(chart.ChartOptions.AutoScroll)
    const handleNewOrder = useCallback(() => {
        addModal(MODAL_NAMES.chart.orderModal, { source: 'right_click_menu' })
        removeMenu()
    }, [removeMenu])

    const addNote = useCallback(() => {
        removeMenu()
        GlobalChartsController.Instance.registerPaintTool('Text')
    }, [removeMenu])

    const removeDrawings = useCallback(() => {
        chart.DeleteAllTools()
        removeMenu()
    }, [chart, removeMenu])

    const showChartSettings = useCallback(() => {
        setShowSettings(true)
        removeMenu()
    }, [removeMenu])

    const toggleAutoFollow = useCallback(() => {
        chart.ChartOptions.AutoScroll = !autoFollow
        setAutoFollow(!autoFollow)
    }, [autoFollow, chart])

    const handlePaste = useCallback(() => {
        chart.pastePaintTools()
        removeMenu()
    }, [chart, removeMenu])

    return (
        <Flex direction='column' gap={4} className={styles.content}>
            <Button
                before={<Icon name='menu-new-order' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.newOrder')}
                onClick={handleNewOrder}
                block
            />
            <OrdersManipulations {...props} />
            <Separator width='90%' />
            <Button
                classNames={{ content: styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.paste')}
                onClick={handlePaste}
                block
            />
            <div className={styles.separator} />
            <Button
                before={<Icon name='menu-note' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.addNote')}
                onClick={addNote}
                block
            />
            <Separator width='90%' />
            <Button
                classNames={{ content: styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.removeDrawings')}
                onClick={removeDrawings}
                block
            />
            <Separator width='90%' />
            <Button
                before={autoFollow && <Icon color={'var(--color-gray-900)'} name='check' size={16} />}
                classNames={{ content: autoFollow ? styles.text : styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={
                    <Typography type={autoFollow ? 'interface-medium' : 'interface-regular'}>
                        {t('contextMenu.autoFollow')}
                    </Typography>
                }
                onClick={toggleAutoFollow}
                block
            />
            <Separator width='90%' />
            <Button
                before={<Icon name='settings' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.chartSettings')}
                onClick={showChartSettings}
                block
            />
            <div className={styles.separator} />
        </Flex>
    )
})

const OrdersManipulations: FC<Props> = observer(({ chart, removeMenu }) => {
    const { placeLimitOrderInstant, placeMarketOrderWithLimitOrderInstant } = CreateOrderStore
    const {
        data: { lot }
    } = OneClickTradingStore

    const lotLabel = `${lot}`

    const price = chart.getPriceOfCurrentMousePosition()
    const priceLabel = chart.SymbolData.FormatPriceToStr(price)
    const marketValues = getMarketValuesFromChart(chart, new MarketValues())
    const selectedPositions = getSelectedPositions(chart)
    const { t } = useTranslation()

    const belowBid = price < marketValues.bid
    const aboveAsk = price > marketValues.ask
    const inSpreadZone = price >= marketValues.bid && price <= marketValues.ask

    const handleBuyWithStopLoss = useCallback(() => {
        placeMarketOrderWithLimitOrderInstant(
            { operationType: EOperationType.BUY, lot: lot },
            {
                price: price,
                operationType: EOperationType.SELL
            }
        )
        removeMenu()
    }, [price, lot])

    const handleSellWithTakeProfit = useCallback(() => {
        placeMarketOrderWithLimitOrderInstant(
            { operationType: EOperationType.SELL, lot: lot },
            {
                price: price,
                operationType: EOperationType.BUY
            }
        )
        removeMenu()
    }, [price, lot])

    const handleSellWithStopLoss = useCallback(() => {
        placeMarketOrderWithLimitOrderInstant(
            { operationType: EOperationType.SELL, lot: lot },
            {
                price: price,
                operationType: EOperationType.BUY
            }
        )
        removeMenu()
    }, [price, lot])

    const handleBuyWithTakeProfit = useCallback(() => {
        placeMarketOrderWithLimitOrderInstant(
            { operationType: EOperationType.BUY, lot: lot },
            {
                price: price,
                operationType: EOperationType.SELL
            }
        )
        removeMenu()
    }, [price, lot])

    const handleBuyLimit = useCallback(() => {
        placeLimitOrderInstant(EOperationType.BUY, OrderType.LIMIT, price, lot)
        removeMenu()
    }, [price, lot])

    const handleSellStop = useCallback(() => {
        placeLimitOrderInstant(EOperationType.SELL, OrderType.STOP, price, lot)
        removeMenu()
    }, [price, lot])

    const handleSellLimit = useCallback(() => {
        placeLimitOrderInstant(EOperationType.SELL, OrderType.LIMIT, price, lot)
        removeMenu()
    }, [price, lot])

    const handleBuyStop = useCallback(() => {
        placeLimitOrderInstant(EOperationType.BUY, OrderType.STOP, price, lot)
        removeMenu()
    }, [price, lot])

    const handleMarketOrderMoveStopLoss = useCallback(
        (order: MarketLevel) => {
            const levelsOnChart = order.owner.orderLevels.get(chart)

            const stopLoss = levelsOnChart?.stopLossOrderLevel
            if (stopLoss && stopLoss instanceof StopLossLevel) {
                stopLoss.Capture()
                stopLoss.updLastCorrectValue()
                stopLoss.recalculateLocationByY(price)
                stopLoss.owner.tpos.StopLoss = price
                stopLoss.processNotValidLevel()
                stopLoss.ReleaseCapture()
                GlobalChartsController.Instance.updateCharts()
            }
            removeMenu()
        },
        [price, lot]
    )

    const handleMarketOrderMoveTakeProfit = useCallback(
        (order: MarketLevel) => {
            const levelsOnChart = order.owner.orderLevels.get(chart)

            const takepRofit = levelsOnChart?.takeProfitOrderLevel
            if (takepRofit && takepRofit instanceof TakeProfitLevel) {
                takepRofit.Capture()
                takepRofit.updLastCorrectValue()
                takepRofit.recalculateLocationByY(price)
                takepRofit.owner.tpos.TakeProfit = price
                takepRofit.processNotValidLevel()
                takepRofit.ReleaseCapture()
                GlobalChartsController.Instance.updateCharts()
            }
            removeMenu()
        },
        [price, lot]
    )

    const handlePendingOrderMoveEntryPrice = useCallback(
        (order: PendingLevel) => {
            order.Capture()
            order.updLastCorrectValue()
            order.recalculateLocationByY(price)
            order.owner.tpos.OpenPrice = price
            order.correctPriceIfNotValid()
            order.ReleaseCapture()
            GlobalChartsController.Instance.updateCharts()
            removeMenu()
        },
        [price, lot]
    )

    if (selectedPositions.length > 1) {
        return <></>
    } else if (selectedPositions.length === 1) {
        const pos = selectedPositions[0]
        const posLevels = pos.orderLevels.get(chart)
        if (!posLevels) return <></>

        const posOpenPrice = posLevels.openPriceOrderLevel

        if (posOpenPrice instanceof PendingLevel) {
            const orderNumber = pos.tpos.ticket
            return (
                <>
                    <Button
                        classNames={{ content: styles.text }}
                        before={<Icon name='move-limit' size={16} />}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.moveEntryPrice', { orderNumber, priceLabel })}
                        onClick={() => handlePendingOrderMoveEntryPrice(posOpenPrice)}
                        block
                    />
                </>
            )
        } else if (posOpenPrice instanceof MarketLevel) {
            const orderNumber = pos.tpos.ticket
            const orderPrice = posOpenPrice.owner.tpos.OpenPrice
            const isBuyOrder = posOpenPrice.owner.tpos.PosType === TTradePositionType.tp_Buy

            const getOrderButton = (label: string, action: () => void, icon: 'take-profit' | 'stop-loss') => (
                <Button
                    classNames={{ content: styles.text }}
                    before={<Icon name={icon} size={16} />}
                    size='tiny'
                    type='tetriatry-white'
                    label={label}
                    onClick={action}
                    block
                />
            )

            const moveStopLossLabel = t('contextMenu.moveSL', { orderNumber, priceLabel })
            const moveTakeProfitLabel = t('contextMenu.moveTP', { orderNumber, priceLabel })

            const isMoveStopLoss = isBuyOrder ? price < orderPrice : price > orderPrice
            const action = isMoveStopLoss
                ? () => handleMarketOrderMoveStopLoss(posOpenPrice)
                : () => handleMarketOrderMoveTakeProfit(posOpenPrice)

            const label = isMoveStopLoss ? moveStopLossLabel : moveTakeProfitLabel

            const icon = isMoveStopLoss ? 'stop-loss' : 'take-profit'

            return getOrderButton(label, action, icon)
        } else {
            return <></>
        }
    } else {
        const btnsToRender: React.JSX.Element[] = []

        if (aboveAsk) {
            btnsToRender.push(
                <Button
                    classNames={{ content: styles.text }}
                    before={<Icon name='buy-stop' size={16} />}
                    size='tiny'
                    type='tetriatry-white'
                    label={t('contextMenu.buyStop', { priceLabel, lot: lotLabel })}
                    onClick={handleBuyStop}
                    block
                />
            )
        } else {
            btnsToRender.push(
                <Button
                    classNames={{ content: styles.text }}
                    before={<Icon name='buy-limit' size={16} />}
                    size='tiny'
                    type='tetriatry-white'
                    label={t('contextMenu.buyLimit', { priceLabel, lot: lotLabel })}
                    onClick={handleBuyLimit}
                    block
                />
            )
        }

        if (belowBid) {
            btnsToRender.push(
                <Button
                    classNames={{ content: styles.text }}
                    before={<Icon name='sell-stop' size={16} />}
                    size='tiny'
                    type='tetriatry-white'
                    label={t('contextMenu.sellStop', { priceLabel, lot: lotLabel })}
                    onClick={handleSellStop}
                    block
                />
            )
        } else {
            btnsToRender.push(
                <Button
                    classNames={{ content: styles.text }}
                    before={<Icon name='sell-limit' size={16} />}
                    size='tiny'
                    type='tetriatry-white'
                    label={t('contextMenu.sellLimit', { priceLabel, lot: lotLabel })}
                    onClick={handleSellLimit}
                    block
                />
            )
        }

        if (belowBid && !inSpreadZone) {
            btnsToRender.push(
                <>
                    <Button
                        classNames={{ content: styles.text }}
                        before={<Icon name='stop-loss' size={16} />}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.buyWithStopLoss', { priceLabel, lot: lotLabel })}
                        onClick={handleBuyWithStopLoss}
                        block
                    />
                    <Button
                        classNames={{ content: styles.text }}
                        before={<Icon name='take-profit' size={16} />}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.sellWithTakeProfit', { priceLabel, lot: lotLabel })}
                        onClick={handleSellWithTakeProfit}
                        block
                    />
                </>
            )
        } else if (!belowBid && !inSpreadZone) {
            btnsToRender.push(
                <>
                    <Button
                        classNames={{ content: styles.text }}
                        before={<Icon name='stop-loss' size={16} />}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.sellWithStopLoss', { priceLabel, lot: lotLabel })}
                        onClick={handleSellWithStopLoss}
                        block
                    />
                    <Button
                        classNames={{ content: styles.text }}
                        before={<Icon name='take-profit' size={16} />}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.buyWithTakeProfit', { priceLabel, lot: lotLabel })}
                        onClick={handleBuyWithTakeProfit}
                        block
                    />
                </>
            )
        }

        return (
            <>
                {btnsToRender.map((btn, idx) => (
                    <React.Fragment key={idx}>{btn}</React.Fragment>
                ))}
            </>
        )
    }
})

function getSelectedPositions(chartWin: TChartWindow): TTradePos[] {
    const orders: TTradePos[] = []

    for (const pos of GlobalProcessingCore.ProcessingCore.OpenPositions) {
        const ordersOnCurrChart = pos.orderLevels.get(chartWin)
        if (!ordersOnCurrChart) continue

        if (
            ordersOnCurrChart.openPriceOrderLevel?.isSelected ||
            ordersOnCurrChart.stopLossOrderLevel?.isSelected ||
            ordersOnCurrChart.takeProfitOrderLevel?.isSelected
        ) {
            orders.push(pos)
        }
    }
    return orders
}

export default ChartMenuContent
