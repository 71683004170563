import { makeAutoObservable } from 'mobx'
import { ParamsType } from './types'
import { initialData } from './constants/initialData'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

class GraphToolPanelStore {
    params: ParamsType

    constructor() {
        makeAutoObservable(this)
        this.params = initialData

        this.updateParams = this.updateParams.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.toggleLockTools = this.toggleLockTools.bind(this)
        this.openEditModal = this.openEditModal.bind(this)
        this.removeGraphTools = this.removeGraphTools.bind(this)
        this.handleBringToFront = this.handleBringToFront.bind(this)
        this.handleSendToBack = this.handleSendToBack.bind(this)
        this.duplicateTools = this.duplicateTools.bind(this)
        this.copyTools = this.copyTools.bind(this)
        this.hideTools = this.hideTools.bind(this)
    }

    updateParams(updater: ((prevSettings: ParamsType) => ParamsType) | ParamsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.params = updater(this.params)
        } else {
            // If updater is a value, directly apply the updates
            this.params = updater
        }
    }

    resetStore() {
        this.updateParams((prevSettings) => ({
            ...initialData,
            initialCoords: prevSettings.initialCoords
        }))
    }

    toggleLockTools(isLocked: boolean, tools: any = []) {
        tools.forEach((tool: any) => (isLocked ? tool.unlock() : tool.lock()))
    }

    openEditModal() {}

    removeGraphTools() {}

    handleBringToFront() {}

    handleSendToBack() {}

    duplicateTools(chart: TChartWindow | null) {
        chart?.MainChart.PaintTools.DuplicateSelectedTools()
        GlobalChartsController.Instance.updateCharts()
        this.resetStore()
    }

    copyTools(chart: TChartWindow | null) {
        this.params.tools.forEach((tool) => {
            tool.Selected = true
        })
        chart?.copyPaintTools()
        GlobalChartsController.Instance.updateCharts()
        this.resetStore()
    }

    hideTools(chart: TChartWindow | null) {
        chart?.HideSelectedTools()
        GlobalChartsController.Instance.updateCharts()
        this.resetStore()
    }

    areToolsLocked() {
        return this.params.tools.every((tool) => !!tool?.fLocked)
    }

    bringToFront(chart: TChartWindow) {
        chart.MainChart.PaintTools.BringToFrontSelectedTools()
        this.resetStore()
    }

    sendToBack(chart: TChartWindow) {
        chart.MainChart.PaintTools.SendToBackSelectedTools()
        this.resetStore()
    }
}

export default new GraphToolPanelStore()
