import React, { FC, useMemo } from 'react'

import { EmptyState } from '@fto/ui'

import CreateProjectModalSymbolsGroup from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectModalSymbols/CreateProjectModalSymbolsGroup'

import { makeGroupedList } from '@root/mapper/symbols.mapper'

import { SymbolItemType } from '@root/types/symbols.types'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    debouncedValue: string
    symbols: SymbolItemType[]
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
}

const SymbolsList: FC<Props> = ({ debouncedValue, symbols, formData, setFormData }) => {
    const groupedSymbols = useMemo(() => makeGroupedList(symbols), [symbols])
    const { t } = useTranslation()

    const filteredSymbols = useMemo(() => {
        if (debouncedValue) {
            return groupedSymbols.map((group) => ({
                ...group,
                symbols: group.symbols.filter((symbol) =>
                    symbol.Symbol.toLowerCase().includes(debouncedValue.toLowerCase())
                )
            }))
        }

        return groupedSymbols
    }, [debouncedValue, groupedSymbols])

    const isSymbolsGroupsExist = useMemo(
        () => filteredSymbols.some((group) => group.symbols.length > 0),
        [filteredSymbols]
    )

    return (
        <div className={styles.symbolsList}>
            {isSymbolsGroupsExist ? (
                filteredSymbols.map((group) => (
                    <CreateProjectModalSymbolsGroup
                        key={group.category}
                        symbolsGroup={group}
                        formData={formData}
                        setFormData={setFormData}
                    />
                ))
            ) : (
                <EmptyState description={t('dashboard.modals.projects.noSymbols')} />
            )}
        </div>
    )
}

export default SymbolsList
