import { ComponentPropsWithoutRef } from 'react'
import * as React from 'react'

import cn from 'classnames'
import { FilenameTypes } from '../output/assets-types'

import { Icon } from './Icon'

import styles from './index.module.scss'

interface IconButtonProps {
    name: (typeof FilenameTypes)[number]
    size?: number
    color?: string | null
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    className?: string | null
    disabled?: boolean
}

type NativeProps = Omit<ComponentPropsWithoutRef<'button'>, keyof IconButtonProps>

type Props = NativeProps & IconButtonProps

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
    ({ name, size = 24, color = 'var(--color-gray-1000)', disabled, onClick, className = null, ...rest }, ref) => (
        <div>
            <button
                {...rest}
                ref={ref}
                type='button'
                className={cn(styles.ButtonIcon, className)}
                onClick={onClick}
                disabled={disabled}
            >
                <Icon name={name} size={size} color={color} />
            </button>
        </div>
    )
)
