import { t } from 'i18next'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { ProjectJSON, ProjectJSONfromServer } from '@fto/lib/ProjectAdapter/Types'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { secureApi } from '@root/utils/api'
import { showErrorToast } from '@root/utils/toasts'
import { AxiosError } from 'axios'
import ProjectStore from '@fto/lib/store/projectStore'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

export class SyncProjectManager {
    private static instance: SyncProjectManager
    private static TIME_TO_SAVE = 120000 // 2 minutes
    private intervalId: NodeJS.Timeout | null = null
    private attemptsToSave = 0
    /**
     * String is serialized ProjectJSON
     * @private
     */
    private lastSavedStateString: string | null = null
    private errorFlag = false
    private lastSaveTime = 0

    private constructor() {
        this.SaveProject = this.SaveProject.bind(this)
        this.DownloadProject = this.DownloadProject.bind(this)
    }

    public static get Instance(): SyncProjectManager {
        if (!SyncProjectManager.instance) {
            SyncProjectManager.instance = new SyncProjectManager()
        }

        return SyncProjectManager.instance
    }

    public async SaveProject() {
        if (this.errorFlag) {
            return
        }

        const path = `projects/api/Projects/${GlobalProjectJSONAdapter.Instance.projectId}`
        const { updateData } = ProjectStore

        while (this.attemptsToSave < 3) {
            try {
                updateData((prevData) => ({ ...prevData, saveState: 'saving' }))
                const projectJSON = await this.DownloadProject(GlobalProjectJSONAdapter.Instance.projectId)
                GlobalProjectJSONAdapter.Instance.setDownloadedJSON(projectJSON)

                const projectToSave = GlobalProjectJSONAdapter.Instance.getJSON()

                if (JSON.stringify(projectToSave, null, 2) === this.lastSavedStateString) {
                    this.lastSaveTime = Date.now()
                    updateData((prevData) => ({ ...prevData, saveState: 'ok' }))

                    return
                }

                await secureApi.put(path, projectToSave)

                this.lastSavedStateString = JSON.stringify(projectToSave, null, 2)

                updateData((prevData) => ({ ...prevData, saveState: 'ok' }))
                this.lastSaveTime = Date.now()

                this.attemptsToSave = 0
                return
            } catch (error) {
                this.attemptsToSave++
                await new Promise((resolve) => setTimeout(resolve, 2000)) // Wait 1 second before retrying
                const axiosError = error as AxiosError

                if (this.attemptsToSave < 3) {
                    continue
                }

                updateData((prevData) => ({ ...prevData, saveState: 'error' }))
                showErrorToast({
                    title: t('project.toasts.savingProjectError'),
                    message: t('project.toasts.savingProjectErrorMessage', {
                        count: Math.round(this.getTimeIntervalInMinutes())
                    })
                })

                this.attemptsToSave = 0

                if (axiosError.response) {
                    switch (axiosError.response.status) {
                        case 400: {
                            this.errorFlag = true
                            throw new StrangeError('Invalid project data provided.')
                        }
                        case 401: {
                            throw new StrangeError('Unauthorized access attempted.')
                        }
                        case 403: {
                            throw new StrangeError('Access to the project is denied.')
                        }
                        case 404: {
                            this.errorFlag = true
                            throw new StrangeError('The project with the provided ID was not found.')
                        }
                        case 409: {
                            throw new StrangeError('Project edit conflict. Please try again.')
                        }
                        default: {
                            throw new StrangeError(`An unexpected error occurred. ${axiosError.response.status}`)
                        }
                    }
                } else {
                    throw new StrangeError('Network error or no response from server.')
                }
            }
        }

        throw new StrangeError('Failed to save project after 3 attempts.')
    }

    private getTimeIntervalInMinutes(): number {
        return SyncProjectManager.TIME_TO_SAVE / 60000
    }

    public getLastSaveTime(): number {
        return this.lastSaveTime
    }

    public async DownloadProject(projectId: string | null = null): Promise<ProjectJSONfromServer> {
        if (!projectId) {
            projectId = GlobalProjectJSONAdapter.Instance.projectId
        }

        const path = `projects/api/Projects/${projectId}`

        try {
            const { data } = await secureApi.get<ProjectJSONfromServer>(path)
            return data
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError && axiosError.response) {
                switch (axiosError.response.status) {
                    case 400: {
                        this.errorFlag = true
                        throw new StrangeError('Invalid project ID.')
                    }
                    case 401: {
                        throw new StrangeError('Unauthorized. Please check your credentials.')
                    }
                    case 403: {
                        throw new StrangeError('Access denied to this project.')
                    }
                    case 404: {
                        this.errorFlag = true
                        throw new StrangeError('The project with the provided ID was not found.')
                    }
                    default: {
                        throw new StrangeError('An unexpected error occurred.')
                    }
                }
            } else {
                DebugUtils.error('Network error or no response from server:', axiosError)
                throw new StrangeError('Network error or no response from server.')
            }
        }
    }

    public destroy(): void {
        clearInterval(this.intervalId as NodeJS.Timeout)
        this.intervalId = null
    }

    public start(): void {
        if (!GlobalProjectJSONAdapter.Instance.projectName) {
            throw new StrangeError('Project name is empty')
        }

        if (!this.intervalId) {
            this.intervalId = setInterval(this.SaveProject, SyncProjectManager.TIME_TO_SAVE)
        }
    }

    public forceProjectSave(project: ProjectJSON) {
        const path = `projects/api/Projects/${GlobalProjectJSONAdapter.Instance.projectId}`

        secureApi
            .put<ProjectJSON>(path, project)
            .then(() => {
                DebugUtils.log('Project saved')
            })
            .catch((error) => {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to save project:', error)
            })
    }
}
