import { DateTransitionStrategy } from '@fto/lib/ft_types/data/chunks/DateTransitionStrategies/DateTransitionStrategy'
import { DateTransitionInfo, TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { TChunkStatus } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

export class HighTfDateTransitionStrategy extends DateTransitionStrategy {
    public updateTransitionsByPrevChunk(prevChunk: TBarChunk): void {
        if (
            this._ownerChunk.Status === TChunkStatus.cs_Loaded &&
            prevChunk.Status === TChunkStatus.cs_Loaded &&
            this._ownerChunk.FirstItem &&
            prevChunk.LastItem
        ) {
            const firstItemDT = GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
                this._ownerChunk.FirstItem.DateTime
            )
            const prevChunkLastItemDT =
                GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
                    prevChunk.LastItem.DateTime
                )

            const chunkStartMonth = DateUtils.MonthOf(firstItemDT)
            const prevChunkEndMonth = DateUtils.MonthOf(prevChunkLastItemDT)
            if (chunkStartMonth !== prevChunkEndMonth) {
                this._monthTransitionItems.unshift(
                    new DateTransitionInfo(firstItemDT, this._ownerChunk.FirstGlobalIndex)
                )
            }

            const chunkStartYear = DateUtils.YearOf(this._ownerChunk.FirstItem.DateTime)
            const prevChunkEndYear = DateUtils.YearOf(prevChunk.LastItem.DateTime)
            if (chunkStartYear !== prevChunkEndYear) {
                this._yearTransitionItems.unshift(
                    new DateTransitionInfo(this._ownerChunk.FirstItem.DateTime, this._ownerChunk.FirstGlobalIndex)
                )
            }

            const chunkStartDay = DateUtils.DayOfMonth(firstItemDT)
            const prevChunkEndDay = DateUtils.DayOfMonth(prevChunkLastItemDT)
            if (chunkStartDay !== prevChunkEndDay) {
                this._dayTransitionItems.unshift(new DateTransitionInfo(firstItemDT, this._ownerChunk.FirstGlobalIndex))
            }
        }
    }
    public calculateTransitionItems(): void {
        const firstIndex = this._ownerChunk.FirstGlobalIndex
        const lastIndex = this._ownerChunk.GetLastGlobalIndexInThisChunk()

        const correctedFirstDT = GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
            this._ownerChunk.FirstItem.DateTime
        )
        let currentYear = DateUtils.YearOf(this._ownerChunk.FirstItem.DateTime)
        let currentMonth = DateUtils.MonthOf(correctedFirstDT)
        let currentDay = DateUtils.DayOfMonth(correctedFirstDT)
        for (let i = firstIndex; i <= lastIndex; i++) {
            const record = this._ownerChunk.GetItemByGlobalIndex(i)
            if (record) {
                const correctedRecordDT =
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(record.DateTime)
                const recordYear = DateUtils.YearOf(record.DateTime)
                if (recordYear !== currentYear) {
                    this._yearTransitionItems.push(new DateTransitionInfo(record.DateTime, i))
                    currentYear = recordYear
                }

                const recordMonth = DateUtils.MonthOf(correctedRecordDT)
                if (recordMonth !== currentMonth) {
                    this._monthTransitionItems.push(new DateTransitionInfo(correctedRecordDT, i))
                    currentMonth = recordMonth
                }

                if (this._ownerChunk.DataDescriptor.timeframe <= 1440) {
                    const recordDay = DateUtils.DayOfMonth(correctedRecordDT)
                    if (recordDay !== currentDay) {
                        this._dayTransitionItems.push(new DateTransitionInfo(correctedRecordDT, i))
                        currentDay = recordDay
                    }
                }

                if (this._ownerChunk.DataDescriptor.timeframe < 1440) {
                    if (DateUtils.MinuteOf(correctedRecordDT) % 5 === 0) {
                        this._timeTransitionItems.push(new DateTransitionInfo(correctedRecordDT, i))
                    }
                }
            }
        }
    }
}
