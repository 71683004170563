import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TRect } from '../../extension_modules/common/CommonExternalInterface'
import { TGdiPlusCanvas } from '../../drawing_interface/GdiPlusCanvas'

export default class TPaintContext {
    canvasContext: CanvasRenderingContext2D
    NumberOfVisibleBars: number
    firstVisibleDate: TDateTime
    lastVisibleDate: TDateTime
    PaintRect: TRect
    GdiCanvas: TGdiPlusCanvas
    firstVisibleIndex: number
    lastVisibleIndex_excluding_emptyArea: number

    constructor(
        canvasContext: CanvasRenderingContext2D,
        NumberOfVisibleBars: number,
        firstVisibleDate: TDateTime,
        lastVisibleDate: TDateTime,
        PaintRect: TRect,
        GdiCanvas: TGdiPlusCanvas,
        firstVisibleIndex: number,
        lastVisibleIndex_excluding_emptyArea: number
    ) {
        this.canvasContext = canvasContext
        this.NumberOfVisibleBars = NumberOfVisibleBars
        this.firstVisibleDate = firstVisibleDate
        this.lastVisibleDate = lastVisibleDate
        this.PaintRect = PaintRect
        this.GdiCanvas = GdiCanvas
        this.firstVisibleIndex = firstVisibleIndex
        this.lastVisibleIndex_excluding_emptyArea = lastVisibleIndex_excluding_emptyArea
    }
}
