import { CreateIndicatorStarategy } from '@fto/lib/extension_modules/startegies/CreateIndicatorStrategies/CreateIndicatorStarategy'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { IndicatorDescriptor } from '@fto/lib/extension_modules/indicators/IndicatorDescriptor'
import { GlobalCustomIndicatorsManager } from '@fto/lib/globals/GlobalCustomIndicatorsManager'
import store from '@root/store'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'

export class CreateCustomIndicatorStrategy extends CreateIndicatorStarategy {
    createIndicatorForStrategy(
        symbolData: TSymbolData,
        timeframe: number,
        indicatorFile: string,
        indicatorParams: string
    ): TRuntimeIndicator {
        const indicatorDescriptor: IndicatorDescriptor = GlobalCustomIndicatorsManager.getInstance(
            store
        ).getIndicatorDescriptor('', indicatorFile)

        const runtimeIndicator = GlobalCustomIndicatorsManager.getInstance(store).createRuntimeIndicator(
            indicatorDescriptor,
            symbolData,
            timeframe,
            0
        )
        this.initRuntimeIndicator(runtimeIndicator, indicatorParams)
        return runtimeIndicator
    }
}
