import { Button, Flex, Modal, Typography, addModal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC } from 'react'

import { t } from 'i18next'
import { Icon } from '@fto/icons'
import styles from './index.module.scss'

type Props = {
    name: string
    onReplaced: (name: string, isRewrite: boolean) => void
    onCanceled: (name: string) => void
}

export const ReplaceTemplateModal: FC<Props> = ({ name, onReplaced, onCanceled }) => {
    return (
        <Modal
            size='xs'
            name={MODAL_NAMES.chart.replaceTemplate}
            withClickOutside={false}
            additionalClassNames={{
                container: styles.Container
            }}
        >
            <Modal.Content>
                <Flex direction='column' alignItems='center'>
                    <Icon name='restart-colored' size={56} />
                    <Typography type='h5-semibold' color='gray-1000' className={styles.Title}>
                        {t('templates.replaceTemplateModal.title')}
                    </Typography>
                    <Typography type='interface-regular' align='center' className={styles.Question}>
                        {t('templates.replaceTemplateModal.question', { name })}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.Controls}>
                <div className={styles.Grid}>
                    <Button
                        type='secondary'
                        label={t('global.cancel')}
                        onClick={() => {
                            onCanceled(name)
                        }}
                        block
                    />
                    <Button
                        type='primary'
                        label={t('templates.replaceTemplateModal.submit')}
                        onClick={() => onReplaced(name, true)}
                        block
                    />
                </div>
            </Modal.Controls>
        </Modal>
    )
}
