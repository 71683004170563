import { StrategyImplementation } from '@fto/lib/extension_modules/startegies/api/UserStrategy'
import {
    TInstantOrderType,
    EOrderSelectMode,
    TSearchMode
} from '@fto/lib/extension_modules/startegies/api/StrategyInterfaceUnit'
import { IndicatorReference } from '../../common/IndicatorReference'
import { BuiltInIndicatorFiles } from '../../indicators/built_in_indicators/list/BuiltInIndicatorsList'
import {
    TDateTime,
    TMAType,
    TOptionType,
    TOptValue_number,
    TOptValue_str,
    TPriceType
} from '@fto/lib/extension_modules/common/CommonTypes'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class TestStrategy extends StrategyImplementation {
    symbol: TOptValue_str = new TOptValue_str('EURUSD')
    timeframe: TOptValue_number = new TOptValue_number(15)

    suicheLargo!: TDateTime
    suicheCorto!: TDateTime
    suicheBuena: boolean = true

    // Operaciones
    lotaje: TOptValue_number = new TOptValue_number(0.01)
    magico: TOptValue_number = new TOptValue_number(27)

    awesome_periodF: TOptValue_number = new TOptValue_number(5)

    awesome_periodS: TOptValue_number = new TOptValue_number(34)
    awesome_maType: TOptValue_number = new TOptValue_number(TMAType.ma_SMA)
    awesome_applyToprice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    aoInd: IndicatorReference | undefined = undefined
    // awesome_Handle: Integer;
    // awesome_Lime: array [0 .. 3] of double;
    // awesome_Red: array [0 .. 3] of double;
    // awesome: array [0 .. 3] of double;

    awesome_Lime: number[] = [0, 0, 0, 0]
    awesome_Red: number[] = [0, 0, 0, 0]
    awesome: number[] = [0, 0, 0, 0]

    Init() {
        this.api.StrategyShortName('AwesomeEA')
        this.api.StrategyDescription(
            'Strategy based on the breakthrough of the zero level by the histogram of the Awesome Oscillator and the color of the bar forming'
        )
        // AddSeparator('Symbol and time frame');

        this.api.RegOption('Symbol', TOptionType.ot_String, this.symbol)
        this.api.RegOption('Timeframe', TOptionType.ot_Integer, this.timeframe)
        // AddSeparator('Trades');
        this.api.RegOption('Lot size', TOptionType.ot_double, this.lotaje)
        this.api.SetOptionRange('Lot size', 0.01, Number.MAX_VALUE)
        // RegOption('Magic number', ot_Integer, magico);
        this.api.RegOption('Magic number', TOptionType.ot_Integer, this.magico)

        // AddSeparator('AweSome');
        this.api.RegOption('Period F', TOptionType.ot_Integer, this.awesome_periodF)
        this.api.RegOption('Period S', TOptionType.ot_Integer, this.awesome_periodS)

        this.api.RegMATypeOption(this.awesome_maType)

        this.api.RegApplyToPriceOption(this.awesome_applyToprice, 'Apply to')

        this.aoInd = this.api.CreateIndicator(
            this.symbol.value,
            this.timeframe.value,
            BuiltInIndicatorFiles.AwesomeOsc,
            `${this.awesome_periodF.value};${this.awesome_periodS.value};Simple (SMA);Close`
        )
    }

    OnTick() {
        // debugger
        if (!this.suicheBuena) {
            return
        }

        if (!this.api.SetCurrencyAndTimeframe(this.symbol.value, this.timeframe.value)) {
            console.log('Error: not(SetCurrencyAndTimeframe(simbolo1, TimeFrame))')
            return
        }
        // if (bars < (awesome_periodS + 3)) then
        // Exit;
        if (this.api.Bars() < this.awesome_periodS.value + 3) {
            return
        }

        if (!this.LeerEntradas()) {
            return
        }

        this.CerrarLargo()
        this.CerrarCorto()
        this.AbrirLargo()
        this.AbrirCorto()
        //console.log('TestStrategy.OnTick')
        // Do nothing
    }

    Reset() {
        this.suicheBuena = false

        if (!this.api.SetCurrencyAndTimeframe(this.symbol.value, this.timeframe.value)) {
            console.log('Error: not(SetCurrencyAndTimeframe(simbolo1, TimeFrame))')
            return
        }

        this.api.CreateIndicator(
            this.symbol.value,
            this.timeframe.value,
            'AO',
            `${this.awesome_periodF};${this.awesome_periodS};${TMAType[this.awesome_maType.value]};${TPriceType[this.awesome_applyToprice.value]};true`
        )
        // awesome_Handle := CreateIndicator(symbol, TimeFrame, 'AO', InttoStr(awesome_periodF) + ';' + InttoStr(awesome_periodS) + ';' +
        //     string(StrMAType(TMAType(awesome_maType))) + ';' + string(StrPriceType(TPriceType(awesome_applyToprice))))

        // if (awesome_Handle = 0) then
        // begin
        // Print('Error: awesome_Handle=0');
        // Exit;
        // end;

        this.suicheBuena = true
    }

    // procedure CerrarCorto();
    // var
    // I: Integer;
    // begin
    //
    // if (not((((awesome[1] > 0) and (awesome[2] < 0)) or ((awesome[1] > 0) and (awesome[2] = 0) and (awesome[3] < 0))) or (awesome_Lime[1] <> 0))) then
    // Exit;
    //
    // for I := (OrdersTotal() - 1) downto 0 do
    // begin
    // if (not(OrderSelect(I, SELECT_BY_POS, MODE_TRADES))) then
    // begin
    // Print('Error OrderSelect CerrarCorto');
    // Exit;
    // end;
    //
    // if (OrderMagicNumber() <> magico) then
    // continue;
    //
    // if (OrderType() <> tp_Sell) then
    // continue;
    //
    // if (not(CloseOrder(OrderTicket()))) then
    // begin
    // Print('Error OrderClose CerrarCorto');
    // Exit;
    // end;
    // end;
    // end;

    CerrarCorto() {
        if (
            !(
                (this.awesome[1] > 0 && this.awesome[2] < 0) ||
                (this.awesome[1] > 0 && this.awesome[2] === 0 && this.awesome[3] < 0)
            ) ||
            this.awesome_Lime[1] !== 0
        ) {
            return
        }

        for (let i = this.api.OrdersTotal() - 1; i >= 0; i--) {
            if (!this.api.OrderSelect(i, EOrderSelectMode.osm_SELECT_BY_POS, TSearchMode.sm_MODE_TRADES)) {
                console.log('Error OrderSelect CerrarCorto')
                return
            }

            if (this.api.OrderMagicNumber() !== this.magico.value) {
                continue
            }

            if (this.api.OrderType() !== TTradePositionType.tp_Sell) {
                continue
            }

            if (!this.api.CloseOrder(this.api.OrderTicket())) {
                console.log('Error OrderClose CerrarCorto')
                return
            }
        }
    }

    // procedure AbrirCorto();
    // var
    // OrderHandle: Integer;
    // begin
    //
    // if (suicheCorto = Time(0)) then
    // Exit;
    // suicheCorto := Time(0);
    //
    // if (not((((awesome[1] < 0) and (awesome[2] > 0)) or ((awesome[1] < 0) and (awesome[2] = 0) and (awesome[3] > 0))))) then
    // Exit;
    //
    // if (not(awesome_Red[1] < 0)) then
    // Exit;
    //
    // SendInstantOrder(symbol, op_Sell, lotaje, 0, 0, '', magico, OrderHandle);
    // end;

    AbrirCorto() {
        if (this.suicheCorto === this.api.Time(0)) {
            return
        }
        this.suicheCorto = this.api.Time(0)

        if (
            !(
                (this.awesome[1] < 0 && this.awesome[2] > 0) ||
                (this.awesome[1] < 0 && this.awesome[2] === 0 && this.awesome[3] > 0)
            )
        ) {
            return
        }

        if (!(this.awesome_Red[1] < 0)) {
            return
        }

        let OrderHandle: number
        this.api.SendInstantOrder(
            this.symbol.value,
            TInstantOrderType.op_Sell,
            this.lotaje.value,
            0,
            0,
            '',
            this.magico.value
        )
    }

    // procedure AbrirLargo();
    // var
    // OrderHandle: Integer;
    // begin
    //
    // if (suicheLargo = Time(0)) then
    // Exit;
    //
    // suicheLargo := Time(0);
    //
    // if (not((((awesome[1] > 0) and (awesome[2] < 0)) or ((awesome[1] > 0) and (awesome[2] = 0) and (awesome[3] < 0))))) then
    // Exit;
    //
    // if (not(awesome_Lime[1] > 0)) then
    // Exit;
    //
    // SendInstantOrder(symbol, op_Buy, lotaje, 0, 0, '', magico, OrderHandle);
    // end;

    AbrirLargo() {
        if (this.suicheLargo === this.api.Time(0)) {
            return
        }

        this.suicheLargo = this.api.Time(0)

        if (
            !(
                (this.awesome[1] > 0 && this.awesome[2] < 0) ||
                (this.awesome[1] > 0 && this.awesome[2] === 0 && this.awesome[3] < 0)
            )
        ) {
            return
        }

        if (!(this.awesome_Lime[1] > 0)) {
            return
        }

        let OrderHandle: number
        this.api.SendInstantOrder(
            this.symbol.value,
            TInstantOrderType.op_Buy,
            this.lotaje.value,
            0,
            0,
            '',
            this.magico.value
        )
    }

    // procedure CerrarLargo();
    // var
    // I: Integer;
    // begin
    //
    // if (not((((awesome[1] < 0) and (awesome[2] > 0)) or ((awesome[1] < 0) and (awesome[2] = 0) and (awesome[3] > 0))) or (awesome_Red[1] <> 0))) then
    // Exit;
    //
    // for I := (OrdersTotal() - 1) downto 0 do
    // begin
    // if (not(OrderSelect(I, SELECT_BY_POS, MODE_TRADES))) then
    // begin
    // Print('Error OrderSelect CerrarLargo');
    // Exit;
    // end;
    //
    // if (OrderMagicNumber() <> magico) then
    // continue;
    //
    // if (OrderType() <> tp_Buy) then
    // continue;
    //
    // if (not(CloseOrder(OrderTicket()))) then
    // begin
    // Print('Error OrderClose CerrarLargo');
    // Exit;
    // end;
    // end;
    // end;

    CerrarLargo() {
        if (
            !(
                (this.awesome[1] < 0 && this.awesome[2] > 0) ||
                (this.awesome[1] < 0 && this.awesome[2] === 0 && this.awesome[3] > 0)
            ) ||
            this.awesome_Red[1] !== 0
        ) {
            return
        }

        for (let i = this.api.OrdersTotal() - 1; i >= 0; i--) {
            if (!this.api.OrderSelect(i, EOrderSelectMode.osm_SELECT_BY_POS, TSearchMode.sm_MODE_TRADES)) {
                console.error('Error OrderSelect CerrarLargo')
                return
            }

            if (this.api.OrderMagicNumber() !== this.magico.value) {
                continue
            }

            if (this.api.OrderType() !== TTradePositionType.tp_Buy) {
                continue
            }

            if (!this.api.CloseOrder(this.api.OrderTicket())) {
                console.log('Error OrderClose CerrarLargo')
                return
            }
        }
    }

    LeerEntradas() {
        if (!this.aoInd) {
            console.error('Error: not(awesome_Handle)')
            return false
        }

        for (let i = 0; i < 3; i++) {
            this.awesome[i] = this.api.GetIndicatorValue(this.aoInd, i, 0)
            this.awesome_Lime[i] = this.api.GetIndicatorValue(this.aoInd, i, 3)
            this.awesome_Red[i] = this.api.GetIndicatorValue(this.aoInd, i, 4)
        }

        return true
    }
}
