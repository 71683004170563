import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDataDescriptor } from '../data_arrays/DataDescriptionTypes'
import { TPriceRecord } from '../DataClasses/TPriceRecord'
import { TChunkStatus } from './ChunkEnums'
import { TDataChunk } from '@fto/lib/ft_types/data/chunks/DataChunk/DataChunk'

export default class TPriceChunk extends TDataChunk<TPriceRecord> {
    private fLastDate: number
    private fCount: number
    private fFirstGlobalIndex: number

    constructor(
        aDataDescriptor: TDataDescriptor,
        aFirstDate: number,
        aLastDate: number,
        aCount: number,
        aFirstGlobalIndex: number
    ) {
        super(aDataDescriptor, aFirstDate)
        this.fLastDate = aLastDate
        this.fCount = aCount
        this.fFirstGlobalIndex = aFirstGlobalIndex
    }

    public get Count(): number {
        return this.fCount
    }

    public get FirstGlobalIndex(): number {
        return this.fFirstGlobalIndex
    }

    public get LastPossibleDate(): number {
        return this.fLastDate
    }

    SetValue(globalIndex: number, record: TPriceRecord) {
        switch (this.Status) {
            case TChunkStatus.cs_PartiallyFilled: {
                const Value = record
                const localIndex = globalIndex - this.FirstGlobalIndex
                this._data[localIndex] = Value
                break
            }
            case TChunkStatus.cs_Empty: {
                this.InitWithEmptyValues()
                break
            }
            case TChunkStatus.cs_Loaded:
            case TChunkStatus.cs_Building:
            case TChunkStatus.cs_InQueue:
            case TChunkStatus.cs_InvalidDataOnServer: {
                throw new StrangeError('This status type is not supported for PriceChunks')
            }
            default: {
                throw new StrangeError('Unknown status type')
            }
        }
    }

    InitWithEmptyValues() {
        this._data = new Array(this.Count).fill(null)
        this.Status = TChunkStatus.cs_PartiallyFilled
    }
}
