import { COLOR_NAMES } from '@fto/ui'
import { EOperationType } from '@fto/lib/OrderModalClasses/OrderWndStructs'

export const getTabTextColor = (type: EOperationType, activeTab: EOperationType): (typeof COLOR_NAMES)[number] => {
    const isActive = type === activeTab

    if (isActive) {
        return 'white'
    }

    const isSell = type === EOperationType.SELL

    return isSell ? 'red-500' : 'primary-500'
}
