import StrangeError from '../common/common_errors/StrangeError'
import { TList } from '../delphi_compatibility/BasicLists'

export default class TStringList extends TList<string> {
    private fSorted = false
    protected objectMap: Map<string, any> = new Map() // Map to store associated objects

    constructor() {
        super()
    }

    Add(s: string, obj?: any): number {
        super.Add(s)
        this.objectMap.set(s, obj) // Associate the object with the string

        if (this.fSorted) {
            this.Sort()
        }
        return this.Count - 1
    }

    GetObject(index: number): any {
        const str = this.GetItem(index)
        return this.objectMap.get(str)
    }

    SetObject(index: number, obj: any): void {
        const str = this.GetItem(index)
        this.objectMap.set(str, obj)
    }

    InsertObject(index: number, s: string, obj?: any): void {
        if (index < 0 || index > this.Count) {
            throw new StrangeError('TStringList.InsertObject Index out of bounds')
        }

        // Insert the string at the specified index
        this.splice(index, 0, s)
        // Associate the object with the string
        this.objectMap.set(s, obj)

        if (this.fSorted) {
            this.Sort()
        }
    }

    find_str(s: string, index: { value: number }): boolean {
        let foundIndex = super.IndexOf(s)
        if (foundIndex >= 0) {
            index.value = foundIndex
            return true
        }
        return false
    }

    getText(): string {
        return this.join('\n')
    }

    set sorted(value: boolean) {
        this.fSorted = value
        if (this.fSorted) {
            this.Sort()
        }
    }

    get sorted(): boolean {
        return this.fSorted
    }

    public static CreateFromArray(arr: string[]): TStringList {
        const result = new TStringList()
        for (let i = 0; i < arr.length; i++) {
            result.Add(arr[i])
        }
        return result
    }

    // Other TStringList specific methods can be implemented here
}
