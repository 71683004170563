import { TFontStyle, TFontStyles, TFontStylesOperations } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TGpFontStyle, TGpFontStyles } from '@fto/lib/drawing_interface/GdiPlusCanvas'

export class StylingHelper {
    static ConvertFontStyle(style: TFontStyles): TGpFontStyles {
        let result: TGpFontStyle[] = []

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsBold)) {
            result.push(TGpFontStyle.FontStyleBold)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsItalic)) {
            result.push(TGpFontStyle.FontStyleItalic)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsUnderline)) {
            result.push(TGpFontStyle.FontStyleUnderline)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsStrikeout)) {
            result.push(TGpFontStyle.FontStyleStrikeOut)
        }

        return result.length === 0 ? [TGpFontStyle.FontStyleRegular] : result
    }

    static getFontStyleParams = (style: TGpFontStyle[]) => {
        const [param] = style

        if (param === TGpFontStyle.FontStyleRegular) {
            return { style: 'normal', weight: 'regular' }
        }

        if (param === TGpFontStyle.FontStyleBold) {
            return { style: 'normal', weight: 'bold' }
        }

        if (param === TGpFontStyle.FontStyleItalic) {
            return { style: 'italic', weight: 'regular' }
        }

        if (param === TGpFontStyle.FontStyleBoldItalic) {
            return { style: 'italic', weight: 'bold' }
        }

        return { style: 'normal', weight: 'regular' }
    }

    static getTGpFontStyle({ style, weight }: { style: string; weight: string }): TGpFontStyle[] {
        if (style === 'normal' && weight === 'regular') {
            return [TGpFontStyle.FontStyleRegular]
        }

        if (style === 'normal' && weight === 'bold') {
            return [TGpFontStyle.FontStyleBold]
        }

        if (style === 'italic' && weight === 'regular') {
            return [TGpFontStyle.FontStyleItalic]
        }

        if (style === 'italic' && weight === 'bold') {
            return [TGpFontStyle.FontStyleBoldItalic]
        }

        // Default case, if no match is found
        // Adjust this return based on how you wish to handle unexpected input
        return [TGpFontStyle.FontStyleRegular]
    }
}
