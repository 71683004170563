import { FC } from 'react'
import { Flex, Toggle, Typography } from '@fto/ui'
import { TemplatesDropdown } from './components/TemplatesDropdown'

import { t } from 'i18next'

type TemplateFooterProps = {
    templates: string[]
    selectedTemplate: string
    onTemplateSelected: (templateName: string) => void
    onDeleteTemplate: (templateName: string) => void
    onApplyDefault?: () => void
    onSavingTemplate: () => void
    applyToAll?: boolean
    setApplyToAll?: (value: boolean) => void | undefined
}

export const TemplateFooter: FC<TemplateFooterProps> = ({
    templates,
    selectedTemplate,
    onTemplateSelected,
    onDeleteTemplate,
    onApplyDefault,
    onSavingTemplate,
    applyToAll = true,
    setApplyToAll = undefined
}) => {
    return (
        <Flex gap={8} alignItems='center'>
            <TemplatesDropdown
                value={selectedTemplate}
                templates={templates}
                onChange={onTemplateSelected}
                onDeleteTemplate={onDeleteTemplate}
                onApplyDefault={onApplyDefault}
                onSavingTemplate={onSavingTemplate}
            />

            {setApplyToAll && (
                <>
                    <Toggle checked={applyToAll} onChange={setApplyToAll} />
                    <Typography>{t('templates.dropdown.applyToAll')}</Typography>
                </>
            )}
        </Flex>
    )
}
