import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Separator, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    cells: { title: string; flex: number; active: boolean }[]
}

const WatchlistTableHead: FC<Props> = ({ cells }) => {
    const { t } = useTranslation()

    return (
        <Flex justifyContent='space-between' alignItems='center' className={styles.th} gap={8} block>
            {cells.map((cell, index) => (
                <Fragment key={index}>
                    <Typography
                        type='subtext-regular'
                        color={'gray-800'}
                        style={{ flex: cell.flex }}
                        align={index > 0 ? 'right' : 'left'}
                    >
                        {t('watchlist.fields.' + cell.title)}
                    </Typography>
                    {index !== cells.length - 1 && <Separator height='16px' color='gray-500' />}
                </Fragment>
            ))}
        </Flex>
    )
}

export default WatchlistTableHead
