import { Flex, InputField, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getProject } from '@root/store/projects/projects.selector'
import dayjs from 'dayjs'
import { TIME_TEMPLATES } from '@root/constants/timeTemplates'
import styles from './duplicate-project.module.scss'
import { Toggle } from '@fto/ui/lib/components/Toggle'
import { DUPLICATE_PROJECT_ACTION } from '@root/store/projects/project.actions'
import { ProjectType } from '@root/types/projects'
import { redirectToNewProject } from '../CreateProjectModal/helpers/RedirectToNewProject'
import { useTranslation } from 'react-i18next'

type Props = {
    projectId: string
}

const DuplicateProjectModal: FC<Props> = ({ projectId }) => {
    const { t } = useTranslation()

    const project = useAppSelector((s) => $getProject(s, projectId))
    const [name, setName] = useState(`Copy ${project?.ProjectName || ''}`)
    const [deposit, setDeposit] = useState(project?.Deposit || '10000')
    const [startDate, setStartDate] = useState(true)
    const dispatch = useAppDispatch()

    const handleDuplicate = async () => {
        if (!project) {
            return
        }
        dispatch(
            DUPLICATE_PROJECT_ACTION({
                ...project,
                ProjectName: name,
                Deposit: +deposit,
                StartFrom: new Date().valueOf()
            })
        ).then((responce) => {
            if (responce.meta.requestStatus === 'rejected') {
                return
            }
            redirectToNewProject((responce.payload as ProjectType).Id)
            removeModal(MODAL_NAMES.projects.duplicate)
        })
    }

    useEffect(() => {
        if (!project) {
            return
        }
        setName(`Copy ${project?.ProjectName || ''}`)
        setDeposit(project.Deposit)
    }, [project])

    return (
        <Modal name={MODAL_NAMES.projects.duplicate} size='sm' withCloseIcon>
            <Modal.Header className={styles.line}>
                <Typography type='h4-semibold'>{t('dashboard.modals.projects.duplicateHeader')}</Typography>
            </Modal.Header>
            <Modal.Content className={styles.inputWrapper}>
                <Flex direction={'column'} gap={8}>
                    <InputField
                        label={<Typography color='gray-900'>{t('dashboard.modals.projects.newName')}</Typography>}
                        block
                        value={name}
                        onChange={setName}
                    />
                    <InputField
                        type={'number'}
                        label={
                            <Flex gap={4}>
                                <Typography color='gray-900'>{t('dashboard.modals.projects.deposit')}</Typography>
                                <Typography color='gray-600'>(USD)</Typography>
                            </Flex>
                        }
                        block
                        value={deposit}
                        onChange={setDeposit}
                    />
                </Flex>

                <Flex className={styles.dateBlock} direction={'column'} alignItems={'flex-start'} gap={16}>
                    <Flex direction='column'>
                        <Typography color='gray-1000' type='interface-semibold'>
                            {t('dashboard.modals.projects.testingPeriod')}
                        </Typography>
                        <Typography color='gray-900' type='interface-regular'>
                            {dayjs(project?.StartDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)} - {''}
                            {dayjs(project?.EndDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)}
                        </Typography>
                    </Flex>
                    <Flex direction='column'>
                        <Typography color='gray-1000' type='interface-semibold'>
                            {t('dashboard.modals.projects.currentDate')}
                        </Typography>
                        <Typography color='gray-900' type='interface-regular'>
                            {dayjs(new Date()).format(TIME_TEMPLATES.DASHBOARD_PERIOD)}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex alignItems={'center'} className={styles.dateBlock}>
                    <Toggle checked={startDate} onChange={setStartDate} />
                    <Typography color='gray-1000' type='interface-medium'>
                        {t('dashboard.modals.projects.startProjectLabel')}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                cancelText={t('global.cancel')}
                submitText={t('dashboard.modals.projects.duplicate')}
                onClose={() => removeModal(MODAL_NAMES.projects.duplicate)}
                onSubmit={handleDuplicate}
            />
        </Modal>
    )
}
export default DuplicateProjectModal
