import { DateUtils, TDateTime } from '../../../../delphi_compatibility/DateUtils'
import { TTaskStatus } from '../DownloadRelatedEnums'
import { TBaseDownloadWorkerThread } from '../WorkerThreads/BaseDownloadWorkerThread'
import { TDataFormat } from '../../DataEnums'
import { Common } from '../../../../ft_types/common/Common'

export abstract class TBasicDownloadTask {
    public status: TTaskStatus
    public CreateTime: TDateTime
    protected fDataFormat: TDataFormat
    protected fUrl = '' //for diagnostic purposes
    public ID: string

    constructor(aDataFormat: TDataFormat) {
        this.status = TTaskStatus.ts_WaitingToStart
        this.CreateTime = DateUtils.Now()
        this.fDataFormat = aDataFormat
        this.ID = Common.GenerateUID()
    }

    abstract get Thread(): TBaseDownloadWorkerThread
    abstract IsSameAs(task: TBasicDownloadTask): boolean

    // // Call this method when the task completes
    // protected CompleteTask(aStartIndex: number, aEndIndex: number) {
    //   this.status = TTaskStatus.ts_Completed;
    //   if (this.OnComplete) {
    //     this.OnComplete(aStartIndex, aEndIndex);
    //   }
    // }

    public ReadyToStart(): boolean {
        return true
        // Assuming that DateUtils.Now() returns a TDateTime compatible value and DateUtils.OneSecond is the equivalent of one second in TDateTime units.
        //return (DateUtils.Now() - this.CreateTime) >= DateUtils.OneSecond / 3;
    }

    public start(): void {
        this.status = TTaskStatus.ts_Working
        this.Thread.start()
    }
}
