import { FC, ReactNode } from 'react'

const LevelStepInPx = 24

interface OptionContainerProps {
    children: ReactNode
    level: number
}

const OptionContainer: FC<OptionContainerProps> = ({ children, level = 0 }) => {
    return <div style={{ paddingLeft: `${level * LevelStepInPx}px` }}>{children}</div>
}

export default OptionContainer
