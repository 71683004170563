import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@root/constants/localStorageKeys'
import {
    isAnyLoginActionFulfilled,
    isAnyLoginActionPending,
    isAnyLoginActionRejected,
    LOOKUP,
    REGISTER_BY_EMAIL
} from '@root/store/auth/auth.actions'
import { SetUserAuthPayload } from '@root/store/auth/auth.types'
import { StatusMessages } from '@root/pages/Auth/constants/errorMessages'
import { CODE_REQUIRED } from '@root/pages/Auth/constants/errorStatuses'

import { User } from '@root/types/user'
import { removeProjectQueryStorage } from '@root/utils/localStorage'

export type AuthState = {
    accessToken?: string
    user: User | null
    isAuthenticated: boolean
    userIsLoaded: boolean
    confirmationCode: string | null
    authError: StatusMessages | null
    isAuthProcess: boolean
}

export const initialAuthState: AuthState = {
    accessToken: localStorage.getItem(AUTH_TOKEN_KEY) || '',
    user: null,
    isAuthenticated: !!localStorage.getItem(AUTH_TOKEN_KEY),
    userIsLoaded: false,
    confirmationCode: null,
    authError: null,
    isAuthProcess: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        SET_CONFIRMATION_CODE: (state, action: PayloadAction<string>) => {
            state.confirmationCode = action.payload
        },
        SET_USER_AUTH: (state, action: PayloadAction<SetUserAuthPayload>) => {
            const { user, isAuthenticated, userIsLoaded } = action.payload

            state.user = user
            state.isAuthenticated = isAuthenticated
            state.userIsLoaded = userIsLoaded
        },
        LOGOUT: (state) => {
            localStorage.removeItem(AUTH_TOKEN_KEY)
            localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY)
            removeProjectQueryStorage(state.user?.userId || '')
            state.isAuthenticated = false
            state.userIsLoaded = false
            state.accessToken = ''
            state.user = null
        },
        SET_AUTH_ERROR: (state, action: PayloadAction<StatusMessages | null>) => {
            state.authError = action.payload
        },
        RESET_AUTH_ERROR: (state) => {
            state.authError = null
        },
        SET_AUTH_PROCESS: (state, action: PayloadAction<boolean>) => {
            state.isAuthProcess = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(REGISTER_BY_EMAIL.rejected, (state, action) => {
                state.isAuthProcess = false
                if (action.payload !== CODE_REQUIRED) {
                    state.authError = action.payload || 'unknown'
                }
            })
            .addCase(LOOKUP.fulfilled, (state) => {
                state.userIsLoaded = true
            })
            .addMatcher(isAnyLoginActionFulfilled, (state, action) => {
                const { idToken, refreshToken } = action.payload
                state.isAuthProcess = false
                state.isAuthenticated = true
                state.accessToken = idToken
            })
            .addMatcher(isAnyLoginActionRejected, (state, { payload }) => {
                state.isAuthProcess = false
                state.authError = payload as StatusMessages
            })
            .addMatcher(isAnyLoginActionPending, (state) => {
                state.isAuthProcess = true
            })
    }
})

export const { SET_CONFIRMATION_CODE, RESET_AUTH_ERROR, SET_USER_AUTH, LOGOUT } = authSlice.actions
export default authSlice.reducer
