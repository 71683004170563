import { TValueType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDataDescriptor } from '@fto/lib/ft_types/data/data_arrays/DataDescriptionTypes'
import { CachedPrices } from '@fto/lib/globals/HighestLowestCache/CachedPrices'

export class GlobalHighestLowestPriceCache {
    private static _instance: GlobalHighestLowestPriceCache
    private _cachedHighestPrices: CachedPrices = new CachedPrices()
    private _cachedLowestPrices: CachedPrices = new CachedPrices()

    private constructor() {}

    public static get Instance(): GlobalHighestLowestPriceCache {
        if (!GlobalHighestLowestPriceCache._instance) {
            GlobalHighestLowestPriceCache._instance = new GlobalHighestLowestPriceCache()
        }
        return GlobalHighestLowestPriceCache._instance
    }

    public getHighestValue(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number
    ): number | undefined {
        return this._cachedHighestPrices.get(dataDescriptor, globalIndex, valueType, offset)
    }

    public getLowestValue(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number
    ): number | undefined {
        return this._cachedLowestPrices.get(dataDescriptor, globalIndex, valueType, offset)
    }

    public setHighestValue(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number,
        value: number
    ): void {
        this._cachedHighestPrices.set(dataDescriptor, globalIndex, valueType, offset, value)
    }

    public setLowestValue(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number,
        value: number
    ): void {
        this._cachedLowestPrices.set(dataDescriptor, globalIndex, valueType, offset, value)
    }
}
