import React, { Component, ErrorInfo, ReactNode } from 'react'
import { connect } from 'react-redux'

import ErrorBoundaryPreview from './components/ErrorBoundaryPreview'

import { fireMixpanelEvent } from '@root/utils/api'

import styles from './index.module.scss'
import { RootState } from '@root/store'
import ApiRequestBoundary from '@root/components/ErrorBoundary/components/ApiRequestBoundary'

interface Props {
    children?: ReactNode
    appSettingsStoreData: RootState['appSettings']
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const errorEntity = {
            message: error.message,
            stack: error.stack
        }
        fireMixpanelEvent('app_crashed', { ...errorEntity })

        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        const isOnline = navigator.onLine

        if (this.state.hasError || !isOnline) {
            return (
                <div className={styles.root}>
                    <ErrorBoundaryPreview isOnline={isOnline} />
                </div>
            )
        }
        const { apiSettings } = this.props.appSettingsStoreData

        if (apiSettings.hasError && apiSettings.statusCode === 429) {
            return <ApiRequestBoundary value={apiSettings.offsetData.value} unit={apiSettings.offsetData.unit} />
        }

        return this.props.children
    }
}

function mapStateToProps(state: RootState) {
    const { appSettings } = state
    return { appSettingsStoreData: appSettings }
}

export default connect(mapStateToProps)(ErrorBoundary)
