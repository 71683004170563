import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import RibbonDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/RibbonDropdownMenu'

import { RibbonOptionType } from '../../types'
import RibbonDropdown from '../../RibbonDropdown'
import AdditionalProjectSettingsOptions from '../AdditionalDropdownOptions/AdditionalProjectDropdownOptions'

const ProjectOptionsDropdown: FC = () => {
    const name = GlobalProjectJSONAdapter.Instance.projectName
    const { t } = useTranslation()

    const navigate = useNavigate()
    const projectSettingsOptions = useMemo(
        () =>
            [
                {
                    value: 'create new project',
                    name: t('ribbon.projectOptions.newProject'),
                    icon: 'new-project',
                    action: () => addModal(MODAL_NAMES.projects.create)
                },
                {
                    value: 'duplicate',
                    name: t('ribbon.projectOptions.duplicate'),
                    icon: 'duplicate',
                    action: () => {
                        addModal(MODAL_NAMES.projects.duplicate, {
                            projectId: GlobalProjectJSONAdapter.Instance.projectId
                        })
                    }
                },
                {
                    value: 'projects list',
                    name: t('ribbon.projectOptions.projectsList'),
                    icon: 'folder',
                    action: () => navigate(makeRoute(DASHBOARD_ROUTE))
                }
            ] as RibbonOptionType[],
        [GlobalProjectJSONAdapter.Instance.projectId]
    )

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.projectSettings')}
            label={name || ''}
            withDropdownIcon
            renderer={(close) => (
                <RibbonDropdownMenu
                    close={close}
                    options={projectSettingsOptions}
                    name={t('ribbon.projectOptions.projectSettings')}
                    additionalElements={AdditionalProjectSettingsOptions}
                />
            )}
        />
    )
}
export default ProjectOptionsDropdown
