export default class CommonUtils {
    private static _isInUnitTest = false
    public static IsBasicDataSubscription = false

    //Only for unit testing purposes, do NOT set true in production code
    public static get IsInUnitTest(): boolean {
        if (typeof process !== 'undefined' && process.env) {
            const nodeEnvTest = process.env.NODE_ENV_IS_TEST
            if (nodeEnvTest) {
                this._isInUnitTest = nodeEnvTest === 'true'
            }
        }
        return this._isInUnitTest
    }

    public static set IsInUnitTest(value: boolean) {
        this._isInUnitTest = value
    }

    public static generateUniqueId(): string {
        return `id_${Date.now().toString(36)}_${Math.random().toString(36).slice(2)}`
    }
}
