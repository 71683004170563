import { TTestingManager } from '../processing_core/TestingManager'

export default class GlobalTestingManager {
    private static fTestingManager: TTestingManager

    public static Init(): void {
        GlobalTestingManager.fTestingManager = new TTestingManager()
    }

    public static get TestingManager(): TTestingManager {
        if (!GlobalTestingManager.fTestingManager) {
            GlobalTestingManager.Init()
        }
        return GlobalTestingManager.fTestingManager
    }
}
