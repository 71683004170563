import { TTimeframes } from '../ft_types/TimeframesUnit'

export default class GlobalTimeframes {
    private static fTimeframes: TTimeframes

    public static Init(): void {
        GlobalTimeframes.fTimeframes = new TTimeframes()
        const defaultActiveTimeframes = '15'
        GlobalTimeframes.fTimeframes.LoadFromStr(defaultActiveTimeframes)
    }

    public static get Timeframes(): TTimeframes {
        if (!GlobalTimeframes.fTimeframes) {
            GlobalTimeframes.Init()
        }
        return GlobalTimeframes.fTimeframes
    }
}
