import { TEducationTask } from '@fto/lib/Education/Types'
import EducationStore from '@fto/lib/store/educationStore'

import { TutorialTask } from '@fto/lib/Education/TutorialTasks/TutorialTask'
import { TutorialTasksProcessor } from '@fto/lib/Education/TutorialTasks/TutorialTasksProcessor'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalProjectInfo from '../globals/GlobalProjectInfo'
import GlobalChartsController from '../globals/GlobalChartsController'
import { TRuntimeIndicator } from '../extension_modules/indicators/RuntimeIndicator'
import StrangeError from '../common/common_errors/StrangeError'
import IBasicPaintTool from '../charting/paint_tools/IBasicPaintTool'

export class EducationProcessor {
    private static instance: EducationProcessor
    public taskEntity: TEducationTask | null = null
    tutorialTasks: TutorialTask[] = []
    public tutorialTasksProcessor: TutorialTasksProcessor | null = null
    public staticTools: IBasicPaintTool[] = []
    public staticIndicators: TRuntimeIndicator[] = []

    public static get Instance(): EducationProcessor {
        if (!EducationProcessor.instance) {
            EducationProcessor.instance = new EducationProcessor()
        }
        return EducationProcessor.instance
    }

    public parseTask(task: TEducationTask): void {
        const { updateData } = EducationStore
        this.Reset()
        for (const check of task.checks) {
            const newCheck = new TutorialTask(
                check.name,
                check.criteria,
                check.trueResult,
                check.falseResult,
                check.description
            )
            this.tutorialTasks.push(newCheck)
        }
        this.taskEntity = task

        for (const chart of GlobalChartsController.Instance.getAllCharts()) {
            this.staticTools.push(...chart.MainChart.PaintTools)
            this.staticIndicators.push(
                ...chart.MainChart.indicators,
                ...chart.OscWins.map((osc) => osc.chart.indicators[0])
            )
        }

        GlobalProjectInfo.ProjectInfo.ForwardTestingOnly = true
        this.tutorialTasksProcessor = new TutorialTasksProcessor(this.tutorialTasks)
        updateData({ lesson: task, isShown: true })
    }

    public getAllChecks(): TutorialTask[] {
        return this.tutorialTasks
    }

    public processChecks(): void {
        const { updateData } = EducationStore

        if (this.tutorialTasksProcessor) {
            this.tutorialTasksProcessor.process()
            if (!this.taskEntity) {
                throw new StrangeError('Task entity is not defined')
            }
            updateData({
                lesson: { ...this.taskEntity, checks: this.tutorialTasksProcessor.getJSONArray() },
                isShown: true
            })
        }
    }

    public startHomework(task: TEducationTask): void {
        this.parseTask(task)
        addModal(MODAL_NAMES.messages.educationMessage, { message: task.intro })
    }

    public restartHomework(): void {
        const { updateData } = EducationStore
        GlobalChartsController.Instance.RestartProject()
        this.tutorialTasks.map((task) => task.reset())
        this.tutorialTasksProcessor = new TutorialTasksProcessor(this.tutorialTasks)
        if (!this.taskEntity) {
            throw new StrangeError('Task entity is not defined')
        }
        updateData({ lesson: this.taskEntity, isShown: true })
    }

    public Reset(): void {
        const { resetStore } = EducationStore
        this.tutorialTasks = []
        this.taskEntity = null
        this.tutorialTasksProcessor = null
        resetStore()
    }
}
