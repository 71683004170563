export const initialLineStyles = {
    color: {
        value: '',
        hasDifferentValues: false
    },
    style: {
        value: 0,
        hasDifferentValues: false
    },
    width: {
        value: 0,
        hasDifferentValues: false
    }
}
