import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

export abstract class TBaseDownloadWorkerThread {
    private url: string
    public OnThreadFinished!: (data?: any) => void
    public OnThreadException!: (error: string) => void

    constructor(url: string) {
        this.url = url
    }

    protected abstract get TheWorker(): Worker

    public start(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Maps, 'Starting worker thread, url:', this.url)
        this.TheWorker.postMessage({ url: this.url })
    }

    public terminateWorker(): void {
        this.TheWorker.terminate() // This will terminate the worker process
    }

    // Call this method when the task completes
    protected EmitCompleteCallback(data?: any): void {
        if (this.OnThreadFinished) {
            this.OnThreadFinished(data)
        }

        this.terminateWorker() //TODO: is there a better place to terminate the worker?
    }

    protected EmitExceptionCallback(error: string): void {
        if (this.OnThreadException) {
            this.OnThreadException(error)
        }

        this.terminateWorker()
    }
}
