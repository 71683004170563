export const MODAL_NAMES = {
    dashboard: {
        adminSettings: 'adminSettings',
        bugBounty: 'bugBounty'
    },
    userProfile: {
        changeEmail: 'changeEmail',
        changePassword: 'changePassword',
        renewSubscription: 'renewSubscription',
        suspendSubscription: 'suspendSubscription',
        survey: 'survey'
    },
    projects: {
        delete: 'deleteProject',
        rename: 'renameProject',
        create: 'createProject',
        duplicate: 'duplicateProject',
        analytics: 'analytics',
        deleteAll: 'deleteAllProjects'
    },
    payment: {
        thankYou: 'thankYou',
        failed: 'paymentFailed'
    },
    chart: {
        indicatorModal: 'indicatorModal',
        chartSettings: 'chartSettings',
        graphTools: 'graphTools',
        jumpToDate: 'jumpToDate',
        jumpToSettings: 'jumpToSettings',
        orderModal: 'orderModal',
        changeSymbol: 'changeSymbol',
        changeBalance: 'changeBalance',
        educationModal: 'educationModal',
        restartModal: 'restartModal',
        saveTemplate: 'saveTemplate',
        replaceTemplate: 'replaceTemplate',
        deleteTemplate: 'deleteTemplate',
        applyToAllWarning: 'applyToAllWarning',
        customTimeFrame: 'customTimeFrame',
        rateUsModal: 'rate',
        partialClosing: 'partialClosing',
        education: {
            fail: 'educationFailModal',
            success: 'educationSuccessModal',
            restart: 'educationRestartModal'
        }
    },
    messages: {
        educationMessage: 'educationMessage'
    },
    devEnv: {
        longTermTests: 'longTermTests'
    }
}
