import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TRect } from '../../extension_modules/common/CommonExternalInterface'
import { TColor } from '../../delphi_compatibility/DelphiBasicTypes'
import TStringList from '../../delphi_compatibility/StringList'
import { TMyObjectList } from '../../ft_types/common/Common'
import { NotImplementedError } from '../../utils/common_utils'
import { StrsConv } from './StrsConv'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

export type PListItem = TListItem | null

export class TListItem {
    s: string
    next: PListItem

    constructor(s: string) {
        this.s = s
        this.next = null
    }
}

export class TOffsStringList {
    private fFirstItem: PListItem
    private fLastItem: PListItem
    private fSections: TStringList
    private fSectionName!: string
    private fSectionEndName!: string
    private fSearchPosition!: number
    private fSectionStart!: number
    private fSectionEnd!: number
    private fIndexes: PListItem[]
    public shift: number

    constructor() {
        this.shift = 0
        this.fSections = new TStringList()
        this.fFirstItem = null
        this.fLastItem = null
        this.fIndexes = []
    }

    public get Count(): number {
        return this.GetCount()
    }

    public GetItem(index: number): string {
        return this.GetIndexStr(index)
    }

    public GetIndexStr(index: number): string {
        if (!this.fIndexes) {
            throw new StrangeError('GetIndexStr - fIndexes is not initialized')
        }
        if (index < 0 || index >= this.fIndexes.length) {
            throw new RangeError('GetIndexStr - Index out of bounds')
        }
        const item = this.fIndexes[index]
        if (!item) {
            throw new StrangeError('GetIndexStr - Item at the given index is null')
        }
        return item.s
    }

    public SetIndexStr(index: number, s: string): void {
        if (!this.fIndexes) {
            throw new StrangeError('SetIndexStr - fIndexes is not initialized')
        }
        if (index < 0 || index >= this.fIndexes.length) {
            throw new RangeError('SetIndexStr - Index out of bounds')
        }
        const listItem = this.fIndexes[index]
        if (listItem) {
            listItem.s = s
        } else {
            throw new StrangeError('SetIndexStr - List item does not exist at the specified index')
        }
    }

    private GetCount(): number {
        return this.fIndexes.length
    }

    public OpenSection(SectionName: string): void {
        this.fSections.Add(SectionName)
        // Using template literals for better readability
        this.Add(`<${SectionName}>`)
        this.shift += 2
    }

    public empty(): boolean {
        return this.fFirstItem === null
    }

    public Reindex(): void {
        // get number of items
        let count = 0
        let item: PListItem = this.fFirstItem
        while (item !== null) {
            count++
            item = item.next
        }

        this.fIndexes = new Array(count)

        // fill index array
        let i = 0
        item = this.fFirstItem
        while (item !== null) {
            this.fIndexes[i] = item
            item = item.next
            i++
        }
    }

    public EmptySection(): void {
        this.fSectionStart++
        this.fSectionEnd--
        this.DeleteSection() // Assuming DeleteSection is implemented elsewhere
        this.fSearchPosition++
    }

    public DeleteSection(): void {
        if (this.empty()) {
            return
        }

        // free items
        for (let i = this.fSectionStart; i <= this.fSectionEnd; i++) {
            this.fIndexes[i] = null
        }

        if (this.fSectionStart === 0 && this.fSectionEnd === this.Count - 1) {
            this.fFirstItem = null
            this.fLastItem = null
        } else if (this.fSectionStart === 0) {
            this.fFirstItem = this.fIndexes[this.fSectionEnd + 1]
        } else if (this.fSectionEnd === this.Count - 1) {
            if (this.fIndexes) {
                const prevItem = this.fIndexes[this.fSectionStart - 1]
                if (prevItem) {
                    prevItem.next = null
                }
                this.fLastItem = this.fIndexes[this.fSectionStart - 1]
            }
        } else {
            const prevItem = this.fIndexes[this.fSectionStart - 1]
            if (prevItem) {
                prevItem.next = this.fIndexes[this.fSectionEnd + 1]
            }
        }

        this.Reindex()
        this.fSearchPosition = this.fSectionStart
    }

    public Clear(): void {
        while (this.fFirstItem !== null) {
            this.fFirstItem = this.fFirstItem.next
        }
        // It's important to also set fLastItem to null to maintain the integrity of the list,
        // indicating that the list is indeed empty after clearing it.
        this.fLastItem = null
    }

    public SaveToString(): string {
        let item: PListItem = this.fFirstItem
        let fileContent = ''

        while (item !== null) {
            fileContent += `${item.s}\r\n` // Delphi's writeln adds a carriage return and a newline, so we mimic that here.
            item = item.next
        }

        return fileContent
    }

    public LoadFromString(s: string): void {
        this.Clear()

        const lines = s.split(/\r?\n/)
        lines.forEach((line: string) => {
            this.Add(line)
        })
    }

    public CloseSection(): void {
        const sectionCount = this.fSections.Count
        if (sectionCount > 0) {
            this.shift -= 2
            // Use array indexing to access the last element instead of a custom getString method.
            const lastSection = this.fSections[sectionCount - 1]
            this.Add(`</${lastSection}>`)
            // Use the pop method to remove the last element of the array, which is more idiomatic in JavaScript/TypeScript.
            this.fSections.Delete(this.fSections.Count - 1)
        }
    }

    public Add(s: string): void {
        // If shift is greater than 0, prepend the string with spaces.
        if (this.shift > 0) {
            s = ' '.repeat(this.shift) + s
        }

        // Create a new list item with the string 's' and next set to null.
        const item = new TListItem(s)

        // If the list is empty, set both the first and last items to the new item.
        // Otherwise, append the new item to the end of the list and update the last item.
        if (!this.fFirstItem) {
            this.fFirstItem = item
            this.fLastItem = item
        } else {
            // Ensure that fLastItem is not null before setting its 'next' property.
            if (this.fLastItem) {
                this.fLastItem.next = item
            }
            this.fLastItem = item
        }
    }

    public GetSection(SectionName: string, list: TOffsStringList, NoTrim = true): boolean {
        list.Clear()

        // find section, delete all strings before it
        const startTag = `<${SectionName}>`
        while (!this.empty() && this.GetString() !== startTag) {
            /* nothing like in Delphi */
        }
        if (this.empty()) {
            return false
        }

        // section found, read it to list
        const endTag = `</${SectionName}>`

        while (!this.empty()) {
            const line = this.GetString(NoTrim)

            if (line.trim() === endTag) {
                break
            } else {
                list.Add(line)
            }
        }

        list.ShiftLeftToFirst()
        return true
    }

    public GetString(NoTrim = false, DelFromList = true): string {
        // Check if the list is empty by directly comparing the first item to null
        if (this.fFirstItem === null || this.empty()) {
            //replaces empty to remove null check for fFirstItem
            return ''
        }

        let result: string = this.fFirstItem.s
        // Use the parameter name 'NoTrim' as it is, to match the Delphi convention
        if (!NoTrim) {
            result = result.trim()
        }

        if (DelFromList) {
            // Delete the first item from the list
            const item = this.fFirstItem // Type inference can be used here
            this.fFirstItem = item.next // Directly access the 'next' property

            // In TypeScript, we don't explicitly dispose objects as in Delphi.
            // The garbage collector will clean up unreferenced objects.

            // Check if the list is now empty by directly comparing the first item to null
            if (this.fFirstItem === null) {
                this.fLastItem = null
            }
        }

        return result
    }

    // - Used trimLeft() to remove leading spaces instead of substring to avoid unnecessary string operations.
    // - Added a check for null or undefined on fFirstItem to prevent potential runtime errors.
    public ShiftLeftToFirst(): void {
        // Exit early if the list is empty or the first item is not defined
        if (this.empty() || !this.fFirstItem) {
            return
        }

        // Get the number of leading spaces from the first item
        const cnt: number = this.getSpaces(this.fFirstItem.s)
        // If there are no leading spaces, no need to shift anything
        if (cnt === 0) {
            return
        }

        let item: PListItem | null = this.fFirstItem
        while (item !== null) {
            const s: string = item.s
            const spaces: number = this.getSpaces(s)
            // If the current item has less or equal leading spaces than the first item, trim them
            if (spaces <= cnt) {
                item.s = s.trimStart()
            } else {
                // Otherwise, remove the same number of spaces as in the first item
                item.s = s.substring(cnt)
            }
            item = item.next
        }
    }

    private getSpaces(s: string): number {
        let result = 1
        while (result <= s.length && s[result] === ' ') {
            // maybe we need getSpaces to start counting from 1 and decrement the result to match Delphi's behavior
            result++
        }
        // Decrement result to account for the last increment that exits the loop
        return result
    }

    public SectionExists(SectionName: string): boolean {
        if (this.empty()) {
            return false
        }

        const s = `<${SectionName}>`
        for (let item: PListItem | null = this.fFirstItem; item !== null; item = item.next) {
            if (item.s.trim() === s) {
                return true
            }
        }

        return false
    }

    public Assign(list: TOffsStringList): void {
        this.Clear()
        let item: PListItem = list.fFirstItem
        while (item !== null) {
            this.Add(item.s)
            item = item.next
        }
    }

    public ReplaceVarStr(VarName: string, NewValue: string): void {
        let item: PListItem = this.fFirstItem
        while (item !== null) {
            const s: string = item.s.trim()
            if (s.startsWith(`${VarName}=`)) {
                item.s = `${VarName}=${NewValue}`
                return
            }
            item = item.next
        }
    }

    public GetVarStr(VarName: string): string {
        let item: PListItem = this.fFirstItem
        while (item !== null) {
            const s: string = item.s.trim()
            if (s.startsWith(`${VarName}=`)) {
                return s.substring(VarName.length + 1)
            }
            item = item.next
        }
        return ''
    }

    public AddList(SectionName: string, list: TOffsStringList, NoTrim = false): void {
        if (SectionName !== '') {
            this.OpenSection(SectionName)
        }

        let item: PListItem = list.fFirstItem
        while (item !== null) {
            this.Add(item.s)
            item = item.next
        }

        if (SectionName !== '') {
            this.CloseSection()
        }
    }

    public StartSearch(SectionName: string): void {
        this.fSectionName = `<${SectionName}>`
        this.fSectionEndName = `</${SectionName}>`
        this.fSearchPosition = 0
        this.fSectionStart = -1
        this.fSectionEnd = -1
        this.Reindex()
    }
    public FindNextSection(list: TOffsStringList): boolean {
        let result = false

        if (this.empty()) {
            return result
        }

        if (list !== null) {
            list.Clear()
        }

        const cnt: number = this.Count
        while (this.fSearchPosition < cnt - 1) {
            if (this.GetIndexStr(this.fSearchPosition).trim() === this.fSectionName) {
                // we found start of a section
                result = true
                this.fSectionStart = this.fSearchPosition
                this.fSearchPosition++

                // search for the end of a section
                while (
                    this.fSearchPosition < cnt - 1 &&
                    this.GetIndexStr(this.fSearchPosition).trim() !== this.fSectionEndName
                ) {
                    this.fSearchPosition++
                }

                // copy section to list
                if (list !== null) {
                    for (let i = this.fSectionStart + 1; i < this.fSearchPosition; i++) {
                        list.Add(this.GetIndexStr(i))
                    }
                }

                this.fSectionEnd = this.fSearchPosition
                this.fSearchPosition++
                return result
            }

            this.fSearchPosition++
        }

        return result
    }

    public ReplaceSection(list: TOffsStringList): void {
        this.fSectionStart++
        this.fSectionEnd--

        // get item before section start
        const item: PListItem = this.fSectionStart === 0 ? null : this.fIndexes[this.fSectionStart - 1]

        // delete section
        this.DeleteSection()

        // insert strings
        list.Reindex()
        for (let i = list.Count - 1; i >= 0; i--) {
            this.InsertAfter(item, list.GetIndexStr(i))
        }
    }

    public InsertAfter(item: PListItem, s: string): void {
        if (this.empty() || item === this.fLastItem) {
            this.Add(s)
            return
        }

        const newItem = new TListItem(s)

        if (item === null) {
            newItem.next = this.fFirstItem
            this.fFirstItem = newItem
            if (this.fFirstItem.next === null) {
                this.fLastItem = newItem // If the list was empty, the new item is also the last item now
            }
        } else {
            newItem.next = item.next
            item.next = newItem
            if (newItem.next === null) {
                this.fLastItem = newItem // If the item was the last one, update fLastItem to the new item
            }
        }
    }
}

export class TVar {
    public name: string
    public value: string

    constructor(aName: string, aValue: string) {
        this.name = aName
        this.value = aValue
    }
}

export class TVarList extends TMyObjectList<TVar> {
    public LoadFromList(list: TOffsStringList): void {
        this.Clear()
        list.Reindex()

        for (let i = 0; i <= list.Count - 1; i++) {
            const s: string = list.GetItem(i).trim()
            if (s.indexOf('=') === -1) continue

            if (s.startsWith('<')) break

            // Assuming GetSubStr is a method that splits the string at the '=' character and returns the first part.
            const [s1, remainingString] = StrsConv.GetSubStr(s, '=')
            // Check if the AddVarStr method exists before calling it.
            this.AddVarStr(s1, remainingString)
        }
    }

    public AddVarStr(name: string, value: string): void {
        const v = new TVar(name, value)
        this.Add(v)
    }

    public GetValue(name: string): string {
        for (let i = 0; i < this.count; i++) {
            // Delphi's SameText function is case-insensitive string comparison
            // In TypeScript, we can use toLowerCase for a similar effect
            if (this[i].name.toLowerCase() === name.toLowerCase()) {
                return this[i].value
            }
        }
        // If the value is not found, log the debug message and return an empty string
        // console.debug(`[TVarList.GetValue] Cannot find var "${name}"`);
        return ''
    }

    public GetInt(name: string): number {
        try {
            // Using parseInt to convert the string value to an integer.
            // The radix parameter (10) is specified to ensure the string is treated as a decimal number.
            return parseInt(this.GetValue(name), 10)
        } catch {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `[TVarList.GetInt] Cannot convert var "${name}" to integer`
            )
            return 0
        }
    }

    public GetBool(name: string): boolean {
        return StrsConv.GetBool(this.GetValue(name))
    }

    public GetDateTime(name: string): TDateTime {
        try {
            return StrsConv.GetDateTime(this.GetValue(name))
        } catch {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `[TVarList.GetDateTime] Cannot convert var "${name}" to TDateTime`
            )
            return 0
        }
    }

    public GetRect(name: string): TRect {
        const result: TRect = new TRect(0, 0, 0, 0)
        try {
            const s = this.GetValue(name)
            let remainingString: string
            ;[result.Left, remainingString] = StrsConv.GetStrInt(s, ',')
            ;[result.Top, remainingString] = StrsConv.GetStrInt(remainingString, ',')
            ;[result.Right, remainingString] = StrsConv.GetStrInt(remainingString, ',')
            result.Bottom = StrsConv.GetStrInt(remainingString, ',')[0] // No need to update remainingString after the last use
        } catch {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `[TVarList.GetRect] Cannot convert var "${name}" to TRect`
            )
        }
        return result
    }

    public GetDouble(name: string): number {
        try {
            // Attempt to convert the string value to a double using StrsConv
            return StrsConv.GetDouble(this.GetValue(name))
        } catch {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `[TVarList.GetDouble] Cannot convert var "${name}" to double`
            )
            // If conversion fails, return 0 as per Delphi's exception handling
            return 0
        }
    }

    //TODO: is it correct that it is typically used for colors?
    public GetHex(name: string): string {
        const hexValue = this.GetValue(name)

        // Regular expression to check if the string is a valid hex color
        const hexColorRegex = /^#([\da-f]{3,4}|[\da-f]{6}|[\da-f]{8})$/i

        if (hexColorRegex.test(hexValue)) {
            return hexValue
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(`Invalid hex color: ${hexValue}`)
            return '#FFFFFF'
        }
    }

    public GetHexColor(name: string): TColor {
        return StrsConv.GetColor(this.GetHex(name))
    }

    public LoadFromListSection(list: TOffsStringList, SectionName: string): boolean {
        const list1 = new TOffsStringList()
        const result = list.GetSection(SectionName, list1)
        this.LoadFromList(list1)

        return result
    }

    public SaveToList(list: TOffsStringList, SectionName: string): void {
        if (SectionName !== '') {
            list.OpenSection(SectionName)
        }

        for (let i = 0; i < this.count; i++) {
            list.Add(`${this[i].name}=${this[i].value}`)
        }

        if (SectionName !== '') {
            list.CloseSection()
        }
    }

    public AddVarInt(name: string, value: number): void {
        this.AddVarStr(name, StrsConv.IntToStr(value))
    }

    public AddVarBool(name: string, value: boolean): void {
        this.AddVarStr(name, StrsConv.StrBool(value))
    }

    public AddVarDateTime(name: string, value: TDateTime): void {
        this.AddVarStr(name, StrsConv.StrDateTime(value, true))
    }

    public addVar(name: string, value: number, decimals?: number): void {
        if (typeof decimals === 'number') {
            this.AddVarDouble(name, value, decimals)
        } else {
            throw new NotImplementedError('cannot implement this because addVarDouble code was not provided.')
        }
    }

    public AddVarDouble(name: string, value: number, decimals = 4): void {
        this.AddVarStr(name, StrsConv.StrDouble(value, decimals))
    }
}
