import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, InputField, OptionType, Separator, SimpleDropdown, ValueType, EmptyState, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { setI18nLang } from '@root/i18n'
import { getSelectableLanguages } from '@root/utils/getSelectableLanguages'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $useCrowdin } from '@root/store/appSettings/selectors'

import styles from './index.module.scss'

const LanguagesSelector: FC = () => {
    const { i18n, t } = useTranslation()
    const useCrowdin = useAppSelector($useCrowdin)

    const [searchValue, setSearchValue] = useState('')

    const changeLanguage = useCallback((lng: ValueType) => {
        setI18nLang(lng.toString()).then(() => {
            window.location.reload()
        })
    }, [])

    const languagesOpts = useMemo(
        () => getSelectableLanguages(useCrowdin).map((ln): OptionType => ({ value: ln.value, label: ln.name })),
        [useCrowdin]
    )

    const filteredOptions = useMemo(() => {
        return searchValue === ''
            ? languagesOpts
            : languagesOpts.filter((item) => {
                  const label = item.label as string
                  return label?.toLowerCase().includes(searchValue.toLowerCase())
              })
    }, [searchValue, languagesOpts])

    const selectedOption = useMemo(() => {
        return languagesOpts.find((item) => item.value === i18n.language)
    }, [i18n.language, languagesOpts])

    return (
        <SimpleDropdown
            value={i18n.language}
            options={filteredOptions}
            onChange={changeLanguage}
            title={
                <Flex direction='column' gap={4}>
                    <InputField
                        className={styles.Input}
                        value={searchValue}
                        onChange={setSearchValue}
                        suffix={
                            <Flex alignItems='center' gap={8}>
                                {searchValue && <Separator width='1px' height='24px' color='gray-500' />}
                                <Icon name='search' size={18} />
                            </Flex>
                        }
                        placeholder={t('general.search')}
                        reset
                        id='incicator-name'
                    />
                    {filteredOptions.length === 0 && (
                        <EmptyState
                            classNames={{ root: styles.EmptyStateWrapper }}
                            title={t('general.noResultsFound')}
                            description={t('general.anyStringMatching')}
                        />
                    )}
                </Flex>
            }
            classNames={{ body: styles.Container, root: styles.ListRoot }}
        >
            <Flex alignItems='center' justifyContent='center' gap={8} className={styles.Header}>
                <Typography color='gray-1000'>{selectedOption?.label || i18n.language}</Typography>
                <Icon name='chevron-down' size={12} color={'var(--color-gray-1000)'} />
            </Flex>
        </SimpleDropdown>
    )
}

export default LanguagesSelector
