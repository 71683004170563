import { t } from 'i18next'
import CommonConstants from '../ft_types/common/CommonConstants'
import DateConversionError from './DateTimeAuxiliary/DateConversionError'
import { EDayOfWeek } from './DateTimeAuxiliary/EDayOfWeek'
import TDateDiff from './DateTimeAuxiliary/TDateDiff'

export type TDateTime = number

interface IDateTimeReplacements {
    [key: string]: string | undefined

    yyyy?: string
    MMMM?: string
    MMM?: string
    mmm?: string
    MM?: string
    mm?: string
    dddd?: string
    ddd?: string
    dd?: string
    d?: string
    HH?: string
    hh?: string
    nn?: string
    ss?: string
    tt?: string
}

export interface IDayInfo {
    dayStartMs: number
    dayEndMs: number
}

export class DateUtils {
    private static readonly DelphiBaseDate = new Date('1899-12-30T00:00:00Z')
    private static readonly TDateTimeUnixEpochStartDifference = 25569
    // Constants for TDateTime calculations
    public static readonly MillisecondsPerDay = 24 * 60 * 60 * 1000
    public static readonly SecondsPerDay = 24 * 60 * 60
    public static readonly SecondsPerMinute = 60
    public static readonly MinutesPerHour = 60
    public static readonly SecondsPerHour = 60 * 60
    public static readonly MinutesPerDay = 24 * 60
    public static readonly HoursPerDay = 24
    public static readonly DaysPerWeek = 7
    public static readonly MinutesPerWeek = DateUtils.MinutesPerDay * DateUtils.DaysPerWeek
    public static readonly OneMillisecond = 1 / DateUtils.MillisecondsPerDay
    public static readonly OneSecond = DateUtils.OneMillisecond * 1000
    public static readonly OneMinute = DateUtils.OneSecond * 60
    public static readonly OneHour = DateUtils.OneMinute * 60
    public static readonly OneDay = 1 // One full day in TDateTime
    public static readonly OneWeek = 7 // One full week in TDateTime
    public static readonly OneYear_Approx = 365 // One full year in TDateTime
    public static readonly OneMonth_Approx = 30 // One full month in TDateTime
    private static localeStringCache = new Map<string, string>()
    private static dateTimeCache = new Map<string, Date>()
    public static readonly EmptyDate = -693594 // 1/1/0001
    public static readonly VeryBigDate = 2958465 // 12/31/9999

    public static readonly Sunday = 0
    public static readonly Monday = 1
    public static readonly Tuesday = 2
    public static readonly Wednesday = 3
    public static readonly Thursday = 4
    public static readonly Friday = 5
    public static readonly Saturday = 6
    public static readonly MinPossibleDate = this.EncodeDate(1, 1, 1)
    public static readonly MaxPossibleDate = this.EncodeDate(9999, 12, 31)

    public static Now(): TDateTime {
        return (Date.now() - DateUtils.DelphiBaseDate.getTime()) / DateUtils.MillisecondsPerDay
    }

    // Adjusting FromDate and ToDate to work with UTC
    public static FromDate(date: Date): TDateTime {
        return (date.getTime() - DateUtils.DelphiBaseDate.getTime()) / DateUtils.MillisecondsPerDay
    }

    public static EncodeDateTime(
        year: number,
        month: number,
        day: number,
        hour = 0,
        minute = 0,
        second = 0,
        millisecond = 0
    ): TDateTime {
        const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second, millisecond))
        return DateUtils.FromDate(date)
    }

    public static MinutesBetween(ANow: TDateTime, AThen: TDateTime): number {
        const MinsPerDay = 1440
        const spn = Math.abs((ANow - AThen) * MinsPerDay)
        return Math.round(spn)
    }

    public static EncodeTime(hour = 0, minute = 0, second = 0, millisecond = 0): TDateTime {
        const time = new Date(DateUtils.DelphiBaseDate)
        time.setUTCHours(hour, minute, second, millisecond)
        return (time.getTime() - DateUtils.DelphiBaseDate.getTime()) / DateUtils.MillisecondsPerDay
    }

    public static EncodeDate(
        year: number,
        month: number,
        day: number,
        hour = 0,
        minute = 0,
        second = 0,
        millisecond = 0
    ): TDateTime {
        const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second, millisecond))
        return (date.getTime() - DateUtils.DelphiBaseDate.getTime()) / DateUtils.MillisecondsPerDay
    }

    // Adjusting DecodeDate to use UTC methods
    public static DecodeDate(dateTime: TDateTime): {
        year: number
        month: number
        day: number
    } {
        if (DateUtils.IsEmpty(dateTime)) return { year: 0, month: 0, day: 0 }
        const date = this.ToDate(dateTime)
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1, // JavaScript months are 0-based
            day: date.getUTCDate()
        }
    }

    public static DecodeTime(dateTime: TDateTime): {
        hour: number
        minute: number
        second: number
        millisecond: number
    } {
        const date = this.ToDate(dateTime)
        return {
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes(),
            second: date.getUTCSeconds(),
            millisecond: date.getUTCMilliseconds()
        }
    }

    //should return time in UTC if no timezone is specified
    public static StrToDateTime(dateStr: string): TDateTime {
        // Check if the date string has no timezone information
        const hasTimeZone = /[+Z-]/.test(dateStr)

        // If no timezone is present, assume UTC by appending 'Z'
        const utcDateStr = hasTimeZone ? dateStr : `${dateStr}Z`

        const parsedDate = new Date(utcDateStr)

        if (isNaN(parsedDate.getTime())) {
            throw new DateConversionError('Invalid date/time format')
        } else {
            if (dateStr.includes('0000.00.00')) return DateUtils.EmptyDate
            // Return the date components as UTC
            return DateUtils.EncodeDate(
                parsedDate.getUTCFullYear(),
                parsedDate.getUTCMonth() + 1,
                parsedDate.getUTCDate(),
                parsedDate.getUTCHours(),
                parsedDate.getUTCMinutes(),
                parsedDate.getUTCSeconds(),
                parsedDate.getUTCMilliseconds()
            )
        }
    }

    //just alias
    public static FromString(dateInString: string): TDateTime {
        return this.StrToDateTime(dateInString)
    }

    public static DayOfMonth(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCDate()
    }

    // Get the day of the week (0-6, where 0 is Sunday and 6 is Saturday)
    public static DayOfWeek(dateTime: TDateTime): EDayOfWeek {
        const date = this.ToDate(dateTime)

        switch (date.getUTCDay()) {
            case 0: {
                return EDayOfWeek.Sunday
            }
            case 1: {
                return EDayOfWeek.Monday
            }
            case 2: {
                return EDayOfWeek.Tuesday
            }
            case 3: {
                return EDayOfWeek.Wednesday
            }
            case 4: {
                return EDayOfWeek.Thursday
            }
            case 5: {
                return EDayOfWeek.Friday
            }
            case 6: {
                return EDayOfWeek.Saturday
            }
            default: {
                throw new DateConversionError('Invalid day of the week')
            }
        }
    }

    public static MonthOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCMonth() + 1 // JavaScript months are 0-based
    }

    public static YearOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCFullYear()
    }

    public static WeekOf(dateTime: TDateTime): number {
        const firstDayOfYear = new Date(this.YearOf(dateTime), 0, 1)
        const pastDaysOfYear = (this.ToDate(dateTime).getTime() - firstDayOfYear.getTime()) / 86400000
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay()) / 7)
    }

    public static HourOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCHours()
    }

    public static MinuteOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCMinutes()
    }

    public static SecondOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCSeconds()
    }

    public static MilliSecondOf(dateTime: TDateTime): number {
        return this.ToDate(dateTime).getUTCMilliseconds()
    }

    public static CompareDate(dateTime1: TDateTime, dateTime2: TDateTime): number {
        const date1 = this.DecodeDate(dateTime1)
        const date2 = this.DecodeDate(dateTime2)

        if (date1.year !== date2.year) return date1.year < date2.year ? -1 : 1
        if (date1.month !== date2.month) return date1.month < date2.month ? -1 : 1
        if (date1.day !== date2.day) return date1.day < date2.day ? -1 : 1

        return 0
    }

    public static CompareTime(dateTime1: TDateTime, dateTime2: TDateTime): number {
        const time1 = this.DecodeTime(dateTime1)
        const time2 = this.DecodeTime(dateTime2)

        if (time1.hour !== time2.hour) return time1.hour < time2.hour ? -1 : 1
        if (time1.minute !== time2.minute) return time1.minute < time2.minute ? -1 : 1
        if (time1.second !== time2.second) return time1.second < time2.second ? -1 : 1
        if (time1.millisecond !== time2.millisecond) return time1.millisecond < time2.millisecond ? -1 : 1

        return 0
    }

    public static IsLeapYear(year: number): boolean {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
    }

    public static StartOfTheYear(year: number): TDateTime {
        return this.FromDate(new Date(Date.UTC(year, 0, 1)))
    }

    public static EndOfTheYear(year: number): TDateTime {
        return this.FromDate(new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999)))
    }

    public static StartOfTheMonth(dateTime: TDateTime): TDateTime {
        const { year, month } = this.DecodeDate(dateTime)
        return this.FromDate(new Date(Date.UTC(year, month - 1, 1)))
    }

    public static EndOfTheMonth(dateTime: TDateTime): TDateTime {
        const { year, month } = this.DecodeDate(dateTime)
        return this.FromDate(new Date(Date.UTC(year, month, 0, 23, 59, 59, 999)))
    }

    public static StartOfTheWeek(dateTime: TDateTime): TDateTime {
        const date = this.ToDate(dateTime)
        const day = date.getUTCDay() // Sunday - 0, Monday - 1, etc.
        const diff = date.getUTCDate() - day // Adjust to previous Sunday
        return this.FromDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff)))
    }

    public static EndOfTheWeek(dateTime: TDateTime): TDateTime {
        const date = this.ToDate(dateTime)
        const day = date.getUTCDay() // Sunday - 0, Monday - 1, etc.
        const diff = date.getUTCDate() - day + 6 // Adjust to next Saturday
        return this.FromDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff, 23, 59, 59, 999)))
    }

    public static StartOfTheDay(dateTime: TDateTime): TDateTime {
        return this.EncodeDate(this.YearOf(dateTime), this.MonthOf(dateTime), this.DayOfMonth(dateTime))
    }

    public static StartOfTheMinute(dateTime: TDateTime): TDateTime {
        return this.EncodeDateTime(
            this.YearOf(dateTime),
            this.MonthOf(dateTime),
            this.DayOfMonth(dateTime),
            this.HourOf(dateTime),
            this.MinuteOf(dateTime)
        )
    }

    public static StartOfTheSecond(dateTime: TDateTime): TDateTime {
        return this.EncodeDateTime(
            this.YearOf(dateTime),
            this.MonthOf(dateTime),
            this.DayOfMonth(dateTime),
            this.HourOf(dateTime),
            this.MinuteOf(dateTime),
            this.SecondOf(dateTime)
        )
    }

    public static EndOfTheDay(dateTime: TDateTime): TDateTime {
        return this.EncodeDateTime(
            this.YearOf(dateTime),
            this.MonthOf(dateTime),
            this.DayOfMonth(dateTime),
            23,
            59,
            59,
            999
        )
    }

    public static IsToday(dateTime: TDateTime): boolean {
        const today = new Date()
        const date = this.ToDate(dateTime)
        return (
            date.getUTCDate() === today.getUTCDate() &&
            date.getUTCMonth() === today.getUTCMonth() &&
            date.getUTCFullYear() === today.getUTCFullYear()
        )
    }

    public static IsSameDay(dateTime1: TDateTime, dateTime2: TDateTime): boolean {
        const date1 = Math.trunc(dateTime1)
        const date2 = Math.trunc(dateTime2)

        return date1 === date2
    }

    public static DaysInMonth(dateTime: TDateTime): number {
        const { year, month } = this.DecodeDate(dateTime)
        return new Date(Date.UTC(year, month, 0)).getUTCDate()
    }

    public static DaysInYear(year: number): number {
        return this.IsLeapYear(year) ? 366 : 365
    }

    public static IncMilliSecond(dateTime: TDateTime, milliseconds: number): TDateTime {
        return dateTime + milliseconds * DateUtils.OneMillisecond
    }

    public static IncSecond(dateTime: TDateTime, seconds: number): TDateTime {
        return dateTime + seconds * DateUtils.OneSecond
    }

    public static IncMinute(dateTime: TDateTime, minutes: number): TDateTime {
        return dateTime + minutes * DateUtils.OneMinute
    }

    public static IncHour(dateTime: TDateTime, hours: number): TDateTime {
        return dateTime + hours * DateUtils.OneHour
    }

    public static IncDay(dateTime: TDateTime, days: number): TDateTime {
        return dateTime + days * DateUtils.OneDay
    }

    public static IncMonth(dateTime: TDateTime, months: number): TDateTime {
        const date = new Date(DateUtils.ToDate(dateTime))
        date.setUTCMonth(date.getUTCMonth() + months)
        return DateUtils.FromDate(date)
    }

    public static IncYear(dateTime: TDateTime, years: number): TDateTime {
        const date = new Date(DateUtils.ToDate(dateTime))
        date.setUTCFullYear(date.getUTCFullYear() + years)
        return DateUtils.FromDate(date)
    }

    public static fromUnixTimeSeconds(unixTimeSeconds: number): TDateTime {
        return (
            this.RoundTillNearestSeconds(unixTimeSeconds * 1000 - DateUtils.DelphiBaseDate.getTime()) /
            DateUtils.MillisecondsPerDay
        )
    }

    public static fromUnixTimeMilliseconds(unixTimeMilliseconds: number): TDateTime {
        return (unixTimeMilliseconds - DateUtils.DelphiBaseDate.getTime()) / DateUtils.MillisecondsPerDay
    }

    public static toUnixTimeSeconds(dateTime: TDateTime): number {
        const milliseconds = this.toUnixTimeMilliseconds(dateTime)

        return Math.round(milliseconds / 1000)
    }

    //The getTime() method in JavaScript returns the number of milliseconds since January 1, 1970 (UTC).
    //So, when we call getTime() on a Date object, it gives us the timestamp in UTC milliseconds
    public static toUnixTimeMillisecondsUTC(dateTime: TDateTime): number {
        return DateUtils.ToDate(dateTime).getTime()
    }

    public static toUnixTimeMilliseconds(dateTime: TDateTime): number {
        return DateUtils.ToDate(dateTime).getTime()
    }

    public static fromJSDateToUnixSeconds(date: Date): number {
        // Convert JavaScript Date to Unix timestamp in seconds
        return Math.floor(date.getTime() / 1000)
    }

    public static fromUnixSecondsToJSDate(unixSeconds: number): Date {
        // Convert Unix timestamp in seconds to JavaScript Date
        //this should return the date in UTC
        return new Date(unixSeconds * 1000)
    }

    public static ToDate(dateTime: TDateTime): Date {
        if (this.dateTimeCache.size > 50) {
            this.dateTimeCache.clear()
        }

        const cacheKey = dateTime.toString()
        if (this.dateTimeCache.has(cacheKey)) {
            const cachedDate = this.dateTimeCache.get(cacheKey)

            if (cachedDate) {
                return new Date(cachedDate.getTime())
            }
        }

        const dateInLocalTimezone = this.ToDateWithLocalTimezoneStamp(dateTime)
        const dateInUTC = new Date(
            Date.UTC(
                dateInLocalTimezone.getUTCFullYear(),
                dateInLocalTimezone.getUTCMonth(),
                dateInLocalTimezone.getUTCDate(),
                dateInLocalTimezone.getUTCHours(),
                dateInLocalTimezone.getUTCMinutes(),
                dateInLocalTimezone.getUTCSeconds(),
                dateInLocalTimezone.getUTCMilliseconds()
            )
        )

        this.dateTimeCache.set(cacheKey, dateInUTC)

        return dateInUTC
    }

    public static ToDateWithLocalTimezoneStamp(dateTime: TDateTime): Date {
        const millisecondsUnrounded = dateTime * DateUtils.MillisecondsPerDay
        const milliseconds = Math.round(millisecondsUnrounded)
        return new Date(DateUtils.DelphiBaseDate.getTime() + milliseconds)
    }

    public static getLocaleString(date: Date, options: Intl.DateTimeFormatOptions): string {
        const cacheKey = `${date.toISOString()}-${JSON.stringify(options)}`
        if (this.localeStringCache.has(cacheKey)) {
            return this.localeStringCache.get(cacheKey)!
        }

        const localeString = date.toLocaleString(undefined, options)
        this.localeStringCache.set(cacheKey, localeString)
        return localeString
    }

    //Debug formatting
    public static DF(dateTime: TDateTime, timeFormat?: string): string {
        if (timeFormat) {
            return this.FormatDateTime(timeFormat, dateTime)
        } else {
            return this.FormatDateTime('yyyy-MM-dd HH:nn:ss', dateTime)
        }
    }

    public static FormatDateTime(format: string, dateTime: TDateTime, dateSeparator = '', timeSeparator = ''): string {
        const date = DateUtils.ToDate(dateTime)

        const padZero = (num: number, length = 2) => num.toString().padStart(length, '0')

        const replacements: IDateTimeReplacements = {}

        if (/(YYYY|yyyy)/.test(format)) {
            replacements['yyyy'] = date.getUTCFullYear().toString()
            replacements['YYYY'] = date.getUTCFullYear().toString()
        }
        if (/(MMMM|MMM|mmm)/.test(format)) {
            replacements['MMMM'] = this.getLocaleString(date, {
                month: 'long',
                timeZone: 'UTC'
            })
            replacements['MMM'] = replacements['mmm'] = this.getLocaleString(date, { month: 'short', timeZone: 'UTC' })
        }
        if (/(MM|mm)/.test(format)) {
            replacements['MM'] = replacements['mm'] = padZero(date.getUTCMonth() + 1)
        }
        if (/(DDDD|dddd)/.test(format)) {
            replacements['DDDD'] = replacements['dddd'] = this.getLocaleString(date, {
                weekday: 'long',
                timeZone: 'UTC'
            })
        }
        if (/(DDD|ddd)/.test(format)) {
            replacements['DDD'] = replacements['ddd'] = this.getLocaleString(date, {
                weekday: 'short',
                timeZone: 'UTC'
            })
        }
        if (/(DD|dd|d)/.test(format)) {
            replacements['DD'] = padZero(date.getUTCDate())
            replacements['dd'] = padZero(date.getUTCDate())
            replacements['d'] = date.getUTCDate().toString()
        }

        if (/(HH|hh)/.test(format)) {
            let hours = date.getUTCHours()
            replacements['HH'] = padZero(hours)
            if (hours > 12) hours -= 12
            if (hours === 0) hours = 12 // Midnight is 12 AM
            replacements['hh'] = padZero(hours)
        }
        if (/nn/.test(format)) {
            replacements['nn'] = padZero(date.getUTCMinutes())
        }
        if (/ss/.test(format)) {
            replacements['ss'] = padZero(date.getUTCSeconds())
        }
        if (/fff/.test(format)) {
            replacements['fff'] = padZero(date.getUTCMilliseconds(), 3)
        }
        if (/tt/.test(format)) {
            replacements['tt'] = date.getUTCHours() >= 12 ? 'PM' : 'AM'
        }

        // Replace specifiers in a single pass
        format = format.replaceAll(
            /(YYYY|yyyy|MMMM|MMM|mmm|MM|mm|DDDD|dddd|DDD|ddd|DD|dd|d|HH|hh|nn|ss|fff|tt)/g,
            (match) => {
                return replacements[match] || match
            }
        )

        // Replace custom separators
        if (dateSeparator !== '') {
            format = format.replaceAll('.', dateSeparator)
        }

        if (timeSeparator !== '') {
            format = format.replaceAll(':', timeSeparator)
        }

        return format
    }

    /**
     * Checks if a date falls within a specified range
     * @param date TDateTime - The date to check
     * @param startDate TDateTime - The start of the range
     * @param endDate TDateTime - The end of the range
     * @returns boolean - True if the date is within the range, false otherwise
     */
    public static InRange(date: TDateTime, startDate: TDateTime, endDate: TDateTime): boolean {
        // Ensure the date is within the range [startDate, endDate]
        return date >= startDate && date <= endDate
    }

    public static AreEqual(dateA: number, dateB: number): boolean {
        return Math.abs(dateA - dateB) < CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME
    }

    public static MoreOrEqual(dateA: number, dateB: number): boolean {
        return dateA > dateB || this.AreEqual(dateA, dateB)
    }

    public static LessOrEqual(dateA: number, dateB: number): boolean {
        return dateA < dateB || this.AreEqual(dateA, dateB)
    }

    public static IsValidDateRange(firstDate: TDateTime, lastDate: TDateTime): boolean {
        return (
            !DateUtils.IsEmpty(firstDate) && !DateUtils.IsEmpty(lastDate) && DateUtils.MoreOrEqual(lastDate, firstDate)
        )
    }

    public static DateTimeToString(value: TDateTime, format: string): string {
        return DateUtils.FormatDateTime(format, value)
    }

    public static IsEqualUpToSeconds(dateA: TDateTime, dateB: TDateTime): boolean {
        return Math.abs(dateA - dateB) < DateUtils.OneSecond
    }

    public static SubtractSeconds(dateTime: TDateTime, seconds: number): TDateTime {
        return dateTime - seconds * DateUtils.OneSecond
    }

    public static GetDayInfo(delphiTime: TDateTime): IDayInfo {
        const startOfDay = (Math.trunc(delphiTime) - this.TDateTimeUnixEpochStartDifference) * this.MillisecondsPerDay
        const endOfDay = startOfDay + this.MillisecondsPerDay - 1

        return {
            dayStartMs: startOfDay,
            dayEndMs: endOfDay
        }
    }

    public static DifferenceInMilliseconds(dateA: TDateTime, dateB: TDateTime): number {
        const date1: Date = this.ToDate(dateA)
        const date2: Date = this.ToDate(dateB)

        return date1.getTime() - date2.getTime()
    }

    public static GetDateWithNoTime(dateTime: TDateTime): TDateTime {
        return Math.trunc(dateTime)
    }

    // Round the TDateTime value to the nearest second
    public static RoundTillNearestSeconds(dateTime: TDateTime): TDateTime {
        const totalSeconds = dateTime * DateUtils.SecondsPerDay
        const roundedSeconds = Math.round(totalSeconds)
        return roundedSeconds / DateUtils.SecondsPerDay
    }

    // Round the TDateTime value to the nearest minute
    public static RoundTillNearestMinutes(dateTime: TDateTime): TDateTime {
        const totalMinutes = dateTime * DateUtils.MinutesPerDay
        const roundedMinutes = Math.round(totalMinutes)
        return roundedMinutes / DateUtils.MinutesPerDay
    }

    // Round the TDateTime value to the nearest hour
    public static RoundTillNearestHours(dateTime: TDateTime): TDateTime {
        const totalHours = dateTime * DateUtils.HoursPerDay
        const roundedHours = Math.round(totalHours)
        return roundedHours / DateUtils.HoursPerDay
    }

    public static GetTimeDifferenceStr(tm: TDateTime, showMinutes = true): string {
        let mm: number
        let s = ''

        mm = DateUtils.MinutesBetween(0, tm)
        const hh = Math.floor(mm / 60)
        mm = mm % 60
        if (hh > 0) {
            if (showMinutes) {
                s = t('timeFormat.hoursMinutesShort', { hh, mm })
            } else {
                s = t('timeFormat.hoursShort', { hh })
            }
        } else {
            s = t('timeFormat.minutesShort', { mm })
        }

        return s
    }

    public static AbsDiffInDays(date1: TDateTime, date2: TDateTime): number {
        return new TDateDiff(Math.abs(date1 - date2)).getDifferenceInDays()
    }

    public static DiffInDays(date1: TDateTime, date2: TDateTime): number {
        return new TDateDiff(date1 - date2).getDifferenceInDays()
    }

    public static GetDateDiff(date1: TDateTime, date2: TDateTime): TDateDiff {
        return new TDateDiff(date1 - date2)
    }

    public static GetSecondsFromMilliseconds(ms: number): number {
        return ms / 1000
    }

    public static IsEmpty(date: TDateTime): boolean {
        return date <= 0 //this includes EmptyDate
    }

    public static GetTimeOnly(dateTime: TDateTime): TDateTime {
        return dateTime % 1
    }

    public static GetFirstDayOfMonthUTC(year: number, month: number, dayOfWeek: number): Date {
        const date = new Date(Date.UTC(year, month, 1))
        while (date.getUTCDay() !== dayOfWeek) {
            date.setUTCDate(date.getUTCDate() + 1)
        }
        return date
    }

    public static GetLastDayOfMonthUTC(year: number, month: number, dayOfWeek: number): Date {
        const date = new Date(Date.UTC(year, month + 1, 0))
        while (date.getUTCDay() !== dayOfWeek) {
            date.setUTCDate(date.getUTCDate() - 1)
        }
        return date
    }
}
