export type User = {
    AccountType: 'BetaTester'
    subscriptionStatus: 'Unknown' | 'Canceled' | 'Active'
    subscriptionId: number
    isTrialExpired: boolean
    customAuth: boolean
    tenantId: string[]
    email: string
    role: Roles
    displayName: string
    trialPeriodEnd: string
    userId: string
    IsNewUser: boolean
}

export enum Roles {
    TRANSLATOR = 'Translator',
    DEVELOPER = 'Developer'
}
