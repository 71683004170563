import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { LOGIN_ROUTE, makeRoute, RESET_PASSWORD_ROUTE } from '@root/constants/routes'
import { fireMixpanelEvent, requestCode } from '@root/utils/api'
import { Button, Flex, InputField, Typography, Link } from '@fto/ui'
import useEmailField from '@root/hooks/useEmailField'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import MessageInformer from '@root/components/MessageInformer'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'
import useDebouncedEnterSubmit from '@root/hooks/useDebouncedEnterSubmit'

const ForgotPassword = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const {
        email,
        handleEmailChange,
        validationData: emailValidationData,
        validate
    } = useEmailField({ initialValue: '' })

    const resetPassword = useCallback(async () => {
        const isValid = await validate(email)
        if (!isValid) return

        setIsLoading(true)
        requestCode({
            email,
            type: 'forgotpassword'
        })
            .then(() => {
                const hash = window.btoa(email as string)
                navigate(`${makeRoute(RESET_PASSWORD_ROUTE)}?hash=${hash}`)
            })
            .catch((err) => {
                if (err instanceof AxiosError) {
                    setError(err.response?.data?.status)
                }

                if (err.status) {
                    setError(err.status)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [email])

    useDebouncedEnterSubmit(resetPassword)

    const errorMessages = useMemo(
        () => ({
            ...statusMessages,
            invalid_email: t('auth.errors.forgotPassword.shouldBeValid')
        }),
        []
    )

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'forgot_password' })
    }, [])

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h4-semibold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.forgotPasswordTitle')}
                        </Typography>
                        <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            {t('auth.forgotPasswordText')}
                        </Typography>
                    </Flex>
                    <form>
                        <Flex direction={'column'} gap={24}>
                            <InputField
                                isAbsoluteHelperText
                                error={!emailValidationData.isValid}
                                helperText={emailValidationData.errorMessage}
                                block
                                type={'email'}
                                value={email}
                                onChange={handleEmailChange}
                                label={t('auth.email')}
                                placeholder={t('auth.email')}
                            />
                            <MessageInformer type='error' isShown={!!error} onClose={() => setError(null)} disappearing>
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    {t(errorMessages[error || 'unknown'])}
                                </Typography>
                            </MessageInformer>
                            <Button
                                loading={isLoading}
                                type={'marketing'}
                                disabled={!emailValidationData.isValid || !email}
                                block
                                onClick={resetPassword}
                                label={t('global.continue')}
                            />
                        </Flex>
                    </form>
                    <Flex justifyContent={'center'}>
                        <Link to={makeRoute(LOGIN_ROUTE)}>{t('auth.backToLogin')}</Link>
                    </Flex>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default ForgotPassword
