import { TypeAvailableTypes } from '@root/pages/ChartPage/components/CustomTimeframeModal/utils/getOptions'

export const convertToMinutes = (value: number, unit: TypeAvailableTypes): number => {
    const minutesPerHour = 60
    const hoursPerDay = 24
    const daysPerWeek = 7
    const daysPerMonth = 30
    // const daysPerYear = 365

    switch (unit) {
        case 'hours':
            return value * minutesPerHour
        case 'days':
            return value * hoursPerDay * minutesPerHour
        case 'weeks':
            return value * daysPerWeek * hoursPerDay * minutesPerHour
        case 'months':
            return value * daysPerMonth * hoursPerDay * minutesPerHour
        // case 'years':
        //     return value * daysPerYear * hoursPerDay * minutesPerHour
        default:
            return value
    }
}
