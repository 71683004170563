import { FC, Fragment, useEffect, useMemo, useState } from 'react'

import { CHECKOUT_PAGE, DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $getUserSubscriptionStatus } from '@root/store/auth/selectors'

import styles from './index.module.scss'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { PaymentFailedModal } from './components/Modals/PaymentFailedModal'
import { useNavigate } from 'react-router-dom'
import { AppHeader } from '@root/components/AppHeader'
import { fireMixpanelEvent } from '@root/utils/api'
import { getLocalStorage } from '@root/utils/localStorage'
import { CONVERSION_PARAMS_FROM_SITES } from '@root/constants/localStorageKeys'

const meta = import.meta.env

type PlanType = 'monthly' | 'annual' | 'lifetime'

type ConversionDataType = {
    Landing_subscription_selected?: string
} | null

const isNonTrial = true

const CheckoutPage: FC = () => {
    const [plan, setPlan] = useState<PlanType>('monthly')

    const { userId, email, displayName } = useAppSelector($getUser)

    const { isTrial, isActive } = useAppSelector($getUserSubscriptionStatus)
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)

    /* #NOTE: commented code for subscriptions with trial (#TRIAL) */
    // const trial = params.get('trial')
    // const isNonTrial = (trial && trial === 'false') || isTrialExpired

    const conversionData: ConversionDataType = useMemo(() => getLocalStorage(CONVERSION_PARAMS_FROM_SITES), [])

    useEffect(() => {
        // TODO: add logic to change plan type (monthly, annual, lifetime) and add logic to get play type after blocked page (subscription renewal)
        const planData = conversionData?.['Landing_subscription_selected']

        if (conversionData && planData) {
            const [planPeriod, _] = planData.split('_')

            planPeriod && setPlan(planPeriod as PlanType)
        }
    }, [conversionData])

    const productId = useMemo(() => {
        switch (plan) {
            case 'monthly': {
                return isNonTrial ? meta.VITE_PAY_PRO_MONTHLY_ID_NON_TRIAL : meta.VITE_PAY_PRO_MONTHLY_ID_WITH_TRIAL
            }
            case 'annual': {
                return isNonTrial ? meta.VITE_PAY_PRO_ANNUAL_ID_NON_TRIAL : meta.VITE_PAY_PRO_ANNUAL_ID_WITH_TRIAL
            }
            case 'lifetime': {
                return isNonTrial ? meta.VITE_PAY_PRO_LIFETIME_ID_NON_TRIAL : meta.VITE_PAY_PRO_LIFETIME_ID_WITH_TRIAL
            }
            default: {
                return isNonTrial ? meta.VITE_PAY_PRO_MONTHLY_ID_NON_TRIAL : meta.VITE_PAY_PRO_MONTHLY_ID_WITH_TRIAL
            }
        }
    }, [plan, isNonTrial])

    const pageTemplate = useMemo(
        () => (isNonTrial ? meta.VITE_PAYPRO_PAGE_TEMPLATE_NON_TRIAL : meta.VITE_PAYPRO_PAGE_TEMPLATE),
        [isNonTrial]
    )

    const encodedEmail = useMemo(() => encodeURIComponent(email), [email])

    const encodedFullName = useMemo(() => {
        const fullName = displayName.split(' ')

        return [encodeURIComponent(fullName[0]), encodeURIComponent(fullName[1])]
    }, [displayName])

    const customFields = useMemo(() => {
        let sendPulseParams = ''

        if (conversionData) {
            Object.entries(conversionData).forEach(([key, value]) => {
                sendPulseParams += `&x-${key}=${encodeURIComponent(value)}`
            })
        }

        return sendPulseParams
    }, [conversionData])

    useEffect(() => {
        if (isTrial || isActive) {
            navigate(makeRoute(DASHBOARD_ROUTE))
        }
        const actionType = params.get('action')
        if (actionType && actionType === 'payment') {
            addModal(MODAL_NAMES.payment.failed)
            navigate(makeRoute(CHECKOUT_PAGE))
        }
    }, [isTrial, isActive])

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'checkout' })

        const callback = (event: { origin: string; data: { type: string; url: string } }) => {
            if (event.origin !== meta.VITE_SERVER_PAYMENT_REDIRECT_URL) {
                return // Ignore messages from untrusted origins
            }

            const actionType = params.get('action')

            if (event.data.type === 'REDIRECT' && !actionType) {
                window.location.href = makeRoute(DASHBOARD_ROUTE + '?action=payment&success=true')
            }
        }
        window.addEventListener('message', callback, false)

        return () => {
            window.removeEventListener('message', callback)
        }
    }, [])

    const paymentSrc = useMemo(() => {
        // NOTE: this is real payment link

        if (meta.VITE_ENV_TYPE === 'staging') {
            return `https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${productId}&page-template=${pageTemplate}&x-user=${userId}&billing-email=${encodedEmail}${customFields}`
        }

        return `https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${productId}&page-template=${pageTemplate}&x-user=${userId}&billing-email=${encodedEmail}&billing-first-name=${encodedFullName[0]}&billing-last-name=${encodedFullName[1]}${customFields}`
    }, [productId, pageTemplate, userId, encodedEmail, encodedFullName, customFields])

    return (
        <Fragment>
            <AppHeader isCheckout />
            <iframe className={styles.frame} src={paymentSrc} />
            <PaymentFailedModal />
        </Fragment>
    )
}

export default CheckoutPage
