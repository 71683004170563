export const CONTEXT_MENU_NAMES = {
    tool: 'tool',
    chart: 'chart',
    indicator: 'indicator',
    news: 'news',
    terminal: 'terminal',
    timeframeChange: 'timeframeChange',
    oneClickTrading: 'oneClickTrading',
    timeZones: 'timeZones'
}
