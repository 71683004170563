export const extractViewBoxValue = (str?: string) => {
    if (!str) {
        return ''
    }
    const viewBoxRegExp = /viewBox="([^"]*)"/

    // Regular expression to extract the viewBox value
    const match = str.match(viewBoxRegExp)

    // Extracted viewBox value
    return match ? match[1] : ''
}
