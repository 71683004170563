import {
    TCriteria,
    TCriteriaBase,
    TCriteriaOperator,
    TCriteriaRange,
    TCriteriaType,
    TTutorialTask
} from '@fto/lib/Education/Types'
import { TutorialCheckBase } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TutorialCheckBase'
import { TotalPNL } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TotalPNL'
import { NumberOfClosedOrders } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/NumberOrClosedOrders'
import { NumberOfOpenedOrders } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/NumberOfOpenedOrders'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TimeRangeForCompletedTaskCheck } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TimeRangeCheck'
import { FirstOrderOpenPrice } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/FirstOrderOpenPrice'
import { StatisticsChecks } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/StatisticsChecks'

export class TutorialTask {
    name: string
    criteria: TCriteriaBase[]
    trueResult: string
    falseResult: string
    description: string
    private isPassed?: boolean
    private criteriaStrategies: Array<TutorialCheckBase> = []
    public timeRangeForCompletedTaskCheck: TimeRangeForCompletedTaskCheck | null = null

    constructor(name: string, criteria: TCriteriaBase[], trueResult: string, falseResult: string, description: string) {
        this.name = name
        this.description = description
        this.criteria = criteria
        this.trueResult = trueResult
        this.falseResult = falseResult
        this.createStrategiesForAllCriterias()
    }

    public pass(): void {
        this.isPassed = true
    }

    public fail(): void {
        this.isPassed = false
    }

    public reset(): void {
        this.isPassed = undefined
    }

    public isCheckPassed(): boolean {
        return this.isPassed === true
    }

    private getRangeValue(criteria: TCriteriaRange): { valueStart: number; valueEnd: number } {
        return {
            valueStart: criteria.valueStart,
            valueEnd: criteria.valueEnd
        }
    }

    private getCorrespondingCriteriaStrategy(criteria: TCriteriaBase): TutorialCheckBase | void {
        switch (criteria.criteriaType) {
            case TCriteriaType.TOTAL_PNL:
                if (criteria.operator === TCriteriaOperator.IN_RANGE) {
                    return new TotalPNL(criteria.operator, this.getRangeValue(criteria as TCriteriaRange))
                } else {
                    return new TotalPNL(criteria.operator, (criteria as TCriteria).value)
                }
            case TCriteriaType.NUMBER_OF_CLOSED_ORDERS:
                if (criteria.operator === TCriteriaOperator.IN_RANGE) {
                    return new NumberOfClosedOrders(criteria.operator, this.getRangeValue(criteria as TCriteriaRange))
                } else {
                    return new NumberOfClosedOrders(criteria.operator, (criteria as TCriteria).value)
                }
            case TCriteriaType.NUMBER_OF_OPENED_ORDERS:
                if (criteria.operator === TCriteriaOperator.IN_RANGE) {
                    return new NumberOfOpenedOrders(criteria.operator, this.getRangeValue(criteria as TCriteriaRange))
                } else {
                    return new NumberOfOpenedOrders(criteria.operator, (criteria as TCriteria).value)
                }
            case TCriteriaType.TIME_RANGE_FOR_COMPLETE_TASK:
                this.timeRangeForCompletedTaskCheck = new TimeRangeForCompletedTaskCheck({
                    valueStart: (criteria as TCriteriaRange).valueStart,
                    valueEnd: (criteria as TCriteriaRange).valueEnd
                })
                break
            case TCriteriaType.FIRST_ORDER_OPEN_PRICE:
                if (criteria.operator === TCriteriaOperator.IN_RANGE) {
                    return new FirstOrderOpenPrice(criteria.operator, this.getRangeValue(criteria as TCriteriaRange))
                } else {
                    return new FirstOrderOpenPrice(criteria.operator, (criteria as TCriteria).value)
                }
            case TCriteriaType.DAY_PROCESSED:
            case TCriteriaType.MONTH_PROCESSED:
            case TCriteriaType.TOTAL_TRADES:
            case TCriteriaType.PROFIT_TRADES:
            case TCriteriaType.LOSS_TRADES:
            case TCriteriaType.PROFIT_TRADES_CONS:
            case TCriteriaType.LOSS_TRADES_CONS:
            case TCriteriaType.TRADES_PER_DAY:
            case TCriteriaType.TRADES_PER_MONTH:
            case TCriteriaType.PROFIT_TRADES_PER_MONTH:
            case TCriteriaType.LOSS_TRADES_PER_MONTH:
            case TCriteriaType.MAX_PROFIT_TRADE:
            case TCriteriaType.MAX_LOSS_TRADE:
            case TCriteriaType.NET_PROFIT:
            case TCriteriaType.GROSS_PROFIT:
            case TCriteriaType.GROSS_LOSS:
            case TCriteriaType.PROFIT_PER_MONTH:
            case TCriteriaType.AVERAGE_PROFIT:
            case TCriteriaType.AVERAGE_LOSS:
            case TCriteriaType.MAX_DRAWDOWN:
            case TCriteriaType.PROFIT_FACTOR:
            case TCriteriaType.RETURN_PERCENTS:
            case TCriteriaType.MAX_LOT_USED:
            case TCriteriaType.RESTORATION_FACTOR:
            case TCriteriaType.RELIABILITY_FACTOR:
            case TCriteriaType.PROFIT_PROBABILITY:
            case TCriteriaType.LOSS_PROBABILITY:
            case TCriteriaType.BALANCE:
            case TCriteriaType.EQUITY:
            case TCriteriaType.MARGIN:
            case TCriteriaType.FREE_MARGIN:
                if (criteria.operator === TCriteriaOperator.IN_RANGE) {
                    return new StatisticsChecks(
                        criteria.operator,
                        this.getRangeValue(criteria as TCriteriaRange),
                        criteria.criteriaType
                    )
                } else {
                    return new StatisticsChecks(criteria.operator, (criteria as TCriteria).value, criteria.criteriaType)
                }
            default:
                return new TutorialCheckBase(criteria.operator, (criteria as TCriteria).value)
        }
    }

    public createStrategiesForAllCriterias(): void {
        this.criteria.forEach((criteria) => {
            const strategy = this.getCorrespondingCriteriaStrategy(criteria)

            if (strategy) {
                this.criteriaStrategies.push(strategy)
            }
        })
    }

    processCriteriaStrategies(): boolean | [boolean, string] {
        if (this.criteriaStrategies.length === 0 && this.isCheckPassed()) {
            return true
        }

        if (this.criteriaStrategies.length === 0) {
            return false
        }

        if (this.isPassed === undefined && this.criteriaStrategies.every((strategy) => strategy.check())) {
            this.pass()

            return true
        } else {
            if (this.timeRangeForCompletedTaskCheck?.checkMoreThan(GlobalProcessingCore.ProcessingCore.CurrTime)) {
                this.fail()

                return [false, 'timeRange']
            }
            return false
        }
    }

    public checkIfTaskInTimerRange(time?: number) {
        if (time) {
            return this.timeRangeForCompletedTaskCheck?.checkTimeRange(time)
        } else {
            return
        }
    }

    public toJSON(): TTutorialTask {
        return {
            name: this.name,
            criteria: this.criteria,
            description: this.description,
            trueResult: this.trueResult,
            falseResult: this.falseResult,
            isPassed: this.isPassed,
            checkInterval: 0
        }
    }
}
