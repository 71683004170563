import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export class USDQouteSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(operationType: TTradePositionType): number {
        return this.symbolData.symbolInfo.lot * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(operationType: TTradePositionType, openPrice: number | null): number {
        const priceForMarginCalculation = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        return (priceForMarginCalculation / GlobalProjectInfo.ProjectInfo.leverage) * this.symbolData.symbolInfo.lot
    }
}
