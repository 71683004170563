import * as XLSX from 'xlsx'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

export type TableData = {
    name: string
    data: any[]
}

export const downloadSpreadsheet = (tables: TableData[], filename: string, type: 'csv' | 'xlsx') => {
    const blob = type === 'csv' ? generateCSVBlob(tables) : generateXLSXBlob(tables)
    const url = URL.createObjectURL(blob)

    const filenameWithExtraInfo = formatFilename(filename, type)

    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', filenameWithExtraInfo)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const generateXLSXBlob = (tables: TableData[]): Blob => {
    const workbook = XLSX.utils.book_new()

    for (const table of tables) {
        if (table.data.length === 0) continue

        const worksheet = XLSX.utils.json_to_sheet(table.data)
        XLSX.utils.book_append_sheet(workbook, worksheet, table.name)
    }

    const xlsxArray = XLSX.write(workbook, { type: 'array' })
    return new Blob([xlsxArray], { type: 'application/octet-stream' })
}

const generateCSVBlob = (tables: TableData[]): Blob => {
    const csvRows: string[] = []

    for (const table of tables) {
        if (table.data.length === 0) continue
        csvRows.push(table.name)

        const headers = Object.keys(table.data[0])
        csvRows.push(headers.join(','))

        for (const row of table.data) {
            const values = headers.map((header) => {
                const escaped = ('' + row[header]).replace(/"/g, '""')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }

        csvRows.push('')
    }

    const csvData = csvRows.join('\n')
    return new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
}

const formatFilename = (filename: string, type: 'csv' | 'xlsx'): string => {
    const now = new Date()

    const day = String(now.getDate()).padStart(2, '0')
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const year = now.getFullYear()
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')

    const formattedDate = `${day}-${month}-${year}_${hours}-${minutes}`

    const projectName = GlobalProjectJSONAdapter.Instance.projectName

    return `${filename}_${projectName}_${formattedDate}.${type}`
}
