import { SymbolItemType } from '@root/types/symbols.types'

export type SymbolsCategoryType = {
    category: SymbolItemType['Category']
    symbols: SymbolItemType[]
}

const groupOrder = ['Majors', 'Crosses', 'Crypto', 'Exotic', 'Futures', 'Indexes', 'Metals', 'Stocks']

export const makeGroupedList = (symbols: SymbolItemType[]): SymbolsCategoryType[] => {
    const groupedSymbols: SymbolsCategoryType[] = []

    symbols.forEach((symbol) => {
        const category = symbol.Category

        if (!groupedSymbols.find((item) => item.category === category)) {
            groupedSymbols.push({
                category,
                symbols: []
            })
        }

        groupedSymbols.find((item) => item.category === category)?.symbols.push(symbol)
    })

    groupedSymbols.sort((a, b) => {
        return groupOrder.indexOf(a.category) - groupOrder.indexOf(b.category)
    })

    groupedSymbols.map((group) => group.symbols.sort((a, b) => a.Symbol.localeCompare(b.Symbol)))

    return groupedSymbols
}
