export enum NewsPriority {
    High = '2',
    Medium = '1',
    Low = '0'
}

export class News {
    Time: number
    Symbol: string
    Title: string
    Country: string
    Priority: NewsPriority
    Actual: string
    Consensus: string
    Previews: string
    IsPlanned: boolean
    coordinates: {
        centerX: number
        centerY: number
        radius: number
    } = {
        centerX: 0,
        centerY: 0,
        radius: 0
    }
    inFocusedState: boolean = false
    isSearched: boolean = false

    constructor(
        Time: number,
        Symbol: string,
        Title: string,
        Country: string,
        Priority: NewsPriority,
        Actual: string,
        Consensus: string,
        Previews: string,
        IsPlanned: boolean
    ) {
        this.Time = Time
        this.Symbol = Symbol
        this.Title = Title
        this.Country = Country
        this.Priority = Priority
        this.Actual = Actual
        this.Consensus = Consensus
        this.Previews = Previews
        this.IsPlanned = IsPlanned
    }

    public setCoordinates(centerX: number, centerY: number, radius: number) {
        this.coordinates.centerX = centerX
        this.coordinates.centerY = centerY
        this.coordinates.radius = radius
    }

    isInside(x: number, y: number): boolean {
        return (
            Math.sqrt((x - this.coordinates.centerX) ** 2 + (y - this.coordinates.centerY) ** 2) <
            this.coordinates.radius
        )
    }

    paintLightBackground(ctx: CanvasRenderingContext2D) {
        if (!this.inFocusedState) return

        const { centerX, centerY, radius } = this.coordinates
        const outerRadius = radius + 6

        ctx.beginPath()
        ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI, false)
        ctx.arc(centerX, centerY, radius + 1, 0, 2 * Math.PI, true)
        ctx.fillStyle = 'rgba(47,128,237,0.7)'
        ctx.fill('evenodd')
    }
}
