export const FONT_SIZE_OPTIONS = [
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 14, label: 14 },
    { value: 16, label: 16 },
    { value: 20, label: 20 },
    { value: 24, label: 24 },
    { value: 28, label: 28 },
    { value: 32, label: 32 },
    { value: 40, label: 40 }
]
