import { Typography, TypoProps } from '@fto/ui'
import { FC } from 'react'
import style from './tag.module.scss'
import classNames from 'classnames'

type Props = TypoProps & {
    text: string
    className?: string
}

export const Tag: FC<Props> = (props) => {
    const { text, className, ...rest } = props
    return (
        <div className={classNames(style.tag, className)}>
            <Typography {...rest}>{text}</Typography>
        </div>
    )
}
Tag.displayName = 'Tag'
export default Tag
