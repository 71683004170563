import { FC, useCallback } from 'react'

import cx from 'classnames'

import { Typography } from '@fto/ui'

import { TabType } from '../../types'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    tab: TabType
    isActive: boolean
    className?: string
    handleTabChange: (tab: string | number) => void
}
export const Tab: FC<Props> = ({ tab, isActive, handleTabChange, className }) => {
    const handleTabSelect = useCallback(() => {
        handleTabChange(tab.value)
    }, [handleTabChange, tab.value])
    const { t } = useTranslation()

    return (
        <div className={cx(styles.tab, className, { [styles.active]: isActive })} onClick={handleTabSelect}>
            <Typography
                type={isActive ? 'interface-medium' : 'interface-regular'}
                color={isActive ? 'primary-500' : 'gray-1000'}
            >
                {tab.label ? tab.label : t(tab.localeKey || '')}
            </Typography>
        </div>
    )
}

export default Tab
