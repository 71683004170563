import { useEffect } from 'react'
import debounce from 'lodash/debounce'

const useDebouncedEnterSubmit = (onSubmit: () => void) => {
    useEffect(() => {
        const debouncedHandler = debounce(onSubmit, 500)

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault()
                debouncedHandler?.()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [onSubmit])
}

export default useDebouncedEnterSubmit
