import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { Command } from './Command'

class TickForwardCommand extends Command {
    public execute(): void {
        GlobalTestingManager.TestingManager.StepForwardBy1Tick()
    }
}

export { TickForwardCommand }
