export enum ELockToOption {
    //these 3 are configurable in the settings
    lt_LeftBar = 0,
    lt_RightBar = 1,
    lt_Center = 2,

    //these are not available in the settings
    lt_SpecificDateAndX = 3,
    lt_GetFromSettings = 4
}
