import { DebugUtils } from '../DebugUtils'
import { Command } from './Command'

class DebugCommand extends Command {
    public execute(): void {
        if (DebugUtils.DebugMode) {
            DebugUtils.DebugMode = false
            alert('DEBUG MODE OFF')
        } else {
            DebugUtils.DebugMode = true
            alert('DEBUG MODE ON')
        }
    }
}

export { DebugCommand }
