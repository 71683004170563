import { FC } from 'react'

import { Flex, Typography } from '@fto/ui'

import { FilenameTypes, Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    name: string
    icon?: (typeof FilenameTypes)[number]
    iconSize?: number
}

const IndicatorInnerDropdownButton: FC<Props> = ({ name, icon, iconSize = 18 }) => {
    return (
        <Flex className={styles.indicatorDropdown} justifyContent='space-between' alignItems='center'>
            <Flex gap={8} alignItems='center'>
                {icon && (
                    <span className={styles.IconWrapper}>
                        <Icon size={iconSize} name={icon} />
                    </span>
                )}
                <Typography type='interface-regular'>{name}</Typography>
            </Flex>
            <Icon size={16} name='arrow-right'></Icon>
        </Flex>
    )
}

export default IndicatorInnerDropdownButton
