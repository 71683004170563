export default class CommonConstants {
    public static readonly MAX_INT = 2147483647
    public static readonly EMPTY_INDEX: number = -1

    public static readonly MAX_NUMBER_OF_CHARTS = 8

    public static readonly DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS = 5
    //we do not have enough precision in TDateTime type, so let's define the accepted precision here
    public static readonly DATE_PRECISION_MINIMAL_STEP_AS_DATETIME =
        (1 / (24 * 60 * 60 * 1000)) * this.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS //do not use DateUtils here to avoid circular dependencies

    public static AVAILABLE_SYMBOLS: string[] = [
        'EURUSD',
        'USDJPY',
        'GBPUSD',
        'GBPJPY',
        'AUDUSD',
        'EURJPY',
        'XAUUSD',
        'USDCAD',
        'NZDJPY',
        'EURGBP',
        'NZDUSD',
        'USDCHF',
        'EURCAD',
        'CHFJPY',
        'EURCHF',
        'GBPCHF',
        'XAGUSD',
        'EURAUD',
        'DJI',
        'EURHKD',
        'GBPAUD',
        'CADJPY',
        'BTCUSD',
        'AUDCHF',
        'GBPCAD',
        'AUDCAD',
        'EURNZD',
        'AUDJPY',
        'AUDNZD',
        'USDZAR',
        'NG',
        'SPX500',
        'NAS100'
    ]

    // static readonly TIME_FRAMES = [1, 5, 15, 30, 60, 240, 1440, 10080] as const

    //this is not a constant just to be able to run some tests
    //FIXME: remove 240 and 1440
    public static readonly TIMEFRAMES_DOWNLOADABLE_FROM_SERVER_DEFAULT_VALUE = [
        1, 5, 15, 30, 60, 240, 1440, 10080, 43200
    ]
    public static TIMEFRAMES_DOWNLOADABLE_FROM_SERVER: number[] =
        CommonConstants.TIMEFRAMES_DOWNLOADABLE_FROM_SERVER_DEFAULT_VALUE //this is done this way to let tests change this value and then se it back to default

    //TODO: should we use -1 here instead of 0?
    public static readonly EMPTY_DATA_VALUE = 0

    public static readonly DEFAULT_BROKER = 'Advanced'
}
