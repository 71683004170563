export const cellsNames = [
    'id',
    'symbol',
    'type',
    'lots',
    'openTime',
    'openPrice',
    'sl',
    'tp',
    'marketPrice',
    'comment',
    'swap',
    'commision',
    'points',
    'profit',
    'orderStatus'
] as const

type BaseColumnNames = 'id' | 'symbol' | 'type' | 'lots' | 'openTime' | 'sl' | 'tp' | 'comment'

type OpenPositionColumnNames =
    | BaseColumnNames
    | 'openPrice'
    | 'marketPrice'
    | 'profit'
    | 'points'
    | 'swap'
    | 'commision'
type PendingOrdersColumnNames = BaseColumnNames | 'executionPrice' | 'marketPrice'
type HistoryColumnNames = OpenPositionColumnNames | 'closeTime' | 'closePrice' | 'orderStatus'
export type TerminalColumnsNames = OpenPositionColumnNames | PendingOrdersColumnNames | HistoryColumnNames

export type TerminalCellsType<C> = {
    value: C
    index: number
    minWidth: number
    maxWidth: number
    notSortable?: boolean
}

export const OPEN_POSITION_CELLS_LIST: Array<TerminalCellsType<OpenPositionColumnNames>> = [
    { value: 'id', index: 1, minWidth: 40, maxWidth: 100 },
    { value: 'symbol', index: 2, minWidth: 60, maxWidth: 200 },
    { value: 'type', index: 3, minWidth: 60, maxWidth: 200 },
    { value: 'lots', index: 4, minWidth: 50, maxWidth: 100 },
    { value: 'openTime', index: 5, minWidth: 140, maxWidth: 250 },
    { value: 'openPrice', index: 6, minWidth: 60, maxWidth: 200 },
    { value: 'marketPrice', index: 7, minWidth: 60, maxWidth: 200 },
    { value: 'profit', index: 8, minWidth: 40, maxWidth: 150 },
    { value: 'points', index: 9, minWidth: 40, maxWidth: 150 },
    { value: 'sl', index: 10, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'tp', index: 11, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'swap', index: 12, minWidth: 40, maxWidth: 150 },
    { value: 'commision', index: 13, minWidth: 60, maxWidth: 150 },
    { value: 'comment', index: 14, minWidth: 50, maxWidth: 300, notSortable: true }
]

export const PENDING_ORDERS_CELLS_LIST: Array<TerminalCellsType<PendingOrdersColumnNames>> = [
    { value: 'id', index: 1, minWidth: 40, maxWidth: 100 },
    { value: 'symbol', index: 2, minWidth: 60, maxWidth: 200 },
    { value: 'type', index: 3, minWidth: 80, maxWidth: 200 },
    { value: 'lots', index: 4, minWidth: 50, maxWidth: 100 },
    { value: 'openTime', index: 5, minWidth: 140, maxWidth: 250 },
    { value: 'executionPrice', index: 6, minWidth: 60, maxWidth: 200 },
    { value: 'marketPrice', index: 7, minWidth: 60, maxWidth: 200 },
    { value: 'sl', index: 8, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'tp', index: 9, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'comment', index: 10, minWidth: 50, maxWidth: 300, notSortable: true }
]

export const HISTORY_CELLS_LIST: Array<TerminalCellsType<HistoryColumnNames>> = [
    { value: 'orderStatus', index: 1, minWidth: 30, maxWidth: 100 },
    { value: 'id', index: 2, minWidth: 40, maxWidth: 100 },
    { value: 'symbol', index: 3, minWidth: 60, maxWidth: 200 },
    { value: 'type', index: 4, minWidth: 60, maxWidth: 200 },
    { value: 'lots', index: 5, minWidth: 50, maxWidth: 100 },
    { value: 'openTime', index: 6, minWidth: 140, maxWidth: 250 },
    { value: 'openPrice', index: 7, minWidth: 60, maxWidth: 200 },
    { value: 'closeTime', index: 8, minWidth: 140, maxWidth: 250 },
    { value: 'closePrice', index: 9, minWidth: 60, maxWidth: 200 },
    { value: 'profit', index: 10, minWidth: 40, maxWidth: 150 },
    { value: 'points', index: 11, minWidth: 40, maxWidth: 150 },
    { value: 'sl', index: 12, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'tp', index: 13, minWidth: 60, maxWidth: 200, notSortable: true },
    { value: 'swap', index: 14, minWidth: 40, maxWidth: 150 },
    { value: 'commision', index: 15, minWidth: 60, maxWidth: 150 },
    { value: 'comment', index: 16, minWidth: 50, maxWidth: 300, notSortable: true }
]

export const EXCLUDED_OPEN_POSITION_COLUMNS = [
    'symbol',
    'type',
    'lots',
    'openPrice',
    'marketPrice',
    'profit',
    'openTime'
]
export const EXCLUDED_PENDING_ORDERS_COLUMNS = ['symbol', 'type', 'lots', 'marketPrice', 'openTime', 'executionPrice']
export const EXCLUDED_HISTORY_COLUMNS = ['symbol', 'type', 'lots', 'openPrice', 'profit', 'closePrice']

export const COLUMNS_LIST_WITH_SIZES = {
    open: OPEN_POSITION_CELLS_LIST,
    pending: PENDING_ORDERS_CELLS_LIST,
    history: HISTORY_CELLS_LIST
}
