import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export default class Momentum extends IndicatorImplementation {
    period: TOptValue_number = new TOptValue_number(14)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    // Buffers
    buff!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.momentum'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.SetEmptyValue(99.99)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Number.MAX_SAFE_INTEGER)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.buff = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.buff)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#1E90FF')
        this.api.SetIndexLabel(0, t('indicatorModal.momentum.fields.momentum'))
    }

    //TODO:IN SetEmptyValue /most likely relies on SetEmptyValue method which is not implemented
    //TODO:IN first bar is being calculated properly and u can see the dot on the chart but the line from first to second bars is not being drawn
    Calculate(index: number): void {
        if (index + this.period.value >= this.api.Bars()) {
            // const temp = this.api.Bars()
            // if (index + 3 === this.api.Bars()) {
            // i think, this should be removed after SetEmptyValue is implemented
            this.buff.setValue(index, 99.99)
            // }
            return
        }

        try {
            const currentPrice = this.api.GetPrice(index, this.ApplyToPrice.value)
            const pastPrice = this.api.GetPrice(index + this.period.value, this.ApplyToPrice.value)

            this.buff.setValue(index, (currentPrice * 100) / pastPrice)
        } catch (error) {
            this.buff.setValue(index, 99.99)
        }
    }
}
