import { useCallback, useMemo, FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Modal, removeModal, Flex, ModalTabs, Button } from '@fto/ui'

import GraphToolStore, { OptionType } from '@fto/lib/charting/tool_storages/graphToolStore'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { MODAL_NAMES } from '@root/constants/modalNames'

import FieldCreator from './components/FieldCreator'
import { fireMixpanelEvent } from '@root/utils/api'

import LevelsCreator, { LEVEL_TEMPLATE } from '@fto/chart_components/LevelsCreator'
import { LevelType } from '@fto/lib/drawing_interface/GraphicObjects/LevelType'
import { LevelCreatorTypes } from '@fto/chart_components/LevelsCreator/types'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { TemplateManager } from '../TemplateManager'

import PaintToolDefaultUtils from '@fto/lib/charting/paint_tools/PaintToolDefaultUtils'
import { SupportedPaintTools } from '@fto/lib/globals/TemplatesManager/default_templates/DefaultToolsTemplates'
import TimeframesCreator from './components/TimeframesCreator'
import { DelphiLikeArray } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'

type Props = {
    toolType: SupportedPaintTools
    toolName: string
}

const TABS = [
    { value: 'style', localeKey: 'toolsTabs.style.title' },
    { value: 'timeframes', localeKey: 'toolsTabs.timeframes.title' },
    { value: 'levels', localeKey: 'toolsTabs.levels.title' }
]

export const GraphToolModal: FC<Props> = observer(({ toolType, toolName }) => {
    const { t } = useTranslation()

    const { toolSettings, updateToolSettings, resetToolSettings, timeframes, updateTimeframes, resetTimeframes } =
        GraphToolStore

    const [activeTab, setActiveTab] = useState('style')
    const [templates, setTemplates] = useState<string[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<string>('')
    const [shouldBeVisible, setShouldBeVisible] = useState(true)

    const options = useMemo(() => Object.values(toolSettings), [toolSettings])

    const updateData = useCallback(
        (value: string | number | boolean | TLineStyle, option: OptionType, isOpacity: boolean = false) => {
            const { key, type, withOpacity, opacityOptionKey } = option
            setSelectedTemplate('')

            if (isOpacity) {
                return updateToolSettings((prev: any) => {
                    return {
                        ...prev,
                        [key]: { ...prev[key], opacityValue: value },
                        [opacityOptionKey as string]: { ...prev[opacityOptionKey as string], value }
                    }
                })
            }

            if (type === 'style') {
                const { color, width, style } = value as TLineStyle

                return updateToolSettings((prev: any) => ({
                    ...prev,
                    [key]: { ...prev[key], value: new TLineStyle(color, style, width) }
                }))
            }

            updateToolSettings((prev: any) => ({ ...prev, [key]: { ...prev[key], value } }))
        },
        [options]
    )

    const toggleOptionalOption = useCallback((option: OptionType) => {
        const { key } = option
        setSelectedTemplate('')

        updateToolSettings((prev: any) => ({ ...prev, [key]: { ...prev[key], isActive: !prev[key].isActive } }))
    }, [])

    const applySettings = useCallback(() => {
        GlobalChartsController.Instance.applyNewSettingsOnSelectedPaintTool()

        fireMixpanelEvent('graph_tool_setting changed', { graph_tool_name: toolName })

        // Apply the settings
        removeModal(MODAL_NAMES.chart.graphTools)
    }, [toolName])

    const levelOption = useMemo(() => {
        return options.find(({ type }) => type === 'levels')
    }, [options])

    const onClose = useCallback(() => {
        resetToolSettings()
        resetTimeframes()
        GlobalChartsController.Instance.clearToolToEdit()
    }, [])

    const addLevel = useCallback(() => {
        updateToolSettings((prevSettings) => ({
            ...prevSettings,
            ['levels']: {
                ...prevSettings.levels,
                value: [...prevSettings.levels.value, { ...LEVEL_TEMPLATE, id: +new Date() }]
            }
        }))
    }, [])

    const updateLevelStyle: LevelCreatorTypes['updateStyles'] = useCallback((value, id) => {
        updateToolSettings((prevSettings) => ({
            ...prevSettings,
            ['levels']: {
                ...prevSettings.levels,
                value: prevSettings.levels.value.map((level: LevelType) => {
                    return level.id === id ? { ...level, ...value } : level
                })
            }
        }))
    }, [])

    const handleLevelValueChange: LevelCreatorTypes['handleLevelValueChange'] = useCallback((value, id) => {
        updateToolSettings((prevSettings) => ({
            ...prevSettings,
            ['levels']: {
                ...prevSettings.levels,
                value: prevSettings.levels.value.map((level: LevelType) => {
                    return level.id === id ? { ...level, value } : level
                })
            }
        }))
    }, [])

    const toggleLevel: LevelCreatorTypes['toggleLevel'] = useCallback((value, id) => {
        updateToolSettings((prevSettings) => ({
            ...prevSettings,
            ['levels']: {
                ...prevSettings.levels,
                value: prevSettings.levels.value.map((level: LevelType) => {
                    return level.id === id ? { ...level, isActive: value } : level
                })
            }
        }))
    }, [])

    const removeLevel: LevelCreatorTypes['removeLevel'] = useCallback((id) => {
        updateToolSettings((prevSettings) => ({
            ...prevSettings,
            ['levels']: {
                ...prevSettings.levels,
                value: prevSettings.levels.value.filter((level: LevelType) => level.id !== id)
            }
        }))
    }, [])

    const handleTimeframeChange = useCallback(
        (timeFrame: number) => {
            const updatedTimeframes = timeframes.includes(timeFrame)
                ? timeframes.filter((tf) => tf !== timeFrame)
                : timeframes.concat(timeFrame)
            updateTimeframes(updatedTimeframes as DelphiLikeArray<number>)
        },
        [timeframes, updateTimeframes]
    )

    const updateTemplates = useCallback(() => {
        setTemplates(GlobalTemplatesManager.Instance.getToolTemplatesNames(toolName))
    }, [toolName])

    const saveTemplate = useCallback(
        async (templateName: string, isRewrite: boolean = false) => {
            const template: {
                [key: string]: { type: string; value: string | number | boolean | TLineStyle | number[] }
            } = {}
            for (let key in options) {
                const option = options[key]
                template[option.key] = {
                    type: option.type,
                    value: option.value
                }
            }

            template[`visible_timeframes`] = {
                type: 'timeframes',
                value: timeframes
            }

            await GlobalTemplatesManager.Instance.addToolTemplate(
                templateName,
                toolName,
                JSON.stringify(template),
                isRewrite
            )
            updateTemplates()
            setSelectedTemplate(templateName)
        },
        [toolName, options, updateTemplates, timeframes]
    )

    const deleteTemplate = useCallback(
        async (templateName: string) => {
            await GlobalTemplatesManager.Instance.removeToolTemplate(toolName, templateName)
            updateTemplates()
        },
        [toolName, updateTemplates]
    )

    const selectTemplate = useCallback(
        (templateName: string) => {
            const template = GlobalTemplatesManager.Instance.getToolTemplate(toolName, templateName)
            if (!template) return

            const parsedTemplate = JSON.parse(template.templateJSON)

            for (let key in parsedTemplate) {
                const currentOption = options.find((option) => option.key === key)
                if (!currentOption) continue

                const option = parsedTemplate[key]
                updateData(option.value, currentOption)
                setSelectedTemplate(templateName)
            }

            updateTimeframes(parsedTemplate['visible_timeframes'].value)
        },
        [toolName, options, updateData]
    )

    const onApplyDefault = useCallback(() => {
        const template = GlobalTemplatesManager.Instance.getToolDefaultTemplate(toolType)
        updateToolSettings((prev: any) => ({
            ...prev,
            ...PaintToolDefaultUtils.getNewStorageDataForToolByTemplate(toolType, template)
        }))
        setSelectedTemplate('')
    }, [toolType])

    useEffect(() => {
        updateTemplates()
    }, [toolName, updateTemplates])

    return (
        <Modal
            size='sm'
            name={MODAL_NAMES.chart.graphTools}
            withCloseIcon
            onClose={onClose}
            additionalClassNames={{
                root: !shouldBeVisible ? styles.HiddenModal : ''
            }}
        >
            <Modal.Header className={styles.Header} withBorderBottom>
                {t(`graphicTools.toolNames.${toolName}`)}
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={8}>
                    <Flex direction='column' gap={16}>
                        {
                            <ModalTabs
                                tabsList={levelOption ? TABS : TABS.filter((tab) => tab.value !== 'levels')}
                                handleTabChange={(value) => setActiveTab(value as string)}
                                activeTab={activeTab}
                            />
                        }
                        {activeTab === 'style' &&
                            options.map((option) => (
                                <FieldCreator
                                    key={option.key}
                                    option={option}
                                    setData={updateData}
                                    toggleOptionalOption={toggleOptionalOption}
                                />
                            ))}
                        {activeTab === 'levels' && (
                            <LevelsCreator
                                levelsList={levelOption?.value || []}
                                addLevel={addLevel}
                                updateStyles={updateLevelStyle}
                                handleLevelValueChange={handleLevelValueChange}
                                toggleLevel={toggleLevel}
                                removeLevel={removeLevel}
                                withOpacity
                            />
                        )}
                        {activeTab === 'timeframes' && (
                            <TimeframesCreator handleTimeframeChange={handleTimeframeChange} timeframes={timeframes} />
                        )}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Footer>
                <TemplateManager
                    changeMainModalVisibleState={setShouldBeVisible}
                    onSaveTemplate={saveTemplate}
                    onSelectTemplate={selectTemplate}
                    onDeleteTemplate={deleteTemplate}
                    templates={templates}
                    selectedTemplate={selectedTemplate}
                    applyToAll={false}
                    onApplyDefault={onApplyDefault}
                />
            </Modal.Footer>
            <Modal.Controls>
                <Button
                    type='secondary'
                    label={t('global.cancel')}
                    onClick={() => {
                        removeModal(MODAL_NAMES.chart.graphTools)
                    }}
                    classNames={{ content: styles.ControlButton }}
                />
                <Button
                    type='primary'
                    label={t('toolsModal.cta')}
                    onClick={applySettings}
                    classNames={{ content: styles.ControlButton }}
                />
            </Modal.Controls>
        </Modal>
    )
})
