import { MODAL_NAMES } from '@root/constants/modalNames'
import { removeModal } from '@fto/ui'

const draggableModals = [MODAL_NAMES.chart.orderModal]

export const hideModals = () => {
    draggableModals.forEach((modalName) => {
        removeModal(modalName)
    })
}
