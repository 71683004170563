import { Button, Flex, InputField, Modal, ModalTabs, removeModal, TabValue, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { terminalOrderOpenPositionType } from '@fto/lib/store/ordersStore/types'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { IconButton } from '@fto/icons'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { OrderMarkerInfo, OrderMarkerType } from '@fto/lib/OrderModalClasses/OrderMarker'
import { PartialClosePricePicker } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PartialClosePricePicker'
import { LESS_THAN_TWO_DIGITS_AFTER_DECIMAL_REGEX } from '@root/constants/regex'

const TABS = [
    {
        value: 'market',
        localeKey: 'partialModal.market'
    },
    {
        value: 'limit',
        localeKey: 'partialModal.limit'
    }
]

const percentages = [10, 25, 50, 75, 100]

type Props = {
    order: terminalOrderOpenPositionType
    tab?: TabValue
    newPrice?: number
    newPoints?: number
    lotsBefore?: number
}

let pricePickerInstance: PartialClosePricePicker | null = null

const MINIMUM_LOT = 0.01

const PartialClosingModal: FC<Props> = ({ order, tab, newPrice, newPoints, lotsBefore }) => {
    const initialLotValue = useMemo(
        () =>
            Number(((order.lots / 100) * 10).toFixed(2)) < MINIMUM_LOT
                ? MINIMUM_LOT
                : Number(((order.lots / 100) * 10).toFixed(2)),
        [order.lots]
    )

    const [activeTab, setActiveTab] = useState<TabValue>(tab || TABS[0].value)
    const [lotValue, setLotValue] = useState<number>(lotsBefore || initialLotValue)
    const [price, setPrice] = useState<number>(newPrice || order.marketPrice)
    const [points, setPoints] = useState<number>(newPoints || 0)
    const { t } = useTranslation()

    const decimals = useMemo(
        () => GlobalSymbolList.SymbolList.GetExistingSymbol(order.symbol)!.symbolInfo.decimals ?? 0,
        [order.symbol]
    )

    const priceStep = useMemo(() => Number(Math.pow(10, -decimals).toFixed(decimals)), [decimals])

    const handleLotChange = useCallback((value: string) => {
        if (LESS_THAN_TWO_DIGITS_AFTER_DECIMAL_REGEX.test(value)) {
            Number(value) > order.lots ? setLotValue(order.lots) : setLotValue(Number(value))
        }
    }, [])

    const handlePriceChange = useCallback((value: string) => {
        setPrice(Number(value))
        setPoints((Number(value) - order.marketPrice) / priceStep)
    }, [])

    const handlePointsChange = useCallback((value: string) => {
        const points = Math.floor(Number(value))
        setPoints(points)
        setPrice(order.marketPrice + points * priceStep)
    }, [])

    const closePosition = useCallback(() => {
        GlobalProcessingCore.ProcessingCore.CloseOrder(order.id, lotValue)
        handleModalClose()
    }, [lotValue, order.id])

    const createPendingCloseOrder = useCallback(() => {
        GlobalProcessingCore.ProcessingCore.setPendingPartialCloseOrder(order.id, lotValue, price, points)
        handleModalClose()
    }, [lotValue, order.id, price, points])

    const handlePercentageChange = useCallback(
        (percentage: number) => {
            const value = Number(((order.lots / 100) * percentage).toFixed(2))
            if (value < MINIMUM_LOT) {
                setLotValue(MINIMUM_LOT)
            } else {
                setLotValue(value)
            }
        },
        [order.lots]
    )

    const handleModalClose = useCallback(() => {
        if (pricePickerInstance) {
            pricePickerInstance.deleteMarkerByLinkNumber()
            pricePickerInstance = null
        }
        removeModal(MODAL_NAMES.chart.partialClosing)
    }, [])

    const handlePricePickerClick = useCallback(() => {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (!activeChart) return
        if (
            pricePickerInstance &&
            pricePickerInstance.chart === activeChart.MainChart &&
            !pricePickerInstance.isDeleted
        ) {
            pricePickerInstance.lotValue = lotValue
            pricePickerInstance.OnPicker()
        } else {
            pricePickerInstance?.deleteMarkerByLinkNumber()
            pricePickerInstance = null
            const markerInfo: OrderMarkerInfo = {
                markerName: 'Partial close',
                price: 1,
                tDateTime: GlobalProcessingCore.ProcessingCore.CurrTime,
                points: points
            }

            const pricePicker = new PartialClosePricePicker(
                activeChart.MainChart,
                markerInfo,
                OrderMarkerType.TAKE_PROFIT
            )

            pricePicker.order = order
            pricePicker.lotValue = lotValue
            pricePicker.OnPicker()

            pricePickerInstance = pricePicker
        }

        removeModal(MODAL_NAMES.chart.partialClosing)
    }, [order, lotValue, points])

    return (
        <Modal
            size='sm'
            name={MODAL_NAMES.chart.partialClosing}
            onClose={() => {
                handleModalClose()
            }}
        >
            <Modal.Header withBorderBottom>
                <Typography>{t('partialModal.title')}</Typography>
            </Modal.Header>
            <Modal.Content>
                <Flex direction='column' gap={8}>
                    <ModalTabs tabsList={TABS} handleTabChange={setActiveTab} activeTab={activeTab} />
                    <Flex direction='column' className={styles.info} gap={8}>
                        <Typography type='tiny-medium' color='gray-600'>
                            {t('partialModal.info')}
                        </Typography>
                        <Flex direction='column'>
                            <Flex gap={4}>
                                <Typography type='subtext-medium'>{t('partialModal.lots')}</Typography>
                                <Typography type='subtext-medium'>{order.lots}</Typography>
                            </Flex>
                            <Flex gap={4}>
                                <Typography type='subtext-medium'>{t('partialModal.price')}</Typography>
                                <Typography type='subtext-medium'>{order.marketPrice}</Typography>
                            </Flex>
                            <Flex gap={4}>
                                <Typography type='subtext-medium'>{t('partialModal.pnl')}</Typography>
                                <Typography type='subtext-medium'>{order.profit}</Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                    {activeTab === 'limit' && (
                        <Flex alignItems='flex-end' gap={8}>
                            <InputField
                                type='number'
                                label={<Typography type='interface-medium'>{t('partialModal.atPrice')}</Typography>}
                                value={price.toFixed(decimals)}
                                min={0}
                                step={priceStep}
                                onChange={handlePriceChange}
                                className={styles.input}
                                block
                            />
                            <InputField
                                type='number'
                                label={<Typography type='interface-medium'>{t('partialModal.point')}</Typography>}
                                value={points.toFixed()}
                                step={1}
                                onChange={handlePointsChange}
                                className={styles.input}
                                block
                            />
                            <IconButton
                                className={styles.picker}
                                name='picker'
                                size={18}
                                color='var(--color-gray-900)'
                                onClick={handlePricePickerClick}
                            />
                        </Flex>
                    )}
                    <Flex gap={4} alignItems='flex-end'>
                        <InputField
                            type='number'
                            label={<Typography type='interface-medium'>{t('partialModal.closeLots')}</Typography>}
                            value={lotValue}
                            min={MINIMUM_LOT}
                            max={order.lots}
                            step={0.01}
                            onChange={handleLotChange}
                            className={styles.input}
                        />
                        {percentages.map((percentage) => {
                            return (
                                <Button
                                    type='tetriatry-white'
                                    classNames={{ content: styles.buttonContent }}
                                    key={percentage}
                                    label={`${percentage}%`}
                                    onClick={() => handlePercentageChange(percentage)}
                                />
                            )
                        })}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                submitText={activeTab === 'limit' ? t('partialModal.placeLimit') : t('partialModal.closePosition')}
                onSubmit={activeTab === 'limit' ? createPendingCloseOrder : closePosition}
            />
        </Modal>
    )
}

export default PartialClosingModal
