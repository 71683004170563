import { Command } from './Command'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

class DeleteAllProjectsCommand extends Command {
    public execute(): void {
        addModal(MODAL_NAMES.projects.deleteAll)
    }
}

export { DeleteAllProjectsCommand }
