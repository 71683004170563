import { FC, useCallback, useMemo, useState } from 'react'

import { Flex, Modal, ModalTabs, removeModal } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'

import Content from './components/Content'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { showErrorToast } from '@root/utils/toasts'
import { useTranslation } from 'react-i18next'
import { TabType } from '@fto/ui/lib/components/ModalTabs/types'

const TABS: TabType[] = [
    { value: 'deposit', localeKey: 'balanceModal.deposit' },
    { value: 'withdraw', localeKey: 'balanceModal.withdraw' }
]

const ChangeBalanceModal: FC = () => {
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState(TABS[0].value)
    const [amount, setAmount] = useState<number | ''>(1000)
    const [comment, setComment] = useState('')
    const isWithdraw = useMemo(() => activeTab === 'withdraw', [activeTab])

    const action = useCallback(() => {
        if (isWithdraw && Number(amount) > GlobalProcessingCore.ProcessingCore.Balance) {
            showErrorToast({
                title: t('balanceModal.notEnoughMoneyHeader'),
                message: t('balanceModal.notEnoughMoneyText')
            })
        }
        isWithdraw
            ? GlobalProcessingCore.ProcessingCore.WithdrawMoney(Number(amount), comment)
            : GlobalProcessingCore.ProcessingCore.DepositMoney(Number(amount), comment)
        removeModal(MODAL_NAMES.chart.changeBalance)
    }, [amount, comment, activeTab, isWithdraw])

    return (
        <Modal size='sm' name={MODAL_NAMES.chart.changeBalance} withCloseIcon>
            <Modal.Header withBorderBottom>{t('balanceModal.changeBalance')}</Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <ModalTabs tabsList={TABS} activeTab={activeTab} handleTabChange={setActiveTab} />
                    <Content
                        type={activeTab}
                        amount={amount}
                        comment={comment}
                        setAmount={setAmount}
                        setComment={setComment}
                    />
                </Flex>
            </Modal.Content>
            <Modal.Controls
                disabled={amount === '' || amount === 0}
                onSubmit={action}
                submitText={isWithdraw ? t('balanceModal.withdraw') : t('balanceModal.deposit')}
            />
        </Modal>
    )
}

export default ChangeBalanceModal
