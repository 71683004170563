import { TOptions } from '../ft_types/common/OptionsUnit'

export default class GlobalOptions {
    private static fOptions: TOptions

    public static Init(): void {
        GlobalOptions.fOptions = new TOptions()
    }

    public static get Options(): TOptions {
        if (!GlobalOptions.fOptions) {
            GlobalOptions.Init()
        }
        return GlobalOptions.fOptions
    }
}
