export class DateIsBeforeChunkStart extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'DateIsBeforeChunkStart'
    }
}

export class DateIsAfterChunkEnd extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'DateIsAfterChunkEnd'
    }
}
