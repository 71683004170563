import { FC, SetStateAction, Dispatch } from 'react'
import cx from 'classnames'
import Tab from './components/Tab'
import { TabType } from './types'

import styles from './index.module.scss'
import { Tooltip, placements, tooltipColors } from '../Tooltip'
import { t } from 'i18next'

export type TabValue = string | number

type Props = {
    tabsList: TabType[]
    handleTabChange: (tab: TabValue) => void | Dispatch<SetStateAction<TabValue>>
    activeTab: TabValue
    classNames?: {
        root?: string
        tab?: string
    }
    withTooltip?: boolean
    tooltipText?: string
    tooltipPlacement?: (typeof placements)[number]
    tooltipColor?: keyof typeof tooltipColors
    shouldShowTooltipArrow?: boolean
}
export const ModalTabs: FC<Props> = ({
    tabsList,
    handleTabChange,
    activeTab,
    classNames = {},
    withTooltip,
    tooltipText,
    tooltipPlacement,
    tooltipColor,
    shouldShowTooltipArrow
}) => {
    return (
        <div
            className={cx(styles.ModalTabs, classNames.root)}
            style={{ gridTemplateColumns: `repeat(${tabsList.length}, 1fr)` }}
        >
            {tabsList.map((tab) =>
                withTooltip ? (
                    <Tooltip
                        content={t(`${tooltipText}.${tab.localeKey!.split('.').at(-1)}`)}
                        placement={tooltipPlacement}
                        color={tooltipColor}
                        arrow={shouldShowTooltipArrow}
                    >
                        <Tab
                            className={classNames.tab}
                            tab={tab}
                            key={tab.value}
                            handleTabChange={handleTabChange}
                            isActive={activeTab === tab.value}
                        />
                    </Tooltip>
                ) : (
                    <Tab
                        className={classNames.tab}
                        tab={tab}
                        key={tab.value}
                        handleTabChange={handleTabChange}
                        isActive={activeTab === tab.value}
                    />
                )
            )}
        </div>
    )
}
