export type SortByType = 'ProjectName' | 'created' | 'lastUse'
export type OrderByType = 'asc' | 'desc'
export type QueryParams = {
    Limit?: number
    Offset?: number
    Search?: string
    SortBy?: SortByType
    Symbols?: string
    OrderBy?: OrderByType
}

export const initQueryParams: QueryParams = {
    Limit: 10,
    Offset: 0,
    Search: '',
    SortBy: 'ProjectName',
    OrderBy: 'asc',
    Symbols: ''
}

export type QueryParamType = {
    [key in keyof QueryParams]?: QueryParams[key]
}

const queryParamsUtil = (query: QueryParams | null): string => {
    const params = new URLSearchParams()
    let queryData = !query || Object.keys(query).length === 0 ? initQueryParams : query

    Object.keys(queryData).forEach((key) => {
        const keyName = key as keyof QueryParams
        if (query && query[keyName]) {
            const value = query[keyName]

            if (value) {
                if (keyName === 'Symbols' && Array.isArray(value)) {
                    params.append(key, value.join(','))
                } else {
                    params.append(key, value.toString())
                }
            }
        }
    })
    return '?' + params.toString()
}
export default queryParamsUtil
