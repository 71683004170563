import { ChangeEvent, FC, useCallback, useMemo, useRef, useState, ReactElement, cloneElement, useEffect } from 'react'
import ReactSlider from 'react-slider'

import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import { Flex, TriggerOverlay, TriggerOverlayProps, Typography } from '@fto/ui'

import Palette from '@fto/ui/lib/components/ColorPicker/components/Palette'
import Selector from '@fto/ui/lib/components/ColorPicker/components/Selector'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    color: string
    onColorChange: (value: string) => void
    disabled?: boolean
    opacity?: number
    onOpacityChange?: (value: number) => void
    transformOrigin?: TriggerOverlayProps['transformOrigin']
    anchorOrigin?: TriggerOverlayProps['anchorOrigin']
    withOpacity?: boolean
    shouldCloseAfterSelection?: boolean
    triggerElement?: ReactElement
}

export const ColorPicker: FC<Props> = ({
    color,
    onColorChange,
    disabled = false,
    opacity,
    onOpacityChange,
    transformOrigin,
    anchorOrigin,
    withOpacity = true,
    triggerElement = null
}) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement>(null)

    const [isOpen, setIsOpen] = useState(false)

    const handleSelectorClick = useCallback(() => {
        // Programmatically open the color picker

        inputRef.current?.click()
    }, [])

    const debouncedColorChange = useCallback(
        debounce((value: string) => {
            onColorChange?.(value)
        }, 200),
        [onColorChange]
    )

    const handleColorChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            debouncedColorChange(e.target.value)
        },
        [debouncedColorChange]
    )

    const opacityValue = useMemo(() => {
        if (!withOpacity || !opacity) {
            return 0
        }

        const valueInPercent = opacity * 100
        return valueInPercent.toFixed(0)
    }, [opacity, withOpacity])

    const hidePalette = useCallback(() => {
        setIsOpen(false)
    }, [])

    const openPalette = useCallback(() => setIsOpen(true), [])

    const triggerButton = useMemo(() => {
        // NOTE: set up manually open state for trigger element

        if (triggerElement) {
            return cloneElement(triggerElement, {
                color,
                openPalette
            })
        }

        return <Selector color={color} handleOpen={openPalette} disabled={disabled} />
    }, [triggerElement, color, openPalette, disabled])

    const throttledOpacityChange = useMemo(
        () =>
            throttle((value: number) => {
                onOpacityChange?.(value)
            }, 100),
        [onOpacityChange]
    )

    const handleOpacityChange = useCallback(
        (value: number) => {
            throttledOpacityChange(value)
        },
        [throttledOpacityChange]
    )

    useEffect(() => {
        return () => {
            debouncedColorChange.cancel()
            throttledOpacityChange.cancel()
        }
    }, [debouncedColorChange, throttledOpacityChange])

    return (
        <div className={styles.ColorPicker}>
            <TriggerOverlay
                isOpen={isOpen}
                setIsOpened={setIsOpen}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
                disableBackdropChartActivity
                optionsRenderer={
                    <Flex direction='column' gap={8}>
                        <Palette setColor={onColorChange} selectedColor={color} onClose={hidePalette} />
                        <Flex alignItems='center' gap={4}>
                            <div className={styles.Select} onClick={handleSelectorClick}>
                                <Selector color={color} handleOpen={openPalette} />
                                <input
                                    ref={inputRef}
                                    type='color'
                                    value={color}
                                    onChange={handleColorChange}
                                    disabled={disabled}
                                    className={styles.Input}
                                />
                            </div>
                            {withOpacity && (
                                <>
                                    <Flex direction='column'>
                                        <Typography type='subtext-medium'>
                                            {t('uiKit.colorPicker.opacity') || 'Opacity:'}
                                        </Typography>
                                        <div
                                            className={styles.trackWrapper}
                                            style={{ background: `linear-gradient(to right, transparent, ${color})` }}
                                        >
                                            <ReactSlider
                                                max={1}
                                                min={0}
                                                step={0.01}
                                                value={opacity}
                                                onChange={handleOpacityChange}
                                                className={styles.slider}
                                                thumbClassName={styles.thumb}
                                                trackClassName={styles.track}
                                                renderThumb={(props: any) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            background: color
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Flex>

                                    <div className={styles.OpacityValue}>
                                        <Typography type='interface-regular' color='gray-1000'>
                                            {opacityValue}%
                                        </Typography>
                                    </div>
                                </>
                            )}
                        </Flex>
                    </Flex>
                }
                disabled={disabled}
            >
                {triggerButton}
            </TriggerOverlay>
        </div>
    )
}
