import { FC, useCallback, useEffect, useState } from 'react'

import { Tooltip } from '@fto/ui'

import { TooltipTrigger } from '../../types'
import { DropdownIcon } from '../DropdownIcon'

import styles from '../../../../common.module.scss'
import VisibilityDropdownMenu from './components/VisibilityDropdownMenu'

type Props = {
    anyHiddenObjects: boolean
}

const VisibilityDropdown: FC<Props> = ({ anyHiddenObjects }) => {
    const [tooltipTrigger, setTooltipTrigger] = useState<TooltipTrigger>({
        isOpen: undefined,
        closedFromMenu: false
    })

    const resetTooltipState = useCallback(() => {
        setTooltipTrigger({ isOpen: undefined, closedFromMenu: false })
    }, [])

    useEffect(() => {
        if (tooltipTrigger.isOpen === false && tooltipTrigger.closedFromMenu) {
            resetTooltipState()
        }
    }, [tooltipTrigger])

    return (
        <Tooltip
            content={<VisibilityDropdownMenu setTooltipTrigger={setTooltipTrigger} />}
            interactive
            arrow={false}
            placement='right-start'
            classNames={{ tooltip: styles.dropdownTooltip }}
            color='light'
            open={tooltipTrigger.isOpen}
        >
            <DropdownIcon
                onClick={() => {}}
                icon={anyHiddenObjects ? 'sidebar-eye-notification' : 'sidebar-eye'}
                size={24}
                resetTooltipState={resetTooltipState}
            />
        </Tooltip>
    )
}

export default VisibilityDropdown
