import { FC, useMemo } from 'react'
import { Tag } from '@fto/ui'
import styles from './table-cells.module.scss'

type Props = {
    pairs: string[]
}

const TablePairs: FC<Props> = ({ pairs }) => {
    const isOverflow = pairs.length > 4
    const pairsArray = useMemo(() => {
        return isOverflow ? pairs.slice(0, 3) : pairs
    }, [])
    return (
        <>
            {pairsArray.map((pair: string, index: number) => (
                <Tag
                    color={'gray-900'}
                    type={'subtext-regular'}
                    className={styles.tag}
                    text={pair}
                    key={`${pair}/${index}`}
                />
            ))}
            {isOverflow && (
                <Tag
                    color={'gray-900'}
                    type={'subtext-regular'}
                    weight={'medium'}
                    className={styles.tag}
                    text={`+${pairs.length - 3} more`}
                />
            )}
        </>
    )
}

export default TablePairs
