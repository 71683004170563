class GlobalImageManager {
    settingsImage: HTMLImageElement | null = null
    eyeImage: HTMLImageElement | null = null
    eyeCloseImage: HTMLImageElement | null = null
    closeImage: HTMLImageElement | null = null
    chevronUpBold: HTMLImageElement | null = null
    chevronDownBold: HTMLImageElement | null = null
    moveToLastBar: HTMLImageElement | null = null
    sellOrderIcon: HTMLImageElement | null = null
    buyOrderIcon: HTMLImageElement | null = null
    buyOrderMarker: HTMLImageElement
    sellOrderMarker: HTMLImageElement
    editPenIcon: HTMLImageElement | null = null
    closeIcon: HTMLImageElement | null = null
    warningTriangleYellow: HTMLImageElement | null = null
    public imageColoredMap: Map<string, HTMLCanvasElement> = new Map()
    trendDown: HTMLImageElement | null = null
    trendUp: HTMLImageElement | null = null

    private static instance: GlobalImageManager
    public static orderIconPath = '/icons/orders/'

    public static get Instance(): GlobalImageManager {
        if (!GlobalImageManager.instance) {
            GlobalImageManager.instance = new GlobalImageManager()
        }
        return GlobalImageManager.instance
    }

    private constructor() {
        this.eyeCloseImage = new Image()
        this.eyeCloseImage.src = '/icons/eye-off.svg'

        this.settingsImage = new Image()
        this.settingsImage.src = '/icons/settings.svg'

        this.eyeImage = new Image()
        this.eyeImage.src = '/icons/eye.svg'

        this.closeImage = new Image()
        this.closeImage.src = '/icons/close.svg'

        this.chevronDownBold = new Image()
        this.chevronDownBold.src = '/icons/chevron-down-bold.svg'

        this.chevronUpBold = new Image()
        this.chevronUpBold.src = '/icons/chevron-up-bold.svg'

        this.moveToLastBar = new Image()
        this.moveToLastBar.src = '/icons/move-to-last-bar.svg'

        this.sellOrderIcon = new Image()
        this.sellOrderIcon.src = GlobalImageManager.orderIconPath + 'sell-order.svg'

        this.buyOrderIcon = new Image()
        this.buyOrderIcon.src = GlobalImageManager.orderIconPath + 'buy-order.svg'

        this.buyOrderMarker = new Image()
        this.buyOrderMarker.src = GlobalImageManager.orderIconPath + 'buy-order-marker.svg'

        this.sellOrderMarker = new Image()
        this.sellOrderMarker.src = GlobalImageManager.orderIconPath + 'sell-order-marker.svg'

        this.editPenIcon = new Image()
        this.editPenIcon.src = '/icons/edit-pen.svg'

        this.closeIcon = new Image()
        this.closeIcon.src = '/icons/close-sign.svg'

        this.warningTriangleYellow = new Image()
        this.warningTriangleYellow.src = '/icons/warning-triangle-yellow.svg'

        this.trendDown = new Image()
        this.trendDown.src = '/icons/trend_down.svg'

        this.trendUp = new Image()
        this.trendUp.src = '/icons/trend_up.svg'
    }

    private svgToDataUrl(svg: string): string {
        return 'data:image/svg+xml;base64,' + btoa(svg)
    }

    public setBuyOrderMarkerColor(color: string): void {
        const svg = `
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="2.5" fill="white" stroke="${color}"/>
            </svg>
        `
        this.buyOrderMarker.src = this.svgToDataUrl(svg)
    }

    public setSellOrderMarkerColor(color: string): void {
        const svg = `
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="2.5" fill="white" stroke="${color}"/>
            </svg>
        `
        this.sellOrderMarker.src = this.svgToDataUrl(svg)
    }
}

export default GlobalImageManager
