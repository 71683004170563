import { ALL_COUNTRIES_WITH_EU } from '@root/constants/countries'

export class CountryChartImages {
    private static instance: CountryChartImages
    public chartImageElements: { [countryName: string]: HTMLImageElement } = {}

    private constructor() {
        const iconWidth = 24
        const iconHeight = 24

        ALL_COUNTRIES_WITH_EU.forEach((country) => {
            import(`../assets/${country.iconName}.svg`).then(async (iconModule) => {
                const response = await fetch(iconModule.default)
                const svgContent = await response.text()

                const parser = new DOMParser()
                const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
                const svgElement = svgDoc.documentElement

                svgElement.setAttribute('width', iconWidth.toString())
                svgElement.setAttribute('height', iconHeight.toString())

                const serializer = new XMLSerializer()
                const resizedSvgContent = serializer.serializeToString(svgDoc)

                const image = new Image()
                image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(resizedSvgContent)
                this.chartImageElements[country.name] = image
            })
        })

        import(`../assets/country-entire-world.svg`).then(async (iconModule) => {
            const response = await fetch(iconModule.default)
            const svgContent = await response.text()

            const parser = new DOMParser()
            const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
            const svgElement = svgDoc.documentElement

            svgElement.setAttribute('width', iconWidth.toString())
            svgElement.setAttribute('height', iconHeight.toString())

            const serializer = new XMLSerializer()
            const resizedSvgContent = serializer.serializeToString(svgDoc)

            const image = new Image()
            image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(resizedSvgContent)
            this.chartImageElements['Entire World'] = image
        })
    }

    public static getInstance(): CountryChartImages {
        if (!CountryChartImages.instance) {
            CountryChartImages.instance = new CountryChartImages()
        }
        return CountryChartImages.instance
    }

    public getChartCountryImageElement(countryName: string): HTMLImageElement {
        return this.chartImageElements[countryName] || new Image()
    }
}
