import { $getUser } from '@root/store/auth/selectors'
import { useAppDispatch, useAppSelector } from './useStoreHook'
import { useMemo } from 'react'

const useLocalStorageKey = (key: string) => {
    const { userId } = useAppSelector($getUser)

    const generatedKey = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')
        return `${userId}_${projectId}_${key}`
    }, [userId])

    return generatedKey
}

export default useLocalStorageKey
