import { makeAutoObservable } from 'mobx'

import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'

type SettingsType = {
    description: string
    lineStyle: TLineStyle
    dateTime1: TDateTime
    dateTime2: TDateTime
    price1: number
    price2: number
}

type PassedSettingsType = {
    description?: SettingsType['description']
    lineStyle?: SettingsType['lineStyle']
    dateTime1?: SettingsType['dateTime1']
    dateTime2?: SettingsType['dateTime2']
    price1?: SettingsType['price1']
    price2?: SettingsType['price2']
}

const initialSettings = {
    description: '',
    lineStyle: new TLineStyle('#FF0000', 0, 1),
    dateTime1: 0,
    dateTime2: 0,
    price1: 0,
    price2: 0
}

class TrendLineStore {
    trendLineData: SettingsType

    constructor() {
        makeAutoObservable(this)

        this.trendLineData = initialSettings
        this.setTrendLineData = this.setTrendLineData.bind(this)
        this.resetTrendLineData = this.resetTrendLineData.bind(this)
    }

    // The argument can be either an updater function or a PassedSettingsType object
    setTrendLineData(updater: ((prevSettings: SettingsType) => PassedSettingsType) | PassedSettingsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.trendLineData = {
                ...this.trendLineData,
                ...updater(this.trendLineData)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.trendLineData = {
                ...this.trendLineData,
                ...updater
            }
        }
    }

    resetTrendLineData() {
        this.trendLineData = initialSettings
    }
}

export default new TrendLineStore()
