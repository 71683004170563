import { useState, FC, ReactNode, useEffect, useCallback, useMemo } from 'react'

import { ColorPicker, LineStyleSelector, Flex, Typography } from '@fto/ui'

import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { StylesType } from '@fto/chart_components/LevelsCreator/types'
import { useTranslation } from 'react-i18next'

type LabelBoxProps = {
    withLabel?: boolean
    label: string
    children: ReactNode
}

const LabelBox: FC<LabelBoxProps> = ({ label, children, withLabel = true }) => (
    <Flex direction='column' gap={8} alignItems='center'>
        {withLabel && (
            <Typography type='subtext-regular' color='gray-600'>
                {label}
            </Typography>
        )}
        {children}
    </Flex>
)

type SelectorMap = {
    [key: string]: { value: any; setter: (value: any) => void }
}

interface LineStylePickerProps {
    data: {
        [key: string]: StylesType
    }
    attribute: string
    withLabel?: boolean
    withOpacity?: boolean
    onChange: (value: StylesType) => void
}

const LineStylePicker: FC<LineStylePickerProps> = ({
    data,
    attribute,
    onChange,
    withLabel = true,
    withOpacity = false
}) => {
    const { t } = useTranslation()

    const [selectedColor, setSelectedColor] = useState<TLineStyle['color']>(data[attribute].color)
    const [selectedStyle, setSelectedStyle] = useState<TLineStyle['style']>(data[attribute].style)
    const [selectedWidth, setSelectedWidth] = useState<TLineStyle['width']>(data[attribute].width)
    const [opacity, setOpacity] = useState<TLineStyle['width']>(data[attribute].opacity || 0)

    const selectorsMap: SelectorMap = useMemo(
        () => ({
            color: {
                value: selectedColor,
                setter: setSelectedColor
            },
            style: {
                value: selectedStyle,
                setter: setSelectedStyle
            },
            width: {
                value: selectedWidth,
                setter: setSelectedWidth
            }
        }),
        []
    )

    const updateValue = useCallback(
        (key: string, value: any) => {
            selectorsMap[key].setter(value)
            onChange({
                color: key == 'color' ? value : selectedColor,
                style: key == 'style' ? value : selectedStyle,
                width: key == 'width' ? value : selectedWidth,
                opacity: key == 'opacity' ? value : opacity
            })
        },
        [selectedColor, selectedWidth, selectedStyle, opacity]
    )

    useEffect(() => {
        setSelectedColor(data[attribute].color)
        setSelectedStyle(data[attribute].style)
        setSelectedWidth(data[attribute].width)
        setOpacity(data[attribute].opacity || 0)
    }, [data])

    const shouldRender = useCallback(
        (config: any) => {
            return !isNaN(selectedStyle) && config !== undefined && config !== null
        },
        [selectedStyle, selectedWidth]
    )

    return (
        <Flex alignItems='center' gap={8}>
            {selectedColor && (
                <LabelBox label={t('modalFieldsCreator.color')} withLabel={withLabel}>
                    <ColorPicker
                        color={selectedColor}
                        onColorChange={(value) => {
                            updateValue('color', value)
                        }}
                        withOpacity={withOpacity}
                        opacity={opacity}
                        onOpacityChange={setOpacity}
                    />
                </LabelBox>
            )}
            {shouldRender(selectedStyle) && (
                <LabelBox label={t('modalFieldsCreator.type')} withLabel={withLabel}>
                    <LineStyleSelector
                        type='lineType'
                        value={selectedStyle}
                        onChange={(value) => {
                            updateValue('style', value)
                        }}
                    />
                </LabelBox>
            )}

            {shouldRender(selectedWidth) && (
                <LabelBox label={t('modalFieldsCreator.width')} withLabel={withLabel}>
                    <LineStyleSelector
                        type='lineWidth'
                        value={selectedWidth}
                        onChange={(value) => {
                            updateValue('width', value)
                        }}
                    />
                </LabelBox>
            )}
        </Flex>
    )
}

export default LineStylePicker
