import { Icon } from '@fto/icons'
import { Button, Flex, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'

import { DELETE_ALL_PROJECTS_ACTION } from '@root/store/projects/project.actions'
import { $getProjects } from '@root/store/projects/projects.selector'
import { $getUser } from '@root/store/auth/selectors'

import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'
import { getAllLocalStorageKeys } from '@root/utils/localStorage'

export const DeleteAllProjectsModal: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { userId } = useAppSelector($getUser)
    const projects = useAppSelector($getProjects)

    const handleDeleteAll = useCallback(() => {
        dispatch(DELETE_ALL_PROJECTS_ACTION(projects)).then((response: any) => {
            const allLocalStorageKeys = getAllLocalStorageKeys()
            if (projects) {
                projects.forEach((project) => {
                    const keyTemplate = `${userId}_${project.Id}`
                    allLocalStorageKeys.forEach((key) => {
                        if (key.includes(keyTemplate)) {
                            localStorage.removeItem(key)
                        }
                    })
                })
            }
            response.meta.requestStatus === 'fulfilled' && removeModal(MODAL_NAMES.projects.deleteAll)
        })
    }, [userId, projects, dispatch])

    return (
        <Modal name={MODAL_NAMES.projects.deleteAll} size='sm'>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='trash' size={72} />
                    <Typography type='h3-bold'>
                        {t('dashboard.modals.projects.deleteAllModal.deleteAllHeader')}
                    </Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Flex direction='column' alignItems='center'>
                    <Typography color='gray-900' type='text-regular'>
                        {t('dashboard.modals.projects.deleteAllModal.deleteAllText')}
                    </Typography>
                    <Typography color='gray-900' type='text-regular'>
                        {t('dashboard.modals.projects.undone')}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls>
                <Flex gap={8}>
                    <Button
                        label={t('global.cancel')}
                        onClick={() => removeModal(MODAL_NAMES.projects.deleteAll)}
                        type='secondary'
                    />
                    <Button
                        label={t('dashboard.modals.projects.deleteAllModal.deleteAllButton')}
                        onClick={handleDeleteAll}
                        type='primary'
                        classNames={{ content: styles.red }}
                    />
                </Flex>
            </Modal.Controls>
        </Modal>
    )
}
