import { TSymbolCalcType } from '@fto/lib/ft_types/data/DataEnums'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { DelphiMathCompatibility } from '../../delphi_compatibility/DelphiMathCompatibility'
import BasicClasses_CommonFunctions from '../../ft_types/common/BasicClasses/BasicClasses_CommonFunctions'
import {
    TOperationType,
    TSymbolType,
    TTradePositionStatus,
    TTrailingStopType
} from '../../ft_types/common/BasicClasses/BasicEnums'
import { TTradePosition } from '../../ft_types/common/BasicClasses/TradePosition'
import { TOffsStringList, TVarList } from '../../ft_types/common/OffsStringList'
import { TSymbolData } from '../../ft_types/data/SymbolData'
import { TSymbolList } from '../../ft_types/data/SymbolList'
import GlobalOptions from '../../globals/GlobalOptions'
import { TSymbolCalcParams } from './SymbolCalcParams'
import ProcessingCoreUtils from '../ProcessingCoreUtils'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { SellBuyConnectorLine } from '@fto/lib/processing_core/TradePositionClasses/SellBuyConnectorLine'
import { ChartControl, ChartControlId, ChartControlParams, LocationParams } from '@fto/chart_components/ChartControl'
import { TRect, TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { StoplossValidationMarketBuy } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationMarketBuy'
import { MarketBuyStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/MarketBuyStoplossCalculation'
import { ValidationStrategy } from '@fto/lib/OrderModalClasses/Validations/ValidationStrategy'
import { StoplossCalculationStrategy } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/StoplossCalculationStrategy'
import { TakeprofitCalculationStrategy } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/TakeprofitCalculationStrategy'
import { MarketBuyTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/MarketBuyTakeprofitCalculation'
import { TakeprofitValidationMarketBuy } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationMarketBuy'
import { MarketSellStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/MarketSellStoplossCalculation'
import { StoplossValidationMarketSell } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationMarketSell'
import { MarketSellTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/MarketSellTakeprofitCalculation'
import { TakeprofitValidationMarketSell } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationMarketSell'
import { StoplossValidationBuyLimit } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationBuyLimit'
import { BuyLimitStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/BuyLimitStoplossCalculation'
import { BuyLimitAtPriceValidation } from '@fto/lib/OrderModalClasses/Validations/PendingExecutionPriceValidations/BuyLimitAtPriceValidation'
import { BuyLimitTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/BuyLimitTakeprofitCalculation'
import { TakeprofitValidationBuyLimit } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationBuyLimit'
import { StoplossValidationBuyStop } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationBuyStop'
import { BuyStopStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/BuyStopStoplossCalculation'
import { BuyStopAtPriceValidation } from '@fto/lib/OrderModalClasses/Validations/PendingExecutionPriceValidations/BuyStopAtPriceValidation'
import { BuyStopTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/BuyStopTakeprofitCalculation'
import { TakeprofitValidationBuyStop } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationBuyStop'
import { StoplossValidationSellLimit } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationSellLimit'
import { SellLimitStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/SellLimitStoplossCalculation'
import { SellLimitAtPriceValidation } from '@fto/lib/OrderModalClasses/Validations/PendingExecutionPriceValidations/SellLimitAtPriceValidation'
import { SellLimitTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/SellLimitTakeprofitCalculation'
import { TakeprofitValidationSellLimit } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationSellLimit'
import { StoplossValidationSellStop } from '@fto/lib/OrderModalClasses/Validations/StoplossValidations/StoplossValidationSellStop'
import { SellStopStoplossCalculation } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/SellStopStoplossCalculation'
import { SellStopAtPriceValidation } from '@fto/lib/OrderModalClasses/Validations/PendingExecutionPriceValidations/SellStopAtPriceValidation'
import { SellStopTakeprofitCalculation } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/SellStopTakeprofitCalculation'
import { TakeprofitValidationSellStop } from '@fto/lib/OrderModalClasses/Validations/TakeProfitValidations/TakeProfitValidationSellStop'
import { EOperationType, OrderType, OrderValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { mapTradePositionType } from '@fto/lib/utils/ordersUtils'
import { StopLossLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/StopLossLevel'
import { TakeProfitLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/TakeProfitLevel'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { PendingLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PendingLevel'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { changeImageColor } from '@fto/lib/utils/common_utils'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { CrossSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/CrossSymbolCalculationStrategy'
import { EDayOfWeek } from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/EDayOfWeek'
import { CanvasLayer, LayerType } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'
import { TTrailingStopInfo } from './TrailingStopInfo'
import { t } from 'i18next'
import { PartialCloseLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PartialCloseLevel'

class OrderStrategies {
    pCalucalationStrategy: StoplossCalculationStrategy | null = null
    pStoplossValidationStarategy: ValidationStrategy | null = null
    pPendingAtPriceValidationStrategy: ValidationStrategy | null = null
    pTakeProfitCalculationStrategy: TakeprofitCalculationStrategy | null = null
    pTakeprofitValidationStrategy: ValidationStrategy | null = null

    constructor(
        stoplossCalculation: StoplossCalculationStrategy,
        stoplossValidation: ValidationStrategy,
        atPriceValidation: ValidationStrategy | null,
        takeprofitCalculation: TakeprofitCalculationStrategy,
        takeprofitValidation: ValidationStrategy
    ) {
        this.pCalucalationStrategy = stoplossCalculation
        this.pStoplossValidationStarategy = stoplossValidation
        this.pPendingAtPriceValidationStrategy = atPriceValidation
        this.pTakeProfitCalculationStrategy = takeprofitCalculation
        this.pTakeprofitValidationStrategy = takeprofitValidation
    }
}

export class TTradePos {
    private fSymbolList: TSymbolList
    private fCalcType!: TSymbolCalcType
    // private fExtraSymbolParams!: TSymbolCalcParams
    private fLotParams!: TSymbolCalcParams //TODO: is it used anywhere? Can be removed?
    private fMarginParams!: TSymbolCalcParams
    private fPwr10!: number
    public stopLossValidationStrategy: ValidationStrategy | null = null
    public takeProfitValidationStrategy: ValidationStrategy | null = null
    public stopLossCalculationStrategy: StoplossCalculationStrategy | null = null
    public takeProfitCalculationStrategy: TakeprofitCalculationStrategy | null = null
    public m_pOrderValues = new OrderValues()
    public m_Strategies: Map<string, OrderStrategies> = new Map()
    public isStrategyInitialized = false
    public atPriceValidationStrategy: ValidationStrategy | null = null
    public isTransparent = false

    tpos: TTradePosition
    tstop!: TTrailingStopInfo
    symbol: TSymbolData | null //positions like deposit or withdrawal have no symbol
    flag!: boolean
    visible: boolean
    StopLossHit!: boolean
    TakeProfitHit!: boolean
    PendingPartialCloseHit!: boolean
    AdditionalInfoForStatistics!: {
        risk_amount: string
        risk_percentage: string
        tp_percentage: string
        sl_percentage: string
    }

    constructor(
        SymList: TSymbolList,
        ticket: number,
        SymbolName: string,
        OpenTime: TDateTime,
        PosType: TTradePositionType,
        lot: number,
        OpenPrice: number,
        StopLoss: number,
        TakeProfit: number,
        comments: string,
        MagicNumber: number,
        AutoCloseTime: TDateTime = 0,
        isHistory = false
    ) {
        this.tpos = {
            ticket: ticket,
            OpenTime: OpenTime,
            CloseTime: OpenTime, // Assuming CloseTime is set to OpenTime initially
            PosType: PosType,
            lot: lot,
            SymbolName: SymbolName,
            OpenPrice: OpenPrice,
            ClosePrice: OpenPrice, // Assuming ClosePrice is set to OpenPrice initially
            StopLoss: StopLoss,
            TakeProfit: TakeProfit,
            commission: 0,
            swap: 0,
            profit: 0,
            ProfitPips: 0,
            Comments: comments,
            margin: 0,
            HedgedMargin: 0,
            MagicNumber: MagicNumber,
            MaxEquity: 0,
            MinEquity: 0,
            AutoCloseTime: AutoCloseTime,
            orderStatus: TTradePositionStatus.tps_None,
            pendingPartialCloseLotValue: 0,
            pendingPartialClosePrice: 0,
            pendingPartialCloseMarketPrice: 0,
            pendingPartialClosePoints: 0,
            isPendingPartialClosed: false,
            ticketToJoin: null
        }

        this.fSymbolList = SymList
        this.symbol = this.fSymbolList.GetOrCreateSymbol(SymbolName, true)

        this.SetTrailingStop(false, TTrailingStopType.ts_Immediate, 0, 0, 0)
        if (!isHistory) {
            this.InitDynamicInfo()
        }
        this.visible = true

        this.m_pOrderValues.lot = lot
        this.m_pOrderValues.atPrice = OpenPrice
    }

    public selectAllRelatedOrderLevels(multiSelect: boolean): void {
        for (const levels of this.orderLevels.values()) {
            levels.openPriceOrderLevel?.onSelect(multiSelect)
            levels.stopLossOrderLevel?.onSelect(multiSelect)
            levels.takeProfitOrderLevel?.onSelect(multiSelect)
            levels.sellBuyConnectorLine?.onSelect()
            levels.partialCloseOrderLevel?.onSelect(multiSelect)
        }
    }

    public deselectAllRelatedOrderLevels(): void {
        for (const levels of this.orderLevels.values()) {
            levels.openPriceOrderLevel?.onDeselect()
            levels.stopLossOrderLevel?.onDeselect()
            levels.takeProfitOrderLevel?.onDeselect()
            levels.sellBuyConnectorLine?.onDeselect()
            levels.partialCloseOrderLevel?.onDeselect()
        }
    }

    public static LoadFromOffsStringList(SymList: TSymbolList, list: TOffsStringList): TTradePos {
        const result = new TTradePos(SymList, 0, '', 0, TTradePositionType.tp_Buy, 0, 0, 0, 0, '', 0)
        result.LoadFromList(list)
        result.fSymbolList = SymList
        result.symbol = result.fSymbolList.GetOrCreateSymbol(result.tpos.SymbolName, true)
        result.InitDynamicInfo()
        result.visible = true

        return result
    }

    public LoadFromList(list: TOffsStringList): void {
        const vars = new TVarList()
        const list1 = new TOffsStringList()

        vars.LoadFromListSection(list, 'settings')

        this.tpos.ticket = vars.GetInt('ticket')
        this.tpos.PosType = vars.GetInt('PosType')
        this.tpos.lot = vars.GetDouble('lot')
        this.tpos.SymbolName = vars.GetValue('symbol')
        this.tpos.OpenTime = vars.GetDateTime('OpenTime')
        this.tpos.OpenPrice = vars.GetDouble('OpenPrice')
        this.tpos.StopLoss = vars.GetDouble('StopLoss')
        this.tpos.TakeProfit = vars.GetDouble('TakeProfit')
        this.tpos.CloseTime = vars.GetDateTime('CloseTime')
        this.tpos.ClosePrice = vars.GetDouble('ClosePrice')
        this.tpos.swap = vars.GetDouble('swap')
        this.tpos.profit = vars.GetDouble('profit')
        this.tpos.ProfitPips = vars.GetInt('ProfitPips')
        this.tpos.commission = vars.GetDouble('commission')
        this.tpos.margin = vars.GetDouble('margin')
        this.tpos.HedgedMargin = vars.GetDouble('HedgedMargin')
        this.tpos.Comments = vars.GetValue('comments')
        this.tpos.MagicNumber = vars.GetInt('MagicNumber')
        this.tpos.MinEquity = vars.GetDouble('MinEquity')
        this.tpos.MaxEquity = vars.GetDouble('MaxEquity')
        this.tpos.AutoCloseTime = vars.GetDateTime('AutoCloseTime')
        this.tpos.pendingPartialCloseLotValue = vars.GetDouble('pendingPartialCloseLotValue')
        this.tpos.pendingPartialClosePrice = vars.GetDouble('pendingPartialClosePrice')
        this.tpos.pendingPartialCloseMarketPrice = vars.GetDouble('pendingPartialCloseMarketPrice')
        this.tpos.pendingPartialClosePoints = vars.GetDouble('pendingPartialClosePoints')
        this.tpos.isPendingPartialClosed = vars.GetBool('isPendingPartialClosed')

        list.GetSection('TrailingStop', list1)
        this.tstop.LoadFromList(list1)

        this.InitDynamicInfo()

        vars.Clear()
        list1.Clear()

        this.m_pOrderValues.lot = this.tpos.lot
        this.m_pOrderValues.atPrice = this.tpos.OpenPrice
    }

    public SaveToList(list: TOffsStringList): void {
        const vars = new TVarList()
        try {
            vars.AddVarInt('ticket', this.tpos.ticket)
            vars.AddVarInt('PosType', this.tpos.PosType)
            vars.AddVarDouble('lot', this.tpos.lot, 4)
            vars.AddVarStr('symbol', this.tpos.SymbolName)
            vars.AddVarDateTime('OpenTime', this.tpos.OpenTime)
            vars.AddVarDouble('OpenPrice', this.tpos.OpenPrice, this.symbol?.symbolInfo.decimals || 2)
            vars.AddVarDouble('StopLoss', this.tpos.StopLoss, this.symbol?.symbolInfo.decimals || 2)
            vars.AddVarDouble('TakeProfit', this.tpos.TakeProfit, this.symbol?.symbolInfo.decimals || 2)
            vars.AddVarDateTime('CloseTime', this.tpos.CloseTime)
            vars.AddVarDouble('ClosePrice', this.tpos.ClosePrice, this.symbol?.symbolInfo.decimals || 2)
            vars.AddVarDouble('swap', this.tpos.swap, 2)
            vars.AddVarDouble('profit', this.tpos.profit, 2)
            vars.AddVarInt('ProfitPips', this.tpos.ProfitPips)
            vars.AddVarDouble('commission', this.tpos.commission, 2)
            vars.AddVarDouble('margin', this.tpos.margin, 2)
            vars.AddVarDouble('HedgedMargin', this.tpos.HedgedMargin, 2)
            vars.AddVarStr('comments', this.tpos.Comments)
            vars.AddVarInt('MagicNumber', this.tpos.MagicNumber)
            vars.AddVarDouble('MinEquity', this.tpos.MinEquity, 2)
            vars.AddVarDouble('MaxEquity', this.tpos.MaxEquity, 2)
            vars.AddVarDateTime('AutoCloseTime', this.tpos.AutoCloseTime)
            vars.AddVarDouble('pendingPartialCloseLotValue', this.tpos.pendingPartialCloseLotValue, 2)
            vars.AddVarDouble(
                'pendingPartialClosePrice',
                this.tpos.pendingPartialClosePrice,
                this.symbol?.symbolInfo.decimals || 2
            )
            vars.AddVarDouble(
                'pendingPartialCloseMarketPrice',
                this.tpos.pendingPartialCloseMarketPrice,
                this.symbol?.symbolInfo.decimals || 2
            )
            vars.AddVarDouble('pendingPartialClosePoints', this.tpos.pendingPartialClosePoints, 2)
            vars.AddVarBool('isPendingPartialClosed', this.tpos.isPendingPartialClosed)
            vars.SaveToList(list, 'settings')
        } finally {
            vars.Clear()
        }

        list.OpenSection('TrailingStop')
        this.tstop.SaveToList(list)
        list.CloseSection()
    }

    get PosType(): TTradePositionType {
        return this.tpos.PosType
    }

    public UpdatePriceForOpenPosition(): void {
        this.tpos.ClosePrice = this.GetClosePrice()
        this.StopLossHit = false
        this.TakeProfitHit = false
        this.PendingPartialCloseHit = false

        switch (this.tpos.PosType) {
            case TTradePositionType.tp_Buy: {
                if (this.tpos.StopLoss !== 0 && this.tpos.ClosePrice <= this.tpos.StopLoss) {
                    if (!(GlobalOptions.Options.CloseByGapPriceOnSL && this.symbol?.IsGap)) {
                        this.tpos.ClosePrice = this.tpos.StopLoss
                    }
                    this.StopLossHit = true
                }
                if (this.tpos.TakeProfit !== 0 && this.tpos.ClosePrice >= this.tpos.TakeProfit) {
                    this.tpos.ClosePrice = this.tpos.TakeProfit
                    this.TakeProfitHit = true
                }
                break
            }
            case TTradePositionType.tp_Sell: {
                if (this.tpos.StopLoss !== 0 && this.tpos.ClosePrice >= this.tpos.StopLoss) {
                    if (!(GlobalOptions.Options.CloseByGapPriceOnSL && this.symbol?.IsGap)) {
                        this.tpos.ClosePrice = this.tpos.StopLoss
                    }
                    this.StopLossHit = true
                }
                if (this.tpos.TakeProfit !== 0 && this.tpos.ClosePrice <= this.tpos.TakeProfit) {
                    this.tpos.ClosePrice = this.tpos.TakeProfit
                    this.TakeProfitHit = true
                }
                break
            }
            default:
            // do nothing, ignore other position types like pending orders
        }

        if (this.tpos.pendingPartialClosePrice !== 0) {
            const initialPartialCloseMarketPrice = this.tpos.pendingPartialCloseMarketPrice

            if (initialPartialCloseMarketPrice >= this.tpos.pendingPartialClosePrice) {
                if (this.tpos.ClosePrice <= this.tpos.pendingPartialClosePrice) {
                    this.PendingPartialCloseHit = true
                }
            } else {
                if (this.tpos.ClosePrice >= this.tpos.pendingPartialClosePrice) {
                    this.PendingPartialCloseHit = true
                }
            }
        }
    }

    public GetClosePrice(): number {
        if (!this.symbol) return 0

        switch (this.tpos.PosType) {
            case TTradePositionType.tp_Sell:
            case TTradePositionType.tp_BuyStop:
            case TTradePositionType.tp_BuyLimit: {
                return this.symbol.ask
            }
            default: {
                return this.symbol.bid
            }
        }
    }

    public SetTrailingStop_TStopInfo(info: TTrailingStopInfo): void {
        this.SetTrailingStop(info.enabled, info.tsType, info.AtProfit, info.StopPoints, info.StepPoints)
    }

    public SetTrailingStop(
        enabled: boolean,
        tsType: TTrailingStopType,
        profit: number,
        stop: number,
        step: number
    ): void {
        this.tstop = new TTrailingStopInfo(enabled, tsType, profit, stop, step)
    }

    private CorrectCurr = (currency: string): string => {
        if (currency === 'USDT' || currency === 'USDC') return 'USD'
        return currency
    }

    private InitDynamicInfo(): void {
        if (!this.symbol) return

        this.fPwr10 = Math.pow(10, this.symbol.symbolInfo.decimals)

        let curr1: string, curr2: string

        switch (this.symbol.symbolInfo.s_type) {
            case TSymbolType.st_Stock: {
                this.fCalcType = TSymbolCalcType.sc_Normal_xxxUSD
                break
            }

            case TSymbolType.st_Crypto: {
                curr1 = this.CorrectCurr(this.symbol.symbolInfo.BaseCurrency)
                curr2 = this.CorrectCurr(this.tpos.SymbolName.slice(curr1.length))

                if (curr2 === 'USD') {
                    this.fCalcType = TSymbolCalcType.sc_Normal_xxxUSD
                } else if (curr1 === 'USD') {
                    this.fCalcType = TSymbolCalcType.sc_InvertedUSD_USDxxx
                } else {
                    this.fCalcType = TSymbolCalcType.sc_Cross
                }
                break
            }

            case TSymbolType.st_CurrencyPair: {
                curr1 = this.tpos.SymbolName.slice(0, 3)
                curr2 = this.tpos.SymbolName.slice(3, 6)
                if (this.symbol.symbolInfo.group === 'Indexes') {
                    curr1 = this.symbol.symbolInfo.SymbolName
                    curr2 = ''
                }

                if (this.symbol.symbolInfo.group === 'Commodities' || this.symbol.symbolInfo.group === 'Metals') {
                    this.fCalcType = TSymbolCalcType.sc_Normal_xxxUSD
                    break
                }

                if (curr2 === 'USD') {
                    this.fCalcType = TSymbolCalcType.sc_Normal_xxxUSD
                } else if (curr1 === 'USD') {
                    this.fCalcType = TSymbolCalcType.sc_InvertedUSD_USDxxx
                } else {
                    this.fCalcType = TSymbolCalcType.sc_Cross
                }

                break
            }

            case TSymbolType.st_Index: {
                this.fCalcType = TSymbolCalcType.sc_Index
                break
            }

            default: {
                throw new StrangeError(`Symbol type ${this.symbol.symbolInfo.s_type} not supported`)
            }
        }

        const margin = this.symbol.symbolInfo
            .getSymbolCalculationStrategy()
            .calculateMarginRequirementForSymbol(this.tpos.PosType, this.tpos.OpenPrice)

        if (this.fCalcType === TSymbolCalcType.sc_Cross) {
            // xxxyyy case
            this.tpos.HedgedMargin = this.GetMargin(true, true)
        } else {
            // xxxUSD or USDxxx case
            this.tpos.HedgedMargin = this.GetMargin(true)
        }

        if (margin) {
            this.tpos.margin = margin * this.tpos.lot
        }
    }

    public GetMargin(hedged: boolean, isCross = false): number {
        if (!this.symbol) return 0 // we do this because symbol can be null for positions like deposit or withdrawal //TODO: find a better way to handle this
        const OpType: TOperationType = BasicClasses_CommonFunctions.ConvertOp(this.tpos.PosType)
        // Simplified the return statement to avoid repetition.
        const marginType = hedged ? this.symbol.symbolInfo.HedgedMargin : this.symbol.symbolInfo.Margin

        let marginInUsd = 0
        if (isCross) {
            const exchangeRate = OpType === TOperationType.ot_Sell ? this.symbol.ask : this.symbol.bid

            const USDConversationSymbol = (
                this.symbol.symbolInfo.getSymbolCalculationStrategy() as CrossSymbolCalculationStrategy
            ).USDConversationSymbol

            if (!USDConversationSymbol) {
                throw new StrangeError('USD conversation symbol is not found')
            }
            marginInUsd =
                marginType * exchangeRate * this.GetOpenPriceForMarginCalculation(USDConversationSymbol) * this.tpos.lot
        } else {
            marginInUsd = marginType * this.tpos.lot * this.GetOpenPriceForMarginCalculation(this.symbol)
        }

        return marginInUsd
    }

    private GetOpenPriceForMarginCalculation(symbol: TSymbolData): number {
        if (this.fCalcType === TSymbolCalcType.sc_Normal_xxxUSD) {
            return symbol.getCurrentOpenPriceByOrderType(this.PosType)
        } else {
            return 1 / symbol.getCurrentOpenPriceByOrderType(this.PosType)
        }
    }

    public GetPosInfo(): string {
        if (!this.symbol) throw new StrangeError('GetPosInfo - SYMBOL NOT FOUND')

        return t('processingCore.tradePosition.position-info', {
            symbolName: this.tpos.SymbolName,
            posType: ProcessingCoreUtils.StrPosType(this.tpos.PosType),
            lot: this.tpos.lot.toFixed(4),
            openPrice: this.symbol.FormatPriceToStr(this.tpos.OpenPrice),
            stopLoss: this.symbol.FormatPriceToStr(this.tpos.StopLoss),
            takeProfit: this.symbol.FormatPriceToStr(this.tpos.TakeProfit)
        })
    }

    public SwitchPositionToOpen(time: TDateTime): void {
        if (!this.symbol) {
            throw new StrangeError('Symbol not found')
        }

        switch (this.tpos.PosType) {
            case TTradePositionType.tp_BuyLimit:
            case TTradePositionType.tp_BuyStop: {
                this.tpos.PosType = TTradePositionType.tp_Buy
                break
            }
            default: {
                this.tpos.PosType = TTradePositionType.tp_Sell
            }
        }

        const pointCost =
            (this.symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType, null) *
                this.tpos.lot) /
            this.symbol.symbolInfo.MinPoint

        this.tpos.OpenTime = time
        this.tpos.CloseTime = time
        this.tpos.ClosePrice = this.GetClosePrice()
        this.tpos.margin = this.GetMargin(false)
        this.tpos.HedgedMargin = this.GetMargin(true)
        this.UpdateProfit()
    }

    public ApplyCommission(): void {
        if (!this.symbol) return // we do this because symbol can be null for positions like deposit or withdrawal //TODO: find a better way to handle this

        this.tpos.commission += DelphiMathCompatibility.RoundTo(this.symbol.symbolInfo.CommPerLot * this.tpos.lot, -2)
    }

    public RemoveCommission(): void {
        if (!this.symbol) return // we do this because symbol can be null for positions like deposit or withdrawal //TODO: find a better way to handle this

        this.tpos.commission = Math.max(
            0,
            this.tpos.commission -
                DelphiMathCompatibility.RoundTo(this.symbol.symbolInfo.CommPerLot * this.tpos.lot, -2)
        )
    }

    public UpdateSwaps(CurrTime: TDateTime): void {
        if (!this.symbol) return // we do this because symbol can be null for positions like deposit or withdrawal //TODO: find a better way to handle this

        let swp: number
        let date: TDateTime

        if (!DateUtils.IsSameDay(this.tpos.CloseTime, CurrTime)) {
            if (this.tpos.PosType === TTradePositionType.tp_Buy) {
                swp = this.symbol.symbolInfo.SwapLong
            } else {
                swp = this.symbol.symbolInfo.SwapShort
            }

            // if we roll back
            date = this.tpos.CloseTime
            if (this.tpos.CloseTime > CurrTime) {
                swp = -swp
                date = CurrTime
            }

            //FIXME: we only need to do this adjustment if the position was held on weekend, and seems to be no check for that
            if (DateUtils.DayOfWeek(date) === EDayOfWeek.Wednesday) {
                swp = swp * 3
            }

            const pointCost = this.getPointCostUSD()

            this.tpos.swap += swp * pointCost * this.symbol.symbolInfo.MinPoint
        }
    }

    public calculateProfitForClosePrice(closePrice: number): number {
        const pointCostInUSD_x = this.getPointCostUSD()
        const profitDirectionMultiplier = this.getProfitDirectionMultiplier()
        return (
            ((closePrice - this.tpos.OpenPrice) * pointCostInUSD_x + this.tpos.swap - this.tpos.commission) *
            profitDirectionMultiplier
        )
    }

    private getPointCostUSD() {
        if (!this.symbol) {
            throw new StrangeError('Symbol is not found for trading position')
        }
        const pointCost =
            (this.symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType, null) *
                this.tpos.lot) /
            this.symbol.symbolInfo.MinPoint
        return pointCost
    }

    public getProfitDirectionMultiplier(): number {
        return this.tpos.PosType === TTradePositionType.tp_Sell ? -1 : 1
    }

    private calculateProfitInPips(): number {
        const profitDirectionMultiplier = this.getProfitDirectionMultiplier()
        return Math.round((this.tpos.ClosePrice - this.tpos.OpenPrice) * this.fPwr10) * profitDirectionMultiplier
    }

    public UpdateProfit(): void {
        this.tpos.profit = this.calculateProfitForClosePrice(this.tpos.ClosePrice)

        this.tpos.ProfitPips = this.calculateProfitInPips()

        this.tpos.MaxEquity = Math.max(this.tpos.profit, this.tpos.MaxEquity)
        this.tpos.MinEquity = Math.min(this.tpos.profit, this.tpos.MinEquity)
    }

    public getStopLossPoints(): number {
        const profitDirectionMultiplier = [
            TTradePositionType.tp_Sell,
            TTradePositionType.tp_SellStop,
            TTradePositionType.tp_SellLimit
        ].includes(this.tpos.PosType)
            ? 1
            : -1

        if (this.tpos.StopLoss === 0) {
            return 0
        }

        return Math.round((this.tpos.StopLoss - this.tpos.OpenPrice) * this.fPwr10) * profitDirectionMultiplier
    }

    public getTakeProfitPoints(): number {
        const profitDirectionMultiplier = [
            TTradePositionType.tp_Sell,
            TTradePositionType.tp_SellStop,
            TTradePositionType.tp_SellLimit
        ].includes(this.tpos.PosType)
            ? -1
            : 1

        if (this.tpos.TakeProfit === 0) {
            return 0
        }

        return Math.round((this.tpos.TakeProfit - this.tpos.OpenPrice) * this.fPwr10) * profitDirectionMultiplier
    }

    public getTakeProfitUSD(price: number = this.tpos.TakeProfit, openPrice: number = this.tpos.OpenPrice): number {
        if (!this.symbol) {
            throw new StrangeError('Symbol is not found')
        }

        const pointCost =
            this.symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType, null) *
            this.tpos.lot

        const profitDirectionMultiplier = [
            TTradePositionType.tp_Sell,
            TTradePositionType.tp_SellStop,
            TTradePositionType.tp_SellLimit
        ].includes(this.tpos.PosType)
            ? -1
            : 1

        return (price - openPrice) * this.fPwr10 * pointCost * profitDirectionMultiplier
    }

    public getStopLossUSD(price: number = this.tpos.StopLoss, openPrice: number = this.tpos.OpenPrice): number {
        if (!this.symbol) {
            throw new StrangeError('Symbol is not found')
        }

        const pointCost =
            this.symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType, null) *
            this.tpos.lot

        const profitDirectionMultiplier = [
            TTradePositionType.tp_Sell,
            TTradePositionType.tp_SellStop,
            TTradePositionType.tp_SellLimit
        ].includes(this.tpos.PosType)
            ? -1
            : 1

        return (price - openPrice) * this.fPwr10 * pointCost * profitDirectionMultiplier
    }

    paintOrderIcon(canvas: TGdiPlusCanvas, mainChart: TMainChart, yShiftForOrderIcon: number): void {
        const ctx = canvas.graphics.Context
        if (!ctx) return

        this.initStrategies(mainChart)

        const dpr = window.devicePixelRatio || 1
        const x = mainChart.GetXFromDate(this.tpos.OpenTime)

        let barRecord: TBarRecord | null = mainChart.GetBarFromX(x)
        if (!barRecord) {
            barRecord = mainChart.GetLastBarInTesting()
        }

        let icon: HTMLImageElement | HTMLCanvasElement | null = null
        let y = 0

        if (this.tpos.PosType === TTradePositionType.tp_Buy) {
            icon = GlobalImageManager.Instance.buyOrderIcon
            if (!icon) {
                throw new StrangeError('Buy order icon is not found')
            }
            icon = GlobalImageManager.Instance.buyOrderIcon as HTMLImageElement

            icon = changeImageColor(
                icon,
                mainChart.ChartOptions.ColorScheme.BuyMarkerColor,
                icon.width * dpr,
                icon.height * dpr
            )

            if (barRecord) {
                y = mainChart.GetY(barRecord.low) + 15 * dpr
            }

            y += yShiftForOrderIcon * icon.height * 1.125
        } else if (this.tpos.PosType === TTradePositionType.tp_Sell) {
            icon = GlobalImageManager.Instance.sellOrderIcon
            if (!icon) {
                throw new StrangeError('Sell order icon is not found')
            }
            icon = GlobalImageManager.Instance.sellOrderIcon as HTMLImageElement

            icon = changeImageColor(
                icon,
                mainChart.ChartOptions.ColorScheme.SellMarkerColor,
                icon.width * dpr,
                icon.height * dpr
            )

            if (barRecord) {
                y = mainChart.GetY(barRecord.high) - 15 * dpr
            }
            y -= yShiftForOrderIcon * icon.height * 1.125
        }

        if (icon) {
            ctx.drawImage(icon, x - icon.width / 2, y - icon.height / 2)
        }
    }

    paintOrderIconForClosedOrder(
        canvas: TGdiPlusCanvas,
        mainChart: TMainChart,
        yShiftForOpenIcon: number,
        yShiftForCloseIconL: number
    ): void {
        const ctx = canvas.graphics.Context
        if (!ctx) return

        const dpr = window.devicePixelRatio || 1

        const xOpen = mainChart.GetXFromDate(this.tpos.OpenTime)
        const xClose = mainChart.GetXFromDate(this.tpos.CloseTime)

        // const barInfoOpen: IAnchorPoint | null = mainChart.GetAnchorPointByX(xOpen)
        // const barInfoClose = mainChart.GetAnchorPointByX(xClose)
        let barRecordOpen: TBarRecord | null = mainChart.GetBarFromX(xOpen)
        // it`s for case if we have no info about closed bar

        let barRecordClose: TBarRecord | null = mainChart.GetBarFromX(xClose)
        if (!barRecordClose) {
            barRecordClose = mainChart.GetLastBarInTesting()
        }
        if (!barRecordOpen) {
            barRecordOpen = barRecordClose
        }

        let iconOpen: HTMLImageElement | HTMLCanvasElement | null = null
        let iconClose: HTMLImageElement | HTMLCanvasElement | null = null

        let yOpen = 0
        let yClose = 0

        if (this.tpos.PosType === TTradePositionType.tp_Buy) {
            iconOpen = GlobalImageManager.Instance.buyOrderIcon
            iconClose = GlobalImageManager.Instance.sellOrderIcon
            if (!iconOpen || !iconClose) {
                throw new StrangeError('Buy or sell order icon is not found')
            }
            iconOpen = GlobalImageManager.Instance.buyOrderIcon as HTMLImageElement
            iconClose = GlobalImageManager.Instance.sellOrderIcon as HTMLImageElement

            iconOpen = changeImageColor(
                iconOpen,
                mainChart.ChartOptions.ColorScheme.BuyMarkerColor,
                iconOpen.width * dpr,
                iconOpen.height * dpr
            )
            iconClose = changeImageColor(
                iconClose,
                mainChart.ChartOptions.ColorScheme.SellMarkerColor,
                iconClose.width * dpr,
                iconClose.height * dpr
            )

            if (barRecordOpen) {
                yOpen = mainChart.GetY(barRecordOpen.low) + 15 * dpr
            }
            if (barRecordClose) {
                yClose = mainChart.GetY(barRecordClose.high) - 15 * dpr
            }

            yOpen += yShiftForOpenIcon * iconOpen.height * 1.125
            yClose -= yShiftForCloseIconL * iconClose.height * 1.125
        } else if (this.tpos.PosType === TTradePositionType.tp_Sell) {
            iconOpen = GlobalImageManager.Instance.sellOrderIcon as HTMLImageElement
            iconClose = GlobalImageManager.Instance.buyOrderIcon as HTMLImageElement

            iconOpen = changeImageColor(
                iconOpen as HTMLImageElement,
                mainChart.ChartOptions.ColorScheme.SellMarkerColor,
                iconOpen.width * dpr,
                iconOpen.height * dpr
            )
            iconClose = changeImageColor(
                iconClose as HTMLImageElement,
                mainChart.ChartOptions.ColorScheme.BuyMarkerColor,
                iconClose.width * dpr,
                iconClose.height * dpr
            )

            if (barRecordOpen) {
                yOpen = mainChart.GetY(barRecordOpen.high) - 15 * dpr
            }
            if (barRecordClose) {
                yClose = mainChart.GetY(barRecordClose.low) + 15 * dpr
            }

            yOpen -= yShiftForOpenIcon * iconOpen.height * 1.125
            yClose += yShiftForCloseIconL * iconClose.height * 1.125
        }

        const levels = this.orderLevels.get(mainChart.ChartWindow as TChartWindow)
        const sellBuyConnectorLine = levels?.sellBuyConnectorLine

        if (iconOpen) {
            ctx.drawImage(iconOpen, xOpen - iconOpen.width / 2, yOpen - iconOpen.height / 2)
            if (sellBuyConnectorLine && sellBuyConnectorLine.openOrderInfo) {
                sellBuyConnectorLine.openOrderInfo.coords = {
                    x: xOpen - iconOpen.width / 2,
                    y: yOpen - iconOpen.height / 2,
                    height: iconOpen.height,
                    width: iconOpen.width
                }
            }
        }

        if (iconClose) {
            ctx.drawImage(iconClose, xClose - iconClose.width / 2, yClose - iconClose.height / 2)
            if (sellBuyConnectorLine && sellBuyConnectorLine.closeOrderInfo) {
                sellBuyConnectorLine.closeOrderInfo.coords = {
                    x: xClose - iconClose.width / 2,
                    y: yClose - iconClose.height / 2,
                    height: iconClose.height,
                    width: iconClose.width
                }
            }
        }
    }

    public paintLinesForClosedOrder(canvas: TGdiPlusCanvas, mainChart: TMainChart): void {
        const ctx = canvas.graphics.Context
        if (!ctx) return

        const xOpen = mainChart.GetXFromDate(this.tpos.OpenTime)
        const xClose = mainChart.GetXFromDate(this.tpos.CloseTime)
        let markerOpen: HTMLImageElement | HTMLCanvasElement | null = null
        let markerClose: HTMLImageElement | HTMLCanvasElement | null = null
        let openPosType = ''
        let closePosType = ''

        const isProfitable = this.tpos.profit > 0

        const yOpen = mainChart.GetY(this.tpos.OpenPrice)
        const yClose = mainChart.GetY(this.tpos.ClosePrice)

        GlobalImageManager.Instance.setBuyOrderMarkerColor(mainChart.ChartOptions.ColorScheme.BuyMarkerColor)
        GlobalImageManager.Instance.setSellOrderMarkerColor(mainChart.ChartOptions.ColorScheme.SellMarkerColor)

        if (this.tpos.PosType === TTradePositionType.tp_Buy) {
            markerOpen = GlobalImageManager.Instance.buyOrderMarker
            markerClose = GlobalImageManager.Instance.sellOrderMarker

            openPosType = 'Buy '
            closePosType = 'Sell'
        } else if (this.tpos.PosType === TTradePositionType.tp_Sell) {
            markerOpen = GlobalImageManager.Instance.sellOrderMarker
            markerClose = GlobalImageManager.Instance.buyOrderMarker

            openPosType = 'Sell'
            closePosType = 'Buy '
        }
        const chartWindow = mainChart.ChartWindow as TChartWindow
        const controlsManager = chartWindow.controlsManager

        if (!this.orderLevels.has(chartWindow)) {
            this.orderLevels.set(chartWindow, {
                openPriceOrderLevel: null,
                takeProfitOrderLevel: null,
                stopLossOrderLevel: null,
                sellBuyConnectorLine: null,
                partialCloseOrderLevel: null
            })
        }

        const levels = this.orderLevels.get(chartWindow)
        if (!levels) {
            throw new StrangeError('Levels are not found')
        }

        if (!levels.sellBuyConnectorLine) {
            levels.sellBuyConnectorLine = new SellBuyConnectorLine(
                new ChartControlParams(
                    new CanvasLayer(
                        mainChart.HTML_Canvas,
                        chartWindow,
                        LayerType.ENTIRE_CHART_BLOCKING,
                        'SellBuyConnectorLine'
                    ),
                    new LocationParams(0, 0, 0, 0),
                    ChartControlId.UNKNOWN,
                    false
                ),
                this,
                chartWindow
            )
        }

        if (
            levels.sellBuyConnectorLine &&
            !controlsManager.hasControl(levels.sellBuyConnectorLine) &&
            mainChart.SymbolData.symbolInfo.SymbolName === this.tpos.SymbolName
        ) {
            controlsManager.addControl(levels.sellBuyConnectorLine)
        }

        if (levels.sellBuyConnectorLine) {
            levels.sellBuyConnectorLine.setLocation(new TRect(xOpen, yOpen, xClose, yClose))

            levels.sellBuyConnectorLine.markerClose = markerClose
            levels.sellBuyConnectorLine.markerOpen = markerOpen
            levels.sellBuyConnectorLine.isProfitable = isProfitable
            levels.sellBuyConnectorLine.setOpenOrderInfo(
                openPosType,
                this.tpos.OpenPrice.toFixed(this.symbol?.symbolInfo.decimals || 5),
                this.tpos.lot.toFixed(2)
            )
            levels.sellBuyConnectorLine.setCloseOrderInfo(
                closePosType,
                this.tpos.ClosePrice.toFixed(this.symbol?.symbolInfo.decimals || 5),
                this.tpos.lot.toFixed(2),
                this.tpos.profit.toFixed(2),
                this.tpos.ProfitPips
            )
            levels.sellBuyConnectorLine.draw(canvas)
        }
    }

    isMyControl(control: ChartControl | null): boolean {
        if (!control) {
            return false
        }

        for (const levels of this.orderLevels.values()) {
            if (
                control === levels.openPriceOrderLevel ||
                control === levels.takeProfitOrderLevel ||
                control === levels.stopLossOrderLevel ||
                control === levels.sellBuyConnectorLine ||
                control === levels.partialCloseOrderLevel
            ) {
                return true
            }
        }

        return false
    }

    public orderLevels: Map<
        TChartWindow,
        {
            openPriceOrderLevel: OrderLevel | null
            takeProfitOrderLevel: OrderLevel | null
            stopLossOrderLevel: OrderLevel | null
            sellBuyConnectorLine: SellBuyConnectorLine | null
            partialCloseOrderLevel: PartialCloseLevel | null
        }
    > = new Map()

    initOrderLevels(mainChart: TMainChart): void {
        const chartWindow = mainChart.ChartWindow as TChartWindow
        const controlsManager = chartWindow.controlsManager

        if (!this.orderLevels.has(chartWindow)) {
            this.orderLevels.set(chartWindow, {
                openPriceOrderLevel: null,
                takeProfitOrderLevel: null,
                stopLossOrderLevel: null,
                sellBuyConnectorLine: null,
                partialCloseOrderLevel: null
            })
        }

        const levels = this.orderLevels.get(chartWindow)
        if (!levels) {
            throw new StrangeError('Levels are not found')
        }

        if (this.visible) {
            if (!levels.takeProfitOrderLevel) {
                levels.takeProfitOrderLevel = new TakeProfitLevel(
                    new ChartControlParams(
                        new CanvasLayer(
                            mainChart.HTML_Canvas,
                            chartWindow,
                            LayerType.ENTIRE_CHART_BLOCKING,
                            'TakeProfitLine'
                        ),
                        new LocationParams(0, 0, 0, 0),
                        ChartControlId.UNKNOWN,
                        true
                    ),
                    this.takeProfitValidationStrategy!,
                    this,
                    this.takeProfitCalculationStrategy!,
                    'Takeprofit',
                    chartWindow
                )
            }

            if (!levels.stopLossOrderLevel) {
                levels.stopLossOrderLevel = new StopLossLevel(
                    new ChartControlParams(
                        new CanvasLayer(
                            mainChart.HTML_Canvas,
                            chartWindow,
                            LayerType.ENTIRE_CHART_BLOCKING,
                            'StopLossLine'
                        ),
                        new LocationParams(0, 0, 0, 0),
                        ChartControlId.UNKNOWN,
                        true
                    ),
                    this.stopLossValidationStrategy!,
                    this,
                    this.stopLossCalculationStrategy!,
                    'Stoploss',
                    chartWindow
                )
            }

            if (!levels.partialCloseOrderLevel) {
                levels.partialCloseOrderLevel = new PartialCloseLevel(
                    new ChartControlParams(
                        new CanvasLayer(
                            mainChart.HTML_Canvas,
                            chartWindow,
                            LayerType.ENTIRE_CHART_BLOCKING,
                            'PartialCloseLine'
                        ),
                        new LocationParams(0, 0, 0, 0),
                        ChartControlId.UNKNOWN,
                        true
                    ),
                    null,
                    this,
                    null,
                    'PartialClose',
                    chartWindow
                )
            }
        }

        if (!levels.openPriceOrderLevel) {
            if ([TTradePositionType.tp_Buy, TTradePositionType.tp_Sell].includes(this.PosType)) {
                levels.openPriceOrderLevel = new MarketLevel(
                    new ChartControlParams(
                        new CanvasLayer(
                            mainChart.HTML_Canvas,
                            chartWindow,
                            LayerType.ENTIRE_CHART_BLOCKING,
                            'MarketOrderLine'
                        ),
                        new LocationParams(0, 0, 0, 0),
                        ChartControlId.UNKNOWN,
                        false
                    ),
                    null,
                    this,
                    null,
                    'OpenPrice',
                    chartWindow
                )
            } else if (
                [
                    TTradePositionType.tp_BuyLimit,
                    TTradePositionType.tp_BuyStop,
                    TTradePositionType.tp_SellLimit,
                    TTradePositionType.tp_SellStop
                ].includes(this.PosType) &&
                this.visible
            ) {
                levels.openPriceOrderLevel = new PendingLevel(
                    new ChartControlParams(
                        new CanvasLayer(
                            mainChart.HTML_Canvas,
                            chartWindow,
                            LayerType.ENTIRE_CHART_BLOCKING,
                            'PendingOrderLine'
                        ),
                        new LocationParams(0, 0, 0, 0),
                        ChartControlId.UNKNOWN,
                        true
                    ),
                    this.atPriceValidationStrategy,
                    this,
                    null,
                    'OpenPrice',
                    chartWindow
                )
            }
        }

        if (
            levels.takeProfitOrderLevel &&
            !controlsManager.hasControl(levels.takeProfitOrderLevel) &&
            mainChart.SymbolData.symbolInfo.SymbolName === this.tpos.SymbolName
        ) {
            controlsManager.addControl(levels.takeProfitOrderLevel)
        }

        if (
            levels.stopLossOrderLevel &&
            !controlsManager.hasControl(levels.stopLossOrderLevel) &&
            mainChart.SymbolData.symbolInfo.SymbolName === this.tpos.SymbolName
        ) {
            controlsManager.addControl(levels.stopLossOrderLevel)
        }

        if (
            levels.openPriceOrderLevel &&
            !controlsManager.hasControl(levels.openPriceOrderLevel) &&
            mainChart.SymbolData.symbolInfo.SymbolName === this.tpos.SymbolName
        ) {
            controlsManager.addControl(levels.openPriceOrderLevel)
        }

        if (
            levels.partialCloseOrderLevel &&
            !controlsManager.hasControl(levels.partialCloseOrderLevel) &&
            mainChart.SymbolData.symbolInfo.SymbolName === this.tpos.SymbolName
        ) {
            controlsManager.addControl(levels.partialCloseOrderLevel)
        }
    }

    paintOrderLevel(canvas: TGdiPlusCanvas, mainChart: TMainChart): void {
        const chartWindow = mainChart.ChartWindow as TChartWindow

        this.initOrderLevels(mainChart)

        const levels = this.orderLevels.get(chartWindow)
        if (!levels) {
            throw new StrangeError('Levels are not found')
        }

        if (!levels.takeProfitOrderLevel?.IsCaptured()) {
            levels.takeProfitOrderLevel?.recalculateLocationByY(this.tpos.TakeProfit)
        }

        if (!levels.stopLossOrderLevel?.IsCaptured()) {
            levels.stopLossOrderLevel?.recalculateLocationByY(this.tpos.StopLoss)
        }

        if (!levels.openPriceOrderLevel?.IsCaptured()) {
            levels.openPriceOrderLevel?.recalculateLocationByY(this.tpos.OpenPrice)
        }

        if (!levels.partialCloseOrderLevel?.IsCaptured()) {
            levels.partialCloseOrderLevel?.recalculateLocationByY(this.tpos.pendingPartialClosePrice)
        }

        if ([TTradePositionType.tp_Buy, TTradePositionType.tp_Sell].includes(this.PosType)) {
            levels.openPriceOrderLevel?.draw(canvas)
        } else if (this.visible) {
            levels.openPriceOrderLevel?.draw(canvas)
        }

        if (this.visible) {
            levels.stopLossOrderLevel?.draw(canvas)
            levels.takeProfitOrderLevel?.draw(canvas)
            levels.partialCloseOrderLevel?.draw(canvas)
        }

        this.paintRightMarkers(mainChart)
    }

    initStrategies(mainChart: TMainChart): void {
        if (this.isStrategyInitialized) {
            return
        }

        const marketValues = mainChart.marketValues

        // market buy
        const pMarketBuyStoplossCalculation = new MarketBuyStoplossCalculation()
        const pStoplossPriceValidationStrategy = new StoplossValidationMarketBuy(marketValues)
        const pMarketBuyTakeprofitCalculation = new MarketBuyTakeprofitCalculation()
        const pMarketBuyTakeprofitValidation = new TakeprofitValidationMarketBuy(marketValues)

        const marketBuyStrategies = new OrderStrategies(
            pMarketBuyStoplossCalculation,
            pStoplossPriceValidationStrategy,
            null,
            pMarketBuyTakeprofitCalculation,
            pMarketBuyTakeprofitValidation
        )

        // market sell
        const pMarketSellStoplossCalculation = new MarketSellStoplossCalculation()
        const pStoplossValidationMarketSell = new StoplossValidationMarketSell(marketValues)
        const pMarketSellTakeprofitCalculation = new MarketSellTakeprofitCalculation()
        const pMarketSellTakeprofitValidation = new TakeprofitValidationMarketSell(marketValues)

        const marketSellStrategies = new OrderStrategies(
            pMarketSellStoplossCalculation,
            pStoplossValidationMarketSell,
            null,
            pMarketSellTakeprofitCalculation,
            pMarketSellTakeprofitValidation
        )

        // buy limit
        const pStoplossValidationBuyLimit = new StoplossValidationBuyLimit(marketValues, this.m_pOrderValues)
        const pBuyLimitStoplossCalculation = new BuyLimitStoplossCalculation()
        const pBuyLimitAtPriceValidation = new BuyLimitAtPriceValidation(marketValues)
        const pBuyLimitTakeprofitCalculation = new BuyLimitTakeprofitCalculation()
        const pBuyLimitTakeprofitValidation = new TakeprofitValidationBuyLimit(marketValues, this.m_pOrderValues)

        const buyLimitStrategies = new OrderStrategies(
            pBuyLimitStoplossCalculation,
            pStoplossValidationBuyLimit,
            pBuyLimitAtPriceValidation,
            pBuyLimitTakeprofitCalculation,
            pBuyLimitTakeprofitValidation
        )

        // buy stop
        const pStoplossValidationBuyStop = new StoplossValidationBuyStop(marketValues, this.m_pOrderValues)
        const pBuyStopStoplossCalculation = new BuyStopStoplossCalculation()
        const pBuyStopAtPriceValidation = new BuyStopAtPriceValidation(marketValues)
        const pBuyStopTakeprofitCalculation = new BuyStopTakeprofitCalculation()
        const pBuyStopTakeprofitValidation = new TakeprofitValidationBuyStop(marketValues, this.m_pOrderValues)
        const buyStopStrategies = new OrderStrategies(
            pBuyStopStoplossCalculation,
            pStoplossValidationBuyStop,
            pBuyStopAtPriceValidation,
            pBuyStopTakeprofitCalculation,
            pBuyStopTakeprofitValidation
        )

        // sell limit
        const pStoplossValidationSellLimit = new StoplossValidationSellLimit(marketValues, this.m_pOrderValues)
        const pSellLimitStoplossCalculation = new SellLimitStoplossCalculation()
        const pSellLimitAtPriceValidation = new SellLimitAtPriceValidation(marketValues)
        const pSellLimitTakeprofitCalculation = new SellLimitTakeprofitCalculation()
        const pSellLimitTakeprofitValidation = new TakeprofitValidationSellLimit(marketValues, this.m_pOrderValues)
        const sellLimitStrategies = new OrderStrategies(
            pSellLimitStoplossCalculation,
            pStoplossValidationSellLimit,
            pSellLimitAtPriceValidation,
            pSellLimitTakeprofitCalculation,
            pSellLimitTakeprofitValidation
        )

        // sell stop
        const pStoplossValidationSellStop = new StoplossValidationSellStop(marketValues, this.m_pOrderValues)
        const pSellStopStoplossCalculation = new SellStopStoplossCalculation()
        const pSellStopAtPriceValidation = new SellStopAtPriceValidation(marketValues)
        const pSellStopTakeprofitCalculation = new SellStopTakeprofitCalculation()
        const pSellStopTakeprofitValidation = new TakeprofitValidationSellStop(marketValues, this.m_pOrderValues)

        const sellStopStrategies = new OrderStrategies(
            pSellStopStoplossCalculation,
            pStoplossValidationSellStop,
            pSellStopAtPriceValidation,
            pSellStopTakeprofitCalculation,
            pSellStopTakeprofitValidation
        )

        // Market Buy
        this.m_Strategies.set(this.serializeKey(OrderType.MARKET, EOperationType.BUY), marketBuyStrategies)

        // Market Sell
        this.m_Strategies.set(this.serializeKey(OrderType.MARKET, EOperationType.SELL), marketSellStrategies)

        // Buy Limit
        this.m_Strategies.set(this.serializeKey(OrderType.LIMIT, EOperationType.BUY), buyLimitStrategies)

        // Buy Stop
        this.m_Strategies.set(this.serializeKey(OrderType.STOP, EOperationType.BUY), buyStopStrategies)

        // Sell Limit
        this.m_Strategies.set(this.serializeKey(OrderType.LIMIT, EOperationType.SELL), sellLimitStrategies)

        // Sell Stop
        this.m_Strategies.set(this.serializeKey(OrderType.STOP, EOperationType.SELL), sellStopStrategies)

        this.isStrategyInitialized = true

        this.setUpStrategies()
    }

    private serializeKey(orderType: OrderType, operationType: EOperationType): string {
        return `${orderType}_${operationType}`
    }

    public getStrategies(orderType: OrderType, operationType: EOperationType): OrderStrategies {
        const key = this.serializeKey(orderType, operationType)
        const strategies = this.m_Strategies.get(key)

        if (!strategies) {
            throw new StrangeError(`Strategies not found for key ${key}`)
        }

        return strategies
    }

    setUpStrategies(): void {
        const orderOperationResult = mapTradePositionType(this.tpos.PosType)

        if (orderOperationResult) {
            const { orderType, operationType } = orderOperationResult

            const strategies = this.getStrategies(orderType, operationType)

            this.stopLossValidationStrategy = strategies.pStoplossValidationStarategy
            this.takeProfitValidationStrategy = strategies.pTakeprofitValidationStrategy
            this.stopLossCalculationStrategy = strategies.pCalucalationStrategy
            this.takeProfitCalculationStrategy = strategies.pTakeProfitCalculationStrategy
            this.atPriceValidationStrategy = strategies.pPendingAtPriceValidationStrategy
        }
    }

    reset(): void {
        this.isStrategyInitialized = false
        this.stopLossValidationStrategy = null
        this.takeProfitValidationStrategy = null
        this.stopLossCalculationStrategy = null
        this.takeProfitCalculationStrategy = null
        this.atPriceValidationStrategy = null

        for (const [chartWindow, levels] of this.orderLevels.entries()) {
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.openPriceOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.takeProfitOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.stopLossOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.sellBuyConnectorLine!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.partialCloseOrderLevel!)
        }

        this.orderLevels.clear()
    }

    public resetStopLoss(): void {
        this.tpos.StopLoss = 0
        this.resetOrderLevels()
    }

    public resetTakeProfit(): void {
        this.tpos.TakeProfit = 0
        this.resetOrderLevels()
    }

    public resetPartialClose(): void {
        this.tpos.pendingPartialClosePrice = 0
        this.tpos.pendingPartialCloseLotValue = 0
        this.tpos.pendingPartialClosePoints = 0
        this.resetOrderLevels()
    }

    resetOrderLevels(): void {
        for (const [chartWindow, levels] of this.orderLevels.entries()) {
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.openPriceOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.takeProfitOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.stopLossOrderLevel!)
            GlobalChartsController.Instance.removeControlFromControlsManager(levels.partialCloseOrderLevel!)
        }

        this.orderLevels.clear()
        GlobalChartsController.Instance.updateCharts()
    }

    setTransparentOrderLevels(isTransparent: boolean): void {
        this.isTransparent = isTransparent
    }

    onEdit(): void {
        this.visible = false
        this.reset()
    }

    onEditFinished(): void {
        this.visible = true
        GlobalChartsController.Instance.updateCharts()
    }

    paintRightMarkers(mainChart: TMainChart): void {
        const chartWindow = mainChart.ChartWindow as TChartWindow
        const levels = this.orderLevels.get(chartWindow)

        if (!levels) return

        if (
            levels.takeProfitOrderLevel &&
            this.visible &&
            (levels.takeProfitOrderLevel.IsCaptured() ||
                levels.takeProfitOrderLevel.isSelected ||
                levels.takeProfitOrderLevel.isMouseInside())
        ) {
            const color = levels.takeProfitOrderLevel.isValid
                ? levels.takeProfitOrderLevel.chartWindow.ChartOptions.ColorScheme.TakeProfitColor
                : levels.takeProfitOrderLevel.invalidColor

            mainChart.PaintOrdersRightMarker(
                (levels.takeProfitOrderLevel as TakeProfitLevel).takeProfitValues?.price || this.tpos.TakeProfit,
                color
            )
        }

        if (
            levels.stopLossOrderLevel &&
            this.visible &&
            (levels.stopLossOrderLevel.IsCaptured() ||
                levels.stopLossOrderLevel.isSelected ||
                levels.stopLossOrderLevel.isMouseInside())
        ) {
            const color = levels.stopLossOrderLevel.isValid
                ? levels.stopLossOrderLevel.chartWindow.ChartOptions.ColorScheme.StopLossColor
                : levels.stopLossOrderLevel.invalidColor

            mainChart.PaintOrdersRightMarker(
                (levels.stopLossOrderLevel as StopLossLevel).stopLossValues?.price || this.tpos.StopLoss,
                color
            )
        }

        if (
            levels.openPriceOrderLevel &&
            [TTradePositionType.tp_Buy, TTradePositionType.tp_Sell].includes(this.PosType) &&
            (levels.openPriceOrderLevel.IsCaptured() ||
                levels.openPriceOrderLevel.isSelected ||
                levels.openPriceOrderLevel.isMouseInside())
        ) {
            let color = levels.openPriceOrderLevel.isValid
                ? levels.openPriceOrderLevel.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor
                : levels.openPriceOrderLevel.invalidColor

            if (
                [TTradePositionType.tp_Sell, TTradePositionType.tp_SellLimit, TTradePositionType.tp_SellStop].includes(
                    this.PosType
                )
            ) {
                color = levels.openPriceOrderLevel.isValid
                    ? levels.openPriceOrderLevel.chartWindow.ChartOptions.ColorScheme.SellMarkerColor
                    : levels.openPriceOrderLevel.invalidColor
            }

            mainChart.PaintOrdersRightMarker(this.tpos.OpenPrice, color)
        } else if (
            this.visible &&
            levels.openPriceOrderLevel &&
            (levels.openPriceOrderLevel.IsCaptured() ||
                levels.openPriceOrderLevel.isSelected ||
                levels.openPriceOrderLevel.isMouseInside())
        ) {
            let color = levels.openPriceOrderLevel.isValid
                ? levels.openPriceOrderLevel.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor
                : levels.openPriceOrderLevel.invalidColor

            if (
                [TTradePositionType.tp_Sell, TTradePositionType.tp_SellLimit, TTradePositionType.tp_SellStop].includes(
                    this.PosType
                )
            ) {
                color = levels.openPriceOrderLevel.isValid
                    ? levels.openPriceOrderLevel.chartWindow.ChartOptions.ColorScheme.SellMarkerColor
                    : levels.openPriceOrderLevel.invalidColor
            }

            mainChart.PaintOrdersRightMarker(
                (levels.openPriceOrderLevel as PendingLevel).currentOpenPrice || this.tpos.OpenPrice,
                color
            )
        }

        // paint partialClose right marker
        if (this.visible && levels.partialCloseOrderLevel) {
            if (
                levels.partialCloseOrderLevel.IsCaptured() ||
                levels.partialCloseOrderLevel.isSelected ||
                levels.partialCloseOrderLevel.isMouseInside()
            ) {
                const color = mainChart.ChartOptions.ColorScheme.PartialCloseColor

                mainChart.PaintOrdersRightMarker((levels.partialCloseOrderLevel as PartialCloseLevel).tempPrice, color)
            }
        }
    }

    public hideClosedOrderInfoOutsideView(): void {
        for (const levels of this.orderLevels) {
            if (levels[1].sellBuyConnectorLine) {
                levels[1].sellBuyConnectorLine?.hide()
            }
        }
    }

    public showClosedOrderInfo(): void {
        for (const levels of this.orderLevels) {
            if (levels[1].sellBuyConnectorLine) {
                levels[1].sellBuyConnectorLine?.show()
            }
        }
    }

    public getProfitToPartialClose(points = this.tpos.pendingPartialClosePoints): number {
        const pointCost = this.symbol?.symbolInfo
            .getSymbolCalculationStrategy()
            .getPointCostForOneStandardLot(this.PosType, this.tpos.pendingPartialCloseMarketPrice)

        if (!pointCost) {
            throw new StrangeError('Point cost is not defined')
        }

        return points * pointCost * this.tpos.pendingPartialCloseLotValue
    }

    public getPointsByPrice(price: number): number {
        return Math.round((price - this.tpos.OpenPrice) * this.fPwr10) * this.getProfitDirectionMultiplier()
    }

    public onPartialClosePriceChange(partialClosePrice: number): void {
        const pointCost = this.symbol?.symbolInfo
            .getSymbolCalculationStrategy()
            .getPointCostForOneStandardLot(this.PosType, this.tpos.OpenPrice)

        if (!pointCost) {
            throw new StrangeError('Point cost is not defined')
        }
        this.tpos.pendingPartialClosePrice = partialClosePrice
        this.tpos.pendingPartialClosePoints =
            (this.tpos.pendingPartialClosePrice - this.tpos.OpenPrice) *
            this.fPwr10 *
            this.getProfitDirectionMultiplier()
    }
}
