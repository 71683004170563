import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { IChartInterfaceProcRec } from '../CommonProcRecInterfaces/IChartInterfaceProcRec'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import { TChartInfo } from '../CommonExternalInterface'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'

export class ChartInterfaceProcRecImplementation extends BasicProcRecImplementation implements IChartInterfaceProcRec {
    public GetImplementation(): IChartInterfaceProcRec {
        return {
            ChartToScrX: this.ChartToScrX.bind(this),
            ChartToScrY: this.ChartToScrY.bind(this),
            GetChartInfo: this.GetChartInfo.bind(this),
            UpdateCharts: this.UpdateCharts.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_ChartInterface_${super.generateDName()}`
    }

    private chartGetter: () => IChart

    private getChart(): IChart {
        if (!this.chartGetter) {
            throw new API_InvalidInitialization('ChartGetter is not initialized.')
        }
        const chart = this.chartGetter()
        if (!chart) {
            throw new API_InvalidInitialization('Chart is not initialized.')
        }
        return chart
    }

    private get bars() {
        if (!this.getChart().Bars.IsSeeked) {
            throw new DataNotDownloadedYetError('Bars are not seeked yet.')
        }
        return this.getChart().Bars
    }

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds, chartGetter: () => IChart) {
        super(procRecsEveryoneNeeds)
        this.chartGetter = chartGetter
    }

    public UpdateCharts(): void {
        GlobalChartsController.Instance.RefreshCharts(false, false)
    }

    //TODO:EX function TDllIndicator.ChartToScrX(index: integer): integer;
    public ChartToScrX(index: number): number {
        index = this.bars.FitIndex(index)
        //same as above, i am guessing this.fBars.BarsCount is the equivalent of fBars.count
        index = this.bars.totalBarsCount - 1 - index
        //ex fChartWindow.GetXFromDate(fBars[index].DateTime)
        return this.getChart().GetXFromDate(this.bars.GetDateByGlobalIndex(index, false))
    }

    public ChartToScrY(price: number): number {
        return this.getChart().GetY(price)
    }

    public GetChartInfo(): TChartInfo {
        return this.getChart().GetChartInfo()
    }
}
