import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'

import { ColorPicker } from '@fto/ui'

import { LineStylesType } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'

import TriggerElement from '../../../TriggerElement'
import { useTranslation } from 'react-i18next'

type Props = {
    colorValue: string
    tools: any[]
    setLineStyles: Dispatch<SetStateAction<LineStylesType>>
}
const ColorBlock: FC<Props> = ({ tools, setLineStyles, colorValue }) => {
    const { t } = useTranslation()

    const [singleTool] = tools

    const isMultiColoring = useMemo(() => {
        const hasCalculatedDifferentValues = tools.some((tool) => {
            const toolLineStyles = tool.getLineStyleParams()

            return toolLineStyles.color.hasDifferentValues
        })

        const allToolsColors = new Set(
            tools.map((tool) => {
                const toolLineStyles = tool.getLineStyleParams()

                return toolLineStyles.color.value
            })
        )

        return hasCalculatedDifferentValues || (tools.length > 1 && Array.from(allToolsColors).length > 1)
    }, [tools, colorValue])

    const color = useMemo(() => {
        if (tools.length > 1 && !isMultiColoring) {
            return singleTool.getLineStyleParams().color.value
        }

        return colorValue
    }, [tools, colorValue, isMultiColoring])

    const handleLineColorChange = useCallback(
        (color: string) => {
            tools.forEach((tool) => {
                const toolLineStyles = tool.getLineStyleParams()

                tool.setLineStylesParams({
                    color,
                    style: toolLineStyles.style.value,
                    width: toolLineStyles.width.value,
                    byKey: 'color'
                })
            })
            setLineStyles((prevState) => ({
                ...prevState,
                color: {
                    ...prevState.color,
                    value: color
                }
            }))
        },
        [tools, setLineStyles]
    )
    return (
        <ColorPicker
            triggerElement={
                <TriggerElement
                    tooltipLabel={t('graphToolPanel.tooltips.lineColor')}
                    color={color}
                    type='lineColor'
                    isMultiColoring={isMultiColoring}
                />
            }
            withOpacity={false}
            color={color}
            onColorChange={handleLineColorChange}
        />
    )
}

export default ColorBlock
