import { ExternalModuleDescriptor } from '../common/ExternalModuleDescriptor'

export class IndicatorDescriptor extends ExternalModuleDescriptor {
    private _isCustom: boolean
    public get isCustom(): boolean {
        return this._isCustom
    }

    constructor(fileName: string, shortName: string, description = '', isCustom = false) {
        super(fileName, shortName, description)
        this._isCustom = isCustom
    }

    public IsEqual(ind: IndicatorDescriptor): boolean {
        return this.shortName === ind.shortName && this._isCustom === ind.isCustom && this.FileName === ind.FileName
    }

    public clone(): IndicatorDescriptor {
        return new IndicatorDescriptor(this.FileName, this.shortName, this.description, this.isCustom)
    }
}
