import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TBaseDownloadWorkerThread } from './BaseDownloadWorkerThread'

export class TDownloadMapThread extends TBaseDownloadWorkerThread {
    private fWorker: Worker

    constructor(url: string) {
        super(url)

        //TODO: handle error when we cannot create worker or load script
        this.fWorker = new Worker('/workers/MapDownloadWorkerMSGPACK_Timeout.js')

        this.fWorker.addEventListener('message', (event) => {
            if (event.data.type === 'downloadedData') {
                // Assuming event.data contains the map data
                this.EmitCompleteCallback(event.data.downloadedData)
            } else if (event.data.type === 'error') {
                this.EmitExceptionCallback(event.data.error)
            }
        })
    }

    protected get TheWorker(): Worker {
        return this.fWorker
    }
}
