import { forwardRef, useMemo, ElementType } from 'react'
import * as React from 'react'

import cx from 'classnames'

import './Box.scss'

/**
 * Return class names according to general spacing classes
 * by following shape {attribute}-{side}-{size}
 * where {attribute} - first letter of attribute (m)
 * where {side} - (t|r|b|l)
 * where {size} - (4|8|12|16|...)
 *
 * @example m-t-4 | m-b-12 | m-l-8
 *
 */
const getMeasurementClass = (attribute: string, side: string, value: string) =>
    value ? `${attribute.charAt(0)}-${side}-${value}` : ''

const mapToClasses = (attribute: string) => (measurements: string[]) => [
    getMeasurementClass(attribute, 't', measurements[0]),
    getMeasurementClass(attribute, 'r', measurements[1]),
    getMeasurementClass(attribute, 'b', measurements[2]),
    getMeasurementClass(attribute, 'l', measurements[3])
]

const mapOverrides = (top?: string, right?: string, bottom?: string, left?: string) => (measurements: string[]) => {
    if (top) {
        measurements[0] = top
    }
    if (right) {
        measurements[1] = right
    }
    if (bottom) {
        measurements[2] = bottom
    }
    if (left) {
        measurements[3] = left
    }
    return measurements
}

const measurementStringToArray = (measurementString = '') => {
    const tokenized = measurementString.split(' ')
    switch (tokenized.length) {
        case 1:
            return [tokenized[0], tokenized[0], tokenized[0], tokenized[0]]
        case 2:
            return [tokenized[0], tokenized[1], tokenized[0], tokenized[1]]
        case 3:
            return [tokenized[0], tokenized[1], tokenized[2], tokenized[1]]
        case 4:
            return tokenized
        default:
            return []
    }
}

type OwnBoxProps = {
    component?: any

    m?: string | 'none'
    mx?: string
    my?: string
    mt?: string
    mr?: string
    mb?: string
    ml?: string

    p?: string | 'none'
    px?: string
    py?: string
    pt?: string
    pr?: string
    pb?: string
    pl?: string
}

export type BoxProps = Omit<React.ComponentPropsWithoutRef<'div'>, keyof OwnBoxProps> & OwnBoxProps

export const Box = forwardRef<HTMLDivElement, BoxProps>(
    (
        {
            children,
            component: Component = 'div' as ElementType,
            m: margin = 'none',
            mx: marginX,
            my: marginY,
            mt: marginTop,
            mr: marginRight,
            mb: marginBottom,
            ml: marginLeft,

            p: padding = 'none',
            px: paddingX,
            py: paddingY,
            pt: paddingTop,
            pr: paddingRight,
            pb: paddingBottom,
            pl: paddingLeft,

            className,
            ...props
        },
        ref
    ) => {
        const marginClasses = useMemo(
            () =>
                margin || marginRight || marginLeft || marginTop || marginBottom
                    ? mapToClasses('margin')(
                          mapOverrides(
                              marginY || marginTop,
                              marginX || marginRight,
                              marginY || marginBottom,
                              marginX || marginLeft
                          )(measurementStringToArray(margin))
                      )
                    : [],
            [margin, marginBottom, marginLeft, marginRight, marginTop, marginX, marginY]
        )

        const paddingClasses = useMemo(
            () =>
                padding || paddingRight || paddingLeft || paddingTop || paddingBottom
                    ? mapToClasses('padding')(
                          mapOverrides(
                              paddingY || paddingTop,
                              paddingX || paddingRight,
                              paddingY || paddingBottom,
                              paddingX || paddingLeft
                          )(measurementStringToArray(padding))
                      )
                    : [],
            [padding, paddingBottom, paddingLeft, paddingRight, paddingTop, paddingX, paddingY]
        )

        return (
            <Component {...props} ref={ref} className={cx(marginClasses, paddingClasses, className)}>
                {children}
            </Component>
        )
    }
)

Box.displayName = 'Box'

export default Box
