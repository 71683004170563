import { FC, useCallback, useEffect } from 'react'

import { OrderDataType } from '@fto/lib/store/ordersStore/types'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'

import TerminalMenuContent from '../components/TerminalMenuContent'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        activeTab: string
        order?: OrderDataType
    }
}

const TerminalContextMenu: FC<Props> = ({ anchorX, anchorY, additionalProps }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.terminal)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.terminal} anchorX={anchorX} anchorY={anchorY}>
            <TerminalMenuContent
                order={additionalProps.order}
                activeTab={additionalProps.activeTab}
                removeMenu={removeMenu}
            />
        </ContextMenu>
    )
}

export default TerminalContextMenu
