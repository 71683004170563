import { TChart } from '../../charting/chart_classes/BasicChart'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { DelphiMathCompatibility } from '../../delphi_compatibility/DelphiMathCompatibility'
import { TPaintToolType } from '../paint_tools/PaintToolsAuxiliaryClasses'
import { TOscWinSplitter } from '../OscWinsListUnit'
import { TIndexPos } from '@fto/lib/drawing_interface/GraphicObjects/TIndexPos'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartWindow } from '../chart_windows/ChartWindow'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { IVisibleIndexBuffer } from '@fto/lib/extension_modules/indicators/api/ITVisibleIndexBuffer'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { TDrawStyle, TPoint, TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'

enum TValign {
    al_Top,
    al_Middle,
    al_Bottom
}

export class TOscChart extends TChart {
    private Splitter: TOscWinSplitter
    private MainChart: TChart

    private isOwnerIndicatorVisible = true

    constructor(
        canvas: HTMLCanvasElement,
        parentChartWindow: TChartWindow,
        splitter: TOscWinSplitter,
        mainChart: TChart,
        oscChartNumber: number
    ) {
        super(parentChartWindow)
        this.fMargins = new TRect(0, 0, 0, 0)
        this.Splitter = splitter
        this.MainChart = mainChart

        this.Set_HTML_Canvas(canvas)
        this.SetName(`TOscChart #${oscChartNumber} of ${parentChartWindow.DName}`)
    }

    public Paint(): void {
        if (!this.isDataLoaded || !this.isChartOptionsInitialized || !this.is_HTML_Canvas_initialized()) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Painting,
                `OscWin ${this.DName} - Paint - Data is not loaded or chart options are not initialized or canvas is not ready`
            )
            return
        }
        try {
            this.PaintCounter++
            this.CalcDimensions()
            this.InitPaintContextCache()

            this.ClearBox()

            this._areDimensionsCalculated = true
            if (this.ChartOptions.ShowGrid) {
                this.PaintGrid()
            }
            if (this.ChartOptions.ShowPeriodSeparators) {
                this.PaintPeriodSeparators()
            }

            if (this._areDimensionsCalculated) {
                // paint tools' backgrounds

                /*this.PaintPeriodMarks();*/
                if (this.isOwnerIndicatorVisible) {
                    this.PaintLevels(this.fIndicators[0])
                }
                //this.PaintPosMarker();
                this.PaintIndicators()
                this.paintMarkerOnIndicator()
                this.PaintVLines()
                this.PaintTools.Paint()

                // z-order, above all
                this.PaintIndicatorControls()

                // paint border and text on it
                this.PaintBorder()

                this.PaintRooler()
            }

            this.Splitter.Paint()
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
        } finally {
            this.FreePaintContextCache()
        }
    }

    public paintMarkerOnIndicator(): void {
        if (!TChart.SelIndValue) {
            return
        }

        if (
            TChart.SelIndValue.sender === this &&
            TChart.SelIndValue.runtimeIndicator !== null &&
            TChart.SelIndValue.runtimeIndicator.IsVisible()
        ) {
            TChart.SelIndValue.drawMarker(this.CanvasContext, this)
        }
    }

    protected PaintBorder(): void {
        super.PaintBorder()

        if (this.fIndicators.Count === 0) {
            return
        }

        if (this._areDimensionsCalculated && this.isOwnerIndicatorVisible) {
            // paint min and max values text
            this.PaintRightText(this.fMinValue, TValign.al_Bottom)
            this.PaintRightText(this.fMaxValue, TValign.al_Top)

            // paint levels values
            const indicator: TRuntimeIndicator = this.fIndicators[0]
            for (let i = 0; i < indicator.levels.Count; i++) {
                if (indicator.levels[i].isActive) {
                    this.PaintRightText(indicator.levels[i].value, TValign.al_Middle)
                }
            }
        }
        this.PaintIndicatorMarks()
        this.PaintHLinesMarkers()
    }

    private PaintIndicator(indicator: TRuntimeIndicator) {
        if (!this._areDimensionsCalculated) {
            return
        }
        if (indicator.OnPaintProc) {
            indicator.OnPaintProc(this.PaintContextCache.canvasContext)
        }
    }

    protected paintIndicators(): void {
        this.PaintIndicator(this.fIndicators[0])

        for (let i = 1; i < this.fIndicators.count; i++) {
            // paint additional indicators
            this.CalcDimensionsByIndicator(this.fIndicators[i])
            this.PaintIndicator(this.fIndicators[i])
        }

        // restore calculations if needed
        if (this.fIndicators.count > 1) {
            this.CalcDimensionsByIndicator(this.fIndicators[0])
        }
    }

    private PaintRightText(value: number, align: TValign): void {
        if (!this.is_HTML_Canvas_initialized) return
        const context = this.CanvasContext

        const style = new TMkFontStyle(
            'Roboto Flex',
            0,
            GlobalOptions.Options.PriceAxisFontSize * window.devicePixelRatio,
            '#3c8f2b'
        )

        this.PaintContextCache.GdiCanvas.setFont(style.name, style.style, style.size)
        this.PaintContextCache.GdiCanvas.setPenColor(style.color, ColorHelperFunctions.GetOpacity(style.color))

        const x = this.PaintContextCache.PaintRect.Right
        let y = Math.max(this.GetY(value), 1)

        switch (align) {
            case TValign.al_Middle: {
                y += this.PaintContextCache.GdiCanvas.TextHeight('0') / 2
                break
            }
            case TValign.al_Bottom: {
                y -= this.PaintContextCache.GdiCanvas.TextHeight('0') - 4
                break
            }
            case TValign.al_Top: {
                y += this.PaintContextCache.GdiCanvas.TextHeight('0') + 4
                break
            }
            default: {
                throw new StrangeError('Unexpected align value')
            }
        }
        value = DelphiMathCompatibility.RoundTo(value, -this.ScaleDecimals())

        const theText = this.FormatNumberWithSuffix(value)
        context.font = `${GlobalOptions.Options.PriceAxisFontSize}px Roboto Flex`

        context.font = GlobalOptions.Options.VERTICAL_GRID_FONT

        context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.strokeStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.fillText(theText, x + 5, y)
    }

    public CalcDimensions(): void {
        super.CalcDimensions()

        this.fMargins.Right = this.MainChart.getMargins().Right
        this._areDimensionsCalculated = false

        if (this.fIndicators.Count === 0) {
            return
        }

        this.CalcDimensionsByIndicator(this.fIndicators[0])
    }

    private CalcDimensionsByIndicator(indicator: TRuntimeIndicator): void {
        let paintRect

        if (this.IsPaintContextCacheInitialized) {
            paintRect = this.PaintContextCache.PaintRect
        } else {
            paintRect = this.GetPaintRect()
        }

        this._areDimensionsCalculated = false

        if (indicator.VisibleBuffers.length === 0) {
            return
        }

        if (indicator.MinValue === indicator.MaxValue) {
            // not fixed range
            let min_v = 0
            let max_v = 0
            this.fMinValue = Number.MAX_VALUE / 10
            this.fMaxValue = -Number.MAX_VALUE / 10
            for (let i = 0; i < indicator.VisibleBuffers.length; i++) {
                const buff: IVisibleIndexBuffer = indicator.VisibleBuffers[i]
                if (buff && buff.buffer && buff.IsVisible()) {
                    min_v = buff.buffer.GetMin(
                        Math.max(this.position, buff.PaintFrom),
                        this.GetLastVisibleBarIndex(),
                        buff.EmptyValue
                    )
                    max_v = buff.buffer.GetMax(
                        Math.max(this.position, buff.PaintFrom),
                        this.GetLastVisibleBarIndex(),
                        buff.EmptyValue
                    )

                    if (min_v === buff.EmptyValue || max_v === buff.EmptyValue) {
                        continue
                    }

                    if (buff.style.DrawingStyle === TDrawStyle.ds_Histogram) {
                        max_v = Math.max(0, max_v)
                        min_v = Math.min(0, min_v)
                    }

                    this.fMinValue = Math.min(min_v, this.fMinValue)
                    this.fMaxValue = Math.max(max_v, this.fMaxValue)
                } else {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation('!buff || !buff.buffer')
                }
            }

            if (this.fMinValue < this.fMaxValue) {
                this.fMinValue -= Math.abs(this.fMaxValue - this.fMinValue) / 10
                this.fMaxValue += Math.abs(this.fMaxValue - this.fMinValue) / 10
            }
        } else {
            // fixed range
            this.fMinValue = indicator.MinValue
            this.fMaxValue = indicator.MaxValue
        }

        if (this.fMinValue < this.fMaxValue) {
            this.fVScale = (paintRect.Bottom - paintRect.Top - 3) / (this.fMaxValue - this.fMinValue)
            this._areDimensionsCalculated = true
        } else {
            this.fVScale = 1
        }
    }

    public ScaleDecimals(): number {
        return this.fIndicators.Count > 0 ? this.fIndicators[0].decimals : 4
    }

    public GetY(value: number): number {
        try {
            const paintRect = this.IsPaintContextCacheInitialized
                ? this.PaintContextCache.PaintRect
                : this.GetPaintRect()
            return paintRect.Bottom - 2 - Math.round((value - this.fMinValue) * this.fVScale)
        } catch (error) {
            // If an error occurs, log it and return 0 as a safe default
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Error in GetY:', error)
            return 0
        }
    }

    public GetPriceFromY(y: number): number {
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()
        return (-y + paintRect.Bottom - 2) / this.fVScale + this.fMinValue
    }

    private PaintVLines(): void {
        this.MainChart.CalcDimensions()

        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect

        try {
            for (let i = 0; i < this.MainChart.PaintTools.Count; i++) {
                const tool = this.MainChart.PaintTools[i]

                if (!tool.IsVisible()) continue

                if (tool.ToolType === TPaintToolType.tt_VLine) {
                    if (!tool.IsVisibleOnCurrTimeframe()) {
                        continue
                    }

                    tool.RecountScreenCoords()
                    if (!tool.isVisibleInChartFrame) {
                        continue
                    }

                    const x: number = tool.points[0].x

                    context.strokeStyle = tool.LineStyle.color
                    context.lineWidth = tool.LineStyle.width

                    context.beginPath()
                    context.moveTo(x, paintRect.Top)
                    context.lineTo(x, paintRect.Bottom)
                    context.stroke()
                }
            }
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Error during painting vertical lines in TOscChart ${error}`
            )
        }
    }

    public GetIndicatorUnderMouse(): [IVisibleIndexBuffer | null, number | null, TRuntimeIndicator | null] {
        let buffer: IVisibleIndexBuffer | null = null
        let value: number | null = null
        let result: TRuntimeIndicator | null = null

        if (!this._areDimensionsCalculated) {
            return [buffer, value, result]
        }

        const p: { x: number; y: number } = this.MouseToLocal()
        const index: number = this.GetMouseIndex()

        for (let i = 0; i < this.fIndicators.count; i++) {
            if (i > 0) {
                this.CalcDimensionsByIndicator(this.fIndicators.GetItem(i))
                if (!this._areDimensionsCalculated) {
                    continue
                }
            }

            for (let j = 0; j < this.fIndicators.GetItem(i).VisibleBuffers.length; j++) {
                buffer = this.fIndicators.GetItem(i).VisibleBuffers[j]
                if (buffer?.CheckIndex(index) === TIndexPos.ip_Valid) {
                    value = buffer.GetValue(index)
                    if (value !== buffer.EmptyValue && this.MouseInPriceRange(new TPoint(p.x, p.y), value)) {
                        result = this.fIndicators.GetItem(i)
                        if (buffer.IsVisible()) {
                            return [buffer, value, result]
                        }
                    }
                }
            }
        }

        // Recount dimensions
        if (this.fIndicators.count > 1) {
            this.CalcDimensions()
        }

        return [buffer, value, result]
    }

    public onIndicatorHide(): void {
        this.isOwnerIndicatorVisible = false
    }

    public onIndicatorShow(): void {
        this.isOwnerIndicatorVisible = true
    }
}
