import { ProjectTableHeadItem } from '@root/pages/Dashboard/components/ProjectsTable/ProjectsTable'

const projectTableHead: ProjectTableHeadItem[] = [
    {
        label: 'Project name',
        value: 'projectName'
    },
    {
        label: 'Period',
        value: 'period'
    },
    {
        label: 'Initial balance',
        value: 'balance'
    },
    {
        label: 'Pairs',
        value: 'pairs'
    },
    {
        label: 'Profit and loss',
        value: 'profitAndLoss'
    }
]

export default projectTableHead
