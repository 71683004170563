import { ProjectType } from '@root/types/projects'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    anyProjectActionFulfilled,
    anyProjectActionPending,
    anyProjectActionRejected,
    CREATE_PROJECT_ACTION,
    DELETE_PROJECT_ACTION,
    GET_PROJECTS_ACTION
} from '@root/store/projects/project.actions'
import { Pagable } from '@root/types/store.types'
import { initQueryParams, QueryParams, QueryParamType } from '@root/utils/query-params.util'

export type ProjectState = {
    projects: Pagable<ProjectType> | null
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
    query: QueryParams
}

const projectsInitState: ProjectState = {
    projects: null,
    loading: 'idle',
    error: null,
    query: initQueryParams
}

export const projectSlice = createSlice({
    name: 'projects',
    initialState: projectsInitState,
    reducers: {
        SET_QUERY_PARAM: (state, payload: PayloadAction<QueryParamType>) => {
            state.query = {
                ...state.query,
                ...payload.payload
            }
            state.loading = 'idle'
        },
        SET_QUERY_PARAMS: (state, payload: PayloadAction<QueryParams>) => {
            state.query = {
                ...state.query,
                ...payload.payload
            }
            state.loading = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_PROJECTS_ACTION.fulfilled, (state, payload) => {
                {
                    state.projects = payload.payload
                    state.loading = 'succeeded'
                    state.error = null
                }
            })
            .addCase(CREATE_PROJECT_ACTION.fulfilled, (state, payload) => {
                {
                    state.error = null
                }
            })
            .addCase(DELETE_PROJECT_ACTION.fulfilled, (state, payload) => {
                {
                    if (!state.projects) return state
                    state.error = null
                }
            })
            .addMatcher(anyProjectActionRejected, (state, payload) => {
                {
                    const error = payload.error
                    state.loading = 'failed'
                    state.error = error.message || null
                }
            })
            .addMatcher(anyProjectActionPending, (state) => {
                {
                    state.loading = 'pending'
                }
            })
            .addMatcher(anyProjectActionFulfilled, (state) => {
                {
                    state.error = null
                }
            })
    }
})
export const { SET_QUERY_PARAM, SET_QUERY_PARAMS } = projectSlice.actions
export default projectSlice.reducer
