import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { TPtAndrewsPitchfork } from '@fto/lib/charting/paint_tools/SpecificTools/ptAndrewsPitchfork'
import { TPtEllipse } from '@fto/lib/charting/paint_tools/SpecificTools/ptEllipse'
import { TPtExtPriceLabel } from '@fto/lib/charting/paint_tools/SpecificTools/ptExtPriceLabel'
import { TPtFiboArc } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboArc'
import { TPtFiboChannel } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboChannel'
import { TPtFiboExtension } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboExtension'
import { TPtFiboFan } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboFan'
import { TPtFiboRetracement } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboRetracement'
import { TPtFiboTimeZones } from '@fto/lib/charting/paint_tools/SpecificTools/ptFiboTimeZones'
import { TPtHLine } from '@fto/lib/charting/paint_tools/SpecificTools/ptHLine'
import { TPtMeasure } from '@fto/lib/charting/paint_tools/SpecificTools/ptMeasure'
import { TPtPolyline } from '@fto/lib/charting/paint_tools/SpecificTools/ptPolyline'
import { TPtLeftPriceLabel, TPtRightPriceLabel } from '@fto/lib/charting/paint_tools/SpecificTools/ptPriceLabel'
import { TPtRay } from '@fto/lib/charting/paint_tools/SpecificTools/ptRay'
import { TPtRectangle } from '@fto/lib/charting/paint_tools/SpecificTools/ptRectangle'
import { TPtRegressionChannel } from '@fto/lib/charting/paint_tools/SpecificTools/ptRegressionChannel'
import { TPtRiskReward, TRiskToolType } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { TPtSign } from '@fto/lib/charting/paint_tools/SpecificTools/ptSign'
import { TPtText } from '@fto/lib/charting/paint_tools/SpecificTools/ptText'
import { TPtTrendLine } from '@fto/lib/charting/paint_tools/SpecificTools/ptTrendLine'
import { TPtTriangle } from '@fto/lib/charting/paint_tools/SpecificTools/ptTriangle'
import { TPtVLine } from '@fto/lib/charting/paint_tools/SpecificTools/ptVLine'
import {
    TPtWaveSymbolsComplexCorrection,
    TPtWaveSymbolsImpulse,
    TPtWaveSymbolsIndefiniteStart,
    TPtWaveSymbolsSimpleCorrection
} from '@fto/lib/charting/paint_tools/SpecificTools/ptWaveSymbols'
import { PAINT_TOOLS_LOCALIZATION_KEY } from '@fto/chart_components/constants/paintToolsSignNames'
import { TPtBrush } from '@fto/lib/charting/paint_tools/SpecificTools/ptBrush'
import { TPtGannBox } from '@fto/lib/charting/paint_tools/SpecificTools/ptGannBox'

const PaintToolsRegister = (chartInstance: TChartWindow, toolName: string) => {
    const toolNameToRiskRewardType: {
        [key: string]: TRiskToolType
    } = {
        [PaintToolNames.ptRiskRewardBuy]: TRiskToolType.rt_Buy,
        [PaintToolNames.ptRiskRewardSell]: TRiskToolType.rt_Sell
    }

    const riskRewardType = toolNameToRiskRewardType[toolName]
    if (riskRewardType !== undefined) {
        toolName = PaintToolNames.ptRiskReward
    }

    let signSymbol = 0

    let signName = PAINT_TOOLS_LOCALIZATION_KEY[toolName]

    if (PaintToolManager.GetPaintToolClass(toolName) === null) {
        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (toolName) {
            case PaintToolNames.ptBrush: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptBrush, TPtBrush)
                break
            }
            case PaintToolNames.ptTrendLine: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptTrendLine, TPtTrendLine)
                break
            }
            case PaintToolNames.ptTriangle: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptTriangle, TPtTriangle)
                break
            }
            case PaintToolNames.ptRectangle: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptRectangle, TPtRectangle)
                break
            }
            case PaintToolNames.ptGannBox: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptGannBox, TPtGannBox)
                break
            }
            case PaintToolNames.ptEllipse: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptEllipse, TPtEllipse)
                break
            }
            case PaintToolNames.ptRay: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptRay, TPtRay)
                break
            }
            case PaintToolNames.ptFiboFan: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboFan, TPtFiboFan)
                break
            }
            case PaintToolNames.ptVLine: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptVLine, TPtVLine)
                break
            }
            case PaintToolNames.ptHLine: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptHLine, TPtHLine)
                break
            }
            case PaintToolNames.ptPolyline: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptPolyline, TPtPolyline)
                break
            }
            case PaintToolNames.ptFiboArc: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboArc, TPtFiboArc)
                break
            }
            case PaintToolNames.ptAndrewsPitchfork: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptAndrewsPitchfork, TPtAndrewsPitchfork)
                break
            }
            case PaintToolNames.ptFiboRetracement: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboRetracement, TPtFiboRetracement)
                break
            }
            case PaintToolNames.ptFiboTimeZones: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboTimeZones, TPtFiboTimeZones)
                break
            }
            case PaintToolNames.ptFiboExtension: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboExtension, TPtFiboExtension)
                break
            }
            case PaintToolNames.ptFiboChannel: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboChannel, TPtFiboChannel)
                break
            }
            case PaintToolNames.ptRegressionChannel: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptRegressionChannel, TPtRegressionChannel)
                break
            }
            case PaintToolNames.ptExtPriceLabel: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptExtPriceLabel, TPtExtPriceLabel)
                break
            }
            case PaintToolNames.ptPriceLabelRight: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptPriceLabelRight, TPtRightPriceLabel)
                break
            }
            case PaintToolNames.ptPriceLabelLeft: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptPriceLabelLeft, TPtLeftPriceLabel)
                break
            }
            case PaintToolNames.ptMeasure: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptMeasure, TPtMeasure)
                break
            }
            case PaintToolNames.ptText: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptText, TPtText)
                break
            }
            case PaintToolNames.ptRiskReward: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptRiskReward, TPtRiskReward)
                break
            }
            case PaintToolNames.ptSign: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                break
            }
            case PaintToolNames.ptArrowUp: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                toolName = PaintToolNames.ptSign
                signSymbol = 241
                signName = 'arrowUp'
                break
            }
            case PaintToolNames.ptArrowDown: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                signSymbol = 242
                toolName = PaintToolNames.ptSign
                signName = 'arrowDown'
                break
            }
            case PaintToolNames.ptThumbUp: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                signSymbol = 67
                toolName = PaintToolNames.ptSign
                signName = 'thumbUp'
                break
            }
            case PaintToolNames.ptThumbDown: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                signSymbol = 68
                toolName = PaintToolNames.ptSign
                signName = 'thumbDown'
                break
            }
            case PaintToolNames.ptCheck: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                signSymbol = 252
                toolName = PaintToolNames.ptSign
                signName = 'check'
                break
            }
            case PaintToolNames.ptStop: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
                signSymbol = 251
                toolName = PaintToolNames.ptSign
                signName = 'stop'
                break
            }
            case PaintToolNames.ptElliottWaveSymbolsImpulse: {
                PaintToolManager.RegisterPaintTool(PaintToolNames.ptElliottWaveSymbolsImpulse, TPtWaveSymbolsImpulse)
                break
            }
            case PaintToolNames.ptElliottWaveSymbolsSimpleCorrection: {
                PaintToolManager.RegisterPaintTool(
                    PaintToolNames.ptElliottWaveSymbolsSimpleCorrection,
                    TPtWaveSymbolsSimpleCorrection
                )
                break
            }
            case PaintToolNames.ptElliottWaveSymbolsComplexCorrection: {
                PaintToolManager.RegisterPaintTool(
                    PaintToolNames.ptElliottWaveSymbolsComplexCorrection,
                    TPtWaveSymbolsComplexCorrection
                )
                break
            }
            case PaintToolNames.ptElliottWaveSymbolsIndefiniteStart: {
                PaintToolManager.RegisterPaintTool(
                    PaintToolNames.ptElliottWaveSymbolsIndefiniteStart,
                    TPtWaveSymbolsIndefiniteStart
                )
                break
            }

            default: {
                break
            }
        }
    }

    chartInstance.CreatePaintTool(toolName, riskRewardType, signName, signSymbol)
}

export default PaintToolsRegister
