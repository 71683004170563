import {
    REASONS,
    PERIODS
} from '@root/pages/UserProfile/components/SuspendSubscriptionModal/components/ReasonDefinition/constants/index'

const mainBlockKey = 'profile.modals.suspendSubscriptionModal.mainBlock'
const priceToHighKey = 'profile.modals.suspendSubscriptionModal.priceToHigh'
const noNeedKey = 'profile.modals.suspendSubscriptionModal.noNeed'
const notSatisfiedKey = 'profile.modals.suspendSubscriptionModal.notSatisfied'
const switchKey = 'profile.modals.suspendSubscriptionModal.switch'
const otherKey = 'profile.modals.suspendSubscriptionModal.other'

export const TEXT_LOCALES = {
    controls: {
        cancel: `${mainBlockKey}.controls.cancel`,
        continue: `${mainBlockKey}.controls.continue`,
        contactSupport: `${mainBlockKey}.controls.contactSupport`,
        stepsCta: {
            cancel: `${mainBlockKey}.controls.stepsCta.cancel`,
            continue: `${mainBlockKey}.controls.stepsCta.continue`
        }
    },
    mainBlock: {
        title: `${mainBlockKey}.title`,
        description: `${mainBlockKey}.description`,
        options: {
            [REASONS.priceToHigh]: `${mainBlockKey}.options.priceToHigh`,
            [REASONS.noNeed]: `${mainBlockKey}.options.noNeed`,
            [REASONS.notSatisfied]: `${mainBlockKey}.options.notSatisfied`,
            [REASONS.switch]: `${mainBlockKey}.options.switch`,
            [REASONS.other]: `${mainBlockKey}.options.other`
        }
    },
    [REASONS.priceToHigh]: {
        title: `${priceToHighKey}.title`,
        pricePlaceholder: `${priceToHighKey}.pricePlaceholder`,
        periods: {
            [PERIODS.month]: `${priceToHighKey}.periods.month`,
            [PERIODS.year]: `${priceToHighKey}.periods.year`,
            [PERIODS.lifetime]: `${priceToHighKey}.periods.lifetime`
        }
    },
    [REASONS.noNeed]: {
        title: `${noNeedKey}.title`,
        textAreaPlaceholder: `${noNeedKey}.textAreaPlaceholder`
    },
    [REASONS.notSatisfied]: {
        title: `${notSatisfiedKey}.title`,
        options: {
            toBaggy: {
                title: `${notSatisfiedKey}.options.toBaggy.title`,
                textAreaPlaceholder: `${notSatisfiedKey}.options.toBaggy.textAreaPlaceholder`
            },
            missingFeatures: {
                title: `${notSatisfiedKey}.options.missingFeatures.title`,
                textAreaPlaceholder: `${notSatisfiedKey}.options.missingFeatures.textAreaPlaceholder`
            },
            tooComplicated: {
                title: `${notSatisfiedKey}.options.tooComplicated.title`,
                textAreaPlaceholder: `${notSatisfiedKey}.options.tooComplicated.textAreaPlaceholder`
            },
            other: {
                title: `${notSatisfiedKey}.options.other.title`,
                textAreaPlaceholder: `${notSatisfiedKey}.options.other.textAreaPlaceholder`
            }
        }
    },
    [REASONS.switch]: {
        title: `${switchKey}.title`,
        placeholder: `${switchKey}.placeholder`
    },
    [REASONS.other]: {
        title: `${otherKey}.title`,
        textAreaPlaceholder: `${otherKey}.textAreaPlaceholder`
    }
}
