import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TMyObjectList } from '@fto/lib/ft_types/common/Common'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { TLevelData } from './TLevelData'

export class TLevelsList extends TMyObjectList<TLevelData> {
    clone(): TLevelsList {
        const clonedList = new TLevelsList()
        for (let i = 0; i < this.Count; i++) {
            clonedList.Add(this[i].clone())
        }
        return clonedList
    }

    Assign(list: TLevelsList): void {
        this.Clear()
        for (let i = 0; i < list.Count; i++) {
            const levelData = list[i]
            this.Add(
                TLevelData.Create_TLevelData_Style(levelData.value, levelData.text, levelData.style, levelData.isActive)
            )
        }
    }

    ImportFromStr(s: string): void {
        this.Clear()

        let startIndex = 0
        let s1: string
        while (startIndex < s.length) {
            s1 = StrsConv.GetQuotedStr(s.slice(Math.max(0, startIndex)), '{', '}')
            if (s1 === '') {
                break
            } else {
                try {
                    this.Add(new TLevelData(s1))
                } catch (error) {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                }

                startIndex += s.slice(Math.max(0, startIndex)).indexOf(`{${s1}}`) + s1.length + 2
            }
        }
    }

    ExportToStr(precision: number): string {
        let result = ''
        for (let i = 0; i < this.Count; i++) {
            result += StrsConv.QuoteStr(this[i].GetLevelStr(precision), '{', '}')
        }
        return result
    }

    public Item(index: number): TLevelData {
        return this[index]
    }

    SortByValue(): void {
        this.sort((a, b) => a.value - b.value)
    }
}
