import { useEffect, useRef, useState } from 'react'

import { getLocalStorage, setLocalStorage, getSessionStorage, setSessionStorage } from '@root/utils/localStorage'

export enum StorageType {
    LocalStorage = 'LocalStorage',
    SessionStorage = 'SessionStorage'
}

const storageTypesMap = {
    [StorageType.LocalStorage]: {
        getStorage: getLocalStorage,
        setStorage: setLocalStorage,
        storage: localStorage
    },
    [StorageType.SessionStorage]: {
        getStorage: getSessionStorage,
        setStorage: setSessionStorage,
        storage: sessionStorage
    }
}

export const usePersistedState = <T>(
    name: string,
    defaultValue: T,
    storageType = StorageType.LocalStorage
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const { getStorage, setStorage, storage } = storageTypesMap[storageType]
    const nameRef = useRef(name)
    const [value, setValue] = useState<T>(getStorage(name) || defaultValue)

    useEffect(() => {
        setStorage(nameRef.current, value)
    }, [value])

    useEffect(() => {
        const lastName = nameRef.current
        if (name !== lastName) {
            setStorage(nameRef.current, value)
            nameRef.current = name
            storage.removeItem(lastName)
        }
    }, [name])

    return [value, setValue]
}
