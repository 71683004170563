import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'

export class DateIndex {
    DateTime: TDateTime
    index: number

    constructor(DateTime: TDateTime, index: number) {
        this.DateTime = DateTime
        this.index = index
    }
}
