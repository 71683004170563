export const statusMessages = {
    code_required: 'auth.errors.code.required',
    code_expired: 'auth.errors.code.expired',
    invalid_email: 'auth.errors.email.shouldBeValid',
    invalid_credentials: 'auth.errors.invalidCredentials',
    user_exist: 'auth.errors.email.alreadyExist',
    invalid_code: 'auth.errors.code.shouldBeValid',
    unauthorized: 'auth.errors.unauthorized',
    email_doesnt_allowed: 'auth.errors.email_doesnt_allowed',
    unknown: 'auth.errors.somethingWentWrong'
}
export type StatusMessages = keyof typeof statusMessages
