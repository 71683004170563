import IStrategyProcRec from '@fto/lib/extension_modules/startegies/StrategyProcRecInterfaces/IStrategyProcRec'

export abstract class StrategyImplementation {
    protected api!: IStrategyProcRec

    OnAttach(api: IStrategyProcRec) {
        this.api = api
    }

    abstract Init(): void
    abstract OnTick(): void

    public Reset(): void {
        // Do nothing
    }

    public Done(): void {
        // Do nothing
    }
}
