import { News } from '@fto/lib/News/News'
import { TNewsItem } from '@fto/lib/News/Types'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'

export class NewsGroup {
    private readonly fNews: News[]
    public currency: string

    constructor(currency: string) {
        this.fNews = []
        this.currency = currency
    }

    public addNews(news: TNewsItem) {
        this.fNews.push(
            new News(
                news.Time,
                news.Symbol,
                news.Title,
                news.Country,
                news.Priority,
                news.Actual,
                news.Consensus,
                news.Previews,
                news.IsPlanned
            )
        )
    }

    sortNews() {
        this.fNews.sort((a, b) => a.Time - b.Time)
    }

    public getNews(): News[] {
        return this.fNews
    }

    public getNewsByDateRange(startDate: TDateTime, endDate: TDateTime): News[] {
        const startDateInMilliseconds = DateUtils.toUnixTimeSeconds(startDate)
        const endDateInMilliseconds = DateUtils.toUnixTimeSeconds(endDate)

        const sortedNews = this.fNews // Ensure news is sorted by Time

        // Binary search helper functions
        const binarySearch = (arr: News[], target: number, compare: (a: number, b: number) => boolean): number => {
            let low = 0,
                high = arr.length - 1
            while (low <= high) {
                const mid = Math.floor((low + high) / 2)
                if (compare(arr[mid].Time, target)) {
                    low = mid + 1
                } else {
                    high = mid - 1
                }
            }
            return low
        }

        // Find the start index
        const startIndex = binarySearch(sortedNews, startDateInMilliseconds, (a, b) => a < b)

        // Find the end index
        const endIndex = binarySearch(sortedNews, endDateInMilliseconds, (a, b) => a <= b)

        // Slice the array to get the news within the date range
        return sortedNews.slice(startIndex, endIndex)
    }
}
