import { FC, useMemo } from 'react'
import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'
import { FilerPeriodType, FilterOptionType } from '@root/pages/ChartPage/components/Terminal/types'

import styles from './index.module.scss'

type Props = {
    setSelectedPeriod: (period: any) => void
    onClose: () => void
    selectedPeriod: FilerPeriodType
    options: FilterOptionType
}

const RangesRenderer: FC<Props> = ({ setSelectedPeriod, selectedPeriod, onClose, options }) => {
    return (
        <Flex direction='column' gap={4} className={styles.RangesRenderer}>
            {options.map((option) => {
                const isSelected = selectedPeriod === option.value

                return (
                    <Flex
                        key={option.value}
                        alignItems='center'
                        className={cx(styles.option, { [styles.selectedOption]: isSelected })}
                        gap={8}
                        onClick={() => {
                            setSelectedPeriod(option.value)
                            onClose()
                        }}
                    >
                        {isSelected && <Icon name='check' size={12} color='var(--color-primary-500)' />}
                        <Typography type='interface-medium' color={isSelected ? 'primary-500' : 'gray-1000'}>
                            {option.label}
                        </Typography>
                    </Flex>
                )
            })}
        </Flex>
    )
}

export default RangesRenderer
