import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Flex, Typography, HoverElement } from '@fto/ui'
import RibbonDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/RibbonDropdownMenu'

import RibbonDropdown from '../../RibbonDropdown'
import { RibbonOptionType } from '../../types'

import styles from '../../../index.module.scss'

const currentVersion = import.meta.env.VITE_FTO_VERSION ?? '0.0.0'

type Props = {
    openKeyboardShortcutsTab: () => void
}

const HelpDropdown: FC<Props> = ({ openKeyboardShortcutsTab }) => {
    const { t } = useTranslation()

    const helpOptions = useMemo(
        () =>
            [
                {
                    value: 'featureBase',
                    name: t('ribbon.help.contactSupport'),
                    icon: 'contact-support',
                    action: () => {
                        if (window.Tawk_API) {
                            window.Tawk_API.start()
                            window.Tawk_API.maximize()
                        }
                    }
                },
                {
                    value: 'keyboard shortcuts',
                    name: t('ribbon.help.keyboardShortcuts'),
                    icon: 'keyboard',
                    action: openKeyboardShortcutsTab
                }
            ] as RibbonOptionType[],
        [openKeyboardShortcutsTab]
    )

    const versionInfo = (
        <Flex gap={8} className={styles.info}>
            <Typography type='subtext-regular' color='gray-600'>
                {t('ribbon.help.currentVersion')}
            </Typography>
            <Typography type='subtext-regular'>{currentVersion}</Typography>
        </Flex>
    )

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.help')}
            renderer={(close) => (
                <RibbonDropdownMenu
                    additionalElements={versionInfo}
                    close={close}
                    options={helpOptions}
                    name={t('ribbon.help.help')}
                />
            )}
        >
            <HoverElement size={32}>
                <Icon size={18} name='help' />
            </HoverElement>
        </RibbonDropdown>
    )
}
export default HelpDropdown
