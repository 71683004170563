import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { IChart } from '../../chart_classes/IChart'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class TPtTriangle extends TBasicPaintTool {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptTriangle)
        this.ShortName = 'Triangle'
        this.fToolType = TPaintToolType.tt_Polygon
        this.fMaxPoints = 3
        this.fClosedPolygon = true
        this.CursorStyle = CustomCursorPointers.crCursorTriangle
        this.icon = 60
        // this.fEditDialog = 'TriangleFrm'; //TODO: Replace with actual form or dialog reference
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptTriangle)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptTriangle)
        }

        if (!styles) throw new StrangeError('Default styles for Triangle are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.brush = IGPSolidBrush.fromSerialized(styles.fillingStyle)
        this.fShouldFillInside = styles.fShouldFillInside
    }

    clone(): TPtTriangle {
        const cloneObj = new TPtTriangle(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    public fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }

    ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    isActive: this.fShouldFillInside,
                    opacity: this.brush.getOpacity()
                },
                label: 'background',
                withOpacity: true,
                type: 'fillColor',
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptTriangle, toolName: 'triangle' })
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { resetToolSettings, getKeyValueData } = GraphToolStore
        const formattedToolSettings = getKeyValueData()
        const { description, lineStyle, fillInsideColor } = formattedToolSettings

        this.description = description
        this.fLineStyle = lineStyle
        this.fShouldFillInside = fillInsideColor.isActive

        this.brush.setColor(fillInsideColor.color)
        this.brush.setOpacity(fillInsideColor.opacity)

        this.saveToManager()
        resetToolSettings()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptTriangle, {
            toolName: PaintToolNames.ptTriangle,
            lineStyle: this.fLineStyle.getSerialized(),
            fillingStyle: this.brush.getSerialized(),
            fShouldFillInside: this.fShouldFillInside
        })
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    Paint() {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y),
                new TPoint(this.fPoints[2].x, this.fPoints[2].y)
            ]
            this.PaintHoverLine(points)
        }
        super.Paint()
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptTriangle, TPtTriangle)
