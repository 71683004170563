import { FC } from 'react'

import { ColorPicker, Flex } from '@fto/ui'
import TriggerElement from '../../../TriggerElement'
import useFillColor from '../../hooks/useFillColor'
import { useTranslation } from 'react-i18next'

type Props = {
    tools: any[]
}

const RiskRewardFillColor: FC<Props> = ({ tools }) => {
    const { t } = useTranslation()

    const profitParams = useFillColor({
        tools,
        setParamsFunctionKey: 'setProfitFillColorParams',
        getParamsFunctionKey: 'getProfitFillColorParams'
    })

    const lossParams = useFillColor({
        tools,
        setParamsFunctionKey: 'setLossFillColorParams',
        getParamsFunctionKey: 'getLossFillColorParams'
    })

    return (
        <Flex alignItems='center' gap={4}>
            <ColorPicker
                triggerElement={
                    <TriggerElement
                        color={profitParams.color}
                        tooltipLabel={t('graphToolPanel.tooltips.profitBgColor')}
                        type='fillColor'
                        isMultiColoring={profitParams.isMultiColoring}
                    />
                }
                color={profitParams.color}
                onColorChange={profitParams.handleColorChange}
                withOpacity
                onOpacityChange={profitParams.handleOpacityChange}
                opacity={profitParams.opacity}
            />
            <ColorPicker
                triggerElement={
                    <TriggerElement
                        color={lossParams.color}
                        tooltipLabel={t('graphToolPanel.tooltips.lossBgColor')}
                        type='fillColor'
                        isMultiColoring={lossParams.isMultiColoring}
                    />
                }
                color={lossParams.color}
                onColorChange={lossParams.handleColorChange}
                withOpacity
                onOpacityChange={lossParams.handleOpacityChange}
                opacity={lossParams.opacity}
            />
        </Flex>
    )
}

export default RiskRewardFillColor
