import React, {
    createRef,
    Dispatch,
    FC,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import cx from 'classnames'

import { IconButton } from '@fto/icons'
import { Flex, Tooltip, TriggerOverlay, Typography } from '@fto/ui'
import { ColumnsListType, TabType } from '../../../../types/index'

import CellsSelector from './components/CellsSelector'

import styles from './index.module.scss'
import commonStyles from '../../commonStyles.module.scss'

import { COLUMNS_LIST_WITH_SIZES } from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'
import { UserTerminalSettings } from '@root/utils/localStorage'
import TerminalSortingCell from '@root/pages/ChartPage/components/Terminal/components/Table/components/THead/components/TerminalSortingCell'
import { useTranslation } from 'react-i18next'

export type TableHeadRefType = { ref: RefObject<HTMLDivElement>; id: string }

type Props = {
    cellsList: ColumnsListType
    setCellsList: Dispatch<SetStateAction<ColumnsListType>>
    activeTab: TabType
    tableColumns: UserTerminalSettings
    setTableColumnsSettings: Dispatch<SetStateAction<UserTerminalSettings>>
}

const Table: FC<Props> = ({ cellsList, setCellsList, tableColumns, activeTab, setTableColumnsSettings }) => {
    const { t } = useTranslation()
    const [isResizing, setIsResizing] = useState(false)
    const [separator, setSeparator] = useState<RefObject<HTMLDivElement> | null>(null)

    const tableHeadRefs = useMemo(() => {
        const refs: TableHeadRefType[] = []
        cellsList.forEach((item) => {
            refs.push({ ref: createRef(), id: item.value })
        })
        return refs
    }, [cellsList])

    const setElemRef = useCallback(
        (id: string) => tableHeadRefs.find((item) => item.id === id)?.ref || null,
        [tableHeadRefs]
    )

    const startResizing = useCallback(
        (e: React.MouseEvent, id: string) => {
            document.body.style.userSelect = 'none'
            setIsResizing(true)
            setSeparator(tableHeadRefs.find((item) => item.id === id)?.ref || null)
        },
        [tableHeadRefs]
    )

    const resize = useCallback(
        (e: MouseEvent) => {
            const parentElem = separator?.current?.parentElement as HTMLElement
            const id = separator?.current?.id
            if (!parentElem || !id) return

            const parentElemWidth = parentElem.getBoundingClientRect().width
            const elementWidth = parentElemWidth + e.movementX
            const { minWidth, maxWidth } = COLUMNS_LIST_WITH_SIZES[activeTab].find((item) => item.value === id) || {
                minWidth: 50,
                maxWidth: 150
            }

            const newWidth = elementWidth <= minWidth ? minWidth : elementWidth >= maxWidth ? maxWidth : elementWidth

            parentElem.style.setProperty('width', `${newWidth}px`)
            const tabTableSettings: UserTerminalSettings = {
                ...tableColumns,
                [activeTab]: {
                    ...tableColumns[activeTab],
                    settings: {
                        ...tableColumns[activeTab].settings,
                        [id]: {
                            width: `${newWidth}px`,
                            isHidden: false
                        }
                    }
                }
            }
            setTableColumnsSettings(tabTableSettings)
        },
        [separator, setTableColumnsSettings, activeTab]
    )

    const handleResizeEnd = useCallback(() => {
        setIsResizing(false)
        document.body.style.userSelect = ''
    }, [])

    const initTableColumnsWidth = useCallback(() => {
        tableHeadRefs.forEach((item) => {
            const parentElem = item.ref.current?.parentElement
            parentElem?.style.setProperty('width', tableColumns[activeTab].settings[item.id]?.width || null)
        })
    }, [tableColumns, tableHeadRefs])

    const handleSort = (cell: string) => {
        const cellState = tableColumns[activeTab]?.sorting?.direction
        const direction = cellState === 'asc' ? 'desc' : 'asc'

        setTableColumnsSettings((prev) => {
            return {
                ...prev,
                [activeTab]: {
                    ...prev[activeTab],
                    sorting: {
                        direction,
                        key: cell
                    }
                }
            }
        })
    }

    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', resize)
        }
        return () => window.removeEventListener('mousemove', resize)
    }, [isResizing])

    useEffect(() => {
        initTableColumnsWidth()
    }, [cellsList])

    useEffect(() => {
        initTableColumnsWidth()
        document.addEventListener('mouseup', handleResizeEnd)
        return () => window.removeEventListener('mouseup', handleResizeEnd)
    }, [])

    return (
        <Flex gap={4} alignItems='center' className={styles.tHead}>
            {cellsList.map((cell) => {
                const isOrderStatus = cell.value === 'orderStatus'
                return (
                    <Flex
                        onClick={() => handleSort(cell.value)}
                        justifyContent='space-between'
                        alignItems='center'
                        className={cx(
                            styles.th,
                            commonStyles[`cell-${cell.value}`],
                            tableColumns[activeTab]?.settings[cell.value]?.isHidden && 'hidden'
                        )}
                        key={cell.value}
                        gap={4}
                    >
                        <Typography
                            type='subtext-regular'
                            color={tableColumns[activeTab].sorting?.key === cell.value ? 'gray-1000' : 'gray-800'}
                            align='center'
                            block
                            className={commonStyles.cellLabel}
                            truncate
                            title={t(`terminal.tableCells.${cell.value}`)}
                        >
                            {isOrderStatus ? '' : t(`terminal.tableCells.${cell.value}`)}
                        </Typography>
                        {!cell.notSortable && (
                            <TerminalSortingCell
                                activeTab={activeTab}
                                tableColumns={tableColumns}
                                setTableColumns={setTableColumnsSettings}
                                cell={cell}
                            />
                        )}
                        <div
                            ref={setElemRef(cell.value)}
                            id={cell.value}
                            className={styles.resizeItemWrapper}
                            onMouseDown={(e) => {
                                startResizing(e, cell.value)
                            }}
                        >
                            <div className={styles.resizeItem} />
                        </div>
                    </Flex>
                )
            })}
            <Flex className={commonStyles.cellSettings} justifyContent='flex-end' alignItems='center'>
                <TriggerOverlay
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    optionsRenderer={
                        <CellsSelector
                            setTableColumns={setTableColumnsSettings}
                            tableColumns={tableColumns}
                            cellsList={cellsList}
                            setCellsList={setCellsList}
                            activeTab={activeTab}
                        />
                    }
                >
                    <Tooltip placement='left' color='dark' content={t('terminal.tooltips.columnSetup')}>
                        <IconButton name='customize' size={12} color='var(--color-gray-900)' />
                    </Tooltip>
                </TriggerOverlay>
            </Flex>
        </Flex>
    )
}

export default Table
