import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDataFormat } from '../../DataEnums'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import { TFMBarsArray } from '../../data_arrays/chunked_arrays/BarsArray/BarsArray'
import { TTaskStatus } from '../DownloadRelatedEnums'
import { BASE_URL } from '../URLConfig'
import { TBaseDownloadWorkerThread } from '../WorkerThreads/BaseDownloadWorkerThread'
import { TDownloadMapThread } from '../WorkerThreads/DownloadMapThread'
import { TBasicDownloadTask } from './BasicDownloadTask'
import { showErrorToast } from '@root/utils/toasts'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { t } from 'i18next'

export class TBarsMapDownloadTask extends TBasicDownloadTask {
    private fThread: TDownloadMapThread
    private fStartDate: number
    private fEndDate: number
    private fDataArray: TFMBarsArray

    constructor(dataArray: TFMBarsArray, aStartIndex: number, aEndIndex: number) {
        super(TDataFormat.df_MSGPPACK)
        this.fDataArray = dataArray
        this.fStartDate = aStartIndex
        this.fEndDate = aEndIndex
        this.fUrl = this.GetUrlForMap(dataArray.DataDescriptor)

        this.fThread = new TDownloadMapThread(this.fUrl)
        this.fThread.OnThreadFinished = (data?: any) => {
            this.CompleteTask(data)
        }
        this.fThread.OnThreadException = (error: string) => {
            this.OnException(error)
        }
    }

    public get DataArray(): TFMBarsArray {
        return this.fDataArray
    }

    private CompleteTask(data: any): void {
        //TODO: handle download erros here

        if (!data || data.length === 0) {
            // eslint-disable-next-line no-console
            throw new StrangeError('Error downloading MAP data, data is empty')
            this.status = TTaskStatus.ts_Completed_WithError
            return
        }

        const downloadStatus = TChunkMapStatus.cms_Loaded

        this.fDataArray.ImportServerDataIntoChunkMap(data, downloadStatus, this.fDataFormat)
        this.status = TTaskStatus.ts_Completed
    }

    public get Thread(): TBaseDownloadWorkerThread {
        return this.fThread
    }

    private GetUrlForMap(dataDescriptor: TDataDescriptor): string {
        let url = `${BASE_URL}data/api/metadata/bars?Timeframe=${dataDescriptor.timeframe}&Symbol=${dataDescriptor.symbolName}&Start=${this.fStartDate}&End=${this.fEndDate}&Broker=${dataDescriptor.broker}`
        if (this.fDataFormat === TDataFormat.df_MSGPPACK) {
            url += '&UseMessagePack=true'
        }

        return url
    }

    IsSameAs(task: TBarsMapDownloadTask): boolean {
        return (
            this.fDataArray === task.fDataArray &&
            this.fStartDate === task.fStartDate &&
            this.fEndDate === task.fEndDate
        )
    }
    OnException(error: string): void {
        if (error === 'Unsupported timeframe') {
            showErrorToast({
                title: t('customTimeframes.toasts.timeframeNotSupported.title'),
                message: t('customTimeframes.toasts.timeframeNotSupported.message')
            })
            GlobalChartsController.Instance.onCustomTimeframeChangeFailed(this.fDataArray)
        } else {
            throw new StrangeError(`Error downloading MAP data: ${error}`)
        }
    }
}
