import { FC, ReactNode, useState } from 'react'

import cx from 'classnames'

import { isContentOverflowingY } from '../hooks/useOverflowObserver'

import { useResizeObserver } from '../hooks/useResizeObserver'

import styles from '../index.module.scss'

export type ContentProps = {
    className?: string
    children?: ReactNode
    outbound?: boolean
    withBorderBottom?: boolean

    hideOverflowOverriding?: boolean
}

const Content: FC<ContentProps> = ({
    hideOverflowOverriding = false,
    withBorderBottom,
    outbound,
    children,
    className
}) => {
    const [isOverflowingY, setIsOverflowingY] = useState(false)

    const overflowRef = useResizeObserver((entries: ResizeObserverEntry[]) => {
        const node = entries[0]
        if (node) {
            setIsOverflowingY(isContentOverflowingY(node.target as HTMLElement))
        }
    })

    return (
        <div
            ref={overflowRef}
            className={cx(styles.content, className, {
                [styles.BorderBottom]: withBorderBottom,
                [styles.outbound]: outbound,
                [styles.overflowed]: isOverflowingY && !hideOverflowOverriding
            })}
        >
            {children}
        </div>
    )
}

Content.displayName = 'Modal.Content'

export default Content
