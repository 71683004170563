import { FC, useMemo, Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Flex } from '@fto/ui'

import TextArea from '../TextArea'
import Header from '../Header'
import Controls from '../Controls'

import { TEXT_LOCALES } from '../../constants/texts'
import { REASONS } from '../../constants'
import { ReasonDataType } from '../../types'
import { splitStringByLength } from '../../utils'
import { fireMixpanelEvent } from '@root/utils/api'

type Props = {
    step: typeof REASONS.noNeed | typeof REASONS.other
    comment: string
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
    setStep: (n: number) => void
}

const TellUsMoreBlock: FC<Props> = ({ step, comment, setSuspendData, setStep }) => {
    const { t } = useTranslation()

    const placeholder = useMemo(() => {
        return t(TEXT_LOCALES[step]?.textAreaPlaceholder)
    }, [step])

    const onChange = useCallback(
        (value: string) => {
            setSuspendData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [step]: {
                        comment: value
                    }
                }
            }))
        },
        [setSuspendData]
    )

    const onSubmit = useCallback(() => {
        const commentData = comment.length <= 255 ? { comment } : splitStringByLength(comment, 'comment')

        setStep(2)
        fireMixpanelEvent('subscription_suspend_detailed_feedback', {
            initial_feedback: step,
            ...commentData
        })
    }, [setStep, step, comment])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <Header reason={step} />
                    <TextArea value={comment} onChange={onChange} placeholder={placeholder} />
                </Flex>
            </Modal.Content>
            <Controls disabled={!comment} onSubmit={onSubmit} />
        </>
    )
}

export default TellUsMoreBlock
