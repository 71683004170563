export const fireGoogleTagManagerEvent = (eventKey: string, properties: any = {}) => {
    const win = window as any

    win.dataLayer.push({ event: eventKey, ...properties })
}

export const gtag = (...rest: any) => {
    const win = window as any

    win.dataLayer.push(rest)
}
