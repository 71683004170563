import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { IChart } from '../../chart_classes/IChart'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { PaintToolNames } from '../PaintToolNames'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class TPtPolyline extends TBasicPaintTool {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptPolyline)

        this.fToolType = TPaintToolType.tt_Polyline
        this.fMaxPoints = 1000
        this.fClosedPolygon = false
        this.fShouldFillInside = false
        this.CursorStyle = CustomCursorPointers.crCursorPolyline
        this.icon = 42

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptPolyline)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptPolyline)
        }

        if (!styles) throw new StrangeError('Default styles for Polyline are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
    }

    clone(): TPtPolyline {
        const cloneObj = new TPtPolyline(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptPolyline, toolName: 'polyline' })
    }

    Paint() {
        if (this.fHighlighted) {
            const points = this.fPoints.map((point, index) => {
                return new TPoint(point.x, point.y)
            })
            this.PaintHoverLine(points)
        }
        super.Paint()
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()

        this.description = formattedToolSettings.description
        this.fLineStyle = formattedToolSettings.lineStyle
        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptPolyline, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptPolyline
        })
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    public fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptPolyline, TPtPolyline)
