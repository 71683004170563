import { FC, MouseEvent, ReactNode, useMemo } from 'react'
import cx from 'classnames'

import styles from './index.module.scss'

type HoverElementProps = {
    children: ReactNode
    onClick?: (e: MouseEvent<HTMLDivElement>) => void
    isSelected?: boolean
    classNames?: {
        root?: string
        selected?: string
    }
    size?: number
    withSelectedState?: boolean
    disabled?: boolean
}

export const HoverElement: FC<HoverElementProps> = ({
    children,
    onClick,
    isSelected,
    classNames = {},
    size = 24,
    withSelectedState = true,
    disabled = false
}) => {
    const childrenJSX = useMemo(() => {
        if (!disabled) {
            return children
        }

        return (
            <div className={styles.DisableEvents} onClick={onClick}>
                {children}
            </div>
        )
    }, [children, disabled, onClick])
    return (
        <div
            className={cx(styles.HoverIconElement, classNames.root, {
                [classNames.selected || styles.SelectedOptionState]: isSelected && withSelectedState,
                [styles.Disabled]: disabled
            })}
            style={{ width: size, height: size }}
            {...(!disabled && { onClick })}
        >
            {childrenJSX}
        </div>
    )
}
