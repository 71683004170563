import { TLevelType } from '../../auxiliary_classes_charting/ChartingEnums'

export class TSelOrder {
    OrderHandle: number
    LevelType: TLevelType
    value: number

    constructor() {
        this.OrderHandle = -1 // Default value
        this.LevelType = TLevelType.lt_Price // Default value
        this.value = 0 // Default value
        this.clear()
    }

    init(handle: number, _type: TLevelType, aValue: number): void {
        this.OrderHandle = handle
        this.LevelType = _type
        this.value = aValue
    }

    empty(): boolean {
        return this.OrderHandle === -1
    }

    clear(): void {
        this.OrderHandle = -1
    }
}
