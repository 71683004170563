import { useEffect } from 'react'
import IndicatorsStore from '@fto/lib/store/indicatorsStore'

const useInitializeSettings = () => {
    useEffect(() => {
        IndicatorsStore.initializeFavoriteIndicators()
    }, [])
}

export default useInitializeSettings
