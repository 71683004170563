import { ChangeEvent, ComponentPropsWithoutRef, forwardRef, memo, useCallback, useId, useRef } from 'react'

import cx from 'classnames'

import { Flex, InputControl, InputControlProps } from '..'

import { FilenameTypes, Icon } from '@fto/icons'

import { mergeRefs } from '../../utils/mergeRefs'

import styles from './index.module.scss'

const noop = () => {}

type Props = {
    onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void
    classNames?: {
        root?: string
        content?: string
    }
}

type OtherNativeInputProps = Omit<ComponentPropsWithoutRef<'input'>, keyof Props>
export type InputRadioProps = InputControlProps & OtherNativeInputProps & Props

const ICON_NAME_MAP: {
    selected: (typeof FilenameTypes)[number]
    unselected: (typeof FilenameTypes)[number]
} = {
    selected: 'radio-button-radio-outline',
    unselected: 'radio-button-empty-outline'
}

export const InputRadio = memo(
    forwardRef<HTMLInputElement, InputRadioProps>(
        (
            {
                id,
                checked = false,
                onChange = noop,
                disabled,

                variant = 'column',
                label,
                helperText,
                success,
                error,
                required,
                block,
                size = 18,
                className,
                classNames,
                children,
                ...rest
            },
            ref
        ) => {
            const inputRef = useRef<HTMLInputElement>(null)
            const _id = useId()
            const localId = id || _id

            const iconName = checked && !error ? ICON_NAME_MAP['selected'] : ICON_NAME_MAP['unselected']
            const handleChange = useCallback(
                (e: ChangeEvent<HTMLInputElement>) => onChange(!checked, e),
                [checked, onChange]
            )

            return (
                <span>
                    <InputControl
                        className={className}
                        id={localId}
                        variant={variant}
                        success={success}
                        error={error}
                        required={required}
                        label={label}
                        block={block}
                        helperText={helperText}
                    >
                        <Flex direction='row' alignItems='flex-start' gap={8}>
                            <span className={cx(styles.iconBlock, classNames?.root)}>
                                <input
                                    {...rest}
                                    ref={mergeRefs(ref, inputRef)}
                                    id={localId}
                                    type='radio'
                                    checked={checked}
                                    onChange={handleChange}
                                    disabled={disabled}
                                    className={styles.input}
                                />
                                <Icon
                                    name={iconName}
                                    className={cx(styles.icon, {
                                        [styles.selected]: checked && !error,
                                        [styles.disabled]: disabled
                                    })}
                                    onClick={() => inputRef.current?.click()}
                                    size={size}
                                />
                            </span>

                            {children && <div className={cx(styles.textBlock, classNames?.content)}>{children}</div>}
                        </Flex>
                    </InputControl>
                </span>
            )
        }
    )
)

InputRadio.displayName = 'InputRadio'

export default InputRadio
