import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TMyObjectList } from '../../common/Common'
import CommonConstants from '../../common/CommonConstants'
import { TChunkMapStatus } from '../TChunkMapStatus'
import { TFMBarsArray } from './chunked_arrays/BarsArray/BarsArray'
import IFMBarsArray from './chunked_arrays/IFMBarsArray'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TDataArrayEvents } from '../data_downloading/DownloadRelatedEnums'
import IEventEmitter from '@fto/lib/common/IEventEmitter'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import { TBasicChunk } from '../chunks/BasicChunk'
import DataNotDownloadedYetError from '../data_errors/DataUnavailableError'
import { ISeekable } from '../ISeekable'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { INamed } from '@fto/lib/utils/INamed'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTickRecord } from '../DataClasses/TTickRecord'

export class TBarArrays extends TMyObjectList<IFMBarsArray> implements IEventEmitter, ISeekable, INamed {
    //TODO: think how to control that
    private activeTimeframes: IFMBarsArray[] = []
    private symbolName: string

    constructor(symbolName: string) {
        super()
        this.symbolName = symbolName
    }

    public get DName(): string {
        return `Bar array list ${this.symbolName}`
    }

    public getSymbolName(): string {
        return this.symbolName
    }

    public toString(): string {
        return this.DName
    }

    public get IsSeeked(): boolean {
        return this.activeTimeframes.every((barsArray) => barsArray.IsSeeked)
    }

    public get LastProcessedTickTime(): TDateTime | null {
        //max of all timeframes
        let result: TDateTime | null = null
        for (const timeframeBars of this.activeTimeframes) {
            if (timeframeBars.LastProcessedTickTime && (!result || timeframeBars.LastProcessedTickTime > result)) {
                result = timeframeBars.LastProcessedTickTime
            }
        }
        return result
    }

    public Events: TEventsFunctionality = new TEventsFunctionality('TBarArrays')

    private SortByTimeframe() {
        this.Sort((a, b) => a.DataDescriptor.timeframe - b.DataDescriptor.timeframe)
    }

    public IsTimeframeActive(timeframe: number): boolean {
        for (const activeBarsArray of this.activeTimeframes) {
            if (activeBarsArray.DataDescriptor.timeframe === timeframe) {
                return true
            }
        }
        return false
    }

    //TODO: come up with a way to deactivate timeframes that are not used anymore
    public EnsureTimeframeIsActive(timeframe: number): IFMBarsArray {
        //check if already active
        for (const activeBarsArray of this.activeTimeframes) {
            if (activeBarsArray.DataDescriptor.timeframe === timeframe) {
                return activeBarsArray
            }
        }

        //we know that it is inactive because otherwise we would return above
        const existingInactiveBarsArray = this.GetExistingBarsArrayByTimeframe(timeframe)

        let barsArrayToActivate
        if (existingInactiveBarsArray) {
            barsArrayToActivate = existingInactiveBarsArray
        } else {
            //so we do not have existing inactive bars array, let's create a new one
            barsArrayToActivate = this.createNewBarsArray(timeframe)
        }

        this.activeTimeframes.push(barsArrayToActivate)
        //make sure we seek either new or newly activated bars array
        GlobalSymbolList.SymbolList.SeekToLastTickInProject()

        return barsArrayToActivate
    }

    public GetOrCreateActiveBarArray(timeframe: number): IFMBarsArray {
        this.EnsureTimeframeIsActive(timeframe)

        const result = this.GetExistingBarsArrayByTimeframe(timeframe)
        if (!result) {
            throw new StrangeError(
                `GetOrCreateActiveBarArray - failed to create active bars array for timeframe ${timeframe}`
            )
        }
        return result
    }

    private createNewBarsArray(period: number): IFMBarsArray {
        const newBarsArray = new TFMBarsArray(period, this.symbolName, CommonConstants.DEFAULT_BROKER)
        newBarsArray.SetName(`Internal barsArray`)
        newBarsArray.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundPassForwardChunkLoadedEvent)
        newBarsArray.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundHandleMapDownloaded)
        this.Add(newBarsArray)
        this.SortByTimeframe()
        //let's activate it right away, there seems to be no sense to add inactive bars arrays (or maybe we can do it to preload maps??)
        //if this logic ever changes, update EnsureTimeframeIsActive method where the activation will be needed after adding or maybe we can create addInactiveBarsArray method
        newBarsArray.InitMapIfNecessary()
        this.SortActiveTimeframes()
        return newBarsArray
    }

    private SortActiveTimeframes() {
        this.activeTimeframes.sort((a, b) => a.DataDescriptor.timeframe - b.DataDescriptor.timeframe)
    }

    private boundPassForwardChunkLoadedEvent = this.passForwardChunkLoadedEvent.bind(this)
    private passForwardChunkLoadedEvent(chunk: TBasicChunk) {
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    private boundHandleMapDownloaded = this.handleMapDownloaded.bind(this)
    private handleMapDownloaded() {
        this.Events.EmitEvent(TDataArrayEvents.de_MapDownloaded, this)
    }

    public GetExistingBarsArrayByTimeframe(timeframe: number): IFMBarsArray | null {
        for (let i = 0; i < this.Count; i++) {
            const barsArray = this.GetItem(i)
            if (barsArray.DataDescriptor.timeframe === timeframe) {
                return barsArray
            }
        }
        return null
    }

    public AddSingleTick(tick: TTickRecord): void {
        for (const timeframeBars of this.activeTimeframes) {
            if (timeframeBars.IsSeeked) {
                timeframeBars.AddSingleTick(tick)
            }
        }
    }

    public Seek(lastProcessedTickTimeForThisSymbol: TDateTime): TDateTime | null {
        this.CheckActiveTimeframesAndWarnIfNone()
        let maxSeekedDate: TDateTime | null = null
        for (const timeframeBars of this.activeTimeframes) {
            if (timeframeBars.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
                const seekedDateForThisTF = timeframeBars.Seek(lastProcessedTickTimeForThisSymbol)
                if (seekedDateForThisTF && (!maxSeekedDate || seekedDateForThisTF > maxSeekedDate)) {
                    maxSeekedDate = seekedDateForThisTF
                }
            } else {
                timeframeBars.InitMapIfNecessary()
                throw new DataNotDownloadedYetError(
                    `BarArrays.SeekToLastDateInTesting - Map is not loaded yet for ${timeframeBars.DName}`
                )
            }
        }
        return maxSeekedDate
    }

    private CheckActiveTimeframesAndWarnIfNone() {
        //this can be fine if we just need ticks (for let's say cross pairs calculation, so let's comment)
        // if (this.activeTimeframes.length === 0) {
        //     StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
        //         `Seek called without any active timeframes for symbol ${this.symbolName}`
        //     )
        // }
    }

    public PreloadDataIfNecessary(startDate: number, endDate: number): void {
        this.CheckActiveTimeframesAndWarnIfNone()
        for (const timeframeBars of this.activeTimeframes) {
            timeframeBars.PreloadDataIfNecessary(startDate, endDate)
        }
    }

    public isVisibleDataReady(): boolean {
        this.CheckActiveTimeframesAndWarnIfNone()

        for (const timeframeBars of this.activeTimeframes) {
            if (
                !timeframeBars.IsDataReady() &&
                GlobalChartsController.Instance.IsTimeframeVisible(timeframeBars.DataDescriptor)
            ) {
                return false
            }
        }
        return true
    }

    public UpdateChunksInfo(projectLastTickTime: TDateTime): void {
        for (const timeframeBars of this.activeTimeframes) {
            timeframeBars.UpdateChunksInfo(projectLastTickTime)
        }
    }

    public onTimezoneOrDSTChanged(): void {
        for (const timeframeBars of this.activeTimeframes) {
            timeframeBars.onTimezoneOrDSTChanged()
        }
    }

    public GetActiveBarArrays(): IFMBarsArray[] {
        return this.activeTimeframes
    }

    public isDateInAvailableRange(date: number): boolean {
        //if at least one bars array has the date, we are good
        for (const barsArray of this.activeTimeframes) {
            if (barsArray.isDateInAvailableRange(date)) {
                return true
            }
        }
        return false
    }

    public ResetSeekStatus(): void {
        for (const barsArray of this.activeTimeframes) {
            barsArray.ResetSeekStatus()
        }
    }
}
