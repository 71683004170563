import { FC, useMemo } from 'react'

import { EmptyState, Flex, InputField, Typography } from '@fto/ui'
import { t } from 'i18next'
import cx from 'classnames'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TimeZoneDesc } from '@fto/lib/Timezones&DST/TimeZoneManager'

import styles from './index.module.scss'

type Props = {
    onClose: () => void
    searchValue: string
    setSearchValue: (value: string) => void
    value: number
    handleChange: (tz: TimeZoneDesc) => void
}

const List: FC<Props> = ({ onClose, searchValue, value, setSearchValue, handleChange }) => {
    const options = useMemo(() => GlobalTimezoneDSTController.Instance.getTimezones(), [])

    const filteredOptions = useMemo(() => {
        return options.filter(
            (item) =>
                item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.description.toLowerCase().includes(searchValue.toLowerCase())
        )
    }, [searchValue, options])

    const activeTimeZone = useMemo(
        () =>
            options.find((item) => {
                return item.serverValue === value
            }),
        [value, options]
    )

    return (
        <Flex direction='column' gap={4} className={styles.ContentBlock}>
            <InputField
                value={searchValue}
                withSearchSuffix
                reset
                placeholder={t('general.search')}
                onChange={setSearchValue}
                autoFocus
            />

            {filteredOptions.length > 0 ? (
                <Flex direction='column' gap={4} className={styles.Wrapper}>
                    {filteredOptions.map((tz) => {
                        const isActive = activeTimeZone?.value === tz.value

                        const onClick = () => {
                            handleChange(tz)
                            onClose()
                        }

                        return (
                            <Flex
                                key={tz.value}
                                className={cx(styles.Option, { [styles.Active]: isActive })}
                                alignItems='center'
                                block
                                onClick={onClick}
                            >
                                <Typography
                                    className={styles.Label}
                                    color={isActive ? 'white' : 'gray-1000'}
                                    title={tz.description}
                                    block
                                >
                                    {tz.label}
                                </Typography>
                            </Flex>
                        )
                    })}
                </Flex>
            ) : (
                <EmptyState
                    description={t('dashboard.modals.projects.advanced.timeZone.emptyState.description')}
                    title={t('dashboard.modals.projects.advanced.timeZone.emptyState.title')}
                    classNames={{ root: styles.EmptyState }}
                />
            )}
        </Flex>
    )
}

export default List
