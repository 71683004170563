import { TOO_MANY_REQUESTS_ERROR, USE_CROWDIN, USE_MIXPANEL_EVENTS } from '@root/constants/localStorageKeys'
import { createSlice } from '@reduxjs/toolkit'
import { setDefaultLang, setPseudoLangForCrowdin } from '@root/i18n'
import { getLocalStorage, setLocalStorage } from '@root/utils/localStorage'
import { ErrorOffsetDataType, extractAndConvertTime } from '@root/store/appSettings/utils'

export type AppSettingsState = {
    isMixpanelEnabled: boolean
    useCrowdin: boolean
    apiSettings: {
        hasError: boolean
        statusCode: number | null
        errorAppearanceTime: number | null
        offsetData: ErrorOffsetDataType
    }
}

type apiSettingsStorageType = AppSettingsState['apiSettings'] | null

const getApiSettingsFromStorage = () => {
    const initialData = {
        hasError: false,
        statusCode: null,
        errorAppearanceTime: null,
        offsetData: { value: 0, unit: 's', milliseconds: 0 }
    }

    const storedData: apiSettingsStorageType = getLocalStorage(TOO_MANY_REQUESTS_ERROR)

    if (storedData) {
        const { errorAppearanceTime, offsetData } = storedData

        return Date.now() - (errorAppearanceTime ?? 0) > offsetData.milliseconds ? initialData : storedData
    }

    return initialData
}

export const initState: AppSettingsState = {
    useCrowdin: localStorage.getItem(USE_CROWDIN) === 'true',
    apiSettings: getApiSettingsFromStorage(),
    isMixpanelEnabled: localStorage.getItem(USE_MIXPANEL_EVENTS) === 'true',
}

export const slice = createSlice({
    name: 'appSettings',
    initialState: initState,
    reducers: {
        SET_CROWDIN: (state, action) => {
            state.useCrowdin = action.payload
            localStorage.setItem(USE_CROWDIN, action.payload)

            // side effects
            if (action.payload) {
                setPseudoLangForCrowdin()
            } else {
                setDefaultLang().then(() => {
                    window.location.reload()
                })
            }
        },
        UPDATE_MIXPANEL_EVENTS_ACCESS: (state, action) => {
            state.isMixpanelEnabled = action.payload
            localStorage.setItem(USE_MIXPANEL_EVENTS, action.payload)
        },
        CATCH_TOO_MANY_REQUESTS_ERROR: (state, action) => {
            const offsetData = extractAndConvertTime(action.payload.responseMessage)

            const payloadData = {
                hasError: action.payload.hasError,
                statusCode: action.payload.statusCode,
                errorAppearanceTime: action.payload.errorAppearanceTime,
                offsetData
            }

            state.apiSettings = payloadData

            setLocalStorage(TOO_MANY_REQUESTS_ERROR, payloadData)
        }
    }
})

export const { SET_CROWDIN, CATCH_TOO_MANY_REQUESTS_ERROR, UPDATE_MIXPANEL_EVENTS_ACCESS } = slice.actions

export default slice.reducer
