import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Flex } from '@fto/ui'
import { fireMixpanelEvent } from '@root/utils/api'

import { NotSatisfiedReasonType, ReasonDataType } from '../../types'
import { splitStringByLength } from '../../utils'
import { REASONS } from '../../constants'
import { TEXT_LOCALES } from '../../constants/texts'

import OptionRow from '../OptionRow'
import TextArea from '../TextArea'
import Header from '../Header'
import Controls from '../Controls'

type Props = {
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
    comment: string
    reason: string
    setStep: (n: number) => void
}

const NotSatisfiedBlock: FC<Props> = ({ reason, comment, setSuspendData, setStep }) => {
    const { t } = useTranslation()

    const options = useMemo(() => {
        return Object.entries(TEXT_LOCALES[REASONS.notSatisfied].options).map(([key, value]) => ({ key, txt: value }))
    }, [])

    const onReasonChange = useCallback(
        (value: string) => {
            setSuspendData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [REASONS.notSatisfied]: {
                        reason: value as NotSatisfiedReasonType,
                        comment: ''
                    }
                }
            }))
        },
        [setSuspendData]
    )

    const onCommentChange = useCallback(
        (value: string) => {
            setSuspendData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [REASONS.notSatisfied]: {
                        ...prev.data[REASONS.notSatisfied],
                        comment: value
                    }
                }
            }))
        },
        [setSuspendData]
    )

    const onSubmit = useCallback(() => {
        const commentData = comment.length <= 255 ? { comment } : splitStringByLength(comment, 'comment')

        setStep(2)
        fireMixpanelEvent('subscription_suspend_detailed_feedback', {
            initial_feedback: REASONS.notSatisfied,
            reason,
            ...commentData
        })
    }, [setStep, reason, comment])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <Header reason={REASONS.notSatisfied} />
                    <Flex direction='column' gap={8}>
                        {options.map((option) => {
                            const isActive = reason === option.key

                            return (
                                <Flex direction='column' gap={8}>
                                    <OptionRow
                                        key={option.key}
                                        idKey={option.key}
                                        label={t(option.txt.title)}
                                        onClick={onReasonChange}
                                        isActive={isActive}
                                    />
                                    {isActive && (
                                        <TextArea
                                            value={comment}
                                            onChange={onCommentChange}
                                            placeholder={t(option.txt.textAreaPlaceholder)}
                                        />
                                    )}
                                </Flex>
                            )
                        })}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Controls onSubmit={onSubmit} disabled={!comment} />
        </>
    )
}

export default NotSatisfiedBlock
