import { FC, useCallback } from 'react'
import { TemplateFooter } from '../TemplateFooter'
import { addModal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

type TemplateManagerProps = {
    changeMainModalVisibleState: (isVisible: boolean) => void
    onSaveTemplate: (name: string, isRewrite: boolean) => void
    onSelectTemplate: (name: string) => void
    onDeleteTemplate: (name: string) => void
    onApplyDefault?: () => void
    templates: string[]
    selectedTemplate: string
    applyToAll?: boolean
    setApplyToAll?: (value: boolean) => void | undefined
}

export const TemplateManager: FC<TemplateManagerProps> = ({
    changeMainModalVisibleState,
    onSaveTemplate,
    onSelectTemplate,
    onDeleteTemplate,
    onApplyDefault,
    templates,
    selectedTemplate,
    applyToAll = true,
    setApplyToAll = undefined
}) => {
    const onSavedTemplate = useCallback(
        (name: string, isRewrite = false) => {
            removeModal(MODAL_NAMES.chart.saveTemplate)
            changeMainModalVisibleState(true)
            onSaveTemplate(name, isRewrite)
        },
        [templates, onSaveTemplate]
    )

    const onReplacedTemplate = useCallback(
        (name: string, isRewrite = false) => {
            removeModal(MODAL_NAMES.chart.replaceTemplate)
            changeMainModalVisibleState(true)
            onSaveTemplate(name, isRewrite)
        },
        [templates, onSaveTemplate]
    )

    const onReplaceTempalate = useCallback(
        (name: string) => {
            removeModal(MODAL_NAMES.chart.saveTemplate)
            addModal(MODAL_NAMES.chart.replaceTemplate, {
                name,
                onReplaced: onReplacedTemplate,
                onCanceled: onCanceledReplaceModal
            })
        },
        [templates, onSaveTemplate]
    )

    const onCanceledSaveModal = useCallback(() => {
        changeMainModalVisibleState(true)
        removeModal(MODAL_NAMES.chart.saveTemplate)
    }, [templates, changeMainModalVisibleState])

    const onCanceledReplaceModal = useCallback(
        (name: string) => {
            removeModal(MODAL_NAMES.chart.replaceTemplate)
            addModal(MODAL_NAMES.chart.saveTemplate, {
                templatesList: templates,
                name: name,
                onSaved: onSavedTemplate,
                onReplace: onReplaceTempalate,
                onCanceled: onCanceledSaveModal
            })
        },
        [templates, onSaveTemplate, onSavedTemplate, onReplaceTempalate, onCanceledSaveModal]
    )

    const onSavingTemplate = useCallback(() => {
        changeMainModalVisibleState(false)
        addModal(MODAL_NAMES.chart.saveTemplate, {
            templatesList: templates,
            onSaved: onSavedTemplate,
            onReplace: onReplaceTempalate,
            onCanceled: onCanceledSaveModal
        })
    }, [
        templates,
        changeMainModalVisibleState,
        onSaveTemplate,
        onSavedTemplate,
        onReplaceTempalate,
        onCanceledSaveModal
    ])

    const onDeletedTemplate = useCallback(
        (name: string) => {
            removeModal(MODAL_NAMES.chart.deleteTemplate)
            changeMainModalVisibleState(true)
            onDeleteTemplate(name)
        },
        [templates, changeMainModalVisibleState, onDeleteTemplate]
    )

    const onCanceledDeleteModal = useCallback(() => {
        removeModal(MODAL_NAMES.chart.deleteTemplate)
        changeMainModalVisibleState(true)
    }, [templates, changeMainModalVisibleState])

    const onDeletingTemplate = useCallback(
        (name: string) => {
            changeMainModalVisibleState(false)
            addModal(MODAL_NAMES.chart.deleteTemplate, {
                name,
                onDeleted: onDeletedTemplate,
                onCanceled: onCanceledDeleteModal
            })
        },
        [
            templates,
            onSaveTemplate,
            onSavedTemplate,
            onDeleteTemplate,
            changeMainModalVisibleState,
            onDeletedTemplate,
            onCanceledDeleteModal
        ]
    )

    return (
        <TemplateFooter
            onTemplateSelected={onSelectTemplate}
            onDeleteTemplate={onDeletingTemplate}
            onSavingTemplate={onSavingTemplate}
            onApplyDefault={onApplyDefault}
            templates={templates}
            selectedTemplate={selectedTemplate}
            applyToAll={applyToAll}
            setApplyToAll={setApplyToAll}
        />
    )
}
