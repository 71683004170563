import { TMyObjectList } from '@fto/lib/ft_types/common/Common'
import { TIndexBufferProgramSide } from './IndexBuffers'

export class TIndexBuffersList extends TMyObjectList<TIndexBufferProgramSide> {
    public ClearValueAtGlobalIndex(globalIndex: number) {
        for (let i = 0; i < this.Count; i++) {
            this[i].SetValue_reversedIndex(globalIndex, null)
        }
    }

    public ClearValues(): void {
        for (let i = 0; i < this.Count; i++) {
            this[i].Clear()
        }
    }

    public IsEmpty(globalIndex: number): boolean {
        //TODO: if at least one buffer has some values, returns true, is it a good way to do it? Maybe we can store "is calculated" somewhere else?
        for (let i = 0; i < this.Count; i++) {
            if (!this[i].IsEmpty(globalIndex)) {
                return false
            }
        }
        return true
    }
}
