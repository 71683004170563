import { IVisibleIndexBuffer } from './ITVisibleIndexBuffer'
import { IIndexBuffer } from './IIndexBuffer'

export class TVisibleBufferInfo {
    buffer: TIndexBuffer | null
    name: string
    PaintFrom: number

    constructor(visibleBufferInfo: IVisibleIndexBuffer) {
        this.buffer = visibleBufferInfo.buffer ? new TIndexBuffer(visibleBufferInfo.buffer) : null
        this.name = visibleBufferInfo.name
        this.PaintFrom = visibleBufferInfo.PaintFrom
    }
}

export class TIndexBuffer {
    private programSideBuffer: IIndexBuffer

    constructor(programSideBuffer: IIndexBuffer) {
        this.programSideBuffer = programSideBuffer
    }

    public getBuffer(): IIndexBuffer {
        return this.programSideBuffer
    }

    public setValue(index: number, price: number): void {
        this.programSideBuffer.SetValue_reversedIndex(index, price)
    }

    public getValue(index: number): number {
        return this.programSideBuffer.GetValue_reversedIndex(index)
    }
}
