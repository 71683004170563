import { TPaintBox } from '../../delphi_compatibility/TPaintBox'
import { TChartOptions } from '../ChartBasicClasses'
import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TRect } from '../../extension_modules/common/CommonExternalInterface'
import { TNoExactMatchBehavior } from '../../ft_types/data/chunks/ChunkEnums'
import { TGdiPlusCanvas } from '../../drawing_interface/GdiPlusCanvas'
import TPaintContextCache from '../auxiliary_classes_charting/PaintContextCache'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartWindow } from '../chart_windows/ChartWindow'

export abstract class TBasicChart extends TPaintBox {
    //it comes from ChartWindow
    protected _chartOptions: TChartOptions | undefined = undefined

    public get ChartOptions(): TChartOptions {
        if (!this._chartOptions) {
            throw new StrangeError('ChartOptions is not initialized in Chart')
        }
        return this._chartOptions
    }

    public get DName(): string {
        return `${super.DName} of ${this._chartWindow.DName}`
    }

    protected SetChartOptions(value: TChartOptions): void {
        this._chartOptions = value
    }

    public get isChartOptionsInitialized(): boolean {
        return this._chartOptions !== undefined
    }

    private _html_canvas: HTMLCanvasElement | undefined = undefined

    public get HTML_Canvas(): HTMLCanvasElement {
        if (!this._html_canvas) {
            throw new StrangeError('html_canvas is not initialized in Chart')
        }
        return this._html_canvas
    }

    public get CanvasContext(): CanvasRenderingContext2D {
        if (this.IsPaintContextCacheInitialized) {
            return this.PaintContextCache.canvasContext
        } else {
            const canvasContext = this.HTML_Canvas.getContext('2d')
            if (!canvasContext) {
                throw new StrangeError('Canvas context is null')
            }
            return canvasContext
        }
    }

    public Set_HTML_Canvas(value: HTMLCanvasElement): void {
        this._html_canvas = value
    }

    public is_HTML_Canvas_initialized(): boolean {
        return this._html_canvas !== undefined
    }

    protected _chartWindow: TChartWindow
    protected _isPaintContextCacheInitialized = false

    protected _paintContextCache: TPaintContextCache | null = null
    public get PaintContextCache(): TPaintContextCache {
        if (!this.IsPaintContextCacheInitialized || !this._paintContextCache) {
            throw new StrangeError('PaintContextCache is used before initialization')
        }
        return this._paintContextCache
    }

    public get IsPaintContextCacheInitialized(): boolean {
        if (this._isPaintContextCacheInitialized && this._paintContextCache) {
            return true
        }
        return false
    }

    protected constructor(parentChartWindow: TChartWindow) {
        super()

        this._chartWindow = parentChartWindow
    }

    public abstract get GdiCanvas(): TGdiPlusCanvas // Replace with actual type later

    public get ChartWindow(): TChartWindow {
        if (!this._chartWindow) {
            throw new StrangeError('ChartWindow is not initialized in Chart')
        }
        return this._chartWindow
    }

    public abstract GetPaintRect(): TRect

    public abstract ScaleDecimals(): number
    public abstract GetX(value: number): number
    public abstract GetY(value: number): number
    public abstract GetBarDateFromX(x: number): TDateTime
    public abstract GetPriceFromY(y: number): number
    public abstract GetXFromDate(DateTime: TDateTime, NegativeAllowed?: boolean): number
    public abstract GetIndexFromX(x: number, FitToRange?: boolean): number
    public abstract GetGlobalIndexByDate(
        dateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        approximationAllowed: boolean
    ): number
    public abstract GetDateByIndex(index: number): TDateTime
    public abstract GetRayCoords(x1: number, y1: number, x2: number, y2: number, price1: number, price2: number): void
    public abstract IsRayVisible(x1: number, y1: number, x2: number, y2: number): boolean
    public abstract IsLineVisible(x1: number, y1: number, x2: number, y2: number): boolean
    public abstract ChangeToolName(tool: any, NewName: string): void // Replace with actual type later
    public abstract invalidate(): void
}
