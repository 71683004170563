import { FC, ReactNode, useEffect, useMemo, useState } from 'react'

import { Flex } from '@fto/ui'

import ColorBlock from './components/ColorBlock'
import LineStyleBlock from './components/LineStyleBlock'
import { LineStylesType } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { initialLineStyles } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/constants'

type Props = {
    tools: any[]
    children?: ReactNode
}

const LineStyles: FC<Props> = ({ tools = [], children }) => {
    const [singleTool] = tools

    const [lineStyles, setLineStyles] = useState<LineStylesType>(initialLineStyles)

    const toolsWithLineStylesParams = useMemo(() => {
        return tools.filter((tool) => tool.hasLines)
    }, [tools])

    useEffect(() => {
        if (tools.length > 0) {
            setLineStyles(toolsWithLineStylesParams.length > 1 ? initialLineStyles : singleTool.getLineStyleParams())
        }
    }, [tools, toolsWithLineStylesParams])

    if (toolsWithLineStylesParams.length === 0) {
        return <>{children}</>
    }

    return (
        <Flex alignItems='center' gap={4}>
            <ColorBlock
                setLineStyles={setLineStyles}
                colorValue={lineStyles.color.value}
                tools={toolsWithLineStylesParams}
            />
            {children}
            <LineStyleBlock
                tools={toolsWithLineStylesParams}
                setLineStyles={setLineStyles}
                lineStyleValue={lineStyles.width.value}
                type='width'
                selectorType='lineWidth'
            />
            <LineStyleBlock
                tools={toolsWithLineStylesParams}
                setLineStyles={setLineStyles}
                lineStyleValue={lineStyles.style.value}
                type='style'
                selectorType='lineType'
            />
        </Flex>
    )
}

export default LineStyles
