import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataFormat } from '../../DataEnums'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import Ticks_converter from '../../data_converters/Ticks_converter'
import { ServerDayTickDataMSGPACK } from '../../data_downloading/ServerDataClasses'
import { TChunkStatus } from '../ChunkEnums'
import { DownloadController } from '@fto/lib/ft_types/data/data_downloading/DownloadController'
import { BASE_URL } from '@fto/lib/ft_types/data/data_downloading/URLConfig'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import MathUtils from '@fto/lib/utils/MathUtils'
import { TTickRecord } from '../../DataClasses/TTickRecord'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { TBaseTickChunk } from './BaseTickChunk'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'

export class TRealTickChunk extends TBaseTickChunk {
    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: TDateTime, aLastPossibleDate: TDateTime) {
        super(aDataDescriptor, aFirstDate, aLastPossibleDate)
        this.SetName(
            `RealTickChunk_${aDataDescriptor.symbolName}_${aFirstDate}-${MathUtils.roundTo(
                aLastPossibleDate,
                4
            )}_${DateUtils.DF(aFirstDate)}`
        )
    }

    public ImportChunkData(data: any, dataFormat: TDataFormat): TChunkStatus {
        const convertedData = this.GetConvertedData(data, dataFormat)

        this.CheckEmptyDataCase(convertedData)

        this._data = convertedData

        DebugUtils.logTopic(
            [ELoggingTopics.lt_ChunkLoading, ELoggingTopics.lt_ChunkLoadingCompleted],
            'Tick chunk loaded with',
            convertedData.length,
            'ticks. Chunk date:',
            this.FirstDate,
            DateUtils.DF(this.FirstDate),
            this.DataDescriptor
        )

        this.Status = TChunkStatus.cs_Loaded
        this.saferEmitChunkLoadedEvent()

        this.__debugCheckDataConsistency()

        return this.Status
    }

    private __debugCheckDataConsistency() {
        if (DebugUtils.DebugMode) {
            for (let i = 0; i < this.Count - 1; i++) {
                if (this._data[i].DateTime > this._data[i + 1].DateTime) {
                    throw new StrangeError(
                        'Data is not sorted in the tick chunk',
                        this.DName,
                        'at index',
                        i,
                        this._data[i].DateTime,
                        this._data[i + 1].DateTime
                    )
                }
            }
        }
    }

    private CheckEmptyDataCase(convertedData: TTickRecord[]): void {
        if (convertedData.length === 0) {
            // No data
            this._isNoDataOnServer = true
            DebugUtils.warnTopic(
                ELoggingTopics.lt_ChunkLoading,
                'No data were downloaded for the tick chunk',
                this.FirstDate,
                this.DataDescriptor
            )
            //it is quite possible that there is no data for the requested period, but we still need to mark the chunk as loaded
        }
    }

    private GetConvertedData(data: any, dataFormat: TDataFormat): TTickRecord[] {
        switch (dataFormat) {
            case TDataFormat.df_Json: {
                return Ticks_converter.ConvertFromJSONToTicks(data)
            }
            case TDataFormat.df_MSGPPACK: {
                return this.ConvertMSGPACKDataToTickRecords(data)
            }
            case TDataFormat.df_MockObjects: {
                return Ticks_converter.ConvertFromMocksToTicks(data)
            }
            case TDataFormat.df_Binary: {
                return this.ConvertBinaryDataToTickRecords(this.parseDailyTicks(data))
            }
            case TDataFormat.df_ActualRecords: {
                return data as TTickRecord[]
            }
            default: {
                throw new StrangeError('Unknown data format')
            }
        }
    }

    private ConvertMSGPACKDataToTickRecords(data: ServerDayTickDataMSGPACK[]): TTickRecord[] {
        const deserializedData = data as ServerDayTickDataMSGPACK[]

        if (deserializedData.length > 1) {
            // Data length mismatch
            throw new StrangeError('more than 1 day of data was downloaded for the tick chunk')
        }

        //TODO: revise this when backend can return definitive answer for chunks with no data
        if (data.length === 0) {
            return []
        }

        return Ticks_converter.ConvertServerTicksToTickRecordsMSGPACK(deserializedData[0])
    }

    protected StartLoadingData(): void {
        if (this.Status === TChunkStatus.cs_Empty) {
            if (this.IsEmptyOnServer()) {
                this.ImportChunkData([], this.LoadingDataFormat)
            } else {
                DownloadController.Instance.loadHistoryIfNecessary(this)
            }
        }
    }

    public GetChunkUrl(): string {
        const startDate = DateUtils.toUnixTimeSeconds(this.FirstDate)
        const endDate = DateUtils.toUnixTimeSeconds(this.LastPossibleDate)
        // Assuming this method returns a URL for the chunk
        //https://fto-staging.forextester.com/data/api/Metadata/bars/daily?Broker=Advanced&Symbol=EURUSD&Timeframe=60&Start=1&End=3
        let url = `${BASE_URL}data/api/HistoricalData/ticks/daily?Broker=${this.DataDescriptor.broker}&Symbol=${this.DataDescriptor.symbolName}&Start=${startDate}&End=${endDate}`

        if (this.LoadingDataFormat === TDataFormat.df_MSGPPACK) {
            url += '&UseMessagePack=true'
        } else if (this.LoadingDataFormat === TDataFormat.df_Binary) {
            url += '&UseZip=true&UseMessagePack=false&PriceType=float'
        }

        return url
    }

    private parseDailyTicks(buffer: ArrayBuffer): any[] {
        let offset = 0
        const dataView = new DataView(buffer)

        function readInt(): number {
            const value = dataView.getInt32(offset, true)
            offset += 4
            return value
        }

        function readLong(): number {
            const value = dataView.getBigInt64(offset, true)
            offset += 8
            return Number(value)
        }

        function readFloat(): number {
            const value = dataView.getFloat32(offset, true)
            offset += 4
            return value
        }

        const ticksCount = readInt()
        const dailyTicks = []
        const symbolInfo = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
            this.DataDescriptor.symbolName
        ).symbolInfo

        for (let i = 0; i < ticksCount; i++) {
            const day = readLong()
            const ticksInDayCount = readInt()
            const ticksInDay = []

            for (let j = 0; j < ticksInDayCount; j++) {
                const time = readInt()
                const tickCount = readInt()
                const ticks = []

                for (let k = 0; k < tickCount; k++) {
                    const ask = readFloat()
                    const bid = readFloat()
                    ticks.push({ ask, bid })
                }

                ticksInDay.push({ time, ticks })
            }

            dailyTicks.push({ day, ticks: ticksInDay })
        }

        return dailyTicks
    }

    private ConvertBinaryDataToTickRecords(data: any[]): TTickRecord[] {
        if (data.length === 0) {
            return []
        }

        return Ticks_converter.ConvertServerTicksToTickRecordsBINARY(data)
    }
}
