import dayjs from 'dayjs'

import { terminalOrderHistoryPositionType } from '@fto/lib/store/ordersStore/types'

import {
    DateFilterParamsType,
    DateIncludesType,
    SearchBySymbolValueType
} from '@root/pages/ChartPage/components/Terminal/types'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

type DateType = Date | number
const matchDateRange = (
    from: DateType,
    to: DateType,
    openTime: DateType,
    closeTime: DateType,
    filterByDateParamsIncludes: DateIncludesType
): boolean => {
    if (filterByDateParamsIncludes === 'openDate') {
        return Boolean(openTime >= from && openTime <= to)
    }

    if (filterByDateParamsIncludes === 'closeDate') {
        return Boolean(closeTime >= from && closeTime <= to)
    }

    if (filterByDateParamsIncludes === 'openOrCloseDate') {
        return Boolean((closeTime >= from && closeTime <= to) || (openTime >= from && openTime <= to))
    }

    return Boolean(closeTime >= from && openTime >= from && openTime <= to && closeTime <= to)
}

export const filterHistoryOrdersByDate = (
    order: terminalOrderHistoryPositionType,
    dateFilters: DateFilterParamsType,
    filterByDateParamsIncludes: DateIncludesType
) => {
    const { rangeType, selectedHole, rangeData } = dateFilters
    const { closeTime, openTime } = order

    const lastTickDate = dayjs(
        DateUtils.toUnixTimeMillisecondsUTC(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))
    )

    if (rangeType === 'hole') {
        if (selectedHole === 'all') {
            return true
        }

        if (selectedHole === 'today') {
            const startOfToday = lastTickDate.startOf('day').valueOf()
            // Get the end of today (23:59:59)
            const endOfToday = lastTickDate.endOf('day').valueOf()

            return matchDateRange(startOfToday, endOfToday, openTime, closeTime, filterByDateParamsIncludes)
        }

        if (selectedHole === 'yesterday') {
            const yesterday = lastTickDate.subtract(1, 'day')

            // Get the start of yesterday (00:00:00)
            const startOfYesterday = yesterday.startOf('day').valueOf()

            // Get the end of yesterday (23:59:59)
            const endOfYesterday = yesterday.endOf('day').valueOf()
            return matchDateRange(startOfYesterday, endOfYesterday, openTime, closeTime, filterByDateParamsIncludes)
        }

        if (selectedHole === 'lastWeek') {
            const now = lastTickDate

            // Find the start of last week by subtracting the current day of the week from the current date,
            // then subtracting an additional 7 days to go back one week
            const startOfLastWeek = now.subtract(now.day() + 7, 'day').startOf('week')

            // The end of last week can be found by adding 6 days to the start of last week
            const endOfLastWeek = startOfLastWeek.add(6, 'day').endOf('day')

            // Convert to Unix timestamps
            const startOfLastWeekTimestamp = startOfLastWeek.valueOf()
            const endOfLastWeekTimestamp = endOfLastWeek.valueOf()

            return matchDateRange(
                startOfLastWeekTimestamp,
                endOfLastWeekTimestamp,
                openTime,
                closeTime,
                filterByDateParamsIncludes
            )
        }

        if (selectedHole === 'lastMonth') {
            const lastMonth = lastTickDate.subtract(1, 'month')

            // Get the start of last month
            const startOfLastMonth = lastMonth.startOf('month').valueOf()

            // Get the end of last month
            const endOfLastMonth = lastMonth.endOf('month').valueOf()

            return matchDateRange(startOfLastMonth, endOfLastMonth, openTime, closeTime, filterByDateParamsIncludes)
        }

        if (selectedHole === 'lastYear') {
            const lastYear = lastTickDate.subtract(1, 'year')

            // Get the start of last year
            const startOfLastYear = lastYear.startOf('year').valueOf()

            // Get the end of last year
            const endOfLastYear = lastYear.endOf('year').valueOf()

            return matchDateRange(startOfLastYear, endOfLastYear, openTime, closeTime, filterByDateParamsIncludes)
        }
    }

    const startRangeTimeStamp = dayjs(rangeData.from).valueOf()
    const endRangeTimeStamp = dayjs(rangeData.to).valueOf()

    const dayStartTime = dayjs(startRangeTimeStamp).startOf('day').valueOf()
    const dayEndTime = dayjs(endRangeTimeStamp).endOf('day').valueOf()

    return matchDateRange(dayStartTime, dayEndTime, openTime, closeTime, filterByDateParamsIncludes)
}

export const filterOrdersBySymbolSelection = (
    orderSymbol: string,
    activeChartSymbol: string,
    filterSelection: SearchBySymbolValueType
) => {
    if (filterSelection === 'all') {
        return true
    }

    return orderSymbol === activeChartSymbol
}
