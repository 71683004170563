import { FC } from 'react'
import cx from 'classnames'

import styles from './index.module.scss'
import { COLOR_NAMES } from '../../output/colors'

type Props = {
    margin?: string
    width?: string
    height?: string
    color?: (typeof COLOR_NAMES)[number]
    className?: string
}

export const Separator: FC<Props> = ({ margin, width, height, color = 'gray-400', className }) => {
    return (
        <div
            style={{
                margin: margin,
                width: width,
                height: height,
                background: `var(--color-${color})`
            }}
            className={cx(styles.separator, className)}
        />
    )
}
