import React, { FC, useCallback, useMemo } from 'react'
import ReactSlider from 'react-slider'
import cx from 'classnames'

import { Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    value: number
    onChange: (value: number) => void
    // NOTE: if rangeValues is defined, that means we have selected point to select (skip prev steps) like [1, 25, 50, 100]
    rangeValues?: number[]
    classNames?: {
        root?: string
        slider?: string
        thumb?: string
        track?: string
        rangeMarks?: string
    }
    pointsToShow?: number[]
    withPoints?: boolean
    step?: number
    min?: number
    max?: number
    formatLabel?: (value: number) => string | number
}

export const RangeSlider: FC<Props> = ({
    onChange,
    rangeValues,
    pointsToShow,
    classNames,
    value,
    withPoints = true,
    step = 1,
    min = 0,
    max = 100,
    formatLabel
}) => {
    const handleChange = useCallback(
        (newValue: number) => {
            if (rangeValues) {
                // NOTE: newValue works like index of rangeValues
                return onChange(rangeValues[newValue])
            }
            return onChange(newValue)
        },
        [onChange, rangeValues]
    )

    const labelsList = useMemo(() => {
        if (!pointsToShow && rangeValues) {
            return rangeValues
        }

        if (rangeValues && pointsToShow) {
            return rangeValues.filter((point) => pointsToShow.includes(point))
        }

        return pointsToShow || []
    }, [rangeValues, pointsToShow])

    const minValue = useMemo(() => {
        return rangeValues ? 0 : min
    }, [rangeValues, min])

    const maxValue = useMemo(() => {
        return rangeValues ? rangeValues.length - 1 : max
    }, [rangeValues, max])

    const calculatedValue = useMemo(() => {
        if (rangeValues) {
            return rangeValues.indexOf(value)
        }

        return value
    }, [value, rangeValues])

    return (
        <div className={cx(styles.sliderWrap, classNames?.root)}>
            <ReactSlider
                marks={rangeValues}
                value={calculatedValue}
                max={maxValue}
                step={step}
                min={minValue}
                onChange={handleChange}
                className={cx(styles.slider, classNames?.slider)}
                thumbClassName={cx(styles.thumb, classNames?.thumb)}
                trackClassName={cx(styles.track, classNames?.track)}
                renderThumb={(props) => <div {...props} />}
            />
            {withPoints && (
                <div className={styles.rangeMarks}>
                    {labelsList.map((point) => {
                        const label = formatLabel ? formatLabel(point) : point
                        return (
                            <div key={point}>
                                <div className={styles.point}>
                                    |
                                    <Typography
                                        style={{ position: 'absolute', left: String(label).length * -3 }}
                                        data-value={label}
                                        className={styles.label}
                                        color='gray-600'
                                        type='subtext-regular'
                                    ></Typography>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
