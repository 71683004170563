import StrangeError from '../../common/common_errors/StrangeError'

export class ExternalModuleDescriptor {
    constructor(fileName: string, shortName: string, description = '') {
        this.fFileName = fileName
        this._shortName = shortName
        this._description = description
    }

    private _description: string
    public get description(): string {
        return this._description
    }

    public set description(value: string) {
        this._description = value
    }

    private _shortName: string
    public get shortName(): string {
        return this._shortName
    }

    public set shortName(value: string) {
        this._shortName = value
    }

    private _libName: string | undefined = undefined
    public get libName(): string {
        if (!this._libName) {
            throw new StrangeError('LibName is not set in TVeryBasicDllClass')
        }
        return this._libName
    }

    public set libName(value: string) {
        this._libName = value
    }

    protected fFileName: string

    public get FileName(): string {
        return this.fFileName
    }
}
