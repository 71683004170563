import { ComponentPropsWithoutRef, forwardRef, memo, ReactNode } from 'react'

import cx from 'classnames'

import { InputSizes } from '@fto/ui/lib/components/InputField/components/Input'
import styles from './index.module.scss'

const getContent = (node: any) => (typeof node === 'function' ? node() : node)

export const inputContainerVariants = ['outline'] as const

export type OwnInputContainerProps = {
    variant?: (typeof inputContainerVariants)[number]

    suffix?: ReactNode | (() => ReactNode)
    prefix?: ReactNode | (() => ReactNode)

    loading?: boolean
    success?: boolean
    error?: boolean
    disabled?: boolean

    align?: 'start' | 'center' | 'end'
    block?: boolean
    compact?: boolean
    scrollable?: boolean
    outbound?: boolean
    size?: (typeof InputSizes)[number]
}

export type InputContainerProps = Omit<ComponentPropsWithoutRef<'div'>, keyof OwnInputContainerProps> &
    OwnInputContainerProps

export const InputContainer = memo(
    forwardRef<HTMLDivElement, InputContainerProps>(
        (
            {
                variant = 'outline',

                prefix,
                suffix,

                success,
                error,
                disabled,
                loading,

                scrollable,
                outbound,
                block,
                compact,
                align = 'start',

                children,
                className,
                size = 'default',
                ...rest
            },
            ref
        ) => {
            const _prefix = getContent(prefix)
            const _suffix = getContent(suffix)

            return (
                <div
                    {...rest}
                    ref={ref}
                    tabIndex={0}
                    className={cx(
                        styles.container,
                        [styles[`variant-${variant}`]],
                        [styles[`size-${size}`]],
                        className,
                        {
                            [styles.isSuccess]: success,
                            [styles.isError]: error,
                            [styles.isLoading]: loading,
                            [styles.isDisabled]: disabled,
                            [styles.focusable]: !disabled,
                            [styles.block]: block,
                            [styles.compact]: compact,
                            [styles.scrollable]: scrollable,
                            [styles.outbound]: outbound
                        }
                    )}
                >
                    {_prefix && <div className={styles.prefix}>{_prefix}</div>}
                    <div className={cx(styles.content, [styles[`align-${align}`]])}>{children}</div>
                    {_suffix && <div className={styles.suffix}>{_suffix}</div>}
                </div>
            )
        }
    )
)

InputContainer.displayName = 'InputContainer'

export default InputContainer
