import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { DEFAULT_HOTKEYS, addHotkey } from './Hotkeys'
import ToolInfoStore from '@fto/lib/store/tools'

class KeyboardTracker {
    private static instance: KeyboardTracker
    public isCtrlPressed = false
    public isShiftPressed = false
    public isAltPressed = false
    public isFakeMouseMoveDone = false
    private keyboardPressedList = new Set()

    private constructor() {
        for (const hotkey of DEFAULT_HOTKEYS) {
            addHotkey(hotkey)
        }
    }

    public static getInstance(): KeyboardTracker {
        if (!KeyboardTracker.instance) {
            KeyboardTracker.instance = new KeyboardTracker()
        }
        return KeyboardTracker.instance
    }

    public onKeyDown(event: KeyboardEvent): void {
        this.keyboardPressedList.add(event.key)

        const isOnlyOneButtonPressed = this.keyboardPressedList.size === 1
        if (event.key === 'Control') {
            this.isCtrlPressed = true
        }

        if (event.key === 'Shift') {
            this.isShiftPressed = true
        }

        if (event.key === 'Alt') {
            this.isAltPressed = true
        }

        const numberPattern = /^[1-9]$/ // Pattern for 1-9 number
        const isAnyModalOpen = document.querySelector('dialog') !== null
        if (
            isOnlyOneButtonPressed &&
            numberPattern.test(event.key) &&
            !isAnyModalOpen &&
            document.activeElement?.nodeName !== 'INPUT' &&
            document.activeElement?.nodeName !== 'TEXTAREA'
        ) {
            addModal(MODAL_NAMES.chart.customTimeFrame, { value: '', isChange: true })
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        this.keyboardPressedList.delete(event.key)

        if (event.key === 'Control') {
            this.isCtrlPressed = false
        }

        if (event.key === 'Shift') {
            this.isShiftPressed = false
        }

        if (event.key === 'Alt') {
            this.isAltPressed = false
        }
    }

    public onWindowBlur(): void {
        this.isCtrlPressed = false
        this.isAltPressed = false
        this.isShiftPressed = false
        this.keyboardPressedList.clear()
    }
}

export default KeyboardTracker
