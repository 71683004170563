import { FC } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useTranslation } from 'react-i18next'

export const PaymentFailedModal: FC = () => {
    const { t } = useTranslation()

    return (
        <Modal name={MODAL_NAMES.payment.failed} size='xs'>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='face-frown' size={72} />
                    <Typography type='h3-bold' align='center'>
                        {t('profile.modals.payment.failedHeader')}
                    </Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.payment.failedText')}
                </Typography>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.payment.tryAgainText')}
                </Typography>
            </Modal.Content>
            <Modal.Controls>
                <Button
                    label={t('profile.modals.payment.tryAgainAction')}
                    onClick={() => removeModal(MODAL_NAMES.payment.failed)}
                    type='primary'
                    block
                />
            </Modal.Controls>
        </Modal>
    )
}
