import { makeAutoObservable } from 'mobx'
import { TEducationTask } from '@fto/lib/Education/Types'

export type UpdaterType = ((prevSettings: StoreType) => StoreType) | StoreType

type StoreType = {
    lesson: TEducationTask
    isShown: boolean
}

const initialValue: StoreType = {
    lesson: {
        presetId: 0,
        taskId: 0,
        title: '',
        intro: '',
        checks: [],
        redirectLink: ''
    },
    isShown: false
}

class EducationStore {
    data: StoreType

    constructor() {
        this.data = initialValue
        makeAutoObservable(this)
        this.updateData = this.updateData.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.data = initialValue
    }
}

export default new EducationStore()
