import { ChartInfoType, isSupportedCount } from '@fto/chart_components/types/chartTypes'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import { removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'
import { LAYOUTS } from '@fto/chart_components/constants/layouts'

export function selectSymbol(
    symbol: string,
    modalType: 'symbolChange' | 'addChart',
    chartWindow: TChartWindow | undefined,
    addSymbolToProject: (symbol: string) => void,
    setSelectedChartInfo: (chartInfo: ChartInfoType) => void
) {
    if (modalType === 'symbolChange' && chartWindow) {
        fireMixpanelEvent('currency_changed', {
            from: globalChartsStore.data.activeChartSymbol,
            to: symbol
        })

        GlobalChartsController.Instance.setNewSymbol(symbol, chartWindow)
    } else {
        CreateNewChart(symbol, setSelectedChartInfo)
    }

    addSymbolToProject(symbol)

    removeModal(MODAL_NAMES.chart.changeSymbol)
}

function CreateNewChart(symbolName: string, setSelectedChartInfo: (chartInfo: ChartInfoType) => void) {
    const chartsLength = GlobalChartsController.Instance.getAllCharts().length

    const newLength = chartsLength + 1

    const layout = LAYOUTS.find((configItem) => {
        return configItem.value.count === newLength
    })?.value

    if (!isSupportedCount(newLength) || !layout) {
        return
    }

    const chartInfo = {
        symbol: symbolName,
        layout: layout.layout,
        count: layout.count
    } as ChartInfoType

    setSelectedChartInfo(chartInfo)
    GlobalProjectJSONAdapter.Instance.setLayoutSettings(layout)

    fireMixpanelEvent('multichart_added', { layout: layout.layout, total_charts: layout.count })
}
