import { t } from 'i18next'
import TimeZoneManager, { TimeZoneDesc } from '@fto/lib/Timezones&DST/TimeZoneManager'
import { DaylightSavingTimeManager, TDstType } from '@fto/lib/Timezones&DST/DaylightSavingTimeManager'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ThrottleForRepaint } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'

export class GlobalTimezoneDSTController {
    private static instance: GlobalTimezoneDSTController
    private _timezoneManager: TimeZoneManager = new TimeZoneManager()
    private _currentTimezoneDesc: TimeZoneDesc = this._timezoneManager.getDefaulTimeZone()
    private _daylightSavingManager: DaylightSavingTimeManager = new DaylightSavingTimeManager()
    private _currentDST: TDstType = TDstType.dst_None

    public static get Instance(): GlobalTimezoneDSTController {
        if (!GlobalTimezoneDSTController.instance) {
            GlobalTimezoneDSTController.instance = new GlobalTimezoneDSTController()
        }
        return GlobalTimezoneDSTController.instance
    }

    public getServerFormatTimezone(): number {
        return this._currentTimezoneDesc.offsetSeconds / 3600
    }

    public getServerFormatDST(): string {
        switch (this._currentDST) {
            case TDstType.dst_None:
                return 'None'
            case TDstType.dst_US:
                return 'US'
            case TDstType.dst_European:
                return 'EU'
            case TDstType.dst_Australian:
                return 'AU'
            default:
                throw new StrangeError('Unknown DST type')
        }
    }

    public updateDstFromServer(dst: string): void {
        this._currentDST = this.convertServerDstToInnerLibDst(dst)
        this.notifyAboutTimezoneChange()
    }

    public updateTimezoneFromServer(timezoneOffsetInHours: number): void {
        this._currentTimezoneDesc = this._timezoneManager.getTimezoneDescByTimezoneOffsetInHours(timezoneOffsetInHours)
        this.notifyAboutTimezoneChange()
    }

    public updateDST_ByDateRange(startDate: TDateTime, endDate: TDateTime): void {
        this._daylightSavingManager.updateDST_ByDateRange(startDate, endDate)
    }

    public upTimeZone(): void {
        this._currentTimezoneDesc = this._timezoneManager.zoneUp(this._currentTimezoneDesc)
        this.notifyAboutTimezoneChange()
    }

    public downTimeZone(): void {
        this._currentTimezoneDesc = this._timezoneManager.zoneDown(this._currentTimezoneDesc)
        this.notifyAboutTimezoneChange()
    }

    public convertFromInnerLibDateTimeByTimezoneAndDst(dateTimeToConvert: TDateTime): TDateTime {
        let result = dateTimeToConvert + this.getTimeZoneDT()
        result += this._daylightSavingManager.getOffsetInDTByDst(result, this._currentDST)
        return result
    }

    public convertFromInnerLibUnixSecondsByTimezoneAndDst(unixSeconds: number): number {
        let result = unixSeconds + this.getTimeZoneSeconds()
        result += this._daylightSavingManager.getOffsetInSecondsByDst(result, this._currentDST)
        return result
    }

    public convertToInnerlibDateTimeByTimezoneAndDst(dateTimeToConvert: TDateTime): TDateTime {
        let result = dateTimeToConvert - this.getTimeZoneDT()
        result -= this._daylightSavingManager.getOffsetInDTByDst(result, this._currentDST)
        return result
    }

    public convertToInnerlibUnixSecondsByTimezoneAndDst(unixSeconds: number): number {
        let result = unixSeconds - this.getTimeZoneSeconds()
        result -= this._daylightSavingManager.getOffsetInSecondsByDst(result, this._currentDST)
        return result
    }

    public convertToInnerlibUnixMilisecondsByTimezoneAndDst(unixMiliseconds: number): number {
        let result = unixMiliseconds - this.getTimeZoneSeconds() * 1000
        result -= this._daylightSavingManager.getOffsetInSecondsByDst(result / 1000, this._currentDST) * 1000
        return result
    }

    public convertFromInnerLibUnixMilisecondsByTimezoneAndDst(unixMiliseconds: number): number {
        let result = unixMiliseconds + this.getTimeZoneSeconds() * 1000
        result += this._daylightSavingManager.getOffsetInSecondsByDst(result / 1000, this._currentDST) * 1000
        return result
    }

    public getTimeZoneDT(): number {
        return this._currentTimezoneDesc.offsetDT
    }

    public getTimeZoneSeconds(): number {
        return this._currentTimezoneDesc.offsetSeconds
    }

    public getTimezoneString(): string {
        return this._currentTimezoneDesc.value
    }

    public getDstString(): string {
        switch (this._currentDST) {
            case TDstType.dst_None:
                return t('dashboard.modals.projects.advanced.dstOptions1')
            case TDstType.dst_US:
                return t('dashboard.modals.projects.advanced.dstOptions2')
            case TDstType.dst_European:
                return t('dashboard.modals.projects.advanced.dstOptions3')
            case TDstType.dst_Australian:
                return t('dashboard.modals.projects.advanced.dstOptions4')
            default:
                throw new StrangeError('Unknown DST type from enum')
        }
    }

    private notifyAboutTimezoneChange(): void {
        Promise.all([
            import('@fto/lib/globals/GlobalSymbolList').then((module) => {
                const GlobalSymbolList = module.default
                GlobalSymbolList.SymbolList.onTimezoneOrDSTChanged()
            }),
            import('@fto/lib/globals/GlobalProcessingCore').then((module) => {
                const GlobalProcessingCore = module.default
                GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
            })
        ])
            .then(() => {
                GlobalChartsController.Instance.updateCharts(ThrottleForRepaint.Disabled)
            })
            .catch((error) => {
                throw new StrangeError('Error in notifyAboutTimezoneChange', error)
            })
    }

    private convertServerDstToInnerLibDst(serverDst: string): TDstType {
        switch (serverDst) {
            case 'None':
                return TDstType.dst_None
            case 'US':
                return TDstType.dst_US
            case 'EU':
                return TDstType.dst_European
            case 'AU':
                return TDstType.dst_Australian
            default:
                throw new StrangeError('Unknown DST type from server JSON')
        }
    }

    public getTimezones() {
        return this._timezoneManager.getTimezones()
    }

    public setTimeZone(tz: TimeZoneDesc) {
        this._currentTimezoneDesc = tz
        this.notifyAboutTimezoneChange()
    }

    private constructor() {}
}
