import IBasicPaintTool from '../IBasicPaintTool'

class PaintToolsListMemento {
    private tools: IBasicPaintTool[]

    constructor(tools: IBasicPaintTool[]) {
        this.tools = tools
    }

    getTools(): IBasicPaintTool[] {
        return [...this.tools]
    }
}

export default PaintToolsListMemento
