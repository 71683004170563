import { t } from 'i18next'
import {
    TMAType,
    TOptionType,
    TOptValue_bool,
    TOptValue_number,
    TOutputWindow,
    TPriceType
} from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TIndexBuffer } from '@fto/lib/extension_modules/indicators/api/IIndicatorApi'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'
import { IndicatorImplementation } from '../api/IndicatorImplementation'

export class Awesome extends IndicatorImplementation {
    periodF: TOptValue_number = new TOptValue_number(5)
    periodS: TOptValue_number = new TOptValue_number(34)
    MAType: TOptValue_number = new TOptValue_number(TMAType.ma_SMA)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    SMA1!: TIndexBuffer
    SMA2!: TIndexBuffer
    SMAF!: TIndexBuffer
    SMAS!: TIndexBuffer
    SMA!: TIndexBuffer

    isStrategy: TOptValue_bool = new TOptValue_bool(true)

    Init() {
        this.api.IndicatorShortName(t('indicators.awersomeOscillator'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.SetEmptyValue(0)

        this.api.RegOption('PeriodF', TOptionType.ot_Integer, this.periodF)
        this.api.SetOptionRange('PeriodF', 1, INT_MAX)

        this.api.RegOption('PeriodS', TOptionType.ot_Integer, this.periodS)
        this.api.SetOptionRange('PeriodS', 1, INT_MAX)

        this.api.RegMATypeOption(this.MAType)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, 'Apply to')

        this.api.RegOption('isStrategy', TOptionType.ot_Boolean, this.isStrategy)

        this.SMA = this.api.CreateIndexBuffer()
        this.SMAF = this.api.CreateIndexBuffer()
        this.SMAS = this.api.CreateIndexBuffer()
        this.SMA1 = this.api.CreateIndexBuffer()
        this.SMA2 = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(2)
        this.api.SetIndexBuffer(0, this.SMA1)
        this.api.SetIndexBuffer(1, this.SMA2)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 3, '#66CDAA')
        this.api.SetIndexLabel(0, 'SMA 1')
        this.api.SetIndexStyle(1, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 3, '#ff1500')
        this.api.SetIndexLabel(1, 'SMA 2')
    }
    Calculate(index: number) {
        if (index + this.periodF.value >= this.api.Bars() || index + this.periodS.value >= this.api.Bars()) {
            return
        }

        this.SMAF.setValue(
            index,
            this.api.GetMA(
                index,
                0,
                this.periodF.value,
                this.MAType.value,
                this.ApplyToPrice.value,
                this.SMAF.getValue(index + 1)
            )
        )

        this.SMAS.setValue(
            index,
            this.api.GetMA(
                index,
                0,
                this.periodS.value,
                this.MAType.value,
                this.ApplyToPrice.value,
                this.SMAS.getValue(index + 1)
            )
        )

        this.SMA.setValue(index, this.SMAF.getValue(index) - this.SMAS.getValue(index))
        const current = this.SMA.getValue(index)
        const prev = this.SMA.getValue(index + 1)

        if (current < prev) {
            // if (index === 0) {
            //     if (this.isStrategy.value === true) {
            //         console.log('from strategy', 'SMA2 =', current, 'SMA1 =', 0)
            //     } else {
            //         console.log('from indicator', 'SMA2 =', current, 'SMA1 =', 0)
            //     }
            // }
            this.SMA2.setValue(index, current)
            this.SMA1.setValue(index, 0)
        }

        if (current > prev) {
            // if (index === 0) {
            //     if (this.isStrategy.value === true) {
            //         console.log('from strategy', 'SMA1 =', current, 'SMA2 =', 0)
            //     } else {
            //         console.log('from indicator', 'SMA1 =', current, 'SMA2 =', 0)
            //     }
            // }
            this.SMA1.setValue(index, current)
            this.SMA2.setValue(index, 0)
        }
    }
}
