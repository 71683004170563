export class DelphiMathCompatibility {
    static RoundTo(value: number, digits: number): number {
        const scale = Math.pow(10, -digits)
        const scaledValue = value * scale
        let roundedValue

        if (digits >= 0) {
            // For non-negative digits, scale up, round, and scale down
            roundedValue = Math.round(scaledValue) / scale
        } else {
            // For negative digits, scale up, apply Banker's rounding, and scale down
            let integralPart = Math.trunc(scaledValue)
            const fractionalPart = Math.abs(scaledValue - integralPart)

            if (fractionalPart > 0.5 || (fractionalPart === 0.5 && integralPart % 2 !== 0)) {
                integralPart += value > 0 ? 1 : -1
            }

            roundedValue = integralPart / scale
        }

        return roundedValue
    }

    static Mod(dividend: number, divisor: number): number {
        if (divisor === 0) {
            throw new Error('Divisor cannot be zero')
        }
        let remainder = dividend % divisor
        if (remainder < 0 && dividend < 0) {
            // Adjust remainder for negative dividends to mimic Delphi's behavior
            remainder += Math.abs(divisor)
        }
        return remainder
    }
}
