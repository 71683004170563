import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import {
    IMockBarChunk,
    IMockDataForTimeframe,
    IMockSymbolData,
    IMockTickChunk
} from '../MocksForTesting/MockDataInterfaces'
import IMockDataDictionary from './IMockDataDictionary'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataFormat } from '@fto/lib/ft_types/data/DataEnums'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TBaseTickChunk } from '@fto/lib/ft_types/data/chunks/TickChunks/BaseTickChunk'
import { TTickDataFillStyle } from '../MocksForTesting/MockDataEnums'
import MockChunkGenerator from '../MocksForTesting/MockChunkGenerator'
import IMockSymbolsWithData from './IMockDataDictionary'
import { TDataRecordWithDate } from '@fto/lib/ft_types/data/DataClasses/TDataRecordWithDate'

export default class MockServerDataImporter {
    public static MockData: IMockDataDictionary = {}
    public static NoDataNeeded = false
    public static ActivateTimeframesIfDataAvailable = true
    public static TickDataFillStyle: TTickDataFillStyle = TTickDataFillStyle.tdfs_FillWithProvidedData

    public static Reset(): void {
        MockServerDataImporter.MockData = {}
    }

    public static AddMockSymbolWithEmptyData(symbolName: string): void {
        const emptyMockData = this.GetEmptyMockData()

        // Add the mock data to the dictionary
        MockServerDataImporter.MockData[symbolName] = emptyMockData
    }

    private static IsTimeframesListEmpty(mockData: IMockSymbolData) {
        return Object.keys(mockData.timeframes).length === 0
    }

    public static ActivateAppropriateTimeframes(symbolName: string, mockData: IMockSymbolData): void {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)
        if (!symbolData) throw new StrangeError('Symbol not found')
        //get keys from mockData.timeframes
        const timeframeKeys = Object.keys(mockData.timeframes)
        //activate each timeframe
        for (const timeframe of timeframeKeys) {
            symbolData.EnsureTimeframeIsActive(Number(timeframe))
        }
    }

    public static AddMockSymbolsListWithData(symbolDataDictionary: IMockSymbolsWithData): void {
        for (const symbolName in symbolDataDictionary) {
            this.AddMockSymbolWithData(symbolName, symbolDataDictionary[symbolName])
        }
    }

    public static AddMockSymbolWithData(symbolName: string, mockDataForSymbol: IMockSymbolData): void {
        if (this.MockData[symbolName]) {
            this.MockData[symbolName] = mockDataForSymbol
        }

        this.AddMockSymbolWithEmptyData(symbolName)

        MockServerDataImporter.SetMockDataForExistingSymbol(symbolName, mockDataForSymbol)
    }

    public static SetMockDataForExistingSymbol(symbolName: string, mockData: IMockSymbolData): void {
        // this.Add_m15_TimeframeIfNotExists(mockData)
        this.Add_ticks_IfNotExists(mockData)

        this.__mockTickChunkConsistencyCheck(mockData)

        MockServerDataImporter.MockData[symbolName] = mockData
        if (!this.IsTimeframesListEmpty(mockData) && MockServerDataImporter.ActivateTimeframesIfDataAvailable) {
            this.ActivateAppropriateTimeframes(symbolName, mockData)
        }
    }

    public static GetDefaultMockData(): IMockSymbolData {
        const mockData: IMockSymbolData = { timeframes: {}, tickChunks: [] }
        this.Add_m15_TimeframeIfNotExists(mockData)
        this.Add_ticks_IfNotExists(mockData)
        return mockData
    }

    private static Add_ticks_IfNotExists(mockData: IMockSymbolData): void {
        if (!mockData.tickChunks || mockData.tickChunks.length === 0) {
            mockData.tickChunks = [
                {
                    firstDateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 0, 0),
                    ticks: [
                        {
                            DateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 0, 0),
                            Ask: 1.1001,
                            Bid: 1.1
                        },
                        {
                            DateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 1, 0),
                            Ask: 1.1011,
                            Bid: 1.101
                        },
                        {
                            DateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 16, 0),
                            Ask: 1.1011,
                            Bid: 1.101
                        }
                    ]
                }
            ]
        }
    }

    private static Add_m15_TimeframeIfNotExists(mockData: IMockSymbolData): void {
        if (!mockData.timeframes[15]) {
            mockData.timeframes[15] = {
                map: {
                    chunkInfos: [
                        {
                            Start: DateUtils.EncodeDate(2000, 1, 1, 0, 0, 0),
                            End: DateUtils.EncodeDate(2030, 1, 1, 23, 59, 59),
                            BarsCount: 2,
                            FirstBarIndex: 0
                        }
                    ]
                },
                barChunks: [
                    {
                        firstDateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 0, 0),
                        bars: [
                            {
                                DateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 0, 0),
                                Open: 1.1,
                                High: 1.1,
                                Low: 1.1,
                                Close: 1.1,
                                TickVolume: 1
                            },
                            {
                                DateTime: DateUtils.EncodeDate(2000, 1, 1, 0, 15, 0),
                                Open: 1.101,
                                High: 1.101,
                                Low: 1.101,
                                Close: 1.101,
                                TickVolume: 1
                            }
                        ]
                    }
                ]
            }
        }
    }

    private static EnsureTimeframeActivated(chunk: TBarChunk) {
        const symbolName = chunk.DataDescriptor.symbolName
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)
        if (!symbolData) throw new StrangeError('Symbol not found')
        const timeframe = chunk.DataDescriptor.timeframe
        symbolData.EnsureTimeframeIsActive(timeframe)
    }

    private static ImportBarChunkForSymbol(chunk: TBarChunk, symbolMockData: IMockSymbolData) {
        const timeframeMockData = symbolMockData.timeframes[chunk.DataDescriptor.timeframe]
        if (timeframeMockData) {
            this.EnsureTimeframeActivated(chunk)
            this.ImportBarChunkForTimeframe(chunk, timeframeMockData)
        } else {
            if (MockServerDataImporter.NoDataNeeded) {
                return
            } else {
                throw new StrangeError(`No BAR mock data for timeframe ${chunk.DataDescriptor.timeframe}`)
            }
        }
    }

    private static MockBarDataInsideChunkRange(mockChunk: IMockBarChunk, chunk: TBarChunk) {
        const mockChunkFirstDate = mockChunk.bars[0].DateTime
        const mockChunkLastDate = mockChunk.bars[mockChunk.bars.length - 1].DateTime

        return mockChunkFirstDate >= chunk.FirstDate && mockChunkLastDate <= chunk.LastPossibleDate
    }

    private static ImportBarChunkForTimeframe(chunk: TBarChunk, timeframeMockData: IMockDataForTimeframe) {
        //find the mock chunk data that corresponds to the chunk's first date
        for (const mockChunk of timeframeMockData.barChunks) {
            if (this.MockBarDataInsideChunkRange(mockChunk, chunk)) {
                chunk.ImportChunkData(mockChunk, TDataFormat.df_MockObjects)
                return
            }
        }
        throw new StrangeError(
            `No appropriate mock Bar data for chunk with first date ${DateUtils.DF(chunk.FirstDate)}`
        )
    }

    private static GetMockDataForChunkSymbol(chunk: TDownloadableChunk<TDataRecordWithDate>): IMockSymbolData {
        const symbolName = chunk.DataDescriptor.symbolName
        const symbolMockData = MockServerDataImporter.MockData[symbolName]
        if (symbolMockData) {
            return symbolMockData
        } else {
            if (MockServerDataImporter.NoDataNeeded) {
                return MockServerDataImporter.GetEmptyMockData()
            } else {
                throw new StrangeError(`No mock data for symbol ${symbolName}`)
            }
        }
    }

    static GetEmptyMockData(): IMockSymbolData {
        return {
            timeframes: {},
            tickChunks: []
        }
    }

    private static get EmptyTimeframeData(): IMockDataForTimeframe {
        return { map: { chunkInfos: [] }, barChunks: [] }
    }

    private static GetMockDataForTimeframe(chunk: TBarChunk): IMockDataForTimeframe {
        const symbolMockData = this.GetMockDataForChunkSymbol(chunk)
        const timeframeMockData = symbolMockData.timeframes[chunk.DataDescriptor.timeframe]
        if (timeframeMockData) {
            return timeframeMockData
        } else {
            if (MockServerDataImporter.NoDataNeeded) {
                return this.EmptyTimeframeData
            } else {
                throw new StrangeError(`No mock data for timeframe ${chunk.DataDescriptor.timeframe}`)
            }
        }
    }

    public static MockDownloadChunk_Bars(chunk: TBarChunk): void {
        const symbolMockData = this.GetMockDataForChunkSymbol(chunk)
        this.ImportBarChunkForSymbol(chunk, symbolMockData)
    }

    private static MockDownloadChunk_Ticks(chunk: TBaseTickChunk) {
        const symbolName = chunk.DataDescriptor.symbolName
        const symbolMockData = MockServerDataImporter.MockData[symbolName]
        if (symbolMockData) {
            switch (this.TickDataFillStyle) {
                case TTickDataFillStyle.tdfs_FillWithGeneratedData: {
                    this.FillTickChunkWithGeneratedTickData(chunk)
                    break
                }
                case TTickDataFillStyle.tdfs_FillWithProvidedData: {
                    this.FillTickChunkWithMockData(chunk, symbolMockData)
                    break
                }
                case TTickDataFillStyle.tdfs_IgnoreTicks: {
                    break
                }
                default: {
                    throw new StrangeError('Unknown tick data fill style')
                }
            }
        }
    }

    private static FillTickChunkWithGeneratedTickData(chunk: TBaseTickChunk) {
        const mockChunkData = MockChunkGenerator.GenerateMockTickChunkData_ticksEachSecond(chunk, 10)
        chunk.ImportChunkData(mockChunkData, TDataFormat.df_MockObjects)
    }

    static HasDataForChunk(chunk: TDownloadableChunk<TDataRecordWithDate>): boolean {
        if (!this.MockData[chunk.DataDescriptor.symbolName]) {
            return false
        }
        const symbolData = this.MockData[chunk.DataDescriptor.symbolName]
        const mockSymbolData = symbolData

        const firstDate = chunk.FirstDate
        const lastDate = chunk.LastPossibleDate
        if (chunk instanceof TBaseTickChunk) {
            return this.HasTickDataForPeriod(mockSymbolData, firstDate, lastDate)
        } else if (chunk instanceof TBarChunk) {
            return this.HasBarDataForPeriod(mockSymbolData, firstDate, lastDate, chunk.DataDescriptor.timeframe)
        }
        throw new StrangeError('Chunk type not supported')
    }

    static HasBarDataForPeriod(
        mockSymbolData: IMockSymbolData,
        firstDate: number,
        lastDate: number,
        timeframe: number
    ): boolean {
        const timeframeData = mockSymbolData.timeframes[timeframe]
        if (timeframeData) {
            for (const barChunk of timeframeData.barChunks) {
                if (barChunk.firstDateTime >= firstDate && barChunk.firstDateTime <= lastDate) {
                    return true
                }
            }
        }
        return false
    }

    static HasTickDataForPeriod(mockSymbolData: IMockSymbolData, firstDate: number, lastDate: number): boolean {
        for (const tickChunk of mockSymbolData.tickChunks) {
            if (
                DateUtils.MoreOrEqual(tickChunk.firstDateTime, firstDate) &&
                DateUtils.LessOrEqual(tickChunk.firstDateTime, lastDate)
            ) {
                return true
            }
        }
        return false
    }

    private static FillTickChunkWithMockData(chunk: TBaseTickChunk, symbolMockData: IMockSymbolData) {
        //find the mock chunk data that corresponds to the chunk's first date
        for (const mockChunk of symbolMockData.tickChunks) {
            if (this.MockTickDataInsideChunkRange(mockChunk, chunk)) {
                chunk.ImportChunkData(mockChunk, TDataFormat.df_MockObjects)
                return
            }
        }
        throw new StrangeError(
            `No appropriate mock TICK data for chunk with first date ${DateUtils.DF(chunk.FirstDate)}`
        )
    }

    private static __mockTickChunkConsistencyCheck(symbolMockData: IMockSymbolData) {
        for (const mockChunk of symbolMockData.tickChunks) {
            if (mockChunk.ticks.length > 0) {
                const firstTickDay = DateUtils.GetDateWithNoTime(mockChunk.ticks[0].DateTime)
                const lastTickDay = DateUtils.GetDateWithNoTime(mockChunk.ticks[mockChunk.ticks.length - 1].DateTime)
                const chunkFirstDay = DateUtils.GetDateWithNoTime(mockChunk.firstDateTime)
                if (
                    !DateUtils.AreEqual(chunkFirstDay, firstTickDay) ||
                    !DateUtils.AreEqual(chunkFirstDay, lastTickDay)
                ) {
                    throw new StrangeError(
                        'invalid tick chunk, first tick date day is not equal to chunk first date day'
                    )
                }
            }
        }
    }

    private static MockTickDataInsideChunkRange(mockChunk: IMockTickChunk, chunk: TBaseTickChunk) {
        let mockChunkFirstDate, mockChunkLastDate

        if (mockChunk.ticks.length === 0) {
            mockChunkFirstDate = mockChunk.firstDateTime
            mockChunkLastDate = mockChunkFirstDate
        } else {
            mockChunkFirstDate = mockChunk.ticks[0].DateTime
            mockChunkLastDate = mockChunk.ticks[mockChunk.ticks.length - 1].DateTime
        }
        return mockChunkFirstDate >= chunk.FirstDate && mockChunkLastDate <= chunk.LastPossibleDate
    }

    public static MockDownloadChunk(chunk: TDownloadableChunk<TDataRecordWithDate>): void {
        if (chunk instanceof TBarChunk) {
            this.MockDownloadChunk_Bars(chunk)
        } else if (chunk instanceof TBaseTickChunk) {
            this.MockDownloadChunk_Ticks(chunk)
        } else {
            throw new StrangeError('Unknown chunk type')
        }
    }
}
