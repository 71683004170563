import { TRectPaintTool } from './ptRect'
import GraphToolStore from '@fto/lib//charting/tool_storages/graphToolStore'
import { RectangleJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import {
    TCoordsRect,
    TPaintToolStatus,
    TPaintToolType,
    TPointsArray
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TPoint } from '../../../extension_modules/common/CommonExternalInterface'
import { IChart } from '../../chart_classes/IChart'

export class TPtRectangle extends TRectPaintTool {
    private brushForText: IGPSolidBrush
    public drawMiddleLine = true
    private middleLineStyle: TLineStyle = this.fLineStyle.clone()

    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptRectangle) // Call the parent class constructor with the chart instance

        this.fClosedPolygon = true // Indicates that the rectangle is a closed polygon
        this.CursorStyle = CustomCursorPointers.crCursorRectangle // Set the cursor style for the rectangle tool
        this.icon = 58 // Assign an icon index for the tool
        this.fToolType = TPaintToolType.tt_Polygon
        this.brushForText = new IGPSolidBrush(DelphiColors.clBlack)

        this.applySettings()
    }

    private applySettings() {
        const styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptRectangle)
        const defaultStyles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptRectangle)

        if (!styles && !defaultStyles) {
            throw new StrangeError('Default styles for Rectangle are not found')
        }

        const source = styles || defaultStyles

        this.fLineStyle = TLineStyle.fromSerialized(source.lineStyle)
        this.brush = IGPSolidBrush.fromSerialized(source.fillingStyle)
        this.fShouldFillInside = source.fShouldFillInside

        this.extendsRay.left = source.extendLeft
        this.extendsRay.right = source.extendRight
        this.drawMiddleLine = source.drawMiddleLine ?? defaultStyles.drawMiddleLine
        this.middleLineStyle = TLineStyle.fromSerialized(source.middleLineStyle ?? defaultStyles.middleLineStyle)
    }

    clone(): TPtRectangle {
        const cloneObj = new TPtRectangle(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public assign(tool: TPtRectangle, isCopy = false): void {
        super.assign(tool, isCopy)
        this.drawMiddleLine = tool.drawMiddleLine
        this.middleLineStyle = tool.middleLineStyle.clone()
    }

    public toJson(): RectangleJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            brushForText: {
                color: this.brushForText.getColor(),
                opacity: this.brushForText.getOpacity(),
                style: this.brushForText.getStyle()
            },
            drawMiddleLine: this.drawMiddleLine,
            middleLineStyle: {
                color: this.middleLineStyle.color,
                width: this.middleLineStyle.width,
                style: this.middleLineStyle.style
            }
        }
    }

    public fromJSON(json: RectangleJSON): void {
        super.fromJSON(json)
        this.brushForText.setColor(json.brushForText.color)
        this.brushForText.setOpacity(json.brushForText.opacity)
        this.brushForText.setStyle(json.brushForText.style)
        if (json.drawMiddleLine) {
            this.drawMiddleLine = json.drawMiddleLine
        } else {
            this.drawMiddleLine = false
        }
        if (json.middleLineStyle) {
            this.middleLineStyle.color = json.middleLineStyle.color
            this.middleLineStyle.width = json.middleLineStyle.width
            this.middleLineStyle.style = json.middleLineStyle.style
        } else {
            this.middleLineStyle = this.fLineStyle.clone()
        }
    }

    ExportToDialog(): void {
        super.ExportToDialog()
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            middleLineStyle: {
                key: 'middleLineStyle',
                value: this.middleLineStyle,
                label: 'toolsModal.fields.middleLine',
                type: 'style',
                isOptional: true,
                isActive: this.drawMiddleLine
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    isActive: this.fShouldFillInside,
                    opacity: this.brush.getOpacity()
                },
                label: 'background',
                withOpacity: true,
                type: 'fillColor',
                isOptional: true
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.extendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.extendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptRectangle, toolName: 'rectangle' })
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { toolSettings, getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()

        const { description, lineStyle, fillInsideColor, extendLeft, extendRight, middleLineStyle } =
            formattedToolSettings

        this.description = description
        this.fLineStyle = lineStyle.clone()
        this.fPoints[1].price = this.fPoints[0].price
        this.fPoints[1].time = this.fPoints[2].time
        this.fPoints[3].price = this.fPoints[2].price
        this.fPoints[3].time = this.fPoints[0].time

        this.extendsRay.left = extendLeft
        this.extendsRay.right = extendRight

        this.middleLineStyle = middleLineStyle.clone()
        this.drawMiddleLine = toolSettings?.middleLineStyle?.isActive ?? this.drawMiddleLine

        this.AdjustRectangle()
        this.fShouldFillInside = fillInsideColor.isActive
        // Set color and opacity
        this.brush.setColor(fillInsideColor.color)
        this.brush.setOpacity(fillInsideColor.opacity)

        // this.pen.setPenStyle(this.fLineStyle.style)
        // this.pen.setWidth(this.fLineStyle.width)
        // this.pen.setColor(this.fLineStyle.color)
        // this.pen.opacity = ColorHelperFunctions.GetOpacity(this.fLineStyle.color)

        resetToolSettings()
        this.saveToManager()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptRectangle, {
            toolName: PaintToolNames.ptRectangle,
            lineStyle: this.fLineStyle.getSerialized(),
            fillingStyle: this.brush.getSerialized(),
            fShouldFillInside: this.fShouldFillInside,
            extendLeft: this.extendsRay.left,
            extendRight: this.extendsRay.right,
            middleLineStyle: this.middleLineStyle.getSerialized(),
            drawMiddleLine: this.drawMiddleLine
        })
    }

    // if needed to fill inside the rectangle with a color
    protected FillInside(): void {
        const gdiCanvas = this.fChart.GdiCanvas

        // Check if the rectangle is completed, has less than 2 points, or if fill inside is not set
        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.Count < 2 || !this.fShouldFillInside) {
            super.FillInside() // Call the parent method if the conditions are not met
        } else {
            // Ensure that GdiCanvas is available on the fChart object
            if (!gdiCanvas) {
                throw new StrangeError('GdiCanvas is not available on the fChart object.')
            }

            gdiCanvas.ClearPath()

            // GetPoints() is assumed to return an array of TPointsArray, which is the correct type
            const arr: TPointsArray = this.getPoints()
            if (arr.length === 0) {
                throw new StrangeError('The points array is empty.')
            }

            // Move to the starting point of the rectangle
            gdiCanvas.MoveTo(arr[0].x, arr[0].y)
            // Loop through the points array and create a path
            for (let i = 1; i < 4; i++) {
                gdiCanvas.LineToPath(arr[i].x, arr[i].y)
            }

            // Close the path by connecting the last point to the first
            gdiCanvas.LineToPath(arr[0].x, arr[0].y)

            // Set the brush for filling and fill the path
            gdiCanvas.FillPath(this.brush)
        }
        if (this.fPoints.length > 2) {
            this.PaintExtendsRay()
        }
    }

    Paint(): void {
        super.Paint()
        this.PaintMarkers(true, [4, 5, 6, 7])
        if (this.fText) {
            const rectWidth = Math.abs(this.fPoints[0].x - this.fPoints[2].x)

            const textWidth = this.font.getTextWidthByContext(this.fText, this.fChart.GdiCanvas.graphics.Context)
            if ((textWidth / rectWidth) * 100 < 250) {
                this.fChart.GdiCanvas.textOutByTmkFontStyle(
                    this.fPoints[0].x,
                    this.fPoints[0].y,
                    this.fText,
                    this.fFontStyle,
                    true
                )
            }
        }
    }

    protected PaintLines(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y),
                new TPoint(this.fPoints[2].x, this.fPoints[2].y),
                new TPoint(this.fPoints[3].x, this.fPoints[3].y)
            ]
            this.PaintHoverLine(points)
        }
        const gdiCanvas = this.fChart.GdiCanvas
        if (this.drawMiddleLine && this.fPoints.length === 2) {
            const middleY = (this.fPoints[0].y + this.fPoints[1].y) / 2
            gdiCanvas.MoveTo(this.fPoints[0].x, middleY)
            gdiCanvas.LineTo(this.fPoints[1].x, middleY, this.middleLineStyle.getPen())
        }
        if (this.drawMiddleLine && this.fPoints.length > 4) {
            gdiCanvas.MoveTo(this.fPoints[5].x, this.fPoints[5].y)
            gdiCanvas.LineTo(this.fPoints[7].x, this.fPoints[7].y, this.middleLineStyle.getPen())
        }
        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.length < 2) {
            if (this.isNeedDrawBorder) {
                super.PaintLines()
            }
        } else {
            const arr: TPointsArray = this.getPoints()
            gdiCanvas.MoveTo(arr[0].x, arr[0].y)
            for (let i = 1; i <= 3; i++) {
                gdiCanvas.LineTo(arr[i].x, arr[i].y, this.fLineStyle.getPen())
            }
            gdiCanvas.LineTo(arr[0].x, arr[0].y, this.fLineStyle.getPen())
            this.PaintText(gdiCanvas)
        }
    }

    private PaintText(canvas: TGdiPlusCanvas): void {
        // Obtain the coordinates rectangle for the current points
        const R: TCoordsRect = this.GetCoordsRect()

        // Convert the rectangle's date/time values to chart coordinates
        const x1: number = this.chart.GetXFromDate(R.x1)
        const x2: number = this.chart.GetXFromDate(R.x2)
        const y2: number = this.chart.GetY(R.y2)

        // Calculate the indexes on the chart for the x coordinates
        const index1: number = this.chart.GetIndexFromX(x1)
        const index2: number = this.chart.GetIndexFromX(x2)

        // Calculate the width and height in chart units
        const w: number = Math.abs(index2 - index1)
        const h: number = Math.abs(R.y1 - R.y2)

        // Format the height value to a string with the appropriate number of decimal places
        const s: string = (h * 10 ** this.chart.ScaleDecimals()).toFixed(0)

        // Draw the text showing the width and height next to the rectangle
        canvas.textOut(x2 + 3, y2 + 3, `${w} / ${s}`, this.font, this.brushForText, true)
    }

    public PointToScreen(pointIndex: number): TPoint {
        const result = new TPoint(-1, -1)

        if (!DateUtils.IsEmpty(this.fPoints[pointIndex].time)) {
            result.x = this.chart.GetPreciseXFromDate(this.fPoints[pointIndex].time)
            result.y = this.chart.GetY(this.fPoints[pointIndex].price)
        }

        return result
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptRectangle, TPtRectangle)
