import { makeAutoObservable } from 'mobx'
import { initialData } from './constants'
import { SymbolComparisonModalDataType, UpdaterType } from './types'

class LongTermTestsStore {
    data: SymbolComparisonModalDataType

    constructor() {
        makeAutoObservable(this)

        this.data = initialData
        this.updateData = this.updateData.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.updateData(initialData)
    }
}

export default new LongTermTestsStore()
