import { t } from 'i18next'
import { TIndexBuffer } from '@fto/lib/extension_modules/indicators/api/IIndicatorApi'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TDrawStyle } from '../../common/CommonExternalInterface'
import { TOutputWindow } from '../IndicatorTypes'
import { IndicatorImplementation } from '../api/IndicatorImplementation'

export default class InsideBar extends IndicatorImplementation {
    InsideBuffer!: TIndexBuffer

    Init() {
        // this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.insideBar'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)

        this.api.AddSeparator('Common')

        this.InsideBuffer = this.api.CreateIndexBuffer()
        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.InsideBuffer)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Symbol, TPenStyle.psSolid, 10, DelphiColors.clRed)
        this.api.SetIndexSymbol(0, 242, 0, 10)
        this.api.SetIndexLabel(0, t('indicatorModal.insideBar.fields.inside'))
    }

    Calculate(index: number) {
        this.InsideBuffer.setValue(index, 0)

        if (this.api.Low(index) >= this.api.Low(index + 1) && this.api.High(index) <= this.api.High(index + 1)) {
            this.InsideBuffer.setValue(index, this.api.High(index))
        }
    }
}
