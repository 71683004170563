import { FC, useMemo } from 'react'
import TextBlock from './components/TextBlock'

type Props = {
    tools: any[]
}

const TextBlockWrapper: FC<Props> = ({ tools }) => {
    const toolsListWithText = useMemo(() => tools.filter((tool) => tool.hasText), [tools])

    if (toolsListWithText.length === 0) {
        return null
    }

    return <TextBlock tools={toolsListWithText} />
}

export default TextBlockWrapper
