export class OffsetLevel {
    private map: Map<number, number>

    constructor() {
        this.map = new Map<number, number>()
    }

    set(offset: number, value: number): void {
        if (!this.map.has(offset)) {
            this.map.set(offset, value)
        } else {
            this.map.set(offset, value)
        }
    }

    get(offset: number): number | undefined {
        return this.map.get(offset)?.valueOf()
    }

    has(offset: number): boolean {
        return this.map.has(offset) ?? false
    }
}
