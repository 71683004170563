import { TChartOptions } from '@fto/lib/charting/ChartBasicClasses'
import { TColorScheme } from '@fto/lib/charting/auxiliary_classes_charting/TColorScheme'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'
import { OptionType as DropdownOption } from '@fto/ui/lib/components/SimpleDropdown'

type StringPropertyNames<T> = {
    [K in keyof T]: T[K] extends string ? K : never
}[keyof T]
export type StringProperties<T> = Pick<T, StringPropertyNames<T>>

type NumberPropertyNames<T> = {
    [K in keyof T]: T[K] extends number ? K : never
}[keyof T]
export type NumberProperties<T> = Pick<T, NumberPropertyNames<T>>

type BooleanPropertyNames<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
}[keyof T]
export type BooleanProperties<T> = Pick<T, BooleanPropertyNames<T>>

type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never
}[keyof T]
export type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>

export type OptionsType = keyof TChartOptions | keyof TOptions
export type Option = { [key: string]: any }

export interface SettingsTabRef {
    loadOptionsSetting: (data: any, shouldUpdateConfig: boolean) => any
}
export interface OptionSettingRef {
    name: string
    loadOptionSetting: (data: any, shouldUpdateConfig: boolean) => any
}

export enum ConfigType {
    ColorScheme = 'ColorScheme',
    ChartOptions = 'ChartOptions',
    GlobalOptions = 'GlobalOptions'
}
export const ConfigTypes = {
    [ConfigType.ColorScheme]: TColorScheme,
    [ConfigType.ChartOptions]: TChartOptions,
    [ConfigType.GlobalOptions]: TOptions
}

export type SliderSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type InputSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    maxValue?: number
    minValue?: number
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type DropdownSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    initialData: Partial<TOptions & TChartOptions>
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type ColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type CheckboxSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
    setChildsDisabled: (state: boolean) => void
}

export type CheckboxAndColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
    setChildsDisabled: (state: boolean) => void
}

export type Tabs = 'canvas' | 'views' | 'general'

type OnDifferentSettingsUpdateType = (tab: string, newValue: any, prevValue: any, key: string) => void

export type CanvasSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type TradingSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type ViewsSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type GeneralSettingsProps = {
    chart: TChartWindow
    globalOptions: TOptions
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type OptionSetting = {
    key: string
    type: string
    name: string
    subOptions?: { [key: string]: AvailableOptionsSettings }
}

export type SliderSetting = {
    type: 'sliderSetting'
    valueKey: string
    valueConfigType: ConfigType
    maxValue: number
    divisionFactor: number
    withInputField: boolean
} & OptionSetting

export type ColorSetting = {
    type: 'colorSetting'
    colorKey: string
    colorConfigType: ConfigType
} & OptionSetting

export type CheckboxSetting = {
    type: 'checkboxSetting'
    booleanKey: string
    booleanConfigType: ConfigType
} & OptionSetting

export type CheckboxAndColorSetting = {
    type: 'checkboxAndColorSetting'
    colorKey: string
    colorConfigType: ConfigType
    booleanKey: string
    booleanConfigType: ConfigType
} & OptionSetting

export type InputSetting = {
    type: 'inputSetting'
    valueKey: string
    valueConfigType: ConfigType
} & OptionSetting

export type DropdownSetting = {
    type: 'dropdownSetting'
    valueKey: string
    valueKeyMethod?: string
    valueConfigType: ConfigType
    options: DropdownOption[]
} & OptionSetting

export type AvailableOptionsSettings =
    | SliderSetting
    | ColorSetting
    | CheckboxSetting
    | CheckboxAndColorSetting
    | InputSetting
    | DropdownSetting
