import { ExtPriceLabelJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { DelphiColors } from '../../../delphi_compatibility/DelphiBasicTypes'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import { NotImplementedError } from '../../../utils/common_utils'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import {
    FillColorParamsType,
    FontStylesType
} from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { IChart } from '../../chart_classes/IChart'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TPoint, TRect } from '../../../extension_modules/common/CommonExternalInterface'

export class TPtExtPriceLabel extends TBasicPaintTool {
    private fLabelFontStyle!: TMkFontStyle
    private fillBackgroundBrush!: IGPSolidBrush

    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptExtPriceLabel)
        this.ShortName = 'Price Label'
        this.fToolType = TPaintToolType.tt_Text
        this.fMaxPoints = 2
        this.fClosedPolygon = true
        this.fShouldFillInside = true
        this.hasText = true
        this.CursorStyle = CustomCursorPointers.crCross
        this.icon = 87
        this.fFontStyle.color = this.fLineStyle.color
        this.fFontStyle.size = 16
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.brush.setColor(this.fFontStyle.color)
        this.fillBackgroundBrush = new IGPSolidBrush(DelphiColors.clWhite, 0.25)

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptExtPriceLabel)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptExtPriceLabel)
        }

        if (!styles) throw new StrangeError('Default styles for ExtPriceLabel are not found')

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            styles.text.size,
            StylingHelper.getTGpFontStyle({
                style: styles.text.style,
                weight: styles.text.weight
            })
        )
        this.brush.setColor(styles.text.color)
        this.fLineStyle = TLineStyle.fromSerialized(styles.line)
        this.fShouldFillInside = styles.fShouldFillInside
        this.fillBackgroundBrush = IGPSolidBrush.fromSerialized(styles.fill)
    }

    clone(): TPtExtPriceLabel {
        const cloneObj = new TPtExtPriceLabel(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fillBackgroundBrush = this.fillBackgroundBrush.clone()
        return cloneObj
    }

    public toJson(): ExtPriceLabelJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            ToolType: this.fToolType,
            MaxPoints: this.fMaxPoints,
            ClosedPolygon: this.fClosedPolygon,
            CursorStyle: this.CursorStyle,
            icon: this.icon,
            ShortName: this.ShortName,
            ShouldFillInside: this.fShouldFillInside,
            LabelFont: this.font.toString(),
            LabelBrush: {
                color: this.brush.getColor(),
                style: this.brush.getStyle(),
                opacity: this.brush.getOpacity()
            },
            LineStyle: {
                color: this.fLineStyle.color,
                style: this.fLineStyle.style,
                width: this.fLineStyle.width
            },
            FillBackgroundBrush: {
                color: this.fillBackgroundBrush.getColor(),
                style: this.fillBackgroundBrush.getStyle(),
                opacity: this.fillBackgroundBrush.getOpacity()
            }
        }
    }

    fromJSON(json: ExtPriceLabelJSON) {
        super.fromJSON(json)
        this.font = IGPFont.fromString(json.font)
        this.brush = new IGPSolidBrush(json.LabelBrush.color, json.LabelBrush.opacity, json.LabelBrush.style)
        this.fLineStyle = new TLineStyle(json.LineStyle.color, json.LineStyle.style, json.LineStyle.width)
        this.fToolType = json.ToolType
        this.fMaxPoints = json.MaxPoints
        this.fClosedPolygon = json.ClosedPolygon
        this.CursorStyle = json.CursorStyle
        this.icon = json.icon
        this.ShortName = json.ShortName
        this.fShouldFillInside = json.ShouldFillInside
        this.fillBackgroundBrush = new IGPSolidBrush(
            json.FillBackgroundBrush.color,
            json.FillBackgroundBrush.opacity,
            json.FillBackgroundBrush.style
        )
    }

    public EdgeUnderMouse(x: number, y: number): number {
        let result: number = super.EdgeUnderMouse(x, y)

        // If the base class did not find an edge under the mouse, check if the point is within the text rectangle
        if (result === -1) {
            if (this.GetFrameRect().PtInRect(new TPoint(x, y))) {
                result = 0
            }
        }

        return result
    }

    public Paint(): void {
        super.Paint()

        if (this.fPoints.Count < 2) {
            return
        }
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }

        this.PaintLineWithText(this.fPoints[0], this.fPoints[1], this.fText)

        const canvas = this.chart.GdiCanvas
        const lineStylesPen = this.fLineStyle.getPen()
        const R = this.GetFrameRect()

        const text = StrsConv.StrDouble(this.fPoints[0].price, this.chart.ScaleDecimals())

        if (this.fShouldFillInside) {
            canvas.FillRect(R, this.fillBackgroundBrush)
        }

        const textWidth = canvas.TextWidth(text, this.font)
        const textHeight = canvas.TextHeight('0', this.font)

        canvas.textOut(
            R.Left + (R.Right - R.Left - textWidth) / 2,
            R.Bottom - (R.Bottom - R.Top - textHeight) / 2,
            text,
            this.font,
            this.brush,
            true
        )
        canvas.rectangle(R, lineStylesPen)

        canvas.MoveTo(this.fPoints[1].x, this.fPoints[1].y)
        if (this.fPoints[0].x < this.fPoints[1].x) {
            canvas.LineTo(R.Left, this.fPoints[1].y, lineStylesPen)
        } else {
            canvas.LineTo(R.Right, this.fPoints[1].y, lineStylesPen)
        }

        this.PaintMarkers()
    }

    ExportToDialog(): void {
        super.ExportToDialog()
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const fontParams = this.font.getFontParams()
        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)
        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            textStyle: {
                key: 'textStyle',
                type: 'textStyle',
                label: 'toolsModal.fields.textStyle',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    size: fontParams.size,
                    style: fontStyleParams.style,
                    weight: fontStyleParams.weight
                }
            },
            lineStyle: {
                key: 'lineStyle',
                label: 'toolsModal.fields.line',
                value: this.fLineStyle,
                type: 'style'
            },
            fillBackground: {
                key: 'fillBackground',
                label: 'background',
                type: 'fillColor',
                isOptional: true,
                withOpacity: true,
                value: {
                    isActive: this.fShouldFillInside,
                    color: this.fillBackgroundBrush.getColor(),
                    opacity: this.fillBackgroundBrush.getOpacity()
                }
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptExtPriceLabel, toolName: 'priceLabel' })
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings } = GraphToolStore

        const formattedToolSettings = getKeyValueData()

        this.description = formattedToolSettings.description
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            formattedToolSettings.textStyle.size,
            StylingHelper.getTGpFontStyle({
                style: formattedToolSettings.textStyle.style,
                weight: formattedToolSettings.textStyle.weight
            })
        )
        this.brush.setColor(formattedToolSettings.textStyle.color)
        this.fLineStyle = formattedToolSettings.lineStyle

        this.fShouldFillInside = formattedToolSettings.fillBackground.isActive
        this.fillBackgroundBrush.setColor(formattedToolSettings.fillBackground.color)
        this.fillBackgroundBrush.setOpacity(formattedToolSettings.fillBackground.opacity)

        this.saveToManager()
        resetToolSettings()
    }
    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptExtPriceLabel, {
            toolName: PaintToolNames.ptExtPriceLabel,
            fill: this.fillBackgroundBrush.getSerialized(),
            line: this.fLineStyle.getSerialized(),
            fShouldFillInside: this.fShouldFillInside,
            text: {
                size: this.font.getFontParams().size,
                color: this.brush.getColor(),
                weight: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).weight,
                style: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).style
            }
        })
    }

    getFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: this.fillBackgroundBrush.getColor(),
                hasDifferentValues: false
            },
            opacity: this.fillBackgroundBrush.getOpacity(),
            shouldFillInside: this.fShouldFillInside
        }
    }

    setFillColorParams(color: string, opacity: number) {
        this.chart.ChartWindow.saveStateWithNotify()

        this.fillBackgroundBrush.setColor(color)
        this.fillBackgroundBrush.setOpacity(opacity)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    getFontStyles(): FontStylesType {
        return {
            color: {
                value: this.brush.getColor(),
                hasDifferentValues: false
            },
            fontSize: this.font.getFontParams().size
        }
    }

    override setFontStyles(color: string, fontSize: number) {
        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            fontSize,
            StylingHelper.getTGpFontStyle({
                style: fontStyleParams.style,
                weight: fontStyleParams.weight
            })
        )
        this.fFontStyle.color = color
        this.fFontStyle.size = fontSize
        this.brush.setColor(color)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    assign(tool: TPtExtPriceLabel, isCopy: boolean = false): void {
        super.assign(tool, isCopy)
        this.fillBackgroundBrush = tool.fillBackgroundBrush
        this.font = tool.font
    }

    public SaveToList(list: TOffsStringList, all: boolean = true): void {
        throw new NotImplementedError()
    }

    public GetFrameRect(): TRect {
        const dpr = window.devicePixelRatio
        // get base x, y coordinates
        const x = this.fPoints[1].x
        const y = this.fPoints[1].y

        // Assuming StrDouble is a method that formats the price with the chart's scale decimals
        const text = StrsConv.StrDouble(this.fPoints[0].price, this.chart.ScaleDecimals())

        // get text width/height
        const w = this.chart.GdiCanvas.TextWidth(text, this.font, true)
        const h = this.chart.GdiCanvas.TextHeight('0', this.font, true)

        const frameMargin = 10 * window.devicePixelRatio

        let result: TRect
        if (this.fPoints[0].x < this.fPoints[1].x) {
            // If the first point is to the left of the second point, position the text to the right.
            result = new TRect(x + frameMargin, y - h / 2, x + frameMargin + w, y + h / 2)
        } else {
            // If the first point is to the right of the second point, position the text to the left.
            result = new TRect(x - frameMargin - w, y - h / 2, x - frameMargin, y + h / 2)
        }

        // Inflate the rectangle slightly for visual padding
        result.Inflate(2 * dpr, 2 * dpr)

        return result
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptExtPriceLabel, TPtExtPriceLabel)
