export class IndicatorsNameMappings {
    public static readonly mappings: { [key: string]: string } = {
        ishimoku: 'Ichimoku',
        'heiken ashi': 'Heikin Ashi'
    }

    public static getMappedName(name: string): string | undefined {
        return IndicatorsNameMappings.mappings[name]
    }
}
