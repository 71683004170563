import { TakeprofitValues, MarketValues, OrderValues } from '../OrderWndStructs'

export abstract class TakeprofitCalculationStrategy {
    abstract onChangeTakeprofitPrice(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract onChangeTakeprofitPoints(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract onChangeTakeprofitInUsd(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract onChangeTakeprofitInPercent(
        takeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null

    abstract recalculateTakeprofitValuesByPoints(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract recalculateTakeprofitValuesByPrice(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract recalculateTakeprofitValuesByUsd(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
    abstract recalculateTakeprofitValuesByPercent(
        currTakeprofitValues: TakeprofitValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): TakeprofitValues | null
}
