import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { showSuccessToast } from '@root/utils/toasts'
import { t } from 'i18next'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class PartialCloseLevel extends OrderLevel {
    public tempPrice = 0

    public draw(canvas: TGdiPlusCanvas): void {
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected

        const ctx = canvas.graphics.Context
        const { Top } = this.getLocation()
        const baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.PartialCloseColor : this.invalidColor
        const labelText = 'PC'
        let valueText = Number(this.owner.getProfitToPartialClose().toFixed(2))
        let pointsText = `${formatCurrency(this.owner.tpos.pendingPartialClosePoints, '', 0)} points`

        if (this.IsCaptured()) {
            const tempPoints = this.owner.getPointsByPrice(this.tempPrice)

            pointsText = `${formatCurrency(tempPoints, '', 0)} points`

            valueText = Number(this.owner.getProfitToPartialClose(tempPoints).toFixed(2))
        }

        const dpr = window.devicePixelRatio || 1

        const ticketText = `#${this.owner.tpos.ticket}`
        const fontSize = 12 * dpr
        const font = `${fontSize}px Roboto Flex`
        const boxHeight = 16 * dpr

        this.drawCommonElements(
            canvas,
            this.getXCoordinateToDrawLevel(ctx),
            Top,
            labelText,
            formatCurrency(Number(valueText)),
            pointsText,
            ticketText,
            isActive,
            baseColor,
            boxHeight,
            font,
            true,
            true,
            this.owner.tpos.pendingPartialCloseLotValue
        )
    }

    public recalculateLocationByY(price: number): void {
        super.recalculateLocationByY(price)

        this.tempPrice = price
    }

    public close(): void {
        this.owner.resetPartialClose()
    }

    public onMouseUp(event: MouseEvent, sender: TMainChart): void {
        const dpr = window.devicePixelRatio || 1
        const x = event.offsetX * dpr
        const y = event.offsetY * dpr
        const isCurrentControl = this.isMouseInsideInfoBox(new TPoint(x, y)) || this.isPointInside(x, y)
        const currentPartialClosePrice = this.owner.tpos.pendingPartialClosePrice

        this.owner.onPartialClosePriceChange(this.tempPrice)
        this.recalculateLocationByY(this.tempPrice)

        super.onMouseUp(event, sender)

        if (this.tempPrice && this.IsCaptured()) {
            if (isCurrentControl && currentPartialClosePrice !== this.tempPrice) {
                const decimals = this.owner.symbol?.symbolInfo.decimals
                showSuccessToast({
                    title: t('processingCore.toasts.partialClosePending', {
                        lot: this.owner.tpos.pendingPartialCloseLotValue.toFixed(2),
                        price: this.tempPrice.toFixed(decimals || 5)
                    })
                })
            }
        }

        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }

    public modify() {
        // TODO: need to think about what should be there
    }
}
