import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@fto/icons'
import { Button, Flex, InputField, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

import styles from './index.module.scss'
import { showSuccessToast } from '@root/utils/toasts'

export const RateUsModal: FC = () => {
    const { t } = useTranslation()

    const [comment, setComment] = useState('')
    const [score, setScore] = useState(0)
    const [hover, setHover] = useState(0)

    const rate = useCallback(() => {
        const params =
            comment.length > 255
                ? { score, comment: comment.slice(0, 255), commentPartTwo: comment.slice(255) }
                : { score, comment }

        fireMixpanelEvent('user_app_rated', params)

        removeModal(MODAL_NAMES.chart.rateUsModal)
        showSuccessToast({
            title: t('rateUsModal.toasts.success'),
            message: t('rateUsModal.toasts.successText')
        })
    }, [score, comment])

    const remove = useCallback(() => {
        removeModal(MODAL_NAMES.chart.rateUsModal)
    }, [])

    const handleCommentChange = useCallback((e: string) => {
        if (e.length > 500) return
        setComment(e)
    }, [])

    return (
        <Modal size='xs' name={MODAL_NAMES.chart.rateUsModal} withClickOutside={false}>
            <Modal.Content>
                <Flex direction='column' gap={12}>
                    <Flex justifyContent='center'>
                        <Typography type='text-semibold'>{t('rateUsModal.rate')}</Typography>
                    </Flex>
                    <Flex justifyContent='center' gap={8} onMouseLeave={() => setHover(score)}>
                        {[...Array(5)].map((star, index) => {
                            index += 1
                            const isActive = index <= (hover || score)
                            return (
                                <IconButton
                                    name={isActive ? 'star' : 'star-empty'}
                                    key={index}
                                    color={isActive ? 'var(--color-orange-400)' : 'var(--color-gray-1000)'}
                                    className={styles.star}
                                    onClick={() => setScore(index)}
                                    onMouseEnter={() => setHover(index)}
                                />
                            )
                        })}
                    </Flex>
                    <Flex direction='column'>
                        <Flex alignItems='center' gap={4}>
                            <Typography type='interface-semibold'>{t('rateUsModal.share')}</Typography>
                            <Typography color='gray-600'>{t('rateUsModal.optional')}</Typography>
                        </Flex>
                        <textarea
                            value={comment}
                            onChange={(e) => handleCommentChange(e.target.value)}
                            className={styles.textarea}
                        />
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls>
                <Flex gap={8} block>
                    <Button type='secondary' label={t('global.cancel')} block onClick={remove} />
                    <Button type='primary' label={t('rateUsModal.send')} block disabled={score === 0} onClick={rate} />
                </Flex>
            </Modal.Controls>
        </Modal>
    )
}
