import { FC, useCallback } from 'react'
import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'

import { RibbonOptionType } from '@fto/chart_components/ProjectInterface/components/Ribbon/components/types'
import { FavoriteIndicatorListType } from '@fto/lib/store/indicatorsStore/types'

import OptionRow from '@fto/chart_components/ProjectInterface/components/Ribbon/components/Dropdowns/components/IndicatorDropdown/components/ContentRenderer/components/InnerDropdown/components/InnerDropdownMenu/components/OptionRow'

import styles from './index.module.scss'

type Props = {
    options: RibbonOptionType[]
    close: () => void
    name: string
    favoriteIndicators: FavoriteIndicatorListType
    toggleFavorite: (value: string) => void
    isScrollable?: boolean
}

const IndicatorInnerDropdownMenu: FC<Props> = ({
    options,
    close,
    name,
    favoriteIndicators,
    toggleFavorite,
    isScrollable = false
}) => {
    const handleAction = useCallback(
        (action: (() => void) | null) => {
            action?.()
            close()
        },
        [close]
    )

    return (
        <Flex direction='column' className={cx(styles.dropdown, { [styles.Scrollable]: isScrollable })}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            <Flex direction='column' className={cx({ [styles.Scrollable]: isScrollable })}>
                {options.map((option) => {
                    const isPreselected = favoriteIndicators.includes(option.value)

                    return (
                        <OptionRow
                            option={option}
                            toggleFavorite={toggleFavorite}
                            isPreselected={isPreselected}
                            handleAction={handleAction}
                            key={option.value}
                        />
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default IndicatorInnerDropdownMenu
