import { Icon } from '@fto/icons'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { Flex, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const RestartModal: FC = () => {
    const { t } = useTranslation()

    const restart = useCallback(() => {
        EducationProcessor.Instance.restartHomework()
        removeModal(MODAL_NAMES.chart.education.restart)
    }, [])

    return (
        <Modal size='xs' name={MODAL_NAMES.chart.education.restart}>
            <Modal.Content>
                <Flex alignItems='center' direction='column' gap={16}>
                    <Icon size={56} name='restart-colored' />
                    <Flex alignItems='center' direction='column' gap={8}>
                        <Typography type='h5-semibold'>{t('educationModals.restart.header')}</Typography>
                        <Typography type='interface-regular'>{t('educationModals.restart.text')}</Typography>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls onSubmit={restart} submitText={t('educationModals.restart.submitText')} />
        </Modal>
    )
}
