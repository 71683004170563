/* eslint-disable unicorn/throw-new-error */
import { IErrorHandlingProcRec } from '../CommonProcRecInterfaces/IErrorHandlingProcRec'
import { EErrorHandlingMode } from '../CommonTypes'
import { VeryBasicProcRecImplementation } from './VeryBasicProcRecImplementation'

export class ErrorHandlingProcRecImplementation extends VeryBasicProcRecImplementation {
    private lastError = ''
    public errorHandlingMode = EErrorHandlingMode.ehm_ThrowError

    public GetImplementation(): IErrorHandlingProcRec {
        return {
            GetLastError: this.GetLastError.bind(this),
            SetLastError: this.SetLastError.bind(this),
            ResetLastError: this.ResetLastError.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_ErrorHandling_${super.generateDName()}`
    }

    public SetLastErrorBasedOnException(error: unknown): void {
        if (error instanceof Error) {
            this.SetLastError(error.message)
        } else {
            this.SetLastError(String(error))
        }
    }

    public SetLastError(error: string): void {
        this.lastError = error
    }

    public GetLastError(): string {
        return this.lastError
    }

    public ResetLastError(): void {
        this.lastError = ''
    }
}
