import { TDateTime } from '../delphi_compatibility/DateUtils'
import { TPoint } from '../extension_modules/common/CommonExternalInterface'
import { ColorHelperFunctions } from '../drawing_interface/ColorHelperFunctions'
import { TChartStyle } from './auxiliary_classes_charting/ChartingEnums'
import { OneClickTradingMode } from '@fto/chart_components/OneClickTrading'
import { ColorSchemes, TColorScheme } from './auxiliary_classes_charting/TColorScheme'

export enum TCursorMode {
    cm_Default,
    cm_CrossHair,
    cm_CrossHairRooler
}

export interface TChartScale {
    BarWidth2: number // Half of the bar width
    PixBetweenBars: number // distance between centers of 2 bars
}

const NumOfColorSchemes = 1

export class TChartOptions {
    HorzMagnifier: number // scale of graph (1, 2, 4, 8, 16)
    MinHorzMagnifier: number
    MaxHorzMagnifier: number
    ColorScheme!: TColorScheme // Replace with actual type
    FixedGrid: boolean
    GridSize: number
    FixedScale: boolean
    FixedScaleBaseLevel: number
    VerticalScale: number
    FixedVScale: number
    Timeframe: number
    ChartStyle: TChartStyle
    ShowGrid: boolean
    AutoScroll: boolean
    RightOffset: boolean
    OffsetPercentage: number
    ShowVolume: boolean
    ShowPeriodSeparators: boolean
    ChartOnForeground: boolean
    ShowBidLevel: boolean
    ShowAskLevel: boolean
    ShowIndicatorValues: boolean
    ShowBalance!: boolean
    ShowMargin!: boolean
    ShowDrawdown!: boolean
    ProfitChartOldStyle: boolean
    ShowNotes: boolean
    ShowNews: boolean
    ShowHistory: boolean
    Version: number
    OneClickTradingMode: number
    MatchPriceWithBarsColor: boolean
    CustomPriceGoesUp: boolean
    CustomPriceGoesDown: boolean

    constructor() {
        this.HorzMagnifier = 12
        this.MinHorzMagnifier = 1
        this.MaxHorzMagnifier = 500
        this.SetColorScheme(0)
        this.FixedGrid = false
        this.GridSize = 20

        //scale block
        this.VerticalScale = 1
        this.FixedScale = false
        this.FixedScaleBaseLevel = 0
        this.FixedVScale = 1

        this.Timeframe = 15
        this.ChartStyle = TChartStyle.cs_Bar
        this.ShowGrid = true
        this.AutoScroll = true // TODO: Verify this is the correct default value check it when we will have last testing date
        this.RightOffset = true
        this.OffsetPercentage = 0.2
        this.ShowVolume = true
        this.ShowPeriodSeparators = true
        this.ChartOnForeground = false
        this.ShowBidLevel = true
        this.ShowAskLevel = false
        this.ShowIndicatorValues = true
        this.ProfitChartOldStyle = false
        this.ShowNotes = true
        this.ShowNews = true
        this.ShowHistory = true
        this.Version = 1
        this.OneClickTradingMode = OneClickTradingMode.FULL
        this.MatchPriceWithBarsColor = false
        this.CustomPriceGoesUp = false
        this.CustomPriceGoesDown = false
    }

    public getDefault(): TChartOptions {
        return new TChartOptions()
    }

    public reset() {
        const defaultOptions = this.getDefault()
        this.copyFrom(defaultOptions)
    }

    public copyFrom(other: TChartOptions) {
        this.HorzMagnifier = other.HorzMagnifier
        this.MinHorzMagnifier = other.MinHorzMagnifier
        this.MaxHorzMagnifier = other.MaxHorzMagnifier
        this.ColorScheme = other.ColorScheme.Clone()
        this.FixedGrid = other.FixedGrid
        this.GridSize = other.GridSize
        this.VerticalScale = other.VerticalScale
        this.FixedScale = other.FixedScale
        this.FixedScaleBaseLevel = other.FixedScaleBaseLevel
        this.FixedVScale = other.FixedVScale
        this.Timeframe = other.Timeframe
        this.ChartStyle = other.ChartStyle
        this.ShowGrid = other.ShowGrid
        this.AutoScroll = other.AutoScroll
        this.RightOffset = other.RightOffset
        this.OffsetPercentage = other.OffsetPercentage
        this.ShowVolume = other.ShowVolume
        this.ShowPeriodSeparators = other.ShowPeriodSeparators
        this.ChartOnForeground = other.ChartOnForeground
        this.ShowBidLevel = other.ShowBidLevel
        this.ShowAskLevel = other.ShowAskLevel
        this.ShowIndicatorValues = other.ShowIndicatorValues
        this.ProfitChartOldStyle = other.ProfitChartOldStyle
        this.ShowNotes = other.ShowNotes
        this.ShowNews = other.ShowNews
        this.MatchPriceWithBarsColor = other.MatchPriceWithBarsColor
        this.CustomPriceGoesUp = other.CustomPriceGoesUp
        this.CustomPriceGoesDown = other.CustomPriceGoesDown
    }

    public Clone(): TChartOptions {
        const cloneObj = this.getDefault()
        cloneObj.copyFrom(this)
        return cloneObj
    }

    public ApplyOptions(options: TChartOptions): void {
        //'as string & number' is a typescript bug workaround, more info https://stackoverflow.com/questions/74593347/type-never-inferred-on-thiskey-in-class-constructor
        for (const key of Object.keys(options)) {
            if (key === 'ColorScheme') {
                for (const colorKey of Object.keys(options.ColorScheme)) {
                    this.ColorScheme[colorKey as keyof TColorScheme] = options.ColorScheme[
                        colorKey as keyof TColorScheme
                    ] as string & number
                }
                continue
            }
            this[key as keyof TChartOptions] = options[key as keyof TChartOptions] as string & number
        }
    }

    SetColorScheme(index: number): void {
        // Assuming ColorSchemes is an array of TColorScheme objects available in the scope
        this.ColorScheme = ColorSchemes[index].Clone()
        // Assuming MakeColor is a function that modifies the color's opacity
        this.ColorScheme.PeriodSeparator.color = ColorHelperFunctions.MakeColor(
            this.ColorScheme.PeriodSeparator.color,
            50
        )
    }

    ColorSchemeIndex(ColorScheme: TColorScheme): number {
        return ColorSchemes.indexOf(ColorScheme)
    }

    SaveToList(list: unknown): void {
        // Implementation needed
    }

    LoadFromList(list: unknown): void {
        // Implementation needed
    }

    GetScaleInfo(): TChartScale {
        const result: TChartScale = {
            PixBetweenBars: this.HorzMagnifier,
            BarWidth2: Math.round((this.HorzMagnifier - 1) / 2.5)
        }

        return result
    }

    IsHScaleUpPossible(): boolean {
        return this.HorzMagnifier < this.MaxHorzMagnifier
    }

    IsHScaleDownPossible(): boolean {
        return this.HorzMagnifier > this.MinHorzMagnifier
    }

    public ZoomIn(zoomSpeedCoefficient = 1): void {
        // Calculate the increment size based on the current value
        const increment = this.calculateZoomIncrement(this.HorzMagnifier)
        this.HorzMagnifier += increment * zoomSpeedCoefficient

        // Ensure the magnifier does not exceed the maximum value
        if (this.HorzMagnifier > this.MaxHorzMagnifier) {
            this.HorzMagnifier = this.MaxHorzMagnifier
        }
    }

    public ZoomOut(zoomSpeedCoefficient = 1): void {
        // Calculate the decrement size based on the current value
        const decrement = this.calculateZoomIncrement(this.HorzMagnifier)
        this.HorzMagnifier -= decrement * zoomSpeedCoefficient

        // Ensure the magnifier does not go below the minimum value
        if (this.HorzMagnifier < this.MinHorzMagnifier) {
            this.HorzMagnifier = this.MinHorzMagnifier
        }
    }

    // Function to calculate the increment size based on the current magnifier value
    private calculateZoomIncrement(value: number): number {
        const minIncrement = 0.05
        const maxIncrement = 40.0
        const minRange = this.MinHorzMagnifier
        const maxRange = this.MaxHorzMagnifier

        // Normalize the current value to a range from 0 to 1
        const normalizedValue = (value - minRange) / (maxRange - minRange)

        // Calculate the increment based on the normalized value
        // Use an exponential function to make increments smaller near the minimum and larger near the maximum
        return minIncrement + (maxIncrement - minIncrement) * normalizedValue
    }

    findSchemeIndex(schemeName: string) {
        const schemeNames: Record<string, number> = {
            FT6: 0,
            'Green on Black': 1,
            'Red and Blue': 2,
            'Black and White': 3,
            'White on Black': 4,
            'Blue and Gray Medium': 5,
            'Blue and Gray': 6,
            'Green and Red Medium': 7,
            'Midnight Blue': 8
        }
        return schemeNames[schemeName] ?? -1
    }

    getCurrentSchemeIndex() {
        return this.ColorSchemeIndex(this.ColorScheme)
    }

    SetBarStyle(style: TChartStyle): void {
        this.ChartStyle = parseInt(style as unknown as string) as TChartStyle
    }

    applyAdditionalSettings(newSettings: any) {
        for (const key in newSettings) {
            if (this.hasOwnProperty(key)) {
                this[key as keyof typeof this] = newSettings[key]
            }
        }
    }
}

export class TCrossHair {
    public DateTime!: TDateTime
    public Price!: number
    public roolerDateTime!: TDateTime
    public roolerPrice!: number
    public RoundRoolerV!: boolean
}

export class TPosMarker {
    public DateTime!: TDateTime
    public price!: number

    constructor() {
        this.DateTime = -1
        this.price = -1
    }
}
