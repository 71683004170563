import { TChunkMapStatus } from '../../../TChunkMapStatus'
import { TDataDescriptor, TDataTypes } from '../../DataDescriptionTypes'
import TPriceChunk from '../../../chunks/PriceChunk'
import DataNotDownloadedYetError from '../../../data_errors/DataUnavailableError'
import IFMBarsArray from '../IFMBarsArray'
import { TBasicChunkedArray } from '../BasicChunkedArray'
import { TPriceRecord } from '../../../DataClasses/TPriceRecord'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export default class TIndicatorBufferArray extends TBasicChunkedArray<TPriceRecord, TPriceChunk> {
    public get LastItemInTestingIndex(): number {
        return this.fBarsArray.LastItemInTestingIndex
    }

    public get LastItemInTesting(): TPriceRecord | null {
        return this.GetItemByGlobalIndex(this.LastItemInTestingIndex)
    }

    protected fBarsArray: IFMBarsArray

    constructor(aBars: IFMBarsArray) {
        const descriptor = {
            broker: aBars.DataDescriptor.broker,
            symbolName: aBars.DataDescriptor.symbolName,
            dataType: TDataTypes.dt_IndicatorBuffer,
            timeframe: aBars.DataDescriptor.timeframe
        }
        super(descriptor)
        this.fBarsArray = aBars
    }

    public set setDataDescriptor(value: TDataDescriptor) {
        this.fDataDescriptor = value
    }

    public set setBarsArray(value: IFMBarsArray) {
        this.fBarsArray = value
    }

    HasSomeValues(): boolean {
        //TODO: maybe change this logic
        return this.fChunks.Count > 0
    }

    public get LastPossibleIndexInHistory(): number {
        return this.fBarsArray.LastPossibleIndexInHistory
    }

    public SetValue(firstItem0_globalIndex: number, value: number): void {
        const chunk = this.GetOrCreateChunkByGlobalIndex(firstItem0_globalIndex)

        const bar = this.fBarsArray.GetItemByGlobalIndex(firstItem0_globalIndex)
        if (!bar) {
            throw new DataNotDownloadedYetError('Bar is not available - IndicatorBufferArray.SetValue')
        }

        const priceRecord = new TPriceRecord(bar.DateTime, value)
        chunk.SetValue(firstItem0_globalIndex, priceRecord)
    }

    GetOrCreateChunkByGlobalIndex(globalIndex: number): TPriceChunk {
        const existingChunk = this.GetChunkByGlobalIndex(globalIndex) as TPriceChunk
        if (existingChunk) {
            return existingChunk
        }

        const newChunk = this.AddEmptyChunk(globalIndex)
        return newChunk
    }

    protected AddEmptyChunk(globalIndex: number): TPriceChunk {
        if (this.fBarsArray.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            //partial map is not good enough here, so let's wait for the full map
            throw new DataNotDownloadedYetError('Bars chunk map is not available - IndicatorBufferArray.AddEmptyChunk')
        }

        //init same chunk as in BarsArray
        const barsChunkInfo = this.fBarsArray.GetChunkInfoByGlobalIndex(globalIndex)
        if (!barsChunkInfo) {
            throw new DataNotDownloadedYetError('Bars chunk is not available - IndicatorBufferArray.AddEmptyChunk')
        }
        const newChunk = new TPriceChunk(
            this.DataDescriptor,
            barsChunkInfo.FirstDate,
            barsChunkInfo.LastDate,
            barsChunkInfo.Count,
            barsChunkInfo.FirstIndex
        )
        newChunk.InitWithEmptyValues()
        this.fChunks.InsertChunkByGlobalIndex(newChunk)
        return newChunk
    }

    public GetMax(firstPos: number, lastPos: number, emptyValue: number, ignoreEmpty = true): number {
        // Return the empty value immediately if there are no items.
        if (!this.LastItemInTestingIndexAvailable) {
            return emptyValue
        }

        // Correct the range and capture the corrected values.
        ;[firstPos, lastPos] = this.CorrectRange(firstPos, lastPos)

        let result = emptyValue
        // Iterate over the corrected range.
        for (let i = firstPos; i <= lastPos; i++) {
            // Ensure the index is within the bounds of the items array.
            if (i >= 0 && i < this.LastItemInTestingIndex) {
                const item = this.GetItemByGlobalIndex(i)
                if (!item) {
                    if (ignoreEmpty) {
                        continue
                    } else throw new StrangeError('Item is not available - TFMPriceArray.GetMax')
                }
                const value = item.value
                // Update the result if the current value is greater than the result or if the result is still the empty value.
                if (value !== emptyValue && (value > result || result === emptyValue)) {
                    result = value
                }
            }
        }
        return result
    }

    // Improved the implementation by ensuring that the parameters firstPos and lastPos are numbers.
    // Added a check to ensure that firstPos and lastPos are within the bounds of the items array.
    // Used a more concise conditional check within the loop.
    public GetMin(firstPos: number, lastPos: number, emptyValue: number, ignoreEmpty = true): number {
        // Return early if there are no items to compare.
        if (!this.LastItemInTestingIndexAvailable) {
            return 0
        }

        // Ensure the range is within the bounds of the items array.
        ;[firstPos, lastPos] = this.CorrectRange(firstPos, lastPos)

        let result = emptyValue
        for (let i = firstPos; i <= lastPos; i++) {
            const item = this.GetItemByGlobalIndex(i)
            if (!item) {
                if (ignoreEmpty) {
                    continue
                } else throw new StrangeError('Item is not available - TFMPriceArray.GetMin')
            }
            const value = item.value
            // Update result if the current value is not the empty value and is less than the current result,
            // or if the result itself is the empty value.
            if (value !== emptyValue && (result === emptyValue || value < result)) {
                result = value
            }
        }
        return result
    }
}
