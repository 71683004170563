import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { secureApi } from '@root/utils/api'
import { AxiosError } from 'axios'
import { SymbolItemType } from '@root/types/symbols.types'

const path = 'data/api/HistoricalDataQuality/symbols/'

const GET_SYMBOLS_ACTION = createAsyncThunk<SymbolItemType[], void>(
    'symbols/getSymbols',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await secureApi.get<SymbolItemType[]>(path)
            return data
        } catch (e: unknown) {
            const error = e as AxiosError
            console.error(error.message)
            return rejectWithValue(error.status)
        }
    }
)

const anySymbolActionRejected = isRejected(GET_SYMBOLS_ACTION)
const anySymbolActionPending = isPending(GET_SYMBOLS_ACTION)
const anySymbolActionFulfilled = isFulfilled(GET_SYMBOLS_ACTION)

export { GET_SYMBOLS_ACTION, anySymbolActionRejected, anySymbolActionPending, anySymbolActionFulfilled }
