import { FC } from 'react'
import { TabType } from '@root/pages/ChartPage/components/Terminal/types'

import { Typography, EmptyState } from '@fto/ui'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    activeTab: TabType
}

const EmptyStateBlock: FC<Props> = ({ activeTab }) => {
    const { t } = useTranslation()

    return (
        <EmptyState classNames={{ root: styles.EmptyState }}>
            <Typography color='gray-800' type='interface-regular'>
                {t(`terminal.tableEmptyStates.${activeTab}`)}
            </Typography>
        </EmptyState>
    )
}

export default EmptyStateBlock
