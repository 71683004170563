import { IndicatorDescriptor } from './IndicatorDescriptor'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { IndicatorsNameMappings } from './IndicatorsNameMappings'

export default class TIndicatorDescriptorList extends Array<IndicatorDescriptor> {
    constructor() {
        super()
    }

    public findByName(name: string): IndicatorDescriptor {
        for (let i = 0; i < this.length; i++) {
            if (this[i].shortName.toLowerCase() === name.toLowerCase()) {
                return this[i]
            }
        }
        throw new StrangeError('Indicator not found.')
    }

    public findByLibName(libName: string): IndicatorDescriptor {
        for (let i = 0; i < this.length; i++) {
            if (this[i].FileName.toLowerCase() === libName.toLowerCase()) {
                return this[i]
            }
        }
        throw new StrangeError('Indicator not found.')
    }

    public GetIndicatorByShortName(name: string): IndicatorDescriptor | null {
        const lowerName = name.toLocaleLowerCase()

        for (let i = 0; i < this.length; i++) {
            const indicator = this[i]
            if (indicator.shortName.toLocaleLowerCase() === lowerName) {
                return indicator
            }
        }

        // some names were changed - search for new ones
        const mappedName = IndicatorsNameMappings.getMappedName(name)
        if (mappedName) {
            return this.GetIndicatorByShortName(mappedName)
        }

        throw new StrangeError(`Indicator ${name} not found`)
    }
}
