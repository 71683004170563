import { DateFilterParamsType, DateIncludesType } from '@root/pages/ChartPage/components/Terminal/types'

export const getAppliedFiltersLabel = (
    filterByDate: DateFilterParamsType,
    filterByDateParamsIncludes: DateIncludesType
): string => {
    const { rangeType, selectedHole } = filterByDate

    const isDateFilterApplied = filterByDateParamsIncludes !== 'openAndCloseDate'
    const isRangeFilterApplied = rangeType === 'range' || selectedHole !== 'all'

    if (isRangeFilterApplied) {
        return isDateFilterApplied ? 'terminal.toolbar.filteredByDateAndPeriod' : 'terminal.toolbar.filteredBydPeriod'
    }

    if (isDateFilterApplied && !isRangeFilterApplied) {
        return 'terminal.toolbar.filteredByDate'
    }

    return 'terminal.toolbar.noFiltersApplied'
}
