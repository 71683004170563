import {
    MODE_HISTORY,
    MODE_TRADES,
    SELECT_BY_POS,
    SELECT_BY_TICKET
} from '../../common/CommonConstantsForExternalModules'
import { TTradePositionType } from '../../common/CommonExternalInterface'
import { TDateTime } from '../../common/CommonTypes'

export enum EOrderSelectMode {
    osm_SELECT_BY_POS = SELECT_BY_POS,
    osm_SELECT_BY_TICKET = SELECT_BY_TICKET
}

export enum TSearchMode {
    sm_MODE_TRADES = MODE_TRADES,
    sm_MODE_HISTORY = MODE_HISTORY
}

export enum TInstantOrderType {
    op_Buy = 0,
    op_Sell = 1
}

export class TTradePosInfo {
    ticket!: number
    openTime!: TDateTime
    closeTime!: TDateTime
    posType!: TTradePositionType
    lot!: number
    symbol!: string
    openPrice!: number
    closePrice!: number
    stopLoss!: number
    takeProfit!: number
    commission!: number
    swap!: number
    profit!: number
    profitPips!: number
    comments!: string
    margin!: number
    hedgedMargin!: number
    magicNumber!: number
}
