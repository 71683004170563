import { EOperationType, OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTradePositionType } from '../extension_modules/common/CommonExternalInterface'

type OrderOperationResult = {
    orderType: OrderType
    operationType: EOperationType
}

export function mapTradePositionType(positionType: TTradePositionType): OrderOperationResult {
    switch (positionType) {
        case TTradePositionType.tp_Buy: {
            return { orderType: OrderType.MARKET, operationType: EOperationType.BUY }
        }
        case TTradePositionType.tp_Sell: {
            return { orderType: OrderType.MARKET, operationType: EOperationType.SELL }
        }
        case TTradePositionType.tp_BuyLimit: {
            return { orderType: OrderType.LIMIT, operationType: EOperationType.BUY }
        }
        case TTradePositionType.tp_SellLimit: {
            return { orderType: OrderType.LIMIT, operationType: EOperationType.SELL }
        }
        case TTradePositionType.tp_BuyStop: {
            return { orderType: OrderType.STOP, operationType: EOperationType.BUY }
        }
        case TTradePositionType.tp_SellStop: {
            return { orderType: OrderType.STOP, operationType: EOperationType.SELL }
        }
        // Add additional mappings if needed
        default: {
            throw new StrangeError(`Invalid trade position type: ${positionType}`)
        }
    }
}

export function mapOrderTypeToTradePositionType(
    orderType: OrderType,
    operationType: EOperationType
): TTradePositionType {
    switch (orderType) {
        case OrderType.MARKET: {
            return operationType === EOperationType.BUY ? TTradePositionType.tp_Buy : TTradePositionType.tp_Sell
        }
        case OrderType.LIMIT: {
            return operationType === EOperationType.BUY
                ? TTradePositionType.tp_BuyLimit
                : TTradePositionType.tp_SellLimit
        }
        case OrderType.STOP: {
            return operationType === EOperationType.BUY ? TTradePositionType.tp_BuyStop : TTradePositionType.tp_SellStop
        }
        // Add additional mappings if needed
        default: {
            throw new StrangeError(`Invalid order type: ${orderType} and operation type: ${operationType}`)
        } // Return null or handle the case where mapping is not applicable
    }
}

export function formatCurrency(amount: number, currency = '$', fractionDigits = 2): string {
    const amountString = amount.toFixed(fractionDigits)

    if (amount > 0) {
        return `+${currency}${amountString}`.replace('.', ',')
    } else if (amount < 0) {
        return `-${currency}${amountString.slice(1)}`.replace('.', ',')
    } else {
        return `${currency}${amountString}`.replace('.', ',')
    }
}
