import { forwardRef } from 'react'
import * as React from 'react'

import cx from 'classnames'

import styles from './index.module.scss'

export const helperTextVariants = ['default', 'success', 'error'] as const

type Props = {
    variant?: (typeof helperTextVariants)[number]
    isAbsolute?: boolean
}

export type HelperTextProps = Omit<React.ComponentPropsWithoutRef<'span'>, keyof Props> & Props

export const HelperText = forwardRef<HTMLDivElement, HelperTextProps>(
    ({ variant = 'default', className, children, isAbsolute = false, ...rest }, ref) => {
        return (
            <span
                {...rest}
                ref={ref}
                className={cx(styles.root, className, [styles[`variant-${variant}`]], {
                    [styles.absolute]: isAbsolute
                })}
            >
                {children}
            </span>
        )
    }
)

HelperText.displayName = 'InputField.HelperText'

export default HelperText
