import { IBasicExternalModuleParamsProcRec } from '../CommonProcRecInterfaces/IBasicExternalModuleParamsProcRec'
import { ExternalModuleDescriptor } from '../ExternalModuleDescriptor'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'

export class BasicExternalModuleParamsProcRecImplementation extends BasicProcRecImplementation {
    private descriptor: ExternalModuleDescriptor

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds, descriptor: ExternalModuleDescriptor) {
        super(procRecsEveryoneNeeds)
        this.descriptor = descriptor
    }

    protected override generateDName(): string {
        return `API_BasicExternalModuleParamsProcRec_${super.generateDName()}`
    }

    public GetImplementation(): IBasicExternalModuleParamsProcRec {
        return {
            SetShortName: (name: string) => {
                this.descriptor.shortName = name
            },
            SetDescription: (description: string) => {
                this.descriptor.description = description
            }
        }
    }
}
