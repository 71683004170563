import { ChangeEvent, forwardRef, useCallback } from 'react'

import cx from 'classnames'

import styles from './index.module.scss'

interface ToggleSwitchProps {
    checked: boolean
    onChange?: (isChecked: boolean) => void
    disabled?: boolean
}

export const Toggle = forwardRef<HTMLLabelElement, ToggleSwitchProps>(
    ({ checked, onChange, disabled = false }, ref) => {
        const toggle = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                onChange?.(e.target.checked)
            },
            [onChange]
        )

        return (
            <label
                ref={ref}
                className={cx(styles.Label, {
                    [styles.Checked]: checked,
                    [styles.Disabled]: disabled
                })}
            >
                <input
                    type='checkbox'
                    className={styles.HiddenCheckbox}
                    checked={checked}
                    onChange={toggle}
                    disabled={disabled}
                />
            </label>
        )
    }
)
