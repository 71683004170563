import { FC, useCallback, MouseEvent } from 'react'
import cx from 'classnames'

import { Flex, HoverElement, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { RibbonOptionType } from '@fto/chart_components/ProjectInterface/components/Ribbon/components/types'

import styles from './index.module.scss'

type Props = {
    option: RibbonOptionType
    toggleFavorite: (val: string) => void
    isPreselected: boolean
    handleAction: (f: RibbonOptionType['action']) => void
}

const OptionRow: FC<Props> = ({ option, toggleFavorite, isPreselected, handleAction }) => {
    const triggerAction = useCallback(() => {
        handleAction(option.action)
    }, [option, handleAction])

    const handleFavoriteSelection = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            toggleFavorite(option.value)
        },
        [toggleFavorite, option]
    )

    return (
        <div className={cx(styles.Option, { [styles.WithBasePadding]: isPreselected })} onClick={triggerAction}>
            <Flex alignItems='center' gap={8} flexShrink={0} justifyContent='space-between' block>
                <Typography truncate title={option.name} className={styles.IndicatorName} type='interface-regular'>
                    {option.name}
                </Typography>
                <HoverElement
                    onClick={handleFavoriteSelection}
                    size={24}
                    classNames={{
                        root: cx(styles.FavoriteIcon, {
                            [styles.FavoriteIconVisible]: isPreselected
                        })
                    }}
                >
                    <Icon
                        name={isPreselected ? 'star' : 'star-empty'}
                        size={20}
                        color={isPreselected ? 'var(--color-orange-400)' : 'var(--color-gray-1000'}
                    />
                </HoverElement>
            </Flex>
        </div>
    )
}

export default OptionRow
