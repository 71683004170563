import { ChartControl, ChartControlParams } from '@fto/chart_components/ChartControl'
import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { changeImageColor } from '@fto/lib/utils/common_utils'

export class CloseBtn extends ChartControl {
    public owner: OrderLevel
    public mainColor: string = '#E99537'

    constructor(chartControlParams: ChartControlParams, owner: OrderLevel) {
        super(chartControlParams)
        this.owner = owner
    }

    draw(canvas: TGdiPlusCanvas) {
        const ctx = canvas.graphics.Context
        const image = GlobalImageManager.Instance.closeIcon

        const { Left, Top, Right, Bottom } = this.getLocation()
        const BorderRadius = (Bottom - Top) / 8
        const padding = BorderRadius * 2
        const buttonSize = Bottom - Top - padding * 2

        // Draw the button background
        ctx.save()
        ctx.fillStyle = '#FFFFFF' // Button background color
        ctx.strokeStyle = this.mainColor // Border color
        ctx.lineWidth = 1
        ctx.globalAlpha = this.owner.owner.isTransparent ? 0.5 : 1

        ctx.beginPath()
        canvas.graphics.drawRoundedRect(ctx, Left, Top, Right - Left, Bottom - Top, BorderRadius, '#FFFFFF')
        ctx.fill()
        ctx.stroke()
        ctx.restore()

        // Draw the SVG image (close icon) with color change
        const coloredImage = changeImageColor(image!, this.mainColor, buttonSize, buttonSize) // Change the color to the desired color
        ctx.drawImage(coloredImage, Left + padding, Top + padding, buttonSize, buttonSize)
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (super.onMouseDown(event, sender)) {
            this.owner.close()
            return this
        }

        return null
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        if (this.isPointInside(event.offsetX, event.offsetY)) {
            ;(sender.ChartWindow as TChartWindow).SetCursor(TCursor.crHandPoint)
            return this
        }
        return null
    }
}
