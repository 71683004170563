import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDateTime, DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataRecordWithDate } from './TDataRecordWithDate'
import { TTimeframe } from '../../common/BasicClasses/Timeframe'
import CommonConstants from '../../common/CommonConstants'
import { StrsConv } from '../../common/StrsConv'
import { TimeframeUtils } from '../../common/TimeframeUtils'

// TypeScript equivalent of TBarRecord

export class TBarRecord extends TDataRecordWithDate {
    public open: number
    public close: number
    public high: number
    public low: number
    public volume: number

    constructor(dateTime: TDateTime, open: number, high: number, low: number, close: number, volume: number) {
        super(dateTime)
        this.open = open
        this.close = close
        this.high = high
        this.low = low
        this.volume = volume
    }

    public toString(): string {
        return `${DateUtils.FormatDateTime('yyyy-MM-dd HH:nn:ss', this.DateTime)}, open: ${this.open}, close: ${
            this.close
        }, high: ${this.high}, low: ${this.low}, volume: ${this.volume}`
    }

    public static GetSafeEndDateForBar(bar: TBarRecord, timeframe: TTimeframe): TDateTime {
        return (
            TimeframeUtils.GetPeriodEnd(
                bar.DateTime + CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME,
                timeframe
            ) - CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME
        )
    }

    public static ConvertStrToItem(s: string): TBarRecord {
        const result = new TBarRecord(0, 0, 0, 0, 0, 0)
        let unixDate, remainingString: string
        ;[unixDate, remainingString] = StrsConv.GetStrInt(s, ',')
        result.DateTime = DateUtils.fromUnixTimeSeconds(unixDate)
        ;[result.open, remainingString] = StrsConv.GetStrDouble(remainingString, ',')
        ;[result.high, remainingString] = StrsConv.GetStrDouble(remainingString, ',')
        ;[result.low, remainingString] = StrsConv.GetStrDouble(remainingString, ',')
        ;[result.close, remainingString] = StrsConv.GetStrDouble(remainingString, ',')
        ;[result.volume, remainingString] = StrsConv.GetStrDouble(remainingString, ',')

        return result
    }

    public static DateInsideBar(dateTime: TDateTime, bar: TBarRecord, timeframe: number): boolean {
        const [barStartDate, barEndDate] = TimeframeUtils.GetPeriod(bar.DateTime, timeframe)
        if (!DateUtils.AreEqual(barStartDate, bar.DateTime)) {
            throw new StrangeError('barStartDate != bar.DateTime')
        }
        return dateTime >= barStartDate && dateTime < barEndDate
    }

    public static MakeACopy(bar: TBarRecord | null): TBarRecord {
        if (!bar) {
            throw new StrangeError('bar is null - MakeACopy')
        }
        return new TBarRecord(bar.DateTime, bar.open, bar.high, bar.low, bar.close, bar.volume)
    }

    public static GetEmptyBar(): TBarRecord {
        return new TBarRecord(DateUtils.EmptyDate, 0, 0, 0, 0, 0)
    }
}
