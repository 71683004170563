import { CreateProjectPayload } from '@root/types/projects'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

class ProjectMapper {
    public toCreateDto(form: CreateProjectModalFormData): CreateProjectPayload {
        const DataType = form.DataType ? (form.DataType as unknown as CreateProjectPayload['DataType']) : 'OneMin'

        const SessionCloseTime = form.SessionCloseTime
            ? (form.SessionCloseTime as unknown as CreateProjectPayload['SessionCloseTime'])
            : 'Custom'

        const DaylightSavingTime = form.DaylightSavingTime
            ? (form.DaylightSavingTime as unknown as CreateProjectPayload['DaylightSavingTime'])
            : 'None'

        return {
            ...form,
            StartDate: form.StartDate.valueOf(),
            EndDate: form.EndDate?.valueOf() || 0,
            StartFrom: form.StartFrom.valueOf(),
            DataType,
            SessionCloseTime,
            DaylightSavingTime,
            Symbols: form.Symbols?.map((item) => item.Symbol) || []
        }
    }
}

export default new ProjectMapper()
