import { ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { ProcessingCoreEvent, TProcessingCore } from '@fto/lib/processing_core/ProcessingCore'
import OrdersStore from '@fto/lib/store/ordersStore'
import {
    StoreDataType,
    terminalOrderBaseType,
    terminalOrderHistoryPositionType,
    terminalOrderOpenPositionType,
    terminalOrderPendingPositionType
} from '@fto/lib/store/ordersStore/types'
import { throttle } from 'lodash'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TTradePositionType } from '../extension_modules/common/CommonExternalInterface'

export class TerminalOrderManager implements ObserverTemplate<ProcessingCoreEvent, TProcessingCore> {
    private processingCore: TProcessingCore
    private digitsToRound
    private profitAndPNLdigitsToRound = 2
    private _throttledProcessEvent = throttle((event: ProcessingCoreEvent, item: TProcessingCore) => {
        if (event === ProcessingCoreEvent.UPDATE_HISTORY) {
            const { updateData } = OrdersStore

            updateData((prevState) => ({
                ...prevState,
                ...this.getDataForOrdersStore()
            }))
        }
    }, 500)

    constructor() {
        this.processingCore = GlobalProcessingCore.ProcessingCore
        //TODO: review this, why do we need to get the decimals from the chart? what if no charts are active?
        this.digitsToRound = GlobalChartsController.Instance.getActiveChart()?.SymbolData.symbolInfo.fDecimals || 5
    }

    processEvent(event: ProcessingCoreEvent, item: TProcessingCore) {
        this._throttledProcessEvent(event, item)
    }

    init() {
        this.processingCore.attachObserver(this)
        this.digitsToRound = GlobalChartsController.Instance.getActiveChart()?.SymbolData.symbolInfo.fDecimals || 5
        return this.getDataForOrdersStore()
    }

    private getDataForOrdersStore(): StoreDataType {
        return {
            orders: {
                open: this.getDataForOpenPositions(),
                history: this.getDataForHistory(),
                pending: this.getDataForPendingPositions()
            },
            balance: this.getBalance(),
            equity: this.getEquity(),
            pnl: Number(
                this.doubleToWstringWithPrecision(
                    this.processingCore.getTotalProfitAndLoss(),
                    this.profitAndPNLdigitsToRound
                )
            ),
            selectedPositionsIds: this.getSelectedPositions()
        }
    }

    private getSelectedPositions(): number[] {
        const result: number[] = []

        for (const openPosition of this.processingCore.OpenPositions) {
            const positionIsSelected = [...openPosition.orderLevels.values()].some((v) => {
                return (
                    v.openPriceOrderLevel?.isSelected ||
                    v.stopLossOrderLevel?.isSelected ||
                    v.takeProfitOrderLevel?.isSelected ||
                    v.sellBuyConnectorLine?.isSelected ||
                    v.partialCloseOrderLevel?.isSelected
                )
            })

            if (positionIsSelected) {
                result.push(openPosition.tpos.ticket)
            }
        }

        return result
    }
    public getDataForOpenPositions(): terminalOrderOpenPositionType[] {
        return this.processingCore.OpenPositions.filter((op) =>
            [TTradePositionType.tp_Buy, TTradePositionType.tp_Sell].includes(op.tpos.PosType)
        ).map((op) => {
            const position = op.tpos
            return {
                id: position.ticket,
                symbol: position.SymbolName,
                type: position.PosType,
                lots: Number(this.doubleToWstringWithPrecision(position.lot, 2)),
                openTime: DateUtils.toUnixTimeMillisecondsUTC(
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(position.OpenTime)
                ),
                openPrice: Number(this.doubleToWstringWithPrecision(position.OpenPrice, this.digitsToRound)),
                marketPrice: Number(this.doubleToWstringWithPrecision(position.ClosePrice, this.digitsToRound)),
                swap: position.swap,
                commision: position.commission,
                points: position.ProfitPips,
                profit: Number(this.doubleToWstringWithPrecision(position.profit, this.profitAndPNLdigitsToRound)),
                sl: position.StopLoss,
                tp: position.TakeProfit,
                comment: position.Comments
            }
        })
    }

    public getDataForHistory(): terminalOrderHistoryPositionType[] {
        return this.processingCore.History.map((po) => {
            const order = po.tpos
            const orderOpenTime = DateUtils.toUnixTimeMillisecondsUTC(
                GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(order.OpenTime)
            )

            const closeTime = DateUtils.toUnixTimeMillisecondsUTC(
                GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(order.CloseTime)
            )

            return {
                id: order.ticket,
                symbol: order.SymbolName,
                type: order.PosType,
                lots: Number(this.doubleToWstringWithPrecision(order.lot, 2)),
                openTime: orderOpenTime,
                openPrice: Number(this.doubleToWstringWithPrecision(order.OpenPrice, this.digitsToRound)),
                marketPrice: Number(this.doubleToWstringWithPrecision(order.ClosePrice, this.digitsToRound)),
                swap: order.swap,
                commision: order.commission,
                points: order.ProfitPips,
                profit: Number(this.doubleToWstringWithPrecision(order.profit, this.profitAndPNLdigitsToRound)),
                sl: order.StopLoss,
                tp: order.TakeProfit,
                comment: order.Comments,
                closePrice: Number(this.doubleToWstringWithPrecision(order.ClosePrice, this.digitsToRound)),
                closeTime: closeTime,
                orderStatus: order.orderStatus
            }
        })
    }

    public getDataForPendingPositions(): terminalOrderPendingPositionType[] {
        return this.processingCore.OpenPositions.filter((op) =>
            [
                TTradePositionType.tp_BuyLimit,
                TTradePositionType.tp_SellLimit,
                TTradePositionType.tp_BuyStop,
                TTradePositionType.tp_SellStop
            ].includes(op.tpos.PosType)
        ).map((op) => {
            const position = op.tpos
            return {
                id: position.ticket,
                symbol: position.SymbolName,
                type: position.PosType,
                lots: Number(this.doubleToWstringWithPrecision(position.lot, 2)),
                openTime: DateUtils.toUnixTimeMillisecondsUTC(
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(position.OpenTime)
                ),
                executionPrice: Number(this.doubleToWstringWithPrecision(position.OpenPrice, this.digitsToRound)),
                marketPrice: Number(this.doubleToWstringWithPrecision(position.ClosePrice, this.digitsToRound)),
                sl: position.StopLoss,
                tp: position.TakeProfit,
                comment: position.Comments
            }
        })
    }

    public closeAllOpenOrders(data: StoreDataType): StoreDataType {
        const { open } = data.orders
        for (const order of open) {
            this.closeOrder(data, order, true)
        }

        GlobalChartsController.Instance.getActiveChart()?.Repaint()

        return { ...data, ...this.getDataForOrdersStore() }
    }

    public closeAllPendingOrders(data: StoreDataType): StoreDataType {
        const { pending } = data.orders
        for (const order of pending) {
            this.closeOrder(data, order, true)
        }

        GlobalChartsController.Instance.updateCharts()

        return { ...data, ...this.getDataForOrdersStore() }
    }

    public closeOrder(data: StoreDataType, order: terminalOrderBaseType, isCloseAll = false): StoreDataType {
        if (order.type === TTradePositionType.tp_Buy || order.type === TTradePositionType.tp_Sell) {
            this.processingCore.CloseOrder(order.id, order.lots)
        } else {
            this.processingCore.CancelOrder(order.id)
        }

        if (!isCloseAll) {
            GlobalChartsController.Instance.updateCharts()
        }

        return { ...data, ...this.getDataForOrdersStore() }
    }

    getEquity(): number {
        return Number(this.doubleToWstringWithPrecision(this.processingCore.Equity, 2))
    }

    getBalance(): number {
        return Number(this.doubleToWstringWithPrecision(this.processingCore.Balance, 2))
    }

    private doubleToWstringWithPrecision(value: number, precision: number, trimTrailingNull = false): string {
        if (Number.isNaN(value)) {
            return 'NaN'
        }

        if (!Number.isFinite(value)) {
            return value < 0 ? '-inf' : 'inf'
        }

        let str: string = value.toFixed(precision)

        if (trimTrailingNull) {
            const dotPos: number = str.indexOf('.')
            if (dotPos !== -1) {
                str = str.replace(/0+$/, '')
                if (str.endsWith('.')) {
                    str = str.slice(0, -1)
                }
            }
        }

        return str
    }
}
