import { TSymbolList } from '../ft_types/data/SymbolList'
import MockSymbolList from '../mocks/MocksForTesting/MockSymbolList'

export default class GlobalSymbolList {
    private static _symbolList: TSymbolList

    public static Init(): void {
        GlobalSymbolList._symbolList = new TSymbolList()
    }

    public static Destroy(): void {
        GlobalSymbolList._symbolList.ClearAll()
        GlobalSymbolList._symbolList.ClearQueuedTick()
    }

    public static get SymbolList(): TSymbolList {
        if (!GlobalSymbolList._symbolList) {
            GlobalSymbolList.Init()
        }
        return GlobalSymbolList._symbolList
    }

    public static InitForUnitTests(): void {
        this._symbolList = new MockSymbolList()
    }
}
