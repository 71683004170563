// TypeScript equivalent of TSearchMode enumeration
export enum TSearchMode {
    sm_Open,
    sm_High,
    sm_Low,
    sm_Close,
    sm_Volume
}

export interface TDataWindow {
    firstIndex_globalIndex: number
    lastIndex_globalIndex: number
}
