import { t } from 'i18next'

import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TIndexBuffer } from '../api/IIndicatorApi'
import { DelphiColors } from '../../../delphi_compatibility/DelphiBasicTypes'
import { TMAType, TOptionType, TOptValue_number, TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

class DeMarker extends IndicatorImplementation {
    DeMarkerPeriod: TOptValue_number = new TOptValue_number(14)

    DeMarkerBuffer!: TIndexBuffer
    ExtMaxBuffer!: TIndexBuffer
    ExtMinBuffer!: TIndexBuffer
    dNUM1!: TIndexBuffer
    dNUM2!: TIndexBuffer

    Init() {
        this.api.RegOption(
            t('indicatorModal.deMarker.fields.deMarkerPeriod'),
            TOptionType.ot_Integer,
            this.DeMarkerPeriod
        )
        this.api.IndicatorShortName(t('indicators.deMarker'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.DeMarkerBuffer = this.api.CreateIndexBuffer()
        this.ExtMaxBuffer = this.api.CreateIndexBuffer()
        this.ExtMinBuffer = this.api.CreateIndexBuffer()
        this.dNUM1 = this.api.CreateIndexBuffer()
        this.dNUM2 = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.DeMarkerBuffer)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, DelphiColors.clLime)
        this.api.SetIndexLabel(0, t('indicatorModal.deMarker.fields.deMarker'))
    }

    private iMAOnArray(
        buffer: TIndexBuffer,
        total: number,
        period: number,
        ma_shift: number,
        MAType: TMAType,
        shift: number,
        prev = 0
    ): number {
        let i, N: number
        let sum,
            weight,
            q1,
            k,
            res = 0

        if (period === 0) {
            return 0
        }

        switch (MAType) {
            case TMAType.ma_SMA: {
                sum = 0
                for (i = shift; i < shift + period; i++) {
                    q1 = buffer.getValue(i)
                    sum += q1
                }
                res = sum / period
                break
            }

            case TMAType.ma_EMA: {
                k = 2 / (period + 1)
                if (prev === 0) {
                    res = buffer.getValue(shift)
                } else {
                    q1 = buffer.getValue(shift)
                    res = prev + k * (q1 - prev)
                }
                break
            }

            case TMAType.ma_SSMA: {
                if (prev === 0) {
                    res = this.api.GetMA(shift, 0, period, MAType, 0)
                } else {
                    q1 = buffer.getValue(shift)
                    res = (prev * period - prev + q1) / period
                }
                break
            }

            case TMAType.ma_WMA: {
                sum = 0
                weight = 0
                for (i = shift; i < shift + period; i++) {
                    q1 = buffer.getValue(i)
                    N = period - i + shift
                    sum += q1 * N
                    weight += N
                }
                res = weight === 0 ? 0 : sum / weight
                break
            }

            default: {
                throw new StrangeError('Unknown MA type')
            }
        }

        return res
    }

    Calculate(index: number): void {
        let dNum: number

        if (this.api.Bars() < this.DeMarkerPeriod.value + 2) return

        this.ExtMaxBuffer.setValue(index, 0)
        this.ExtMinBuffer.setValue(index, 0)

        dNum = this.api.High(index) - this.api.High(index + 1)
        if (dNum < 0) dNum = 0
        this.ExtMaxBuffer.setValue(index, dNum)

        dNum = this.api.Low(index + 1) - this.api.Low(index)
        if (dNum < 0) dNum = 0
        this.ExtMinBuffer.setValue(index, dNum)

        this.dNUM1.setValue(
            index,
            this.iMAOnArray(
                this.ExtMaxBuffer,
                0,
                this.DeMarkerPeriod.value,
                0,
                0,
                index,
                this.dNUM1.getValue(index + 1)
            )
        )
        this.dNUM2.setValue(
            index,
            this.iMAOnArray(
                this.ExtMinBuffer,
                0,
                this.DeMarkerPeriod.value,
                0,
                0,
                index,
                this.dNUM2.getValue(index + 1)
            )
        )

        dNum = this.dNUM1.getValue(index) + this.dNUM2.getValue(index)

        if (dNum === 0) {
            this.DeMarkerBuffer.setValue(index, 0)
        } else {
            this.DeMarkerBuffer.setValue(
                index,
                this.iMAOnArray(this.ExtMaxBuffer, 0, this.DeMarkerPeriod.value, 0, 0, index, index) / dNum
            )
        }
    }
}

export default DeMarker
