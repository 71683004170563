import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import elliottWaves, {
    FormTypes,
    ImpulseWaveForms,
    IndefiniteStartForms,
    LetterWaveForms,
    createOptionsByType
} from '@fto/lib/utils/toolUtils'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { IGPFont, TGPFontFamily, TGpFontStyle } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TPointInfo } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'

import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { WaveSymbolsJSON } from '@fto/lib/ProjectAdapter/Types'
import { IChart } from '../../chart_classes/IChart'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'

abstract class TPtWaveSymbols extends TBasicPaintTool {
    protected waveType: keyof typeof elliottWaves
    protected waveStyle: keyof ImpulseWaveForms | keyof LetterWaveForms | keyof IndefiniteStartForms
    protected waveLabels: string[]
    private labelPositionSides: boolean[] // true - upper, false - lower
    protected isPaintLineBetweenPoints = true

    protected constructor(aChart: IChart, aName: PaintToolNames) {
        super(aChart, aName)
        this.waveType = 'Impulse'
        this.waveStyle = 'RomanUpper'
        this.waveLabels = elliottWaves[this.waveType][this.waveStyle]
        this.fMaxPoints = this.waveLabels.length
        this.font = new IGPFont(new TGPFontFamily('Roboto Flex'), 14, [TGpFontStyle.FontStyleRegular])
        this.brush.setColor('#2F80ED')
        this.labelPositionSides = []
    }

    public toJson(): WaveSymbolsJSON {
        return {
            ...super.toJson(),
            waveType: this.waveType,
            waveStyle: this.waveStyle,
            isPaintLineBetweenPoints: this.isPaintLineBetweenPoints,
            font: this.font.toString()
        }
    }

    public fromJSON(json: WaveSymbolsJSON) {
        super.fromJSON(json)
        this.waveType = json.waveType as keyof typeof elliottWaves
        this.waveStyle = json.waveStyle as keyof ImpulseWaveForms | keyof LetterWaveForms | keyof IndefiniteStartForms
        this.isPaintLineBetweenPoints = json.isPaintLineBetweenPoints
        this.SetWaveType(this.waveType, this.waveStyle)
        this.font = IGPFont.fromString(json.font)
    }

    protected applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(
            this.ShortName as
                | PaintToolNames.ptElliottWaveSymbolsImpulse
                | PaintToolNames.ptElliottWaveSymbolsIndefiniteStart
                | PaintToolNames.ptElliottWaveSymbolsComplexCorrection
                | PaintToolNames.ptElliottWaveSymbolsSimpleCorrection
        )
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(
                this.ShortName as
                    | PaintToolNames.ptElliottWaveSymbolsImpulse
                    | PaintToolNames.ptElliottWaveSymbolsIndefiniteStart
                    | PaintToolNames.ptElliottWaveSymbolsComplexCorrection
                    | PaintToolNames.ptElliottWaveSymbolsSimpleCorrection
            )
        }

        if (!styles) throw new StrangeError('Default styles for Ellipse are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.isPaintLineBetweenPoints = styles.isPaintLineBetweenPoints
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            styles.font.size,
            StylingHelper.getTGpFontStyle({
                style: styles.font.style,
                weight: styles.font.weight
            })
        )
        this.brush.setColor(styles.font.color)
        this.waveType = styles.waveType
        this.waveStyle = styles.waveStyle
        this.SetWaveType(this.waveType, this.waveStyle)
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(this.getShortName(), {
            toolName: this.getShortName() as PaintToolNames.ptElliottWaveSymbolsComplexCorrection,
            isPaintLineBetweenPoints: this.isPaintLineBetweenPoints,
            font: {
                size: this.font.getFontParams().size,
                color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                style: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).style,
                weight: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).weight
            },
            waveType: this.waveType,
            waveStyle: this.waveStyle,
            lineStyle: this.fLineStyle.getSerialized()
        })
    }

    private getShortName():
        | PaintToolNames.ptElliottWaveSymbolsIndefiniteStart
        | PaintToolNames.ptElliottWaveSymbolsImpulse
        | PaintToolNames.ptElliottWaveSymbolsSimpleCorrection
        | PaintToolNames.ptElliottWaveSymbolsComplexCorrection {
        switch (this.ShortName) {
            case PaintToolNames.ptElliottWaveSymbolsImpulse: {
                return PaintToolNames.ptElliottWaveSymbolsImpulse
            }
            case PaintToolNames.ptElliottWaveSymbolsSimpleCorrection: {
                return PaintToolNames.ptElliottWaveSymbolsSimpleCorrection
            }
            case PaintToolNames.ptElliottWaveSymbolsComplexCorrection: {
                return PaintToolNames.ptElliottWaveSymbolsComplexCorrection
            }
            case PaintToolNames.ptElliottWaveSymbolsIndefiniteStart: {
                return PaintToolNames.ptElliottWaveSymbolsIndefiniteStart
            }
            default: {
                throw new StrangeError('Unknown wave type')
            }
        }
    }

    public clone(): TPtWaveSymbols {
        const cloneObj = new (this.constructor as new (aChart: IChart) => TPtWaveSymbols)(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public Paint(): void {
        if (this.fPoints.length < 2) {
            return
        }

        const dpr = window.devicePixelRatio || 1
        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        this.fLineStyle.getPen().applyDashPattern(gdiPlusCanvas.graphics.Context)

        this.updateLabelPositionSides()
        const textMargin = 5 * dpr

        for (let i = 0; i <= this.fPoints.length - 1; i++) {
            const p1 = this.fPoints[i]
            let isLabelUpper = this.labelPositionSides[i]
            const textHeight = gdiPlusCanvas.TextHeight(this.waveLabels[i], this.font, true)

            if (i === this.fPoints.length - 1) {
                const pPrev = this.fPoints[i - 1]
                const yOffset = this.calculateAdaptiveLabelPosition(pPrev, p1, this.waveLabels[i])
                gdiPlusCanvas.textOut(p1.x - 7.5 * dpr, p1.y + yOffset, this.waveLabels[i], this.font, this.brush, true)
                break
            }

            if (this.isPaintLineBetweenPoints) {
                const p2 = this.fPoints[i + 1]
                gdiPlusCanvas.MoveTo(p1.x, p1.y)
                gdiPlusCanvas.LineTo(p2.x, p2.y, this.fLineStyle.getPen())
            }

            const yOffset = isLabelUpper ? -textMargin : textHeight + textMargin

            gdiPlusCanvas.textOut(p1.x - 7.5 * dpr, p1.y + yOffset, this.waveLabels[i], this.font, this.brush, true)
        }

        this.fLineStyle.getPen().restoreDashPattern(gdiPlusCanvas.graphics.Context)
        super.PaintMarkers()
    }

    private updateLabelPositionSides(): void {
        this.labelPositionSides = []
        let drawUpper = this.fPoints[0].y < this.fPoints[1].y

        for (let i = 0; i < this.fPoints.length; i++) {
            if (drawUpper) this.labelPositionSides.push(true)
            else this.labelPositionSides.push(false)

            drawUpper = !drawUpper
        }
    }

    private calculateAdaptiveLabelPosition(p1: TPointInfo, p2: TPointInfo, text: string): number {
        const dpr = window.devicePixelRatio || 1
        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        const textHeight = gdiPlusCanvas.TextHeight(text, this.font, true)
        const margin = 5 * dpr
        const deltaY = p2.y - p1.y
        const deltaX = p2.x - p1.x
        const slope = deltaY / (deltaX === 0 ? 1 : deltaX)

        return slope >= 0 ? textHeight + margin : -margin
    }

    public SetWaveType(waveType: keyof typeof elliottWaves, waveStyle: FormTypes): void {
        this.waveType = waveType
        this.waveStyle = waveStyle

        switch (waveType) {
            case 'Impulse': {
                this.waveLabels = elliottWaves.Impulse[waveStyle as keyof ImpulseWaveForms] as string[]

                break
            }
            case 'SimpleCorrection': {
                this.waveLabels = elliottWaves.SimpleCorrection[waveStyle as keyof LetterWaveForms] as string[]

                break
            }
            case 'ComplexCorrection': {
                this.waveLabels = elliottWaves.ComplexCorrection[waveStyle as keyof LetterWaveForms] as string[]

                break
            }
            case 'IndefiniteStart': {
                this.waveLabels = elliottWaves.IndefiniteStart[waveStyle as keyof IndefiniteStartForms] as string[]

                break
            }

            default: {
                throw new StrangeError('Unknown wave type')
            }
        }

        this.fMaxPoints = this.waveLabels.length
        this.labelPositionSides = []
        this.invalidate()
    }

    public ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore

        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        const data = {
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.wave',
                type: 'style',
                disabled: false,
                isOptional: true,
                isActive: this.isPaintLineBetweenPoints
            },

            textStyle: {
                key: 'textStyle',
                type: 'textStyle',
                label: 'toolsModal.fields.textStyle',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    size: fontParams.size,
                    style: fontStyleParams.style,
                    weight: fontStyleParams.weight
                }
            },

            degree: {
                key: 'degree',
                value: this.waveStyle,
                label: 'toolsModal.fields.degree',
                type: 'select',
                disabled: false,
                selectOptions: createOptionsByType(this.waveType)
            }
        }

        updateToolSettings(data)
    }

    public ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings, toolSettings } = GraphToolStore
        const formattedToolSettings = getKeyValueData()

        this.fLineStyle = formattedToolSettings.lineStyle
        this.isPaintLineBetweenPoints = !!toolSettings.lineStyle.isActive
        this.SetWaveType(this.waveType, formattedToolSettings.degree)
        this.font = new IGPFont(
            new TGPFontFamily('Roboto Flex'),
            formattedToolSettings.textStyle.size,
            StylingHelper.getTGpFontStyle({
                style: formattedToolSettings.textStyle.style,
                weight: formattedToolSettings.textStyle.weight
            })
        )
        this.brush.setColor(formattedToolSettings.textStyle.color)

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }
}

export class TPtWaveSymbolsImpulse extends TPtWaveSymbols {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptElliottWaveSymbolsImpulse)
        this.waveType = 'Impulse'
        this.waveStyle = 'RomanUpper'
        this.waveLabels = elliottWaves[this.waveType][this.waveStyle]
        this.fMaxPoints = this.waveLabels.length

        super.applySettings()
    }

    public ExportToDialog(): void {
        super.ExportToDialog()
        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptElliottWaveSymbolsImpulse,
            toolName: 'impulseElliotWave'
        })
    }
}

export class TPtWaveSymbolsSimpleCorrection extends TPtWaveSymbols {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptElliottWaveSymbolsSimpleCorrection)
        this.waveType = 'SimpleCorrection'
        this.waveStyle = 'UpperCaseLetters'
        this.waveLabels = elliottWaves[this.waveType][this.waveStyle]
        this.fMaxPoints = this.waveLabels.length

        super.applySettings()
    }

    public ExportToDialog(): void {
        super.ExportToDialog()
        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptElliottWaveSymbolsSimpleCorrection,
            toolName: 'simpleElliotWave'
        })
    }
}

export class TPtWaveSymbolsComplexCorrection extends TPtWaveSymbols {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptElliottWaveSymbolsComplexCorrection)
        this.waveType = 'ComplexCorrection'
        this.waveStyle = 'UpperCaseLetters'
        this.waveLabels = elliottWaves[this.waveType][this.waveStyle]
        this.fMaxPoints = this.waveLabels.length

        super.applySettings()
    }

    public ExportToDialog(): void {
        super.ExportToDialog()
        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptElliottWaveSymbolsComplexCorrection,
            toolName: 'complexElliotWave'
        })
    }
}

export class TPtWaveSymbolsIndefiniteStart extends TPtWaveSymbols {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptElliottWaveSymbolsIndefiniteStart)
        this.waveType = 'IndefiniteStart'
        this.waveStyle = 'MixedWithParentheses'
        this.waveLabels = elliottWaves[this.waveType][this.waveStyle]
        this.fMaxPoints = this.waveLabels.length

        super.applySettings()
    }

    public ExportToDialog(): void {
        super.ExportToDialog()
        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptElliottWaveSymbolsIndefiniteStart,
            toolName: 'indefiniteElliotWave'
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptElliottWaveSymbolsImpulse, TPtWaveSymbolsImpulse)
PaintToolManager.RegisterPaintTool(PaintToolNames.ptElliottWaveSymbolsSimpleCorrection, TPtWaveSymbolsSimpleCorrection)
PaintToolManager.RegisterPaintTool(
    PaintToolNames.ptElliottWaveSymbolsComplexCorrection,
    TPtWaveSymbolsComplexCorrection
)
PaintToolManager.RegisterPaintTool(PaintToolNames.ptElliottWaveSymbolsIndefiniteStart, TPtWaveSymbolsIndefiniteStart)
