import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { DateUtils } from '../../delphi_compatibility/DateUtils'
import { TBarChunk } from '../../ft_types/data/chunks/BarChunk'
import { TBaseTickChunk } from '../../ft_types/data/chunks/TickChunks/BaseTickChunk'
import { IMockTickChunk, IMockTickRecord } from './MockDataInterfaces'

export default class MockChunkGenerator {
    static GenerateMockBarChunkData(chunk: TBarChunk): TBarRecord[] {
        const mockData: TBarRecord[] = []
        const timeframe = chunk.DataDescriptor.timeframe // Assuming this returns the timeframe in milliseconds
        let currentDateTime = chunk.FirstDate

        for (let i = 0; i < chunk.Count; i++) {
            // Assuming TBarRecord has a constructor that takes DateTime and other necessary fields
            const barRecord = new TBarRecord(currentDateTime, 1, 1, 1, 1, 1)
            mockData.push(barRecord)

            currentDateTime += timeframe // Move to the next timeframe
        }

        return mockData
    }

    static GenerateMockTickChunkData_ticksEachSecond(chunk: TBaseTickChunk, numberOfTicks: number): IMockTickChunk {
        const mockData: IMockTickRecord[] = []

        let currentDateTime = chunk.FirstDate

        for (let i = 0; i < numberOfTicks; i++) {
            // Assuming TBarRecord has a constructor that takes DateTime and other necessary fields
            const tickRecord: IMockTickRecord = {
                DateTime: currentDateTime,
                Bid: 1,
                Ask: 1
            }
            mockData.push(tickRecord)

            currentDateTime += DateUtils.OneSecond // Move to the next timeframe
        }

        return {
            firstDateTime: chunk.FirstDate,
            ticks: mockData
        }
    }
}
