import { DateUtils, TDateTime } from '../../../../delphi_compatibility/DateUtils'
import { TChunkStatus, TNoExactMatchBehavior } from '../../chunks/ChunkEnums'
import { TBaseTickChunk } from '../../chunks/TickChunks/BaseTickChunk'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import { TDataTypes } from '../DataDescriptionTypes'
import { TBasicChunkedArray } from './BasicChunkedArray'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTickRecord } from '../../DataClasses/TTickRecord'
import GlobalServerSymbolInfo from '@fto/lib/globals/GlobalServerSymbolInfo'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'
import { TPseudoTickChunk } from '../../chunks/TickChunks/PseudoTickChunk'
import { TRealTickChunk } from '../../chunks/TickChunks/RealTickChunk'

export class TFMTickArray extends TBasicChunkedArray<TTickRecord, TBaseTickChunk> {
    public get VeryLastDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.DataDescriptor.symbolName).EndDate
    }

    public get VeryFirstDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.DataDescriptor.symbolName).StartDate
    }

    public isDateInAvailableRange(date: number): boolean {
        if (!this.VeryFirstDateInHistory || !this.VeryLastDateInHistory) {
            throw new StrangeError(
                'isDateInAvailableRange - VeryFirstDateInHistory or VeryLastDateInHistory is not set'
            )
        }
        return (
            DateUtils.MoreOrEqual(date, this.VeryFirstDateInHistory) &&
            DateUtils.LessOrEqual(date, this.VeryLastDateInHistory)
        )
    }

    public get LastPossibleIndexInHistory(): number {
        throw new NotImplementedError(
            'MaxPossibleIndex - Method not implemented for ticks because indexes in ticks make little sense, we work by date stamps.'
        )
    }
    public get LastItemInTestingIndex(): number {
        throw new NotImplementedError(
            'LastItemInTestingIndex - Method not implemented for ticks because indexes in ticks make little sense, we work by date stamps.'
        )
    }
    public get LastItemInTesting(): TTickRecord | null {
        throw new NotImplementedError('LastItemInTesting - Method not implemented for ticks.')
    }

    public GetChunksForRangeDates(firstDate: TDateTime, lastDate: TDateTime): TBaseTickChunk[] {
        if (DateUtils.IsEmpty(firstDate) || DateUtils.IsEmpty(lastDate)) {
            throw new StrangeError('GetChunksForRangeDates - Invalid date')
        }
        //TODO: check for start of data and end of data

        const result: TBaseTickChunk[] = []

        let currentChunk = this.GetOrCreateChunkByDate(firstDate)

        do {
            result.push(currentChunk)
            currentChunk = this.GetOrCreateNextChunk(currentChunk.FirstDate)
        } while (DateUtils.LessOrEqual(currentChunk.FirstDate, lastDate))

        return result
    }

    EnsureChunkIsReadyOrDownloading(DateTime: TDateTime): void {
        const chunk = this.GetOrCreateChunkByDate(DateTime)

        chunk.EnsureDataIsPresentOrDownloading()
    }

    //Do not download data here. Just create an empty chunk
    AddEmptyChunk(DateTime: TDateTime): TBaseTickChunk {
        this.__checkNumberOfChunksLimit()

        const StartOfTheDay = DateUtils.StartOfTheDay(DateTime)
        const EndOfTheDay = DateUtils.EndOfTheDay(DateTime)

        let newChunk: TBaseTickChunk
        if (CommonUtils.IsBasicDataSubscription) {
            newChunk = new TPseudoTickChunk(this.fDataDescriptor, StartOfTheDay, EndOfTheDay)
        } else {
            newChunk = new TRealTickChunk(this.fDataDescriptor, StartOfTheDay, EndOfTheDay)
        }
        newChunk.Status = TChunkStatus.cs_Empty
        this.fChunks.InsertChunkByDate(newChunk)
        newChunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundPassForwardChunkLoadedEvent)
        return newChunk
    }

    private __checkNumberOfChunksLimit() {
        if (this.fChunks.Count >= 20000) {
            //most likely this means that we have a bug in our code and we are creating too many chunks
            throw new StrangeError(`Tick Chunks limit reached for ${this.DataDescriptor.symbolName}. Limit is ${20000}`)
        }
    }

    private boundPassForwardChunkLoadedEvent = this.PassForwardChunkLoadedEvent.bind(this)
    private PassForwardChunkLoadedEvent(chunk: TBaseTickChunk) {
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    public get HasSomeData(): boolean {
        return this.fChunks.Count > 0
    }

    constructor(aSymbol: string, aBroker: string) {
        const dataDescriptor = {
            dataType: TDataTypes.dt_Ticks,
            symbolName: aSymbol,
            broker: aBroker,
            timeframe: 0
        }
        super(dataDescriptor)
        this.SetName(`TicksArray_`)
    }

    public GetItemByDatePosition(position: TDateTime, noExactMatchBehavior: TNoExactMatchBehavior): TTickRecord | null {
        const chunk = this.GetChunkByDate(position, true)
        if (!chunk || chunk.Status !== TChunkStatus.cs_Loaded) {
            this.EnsureChunkIsReadyOrDownloading(position)
            throw new DataNotDownloadedYetError(
                `GetItemByDatePosition - Ticks are not available for ${
                    this.fDataDescriptor.symbolName
                } at ${DateUtils.DF(position)}`
            )
        }

        return chunk.GetItemByDateTime(position, noExactMatchBehavior)
    }

    //Returns the chunk that comes before the chunk with the specified date
    public GetOrCreatePrevChunk(ChunkStartDateTime: TDateTime, isNeedLoadingChunk = true): TBaseTickChunk {
        const prevChunkStartDate = this.GetPrevChunkStartDate(ChunkStartDateTime)
        return this.GetOrCreateChunkByDate(prevChunkStartDate, isNeedLoadingChunk)
    }

    public GetOrCreateChunkByDate(dateInChunk: TDateTime, isNeedLoadingChunk = true): TBaseTickChunk {
        if (this.fChunks.Count > 0) {
            const existingChunk = this.GetChunkByDate(dateInChunk, true)
            if (existingChunk) {
                return existingChunk
            }
        }

        const newChunk = this.AddEmptyChunk(dateInChunk)
        if (isNeedLoadingChunk) {
            newChunk.EnsureDataIsPresentOrDownloading()
        }
        return newChunk
    }

    //Returns the chunk that comes after the chunk with the specified date
    public GetOrCreateNextChunk(ChunkStartDateTime: TDateTime, isNeedLoadingChunk = true): TBaseTickChunk {
        const nextChunkStartDate = this.GetNextChunkStartDate(ChunkStartDateTime)

        return this.GetOrCreateChunkByDate(nextChunkStartDate, isNeedLoadingChunk)
    }

    GetPrevChunkStartDate(ChunkStartDateTime: TDateTime): TDateTime {
        // Subtract one day to get the previous day
        const prevDay = ChunkStartDateTime - DateUtils.OneDay

        // Now get the start of this previous day
        return DateUtils.StartOfTheDay(prevDay)
    }

    GetNextChunkStartDate(ChunkStartDateTime: TDateTime): TDateTime {
        // Add one day to get the next day
        const nextDay = ChunkStartDateTime + DateUtils.OneDay

        // Now get the start of this next day
        return DateUtils.StartOfTheDay(nextDay)
    }

    public PreloadDataIfNecessary(startDate: TDateTime, endDate: TDateTime): void {
        const chunks = this.GetChunksForRangeDates(startDate, endDate)
        for (const chunk of chunks) {
            chunk.EnsureDataIsPresentOrDownloading()
        }
    }
}
