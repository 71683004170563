import { TBasicPaintTool } from '../BasicPaintTool'
import { TBasicChart } from '@fto/lib/charting/chart_classes/VeryBasicChart'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { TPaintToolType } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import {
    FillColorParamsType,
    FontStylesType
} from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { ExtPriceLabelJSON } from '@fto/lib/ProjectAdapter/Types'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { IChart } from '../../chart_classes/IChart'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TPoint, TRect } from '../../../extension_modules/common/CommonExternalInterface'

export enum TAlignment {
    taLeftJustify,
    taRightJustify,
    taCenter
}

export class TPtRightPriceLabel extends TBasicPaintTool {
    private fAlign: TAlignment
    private fillBackgroundBrush!: IGPSolidBrush

    constructor(aChart: IChart, shouldApplySettings: boolean = true) {
        super(aChart, PaintToolNames.ptPriceLabelRight)
        this.ShortName = PaintToolNames.ptPriceLabelRight
        this.fToolType = TPaintToolType.tt_Text
        this.fMaxPoints = 1
        this.fClosedPolygon = true
        this.fShouldFillInside = true
        this.CursorStyle = TCursor.crCross
        this.icon = 85
        this.fAlign = TAlignment.taRightJustify
        // Assuming fFontStyle and fLineStyle are accessible via TBasicPaintTool
        this.fFontStyle.color = this.fLineStyle.color
        this.fFontStyle.size = 16
        this.hasText = true
        // Assuming fEditDialog is a property of TBasicPaintTool
        // this.fEditDialog = PriceLabelFrm; // Not directly translatable to TypeScript
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.brush = new IGPSolidBrush(this.fFontStyle.color)
        this.fillBackgroundBrush = new IGPSolidBrush(DelphiColors.clWhite, 0.25)

        if (shouldApplySettings) {
            this.applySettings()
        }
    }

    protected applySettings() {
        const name = this.getToolNameLabelRightOrLeftOrNull()
        if (name === null) return

        let styles = LastPaintToolStyleManager.loadToolProperties(name)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(name)
        }

        if (!styles) throw new StrangeError(`Default styles for ${name} are not found`)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            styles.text.size,
            StylingHelper.getTGpFontStyle({
                style: styles.text.style,
                weight: styles.text.weight
            })
        )
        this.brush.setColor(styles.text.color)
        this.fLineStyle = TLineStyle.fromSerialized(styles.line)
        this.fShouldFillInside = styles.fShouldFillInside
        this.fillBackgroundBrush = IGPSolidBrush.fromSerialized(styles.fill)
    }

    protected saveToManager() {
        const name = this.getToolNameLabelRightOrLeftOrNull()
        if (name === null) return

        LastPaintToolStyleManager.saveToolProperties(name, {
            toolName: name,
            fill: this.fillBackgroundBrush.getSerialized(),
            line: this.fLineStyle.getSerialized(),
            fShouldFillInside: this.fShouldFillInside,
            text: {
                color: this.brush.getColor(),
                size: this.font.getFontParams().size,
                style: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).style,
                weight: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).weight
            }
        })
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    private getToolNameLabelRightOrLeftOrNull():
        | PaintToolNames.ptPriceLabelRight
        | PaintToolNames.ptPriceLabelLeft
        | null {
        if (this.ShortName !== PaintToolNames.ptPriceLabelRight && this.ShortName !== PaintToolNames.ptPriceLabelLeft) {
            return null
        }
        return this.ShortName
    }

    clone(): TPtRightPriceLabel {
        const cloneObj = new TPtRightPriceLabel(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)

        cloneObj.fAlign = this.fAlign
        cloneObj.fillBackgroundBrush = this.fillBackgroundBrush

        return cloneObj
    }
    fromJSON(json: ExtPriceLabelJSON) {
        super.fromJSON(json)
        this.font = IGPFont.fromString(json.font)
        this.fillBackgroundBrush = new IGPSolidBrush(
            json.FillBackgroundBrush.color,
            json.FillBackgroundBrush.opacity,
            json.FillBackgroundBrush.style
        )
    }

    public toJson(): ExtPriceLabelJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            icon: this.icon,
            LabelFont: this.font.toString(),
            LabelBrush: {
                color: this.brush.getColor(),
                style: this.brush.getStyle(),
                opacity: this.brush.getOpacity()
            },
            LineStyle: {
                color: this.fLineStyle.color,
                style: this.fLineStyle.style,
                width: this.fLineStyle.width
            },
            FillBackgroundBrush: {
                color: this.fillBackgroundBrush.getColor(),
                style: this.fillBackgroundBrush.getStyle(),
                opacity: this.fillBackgroundBrush.getOpacity()
            }
        }
    }

    public EdgeUnderMouse(x: number, y: number): number {
        // if PtInRect(GetFrameRect, point(x, y)) then
        if (this.GetFrameRect().PtInRect(new TPoint(x, y))) {
            return 0
        } else {
            return -1
        }
    }

    GetFrameRect(): TRect {
        const dpr = window.devicePixelRatio
        // get base x, y coordinates
        const x = this.fPoints[0].x
        const y = this.fPoints[0].y

        // Assuming StrDouble is a method that formats the price with the chart's scale decimals
        const text = StrsConv.StrDouble(this.fPoints[0].price, this.fChart.ScaleDecimals())

        // get text width/height
        const w = this.chart.GdiCanvas.TextWidth(text, this.font)
        const h = this.chart.GdiCanvas.TextHeight('0', this.font)

        const frameMargin = 10 * window.devicePixelRatio

        let rect = new TRect(0, 0, 0, 0)
        if (this.fAlign === TAlignment.taRightJustify) {
            rect = new TRect(x + frameMargin, y - h / 2, x + frameMargin + w, y + h / 2)
        } else {
            rect = new TRect(x - frameMargin - w, y - h / 2, x - frameMargin, y + h / 2)
        }

        // InflateRect equivalent in TypeScript
        rect.Inflate(2 * dpr, 2 * dpr)

        return rect
    }

    Paint(): void {
        if (this.fPoints.length === 0) return

        const chartCanvas = this.chart.GdiCanvas
        const R = this.GetFrameRect()
        const text = StrsConv.StrDouble(this.fPoints[0].price, this.chart.ScaleDecimals())

        if (this.fShouldFillInside) {
            chartCanvas.FillRect(R, this.fillBackgroundBrush)
        }

        const textWidth = chartCanvas.TextWidth(text, this.font)
        const textHeight = chartCanvas.TextHeight('0', this.font)

        chartCanvas.textOut(
            R.Left + (R.Right - R.Left - textWidth) / 2,
            R.Bottom - (R.Bottom - R.Top - textHeight) / 2,
            text,
            this.font,
            this.brush,
            true
        )

        // chartCanvas.setPen(this.fLineStyle)

        const lineStylesPen = this.fLineStyle.getPen()

        chartCanvas.rectangle(R, lineStylesPen)

        chartCanvas.MoveTo(this.fPoints[0].x, this.fPoints[0].y)
        if (this.fAlign === TAlignment.taRightJustify) {
            chartCanvas.LineTo(R.Left, this.fPoints[0].y, lineStylesPen)
        } else {
            chartCanvas.LineTo(R.Right, this.fPoints[0].y, lineStylesPen)
        }

        this.PaintMarkers()
    }

    assign(tool: TPtRightPriceLabel, isCopy: boolean = false): void {
        super.assign(tool, isCopy)

        this.pen = tool.pen
        this.font = tool.font
        this.fillBackgroundBrush = tool.fillBackgroundBrush
    }

    ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            textStyle: {
                key: 'textStyle',
                type: 'textStyle',
                label: 'toolsModal.fields.textStyle',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    size: fontParams.size,
                    style: fontStyleParams.style,
                    weight: fontStyleParams.weight
                }
            },
            lineStyle: {
                key: 'lineStyle',
                label: 'toolsModal.fields.line',
                value: this.fLineStyle,
                type: 'style'
            },
            fillBackground: {
                key: 'fillBackground',
                label: 'background',
                type: 'fillColor',
                isOptional: true,
                withOpacity: true,
                value: {
                    isActive: this.fShouldFillInside,
                    color: this.fillBackgroundBrush.getColor(),
                    opacity: this.fillBackgroundBrush.getOpacity()
                }
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: this.ShortName,
            toolName: this.ShortName == PaintToolNames.ptPriceLabelRight ? 'priceLabelRight' : 'priceLabelLeft'
        })
    }

    ImportFromDialog(): void {
        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()

        this.description = formattedToolSettings.description
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            formattedToolSettings.textStyle.size,
            StylingHelper.getTGpFontStyle({
                style: formattedToolSettings.textStyle.style,
                weight: formattedToolSettings.textStyle.weight
            })
        )
        this.brush.setColor(formattedToolSettings.textStyle.color)
        this.fLineStyle = formattedToolSettings.lineStyle.clone()
        this.fShouldFillInside = formattedToolSettings.fillBackground.isActive
        this.fillBackgroundBrush.setColor(formattedToolSettings.fillBackground.color)
        this.fillBackgroundBrush.setOpacity(formattedToolSettings.fillBackground.opacity)

        this.saveToManager()
        resetToolSettings()
    }
    getFontStyles(): FontStylesType {
        return {
            color: {
                value: this.brush.getColor(),
                hasDifferentValues: false
            },
            fontSize: this.font.getFontParams().size
        }
    }

    override setFontStyles(color: string, fontSize: number) {
        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            fontSize,
            StylingHelper.getTGpFontStyle({
                style: fontStyleParams.style,
                weight: fontStyleParams.weight
            })
        )

        this.fFontStyle.color = color
        this.fFontStyle.size = fontSize
        this.brush.setColor(color)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    getFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: this.fillBackgroundBrush.getColor(),
                hasDifferentValues: false
            },
            opacity: this.fillBackgroundBrush.getOpacity(),
            shouldFillInside: this.fShouldFillInside
        }
    }

    setFillColorParams(color: string, opacity: number) {
        this.chart.ChartWindow.saveStateWithNotify()

        this.fillBackgroundBrush.setColor(color)
        this.fillBackgroundBrush.setOpacity(opacity)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    get align(): TAlignment {
        return this.fAlign
    }

    set align(value: TAlignment) {
        this.fAlign = value
    }
}

export class TPtLeftPriceLabel extends TPtRightPriceLabel {
    constructor(aChart: IChart) {
        super(aChart, false)
        this.ShortName = PaintToolNames.ptPriceLabelLeft
        this.align = TAlignment.taLeftJustify
        this.icon = 86
        this.applySettings()
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptPriceLabelRight, TPtRightPriceLabel)
PaintToolManager.RegisterPaintTool(PaintToolNames.ptPriceLabelLeft, TPtLeftPriceLabel)
