import { memo, forwardRef, ComponentPropsWithoutRef, MouseEvent } from 'react'

import cx from 'classnames'

import type { TYPOGRAPHY_TYPES, WEIGHTS, SIZES, STYLES } from './types'
import type { COLOR_NAMES } from '@fto/ui'

import styles from './index.module.scss'

export type TypoProps = {
    type?: (typeof TYPOGRAPHY_TYPES)[number]
    color?: (typeof COLOR_NAMES)[number] | 'inherit'
    size?: keyof typeof SIZES
    weight?: keyof typeof WEIGHTS
    fontStyle?: keyof typeof STYLES
    truncate?: boolean
    underline?: boolean
    block?: boolean
    align?: 'left' | 'center' | 'right'
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void
}

type OtherNativeSpanProps = Omit<ComponentPropsWithoutRef<'span'>, keyof TypoProps>

export type TypographyProps = TypoProps & OtherNativeSpanProps

export const Typography = memo(
    forwardRef<HTMLSpanElement, TypographyProps>(
        (
            {
                type = 'regular',
                color = 'gray-1000',
                size,
                weight,
                fontStyle,
                underline = false,
                truncate = false,
                block = false,
                align = 'left',
                children,
                className,
                style,
                onClick,
                ...props
            },
            ref
        ) => (
            <span
                {...props}
                ref={ref}
                style={{
                    ...style,
                    ...{ color: `var(--color-${color})` }
                }}
                onClick={onClick}
                className={cx(
                    {
                        [styles[`type-${type}`]]: type,
                        [styles[`font-${size}`]]: size,
                        [styles[`font-weight-${weight}`]]: weight,
                        [styles[`font-style-${fontStyle}`]]: fontStyle,
                        [styles['font-truncate']]: truncate,
                        [styles[`align-${align}`]]: align,
                        [styles.isUnderline]: underline,
                        [styles.isBlock]: block,
                        [styles.clickable]: onClick
                    },
                    className
                )}
            >
                {children}
            </span>
        )
    )
)

Typography.displayName = 'Typography'

export default Typography
