import store from '@root/store'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '../delphi_compatibility/DateUtils'
import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'
import MockerForServerSymbols from '../mocks/MocksForDownloading/MockerForServerSymbols'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export class ServerSymbolInfo {
    public Broker: string
    public Symbol: string
    public Category: string
    public Subscription: number
    public IsAvailable: boolean
    public StartDate: TDateTime
    public EndDate: TDateTime
    public BaseCurrency: string
    public QuoteCurrency: string

    constructor(data: any) {
        this.Broker = data.Broker
        this.Symbol = data.Symbol
        this.Category = data.Category
        this.Subscription = data.Subscription
        this.IsAvailable = data.IsAvailable
        this.StartDate = DateUtils.FromString(data.Start)
        this.EndDate = DateUtils.FromString(data.End)
        this.BaseCurrency = data.BaseCurrency
        this.QuoteCurrency = data.QuoteCurrency
    }
}

class GlobalServerSymbolInfo {
    private static instance: GlobalServerSymbolInfo
    private symbols: ServerSymbolInfo[] = []
    private isSymbolsLoaded = false

    public static get Instance(): GlobalServerSymbolInfo {
        if (!GlobalServerSymbolInfo.instance) {
            GlobalServerSymbolInfo.instance = new GlobalServerSymbolInfo()
        }
        return GlobalServerSymbolInfo.instance
    }

    private constructor() {
        this.prepareSymbols()
    }

    public checkSymbolStore(): void {
        if (!this.isSymbolsLoaded) {
            throw new StrangeError('GlobalServerSymbolInfo: Data is not loaded')
        }
    }

    private prepareSymbols() {
        if (CommonUtils.IsInUnitTest) {
            this.populateSymbolsWithMocks()
            return
        }

        const result = store.getState().symbols

        if (result) {
            if (result.hasOwnProperty('loading')) {
                if (result.loading === 'succeeded') {
                    if (result.hasOwnProperty('symbols')) {
                        if (result.symbols.length > 0) {
                            for (const symbol of result.symbols) {
                                this.makeSureNoDuplicates(symbol)
                                this.symbols.push(new ServerSymbolInfo(symbol))
                            }
                        } else {
                            throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.symbols is empty')
                        }
                    } else {
                        throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.symbols is undefined')
                    }
                } else {
                    this.processNotSucceededStateForSymbols(result)
                }
            } else {
                throw new StrangeError('GlobalServerSymbolInfo: invalid symbols state')
            }
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols is undefined')
        }

        this.isSymbolsLoaded = true
        const range = this.getFullAvailableRangeByAllSymbols()
        GlobalTimezoneDSTController.Instance.updateDST_ByDateRange(range[0], range[1])
    }

    private processNotSucceededStateForSymbols(result: any) {
        switch (result.loading) {
            case 'pending': {
                throw new DataNotDownloadedYetError(
                    'GlobalServerSymbolInfo: store.getState().symbols.loading is loading'
                )
            }
            case 'failed': {
                throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.loading failed')
            }
            case 'idle': {
                throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.loading is idle')
            }
            // No default
        }
    }

    private populateSymbolsWithMocks() {
        const mockData = MockerForServerSymbols.symbols

        for (const mockSymbol of mockData) {
            this.makeSureNoDuplicates(mockSymbol)
            this.symbols.push(new ServerSymbolInfo(mockSymbol))
        }

        this.isSymbolsLoaded = true
    }

    private makeSureNoDuplicates(newSymbol: any) {
        const newSymbolName = newSymbol.Symbol
        const existingSymbolsWithThisName = this.findSymbolByName_returnUndefinedIfNotFound(newSymbolName)
        if (existingSymbolsWithThisName) {
            throw new StrangeError(`GlobalServerSymbolInfo: Symbol ${newSymbolName} already exists`)
        }
    }

    public getSymbols(): ServerSymbolInfo[] {
        this.checkSymbolStore()
        return this.symbols
    }

    public getSymbolsByDate(date: TDateTime): ServerSymbolInfo[] {
        this.checkSymbolStore()
        return this.symbols.filter((symbol) => {
            //TODO: Merge question to Oleksii Piun: should we use UTC date here?
            const startDate = symbol.StartDate
            const endDate = symbol.EndDate
            return DateUtils.MoreOrEqual(date, startDate) && DateUtils.LessOrEqual(date, endDate)
        })
    }

    public getSymbolCategory(symbol: string): string {
        this.checkSymbolStore()
        const symbolInfo = this.symbols.find((s) => s.Symbol === symbol)
        if (symbolInfo) {
            return symbolInfo.Category
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: symbol not found')
        }
    }

    private findSymbolByName_returnUndefinedIfNotFound(symbolName: string): ServerSymbolInfo | undefined {
        return this.symbols.find((symb) => symb.Symbol === symbolName)
    }

    public getServerSymbolInfo(symbolName: string): ServerSymbolInfo {
        this.checkSymbolStore()
        const symbol = this.findSymbolByName_returnUndefinedIfNotFound(symbolName)
        if (!symbol) {
            throw new StrangeError(`GlobalServerSymbolInfo: Symbol ${symbolName} not found`)
        }
        return symbol
    }

    private getFullAvailableRangeByAllSymbols(): [number, number] {
        this.checkSymbolStore()
        let minStartDate = Number.MAX_VALUE
        let maxEndDate = Number.MIN_VALUE
        for (const symbol of this.symbols) {
            if (DateUtils.LessOrEqual(symbol.StartDate, minStartDate)) {
                minStartDate = symbol.StartDate
            }
            if (DateUtils.MoreOrEqual(symbol.EndDate, maxEndDate)) {
                maxEndDate = symbol.EndDate
            }
        }
        return [minStartDate, maxEndDate]
    }
}

export default GlobalServerSymbolInfo
