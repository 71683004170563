const getBrowserType = (): string => {
    let userAgent = navigator.userAgent
    let browser = 'Unknown'

    // Detect Chrome
    if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
        browser = 'Google Chrome'
    }
    // Detect Chromium-based Edge
    else if (/Edg/.test(userAgent)) {
        browser = 'Microsoft Edge'
    }
    // Detect Firefox
    else if (/Firefox/.test(userAgent)) {
        browser = 'Mozilla Firefox'
    }
    // Detect Safari
    else if (/Safari/.test(userAgent)) {
        browser = 'Apple Safari'
    }
    // Detect Internet Explorer
    else if (/Trident/.test(userAgent)) {
        browser = 'Internet Explorer'
    }

    return browser
}

const getOS = (): string => {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = ''

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
    }

    return os
}

const getDeviceType = () => {
    const ua = navigator.userAgent
    const width = window.innerWidth
    const hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    if (hasTouchScreen && width < 768) {
        return 'mobile' // Assume smaller touch devices are mobile
    } else if (hasTouchScreen && width < 992) {
        return 'tablet' // Larger touch devices could be tablets
    } else if (/tablet|ipad|playbook|silk/i.test(ua)) {
        return 'tablet' // User agent explicitly states it's a tablet
    } else if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
            ua
        )
    ) {
        return 'mobile' // User agent explicitly states it's a mobile
    } else {
        return 'desktop' // Default to desktop
    }
}

export const getBrowserData = () => {
    return {
        os_type: getOS(),
        browser_type: getBrowserType(),
        device_type: getDeviceType(),
        screen_resolution: {
            width: screen.width,
            height: screen.height
        }
    }
}
