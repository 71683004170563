import { TDateTime, DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataRecordWithDate } from './TDataRecordWithDate'
import BasicConstants from '../../common/BasicClasses/BasicConstants'

export class TPriceRecord extends TDataRecordWithDate {
    public value: number

    constructor(dateTime: TDateTime, value: number) {
        super(dateTime)
        this.value = value
    }

    public IsEmpty(): boolean {
        return DateUtils.IsEmpty(this.DateTime) && this.value === BasicConstants.ArrEmptyValue
    }

    public isValidDate(): boolean {
        return !DateUtils.IsEmpty(this.DateTime)
    }
}
