import { FC, useCallback, useMemo } from 'react'
import cx from 'classnames'

import { ContextMenu, removeContextMenu, Flex, Typography } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import styles from './index.module.scss'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TimeZoneDesc } from '@fto/lib/Timezones&DST/TimeZoneManager'

type Props = {
    anchorX: number
    anchorY: number
}

const TImeZoneChangeContextMenu: FC<Props> = ({ anchorX, anchorY }) => {
    const options = useMemo(() => GlobalTimezoneDSTController.Instance.getTimezones(), [])

    const handleClick = useCallback((tzData: TimeZoneDesc) => {
        removeContextMenu(CONTEXT_MENU_NAMES.timeZones)
        GlobalTimezoneDSTController.Instance.setTimeZone(tzData)
    }, [])

    const activeTimeZone = useMemo(() => GlobalTimezoneDSTController.Instance.getTimezoneString(), [])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.timeZones} anchorX={anchorX} anchorY={anchorY}>
            <Flex direction='column' className={styles.List} gap={4}>
                {options.map((tz) => {
                    const isSelected = activeTimeZone === tz.value
                    return (
                        <Typography
                            key={tz.value}
                            color={isSelected ? 'white' : 'gray-1000'}
                            truncate
                            title={tz.description}
                            type='interface-regular'
                            className={cx(styles.Option, { [styles.Selected]: isSelected })}
                            onClick={() => handleClick(tz)}
                        >
                            {tz.label}
                        </Typography>
                    )
                })}
            </Flex>
        </ContextMenu>
    )
}

export default TImeZoneChangeContextMenu
