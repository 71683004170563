import { TColor, TFontStylesOperations, TFontStyle, TFontStyles } from '../delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '../extension_modules/common/CommonExternalInterface'
import { TMkFontStyle } from './VCLCanvas/TMkFontStyle'
import { TLineStyle } from './VCLCanvas/TLineStyle'
import {
    IGPFont,
    IGPGraphics,
    IGPGraphicsPath,
    IGPPen,
    IGPSolidBrush,
    TGPDashStyle,
    TGPFontFamily
} from '../delphi_compatibility/DelphiGDICompatibility'
import { TLineStyleRec } from './GraphicObjects/TLineStyleRec'
import { ColorHelperFunctions } from '../drawing_interface/ColorHelperFunctions'
import StrangeError from '../common/common_errors/StrangeError'
import { TRect } from '../extension_modules/common/CommonExternalInterface'

export enum TGpFontStyle {
    FontStyleRegular,
    FontStyleBold,
    FontStyleItalic,
    FontStyleBoldItalic,
    FontStyleUnderline,
    FontStyleStrikeOut
    // Add other styles if needed
}

export enum TRoundRectType {
    NONE = 0,
    TOP = 1,
    BOTTOM = 2,
    BOTH = 3
}

export type TGpFontStyles = TGpFontStyle[]

export class TGdiPlusCanvas {
    graphics: IGPGraphics
    pen: IGPPen
    brush: IGPSolidBrush
    path: IGPGraphicsPath
    font: IGPFont

    public get canvas(): HTMLCanvasElement {
        return this.graphics.Canvas
    }

    public get ctx(): CanvasRenderingContext2D {
        return this.graphics.Context
    }

    private fX!: number
    private fY!: number

    constructor(canvas: HTMLCanvasElement) {
        this.graphics = new IGPGraphics(canvas)
        this.pen = new IGPPen('black', 1) // Default values, will be replaced by SetPen methods
        this.brush = new IGPSolidBrush('black') // Default values, will be replaced by SetBrushColor methods
        this.path = new IGPGraphicsPath()
        // Assuming TGPFontFamily and IGPFont are defined as previously shown

        // Create a default font family
        const defaultFontFamily = new TGPFontFamily('Roboto Flex')
        // Default font size
        const defaultFontSize = 12
        // Default font styles (regular style as default)
        const defaultFontStyles: TGpFontStyles = [TGpFontStyle.FontStyleRegular]
        // Creating the default IGPFont object
        this.font = new IGPFont(defaultFontFamily, defaultFontSize, defaultFontStyles)
    }

    SetTextBaseline(baseline: 'top' | 'hanging' | 'middle' | 'alphabetic' | 'ideographic' | 'bottom'): void {
        this.graphics.Context.textBaseline = baseline
    }

    SetTextAlign(align: 'start' | 'end' | 'left' | 'right' | 'center'): void {
        this.graphics.Context.textAlign = align
    }

    MoveTo(x: number, y: number) {
        this.fX = x
        this.fY = y
    }

    LineTo(x: number, y: number, pen: IGPPen = this.pen) {
        this.graphics.drawLine(pen, this.fX, this.fY, x, y)
        this.MoveTo(x, y)
    }

    FillRect(rect: TRect, brush: IGPSolidBrush = this.brush): void {
        this.throwErrorIfPathIsNotInitialized()

        if (rect.Width <= 0 || rect.Height <= 0) {
            throw new StrangeError('Invalid rectangle dimensions.')
        }

        this.path.reset()
        this.MoveTo(rect.Left, rect.Top)
        this.LineToPath(rect.Right, rect.Top)
        this.LineToPath(rect.Right, rect.Bottom)
        this.LineToPath(rect.Left, rect.Bottom)
        this.LineToPath(rect.Left, rect.Top)
        this.FillPath(brush)
    }

    public FillRectRounded(
        rect: TRect,
        brush: IGPSolidBrush = this.brush,
        radius: number = 5,
        roundTopLeft: boolean = true,
        roundTopRight: boolean = true,
        roundBottomRight: boolean = true,
        roundBottomLeft: boolean = true
    ): void {
        if (!this.path) {
            throw new Error('Path is not initialized.')
        }

        if (rect.Width <= 0 || rect.Height <= 0) {
            throw new StrangeError('Invalid rectangle dimensions.')
            return
        }

        this.graphics.fillRoundRectangle(
            this.graphics.Context,
            rect.Left,
            rect.Top,
            rect.Width,
            rect.Height,
            radius,
            brush,
            roundTopLeft,
            roundTopRight,
            roundBottomRight,
            roundBottomLeft
        )
    }

    private throwErrorIfPathIsNotInitialized() {
        if (!this.path) {
            throw new StrangeError('Path is not initialized.')
        }
    }

    FillEllipse(R: TRect, brush: IGPSolidBrush = this.brush): void {
        this.graphics.fillEllipse(brush, R)
    }

    ellipse(R: TRect, pen: IGPPen = this.pen): void {
        this.graphics.drawEllipse(pen, R)
    }

    SetBrushColor(color: TColor, opacity?: number): void {
        // Ensure color string starts with '#'
        if (!color.startsWith('#')) {
            color = '#' + color
        }

        // Default to using the entire color string (including potential alpha)
        let colorToUse = color

        // If opacity is provided, or if the color string doesn't contain alpha (RGB format),
        // adjust the color string to use the provided opacity or full opacity.
        if (opacity !== undefined || color.length === 7) {
            colorToUse = ColorHelperFunctions.MakeColor(colorToUse)
        }

        this.brush.setColor(colorToUse)
        this.brush.setOpacity(opacity ?? 1)
    }

    private ConvertFontStyle(style: TFontStyles): TGpFontStyles {
        let result: TGpFontStyle[] = []

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsBold)) {
            result.push(TGpFontStyle.FontStyleBold)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsItalic)) {
            result.push(TGpFontStyle.FontStyleItalic)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsUnderline)) {
            result.push(TGpFontStyle.FontStyleUnderline)
        }

        if (TFontStylesOperations.hasFontStyle(style, TFontStyle.fsStrikeout)) {
            result.push(TGpFontStyle.FontStyleStrikeOut)
        }

        return result.length === 0 ? [TGpFontStyle.FontStyleRegular] : result
    }

    setFont(fontName: string, style: TFontStyles, size: number) {
        const fontFamily = new TGPFontFamily(fontName)
        this.font = new IGPFont(fontFamily, size, this.ConvertFontStyle(style))
    }

    LineToPath(x: number, y: number) {
        this.path.lineTo(x, y)
    }

    DrawPath(pen: IGPPen = this.pen) {
        this.graphics.drawPath(pen, this.path)
    }

    FillPath(brush: IGPSolidBrush = this.brush) {
        this.graphics.fillPath(brush, this.path)
    }

    ClearPath() {
        this.path = new IGPGraphicsPath() // Resets the path
    }

    setPenColor(color: TColor, opacity: number): void {
        // Use the MakeColor method to create a color with the specified opacity
        const newColor = ColorHelperFunctions.MakeColor(color, opacity)
        this.pen.setColor(newColor)
    }

    setPenStyle(style: TPenStyle | TGPDashStyle): void {
        this.pen.setPenStyle(style)
    }

    setPenWidth(width: number) {
        this.pen.setWidth(width)
    }

    SetPenFromStyle(style: TLineStyle): void {
        this.setPenWithOpacity(style.width, style.style, style.color, 255) // Default to full opacity
    }

    // Corresponds to the 1.1 SetPen method in Delphi
    SetPenFromLineStyleRec(style: TLineStyleRec): void {
        this.setPenWithOpacity(style.width, style.style, style.color, 255) // Default to full opacity
    }

    // Corresponds to the second SetPen method in Delphi
    setPenWithOpacity(width: number, style: TPenStyle, color: TColor, opacity: number): void {
        this.pen.setWidth(width)
        this.pen.setPenStyle(style)
        const newColor = ColorHelperFunctions.MakeColor(color, opacity)
        this.pen.setColor(newColor)
    }

    // Corresponds to the third SetPen method in Delphi
    SetPen(width: number, style: TPenStyle, color: TColor): void {
        this.pen.setWidth(width)
        this.pen.setPenStyle(style)
        // Assuming color includes opacity if needed
        this.pen.setColor(color)
    }

    textOut(
        x: number,
        y: number,
        s: string,
        font: IGPFont = this.font,
        brush: IGPSolidBrush = this.brush,
        modifyWithDpr = false
    ) {
        this.graphics.drawString(s, modifyWithDpr ? font.cloneWithDpr() : font, brush, x, y)
    }

    textOutByTmkFontStyle(x: number, y: number, s: string, mkFontStyle: TMkFontStyle, modifyWithDpr = false) {
        this.graphics.drawStringWithOwnParams(x, y, s, modifyWithDpr ? mkFontStyle.clone() : mkFontStyle)
    }

    TextWidth(text: string, font: IGPFont | string = this.font, modifyWithDpr = true): number {
        const fontObj = typeof font === 'string' ? IGPFont.fromString(font) : font
        const fontString = modifyWithDpr ? fontObj.cloneWithDpr().toString() : fontObj.toString()
        return TGdiPlusCanvas.CalculateTextWidth(this.graphics.Context, text, fontString)
    }

    TextHeight(text: string, font: IGPFont | string = this.font, modifyWithDpr = true): number {
        const fontObj = typeof font === 'string' ? IGPFont.fromString(font) : font
        const fontString = modifyWithDpr ? fontObj.cloneWithDpr().toString() : fontObj.toString()
        return TGdiPlusCanvas.CalculateTextHeight(this.graphics.Context, fontString)
    }

    public static CalculateTextHeight(canvasContext: CanvasRenderingContext2D, font: string): number {
        document.fonts.load(font)
        canvasContext.textBaseline = 'alphabetic'
        canvasContext.font = font

        let fontSizeStr
        if (font === '') {
            fontSizeStr = canvasContext.font.match(/\d+/)
        } else {
            fontSizeStr = font.match(/\d+/)
        }
        if (!fontSizeStr) {
            return 10 //TODO: maybe do something better here
        }
        let fontSize = parseInt(fontSizeStr[0])
        let textHeight = fontSize

        if (canvasContext.measureText) {
            let textMetrics = canvasContext.measureText('M')
            textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent
        }

        return textHeight
    }

    public static CalculateTextWidth(canvasContext: CanvasRenderingContext2D, text: string, font: string): number {
        document.fonts.load(font)
        canvasContext.textBaseline = 'alphabetic'
        canvasContext.font = font
        return canvasContext.measureText(text).width
    }

    public FrameRect(rect: TRect, pen: IGPPen = this.pen): void {
        this.throwErrorIfPathIsNotInitialized()

        if (rect.Width <= 0 || rect.Height <= 0) {
            throw new StrangeError('Invalid rectangle dimensions.')
        }

        this.path.reset()
        this.MoveTo(rect.Left, rect.Top)
        this.LineToPath(rect.Right, rect.Top)
        this.LineToPath(rect.Right, rect.Bottom)
        this.LineToPath(rect.Left, rect.Bottom)
        this.LineToPath(rect.Left, rect.Top)
        this.DrawPath(pen)
    }

    rectangle(R: TRect, pen: IGPPen = this.pen): void {
        this.throwErrorIfPathIsNotInitialized()

        pen.applyDashPattern(this.graphics.Context)

        this.path.reset()
        this.MoveTo(R.Left, R.Top)
        this.LineToPath(R.Right, R.Bottom)
        this.LineToPath(R.Left, R.Bottom)
        this.LineToPath(R.Left, R.Top)
        this.LineToPath(R.Right, R.Top)
        this.LineToPath(R.Right, R.Bottom)

        this.DrawPath(pen)

        pen.setDashPattern([])
        pen.applyDashPattern(this.graphics.Context)
    }

    arc(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        x3: number,
        y3: number,
        x4: number,
        y4: number,
        counterclockwise: boolean,
        fullEllipse: boolean,
        pen: IGPPen = this.pen
    ): void {
        this.graphics.drawDelphiStyleArc(pen, x1, y1, x2, y2, x3, y3, x4, y4, counterclockwise, fullEllipse)
    }

    drawButton(
        text: string,
        padding: number,
        borderRadius: number,
        x: number,
        y: number,
        color: string,
        font: IGPFont = this.font,
        iconWidth = 0,
        brush: IGPSolidBrush = this.brush
    ) {
        this.throwErrorIfPathIsNotInitialized()

        brush.setOpacity(1)

        const fontSize = font.getFontParams().size
        this.graphics.Context.font = fontSize + 'px Arial'

        const symbolMetrics = this.graphics.Context.measureText(text)

        const buttonHeight = 24
        const buttonWidth = symbolMetrics.width + iconWidth + padding * 2
        const textHeight = fontSize

        this.path.reset()
        this.graphics.roundRect(this.graphics.Context, x, y, buttonWidth, buttonHeight, borderRadius)

        brush.Color = color
        this.graphics.fillRoundRectangle(this.graphics.Context, x, y, buttonWidth, buttonHeight, borderRadius, brush)

        this.graphics.Context.strokeStyle = '#D0D5DD'
        this.graphics.Context.stroke()

        const symbolTextX = x + padding
        const symbolTextY = y + (buttonHeight + textHeight - padding) / 2

        brush.Color = '#000000'
        this.textOut(symbolTextX, symbolTextY, text, font, brush)

        return new TRect(x, y, x + buttonWidth, y + buttonHeight)
    }

    strokeRect(
        rect: TRect,
        roundedType: TRoundRectType = TRoundRectType.NONE,
        radius = 5,
        fillInside: boolean,
        pen: IGPPen,
        brus: IGPSolidBrush
    ): void {
        this.graphics.strokeRoundedRect(
            rect.Left,
            rect.Top,
            rect.Width,
            rect.Height,
            roundedType,
            radius,
            fillInside,
            pen,
            brus
        )
    }

    public bezierCurve(
        startX: number,
        startY: number,
        cp1X: number,
        cp1Y: number,
        cp2X: number,
        cp2Y: number,
        endX: number,
        endY: number,
        pen: IGPPen = this.pen
    ): void {
        this.graphics.drawBezierCurve(pen, startX, startY, cp1X, cp1Y, cp2X, cp2Y, endX, endY)
    }

    public drawCircle(x: number, y: number, radius: number, pen: IGPPen = this.pen): void {
        this.graphics.drawCircle(pen, x, y, radius)
    }
}
