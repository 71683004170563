import { t } from 'i18next'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TValueType } from '../api/IndicatorInterfaceUnit'
import { TIndexBuffer } from '../api/IIndicatorApi'
import { DelphiColors } from '../../../delphi_compatibility/DelphiBasicTypes'
import { TOptionType, TOptValue_number, TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TPenStyle } from '@fto/ui/lib/components/LineStyleSelector/constants'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class Ichimoku extends IndicatorImplementation {
    TenkanSenPeriod: TOptValue_number = new TOptValue_number(9)
    KijunSenPeriod: TOptValue_number = new TOptValue_number(26)
    SenkouSpanPeriod: TOptValue_number = new TOptValue_number(52)
    ChinkouSpanPeriod: TOptValue_number = new TOptValue_number(26)

    TenkanSen!: TIndexBuffer
    KijunSen!: TIndexBuffer
    ChinkouSpan!: TIndexBuffer
    SenkouSpanA!: TIndexBuffer
    SenkouSpanB!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.ichimoku'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)

        this.api.AddSeparator('Common')

        this.api.RegOption(
            t('indicatorModal.ichimoku.fields.tenkanSenPeriod'),
            TOptionType.ot_Integer,
            this.TenkanSenPeriod
        )
        this.api.SetOptionRange(t('indicatorModal.ichimoku.fields.tenkanSenPeriod'), 1, INT_MAX)

        this.api.RegOption(
            t('indicatorModal.ichimoku.fields.kijunSenPeriod'),
            TOptionType.ot_Integer,
            this.KijunSenPeriod
        )
        this.api.SetOptionRange(t('indicatorModal.ichimoku.fields.kijunSenPeriod'), 1, INT_MAX)

        this.api.RegOption(
            t('indicatorModal.ichimoku.fields.senkouSpanPeriod'),
            TOptionType.ot_Integer,
            this.SenkouSpanPeriod
        )
        this.api.SetOptionRange(t('indicatorModal.ichimoku.fields.senkouSpanPeriod'), 1, INT_MAX)

        this.api.RegOption(
            t('indicatorModal.ichimoku.fields.chinkouSpanPeriod'), // Регистрируем новый параметр
            TOptionType.ot_Integer,
            this.ChinkouSpanPeriod
        )
        this.api.SetOptionRange(t('indicatorModal.ichimoku.fields.chinkouSpanPeriod'), 1, INT_MAX)

        this.TenkanSen = this.api.CreateIndexBuffer()
        this.KijunSen = this.api.CreateIndexBuffer()
        this.ChinkouSpan = this.api.CreateIndexBuffer()
        this.SenkouSpanA = this.api.CreateIndexBuffer()
        this.SenkouSpanB = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(5)

        this.api.SetIndexBuffer(0, this.TenkanSen)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, DelphiColors.clRed)
        this.api.SetIndexLabel(0, t('indicatorModal.ichimoku.fields.tenkanSen'))

        this.api.SetIndexBuffer(1, this.KijunSen)
        this.api.SetIndexLabel(1, t('indicatorModal.ichimoku.fields.kijunSen'))
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, DelphiColors.clBlue)

        this.api.SetIndexBuffer(2, this.ChinkouSpan)
        this.api.SetIndexLabel(2, t('indicatorModal.ichimoku.fields.chinkouSpan'))
        this.api.SetIndexStyle(2, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, DelphiColors.clGreen)

        this.api.SetIndexBuffer(3, this.SenkouSpanA)
        this.api.SetIndexLabel(3, t('indicatorModal.ichimoku.fields.senkouSpanA'))
        this.api.SetIndexStyle(3, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FFAB7964')

        this.api.SetIndexBuffer(4, this.SenkouSpanB)
        this.api.SetIndexLabel(4, t('indicatorModal.ichimoku.fields.senkouSpanB'))
        this.api.SetIndexStyle(4, TDrawStyle.ds_Fill, TPenStyle.psSolid, 1, '#9E9EFF63')
    }

    Calculate(index: number): void {
        // Calculate Tenkan-sen
        let highest = this.api.GetHighestValue(TValueType.vt_High, index, this.TenkanSenPeriod.value)
        let lowest = this.api.GetLowestValue(TValueType.vt_Low, index, this.TenkanSenPeriod.value)
        this.TenkanSen.setValue(index, (highest + lowest) / 2)

        // Calculate Kijun-sen
        highest = this.api.GetHighestValue(TValueType.vt_High, index, this.KijunSenPeriod.value)
        lowest = this.api.GetLowestValue(TValueType.vt_Low, index, this.KijunSenPeriod.value)
        this.KijunSen.setValue(index, (highest + lowest) / 2)

        // Calculate Chinkou Span
        this.ChinkouSpan.setValue(index, this.api.Close(index))

        // Calculate Senkou Span A
        this.SenkouSpanA.setValue(index, (this.TenkanSen.getValue(index) + this.KijunSen.getValue(index)) / 2)

        // Calculate Senkou Span B
        highest = this.api.GetHighestValue(TValueType.vt_High, index, this.SenkouSpanPeriod.value)
        lowest = this.api.GetLowestValue(TValueType.vt_Low, index, this.SenkouSpanPeriod.value)
        this.SenkouSpanB.setValue(index, (highest + lowest) / 2)
    }

    OnParamsChange() {
        this.api.SetBufferShift(2, -this.ChinkouSpanPeriod.value) // Используем период Chinkou Span
        this.api.SetBufferShift(3, this.KijunSenPeriod.value)
        this.api.SetBufferShift(4, this.KijunSenPeriod.value)
    }
}
