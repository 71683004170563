import { FC, useEffect } from 'react'
import { useModalState } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { IndicatorModal } from '@root/pages/ChartPage/components/IndicatorModal'
import OrderModal from '@root/pages/ChartPage/components/OrderModal'
import { GraphToolModal } from '@root/pages/ChartPage/components/GraphToolModal'

import { JumpToDateModal } from '../JumpToModal'
import CustomTimeframeModal from '../CustomTimeframeModal'
import ChangeBalanceModal from '../ChangeBalanceModal'
import LongTermsTestModal from '../LongTermsTestModal'
import EducationModal from '@root/pages/ChartPage/components/EducationModal'
import EducationMessage from '@root/pages/ChartPage/components/EducationMessage'
import RestartProjectModal from '../RestartProjectModal'
import DuplicateProjectModal from '@root/pages/Dashboard/components/Modals/DuplicateProjectModal/DuplicateProjectModal'
import { SaveTemplateModal } from '../SaveTemplateModal'
import { ReplaceTemplateModal } from '../ReplaceTemplateModal'
import { DeleteTemplateModal } from '../DeleteTemplateModal'
import { ApplyToAllWarningModal } from '../ApplyToAllWarningModal'

import { CreateProjectModal } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectModal'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { FailModal, RestartModal, SuccessModal } from '../TaskModals'
import { RateUsModal } from '../RateUsModal'
import { JumpToSettingsModal } from '../JumpToSettingsModal'
import PartialClosingModal from '../PartialClosingModal'

const ModalsWrapper: FC = () => {
    const { open: isNewProjectModalOpen } = useModalState(MODAL_NAMES.projects.create)
    const { open: isDuplicateModalOpen, props: duplicateModalProps } = useModalState(MODAL_NAMES.projects.duplicate)
    const { open: isIndicatorModalOpen, props: indicatorModalProps } = useModalState(MODAL_NAMES.chart.indicatorModal)
    // const { open: isTrendModalOpen } = useModalState(MODAL_NAMES.chart.trendLine)
    const { open: isGraphToolsModalOpen, props: graphToolsModal } = useModalState(MODAL_NAMES.chart.graphTools)
    const { open: isJumpToDateModalOpen } = useModalState(MODAL_NAMES.chart.jumpToDate)
    const { open: isJumpToSettingsModalOpen, props: jumpToSettingsModalProps } = useModalState(
        MODAL_NAMES.chart.jumpToSettings
    )
    const { open: isOrderModalOpen, props: orderModalProps } = useModalState(MODAL_NAMES.chart.orderModal)
    const { open: isChangeBalanceModalOpen } = useModalState(MODAL_NAMES.chart.changeBalance)
    const { open: isEducationModalOpen } = useModalState(MODAL_NAMES.chart.educationModal)
    const { open: isEducationMessageOpen, props: message } = useModalState(MODAL_NAMES.messages.educationMessage)
    const { open: isRestartProjectModalOpen } = useModalState(MODAL_NAMES.chart.restartModal)
    const { open: isSaveTempalteModalOpen, props: saveTemplateModalProps } = useModalState(
        MODAL_NAMES.chart.saveTemplate
    )
    const { open: isReplaceTemplateModalOpen, props: replaceTemplateModalProps } = useModalState(
        MODAL_NAMES.chart.replaceTemplate
    )
    const { open: isDeleteTemplateModalOpen, props: deleteTemplateModalProps } = useModalState(
        MODAL_NAMES.chart.deleteTemplate
    )
    const { open: isApplyToAllWarningModalOpen, props: applyToAllWarningModalProps } = useModalState(
        MODAL_NAMES.chart.applyToAllWarning
    )
    const { open: isRateUsModalOpen } = useModalState(MODAL_NAMES.chart.rateUsModal)
    const { open: isPartialModalOpen, props: partialModalProps } = useModalState(MODAL_NAMES.chart.partialClosing)
    const { open: isFailModalOpen, props: failModalProps } = useModalState(MODAL_NAMES.chart.education.fail)
    const { open: isSuccessModalOpen } = useModalState(MODAL_NAMES.chart.education.success)
    const { open: isRestartModalOpen } = useModalState(MODAL_NAMES.chart.education.restart)
    const { open: isCustomTimeframeModalOpen, props: customTimeframeModalProps } = useModalState(
        MODAL_NAMES.chart.customTimeFrame
    )
    const { open: isLongTermsTestModalOpen } = useModalState(MODAL_NAMES.devEnv.longTermTests)

    useEffect(() => {
        GlobalChartsController.Instance.isForbiddenToChangeActiveChart = isOrderModalOpen
    }, [isOrderModalOpen])

    return (
        <>
            {isNewProjectModalOpen && <CreateProjectModal />}
            {isDuplicateModalOpen && <DuplicateProjectModal {...duplicateModalProps} />}
            {isIndicatorModalOpen && <IndicatorModal {...indicatorModalProps} />}
            {isGraphToolsModalOpen && <GraphToolModal {...graphToolsModal} />}
            {isJumpToDateModalOpen && <JumpToDateModal />}
            {isJumpToSettingsModalOpen && <JumpToSettingsModal {...jumpToSettingsModalProps} />}
            {isOrderModalOpen && <OrderModal {...orderModalProps} />}
            {isChangeBalanceModalOpen && <ChangeBalanceModal />}
            {isEducationModalOpen && <EducationModal />}
            {isEducationMessageOpen && <EducationMessage {...message} />}
            {isRestartProjectModalOpen && <RestartProjectModal />}
            {isRateUsModalOpen && <RateUsModal />}
            {isPartialModalOpen && <PartialClosingModal {...partialModalProps} />}
            {isSaveTempalteModalOpen && <SaveTemplateModal {...saveTemplateModalProps} />}
            {isReplaceTemplateModalOpen && <ReplaceTemplateModal {...replaceTemplateModalProps} />}
            {isDeleteTemplateModalOpen && <DeleteTemplateModal {...deleteTemplateModalProps} />}
            {isApplyToAllWarningModalOpen && <ApplyToAllWarningModal {...applyToAllWarningModalProps} />}
            {isRateUsModalOpen && <RateUsModal {...failModalProps} />}
            {isFailModalOpen && <FailModal {...failModalProps} />}
            {isSuccessModalOpen && <SuccessModal />}
            {isRestartModalOpen && <RestartModal />}
            {isCustomTimeframeModalOpen && <CustomTimeframeModal {...customTimeframeModalProps} />}
            {isLongTermsTestModalOpen && <LongTermsTestModal />}
        </>
    )
}

export default ModalsWrapper
