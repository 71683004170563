import React, { FC } from 'react'
import { Typography } from '@fto/ui'
import { useTranslation } from 'react-i18next'

type Props = {
    text: string
}
const RequiredLabel: FC<Props> = ({ text }) => {
    const { t } = useTranslation()

    return (
        <Typography>
            {text}{' '}
            <Typography color={'gray-600'} type={'interface-medium'}>
                ({t('global.required')})
            </Typography>
        </Typography>
    )
}
export default RequiredLabel
