import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import {
    ServerDayChunkData_Ticks_JSON,
    ServerDayTickDataMSGPACK,
    TServerTicksOfASecondJSON,
    TServerTicksOfASecondMSGPACK
} from '../data_downloading/ServerDataClasses'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import { IMockTickChunk, IMockTickRecord } from '@fto/lib/mocks/MocksForTesting/MockDataInterfaces'
import { TTickRecord } from '../DataClasses/TTickRecord'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export default class Ticks_converter {
    static ConvertFromMocksToTicks(data: IMockTickChunk): TTickRecord[] {
        const result: TTickRecord[] = []
        for (const mockTick of data.ticks) {
            result.push(this.ConvertMockTick_2_TTickRecord(mockTick))
        }
        return result
    }

    static ConvertMockTick_2_TTickRecord(mockTick: IMockTickRecord) {
        return new TTickRecord(mockTick.DateTime, mockTick.Bid, mockTick.Ask, 0)
    }

    static ConvertFromJSONToTicks(dataString: string): TTickRecord[] {
        const serverTickDays: ServerDayChunkData_Ticks_JSON[] = JSON.parse(dataString)

        if (serverTickDays.length > 1) {
            // Data length mismatch
            throw new StrangeError('more than 1 day of data were downloaded for the chunk')
        }

        return Ticks_converter.ConvertServerTicksToTickRecordsJSON(serverTickDays[0])
    }

    private static ConvertServerTicksToTickRecordsJSON(serverTicks: ServerDayChunkData_Ticks_JSON): TTickRecord[] {
        const fakeMmStart = 10 //to aviod having several ticks from different currencies at the same time
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2 //to aviod having several ticks from different currencies at the same time
        //10 is the smallest number because we do not have enough precision to go lower than that

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks.Ticks) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondJSON_2_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks.Day,
                    fakeMmStart,
                    fakeMsIncrement
                )
            ) //TODO: optimize this?
        }
        return result
    }

    private static ConvertServerTicksOfASecondJSON_2_TTickRecord(
        serverTicksOfASecond: TServerTicksOfASecondJSON,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number
    ): TTickRecord[] {
        const result: TTickRecord[] = []

        //FIXME: temprorary solution to avoid having several ticks from different currencies at the same time
        //TODO: change this once the server has ticks in milliseconds
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement //add a fake 20 milliseconds to the time to make sure that ticks are not on top of each other.
        //if we add just 1 millisecond, then we do not have enough precision of the DateTime type to distinguish ticks that are on top of each other

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.Time + dayStart) * 1000

        for (const serverTick of serverTicksOfASecond.Ticks) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds > 700) {
                DebugUtils.error(`too many ticks in a second 1 ${tickDateTime}`)
                millisecondsIncrement = 5 //reduce the increment to have more ticks in a second.
            }

            if (fakeMilliseconds >= 1000) {
                DebugUtils.error(`too many ticks in a second 2 ${tickDateTime}`)
                continue
            }

            result.push(new TTickRecord(tickDateTime, serverTick.Bid, serverTick.Ask, 0))
        }
        return result
    }

    static ConvertServerTicksToTickRecordsMSGPACK(serverTicks: ServerDayTickDataMSGPACK): TTickRecord[] {
        const fakeMmStart = 10 //to aviod having several ticks from different currencies at the same time
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2 //to aviod having several ticks from different currencies at the same time
        //10 is the smallest number because we do not have enough precision to go lower than that

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks.t) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondMSGPACK_2_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks.d,
                    fakeMmStart,
                    fakeMsIncrement
                )
            ) //TODO: optimize this?
        }
        return result
    }

    private static ConvertServerTicksOfASecondMSGPACK_2_TTickRecord(
        serverTicksOfASecond: TServerTicksOfASecondMSGPACK,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number
    ): TTickRecord[] {
        const result: TTickRecord[] = []
        //TODO: change this when the server is able to give us the data in the correct format (ticks in milliseconds)
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement //add a fake 20 milliseconds to the time to make sure that ticks are not on top of each other.
        //if we add just 1 millisecond, then we do not have enough precision of the DateTime type to distinguish ticks that are on top of each other

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.t + dayStart) * 1000
        for (const serverTick of serverTicksOfASecond.d) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds >= 700) {
                DebugUtils.error(`too many ticks in a second 3 ${tickDateTime}`)
                millisecondsIncrement = 10 //reduce the increment to have more ticks in a second.
            }

            //TODO: should we get volume somewhere?
            result.push(new TTickRecord(tickDateTime, Number(serverTick.b), Number(serverTick.a), 0))
        }
        return result
    }

    private static ConvertServerTicksOfASecondBINARY_TTickRecord(
        serverTicksOfASecond: any,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number
    ): TTickRecord[] {
        const result: TTickRecord[] = []
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.time + dayStart) * 1000
        for (const serverTick of serverTicksOfASecond.ticks) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds >= 700) {
                DebugUtils.error(`too many ticks in a second 4 ${tickDateTime}`)
                millisecondsIncrement = 10 //reduce the increment to have more ticks in a second.
            }

            result.push(new TTickRecord(tickDateTime, Number(serverTick.bid), Number(serverTick.ask), 0))
        }
        return result
    }

    static ConvertServerTicksToTickRecordsBINARY(serverTicks: any): TTickRecord[] {
        const fakeMmStart = 10
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks[0].ticks) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondBINARY_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks[0].day,
                    fakeMmStart,
                    fakeMsIncrement
                )
            )
        }
        return result
    }
}
