import { DebugUtils } from '../DebugUtils'
import { Command } from './Command'

class DebugLogCommand extends Command {
    execute() {
        if (DebugUtils.DebugMode) {
            DebugUtils.Instance.copyLogStringToClipboard()
        }
    }
}

export { DebugLogCommand }
