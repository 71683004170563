import { INamed } from '@fto/lib/utils/INamed'
import { IVeryBasicProcRec } from '../CommonProcRecInterfaces/IVeryBasicProcRec'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'

export abstract class VeryBasicProcRecImplementation implements INamed {
    protected _dName = CommonUtils.generateUniqueId()

    //this unique id is useful in debug
    //it will be overridden in child classes
    protected generateDName(): string {
        return CommonUtils.generateUniqueId()
    }

    constructor() {
        this._dName = this.generateDName()
    }

    public get DName(): string {
        return this._dName
    }

    public toString(): string {
        return this.DName
    }
    public abstract GetImplementation(): IVeryBasicProcRec
}
