import { forwardRef } from 'react'
import * as React from 'react'

import cx from 'classnames'

import styles from './index.module.scss'

type Props = {
    required?: boolean
}

export type LabelProps = Omit<React.ComponentPropsWithoutRef<'label'>, keyof Props> & Props

const Required = () => <span className={cx(styles.required)}>*</span>

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
    ({ required = false, className, children, ...rest }, ref) => {
        return (
            <label {...rest} ref={ref} className={cx(styles.label, className)}>
                {children}
                {required && <Required />}
            </label>
        )
    }
)

Label.displayName = 'InputField.Label'

export default Label
