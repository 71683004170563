import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export default class OBV extends IndicatorImplementation {
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    OBVBuf!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.SetBackOffsetForCalculation(200)
        this.api.IndicatorShortName(t('indicators.obv'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.OBVBuf = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.OBVBuf)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 2, '#0000FF')
        this.api.SetIndexLabel(0, t('indicatorModal.obv.fields.obv'))
    }

    Calculate(index: number): void {
        if (index >= this.api.Bars()) {
            return
        }

        const price0: number = this.api.GetPrice(index, this.ApplyToPrice.value)
        const price1: number = this.api.GetPrice(index + 1, this.ApplyToPrice.value)

        if (price0 > price1) {
            this.OBVBuf.setValue(index, this.OBVBuf.getValue(index + 1) + this.api.Volume(index))
        } else if (price0 < price1) {
            this.OBVBuf.setValue(index, this.OBVBuf.getValue(index + 1) - this.api.Volume(index))
        } else {
            this.OBVBuf.setValue(index, this.OBVBuf.getValue(index + 1))
        }
    }
}
