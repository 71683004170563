import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { IVisibleIndexBuffer } from '@fto/lib/extension_modules/indicators/api/ITVisibleIndexBuffer'
import { TChart } from '../chart_classes/BasicChart'
import { TPointsList } from '../chart_classes/auxiliary_classes_charts/TPointsList'
import { TPointPair } from '../chart_classes/auxiliary_classes_charts/TPointPair'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'

export class TSelIndValue {
    runtimeIndicator: TRuntimeIndicator | null
    buffer: IVisibleIndexBuffer | null
    index: number
    value: number
    sender: TChart | null = null

    constructor() {
        this.runtimeIndicator = null
        this.buffer = null
        this.index = 0
        this.value = 0
    }

    init(indicator: TRuntimeIndicator, buff: IVisibleIndexBuffer, idx: number, v: number): void {
        this.runtimeIndicator = indicator
        this.buffer = buff
        this.index = idx
        this.value = v
    }

    clear(): void {
        this.runtimeIndicator = null
    }

    empty(): boolean {
        // Check if the indicator is null
        return this.runtimeIndicator === null
    }

    equalTo(rec: TSelIndValue): boolean {
        return (
            this.runtimeIndicator === rec.runtimeIndicator &&
            this.buffer?.buffer === rec.buffer?.buffer &&
            this.index === rec.index &&
            this.value === rec.value
        )
    }

    protected FilterPoints(points: TPointsList, threshold: number): TPointPair[] {
        if (points.length < 2) return points // Need at least 3 points to apply the filter to the first and last points

        const filteredPoints: TPointPair[] = []

        // Check the first point against the second point
        if (
            Math.abs(points[1].x - points[0].x) <= threshold &&
            Math.abs(points[1].y1 - points[0].y1) <= threshold &&
            Math.abs(points[1].y2 - points[0].y2) <= threshold
        ) {
            filteredPoints.push(points[0])
        }

        // Add all middle points
        for (let i = 1; i < points.length - 1; i++) {
            filteredPoints.push(points[i])
        }

        // Check the last point against the second to last point
        const lastPoint = points[points.length - 1]
        const secondLastPoint = points[points.length - 2]
        if (
            Math.abs(lastPoint.x - secondLastPoint.x) <= threshold &&
            Math.abs(lastPoint.y1 - secondLastPoint.y1) <= threshold &&
            Math.abs(lastPoint.y2 - secondLastPoint.y2) <= threshold
        ) {
            filteredPoints.push(lastPoint)
        }

        return filteredPoints
    }

    drawMarker(canvas: CanvasRenderingContext2D, chart: TChart): void {
        if (this.buffer && this.buffer.buffer && this.buffer.IsVisible()) {
            const color = this.buffer.style.color || '#000000'

            const x = chart.GetX(chart.GetMouseIndex())
            const y = chart.GetY(this.value)

            canvas.beginPath()
            canvas.arc(x || 0, y || 0, 4, 0, 2 * Math.PI)
            canvas.fillStyle = color
            canvas.fill()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Could not get buffer or buffer.buffer')
        }
    }
}
