export enum TCriteriaType {
    NUMBER_OF_OPENED_ORDERS = 'NUMBER_OF_OPENED_ORDERS',
    NUMBER_OF_CLOSED_ORDERS = 'NUMBER_OF_CLOSED_ORDERS',
    TOTAL_PNL = 'TOTAL_PNL',
    FIRST_ORDER_OPEN_PRICE = 'FIRST_ORDER_OPEN_PRICE',
    TIME_RANGE_FOR_COMPLETE_TASK = 'TIME_RANGE_FOR_COMPLETE_TASK',
    DAY_PROCESSED = 'DAY_PROCESSED',
    MONTH_PROCESSED = 'MONTH_PROCESSED',
    TOTAL_TRADES = 'TOTAL_TRADES',
    PROFIT_TRADES = 'PROFIT_TRADES',
    LOSS_TRADES = 'LOSS_TRADES',
    PROFIT_TRADES_CONS = 'PROFIT_TRADES_CONS',
    LOSS_TRADES_CONS = 'LOSS_TRADES_CONS',
    TRADES_PER_DAY = 'TRADES_PER_DAY',
    TRADES_PER_MONTH = 'TRADES_PER_MONTH',
    PROFIT_TRADES_PER_MONTH = 'PROFIT_TRADES_PER_MONTH',
    LOSS_TRADES_PER_MONTH = 'LOSS_TRADES_PER_MONTH',
    MAX_PROFIT_TRADE = 'MAX_PROFIT_TRADE',
    MAX_LOSS_TRADE = 'MAX_LOSS_TRADE',
    NET_PROFIT = 'NET_PROFIT',
    GROSS_PROFIT = 'GROSS_PROFIT',
    GROSS_LOSS = 'GROSS_LOSS',
    PROFIT_PER_MONTH = 'PROFIT_PER_MONTH',
    AVERAGE_PROFIT = 'AVERAGE_PROFIT',
    AVERAGE_LOSS = 'AVERAGE_LOSS',
    MAX_DRAWDOWN = 'MAX_DRAWDOWN',
    PROFIT_FACTOR = 'PROFIT_FACTOR',
    RETURN_PERCENTS = 'RETURN_PERCENTS',
    MAX_LOT_USED = 'MAX_LOT_USED',
    RESTORATION_FACTOR = 'RESTORATION_FACTOR',
    RELIABILITY_FACTOR = 'RELIABILITY_FACTOR',
    PROFIT_PROBABILITY = 'PROFIT_PROBABILITY',
    LOSS_PROBABILITY = 'LOSS_PROBABILITY',
    BALANCE = 'BALANCE',
    EQUITY = 'EQUITY',
    MARGIN = 'MARGIN',
    FREE_MARGIN = 'FREE_MARGIN'
}

export enum TCriteriaOperator {
    MORE = 'MORE',
    LESS = 'LESS',
    EQUAL = 'EQUAL',
    MORE_OR_EQUAL = 'MORE_OR_EQUAL',
    LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    IN_RANGE = 'IN_RANGE'
}

export type TCriteriaBase = {
    criteriaType: TCriteriaType
    operator: TCriteriaOperator
}

export type TCriteria = TCriteriaBase & {
    value: number
}

export type TCriteriaRange = TCriteriaBase & {
    valueStart: number
    valueEnd: number
}

export type TTutorialTask = {
    name: string
    criteria: TCriteriaBase[]
    trueResult: string
    falseResult: string
    checkInterval: number
    description: string
    isPassed?: boolean
}

export type TEducationTask = {
    taskId: number
    presetId: number
    title: string
    intro: string
    checks: TTutorialTask[]
    redirectLink?: string
}

// const x = {
//     taskId: 1,
//     presetId: 1,
//     intro: 'Look at the chart from 2024-04-15 to 2024-04-16 and open a deal at the best price and close it earning at least $100. You can only open one position.',
//     checks: [
//         {
//             name: 'Open order at right price',
//             criteria: [
//                 {
//                     criteriaType: 'NUMBER_OF_OPENED_ORDERS',
//                     operator: 'MORE_OR_EQUAL',
//                     value: 1
//                 },
//                 {
//                     criteriaType: 'First_order_price',
//                     operator: 'IN_RANGE',
//                     valueStart: '1.2000',
//                     valueEnd: '1.2050'
//                 },
//                 {
//                     criteriaType: 'TIME_RANGE_FOR_COMPLETE_TASK',
//                     operator: 'IN_RANGE',
//                     valueStart: '2024-04-15',
//                     valueEnd: '2024-04-16'
//                 }
//             ],
//             trueResult: "Yes, that's right, you opened a deal at the most favorable moment.",
//             falseResult: 'No, unfortunately you will not be able to earn money from this position.',
//             checkInterval: 1 // in history minutes
//         },
//         {
//             name: 'Close order at profit of at least $100',
//             criteria: [
//                 {
//                     criteriaType: 'NUMBER_OF_CLOSED_ORDERS',
//                     operator: 'MORE_OR_EQUAL',
//                     value: 1
//                 },
//                 {
//                     criteriaType: 'TOTAL_PNL',
//                     operator: 'MORE_OR_EQUAL',
//                     value: 100
//                 }
//             ],
//             trueResult: 'Congrats! You have earned $<Profit value>!',
//             falseResult: 'Sorry, but you have not been able to earn at least $100.'
//         }
//     ]
// }
