import './Calendar.scss'
import classNames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { FC, useCallback, useMemo } from 'react'
import Calendar from 'react-calendar'
import { CURRENT_LANGUAGE_KEY } from '@root/constants/localStorageKeys'

type ValuePiece = Date | null
dayjs.extend(utc)

export type CalendarValue = ValuePiece | [ValuePiece, ValuePiece]

type Props = {
    className?: string
    calendarClassName?: string
    setCalendarValue?: (value: CalendarValue) => void
    initialDate?: Date
    maxDate?: Date
    minDate?: Date
    showTime?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const AppCalendar: FC<Props> = ({
    className,
    calendarClassName,
    setCalendarValue,
    maxDate,
    minDate,
    initialDate,
    showTime = false,
    ...rest
}) => {
    const handleTileClassName = useCallback(
        ({ date, view }: { date: ValuePiece; view: string }): string | null => {
            // Ensure 'date' and 'value' are both valid Date objects to avoid runtime errors
            if (
                view === 'month' &&
                date &&
                initialDate &&
                dayjs.utc(date).valueOf() === dayjs.utc(initialDate).valueOf()
            ) {
                return 'react-calendar__tile--selected'
            }
            return null // Return null or an empty string if no custom class should be applied
        },
        [initialDate]
    )

    const localeKey = useMemo(() => {
        return localStorage.getItem(CURRENT_LANGUAGE_KEY) || undefined
    }, [])

    const handleChange = (value: CalendarValue): void => {
        if (Array.isArray(value) || !value) {
            return
        }

        const utcDate = new Date(
            Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes())
        )
        setCalendarValue && setCalendarValue(utcDate)
    }
    return (
        <div {...rest} className={className}>
            <Calendar
                tileClassName={handleTileClassName}
                minDate={minDate}
                maxDate={maxDate}
                className={classNames(calendarClassName)}
                onChange={handleChange}
                value={initialDate}
                locale={localeKey}
            />
            {showTime && (
                <input
                    type='time'
                    defaultValue={initialDate ? dayjs(initialDate).format('HH:mm') : '00:00'}
                    onChange={(e) => {
                        const [hours, minutes] = e.target.value.split(':').map(Number)
                        const newDate = new Date(initialDate || new Date())
                        newDate.setHours(hours, minutes)
                        const utcDate = new Date(
                            Date.UTC(
                                newDate.getFullYear(),
                                newDate.getMonth(),
                                newDate.getDate(),
                                newDate.getHours(),
                                newDate.getMinutes()
                            )
                        )
                        setCalendarValue && setCalendarValue(utcDate)
                    }}
                />
            )}
        </div>
    )
}

export default AppCalendar
