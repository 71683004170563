import { Button, Checkbox, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback, useState } from 'react'

import { t } from 'i18next'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    withDontShowAgainCheckbox: boolean
    onCancel: () => void
    onApply: (dontShowAgain: boolean) => void
}

export const ApplyToAllWarningModal: FC<Props> = ({ withDontShowAgainCheckbox = false, onCancel, onApply }) => {
    const [dontShowCheckboxValue, setDontShowCheckboxValue] = useState(false)

    const onClosing = useCallback(() => {
        onCancel()
        removeModal(MODAL_NAMES.chart.applyToAllWarning)
    }, [onCancel])

    const onSubmitting = useCallback(() => {
        withDontShowAgainCheckbox && onApply(dontShowCheckboxValue)
        removeModal(MODAL_NAMES.chart.applyToAllWarning)
    }, [onApply, withDontShowAgainCheckbox, dontShowCheckboxValue])

    return (
        <Modal size='xs' name={MODAL_NAMES.chart.applyToAllWarning} withClickOutside={false}>
            <Modal.Content>
                <Flex direction='column' alignItems='center' gap={12}>
                    <Icon name='attention-colored' size={56} />
                    <Typography type='h5-semibold' color='gray-1000'>
                        {t('templates.applyToAllWarningModal.title')}
                    </Typography>
                    <Typography type='interface-regular' align='center'>
                        {t('templates.applyToAllWarningModal.question')}
                    </Typography>
                    {withDontShowAgainCheckbox && (
                        <Checkbox checked={dontShowCheckboxValue} onChange={setDontShowCheckboxValue}>
                            {t('templates.applyToAllWarningModal.remember')}
                        </Checkbox>
                    )}
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.Controls}>
                <div className={styles.Grid}>
                    <Button type='secondary' label={t('global.cancel')} onClick={onClosing} block />
                    <Button
                        type='primary'
                        label={t('templates.applyToAllWarningModal.submit')}
                        onClick={onSubmitting}
                        block
                    />
                </div>
            </Modal.Controls>
        </Modal>
    )
}
