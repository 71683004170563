import { useCallback, FC, useMemo, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal, removeModal, Typography, Flex, ModalTabs, Button } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'
import IndicatorOptionsStore from '@fto/lib/charting/tool_storages/indicators'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import LevelsCreator, { LEVEL_TEMPLATE } from '@fto/chart_components/LevelsCreator'
import { LevelCreatorTypes } from '@fto/chart_components/LevelsCreator/types'
import { TemplateManager } from '../TemplateManager'
import TradingSessions from './components/TradingSessionView'
import FieldCreator from './components/FieldCreator'
import styles from './index.module.scss'
import useIndicatorTemplate from './hooks'
import { TOptionType } from '@fto/lib/extension_modules/common/CommonTypes'

const TABS = {
    style: { value: 'style', localeKey: 'toolsTabs.style.title' },
    levels: { value: 'levels', localeKey: 'toolsTabs.levels.title' },
    sessions: { value: 'sessions', localeKey: 'toolsTabs.sessions.title' }
}

type TabsType = 'style' | 'levels' | 'sessions'

type TabsParams = {
    activeTab: TabsType
    isShown: boolean
}

type Props = {
    isEdit: boolean
}

export const IndicatorModal: FC<Props> = observer(({ isEdit }) => {
    const { t } = useTranslation()
    const { indicatorOptions, setIndicatorOptions, resetIndicatorOptions } = IndicatorOptionsStore

    const [selectedTemplate, setSelectedTemplate] = useState<string>('')
    const [applyToAll, setApplyToAll] = useState(false)
    const [shouldBeVisible, setShouldBeVisible] = useState(true)

    const [tabParams, setTabParams] = useState<TabsParams>({
        isShown: false,
        activeTab: 'style'
    })
    const { activeTab } = tabParams

    const options = useMemo(() => {
        const entries = Object.entries(indicatorOptions)

        return entries.reduce(
            (options_in_callback: any, item: any) => {
                const [optionKey, value] = item

                if (optionKey === 'indicatorInstance') {
                    return options_in_callback
                }

                if (value.type === TOptionType.at_Levels) {
                    return value.fvalue !== null
                        ? {
                              ...options_in_callback,
                              levels: item
                          }
                        : options_in_callback
                }

                if (value.type === TOptionType.ot_Session || value.type === TOptionType.ot_SessionsArray) {
                    return {
                        ...options_in_callback,
                        sessions: [...options_in_callback.sessions, item]
                    }
                }

                return {
                    ...options_in_callback,
                    style: [...options_in_callback.style, item]
                }
            },
            {
                levels: null,
                style: [],
                sessions: []
            }
        )
    }, [indicatorOptions])

    const setIndicatorOptionsWrapper = useCallback((cb: any, shouldTemplateReset = true) => {
        shouldTemplateReset && setSelectedTemplate('')
        setIndicatorOptions(cb as Extract<ThisParameterType<typeof setIndicatorOptions>, 'IIndicatorOptionsStore'>)
    }, [])

    const { templates, applyDefault, saveTemplate, deleteTemplate, selectTemplate } = useIndicatorTemplate({
        options,
        indicatorOptions,
        setSelectedTemplate,
        setIndicatorOptionsWrapper
    })

    const applySettings = useCallback(() => {
        const { indicatorInstance } = indicatorOptions

        if (isEdit) {
            fireMixpanelEvent('indicator_setting_changed', {
                indicator_name: indicatorOptions.indicatorInstance?.ShortName
            })
        }

        if (indicatorInstance) {
            GlobalChartsController.Instance.setUpIndicator(indicatorInstance, isEdit, applyToAll)
            resetIndicatorOptions()
        }

        removeModal(MODAL_NAMES.chart.indicatorModal)
    }, [indicatorOptions, isEdit, applyToAll])

    const levelKey = useMemo(() => {
        if (options.levels) {
            return options.levels[0]
        }
        return ''
    }, [options])

    const addLevel = useCallback(() => {
        if (!levelKey) {
            return
        }

        setIndicatorOptionsWrapper((prevData: any) => {
            return {
                ...prevData,
                [levelKey]: {
                    ...prevData[levelKey],
                    fvalue: [...prevData[levelKey].fvalue, { ...LEVEL_TEMPLATE, id: +new Date() }]
                }
            }
        })
    }, [levelKey])

    const updateLevelStyle: LevelCreatorTypes['updateStyles'] = useCallback(
        (value, id) => {
            const { color, width, style } = value

            if (!levelKey) {
                return
            }

            setIndicatorOptionsWrapper((prevData: any) => {
                return {
                    ...prevData,
                    [levelKey]: {
                        ...prevData[levelKey],
                        fvalue: prevData[levelKey].fvalue.map((item: any) => {
                            return item.id === id
                                ? {
                                      ...item,
                                      style,
                                      color,
                                      width
                                  }
                                : item
                        })
                    }
                }
            })
        },
        [levelKey]
    )

    const handleLevelValueChange: LevelCreatorTypes['handleLevelValueChange'] = useCallback(
        (value, id) => {
            if (!levelKey) {
                return
            }

            setIndicatorOptionsWrapper((prevData: any) => {
                return {
                    ...prevData,
                    [levelKey]: {
                        ...prevData[levelKey],
                        fvalue: prevData[levelKey].fvalue.map((item: any) =>
                            item.id === id
                                ? {
                                      ...item,
                                      value: Number(value)
                                  }
                                : item
                        )
                    }
                }
            })
        },
        [levelKey]
    )

    const toggleLevel: LevelCreatorTypes['toggleLevel'] = useCallback(
        (value, id) => {
            if (!levelKey) {
                return
            }

            setIndicatorOptionsWrapper((prevData: any) => {
                return {
                    ...prevData,
                    [levelKey]: {
                        ...prevData[levelKey],
                        fvalue: prevData[levelKey].fvalue.map((item: any) =>
                            item.id === id
                                ? {
                                      ...item,
                                      isActive: value
                                  }
                                : item
                        )
                    }
                }
            })
        },
        [levelKey]
    )

    const removeLevel: LevelCreatorTypes['removeLevel'] = useCallback(
        (id) => {
            if (!levelKey) {
                return
            }
            setIndicatorOptionsWrapper((prevData: any) => {
                return {
                    ...prevData,
                    [levelKey]: {
                        ...prevData[levelKey],
                        fvalue: prevData[levelKey].fvalue.filter((item: any) => item.id !== id)
                    }
                }
            })
        },
        [levelKey]
    )

    const viewJSXByIndicator = useMemo(() => {
        if (activeTab === 'style') {
            return (
                <Flex direction='column' gap={8}>
                    {options.style.map((item: any) => {
                        const [key, option] = item
                        return (
                            <FieldCreator
                                key={key}
                                optionKey={key}
                                option={option}
                                setIndicatorOptions={setIndicatorOptionsWrapper}
                            />
                        )
                    })}
                </Flex>
            )
        }
        return null
    }, [options, activeTab])

    const tabsList = useMemo(() => {
        const hasStyles = options.style.length > 0
        const hasLevels = options.levels !== null
        const hasSessions = options.sessions.length > 0

        const list = []

        if (hasSessions && (hasStyles || hasLevels)) {
            list.push(TABS.sessions)
        }

        if (hasStyles && (hasLevels || hasSessions)) {
            list.push(TABS.style)
        }

        if (hasLevels && (hasStyles || hasSessions)) {
            list.push(TABS.levels)
        }

        return list
    }, [options])

    useEffect(() => {
        const hasStyles = options.style.length > 0 ? 1 : 0
        const hasLevels = options.levels ? 1 : 0
        const hasSessions = options.sessions.length > 0 ? 1 : 0

        let activeTabKey = 'style'

        if (hasLevels && !hasStyles && !hasSessions) {
            activeTabKey = 'levels'
        } else if (hasSessions) {
            activeTabKey = 'sessions'
        }

        setTabParams({
            activeTab: activeTabKey as TabsType,
            isShown: hasStyles + hasLevels + hasSessions > 1
        })
    }, [])

    return (
        <Modal
            size='md'
            name={MODAL_NAMES.chart.indicatorModal}
            withCloseIcon
            withClickOutside={false}
            additionalClassNames={{
                container: styles.Modal,
                root: shouldBeVisible ? '' : styles.HiddenModal
            }}
        >
            <Modal.Header withBorderBottom>
                <Typography>
                    {t('indicatorModal.general.title', { name: indicatorOptions.indicatorInstance?.ShortName })}
                </Typography>
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    {tabParams.isShown ? (
                        <ModalTabs
                            tabsList={tabsList}
                            handleTabChange={(value) =>
                                setTabParams((prevState) => ({ ...prevState, activeTab: value as TabsType }))
                            }
                            activeTab={activeTab}
                        />
                    ) : null}
                    {viewJSXByIndicator}
                    {activeTab === 'levels' && options.levels[1] && (
                        <LevelsCreator
                            addLevel={addLevel}
                            updateStyles={updateLevelStyle}
                            handleLevelValueChange={handleLevelValueChange}
                            toggleLevel={toggleLevel}
                            removeLevel={removeLevel}
                            levelsList={options.levels[1].fvalue}
                        />
                    )}
                    {activeTab === 'sessions' && <TradingSessions sessions={options.sessions} />}
                </Flex>
            </Modal.Content>
            <Modal.Footer>
                <TemplateManager
                    changeMainModalVisibleState={setShouldBeVisible}
                    onSaveTemplate={saveTemplate}
                    onSelectTemplate={selectTemplate}
                    onDeleteTemplate={deleteTemplate}
                    onApplyDefault={applyDefault}
                    templates={templates}
                    selectedTemplate={selectedTemplate}
                    applyToAll={applyToAll}
                    setApplyToAll={setApplyToAll}
                />
            </Modal.Footer>
            <Modal.Controls>
                <Button
                    type='secondary'
                    label={t('global.cancel')}
                    onClick={() => {
                        removeModal(MODAL_NAMES.chart.indicatorModal)
                    }}
                    classNames={{ content: styles.ControlButton }}
                />
                <Button
                    type='primary'
                    label={t('toolsModal.cta')}
                    onClick={applySettings}
                    classNames={{ content: styles.ControlButton }}
                />
            </Modal.Controls>
        </Modal>
    )
})
