import { OrderMarker, OrderMarkerEvent } from '@fto/lib/OrderModalClasses/OrderMarker'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { OrderDataType } from '@fto/lib/store/ordersStore/types'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { TPaintToolStatus } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { boolean } from 'yup'

export class PartialClosePricePicker extends OrderMarker {
    public order: OrderDataType | null = null
    public lotValue: number = 0
    public newPoints = 0
    public isDeleted = false
    private pointsBeforeModalClose: number = 0

    public OnMouseDown(time: TDateTime, price: number, button: TMouseButton): void {
        this.isNeededToCopyToOtherCharts = false
        if (this.isNeedShowModal) {
            addModal(MODAL_NAMES.chart.partialClosing, {
                order: this.order,
                tab: 'limit',
                newPrice: this.fChart.GetPriceFromY(this.fPoints.LastItem.y),
                newPoints: this.newPoints,
                lotsBefore: this.lotValue
            })

            this.isNeedShowModal = false
        }

        this.isOnPickerActive = false

        if (this.fStatus !== TPaintToolStatus.ts_Completed && button === TMouseButton.mbRight) {
            this.onCancelPricePick()
        }

        super.OnMouseDown(time, price, button)
    }

    public OnMouseUp(time: TDateTime, price: number, button: TMouseButton): void {
        this.isNeededToCopyToOtherCharts = false
        if (this.isNeedShowModal) {
            addModal(MODAL_NAMES.chart.partialClosing, {
                order: this.order,
                tab: 'limit',
                newPrice: this.fChart.GetPriceFromY(this.fPoints.LastItem.y),
                newPoints: this.newPoints,
                lotsBefore: this.lotValue
            })
            this.isNeedShowModal = false
        }

        this.isOnPickerActive = false

        super.OnMouseUp(time, price, button)
    }

    public Paint(): void {
        if (!this.order) {
            return
        }

        const canvasContext = this.fChart.CanvasContext

        const labelText = 'PC'

        const order = GlobalProcessingCore.ProcessingCore.GetOpenPos(this.order.id)

        const profitDirectionMultiplier = order.getProfitDirectionMultiplier()

        const currentPrice = this.fChart.GetPriceFromY(this.fPoints.LastItem.y)

        const symbolInfo = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(this.order.symbol).symbolInfo
        const decimals = symbolInfo.decimals

        const priceStep = Number(Math.pow(10, -decimals).toFixed(decimals))

        const symbolCalculationStrategy = (
            this.fChart as TMainChart
        ).SymbolData.symbolInfo.getSymbolCalculationStrategy()
        const pointCostForOneLot = symbolCalculationStrategy.getPointCostForOneStandardLot(this.order.type, null)
        const pointCost = pointCostForOneLot * this.lotValue

        const pointsDifference = (currentPrice - order.tpos.ClosePrice) / priceStep
        const pointsCount = (Math.round(pointsDifference) * profitDirectionMultiplier).toFixed(0)

        const valueText = formatCurrency(Number(pointsCount) * pointCost)

        const boxY = this.chart.GetY(this.fPoints.LastItem.price)

        const baseColor = '#01987C'
        this.newPoints = Number(pointsCount)

        const pointsText = `${pointsCount} points`

        this.drawMarkerBox(
            canvasContext,
            labelText,
            valueText,
            pointsText,
            boxY,
            baseColor,
            Number(this.lotValue.toFixed(2))
        )
    }

    public onCancelPricePick(): void {
        if (this.previousPoints) {
            this.fPoints = this.previousPoints
            this.previousPoints = null
            this.observableItem.notify(OrderMarkerEvent.PICK_PRICE, this)
        }

        this.openLimitPartialCloseModal()

        this.ReportCancelOfWork()
        this.isDeleted = true
        this.activeChartWindow?.SetNormalMode()
    }

    private openLimitPartialCloseModal(): void {
        addModal(MODAL_NAMES.chart.partialClosing, {
            order: this.order,
            tab: 'limit',
            newPoints: this.pointsBeforeModalClose,
            lotsBefore: this.lotValue
        })
    }

    public OnPicker(isInitialize: boolean = false) {
        super.OnPicker(isInitialize)

        if (this.orderMarkerInfo.points) {
            this.pointsBeforeModalClose = this.orderMarkerInfo.points
        }
    }
}
