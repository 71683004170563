import { useCallback, FC } from 'react'
import { IResolveParams, LoginSocialMicrosoft } from 'reactjs-social-login'
import { useTranslation } from 'react-i18next'

import { Button } from '@fto/ui'
import { Icon } from '@fto/icons'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@root/hooks/useStoreHook'
import { LOGIN_MICROSOFT } from '@root/store/auth/auth.actions'
import { fireMixpanelEvent } from '@root/utils/api'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'

type Props = {
    type?: 'logIn' | 'signUp'
}
export const MicrosoftAuth: FC<Props> = ({ type = 'logIn' }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const handleResolve = useCallback(
        async ({ provider, data }: IResolveParams) => {
            if (!data || !data['access_token']) return
            const IdToken = data.access_token

            const isLogin = type === 'logIn'

            try {
                fireMixpanelEvent(isLogin ? 'log_in' : 'sign_up', { login_method: 'Microsoft' })
                await dispatch(LOGIN_MICROSOFT({ IdToken })).then(() => {
                    if (!isLogin) {
                        fireGoogleTagManagerEvent('sign_up', {
                            method: 'Microsoft'
                        })
                    }
                })
                navigate(0)
            } catch (e) {}
        },
        [type]
    )

    const REDIRECT_URI = window.location.origin + '/auth/login'
    return (
        <LoginSocialMicrosoft
            client_id={import.meta.env.VITE_MICROSOFT_APP_ID}
            redirect_uri={REDIRECT_URI}
            isOnlyGetToken={true}
            onResolve={handleResolve}
            scope='user.read'
            onReject={(err) => {
                console.error(err)
            }}
        >
            <Button
                type='secondary'
                label={t(type === 'logIn' ? 'auth.loginWithMicrosoft' : 'auth.signUpWithMicrosoft')}
                before={<Icon name='microsoft' size={16} color='currentColor' />}
                block
            />
        </LoginSocialMicrosoft>
    )
}
