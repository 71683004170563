import { FC, ReactNode } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Flex, FlexAligns, Typography, Checkbox } from '@fto/ui'

import commonStyles from '../../common.module.scss'

type Props = {
    label: string
    className?: string
    alignItems?: (typeof FlexAligns)[number]
    children: ReactNode
    isOptional?: boolean
    isActive?: boolean
    toggleOptional?: () => void
}

const FieldWrapper: FC<Props> = ({
    label,
    className,
    children,
    alignItems = 'center',
    isOptional,
    isActive,
    toggleOptional
}) => {
    const { t } = useTranslation()

    return (
        <Flex alignItems={alignItems} justifyContent='space-between' gap={8}>
            <Flex gap={8} alignItems='center'>
                {isOptional && <Checkbox checked={isActive} onChange={toggleOptional} />}
                <Typography type='interface-regular' color='gray-1000' className={cx(className, commonStyles.LeftPart)}>
                    {t(label)}
                </Typography>
            </Flex>
            {children}
        </Flex>
    )
}

export default FieldWrapper
