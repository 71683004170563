import { TPngImage } from '../../delphi_compatibility/DelphiBasicTypes'

export class MarkerImagesManager {
    public static MarkerImgs: TPngImage[] = new Array(8) // Creates an array with 4 elements

    public static LoadImgs(): void {
        for (let i = 0; i <= 7; i++) {
            MarkerImagesManager.MarkerImgs[i] = new TPngImage(
                MarkerImagesManager.GetToolImageUrl(`MarkerImg_${(i + 1).toString().padStart(2, '0')}.png`)
            )
        }
    }

    public static GetToolImageUrl(imageName: string): string {
        //FIXME: Change for real url
        return window.location.origin + '/icons/charting/paint_tools_icons/' + imageName
    }

    // Call LoadImgs statically
    private static _init = (() => {
        if (typeof window !== 'undefined') {
            MarkerImagesManager.LoadImgs()
        }
    })()
}
