import { TOptionType, TOptValue } from '../CommonTypes'
import { TDllOptions } from '../options/DllOptions'
import { IOptionsProcRec } from '../CommonProcRecInterfaces/IOptionsProcRec'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'

export class OptionsProcRecImplementation extends BasicProcRecImplementation implements IOptionsProcRec {
    public GetImplementation(): IOptionsProcRec {
        return {
            RegOption: this.RegOption.bind(this),
            SetOptionRange: this.SetOptionRange.bind(this),
            AddOptionValue: this.AddOptionValue.bind(this),
            AddSeparator: this.AddSeparator.bind(this),
            RegMATypeOption: this.RegMATypeOption.bind(this),
            RegApplyToPriceOption: this.RegApplyToPriceOption.bind(this)
        }
    }

    private options: TDllOptions

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds, options: TDllOptions) {
        super(procRecsEveryoneNeeds)
        this.options = options
    }

    protected override generateDName(): string {
        return `API_Options_${super.generateDName()}`
    }

    public AddOptionValue(optionName: string, value: string): void {
        this.options.AddOptionValue(optionName, value)
    }

    public RegOption(
        optionName: string,
        optionType: TOptionType,
        optPtrOrValue: string | number | boolean | TOptValue,
        useInNameWithParams = true
    ): void {
        this.options.RegOption(optionName, optionType, optPtrOrValue, useInNameWithParams)
    }

    public SetOptionRange(optionName: string, minValue: number, maxValue: number): void {
        this.options.SetOptionRange(optionName, minValue, maxValue)
    }

    public AddSeparator(separatorName: string): void {
        this.options.AddSeparator(separatorName)
    }

    public RegMATypeOption(optionPtr: TOptValue, name?: string): void {
        this.options.RegMATypeOption(optionPtr, name)
    }

    public RegApplyToPriceOption(optionPtr: TOptValue, name: string): void {
        this.options.RegApplyToPriceOption(optionPtr, name)
    }
}
