import { TColor, DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { LevelType } from './LevelType'

// Make the Level class implement the LevelType interface

export class Level implements LevelType {
    value: string | null = null
    style: TPenStyle | null = null
    width: number | null = null
    color: TColor | null = null
    opacity: number | null = 1
    text: string | null = null
    id: string | number = ''
    isActive = true

    constructor() {
        this.value = ''
        this.style = TPenStyle.psSolid
        this.width = 1
        this.color = DelphiColors.clBlack
        this.opacity = 1
    }

    LevelFromString(s: string): Level[] | void {
        if (s === '') {
            return []
        }
        const levelList: Level[] = []
        const levelStrings = s.match(/{(.*?)}/g) || []
        for (const levelStr of levelStrings) {
            const level = new Level()
            const parts =
                levelStr
                    .slice(1, -1)
                    .match(/(".*?"|\S+)/g)
                    ?.map((part) => part.replaceAll('"', '')) || []
            if (parts.length >= 6) {
                level.value = parts[0]
                level.text = parts[1]
                level.color = parts[2]
                level.style = parseInt(parts[3])
                level.width = parseInt(parts[4])
                level.id = parseInt(parts[5])
                level.isActive = parseInt(parts[6]) === 1
            }
            levelList.push(level)
        }
        return levelList
    }
    LevelToString(levels: Level[]): string {
        return levels
            .map((level) => {
                const text = level.text ? `"${level.text}"` : '"n"'
                const value = `${level.value}`
                return `{${[value, text, level.color, level.style, level.width, level.id, level.isActive ? 1 : 0].join(
                    ' '
                )}}`
            })
            .join('')
    }
}
