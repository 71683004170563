import { TPointInfo } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { IGPPen } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'

export class TExtendsRay {
    left: boolean
    right: boolean
    fillLeft: boolean
    fillRight: boolean
    leftfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
    rightfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
    fillLeftfPoints:
        | { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
        | { [key: number]: [[TPointInfo, TPointInfo], [TPointInfo, TPointInfo], IGPPen | null] }[]
        | null
    fillRightfPoints:
        | { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
        | { [key: number]: [[TPointInfo, TPointInfo], [TPointInfo, TPointInfo], IGPPen | null] }[]
        | null

    constructor() {
        this.left = false
        this.right = false
        this.leftfPoints = []
        this.rightfPoints = []
        this.fillLeft = false
        this.fillRight = false
        this.fillLeftfPoints = null
        this.fillRightfPoints = null
    }

    clone(): TExtendsRay {
        const clone = new TExtendsRay()
        clone.left = this.left
        clone.right = this.right
        clone.fillLeft = this.fillLeft
        clone.fillRight = this.fillRight
        clone.leftfPoints = this.leftfPoints
        clone.rightfPoints = this.rightfPoints
        clone.fillLeftfPoints = this.fillLeftfPoints
        clone.fillRightfPoints = this.fillRightfPoints
        return clone
    }
}
