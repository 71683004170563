import { useState, useCallback } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

type Props = {
    onEmailChange?: (password: string) => void
    initialValue?: string
    schema?: Yup.AnySchema
    validationDelay?: number
}

const EMAIL_VALIDATION_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useEmailField = ({ initialValue = '', onEmailChange, schema }: Props) => {
    const [email, setEmail] = useState(initialValue)

    const [validationData, setValidationData] = useState<{
        isValid: boolean
        errorMessage: string
    }>({ isValid: true, errorMessage: '' })

    const { t } = useTranslation()

    const validateScheme = useCallback(async (email: string, schema: Yup.AnySchema): Promise<boolean> => {
        try {
            await schema.validate(email)
            setValidationData({ isValid: true, errorMessage: '' })
            return true
        } catch (error: any) {
            setValidationData({ isValid: false, errorMessage: error.message })
            return false
        }
    }, [])

    const validate = useCallback(async (email: string): Promise<boolean> => {
        const defaultSchema = Yup.string()
            .required(t('auth.errors.email.required'))
            .email(t('auth.errors.email.shouldBeValid'))
            .matches(EMAIL_VALIDATION_REGEX, { message: t('auth.errors.email.shouldBeValid') })

        const result = schema ? await validateScheme(email, schema) : await validateScheme(email, defaultSchema)

        return result
    }, [])

    const handleEmailChange = useCallback(
        (value: string) => {
            setEmail(value)
            if (!validationData.isValid) {
                validate(value)
            }
            onEmailChange?.(value)
        },
        [onEmailChange, validationData.isValid]
    )

    return {
        email,
        handleEmailChange,
        validationData,
        validate
    }
}

export default useEmailField
