import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Checkbox, Flex, Typography } from '@fto/ui'
import { CheckboxSettingType, OptionSettingRef } from '../../../types'

import styles from './index.module.scss'
import { getBooleanValue, setBooleanValue } from '../../../utils'
import { t } from 'i18next'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const CheckboxSetting = forwardRef<OptionSettingRef, CheckboxSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate, setChildsDisabled }, ref) => {
        const [checked, setChecked] = useState<boolean>(() =>
            getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
        )

        const setCheckboxValue = useCallback(
            (value: boolean, redraw = true, shouldUpdateConfig = true) => {
                setChecked(value)
                setChildsDisabled(!value)

                shouldUpdateConfig &&
                    setBooleanValue(chart, option.booleanConfigType, option.booleanKey, value, applyToAll)

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [option, chart, applyToAll]
        )

        const handleCheckboxChange = useCallback(
            (value: boolean) => {
                onDifferentSettingsUpdate(tab, value, checked, option.colorKey)
                setCheckboxValue(value)
            },
            [tab, option, checked, setCheckboxValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setCheckboxValue(data.checked, false, shouldUpdateConfig)
            },
            [setCheckboxValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex>
                <Flex alignItems='center' block>
                    <Checkbox
                        className={styles.checkbox}
                        label={
                            <Typography
                                className={styles.checkboxLabel}
                                type='text-regular'
                                color={shouldBeDisabled ? 'gray-600' : 'gray-1000'}
                            >
                                {t(option.name)}
                            </Typography>
                        }
                        block
                        inputControlVariant={'row-reverse'}
                        checked={checked}
                        onChange={handleCheckboxChange}
                        disabled={shouldBeDisabled}
                    ></Checkbox>
                </Flex>
            </Flex>
        )
    }
)

export default CheckboxSetting
