import { useEffect, useState } from 'react'
import ColorSetting from '../components/Settings/ColorSetting'
import CheckboxAndColorSetting from '../components/Settings/CheckboxAndColorSetting'
import SliderSettings from '../components/Settings/SliderSetting'
import CheckboxSettings from '../components/Settings/CheckboxSetting'
import InputSettings from '../components/Settings/InputSetting'
import DropdownSettings from '../components/Settings/DropdownSetting'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import { AvailableOptionsSettings, ConfigType, Option, OptionSettingRef } from '../types/index'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { setStringValue, setBooleanValue, setNumberValue, getBooleanValue } from '.'

import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'
import { TChartOptions } from '@fto/lib/charting/ChartBasicClasses'
import OptionContainer from '../components/OptionContainer'

function isColorSettingOption(option: Option) {
    return option?.type === 'colorSetting'
}

function isCheckboxAndColorSettingOption(option: Option) {
    return option?.type === 'checkboxAndColorSetting'
}

function isSliderSettingOption(option: Option) {
    return option?.type === 'sliderSetting'
}

function isInputSettingOption(option: Option) {
    return option?.type === 'inputSetting'
}

function isCheckboxSettingOption(option: Option) {
    return option?.type === 'checkboxSetting'
}

function isDropdownSettingOption(option: Option) {
    return option?.type === 'dropdownSetting'
}

export function getConfigUnit(chart: TChartWindow, optionType: ConfigType) {
    const chartOptions = chart.ChartOptions
    const colorScheme = chartOptions.ColorScheme

    if (optionType === ConfigType.ColorScheme) {
        return colorScheme
    }

    if (optionType === ConfigType.ChartOptions) {
        return chartOptions
    }

    if (optionType === ConfigType.GlobalOptions) {
        return GlobalOptions.Options
    }

    throw new StrangeError('Unknown option config type')
}

export const getOptionState = (option: Option, chart: TChartWindow) => {
    if (isColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            }
        }
    }

    if (isCheckboxAndColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            },
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isSliderSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isInputSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isCheckboxSettingOption(option)) {
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    return { notNow: true }
}

export const setOptionState = (chart: TChartWindow, optionConfig: Option, savedOption: Option) => {
    if (isColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value)
        return
    }
    if (isCheckboxAndColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value)
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked)
        return
    }
    if (isSliderSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    if (isInputSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    if (isCheckboxSettingOption(savedOption)) {
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked)
        return
    }
    if (isDropdownSettingOption(savedOption)) {
        setNumberValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    throw new StrangeError('Unknown option type')
}

function getParentDisableState(option: Option, chart: TChartWindow) {
    if (isCheckboxSettingOption(option) || isCheckboxAndColorSettingOption(option)) {
        return !getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
    }

    return false
}

type OptionSettingByConfigProp = {
    tab: string
    chart: TChartWindow
    option: AvailableOptionsSettings
    applyToAll: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
    initialData: Partial<TOptions & TChartOptions>
}

export const getOptionSettingByConfig = (
    { tab, chart, option, applyToAll, initialData, onDifferentSettingsUpdate }: OptionSettingByConfigProp,
    addRef: (ref: OptionSettingRef) => void,
    level = 0,
    shouldBeDisabled: boolean = false
): React.ReactNode => {
    const { subOptions = null, ...mainOption } = option

    const elements = []

    const [childDisabledState, setChildDisabledState] = useState<boolean>(
        shouldBeDisabled || getParentDisableState(mainOption, chart)
    )

    useEffect(() => {
        setChildDisabledState(shouldBeDisabled || getParentDisableState(mainOption, chart))
    }, [shouldBeDisabled])

    elements.push(
        getSingleOptionSettingByConfig(
            {
                tab,
                chart,
                option: mainOption,
                applyToAll,
                onDifferentSettingsUpdate,
                initialData
            },
            addRef,
            level,
            setChildDisabledState,
            shouldBeDisabled
        )
    )

    if (!subOptions) {
        return <>{elements}</>
    }

    for (let key in subOptions) {
        elements.push(
            getOptionSettingByConfig(
                {
                    tab,
                    chart,
                    option: subOptions[key],
                    applyToAll,
                    onDifferentSettingsUpdate,
                    initialData
                },
                addRef,
                level + 1,
                childDisabledState
            )
        )
    }

    return <>{elements}</>
}

export const getSingleOptionSettingByConfig = (
    { tab, chart, option, applyToAll, initialData, onDifferentSettingsUpdate }: OptionSettingByConfigProp,
    addRef: (ref: OptionSettingRef) => void,
    level = 0,
    setChildsDisabled: (val: boolean) => void,
    shouldBeDisabled: boolean
) => {
    if (isColorSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <ColorSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isCheckboxSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <CheckboxSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    setChildsDisabled={setChildsDisabled}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isCheckboxAndColorSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <CheckboxAndColorSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    setChildsDisabled={setChildsDisabled}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isSliderSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <SliderSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isInputSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <InputSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isDropdownSettingOption(option)) {
        return (
            <OptionContainer level={level}>
                <DropdownSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    initialData={initialData}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    throw new StrangeError('Unknown option type')
}

export default getOptionSettingByConfig
