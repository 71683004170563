import { FC, useCallback, useMemo, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@fto/ui'
import { Icon } from '@fto/icons'

import { UndoCommand } from '@fto/lib/utils/FTOCommands/UndoCommand'
import { RedoCommand } from '@fto/lib/utils/FTOCommands/RedoCommand'
import { UndoRedoType } from '@fto/lib/store/chartSettings/types'

import commonStyles from '@fto/chart_components/ProjectInterface/components/ToolsSidebar/index.module.scss'
import styles from './index.module.scss'

type Props = {
    undoRedoAvailability: UndoRedoType
}

const UndoRedo: FC<Props> = ({ undoRedoAvailability }) => {
    const { t } = useTranslation()
    const undoCommand = useMemo(() => new UndoCommand(), [])
    const redoCommand = useMemo(() => new RedoCommand(), [])

    const handleUndo = useCallback(() => {
        undoCommand.execute()
    }, [undoCommand])

    const handleRedo = useCallback(() => {
        redoCommand.execute()
    }, [redoCommand])

    const { isUndoAvailable, isRedoAvailable } = undoRedoAvailability

    return (
        <>
            <Tooltip content={t('graphicTools.toolNames.undo')} placement='right' key={'undo'}>
                <div className={cx({ [styles.cursorNotAllowed]: !isUndoAvailable })}>
                    <div
                        className={cx(commonStyles.iconWrapper, { [styles.disabled]: !isUndoAvailable })}
                        onClick={handleUndo}
                    >
                        <Icon
                            color={isUndoAvailable ? 'var(--color-gray-1000)' : 'var(--color-gray-500'}
                            size={18}
                            name={'arrow-undo'}
                        />
                    </div>
                </div>
            </Tooltip>
            <Tooltip content={t('graphicTools.toolNames.redo')} placement='right' key={'redo'}>
                <div className={cx({ [styles.cursorNotAllowed]: !isRedoAvailable })}>
                    <div
                        className={cx(commonStyles.iconWrapper, { [styles.disabled]: !isRedoAvailable })}
                        onClick={handleRedo}
                    >
                        <Icon
                            color={isRedoAvailable ? 'var(--color-gray-1000)' : 'var(--color-gray-500'}
                            size={18}
                            name={'arrow-redo'}
                        />
                    </div>
                </div>
            </Tooltip>
        </>
    )
}

export default UndoRedo
