import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { suspendSubscription } from '@root/utils/api'

import styles from './index.module.scss'

const localeKey = 'profile.modals.suspendSubscriptionModal.cancelAnyway'

type Props = {
    chargeDate: Date | null
    subscriptionStatus: 'monthly' | 'annual' | 'lifetime' | 'Unknown'
    cancelSubscription: () => void
}

const SuspendAnyway: FC<Props> = ({ chargeDate, subscriptionStatus, cancelSubscription }) => {
    const { t } = useTranslation()

    const { isTrialExpired, trialPeriodEnd } = useAppSelector($getUser)

    const date = useMemo(() => {
        return dayjs(isTrialExpired ? chargeDate : trialPeriodEnd, 'MM/DD/YYYY h:mm A')
            .locale('en')
            .format('MMMM DD, YYYY')
    }, [trialPeriodEnd, chargeDate, isTrialExpired])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16} alignItems='center'>
                    <Icon size={72} name='face-frown' />
                    <Typography type='h5-semibold' color='gray-1000'>
                        {t(`${localeKey}.title`)}
                    </Typography>
                    <Typography type='interface-regular' color='gray-1000' align='center'>
                        {t(`${localeKey}.description`, { date: date })}
                    </Typography>
                    <Flex direction='column' gap={16} className={styles.Points}>
                        <Flex gap={16}>
                            <div className={styles.IconWrapper}>
                                <Icon name='settings' size={18} color='var(--color-green-500)' />
                            </div>

                            <Flex direction='column' gap={4}>
                                <Typography type='interface-semibold' color='gray-1000'>
                                    {t(`${localeKey}.benefits.boostTrading.title`)}
                                </Typography>
                                <Typography type='interface-regular' color='gray-1000' align='left'>
                                    {t(`${localeKey}.benefits.boostTrading.description`)}
                                </Typography>
                            </Flex>
                        </Flex>
                        <Flex gap={16}>
                            <div className={styles.IconWrapper}>
                                <Icon name='settings' size={18} color='var(--color-green-500)' />
                            </div>

                            <Flex direction='column' gap={4}>
                                <Typography type='interface-semibold' color='gray-1000'>
                                    {t(`${localeKey}.benefits.profitable.title`)}
                                </Typography>
                                <Typography type='interface-regular' color='gray-1000' align='left'>
                                    {t(`${localeKey}.benefits.profitable.description`)}
                                </Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.GridContainer}>
                <Button
                    type='secondary'
                    onClick={() => removeModal(MODAL_NAMES.userProfile.suspendSubscription)}
                    label={t(`${localeKey}.cancel`)}
                    block
                />
                <Button type='primary' onClick={cancelSubscription} label={t(`${localeKey}.cta`)} block />
            </Modal.Controls>
        </>
    )
}

export default SuspendAnyway
