import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

export class TimeRangeForCompletedTaskCheck {
    private timeRange: {
        valueStart: number
        valueEnd: number
    }
    constructor(timeRange: { valueStart: number; valueEnd: number }) {
        this.timeRange = timeRange
    }

    public checkTimeRange(time: number): boolean {
        return (
            DateUtils.toUnixTimeMilliseconds(time) >= this.timeRange.valueStart &&
            DateUtils.toUnixTimeMilliseconds(time) <= this.timeRange.valueEnd
        )
    }

    public checkMoreThan(time: number): boolean {
        return DateUtils.toUnixTimeMilliseconds(time) > this.timeRange.valueEnd
    }
}
