import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

type DstRange = {
    startDT: TDateTime
    endDT: TDateTime
    startUnixSeconds: number
    endUnixSeconds: number
}

export enum TDstType {
    dst_None = 0,
    dst_US = 1,
    dst_European = 2,
    dst_Australian = 3
}

export class DaylightSavingTimeManager {
    private _dstMap: Map<TDstType, DstRange[]> = new Map()
    constructor() {
        this._dstMap.set(TDstType.dst_US, [])
        this._dstMap.set(TDstType.dst_European, [])
        this._dstMap.set(TDstType.dst_Australian, [])
    }

    public getOffsetInDTByDst(date: TDateTime, dstType: TDstType): TDateTime {
        if (dstType === TDstType.dst_None) {
            return 0
        }

        let ranges = this._dstMap.get(dstType)
        if (!ranges) {
            throw new StrangeError('ranges is undefined')
        }
        for (let range of ranges) {
            if (date >= range.startDT && date <= range.endDT) {
                return DateUtils.OneHour
            }
        }
        return 0
    }

    getOffsetInSecondsByDst(unixSeconds: number, dstType: TDstType): number {
        if (dstType === TDstType.dst_None) {
            return 0
        }

        let ranges = this._dstMap.get(dstType)
        if (!ranges) {
            throw new StrangeError('ranges is undefined')
        }
        for (let range of ranges) {
            if (unixSeconds >= range.startUnixSeconds && unixSeconds <= range.endUnixSeconds) {
                return 1 * 60 * 60 // 1 hour in seconds
            }
        }
        return 0
    }

    public updateDST_ByDateRange(startDate: TDateTime, endDate: TDateTime): void {
        let endYear = DateUtils.YearOf(endDate)
        let startYear = DateUtils.YearOf(startDate)
        for (let year = startYear; year <= endYear; year++) {
            this.updateDST_ByYear(year)
        }
    }

    private updateDST_ByYear(year: number): void {
        let rangeUS_DST = this.getUS_DST_Range_UTC(year)
        let UsRanges = this._dstMap.get(TDstType.dst_US)
        if (UsRanges) {
            UsRanges.push(rangeUS_DST)
        } else {
            throw new StrangeError('USRanges is undefined')
        }

        let rangeEuropean_DST = this.getEuropean_DST_Range_UTC(year)
        let EuropeanRanges = this._dstMap.get(TDstType.dst_European)
        if (EuropeanRanges) {
            EuropeanRanges.push(rangeEuropean_DST)
        } else {
            throw new StrangeError('EuropeanRanges is undefined')
        }

        let rangeAustralian_DST = this.getAustralian_DST_Range_UTC(year)
        let AustralianRanges = this._dstMap.get(TDstType.dst_Australian)
        if (AustralianRanges) {
            AustralianRanges.push(rangeAustralian_DST)
        } else {
            throw new StrangeError('AustralianRanges is undefined')
        }
    }

    getUS_DST_Range_UTC(year: number): DstRange {
        let start: Date
        let end: Date

        if (year < 2007) {
            // from first sunday of april to last sunday of october
            start = DateUtils.GetFirstDayOfMonthUTC(year, 3, 0)
            end = DateUtils.GetLastDayOfMonthUTC(year, 9, 0)
        } else {
            // from second sunday of march to first saturday of november
            start = DateUtils.GetFirstDayOfMonthUTC(year, 2, 0)
            start.setUTCDate(start.getUTCDate() + 7)

            end = DateUtils.GetFirstDayOfMonthUTC(year, 10, 0)
        }
        const startInDT: TDateTime = DateUtils.FromDate(start)
        const endInDT: TDateTime = DateUtils.FromDate(end)
        const startUnixSeconds = start.getTime() / 1000
        const endUnixSeconds = end.getTime() / 1000
        return {
            startDT: startInDT,
            endDT: endInDT,
            startUnixSeconds: startUnixSeconds,
            endUnixSeconds: endUnixSeconds
        }
    }

    getEuropean_DST_Range_UTC(year: number): DstRange {
        // from last sunday of march to last saturday of october
        const start = DateUtils.GetLastDayOfMonthUTC(year, 2, 0)
        const end = DateUtils.GetLastDayOfMonthUTC(year, 9, 6)
        const startInDT: TDateTime = DateUtils.FromDate(start)
        const endInDT: TDateTime = DateUtils.FromDate(end)
        const startUnixSeconds = start.getTime() / 1000
        const endUnixSeconds = end.getTime() / 1000
        return {
            startDT: startInDT,
            endDT: endInDT,
            startUnixSeconds: startUnixSeconds,
            endUnixSeconds: endUnixSeconds
        }
    }

    getAustralian_DST_Range_UTC(year: number): DstRange {
        if (year < 2008) {
            // from last sunday of october to last sunday of march
            const start = DateUtils.GetLastDayOfMonthUTC(year, 9, 0)
            const end = DateUtils.GetLastDayOfMonthUTC(year + 1, 2, 0)
            const startInDT: TDateTime = DateUtils.FromDate(start)
            const endInDT: TDateTime = DateUtils.FromDate(end)
            const startUnixSeconds = start.getTime() / 1000
            const endUnixSeconds = end.getTime() / 1000
            return {
                startDT: startInDT,
                endDT: endInDT,
                startUnixSeconds: startUnixSeconds,
                endUnixSeconds: endUnixSeconds
            }
        } else {
            // from first sunday of october to first sunday of april
            const start = DateUtils.GetFirstDayOfMonthUTC(year, 9, 0)
            const end = DateUtils.GetFirstDayOfMonthUTC(year + 1, 3, 0)
            const startInDT: TDateTime = DateUtils.FromDate(start)
            const endInDT: TDateTime = DateUtils.FromDate(end)
            const startUnixSeconds = start.getTime() / 1000
            const endUnixSeconds = end.getTime() / 1000
            return {
                startDT: startInDT,
                endDT: endInDT,
                startUnixSeconds: startUnixSeconds,
                endUnixSeconds: endUnixSeconds
            }
        }
    }
}
