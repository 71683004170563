import { ChartControl, chartControlEvent, ChartControlParams } from '@fto/chart_components/ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { formatImageSize } from '@fto/lib/utils/common_utils'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'

export default class VisibilityButton extends ChartControl {
    private pen: IGPPen = new IGPPen('#dadcde', 1)
    private brush: IGPSolidBrush = new IGPSolidBrush('#ffffff')
    private brushHovered: IGPSolidBrush = new IGPSolidBrush('#f4f4f4')


    constructor(chartControlParams: ChartControlParams) {
        super(chartControlParams)
    }

    draw(canvas: TGdiPlusCanvas, isIndicatorVisible = true) {
        const dpr = window.devicePixelRatio || 1
        const ctx = canvas.graphics.Context
        const image = isIndicatorVisible ? GlobalImageManager.Instance.eyeImage : GlobalImageManager.Instance.eyeCloseImage

        const location = this.getLocation()
        const { Left, Top, Right, Bottom } = location
        const BorderRadius = 5 * dpr
        const padding = BorderRadius * 0.75
        const buttonSize = Bottom - Top - padding * 2

        if (this.isMouseInside()) {
            canvas.strokeRect(location, TRoundRectType.BOTH, BorderRadius, true, this.pen, this.brushHovered)
            if (this.tooltip) {
                this.showTooltip(canvas)
            }
        } else {
            canvas.strokeRect(location, TRoundRectType.BOTH, BorderRadius, true, this.pen, this.brush)
        }

        const coloredImage = formatImageSize(image!, buttonSize, buttonSize)
        ctx.drawImage(coloredImage, Left + padding, Top + padding, buttonSize, buttonSize)
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (super.onMouseDown(event, sender)) {
            return this
        }

        return null
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        super.onMouseMove(event, sender)

        let result = null
        if (this.isPointInside(event.offsetX, event.offsetY)) {
            ;(sender.ChartWindow as TChartWindow).SetCursor(TCursor.crHandPoint)
            result = this
            this.notify(chartControlEvent.BUTTON_PRESSED)
            return this
        }
        return null
    }
}
