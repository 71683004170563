import { EErrorHandlingMode } from '../CommonTypes'
import { ErrorHandlingProcRecImplementation } from './ErrorHandlingProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'
import { VeryBasicProcRecImplementation } from './VeryBasicProcRecImplementation'

export abstract class BasicProcRecImplementation extends VeryBasicProcRecImplementation {
    protected errorHandlingImplementation: ErrorHandlingProcRecImplementation

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds) {
        super()
        this.errorHandlingImplementation = procRecsEveryoneNeeds.errorHandlingProcRecImplementation
    }

    protected errorHandlingWrapper<T extends (...args: any[]) => any>(
        fn: T,
        ...[defaultValue]: ReturnType<T> extends void ? [undefined?] : [ReturnType<T>]
    ): T {
        return ((...args: any[]) => {
            try {
                return fn(...args)
            } catch (error) {
                this.errorHandlingImplementation.SetLastErrorBasedOnException(error)
                if (
                    this.errorHandlingImplementation.errorHandlingMode ===
                    EErrorHandlingMode.ehm_FallSilentlyAndRememberLastError
                ) {
                    return defaultValue
                } else {
                    throw error
                }
            }
        }) as T
    }
}
