import { FC, useCallback, useMemo } from 'react'

import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'

import { EOperationType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { orderModelType } from '@fto/lib/store/createOrder/types'

import { getTabTextColor } from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/SellBuyTabs/utils'
import { ORDER_TYPE_TABS } from '@root/pages/ChartPage/components/OrderModal/constants/tabs'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type TabProp = {
    value: EOperationType
    localeKey: string
    activeTab: EOperationType
    setActiveTab: (tab: EOperationType) => void
    priceValue: orderModelType['m_ask'] | orderModelType['m_bid']
}
const Tab: FC<TabProp> = ({ value, localeKey, activeTab, setActiveTab, priceValue = 0 }) => {
    const { t } = useTranslation()

    const textColor = useMemo(() => getTabTextColor(value, activeTab), [value, activeTab])
    const isSell = useMemo(() => value === EOperationType.SELL, [value])

    const selectOrderType = useCallback(() => {
        setActiveTab(value)
    }, [value])

    return (
        <Flex
            alignItems='center'
            className={cx(styles.Tab, styles[`type-${value === EOperationType.BUY ? 'buy' : 'sell'}`], {
                [styles.activeBuy]: activeTab === EOperationType.BUY && !isSell,
                [styles.activeSell]: activeTab === EOperationType.SELL && isSell
            })}
            onClick={selectOrderType}
        >
            <Flex
                direction='column'
                justifyContent='flex-start'
                className={cx(styles[`textAlign-${value === EOperationType.BUY ? 'buy' : 'sell'}`])}
            >
                <Typography type='text-regular' color={textColor} align={isSell ? 'left' : 'right'}>
                    {t(localeKey)}
                </Typography>
                <Typography type='text-semibold' color={textColor} align={isSell ? 'left' : 'right'}>
                    {priceValue}
                </Typography>
            </Flex>
        </Flex>
    )
}

type Props = {
    activeTab: EOperationType
    setActiveTab: (tab: EOperationType) => void
    spread: orderModelType['m_spread']
    askValue: orderModelType['m_ask']
    bidValue: orderModelType['m_bid']
}
const SellBuyTabs: FC<Props> = ({ activeTab, setActiveTab, spread, askValue, bidValue }) => {
    return (
        <Flex className={styles.TabsWrapper}>
            {ORDER_TYPE_TABS.map(({ value, localeKey }) => (
                <Tab
                    key={value}
                    localeKey={localeKey}
                    value={value}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    priceValue={value !== EOperationType.SELL ? askValue : bidValue}
                />
            ))}
            <Flex className={styles.Spreed} alignItems='center' justifyContent='center'>
                <Typography type='interface-regular' color='gray-1000'>
                    {spread}
                </Typography>
            </Flex>
        </Flex>
    )
}

export default SellBuyTabs
