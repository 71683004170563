import React, { FC, useCallback, useMemo, ReactNode } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { FilenameTypes, Icon } from '@fto/icons'
import { TriggerOverlay, Flex, Typography, TriggerOverlayProps } from '@fto/ui'

import { lineTypeOptions, lineWidthOptions, TPenStyle } from './constants'

import styles from './index.module.scss'

export type LineSelectorValueType = string | number

export type LineStyleSelectorProps = {
    onChange: (value: LineSelectorValueType) => void
    value: LineSelectorValueType
    title?: string
    type?: 'lineType' | 'lineWidth'
    transformOrigin?: TriggerOverlayProps['transformOrigin']
    anchorOrigin?: TriggerOverlayProps['anchorOrigin']
    classNames?: {
        root?: string
    }
    previewLineWidth?: number
    withArrow?: boolean
    children?: ReactNode
    disableBackdropChartActivity?: TriggerOverlayProps['disableBackdropChartActivity']
}
export const LineStyleSelector: FC<LineStyleSelectorProps> = ({
    value,
    onChange,
    title = '',
    type = 'lineType',
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right'
    },
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right'
    },
    classNames,
    previewLineWidth = 64,
    withArrow = true,
    disableBackdropChartActivity = false,
    children
}) => {
    const { t } = useTranslation()
    const isLineType = useMemo(() => type === 'lineType', [type])

    const options = useMemo(() => (isLineType ? lineTypeOptions : lineWidthOptions), [type, isLineType])

    const titleLabel = useMemo(() => {
        if (title) {
            return title
        }

        return t(`uiKit.lineStyleSelector.${type}`)
    }, [title, type])

    const selectorValueParams = useMemo(() => {
        if (!isLineType) {
            return {
                name: 'solid-line' as (typeof FilenameTypes)[number],
                size: previewLineWidth,
                height: value as number
            }
        }
        let lineStyleIcon = ''

        switch (value) {
            case TPenStyle.psSolid: {
                lineStyleIcon = 'solid-line'
                break
            }
            case TPenStyle.psDash: {
                lineStyleIcon = 'dashed-line'
                break
            }
            case TPenStyle.psDot: {
                lineStyleIcon = 'shortDashed-line'
                break
            }
            case TPenStyle.psDashDot: {
                lineStyleIcon = 'dotDashed-line'
                break
            }
            case TPenStyle.psDashDotDot: {
                lineStyleIcon = 'doubleDotDashed-line'
                break
            }
            default: {
                lineStyleIcon = 'solid-line'
            }
        }

        return {
            name: lineStyleIcon as (typeof FilenameTypes)[number],
            size: previewLineWidth,
            height: 2
        }
    }, [isLineType, title, value, previewLineWidth])

    const optionRenderer = useCallback(
        (onClose: () => void) => {
            return (
                <Flex direction='column' className={styles.Select} gap={4}>
                    <Typography color='gray-600' type='tiny-regular' className={styles.Title}>
                        {titleLabel}
                    </Typography>
                    {options.map((item: (typeof options)[number]) => {
                        const onSelect = () => {
                            onChange(item.value)
                            onClose()
                        }

                        const isSelected = value === item.value
                        return (
                            <Flex
                                alignItems='center'
                                justifyContent='center'
                                className={cx(styles.Option, { [styles.Selected]: isSelected })}
                                onClick={onSelect}
                                key={item.value}
                            >
                                <Icon
                                    size={item.iconSize}
                                    height={item.iconHeight}
                                    name={item.icon as (typeof FilenameTypes)[number]}
                                    color={isSelected ? 'var(--color-white)' : 'var(--color-gray-1000)'}
                                />
                            </Flex>
                        )
                    })}
                </Flex>
            )
        },
        [value, title, options, onChange, titleLabel]
    )

    return (
        <div className={cx(styles.LineTypeSelectorWrapper, classNames?.root)}>
            <TriggerOverlay
                optionsRenderer={optionRenderer}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
                disableBackdropChartActivity={disableBackdropChartActivity}
            >
                {children ? (
                    children
                ) : (
                    <Flex alignItems='center' justifyContent='center' gap={4} className={styles.LineTypeSelector}>
                        <Icon {...selectorValueParams} color={'var(--color-gray-1000)'} />
                        {withArrow && <Icon name='chevron-down' size={12} color={'var(--color-gray-1000)'} />}
                    </Flex>
                )}
            </TriggerOverlay>
        </div>
    )
}
