import { useState } from 'react'

// Browsers occasionally miscalculate scroll and client width/height by one pixel up or down
//  so we're use |x| > 1 instead of x > 0 assertion eliminating the issue at the cost of
//  a false-negative result if the content is overflowing by just 1 pixel
const isContentOverflowing = (length: number, visibleLength: number) => {
    return Math.abs(length - visibleLength) > 1
}

export const isContentOverflowingX = (node: HTMLElement) => {
    return isContentOverflowing(node.scrollWidth, node.clientWidth)
}

export const isContentOverflowingY = (node: HTMLElement) => {
    return isContentOverflowing(node.scrollHeight, node.clientHeight)
}

export const isOverflown = (node: HTMLElement) => {
    if (!node) {
        return false
    }

    const isOverflowingX = isContentOverflowingX(node)
    const isOverflowingY = isContentOverflowingY(node)

    return isOverflowingX || isOverflowingY
}

/**
 * @typedef OverflowState
 * @type {object}
 * @property {callback} ref - (el: HTMLElement | null) => void
 * @property {boolean} isOverflowing
 * @property {boolean} isOverflowingX
 * @property {boolean} isOverflowingY
 */

/**
 * @returns {OverflowState}
 */
export function useOverflowObserver() {
    const [isOverflowingX, setIsOverflowingX] = useState(false)
    const [isOverflowingY, setIsOverflowingY] = useState(false)

    const ref = (node: HTMLElement) => {
        if (!node) {
            return
        }

        const newIsOverflowingX = isContentOverflowingX(node)
        if (newIsOverflowingX !== isOverflowingX) {
            setIsOverflowingX(newIsOverflowingX)
        }

        const newIsOverflowingY = isContentOverflowingY(node)
        if (newIsOverflowingY !== isOverflowingY) {
            setIsOverflowingY(newIsOverflowingY)
        }
    }

    return {
        ref,
        isOverflowingX,
        isOverflowingY,
        isOverflowing: isOverflowingX || isOverflowingY
    }
}
