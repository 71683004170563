export enum EDebugLoggingLevel {
    None = 0,
    Error = 1,
    Warning = 2,
    All = 3
}

export enum ELoggingTopics {
    lt_All = 'All',
    lt_Significant = 'Significant',
    lt_NotImplemented = 'NotImplemented',
    lt_Events = 'Events',
    lt_Seek = 'Seek',
    lt_Maps = 'Maps',
    lt_ChunkLoading = 'ChunkLoading',
    lt_BarInfo = 'BarInfo',
    lt_Painting = 'Painting',
    lt_Loading = 'Loading',
    lt_ChunkLoadingCompleted = 'ChunkLoadingCompleted',
    lt_ScrollAndZoom = 'Scroll & Zoom',
    lt_Temp = 'Temp',
    lt_TestingSpeed = 'TestingSpeed',
    lt_MouseEvents = 'MouseEvents',
    lt_BarBuilding = 'BarBuilding',
    lt_TestingControl = 'TestingControl'
}
