import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { renewSubscription } from '@root/utils/api'
import { FC } from 'react'
import styles from './modals.module.scss'
import store from '@root/store'
import { LOOKUP } from '@root/store/auth/auth.actions'
import { useTranslation } from 'react-i18next'

type RenewSubscriptionModalProps = {
    subscriptionId: number
}

export const RenewSubscriptionModal: FC<RenewSubscriptionModalProps> = ({ subscriptionId }) => {
    const { t } = useTranslation()

    const renew = () => {
        renewSubscription({ subscriptionId: subscriptionId }).then(() => {
            store.dispatch(LOOKUP())
        })
        removeModal(MODAL_NAMES.userProfile.renewSubscription)
    }

    const onClose = () => {
        removeModal(MODAL_NAMES.userProfile.renewSubscription)
    }
    return (
        <Modal name={MODAL_NAMES.userProfile.renewSubscription} size='sm' onClose={onClose}>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='face-smile' size={72} />
                    <Typography type='h3-bold'>{t('profile.modals.renewSubscription')}</Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                    {t('profile.modals.renewText')}
                </Typography>
            </Modal.Content>
            <Modal.Controls>
                <Button label={t('global.cancel')} type='secondary' block onClick={onClose} />
                <Button
                    classNames={{ root: styles.renewButton }}
                    label={t('profile.modals.renewSubscription')}
                    type='marketing'
                    size='small'
                    block
                    onClick={renew}
                />
            </Modal.Controls>
        </Modal>
    )
}
