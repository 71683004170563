import StrangeError from '../common/common_errors/StrangeError'
import { TColor } from '../delphi_compatibility/DelphiBasicTypes'

export class ColorHelperFunctions {
    static MakeColor(color: TColor, opacity = 255): TColor {
        let colorPart: string
        let currentOpacity: number

        // Check if the color includes opacity
        if (/^#[\dA-Fa-f]{8}$/.test(color)) {
            // Extract color and current opacity
            colorPart = color.slice(0, 7)
            currentOpacity = parseInt(color.slice(7, 9), 16)
        } else if (/^#[\dA-Fa-f]{6}$/.test(color)) {
            // If color does not include opacity, use the whole color and default opacity
            colorPart = color
            currentOpacity = 255
        } else {
            throw new StrangeError('Invalid color format')
        }

        // If the new opacity is different from the current, update it
        if (opacity !== currentOpacity) {
            const hexOpacity = opacity.toString(16).padStart(2, '0')
            return `${colorPart}${hexOpacity}`
        }

        // If the opacity is the same, return the original color
        return color
    }

    static FixColor(color: TColor): TColor {
        //TODO: do we need this? Maybe this was for backwards compatibility with old FT versions?
        return color.length === 7 ? `${color}ff` : color
    }

    //-----Get basic color without opacity----------------------------------------
    static BasicColor(color: TColor): TColor {
        // Check if color starts with '#'
        if (color.startsWith('#')) {
            // Return the substring that excludes the alpha channel, if present
            return color.length > 7 ? color.slice(0, 7) : color
        } else {
            // If '#' is not present, return the color as is
            return `#${color}`
        }
    }

    static GetAntiColor(color: TColor) {
        // Extract the red, green, and blue components from the color
        const r = parseInt(color.slice(1, 3), 16)
        const g = parseInt(color.slice(3, 5), 16)
        const b = parseInt(color.slice(5, 7), 16)

        // Determine if the color is closer to black or white
        if (r + g + b < 500) {
            return '#FFFFFF' // clWhite
        } else {
            return '#000000' // clBlack
        }
    }

    static GetOpacity(color: TColor): number {
        return color.length === 9 ? parseInt(color.slice(7, 9), 16) / 255 : 255
    }

    static ModifyColor(color: string, useOpacity: boolean): string {
        if (useOpacity) {
            //return InvertOpacity(color); - does not make sense in FTO anymore, was used for backward compatibility with very old FT versions
            return color
        } else {
            // Assuming color is in the format "#RRGGBB" or "RRGGBB"
            // Extract the RRGGBB part and convert to a number for bitwise operation
            let numericColor = parseInt(color.replace(/^#/, ''), 16)
            // Apply bitwise AND with 0xFFFFFF to extract RGB components (ignoring opacity)
            numericColor &= 0xffffff
            // Convert back to hexadecimal string
            return numericColor.toString(16).padStart(6, '0')
        }
    }
}
