import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { Flex, Typography } from '@fto/ui'
import { AvailableOptionsSettings, CanvasSettingsProps, OptionSettingRef } from '../../../types'
import { useTranslation } from 'react-i18next'

import getOptionSettingByConfig from '../../../utils/getOptionSettingByConfig'
import OptionsConfig from './config'
import { SettingsTabRef } from '../../../types'

const CanvasSettings = forwardRef<SettingsTabRef, CanvasSettingsProps>(
    ({ chart, applyToAll, initialData, onDifferentSettingsUpdate }, ref) => {
        const { t } = useTranslation()
        const optionsRefs = useRef<OptionSettingRef[]>([])

        const loadOptionsSetting = useCallback((data: any, shouldUpdateConfig = true) => {
            if (optionsRefs.current) {
                for (const ref of optionsRefs.current) {
                    if (ref.name in data) ref.loadOptionSetting(data[ref.name], shouldUpdateConfig)
                }
            }
        }, [])

        const addRef = useCallback((ref: OptionSettingRef) => {
            if (ref && !optionsRefs.current.includes(ref)) {
                optionsRefs.current.push(ref)
            }
        }, [])

        const optionSettingByKey = useCallback(
            (option: AvailableOptionsSettings) => {
                return getOptionSettingByConfig(
                    {
                        tab: 'canvas',
                        chart,
                        option: option,
                        applyToAll,
                        onDifferentSettingsUpdate,
                        initialData
                    },
                    addRef
                )
            },
            [chart, applyToAll, initialData, onDifferentSettingsUpdate, addRef]
        )

        useImperativeHandle(ref, () => ({ loadOptionsSetting }))

        return (
            <Flex direction='column' block gap={12} justifyContent='flex-start'>
                {optionSettingByKey(OptionsConfig['OffsetPercentage'])}

                <Flex justifyContent='space-between'>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t('settingsModal.chartBasicStyle')}
                    </Typography>
                    <Typography color='gray-600' type='subtext-regular'>
                        {t('settingsModal.color')}
                    </Typography>
                </Flex>

                {optionSettingByKey(OptionsConfig['BackgroundColor'])}
                {optionSettingByKey(OptionsConfig['FrameAndTextColor'])}
                {optionSettingByKey(OptionsConfig['Grid'])}

                <Flex block>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t('settingsModal.candles')}
                    </Typography>
                </Flex>

                {optionSettingByKey(OptionsConfig['CandleUp'])}
                {optionSettingByKey(OptionsConfig['CandleDown'])}
                {optionSettingByKey(OptionsConfig['FillerUp'])}
                {optionSettingByKey(OptionsConfig['FillerDown'])}
                {optionSettingByKey(OptionsConfig['CandleUpShadowColor'])}
                {optionSettingByKey(OptionsConfig['CandleDownShadowColor'])}

                <Flex block>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t('settingsModal.orders')}
                    </Typography>
                </Flex>

                {optionSettingByKey(OptionsConfig['BuyMarker'])}
                {optionSettingByKey(OptionsConfig['SellMarker'])}
                {optionSettingByKey(OptionsConfig['StopLoss'])}
                {optionSettingByKey(OptionsConfig['TakeProfit'])}
                {optionSettingByKey(OptionsConfig['ProfitTransaction'])}
                {optionSettingByKey(OptionsConfig['LossTransaction'])}

                <Flex block>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t('settingsModal.price')}
                    </Typography>
                </Flex>

                {optionSettingByKey(OptionsConfig['AskLevel'])}
                {optionSettingByKey(OptionsConfig['MatchPriceWithBarsColor'])}
            </Flex>
        )
    }
)

export default CanvasSettings
