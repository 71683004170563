import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { fireMixpanelEvent, forgotPassword } from '@root/utils/api'
import { Button, Flex, InputField, Typography } from '@fto/ui'
import usePasswordField from '@root/hooks/usePasswordField'
import { IconButton } from '@fto/icons'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import MessageInformer from '@root/components/MessageInformer'
import { AxiosError } from 'axios'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'

const CreatePassword = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const {
        password,
        passwordType,
        isSubmitDisabled,
        isInputTypePassword,
        handlePasswordChange,
        handlePasswordInputTypeChange,
        validationData
    } = usePasswordField({ initialValue: '' })

    const {
        password: confirmPassword,
        passwordType: confirmPasswordType,
        isSubmitDisabled: isConfirmPasswordDisabled,
        isInputTypePassword: isConfirmInputTypePassword,
        handlePasswordChange: confirmPasswordHandler,
        handlePasswordInputTypeChange: confirmPasswordTypeChange,
        validationData: confirmationPasswordValidationData
    } = usePasswordField({ initialValue: '' })

    const createNewPassword = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault()
            setIsLoading(true)
            forgotPassword({
                password,
                code,
                email
            })
                .then(() => {
                    navigate(makeRoute(LOGIN_ROUTE))
                })
                .catch((err) => {
                    if (err instanceof AxiosError) {
                        setError(err.response?.data?.status)
                    }

                    if (err.status) {
                        setError(err.status)
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        [password, code, email]
    )

    const parseUrl = useCallback(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const hash = queryParameters.get('hash') as string
        const code = queryParameters.get('code') as string
        if (hash && code) {
            const email = window.atob(hash)
            setEmail(email)
            setCode(code)
            return
        }
        navigate(makeRoute(LOGIN_ROUTE))
    }, [])

    const passwordsMissMatch = useMemo(
        () => Boolean(password !== confirmPassword && password && confirmPassword),
        [password, confirmPassword]
    )

    const submitDisabled = useMemo(() => {
        return isSubmitDisabled || isConfirmPasswordDisabled || confirmPassword !== password
    }, [confirmPassword, password, isSubmitDisabled, isConfirmPasswordDisabled])

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'create_password' })
        parseUrl()
    }, [])

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h4-semibold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.createNewPasswordTitle')}
                        </Typography>
                        <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            {t('auth.createNewPasswordText')}
                        </Typography>
                    </Flex>
                    <form>
                        <Flex direction={'column'} gap={24}>
                            <InputField
                                id='password'
                                isAbsoluteHelperText
                                error={!validationData.isValid}
                                helperText={validationData.errorMessage}
                                type={passwordType}
                                value={password}
                                block
                                onChange={handlePasswordChange}
                                label={t('auth.password')}
                                placeholder={t('auth.password')}
                                suffix={
                                    <IconButton
                                        name={isInputTypePassword ? 'eye-off' : 'eye'}
                                        size={16}
                                        onClick={handlePasswordInputTypeChange}
                                    />
                                }
                            />
                            <InputField
                                id='confirm-password'
                                isAbsoluteHelperText
                                error={!confirmationPasswordValidationData.isValid}
                                helperText={confirmationPasswordValidationData.errorMessage}
                                type={confirmPasswordType}
                                value={confirmPassword}
                                block
                                onChange={confirmPasswordHandler}
                                label={t('auth.confirmPassword')}
                                placeholder={t('auth.confirmPassword')}
                                suffix={
                                    <IconButton
                                        name={isConfirmInputTypePassword ? 'eye-off' : 'eye'}
                                        size={16}
                                        onClick={confirmPasswordTypeChange}
                                    />
                                }
                            />
                            <MessageInformer
                                type='error'
                                isShown={!!error || passwordsMissMatch}
                                onClose={() => setError(null)}
                            >
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    {passwordsMissMatch
                                        ? t('auth.errors.password.passwordAndConfirmationMustBeEqual')
                                        : t(statusMessages[error || 'unknown'])}
                                </Typography>
                            </MessageInformer>
                            <Button
                                loading={isLoading}
                                type={'marketing'}
                                disabled={submitDisabled}
                                block
                                onClick={createNewPassword}
                                label={t('auth.createNewPassword')}
                            />
                        </Flex>
                    </form>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default CreatePassword
