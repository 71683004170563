export class TScrollInfo {
    public TotalWidthPix: number
    public PageWidthPix: number
    public MaxLeftBar: number
    public x_offset: number
    public LeftOffset: number
    public PixBetweenBars: number

    constructor() {
        this.TotalWidthPix = 0 // Default value
        this.PageWidthPix = 0 // Default value
        this.MaxLeftBar = 0 // Default value
        this.x_offset = 0 // Default value
        this.LeftOffset = 0 // Default value
        this.PixBetweenBars = 0 // Default value
    }
}
