import { makeAutoObservable } from 'mobx'

type ToolInfo = {
    isDrawing: boolean
    isCrosshairMode: boolean
    anyHiddenTools: boolean
    anyHiddenIndicators: boolean
    isMagnetMode: boolean
}

const initialInfo = {
    isDrawing: false,
    isCrosshairMode: false,
    anyHiddenTools: false,
    anyHiddenIndicators: false,
    isMagnetMode: false
}

class ToolInfoStore {
    info: ToolInfo

    constructor() {
        makeAutoObservable(this)

        this.info = initialInfo
        this.setInfo = this.setInfo.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    setInfo(updater: ((prevInfo: ToolInfo) => ToolInfo) | ToolInfo) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current info to get the updates
            this.info = {
                ...this.info,
                ...updater(this.info)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.info = {
                ...this.info,
                ...updater
            }
        }
    }

    resetStore() {
        this.info = initialInfo
    }
}

export default new ToolInfoStore()
