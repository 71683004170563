import { Icon } from '@fto/icons'
import { Button, Flex, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { DELETE_PROJECT_ACTION } from '@root/store/projects/project.actions'
import { $getProject } from '@root/store/projects/projects.selector'

import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'
import { getAllLocalStorageKeys } from '@root/utils/localStorage'
import { $getUser } from '@root/store/auth/selectors'

type Props = {
    projectId: string
}

export const DeleteProjectModal: FC<Props> = ({ projectId }) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const { userId } = useAppSelector($getUser)
    const project = useAppSelector((s) => $getProject(s, projectId))

    const handleDelete = useCallback(() => {
        if (projectId && project) {
            dispatch(DELETE_PROJECT_ACTION({ id: projectId, project })).then((responce) => {
                const allLocalStorageKeys = getAllLocalStorageKeys()
                const keyTemplate = `${userId}_${projectId}`

                allLocalStorageKeys.forEach((key) => {
                    if (key.includes(keyTemplate)) {
                        localStorage.removeItem(key)
                    }
                })
                responce.meta.requestStatus === 'fulfilled' && removeModal(MODAL_NAMES.projects.delete)
            })
        }
    }, [userId, projectId, project])

    return (
        <Modal name={MODAL_NAMES.projects.delete} size='sm'>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='trash' size={72} />
                    <Typography type='h3-bold'>{t('dashboard.modals.projects.deleteHeader')}</Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Flex direction='column' alignItems='center'>
                    <Typography color='gray-900' type='text-regular'>
                        {t('dashboard.modals.projects.remove')}
                    </Typography>
                    <Typography color='gray-900' type='text-regular'>
                        {t('dashboard.modals.projects.undone')}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls>
                <Flex gap={8}>
                    <Button
                        label={t('global.cancel')}
                        onClick={() => removeModal(MODAL_NAMES.projects.delete)}
                        type='secondary'
                    />
                    <Button
                        label={t('dashboard.modals.projects.deleteButton')}
                        onClick={handleDelete}
                        type='primary'
                        classNames={{ content: styles.red }}
                    />
                </Flex>
            </Modal.Controls>
        </Modal>
    )
}
