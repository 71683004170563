//MQL-like constants:

//imitation of the MQL4 values
export const __DATE__ = '2024.09.16 00:00:00'
export const __DATETIME__ = '2024.09.16 19:09:47'
export const __FILE__ = 'N/A'
export const __FUNCSIG__ = 'N/A'
export const __FUNCTION__ = 'N/A'
export const __LINE__ = 0
export const __MQLBUILD__ = 2409
export const __MQL4BUILD__ = 2409

export const MODE_FAST = 2
export const MODE_SLOW = 3

//ENUM_APPLIED_PRICE
export const PRICE_CLOSE = 0
export const PRICE_OPEN = 1
export const PRICE_HIGH = 2
export const PRICE_LOW = 3
export const PRICE_MEDIAN = 4
export const PRICE_TYPICAL = 5
export const PRICE_WEIGHTED = 6
export const cl_GridColor = 0x01000000

export const tlTop = 0
export const tlCenter = 1
export const tlBottom = 2

export const taLeftJustify = 0
export const taRightJustify = 1
export const taCenter = 2

//unchangeable constants

export const ALIGN_CENTER = 2
export const ALIGN_LEFT = 1
export const ALIGN_RIGHT = 0
export const ANCHOR_BOTTOM = 1
export const ANCHOR_CENTER = 8
export const ANCHOR_LEFT = 1
export const ANCHOR_LEFT_LOWER = 2
export const ANCHOR_LEFT_UPPER = 0
export const ANCHOR_LOWER = 3
export const ANCHOR_RIGHT = 5
export const ANCHOR_RIGHT_LOWER = 4
export const ANCHOR_RIGHT_UPPER = 6
export const ANCHOR_TOP = 0
export const ANCHOR_UPPER = 7
export const BORDER_FLAT = 0
export const BORDER_RAISED = 1
export const BORDER_SUNKEN = 2
export const CHAR_MAX = 127
export const CHAR_MIN = -128

export const clrNONE = 'clrNONE'
export const CLR_NONE = clrNONE

export const CORNER_LEFT_LOWER = 2
export const CORNER_LEFT_UPPER = 0
export const CORNER_RIGHT_LOWER = 3
export const CORNER_RIGHT_UPPER = 1
export const CP_ACP = 0
export const CP_OEMCP = 1
export const CP_SYMBOL = 42
export const CP_THREAD_ACP = 3
export const CP_UTF7 = 65000
export const CP_UTF8 = 65001
export const CRYPT_AES128 = 1
export const CRYPT_AES256 = 2
export const CRYPT_ARCH_ZIP = 7
export const CRYPT_BASE64 = 0
export const CRYPT_DES = 3
export const CRYPT_HASH_MD5 = 6
export const CRYPT_HASH_SHA1 = 4
export const CRYPT_HASH_SHA256 = 5
export const DBL_DIG = 15
export const DBL_EPSILON = Number.EPSILON
export const DBL_MANT_DIG = 53
export const DBL_MAX = Number.MAX_VALUE
export const DBL_MAX_10_EXP = 308
export const DBL_MAX_EXP = 1024
export const DBL_MIN = Number.MIN_VALUE
export const DBL_MIN_10_EXP = -307
export const DBL_MIN_EXP = -1021
export const DRAW_ARROW = 3
export const DRAW_HISTOGRAM = 2
export const DRAW_LINE = 0
export const DRAW_NONE = 12
export const DRAW_SECTION = 1
export const DRAW_ZIGZAG = 4
export const EMPTY = -1
export const EMPTY_VALUE = Number.MAX_SAFE_INTEGER
export const FLT_DIG = 6
export const FLT_EPSILON = Number.EPSILON
export const FLT_MANT_DIG = 24
export const FLT_MAX = 3.402823466e38
export const FLT_MAX_10_EXP = 38
export const FLT_MAX_EXP = 128
export const FLT_MIN = 0.0
export const FLT_MIN_10_EXP = -37
export const FLT_MIN_EXP = -125
export const GANN_DOWN_TREND = 1
export const GANN_UP_TREND = 0
export const IDABORT = 3
export const IDCANCEL = 2
export const IDCONTINUE = 11
export const IDIGNORE = 5
export const IDNO = 7
export const IDOK = 1
export const IDRETRY = 4
export const IDTRYAGAIN = 10
export const IDYES = 6
export const INT_MAX = 2147483647
export const INT_MIN = -2147483648
export const INVALID_HANDLE = -1
export const LONG_MAX = Number.MAX_SAFE_INTEGER
export const LONG_MIN = Number.MIN_SAFE_INTEGER
export const M_1_PI = 0.3183098861837907
export const M_2_PI = 0.6366197723675814
export const M_2_SQRTPI = 1.128379167095513
export const M_E = 2.718281828459045
export const M_LN10 = 2.302585092994046
export const M_LN2 = 0.6931471805599453
export const M_LOG10E = 0.4342944819032518
export const M_LOG2E = 1.442695040888963
export const M_PI = 3.141592653589793
export const M_PI_2 = 1.570796326794897
export const M_PI_4 = 0.7853981633974483
export const M_SQRT1_2 = 0.7071067811865476
export const M_SQRT2 = 1.414213562373095
export const MB_ABORTRETRYIGNORE = 2
export const MB_CANCELTRYCONTINUE = 6
export const MB_DEFBUTTON1 = 0
export const MB_DEFBUTTON2 = 256
export const MB_DEFBUTTON3 = 512
export const MB_DEFBUTTON4 = 768
export const MB_ICONEXCLAMATION = 48
export const MB_ICONWARNING = 48
export const MB_ICONINFORMATION = 64
export const MB_ICONASTERISK = 64
export const MB_ICONQUESTION = 32
export const MB_ICONSTOP = 16
export const MB_ICONERROR = 16
export const MB_ICONHAND = 16
export const MB_OK = 0
export const MB_OKCANCEL = 1
export const MB_RETRYCANCEL = 5
export const MB_YESNO = 4
export const MB_YESNOCANCEL = 3
export const NULL = null
export const OP_BUY = 0
export const OP_BUYLIMIT = 2
export const OP_BUYSTOP = 4
export const OP_SELL = 1
export const OP_SELLLIMIT = 3
export const OP_SELLSTOP = 5
export const PERIOD_CURRENT = 0
export const PERIOD_D1 = 1440
export const PERIOD_H1 = 60
export const PERIOD_H12 = 720
export const PERIOD_H2 = 120
export const PERIOD_H3 = 180
export const PERIOD_H4 = 240
export const PERIOD_H6 = 360
export const PERIOD_H8 = 480
export const PERIOD_M1 = 1
export const PERIOD_M10 = 10
export const PERIOD_M12 = 12
export const PERIOD_M15 = 15
export const PERIOD_M2 = 2
export const PERIOD_M20 = 20
export const PERIOD_M3 = 3
export const PERIOD_M30 = 30
export const PERIOD_M4 = 4
export const PERIOD_M5 = 5
export const PERIOD_M6 = 6
export const PERIOD_MN1 = 43200
export const PERIOD_W1 = 10080
export const POINTER_AUTOMATIC = 2
export const POINTER_DYNAMIC = 1
export const POINTER_INVALID = 0
export const SATURDAY = 6
export const SUNDAY = 0
export const SYMBOL_ARROWDOWN = 242
export const SYMBOL_ARROWUP = 241
export const SYMBOL_CHECKSIGN = 252
export const SYMBOL_LEFTPRICE = 5
export const SYMBOL_RIGHTPRICE = 6
export const SYMBOL_STOPSIGN = 251
export const SYMBOL_THUMBSDOWN = 68
export const SYMBOL_THUMBSUP = 67
export const THURSDAY = 4
export const TUESDAY = 2
export const UCHAR_MAX = 255
export const UINT_MAX = 4294967295
export const ULONG_MAX = Number.MAX_SAFE_INTEGER
export const USHORT_MAX = 65535
export const WHOLE_ARRAY = 0
export const WRONG_VALUE = -1

export const ACCOUNT_BALANCE = 37
export const ACCOUNT_COMPANY = 2
export const ACCOUNT_CREDIT = 38
export const ACCOUNT_CURRENCY = 36
export const ACCOUNT_EQUITY = 40
export const ACCOUNT_MARGIN_FREE = 42
export const ACCOUNT_LEVERAGE = 35
export const ACCOUNT_LIMIT_ORDERS = 47
export const ACCOUNT_LOGIN = 0
export const ACCOUNT_MARGIN = 41
export const ACCOUNT_MARGIN_LEVEL = 43
export const ACCOUNT_MARGIN_SO_CALL = 45
export const ACCOUNT_MARGIN_SO_MODE = 44
export const ACCOUNT_MARGIN_SO_SO = 46
export const ACCOUNT_NAME = 1
export const ACCOUNT_PROFIT = 39
export const ACCOUNT_SERVER = 3
export const ACCOUNT_STOPOUT_MODE_MONEY = 1
export const ACCOUNT_STOPOUT_MODE_PERCENT = 0
export const ACCOUNT_TRADE_ALLOWED = 33
export const ACCOUNT_TRADE_EXPERT = 34
export const ACCOUNT_TRADE_MODE = 32
export const ACCOUNT_TRADE_MODE_CONTEST = 1
export const ACCOUNT_TRADE_MODE_DEMO = 0
export const ACCOUNT_TRADE_MODE_REAL = 2
export const CHART_AUTOSCROLL = 4
export const CHART_BARS = 0
export const CHART_BEGIN = 0
export const CHART_BRING_TO_TOP = 35
export const CHART_CANDLES = 1
export const CHART_COLOR_ASK = 31
export const CHART_COLOR_BACKGROUND = 21
export const CHART_COLOR_BID = 30
export const CHART_COLOR_CANDLE_BEAR = 29
export const CHART_COLOR_CANDLE_BULL = 28
export const CHART_COLOR_CHART_DOWN = 26
export const CHART_COLOR_CHART_LINE = 27
export const CHART_COLOR_CHART_UP = 25
export const CHART_COLOR_FOREGROUND = 22
export const CHART_COLOR_GRID = 23
export const CHART_COLOR_LAST = 32
export const CHART_COLOR_STOP_LEVEL = 33
export const CHART_COLOR_VOLUME = 24
export const CHART_COMMENT = 20
export const CHART_CURRENT_POS = 1
export const CHART_DRAG_TRADE_LEVELS = 43
export const CHART_END = 2
export const CHART_EVENT_MOUSE_MOVE = 40
export const CHART_EVENT_OBJECT_CREATE = 38
export const CHART_EVENT_OBJECT_DELETE = 39
export const CHART_FIRST_VISIBLE_BAR = 104
export const CHART_FIXED_MAX = 8
export const CHART_FIXED_MIN = 9
export const CHART_FIXED_POSITION = 41
export const CHART_FOREGROUND = 1
export const CHART_HEIGHT_IN_PIXELS = 107
export const CHART_IS_OFFLINE = 112
export const CHART_LINE = 2
export const CHART_MODE = 0
export const CHART_MOUSE_SCROLL = 42
export const CHART_POINTS_PER_BAR = 11
export const CHART_PRICE_MAX = 109
export const CHART_PRICE_MIN = 108
export const CHART_SCALE = 5
export const CHART_SCALE_PT_PER_BAR = 10
export const CHART_SCALEFIX = 6
export const CHART_SCALEFIX_11 = 7
export const CHART_SHIFT = 2
export const CHART_SHIFT_SIZE = 3
export const CHART_SHOW_ASK_LINE = 14
export const CHART_SHOW_BID_LINE = 13
export const CHART_SHOW_DATE_SCALE = 36
export const CHART_SHOW_GRID = 17
export const CHART_SHOW_LAST_LINE = 15
export const CHART_SHOW_OBJECT_DESCR = 19
export const CHART_SHOW_OHLC = 12
export const CHART_SHOW_PERIOD_SEP = 16
export const CHART_SHOW_PRICE_SCALE = 37
export const CHART_SHOW_TRADE_LEVELS = 34
export const CHART_SHOW_VOLUMES = 18
export const CHART_VISIBLE_BARS = 100
export const CHART_VOLUME_HIDE = 0
export const CHART_VOLUME_TICK = 1
export const CHART_WIDTH_IN_BARS = 105
export const CHART_WIDTH_IN_PIXELS = 106
export const CHART_WINDOW_HANDLE = 103
export const CHART_WINDOW_IS_VISIBLE = 102
export const CHART_WINDOW_YDISTANCE = 110
export const CHART_WINDOWS_TOTAL = 101
export const CHARTEVENT_CHART_CHANGE = 9
export const CHARTEVENT_CLICK = 4
export const CHARTEVENT_CUSTOM = 1000
export const CHARTEVENT_CUSTOM_LAST = 66534
export const CHARTEVENT_KEYDOWN = 0
export const CHARTEVENT_MOUSE_MOVE = 10
export const CHARTEVENT_OBJECT_CHANGE = 8
export const CHARTEVENT_OBJECT_CLICK = 1
export const CHARTEVENT_OBJECT_CREATE = 7
export const CHARTEVENT_OBJECT_DELETE = 6
export const CHARTEVENT_OBJECT_DRAG = 2
export const CHARTEVENT_OBJECT_ENDEDIT = 3
export const CHARTS_MAX = 100

export const ERR_ACCOUNT_DISABLED = 64
export const ERR_ARRAY_AS_PARAMETER_EXPECTED = 4065
export const ERR_ARRAY_INDEX_OUT_OF_RANGE = 4002
export const ERR_ARRAY_INVALID = 4029
export const ERR_BROKER_BUSY = 137
export const ERR_CANNOT_CALL_FUNCTION = 4019
export const ERR_CANNOT_LOAD_LIBRARY = 4018
export const ERR_CANNOT_OPEN_FILE = 4103
export const ERR_CHART_NOREPLY = 4030
export const ERR_CHART_NOT_FOUND = 4211
export const ERR_CHART_PROP_INVALID = 4210
export const ERR_CHARTINDICATOR_NOT_FOUND = 4213
export const ERR_CHARTWINDOW_NOT_FOUND = 4212
export const ERR_COMMON_ERROR = 2
export const ERR_CUSTOM_INDICATOR_ERROR = 4055
export const ERR_DLL_CALLS_NOT_ALLOWED = 4017
export const ERR_DLLFUNC_CRITICALERROR = 4023
export const ERR_DOUBLE_PARAMETER_EXPECTED = 4064
export const ERR_END_OF_FILE = 4099
export const ERR_EXTERNAL_CALLS_NOT_ALLOWED = 4020
export const ERR_FILE_ARRAYRESIZE_ERROR = 5027
export const ERR_FILE_BIN_STRINGSIZE = 5017
export const ERR_FILE_BUFFER_ALLOCATION_ERROR = 5005
export const ERR_FILE_CANNOT_CLEAN_DIRECTORY = 5026
export const ERR_FILE_CANNOT_DELETE = 5006
export const ERR_FILE_CANNOT_DELETE_DIRECTORY = 5025
export const ERR_FILE_CANNOT_OPEN = 5004
export const ERR_FILE_CANNOT_REWRITE = 5021
export const ERR_FILE_DIRECTORY_NOT_EXIST = 5023
export const ERR_FILE_INCOMPATIBLE = 5018
export const ERR_FILE_INVALID_HANDLE = 5007
export const ERR_FILE_IS_DIRECTORY = 5019
export const ERR_FILE_NOT_BIN = 5011
export const ERR_FILE_NOT_CSV = 5014
export const ERR_FILE_NOT_DIRECTORY = 5024
export const ERR_FILE_NOT_EXIST = 5020
export const ERR_FILE_NOT_TOREAD = 5010
export const ERR_FILE_NOT_TOWRITE = 5009
export const ERR_FILE_NOT_TXT = 5012
export const ERR_FILE_NOT_TXTORCSV = 5013
export const ERR_FILE_READ_ERROR = 5015
export const ERR_FILE_STRINGRESIZE_ERROR = 5028
export const ERR_FILE_STRUCT_WITH_OBJECTS = 5029
export const ERR_FILE_TOO_LONG_FILENAME = 5003
export const ERR_FILE_TOO_MANY_OPENED = 5001
export const ERR_FILE_WRITE_ERROR = 5016
export const ERR_FILE_WRONG_DIRECTORYNAME = 5022
export const ERR_FILE_WRONG_FILENAME = 5002
export const ERR_FILE_WRONG_HANDLE = 5008
export const ERR_FORMAT_TOO_MANY_FORMATTERS = 4027
export const ERR_FORMAT_TOO_MANY_PARAMETERS = 4028
export const ERR_FUNC_NOT_ALLOWED_IN_TESTING = 4059
export const ERR_FUNCTION_NOT_CONFIRMED = 4060
export const ERR_GLOBAL_VARIABLE_NOT_FOUND = 4058
export const ERR_GLOBAL_VARIABLES_PROCESSING = 4057
export const ERR_HISTORY_WILL_UPDATED = 4066
export const ERR_INCOMPATIBLE_ARRAYS = 4056
export const ERR_INCOMPATIBLE_FILEACCESS = 4104
export const ERR_INCORRECT_SERIESARRAY_USING = 4054
export const ERR_INDICATOR_CANNOT_INIT = 4071
export const ERR_INDICATOR_CANNOT_LOAD = 4072
export const ERR_INTEGER_PARAMETER_EXPECTED = 4063
export const ERR_INTERNAL_ERROR = 4024
export const ERR_INVALID_ACCOUNT = 65
export const ERR_INVALID_FUNCTION_PARAMSCNT = 4050
export const ERR_INVALID_FUNCTION_PARAMVALUE = 4051
export const ERR_INVALID_POINTER = 4026
export const ERR_INVALID_PRICE = 129
export const ERR_INVALID_PRICE_PARAM = 4107
export const ERR_INVALID_STOPS = 130
export const ERR_INVALID_TICKET = 4108
export const ERR_INVALID_TRADE_PARAMETERS = 3
export const ERR_INVALID_TRADE_VOLUME = 131
export const ERR_LONG_POSITIONS_ONLY_ALLOWED = 140
export const ERR_LONGS_NOT_ALLOWED = 4110
export const ERR_MALFUNCTIONAL_TRADE = 9
export const ERR_MARKET_CLOSED = 132
export const ERR_NO_CONNECTION = 6
export const ERR_NO_ERROR = 0
export const ERR_NO_HISTORY_DATA = 4073
export const ERR_NO_MEMORY_FOR_ARRAYSTRING = 4010
export const ERR_NO_MEMORY_FOR_CALL_STACK = 4003
export const ERR_NO_MEMORY_FOR_HISTORY = 4074
export const ERR_NO_MEMORY_FOR_PARAM_STRING = 4006
export const ERR_NO_MEMORY_FOR_RETURNED_STR = 4021
export const ERR_NO_MEMORY_FOR_TEMP_STRING = 4007
export const ERR_NO_MQLERROR = 4000
export const ERR_NO_OBJECT_NAME = 4204
export const ERR_NO_ORDER_SELECTED = 4105
export const ERR_NO_RESULT = 1
export const ERR_NO_SPECIFIED_SUBWINDOW = 4206
export const ERR_NOT_ENOUGH_MONEY = 134
export const ERR_NOT_ENOUGH_RIGHTS = 7
export const ERR_NOT_ENOUGH_STACK_FOR_PARAM = 4005
export const ERR_NOT_INITIALIZED_ARRAY = 4016
export const ERR_NOT_INITIALIZED_ARRAYSTRING = 4009
export const ERR_NOT_INITIALIZED_STRING = 4008
export const ERR_NOTIFICATION_ERROR = 4250
export const ERR_NOTIFICATION_PARAMETER = 4251
export const ERR_NOTIFICATION_SETTINGS = 4252
export const ERR_NOTIFICATION_TOO_FREQUENT = 4253
export const ERR_OBJECT_ALREADY_EXISTS = 4200
export const ERR_OBJECT_COORDINATES_ERROR = 4205
export const ERR_OBJECT_DOES_NOT_EXIST = 4202
export const ERR_OFF_QUOTES = 136
export const ERR_OLD_VERSION = 5
export const ERR_ORDER_LOCKED = 139
export const ERR_OUT_OF_MEMORY = 4025
export const ERR_PRICE_CHANGED = 135
export const ERR_RECURSIVE_STACK_OVERFLOW = 4004
export const ERR_REMAINDER_FROM_ZERO_DIVIDE = 4012
export const ERR_REQUOTE = 138
export const ERR_RESOURCE_DUPLICATED = 4070
export const ERR_RESOURCE_NOT_FOUND = 4068
export const ERR_RESOURCE_NOT_SUPPORTED = 4069
export const ERR_SEND_MAIL_ERROR = 4061
export const ERR_SERVER_BUSY = 4
export const ERR_SHORTS_NOT_ALLOWED = 4111
export const ERR_SOME_ARRAY_ERROR = 4053
export const ERR_SOME_FILE_ERROR = 4100
export const ERR_SOME_OBJECT_ERROR = 4207
export const ERR_STRING_FUNCTION_INTERNAL = 4052
export const ERR_STRING_PARAMETER_EXPECTED = 4062
export const ERR_SYMBOL_SELECT = 4220
export const ERR_SYSTEM_BUSY = 4022
export const ERR_TOO_FREQUENT_REQUESTS = 8
export const ERR_TOO_LONG_STRING = 4011
export const ERR_TOO_MANY_OPENED_FILES = 4102
export const ERR_TOO_MANY_REQUESTS = 141
export const ERR_TRADE_CONTEXT_BUSY = 146
export const ERR_TRADE_DISABLED = 133
export const ERR_TRADE_ERROR = 4067
export const ERR_TRADE_EXPERT_DISABLED_BY_SERVER = 4112
export const ERR_TRADE_EXPIRATION_DENIED = 147
export const ERR_TRADE_HEDGE_PROHIBITED = 149
export const ERR_TRADE_MODIFY_DENIED = 145
export const ERR_TRADE_NOT_ALLOWED = 4109
export const ERR_TRADE_PROHIBITED_BY_FIFO = 150
export const ERR_TRADE_TIMEOUT = 128
export const ERR_TRADE_TOO_MANY_ORDERS = 148
export const ERR_UNKNOWN_COMMAND = 4014
export const ERR_UNKNOWN_OBJECT_PROPERTY = 4201
export const ERR_UNKNOWN_OBJECT_TYPE = 4203
export const ERR_UNKNOWN_SYMBOL = 4106
export const ERR_USER_ERROR_FIRST = 65536
export const ERR_WEBREQUEST_CONNECT_FAILED = 5201
export const ERR_WEBREQUEST_INVALID_ADDRESS = 5200
export const ERR_WEBREQUEST_REQUEST_FAILED = 5203
export const ERR_WEBREQUEST_TIMEOUT = 5202
export const ERR_WRONG_FILE_NAME = 4101
export const ERR_WRONG_FUNCTION_POINTER = 4001
export const ERR_WRONG_JUMP = 4015
export const ERR_ZERO_DIVIDE = 4013
export const FILE_ACCESS_DATE = 4
export const FILE_ANSI = 32
export const FILE_BIN = 4
export const FILE_COMMON = 4096
export const FILE_CREATE_DATE = 2
export const FILE_CSV = 8
export const FILE_END = 7
export const FILE_EXISTS = 1
export const FILE_IS_ANSI = 13
export const FILE_IS_BINARY = 11
export const FILE_IS_COMMON = 9
export const FILE_IS_CSV = 12
export const FILE_IS_READABLE = 14
export const FILE_IS_TEXT = 10
export const FILE_IS_WRITABLE = 15
export const FILE_LINE_END = 8
export const FILE_MODIFY_DATE = 3
export const FILE_POSITION = 6
export const FILE_READ = 1
export const FILE_REWRITE = 512
export const FILE_SHARE_READ = 128
export const FILE_SHARE_WRITE = 256
export const FILE_SIZE = 5
export const FILE_TXT = 16
export const FILE_UNICODE = 64
export const FILE_WRITE = 2
export const FRIDAY = 5
export const INDICATOR_DIGITS = 0
export const INDICATOR_HEIGHT = 10
export const INDICATOR_LEVELCOLOR = 5
export const INDICATOR_LEVELS = 4
export const INDICATOR_LEVELSTYLE = 6
export const INDICATOR_LEVELTEXT = 9
export const INDICATOR_LEVELVALUE = 8
export const INDICATOR_LEVELWIDTH = 7
export const INDICATOR_MAXIMUM = 3
export const INDICATOR_MINIMUM = 2
export const INDICATOR_SHORTNAME = 1
export const IS_DEBUG_MODE = 0
export const IS_PROFILE_MODE = 0
export const MODE_ASK = 10
export const MODE_BID = 9
export const MODE_CHIKOUSPAN = 5
export const MODE_CLOSE = 3
export const MODE_DIGITS = 12
export const MODE_EMA = 1
export const MODE_EXPIRATION = 21
export const MODE_FREEZELEVEL = 33
export const MODE_GATORJAW = 1
export const MODE_GATORLIPS = 3
export const MODE_GATORTEETH = 2
export const MODE_HIGH = 2
export const MODE_HISTORY = 1
export const MODE_KIJUNSEN = 2
export const MODE_LOTSIZE = 15
export const MODE_LOTSTEP = 24
export const MODE_LOW = 1
export const MODE_LOWER = 2
export const MODE_LWMA = 3
export const MODE_MAIN = 0
export const MODE_MARGINCALCMODE = 28
export const MODE_MARGINHEDGED = 31
export const MODE_MARGININIT = 29
export const MODE_MARGINMAINTENANCE = 30
export const MODE_MARGINREQUIRED = 32
export const MODE_MAXLOT = 25
export const MODE_MINLOT = 23
export const MODE_MINUSDI = 2
export const MODE_OPEN = 0
export const MODE_PLUSDI = 1
export const MODE_POINT = 11
export const MODE_PROFITCALCMODE = 27
export const MODE_SENKOUSPANA = 3
export const MODE_SENKOUSPANB = 4
export const MODE_SIGNAL = 1
export const MODE_SMA = 0
export const MODE_SMMA = 2
export const MODE_SPREAD = 13
export const MODE_STARTING = 20
export const MODE_STOPLEVEL = 14
export const MODE_SWAPLONG = 18
export const MODE_SWAPSHORT = 19
export const MODE_SWAPTYPE = 26
export const MODE_TENKANSEN = 1
export const MODE_TICKSIZE = 17
export const MODE_TICKVALUE = 16
export const MODE_TIME = 5
export const MODE_TRADEALLOWED = 22
export const MODE_TRADES = 0
export const MODE_UPPER = 1
export const MODE_VOLUME = 4
export const MONDAY = 1
export const MQL_CODEPAGE = 15
export const MQL_DEBUG = 5
export const MQL_DLLS_ALLOWED = 3
export const MQL_LICENSE_TYPE = 9
export const MQL_OPTIMIZATION = 7
export const MQL_PROFILER = 10
export const MQL_PROGRAM_NAME = 0
export const MQL_PROGRAM_PATH = 1
export const MQL_PROGRAM_TYPE = 2
export const MQL_SIGNALS_ALLOWED = 14
export const MQL_TESTER = 6
export const MQL_TRADE_ALLOWED = 4
export const MQL_VISUAL_MODE = 8
export const OBJ_ALL_PERIODS = 511
export const OBJ_ARROW = 22
export const OBJ_ARROW_BUY = 37
export const OBJ_ARROW_CHECK = 34
export const OBJ_ARROW_DOWN = 32
export const OBJ_ARROW_LEFT_PRICE = 35
export const OBJ_ARROW_RIGHT_PRICE = 36
export const OBJ_ARROW_SELL = 38
export const OBJ_ARROW_STOP = 33
export const OBJ_ARROW_THUMB_DOWN = 30
export const OBJ_ARROW_THUMB_UP = 29
export const OBJ_ARROW_UP = 31
export const OBJ_BITMAP = 26
export const OBJ_BITMAP_LABEL = 24
export const OBJ_BUTTON = 25
export const OBJ_CHANNEL = 5
export const OBJ_CYCLES = 20
export const OBJ_EDIT = 27
export const OBJ_ELLIPSE = 18
export const OBJ_EVENT = 42
export const OBJ_EXPANSION = 14
export const OBJ_FIBO = 10
export const OBJ_FIBOARC = 13
export const OBJ_FIBOCHANNEL = 15
export const OBJ_FIBOFAN = 12
export const OBJ_FIBOTIMES = 11
export const OBJ_GANNFAN = 8
export const OBJ_GANNGRID = 9
export const OBJ_GANNLINE = 7
export const OBJ_HLINE = 1
export const OBJ_LABEL = 23
export const OBJ_NO_PERIODS = -1
export const OBJ_PERIOD_D1 = 64
export const OBJ_PERIOD_H1 = 16
export const OBJ_PERIOD_H4 = 32
export const OBJ_PERIOD_M1 = 1
export const OBJ_PERIOD_M15 = 4
export const OBJ_PERIOD_M30 = 8
export const OBJ_PERIOD_M5 = 2
export const OBJ_PERIOD_MN1 = 256
export const OBJ_PERIOD_W1 = 128
export const OBJ_PITCHFORK = 19
export const OBJ_RECTANGLE = 16
export const OBJ_RECTANGLE_LABEL = 28
export const OBJ_REGRESSION = 4
export const OBJ_STDDEVCHANNEL = 6
export const OBJ_TEXT = 21
export const OBJ_TREND = 2
export const OBJ_TRENDBYANGLE = 3
export const OBJ_TRIANGLE = 17
export const OBJ_VLINE = 0
export const OBJPROP_ALIGN = 1036
export const OBJPROP_ANCHOR = 1011
export const OBJPROP_ANGLE = 13
export const OBJPROP_ARROWCODE = 14
export const OBJPROP_BACK = 9
export const OBJPROP_BGCOLOR = 1025
export const OBJPROP_BMPFILE = 1017
export const OBJPROP_BORDER_COLOR = 1035
export const OBJPROP_BORDER_TYPE = 1029
export const OBJPROP_COLOR = 6
export const OBJPROP_CORNER = 101
export const OBJPROP_CREATETIME = 998
export const OBJPROP_DEVIATION = 16
export const OBJPROP_ELLIPSE = 11
export const OBJPROP_FIBOLEVELS = 200
export const OBJPROP_FIRSTLEVEL = 210
export const OBJPROP_FONT = 1001
export const OBJPROP_FONTSIZE = 100
export const OBJPROP_HIDDEN = 208
export const OBJPROP_LEVELCOLOR = 201
export const OBJPROP_LEVELS = 200
export const OBJPROP_LEVELSTYLE = 202
export const OBJPROP_LEVELTEXT = 205
export const OBJPROP_LEVELVALUE = 204
export const OBJPROP_LEVELWIDTH = 203
export const OBJPROP_NAME = 1037
export const OBJPROP_PRICE = 20
export const OBJPROP_PRICE1 = 1
export const OBJPROP_PRICE2 = 3
export const OBJPROP_PRICE3 = 5
export const OBJPROP_RAY = 10
export const OBJPROP_RAY_RIGHT = 1004
export const OBJPROP_READONLY = 1028
export const OBJPROP_SCALE = 12
export const OBJPROP_SELECTABLE = 1000
export const OBJPROP_SELECTED = 17
export const OBJPROP_STATE = 1018
export const OBJPROP_STYLE = 7
export const OBJPROP_SYMBOL = 1021
export const OBJPROP_TEXT = 999
export const OBJPROP_TIME = 19
export const OBJPROP_TIME1 = 0
export const OBJPROP_TIME2 = 2
export const OBJPROP_TIME3 = 4
export const OBJPROP_TIMEFRAMES = 15
export const OBJPROP_TOOLTIP = 206
export const OBJPROP_TYPE = 18
export const OBJPROP_WIDTH = 8
export const OBJPROP_XDISTANCE = 102
export const OBJPROP_XOFFSET = 1033
export const OBJPROP_XSIZE = 1019
export const OBJPROP_YDISTANCE = 103
export const OBJPROP_YOFFSET = 1034
export const OBJPROP_YSIZE = 1020
export const OBJPROP_ZORDER = 207
export const REASON_ACCOUNT = 6
export const REASON_CHARTCHANGE = 3
export const REASON_CHARTCLOSE = 4
export const REASON_CLOSE = 9
export const REASON_INITFAILED = 8
export const REASON_PARAMETERS = 5
export const REASON_PROGRAM = 0
export const REASON_RECOMPILE = 2
export const REASON_REMOVE = 1
export const REASON_TEMPLATE = 7
export const SEEK_CUR = 1
export const SEEK_END = 2
export const SEEK_SET = 0
export const SELECT_BY_POS = 0
export const SELECT_BY_TICKET = 1
export const SERIES_BARS_COUNT = 0
export const SERIES_FIRSTDATE = 1
export const SERIES_LASTBAR_DATE = 5
export const SERIES_SERVER_FIRSTDATE = 2
export const SHORT_MAX = 32767
export const SHORT_MIN = -32768
export const SIGNAL_BASE_AUTHOR_LOGIN = 13
export const SIGNAL_BASE_BALANCE = 17
export const SIGNAL_BASE_BROKER = 10
export const SIGNAL_BASE_BROKER_SERVER = 11
export const SIGNAL_BASE_CURRENCY = 14
export const SIGNAL_BASE_DATE_PUBLISHED = 1
export const SIGNAL_BASE_DATE_STARTED = 2
export const SIGNAL_BASE_EQUITY = 19
export const SIGNAL_BASE_GAIN = 16
export const SIGNAL_BASE_ID = 0
export const SIGNAL_BASE_LEVERAGE = 6
export const SIGNAL_BASE_MAX_DRAWDOWN = 18
export const SIGNAL_BASE_NAME = 12
export const SIGNAL_BASE_PIPS = 8
export const SIGNAL_BASE_PRICE = 15
export const SIGNAL_BASE_RATING = 9
export const SIGNAL_BASE_ROI = 20
export const SIGNAL_BASE_SUBSCRIBERS = 4
export const SIGNAL_BASE_TRADE_MODE = 5
export const SIGNAL_BASE_TRADES = 7
export const SIGNAL_INFO_CONFIRMATIONS_DISABLED = 4
export const SIGNAL_INFO_COPY_SLTP = 3
export const SIGNAL_INFO_DEPOSIT_PERCENT = 5
export const SIGNAL_INFO_EQUITY_LIMIT = 8
export const SIGNAL_INFO_ID = 0
export const SIGNAL_INFO_NAME = 6
export const SIGNAL_INFO_SLIPPAGE = 9
export const SIGNAL_INFO_SUBSCRIPTION_ENABLED = 1
export const SIGNAL_INFO_TERMS_AGREE = 2
export const SIGNAL_INFO_VOLUME_PERCENT = 7
export const STAT_BALANCE_DD = 12
export const STAT_BALANCE_DD_RELATIVE = 14
export const STAT_BALANCE_DDREL_PERCENT = 15
export const STAT_BALANCEDD_PERCENT = 13
export const STAT_BALANCEMIN = 11
export const STAT_CONLOSSMAX = 9
export const STAT_CONLOSSMAX_TRADES = 37
export const STAT_CONPROFITMAX = 7
export const STAT_CONPROFITMAX_TRADES = 35
export const STAT_CUSTOM_ONTESTER = 26
export const STAT_DEALS = 27
export const STAT_EQUITY_DD = 17
export const STAT_EQUITY_DD_RELATIVE = 19
export const STAT_EQUITY_DDREL_PERCENT = 20
export const STAT_EQUITYDD_PERCENT = 18
export const STAT_EQUITYMIN = 16
export const STAT_EXPECTED_PAYOFF = 21
export const STAT_GROSS_LOSS = 4
export const STAT_GROSS_PROFIT = 3
export const STAT_INITIAL_DEPOSIT = 0
export const STAT_LONG_TRADES = 32
export const STAT_LOSS_TRADES = 30
export const STAT_LOSSTRADES_AVGCON = 40
export const STAT_MAX_CONLOSS_TRADES = 38
export const STAT_MAX_CONLOSSES = 10
export const STAT_MAX_CONPROFIT_TRADES = 36
export const STAT_MAX_CONWINS = 8
export const STAT_MAX_LOSSTRADE = 6
export const STAT_MAX_PROFITTRADE = 5
export const STAT_MIN_MARGINLEVEL = 25
export const STAT_PROFIT = 2
export const STAT_PROFIT_FACTOR = 22
export const STAT_PROFIT_LONGTRADES = 34
export const STAT_PROFIT_SHORTTRADES = 33
export const STAT_PROFIT_TRADES = 29
export const STAT_PROFITTRADES_AVGCON = 39
export const STAT_RECOVERY_FACTOR = 23
export const STAT_SHARPE_RATIO = 24
export const STAT_SHORT_TRADES = 31
export const STAT_TRADES = 28
export const STAT_WITHDRAWAL = 1
export const STO_CLOSECLOSE = 1
export const STO_LOWHIGH = 0
export const STYLE_DASH = 1
export const STYLE_DASHDOT = 3
export const STYLE_DASHDOTDOT = 4
export const STYLE_DOT = 2
export const STYLE_SOLID = 0
export const SYMBOL_ASK = 4
export const SYMBOL_ASKHIGH = 5
export const SYMBOL_ASKLOW = 6
export const SYMBOL_BID = 1
export const SYMBOL_BIDHIGH = 2
export const SYMBOL_BIDLOW = 3
export const SYMBOL_CURRENCY_BASE = 22
export const SYMBOL_CURRENCY_MARGIN = 24
export const SYMBOL_CURRENCY_PROFIT = 23
export const SYMBOL_DESCRIPTION = 20
export const SYMBOL_DIGITS = 17
export const SYMBOL_EXPIRATION_MODE = 49
export const SYMBOL_EXPIRATION_TIME = 52
export const SYMBOL_FILLING_MODE = 50
export const SYMBOL_LAST = 7
export const SYMBOL_LASTHIGH = 8
export const SYMBOL_LASTLOW = 9
export const SYMBOL_MARGIN_INITIAL = 42
export const SYMBOL_MARGIN_LIMIT = 46
export const SYMBOL_MARGIN_LONG = 44
export const SYMBOL_MARGIN_MAINTENANCE = 43
export const SYMBOL_MARGIN_SHORT = 45
export const SYMBOL_MARGIN_STOP = 47
export const SYMBOL_MARGIN_STOPLIMIT = 48
export const SYMBOL_ORDER_MODE = 71
export const SYMBOL_PATH = 21
export const SYMBOL_POINT = 16
export const SYMBOL_SELECT = 0
export const SYMBOL_SESSION_AW = 66
export const SYMBOL_SESSION_BUY_ORDERS = 60
export const SYMBOL_SESSION_BUY_ORDERS_VOLUME = 61
export const SYMBOL_SESSION_CLOSE = 65
export const SYMBOL_SESSION_DEALS = 56
export const SYMBOL_SESSION_INTEREST = 59
export const SYMBOL_SESSION_OPEN = 64
export const SYMBOL_SESSION_PRICE_LIMIT_MAX = 69
export const SYMBOL_SESSION_PRICE_LIMIT_MIN = 68
export const SYMBOL_SESSION_PRICE_SETTLEMENT = 67
export const SYMBOL_SESSION_SELL_ORDERS = 62
export const SYMBOL_SESSION_SELL_ORDERS_VOLUME = 63
export const SYMBOL_SESSION_TURNOVER = 58
export const SYMBOL_SESSION_VOLUME = 57
export const SYMBOL_SPREAD = 18
export const SYMBOL_SPREAD_FLOAT = 41
export const SYMBOL_START_TIME = 51
export const SYMBOL_SWAP_LONG = 38
export const SYMBOL_SWAP_MODE = 37
export const SYMBOL_SWAP_ROLLOVER3DAYS = 40
export const SYMBOL_SWAP_SHORT = 39
export const SYMBOL_TIME = 15
export const SYMBOL_TRADE_CALC_MODE = 29
export const SYMBOL_TRADE_CONTRACT_SIZE = 28
export const SYMBOL_TRADE_EXECUTION_EXCHANGE = 3
export const SYMBOL_TRADE_EXECUTION_INSTANT = 1
export const SYMBOL_TRADE_EXECUTION_MARKET = 2
export const SYMBOL_TRADE_EXECUTION_REQUEST = 0
export const SYMBOL_TRADE_EXEMODE = 33
export const SYMBOL_TRADE_FREEZE_LEVEL = 32
export const SYMBOL_TRADE_MODE = 30
export const SYMBOL_TRADE_MODE_CLOSEONLY = 1
export const SYMBOL_TRADE_MODE_DISABLED = 0
export const SYMBOL_TRADE_MODE_FULL = 2
export const SYMBOL_TRADE_MODE_LONGONLY = 3
export const SYMBOL_TRADE_MODE_SHORTONLY = 4
export const SYMBOL_TRADE_STOPS_LEVEL = 31
export const SYMBOL_TRADE_TICK_SIZE = 27
export const SYMBOL_TRADE_TICK_VALUE = 26
export const SYMBOL_TRADE_TICK_VALUE_LOSS = 54
export const SYMBOL_TRADE_TICK_VALUE_PROFIT = 53
export const SYMBOL_VOLUME = 10
export const SYMBOL_VOLUME_LIMIT = 55
export const SYMBOL_VOLUME_MAX = 35
export const SYMBOL_VOLUME_MIN = 34
export const SYMBOL_VOLUME_STEP = 36
export const SYMBOL_VOLUMEHIGH = 11
export const SYMBOL_VOLUMELOW = 12
export const TERMINAL_BUILD = 5
export const TERMINAL_CODEPAGE = 12
export const TERMINAL_COMMONDATA_PATH = 4
export const TERMINAL_COMMUNITY_ACCOUNT = 23
export const TERMINAL_COMMUNITY_BALANCE = 25
export const TERMINAL_COMMUNITY_CONNECTION = 24
export const TERMINAL_COMPANY = 0
export const TERMINAL_CONNECTED = 6
export const TERMINAL_CPU_CORES = 21
export const TERMINAL_DATA_PATH = 3
export const TERMINAL_DISK_SPACE = 20
export const TERMINAL_DLLS_ALLOWED = 7
export const TERMINAL_EMAIL_ENABLED = 9
export const TERMINAL_FTP_ENABLED = 10
export const TERMINAL_LANGUAGE = 13
export const TERMINAL_MAXBARS = 11
export const TERMINAL_MEMORY_AVAILABLE = 16
export const TERMINAL_MEMORY_PHYSICAL = 14
export const TERMINAL_MEMORY_TOTAL = 15
export const TERMINAL_MEMORY_USED = 17
export const TERMINAL_MQID = 22
export const TERMINAL_NAME = 1
export const TERMINAL_NOTIFICATIONS_ENABLED = 26
export const TERMINAL_PATH = 2
export const TERMINAL_PING_LAST = 28
export const TERMINAL_SCREEN_DPI = 27
export const TERMINAL_TRADE_ALLOWED = 8
export const WEDNESDAY = 3

export const clrAliceBlue = '#F0F8FF'
export const clrAntiqueWhite = '#FAEBD7'
export const clrAqua = '#00FFFF'
export const clrAquamarine = '#7FFFD4'
export const clrBeige = '#F5F5DC'
export const clrBisque = '#FFE4C4'
export const clrBlack = '#000000'
export const clrBlanchedAlmond = '#FFEBCD'
export const clrBlue = '#0000FF'
export const clrBlueViolet = '#8A2BE2'
export const clrBrown = '#A52A2A'
export const clrBurlyWood = '#DEB887'
export const clrCadetBlue = '#5F9EA0'
export const clrChartreuse = '#7FFF00'
export const clrChocolate = '#D2691E'
export const clrCoral = '#FF7F50'
export const clrCornflowerBlue = '#6495ED'
export const clrCornsilk = '#FFF8DC'
export const clrCrimson = '#DC143C'
export const clrDarkBlue = '#00008B'
export const clrDarkGoldenrod = '#B8860B'
export const clrDarkGray = '#A9A9A9'
export const clrDarkGreen = '#006400'
export const clrDarkKhaki = '#BDB76B'
export const clrDarkOliveGreen = '#556B2F'
export const clrDarkOrange = '#FF8C00'
export const clrDarkOrchid = '#9932CC'
export const clrDarkSalmon = '#E9967A'
export const clrDarkSeaGreen = '#8FBC8F'
export const clrDarkSlateBlue = '#483D8B'
export const clrDarkSlateGray = '#2F4F4F'
export const clrDarkTurquoise = '#00CED1'
export const clrDarkViolet = '#9400D3'
export const clrDeepPink = '#FF1493'
export const clrDeepSkyBlue = '#00BFFF'
export const clrDimGray = '#696969'
export const clrDodgerBlue = '#1E90FF'
export const clrFireBrick = '#B22222'
export const clrForestGreen = '#228B22'
export const clrGainsboro = '#DCDCDC'
export const clrGold = '#FFD700'
export const clrGoldenrod = '#DAA520'
export const clrGray = '#808080'
export const clrGreen = '#008000'
export const clrGreenYellow = '#ADFF2F'
export const clrHoneydew = '#F0FFF0'
export const clrHotPink = '#FF69B4'
export const clrIndianRed = '#CD5C5C'
export const clrIndigo = '#4B0082'
export const clrIvory = '#FFFFF0'
export const clrKhaki = '#F0E68C'
export const clrLavender = '#E6E6FA'
export const clrLavenderBlush = '#FFF0F5'
export const clrLawnGreen = '#7CFC00'
export const clrLemonChiffon = '#FFFACD'
export const clrLightBlue = '#ADD8E6'
export const clrLightCoral = '#F08080'
export const clrLightCyan = '#E0FFFF'
export const clrLightGoldenrod = '#FAFAD2'
export const clrLightGray = '#D3D3D3'
export const clrLightGreen = '#90EE90'
export const clrLightPink = '#FFB6C1'
export const clrLightSalmon = '#FFA07A'
export const clrLightSeaGreen = '#20B2AA'
export const clrLightSkyBlue = '#87CEFA'
export const clrLightSlateGray = '#778899'
export const clrLightSteelBlue = '#B0C4DE'
export const clrLightYellow = '#FFFFE0'
export const clrLime = '#00FF00'
export const clrLimeGreen = '#32CD32'
export const clrLinen = '#FAF0E6'
export const clrMagenta = '#FF00FF'
export const clrMaroon = '#800000'
export const clrMediumAquamarine = '#66CDAA'
export const clrMediumBlue = '#0000CD'
export const clrMediumOrchid = '#BA55D3'
export const clrMediumPurple = '#9370DB'
export const clrMediumSeaGreen = '#3CB371'
export const clrMediumSlateBlue = '#7B68EE'
export const clrMediumSpringGreen = '#00FA9A'
export const clrMediumTurquoise = '#48D1CC'
export const clrMediumVioletRed = '#C71585'
export const clrMidnightBlue = '#191970'
export const clrMintCream = '#F5FFFA'
export const clrMistyRose = '#FFE4E1'
export const clrMoccasin = '#FFE4B5'
export const clrNavajoWhite = '#FFDEAD'
export const clrNavy = '#000080'
export const clrOldLace = '#FDF5E6'
export const clrOlive = '#808000'
export const clrOliveDrab = '#6B8E23'
export const clrOrange = '#FFA500'
export const clrOrangeRed = '#FF4500'
export const clrOrchid = '#DA70D6'
export const clrPaleGoldenrod = '#EEE8AA'
export const clrPaleGreen = '#98FB98'
export const clrPaleTurquoise = '#AFEEEE'
export const clrPaleVioletRed = '#DB7093'
export const clrPapayaWhip = '#FFEFD5'
export const clrPeachPuff = '#FFDAB9'
export const clrPeru = '#CD853F'
export const clrPink = '#FFC0CB'
export const clrPlum = '#DDA0DD'
export const clrPowderBlue = '#B0E0E6'
export const clrPurple = '#800080'
export const clrRed = '#FF0000'
export const clrRosyBrown = '#BC8F8F'
export const clrRoyalBlue = '#4169E1'
export const clrSaddleBrown = '#8B4513'
export const clrSalmon = '#FA8072'
export const clrSandyBrown = '#F4A460'
export const clrSeaGreen = '#2E8B57'
export const clrSeashell = '#FFF5EE'
export const clrSienna = '#A0522D'
export const clrSilver = '#C0C0C0'
export const clrSkyBlue = '#87CEEB'
export const clrSlateBlue = '#6A5ACD'
export const clrSlateGray = '#708090'
export const clrSnow = '#FFFAFA'
export const clrSpringGreen = '#00FF7F'
export const clrSteelBlue = '#4682B4'
export const clrTan = '#D2B48C'
export const clrTeal = '#008080'
export const clrThistle = '#D8BFD8'
export const clrTomato = '#FF6347'
export const clrTurquoise = '#40E0D0'
export const clrViolet = '#EE82EE'
export const clrWheat = '#F5DEB3'
export const clrWhite = '#FFFFFF'
export const clrWhiteSmoke = '#F5F5F5'
export const clrYellow = '#FFFF00'
export const clrYellowGreen = '#9ACD32'

export const MODE_ASCEND = 1
export const MODE_DESCEND = -1
