import { BasicPaintToolJSON, PaintToolsJSON } from '@fto/lib/ProjectAdapter/Types'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { t } from 'i18next'
import { PaintToolManager } from '../../charting/paint_tools/PaintToolManager'
import { DelphiLikeArray, THashedStringList } from '../../delphi_compatibility/DelphiBasicTypes'
import GlobalOptions from '../../globals/GlobalOptions'
import { TChart } from '../chart_classes/BasicChart'
import PaintToolsListMemento from './Memento/PaintToolsListMemento'
import { TPaintToolStatus, TPaintToolType } from './PaintToolsAuxiliaryClasses'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { objectNameToType } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { TObjectType } from '@fto/lib/extension_modules/common/CommonTypes'
import IBasicPaintTool from './IBasicPaintTool'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'

export class TPaintToolsList extends DelphiLikeArray<IBasicPaintTool> {
    private fToolNames: THashedStringList
    private lastTool: IBasicPaintTool | null = null
    public chart: TChart | null = null
    public symbolName = ''

    constructor() {
        super()
        this.fToolNames = new THashedStringList()
    }

    public BringToFrontSelectedTools(): void {
        for (const tool of this) {
            if (tool.Selected) {
                this.MoveToolToFront(tool)
            }
        }
    }

    public SendToBackSelectedTools(): void {
        for (const tool of this) {
            if (tool.Selected) {
                this.SendToolToBack(tool)
            }
        }
    }

    public MoveToolToFront(tool: IBasicPaintTool): void {
        const index = this.indexOf(tool)

        if (index > -1) {
            this.splice(index, 1)
            this.push(tool)
        }
    }

    public SendToolToBack(tool: IBasicPaintTool): void {
        const index = this.indexOf(tool)

        if (index > -1) {
            this.splice(index, 1)
            this.unshift(tool)
        }
    }

    save(): PaintToolsListMemento {
        const clonedTools = this.map((tool) => tool.clone())
        return new PaintToolsListMemento(clonedTools)
    }

    restore(memento: PaintToolsListMemento): void {
        this.clear()
        const toolsToRestore = memento.getTools()

        for (const tool of toolsToRestore) {
            this.add(tool)
        }
    }

    public AreThereSelected(): boolean {
        for (const tool of this) {
            if (tool.Selected) {
                return true
            }
        }
        return false
    }

    public ToolUnderMouse(x: number, y: number, SelectedOnly = false): IBasicPaintTool | null {
        let toolUnderMouse: IBasicPaintTool | null = null

        for (const tool of this) {
            if (tool.status === TPaintToolStatus.ts_Completed) {
                if (SelectedOnly && !tool.Selected) continue
                if (tool.PointUnderMouse(x, y) !== -1 && tool.IsVisible()) {
                    toolUnderMouse = tool
                    break
                }
            }
        }

        if (toolUnderMouse) {
            return toolUnderMouse
        }

        for (const tool of this) {
            if (tool.status === TPaintToolStatus.ts_Completed) {
                if (SelectedOnly && !tool.Selected) continue
                if (tool.MouseAboveTheTool(x, y) && tool.IsVisible() && tool.IsVisibleOnCurrTimeframe()) return tool
            }
        }
        return null
    }

    public PrepareToMoveSelectedTools(): void {
        for (const tool of this) {
            if (tool.Selected) {
                tool.PrepareToMove()
            }
        }
    }

    public MoveSelectedTools(dx: number, dy: number): void {
        for (const tool of this) {
            if (tool.Selected && !tool.fLocked) {
                tool.OnMoveTool(dx, dy)
            }
        }
    }

    public TransferTool(tool: TBasicPaintTool, NewChart: TChart): void {
        const NewList: TPaintToolsList = NewChart.PaintTools
        const index = this.indexOf(tool)
        if (index === -1) return

        // extract tool
        this.extract(tool)

        // delete tool name
        const nameIndex = this.fToolNames.IndexOf(tool.ToolName)
        if (nameIndex !== -1) {
            this.fToolNames.Delete(nameIndex)
        }
        tool.SymbolName = NewChart.ChartWindow.SymbolData.symbolInfo.SymbolName
        // add tool to a new list
        NewList.AddTool(tool, NewList.NameExists(tool.ToolName))
    }

    public DuplicateTool(tool: IBasicPaintTool, tf: number, shift: boolean): IBasicPaintTool | null {
        const ToolClass = PaintToolManager.GetPaintToolClass(tool.ShortName)
        if (!ToolClass) return null

        const NewTool = new ToolClass(tool.chart)

        if (NewTool.ToolType === TPaintToolType.tt_OrderMarker) {
            return null
        }

        this.AddTool(NewTool)
        NewTool.assign(tool as IBasicPaintTool)

        if (shift) {
            this.applyOffset(NewTool)
        }

        NewTool.Selected = true
        NewTool.status = TPaintToolStatus.ts_Completed
        NewTool.RecountScreenCoords()

        return NewTool
    }

    private GetToolsToCopy(exceptThis: IBasicPaintTool | null = null): IBasicPaintTool[] {
        const result = []
        for (const item of this) {
            if (item.Selected && item !== exceptThis && item.ToolType !== TPaintToolType.tt_OrderMarker) {
                result.push(item)
            }
        }
        return result
    }

    public DuplicateSelectedTools(exceptThis: IBasicPaintTool | null = null): void {
        let chartWindow = null

        if (this.chart && this.chart instanceof TMainChart) {
            chartWindow = this.chart.ChartWindow as TChartWindow
            chartWindow.saveStateWithNotify()
        }

        const toolsToDuplicate = this.GetToolsToCopy(exceptThis)

        for (const toolToDuplicate of toolsToDuplicate) {
            toolToDuplicate.Selected = false
            const newTool = this.DuplicateTool(toolToDuplicate, 1, true)

            if (newTool) {
                newTool.PrepareToMove()
            }
        }

        if (chartWindow) {
            chartWindow.preparedToolsForCopyList = this.GetSelectedTools()
            chartWindow.notifyCopyPaintToolsToOtherCharts()
            chartWindow.preparedToolsForCopyList = []
        }
    }

    public GetSelectedTools(): IBasicPaintTool[] {
        const selectedTools: IBasicPaintTool[] = []
        for (const item of this) {
            if (item.Selected && item.ToolType !== TPaintToolType.tt_OrderMarker) {
                selectedTools.push(item)
            }
        }

        return selectedTools
    }

    copyTools() {
        TMainChart.toolListToCopy = []
        for (const tool of this) {
            if (tool.Selected && tool.status === TPaintToolStatus.ts_Completed) {
                const newTool = tool.clone()
                TMainChart.toolListToCopy.push(newTool)
            }
        }

        if (TMainChart.toolListToCopy.length === 1) {
            showSuccessToast({
                title: t('graphicTools.toolCopiedTitle'),
                message: t('graphicTools.selectedToolCopied', {
                    value: t(`graphicTools.toolNames.${TMainChart.toolListToCopy[0].name}`)
                })
            })
        } else if (TMainChart.toolListToCopy.length > 1) {
            showSuccessToast({
                title: t('graphicTools.toolsCopiedTitle'),
                message: t('graphicTools.selectedToolsCopied')
            })
        }
    }

    private __validateToolType(tool: any) {
        if (!this.isInstanceOfIBasicPaintTool(tool)) {
            throw new StrangeError('Created tool is not an instance of IBasicPaintTool')
        }
    }

    pasteTools(targetChart: TChartWindow) {
        if (TMainChart.toolListToCopy.length === 0) {
            return
        }
        targetChart.saveStateWithNotify()

        targetChart.MainChart.PaintTools.DeselectAllTools()

        for (const tool of TMainChart.toolListToCopy) {
            const shouldApplyOffset = tool.chart === targetChart.MainChart
            const ToolClass = PaintToolManager.GetPaintToolClass(tool.ShortName)
            if (ToolClass === null) continue

            const newTool = new ToolClass(targetChart.MainChart)
            this.__validateToolType(newTool)

            GlobalOptions.Options.ToolsLinkNumber++
            // GlobalOptions.Options.Save();

            newTool.LinkNumber = GlobalOptions.Options.ToolsLinkNumber

            newTool.assign(tool, true)
            targetChart.MainChart.PaintTools.AddTool(newTool, false)

            if (shouldApplyOffset) {
                this.applyOffset(newTool)
            }

            newTool.Selected = true
            newTool.status = TPaintToolStatus.ts_Completed

            if (newTool.isSyncedWithOtherCharts) {
                targetChart.notifyCopyToOtherChartsGraphTool(newTool)
            }
        }
    }

    private isInstanceOfIBasicPaintTool(newTool: any) {
        return newTool.IBasicPaintTool_signature === 'IBasicPaintTool'
    }

    private applyOffset(tool: IBasicPaintTool) {
        const offsY = tool.chart.GetPriceFromY(0) - tool.chart.GetPriceFromY(15)
        const offsX = tool.chart.GetBarDateFromX(15) - tool.chart.GetBarDateFromX(0)
        tool.ShiftPoints(offsX, offsY)
    }

    public DeselectAllTools(): void {
        for (const tool of this) {
            if (tool.status === TPaintToolStatus.ts_Completed) {
                tool.Selected = false
            }
        }
    }

    public Paint(): void {
        const copy = this.slice()
        const hasMorePrio = (tool: IBasicPaintTool) => {
            return tool.Selected || tool.Highlighted
        }

        /**
         * Selected tools should be painted on top layer
         */
        copy.sort((a, b) => {
            if (hasMorePrio(a) && !hasMorePrio(b)) return 1
            if (!hasMorePrio(a) && hasMorePrio(b)) return -1
            return 0
        })
        for (const tool of copy) {
            if (
                (tool as TBasicPaintTool).IsVisibleOnSymbol() &&
                tool.IsVisible() &&
                tool.IsVisibleOnCurrTimeframe() &&
                tool.ToolType !== TPaintToolType.tt_OrderMarker
            ) {
                tool.RecountScreenCoords()
                if (tool.status !== TPaintToolStatus.ts_Completed || tool.isVisibleInChartFrame) {
                    tool.PaintBackground()
                    tool.Paint()
                }
            }
        }
    }

    public PaintOrderMarkers(): void {
        for (const tool of this) {
            if (
                tool.IsVisibleOnCurrTimeframe() &&
                tool.IsVisible() &&
                tool.ToolType === TPaintToolType.tt_OrderMarker
            ) {
                tool.RecountScreenCoords()
                if (tool.status !== TPaintToolStatus.ts_Completed || tool.isVisibleInChartFrame) {
                    tool.PaintBackground()
                    tool.Paint()
                }
            }
        }
    }

    public RecountScreenCoords(): void {
        for (const tool of this) {
            tool.RecountScreenCoords()
        }
    }

    public PaintBackground(): void {
        for (const tool of this) {
            tool.PaintBackground()
        }
    }

    public ChangeToolName(tool: IBasicPaintTool, NewName: string): void {
        // change name in names list
        const index = this.fToolNames.IndexOf(tool.ToolName)
        if (index !== -1) {
            this.fToolNames.remove(tool.ToolName)
            this.fToolNames.addObject(NewName, tool)
            tool.ToolName = NewName
        }
    }

    public AddTool(tool: IBasicPaintTool, CreateName = true): void {
        if (CreateName) {
            tool.ToolName = this.GetNewToolName(tool.ShortName)
        }

        this.add(tool)
        this.fToolNames.addObject(tool.ToolName, tool)
    }

    public DeleteTool(toolOrNameOrIndex: IBasicPaintTool | string | number | null): void {
        const staticTools = EducationProcessor.Instance.staticTools

        if (typeof toolOrNameOrIndex === 'string') {
            const tool = this.GetTool(toolOrNameOrIndex)
            if (tool !== null) {
                this.DeleteTool(tool)
            }
        } else if (typeof toolOrNameOrIndex === 'number') {
            const tool = this[toolOrNameOrIndex]
            if (tool) {
                this.DeleteTool(tool)
            }
        } else if (toolOrNameOrIndex) {
            const nameIndex = this.fToolNames.IndexOf(toolOrNameOrIndex.ToolName)
            if (nameIndex !== -1) {
                this.fToolNames.Delete(nameIndex)
            }
            const anyStaticTool = staticTools.includes(toolOrNameOrIndex)
            if (anyStaticTool) {
                showErrorToast({
                    title: t('graphicTools.staticToolErrorTitle')
                })
                return
            }

            const index = this.indexOf(toolOrNameOrIndex)
            if (index !== -1) {
                this.splice(index, 1)
            }
        }
    }

    public GetObjectName(index: number): string {
        if (this.length > 0 && this.length > index) return this[index].ToolName
        return ''
    }

    public GetLastToolName(): string {
        if (this.length > 0 && this[this.length - 1].status === TPaintToolStatus.ts_Completed) {
            return this[this.length - 1].ToolName
        } else {
            return ''
        }
    }

    public DeleteLast(): void {
        if (this.length > 0 && this[this.length - 1].status === TPaintToolStatus.ts_Completed) {
            const tool = this[this.length - 1]
            this.DeleteTool(tool)
            const { updateParams } = GraphToolPanelStore
            if (tool.ToolType !== TPaintToolType.tt_OrderMarker) {
                updateParams((prevSettings) => {
                    const updatedTools = prevSettings.tools.filter(({ ToolName }) => ToolName !== tool.ToolName)
                    return {
                        ...prevSettings,
                        tools: updatedTools,
                        isOpen: updatedTools.length > 0
                    }
                })
            }
        }
    }

    public DeleteAllToolsByType(type: TObjectType): void {
        const objsToDelete = this.filter((value) => objectNameToType(value.ShortName) === type)
        for (const x of objsToDelete) {
            this.DeleteTool(x)
        }
    }

    public DeleteAllTools(): void {
        this.clear()
        this.fToolNames.clear()
    }

    public GetAllLinkNumbers(): string {
        let result = ''
        for (const item of this) {
            if (item.LinkNumber !== 0) {
                result += (result ? ',' : '') + item.LinkNumber
            }
        }
        return result
    }

    public GetAllToolNames(): string {
        let result = ''
        for (let i = this.length - 1; i >= 0; i--) {
            result += (result ? '\n' : '') + this[i].ToolName
        }
        return result
    }

    public UpdateLinkedTools(tools: TPaintToolsList): number {
        let result = 0

        for (const tool of tools) {
            if (!tool.isSyncedWithOtherCharts) {
                continue
            }

            if (tool.LinkNumber !== 0) {
                const linkedTool = this.GetTool(tool.LinkNumber)

                if (linkedTool && linkedTool.constructor === tool.constructor) {
                    linkedTool.assign(tool)
                    linkedTool.RecountScreenCoords()
                    result++
                }
            }
        }

        return result
    }

    public SelectAllTools(): void {
        for (const tool of this) {
            if (tool.status === TPaintToolStatus.ts_Completed && tool.IsVisible() && tool.IsVisibleOnCurrTimeframe()) {
                tool.Selected = true
            }
        }
    }

    public getAllTools(): TBasicPaintTool[] {
        const result: TBasicPaintTool[] = []
        for (const tool of this) {
            result.push(tool as TBasicPaintTool)
        }
        return result
    }

    public getAllSelectedTools(): TBasicPaintTool[] {
        const result: TBasicPaintTool[] = []
        for (const tool of this) {
            if (tool.Selected) {
                result.push(tool as TBasicPaintTool)
            }
        }
        return result
    }

    public selectTool(tool: IBasicPaintTool | null): void {
        if (tool) {
            tool.Selected = true
        }
    }

    public DeleteToolByLinkNumber(LinkNumber: number): void {
        for (let i = this.length - 1; i >= 0; i--) {
            const tool = this[i]
            if (tool.LinkNumber === LinkNumber) {
                this.DeleteTool(tool)
            }
        }
    }

    public HideToolByLinkNumber(LinkNumber: number): void {
        for (let i = this.length - 1; i >= 0; i--) {
            const tool = this[i]
            if (tool.LinkNumber === LinkNumber) {
                tool.Hide()
            }
        }
    }

    public ShowToolByLinkNumber(LinkNumber: number): void {
        for (let i = this.length - 1; i >= 0; i--) {
            const tool = this[i]
            if (tool.LinkNumber === LinkNumber) {
                tool.Show()
            }
        }
    }

    private GetToolByName(toolName: string): IBasicPaintTool | null {
        const tool = this.fToolNames.getObject(toolName) as IBasicPaintTool
        if (tool && tool.status === TPaintToolStatus.ts_Completed) {
            return tool
        }
        return null
    }

    public GetToolByLinkNumber(linkNumber: number): IBasicPaintTool | null {
        for (const item of this) {
            if (item.LinkNumber === linkNumber) {
                return item
            }
        }
        return null
    }

    public GetTool(toolNameOrLinkNumber: string | number): IBasicPaintTool | null {
        if (typeof toolNameOrLinkNumber === 'string') {
            return this.GetToolByName(toolNameOrLinkNumber)
        } else if (typeof toolNameOrLinkNumber === 'number') {
            return this.GetToolByLinkNumber(toolNameOrLinkNumber)
        }
        throw new StrangeError('Invalid argument type for GetTool')
    }

    public NameExists(ToolName: string): boolean {
        return this.GetTool(ToolName) !== null
    }

    private GetNewToolName(ShortName: string): string {
        let result: string
        do {
            result = `${ShortName} ${GlobalOptions.Options.LastToolID}`
            GlobalOptions.Options.LastToolID++
        } while (this.NameExists(result))
        return result
    }

    public toJson(): PaintToolsJSON {
        const paintToolsInJSON: PaintToolsJSON = []

        for (const tool of this) {
            if (tool.status === TPaintToolStatus.ts_Completed && tool.ToolType !== TPaintToolType.tt_OrderMarker) {
                paintToolsInJSON.push(tool.toJson())
            }
        }

        return paintToolsInJSON
    }

    public fromJSON(paintToolsList: PaintToolsJSON, chartWindow: TChartWindow): void {
        this.clear()
        paintToolsList.forEach((tool: BasicPaintToolJSON) => {
            const ToolClass = PaintToolManager.GetPaintToolClass(tool.ShortName)

            if (ToolClass === null) return

            const newTool = new ToolClass(chartWindow.MainChart)
            this.__validateToolType(newTool)
            newTool.fromJSON(tool)
            this.AddTool(newTool, false)
            chartWindow.MainChart.initialPaintTools.AddTool(newTool.clone())
        })
    }

    public isMyTool(tool: IBasicPaintTool): boolean {
        return !!this.GetToolByLinkNumber(tool.LinkNumber)
    }
}
