import { TDateTime } from '../../../../delphi_compatibility/DateUtils'
import GlobalSymbolList from '../../../../globals/GlobalSymbolList'
import { TimeframeUtils } from '../../../common/TimeframeUtils'
import { TSymbolData } from '../../SymbolData'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import BarBuilderUtils from './LastBarBuilderUtils'
import IBarUnderConstruction from './IBarUnderConstruction'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import LastBarServerFetcher from './LastBarServerFetcher'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export default class TLastBarBuilder {
    public static BuildLastBarToDate(
        aDataDescriptor: TDataDescriptor,
        lastProcessedTickTimeForThisSymbol: TDateTime
        // finishedLastBarIndexForThisDate: number
    ): TBarRecord {
        const symbolData = this.GetSymbolData(aDataDescriptor)

        if (!symbolData.TickData.IsSeeked) {
            throw new DataNotDownloadedYetError('BuildLastBarToDate - Tick data is not seeked yet')
        }

        const barStartDate = TimeframeUtils.GetPeriodStart(
            lastProcessedTickTimeForThisSymbol,
            aDataDescriptor.timeframe
        )
        this.__validateBarDate(barStartDate, lastProcessedTickTimeForThisSymbol, aDataDescriptor)

        const barUnderConstruction = BarBuilderUtils.InitializeEmptyBarUnderConstruction(barStartDate)

        try {
            BarBuilderUtils.AddBarsFromLowerTimeframesToBarUnderConstruction(
                symbolData,
                aDataDescriptor,
                lastProcessedTickTimeForThisSymbol,
                barUnderConstruction
            )

            BarBuilderUtils.AddTicksToBarUnderConstruction(
                symbolData,
                lastProcessedTickTimeForThisSymbol,
                barUnderConstruction
            )
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                return LastBarServerFetcher.Instance.GetTheUnfinishedBarFromServer(
                    aDataDescriptor,
                    barStartDate,
                    lastProcessedTickTimeForThisSymbol
                )
            } else {
                throw error
            }
        }

        if (BarBuilderUtils.IsBarBuiltToDate(barUnderConstruction, lastProcessedTickTimeForThisSymbol)) {
            return this.ConvertConstructedBarToBarRecord(barStartDate, barUnderConstruction)
        } else {
            // Unable to build the bar from available data, maybe we don't have enough data, so let's get the bar from server
            //this can throw DataNotDownloadedYetError
            return LastBarServerFetcher.Instance.GetTheUnfinishedBarFromServer(
                aDataDescriptor,
                barStartDate,
                lastProcessedTickTimeForThisSymbol
            )
        }
    }

    private static ConvertConstructedBarToBarRecord(
        barStartDate: number,
        barUnderConstruction: IBarUnderConstruction
    ): TBarRecord {
        return new TBarRecord(
            barStartDate,
            barUnderConstruction.barOpen,
            barUnderConstruction.barHigh,
            barUnderConstruction.barLow,
            barUnderConstruction.barClose,
            barUnderConstruction.barVolume
        )
    }

    private static GetSymbolData(aDataDescriptor: TDataDescriptor): TSymbolData {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol(aDataDescriptor.symbolName, true)
        if (!symbolData) {
            throw new StrangeError(`Symbol ${aDataDescriptor.symbolName} not found in GlobalSymbolList`)
        }
        return symbolData
    }

    private static __validateBarDate(
        barStartDate: TDateTime,
        lastProcessedTickTimeForThisSymbol: TDateTime,
        aDataDescriptor: TDataDescriptor
    ): void {
        const barTheoreticalEndDate = TimeframeUtils.GetPeriodEnd(
            lastProcessedTickTimeForThisSymbol,
            aDataDescriptor.timeframe
        )
        if (barTheoreticalEndDate < lastProcessedTickTimeForThisSymbol) {
            throw new StrangeError(
                `The bar is already closed, so we should not be here ${barStartDate} ${lastProcessedTickTimeForThisSymbol} ${aDataDescriptor.timeframe}`
            )
        }
        if (barStartDate > lastProcessedTickTimeForThisSymbol) {
            throw new StrangeError(
                `The bar is not started yet, so we should not be here ${barStartDate} ${lastProcessedTickTimeForThisSymbol} ${aDataDescriptor.timeframe}`
            )
        }
    }

    // private static GetOpenPrice(timeframeBars: IFMBarsArray, barUnderConstructionStartDate: TDateTime): number {
    //     try {
    //         const barWithSameOpenDate = timeframeBars.GetItemByDate(
    //             barUnderConstructionStartDate,
    //             TNoExactMatchBehavior.nemb_ThrowError
    //         )
    //         if (barWithSameOpenDate) {
    //             return barWithSameOpenDate.open
    //         }
    //     } catch (e) {
    //         if (e instanceof NoExactMatchError) {
    //             return CommonConstants.EMPTY_DATA_VALUE
    //         } else {
    //             throw e
    //         }
    //     }
    //     return CommonConstants.EMPTY_DATA_VALUE
    // }
}
