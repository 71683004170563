import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import { TDataFormat } from '../../DataEnums'
import { TChunkStatus } from '../../chunks/ChunkEnums'
import { DownloadController } from '../../data_downloading/DownloadController'
import { ESmallerChunksStatus } from './ChunkBuildingEnums'
import ChunkBuilderUtils from './ChunkBuilderUtils'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TBarChunk } from '../../chunks/BarChunk'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { ISmallerChunksWithStatus } from './ChunkBuildingInterfaces'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import DownloadUtils from '../../data_downloading/DownloadUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

export default class ChunkBuilderTask {
    private _chunkToBuild: TBarChunk
    private _downloadAllowed: boolean
    private _smallerChunks: ISmallerChunksWithStatus

    constructor(chunk: TBarChunk, downloadAllowed = true) {
        this._chunkToBuild = chunk
        this._downloadAllowed = downloadAllowed
        this._smallerChunks = { chunks: [], status: ESmallerChunksStatus.scs_ChunkListNotInitializedYet }
    }

    private boundExecuteBuildingTask = this.ExecuteBuildingTask.bind(this)
    public ExecuteBuildingTask(): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Executing building task',
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        if (this._chunkToBuild.DataDescriptor.timeframe === 1) {
            throw new StrangeError('Cannot build M1 chunks, they are always loaded')
        }

        try {
            this.GetSmallerChunksAndBuildFromThem()
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            DebugUtils.warnTopic(
                ELoggingTopics.lt_ChunkLoading,
                'Trying to download data directly for bar chunk',
                this._chunkToBuild.FirstDate,
                this._chunkToBuild.DName
            )
            if (!this.DownloadDataDirectlyIfPossible()) {
                DebugUtils.warnTopic(
                    ELoggingTopics.lt_ChunkLoading,
                    'Cannot download data directly, emitting error event',
                    this._chunkToBuild.FirstDate,
                    this._chunkToBuild.DName
                )
                this._chunkToBuild.Events.EmitEvent(TDataArrayEvents.de_LoadingErrorHappened, this._chunkToBuild, error)
                throw error
            }
        }
    }

    private MakeSureSmallerTFMapIsLoading() {
        const standardRelevantTFValue = ChunkBuilderUtils.GetBiggestRelevantDownloadableTFValue(this._chunkToBuild)
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            `Making sure smaller TF map is loading for ${standardRelevantTFValue}`
        )
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
            this._chunkToBuild.DataDescriptor.symbolName
        )
        const barArray = symbol.GetOrCreateBarArray(standardRelevantTFValue)
        barArray.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundExecuteBuildingTask)
        barArray.InitMapIfNecessary()
    }

    private MakeSureSmallerChunksAreDownloading() {
        for (const smallerChunk of this._smallerChunks.chunks) {
            if (smallerChunk.Status !== TChunkStatus.cs_Loaded) {
                if (
                    smallerChunk.Status === TChunkStatus.cs_InvalidDataOnServer &&
                    !this.DownloadDataDirectlyIfPossible()
                ) {
                    this._chunkToBuild.Events.EmitEvent(
                        TDataArrayEvents.de_LoadingErrorHappened,
                        this._chunkToBuild,
                        `Smaller chunk's data is invalid, cannot build bigger chunk ${this._chunkToBuild.FirstDate} ${this._chunkToBuild.DName}`
                    )
                } else {
                    //someone may have already started building or downloading this chunk, so it's status can be cs_Building or cs_InQueue,
                    //but still it may not have the event attached to it if the building or downloading was initiated from some other place,
                    //therefore,  we need to make sure it is subscribed to the event regardless of its status
                    smallerChunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundExecuteBuildingTask)
                    smallerChunk.Events.on(TDataArrayEvents.de_LoadingErrorHappened, this.boundHandleSmallerChunkError)

                    if (!smallerChunk.IsLoadedOrLoading) {
                        //it seems that nobody has started building or downloading this chunk, so let's download it
                        DownloadController.Instance.loadHistoryIfNecessary(smallerChunk)
                    }
                }
            }
        }
    }

    private boundHandleSmallerChunkError = this.handleSmallerChunkError.bind(this)
    private handleSmallerChunkError(smallerChunk: TBarChunk, error?: Error | string): void {
        let errorMessage = 'Unknown error'
        if (error) {
            if (error instanceof Error) {
                errorMessage = error.message
            } else {
                errorMessage = error.toString()
            }
        }

        DebugUtils.warnTopic(
            ELoggingTopics.lt_ChunkLoading,
            `Smaller chunk error happened ${errorMessage}, lets try to download directly`,
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        if (!this.DownloadDataDirectlyIfPossible()) {
            DebugUtils.warnTopic(
                ELoggingTopics.lt_ChunkLoading,
                'Cannot download data directly, emitting error event',
                this._chunkToBuild.FirstDate,
                this._chunkToBuild.DName
            )
            this._chunkToBuild.Events.EmitEvent(
                TDataArrayEvents.de_LoadingErrorHappened,
                this._chunkToBuild,
                new Error(`Smaller chunk error ${errorMessage}`)
            )
            this.releaseEverything()
        }
    }

    private GetSmallerChunksAndBuildFromThem(): void {
        this.UpdateSmallerChunks()
        this.TryToBuildFromSmallerChunks()
    }

    private UpdateSmallerChunks() {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Updating smaller chunks',
            this._smallerChunks.status,
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        switch (this._smallerChunks.status) {
            case ESmallerChunksStatus.scs_ChunkListNotInitializedYet: {
                this.initSmallerChunks()
                break
            }
            case ESmallerChunksStatus.scs_NoSmallerActiveBarsArray: {
                if (this.DownloadDataDirectlyIfPossible()) {
                    return
                } else {
                    this.ActivateSmallerChunksArray()
                }
                break
            }
            case ESmallerChunksStatus.scs_SmallerChunksMapNotLoaded: {
                //maybe it is loaded already, let's check
                const smallerChunksBarArray = ChunkBuilderUtils.GetSmallerStandardActiveBarsArray(this._chunkToBuild)
                if (!smallerChunksBarArray) {
                    throw new StrangeError(`Smaller chunks array is not defined ${this._chunkToBuild}`)
                }
                if (smallerChunksBarArray.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
                    this.initSmallerChunks()
                }
                break
            }
            case ESmallerChunksStatus.scs_ChunksListReadyButNotLoaded: {
                if (ChunkBuilderUtils.AreAllChunksLoaded(this._smallerChunks.chunks)) {
                    this._smallerChunks.status = ESmallerChunksStatus.scs_ChunksAreLoaded
                }
                break
            }
            case ESmallerChunksStatus.scs_IgnoreSmallerChunksAndDownloadDirectly: {
                //do nothing, the download will happen in the DownloadController instead of building chunk here
                break
            }
            case ESmallerChunksStatus.scs_ChunksAreLoaded: {
                //do nothing, we are all set
                break
            }
            default: {
                throw new StrangeError('Unknown ESmallerChunksStatus - UpdateSmallerChunks')
            }
        }
    }

    private ActivateSmallerChunksArray() {
        DebugUtils.logTopic(ELoggingTopics.lt_ChunkLoading, 'Activating smaller chunks array')
        const standardRelevantTFValue = ChunkBuilderUtils.GetBiggestRelevantDownloadableTFValue(this._chunkToBuild)
        DebugUtils.logTopic(ELoggingTopics.lt_ChunkLoading, 'Standard relevant TF value', standardRelevantTFValue)
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
            this._chunkToBuild.DataDescriptor.symbolName
        )
        const activatedSmallerBarsArray = symbol.EnsureTimeframeIsActive(standardRelevantTFValue)
        activatedSmallerBarsArray.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundExecuteBuildingTask)
        this._smallerChunks.status = ESmallerChunksStatus.scs_SmallerChunksMapNotLoaded
    }

    private initSmallerChunks() {
        this._smallerChunks = ChunkBuilderUtils.GetRelevantSmallerChunks(this._chunkToBuild)
        this.lockSmallerChunks()
    }

    private TryToBuildFromSmallerChunks(): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'TryToBuildFromSmallerChunks',
            this._smallerChunks.status,
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        switch (this._smallerChunks.status) {
            case ESmallerChunksStatus.scs_ChunkListNotInitializedYet: {
                throw new StrangeError(
                    'Smaller chunks are not prepared, this only makes sense for TF=1 and for empty chunks, but these cases should have been handled before this point'
                )
            }
            case ESmallerChunksStatus.scs_NoSmallerActiveBarsArray: {
                if (!this.DownloadDataDirectlyIfPossible()) {
                    this.ActivateSmallerChunksArray()
                }
                break
            }
            case ESmallerChunksStatus.scs_SmallerChunksMapNotLoaded: {
                if (!this.DownloadDataDirectlyIfPossible()) {
                    this.MakeSureSmallerTFMapIsLoading()
                }
                break
            }
            case ESmallerChunksStatus.scs_ChunksListReadyButNotLoaded: {
                if (!this.DownloadDataDirectlyIfPossible()) {
                    this.MakeSureSmallerChunksAreDownloading()
                }
                break
            }
            case ESmallerChunksStatus.scs_ChunksAreLoaded: {
                this.BuildFromLoadedSmallerChunks()
                break
            }
            case ESmallerChunksStatus.scs_IgnoreSmallerChunksAndDownloadDirectly: {
                //do nothing, the download will happen in the DownloadController instead of building chunk here
                break
            }
            default: {
                throw new StrangeError('Unknown ESmallerChunksStatus - TryToBuildFromSmallerChunks')
            }
        }
    }

    public get ChunkToBuild(): TBarChunk {
        return this._chunkToBuild
    }

    private CanBeDownloadedDirectly(): boolean {
        return DownloadUtils.CanBeDownloadedDirectly(this._chunkToBuild)
    }

    private DownloadDataDirectlyIfPossible(): boolean {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'DownloadDataDirectlyIfPossible',
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        if (this._downloadAllowed && this.CanBeDownloadedDirectly()) {
            this.DownloadDataDirectly()
            return true
        }
        return false
    }

    private DownloadDataDirectly(): void {
        if (!this._downloadAllowed) {
            throw new StrangeError('DownloadDataDirectly - Download is not allowed for this task')
        }
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Downloading data directly for chunk',
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )

        this._smallerChunks.status = ESmallerChunksStatus.scs_IgnoreSmallerChunksAndDownloadDirectly

        //we need this status to show the downloader that it needs to download the data directly
        if (this._chunkToBuild.Status === TChunkStatus.cs_InQueue) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_ChunkLoading,
                'The chunk is already in the build queue, no need to add it again',
                this._chunkToBuild.FirstDate,
                this._chunkToBuild.DName
            )
        } else {
            //we probably have TChunkStatus.cs_Building status, so we need to change it to cs_Empty so the downloader can take it
            this._chunkToBuild.Status = TChunkStatus.cs_Empty
            DownloadController.Instance.loadHistoryIfNecessary(this._chunkToBuild)
        }
    }

    private BuildFromLoadedSmallerChunks(): void {
        if (this.AreSmallerBarsGoodForBuilding()) {
            const resultBars = ChunkBuilderUtils.BuildBarsFromSmallerChunks(
                this._chunkToBuild,
                this._smallerChunks.chunks
            )

            this.ImportBuiltBarsIntoChunk(resultBars)
        }
    }

    private AreSmallerBarsGoodForBuilding(): boolean {
        if (!ChunkBuilderUtils.IsBiggerChunkCoveredBySmallerChunks(this._chunkToBuild, this._smallerChunks.chunks)) {
            //we do not have the necessary coverage by the smaller chunks
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `This chunk can be built from smaller chunks, but it is not covered by them, chunk details: ${this._chunkToBuild.DataDescriptor}`
            )
            if (this.DownloadDataDirectlyIfPossible()) {
                return false
            } else {
                throw new StrangeError(
                    `Cannot build chunk (TF: ${this._chunkToBuild.DataDescriptor.timeframe} date: ${this._chunkToBuild.FirstDate}) from smaller chunks (because there is not enough coverage) and it cannot be downloaded directly${this._chunkToBuild.FirstDate}`
                )
            }
        }
        if (!ChunkBuilderUtils.AreAllChunksLoaded(this._smallerChunks.chunks)) {
            throw new StrangeError(
                'BuildFromLoadedSmallerChunks - All smaller chunks should be loaded at this point but they are not'
            )
        }
        return true
    }

    private ImportBuiltBarsIntoChunk(resultBars: TBarRecord[]): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Importing built bars into chunk',
            this._chunkToBuild.FirstDate,
            this._chunkToBuild.DName
        )
        const chunkStatus = this._chunkToBuild.ImportChunkData(resultBars, TDataFormat.df_ActualRecords)
        if (chunkStatus !== TChunkStatus.cs_Loaded) {
            throw new StrangeError(
                `Cannot import the built chunk. This should not happen. The chunk status is ${chunkStatus}, chunk: ${this._chunkToBuild.FirstDate} ${this._chunkToBuild.DName}`
            )
        }
    }

    private releaseAllEventsForSmallerChunks(): void {
        for (const smallerChunk of this._smallerChunks.chunks) {
            smallerChunk.Events.off(TDataArrayEvents.de_ChunkLoaded, this.boundExecuteBuildingTask)
        }
        const smallerChunksArray = ChunkBuilderUtils.GetSmallerStandardActiveBarsArray(this._chunkToBuild)
        if (smallerChunksArray) {
            smallerChunksArray.Events.off(TDataArrayEvents.de_MapDownloaded, this.boundExecuteBuildingTask)
        }
    }

    private unlockSmallerChunks() {
        for (const smallerChunk of this._smallerChunks.chunks) {
            smallerChunk.Unlock()
        }
    }

    private lockSmallerChunks() {
        for (const smallerChunk of this._smallerChunks.chunks) {
            smallerChunk.Lock()
        }
    }

    public releaseEverything(): void {
        this.releaseAllEventsForSmallerChunks()
        this.unlockSmallerChunks()
    }
}
