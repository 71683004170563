import { SymbolItemType } from '@root/types/symbols.types'
import { createSlice } from '@reduxjs/toolkit'
import {
    anySymbolActionFulfilled,
    anySymbolActionPending,
    anySymbolActionRejected,
    GET_SYMBOLS_ACTION
} from '@root/store/symbols/symbols.actions'

export type SymbolState = {
    symbols: SymbolItemType[]
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
}
const initialState: SymbolState = {
    symbols: [],
    loading: 'idle',
    error: null
}

export const symbolSlice = createSlice({
    name: 'symbol',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GET_SYMBOLS_ACTION.fulfilled, (state, action) => {
                state.loading = 'succeeded'
                state.symbols = action.payload
                state.error = null
                state.loading = 'idle'
            })
            .addMatcher(anySymbolActionRejected, (state, action) => {
                const error = action.error
                state.loading = 'failed'
                state.error = error.message || null
            })
            .addMatcher(anySymbolActionPending, (state) => {
                state.loading = 'pending'
            })
            .addMatcher(anySymbolActionFulfilled, (state) => {
                state.loading = 'succeeded'
            })
    }
})

export default symbolSlice.reducer
