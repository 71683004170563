import { FC, useEffect } from 'react'

import { Icon } from '@fto/icons'
import { Flex, Modal, Typography, removeModal } from '@fto/ui'
import { requestCode } from '@root/utils/api'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useCallback, useMemo, useState } from 'react'
import { ChangeEmailContent } from './components/ChangeEmailContent'
import { EnterCodeContent } from './components/EnterCodeContent'
import { EnterEmailContent } from './components/EnterEmailContent'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { useTranslation } from 'react-i18next'

type Stage = 'changeEmail' | 'enterCode' | 'enterEmail'

type Props = {
    code: string
}
export const ChangeEmailModal: FC<Props> = ({ code }) => {
    const { t } = useTranslation()

    const [stage, setStage] = useState<Stage>(code ? 'enterEmail' : 'changeEmail')
    const user = useAppSelector($getUser)
    if (!user) return

    useEffect(() => {
        setStage(code ? 'enterEmail' : 'changeEmail')
    }, [code])

    const removeEmailModal = useCallback(() => {
        removeModal(MODAL_NAMES.userProfile.changeEmail)
        setStage('changeEmail')
    }, [])

    const sendCode = useCallback(() => {
        requestCode({ type: 'changeemail', email: user.email })
        setStage('enterCode')
    }, [user.email])

    const headerText = useMemo(
        () => ({
            changeEmail: t('profile.changeEmail'),
            enterCode: t('profile.modals.enterCode'),
            enterEmail: t('profile.modals.newEmail')
        }),
        []
    )

    const content = useMemo(
        () => ({
            changeEmail: <ChangeEmailContent onClose={removeEmailModal} onSubmit={sendCode} />,
            enterCode: (
                <EnterCodeContent
                    onClose={removeEmailModal}
                    setStage={() => setStage('enterEmail')}
                    email={user.email}
                />
            ),
            enterEmail: <EnterEmailContent code={code} onClose={removeEmailModal} />
        }),
        [removeEmailModal, sendCode, user.email, code]
    )

    return (
        <Modal name={MODAL_NAMES.userProfile.changeEmail} size='sm' onClose={removeEmailModal}>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='envelope' size={72} />
                    <Typography type='h3-bold'>{headerText[stage]}</Typography>
                </Flex>
            </Modal.Header>
            {content[stage]}
        </Modal>
    )
}
