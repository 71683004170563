import { FC, useCallback, useEffect } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'

import OneClickTradingMenuContent from '../components/OneClickTradingMenuContent'

import styles from './index.module.scss'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {}
}

const OneClickTradingContextMenu: FC<Props> = ({ anchorX, anchorY }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.oneClickTrading)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu
            name={CONTEXT_MENU_NAMES.oneClickTrading}
            anchorX={anchorX}
            anchorY={anchorY}
            direction='top-right'
            additionalClassNames={{ container: styles.container }}
        >
            <OneClickTradingMenuContent />
        </ContextMenu>
    )
}

export default OneClickTradingContextMenu
