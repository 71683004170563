import { TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'

export interface WmaInfo {
    value: number
    lastWeightedPrice: number
}
export class GlobalMovingAverageCache {
    private static instance: GlobalMovingAverageCache
    private sumCache = new Map<string, number>()
    private wmaValuesCache = new Map<string, WmaInfo>()
    private constructor() {}

    public static getInstance(): GlobalMovingAverageCache {
        if (!GlobalMovingAverageCache.instance) {
            GlobalMovingAverageCache.instance = new GlobalMovingAverageCache()
        }
        return GlobalMovingAverageCache.instance
    }

    public getPrevSum(
        symbol: string,
        timeframe: number,
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        currentTestingIndex: number
    ): number | null {
        let result = null
        const prevCacheKey = `${index - currentTestingIndex + 1}_${shift}_${period}_${applyTo}_${symbol}_${timeframe}`
        if (this.sumCache.has(prevCacheKey)) {
            const prevSum = this.sumCache.get(prevCacheKey)
            if (prevSum) {
                result = prevSum
            }
        }
        return result
    }

    public saveCurrentSum(
        symbol: string,
        timeframe: number,
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        currentTestingIndex: number,
        currentSum: number
    ) {
        const currCacheKey = `${index - currentTestingIndex}_${shift}_${period}_${applyTo}_${symbol}_${timeframe}`
        this.sumCache.set(currCacheKey, currentSum)
    }

    getCurrWmaValue(
        symbol: string,
        timeframe: number,
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        currentTestingIndex: number
    ): WmaInfo | null {
        let result = null
        const currCacheKey = `${index - currentTestingIndex}_${shift}_${period}_${applyTo}_${symbol}_${timeframe}`
        if (this.wmaValuesCache.has(currCacheKey)) {
            const currValue = this.wmaValuesCache.get(currCacheKey)
            if (currValue) {
                result = currValue
            }
        }

        return result
    }

    saveWmaSum(
        symbol: string,
        timeframe: number,
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        currentTestingIndex: number,
        wmaInfo: WmaInfo
    ) {
        const currCacheKey = `${index - currentTestingIndex}_${shift}_${period}_${applyTo}_${symbol}_${timeframe}`
        this.wmaValuesCache.set(currCacheKey, wmaInfo)
    }
}
