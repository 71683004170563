import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Button, addModal } from '@fto/ui'

import { MODAL_NAMES } from '@root/constants/modalNames'

import { CustomTimeFrame } from '@fto/lib/store/timeframe/types'
import TimeframeStore from '@fto/lib/store/timeframe'

import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'

import Option from './components/Option'

import styles from './index.module.scss'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'

type Props = {
    customTimeframes: CustomTimeFrame[]
    preselectedTimeFrames: number[]
    selectedTimeFrame: number | undefined
    changeTimeFrame: (timeframe: number) => void
    onClose: () => void
}
const Selector: FC<Props> = ({
    onClose,
    customTimeframes,
    selectedTimeFrame,
    preselectedTimeFrames,
    changeTimeFrame
}) => {
    const { t } = useTranslation()
    const { updatePreselectedTimeFrames } = TimeframeStore

    const onDefaultTimeFramesPreselect = useCallback((val: number) => {
        updatePreselectedTimeFrames((prevSettings) => {
            const timeFrameToSearch = prevSettings.find((tf) => tf === val)

            return timeFrameToSearch ? prevSettings.filter((tf) => tf !== val) : [...prevSettings, val]
        })
    }, [])

    const removePreselectedTimeFrame = useCallback((val: number) => {
        updatePreselectedTimeFrames((prevSettings) => {
            return prevSettings.filter((tf) => tf !== val)
        })
    }, [])

    const addNewTimeFrame = useCallback(() => {
        onClose()
        addModal(MODAL_NAMES.chart.customTimeFrame, { value: '1', isChange: false })
    }, [onClose])

    return (
        <Flex direction='column' gap={4} className={styles.Selector}>
            <Flex direction='column' gap={4}>
                <Typography type='tiny-regular' color='gray-600' className={styles.Title}>
                    {t('customTimeframes.select.timeframes')}
                </Typography>
                <Flex direction='column' gap={4}>
                    {TIME_FRAMES.map((timeFrame) => {
                        return (
                            <Option
                                value={timeFrame}
                                name={TimeframeUtils.GetTimeframeNameLabel(timeFrame as number).label}
                                key={timeFrame}
                                isSelected={timeFrame === selectedTimeFrame}
                                isTimeFramePreselected={preselectedTimeFrames.includes(timeFrame)}
                                onTimeframeChange={changeTimeFrame}
                                setPreselectedTimeFrame={onDefaultTimeFramesPreselect}
                            />
                        )
                    })}
                </Flex>
            </Flex>

            {customTimeframes.length > 0 && (
                <Flex direction='column' gap={4}>
                    <Typography type='tiny-regular' color='gray-600' className={styles.Title}>
                        {t('customTimeframes.select.customTimeframes')}
                    </Typography>
                    <Flex direction='column' gap={4} className={styles.CustomTimeFramesBlock}>
                        {customTimeframes.map(({ value, longName, id }) => {
                            return (
                                <Option
                                    isCustom
                                    id={id}
                                    name={longName}
                                    key={id}
                                    isSelected={value === selectedTimeFrame}
                                    isTimeFramePreselected={preselectedTimeFrames.includes(value)}
                                    onTimeframeChange={changeTimeFrame}
                                    value={value}
                                    setPreselectedTimeFrame={onDefaultTimeFramesPreselect}
                                    removePreselectedTimeFrame={removePreselectedTimeFrame}
                                />
                            )
                        })}
                    </Flex>
                </Flex>
            )}

            <Flex direction='column' gap={4}>
                <Typography type='tiny-regular' color='gray-600' className={styles.AddTimeframeLabel}>
                    {t('customTimeframes.select.addTimeframeLabel')}
                </Typography>
                <Button
                    onClick={addNewTimeFrame}
                    size='compact'
                    type='secondary'
                    label={t('customTimeframes.select.addTimeframe')}
                    block
                />
            </Flex>
        </Flex>
    )
}

export default Selector
