import React, { useState, useCallback } from 'react'
import { calculatePosition } from '../utils/calculatePosition'

interface Position {
    x: number
    y: number
}

const useDraggable = (ref: any) => {
    const [isDragging, setIsDragging] = useState(false)
    const [position, setPosition] = useState<Position>({ x: 0, y: 0 })
    const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 })

    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setIsDragging(true)
            setStartPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y
            })
        },
        [position]
    )

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging) return

            const defaultLeftCornerXPosition = window.innerWidth / 2 - ref?.current?.clientWidth / 2
            const defaultLeftCornerYPosition = window.innerHeight / 2 - ref.current.clientHeight / 2

            const newPosition = calculatePosition(
                e.clientX,
                e.clientY,
                startPosition,
                defaultLeftCornerXPosition,
                defaultLeftCornerYPosition
            )

            setPosition(newPosition)
        },
        [isDragging, startPosition]
    )

    const onMouseUp = useCallback(() => {
        setIsDragging(false)
    }, [])

    return { position, onMouseDown, onMouseMove, onMouseUp, isDragging }
}

export default useDraggable
