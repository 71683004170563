import { FC, useCallback, Dispatch, SetStateAction } from 'react'
import { t } from 'i18next'
import { Flex, Typography, Checkbox, SimpleDropdown } from '@fto/ui'

import { ParamsType, priorityType } from '@fto/lib/store/news/types'
import { NEWS_TO_SHOW_INTERVAL } from '@fto/chart_components/ProjectInterface/components/News/components/Header/components/Filter/components/Header/constants/filterOptions'

import styles from './index.module.scss'

type FiltersType = {
    priority: ParamsType['filters']['priority']
    interval: ParamsType['filters']['interval']
}

type Props = {
    filters: FiltersType
    setFilters: Dispatch<SetStateAction<ParamsType['filters']>>
}

const Header: FC<Props> = ({ filters, setFilters }) => {
    const updatePriority = useCallback((value: (typeof priorityType)[number]) => {
        setFilters((prevState) => {
            const isIncluded = prevState.priority.includes(value)
            return {
                ...prevState,
                priority: isIncluded
                    ? prevState.priority.filter((item) => item !== value)
                    : [...prevState.priority, value]
            }
        })
    }, [])
    const updateNewsRange = useCallback((interval: ParamsType['filters']['interval']) => {
        setFilters((prevState) => ({
            ...prevState,
            interval
        }))
    }, [])

    return (
        <Flex gap={8} direction='column' className={styles.Header}>
            <Flex direction='column' gap={8}>
                <Flex direction='column' gap={4}>
                    <Typography type='interface-regular' color='gray-1000'>
                        {t('news.header.filter.show')}
                    </Typography>
                    <SimpleDropdown
                        classNames={{
                            header: styles.ShowFilterHeader,
                            root: styles.ShowFilterRoot,
                            content: styles.ShowFilterContent
                        }}
                        options={NEWS_TO_SHOW_INTERVAL}
                        onChange={updateNewsRange}
                        value={filters.interval}
                    />
                </Flex>

                <Typography type='interface-regular' color='gray-1000'>
                    {t('news.header.filter.priority')}
                </Typography>
                <div className={styles.Container}>
                    <Flex
                        className={styles.CheckboxWrapper}
                        gap={8}
                        alignItems='center'
                        onClick={() => updatePriority('low')}
                    >
                        <Checkbox checked={filters.priority.includes('low')} />
                        <Typography type='interface-regular'>{t('news.header.filter.low')}</Typography>
                    </Flex>
                    <Flex
                        className={styles.CheckboxWrapper}
                        gap={8}
                        alignItems='center'
                        onClick={() => updatePriority('medium')}
                    >
                        <Checkbox checked={filters.priority.includes('medium')} />
                        <Typography type='interface-regular'>{t('news.header.filter.medium')}</Typography>
                    </Flex>
                    <Flex
                        className={styles.CheckboxWrapper}
                        gap={8}
                        alignItems='center'
                        onClick={() => updatePriority('high')}
                    >
                        <Checkbox checked={filters.priority.includes('high')} />
                        <Typography type='interface-regular'>{t('news.header.filter.high')}</Typography>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    )
}

export default Header
