import { FC } from 'react'
import { Button, TriggerOverlay } from '@fto/ui'

import { Icon } from '@fto/icons'

import OptionsRenderer from './components/OptionsRenderer'
import styles from './components/OptionsRenderer/index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {}

const SymbolsContextMenu: FC<Props> = () => {
    const { t } = useTranslation()

    return (
        <TriggerOverlay className={styles.overlay} outStyledList optionsRenderer={<OptionsRenderer />}>
            <Button
                label={t('dashboard.toolbar.lastView.label')}
                type='tetriatry-gray'
                after={<Icon name='chevron-down' size={15} />}
            />
        </TriggerOverlay>
    )
}

export default SymbolsContextMenu
