export const ALL_COUNTRIES = [
    { name: 'Argentina', currency: 'ARS', iconName: 'country-argentina', localeKey: 'countries.argentina' },
    { name: 'Australia', currency: 'AUD', iconName: 'country-australia', localeKey: 'countries.australia' },
    { name: 'Belarus', currency: 'BYN', iconName: 'country-belarus', localeKey: 'countries.belarus' },
    { name: 'Brazil', currency: 'BRL', iconName: 'country-brazil', localeKey: 'countries.brazil' },
    { name: 'Canada', currency: 'CAD', iconName: 'country-canada', localeKey: 'countries.canada' },
    { name: 'China', currency: 'CNY', iconName: 'country-china', localeKey: 'countries.china' },
    { name: 'Czech', currency: 'CZK', iconName: 'country-czech', localeKey: 'countries.czech' },
    { name: 'Denmark', currency: 'DKK', iconName: 'country-denmark', localeKey: 'countries.denmark' },
    { name: 'Egypt', currency: 'EGP', iconName: 'country-egypt', localeKey: 'countries.egypt' },
    { name: 'Austria', currency: 'EUR', iconName: 'country-austria', localeKey: 'countries.austria' },
    { name: 'Belgium', currency: 'EUR', iconName: 'country-belgium', localeKey: 'countries.belgium' },
    { name: 'Cyprus', currency: 'EUR', iconName: 'country-cyprus', localeKey: 'countries.cyprus' },
    { name: 'Estonia', currency: 'EUR', iconName: 'country-estonia', localeKey: 'countries.estonia' },
    { name: 'Finland', currency: 'EUR', iconName: 'country-finland', localeKey: 'countries.finland' },
    { name: 'France', currency: 'EUR', iconName: 'country-france', localeKey: 'countries.france' },
    { name: 'Germany', currency: 'EUR', iconName: 'country-germany', localeKey: 'countries.germany' },
    { name: 'Greece', currency: 'EUR', iconName: 'country-greece', localeKey: 'countries.greece' },
    { name: 'Ireland', currency: 'EUR', iconName: 'country-ireland', localeKey: 'countries.ireland' },
    { name: 'Italy', currency: 'EUR', iconName: 'country-italy', localeKey: 'countries.italy' },
    { name: 'Latvia', currency: 'EUR', iconName: 'country-latvia', localeKey: 'countries.latvia' },
    { name: 'Lithuania', currency: 'EUR', iconName: 'country-lithuania', localeKey: 'countries.lithuania' },
    { name: 'Netherlands', currency: 'EUR', iconName: 'country-netherlands', localeKey: 'countries.netherlands' },
    { name: 'Portugal', currency: 'EUR', iconName: 'country-portugal', localeKey: 'countries.portugal' },
    { name: 'Spain', currency: 'EUR', iconName: 'country-spain', localeKey: 'countries.spain' },
    { name: 'Hong Kong', currency: 'HKD', iconName: 'country-hong-kong', localeKey: 'countries.hongKong' },
    { name: 'Hungary', currency: 'HUF', iconName: 'country-hungary', localeKey: 'countries.hungary' },
    { name: 'India', currency: 'INR', iconName: 'country-india', localeKey: 'countries.india' },
    { name: 'Israel', currency: 'ILS', iconName: 'country-israel', localeKey: 'countries.israel' },
    { name: 'Japan', currency: 'JPY', iconName: 'country-japan', localeKey: 'countries.japan' },
    { name: 'Mexico', currency: 'MXN', iconName: 'country-mexico', localeKey: 'countries.mexico' },
    { name: 'New Zealand', currency: 'NZD', iconName: 'country-new-zealand', localeKey: 'countries.newZealand' },
    { name: 'Norway', currency: 'NOK', iconName: 'country-norway', localeKey: 'countries.norway' },
    { name: 'Philippines', currency: 'PHP', iconName: 'country-philippines', localeKey: 'countries.philippines' },
    { name: 'Poland', currency: 'PLN', iconName: 'country-poland', localeKey: 'countries.poland' },
    { name: 'Romania', currency: 'RON', iconName: 'country-romania', localeKey: 'countries.romania' },
    { name: 'Russia', currency: 'RUB', iconName: 'country-russia', localeKey: 'countries.russia' },
    { name: 'Singapore', currency: 'SGD', iconName: 'country-singapore', localeKey: 'countries.singapore' },
    { name: 'South Africa', currency: 'ZAR', iconName: 'country-south-africa', localeKey: 'countries.southAfrica' },
    { name: 'South Korea', currency: 'KRW', iconName: 'country-south-korea', localeKey: 'countries.southKorea' },
    { name: 'Sweden', currency: 'SEK', iconName: 'country-sweden', localeKey: 'countries.sweden' },
    { name: 'Switzerland', currency: 'CHF', iconName: 'country-switzerland', localeKey: 'countries.switzerland' },
    { name: 'Taiwan', currency: 'TWD', iconName: 'country-taiwan', localeKey: 'countries.taiwan' },
    { name: 'Thailand', currency: 'THB', iconName: 'country-thailand', localeKey: 'countries.thailand' },
    {
        name: 'Great Britain',
        currency: 'GBP',
        iconName: 'country-united-kingdom',
        localeKey: 'countries.unitedKingdom'
    },
    { name: 'United States', currency: 'USD', iconName: 'country-usa', localeKey: 'countries.unitedStates' }
]

export const EURO_ZONE_COUNTRIES = [
    { name: 'Austria', currency: 'EUR', iconName: 'country-austria', localeKey: 'countries.austria' },
    { name: 'Belgium', currency: 'EUR', iconName: 'country-belgium', localeKey: 'countries.belgium' },
    { name: 'Cyprus', currency: 'EUR', iconName: 'country-cyprus', localeKey: 'countries.cyprus' },
    { name: 'Estonia', currency: 'EUR', iconName: 'country-estonia', localeKey: 'countries.estonia' },
    { name: 'Finland', currency: 'EUR', iconName: 'country-finland', localeKey: 'countries.finland' },
    { name: 'France', currency: 'EUR', iconName: 'country-france', localeKey: 'countries.france' },
    { name: 'Germany', currency: 'EUR', iconName: 'country-germany', localeKey: 'countries.germany' },
    { name: 'Greece', currency: 'EUR', iconName: 'country-greece', localeKey: 'countries.greece' },
    { name: 'Ireland', currency: 'EUR', iconName: 'country-ireland', localeKey: 'countries.ireland' },
    { name: 'Italy', currency: 'EUR', iconName: 'country-italy', localeKey: 'countries.italy' },
    { name: 'Latvia', currency: 'EUR', iconName: 'country-latvia', localeKey: 'countries.latvia' },
    { name: 'Lithuania', currency: 'EUR', iconName: 'country-lithuania', localeKey: 'countries.lithuania' },
    { name: 'Netherlands', currency: 'EUR', iconName: 'country-netherlands', localeKey: 'countries.netherlands' },
    { name: 'Portugal', currency: 'EUR', iconName: 'country-portugal', localeKey: 'countries.portugal' },
    { name: 'Spain', currency: 'EUR', iconName: 'country-spain', localeKey: 'countries.spain' }
]

export const EURO_ZONE_ENTITY = {
    name: 'European Union',
    currency: 'EUR',
    iconName: 'country-europe',
    localeKey: 'countries.europe'
}

export const ALL_COUNTRIES_WITH_EU = [...ALL_COUNTRIES, EURO_ZONE_ENTITY]
