import { decode } from '@msgpack/msgpack'

import { MessagePackTNewsItem, TNews } from '@fto/lib/News/Types'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import { apiURL } from '@root/constants/endpoints'

export class NewsDownloader {
    private static instance: NewsDownloader
    public isDownloading: boolean = false

    private convertNewsKeyAfterMessagePack = (pack: MessagePackTNewsItem[]): TNews => {
        return pack.map((item) => {
            return {
                Actual: item['a'],
                Country: item['c'],
                Consensus: item['cs'],
                IsPlanned: item['i'],
                Priority: item['p'],
                Previews: item['pw'],
                Symbol: item['s'],
                Time: item['t'],
                Title: item['tt']
            }
        })
    }

    public static get Instance(): NewsDownloader {
        if (!NewsDownloader.instance) {
            NewsDownloader.instance = new NewsDownloader()
        }

        return NewsDownloader.instance
    }

    public async downloadNews(symbols: string[]) {
        // Download news for the given currencies
        // Function to split each currency pair into individual currencies
        this.isDownloading = true
        const splitCurrencies = (pairs: string[]): string[] => {
            return pairs.flatMap((pair) => {
                // Split the pair into two currencies
                const firstCurrency = pair.slice(0, 3)
                const secondCurrency = pair.slice(3)
                return [firstCurrency, secondCurrency]
            })
        }

        // Function to remove duplicates from an array
        const removeDuplicates = (currencies: string[]): string[] => {
            return [...new Set(currencies)]
        }

        const individualCurrencies = splitCurrencies(symbols)
        const uniqueCurrencies = removeDuplicates(individualCurrencies)

        for (const currency of uniqueCurrencies) {
            const path = `${apiURL}data/api/HistoricalNews/news?UseMessagePack=true&Symbol=${currency}&Start=1&End=99999999999999999` // download all news for the given currency

            try {
                const response = await fetch(path)
                const buff = await response.arrayBuffer()

                const convertedByKeys = this.convertNewsKeyAfterMessagePack(decode(buff) as MessagePackTNewsItem[])

                GlobalNewsController.Instance.parseNewsGroup(convertedByKeys)
            } catch (error: any) {
                throw new Error(`News request error: ${error.status}`)
            }
        }
    }
}
