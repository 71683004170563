import IEventEmitter from '@fto/lib/common/IEventEmitter'
import { TDataFormat } from '../../DataEnums'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import { TChunkStatus } from '../ChunkEnums'
import { TDataChunk } from '../DataChunk/DataChunk'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import { TDataRecordWithDate } from '../../DataClasses/TDataRecordWithDate'

export default abstract class TDownloadableChunk<T extends TDataRecordWithDate>
    extends TDataChunk<T>
    implements IEventEmitter
{
    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: number) {
        super(aDataDescriptor, aFirstDate)
    }

    public abstract ImportChunkData(data: any, dataFormat: TDataFormat): TChunkStatus
    protected abstract StartLoadingData(): void
    public abstract GetChunkUrl(): string

    public GetItemByGlobalIndex(globalIndex: number, downloadDataIfChunkIsEmpty = true): T | null {
        if (this.Status === TChunkStatus.cs_Empty) {
            if (downloadDataIfChunkIsEmpty) {
                this.StartLoadingData()
            } else {
                return null
            }
        }

        return super.GetItemByGlobalIndex(globalIndex)
    }

    public EnsureDataIsPresentOrDownloading(): void {
        if (this.Status === TChunkStatus.cs_Empty) {
            this.StartLoadingData()
        }
    }

    protected saferEmitChunkLoadedEvent(): void {
        //somewhere along the way in event loop we can decide to clean the chunk which would be a mistake at this point, so let's lock it
        const oldLockStatus = this.IsLocked
        this.Lock()
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, this)
        if (!oldLockStatus) this.Unlock()
    }
}
