import { TTickRec } from '@fto/lib/ft_types/data/TickRec'
import { StrategyImplementation } from '@fto/lib/extension_modules/startegies/api/UserStrategy'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import IStrategyProcRec from '@fto/lib/extension_modules/startegies/StrategyProcRecInterfaces/IStrategyProcRec'
import { IndicatorReference } from '../common/IndicatorReference'
import GlobalIndicatorsInStrategiesManager from '@fto/lib/globals/Strategies/GlobalIndicatorsInStrategiesManager'
import { StrategyDescriptor } from './StrategyDescriptor'
import { OrdersProcRecImplementation } from './StrategyProcRecImplementations/OrdersProcRecImplementation'
import { BasicRuntimeLib } from '../common/BasicRuntimeLib'

// export type TInitProc = () => void
// export type TDoneProc = () => void
// export type TResetProc = () => void
// export type TGetSingleTickProc = () => void
// export type TShowPropsDialog = () => void
// export type TOnPauseProc = () => void
// export type TOnTestStartedProc = () => void
// export type TOnTestCompletedProc = () => void
// export type TOnSetPauseProc = () => void
// export type TOnResumeProc = () => void
// export type TOnBreakPointProc = () => void

export class Strategy extends BasicRuntimeLib {
    private _userStrategy: StrategyImplementation
    //private fShowPropsDialog: TShowPropsDialog

    private available = true

    protected _fTimeframe = 0

    // OnSetPauseProc: TOnSetPauseProc // pause testing
    // OnResumeProc: TOnResumeProc // resume testing
    // OnBreakPointProc: TOnBreakPointProc // break point

    constructor(userStrategy: StrategyImplementation, filename: string) {
        const descriptor = new StrategyDescriptor(filename, 'Custom strategy', '')
        super(descriptor)
        this._userStrategy = userStrategy
        this.available = true
        this._fTimeframe = 0
        this.onLoad()
    }

    private onLoad(): void {
        const ordersProcRecImplementation = new OrdersProcRecImplementation(this.procRecsEveryImplementationNeeds)

        const realApiImplementation: IStrategyProcRec = {
            ...super.GetImplementation(),
            ...ordersProcRecImplementation.GetImplementation(),

            SetCurrencyAndTimeframe: (symbol: string, timeframe: number) => {
                this.SetCurrencyAndTimeframe(symbol, timeframe)
                // this._fSymbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbol)
                // this._fTimeframe = timeframe
                return true
            },
            StrategyShortName: (shortName: string) => {
                this.descriptor.shortName = shortName
            },
            StrategyDescription: (description: string) => {
                this.descriptor.description = description
            },

            CreateIndicator: (symbol: string, timeframe: number, indicatorFile: string, indicatorParams: string) => {
                return GlobalIndicatorsInStrategiesManager.Instance.createIndicatorForStrategy(
                    this,
                    symbol,
                    timeframe,
                    indicatorFile,
                    indicatorParams
                )
            },
            GetIndicatorValue: (indicatorDescription: IndicatorReference, barIndex: number, bufferIndex: number) => {
                return GlobalIndicatorsInStrategiesManager.Instance.getIndicatorValue(
                    indicatorDescription,
                    barIndex,
                    bufferIndex
                )
            }
        }
        this._userStrategy.OnAttach(realApiImplementation)
    }
    // private GetCurrentSymbolChart(): TChart {
    //     const chart = GlobalChartsController.Instance.getChartBySymbol(this.symbolData.symbolInfo.SymbolName)
    //     if (!chart) throw new StrangeError('Chart not found')
    //     return chart.MainChart
    // }
    // private GetCurrentSymbolCharts(): TChart[] {
    //     const symbol = this.getSymbolData().symbolInfo.SymbolName
    //     const charts = GlobalChartsController.Instance.getAllCharts()
    //     return charts.filter((chart) => chart.SelectedSymbolName === symbol).map((chart) => chart.MainChart)
    // }

    init(): void {
        this._userStrategy.Init()
    }

    done(): void {
        this._userStrategy.Done()
    }

    reset(): void {
        this._userStrategy.Reset()
    }

    onTick(trec: TTickRec): void {
        try {
            //this.fSymbol = trec.symbolData
            this._userStrategy.OnTick()
        } catch (e) {
            throw new StrangeError('Error in onTick strategy function', e)
        }
    }

    onPause(): void {
        //this._userStrategy.OnPause()
    }

    onTestStarted(): void {
        //this._userStrategy.OnTestStarted()
    }

    onTestCompleted(): void {
        //this._userStrategy.OnTestCompleted()
    }

    onSetPause(): void {
        //this._userStrategy.OnSetPause()
    }

    onResume(): void {
        //this._userStrategy.OnResume()
    }

    onBreakPoint(): void {
        //this._userStrategy.OnBreakPoint()
    }

    resetData() {
        this.clearIndicatorRefs()
        //this._fSymbol = null
        //this._fIndicators.Clear()
        this.reset()
    }
    clearIndicatorRefs() {}
    ClearIndicators() {}
    EnableStrategy() {}
    DisableStrategy() {}

    Pause(text: string): void {
        //this._userStrategy.OnPause()
    }
    Resume(): void {
        //this._userStrategy.OnResume()
    }
    Breakpoint(number: number, text: string): void {
        if (GlobalOptions.Options.EnableBreakpoints) {
            this.onBreakPoint()
        }
    }
}
