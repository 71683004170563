export enum TOutputWindow {
    ow_ChartWindow = 0,
    ow_SeparateWindow = 1
}
export enum TIndFolderType {
    ft_Trend,
    ft_Oscillator,
    ft_Volume,
    ft_Volatility,
    ft_Adaptive,
    ft_Custom
}
