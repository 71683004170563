export enum TDataFormat {
    df_Json = 'df_Json',
    df_MSGPPACK = 'df_MSGPPACK',
    df_MockObjects = 'df_MockObjects',
    df_Binary = 'df_Binary',
    df_ActualRecords = 'df_ActualRecords',
    df_MinutesForTicks = 'df_MinutesForTicks'
}

export enum TSymbolCalcType {
    sc_Normal_xxxUSD, //xxxUSD
    sc_InvertedUSD_USDxxx, //USDxxx
    sc_Cross, //XXXYYY no USD
    sc_Index // Index
}
