import { EFetchStatus } from '@fto/lib/common/UtilEnums/EFetchStatus'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import { BASE_URL } from '../../data_downloading/URLConfig'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'

interface IFetchedLastBarTask {
    url: string
    status: EFetchStatus
    bar: TBarRecord | null
    errorMessage: string
}

export enum EventsFetchLastBar {
    flb_LastBarFetched = 'de_LastBarFetched',
    flb_LastBarFetchFailed = 'de_LastBarFetchFailed'
}

export class FetchBarError extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'FetchBarError'
    }
}

export default class LastBarServerFetcher {
    //singleton
    private static _instance: LastBarServerFetcher

    private constructor() {}

    public static get Instance(): LastBarServerFetcher {
        if (!LastBarServerFetcher._instance) {
            LastBarServerFetcher._instance = new LastBarServerFetcher()
        }
        return LastBarServerFetcher._instance
    }

    public Events: TEventsFunctionality = new TEventsFunctionality('LastBarServerFetcher')

    private readonly notFetchedYetMessage = 'Bar is not fetched yet'

    private _tasks: IFetchedLastBarTask[] = []

    public GetTheUnfinishedBarFromServer(
        aDataDescriptor: TDataDescriptor,
        barStartDate: TDateTime,
        currentDateInTesting: TDateTime
    ): TBarRecord {
        // throw new DataNotDownloadedYetError('GetTheBarFromServer - Method not implemented.')
        const url = this.getTaskUrl(aDataDescriptor, barStartDate, currentDateInTesting)
        const existingTask = this._tasks.find((task) => task.url === url)

        if (existingTask) {
            return this.getBarFromTask(existingTask)
        } else {
            this.createNewTask(url)
            throw new DataNotDownloadedYetError(this.notFetchedYetMessage)
        }
    }

    private createNewTask(url: string) {
        const newTask: IFetchedLastBarTask = {
            url,
            status: EFetchStatus.fs_InProgress,
            bar: null,
            errorMessage: ''
        }
        this._tasks.push(newTask)
        this.fetchTask(newTask)
    }

    private fetchTask(newTask: IFetchedLastBarTask) {
        fetch(newTask.url)
            .then((response) => response.json())
            .then((data) => {
                this.setFetchedBarInTask(newTask, data)
                this.Events.EmitEvent(TDataArrayEvents.de_LastBarDownloaded)
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Error fetching last bar', error)
                newTask.status = EFetchStatus.fs_Failed
                newTask.errorMessage = error.message
                this.Events.EmitEvent(TDataArrayEvents.de_LastBarDownloadError)
            })
    }

    private setFetchedBarInTask(task: IFetchedLastBarTask, data: any) {
        // data example:
        // {
        // 	"Timestamp": 0,
        // 	"Open": 0,
        // 	"High": 0,
        // 	"Low": 0,
        // 	"Close": 0,
        // 	"TickVolume": 0,
        // 	"Index": 0
        //   }
        const dateInInternalFormat = DateUtils.fromUnixTimeSeconds(data.Timestamp)
        const fetchedBar = new TBarRecord(
            dateInInternalFormat,
            data.Open,
            data.High,
            data.Low,
            data.Close,
            data.TickVolume
        )
        task.bar = fetchedBar
        task.status = EFetchStatus.fs_Success
    }

    private getTaskUrl(aDataDescriptor: TDataDescriptor, barStartDate: TDateTime, currentDateInTesting: TDateTime) {
        const currentDateInTestingUnixMilliseconds = DateUtils.toUnixTimeMilliseconds(currentDateInTesting)
        return `${BASE_URL}data/api/Metadata/calculate-last-bar/?Symbol=${aDataDescriptor.symbolName}&Broker=Advanced&Timeframe=${aDataDescriptor.timeframe}&UnixTimeMilliseconds=${currentDateInTestingUnixMilliseconds}`
    }

    private getBarFromCompletedTask(task: IFetchedLastBarTask): TBarRecord {
        if (task.bar) {
            return task.bar
        } else {
            throw new StrangeError('Bar fetch task has status completed, yet it does not have bar data')
        }
    }

    private getBarFromTask(task: IFetchedLastBarTask): TBarRecord {
        switch (task.status) {
            case EFetchStatus.fs_Success: {
                return this.getBarFromCompletedTask(task)
            }
            case EFetchStatus.fs_NotStarted: {
                this.fetchTask(task)
                throw new DataNotDownloadedYetError(this.notFetchedYetMessage)
            }
            case EFetchStatus.fs_Failed: {
                throw new FetchBarError(task.errorMessage)
            }
            case EFetchStatus.fs_InProgress: {
                throw new DataNotDownloadedYetError(this.notFetchedYetMessage)
            }
            default: {
                throw new StrangeError('Unknown task status')
            }
        }
    }
}
