import { FC, useCallback, useState } from 'react'
import { OptionType } from '../../types'
import { IconButton } from '@fto/icons'
import { Tooltip } from '@fto/ui'

import styles from '../../index.module.scss'

type Props = {
    value: boolean
    action: () => void
    option: OptionType
    size?: number
    updatedFromOutside?: boolean
}

const ToolToggle: FC<Props> = ({ value, action, option, size, updatedFromOutside }) => {
    const [active, setActive] = useState(value)

    const handleToggle = useCallback(() => {
        setActive((active) => !active)
        action()
    }, [active, value])

    const colorDecider = updatedFromOutside ? value : active
    return (
        <Tooltip content={option.name} placement='right' key={option.toolKey}>
            <IconButton
                className={styles.iconWrapper}
                name={option.icon!}
                onClick={handleToggle}
                color={colorDecider ? 'var(--color-primary-500)' : 'var(--color-gray-1000)'}
                size={size || 24}
            />
        </Tooltip>
    )
}

export default ToolToggle
