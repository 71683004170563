import IEventEmitter from '../../common/IEventEmitter'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import GlobalProjectInfo from '../../globals/GlobalProjectInfo'
import TEventsFunctionality from '../../utils/EventsFunctionality'
import { TSymbolInfo } from '../common/BasicClasses/SymbolInfo'
import { TMyObjectList } from '../common/Common'
import FixedDataValues from './FixedDataValues'
import { TSymbolData } from './SymbolData'
import { TTickRec } from './TickRec'
import { TTicksSource } from './TicksSource'
import IFMBarsArray from './data_arrays/chunked_arrays/IFMBarsArray'
import { TDataArrayEvents } from './data_downloading/DownloadRelatedEnums'
import DataNotDownloadedYetError from './data_errors/DataUnavailableError'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChunkStatus, TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { BASE_URL } from '@fto/lib/ft_types/data/data_downloading/URLConfig'
import GlobalServerSymbolInfo from '@fto/lib/globals/GlobalServerSymbolInfo'
import { Direction } from '@fto/lib/ft_types/data/TickData'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { TBasicChunk } from './chunks/BasicChunk'
import { ISeekable } from './ISeekable'
import LastBarServerFetcher from './BarBuilding/LastBarBuilding/LastBarServerFetcher'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import CommonUtils from '../common/BasicClasses/CommonUtils'
import { OutOfHistoryBoundsError } from './data_errors/OutOfHistoryBoundsError'
import { showWarningToast } from '@root/utils/toasts'

export class TSymbolList extends TTicksSource implements ISeekable, IEventEmitter {
    public get IsSeeked(): boolean {
        if (this.Count === 0) {
            return false
        }
        //it is seeked when all symbols are seeked
        for (let i = 0; i < this.Count; i++) {
            if (!this.GetItem(i).IsSeeked) {
                return false
            }
        }
        return true
    }

    public Events: TEventsFunctionality = new TEventsFunctionality('TSymbolList')

    public RollToDate(DateTime: TDateTime): void {
        //TODO: Should we use RollToDate here instead??? (probably we do with the orders)
        // this.Seek(DateTime)
        throw new NotImplementedError('RollToDate is not implemented in TSymbolList')
    }

    private _symbols: TMyObjectList<TSymbolData>

    constructor() {
        super()
        this._symbols = new TMyObjectList<TSymbolData>()

        this.StartDateTime = 0

        this.ClearQueuedTick()

        LastBarServerFetcher.Instance.Events.on(
            TDataArrayEvents.de_LastBarDownloaded,
            this.boundHandleLastBarDownloadedEvent
        )
    }

    public onTimezoneOrDSTChanged(): void {
        for (let i = 0; i < this.Count; i++) {
            this.GetItem(i).onTimezoneOrDSTChanged()
        }
    }

    public get LastProcessedTickTime(): TDateTime | null {
        //max of all symbols
        let result: TDateTime | null = null
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            if (symbolData.LastProcessedTickTime && (!result || symbolData.LastProcessedTickTime > result)) {
                result = symbolData.LastProcessedTickTime
            }
        }
        return result
    }

    public isReadyToTick(): boolean {
        if (!this.IsSeeked) {
            return false
        }
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)

            if (!symbolData.isCurrentTestingDateInAvailableRange) {
                continue
            }

            if (!symbolData.IsSeeked || !symbolData.fTickData.isReadyToTick() || !symbolData.isVisibleBarDataReady()) {
                return false
            }
        }
        return true
    }

    public SetLoadingPriority(direction: Direction): void {
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            if (symbolData.fTickData) {
                symbolData.fTickData.SetLoadingPriority(direction)
            }
        }
    }

    public AreNextChunksPreloadedForAll(): boolean {
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)

            if (!symbolData.isDateInAvailableRange(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))) {
                continue
            }

            if (
                !symbolData.IsSeeked ||
                !symbolData.fTickData.IsNextChunkPreloaded() ||
                !symbolData.isVisibleBarDataReady()
            ) {
                return false
            }
        }
        return true
    }

    public UpdateChunksInfo(projectLastTickTime: TDateTime): void {
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            symbolData.UpdateChunksInfo(projectLastTickTime)
        }
    }

    protected AddNewSymbol(newSymbolData: TSymbolData, performSeek: boolean): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Significant, 'Adding new symbol', newSymbolData.symbolInfo.SymbolName)
        this._symbols.Add(newSymbolData)
        newSymbolData.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundHandleChunkLoadedEvent)
        newSymbolData.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundHandleMapLoadedEvent)

        if (performSeek) {
            this.Seek(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))
        }
    }

    public GetItem(index: number): TSymbolData {
        return this._symbols[index]
    }

    public get Symbols(): TMyObjectList<TSymbolData> {
        return this._symbols
    }

    public get Count(): number {
        return this._symbols.Count
    }

    public IsAmongAvailableSymbols(symbolName: string, brokerName: string): boolean {
        if (CommonUtils.IsInUnitTest) {
            return true
        }
        const upperSymbolName = symbolName.toUpperCase()
        const availableSymbols = GlobalServerSymbolInfo.Instance.getSymbols()

        return availableSymbols.some(
            (symbol) =>
                symbol.Symbol.toUpperCase() === upperSymbolName &&
                (brokerName === '' || symbol.Broker.toUpperCase() === brokerName.toUpperCase())
        )
    }

    public GetOrCreate_Forex_SymbolByOneCurrency(currency: string): TSymbolData {
        //---------trying to find existing symbol to aviod adding a new symbol to project-----------
        let symbol = this.GetExisting_Forex_SymbolByOneCurrency(currency)

        if (!symbol) {
            //----------we cannot find the existing symbol, let's add a new one to the project----------------
            symbol = this.Create_Forex_Symbol(currency, true)
        }
        //----------we cannot find the existing symbol, let's add a new one to the project----------------
        if (!symbol) {
            throw new StrangeError(`ProcessingCore: Forex symbol with ${currency} not found.`)
        }

        return symbol
    }

    private Create_Forex_Symbol(currency: string, arg1: boolean): TSymbolData | null {
        let symbol = this.GetOrCreateSymbol(`${currency}USD`)

        // for crypto
        if (!symbol) symbol = this.GetOrCreateSymbol(`${currency}USDT`)

        if (!symbol) symbol = this.GetOrCreateSymbol(`${currency}USDC`)

        if (!symbol) {
            // nope, try to find USDxxx symbol
            symbol = this.GetOrCreateSymbol(`USD${currency}`)
        }

        return symbol
    }

    public CheckPossibleCrossPairs(currency: string): { symbolName: string; isUSDBaseCurrency: boolean } | null {
        const possibleSymbols = [
            { symbolName: `${currency}USD`, isUSDBaseCurrency: false },
            { symbolName: `${currency}USDT`, isUSDBaseCurrency: false },
            { symbolName: `${currency}USDC`, isUSDBaseCurrency: false },
            { symbolName: `USD${currency}`, isUSDBaseCurrency: true }
        ]

        for (const { symbolName, isUSDBaseCurrency } of possibleSymbols) {
            if (this.IsAmongAvailableSymbols(symbolName, '')) {
                return { symbolName, isUSDBaseCurrency }
            }
        }

        return null
    }

    public GetExisting_Forex_SymbolByOneCurrency(currency: string): TSymbolData | null {
        let symbol = this.GetExistingSymbol(`${currency}USD`)

        // for crypto
        if (!symbol) symbol = this.GetExistingSymbol(`${currency}USDT`)

        if (!symbol) symbol = this.GetExistingSymbol(`${currency}USDC`)

        if (!symbol) {
            // nope, try to find USDxxx symbol
            symbol = this.GetExistingSymbol(`USD${currency}`)
        }
        return symbol
    }

    public GetOrCreateSymbol_ThrowErrorIfNull(symbolName: string): TSymbolData {
        const symbol = this.GetOrCreateSymbol(symbolName)

        if (!symbol) {
            throw new StrangeError(`Symbol ${symbolName} not found - very unexpected here`)
        }

        return symbol
    }

    public GetExistingSymbol(symbolName: string): TSymbolData | null {
        for (let i = 0; i < this.Count; i++) {
            const currentItem = this.GetItem(i)

            if (currentItem.symbolInfo.SymbolName.toUpperCase() === symbolName.toUpperCase()) {
                return currentItem
            }
        }

        return null
    }

    public GetExistingSymbol_ThrowErrorIfNull(symbolName: string): TSymbolData {
        const symbol = this.GetExistingSymbol(symbolName)

        if (!symbol) {
            throw new StrangeError(
                `Symbol ${symbolName} not found - very unexpected here since this should only be called from a place where we are sure that we have symbol ready`
            )
        }

        return symbol
    }

    public GetOrCreateSymbol(symbolName: string, ignoreBroker = true, brokerName = ''): TSymbolData | null {
        if (symbolName === '') {
            //for cases like deposit and withdrawal
            return null
        }

        if (!this.IsAmongAvailableSymbols(symbolName, brokerName)) {
            //if the symbol name is not among available symbols, we should not create it, we won't get any data for it anyway
            //such names can be generated by methods that look for auxiliary symbols (like xxxUSD or USDxxx when we calculate point cost for cross currencies)
            return null
        }

        if (!ignoreBroker && brokerName === '') {
            throw new StrangeError('Broker name is required when ignoreBroker is false.')
        }

        for (let i = 0; i < this.Count; i++) {
            const currentItem = this.GetItem(i)

            if (
                currentItem.symbolInfo.SymbolName.toUpperCase() === symbolName.toUpperCase() &&
                (ignoreBroker || currentItem.symbolInfo.Broker.toUpperCase() === brokerName.toUpperCase())
            ) {
                return currentItem
            }
        }

        // create a new symbol if not found
        return this.CreateSymbol(symbolName, true)
    }

    //former GetNewTick
    public GetQueuedTick(): TTickRec {
        let result: TTickRec = new TTickRec()

        if (this.fQueuedTick.IsEmpty()) {
            const symbolData: TSymbolData | null = this.GetEarliestTickData()
            if (
                symbolData &&
                symbolData.fTickData &&
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false) >=
                    symbolData.fTickData.VeryFirstDateInHistory
            ) {
                // get new tick and put to queued
                this.fQueuedTick.tick = symbolData.fTickData.GetNewTick()
                if (this.fQueuedTick.tick) {
                    this.fQueuedTick.SymbolName = symbolData.symbolInfo.SymbolName
                    this.fQueuedTick.symbolData = symbolData
                    result = this.fQueuedTick
                }
            }
        } else {
            result = this.fQueuedTick
        }

        if (result.IsEmpty()) {
            if (DebugUtils.DebugMode) {
                //why are we here? in original Delphi code there were no such block
                // eslint-disable-next-line no-debugger
                debugger
            }

            const symbolData: TSymbolData | null = this.GetEarliestTickData()
            if (symbolData && symbolData.fTickData && symbolData.fTickData.fTicks) {
                const currChunk = symbolData.fTickData.fTicks.GetOrCreateChunkByDate(
                    GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
                )
                if (currChunk && currChunk.Status === TChunkStatus.cs_Loaded) {
                    const nextTick = symbolData.fTickData.fTicks.GetItemByDatePosition(
                        GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
                        TNoExactMatchBehavior.nemb_ReturnNearestHigher
                    )
                    if (nextTick) {
                        result.tick = nextTick
                        result.SymbolName = symbolData.symbolInfo.SymbolName
                        result.symbolData = symbolData
                    }
                }
            }
        }
        return result
    }

    // //TODO: should we rename this to remove confusion with symbolData.fTickData.ExtractNewTick(); ?
    // public ExtractNewTick(): TTickRec {
    //     const result = this.GetQueuedTick()
    //     if (result.SymbolName !== '' && result.tick) {
    //         this.MarkTickAsProcessed(result)
    //         this.ClearQueuedTick()
    //     }
    //     return result
    // }

    public markLastTickAsProcessed(tick: TTickRec, isEmulatedTick: boolean): void {
        if (!tick) {
            throw new StrangeError('MarkTickAsProcessed - Tick is empty')
        }
        if (!tick.symbolData) {
            throw new StrangeError('MarkTickAsProcessed - symbolData is empty')
        }
        if (!tick.tick) {
            throw new StrangeError('MarkTickAsProcessed - tick.tick is empty')
        }
        tick.symbolData.markLastTickAsProcessed(tick.tick.DateTime, isEmulatedTick)
        this.ClearQueuedTick()
    }

    //interates through all symbols and returns the symbol that has the earliest tick
    public GetEarliestTickData(): TSymbolData | null {
        let earliestTime: TDateTime = DateUtils.VeryBigDate
        let result: TSymbolData | null = null

        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)

            //FIXME: can this cause problems?
            // if (
            //     symbolData.fTickData &&
            //     GlobalProjectInfo.ProjectInfo.LastTickTime < symbolData.fTickData.VeryFirstDateInHistory
            // ) {
            //     symbolData.fTickData.LastProcessedTickTime = GlobalProjectInfo.ProjectInfo.LastTickTime
            //     continue
            // }

            const lastAvailableTick = symbolData.fTickData?.GetNewTick() //it will throw an exception if the data were not downloaded yet
            if (!lastAvailableTick) {
                continue
            }

            if (lastAvailableTick.DateTime < earliestTime) {
                earliestTime = lastAvailableTick.DateTime
                result = symbolData
            }
        }

        return result
    }

    public ClearInvisibleBars(): void {
        for (const symbol of this._symbols) {
            symbol.ClearInvisibleBars()
        }
    }

    public ClearAll(): void {
        this._symbols.Clear()
    }

    //TODO: test cases when we are trying to seek for symbols which data ENDED before the requested date
    private PerformSeeking(seekToDateTime: TDateTime): TDateTime | null {
        // TODO: maybe uncomment this when we can get last and first dates in history from server without downloading maps
        //this.EnsureCurrentTestingDataIsAvailable()

        if (
            this.Count === 0 && //the symbols are not available yet, let's defer seeking until they are available
            !DateUtils.IsEmpty(seekToDateTime)
        ) {
            return null //we are not ready yet
        }

        let veryLastProcessedTickTimeAmongAllSymbols: TDateTime = DateUtils.EmptyDate
        let isNotEnoughData = false

        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i) // Assuming Items is an array-like structure with direct index access.
            let lastProcTickTimeForSymbol = null

            //we need try-catch here because we want to initialize downloading data for all symbols even if some of them cannot be seeked yet
            try {
                lastProcTickTimeForSymbol = symbolData.Seek(seekToDateTime)
            } catch (error) {
                if (error instanceof DataNotDownloadedYetError) {
                    isNotEnoughData = true
                    continue
                } else {
                    throw error
                }
            }

            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                `lastProcTickTimeForSymbol for ${symbolData.symbolInfo.SymbolName} is ${lastProcTickTimeForSymbol}`
            )

            //we do DateUtils.LessOrEqual(lastProcTickTimeForSymbol, seekToDateTime) check to avoid the case when we seek several symbols to 2003, some of them gets seek to 2020 and the testing date moves to 2020 instead of 2003
            //so here we exclude symbols that do not have data yet from the calculation of the veryLastProcessedTickTimeAmongAllSymbols
            if (lastProcTickTimeForSymbol && DateUtils.LessOrEqual(lastProcTickTimeForSymbol, seekToDateTime)) {
                veryLastProcessedTickTimeAmongAllSymbols = Math.max(
                    veryLastProcessedTickTimeAmongAllSymbols,
                    lastProcTickTimeForSymbol
                )
            }
        }

        if (isNotEnoughData) {
            throw new DataNotDownloadedYetError('Not enough data for seeking')
        }

        this.ClearQueuedTick()

        if (DateUtils.IsEmpty(veryLastProcessedTickTimeAmongAllSymbols)) {
            //this is the case when all symbols have data AFTER the requested seekToDateTime, for example we request 2003 and data starts from 2020
            //in this case we should take first LastProcessedTickTime from symbol with earliest data

            for (let i = 0; i < this.Count; i++) {
                const symbolData = this.GetItem(i)
                const symbolSeekedTo = symbolData.LastProcessedTickTime
                if (
                    symbolSeekedTo &&
                    (DateUtils.IsEmpty(veryLastProcessedTickTimeAmongAllSymbols) ||
                        DateUtils.LessOrEqual(symbolSeekedTo, veryLastProcessedTickTimeAmongAllSymbols))
                ) {
                    veryLastProcessedTickTimeAmongAllSymbols = symbolSeekedTo
                }
            }
        }

        if (DateUtils.IsEmpty(veryLastProcessedTickTimeAmongAllSymbols)) {
            throw new StrangeError('LastTickTime is empty this is not supposed to happen')
        }

        GlobalProjectInfo.ProjectInfo.SetPreciseLastTickTime(veryLastProcessedTickTimeAmongAllSymbols)
        DebugUtils.logTopic(
            [ELoggingTopics.lt_Seek, ELoggingTopics.lt_Significant],
            `Seek all symbols completed to ${DateUtils.DF(veryLastProcessedTickTimeAmongAllSymbols)}`
        )
        if (DebugUtils.DebugMode) {
            this.__debugValidateSeekingForAllSymbols()
        }
        //this event can trigger adding new symbols (like for cross pairs calculations), so it is at the very end
        this.Events.EmitEvent(TDataArrayEvents.de_SeekCompleted)

        return veryLastProcessedTickTimeAmongAllSymbols
    }

    private __debugValidateSeekingForAllSymbols(): void {
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            if (!symbolData.IsSeeked) {
                throw new StrangeError(
                    `Whole SymbolList is seeked, but symbol ${symbolData.symbolInfo.SymbolName} is not seeked`
                )
            }
        }
    }

    public SeekToLastTickInProject(): TDateTime | null {
        return this.Seek(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))
    }

    //this assumes that lastProcessedTickDate parameters represents the date of last processed tick (not the tick that will be processed next)
    public Seek(lastProcessedTickDate: TDateTime): TDateTime | null {
        if (DateUtils.IsEmpty(this.deferredSeekingDate)) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                'SymbolList Seek - no need to seek - deferredSeekingDate is empty'
            )
        }
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            `SeekAllSymbols to ${lastProcessedTickDate} ${DateUtils.DF(lastProcessedTickDate)}`
        )

        //TODO: can this cause problems?
        GlobalProjectInfo.ProjectInfo.SetApproximateLastTickTime(lastProcessedTickDate)
        try {
            this.ResetSeekStatus()
            const seekedTo = this.PerformSeeking(lastProcessedTickDate)
            if (seekedTo && !DateUtils.IsEmpty(seekedTo)) {
                this.DisableDeferredSeeking()
                return seekedTo
            } else {
                this.DeferSeeking(lastProcessedTickDate)
                return null
            }
        } catch (error) {
            DebugUtils.warnTopic(ELoggingTopics.lt_Seek, 'Seek all symbols not finished yet due to error', error)
            if (error instanceof DataNotDownloadedYetError) {
                this.DeferSeeking(lastProcessedTickDate)
                return null
            } else if (error instanceof OutOfHistoryBoundsError) {
                showWarningToast({ message: error.message })
                this.DisableDeferredSeeking()
                //cannot use testing manager here directly since this causes circular dependency
                this.Events.EmitEvent(TDataArrayEvents.de_OutOfHistoryBoundsError)
                return null
            } else {
                throw error
            }
        }
    }

    public ResetSeekStatus(): void {
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            symbolData.ResetSeekStatus()
        }
    }

    private deferredSeekingDate: TDateTime = DateUtils.EmptyDate

    private DeferSeeking(seekToThisDate: TDateTime): void {
        GlobalChartsController.Instance.enableLoader()
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            "Data is not available yet, let's defer seek SymbolList until it is available"
        )
        this.deferredSeekingDate = seekToThisDate

        this.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundDoDeferredSeek)
        this.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundDoDeferredSeek)
        this.Events.on(TDataArrayEvents.de_LastBarDownloaded, this.boundDoDeferredSeek)
    }

    private boundDoDeferredSeek = this.DoDeferredSeek.bind(this)

    private DoDeferredSeek(): void {
        if (DateUtils.IsEmpty(this.deferredSeekingDate)) {
            DebugUtils.logTopic(ELoggingTopics.lt_Seek, 'DoDeferredSeek no need to seek - deferredSeekingDate is empty')
        } else {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                `DoDeferredSeek to ${this.deferredSeekingDate} ${DateUtils.DF(this.deferredSeekingDate)}`
            )
            //to handle the situation when we are doing deferral from deferral, let's launch next deferral after this ones finishes
            Promise.resolve()
                .then(() => {
                    if (DateUtils.IsEmpty(this.deferredSeekingDate)) {
                        DebugUtils.logTopic(
                            ELoggingTopics.lt_Seek,
                            'DoDeferredSeek - deferredSeekingDate is empty, no need to seek'
                        )
                    } else {
                        this.Seek(this.deferredSeekingDate)
                    }
                })
                .catch((error) => {
                    throw new StrangeError('Error while seeking deferred date', error)
                })
        }
    }

    private DisableDeferredSeeking(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Seek, 'DisableDeferredSeeking for SymbolList')
        this.deferredSeekingDate = DateUtils.EmptyDate

        this.Events.off(TDataArrayEvents.de_ChunkLoaded, this.boundDoDeferredSeek)
        this.Events.off(TDataArrayEvents.de_MapDownloaded, this.boundDoDeferredSeek)
        this.Events.off(TDataArrayEvents.de_LastBarDownloaded, this.boundDoDeferredSeek)
    }

    public CreateSymbol(SymbolName: string, performSeek: boolean): TSymbolData {
        const symbolInfo = new TSymbolInfo(SymbolName)

        fetch(`${BASE_URL}data/api/HistoricalDataQuality/SymbolInfo/${SymbolName}?broker=Advanced`)
            .then((response) => response.json())
            .then((data) => {
                symbolInfo.setSymbolInfoFromServer(data)
                this.Events.EmitEvent(TDataArrayEvents.de_SymbolInfoLoaded)
            })
            .catch((error) => {
                throw new StrangeError('Error while fetching symbol info', error)
            })

        const result = new TSymbolData(symbolInfo)

        this.AddNewSymbol(result, performSeek)
        return result
    }

    public GetPrices(SymbolName: string): [number, number, boolean] {
        let bid = 0
        let ask = 0
        const symbol: TSymbolData | null = this.GetOrCreateSymbol(SymbolName, true)

        if (symbol === null) {
            for (let i = 0; i < FixedDataValues.CurrDataCount; i++) {
                if (FixedDataValues.CurrData[i].name === SymbolName) {
                    bid = FixedDataValues.CurrData[i].bid
                    ask = FixedDataValues.CurrData[i].ask
                    return [bid, ask, true]
                }
            }
        } else {
            bid = symbol.bid
            ask = symbol.ask
            return [bid, ask, true]
        }
        return [0, 0, false]
    }

    public GetOrCreateBarArray(symbolNameForTheseTests: string, timeframe: number): IFMBarsArray {
        const symbolData = this.GetOrCreateSymbol(symbolNameForTheseTests, true)
        if (symbolData) {
            return symbolData.GetOrCreateBarArray(timeframe)
        }
        throw new StrangeError('Symbol not found')
    }

    private boundHandleChunkLoadedEvent = this.handleChunkLoadedEvent.bind(this)

    private handleChunkLoadedEvent(chunk: TBasicChunk): void {
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    private boundHandleMapLoadedEvent = this.handleMapLoadedEvent.bind(this)

    private handleMapLoadedEvent(): void {
        this.Events.EmitEvent(TDataArrayEvents.de_MapDownloaded)
    }

    private boundHandleLastBarDownloadedEvent = this.handleLastBarDownloadedEvent.bind(this)

    private handleLastBarDownloadedEvent(): void {
        this.Events.EmitEvent(TDataArrayEvents.de_LastBarDownloaded)
    }

    public isDateInAvailableRange(date: number): boolean {
        //if at least one symbol has date in its range, return true
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            if (symbolData.isDateInAvailableRange(date)) {
                return true
            }
        }
        return false
    }

    public get VeryFirstDateInHistoryAmongCurrentSymbols(): TDateTime {
        let result: TDateTime = DateUtils.VeryBigDate
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            result = Math.min(result, symbolData.VeryFirstDateInHistory)
        }
        return result
    }

    public get VeryLastDateInHistoryAmongCurrentSymbols(): TDateTime {
        let result: TDateTime = DateUtils.EmptyDate
        for (let i = 0; i < this.Count; i++) {
            const symbolData = this.GetItem(i)
            result = Math.max(result, symbolData.VeryLastDateInHistory)
        }
        return result
    }
}
