import { TBasicPaintTool } from '../../charting/paint_tools/BasicPaintTool'
import { IChart } from '../chart_classes/IChart'

export type TPaintToolClass = new (aChart: IChart) => TBasicPaintTool

export class TPaintToolRegInfo {
    name: string
    ToolType: TPaintToolClass // Assuming TPaintToolClass is defined elsewhere

    constructor(aName: string, aToolType: TPaintToolClass) {
        this.name = aName
        this.ToolType = aToolType
    }
}

export class PaintToolManager {
    private static RegisteredTools: TPaintToolRegInfo[] = []

    public static ReRegisterPaintTool(ToolName: string, ToolType: TPaintToolClass): void {
        for (const tool of PaintToolManager.RegisteredTools) {
            if (tool.name === ToolName) {
                tool.ToolType = ToolType
                return
            }
        }
        PaintToolManager.RegisterPaintTool(ToolName, ToolType)
    }

    public static RegisterPaintTool(ToolName: string, ToolType: TPaintToolClass): void {
        const info = new TPaintToolRegInfo(ToolName, ToolType)
        PaintToolManager.RegisteredTools.push(info)
    }

    public static GetPaintToolClass(ToolName: string): TPaintToolClass | null {
        for (const tool of PaintToolManager.RegisteredTools) {
            if (tool.name === ToolName) {
                return tool.ToolType
            }
        }
        return null
    }
}
