import { FC, useCallback, useMemo } from 'react'
import { t } from 'i18next'

import { Flex, Typography, Separator } from '@fto/ui'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Icon } from '@fto/icons'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getSymbols } from '@root/store/symbols/symbol.selectors'
import chartSettingsStore from '@fto/lib/store/chartSettings'

import TableHead from './components/TableHead'
import NewSymbolsRow from './components/NewSymbolsRow'
import ProjectSymbolsRow from './components/ProjectSymbolsRow'

import styles from './index.module.scss'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import educationStore from '@fto/lib/store/educationStore'
import watchlistStore from '@fto/lib/store/watchlistStore'

type SymbolsListProps = {
    modalType: 'symbolChange' | 'addChart'
    chartWindow: TChartWindow | undefined
    setSelectedChartInfo: (chartInfo: any) => void
    searchQuery: string
}

const SymbolsList: FC<SymbolsListProps> = observer(({ modalType, chartWindow, setSelectedChartInfo, searchQuery }) => {
    const projectSymbols = GlobalProjectJSONAdapter.Instance.getSymbols()
    const { settings } = chartSettingsStore
    const { data: educationData } = educationStore

    const symbols = useAppSelector($getSymbols)
    const symbolsData = useMemo(() => symbols.map((symbol) => symbol.Symbol), [symbols])

    const filteredProjectSymbols = useMemo(
        () => projectSymbols.filter((symbol) => symbol.includes(searchQuery)),
        [projectSymbols, searchQuery]
    )
    const filteredNewSymbols = useMemo(
        () =>
            symbolsData.filter(
                (symbol: string) => symbol.includes(searchQuery) && !filteredProjectSymbols.includes(symbol)
            ),
        [symbolsData, filteredProjectSymbols, searchQuery]
    )

    const isEmpty = filteredNewSymbols.length === 0 && filteredProjectSymbols.length === 0
    const showNewSymbols = filteredNewSymbols.length > 0 && !educationData.isShown

    const addSymbol = useCallback(
        (symbol: string) => {
            if (!projectSymbols.some((existingSymbol) => symbol === existingSymbol)) {
                GlobalProjectJSONAdapter.Instance.setSymbols([...projectSymbols, symbol])
                watchlistStore.updateSymbols([...projectSymbols, symbol])
            }
        },
        [projectSymbols]
    )
    return (
        <Flex
            justifyContent='center'
            alignItems={isEmpty || settings.isSymbolLoading ? 'center' : 'normal'}
            className={styles.block}
        >
            {settings.isSymbolLoading ? (
                <CircularProgress />
            ) : isEmpty ? (
                <Typography color={'gray-600'} type='interface-medium'>
                    {t('dashboard.modals.projects.noSymbols')}
                </Typography>
            ) : (
                <Flex block direction='column' gap={8}>
                    <TableHead />
                    <Flex block direction='column' gap={8} className={styles.symbols}>
                        {filteredProjectSymbols.length > 0 && (
                            <Flex
                                justifyContent='space-between'
                                direction='column'
                                className={styles.body}
                                gap={8}
                                block
                            >
                                {filteredProjectSymbols.map((symbol, index) => (
                                    <ProjectSymbolsRow
                                        symbol={symbol}
                                        key={symbol}
                                        modalType={modalType}
                                        chartWindow={chartWindow}
                                        addSymbolToProject={addSymbol}
                                        setSelectedChartInfo={setSelectedChartInfo}
                                    />
                                ))}
                            </Flex>
                        )}
                        {showNewSymbols && (
                            <Flex>
                                <Separator width='50%' />
                                <Flex justifyContent='center' alignItems='center' className={styles.separator}>
                                    <Typography type='tiny-semibold' color='gray-600' block className={styles.text}>
                                        {t('addChartModal.addNew')}
                                    </Typography>
                                    <Icon
                                        name='arrow-long-left'
                                        color={'var(--color-gray-600)'}
                                        size={16}
                                        className={styles.arrowIcon}
                                    />
                                </Flex>
                                <Separator width='50%' />
                            </Flex>
                        )}
                        {showNewSymbols && (
                            <Flex
                                justifyContent='space-between'
                                direction='column'
                                className={styles.body}
                                gap={8}
                                block
                            >
                                {filteredNewSymbols.map((symbol, index) => (
                                    <NewSymbolsRow
                                        key={index}
                                        chartWindow={chartWindow}
                                        modalType={modalType}
                                        setSelectedChartInfo={setSelectedChartInfo}
                                        addSymbolToProject={addSymbol}
                                        symbol={symbol}
                                    />
                                ))}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}
        </Flex>
    )
})

export default SymbolsList
