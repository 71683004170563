import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TutorialCheckBase } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TutorialCheckBase'
import { TCriteriaOperator } from '@fto/lib/Education/Types'

export class FirstOrderOpenPrice extends TutorialCheckBase {
    constructor(criteriaOperator: TCriteriaOperator, value: number | { valueStart: number; valueEnd: number }) {
        super(criteriaOperator, value)
    }

    checkEqual() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice === (this.value as number)
    }
    checkMore() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice > (this.value as number)
    }
    checkLess() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice < (this.value as number)
    }
    checkNotEqual() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice !== (this.value as number)
    }
    checkMoreOrEqual() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice >= (this.value as number)
    }
    checkLessOrEqual() {
        return GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice <= (this.value as number)
    }
    checkRange() {
        return (
            GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice >=
                (this.value as { valueStart: number; valueEnd: number }).valueStart &&
            GlobalProcessingCore.ProcessingCore.OpenPositions[0].tpos.OpenPrice <=
                (this.value as { valueStart: number; valueEnd: number }).valueEnd
        )
    }
    check() {
        return super.check()
    }
}
