import { EOperationType, OrderType, StoplossState, TakeprofitState } from '@fto/lib/OrderModalClasses/OrderWndStructs'

export const initialData = {
    isLoading: false,
    m_ask: 0, // (sell value in tab - red one)
    m_bid: 0, // (bid value in tab - blue one)
    m_spread: 0, // spread
    m_operationType: EOperationType.BUY, //selected type (sell or buy)
    m_orderType: OrderType.MARKET, // selected tab
    m_priceForLotPoint: 0, // point value
    m_lot: {
        value: 0,
        error: { key: '', value: '' }
    }, // lot input
    m_atPrice: {
        value: 0,
        error: { key: '', value: '' }
    }, // at price input,
    m_riskInUsd: {
        value: 0,
        error: { key: '', value: '' }
    }, // risk in $
    m_riskInPercent: {
        value: 0,
        error: { key: '', value: '' }
    }, // risk in %
    m_stoplossState: StoplossState.DISABLED, // stopLoss checkbox
    m_takeprofitState: TakeprofitState.DISABLED, // takeProfit checkbox
    m_riskRewardRatio: 0, // ratio between takeProfit and stopLoss (0.98 in design)
    stoplossValues: {
        // stop loss inputs
        price: {
            value: 0,
            error: { key: '', value: '' }
        },
        points: {
            value: 0,
            error: { key: '', value: '' }
        },
        usd: {
            value: 0,
            error: { key: '', value: '' }
        },
        percent: {
            value: 0,
            error: { key: '', value: '' }
        }
    },
    takeProfitValues: {
        // take profit inputs
        price: {
            value: 0,
            error: { key: '', value: '' }
        },
        points: {
            value: 0,
            error: { key: '', value: '' }
        },
        usd: {
            value: 0,
            error: { key: '', value: '' }
        },
        percent: {
            value: 0,
            error: { key: '', value: '' }
        }
    },
    pseudoFocuses: {
        m_lot: false,
        m_riskInUsd: false,
        m_riskInPercent: false,
        stoplossValues: {
            price: false,
            usd: false,
            percent: false,
            points: false
        },
        takeProfitValues: {
            price: false,
            usd: false,
            percent: false,
            points: false
        }
    },
    minimumPriceChange: 0, // minimum price change
    m_symbol: '' // symbol
    // comment on FE SIDE
}
