import style from './options-symbol-group.module.scss'
import { makeGroupedList } from '@root/mapper/symbols.mapper'
import React, { FC, useCallback, useState, useMemo } from 'react'
import OptionSymbolsGroup from '@root/pages/Dashboard/components/SymbolsContextMenu/components/OptionsRenderer/OptionSymbolsGroup'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getSymbols } from '@root/store/symbols/symbol.selectors'
import { SymbolItemType } from '@root/types/symbols.types'
import { SET_QUERY_PARAM } from '@root/store/projects/project.slice'
import { useDispatch } from 'react-redux'
import { Flex, Typography, EmptyState, InputField, Checkbox } from '@fto/ui'
import { QueryParams } from '@root/utils/query-params.util'
import { useTranslation } from 'react-i18next'

type Props = {
    query: QueryParams
}

const SymbolOptionsRenderer: FC<Props> = ({ query }) => {
    const { t } = useTranslation()

    const symbols = useAppSelector($getSymbols)

    const dispatch = useDispatch()
    const allSymbols = symbols.map((symbol) => symbol.Symbol)
    const initialSelectedOptions = query.Symbols ? query.Symbols.split(',') : allSymbols

    const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelectedOptions)
    const [searchValue, setSearchValue] = useState('')

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value)
    }, [])

    const handleOptionChange = useCallback((value: SymbolItemType['Symbol']) => {
        setSelectedOptions((prevState) => {
            const isIncluded = prevState.includes(value)

            const newSelectedOptions = isIncluded ? prevState.filter((key) => key !== value) : [...prevState, value]
            dispatch(
                SET_QUERY_PARAM({
                    Symbols: newSelectedOptions.join(',')
                })
            )
            return newSelectedOptions
        })
    }, [])

    const symbolsGrouped = useMemo(
        () =>
            makeGroupedList(
                symbols.filter((symbol) => symbol.Symbol.toLowerCase().includes(searchValue.toLowerCase()))
            ),
        [symbols, searchValue]
    )

    const selectAll = useCallback(() => {
        if (selectedOptions.length === symbols.length) {
            setSelectedOptions([])
            dispatch(
                SET_QUERY_PARAM({
                    Symbols: ''
                })
            )
            return
        }
        setSelectedOptions(allSymbols)
        setSearchValue('')
        dispatch(
            SET_QUERY_PARAM({
                Symbols: allSymbols.join(',')
            })
        )
    }, [selectedOptions, symbols, allSymbols])

    const checkboxVariant = useMemo(() => {
        if (selectedOptions.length === symbols.length) {
            return 'default'
        }

        return 'partial'
    }, [symbols, selectedOptions])

    return (
        <div className={style.dropdown}>
            <Typography type='tiny-regular' color='gray-600' className={style.title}>
                {t('dashboard.toolbar.symbol.title')}
            </Typography>
            <div className={style.header}>
                <Flex onClick={selectAll} alignItems='center' gap={8} className={style.option}>
                    <Checkbox checked={selectedOptions.length > 0} variant={checkboxVariant} />

                    <Typography type='interface-regular' color='gray-1000'>
                        {t('dashboard.toolbar.symbol.symbolsOptions.allSymbols')}
                    </Typography>
                </Flex>
            </div>
            <div className={style.search}>
                <InputField
                    name='Symbols'
                    value={searchValue}
                    onChange={handleSearch}
                    placeholder={t('dashboard.modals.projects.search')}
                    reset
                    withSearchSuffix
                />
            </div>
            {symbolsGrouped.length === 0 ? (
                <EmptyState
                    title={t('dashboard.toolbar.symbol.emptyState.title')}
                    description={t('dashboard.toolbar.symbol.emptyState.description')}
                    classNames={{ root: style.noSymbols }}
                />
            ) : (
                <div className={style.symbolsResult}>
                    {symbolsGrouped.map((group) => (
                        <OptionSymbolsGroup
                            key={group.category}
                            isOpened={!!searchValue}
                            symbolsGroup={group}
                            selectSymbol={handleOptionChange}
                            selectedSymbols={selectedOptions}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default SymbolOptionsRenderer
