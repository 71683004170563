import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTickRecord } from '@fto/lib/ft_types/data/DataClasses/TTickRecord'

export class JumpToSession {
    private _name: string
    private _openTimeInSecondsFromStartDay: number
    private _closeTimeInSecondsFromStartDay: number
    private _openTimeDT: TDateTime
    private _closeTimeDT: TDateTime
    private _isCustom: boolean

    constructor(
        name: string,
        openTimeInSecondsFromStartDay: number,
        closeTimeInSecondsFromStartDay: number,
        isCustom: boolean
    ) {
        this._name = name
        this._openTimeInSecondsFromStartDay = openTimeInSecondsFromStartDay
        this._closeTimeInSecondsFromStartDay = closeTimeInSecondsFromStartDay
        this._isCustom = isCustom
        this._openTimeDT = openTimeInSecondsFromStartDay * DateUtils.OneSecond
        this._closeTimeDT = closeTimeInSecondsFromStartDay * DateUtils.OneSecond
    }

    public getName(): string {
        return this._name
    }

    public isCustom(): boolean {
        return this._isCustom
    }

    getNextOpenSessionDateTimeFromTime(fromTime: TDateTime): TDateTime {
        let testingDay = Math.trunc(fromTime)
        let sessionOpen = testingDay + this._openTimeDT
        let sessionClose = testingDay + this._closeTimeDT

        if (this._openTimeInSecondsFromStartDay > this._closeTimeInSecondsFromStartDay) {
            sessionOpen = sessionClose - DateUtils.OneDay + (this._openTimeDT - this._closeTimeDT)
        }

        while (DateUtils.MoreOrEqual(fromTime, sessionOpen)) {
            sessionOpen += DateUtils.OneDay
            sessionClose += DateUtils.OneDay
        }

        const firstTick = this.getFirstTickFromVisibleCharts(
            sessionOpen,
            TNoExactMatchBehavior.nemb_ReturnNearestHigher
        )

        if (!firstTick) {
            throw new StrangeError('No tick found by session open')
        }

        if (firstTick.DateTime > sessionClose) {
            return this.getNextOpenSessionDateTimeFromTime(firstTick.DateTime)
        } else {
            return firstTick.DateTime
        }
    }

    getNextCloseSessionDateTimeFromTime(fromTime: TDateTime): TDateTime {
        const testingDay = Math.trunc(fromTime)
        let sessionOpen = testingDay + this._openTimeDT
        let sessionClose = testingDay + this._closeTimeDT

        if (this._openTimeInSecondsFromStartDay > this._closeTimeInSecondsFromStartDay) {
            sessionOpen = sessionClose - DateUtils.OneDay + (this._openTimeDT - this._closeTimeDT)
        }

        while (DateUtils.MoreOrEqual(fromTime, sessionClose)) {
            sessionOpen += DateUtils.OneDay
            sessionClose += DateUtils.OneDay
        }

        const firstTick = this.getFirstTickFromVisibleCharts(
            sessionClose,
            TNoExactMatchBehavior.nemb_ReturnNearestHigher
        )

        if (!firstTick) {
            throw new StrangeError('No tick found by session open')
        }

        if (firstTick.DateTime < sessionClose) {
            return this.getNextCloseSessionDateTimeFromTime(firstTick.DateTime)
        } else {
            return firstTick.DateTime
        }
    }

    getPreviousOpenSessionDateTimeFromTime(fromTime: TDateTime): TDateTime {
        let testingDay = Math.trunc(fromTime)
        let sessionOpen = testingDay + this._openTimeDT
        let sessionClose = testingDay + this._closeTimeDT

        if (this._openTimeInSecondsFromStartDay > this._closeTimeInSecondsFromStartDay) {
            sessionOpen = sessionClose - DateUtils.OneDay + (this._openTimeDT - this._closeTimeDT)
        }

        while (DateUtils.LessOrEqual(fromTime, sessionOpen)) {
            sessionOpen -= DateUtils.OneDay
            sessionClose -= DateUtils.OneDay
        }

        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (activeChart) {
            const tickBefore = activeChart.SymbolData.fTickData.GetTickAtDate(
                sessionOpen,
                TNoExactMatchBehavior.nemb_ReturnNearestLower
            )

            if (tickBefore && tickBefore.DateTime < sessionClose) {
                return tickBefore.DateTime
            } else if (tickBefore && tickBefore.DateTime) {
                return tickBefore.DateTime
            } else {
                throw new Error('No tick found before session open')
            }
        } else {
            throw new Error('No active chart')
        }
    }

    private getFirstTickFromVisibleCharts(
        fromTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior
    ): TTickRecord | null {
        const visibleCharts = GlobalChartsController.Instance.getAllCharts()
        let firstTick: TTickRecord | null = null
        for (const chart of visibleCharts) {
            if (chart.SymbolData.isDateInAvailableRange(fromTime)) {
                const tick = chart.SymbolData.fTickData.GetTickAtDate(fromTime, noExactMatchBehavior)
                if (tick && (!firstTick || tick.DateTime < firstTick.DateTime)) {
                    firstTick = tick
                }
            }
        }
        return firstTick
    }
}
