import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import watchlistStore from '@fto/lib/store/watchlistStore'
import { throttle } from 'lodash'

export const throttleWatchlistUpdate = throttle(() => {
    const lastBar = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
    watchlistStore.updateLastBarTime(lastBar)
}, 500)

export const getChangedDigitsLength = (value: string) => {
    const [, decimalPart] = value.split('.')
    const cleanedDecimalPart = decimalPart.replace(/^0+/, '')
    return cleanedDecimalPart.length
}
