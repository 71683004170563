import { StrategyImplementation } from '@fto/lib/extension_modules/startegies/api/UserStrategy'
import { TestStrategy } from '@fto/lib/extension_modules/startegies/built_in_strategies/TestStrategy'
import { TestStrategy_MACross } from '@fto/lib/extension_modules/startegies/built_in_strategies/TestStrategy_MACross'
import { Strategy } from '@fto/lib/extension_modules/startegies/Strategy'
import { TTickRec } from '@fto/lib/ft_types/data/TickRec'

class StrategyInfo {
    public strategy: Strategy
    public fileName: string
    public isRunning = false

    constructor(strategy: Strategy, fileName: string, isRunning: boolean) {
        this.strategy = strategy
        this.fileName = fileName
        this.isRunning = isRunning
    }
}

export default class GlobalStrategiesManager {
    private static _instance: GlobalStrategiesManager
    private _isExecutionAllowed = true
    private _strategies: StrategyInfo[] = []

    public get areStrategiesRunning(): boolean {
        return this._strategies.some((strategyInfo: StrategyInfo) => strategyInfo.isRunning)
    }

    public static get Instance(): GlobalStrategiesManager {
        if (!GlobalStrategiesManager._instance) {
            GlobalStrategiesManager._instance = new GlobalStrategiesManager()
        }

        return GlobalStrategiesManager._instance
    }

    private constructor() {
        //this._strategies.push(new StrategyInfo(new Strategy(new TestStrategy(), 'TestStrategy'), 'TestStrategy', false))
        this._strategies.push(
            new StrategyInfo(
                new Strategy(new TestStrategy_MACross(), 'TestStrategy_MACross'),
                'TestStrategy_MACross',
                false
            )
        )
        //this.runStrategy('TestStrategy')
    }

    private getStrategyByFileName(fileName: string): StrategyImplementation | null {
        return new TestStrategy()
    }

    public onTick(trec: TTickRec): void {
        if (this._isExecutionAllowed) {
            this._strategies.forEach((strategyInfo: StrategyInfo) => {
                if (strategyInfo.isRunning) {
                    strategyInfo.strategy.onTick(trec)
                }
            })
        }
    }

    public runStrategy(fileName: string): void {
        this._strategies.forEach((strategyInfo: StrategyInfo) => {
            if (strategyInfo.fileName === fileName) {
                if (!strategyInfo.isRunning) {
                    strategyInfo.isRunning = true
                    strategyInfo.strategy.init()
                }
            }
        })
    }

    public stopStrategy(fileName: string): void {
        this._strategies.forEach((strategyInfo: StrategyInfo) => {
            if (strategyInfo.fileName === fileName) {
                if (strategyInfo.isRunning) {
                    strategyInfo.isRunning = false
                    strategyInfo.strategy.done()
                }
            }
        })
    }
}
