import { TChartStyle } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { makeAutoObservable } from 'mobx'
import { UndoRedoType } from '@fto/lib/store/chartSettings/types'

export type SettingsType = {
    TestingSpeed: number
    isPlaying: boolean
    isSymbolLoading: boolean
    ChartStyle: TChartStyle
}

const initialSettings = {
    TestingSpeed: 9,
    isPlaying: false,
    isSymbolLoading: false,
    ChartStyle: TChartStyle.cs_Bar
}

class ChartSettingsStore {
    settings: SettingsType
    undoRedo: UndoRedoType

    constructor() {
        makeAutoObservable(this)

        this.settings = initialSettings
        this.undoRedo = {
            isUndoAvailable: false,
            isRedoAvailable: false
        }

        this.setSettings = this.setSettings.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.updateChartSettings = this.updateChartSettings.bind(this)
        this.checkUndoRedoAvailability = this.checkUndoRedoAvailability.bind(this)
    }

    setSettings(updater: ((prevSettings: SettingsType) => SettingsType) | SettingsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.settings = {
                ...this.settings,
                ...updater(this.settings)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.settings = {
                ...this.settings,
                ...updater
            }
        }
    }

    updateChartSettings() {
        GlobalChartsController.Instance.getActiveChart()?.ImportSettings()
    }

    checkUndoRedoAvailability() {
        const activeChart = GlobalChartsController.Instance.getActiveChart()

        this.undoRedo = {
            isUndoAvailable: activeChart?.hasUndoTasks() || false,
            isRedoAvailable: activeChart?.hasRedoTasks() || false
        }
    }

    resetStore() {
        this.settings = initialSettings
        this.undoRedo = {
            isUndoAvailable: false,
            isRedoAvailable: false
        }
    }
}

export default new ChartSettingsStore()
