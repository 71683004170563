import React, { useEffect, useState, useMemo, ComponentPropsWithoutRef, forwardRef } from 'react'
import cx from 'classnames'

import { extractViewBoxValue } from '../utils'

import { FilenameTypes } from '../output/assets-types'

import styles from './index.module.scss'

type Props = {
    className?: string
    name: (typeof FilenameTypes)[number]
    size?: number
    x?: number
    y?: number
    height?: number
    width?: number
    onClick?: React.MouseEventHandler<SVGSVGElement>
    color?: string | null
    stroke?: string
    strokeWidth?: string
    transform?: string
    tabIndex?: number
}

type OtherNativeSVGProps = Omit<ComponentPropsWithoutRef<'svg'>, keyof Props>

export type IconProps = Props & OtherNativeSVGProps

export const Icon = forwardRef<SVGSVGElement, IconProps>(
    (
        {
            className,
            name,
            size = 24,
            x,
            y,
            onClick,
            color = 'var(--color-gray-1000)',
            stroke,
            strokeWidth,
            transform,
            tabIndex,
            style,
            height,
            width,
            ...rest
        },
        ref
    ) => {
        const [iconName, setIconName] = useState('')

        useEffect(() => {
            const getIconName = async () => {
                const assetName = await import(`../assets/${name}.svg?raw`)
                setIconName(assetName.default)
            }

            getIconName()
        }, [name])

        const iconInnerContent = useMemo(() => {
            if (!iconName) {
                return ''
            }

            return iconName.replace(/<svg[^>]*>([\s\S]*?)<\/svg>/g, '$1')
        }, [iconName])

        const viewBoxValue = useMemo(() => extractViewBoxValue(iconName), [iconName])

        if (!iconName) return null

        return (
            <svg
                {...rest}
                viewBox={viewBoxValue}
                ref={ref}
                tabIndex={tabIndex}
                className={cx(
                    styles.Icon,
                    {
                        [styles.focusable]: tabIndex !== undefined && tabIndex >= 0
                    },
                    className
                )}
                width={width || size}
                height={height || size}
                x={x}
                y={y}
                onClick={onClick}
                fill='none'
                style={{ ...style, stroke, strokeWidth, transform, ...(color && { color }) }}
                dangerouslySetInnerHTML={{ __html: iconInnerContent }}
            />
        )
    }
)
