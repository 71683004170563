import { StoplossValues, MarketValues, OrderValues } from '../OrderWndStructs'

export abstract class StoplossCalculationStrategy {
    abstract onChangeStoplossPrice(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract onChangeStoplossPoints(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract onChangeStoplossInUsd(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract onChangeStoplossInPercent(
        stoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null

    abstract recalculateStoplossValuesByPoints(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract recalculateStoplossValuesByPrice(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract recalculateStoplossValuesByUsd(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
    abstract recalculateStoplossValuesByPercent(
        currStoplossValues: StoplossValues,
        marketValues: MarketValues,
        orderValues: OrderValues
    ): StoplossValues | null
}
