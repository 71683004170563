import { FC, useState } from 'react'
import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'
import { IconButton } from '@fto/icons'

import { ISymbolComparison } from '@fto/lib/in_app_testing/BuildingTimeframesCheck/ComparisonInterfaces'
import { EComparisonStatus } from '@fto/lib/in_app_testing/BuildingTimeframesCheck/ComparisonEnums'
import TimeframeRow from './components/TimeframeRow'

import styles from './index.module.scss'

type Props = {
    data: ISymbolComparison
}

const STATUSES = {
    [EComparisonStatus.NotStarted]: 'Not started',
    [EComparisonStatus.InProgress]: 'In progress',
    [EComparisonStatus.Successful]: 'Successful',
    [EComparisonStatus.Failed]: 'Failed'
}
const Row: FC<Props> = ({ data }) => {
    const { symbol, timeframes, status, message } = data
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Flex direction='column' gap={4} className={styles.Row}>
            <Flex alignItems='center' justifyContent='space-between' gap={16}>
                <Flex alignItems='center' gap={8}>
                    <div
                        title={STATUSES[status]}
                        className={cx(styles.status, {
                            [styles.inProgress]: status === EComparisonStatus.InProgress,
                            [styles.Successful]: status === EComparisonStatus.Successful,
                            [styles.Failed]: status === EComparisonStatus.Failed
                        })}
                    />
                    <Typography type='h4-semibold'>{symbol}</Typography>
                </Flex>
                <IconButton
                    name='chevron-down'
                    size={18}
                    onClick={() => setIsOpen((prev) => !prev)}
                    className={cx(styles.Arrow, { [styles.ArrowOpen]: isOpen })}
                />
            </Flex>
            {isOpen && (
                <Flex direction='column' className={styles.Wrapper} gap={16}>
                    {!message && (
                        <Flex gap={8}>
                            <Typography type='interface-semibold'>Message:</Typography>
                            <Typography type='interface-regular' block className={styles.Message}>
                                {message || '-'}
                            </Typography>
                        </Flex>
                    )}
                    {timeframes.map((tf) => (
                        <TimeframeRow timeframe={tf} key={tf.timeframe} />
                    ))}
                </Flex>
            )}
        </Flex>
    )
}

export default Row
