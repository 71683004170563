import styles from '../../index.module.scss'
import { FilenameTypes, Icon } from '@fto/icons'
import { Flex } from '@fto/ui'
import { FC } from 'react'

type Props = {
    onClick: () => void
    icon: (typeof FilenameTypes)[number]
    resetTooltipState: () => void
    active?: boolean
    size?: number
}

export const DropdownIcon: FC<Props> = ({ active, onClick, icon, resetTooltipState, size = 18 }) => {
    return (
        <Flex className={styles.iconWrapper} direction='column' onClick={onClick} onMouseLeave={resetTooltipState}>
            <Icon size={size} name={icon} color={active ? 'var(--color-primary-500)' : 'var(--color-gray-1000)'} />
            <Icon className={styles.dropdownIcon} size={5} name='dropdown' />
        </Flex>
    )
}
