import IBasicPaintTool from '@fto/lib/charting/paint_tools/IBasicPaintTool'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { useMemo } from 'react'

export function useGetObjectsCountByVisibility() {
    const allCharts = GlobalChartsController.Instance.getAllCharts()
    const mainChartTools = useMemo(
        () =>
            allCharts.reduce((acc: IBasicPaintTool[], chart) => {
                return acc.concat(chart.MainChart.PaintTools)
            }, []),
        [allCharts]
    )
    const oscillatorTools = useMemo(
        () =>
            allCharts.reduce((acc: IBasicPaintTool[], chart) => {
                return acc.concat(
                    chart.OscWins.reduce(
                        (acc_local: IBasicPaintTool[], oscWin) => acc_local.concat(oscWin.chart.PaintTools),
                        []
                    )
                )
            }, []),
        [allCharts]
    )
    const tools = [...mainChartTools, ...oscillatorTools]
    const uniqueTools = useMemo(
        () =>
            tools.reduce((acc: IBasicPaintTool[], current) => {
                if (!acc.some((tool) => tool.LinkNumber === current.LinkNumber)) {
                    acc.push(current)
                }
                return acc
            }, []),
        [tools]
    )

    const mainChartIndicators = useMemo(
        () =>
            allCharts.reduce((acc: TRuntimeIndicator[], chart) => {
                return acc.concat(chart.MainChart.indicators)
            }, []),
        [allCharts]
    )
    const oscillators = useMemo(
        () =>
            allCharts.reduce(
                (acc: TRuntimeIndicator[], chart) =>
                    acc.concat(
                        chart.OscWins.reduce(
                            (acc_local: TRuntimeIndicator[], oscWin) => acc_local.concat(oscWin.chart.indicators),
                            []
                        )
                    ),
                []
            ),
        [allCharts]
    )
    const indicators = [...oscillators, ...mainChartIndicators]

    const hiddenToolsCount = useMemo(
        () => uniqueTools.filter((tool) => tool.IsVisible() === false).length,
        [uniqueTools]
    )
    const hiddenIndicatorsCount = useMemo(
        () => indicators.filter((indicator) => indicator.IsVisible() === false).length,
        []
    )
    const shownIndicatorsCount = useMemo(
        () => indicators.filter((indicator) => indicator.IsVisible()).length,
        [indicators]
    )
    const shownToolsCount = useMemo(() => uniqueTools.filter((tool) => tool.IsVisible()).length, [uniqueTools])

    return { hiddenToolsCount, hiddenIndicatorsCount, shownIndicatorsCount, shownToolsCount }
}
