import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDateTime } from '../../../../delphi_compatibility/DateUtils'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import { TChunkStatus } from '../ChunkEnums'
import TDownloadableChunk from '../DownloadableChunk/DownloadableChunk'
import { TTickRecord } from '../../DataClasses/TTickRecord'

export abstract class TBaseTickChunk extends TDownloadableChunk<TTickRecord> {
    // private tickChunkSize = DateUtils.OneDay;
    private fLastPossibleDate: TDateTime
    protected _isNoDataOnServer = false

    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: TDateTime, aLastPossibleDate: TDateTime) {
        super(aDataDescriptor, aFirstDate)
        this.fLastPossibleDate = aLastPossibleDate
        this._status = TChunkStatus.cs_Empty
    }

    /// <summary>like on weekends</summary>
    public IsEmptyOnServer(): boolean {
        return this._isNoDataOnServer
    }

    public get Count(): number {
        return this._data.length
    }

    public get FirstGlobalIndex(): number {
        //TODO: redesign this, make the class structure better so we won't need to override this method
        throw new StrangeError('In tick chunks there are no global indexes, so this method should not be called')
    }

    public get LastPossibleDate(): TDateTime {
        return this.fLastPossibleDate
        //TODO: this may be refactored this way: return this.FirstDate + this.tickChunkSize - DateUtils.OneMillisecond;
    }

    public GetLocalIndexByDate(aDate: TDateTime): number {
        if (this.Status !== TChunkStatus.cs_Loaded) {
            throw new StrangeError('Chunk is not loaded')
        }

        return this.GetDateByLocalIndex(aDate)
    }
}
