import { WorkerWrapper } from '@fto/lib/ft_types/data/data_downloading/WorkerWrapper'

export class WorkerPool {
    private workers: WorkerWrapper[]

    constructor(poolSize: number, workerScriptUrl: string) {
        this.workers = Array.from({ length: poolSize }, () => new WorkerWrapper(workerScriptUrl))
    }

    getFreeWorker(): WorkerWrapper | undefined {
        return this.workers.find((worker) => !worker.isBusy())
    }
}
