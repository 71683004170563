import { TChartType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import AC from './built_in_indicators/AC'
import ADX from './built_in_indicators/ADX'
import ATR from './built_in_indicators/ATR'
import { Awesome } from './built_in_indicators/Awesome'
import BollingerBands from './built_in_indicators/BollingerBands'
import CCI from './built_in_indicators/CCI'
import DeMarker from './built_in_indicators/DeMarker'
import FairValueGap from './built_in_indicators/FVG'
import Ichimoku from './built_in_indicators/Ichimoku'
import InsideBar from './built_in_indicators/InsideBar'
import iSession from './built_in_indicators/ISession'
import MACD from './built_in_indicators/MACD'
import MFI from './built_in_indicators/MFI'
import Momentum from './built_in_indicators/Momentum'
import MovingAverage from './built_in_indicators/MovingAverage'
import OBV from './built_in_indicators/OBV'
import RSI from './built_in_indicators/RSI'
import Stochastic from './built_in_indicators/Stochastic'
import Volume from './built_in_indicators/Volume'
import WPR from './built_in_indicators/WPR'
import { IndicatorDescriptor } from './IndicatorDescriptor'
import { TRuntimeIndicator } from './RuntimeIndicator'
import { IndicatorImplementation } from './api/IndicatorImplementation'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { TMAType, TOptValue_number } from '../common/CommonTypes'
import Alligator from '@fto/lib/extension_modules/indicators/built_in_indicators/Alligator'
import BollingerBandsRatio from '@fto/lib/extension_modules/indicators/built_in_indicators/BollingerBandsRatio'

export class RuntimeIndicatorFactory {
    private static generateLibName(indicatorName: string): string {
        return `BuildInRuntimeIndicator_${indicatorName}_FTO_ourbestteam`
    }

    public static CreateRuntimeIndicator(
        indicator_descriptor: IndicatorDescriptor,
        symbolData: ISymbolData,
        timeframe: number,
        ChartType: TChartType
    ): TRuntimeIndicator {
        const updatedIndicatorDescriptor = indicator_descriptor.clone()
        updatedIndicatorDescriptor.libName = this.generateLibName(indicator_descriptor.shortName)
        const indicatorImplementation = this.getIndicatorImplementation(indicator_descriptor.shortName)
        return new TRuntimeIndicator(
            updatedIndicatorDescriptor,
            indicatorImplementation,
            symbolData,
            timeframe,
            ChartType
        )
    }

    private static getIndicatorImplementation(shortName: string): IndicatorImplementation {
        switch (shortName) {
            case 'Williams Percent Range': {
                return new WPR()
            }
            case 'OBV': {
                return new OBV()
            }
            case 'DeMarker': {
                return new DeMarker()
            }
            case 'Volume': {
                return new Volume()
            }
            case 'Momentum': {
                return new Momentum()
            }
            case 'CCI': {
                return new CCI()
            }
            case 'RSI': {
                return new RSI()
            }
            case 'ATR': {
                return new ATR()
            }
            case 'Ichimoku': {
                return new Ichimoku()
            }
            case 'Stochastic': {
                return new Stochastic()
            }
            case 'ADX': {
                return new ADX()
            }
            case 'Moving Average': {
                return new MovingAverage()
            }
            case 'SMA': {
                const result = new MovingAverage()
                result.MAtype = new TOptValue_number(TMAType.ma_SMA)
                return result
            }
            case 'EMA': {
                const result = new MovingAverage()
                result.MAtype = new TOptValue_number(TMAType.ma_EMA)
                return result
            }
            case 'WMA': {
                const result = new MovingAverage()
                result.MAtype = new TOptValue_number(TMAType.ma_WMA)
                return result
            }
            case 'SSMA': {
                const result = new MovingAverage()
                result.MAtype = new TOptValue_number(TMAType.ma_SSMA)
                return result
            }
            case 'Bollinger Bands': {
                return new BollingerBands()
            }
            case 'Bollinger Bands Ratio': {
                return new BollingerBandsRatio()
            }
            case 'MACD': {
                return new MACD()
            }
            case 'MFI': {
                return new MFI()
            }
            case 'Trading Sessions': {
                return new iSession()
            }
            case 'Fair Value Gap': {
                return new FairValueGap()
            }
            case 'Awesome Oscillator (B. Williams)': {
                return new Awesome()
            }
            case 'Alligator (B. Williams)': {
                return new Alligator()
            }
            case 'Inside Bar': {
                return new InsideBar()
            }
            case 'Accelerator Oscillator (B. Williams)': {
                return new AC()
            }
            // case 'ZigZag': {
            // 	return new ZigZag()
            // }
            // case 'Heikin Ashi': {
            // 	return new HeikinAshi()
            // }
            // case 'Pivot Points': {
            // 	return new PivotPoints()
            // }
            // case 'Parabolic SAR': {
            // 	return new ParabolicSAR()
            // }
            default: {
                throw new NotImplementedError('Indicator not found')
            }
        }
    }
}
