import { TProcessingCore } from '../processing_core/ProcessingCore'

export default class GlobalProcessingCore {
    private static fProcessingCore: TProcessingCore

    private static Init(): void {
        GlobalProcessingCore.fProcessingCore = new TProcessingCore()
    }

    public static Reset(): void {
        this.Init()
    }

    public static get ProcessingCore(): TProcessingCore {
        if (!GlobalProcessingCore.fProcessingCore) {
            GlobalProcessingCore.Init()
        }
        return GlobalProcessingCore.fProcessingCore
    }
}
