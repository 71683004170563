import React, { useCallback, useState, FC } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

interface Position {
    x: number
    y: number
}

const useDraggablePanel = (ref: any, initialCoords?: Position) => {
    const initialPosition = initialCoords || { x: window.innerWidth / 4, y: 60 }

    const [isDragging, setIsDragging] = useState(false)
    const [position, setPosition] = useState<Position>(initialPosition)
    const [startPosition, setStartPosition] = useState<Position>(initialPosition)

    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setIsDragging(true)
            document.body.style.userSelect = 'none' // Disable text selection on the entire body
            setStartPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y
            })
            GlobalChartsController.Instance.disableMouseEvents()
        },
        [position]
    )

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging) return

            const boundaries = ref.current.getBoundingClientRect()
            const defaultLeftCornerXPosition = window.innerWidth
            const defaultLeftCornerYPosition = window.innerHeight

            const newPosition = calculatePosition(
                e.clientX,
                e.clientY,
                startPosition,
                defaultLeftCornerXPosition,
                defaultLeftCornerYPosition,
                boundaries
            )

            setPosition(newPosition)
        },
        [isDragging, startPosition]
    )

    const onMouseUp = useCallback(() => {
        setIsDragging(false)
        document.body.style.userSelect = '' // Re-enable text selection
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    return { position, onMouseDown, onMouseMove, onMouseUp, isDragging }
}

const calculatePosition = (
    mouseX: number,
    mouseY: number,
    startPosition: { x: number; y: number },
    defaultElemX: number,
    defaultElemY: number,
    boundaries: DOMRect
) => {
    let newX = mouseX - startPosition.x
    let newY = mouseY - startPosition.y

    if (boundaries.right >= defaultElemX) {
        newX = newX > defaultElemX - boundaries.width ? defaultElemX - boundaries.width : newX
    }
    if (boundaries.left <= 0) {
        newX = newX < 0 ? 0 : newX
    }
    if (boundaries.bottom >= defaultElemY) {
        newY = newY > defaultElemY - boundaries.height ? defaultElemY - boundaries.height : newY
    }
    if (boundaries.top <= 0) {
        newY = newY < 0 ? 0 : newY
    }

    return { x: newX, y: newY }
}

export default useDraggablePanel
