import { TIndicatorOption } from '@fto/lib/charting/tool_storages/indicators/Interfaces/TIndicatorOption'
import { TBufferStyle } from '@fto/lib/drawing_interface/VCLCanvas/TBufferStyle'
import { TOptionType } from '@fto/lib/extension_modules/common/CommonTypes'

export function findOption(options: { [key: string]: any }, key: string) {
    if (key === 'indicatorInstance') return null
    for (let k in options) {
        const option = options[k]
        if (option.alias === key) return option
    }
    return null
}

export function updateOption(option: TIndicatorOption, newOptionParams: { [key: string]: any }) {
    switch (option.type) {
        case TOptionType.ot_Integer:
        case TOptionType.ot_double: {
            return {
                ...option,
                fvalue: newOptionParams.value,
                LowValue: newOptionParams.min,
                HighValue: newOptionParams.max
            }
        }
        case TOptionType.ot_Color: {
            return {
                ...option,
                fvalue: newOptionParams.value.color
            }
        }

        case TOptionType.ot_LineStyle: {
            return {
                ...option,
                fvalue: new TBufferStyle(
                    newOptionParams.value.color,
                    newOptionParams.value.style,
                    newOptionParams.value.width,
                    newOptionParams.value.checked
                )
            }
        }
        case TOptionType.ot_DateTime:
        case TOptionType.ot_Timeframe:
        case TOptionType.ot_Currency:
        case TOptionType.ot_Indicator:
        case TOptionType.ot_EnumType:
        case TOptionType.ot_String:
        case TOptionType.ot_Longword:
        case TOptionType.ot_Boolean: {
            return {
                ...option,
                fvalue: newOptionParams.value
            }
        }

        default: {
            return option
        }
    }
}
