import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import { TChunkStatus, TNoExactMatchBehavior } from '../../chunks/ChunkEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import IFMBarsArray from '../../data_arrays/chunked_arrays/IFMBarsArray'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TBasicChunk } from '../../chunks/BasicChunk'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import { TBarChunk } from '../../chunks/BarChunk'
import CommonDataUtils from '../../DataUtils/CommonDataUtils'
import { DateIsAfterChunkEnd } from '../../chunks/DateOutOfChunkBoundsErrors'
import { ESmallerChunksStatus } from './ChunkBuildingEnums'
import { ISmallerChunksWithStatus } from './ChunkBuildingInterfaces'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'

interface ICurrentSmallerBarInfo {
    currentSmallerBar: TBarRecord
    barGlobalIndex: number
    currentChunkInfo: ICurrentChunkInfo
}

interface ICurrentChunkInfo {
    currentChunk: TBarChunk
    currentChunkIndex: number
}

export interface ISmallerChunks {
    smallerChunks: TBarChunk[]
    relevantChunksFromSmallerBarsArray: TBarChunk[]
}

//cases to test: there is an empty chunk at the beginning, there is an empty chunk at the end, there is an overlap between chunks
export default class ChunkBuilderUtils {
    public static BuildBarsFromSmallerChunks(
        chunkToBuild: TBarChunk,
        smallerChunksNecessary: TBarChunk[]
    ): TBarRecord[] {
        this.SortChunksByFirstDate(smallerChunksNecessary)

        const resultBars: TBarRecord[] = []

        let currentSmallerBarInfo = this.GetFirstRelevantBarOfASmallerChunk(
            smallerChunksNecessary,
            0,
            chunkToBuild.FirstDate
        )

        let biggerBarUnderConstruction,
            biggerBarEndDate = undefined

        while (
            currentSmallerBarInfo &&
            currentSmallerBarInfo.currentSmallerBar &&
            currentSmallerBarInfo.currentSmallerBar.DateTime < chunkToBuild.LastPossibleDate
        ) {
            if (
                !biggerBarUnderConstruction ||
                !biggerBarEndDate ||
                DateUtils.MoreOrEqual(currentSmallerBarInfo.currentSmallerBar.DateTime, biggerBarEndDate)
            ) {
                AddNewBiggerBarToResults(currentSmallerBarInfo.currentSmallerBar)
            } else {
                //we are still in the same bigger bar, so let's update it
                this.UpdateBiggerBarUnderConstruction(
                    biggerBarUnderConstruction,
                    currentSmallerBarInfo.currentSmallerBar
                )
            }

            currentSmallerBarInfo = this.GetNextSmallerBarInfo(currentSmallerBarInfo, smallerChunksNecessary)
        }

        return resultBars

        function AddNewBiggerBarToResults(smallerBar: TBarRecord) {
            ;[biggerBarUnderConstruction, biggerBarEndDate] = ChunkBuilderUtils.InitBarUnderConstruction(
                smallerBar,
                chunkToBuild.DataDescriptor.timeframe
            )

            ChunkBuilderUtils.ThrowErrorIfBiggerBarDateIsNotCorrect(biggerBarUnderConstruction, chunkToBuild)

            resultBars.push(biggerBarUnderConstruction)
        }
    }

    private static GetNextSmallerBarInfo(
        currentSmallerBarInfo: ICurrentSmallerBarInfo,
        smallerChunksNecessary: TBarChunk[]
    ): ICurrentSmallerBarInfo | undefined {
        currentSmallerBarInfo.barGlobalIndex++

        //did we reach the end of the current chunk?
        if (
            currentSmallerBarInfo.barGlobalIndex <= currentSmallerBarInfo.currentChunkInfo.currentChunk.LastGlobalIndex
        ) {
            //we are still in the same chunk, so let's get the next bar
            currentSmallerBarInfo.currentSmallerBar = this.GetSmallerBarByGlobalIndex(
                currentSmallerBarInfo.currentChunkInfo.currentChunk,
                currentSmallerBarInfo.barGlobalIndex
            )
            return currentSmallerBarInfo
        } else {
            //let's move to the next chunk
            const lastBarEndDate = CommonDataUtils.GetBarEndDate(
                currentSmallerBarInfo.currentSmallerBar,
                currentSmallerBarInfo.currentChunkInfo.currentChunk.DataDescriptor.timeframe
            )

            currentSmallerBarInfo.currentChunkInfo.currentChunkIndex++

            return this.GetFirstRelevantBarOfASmallerChunk(
                smallerChunksNecessary,
                currentSmallerBarInfo.currentChunkInfo.currentChunkIndex,
                lastBarEndDate
            )
        }
    }

    static GetSmallerBarByGlobalIndex(currentChunk: TBarChunk, barGlobalIndex: number): TBarRecord {
        const smallerBar = currentChunk.GetItemByGlobalIndex(barGlobalIndex)
        if (!smallerBar) {
            throw new StrangeError('Cannot get the bar by global index')
        }
        return smallerBar
    }

    private static GetFirstRelevantBarOfASmallerChunk(
        smallerBarChunks: TBarChunk[],
        chunkIndexToStart: number,
        aDateOfABiggerBar: TDateTime
    ): ICurrentSmallerBarInfo | undefined {
        for (let chunkIndex = chunkIndexToStart; chunkIndex < smallerBarChunks.length; chunkIndex++) {
            const currentSmallerChunk = smallerBarChunks[chunkIndex]

            //we should always try to access dates within a chunk's range
            const dateToSearch = Math.max(currentSmallerChunk.FirstDate, aDateOfABiggerBar)
            if (dateToSearch > currentSmallerChunk.LastPossibleDate) {
                //this chunk is not relevant for the current bigger bar, let's continue to the next chunk
                continue
            }

            let firstSmallerBarIndex
            try {
                firstSmallerBarIndex = currentSmallerChunk.GetGlobalIndexByDate(
                    dateToSearch,
                    false,
                    TNoExactMatchBehavior.nemb_ReturnNearestHigher
                    //if there is a gap (smaller bar that should have been the part of a bigger bar does not exist)
                    //then let's find next smaller bar that will be used as a base for building a bigger bar
                )
            } catch (error) {
                if (error instanceof DateIsAfterChunkEnd) {
                    //probably this chunk has a gap at the end, so we cannot take any bars from here
                    //let's proceed to the next chunk
                    continue
                }
                throw error
            }

            if (firstSmallerBarIndex === CommonConstants.EMPTY_INDEX) {
                //probably this chunk has a gap at the end, so we cannot take any bigger bars from here
                //let's proceed to the next chunk
                continue
            }
            const firstSmallerBar = currentSmallerChunk.GetItemByGlobalIndex(firstSmallerBarIndex)

            if (!firstSmallerBar) {
                throw new StrangeError('Cannot get the bar by global index')
            }

            return {
                currentSmallerBar: firstSmallerBar,
                barGlobalIndex: firstSmallerBarIndex,
                currentChunkInfo: { currentChunk: currentSmallerChunk, currentChunkIndex: chunkIndex }
            }
        }
        return undefined
    }

    private static ThrowErrorIfBiggerBarDateIsNotCorrect(
        biggerBarUnderConstruction: TBarRecord,
        chunkToBuild: TBarChunk
    ) {
        if (biggerBarUnderConstruction.DateTime > chunkToBuild.LastPossibleDate) {
            throw new StrangeError("The bigger bar's date is greater than the last possible date of the chunk to build")
        }
    }

    private static UpdateBiggerBarUnderConstruction(
        biggerBarUnderConstruction: TBarRecord,
        currentSmallerBar: TBarRecord
    ) {
        biggerBarUnderConstruction.high = Math.max(biggerBarUnderConstruction.high, currentSmallerBar.high)
        biggerBarUnderConstruction.low = Math.min(biggerBarUnderConstruction.low, currentSmallerBar.low)
        biggerBarUnderConstruction.close = currentSmallerBar.close
        biggerBarUnderConstruction.volume += currentSmallerBar.volume
    }

    private static InitBarUnderConstruction(
        currentSmallerBar: TBarRecord,
        biggerBarTimeframe: number
    ): [TBarRecord, TDateTime] {
        let biggerBarFirstDate,
            biggerBarLastDate
            // eslint-disable-next-line prefer-const
        ;[biggerBarFirstDate, biggerBarLastDate] = TimeframeUtils.GetPeriod(
            currentSmallerBar.DateTime,
            biggerBarTimeframe
        )
        return [
            new TBarRecord(
                biggerBarFirstDate,
                currentSmallerBar.open,
                currentSmallerBar.high,
                currentSmallerBar.low,
                currentSmallerBar.close,
                currentSmallerBar.volume
            ),
            biggerBarLastDate
        ]
    }

    public static GetRelevantSmallerChunks(chunk: TBarChunk): ISmallerChunksWithStatus {
        if (chunk.DataDescriptor.timeframe === 1) {
            throw new StrangeError(
                'We should not be here in GetRelevantSmallerChunks searching smaller than M1 bars array'
            )
        }

        const smallerBarsArray = this.GetSmallerStandardActiveBarsArray(chunk)

        if (smallerBarsArray) {
            if (smallerBarsArray.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
                const foundSmallerChunks = smallerBarsArray.GetChunksForRangeDates(
                    chunk.FirstDate,
                    chunk.LastPossibleDate - CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME
                )
                if (this.AreAllChunksLoaded(foundSmallerChunks)) {
                    return { status: ESmallerChunksStatus.scs_ChunksAreLoaded, chunks: foundSmallerChunks }
                } else {
                    return { status: ESmallerChunksStatus.scs_ChunksListReadyButNotLoaded, chunks: foundSmallerChunks }
                }
            } else {
                return { status: ESmallerChunksStatus.scs_SmallerChunksMapNotLoaded, chunks: [] }
            }
        } else {
            return { status: ESmallerChunksStatus.scs_NoSmallerActiveBarsArray, chunks: [] }
        }
    }

    private static SortChunksByFirstDate(smallerChunks: TBasicChunk[]): void {
        smallerChunks.sort((a, b) => a.FirstDate - b.FirstDate)
    }

    public static IsBiggerChunkCoveredBySmallerChunks(chunk: TBasicChunk, smallerChunks: TBasicChunk[]): boolean {
        if (smallerChunks.length === 0) {
            return false
        }

        // Sort smaller chunks by their FirstDate
        this.SortChunksByFirstDate(smallerChunks)

        // Initialize the coverage range to start from the bigger chunk's first date
        const currentCoverageStart = smallerChunks[0].FirstDate

        if (currentCoverageStart > chunk.FirstDate) {
            return false
        }

        let currentCoverageEnd = smallerChunks[0].LastPossibleDate

        for (const smallerChunk of smallerChunks) {
            if (smallerChunk.FirstDate > currentCoverageEnd) {
                // There's a gap between the current coverage end and the next smaller chunk's start
                return false
            }
            // Extend the current coverage end
            currentCoverageEnd = Math.max(currentCoverageEnd, smallerChunk.LastPossibleDate)
            if (DateUtils.MoreOrEqual(currentCoverageEnd, chunk.LastPossibleDate)) {
                // If the coverage end is greater than or equal to the bigger chunk's end, we're done
                return true
            }
        }

        // After iterating, check if we've covered the entire range of the bigger chunk
        return DateUtils.MoreOrEqual(currentCoverageEnd, chunk.LastPossibleDate)
    }

    public static GetBiggestRelevantDownloadableTFValue(chunk: TBasicChunk): number {
        const sortedStandardTFs = [...CommonConstants.TIMEFRAMES_DOWNLOADABLE_FROM_SERVER].sort((a, b) => {
            return b - a
        })
        for (const standardTF of sortedStandardTFs) {
            if (
                standardTF < chunk.DataDescriptor.timeframe &&
                TimeframeUtils.CanBeBuiltFrom(chunk.DataDescriptor.timeframe, standardTF)
            ) {
                return standardTF
            }
        }

        if (CommonUtils.IsInUnitTest) {
            return 1
        } else {
            //this should never happen since we always have M1 as the smallest TF (except for unit tests)
            throw new StrangeError('Cannot find the biggest relevant standard TF, maybe we are trying to build M1?')
        }
    }

    public static AreAllChunksLoaded(chunks: TBarChunk[]): boolean {
        for (const chunk of chunks) {
            if (chunk.Status !== TChunkStatus.cs_Loaded) {
                return false
            }
        }
        return true
    }

    public static GetSmallerStandardActiveBarsArray(chunk: TBarChunk): IFMBarsArray | undefined {
        if (chunk.DataDescriptor.timeframe === 1) {
            throw new StrangeError('We should not be here searching smaller than M1 bars array')
        }

        const smallerStandardTFValue = this.GetBiggestRelevantDownloadableTFValue(chunk)
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(chunk.DataDescriptor.symbolName)
        const activeBarArrays = symbol.GetActiveBarArrays()

        for (const activeBarsArray of activeBarArrays) {
            if (activeBarsArray.DataDescriptor.timeframe === smallerStandardTFValue) {
                return activeBarsArray
            }
        }
        return undefined
    }
}
