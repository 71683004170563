import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { CREATE_NEW_PASSWORD_ROUTE, FORGOT_PASSWORD_ROUTE, makeRoute } from '@root/constants/routes'
import { fireMixpanelEvent, requestCode } from '@root/utils/api'
import { Button, Flex, InputField, Typography, Link } from '@fto/ui'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import MessageInformer from '@root/components/MessageInformer'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'

type ResetDataType = {
    code: string
    email: string
}

const ResetPassword = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isResendNotificationActive, setResendNotificationActive] = useState(false)

    useEffect(() => {
        if (isResendNotificationActive) {
            const timer = setTimeout(() => {
                setResendNotificationActive(false)
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [isResendNotificationActive])

    const resendEmail = useCallback(() => {
        setResendNotificationActive(true)

        requestCode({
            email,
            type: 'forgotpassword'
        }).catch((err) => {
            if (err instanceof AxiosError) {
                setError(err.response?.data?.status)
            }

            if (err.status) {
                setError(err.status)
            }
        })
    }, [email])

    const parseUrl = useCallback(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const hash = queryParameters.get('hash')
        const code = queryParameters.get('code')
        if (hash) {
            const email = window.atob(hash)
            setEmail(email)
            if (code) {
                setCode(code)
                const hash = window.btoa(email)

                navigate(`${makeRoute(CREATE_NEW_PASSWORD_ROUTE)}?hash=${hash}&code=${code}`)

                return
            }
            return
        }
        navigate(makeRoute(FORGOT_PASSWORD_ROUTE))
    }, [])

    const isSubmitDisabled = useMemo(() => {
        return code.length === 0
    }, [code])

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'reset_password' })
        parseUrl()
    }, [])

    const next = useCallback(() => {
        const hash = window.btoa(email)
        navigate(`${makeRoute(CREATE_NEW_PASSWORD_ROUTE)}?hash=${hash}&code=${code}`)
    }, [email, code])

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h4-semibold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.resetTitle')}
                        </Typography>
                        <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            <Trans
                                i18nKey='auth.resetText'
                                components={{
                                    emailLabel: <Typography type={'text-bold'}>{email}</Typography>
                                }}
                            />
                        </Typography>
                    </Flex>
                    <form>
                        <Flex direction={'column'} gap={24}>
                            <InputField
                                block
                                type={'text'}
                                value={code}
                                onChange={setCode}
                                label={t('auth.code')}
                                placeholder={t('auth.codeEmail')}
                            />
                            <MessageInformer type='error' isShown={!!error} onClose={() => setError(null)} disappearing>
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    {/*@ts-ignore*/}
                                    {t(statusMessages[error])}
                                </Typography>
                            </MessageInformer>
                            <Button
                                loading={isLoading}
                                type={'marketing'}
                                disabled={isSubmitDisabled}
                                block
                                onClick={next}
                                label={t('global.continue')}
                            />
                        </Flex>
                    </form>
                    <Typography type={'text-medium'} align={'center'}>
                        {t('auth.resetHelperText')}
                    </Typography>
                    <Flex justifyContent={'center'} alignItems='center' gap={8}>
                        <Typography type={'interface-regular'}>{t('auth.didNotReceive')}</Typography>
                        <Link onClick={resendEmail}>
                            <Typography type='interface-regular' color={'primary-500'}>
                                {t('auth.resendEmail')}
                            </Typography>
                        </Link>
                    </Flex>
                    <MessageInformer
                        type='success'
                        isShown={isResendNotificationActive}
                        onClose={setResendNotificationActive}
                        disappearing
                    >
                        <Typography type='interface-medium' color='green-600' align='center' block>
                            {t('auth.notifications.resendCode')}
                        </Typography>
                    </MessageInformer>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default ResetPassword
