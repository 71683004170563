import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class WPR extends IndicatorImplementation {
    period: TOptValue_number = new TOptValue_number(14)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    WPRBuffer!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.williamsPercentRange'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.SetFixedMinMaxValues(-100, 0)
        this.api.SetEmptyValue(10)
        this.api.AddLevel(-20, TPenStyle.psDot, 1, '#808080', 1)
        this.api.AddLevel(-80, TPenStyle.psDot, 1, '#808080', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, INT_MAX)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.WPRBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.WPRBuffer)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FF0000')
        this.api.SetIndexLabel(0, t('indicatorModal.williamsPercentRange.fields.percentR'))
    }

    Calculate(index: number): void {
        const dMaxHigh: number = this.HighestLowest(0, index)
        const dMinLow: number = this.HighestLowest(1, index)

        if (dMaxHigh - dMinLow === 0) {
            this.WPRBuffer.setValue(index, 0)
        } else {
            this.WPRBuffer.setValue(
                index,
                (-100 * (dMaxHigh - this.api.GetPrice(index, this.ApplyToPrice.value))) / (dMaxHigh - dMinLow)
            )
        }
    }

    HighestLowest(mode: number, index: number): number {
        let result: number = mode === 0 ? this.api.High(index) : this.api.Low(index)

        for (let i = 1; i <= this.period.value - 1; i++) {
            const value: number = mode === 0 ? this.api.High(index + i) : this.api.Low(index + i)
            if ((mode === 0 && value > result) || (mode !== 0 && value < result)) {
                result = value
            }
        }

        return result
    }
}
