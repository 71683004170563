import { t } from 'i18next'
import { TIndexBuffer } from '../api/IIndicatorApi'

import { IndicatorImplementation } from '../api/IndicatorImplementation'
import {
    TMAType,
    TOptionType,
    TOptValue_number,
    TOutputWindow,
    TPriceType
} from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class Alligator extends IndicatorImplementation {
    public JawsPeriod: TOptValue_number = new TOptValue_number(13)
    public JawsShift: TOptValue_number = new TOptValue_number(8)
    public TeethPeriod: TOptValue_number = new TOptValue_number(8)
    public TeethShift: TOptValue_number = new TOptValue_number(5)
    public LipsPeriod: TOptValue_number = new TOptValue_number(5)
    public LipsShift: TOptValue_number = new TOptValue_number(3)
    public MAType: TOptValue_number = new TOptValue_number(TMAType.ma_SSMA)
    public ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_HL2)

    public Jaws!: TIndexBuffer
    public Teeth!: TIndexBuffer
    public Lips!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.alligator'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)

        this.api.AddSeparator('Common')

        this.api.RegMATypeOption(this.MAType)
        this.api.RegApplyToPriceOption(this.ApplyToPrice, t('indicatorModal.general.applyToPrice'))

        this.api.RegOption(t('indicatorModal.alligator.fields.jawsPeriod'), TOptionType.ot_Integer, this.JawsPeriod)
        this.api.SetOptionRange(t('indicatorModal.alligator.fields.jawsPeriod'), 1, INT_MAX)
        this.api.RegOption(t('indicatorModal.alligator.fields.jawsShift'), TOptionType.ot_Integer, this.JawsShift)

        this.api.RegOption(t('indicatorModal.alligator.fields.teethPeriod'), TOptionType.ot_Integer, this.TeethPeriod)
        this.api.SetOptionRange(t('indicatorModal.alligator.fields.teethPeriod'), 1, INT_MAX)
        this.api.RegOption(t('indicatorModal.alligator.fields.teethShift'), TOptionType.ot_Integer, this.TeethShift)

        this.api.RegOption(t('indicatorModal.alligator.fields.lipsPeriod'), TOptionType.ot_Integer, this.LipsPeriod)
        this.api.SetOptionRange(t('indicatorModal.alligator.fields.lipsPeriod'), 1, INT_MAX)
        this.api.RegOption(t('indicatorModal.alligator.fields.lipsShift'), TOptionType.ot_Integer, this.LipsShift)

        this.Jaws = this.api.CreateIndexBuffer()
        this.Teeth = this.api.CreateIndexBuffer()
        this.Lips = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(3)
        this.api.SetIndexBuffer(0, this.Jaws)
        this.api.SetIndexLabel(0, t('indicatorModal.alligator.fields.jaws'))
        this.api.SetIndexBuffer(1, this.Teeth)
        this.api.SetIndexLabel(1, t('indicatorModal.alligator.fields.teeth'))
        this.api.SetIndexBuffer(2, this.Lips)
        this.api.SetIndexLabel(2, t('indicatorModal.alligator.fields.lips'))

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#0000FF')
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FF0000')
        this.api.SetIndexStyle(2, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#00FF00')
    }

    Calculate(index: number): void {
        this.Calc(index, this.JawsPeriod.value, this.Jaws)
        this.Calc(index, this.TeethPeriod.value, this.Teeth)
        this.Calc(index, this.LipsPeriod.value, this.Lips)
    }

    private Calc(index: number, period: number, buffer: TIndexBuffer): void {
        buffer.setValue(
            index,
            this.api.GetMA(index, 0, period, this.MAType.value, this.ApplyToPrice.value, buffer.getValue(index + 1))
        )
    }

    OnParamsChange() {
        this.api.SetBufferShift(0, this.JawsShift.value)
        this.api.SetBufferShift(1, this.TeethShift.value)
        this.api.SetBufferShift(2, this.LipsShift.value)
    }
}
