import styles from '@root/pages/Dashboard/components/TableSearchBar/table.search-bar.module.scss'
import { Icon } from '@fto/icons'
import { addModal, Button, Flex, Typography } from '@fto/ui'
import React, { FC } from 'react'
import classNames from 'classnames'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useTranslation } from 'react-i18next'

type Props = React.HTMLAttributes<HTMLButtonElement>

const CreateNewProjectButton: FC<Props> = ({ className }) => {
    const { t } = useTranslation()
    const handleCreate = () => {
        addModal(MODAL_NAMES.projects.create)
    }

    return (
        <Button
            onClick={handleCreate}
            classNames={{
                root: classNames(className, styles.newProjectButton),
                content: styles.newProjectButtonContent
            }}
            type={'primary'}
            label={
                <Flex alignItems='center' gap={8}>
                    <Icon size={14} color={'white'} name={'plus'} />
                    <Typography color='white' type='interface-medium'>
                        {t('dashboard.newProject')}
                    </Typography>
                </Flex>
            }
            size='default'
        />
    )
}

export default CreateNewProjectButton
