type ErrorKey = string

/**
 * Manages errors for different controls in the application.
 */
export class ErrorManager {
    private errors: Map<string, { key: ErrorKey; value: string }> = new Map()

    /**
     * Add an error to the specified control.
     * @param error The error key.
     * @param control The control identifier.
     */
    public addError(error: ErrorKey, control: string, newValue = ''): void {
        this.errors.set(control, { key: error, value: newValue })
    }

    /**
     * Returns all the errors.
     * @returns A map of control identifiers and their corresponding error descriptions.
     */
    public getErrors(): Map<string, { key: ErrorKey; value: string }> {
        return this.errors
    }

    /**
     * Returns the errors for the specified control.
     * @param control The control identifier.
     * @returns An array of error descriptions for the control.
     */
    public getErrorForControl(control: string): { key: ErrorKey; value: string } {
        return this.errors.get(control) ?? { key: '', value: '' }
    }

    /**
     * Checks if there are errors for the specified control.
     * @param control The control identifier.
     * @returns True if there are errors for the control, false otherwise.
     */
    public hasErrorsForControl(control: string): boolean {
        return this.errors.has(control)
    }

    /**
     * Deletes all the errors for the specified control.
     * @param control The control identifier.
     */
    public deleteErrorsForControl(control: string): void {
        this.errors.delete(control)
    }
}
