export enum TCursor {
    crDefault = 'default',
    crNone = 'none',
    crArrow = 'default',
    crCross = 'crosshair',
    crIBeam = 'text',
    crSize = 'all-scroll',
    crSizeNESW = 'nesw-resize',
    crSizeNS = 'ns-resize',
    crSizeNWSE = 'nwse-resize',
    crSizeWE = 'ew-resize',
    crUpArrow = 'n-resize',
    crHourGlass = 'wait',
    crDrag = 'grab',
    crNoDrop = 'no-drop',
    crHSplit = 'row-resize',
    crVSplit = 'col-resize',
    crMultiDrag = 'move',
    crSQLWait = 'progress',
    crNo = 'not-allowed',
    crAppStart = 'progress',
    crHelp = 'help',
    crHandPoint = 'pointer',
    crSizeAll = 'move',
    crGrabbing = 'grabbing'
    // Add more if there are any specific custom cursors in your Delphi application
}

export type TCustomCursor = {
    path: string
    hotspotX: number
    hotspotY: number
}

export class CustomCursorPointers {
    public static readonly crCursorVLine: TCustomCursor = {
        path: window.location.origin + '/icons/charting/cursors/CreateVLine.svg',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorHLine: TCustomCursor = {
        path: window.location.origin + '/icons/charting/cursors/CreateHLine.svg',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorTrendLine: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorRay: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorPolyline: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorVArrow: TCustomCursor = {
        path: '',
        hotspotX: 2,
        hotspotY: 7
    }
    public static readonly crCursorFiboRetr: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorFiboArc: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorFiboTime: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorFiboFan: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorAndrewsPitchfork: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorDropper: TCustomCursor = {
        path: '',
        hotspotX: 4,
        hotspotY: 17
    }
    public static readonly crCursorTextLabel: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorRectangle: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorEllipse: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorTriangle: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorFiboExtension: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorFiboChannel: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 9
    }
    public static readonly crCursorOrderMove: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 8
    }
    public static readonly crCursorRiskReward: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 8
    }
    public static readonly crCursorResize: TCustomCursor = {
        path: '',
        hotspotX: 0,
        hotspotY: 0
    }
    public static readonly crCross: TCustomCursor = {
        path: '',
        hotspotX: 8,
        hotspotY: 8
    }
    public static readonly crPicker: TCustomCursor = {
        path: window.location.origin + '/icons/charting/cursors/picker.svg',
        hotspotX: 8,
        hotspotY: 9
    }

    // Assuming TCustomCursor has a unique property like 'path'
    public static isCustomCursor(value: any): value is TCustomCursor {
        return value && typeof value === 'object' && 'path' in value
    }

    static setCursor(element: HTMLElement, cursor: TCustomCursor): void {
        element.style.cursor = `url('${cursor.path}') ${cursor.hotspotX} ${cursor.hotspotY}, auto`
    }

    static clearCursor(element: HTMLElement): void {
        element.style.cursor = 'default'
    }
}
