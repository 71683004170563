import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Icon } from '@fto/icons'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'
import { Button, Flex, HoverElement, Tooltip, TriggerOverlay, Typography } from '@fto/ui'
import { CircularProgress } from '@mui/material'
import { showSuccessToast } from '@root/utils/toasts'
import ProjectStore from '@fto/lib/store/projectStore'

import { getLastSaveTime, getMinutesSinceLastSave } from './helpers'

import styles from './index.module.scss'

const SaveProjectButton: FC = observer(() => {
    const { saveState } = ProjectStore.data
    const isSaving = saveState === 'saving'
    const isSaveError = saveState === 'error'

    const { t } = useTranslation()

    const saveProject = useCallback((close: () => void) => {
        SyncProjectManager.Instance.SaveProject()
            .then(() => {
                close()
                showSuccessToast({ title: t('ribbon.projectSave.success') })
            })
            .catch(() => {
                close()
            })
    }, [])

    const minutesAgo = getMinutesSinceLastSave()

    return (
        <TriggerOverlay
            optionsRenderer={(close) => (
                <Flex direction='column' className={styles.container} gap={4}>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t('ribbon.projectSave.header')}
                    </Typography>
                    <Flex justifyContent='space-between'>
                        <Typography color='gray-800' type='subtext-regular'>
                            {t('ribbon.projectSave.lastSaving')}
                        </Typography>
                        <Typography color='gray-800' type='subtext-regular'>
                            {getLastSaveTime()}
                        </Typography>
                    </Flex>
                    <Button
                        label={isSaving ? t('ribbon.projectSave.saving') : t('ribbon.projectSave.save')}
                        onClick={() => saveProject(close)}
                        block
                        classNames={{ root: styles.button }}
                        type='tetriatry-white'
                        size='compact'
                        disabled={isSaving}
                    />
                </Flex>
            )}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Tooltip
                content={
                    minutesAgo > 0
                        ? t('ribbon.tooltips.lastSaved', { value: getMinutesSinceLastSave() })
                        : t('ribbon.tooltips.savedLessThatAMinuteAgo')
                }
            >
                <HoverElement size={32}>
                    {isSaving ? (
                        <CircularProgress color='inherit' className={styles.loader} size={18} />
                    ) : isSaveError ? (
                        <Icon name='cloud-error' size={20} />
                    ) : (
                        <Icon name='cloud-ok' size={20} />
                    )}
                </HoverElement>
            </Tooltip>
        </TriggerOverlay>
    )
})

export default SaveProjectButton
