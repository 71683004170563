export const REASONS = {
    priceToHigh: 'price',
    noNeed: 'noNeed',
    notSatisfied: 'noSatisfaction',
    switch: 'switch',
    other: 'other'
} as const

export const PERIODS = {
    month: 'month',
    year: 'year',
    lifetime: 'lifetime'
} as const

export const NOT_SATISFIED_REASONS = {
    toBaggy: 'toBaggy',
    missingFeatures: 'missingFeatures',
    tooComplicated: 'tooComplicated',
    other: 'other'
} as const

export const PAY_PRO_REASONS_IDS = {
    [REASONS.priceToHigh]: 1,
    [REASONS.noNeed]: 2,
    [REASONS.notSatisfied]: 3,
    [REASONS.switch]: 4,
    [REASONS.other]: 5
}
