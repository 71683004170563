import React, { useCallback, useEffect, useState } from 'react'
import { ResizeCallback } from 're-resizable'
import styles from '../index.module.scss'
import { usePersistedState } from '@root/hooks/usePersistedState'
import { UserTerminalHeight } from '@root/utils/localStorage'
import { useDebounceHook } from '@root/hooks/useDebounce'

export const MIN_CLOSED_TERMINAL_HEIGHT = 50
export const STANDART_TERMINAL_HEIGHT = 176
export const MAX_RELATIVE_PERCENTS_TERMINAL_HEIGHT = 0.7 // 70%

export type TerminalSizes = {
    x: number
    y: number
    width: string
    height: number
}

type Props = (props: {
    terminalWindowRef: React.RefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    settingsKey: string
}) => {
    onResize: ResizeCallback
    onResizeStop: ResizeCallback
    terminalSize: TerminalSizes
    terminalMinHeight: number
}

export const terminalInitialState: TerminalSizes = {
    x: 0,
    y: 0,
    width: '100%',
    height: 50
}

const toggleClass = (ref: HTMLElement, className: string, condition: boolean) => {
    if (!ref) return
    if (condition) {
        ref.classList.add(className)
    } else {
        ref.classList.remove(className)
    }
}

const terminalResizingHook: Props = ({ terminalWindowRef, isOpen, setIsOpen, settingsKey }) => {
    const [terminalMinHeight, setTerminalMinHeight] = useState(MIN_CLOSED_TERMINAL_HEIGHT)
    const [terminalSize, setTerminalSize] = useState<TerminalSizes>(terminalInitialState)
    const [terminalStorageHeight, setTerminalStorageHeight] = usePersistedState<UserTerminalHeight>(settingsKey, null)
    const [moveDirection, setMoveDirection] = useState<'up' | 'down' | null>(null)
    const debounce = useDebounceHook(terminalSize, 300)

    const TERMINAL_CONTAINER = terminalWindowRef.current as HTMLElement
    const CHARTS_CONTAINER = TERMINAL_CONTAINER?.children[0] as HTMLElement
    const TERMINAL_CONTAINER_WRAPPER_HEIGHT = TERMINAL_CONTAINER?.clientHeight || 0
    const MAX_RELATIVE_CHART_SIZE = TERMINAL_CONTAINER_WRAPPER_HEIGHT * MAX_RELATIVE_PERCENTS_TERMINAL_HEIGHT

    const onResize: ResizeCallback = useCallback(
        (_event, _direction, ref) => {
            const e = _event as MouseEvent
            const resizedHeight = parseInt(ref.style.height)
            const direction = e.movementY > 0 ? 'down' : 'up'

            toggleClass(TERMINAL_CONTAINER, styles.FullTerminal, resizedHeight >= MAX_RELATIVE_CHART_SIZE)
            setMoveDirection(direction)

            if (resizedHeight < STANDART_TERMINAL_HEIGHT && !isOpen) {
                setTerminalSize((prev) => ({ ...prev, height: STANDART_TERMINAL_HEIGHT }))
                setIsOpen(true)
            }

            if (resizedHeight < STANDART_TERMINAL_HEIGHT && isOpen && direction === 'down') {
                setTerminalMinHeight(STANDART_TERMINAL_HEIGHT)
            }
        },
        [isOpen, terminalSize.height]
    )

    const onResizeStop: ResizeCallback = useCallback(
        (_event, _direction, ref) => {
            if (!isOpen) return
            let height = parseInt(ref.style.height)

            if (height <= STANDART_TERMINAL_HEIGHT && moveDirection === 'up') {
                height = STANDART_TERMINAL_HEIGHT
            }

            if (height >= TERMINAL_CONTAINER_WRAPPER_HEIGHT) {
                height = TERMINAL_CONTAINER_WRAPPER_HEIGHT
            }

            setTerminalSize((prev) => ({
                ...prev,
                height
            }))
        },
        [isOpen, terminalSize.height, moveDirection]
    )

    const onWindowResize = () => {}

    useEffect(() => {
        const height = terminalStorageHeight || STANDART_TERMINAL_HEIGHT

        if (isOpen) {
            setTerminalSize((prev) => ({
                ...prev,
                height
            }))
        } else {
            TERMINAL_CONTAINER?.classList.remove(styles.FullTerminalHideTopLine)
            TERMINAL_CONTAINER?.classList.remove(styles.FullTerminal)
            setTerminalMinHeight(MIN_CLOSED_TERMINAL_HEIGHT)
            setTerminalSize(terminalInitialState)
        }
    }, [isOpen])

    useEffect(() => {
        const { height } = terminalSize

        if (height > MAX_RELATIVE_CHART_SIZE && CHARTS_CONTAINER) {
            const chartSize = TERMINAL_CONTAINER_WRAPPER_HEIGHT - MAX_RELATIVE_CHART_SIZE
            CHARTS_CONTAINER.style.height = `${chartSize}px`
        }

        toggleClass(TERMINAL_CONTAINER, styles.FullTerminal, height >= MAX_RELATIVE_CHART_SIZE)
        toggleClass(TERMINAL_CONTAINER, styles.FullTerminalHideTopLine, height >= TERMINAL_CONTAINER_WRAPPER_HEIGHT)
    }, [terminalSize.height])

    // Save to local storage useEffect

    useEffect(() => {
        if (isOpen) {
            const { height } = debounce

            if (height <= STANDART_TERMINAL_HEIGHT && moveDirection === 'up') {
                return
            }
            setTerminalStorageHeight(height)
        }
    }, [debounce, moveDirection])

    useEffect(() => {
        window.addEventListener('resize', onWindowResize)
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    return { terminalSize, terminalMinHeight, onResize, onResizeStop }
}

export default terminalResizingHook
