import { ImageControl } from '@fto/chart_components/ImageControl'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'


export class IndicatorCollapseButton extends ImageControl {
    private _isCollapsed: boolean = false
    private imageCollapsed!: HTMLImageElement
    private imageExpanded!: HTMLImageElement
    private font = new IGPFont(new TGPFontFamily('Roboto Flex'), 14, [])
    private text: string = ''
    private textBrush = new IGPSolidBrush('#333333')

    constructor(chartControlParams: any, collapsedImage: HTMLImageElement, expandedImage: HTMLImageElement) {
        super(chartControlParams, collapsedImage)
        this.imageExpanded = expandedImage
        this.imageCollapsed = collapsedImage
    }

    public setText(text: string): void {
        this.text = text
    }

    collapse() {
        this._isCollapsed = true
        this.image = this.imageExpanded
    }

    expand() {
        this._isCollapsed = false
        this.image = this.imageCollapsed
    }

    switch(): boolean {
        if (this._isCollapsed) {
            this.expand()
        } else {
            this.collapse()
        }
        return this._isCollapsed
    }

    isCollapsed(): boolean {
        return this._isCollapsed
    }

    draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return;
        }

        const location = this.getLocation();

        if (this.isCollapsed() && this.text) {
            const textX = location.Left + 24 + 4;
            const textWidth = canvas.TextWidth(this.text, this.font);
            location.Right = textX + textWidth + 8;
            this.setLocation(location);
        } else {
            location.Right = location.Left + 24;
            this.setLocation(location);
        }

        if (this.isNeedDrawBorder) {
            canvas.strokeRect(location, TRoundRectType.BOTH, 5, true, this.pen, this.brush);
        }

        if (this.isMouseInside()) {
            canvas.FillRect(location, this.brushHovered);
            if (this.tooltip) {
                this.showTooltip(canvas);
            }
        }

        const centerX = location.Left + 12
        const centerY = location.Top + 12
        const imageX = centerX - this.image.width / 2
        const imageY = centerY - this.image.height / 2

        canvas.graphics.Context.drawImage(this.image, imageX, imageY, this.image.width, this.image.height);

        if (this.isCollapsed() && this.text) {
            const textX = location.Left + 24 + 4;
            const textY = location.Top + 17;
            canvas.graphics.drawString(this.text, this.font, this.textBrush, textX, textY);
        }
    }

}
