import { Icon } from '@fto/icons'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { Flex, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const SuccessModal: FC = () => {
    const { t } = useTranslation()

    const restart = useCallback(() => {
        EducationProcessor.Instance.restartHomework()
        removeModal(MODAL_NAMES.chart.education.success)
    }, [])

    return (
        <Modal size='xs' name={MODAL_NAMES.chart.education.success}>
            <Modal.Content>
                <Flex alignItems='center' direction='column' gap={16}>
                    <Icon size={56} name='success-colored' />
                    <Flex alignItems='center' direction='column' gap={8}>
                        <Typography type='h5-semibold'>{t('educationModals.success.header')}</Typography>
                        <Typography type='interface-regular'>{t('educationModals.success.text')}</Typography>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                cancelText={t('educationModals.success.restart')}
                onClose={restart}
                submitText={t('educationModals.success.submitText')}
                onSubmit={() => removeModal(MODAL_NAMES.chart.education.success)}
            />
        </Modal>
    )
}
