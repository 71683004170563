export class GraphicalUtils {
    /**
     * Aligns the given value to the center of the pixel to ensure consistent line thickness.
     * Using integer coordinates draws from the top-left corner of the pixel, causing anti-aliasing
     * and making lines appear blurry. Aligning to the center of the pixel avoids this issue.
     * @param value The original coordinate value.
     * @returns The adjusted coordinate aligned to the pixel center.
     */
    static alignToPixel(value: number): number {
        return Math.round(value) + 0.5
    }
}
