import React, { FC } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import store from '@root/store/index'
import Login from '@root/pages/Auth/components/Login/Login'
import Register from '@root/pages/Auth/components/Register/Register'
import { unSecureRouteLoader, secureRouteLoader, secureBaseRouteLoader } from '@root/utils/auth'
import Dashboard from '@root/pages/Dashboard/Dashboard'
import PageNotFound from '@root/pages/PageNotFound/PageNotFound'
import ResetPassword from '@root/pages/Auth/components/ResetPassword/ResetPassword'
import ForgotPassword from '@root/pages/Auth/components/ForgotPassword/ForgotPassword'
import ChartPage from '@root/pages/ChartPage'
import Layout from '@root/components/Layout/Layout'
import {
    AUTH_ROUTE,
    BASE_ROUTE,
    DASHBOARD_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE,
    CONFIRM_EMAIL_ROUTE,
    PAGE_NOT_FOUND_ROUTE,
    SIGN_UP_ROUTE,
    LOGIN_ROUTE,
    CREATE_NEW_PASSWORD_ROUTE,
    CHECKOUT_PAGE,
    USER_PROFILE_ROUTE,
    BLOCKED_ROUTE,
    SUCCESS_ROUTE,
    FAILED_PAYMENT,
    makeRoute,
    BACK_TESTING_ROUTE,
    GOOGLE_ROUTE
} from '@root/constants/routes'
import UserProfile from '@root/pages/UserProfile/UserProfile'
import EmailConfirmation from '@root/pages/Auth/components/EmailConfirmation/EmailConfirmation'
import CheckoutPage from '@root/pages/CheckoutPage'
import CreatePassword from '@root/pages/Auth/components/CreatePassword/CreatePassword'
import { Provider } from 'react-redux'
import { Blocked } from '@root/pages/Blocked/Blocked'
import { SuccessPage } from '@root/pages/payment/SuccessPage/SuccessPage'
import FailedPayment from '@root/pages/payment/FailedPayment'
import AppLoader from '@root/components/AppLoader'
import { GoogleAuth } from '@root/pages/Auth/components/Social/Google'
import { GoogleRedirectPage } from '@root/pages/Auth/components/Social/Google/redirectPage'

const routes = [
    {
        path: BASE_ROUTE,
        element: <Layout />,
        loader: secureBaseRouteLoader,
        children: [
            {
                path: AUTH_ROUTE,
                children: [
                    {
                        path: makeRoute(LOGIN_ROUTE),
                        element: <Login />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(SIGN_UP_ROUTE),
                        element: <Register />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(FORGOT_PASSWORD_ROUTE),
                        element: <ForgotPassword />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(CONFIRM_EMAIL_ROUTE),
                        element: <EmailConfirmation />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(RESET_PASSWORD_ROUTE),
                        element: <ResetPassword />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(CREATE_NEW_PASSWORD_ROUTE),
                        element: <CreatePassword />,
                        loader: unSecureRouteLoader
                    },
                    {
                        path: makeRoute(GOOGLE_ROUTE),
                        element: <GoogleRedirectPage />,
                        loader: unSecureRouteLoader
                    }
                ]
            },
            {
                path: DASHBOARD_ROUTE,
                loader: secureRouteLoader,
                element: <Dashboard />
            },
            {
                path: CHECKOUT_PAGE,
                element: <CheckoutPage />
            },
            {
                path: makeRoute(USER_PROFILE_ROUTE),
                element: <UserProfile />,
                loader: unSecureRouteLoader
            },
            {
                path: BACK_TESTING_ROUTE,
                loader: secureRouteLoader,
                element: <ChartPage />
            },
            {
                path: PAGE_NOT_FOUND_ROUTE,
                element: <PageNotFound />
            },
            {
                path: BLOCKED_ROUTE,
                element: <Blocked />
            },
            {
                path: SUCCESS_ROUTE,
                element: <SuccessPage />
            },
            {
                path: FAILED_PAYMENT,
                element: <FailedPayment />
            }
        ]
    }
]
const router = createBrowserRouter(routes)

const App: FC = () => {
    return (
        <Provider store={store}>
            <RouterProvider router={router} fallbackElement={<AppLoader size={64} />} />
        </Provider>
    )
}

export default App
