import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Store } from '@root/store'
import { ADD_NEW_INDICATOR } from '@root/store/custom_indicators/indicators.slice'
import { IndicatorDescriptor } from '@fto/lib/extension_modules/indicators/IndicatorDescriptor'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TChartType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { IndicatorImplementation } from '../extension_modules/indicators/api/IndicatorImplementation'
import { TRuntimeIndicator } from '../extension_modules/indicators/RuntimeIndicator'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'

class UserIndicatorData {
    public tIndicatorDescriptor!: IndicatorDescriptor
    public userIndicator!: IndicatorImplementation
    public indicatorImplementationConstructor!: new () => IndicatorImplementation
}

export class GlobalCustomIndicatorsManager {
    private static instance: GlobalCustomIndicatorsManager
    private _customIndicators: Map<string, UserIndicatorData> = new Map<string, UserIndicatorData>()
    private _customIndicatorPrefix = 'user_custom_indicator_'
    private static indicatorsLoaded = false
    private loadPromise: Promise<void> | null = null

    constructor(private store: Store) {}

    public static getInstance(store: Store): GlobalCustomIndicatorsManager {
        if (!GlobalCustomIndicatorsManager.instance) {
            GlobalCustomIndicatorsManager.instance = new GlobalCustomIndicatorsManager(store)
        }

        return GlobalCustomIndicatorsManager.instance
    }

    public static isIndicatorsLoaded(): boolean {
        return GlobalCustomIndicatorsManager.indicatorsLoaded
    }

    public async loadIndicatorsFromStore(): Promise<void> {
        if (this.loadPromise) {
            return this.loadPromise
        }

        this.loadPromise = (async () => {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                if (key && key.startsWith(this._customIndicatorPrefix)) {
                    const storedData = localStorage.getItem(key)
                    if (storedData) {
                        try {
                            const parsedData: { code: string; name: string } = JSON.parse(storedData)
                            const userIndicatorData = await this.loadIndicator(parsedData.code, key)

                            this._customIndicators.set(key, userIndicatorData)

                            this.store.dispatch(
                                ADD_NEW_INDICATOR({ filename: key, code: parsedData.code, name: parsedData.name })
                            )
                        } catch (error) {
                            throw new StrangeError(`Error loading indicator with key ${key}: ${error}`)
                        }
                    }
                }
            }
            GlobalCustomIndicatorsManager.indicatorsLoaded = true
        })()

        return this.loadPromise
    }

    public createRuntimeIndicator(
        indicator_descriptor: IndicatorDescriptor,
        symbol: TSymbolData,
        timeframe: number,
        ChartType: TChartType
    ): TRuntimeIndicator {
        let indicatorData: UserIndicatorData | undefined = undefined
        for (const [key, value] of this._customIndicators) {
            if (value.tIndicatorDescriptor.IsEqual(indicator_descriptor)) {
                indicatorData = value
                break
            }
        }

        if (indicatorData) {
            const indicatorImplementation = new indicatorData.indicatorImplementationConstructor()
            return new TRuntimeIndicator(indicator_descriptor, indicatorImplementation, symbol, timeframe, ChartType)
        } else {
            throw new StrangeError(`Indicator with descriptor ${indicator_descriptor} not found`)
        }
    }

    public getIndicatorDescriptor(indicatorShortName: string, indicatorFileName: string): IndicatorDescriptor {
        const indicatorData = this._customIndicators.get(indicatorFileName)
        if (!indicatorData) {
            showErrorToast({
                title: 'Load indicator error',
                message: `Indicator file not found`
            })
            throw new Error(`Indicator file not found`)
        }

        return new IndicatorDescriptor(indicatorFileName, indicatorShortName, '', true)
    }

    public async installUserIndicator(code: string, filename: string): Promise<IndicatorImplementation> {
        try {
            const localStoreKey = this._customIndicatorPrefix + filename
            const existingIndicator = localStorage.getItem(localStoreKey)

            const userConfirmed = existingIndicator
                ? window.confirm(`File "${filename}" already exists. Do you want to overwrite it?`)
                : true

            if (!userConfirmed) {
                showErrorToast({
                    title: 'Load indicator warning',
                    message: `User canceled the overwrite operation.`
                })
                throw new Error('User canceled the overwrite operation')
            }

            const userIndicatorData = await this.loadIndicator(code, localStoreKey)
            const { userIndicator, tIndicatorDescriptor } = userIndicatorData

            this._customIndicators.set(localStoreKey, userIndicatorData)
            this.store.dispatch(
                ADD_NEW_INDICATOR({ filename: localStoreKey, code, name: tIndicatorDescriptor.shortName })
            )

            const value = { code, name: tIndicatorDescriptor.shortName }
            localStorage.setItem(localStoreKey, JSON.stringify(value))
            showSuccessToast({
                title: 'Load indicator',
                message: `User indicator successfully loaded.`
            })

            return userIndicator
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to load user indicator:', error)
            throw error
        }
    }

    private async loadIndicator(code: string, filename: string): Promise<UserIndicatorData> {
        const blob = new Blob([code], { type: 'application/javascript' })
        const moduleUrl = URL.createObjectURL(blob)

        try {
            const module = await import(/* webpackIgnore: true */ moduleUrl)
            if (module && module.default) {
                const userIndicatorConstructor = module.default as new () => IndicatorImplementation
                const indicatorImplementation = new userIndicatorConstructor()

                const activeChart = GlobalChartsController.Instance.getActiveChart()
                const tempIndicatorDescriptor = new IndicatorDescriptor(filename, 'Custom Indicator', '', true)
                if (activeChart) {
                    //we create it here just to call Init and to check if it works at all
                    //after the Init we will destroy it
                    const ind = new TRuntimeIndicator(
                        tempIndicatorDescriptor,
                        indicatorImplementation,
                        activeChart.SymbolData,
                        activeChart.ChartOptions.Timeframe,
                        activeChart._chartType
                    )
                    ind.Init()

                    //this will probably have correct name
                    const updatedDescriptor = ind.indicatorDescriptor.clone()

                    const userIndicatorData: UserIndicatorData = {
                        tIndicatorDescriptor: updatedDescriptor,
                        userIndicator: indicatorImplementation,
                        indicatorImplementationConstructor: userIndicatorConstructor
                    }
                    return userIndicatorData
                } else {
                    // showErrorToast({
                    //     title: 'Load indicator fail',
                    //     message: `No active chart available while loading user indicator.`
                    // })
                    throw new StrangeError('No active chart available.')
                }
            } else {
                // showErrorToast({
                //     title: 'Load indicator fail',
                //     message: `Invalid module: Does not export a valid UserIndicator class`
                // })
                throw new StrangeError('Invalid module: Does not export a valid UserIndicator class')
            }
        } catch (error) {
            // showErrorToast({
            //     title: 'Load indicator fail',
            //     message: `Failed to load user indicator. Please check the code and try again`
            // })
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to load user indicator:', error)
            throw error
        } finally {
            URL.revokeObjectURL(moduleUrl)
        }
    }
}
