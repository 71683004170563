import { TIndexBuffer } from '@fto/lib/extension_modules/indicators/api/IIndicatorApi'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { t } from 'i18next'
import { TMAType, TOptionType, TOptValue_number, TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'
import { IndicatorImplementation } from '../api/IndicatorImplementation'

export default class AC extends IndicatorImplementation {
    PeriodF: TOptValue_number = new TOptValue_number(5)
    PeriodS: TOptValue_number = new TOptValue_number(34)
    PeriodSAC: TOptValue_number = new TOptValue_number(5)
    MAtype: TOptValue_number = new TOptValue_number(TMAType.ma_SMA)
    ApplyToPrice: TOptValue_number = new TOptValue_number(0)

    //Buffers
    SMA!: TIndexBuffer
    SMA1!: TIndexBuffer
    SMA2!: TIndexBuffer
    SMAF!: TIndexBuffer
    SMAS!: TIndexBuffer
    SMAAC!: TIndexBuffer

    Init() {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.ac'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.ac.fields.periodF'), TOptionType.ot_Integer, this.PeriodF)
        this.api.SetOptionRange(t('indicatorModal.ac.fields.periodF'), 1, INT_MAX)

        this.api.RegOption(t('indicatorModal.ac.fields.periodS'), TOptionType.ot_Integer, this.PeriodS)
        this.api.SetOptionRange(t('indicatorModal.ac.fields.periodS'), 1, INT_MAX)

        this.api.RegOption(t('indicatorModal.ac.fields.periodSAC'), TOptionType.ot_Integer, this.PeriodSAC)
        this.api.SetOptionRange(t('indicatorModal.ac.fields.periodSAC'), 1, INT_MAX)

        this.api.RegMATypeOption(this.MAtype)
        this.api.RegApplyToPriceOption(this.ApplyToPrice, t('indicatorModal.general.applyToPrice'))

        this.SMA = this.api.CreateIndexBuffer()
        this.SMAF = this.api.CreateIndexBuffer()
        this.SMAS = this.api.CreateIndexBuffer()
        this.SMAAC = this.api.CreateIndexBuffer()
        this.SMA1 = this.api.CreateIndexBuffer()
        this.SMA2 = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(2)
        this.api.SetIndexBuffer(0, this.SMA1)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 3, DelphiColors.clLime)
        this.api.SetIndexLabel(0, t('indicatorModal.ac.fields.ac1'))

        this.api.SetIndexBuffer(1, this.SMA2)
        this.api.SetIndexStyle(1, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 3, DelphiColors.clRed)
        this.api.SetIndexLabel(1, t('indicatorModal.ac.fields.ac2'))
    }

    Calculate(index: number) {
        if (index + this.PeriodF.value >= this.api.Bars() || index + this.PeriodS.value >= this.api.Bars()) return

        const smafValue = this.api.GetMA(index, 0, this.PeriodF.value, this.MAtype.value, this.ApplyToPrice.value)
        this.SMAF.setValue(index, smafValue)

        const smasValue = this.api.GetMA(index, 0, this.PeriodS.value, this.MAtype.value, this.ApplyToPrice.value)
        this.SMAS.setValue(index, smasValue)

        const smaValue = this.SMAF.getValue(index) - this.SMAS.getValue(index)
        this.SMA.setValue(index, smaValue)

        let sum = 0
        for (let i = index; i < index + this.PeriodSAC.value; i++) {
            sum += this.SMA.getValue(i)
        }

        this.SMAAC.setValue(index, sum / this.PeriodSAC.value)

        const current = this.SMA.getValue(index) - this.SMAAC.getValue(index)
        const prev = this.SMA.getValue(index + 1) - this.SMAAC.getValue(index + 1)

        if (current < prev) {
            this.SMA2.setValue(index, current)
            this.SMA1.setValue(index, 0)
        } else {
            this.SMA1.setValue(index, current)
            this.SMA2.setValue(index, 0)
        }
    }
}
