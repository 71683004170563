import { LANGUAGES } from '@root/constants/langs'

/**
 * Languages that is used by external services(like Crowdin)
 */

const TECHNICAL_LANGUAGES = [{ value: 'ach', name: 'CROWDIN' }]

const LANGUAGES_LIST = [
    { value: LANGUAGES['en'].value, name: 'English' },
    { value: LANGUAGES['ja'].value, name: '日本語' }, // Japanese
    { value: LANGUAGES['es-ES'].value, name: 'Español (España)' },
    { value: LANGUAGES['es-419'].value, name: 'Español (Latinoamérica)' },
    { value: LANGUAGES['de'].value, name: 'Deutsch' }, // German
    { value: LANGUAGES['ms-MY'].value, name: 'Bahasa Melayu' }, // Malaysian Malay
    { value: LANGUAGES['id-ID'].value, name: 'Bahasa Indonesia' }, // Indonesian Malay
    { value: LANGUAGES['it'].value, name: 'Italiano' }, // Italian
    { value: LANGUAGES['zh-CN'].value, name: '简体中文' }, // Simplified Chinese (Mainland China)
    { value: LANGUAGES['zh-TW'].value, name: '繁體中文 (台灣)' }, // Traditional Chinese (Taiwan)
    { value: LANGUAGES['zh-HK'].value, name: '繁體中文 (香港)' }, // Traditional Chinese (Hong Kong)
    { value: LANGUAGES['fr-FR'].value, name: 'Français (France)' },
    { value: LANGUAGES['fr-CA'].value, name: 'Français (Canada)' },
    { value: LANGUAGES['nl-NL'].value, name: 'Nederlands (Nederland)' }, // Dutch
    { value: LANGUAGES['nl-BE'].value, name: 'Nederlands (België)' }, // Flemish (Belgium)
    // Remaining languages sorted by popularity:
    { value: LANGUAGES['ru'].value, name: 'Русский' }, // Russian
    { value: LANGUAGES['hi'].value, name: 'हिन्दी' }, // Hindi
    { value: LANGUAGES['pl'].value, name: 'Polski' }, // Polish
    { value: LANGUAGES['tr'].value, name: 'Türkçe' }, // Turkish
    { value: LANGUAGES['vi'].value, name: 'Tiếng Việt' }, // Vietnamese
    { value: LANGUAGES['pt-PT'].value, name: 'Português (Portugal)' },
    { value: LANGUAGES['pt-BR'].value, name: 'Português (Brasil)' },
    { value: LANGUAGES['ko'].value, name: '한국어' }, // Korean
    { value: LANGUAGES['uk'].value, name: 'Українська' }, // Ukrainian
    { value: LANGUAGES['th'].value, name: 'ไทย' }, // Thai
    { value: LANGUAGES['ro'].value, name: 'Română' }, // Romanian
    { value: LANGUAGES['bn-IN'].value, name: 'বাংলা (ভারত)' }, // Bengali (India)
    { value: LANGUAGES['bn-BD'].value, name: 'বাংলা (বাংলাদেশ)' }, // Bengali (Bangladesh)
    { value: LANGUAGES['te'].value, name: 'తెలుగు' }, // Telugu
    { value: LANGUAGES['ta-IN'].value, name: 'தமிழ் (இந்தியா)' }, // Tamil (India)
    { value: LANGUAGES['ta-LK'].value, name: 'தமிழ் (இலங்கை)' }, // Tamil (Sri Lanka)
    { value: LANGUAGES['ar-EG'].value, name: 'العربية (مصر)' }, // Arabic (Egypt)
    { value: LANGUAGES['ar-SA'].value, name: 'العربية (السعودية)' }, // Arabic (Saudi Arabia)
    { value: LANGUAGES['ar-MA'].value, name: 'العربية (المغرب)' }, // Arabic (Morocco)
    { value: LANGUAGES['el'].value, name: 'Ελληνικά' }, // Greek
    { value: LANGUAGES['sv'].value, name: 'Svenska' }, // Swedish
    { value: LANGUAGES['he'].value, name: 'עברית' }, // Hebrew
    { value: LANGUAGES['kk-Cyrl'].value, name: 'Қазақ (Кирилл)' }, // Kazakh (Cyrillic)
    { value: LANGUAGES['kk-Latn'].value, name: 'Qazaq (Latın)' }, // Kazakh (Latin)
    { value: LANGUAGES['ur'].value, name: 'اردو' }, // Urdu
    { value: LANGUAGES['sw-KE'].value, name: 'Kiswahili (Kenya)' },
    { value: LANGUAGES['sw-TZ'].value, name: 'Kiswahili (Tanzania)' },
    { value: LANGUAGES['az'].value, name: 'Azərbaycan' }, // Azerbaijani
    { value: LANGUAGES['sr-Cyrl'].value, name: 'Српски (Ћирилица)' }, // Serbian (Cyrillic)
    { value: LANGUAGES['sr-Latn'].value, name: 'Srpski (Latinica)' }, // Serbian (Latin)
    { value: LANGUAGES['bg'].value, name: 'Български' }, // Bulgarian
    { value: LANGUAGES['hr'].value, name: 'Hrvatski' }, // Croatian
    { value: LANGUAGES['uz-Cyrl'].value, name: 'Ўзбек (Кирилл)' }, // Uzbek (Cyrillic)
    { value: LANGUAGES['uz-Latn'].value, name: 'Oʻzbek (Lotin)' }, // Uzbek (Latin)
    { value: LANGUAGES['hu'].value, name: 'Magyar' }, // Hungarian
    { value: LANGUAGES['cs'].value, name: 'Čeština' }, // Czech
    { value: LANGUAGES['fi'].value, name: 'Suomi' }, // Finnish
    { value: LANGUAGES['no'].value, name: 'Norsk' }, // Norwegian
    { value: LANGUAGES['da'].value, name: 'Dansk' }, // Danish
    { value: LANGUAGES['sk'].value, name: 'Slovenčina' }, // Slovak
    { value: LANGUAGES['tl'].value, name: 'Tagalog' }, // Tagalog (Philippines)
    { value: LANGUAGES['ky'].value, name: 'Кыргызча' }, // Kyrgyz
    { value: LANGUAGES['ka'].value, name: 'ქართული' }, // Georgian
    { value: LANGUAGES['hy'].value, name: 'Հայերեն' }, // Armenian
    { value: LANGUAGES['si'].value, name: 'සිංහල' }, // Sinhala
    { value: LANGUAGES['km'].value, name: 'ខ្មែរ' }, // Khmer
    { value: LANGUAGES['tk'].value, name: 'Türkmen' }, // Turkmen
    { value: LANGUAGES['tg'].value, name: 'Тоҷикӣ' }, // Tajik
    { value: LANGUAGES['my'].value, name: 'မြန်မာ' }, // Burmese
    { value: LANGUAGES['mn'].value, name: 'Монгол' }, // Mongolian
    { value: LANGUAGES['lv'].value, name: 'Latviešu' }, // Latvian
    { value: LANGUAGES['lt'].value, name: 'Lietuvių' }, // Lithuanian
    { value: LANGUAGES['et'].value, name: 'Eesti' }, // Estonian
    { value: LANGUAGES['is'].value, name: 'Íslenska' }, // Icelandic
    { value: LANGUAGES['sl'].value, name: 'Slovenščina' }, // Slovenian
    { value: LANGUAGES['mk'].value, name: 'Македонски' }, // Macedonian
    { value: LANGUAGES['bs'].value, name: 'Bosanski' }, // Bosnian
    { value: LANGUAGES['sq'].value, name: 'Shqip' }, // Albanian
    { value: LANGUAGES['zu'].value, name: 'IsiZulu' }, // Zulu
    { value: LANGUAGES['ig'].value, name: 'Igbo' },
    { value: LANGUAGES['yo'].value, name: 'Yorùbá' },
    { value: LANGUAGES['am'].value, name: 'አማርኛ' }, // Amharic
    { value: LANGUAGES['ha'].value, name: 'Hausa' }
]

export function getSelectableLanguages(useTechLanguages: boolean) {
    const res = [...LANGUAGES_LIST]
    if (useTechLanguages) {
        res.push(...TECHNICAL_LANGUAGES)
    }
    return res
}
