import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fto/ui'
import { Icon } from '@fto/icons'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { LAYOUTS } from '@fto/chart_components/constants/layouts'
import { fireMixpanelEvent } from '@root/utils/api'
import LayoutDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/LayoutDropdownMenu'
import { ChartInfoType } from '@fto/chart_components/types/chartTypes'

import RibbonDropdown from '../../RibbonDropdown'
import { RibbonOptionType } from '../../types'

import styles from '../../../index.module.scss'

type Props = {
    selectedChartInfo: ChartInfoType
    setSelectedChartInfo: (value: ChartInfoType) => void
}

const LayoutDropdown: FC<Props> = ({ setSelectedChartInfo, selectedChartInfo }) => {
    const { t } = useTranslation()

    const layoutOptions = useMemo<Map<number, RibbonOptionType[]>>(() => {
        const map = new Map<number, RibbonOptionType[]>()

        for (const layout of LAYOUTS) {
            if (!map.has(layout.value.count)) {
                map.set(layout.value.count, [])
            }
            map.get(layout.value.count)?.push({
                name: t(`ribbon.chartLayouts.${layout.label}`),
                value: layout.label,
                icon: layout.icon,
                action: () => handleLayoutChange(layout.value, layout.label)
            })
        }

        return map
    }, [LAYOUTS])

    const handleLayoutChange = useCallback((value: ChartInfoType, label: string) => {
        value.symbol = GlobalProjectJSONAdapter.Instance.getBaseSymbolName()

        setSelectedChartInfo(value)
        GlobalProjectJSONAdapter.Instance.setLayoutSettings(value)
        fireMixpanelEvent('multichart_added', {
            layout: value.layout,
            total_chart: value.count
        })
    }, [])

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.layouts')}
            renderer={(close) => (
                <LayoutDropdownMenu
                    selectedChartInfo={selectedChartInfo}
                    close={close}
                    options={layoutOptions}
                    activeOption={selectedChartInfo.layout}
                    name={t('ribbon.chartLayouts.chartLayout')}
                />
            )}
        >
            <Button
                classNames={{ content: styles.smallButton }}
                label={t('ribbon.chartLayouts.layouts')}
                before={<Icon name='chart-layouts' size={18} />}
                size='compact'
                type='tetriatry-white'
            />
        </RibbonDropdown>
    )
}
export default LayoutDropdown
