import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, RangeSlider, InputField } from '@fto/ui'

import styles from './index.module.scss'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

type Props = {
    value: CreateProjectModalFormData['Leverage']
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
}

const MIN_LEVERAGE = 1
const MAX_LEVERAGE = 125
const rangeValues = [MIN_LEVERAGE, 25, 50, 75, 100, MAX_LEVERAGE]

const Leverage: FC<Props> = ({ setFormData, value }) => {
    const { t } = useTranslation()

    const onChange = useCallback(
        (v: number) => {
            setFormData((prev) => ({ ...prev, Leverage: v || 100 }))
        },
        [setFormData]
    )

    const onChangeManually = useCallback(
        (val: string) => {
            setFormData((prevState) => {
                const newValue = parseInt(val)

                if (newValue <= MIN_LEVERAGE) {
                    return {
                        ...prevState,
                        Leverage: MIN_LEVERAGE
                    }
                }

                if (newValue >= MAX_LEVERAGE) {
                    return {
                        ...prevState,
                        Leverage: MAX_LEVERAGE
                    }
                }

                return {
                    ...prevState,
                    Leverage: newValue
                }
            })
        },
        [setFormData]
    )

    const onBlur = useCallback(() => {
        if (!value) {
            setFormData((prevState) => ({
                ...prevState,
                Leverage: MIN_LEVERAGE
            }))
        }
    }, [value, setFormData])

    return (
        <Flex direction='column'>
            <Typography type='text-semibold'>{t('dashboard.modals.projects.advanced.leverage')}</Typography>
            <Flex gap={16} alignItems='center' justifyContent='space-between'>
                <RangeSlider
                    classNames={{ root: styles.Slider }}
                    min={MIN_LEVERAGE}
                    max={MAX_LEVERAGE}
                    step={1}
                    value={value}
                    onChange={onChange}
                    pointsToShow={rangeValues}
                    formatLabel={(str) => `${str}x`}
                />
                <div className={styles.Preview}>
                    <InputField value={value} size='medium' onChange={onChangeManually} type='number' onBlur={onBlur} />
                </div>
            </Flex>
        </Flex>
    )
}

export default Leverage
