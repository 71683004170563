import { JumpToStrategy } from '@fto/lib/JumpTo/JumpToStrategies/JumpToStrategy'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { JumpToSession } from '@fto/lib/JumpTo/JumpToSession'

export class NextSessionJumpTo extends JumpToStrategy {
    private _jumpToSession: JumpToSession[] = []
    private _lastProcessedTick: TDateTime

    constructor(lastProcessedTick: TDateTime, sessions: JumpToSession[]) {
        super()
        this._jumpToSession = sessions
        this._lastProcessedTick = lastProcessedTick
    }

    public jump(): void {
        const NextSessionDT = this.getClosestSession()
        import('@fto/lib/globals/GlobalTestingManager').then((module) => {
            const GlobalTestingManager = module.default
            GlobalTestingManager.TestingManager.RollTestingTo(NextSessionDT, true)
        })
    }

    private getClosestSession(): TDateTime {
        let closestSessionDate = DateUtils.EmptyDate
        for (let i = 0; i < this._jumpToSession.length; i++) {
            const session = this._jumpToSession[i]
            const sessionTime = session.getNextOpenSessionDateTimeFromTime(this._lastProcessedTick)
            if (
                closestSessionDate === DateUtils.EmptyDate ||
                (sessionTime < closestSessionDate && sessionTime > this._lastProcessedTick)
            ) {
                closestSessionDate = sessionTime
            }
        }
        return closestSessionDate
    }
}
