import { ChartControl } from '@fto/chart_components/ChartControl'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { isCtrlEvent } from '@fto/lib/utils/eventsUtils'

export class SelectableControl extends ChartControl {
    private _isSelected = false

    onSelect(multiSelect: boolean): void {
        this._isSelected = true
    }

    onDeselect(): void {
        this._isSelected = false
    }
    get isSelected(): boolean {
        return this._isSelected
    }

    set isSelected(value: boolean) {
        this._isSelected = value
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        if (super.onMouseDown(event, sender)) {
            this.onSelect(isCtrlEvent(event))
            this.Capture()
            return this
        }

        if (!isCtrlEvent(event)) {
            this.ReleaseCapture()
            this.onDeselect()
        }
        return null
    }

    onMouseMove(event: MouseEvent, sender: TMainChart): ChartControl | null {
        if (this.IsCaptured() && this.IsDraggable()) {
            return this.capturedMouseMove(event, sender)
        } else {
            return super.onMouseMove(event, sender)
        }
    }
}
