import { BarBackCommand } from './FTOCommands/BarBackCommand'
import { BarForwardCommand } from './FTOCommands/BarForwardCommand'
import { Command } from './FTOCommands/Command'
import { CopyCommand } from './FTOCommands/CopyCommand'
import { DebugCommand } from './FTOCommands/DebugCommand'
import { DebugCompareCommand } from './FTOCommands/DebugCompareCommand'
import { DebugLogCommand } from './FTOCommands/DebugLogCommand'
import { DeleteCommand } from './FTOCommands/DeleteCommand'
import { EducationModalCommand } from './FTOCommands/EducationModalCommand'
import { EnvInfoCommand } from './FTOCommands/EnvInfoCommand'
import { EscapeCommand } from './FTOCommands/EscapeCommand'
import { ExtendToolLeftCommand } from './FTOCommands/ExtendToolLeftCommand'
import { ExtendToolRightCommand } from './FTOCommands/ExtendToolRightCommand'
import { PasteCommand } from './FTOCommands/PasteCommand'
import { PlayPauseCommand } from './FTOCommands/PlayPauseCommand'
import { RedoCommand } from './FTOCommands/RedoCommand'
import { SelectAllCommand } from './FTOCommands/SelectAllCommand'
import { TickForwardCommand } from './FTOCommands/TickForwardCommand'
import { ToolRotateDisableCommand } from './FTOCommands/ToolRotateDisableCommand'
import { ToolRotateEnableCommand } from './FTOCommands/ToolRotateEnableCommand'
import { UndoCommand } from './FTOCommands/UndoCommand'

import ChartSettingsStore from '@fto/lib/store/chartSettings'
import { ToolMagnetModeCommandEnable } from '@fto/lib/utils/FTOCommands/MagnetModeCommandEnable'
import { ToolMagnetModeCommandDisable } from '@fto/lib/utils/FTOCommands/MagnetModeCommandDisable'

export type HotkeyGroup = 'Graphic Tools' | 'Testing' | 'Debug'

export type Hotkey = {
    group: HotkeyGroup
    label: string
    keyCode?: string
    keyDownCommand?: Command
    keyUpCommand?: Command
    timesUsed: number
    isCtrl?: boolean
    isShift?: boolean
    isAlt?: boolean
    isMeta?: boolean
    preventDefault?: boolean
    denyOnActiveTesting?: boolean
}

const setFocusForChart = () => {
    const chartContainer = document.querySelector('#chart-container-0')
    if (!chartContainer) return
    ;(chartContainer as HTMLElement).focus()
}

export const DEFAULT_HOTKEYS: Hotkey[] = [
    {
        group: 'Debug',
        label: 'Debug mod toggle',
        keyCode: 'F5',
        keyDownCommand: new DebugCommand(),
        isCtrl: true,
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Testing',
        label: 'Start/Pause',
        keyCode: 'Enter',
        keyDownCommand: new PlayPauseCommand(),
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Undo',
        keyCode: 'KeyZ',
        keyDownCommand: new UndoCommand(),
        isCtrl: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Redo',
        keyCode: 'KeyY',
        keyDownCommand: new RedoCommand(),
        isCtrl: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Escape picker mode',
        keyCode: 'Escape',
        keyDownCommand: new EscapeCommand(),
        timesUsed: 0
    },
    {
        group: 'Debug',
        label: 'Debug Compare',
        keyCode: 'F3',
        keyDownCommand: new DebugCompareCommand(),
        isCtrl: true,
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Debug',
        label: 'Debug Log Dump',
        keyCode: 'F4',
        keyDownCommand: new DebugLogCommand(),
        isCtrl: true,
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Testing',
        label: 'Tick Forward',
        keyCode: 'Space',
        keyDownCommand: new TickForwardCommand(),
        isCtrl: true,
        denyOnActiveTesting: true,
        timesUsed: 0
    },
    {
        group: 'Testing',
        label: 'Bar Forward',
        keyCode: 'Space',
        keyDownCommand: new BarForwardCommand(),
        timesUsed: 0,
        denyOnActiveTesting: true
    },
    {
        group: 'Testing',
        label: 'Bar Forward',
        keyCode: 'ArrowRight',
        keyDownCommand: new BarForwardCommand(),
        denyOnActiveTesting: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Extend Tool to the right',
        keyCode: 'ArrowRight',
        keyDownCommand: new ExtendToolRightCommand(),
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Extend Tool to the left',
        keyCode: 'ArrowLeft',
        keyDownCommand: new ExtendToolLeftCommand(),
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Testing',
        label: 'Bar Back',
        keyCode: 'Backspace',
        keyDownCommand: new BarBackCommand(),
        denyOnActiveTesting: true,
        timesUsed: 0
    },
    {
        group: 'Testing',
        label: 'Bar Back',
        keyCode: 'ArrowLeft',
        keyDownCommand: new BarBackCommand(),
        denyOnActiveTesting: true,
        timesUsed: 0
    },
    {
        group: 'Debug',
        label: 'Open Education Modal',
        keyCode: 'F7',
        keyDownCommand: new EducationModalCommand(),
        isCtrl: true,
        timesUsed: 0
    },
    {
        group: 'Debug',
        label: 'GetEnvInfo',
        keyCode: 'F10',
        keyDownCommand: new EnvInfoCommand(),
        isMeta: true,
        timesUsed: 0
    },
    {
        group: 'Debug',
        label: 'Open Education Modal',
        keyCode: 'F7',
        keyDownCommand: new EducationModalCommand(),
        isMeta: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Select All Tools',
        keyCode: 'KeyA',
        keyDownCommand: new SelectAllCommand(),
        isCtrl: true,
        preventDefault: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Copy Tool',
        keyCode: 'KeyC',
        keyDownCommand: new CopyCommand(),
        isCtrl: true,
        preventDefault: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Paste Tool',
        keyCode: 'KeyV',
        keyDownCommand: new PasteCommand(),
        isCtrl: true,
        preventDefault: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Delete Tool/Indicator',
        keyCode: 'Delete',
        keyDownCommand: new DeleteCommand(),
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Snap Trend Line or Channel to 45 degrees',
        keyDownCommand: new ToolRotateEnableCommand(),
        keyUpCommand: new ToolRotateDisableCommand(),
        isShift: true,
        timesUsed: 0
    },
    {
        group: 'Graphic Tools',
        label: 'Magnet Mode',
        keyDownCommand: new ToolMagnetModeCommandEnable(),
        keyUpCommand: new ToolMagnetModeCommandDisable(),
        isCtrl: true,
        timesUsed: 0
    }
]

export function addHotkey(hotkey: Hotkey): void {
    if (hotkey.keyDownCommand) {
        window.addEventListener('keydown', (event: KeyboardEvent) => {
            const { isPlaying } = ChartSettingsStore.settings
            const isValid = validateHotkey(event, hotkey)

            if (!isValid) return

            if (hotkey.preventDefault) {
                event.preventDefault()
            }

            if (hotkey.keyCode && event.code !== hotkey.keyCode) return
            if (hotkey.denyOnActiveTesting && isPlaying) return

            setFocusForChart()
            hotkey.keyDownCommand!.execute()
            hotkey.timesUsed += 1
        })
    }

    if (hotkey.keyUpCommand) {
        window.addEventListener('keyup', (event: KeyboardEvent) => {
            const { isPlaying } = ChartSettingsStore.settings

            if (hotkey.preventDefault) {
                event.preventDefault()
            }

            if (hotkey.keyCode && event.code !== hotkey.keyCode) return
            if (hotkey.denyOnActiveTesting && isPlaying) return

            hotkey.keyUpCommand!.execute()
        })
    }
}

function validateHotkey(event: KeyboardEvent, hotkey: Hotkey): boolean {
    if ((hotkey.isCtrl && !event.ctrlKey) || (!hotkey.isCtrl && event.ctrlKey)) return false
    if ((hotkey.isShift && !event.shiftKey) || (!hotkey.isShift && event.shiftKey)) return false
    if ((hotkey.isAlt && !event.altKey) || (!hotkey.isAlt && event.altKey)) return false
    if ((hotkey.isMeta && !event.metaKey) || (!hotkey.isMeta && event.metaKey)) return false

    if (
        document.querySelector('dialog') !== null ||
        document.activeElement?.nodeName === 'INPUT' ||
        document.activeElement?.nodeName === 'TEXTAREA'
    ) {
        return false
    }

    return true
}

export function changeHotkey(hotkey: Hotkey, newKey: string): void {
    hotkey.keyCode = newKey
    removeHotkey(hotkey)
    addHotkey(hotkey)
}

export function removeHotkey(hotkey: Hotkey): void {
    hotkey.keyDownCommand && window.removeEventListener('keydown', hotkey.keyDownCommand.execute)
    hotkey.keyUpCommand && window.removeEventListener('keyup', hotkey.keyUpCommand.execute)
}

export function getHotkeysStats(): { [key: string]: number } {
    const stats: { [key: string]: number } = {}

    for (const hotkey of DEFAULT_HOTKEYS) {
        if (hotkey.timesUsed > 0) stats[`${hotkey.label}(${hotkey.keyCode})`] = hotkey.timesUsed
    }

    return stats
}
