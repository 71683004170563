export const BASE_ROUTE = '/'

//AUTH
export const AUTH_ROUTE = 'auth'
export const LOGIN_ROUTE = `${AUTH_ROUTE}/login`
export const SIGN_UP_ROUTE = `${AUTH_ROUTE}/register`
export const FORGOT_PASSWORD_ROUTE = `${AUTH_ROUTE}/forgot-password`
export const RESET_PASSWORD_ROUTE = `${AUTH_ROUTE}/reset-password`
export const CREATE_NEW_PASSWORD_ROUTE = `${AUTH_ROUTE}/create-new-password`
export const CONFIRM_EMAIL_ROUTE = `${AUTH_ROUTE}/email-confirmation`
export const GOOGLE_ROUTE = `${AUTH_ROUTE}/google`

export const DASHBOARD_ROUTE = `dashboard`
export const BACK_TESTING_ROUTE = `chart`
export const PAGE_NOT_FOUND_ROUTE = `page-not-found`
export const USER_PROFILE_ROUTE = `profile`
export const CHECKOUT_PAGE = `checkout`
export const BLOCKED_ROUTE = `blocked`

//PAYMENT
export const SUCCESS_ROUTE = `success-page`
export const FAILED_PAYMENT = 'failed-payment'

export const makeRoute = (routeName: string): string => {
    return `/${routeName}`
}
