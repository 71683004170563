import { TDataDescriptor } from '@fto/lib/ft_types/data/data_arrays/DataDescriptionTypes'
import { IndexesLevel } from '@fto/lib/globals/HighestLowestCache/IndexesLevel'
import { TValueType } from '@fto/lib/extension_modules/common/CommonTypes'

export class CachedPrices {
    private map: Map<TDataDescriptor, IndexesLevel>

    constructor() {
        this.map = new Map<TDataDescriptor, IndexesLevel>()
    }

    set(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number,
        value: number
    ): void {
        if (!this.map.has(dataDescriptor)) {
            this.map.set(dataDescriptor, new IndexesLevel())
        }
        this.map.get(dataDescriptor)?.set(globalIndex, valueType, offset, value)
    }

    get(
        dataDescriptor: TDataDescriptor,
        globalIndex: number,
        valueType: TValueType,
        offset: number
    ): number | undefined {
        return this.map.get(dataDescriptor)?.get(globalIndex, valueType, offset)
    }

    has(dataDescriptor: TDataDescriptor): boolean {
        return this.map.has(dataDescriptor) ?? false
    }
}
