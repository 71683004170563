import { makeAutoObservable } from 'mobx'

export type UpdaterType = ((prevSettings: StoreType) => StoreType) | StoreType

type StoreType = {
    lot: number
    focused: boolean
}

const initialValue: StoreType = {
    lot: 0.1,
    focused: false
}

class OneClickTradingStore {
    data: StoreType

    constructor() {
        this.data = initialValue
        makeAutoObservable(this)
        this.updateData = this.updateData.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.data = initialValue
    }
}

export default new OneClickTradingStore()
