import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { NODES_TO_PREVENT_GRAPH_EVENTS } from '@fto/ui/lib/constants/controledNodes'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
// Assuming MouseTracker is a singleton with an updateMousePosition method
import MouseTracker from '@fto/lib/utils/MouseTracker'
import { throttle } from 'lodash'
import KeyboardTracker from '@fto/lib/utils/KeyboardTracker'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

const LEFT_MOUSE_BUTTON_CODE = 0
const RIGHT_MOUSE_BUTTON_CODE = 2

const MouseTrackerComponent: FC = () => {
    const [previousPinchDistance, setPreviousPinchDistance] = useState<null | number>(null)
    const chartsController = useMemo(() => GlobalChartsController.Instance, [])

    const keyboardTracker = useMemo(() => KeyboardTracker.getInstance(), [])
    const mouseTracker = useMemo(() => MouseTracker.getInstance(), [])

    const shouldStopPropagation = useCallback((event: MouseEvent) => {
        let element = event.target as Element
        let currentDepth = 0
        const maxDepth = 3
        while (element && currentDepth < maxDepth) {
            if (element.classList.contains('btn-needs-propagation-stop')) {
                event.stopPropagation()
                return true
            }
            element = element.parentElement as Element
            currentDepth++
        }
        return false
    }, [])

    const isInsideControlledNode = useCallback((event: MouseEvent) => {
        const nodes = NODES_TO_PREVENT_GRAPH_EVENTS.reduce((nodesList: Node[], selectorEntity) => {
            const list = document.querySelectorAll(selectorEntity)

            return [...nodesList, ...Array.from(list)]
        }, [])

        for (const node of nodes) {
            if (node.contains(event.target as Node)) {
                chartsController.onEnterControlledNode()
                return true
            }
        }
        chartsController.onLeaveControlledNode()
        return false
    }, [])

    const handleMouseMove = useCallback(
        throttle((event: MouseEvent) => {
            if (isInsideControlledNode(event)) {
                if (GlobalChartsController.Instance.isMouseLeave) {
                    chartsController.onMouseLeave(event)
                    GlobalChartsController.Instance.isMouseLeave = false
                }
                return
            }

            event.preventDefault()
            GlobalChartsController.Instance.isMouseLeave = true
            mouseTracker.updateMousePosition(event.clientX, event.clientY)
            chartsController.onMouseMove(event)
        }, 10),
        [isInsideControlledNode, mouseTracker, chartsController]
    )

    const handleMouseUp = useCallback(
        (event: MouseEvent) => {
            mouseTracker.updateMousePosition(event.clientX, event.clientY)
            chartsController.onMouseUp(event)
        },
        [chartsController]
    )

    const shouldOpenContextMenu = useCallback((event: MouseEvent) => {
        return (
            (event.button === RIGHT_MOUSE_BUTTON_CODE &&
                !keyboardTracker.isShiftPressed &&
                !keyboardTracker.isCtrlPressed &&
                !keyboardTracker.isAltPressed) ||
            (event.button === LEFT_MOUSE_BUTTON_CODE && keyboardTracker.isAltPressed)
        )
    }, [])

    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            if (shouldOpenContextMenu(event)) {
                event.preventDefault()
                GlobalChartsController.Instance.onContextMenu(event)
                return
            }

            if (shouldStopPropagation(event)) {
            } else if (isInsideControlledNode(event)) {
                return
            } else {
                chartsController.onMouseDown(event)
            }
        },
        [shouldStopPropagation, isInsideControlledNode, chartsController]
    )

    const handleMouseWheel = useCallback(
        (event: WheelEvent) => {
            if (isInsideControlledNode(event)) {
                event.stopPropagation()
            } else if (GlobalChartsController.Instance.onMouseWheel(event)) {
                event.preventDefault()
            }
        },
        [isInsideControlledNode, chartsController]
    )

    const handleContextMenu = useCallback((event: MouseEvent) => {
        event.preventDefault()
        // Disable default context menu event
    }, [])

    // Utility function to convert touch event to mouse event
    const convertTouchEvent = useCallback((event: TouchEvent) => {
        const touch = event.touches[0] || event.changedTouches[0]
        return new MouseEvent(event.type, {
            bubbles: true,
            cancelable: true,
            view: window,
            clientX: touch.clientX,
            clientY: touch.clientY
        })
    }, [])

    const onDblClick = useCallback(
        (event: MouseEvent) => {
            if (!isInsideControlledNode(event)) {
                chartsController.onDblClick(event)
            }
        },
        [chartsController, isInsideControlledNode]
    )

    // Mobile event handlers
    const handleTouchStart = useCallback(
        (event: TouchEvent) => {
            const mouseEvent = convertTouchEvent(event)
            handleMouseDown(mouseEvent)
        },
        [handleMouseDown, convertTouchEvent]
    )

    const handlePinchGesture = useCallback(
        (event: TouchEvent) => {
            if (event.touches.length !== 2) return

            const touch1 = event.touches[0]
            const touch2 = event.touches[1]
            const distance = Math.sqrt(
                Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2)
            )

            if (previousPinchDistance != null) {
                const deltaDistance = distance - previousPinchDistance
                const wheelEvent = new WheelEvent('wheel', {
                    deltaY: -deltaDistance
                })
                chartsController.onMouseWheel(wheelEvent)
            }
            setPreviousPinchDistance(distance)
        },
        [previousPinchDistance, chartsController]
    )

    const handleTouchEnd = useCallback(
        (event: TouchEvent) => {
            const mouseEvent = convertTouchEvent(event)
            handleMouseUp(mouseEvent)
            setPreviousPinchDistance(null)
        },
        [handleMouseUp, convertTouchEvent]
    )

    const handleTouchMove = useCallback(
        (event: TouchEvent) => {
            if (event.touches.length === 2) {
                event.preventDefault()
                handlePinchGesture(event)
            } else {
                const mouseEvent = convertTouchEvent(event)
                handleMouseMove(mouseEvent)
            }
        },
        [handlePinchGesture, handleMouseMove]
    )

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('mouseup', handleMouseUp)
        window.addEventListener('mousedown', handleMouseDown)
        window.addEventListener('wheel', handleMouseWheel, { passive: false })
        window.addEventListener('touchmove', handleTouchMove, {
            passive: false
        })
        window.addEventListener('touchstart', handleTouchStart)
        window.addEventListener('touchend', handleTouchEnd)
        window.addEventListener('contextmenu', handleContextMenu)
        window.addEventListener('dblclick', onDblClick)

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('mousedown', handleMouseDown)
            window.removeEventListener('wheel', handleMouseWheel)
            window.removeEventListener('contextmenu', handleContextMenu)
            window.removeEventListener('touchstart', handleTouchStart)
            window.removeEventListener('touchmove', handleTouchMove)
            window.removeEventListener('touchend', handleTouchEnd)
            window.removeEventListener('dblclick', onDblClick)
        }
    }, [])

    return null
}

export default MouseTrackerComponent
