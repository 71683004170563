import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Command } from './Command'
import { OrderMarker } from '@fto/lib/OrderModalClasses/OrderMarker'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

class EscapeCommand extends Command {
    public execute(): void {
        const activeChart = GlobalChartsController.Instance.getActiveChart()

        if (activeChart) {
            const activeTool = activeChart.getActiveTool()
            if (activeTool instanceof OrderMarker) {
                activeTool.onCancelPricePick()
            }
        } else {
            throw new StrangeError('No active chart')
        }
    }
}

export { EscapeCommand }
