import { Icon } from '@fto/icons'
import { Flex, Modal, Typography, removeModal } from '@fto/ui'
import { requestCode } from '@root/utils/api'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EnterCodeContent } from './components/EnterCodeContent'
import { ChangePasswordContent } from './components/ChangePasswordContent'
import { EnterPasswordContent } from './components/EnterPasswordContent'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { useTranslation } from 'react-i18next'

type Stage = 'changePassword' | 'enterCode' | 'enterPassword'

type Props = {
    code: string
}

export const ChangePasswordModal: FC<Props> = ({ code }) => {
    const { t } = useTranslation()

    const [stage, setStage] = useState<Stage>(code ? 'enterPassword' : 'changePassword')
    const user = useAppSelector($getUser)
    if (!user) return

    useEffect(() => {
        setStage(code ? 'enterPassword' : 'changePassword')
    }, [code])

    const removePasswordModal = useCallback(() => {
        removeModal(MODAL_NAMES.userProfile.changePassword)
        setStage('changePassword')
    }, [])

    const sendCode = useCallback(() => {
        requestCode({ type: 'changepassword', email: user.email })
        setStage('enterCode')
    }, [user.email])

    const headerText = useMemo(
        () => ({
            changePassword: t('profile.changePassword'),
            enterCode: t('profile.modals.enterCode'),
            enterPassword: t('profile.modals.newPassword')
        }),
        []
    )

    const content = useMemo(
        () => ({
            changePassword: <ChangePasswordContent onClose={removePasswordModal} onSubmit={sendCode} />,
            enterCode: (
                <EnterCodeContent
                    onClose={removePasswordModal}
                    setStage={() => setStage('enterPassword')}
                    email={user.email}
                />
            ),
            enterPassword: <EnterPasswordContent code={code} onClose={removePasswordModal} />
        }),
        [removePasswordModal, sendCode, user.email, code]
    )

    return (
        <Modal name={MODAL_NAMES.userProfile.changePassword} size='sm' onClose={removePasswordModal}>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='lock-closed' size={72} />
                    <Typography type='h3-bold'>{headerText[stage]}</Typography>
                </Flex>
            </Modal.Header>
            {content[stage]}
        </Modal>
    )
}
