import StrangeError from '../common/common_errors/StrangeError'
import { TDataDownloader } from '../ft_types/data/data_downloading/DataDownloaderUnit'
import IDataDownloader from '../ft_types/data/data_downloading/IDataDownloader'
import MockDataDownloader from '../mocks/MocksForDownloading/MockDataDownloader'

//TODO: this is only used for downloading bar maps. When we refactor downloading maps (like we did for bars and ticks), then we can get rid of this class
export class GlobalDataDownloader {
    private static fDataDownloader: IDataDownloader | null = null

    public static get DataDownloader(): IDataDownloader {
        if (!this.fDataDownloader) {
            GlobalDataDownloader.fDataDownloader = new TDataDownloader()
        }
        if (this.fDataDownloader) {
            return this.fDataDownloader
        } else {
            throw new StrangeError('DataDownloader is not initialized')
        }
    }

    public static ReplaceInstanceWithMock(): void {
        this.fDataDownloader = new MockDataDownloader()
    }
}

//TODO: refactor this
export function getGlobalDataDownloader(): IDataDownloader {
    return GlobalDataDownloader.DataDownloader
}
