import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'
import { SymbolItemType } from '@root/types/symbols.types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const defaultStartDate = new Date('2018-04-01').getTime()

dayjs.extend(utc)

type CalendarDates = {
    StartDate: Date
    EndDate: Date
    initialStartDate: Date
}

const initCalendarDates = {
    StartDate: dayjs.utc(defaultStartDate).toDate(),
    EndDate: dayjs.utc().toDate(),
    initialStartDate: dayjs.utc(defaultStartDate).toDate()
}

function calculateDateRange(Symbols: CreateProjectModalFormData['Symbols']): CalendarDates {
    if (!Symbols?.length) {
        return initCalendarDates
    }

    // Initialize end date using the first symbol's date in UTC
    let StartDate = dayjs.utc(Symbols[0].Start).toDate()
    let EndDate = dayjs.utc(Symbols[0].End).toDate()

    Symbols.forEach((symbol: SymbolItemType) => {
        const symbolStartDate = dayjs.utc(symbol.Start).toDate()
        const symbolEndDate = dayjs.utc(symbol.End).toDate()

        if (symbolStartDate < StartDate) {
            StartDate = symbolStartDate
        }

        if (symbolEndDate > EndDate) {
            EndDate = symbolEndDate
        }
    })

    const adjustedStartDate = dayjs.utc(StartDate).add(1, 'week').toDate()

    let initialStartDate = dayjs.utc(defaultStartDate).toDate()
    if (initialStartDate > EndDate) {
        initialStartDate = EndDate
    } else if (initialStartDate < adjustedStartDate) {
        initialStartDate = adjustedStartDate
    }

    return { StartDate: adjustedStartDate, EndDate, initialStartDate }
}

export { calculateDateRange, initCalendarDates }
