import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Flex, Typography } from '@fto/ui'

import { TEXT_LOCALES } from '../../constants/texts'
import { ReasonType } from '../../types'

type Props = {
    reason?: ReasonType
}
const Header: FC<Props> = ({ reason }) => {
    const { t } = useTranslation()

    const headerTxt = useMemo(() => {
        const locale = TEXT_LOCALES[reason || 'mainBlock']

        return {
            title: locale.title,
            description: 'description' in locale ? locale.description : ''
        }
    }, [reason])

    return (
        <Flex direction='column' gap={16} alignItems='center'>
            <Icon name='question' size={72} />
            <Typography type='h5-semibold' color='gray-1000' block align='center'>
                {t(headerTxt.title)}
            </Typography>
            {headerTxt.description && (
                <Typography type='interface-regular' color='gray-1000' block align='center'>
                    {t(headerTxt.description)}
                </Typography>
            )}
        </Flex>
    )
}

export default Header
