import { addModal, Flex, TriggerOverlay, Typography, Separator } from '@fto/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './index.module.scss'
import { IconButton } from '@fto/icons'
import Task from './components/Task'
import educationStore from '@fto/lib/store/educationStore'
import { observer } from 'mobx-react-lite'
import { MODAL_NAMES } from '@root/constants/modalNames'

const TasksButton: FC = observer(() => {
    const { t } = useTranslation()

    const { data } = educationStore
    const completedTasks = useMemo(() => data.lesson.checks.filter((task) => task.isPassed), [data])

    return (
        data.isShown && (
            <TriggerOverlay
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                optionsRenderer={(close) => (
                    <Flex direction='column' className={styles.dropdownContent} gap={24}>
                        <Flex justifyContent='space-between' gap={8}>
                            <Flex direction='column' gap={8}>
                                <Typography type='text-semibold'>{data.lesson.title}</Typography>
                                <Typography type='interface-regular'>{data.lesson.intro}</Typography>
                            </Flex>
                            <IconButton
                                name='restart'
                                onClick={() => {
                                    addModal(MODAL_NAMES.chart.education.restart)
                                    close()
                                }}
                            />
                        </Flex>
                        <Flex direction='column'>
                            {data.lesson.checks.map((task, index) => {
                                return (
                                    <>
                                        <Task
                                            text={task.name}
                                            completed={task.isPassed}
                                            description={task.description}
                                        />
                                        {index != data.lesson.checks.indexOf(data.lesson.checks.at(-1)!) && (
                                            <Separator width='100%' margin='4px' />
                                        )}
                                    </>
                                )
                            })}
                        </Flex>
                    </Flex>
                )}
            >
                <Flex className={styles.button} gap={4} justifyContent='center' alignItems='center'>
                    <Typography color='white' type='interface-medium'>
                        {t('ribbon.tasks.tasks')}
                    </Typography>
                    <Typography className={styles.counter} type='subtext-regular'>
                        {completedTasks.length + '/' + data.lesson.checks.length}
                    </Typography>
                </Flex>
            </TriggerOverlay>
        )
    )
})

export default TasksButton
