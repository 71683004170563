import StrangeError from '../common/common_errors/StrangeError'

export enum TMouseButton {
    mbLeft = 0, // Usually, the left button is 0
    mbRight = 2, // Right button is typically 2
    mbMiddle = 1 // Middle button (wheel) is usually 1
}

export enum TShiftState {
    ssShift = 1 << 0, // Shift key is down
    ssAlt = 1 << 1, // Alt key is down
    ssCtrl = 1 << 2, // Control key is down
    ssLeft = 1 << 3, // Left mouse button is down
    ssRight = 1 << 4, // Right mouse button is down
    ssMiddle = 1 << 5, // Middle mouse button is down
    ssDouble = 1 << 6 // Double-click (not a key, but sometimes included for completeness)
}

export class DelphiFormsBuiltIns {
    public static ShiftStateIncludes(shiftState: TShiftState, stateToCheck: TShiftState): boolean {
        return (shiftState & stateToCheck) === stateToCheck
    }

    public static ExtractTShiftState(event: MouseEvent): TShiftState {
        let shiftState = 0
        if (event.shiftKey) shiftState |= TShiftState.ssShift
        if (event.altKey) shiftState |= TShiftState.ssAlt
        if (event.ctrlKey) shiftState |= TShiftState.ssCtrl
        if (event.button === TMouseButton.mbLeft) shiftState |= TShiftState.ssLeft
        if (event.button === TMouseButton.mbRight) shiftState |= TShiftState.ssRight
        if (event.button === TMouseButton.mbMiddle) shiftState |= TShiftState.ssMiddle
        if (event.detail === 2) shiftState |= TShiftState.ssDouble // 'detail' property is 2 for a double-click

        return shiftState
    }

    public static ExtractTMouseButton(event: MouseEvent): TMouseButton {
        switch (event.button) {
            case 0: {
                return TMouseButton.mbLeft
            }
            case 1: {
                return TMouseButton.mbMiddle
            }
            case 2: {
                return TMouseButton.mbRight
            }
            default: {
                throw new StrangeError(`Unknown mouse button: ${event.button}`)
            }
        }
    }
}
