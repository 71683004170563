import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { IDataInfoProcRec } from '../CommonProcRecInterfaces/IDataInfoProcRec'
import { CurrencyInfo } from '../CommonTypes'
import { API_Error } from '../errors/API_Error'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class DataInfoProcRecImplementation extends BasicProcRecImplementation implements IDataInfoProcRec {
    public GetImplementation(): IDataInfoProcRec {
        return {
            GetSymbolInfo: this.GetSymbolInfo.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_DataInfo_${super.generateDName()}`
    }

    public GetSymbolInfo(symbolName: string): CurrencyInfo | null {
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)

        if (symbol) {
            // this.LastError = ''
            return new CurrencyInfo(
                symbol.symbolInfo.SymbolName,
                symbol.symbolInfo.decimals,
                symbol.symbolInfo.spreadAsPriceValue,
                symbol.symbolInfo.MinPoint,
                symbol.symbolInfo.lot,
                symbol.symbolInfo.LotCurrency,
                symbol.symbolInfo.SwapLong,
                symbol.symbolInfo.SwapShort
            )
        } else {
            throw new API_Error(`Symbol with name ${symbolName} not found`)
        }
    }
}
