import { Icon } from '@fto/icons'
import { Button, Flex, Typography, addModal, Separator } from '@fto/ui'

import styles from '../../../index.module.scss'
import React, { useCallback } from 'react'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { Closable } from '../../types'
import { useTranslation } from 'react-i18next'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

const AdditionalProjectSettingsOptions: React.FC<Closable> = ({ close }) => {
    const leverage = `1:${GlobalProjectInfo.ProjectInfo.leverage}`
    const timezone = GlobalTimezoneDSTController.Instance.getTimezoneString()
    const dstValue = GlobalTimezoneDSTController.Instance.getDstString()
    const { t } = useTranslation()

    const changeBalance = useCallback(() => {
        close()
        addModal(MODAL_NAMES.chart.changeBalance)
    }, [close])

    const restart = useCallback(() => {
        addModal(MODAL_NAMES.chart.restartModal)
        close()
    }, [close])

    return (
        <Flex direction='column' gap={4}>
            <Separator width='184px' color='gray-500' />
            <Button
                block
                classNames={{ content: styles.buttonContent }}
                before={<Icon name='change-balance' size={18} />}
                type='tetriatry-white'
                size='compact'
                label={t('ribbon.projectOptions.balance')}
                onClick={changeBalance}
            />
            <Separator width='184px' color='gray-500' />
            <Button
                block
                classNames={{ content: styles.buttonContent }}
                before={<Icon name='arrow-path' size={16} />}
                type='tetriatry-white'
                size='compact'
                label={t('ribbon.projectOptions.restart')}
                onClick={restart}
            />
            <Flex direction='column' gap={4} className={styles.info}>
                <Typography type='tiny-regular' color='gray-600'>
                    {t('ribbon.projectOptions.projectInfo')}
                </Typography>
                <Flex direction='column'>
                    <Typography type='subtext-regular'>{`${t(
                        'ribbon.projectOptions.timezone'
                    )} ${timezone}`}</Typography>
                    <Typography type='subtext-regular'>
                        {`${t('ribbon.projectOptions.dstLabel')} ${dstValue}`}
                    </Typography>
                    <Typography type='subtext-regular'>{`${t(
                        'ribbon.projectOptions.leverage'
                    )} ${leverage}`}</Typography>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default AdditionalProjectSettingsOptions
