export enum EDirections {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right'
}

export enum EBlockingLayerState {
    off = 'off',
    loader = 'loader',
    try = 'try',
    goto = 'goto'
}

export enum SizeMode {
    Auto = 'auto',
    Manual = 'manual'
}
