export const createObserver = () => {
    const observers = new Map()

    const subscribe = (key: string, callback: (param: any) => void) => {
        const callbacks = observers.get(key)
        if (callbacks) {
            callbacks.push(callback)
        } else {
            observers.set(key, [callback])
        }
    }

    const unsubscribe = (key: string, handler: Function) => {
        const callbacks = observers.get(key)
        const newCallbacks = callbacks.filter((callback: any) => callback !== handler)

        if (newCallbacks.length > 0) {
            observers.set(key, newCallbacks)
        } else {
            observers.delete(key)
        }
    }

    const dispatch = (key: string, param?: any) =>
        observers.get(key)?.forEach((callback: (param: any) => void) => callback(param))

    return {
        subscribe,
        dispatch,
        unsubscribe
    }
}

export default createObserver()
