import dayjs from 'dayjs'

function formatMoney(amount: number | string, makeAbsValue = false): string {
    if (typeof amount !== 'number' || isNaN(amount)) {
        return 'Invalid amount'
    }

    const value = makeAbsValue ? Math.abs(amount) : amount

    const formatted = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')

    return '$' + formatted
}

function timeToMessageFilters(stringDate: string): string {
    const date = dayjs(stringDate)

    if (date.isSame(dayjs(), 'day')) {
        return 'Today'
    }
    if (date.isSame(dayjs().subtract(1, 'day'), 'day')) {
        return 'Yesterday'
    }

    if (date.isAfter(dayjs().subtract(7, 'day'), 'day')) {
        return `${dayjs().diff(date, 'day')} days ago`
    }

    if (date.isAfter(dayjs().subtract(14, 'day'), 'day')) {
        return 'Last week'
    }

    if (date.isAfter(dayjs().subtract(4, 'week'), 'day')) {
        return `${dayjs().diff(date, 'week')} weeks ago`
    }

    if (date.isAfter(dayjs().subtract(1, 'month'), 'day')) {
        return 'Last month'
    }

    if (date.isAfter(dayjs().subtract(12, 'month'), 'day')) {
        return `${dayjs().diff(date, 'month')} months ago`
    }

    //last year
    if (date.isAfter(dayjs().subtract(1, 'year'), 'day')) {
        return 'Last year'
    }

    return date.format('DD MMM YYYY')
}

export { formatMoney, timeToMessageFilters }
