import GlobalSymbolList from '../../globals/GlobalSymbolList'
import IFMBarsArray from '../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import ISymbolData from '../../ft_types/data/ISymbolData'
import { ExternalModuleDescriptor } from './ExternalModuleDescriptor'
import { TDllOptions } from './options/DllOptions'
import { AccountInfoProcRecImplementation } from './CommonProcRecImplementations/AccountInfoProcRecImplementation'
import { BasicExternalModuleParamsProcRecImplementation } from './CommonProcRecImplementations/BasicExternalModuleParamsProcRecImplementation'
import { ChartInterfaceProcRecImplementation } from './CommonProcRecImplementations/ChartInterfaceProcRecImplementation'
import { CurrentSymbolAndTFInfoProcRecImplementation } from './CommonProcRecImplementations/CurrentSymbolAndTFInfoProcRecImplementation'
import { DataInfoProcRecImplementation } from './CommonProcRecImplementations/DataInfoProcRecImplementation'
import { DateTimeProcRecImplementation } from './CommonProcRecImplementations/DateTimeProcRecImplementation'
import { ErrorHandlingProcRecImplementation } from './CommonProcRecImplementations/ErrorHandlingProcRecImplementation'
import { ObjectsProcRecImplementation } from './CommonProcRecImplementations/ObjectsProcRecImplementation'
import { OptionsProcRecImplementation } from './CommonProcRecImplementations/OptionsProcRecImplementation'
import { TechnicalIndicatorsImplementation } from './CommonProcRecImplementations/TechnicalIndicatorsImplementation'
import { TestingProcRecImplementation } from './CommonProcRecImplementations/TestingProcRecImplementation'
import { TimeseriesProcRecImplementation } from './CommonProcRecImplementations/TimeseriesProcRecImplementation'
import { ICommonProcRec } from './CommonProcRecInterfaces/ICommonProcRec'
import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { IProcRecsEveryImplementationNeeds } from './CommonProcRecImplementations/IProcRecsEveryImplementationNeeds'

export abstract class BasicRuntimeLib {
    protected errorHandlingProcRecImplementation: ErrorHandlingProcRecImplementation
    protected optionsProcRecImplementation: OptionsProcRecImplementation
    protected timeseriesProcRecImplementation: TimeseriesProcRecImplementation
    protected currentSymbolAndTFInfoProcRecImplementation: CurrentSymbolAndTFInfoProcRecImplementation
    protected chartInterfaceProcRecImplementation: ChartInterfaceProcRecImplementation
    protected technicalIndicatorsImplementation: TechnicalIndicatorsImplementation
    protected objectsProcRecImplementation: ObjectsProcRecImplementation
    protected dataInfoProcRecImplementation: DataInfoProcRecImplementation
    protected dateTimeProcRecImplementation: DateTimeProcRecImplementation
    protected testingProcRecImplementation: TestingProcRecImplementation
    protected accountInfoProcRecImplementation: AccountInfoProcRecImplementation
    protected basicExternalModuleParamsProcRec: BasicExternalModuleParamsProcRecImplementation

    public options: TDllOptions
    protected _chart: IChart | undefined

    public getChart(): IChart {
        if (!this._chart) {
            throw new StrangeError('Chart is not set in BasicRuntimeLib')
        }
        return this._chart
    }

    public setChart(value: IChart): void {
        this._chart = value
    }

    protected descriptor: ExternalModuleDescriptor
    protected procRecsEveryImplementationNeeds: IProcRecsEveryImplementationNeeds

    constructor(descriptor: ExternalModuleDescriptor) {
        this.descriptor = descriptor
        this.options = new TDllOptions()

        this.errorHandlingProcRecImplementation = new ErrorHandlingProcRecImplementation()

        this.procRecsEveryImplementationNeeds = {
            errorHandlingProcRecImplementation: this.errorHandlingProcRecImplementation
        }

        this.optionsProcRecImplementation = new OptionsProcRecImplementation(
            this.procRecsEveryImplementationNeeds,
            this.options
        )
        this.timeseriesProcRecImplementation = new TimeseriesProcRecImplementation(
            this.procRecsEveryImplementationNeeds
        )
        this.currentSymbolAndTFInfoProcRecImplementation = new CurrentSymbolAndTFInfoProcRecImplementation(
            this.procRecsEveryImplementationNeeds,
            this.getBars.bind(this),
            this.getSymbolData.bind(this)
        )
        this.chartInterfaceProcRecImplementation = new ChartInterfaceProcRecImplementation(
            this.procRecsEveryImplementationNeeds,
            this.getChart.bind(this)
        )
        this.objectsProcRecImplementation = new ObjectsProcRecImplementation(
            this.procRecsEveryImplementationNeeds,
            this.getChart.bind(this)
        )
        this.technicalIndicatorsImplementation = new TechnicalIndicatorsImplementation(
            this.procRecsEveryImplementationNeeds,
            this.currentSymbolAndTFInfoProcRecImplementation,
            this.getBars.bind(this),
            this.getSymbolData.bind(this)
        )
        this.dataInfoProcRecImplementation = new DataInfoProcRecImplementation(this.procRecsEveryImplementationNeeds)
        this.dateTimeProcRecImplementation = new DateTimeProcRecImplementation(this.procRecsEveryImplementationNeeds)
        this.testingProcRecImplementation = new TestingProcRecImplementation(this.procRecsEveryImplementationNeeds)
        this.accountInfoProcRecImplementation = new AccountInfoProcRecImplementation(
            this.procRecsEveryImplementationNeeds
        )
        this.basicExternalModuleParamsProcRec = new BasicExternalModuleParamsProcRecImplementation(
            this.procRecsEveryImplementationNeeds,
            this.descriptor
        )
    }

    protected get lastError(): string {
        // eslint-disable-next-line unicorn/throw-new-error
        return this.errorHandlingProcRecImplementation.GetLastError()
    }

    protected set lastError(value: string) {
        // eslint-disable-next-line unicorn/throw-new-error
        this.errorHandlingProcRecImplementation.SetLastError(value)
    }

    public GetImplementation(): ICommonProcRec {
        const fullBasicProcRecImplementation: ICommonProcRec = {
            ...this.technicalIndicatorsImplementation.GetImplementation(),
            ...this.timeseriesProcRecImplementation.GetImplementation(),
            ...this.dateTimeProcRecImplementation.GetImplementation(),
            ...this.optionsProcRecImplementation.GetImplementation(),
            ...this.currentSymbolAndTFInfoProcRecImplementation.GetImplementation(),
            ...this.chartInterfaceProcRecImplementation.GetImplementation(),
            ...this.objectsProcRecImplementation.GetImplementation(),
            ...this.dataInfoProcRecImplementation.GetImplementation(),
            ...this.testingProcRecImplementation.GetImplementation(),
            ...this.accountInfoProcRecImplementation.GetImplementation(),
            ...this.errorHandlingProcRecImplementation.GetImplementation(),
            ...this.basicExternalModuleParamsProcRec.GetImplementation()
        }
        return fullBasicProcRecImplementation
    }

    public get ShortName(): string {
        return this.descriptor.shortName
    }

    private _symbolData: ISymbolData | null = null

    public getSymbolData(): ISymbolData {
        if (!this._symbolData) {
            throw new StrangeError('SymbolData is not set in BasicRuntimeLib')
        }
        return this._symbolData
    }

    protected setSymbolData(value: ISymbolData): void {
        this._symbolData = value
    }

    private _timeframe: number | undefined = undefined
    public get timeframe(): number {
        if (this._timeframe === undefined) {
            throw new StrangeError('Timeframe is not set in TVeryBasicDllClass')
        }
        return this._timeframe
    }
    protected set timeframe(value: number) {
        this._timeframe = value
    }

    private _bars: IFMBarsArray | null = null

    public getBars(): IFMBarsArray {
        if (!this._bars) {
            throw new StrangeError('Bars is not set in TVeryBasicDllClass')
        }
        return this._bars
    }

    protected setBars(value: IFMBarsArray): void {
        this._bars = value
    }

    public AddOptionValue(OptionName: string, value: string): void {
        this.options.AddOptionValue(OptionName, value)
    }

    public SetOptionRange(OptionName: string, LowValue: number, HighValue: number): void {
        this.options.SetOptionRange(OptionName, LowValue, HighValue)
    }

    public AddSeparator(text: string): void {
        this.options.AddSeparator(text)
    }

    public GetNameWithParams(): string {
        return `${this.descriptor.shortName} (${this.options.GetParamsString()})`
    }

    public Print(s: string): void {
        // eslint-disable-next-line no-console
        console.log(s)
    }

    private EncodeText(value: string): string {
        const encoder = new TextEncoder()
        const uint8Array = encoder.encode(value)

        return StrsConv.Utf8ToString(uint8Array)
    }

    public SetCurrencyAndTimeframe(SymbolName: string, TimeFrame: number): boolean {
        const newSymbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(SymbolName)
        if (newSymbol === null) {
            throw new StrangeError(`Can not find symbol ${SymbolName}`)
        }
        this.setSymbolData(newSymbol)

        const newBars = newSymbol.GetOrCreateBarArray(TimeFrame)
        if (newBars === null) {
            throw new StrangeError(`Invalid timeframe ${TimeFrame}`)
        }
        this.setBars(newBars)

        this.timeframe = TimeFrame
        return true
    }
}
