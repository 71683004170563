import { IObjectsProcRec } from '../CommonProcRecInterfaces/IObjectsProcRec'
import { TObjectType, TDateTime, TColor } from '../CommonTypes'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { objectTypeToName, objectNameToType } from '@fto/lib/charting/paint_tools/PaintToolNames'
import {
    ObjProp,
    ObjPropStringInObjProp,
    ObjPropNumber,
    ObjPropNumberInObjProp
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { API_ObjectsError } from '../errors/API_ObjectsError'
import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import IBasicPaintTool from '@fto/lib/charting/paint_tools/IBasicPaintTool'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import { TPtRectangle } from '@fto/lib/charting/paint_tools/SpecificTools/ptRectangle'

export class ObjectsProcRecImplementation extends BasicProcRecImplementation implements IObjectsProcRec {
    public GetImplementation(): IObjectsProcRec {
        return {
            ObjectName: this.ObjectName.bind(this),
            ObjectCreate: this.ObjectCreate.bind(this),
            ObjectDelete: this.ObjectDelete.bind(this),
            ObjectExists: this.ObjectExists.bind(this),
            ObjectType: this.ObjectType.bind(this),
            ObjectSet: this.ObjectSet.bind(this),
            ObjectGet: this.ObjectGet.bind(this),
            ObjectGetNumber: this.ObjectGetNumber.bind(this),
            ObjectGetString: this.ObjectGetString.bind(this),
            ObjectsDeleteAll: this.ObjectsDeleteAll.bind(this),
            ObjectSetText: this.ObjectSetText.bind(this),
            ObjectGetText: this.ObjectGetText.bind(this),
            ObjectsTotal: this.ObjectsTotal.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_Objects_${super.generateDName()}`
    }

    private chartGetter: () => IChart

    private getChart(): IChart {
        if (!this.chartGetter) {
            throw new API_InvalidInitialization('ChartGetter is not initialized.')
        }
        const chart = this.chartGetter()
        if (!chart) {
            throw new API_InvalidInitialization('Chart is not initialized.')
        }
        return chart
    }

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds, chartGetter: () => IChart) {
        super(procRecsEveryoneNeeds)
        this.chartGetter = chartGetter
    }

    public ObjectCreate(
        name: string,
        objType: TObjectType,
        window: number,
        time1: TDateTime,
        price1: number,
        time2: TDateTime | undefined,
        price2: number | undefined,
        time3: TDateTime | undefined,
        price3: number | undefined,
        isStatic: boolean | undefined
    ): boolean {
        let result = false

        if (!this.getChart() || this.getChart().PaintTools.NameExists(name)) {
            return result
        }

        const toolNameToCreate = objectTypeToName(objType)

        if (toolNameToCreate === '') {
            return result
        }

        const ToolClass = PaintToolManager.GetPaintToolClass(toolNameToCreate)
        if (ToolClass === null) {
            return false
        }

        const tool = new ToolClass(this.getChart())
        tool.CompleteTool()

        const encoder = new TextEncoder()
        const uint8Array = encoder.encode(name)
        tool.SetAllTimeframes()
        tool.SymbolName = this.getChart().ChartWindow.SymbolData.symbolInfo.SymbolName

        if (isStatic) {
            this.getChart().staticPaintTools.AddTool(tool)
            this.getChart().staticPaintTools.ChangeToolName(tool, StrsConv.Utf8ToString(uint8Array))
        } else {
            this.getChart().PaintTools.AddTool(tool)
            this.getChart().PaintTools.ChangeToolName(tool, StrsConv.Utf8ToString(uint8Array))
        }

        if (objType === TObjectType.obj_VLine) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
        }
        if (objType === TObjectType.obj_HLine) {
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
        }
        if (
            objType === TObjectType.obj_Text
            // ||
            // objType === TObjectType.obj_Sign ||
            // objType === TObjectType.obj_RightPriceLabel ||
            // objType === TObjectType.obj_LeftPriceLabel
        ) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
        }
        if (
            objType === TObjectType.obj_TrendLine ||
            objType === TObjectType.obj_Ray ||
            objType === TObjectType.obj_FiboRetracement ||
            objType === TObjectType.obj_FiboTimeZones ||
            objType === TObjectType.obj_FiboArc ||
            objType === TObjectType.obj_FiboFan
            // ||
            // objType === TObjectType.obj_PriceLabel)
        ) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
        }
        if (objType === TObjectType.obj_Rectangle || objType === TObjectType.obj_Ellipse) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
            tool.SetProperty(ObjProp.OBJPROP_FILLINSIDE, 1)
            if (objType === TObjectType.obj_Rectangle) {
                const rectangle = tool as TPtRectangle
                if (rectangle.drawMiddleLine) {
                    rectangle.drawMiddleLine = false
                }
            }
        }
        if (objType === TObjectType.obj_Triangle) {
            tool.SetProperty(ObjProp.OBJPROP_TIME1, time1)
            tool.SetProperty(ObjProp.OBJPROP_PRICE1, price1)
            tool.SetProperty(ObjProp.OBJPROP_TIME2, time2)
            tool.SetProperty(ObjProp.OBJPROP_PRICE2, price2)
            tool.SetProperty(ObjProp.OBJPROP_TIME3, time3)
            tool.SetProperty(ObjProp.OBJPROP_PRICE3, price3)
        }

        result = true

        return result
    }

    public ObjectsTotal(isStatic = false): number {
        if (isStatic) {
            return this.getChart().staticPaintTools.length
        } else {
            return this.getChart().PaintTools.length
        }
    }

    public ObjectGet(name: string, index: ObjPropNumber | number, isStatic = false): number | string {
        let tool: IBasicPaintTool | null = null

        if (isStatic) {
            tool = this.getChart().staticPaintTools.GetTool(name)
        } else {
            tool = this.getChart().PaintTools.GetTool(name)
        }

        if (tool) {
            const stringProperty = tool.GetStringProperty(index)
            if (stringProperty) return stringProperty
            else return tool.GetNumberProperty(index)
        } else {
            throw new API_ObjectsError(`ObjectGet: object with name ${name} not found`)
        }
    }

    public ObjectGetString(name: string, index: ObjPropNumber | number, isStatic = false): string {
        let tool: IBasicPaintTool | null = null

        if (isStatic) {
            tool = this.getChart().staticPaintTools.GetTool(name)
        } else {
            tool = this.getChart().PaintTools.GetTool(name)
        }

        if (tool) {
            return tool.GetStringProperty(ObjPropStringInObjProp(index))
        } else {
            throw new API_ObjectsError(`ObjectGetString: object with name ${name} not found`)
        }
    }

    public ObjectGetNumber(name: string, index: ObjPropNumber | number, isStatic = false): number {
        let tool: IBasicPaintTool | null = null

        if (isStatic) {
            tool = this.getChart().staticPaintTools.GetTool(name)
        } else {
            tool = this.getChart().PaintTools.GetTool(name)
        }

        if (tool) {
            return tool.GetNumberProperty(ObjPropNumberInObjProp(index))
        } else {
            throw new API_ObjectsError(`ObjectGetNumber: object with name ${name} not found`)
        }
    }

    public ObjectSetText(
        name: string,
        text: string,
        fontSize = TMkFontStyle.DEFAULT_FONT_SIZE,
        fontName = TMkFontStyle.DEFAULT_FONT_NAME,
        fontColor: TColor = TMkFontStyle.DEFAULT_FONT_COLOR,
        isStatic = false
    ): boolean {
        let result = false
        let tool: IBasicPaintTool | null = null

        if (isStatic) {
            tool = this.getChart().staticPaintTools.GetTool(name)
        } else {
            tool = this.getChart().PaintTools.GetTool(name)
        }
        if (tool) {
            tool.SetProperty(ObjProp.OBJPROP_TEXT, text)
            const fontStyle = new TMkFontStyle(fontName, TMkFontStyle.DEFAULT_FONT_STYLE, fontSize, fontColor)
            tool.SetProperty(ObjProp.OBJPROP_TEXT_PARAMS, fontStyle)
            result = true
        }

        return result
    }

    public ObjectSet(name: string, index: number, value: any, isStatic = false): boolean {
        let result = false

        if (isStatic) {
            const tool = this.getChart().staticPaintTools.GetTool(name)
            if (tool) {
                tool.SetProperty(index, value)
                result = true
            }
        } else {
            const tool = this.getChart().PaintTools.GetTool(name)
            if (tool) {
                tool.SetProperty(index, value)
                result = true
            }
        }

        return result
    }

    public ObjectName(index: number, isStatic = false): string {
        if (isStatic) {
            return this.getChart().staticPaintTools.GetObjectName(index)
        } else {
            return this.getChart().PaintTools.GetObjectName(index)
        }
    }

    public ObjectType(name: string, isStatic = false): TObjectType {
        let tool: IBasicPaintTool | null = null
        if (isStatic) tool = this.getChart().staticPaintTools.GetTool(name)
        else tool = this.getChart().PaintTools.GetTool(name)

        if (tool) return objectNameToType(tool.ShortName)

        return TObjectType.obj_AnyObject
    }

    public ObjectGetText(name: string, isStatic = false): string {
        let tool: IBasicPaintTool | null = null

        if (isStatic) {
            tool = this.getChart().staticPaintTools.GetTool(name)
        } else {
            tool = this.getChart().PaintTools.GetTool(name)
        }

        if (tool) return tool.Text
        throw new API_ObjectsError(`ObjectGetText: object with name ${name} not found`)
    }

    public ObjectExists(uniqueObjectName: string, isStatic = false): boolean {
        if (isStatic) {
            return this.getChart().staticPaintTools.NameExists(uniqueObjectName)
        } else {
            return this.getChart().PaintTools.NameExists(uniqueObjectName)
        }
    }

    public ObjectDelete(uniqueObjectName: string, isStatic = false): void {
        if (isStatic) {
            this.getChart().staticPaintTools.DeleteTool(uniqueObjectName)
        } else {
            this.getChart().PaintTools.DeleteTool(uniqueObjectName)
        }
    }

    public ObjectsDeleteAll(objType: TObjectType, isStatic = false): void {
        if (isStatic) {
            this.getChart().staticPaintTools.DeleteAllToolsByType(objType)
        } else {
            this.getChart().PaintTools.DeleteAllToolsByType(objType)
        }
    }
}
