import { FC, useMemo, useCallback, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal, SimpleDropdown, InputField } from '@fto/ui'
import { Icon } from '@fto/icons'
import { fireMixpanelEvent } from '@root/utils/api'

import { TEXT_LOCALES } from '../../constants/texts'
import { REASONS } from '../../constants'

import Header from '../Header'
import Controls from '../Controls'
import { PeriodType, ReasonDataType } from '../../types'

import styles from './index.module.scss'

type Props = {
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
    price: string
    period: PeriodType
    setStep: (n: number) => void
    subscriptionStatus: 'monthly' | 'annual' | 'lifetime' | 'Unknown'
}

const PriceTooHighBlock: FC<Props> = ({ setSuspendData, price, period, setStep, subscriptionStatus }) => {
    const { t } = useTranslation()

    const options = useMemo(() => {
        return Object.entries(TEXT_LOCALES[REASONS.priceToHigh].periods).map(([key, label]) => ({
            value: key,
            label: t(label)
        }))
    }, [])

    const onPriceChange = useCallback(
        (value: string) => {
            setSuspendData((prev) => ({
                ...prev,
                data: { ...prev.data, [REASONS.priceToHigh]: { ...prev.data[REASONS.priceToHigh], price: value } }
            }))
        },
        [setSuspendData]
    )

    const onPeriodChange = useCallback(
        (value: PeriodType) => {
            setSuspendData((prev) => ({
                ...prev,
                data: { ...prev.data, [REASONS.priceToHigh]: { ...prev.data[REASONS.priceToHigh], period: value } }
            }))
        },
        [setSuspendData]
    )

    const onSubmit = useCallback(() => {
        setStep(2)
        fireMixpanelEvent('subscription_suspend_detailed_feedback', {
            initial_feedback: REASONS.priceToHigh,
            price,
            period,
            subscription_period: subscriptionStatus
        })
    }, [setStep, price, period, subscriptionStatus])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <Header reason={REASONS.priceToHigh} />
                    <div className={styles.GridWrapper}>
                        <InputField
                            value={price}
                            onChange={onPriceChange}
                            size='medium'
                            step={0.1}
                            type='number'
                            prefix={<Icon name='dollar' color='var(--color-gray-500)' size={18} />}
                            placeholder={t(TEXT_LOCALES[REASONS.priceToHigh].pricePlaceholder)}
                        />
                        <SimpleDropdown
                            value={period}
                            options={options}
                            onChange={onPeriodChange}
                            classNames={{
                                header: styles.PeriodsHeader,
                                root: styles.PeriodsHeader,
                                body: styles.PeriodsBody
                            }}
                        />
                    </div>
                </Flex>
            </Modal.Content>
            <Controls disabled={!price} onSubmit={onSubmit} />
        </>
    )
}

export default PriceTooHighBlock
