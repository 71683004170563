import StrangeError from '../common/common_errors/StrangeError'
import { TProjectInfo } from '../ft_types/common/BasicClasses/ProjectInfo'

export default class GlobalProjectInfo {
    private static _projectInfo: TProjectInfo | undefined = undefined

    public static get ProjectInfo(): TProjectInfo {
        if (!GlobalProjectInfo._projectInfo) {
            //project info should be initialized at the very beginning of the app, so it would be strange if someone would access it before it's initialized
            throw new StrangeError('ProjectInfo is not initialized')
        }
        return GlobalProjectInfo._projectInfo
    }

    public static set ProjectInfo(info: TProjectInfo) {
        GlobalProjectInfo._projectInfo = info
    }

    public static initEmptyProjectInfo(): void {
        GlobalProjectInfo._projectInfo = new TProjectInfo()
    }

    public static get IsProjectInfoInitialized(): boolean {
        return !!GlobalProjectInfo._projectInfo
    }
}
