import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Flex, Typography, addModal } from '@fto/ui'
import { Icon } from '@fto/icons'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $getUserSubscriptionStatus } from '@root/store/auth/selectors'

import styles from '../../profile.module.scss'

type Props = {
    chargeDate: Date | null
}

export const SubscriptionInfoSuspended: FC<Props> = ({ chargeDate }) => {
    const { t } = useTranslation()

    const { isActive } = useAppSelector($getUserSubscriptionStatus)

    const { trialPeriodEnd, isTrialExpired } = useAppSelector($getUser)

    const date = useMemo(() => {
        return dayjs(isTrialExpired ? chargeDate : trialPeriodEnd, 'MM/DD/YYYY h:mm A')
            .locale('en')
            .format('MMMM DD, YYYY')
    }, [trialPeriodEnd, chargeDate, isTrialExpired])
    return (
        <Flex direction='column' className={styles.subscriptionInfo}>
            <Flex justifyContent='space-between' alignItems='flex-end' className={styles.infoContent}>
                <Flex alignItems='flex-start' direction='column' gap={4}>
                    <Typography type='h4-bold'>
                        {isActive ? t('profile.modals.cancelled') : t('profile.modals.suspended')}
                    </Typography>
                    {chargeDate !== null && (
                        <Flex className={styles.warningWrapper} gap={4}>
                            <Icon name='attention' size={18} color='var(--color-orange-500)' />
                            <Typography type='interface-regular'>
                                <Trans
                                    i18nKey={
                                        isActive
                                            ? 'profile.subscriptionInfoCancelled'
                                            : 'profile.subscriptionInfoSuspended'
                                    }
                                    components={{ date: <Typography type='interface-regular'>{date}</Typography> }}
                                />
                            </Typography>
                        </Flex>
                    )}
                </Flex>
                <Flex className={styles.infoButton}>
                    <Button
                        label={t('profile.renew')}
                        type='marketing'
                        size='small'
                        onClick={() => {
                            addModal(MODAL_NAMES.userProfile.renewSubscription)
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
