import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Flex, addModal, removeModal, useModalState } from '@fto/ui'

import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import ErrorBoundaryPreview from '@root/components/ErrorBoundary/components/ErrorBoundaryPreview'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { BACK_TESTING_ROUTE, makeRoute } from '@root/constants/routes'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { DeleteProjectModal } from '@root/pages/Dashboard/components/Modals/DeleteProjectModal'
import DuplicateProjectModal from '@root/pages/Dashboard/components/Modals/DuplicateProjectModal/DuplicateProjectModal'
import { RenameProjectModal } from '@root/pages/Dashboard/components/Modals/RenameProjectModal'
import ProjectLoadingRow from '@root/pages/Dashboard/components/ProjectsTable/components/ProjectLoadingRow'
import TableHeadItem from '@root/pages/Dashboard/components/ProjectsTable/components/TableHeadItem'
import { $getProjectsStatus } from '@root/store/projects/projects.selector'
import { ProjectType } from '@root/types/projects'
import projectTableHead from '@root/pages/Dashboard/components/ProjectsTable/projectTable.model'

import { ProjectAnalytics } from '../ProjectsAnalytics/ProjectAnalytics'
import EmptyState from './components/EmptyState'
import ProjectRow from './components/ProjectRow'
import { LOADING_ROW_LIST } from './helpers/index'

import styles from './projects-table.module.scss'
import { setLocalStorage } from '@root/utils/localStorage'
import { PROJECT_STARTED_KEY } from '@root/constants/localStorageKeys'
import { useEffect } from 'react'
import { DeleteAllProjectsModal } from '@root/pages/Dashboard/components/Modals/DeleteAllProjectsModal'
import { addHotkey, HotkeyGroup, removeHotkey } from '@fto/lib/utils/Hotkeys'
import { DeleteAllProjectsCommand } from '@fto/lib/utils/FTOCommands/DeleteAllProjectsCommand'

export type ProjectTableHeadItem = {
    label: string
    value: string
    className?: string
}

type Props = {
    items: ProjectType[]
}

export type TableItemAction = 'delete' | 'rename' | 'duplicate' | 'open'

const ProjectsTable: FC<Props> = ({ items }) => {
    const [selectedId, setSelectedId] = useState('')
    const status = useAppSelector($getProjectsStatus)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { open: isRenameModalOpen } = useModalState(MODAL_NAMES.projects.rename)

    const isLoading = useMemo(() => {
        return status === 'idle' || status === 'pending'
    }, [status])

    const openTester = useCallback(
        (id: ProjectType['Id']) => {
            GlobalChartsController.Instance.clearAllChartWindows()
            SyncProjectManager.Instance.DownloadProject(id)
            navigate(`${makeRoute(BACK_TESTING_ROUTE)}?projectId=${id}`)

            const selectedProject = items.find((project) => project.Id === id)

            if (selectedProject) {
                // NOTE: here we set trigger for project_started event to track project load time

                setLocalStorage(PROJECT_STARTED_KEY, {
                    projectStartedTime: Date.now(),
                    projectData: {
                        ...selectedProject,
                        Statistics: selectedProject.Statistics
                            ? JSON.parse(selectedProject.Statistics)
                            : selectedProject.Statistics,
                        Chart: selectedProject.Chart ? JSON.parse(selectedProject.Chart) : selectedProject.Chart
                    }
                })
            }
        },
        [items]
    )

    const openAnalytics = useCallback((id: string) => {
        setSelectedId(id)
        addModal(MODAL_NAMES.projects.analytics)
    }, [])

    const handleAction = useCallback((type: TableItemAction, id: string) => {
        setSelectedId(id)

        switch (type) {
            case 'delete': {
                addModal(MODAL_NAMES.projects.delete)
                break
            }
            case 'rename': {
                addModal(MODAL_NAMES.projects.rename)
                break
            }
            case 'duplicate': {
                addModal(MODAL_NAMES.projects.duplicate)
                break
            }
            default: {
                break
            }
        }
        removeModal(MODAL_NAMES.projects.analytics)
    }, [])

    const hideHeaderClass = useCallback((value: ProjectTableHeadItem['value']) => {
        switch (value) {
            case 'pairs': {
                return styles.onLargeTabletHide
            }
            case 'balance':
            case 'period': {
                return styles.onSmallTabletHide
            }
            case 'profitAndLoss': {
                return styles.onMobileHide
            }
            default: {
                return ''
            }
        }
    }, [])

    useEffect(() => {
        const hotkey = {
            group: 'Debug' as HotkeyGroup,
            label: 'Delete all projects',
            keyCode: 'F2',
            command: new DeleteAllProjectsCommand(),
            isCtrl: true,
            isShift: true,
            isAlt: true,
            timesUsed: 0
        }
        addHotkey(hotkey)

        return () => {
            removeHotkey(hotkey)
        }
    }, [])

    if (status === 'failed' && items.length === 0) {
        return (
            <Flex className={styles.ErrorStateWrapper} alignItems='center' justifyContent='center'>
                <ErrorBoundaryPreview noBoxShadow />
            </Flex>
        )
    }

    if (!isLoading && items.length === 0 && status === 'succeeded') {
        return <EmptyState />
    }

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr>
                        {projectTableHead.map(({ label, value }) => (
                            <TableHeadItem
                                className={hideHeaderClass(label)}
                                label={t(`dashboard.table.thead.${value}`)}
                                key={value}
                            />
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    {isLoading && LOADING_ROW_LIST.map((v, i) => <ProjectLoadingRow key={i} />)}
                    {!isLoading &&
                        items.length > 0 &&
                        items.map((item) => (
                            <ProjectRow
                                key={item.Id}
                                projectData={item}
                                handleContextMenuAction={handleAction}
                                openTester={openTester}
                                onClick={() => {
                                    openTester(item.Id)
                                }}
                            />
                        ))}
                </tbody>
            </table>
            <DeleteProjectModal projectId={selectedId} />
            {isRenameModalOpen && <RenameProjectModal projectId={selectedId} />}
            <DuplicateProjectModal projectId={selectedId} />
            <ProjectAnalytics projectId={selectedId} handleContextMenuAction={handleAction} openTester={openTester} />
            <DeleteAllProjectsModal />
        </div>
    )
}

export default ProjectsTable
