import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { AvailableOptionsSettings, ConfigType, Option } from '../types'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { setBooleanValue, setNumberValue, setStringValue } from '.'

function isColorSettingOption(option: Option) {
    return option?.type === 'colorSetting'
}

function isCheckboxAndColorSettingOption(option: Option) {
    return option?.type === 'checkboxAndColorSetting'
}

function isSliderSettingOption(option: Option) {
    return option?.type === 'sliderSetting'
}

function isInputSettingOption(option: Option) {
    return option?.type === 'inputSetting'
}

function isCheckboxSettingOption(option: Option) {
    return option?.type === 'checkboxSetting'
}

function isDropdownSettingOption(option: Option) {
    return option?.type === 'dropdownSetting'
}

export function getConfigUnit(chart: TChartWindow, optionType: ConfigType) {
    const chartOptions = chart.ChartOptions
    const colorScheme = chartOptions.ColorScheme

    if (optionType === ConfigType.ColorScheme) {
        return colorScheme
    }

    if (optionType === ConfigType.ChartOptions) {
        return chartOptions
    }

    if (optionType === ConfigType.GlobalOptions) {
        return GlobalOptions.Options
    }

    throw new StrangeError('Unknown option config type')
}

export const getOptionState = (option: Option, chart: TChartWindow) => {
    if (isColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            }
        }
    }

    if (isCheckboxAndColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            },
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isSliderSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isInputSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isCheckboxSettingOption(option)) {
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    return { notNow: true }
}

export const getSeparatedOptionsStates = (
    optionsConfig: { [key: string]: AvailableOptionsSettings },
    chart: TChartWindow,
    optionsStates: { [key: string]: any } = {}
) => {
    for (const key in optionsConfig) {
        const option = optionsConfig[key]

        if (key in optionsStates) throw new StrangeError('Option state already exists')

        optionsStates[key] = getOptionState(option, chart)

        if ('subOptions' in option) {
            const subOptions = getSeparatedOptionsStates(option.subOptions!, chart)

            for (const subKey in subOptions) {
                if (subKey in optionsStates) throw new StrangeError('Option state already exists')
                optionsStates[subKey] = subOptions[subKey]
            }
        }
    }

    return optionsStates
}

export const setOptionState = (chart: TChartWindow, optionConfig: Option, savedOption: Option) => {
    console.log('setOptionState', optionConfig, savedOption)
    if (!optionConfig) {
        // Ignore template option that is not in the config
        console.warn('The template has deprecated option')
        return
    }

    if (!savedOption) {
        // Ignore template option that is not in the saved options
        console.warn('The template has not new option')
        return
    }

    if (isColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value)
        return
    }
    if (isCheckboxAndColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value)
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked)
        return
    }
    if (isSliderSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    if (isInputSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    if (isCheckboxSettingOption(savedOption)) {
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked)
        return
    }
    if (isDropdownSettingOption(savedOption)) {
        setNumberValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value)
        return
    }
    throw new StrangeError('Unknown option type')
}
