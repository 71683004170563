import { ColumnsListType, TabType, TerminalCellSortingState } from '@root/pages/ChartPage/components/Terminal/types'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { IconButton } from '@fto/icons'
import styles from './index.module.scss'
import { UserTerminalSettings } from '@root/utils/localStorage'
import classNames from 'classnames'

type Props = {
    cell: ColumnsListType[number]
    activeTab: TabType
    tableColumns: UserTerminalSettings
    setTableColumns: Dispatch<SetStateAction<UserTerminalSettings>>
}

const TerminalSortingCell: FC<Props> = ({ tableColumns, setTableColumns, activeTab, cell: { value } }) => {
    const [cellState, setCellState] = useState<TerminalCellSortingState>('unactive')

    const iconStyle: () => TerminalCellSortingState = () => {
        const sortSettings = tableColumns[activeTab].sorting
        if (!sortSettings) return 'unactive'
        return sortSettings.key === value ? sortSettings.direction : 'unactive'
    }

    return (
        <IconButton
            color={'var(--color-gray-500)'}
            size={12}
            className={classNames(styles.button, styles[iconStyle()])}
            name={'double-chevron'}
        />
    )
}

export default TerminalSortingCell
