import TIndicatorDescriptorList from '../extension_modules/indicators/IndicatorDescriptorList'
import BuiltInIndicatorDescriptors from '../extension_modules/indicators/built_in_indicators/list/BuiltInIndicatorsList'

export default class GlobalIndicatorDescriptors {
    private static fIndicatorDescriptors: TIndicatorDescriptorList
    public static fBuiltInIndicatorDescriptors: BuiltInIndicatorDescriptors

    public static Init(): void {
        GlobalIndicatorDescriptors.fIndicatorDescriptors = new TIndicatorDescriptorList()
        GlobalIndicatorDescriptors.fBuiltInIndicatorDescriptors = new BuiltInIndicatorDescriptors()
    }

    public static get IndicatorDescriptors(): TIndicatorDescriptorList {
        if (!GlobalIndicatorDescriptors.fIndicatorDescriptors) {
            GlobalIndicatorDescriptors.Init()
        }
        return GlobalIndicatorDescriptors.fIndicatorDescriptors
    }

    public static get BuiltInIndicators(): BuiltInIndicatorDescriptors {
        if (!GlobalIndicatorDescriptors.fBuiltInIndicatorDescriptors) {
            GlobalIndicatorDescriptors.Init()
        }
        return GlobalIndicatorDescriptors.fBuiltInIndicatorDescriptors
    }
}
