import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import cx from 'classnames'

import _pickBy from 'lodash/pickBy'

import styles from './index.module.scss'

const pickDefined = (obj: object) => _pickBy(obj, (value) => value !== undefined)

export const FlexDirections = ['column', 'column-reverse', 'row', 'row-reverse'] as const
export const FlexAligns = ['normal', 'flex-start', 'flex-end', 'center', 'stretch', 'baseline'] as const
export const FlexJustifyContent = [
    'normal',
    'flex-start',
    'flex-end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly'
] as const
export const FlexSpacings = [0, 2, 4, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56]

export const FlexWrap = ['wrap', 'nowrap', 'wrap-reverse']

interface Props {
    direction?: (typeof FlexDirections)[number]
    alignItems?: (typeof FlexAligns)[number]
    justifyContent?: (typeof FlexJustifyContent)[number]
    flexWrap?: (typeof FlexWrap)[number]
    spacing?: (typeof FlexSpacings)[number]
    vspacing?: (typeof FlexSpacings)[number]
    gap?: (typeof FlexSpacings)[number]
    inline?: boolean
    block?: boolean
    flexGrow?: number
    flexShrink?: number
    flexBasis?: number
    component?: React.ElementType
    className?: string
    flex?: string
}

export type FlexProps = Omit<ComponentPropsWithoutRef<'div'>, keyof Props> & Props

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
    (
        {
            children,
            direction = 'row',
            inline = false,
            alignItems,
            justifyContent,
            flexWrap,
            flexBasis,
            flexGrow,
            flexShrink,
            spacing,
            vspacing = spacing,
            gap,
            block,
            className,
            component: Component = 'div' as any,
            style,
            flex,
            ...rest
        },
        ref
    ) => (
        <Component
            ref={ref}
            className={cx(
                styles.root,
                {
                    [styles[`flex-inline-${direction}`]]: inline,
                    [styles[`flex-${direction}`]]: !inline,
                    [styles[`flex-align-items-${alignItems}`]]: alignItems,
                    [styles[`flex-justify-content-${justifyContent}`]]: justifyContent,
                    [styles[`flex-wrap-${flexWrap}`]]: flexWrap,
                    [styles[`spacing-${spacing}`]]: spacing,
                    [styles[`vspacing-${vspacing}`]]: vspacing,
                    [styles[`gap-${gap}`]]: gap,
                    [styles.block]: block
                },
                className
            )}
            style={{
                ...style,
                ...pickDefined({ flex, flexBasis, flexGrow, flexShrink })
            }}
            {...rest}
        >
            {React.Children.map(children, (child) => child)}
        </Component>
    )
)

Flex.displayName = 'Flex'

export default Flex
