import React, { FC, useState, useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex, Tooltip, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { SymbolsCategoryType } from '@root/mapper/symbols.mapper'
import { SymbolItemType } from '@root/types/symbols.types'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

import style from './create-project-modal-symbols.module.scss'

type Props = {
    symbolsGroup: SymbolsCategoryType
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
}

const CreateProjectModalSymbolsGroup: FC<Props> = ({ symbolsGroup, formData, setFormData }) => {
    const { t } = useTranslation()

    const [isOpened, setIsOpened] = useState(true)

    const isSymbolsLimitReached = useCallback(() => {
        return formData.Symbols ? formData.Symbols.length === 4 : false
    }, [formData.Symbols])

    const toggleSelect = useCallback(
        (symbol: SymbolItemType, isDisabled: boolean) => {
            const symbols = formData.Symbols

            if (!symbols || isDisabled) return
            const isExist = symbols.find((item: SymbolItemType) => item.Symbol === symbol.Symbol)

            if (isExist) {
                const updatedSymbols = symbols.filter((item: SymbolItemType) => item.Symbol !== symbol.Symbol)
                setFormData((prev) => ({ ...prev, Symbols: updatedSymbols }))
            } else {
                setFormData((prev) => ({ ...prev, Symbols: [...(symbols || []), symbol] }))
            }
        },
        [formData.Symbols, setFormData]
    )

    const isInputChecked = useCallback(
        (symbol: SymbolItemType) => formData.Symbols?.find((s) => s.Symbol === symbol.Symbol),
        [formData.Symbols]
    )

    const toggleGroupOpenState = useCallback(() => setIsOpened((prev) => !prev), [])

    if (symbolsGroup.symbols.length === 0) return null

    return (
        <div>
            <Flex className={style.categoryTitle} alignItems={'center'} onClick={toggleGroupOpenState} gap={8}>
                <Icon name={isOpened ? 'minus' : 'plus'} size={12} className={style.ToggleIcon} />
                <Typography type='interface-regular'>{symbolsGroup.category}</Typography>
            </Flex>
            {isOpened && (
                <div className={style.symbolListGroup}>
                    {symbolsGroup.symbols.map((symbol) => {
                        const isSelected = !!isInputChecked(symbol)
                        const isDisabled = isSymbolsLimitReached() && !isSelected

                        return (
                            <Tooltip
                                placement='top'
                                classNames={{ content: style.Tooltip }}
                                content={isDisabled ? t('dashboard.modals.projects.toasts.maxSymbols') : false}
                            >
                                <Flex
                                    direction='column'
                                    gap={4}
                                    className={cx(style.symbolCheckboxWrap, { [style.disabled]: isDisabled })}
                                    onClick={() => toggleSelect(symbol, isDisabled)}
                                    justifyContent='center'
                                >
                                    <Flex gap={8} alignItems='center'>
                                        <Checkbox disabled={isDisabled} checked={isSelected} key={symbol.Symbol} />
                                        <Typography type={'interface-regular'}>{symbol.Symbol}</Typography>
                                    </Flex>
                                    {symbol.Description && (
                                        <Typography
                                            className={style.Description}
                                            type={'subtext-regular'}
                                            color='gray-800'
                                            truncate
                                            title={symbol.Description}
                                        >
                                            {symbol.Description}
                                        </Typography>
                                    )}
                                </Flex>
                            </Tooltip>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default CreateProjectModalSymbolsGroup
