import { Dispatch, FC, SetStateAction } from 'react'

import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'

import { FilenameTypes, Icon } from '@fto/icons'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Option = {
    value: string | number
    iconName: (typeof FilenameTypes)[number]
    localeKey: string
}

type Props = {
    options: Option[]
    onChange: Dispatch<SetStateAction<any>>
    currentValue: string | number
}

const FilterDropdown: FC<Props> = ({ options, onChange, currentValue }) => {
    const { t } = useTranslation()

    return (
        <Flex direction='column'>
            {options.map((item) => {
                const isSelected = currentValue === item.value
                return (
                    <Flex
                        key={item.value}
                        gap={8}
                        alignItems='center'
                        className={cx(styles.Option, { [styles.Selected]: isSelected })}
                        onClick={() => onChange(item.value)}
                    >
                        {isSelected && <Icon name={item.iconName} size={12} color='var(--color-primary-500)' />}
                        <Typography type='interface-medium' color={isSelected ? 'primary-500' : 'gray-1000'}>
                            {t(item.localeKey)}
                        </Typography>
                    </Flex>
                )
            })}
        </Flex>
    )
}

export default FilterDropdown
