import { FC, ReactNode, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, Typography, Button, Flex, removeModal, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import styles from './index.module.scss'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

type Props = {
    isEdit?: boolean
    headerLabel?: string | ReactNode
}

const EducationModal: FC<Props> = observer(({ isEdit = false, headerLabel }) => {
    const [taskJSON, setTaskJSON] = useState('')

    const handleModalClose = useCallback(() => {
        removeModal(MODAL_NAMES.chart.educationModal)
    }, [])

    const handleJSONChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTaskJSON(event.target.value)
    }, [])

    const handleProcess = useCallback(() => {
        try {
            const json = JSON.parse(taskJSON)
            GlobalProjectJSONAdapter.Instance.setHomeWorksToProject(taskJSON.toString())
            EducationProcessor.Instance.parseTask(json)
            handleModalClose()
            addModal(MODAL_NAMES.messages.educationMessage, { message: json.intro })
        } catch (error) {
            showErrorToast({
                message: (error as Error).message
            })
        }
    }, [taskJSON])

    return (
        <Modal name={MODAL_NAMES.chart.educationModal} size='sm' isDraggable onClose={handleModalClose}>
            <Modal.Header>{headerLabel || <Typography>Education Task Processor</Typography>}</Modal.Header>
            <Modal.Content className={styles.Content}>
                <Flex direction='column' gap={8}>
                    <Typography>Please, paste here JSON that contains the task for the education processor:</Typography>
                </Flex>
                <textarea className={styles.Textarea} value={taskJSON} onChange={handleJSONChange}></textarea>
            </Modal.Content>
            <Modal.Controls className={styles.ControlsWrapper}>
                <Button
                    onClick={handleProcess}
                    label={'Add task'}
                    classNames={{ root: styles.SubmitButton, content: styles.SubmitButton }}
                ></Button>
            </Modal.Controls>
        </Modal>
    )
})

export default EducationModal
