export enum TChunkStatus {
    cs_Empty = 'Empty',
    cs_InQueue = 'InQueue',
    cs_Building = 'Building',
    cs_Loaded = 'Loaded',
    cs_PartiallyFilled = 'PartiallyFilled',
    cs_InvalidDataOnServer = 'InvalidDataOnServer'
}

export enum TNoExactMatchBehavior {
    nemb_ReturnNearestLower = 'ReturnNearestLower',
    nemb_ReturnNearestHigher = 'ReturnNearestHigher',
    nemb_ThrowError = 'ThrowError'
}
