import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { ProjectJSON } from '@fto/lib/ProjectAdapter/Types'

import {
    errorToast,
    successToast
} from '@root/pages/Dashboard/components/Modals/CreateProjectModal/helpers/CreateProjectModalToasts'
import { RootState } from '@root/store'
import { CreateProjectPayload, ProjectType } from '@root/types/projects'
import { Pagable } from '@root/types/store.types'
import { fireMixpanelEvent, secureApi } from '@root/utils/api'
import queryParamsUtil from '@root/utils/query-params.util'

const path = 'projects/api/Projects'

const GET_PROJECTS_ACTION = createAsyncThunk<Pagable<ProjectType>, void>(
    'projects/GET_PROJECTS_ACTION',
    async (_, { rejectWithValue, getState }) => {
        const {
            projects: { query }
        } = getState() as RootState

        const queryStr = queryParamsUtil(query)

        try {
            const { data } = await secureApi.get<Pagable<ProjectType>>(path + queryStr)
            return data
        } catch (error_: unknown) {
            const error = error_ as AxiosError
            if (error.status) {
                errorToast(error.status.toString())
            }
            return rejectWithValue(error.status)
        }
    }
)

const CREATE_PROJECT_ACTION = createAsyncThunk<ProjectType, CreateProjectPayload>(
    'projects/CREATE_PROJECT_ACTION',
    async (project, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await secureApi.post<ProjectType>(path, project)
            dispatch(GET_PROJECTS_ACTION())
            fireMixpanelEvent('project_created', data)

            successToast('create')
            return data
        } catch (error_: unknown) {
            const error = error_ as AxiosError
            if (error.status) {
                errorToast(error.status.toString())
            }
            return rejectWithValue(error.status)
        }
    }
)

const DUPLICATE_PROJECT_ACTION = createAsyncThunk<ProjectType, ProjectType>(
    'projects/DUPLICATE_PROJECT_ACTION',
    async (project, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await secureApi.post<ProjectType>(`${path}/${project.Id}/duplicate`, {
                ...project
            })
            dispatch(GET_PROJECTS_ACTION())
            fireMixpanelEvent('project_duplicated', {})

            successToast('duplicate')
            return data
        } catch (error_: unknown) {
            const error = error_ as AxiosError
            if (error.status) {
                errorToast(error.status.toString())
            }
            return rejectWithValue(error.status)
        }
    }
)

const RENAME_PROJECT_ACTION = createAsyncThunk<
    ProjectType,
    {
        id: string
        projectName: ProjectType['ProjectName']
    }
>('projects/RENAME_PROJECT_ACTION', async ({ id, projectName }, { rejectWithValue, dispatch }) => {
    try {
        const { data } = await secureApi.patch<ProjectType>(`${path}/${id}/rename`, {
            ProjectName: projectName
        })
        dispatch(GET_PROJECTS_ACTION())

        successToast('rename')
        return data
    } catch (error_: unknown) {
        const error = error_ as AxiosError
        if (error.status) {
            errorToast(error.status.toString())
        }
        return rejectWithValue(error.status)
    }
})

export const EDIT_PROJECT_ACTION = createAsyncThunk<
    ProjectJSON,
    {
        id: string
        projectUpdate: Partial<ProjectJSON>
    }
>('projects/EDIT_PROJECT_ACTION', async ({ id, projectUpdate }, { rejectWithValue, dispatch }) => {
    try {
        const { data } = await secureApi.put<ProjectJSON>(`${path}/${id}`, projectUpdate)
        dispatch(GET_PROJECTS_ACTION()) // Refresh the list of projects, if necessary

        successToast('edit')
        return data
    } catch (error_: unknown) {
        const error = error_ as AxiosError
        if (error.status) {
            errorToast(error.status.toString())
        }
        return rejectWithValue(error.response?.status)
    }
})

const DELETE_PROJECT_ACTION = createAsyncThunk<
    void,
    {
        id: string
        project: ProjectType
    }
>('projects/DELETE_PROJECT_ACTION', async ({ id, project }, { rejectWithValue, dispatch }) => {
    try {
        await secureApi.delete(`${path}/${id}`)

        fireMixpanelEvent('project_deleted', {
            ...project,
            Statistics: project.Statistics ? JSON.parse(project.Statistics) : project.Statistics,
            Chart: project.Chart ? JSON.parse(project.Chart) : project.Chart
        })
        dispatch(GET_PROJECTS_ACTION())

        successToast('delete')
    } catch (error_: unknown) {
        const error = error_ as AxiosError
        if (error.status) {
            errorToast(error.status.toString())
        }
        return rejectWithValue(error.status)
    }
})

export const DELETE_ALL_PROJECTS_ACTION = createAsyncThunk(
    'projects/deleteAllProjects',
    async (projects: ProjectType[] | null, { dispatch }) => {
        if (projects) {
            const deletePromises = projects.map((project: ProjectType) =>
                dispatch(DELETE_PROJECT_ACTION({ id: project.Id, project }))
            )
            await Promise.all(deletePromises)
        }
    }
)

const anyProjectActionRejected = isRejected(
    GET_PROJECTS_ACTION,
    CREATE_PROJECT_ACTION,
    DELETE_PROJECT_ACTION,
    RENAME_PROJECT_ACTION,
    DUPLICATE_PROJECT_ACTION
)
const anyProjectActionPending = isPending(
    GET_PROJECTS_ACTION,
    CREATE_PROJECT_ACTION,
    DELETE_PROJECT_ACTION,
    RENAME_PROJECT_ACTION,
    DUPLICATE_PROJECT_ACTION
)
const anyProjectActionFulfilled = isFulfilled(
    GET_PROJECTS_ACTION,
    CREATE_PROJECT_ACTION,
    DELETE_PROJECT_ACTION,
    RENAME_PROJECT_ACTION,
    DUPLICATE_PROJECT_ACTION
)
export { anyProjectActionRejected, anyProjectActionPending, anyProjectActionFulfilled }
export {
    GET_PROJECTS_ACTION,
    CREATE_PROJECT_ACTION,
    DELETE_PROJECT_ACTION,
    RENAME_PROJECT_ACTION,
    DUPLICATE_PROJECT_ACTION
}
