import { FC } from 'react'

import dayjs from 'dayjs'

import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'

import { IconButton } from '@fto/icons'

import { TIME_TEMPLATES } from '@root/constants/timeTemplates'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getProject } from '@root/store/projects/projects.selector'
import { ProjectType } from '@root/types/projects'
import { formatMoney, timeToMessageFilters } from '@root/utils/filters'

import { TableItemAction } from '../ProjectsTable/ProjectsTable'
import TableItemContextMenu from '../TableItemContextMenu/TableItemContextMenu'
import { AnalyticsPairs } from './components/AnalyticsPairs'

import styles from './projects-analytics.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    projectId: string
    handleContextMenuAction: (type: TableItemAction, id: string) => void
    openTester: (is: ProjectType['Id']) => void
}

export const ProjectAnalytics: FC<Props> = ({ projectId, handleContextMenuAction, openTester }) => {
    const { t } = useTranslation()

    const project = useAppSelector((s) => $getProject(s, projectId))
    if (!project) return <></>
    const { Deposit, Created, LastUse, ProjectName, EndDate, StartDate, Symbols } = project
    const hideModal = () => {
        removeModal(MODAL_NAMES.projects.analytics)
    }

    const startTesting = () => {
        openTester(projectId)
    }

    return (
        <Modal name={MODAL_NAMES.projects.analytics} additionalClassNames={{ container: styles.container }}>
            <Modal.Header className={styles.header}>
                <Flex alignItems='center' className={styles.headerContent}>
                    <Flex direction='column' block>
                        <Typography type='h4-semibold'>{ProjectName}</Typography>
                        <Typography color={'gray-700'} type={'subtext-regular'}>
                            {t('analytics.timeInfo', {
                                createdDate: dayjs(Created).format(TIME_TEMPLATES.DASHBOARD_NAME),
                                testedDate: timeToMessageFilters(LastUse)
                            })}
                        </Typography>
                    </Flex>
                    <Flex justifyContent='flex-end' gap={8}>
                        <TableItemContextMenu
                            projectId={projectId}
                            action={handleContextMenuAction}
                            openTester={startTesting}
                        />
                        <IconButton
                            name='close'
                            color={'var(--color-gray-1000)'}
                            className={styles.icon}
                            onClick={hideModal}
                            size={14}
                        />
                    </Flex>
                </Flex>
                <Flex direction='row' className={styles.generalInfo}>
                    <Flex direction='column' block>
                        <Typography type='interface-semibold'>{t('analytics.testingPeriod')}</Typography>
                        <Typography color={'gray-900'} type={'interface-regular'}>
                            {dayjs(StartDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)} - {''}
                            {dayjs(EndDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)}
                        </Typography>
                    </Flex>
                    <Flex direction='column' block>
                        <Typography type='interface-semibold'>{t('analytics.balance')}</Typography>
                        <Typography color={'gray-900'} type={'interface-regular'}>
                            {formatMoney(Deposit)}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex direction='column' className={styles.symbols} gap={4}>
                    <Typography type='interface-semibold'>{t('analytics.symbols')}</Typography>
                    <AnalyticsPairs pairs={Symbols} />
                </Flex>
            </Modal.Header>
            <Modal.Content></Modal.Content>
            <Modal.Controls className={styles.actionButton}>
                <Button label={t('analytics.startProject')} onClick={startTesting} type='primary' block></Button>
            </Modal.Controls>
        </Modal>
    )
}
