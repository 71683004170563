import { Command } from './Command'
import KeyboardTracker from '../KeyboardTracker'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

class ToolRotateDisableCommand extends Command {
    public execute(): void {
        GlobalOptions.Options.TempRotateMode = false
        GlobalChartsController.Instance.doFakeMouseMove()
        KeyboardTracker.getInstance().isFakeMouseMoveDone = false
    }
}

export { ToolRotateDisableCommand }
