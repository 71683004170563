import { TCriteriaOperator } from '@fto/lib/Education/Types'
import { TutorialCheckBase } from '@fto/lib/Education/TutorialTasks/CriteriaChecks/TutorialCheckBase'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'

export class NumberOfOpenedOrders extends TutorialCheckBase {
    constructor(criteriaOperator: TCriteriaOperator, value: number | { valueStart: number; valueEnd: number }) {
        super(criteriaOperator, value)
    }

    checkEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length === (this.value as number)
    }

    checkMore(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length > (this.value as number)
    }

    checkLess(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length < (this.value as number)
    }

    checkNotEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length !== (this.value as number)
    }

    checkMoreOrEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length >= (this.value as number)
    }

    checkLessOrEqual(): boolean {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.length <= (this.value as number)
    }

    checkRange(): boolean {
        return (
            GlobalProcessingCore.ProcessingCore.OpenPositions.length >=
                (this.value as { valueStart: number; valueEnd: number }).valueStart &&
            GlobalProcessingCore.ProcessingCore.OpenPositions.length <=
                (this.value as { valueStart: number; valueEnd: number }).valueEnd
        )
    }

    public check(): boolean {
        return super.check()
    }
}
