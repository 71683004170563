import { FC } from 'react'

import { Flex, InputField, Checkbox } from '@fto/ui'
import { Icon } from '@fto/icons'

import LineStylePicker from '@root/pages/ChartPage/components/lineStylePicker'
import { LevelType } from '@fto/lib/drawing_interface/GraphicObjects/LevelType'

import { LevelCreatorTypes } from '@fto/chart_components/LevelsCreator/types'

import styles from './index.module.scss'

type Props = {
    level: LevelType
    removeLevel: LevelCreatorTypes['removeLevel']
    toggleLevel: LevelCreatorTypes['toggleLevel']
    handleLevelValueChange: LevelCreatorTypes['handleLevelValueChange']
    updateStyles: LevelCreatorTypes['updateStyles']
    withOpacity?: boolean
    withLabel?: boolean
}

const Row: FC<Props> = ({
    level,
    updateStyles,
    handleLevelValueChange,
    toggleLevel,
    removeLevel,
    withOpacity = false,
    withLabel = true
}) => {
    const { id } = level

    return (
        <Flex gap={16} key={level.id} alignItems='flex-end'>
            <Flex alignItems='center' gap={8}>
                <Checkbox checked={level.isActive} onChange={(value) => toggleLevel(value, id)} />
                <div className={styles.inputWrapper}>
                    <InputField
                        step={1}
                        type='number'
                        onChange={(value) => handleLevelValueChange(value, id)}
                        value={level.value as string}
                    />
                </div>
            </Flex>
            <Flex alignItems='flex-end' gap={8}>
                <LineStylePicker
                    data={{ data: level as any }}
                    onChange={(val) => updateStyles(val, id)}
                    attribute='data'
                    withOpacity={withOpacity}
                    withLabel={withLabel}
                />

                <Flex
                    alignItems='center'
                    justifyContent='center'
                    className={styles.removeBtn}
                    onClick={() => removeLevel(id)}
                >
                    <Icon name='delete' size={18} />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Row
