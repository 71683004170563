import StrangeError from '../../../common/common_errors/StrangeError'
import TStringList from '../../../delphi_compatibility/StringList'
import { TLineStyleRec } from '../../../drawing_interface/GraphicObjects/TLineStyleRec'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import GlobalSymbolList from '../../../globals/GlobalSymbolList'
import GlobalTimeframes from '../../../globals/GlobalTimeframes'
import { NotImplementedError } from '../../../utils/common_utils'
import { TDrawStyle } from '../CommonExternalInterface'
import {
    TOptValue,
    TOptionType,
    TOptValue_str,
    TOptValue_number,
    TOptValue_bool,
    TOptValue_DateTime,
    TOptValue_Session,
    TOptValue_SessionsArray,
    TOptValue_empty,
    TOptValue_LineStyleRec
} from '../CommonTypes'

export class TDLLOptionRec {
    private fSelfValue!: TOptValue // self value memory
    private OptValueWrapper: TOptValue // pointer to value memory (to self or to dll)

    name: string
    alias: string
    OptionType: TOptionType
    values!: TStringList
    LowValue!: number
    HighValue!: number
    digits!: number
    isvisible!: boolean
    useInNameWithParams: boolean = true

    constructor(
        aName: string,
        aType: TOptionType,
        optPtrOrValue?: TOptValue | string | number | boolean | TLineStyleRec | null,
        inv = true
    ) {
        this.name = aName
        this.alias = aName
        this.OptionType = aType
        this.OptValueWrapper = this.resolvePointer(optPtrOrValue)
        this.isvisible = inv

        this.values = new TStringList()
        this.RefreshValues()
    }

    private resolvePointer(optPtrOrValue?: TOptValue | string | number | boolean | TLineStyleRec | null): TOptValue {
        if (optPtrOrValue instanceof TOptValue) {
            return optPtrOrValue
        } else if (typeof optPtrOrValue === 'string') {
            return new TOptValue_str(optPtrOrValue)
        } else if (typeof optPtrOrValue === 'number') {
            return new TOptValue_number(optPtrOrValue)
        } else if (typeof optPtrOrValue === 'boolean') {
            return new TOptValue_bool(optPtrOrValue)
        } else if (optPtrOrValue instanceof TLineStyleRec) {
            //ask Dima
            return new TOptValue_LineStyleRec(optPtrOrValue)
            // eslint-disable-next-line unicorn/no-negated-condition
        } else if (!optPtrOrValue) {
            return new TOptValue_empty()
        } else {
            throw new StrangeError(`Invalid option value ${optPtrOrValue}`)
        }
    }

    public get Value(): string {
        return this.GetValue()
    }

    public set Value(value: string) {
        this.SetValue(value)
    }

    public GetValue(): string {
        switch (this.OptionType) {
            case TOptionType.ot_String:
            case TOptionType.at_Levels:
            case TOptionType.ot_Currency: {
                const str_optValue = this.OptValueWrapper as TOptValue_str
                return str_optValue.value
            }
            case TOptionType.ot_Longword:
            case TOptionType.ot_Integer:
            case TOptionType.ot_Timeframe: {
                const num_optValue = this.OptValueWrapper as TOptValue_number
                return num_optValue.value.toString()
            }

            case TOptionType.ot_double: {
                const double_optValue = this.OptValueWrapper as TOptValue_number
                return double_optValue.value.toFixed(this.digits)
            }
            case TOptionType.ot_Boolean: {
                // Simplified boolean representation to match Delphi's ternary-like behavior.
                const bool_optValue = this.OptValueWrapper as TOptValue_bool
                return bool_optValue.value ? 'Yes' : 'No'
            }
            case TOptionType.ot_EnumType: {
                // Check for undefined to handle illegal values.
                const enum_optValue = this.OptValueWrapper as TOptValue_number
                const enumValue = this.values[enum_optValue.value]
                return enumValue ?? 'unsupported value'
            }
            case TOptionType.ot_LineStyle: {
                let isNeedToShowLineProperties = true
                const lineStyle_optValue = this.OptValueWrapper as TOptValue_LineStyleRec
                if (lineStyle_optValue.value.DrawingStyle === TDrawStyle.ds_Histogram) {
                    isNeedToShowLineProperties = false
                }
                // Assuming GetShortStr is a method of the LineStyle object that returns a string representation.
                return lineStyle_optValue.value.GetShortStr(isNeedToShowLineProperties)
            }
            case TOptionType.ot_Color: {
                // Assuming StrColor is a function that converts a color value to a string representation.
                const color_optValue = this.OptValueWrapper as TOptValue_str
                return color_optValue.value
            }
            // case TDLLOptionType.ot_HotKey:
            //   throw new NotImplementedError("TDLLOptionType.ot_HotKey is not implemented yet");
            // return this.fValue.HotKey.toString();
            case TOptionType.ot_DateTime: {
                // Assuming StrDateTime is a function that formats a TDateTime value to a string.
                const datetime_optValue = this.OptValueWrapper as TOptValue_DateTime
                return StrsConv.StrDateTime(datetime_optValue.value, true)
            }
            case TOptionType.ot_Separator: {
                return ''
            }
            case TOptionType.ot_Session: {
                const session_optValue = this.OptValueWrapper as TOptValue_Session
                return session_optValue.toString()
            }
            case TOptionType.ot_SessionsArray: {
                const sessionArray_optValue = this.OptValueWrapper as TOptValue_SessionsArray
                return sessionArray_optValue.toString()
            }
            default: {
                throw new NotImplementedError(`GetValue is not implemented for OptionType: ${this.OptionType}`)
            }
        }
    }

    public GetValueObject(): { [key: string]: any } {
        return this.OptValueWrapper
    }

    public SetValue(s: string): void {
        switch (this.OptionType) {
            case TOptionType.ot_Session: {
                const session_optValue = this.OptValueWrapper as TOptValue_Session
                TOptValue_Session.updateFromString(s, session_optValue)
                break
            }
            case TOptionType.ot_SessionsArray: {
                const sessionArray_optValue = this.OptValueWrapper as TOptValue_SessionsArray
                TOptValue_SessionsArray.updateFromString(s, sessionArray_optValue)
                break
            }
            case TOptionType.at_Levels: {
                const str_optValue = this.OptValueWrapper as TOptValue_str
                str_optValue.value = s
                break
            }
            case TOptionType.ot_Longword:
            case TOptionType.ot_Integer: {
                const tOptNum = this.OptValueWrapper as TOptValue_number
                const numberValue = parseInt(s, 10)
                tOptNum.value = numberValue
                break
            }
            case TOptionType.ot_Timeframe: {
                //TODO: maybe check Timeframe value here?
                // Using parseInt with a radix of 10 to ensure proper number parsing
                const num_optValue = this.OptValueWrapper as TOptValue_number
                const numValue = parseInt(s, 10)
                if (isNaN(numValue)) throw new StrangeError('Invalid number format')

                num_optValue.value = Math.abs(numValue)
                break
            }

            case TOptionType.ot_double: {
                // Assuming StrsConv.GetDouble handles NaN internally or throws an error
                const double_optValue = this.OptValueWrapper as TOptValue_number
                double_optValue.value = StrsConv.GetDouble(s, this.digits)
                break
            }
            case TOptionType.ot_String:
            case TOptionType.ot_Currency: {
                {
                    //TODO: maybe check Currency value here?
                    // Using Utf8Encode to ensure the string is properly encoded
                    const str_optValue = this.OptValueWrapper as TOptValue_str
                    str_optValue.value = s
                }
                break
            }
            case TOptionType.ot_Boolean: {
                const bool_optValue = this.OptValueWrapper as TOptValue_bool
                bool_optValue.value = s === 'Yes'
                break
            }
            case TOptionType.ot_EnumType: {
                // Assuming values.IndexOf handles case sensitivity and returns -1 for not found
                const enum_optValue = this.OptValueWrapper as TOptValue_number
                const valueIndex = this.values.IndexOf(s)
                if (valueIndex < 0) {
                    throw new StrangeError(`Invalid enum value: ${s} for option: ${this.name}`)
                }
                enum_optValue.value = valueIndex
                break
            }
            case TOptionType.ot_LineStyle: {
                // Assuming SetShortStr is implemented and handles the string conversion
                const lineStyle_optValue = this.OptValueWrapper as TOptValue_LineStyleRec
                lineStyle_optValue.value.SetShortStr(s)
                break
            }
            case TOptionType.ot_Color: {
                // Assuming StrsConv.GetColor handles color conversion or throws an error
                const color_optValue = this.OptValueWrapper as TOptValue_str
                color_optValue.value = StrsConv.GetColor(s, true)
                break
            }
            case TOptionType.ot_Separator: {
                //do not need to do anything here
                break
            }
            //TODO: case TDLLOptionType.ot_HotKey:
            //   throw new NotImplementedError("TDLLOptionType.ot_HotKey is not implemented yet");
            // const hotKeyValue = parseInt(s, 10);
            // if (isNaN(hotKeyValue)) throw new StrangeError('Invalid number format');
            // this.fValue.HotKey = hotKeyValue;
            // break;
            case TOptionType.ot_DateTime: {
                // Assuming DateUtils.GetDateTime handles date conversion or throws an error
                const datetime_optValue = this.OptValueWrapper as TOptValue_DateTime
                datetime_optValue.value = StrsConv.GetDateTime(s)
                break
            }
            default: {
                throw new NotImplementedError(`SetValue is not implemented for OptionType: ${this.OptionType}`)
            }
        }
    }

    private RefreshValues(): void {
        this.values.Clear() // Clear the values once, to avoid repetition in each case

        switch (this.OptionType) {
            case TOptionType.ot_Boolean: {
                // Update boolean values
                this.values.Add('Yes')
                this.values.Add('No')
                break
            }

            case TOptionType.ot_Timeframe: {
                // Update timeframes
                for (let i = 0; i < GlobalTimeframes.Timeframes.count; i++) {
                    this.values.Add(GlobalTimeframes.Timeframes[i])
                }
                break
            }

            case TOptionType.ot_Currency: {
                // Update symbols
                for (let i = 0; i < GlobalSymbolList.SymbolList.Count; i++) {
                    this.values.Add(GlobalSymbolList.SymbolList.GetItem(i).symbolInfo.SymbolName)
                }
                break
            }

            default: {
                // Do nothing for other types
                break
            }

            //TODO: Consider adding default case if there are more OptionTypes or if there's a need to handle unexpected values.
        }
    }
}
