import React, { forwardRef, useId, useMemo } from 'react'

import { InputControlProps, InputControl, Separator, Flex } from '@fto/ui'

import Input, { InputProps } from './components/Input'
import { Icon } from '@fto/icons'

export type InputFieldProps = InputControlProps & InputProps

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            variant = 'column',
            id,
            label,
            helperText,
            success,
            error,
            required,
            block,
            isAbsoluteHelperText = false,
            ...rest
        },
        ref
    ) => {
        const _id = useId()
        const localId = id || _id

        return (
            <InputControl
                id={localId}
                variant={variant}
                success={success}
                error={error}
                required={required}
                label={label}
                block={block}
                helperText={helperText}
                isAbsoluteHelperText={isAbsoluteHelperText}
            >
                <Input
                    {...rest}
                    ref={ref}
                    id={localId}
                    required={required}
                    success={success}
                    error={error}
                    block={block}
                />
            </InputControl>
        )
    }
)

InputField.displayName = 'InputField'

export default InputField
