import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import GraphToolPanel from '@fto/chart_components/ProjectInterface/components/GraphToolPanel'

const GraphToolPanelWrapper: FC = observer(() => {
    const {
        params: { isOpen, tools, initialCoords }
    } = GraphToolPanelStore

    const filteredTools = useMemo(() => tools.filter((tool) => !!tool), [tools])

    return isOpen ? <GraphToolPanel tools={filteredTools} initialCoords={initialCoords} /> : null
})

export default GraphToolPanelWrapper
