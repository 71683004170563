import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fto/ui'
import { Icon } from '@fto/icons'
import { GOOGLE_OAUTH_URL } from '@root/constants/endpoints'
import { BASE_URL } from '@fto/lib/ft_types/data/data_downloading/URLConfig'
import { GOOGLE_ROUTE } from '@root/constants/routes'

type Props = {
    type?: 'logIn' | 'signUp'
}
export const GoogleAuth: FC<Props> = ({ type = 'logIn' }) => {
    const { t } = useTranslation()
    const handleSignIn = () => {
        const nonce = Math.random().toString(36).substring(2) + Date.now().toString(36)
        const url = `${GOOGLE_OAUTH_URL}&nonce=${nonce}&redirect_uri=${BASE_URL + GOOGLE_ROUTE}`
        window.location.href = url
    }

    return (
        <Button
            type='secondary'
            label={t(type === 'logIn' ? 'auth.loginWithGoogle' : 'auth.signUpWithGoogle')}
            before={<Icon name='google' size={16} />}
            onClick={() => handleSignIn()}
            block
        />
    )
}
