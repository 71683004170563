import React, { FC, useEffect, useState, useMemo, useCallback } from 'react'
import cx from 'classnames'
import { Button, Checkbox, Flex, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'
import { SymbolItemType } from '@root/types/symbols.types'
import { SymbolsCategoryType } from '@root/mapper/symbols.mapper'
import style from './options-symbol-group.module.scss'

type Props = {
    symbolsGroup: SymbolsCategoryType
    selectedSymbols: string[]
    selectSymbol: (value: SymbolItemType['Symbol']) => void
    isOpened: boolean
}

const CreateProjectModalSymbolsGroup: FC<Props> = ({
    symbolsGroup,
    selectedSymbols,
    selectSymbol,
    isOpened = false
}) => {
    const [isOpen, setIsOpened] = useState(isOpened)

    const select = useCallback(
        (symbol: SymbolItemType) => {
            selectSymbol(symbol.Symbol)
        },
        [selectSymbol]
    )

    const isInputChecked = useCallback(
        (symbol: SymbolItemType) => selectedSymbols?.find((s) => s === symbol.Symbol),
        [selectedSymbols]
    )

    useEffect(() => {
        setIsOpened(isOpened)
    }, [isOpened])

    if (symbolsGroup.symbols.length === 0) {
        return null
    }

    return (
        <div className={style.symbolGroup}>
            <Flex
                className={style.symbolGroupHeader}
                alignItems='center'
                onClick={() => setIsOpened((prev) => !prev)}
                gap={8}
            >
                <Icon name={isOpen ? 'minus' : 'plus'} size={12} />
                <Typography type='interface-medium'>{symbolsGroup.category}</Typography>
            </Flex>

            {isOpen && (
                <Flex className={style.symbolListGroup} direction='column'>
                    {symbolsGroup.symbols.map((symbol) => {
                        const onSelect = () => select(symbol)
                        return (
                            <Flex
                                className={style.symbolCheckboxWrap}
                                key={symbol.Symbol}
                                alignItems='center'
                                onClick={onSelect}
                                gap={8}
                            >
                                <span>
                                    <Checkbox
                                        id={symbol.Symbol}
                                        checked={!!isInputChecked(symbol)}
                                        onChange={onSelect}
                                        key={symbol.Symbol}
                                        inputControlVariant={'row-reverse'}
                                    />
                                </span>
                                <Typography type={'text-light'} color='gray-1000'>
                                    {symbol.Symbol}
                                </Typography>
                            </Flex>
                        )
                    })}
                </Flex>
            )}
        </div>
    )
}

export default CreateProjectModalSymbolsGroup
