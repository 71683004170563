import { FC, useEffect, useState } from 'react'
import { t } from 'i18next'

import { Flex, InputField, Modal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Icon } from '@fto/icons'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'

import SymbolsList from './components/SymbolsList'

import styles from './index.module.scss'

type Props = {
    setSelectedChartInfo: () => void
    modalType: 'symbolChange' | 'addChart'
    chartWindow?: TChartWindow
}

export const ChangeSymbolModal: FC<Props> = ({ setSelectedChartInfo, modalType, chartWindow }) => {
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        GlobalTestingManager.TestingManager.stopTesting()
    }, [])

    return (
        <Modal name={MODAL_NAMES.chart.changeSymbol} withCloseIcon size='md'>
            <Modal.Header withBorderBottom>
                {modalType === 'symbolChange' ? t('addChartModal.editTitle') : t('addChartModal.title')}
            </Modal.Header>
            <Modal.Content className={styles.content}>
                <Flex direction='column' gap={16} className={styles.block}>
                    <InputField
                        className={styles.searchInput}
                        prefix={<Icon size={18} name={'search'} />}
                        placeholder={t('addChartModal.searchPlaceholder')}
                        value={searchQuery}
                        onChange={setSearchQuery}
                        block
                        reset
                    />
                    <SymbolsList
                        chartWindow={chartWindow}
                        modalType={modalType}
                        setSelectedChartInfo={setSelectedChartInfo}
                        searchQuery={searchQuery.toUpperCase()}
                    />
                </Flex>
            </Modal.Content>
        </Modal>
    )
}
