import StrangeSituationNotifier from '../common/StrangeSituationNotifier'

declare global {
    interface FontFaceSet {
        add(font: FontFace): void
    }
}

class GlobalFontManager {
    private static instance: GlobalFontManager

    public static get Instance(): GlobalFontManager {
        if (!GlobalFontManager.instance) {
            GlobalFontManager.instance = new GlobalFontManager()
        }
        return GlobalFontManager.instance
    }

    private constructor() {}

    public async loadFonts(): Promise<void> {
        const fonts = [
            { name: 'Roboto Flex', url: '/fonts/Roboto Flex.ttf' }
            // { name: 'Roboto Mono', url: '/fonts/RobotoMono-Regular.ttf' }
        ]
        const fontPromises = fonts.map(async (font) => {
            const fontFace = new FontFace(font.name, `url('${font.url}')`)
            const loadedFont = await fontFace.load()
            document.fonts.add(loadedFont)
        })

        try {
            await Promise.all(fontPromises)
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Error loading fonts', error)
        }
    }
}

export default GlobalFontManager
