import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'

import { Button, Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { initQueryParams, QueryParams } from '@root/utils/query-params.util'
import { $getTotalProjects } from '@root/store/projects/projects.selector'
import { SET_QUERY_PARAM } from '@root/store/projects/project.slice'

import style from './project-pagination.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    query: QueryParams
}

const ProjectsPagination: FC<Props> = ({ query }) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const total = useAppSelector($getTotalProjects)
    const Offset = query?.Offset ? query.Offset : 0
    const Limit = query?.Limit ? query.Limit : initQueryParams.Limit!
    const totalPages = Math.ceil(total / Limit)

    const [currentPage, setCurrentPage] = useState(Offset / Limit + 1)

    const nextPage = () => {
        const newPage = currentPage + 1 > totalPages ? totalPages : currentPage + 1
        dispatch(SET_QUERY_PARAM({ Offset: currentPage * Limit }))
        setCurrentPage(newPage)
    }
    const prevPage = () => {
        const newPage = currentPage - 1 < 1 ? 1 : currentPage - 1

        dispatch(SET_QUERY_PARAM({ Offset: (newPage - 1) * Limit }))
        setCurrentPage(newPage)
    }

    useEffect(() => {
        if (totalPages < currentPage) {
            prevPage()
        }
    }, [totalPages])

    const prevDisabled = currentPage === 1
    const nextDisabled = currentPage === totalPages

    if (totalPages <= 1) return <></>

    return (
        <Flex justifyContent={'space-between'} alignItems={'center'} className={style.pagination}>
            <Button
                type='tetriatry-gray'
                disabled={prevDisabled}
                classNames={{
                    root: classNames(prevDisabled && 'opacity-50')
                }}
                onClick={prevPage}
                label={
                    <>
                        <Icon name={'arrow-long-left'} />
                        <Typography type={'interface-medium'}>{t('dashboard.table.pagination.prev')}</Typography>
                    </>
                }
            />
            <Typography type={'text-semibold'}>
                {t('dashboard.table.pagination.page')}{' '}
                {t('dashboard.table.pagination.counter', { current: currentPage, total: totalPages })}
            </Typography>
            <Button
                classNames={{
                    root: classNames(nextDisabled && 'opacity-50')
                }}
                type='tetriatry-gray'
                disabled={nextDisabled}
                onClick={nextPage}
                label={
                    <>
                        <Typography type={'interface-medium'}>{t('dashboard.table.pagination.next')}</Typography>
                        <Icon className={style.next} name={'arrow-long-left'} />
                    </>
                }
            />
        </Flex>
    )
}

export default ProjectsPagination
