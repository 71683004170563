import { FC } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex, Typography, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

const EmptyState: FC = () => {
    const { t } = useTranslation()

    const addProject = () => {
        addModal(MODAL_NAMES.projects.create)
    }

    return (
        <Flex alignItems='center' justifyContent='center' className={styles.Wrapper}>
            <Flex direction='column' alignItems='center' justifyContent='center' gap={16} flexBasis={300}>
                <Icon name='greet' size={140} />
                <Typography align='center' type='h4-semibold' color='gray-1000'>
                    {t('dashboard.emptyState.title')}
                </Typography>
                <Typography align='center' type='interface-medium' color='gray-800'>
                    {t('dashboard.emptyState.description')}
                </Typography>
                <Button
                    classNames={{ root: styles.button, content: styles.buttonContent }}
                    label={t('dashboard.emptyState.createNewProject')}
                    type='primary'
                    onClick={addProject}
                />
            </Flex>
        </Flex>
    )
}

export default EmptyState
