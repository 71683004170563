import BasicClasses_CommonFunctions from '@fto/lib/ft_types/common/BasicClasses/BasicClasses_CommonFunctions'
import { TTrailingStopType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { TOffsStringList, TVarList } from '@fto/lib/ft_types/common/OffsStringList'

export class TTrailingStopInfo {
    enabled: boolean
    tsType: TTrailingStopType
    AtProfit: number
    StopPoints: number
    StepPoints: number

    constructor(enabled: boolean, tsType: TTrailingStopType, AtProfit: number, StopPoints: number, StepPoints: number) {
        this.enabled = enabled
        this.tsType = tsType
        this.AtProfit = AtProfit
        this.StopPoints = StopPoints
        this.StepPoints = StepPoints
    }

    LoadFromList(list: TOffsStringList): void {
        const vars = new TVarList()

        vars.LoadFromList(list)

        this.enabled = vars.GetBool('enabled')
        this.tsType = BasicClasses_CommonFunctions.GetTrailingStopType(vars.GetValue('type'))
        this.AtProfit = vars.GetInt('AtProfit')
        this.StopPoints = vars.GetInt('StopPoints')
        this.StepPoints = vars.GetInt('StepPoints')

        vars.Clear()
        vars.SaveToList(list, '')
    }

    SaveToList(list: TOffsStringList): void {
        const vars = new TVarList()

        vars.AddVarBool('enabled', this.enabled)
        vars.AddVarStr('type', this.GetAsString(this.tsType))
        vars.AddVarInt('AtProfit', this.AtProfit)
        vars.AddVarInt('StopPoints', this.StopPoints)
        vars.AddVarInt('StepPoints', this.StepPoints)

        vars.SaveToList(list, '')

        vars.Clear()
    }

    //we are ignoring the translation becuase this data is used for saving object. When we have a separate mechanism for saving object, we can remove this comment and translate the strings
    private GetAsString(tsType: TTrailingStopType): string {
        if (!this.enabled) {
            return 'none'
        }

        const s = `TS: ${this.StopPoints}, step: ${this.StepPoints}` //translation-ignore

        if (this.tsType === TTrailingStopType.ts_Immediate) {
            return `Immediately, ${s}` //translation-ignore
        } else {
            return `At profit: ${this.AtProfit}, ${s}` //translation-ignore
        }
    }

    SetDefault(): void {
        this.enabled = false
        this.tsType = TTrailingStopType.ts_Immediate
        this.AtProfit = 20
        this.StopPoints = 20
        this.StepPoints = 5
    }
}
