const AUTH_ACTIONS = {
    LOGIN_MICROSOFT: 'auth/login-microsoft',
    LOGIN_GOOGLE: 'auth/login-google',
    LOGOUT: 'auth/logout',
    REGISTER: 'auth/register',
    LOGIN: 'auth/login',
    LOOKUP: 'auth/lookup'
}

export default AUTH_ACTIONS
