import { useState, useMemo, useCallback } from 'react'
import * as Yup from 'yup'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

type InputType = 'text' | 'password'

type Props = {
    onPasswordChange?: (password: string) => void
    initialValue?: string
    schema?: Yup.AnySchema
    validationDelay?: number
}

const usePasswordField = ({ initialValue = '', onPasswordChange, schema, validationDelay = 500 }: Props) => {
    const [passwordType, setPasswordType] = useState<InputType>('password')
    const [password, setPassword] = useState(initialValue)
    const [validationData, setValidationData] = useState<{
        isValid: boolean
        errorMessage: string
    }>({ isValid: true, errorMessage: '' })

    const { t } = useTranslation()

    const debouncedValidate = useCallback(
        debounce((password) => {
            const defaultSchema = Yup.string()
                .required(t('auth.errors.password.required'))
                .min(6, t('auth.errors.password.minLength'))

            schema ||
                defaultSchema
                    .validate(password)
                    .then(() => {
                        setValidationData({ isValid: true, errorMessage: '' })
                    })
                    .catch((error) => {
                        setValidationData({ isValid: false, errorMessage: error.message })
                    })
        }, validationDelay),
        [validationDelay]
    )

    const handlePasswordChange = useCallback(
        (value: string) => {
            setPassword(value)
            debouncedValidate(value)
            onPasswordChange?.(value)
        },
        [onPasswordChange, debouncedValidate]
    )

    const handlePasswordInputTypeChange = useCallback(() => {
        setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))
    }, [])

    const isInputTypePassword = useMemo(() => passwordType === 'password', [passwordType])

    const isSubmitDisabled = useMemo(() => {
        return password.length === 0
    }, [password])

    return {
        password,
        passwordType,
        isSubmitDisabled,
        isInputTypePassword,
        handlePasswordChange,
        handlePasswordInputTypeChange,
        validationData
    }
}

export default usePasswordField
