import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import ToolInfoStore from '@fto/lib/store/tools'
import { Command } from '@fto/lib/utils/FTOCommands/Command'

class ToolMagnetModeCommandDisable extends Command {
    public execute(): void {
        GlobalOptions.Options.StrongMagnetMode = false
        GlobalChartsController.Instance.applyStrongMagnetMode()
        ToolInfoStore.setInfo((prevState) => ({ ...prevState, isMagnetMode: false }))
    }
}

export { ToolMagnetModeCommandDisable }
