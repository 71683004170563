import {
    forwardRef,
    useCallback,
    createContext,
    useMemo,
    ReactNode,
    useContext,
    useSyncExternalStore,
    useEffect,
    useRef,
    Ref
} from 'react'

import * as React from 'react'
import ReactDOM from 'react-dom'

import cx from 'classnames'

import { createObserver } from '../Modal/utils/createObserver'

import styles from './index.module.scss'
import useAdjustContextMenuPosition from './hooks/useAdjustedPosition'
import { SELECTORS } from '../../constants/controledNodes'

export interface ContextMenuContextProps {
    anchorX: number
    anchorY: number
    direction?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}

const ContextMenuContext = createContext<ContextMenuContextProps | null>(null)

export const useContextMenuContext = (): ContextMenuContextProps | null => useContext(ContextMenuContext)

export type ContextMenuProps = {
    name: string
    anchorX: number
    anchorY: number
    direction?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

    children: ((props: any) => JSX.Element) | JSX.Element | JSX.Element[] | ReactNode
    additionalClassNames?: {
        root?: string
        container?: string
    }
    ref?: Ref<HTMLDivElement>
    stopPropagation?: boolean
    metadata?: { [key: string]: any }
}

const observer = createObserver()
const contextMenuState = new Map()

const generateKey = (key: string, name: string): string => `${key}_${name}`

const Dialog = forwardRef<HTMLDivElement, ContextMenuProps>(
    ({ additionalClassNames, name, children, metadata, anchorX, anchorY, direction, stopPropagation }, ref) => {
        const { open, props } = useContextMenuState(name)
        const contextMenuRef = useRef<HTMLDivElement>(null)
        const adjustedPosition = useAdjustContextMenuPosition(open, anchorX, anchorY, contextMenuRef, direction)

        const closeContextMenu = useCallback(() => {
            removeContextMenu(name)
        }, [])

        const handleClickOutside = useCallback(
            (event: MouseEvent) => {
                if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
                    stopPropagation && event.stopPropagation()
                    closeContextMenu()
                }
            },
            [closeContextMenu]
        )

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [handleClickOutside])

        const ContextMenuContextValue = useMemo(() => {
            return {
                name,
                onClose: closeContextMenu,
                props,
                anchorX,
                anchorY,
                direction
            }
        }, [name, closeContextMenu, props, anchorX, anchorY, direction])

        return ReactDOM.createPortal(
            open ? (
                <ContextMenuContext.Provider value={ContextMenuContextValue}>
                    <div className={cx(styles.root, additionalClassNames?.root)} data-test-id={name}>
                        <div
                            style={{
                                transform: `translate(${adjustedPosition.x}px, ${adjustedPosition.y}px)`
                            }}
                            className={cx(
                                styles.container,
                                additionalClassNames?.container,
                                SELECTORS.CONTEXT_MENU.className
                            )}
                            ref={contextMenuRef}
                        >
                            {typeof children === 'function' ? children(props) : children}
                        </div>
                    </div>
                </ContextMenuContext.Provider>
            ) : null,
            document.querySelector('body') as Element
        )
    }
)

export const addContextMenu = <T extends {}>(name: string, props?: T) => {
    contextMenuState.set(name, {
        open: true,
        props: props
    })
    observer.dispatch(generateKey('add', name), props)
}
export const removeContextMenu = (name: string) => {
    contextMenuState.delete(name)
    observer.dispatch(generateKey('remove', name))
}

const defaultState = {
    open: false,
    props: null
}

export const useContextMenuState = (name: string) => {
    const subscribe = useCallback((callback: any) => {
        observer.subscribe(generateKey('add', name), callback)
        observer.subscribe(generateKey('remove', name), callback)

        return () => {
            observer.unsubscribe(generateKey('add', name), callback)
            observer.unsubscribe(generateKey('remove', name), callback)
        }
    }, [])

    return useSyncExternalStore(
        subscribe,
        () => contextMenuState.get(name) ?? defaultState,
        () => undefined
    )
}

Dialog.displayName = 'ContextMenu'

export const ContextMenu = Dialog as unknown as React.FC<ContextMenuProps>
