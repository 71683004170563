import { makeAutoObservable } from 'mobx'

import { IIndicatorOptionsStore } from './Interfaces/IIndicatorOptionsStore'

const initialSettings = {}

class IndicatorOptionsStore {
    public indicatorOptions: IIndicatorOptionsStore

    constructor() {
        makeAutoObservable(this)

        this.indicatorOptions = initialSettings
        this.setIndicatorOptions = this.setIndicatorOptions.bind(this)
        this.resetIndicatorOptions = this.resetIndicatorOptions.bind(this)
    }

    // The argument can be either an updater function or a PassedSettingsType object
    public setIndicatorOptions(
        updater: ((prevSettings: IIndicatorOptionsStore) => IIndicatorOptionsStore) | IIndicatorOptionsStore
    ) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.indicatorOptions = {
                ...this.indicatorOptions,
                ...updater(this.indicatorOptions)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.indicatorOptions = {
                ...this.indicatorOptions,
                ...updater
            }
        }
    }

    public resetIndicatorOptions() {
        this.indicatorOptions = initialSettings
    }
}

export default new IndicatorOptionsStore()
