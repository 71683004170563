import { FC, useCallback, useMemo } from 'react'

import TimeframeStore from '@fto/lib/store/timeframe'
import { Checkbox, Flex, Typography } from '@fto/ui'

import { DelphiLikeArray } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'

import styles from './index.module.scss'
import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'

type Props = {
    handleTimeframeChange: (timeframe: number) => void
    timeframes: DelphiLikeArray<number>
}

const TimeframesCreator: FC<Props> = ({ handleTimeframeChange, timeframes }) => {
    const { customTimeframes } = TimeframeStore

    // combine default and custom timeframes with no duplicates
    const allTimeframes = useMemo(
        () => [...new Set([...TIME_FRAMES, ...customTimeframes.map((timeframe) => timeframe.value)])],
        [customTimeframes]
    )
    useMemo(() => allTimeframes.sort((a, b) => a - b), [allTimeframes])

    return (
        <Flex direction='column' gap={16}>
            {allTimeframes.map((timeframe) => {
                return (
                    <Flex onClick={() => handleTimeframeChange(timeframe)} key={timeframe} gap={8} alignItems='center'>
                        <Checkbox checked={timeframes.includes(timeframe)} className={styles.checkbox} />
                        <Typography>{TimeframeUtils.GetTimeframeNameLabel(timeframe as number).label}</Typography>
                    </Flex>
                )
            })}
        </Flex>
    )
}

export default TimeframesCreator
