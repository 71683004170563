import { FC } from 'react'
import { t } from 'i18next'

import { Flex, Typography } from '@fto/ui'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Icon } from '@fto/icons'

import { selectSymbol } from '../../../helpers'

import styles from './common.module.scss'

type Props = {
    symbol: string
    modalType: 'symbolChange' | 'addChart'
    chartWindow: TChartWindow | undefined
    addSymbolToProject: (symbol: string) => void
    setSelectedChartInfo: (chartInfo: any) => void
    close: () => void
}

const Warning: FC<Props> = ({ symbol, close, modalType, chartWindow, addSymbolToProject, setSelectedChartInfo }) => {
    return (
        <Flex className={styles.warning} gap={12} direction='column'>
            <Flex gap={8}>
                <Icon size={16} name='info' className={styles.icon} color={'var(--color-orange-600)'} />
                <Typography type='interface-semibold' color='orange-700'>
                    {t('addChartModal.warning')}
                </Typography>
            </Flex>

            <Flex justifyContent='flex-end' gap={8}>
                <Flex gap={4} alignItems='center' className={styles.button} justifyContent='center' onClick={close}>
                    <Typography type='interface-medium' color='orange-700'>
                        {t('addChartModal.no')}
                    </Typography>
                </Flex>
                <Flex
                    gap={4}
                    alignItems='center'
                    className={styles.button}
                    justifyContent='center'
                    onClick={() =>
                        selectSymbol(symbol, modalType, chartWindow, addSymbolToProject, setSelectedChartInfo)
                    }
                >
                    <Typography type='interface-medium' color='orange-700'>
                        {t('addChartModal.yes')}
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Warning
