import { FC, Fragment, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Button } from '@fto/ui'

import { useModalContext } from '..'

import classNames from 'classnames'

import styles from '../index.module.scss'

export type ControlsProps =
    | {
          className?: string
          disabled?: boolean
          children: ReactNode
          onClose?: never
          onSubmit?: never
          submitText?: never
          cancelText?: never
      }
    | {
          className?: string
          disabled?: boolean
          children?: never
          onClose?: () => void
          onSubmit?: () => void
          submitText?: string | ReactElement
          cancelText?: string | ReactElement
      }

const Controls: FC<ControlsProps> = (props) => {
    const { t } = useTranslation()

    const {
        children,
        className,
        cancelText = t('uiKit.modal.controls.cancel'),
        submitText = t('uiKit.modal.controls.submit'),
        disabled = false,
        onClose,
        onSubmit
    } = props
    const context = useModalContext()

    const closeHandler = onClose || context?.onClose
    const submitHandler = onSubmit || context?.onSubmit

    const isFullSizeButtonWidth = context?.size === 'xs'

    return (
        <div
            className={cx(styles.controls, className, {
                [styles.column]: isFullSizeButtonWidth
            })}
        >
            {children || (
                <Fragment>
                    <Button
                        classNames={{
                            root: styles.Button,
                            content: classNames(styles.ButtonContent, {})
                        }}
                        block={isFullSizeButtonWidth}
                        type='secondary'
                        label={cancelText}
                        onClick={() => closeHandler?.()}
                    />
                    <Button
                        classNames={{
                            root: styles.Button,
                            content: classNames(styles.ButtonContent, {})
                        }}
                        type='primary'
                        label={submitText}
                        disabled={disabled}
                        onClick={() => submitHandler?.()}
                        block={isFullSizeButtonWidth}
                    />
                </Fragment>
            )}
        </div>
    )
}

Controls.displayName = 'Modal.Controls'

export default Controls
