import { TDateTime } from '../../../delphi_compatibility/DateUtils'
import TDummyEquityArray from '../../../mocks/DummyClasses/DummyEquityArray'
import TDummyBalanceArray from '../../../mocks/DummyClasses/DummyBalanceArray'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { TTickRecord } from '../DataClasses/TTickRecord'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

// export class TBalanceRec extends TDataRecordWIthDate {
//     balance: number
//     margin: number
//     drawdown: number

//     constructor(dateTime: TDateTime, balance: number, margin: number, drawdown: number) {
//         super(dateTime)
//         this.balance = balance
//         this.margin = margin
//         this.drawdown = drawdown
//     }

//     // SetValue: (dateTime: TDateTime, balance: number, margin: number, drawdown: number) => void;
// }

// export type TBalanceEquityRec = {
//     balance: number
//     margin: number
//     drawdown: number
//     equity: TBarRecord
// }

export class TEquityArr {
    private fEquity: TDummyEquityArray | null
    private fBalance: TDummyBalanceArray | null

    constructor() {
        this.fEquity = null
        this.fBalance = null
    }

    AddValue(DateTime: TDateTime, equity: number, b: number, m: number, d: number): void {
        if (!this.fEquity || !this.fBalance) return

        const tick: TTickRecord = {
            DateTime: DateTime,
            bid: equity,
            ask: equity,
            volume: 1
        }

        this.fEquity.AddSingleTick(tick)

        if (this.fBalance.Count === this.fEquity.Count) {
            this.fBalance.LastItem.SetValue(b, m, d)
        } else {
            this.fBalance.Flush()
            this.fBalance.LastItem.SetValue(b, m, d)

            while (this.fBalance.Count < this.fEquity.Count) {
                this.fBalance.Add(this.fBalance.LastItem)
            }
        }
    }

    private enabled() {
        return this.fEquity !== null && this.fBalance !== null
    }

    AssignArrays(path: string): void {
        //TODO: implement AssignArrays in different way for FTO. We probably need to use chunks and data stored on server
        DebugUtils.errorTopic(ELoggingTopics.lt_NotImplemented, 'EquityArrays.AssignArrays not implemented for FTO')

        // this.FreeArrays();

        // // Append 'equity\' to the path
        // path += 'equity\\';
        // // Assuming ForceDirectories or equivalent functionality is implemented
        // ForceDirectories(path);

        // // Create instances of TFMBarsArray and TBalanceArray with the respective file paths
        // this.fEquity = new TFMBarsArray(path + 'equity.dat', 15);
        // this.fBalance = new TBalanceArray(path + 'balance.dat');

        // // Correct balance array size if needed
        // if (this.fBalance.GetCount() > this.fEquity.GetCount()) {
        //   this.fBalance.SetCount(this.fEquity.GetCount());
        // }

        // if (this.fBalance.GetCount() < this.fEquity.GetCount()) {
        //   let item: TBalanceRec;
        //   if (this.fBalance.GetCount() > 0) {
        //     const lastItem = this.fBalance.GetItem(this.fBalance.GetCount() - 1);
        //     item = { balance: lastItem.balance, margin: lastItem.margin, drawdown: lastItem.drawdown };
        //   } else {
        //     item = { balance: 0, margin: 0, drawdown: 0 };
        //   }

        //   while (this.fBalance.GetCount() < this.fEquity.GetCount()) {
        //     this.fBalance.Add(item);
        //   }
        // }
    }

    public Clear() {
        DebugUtils.errorTopic(ELoggingTopics.lt_NotImplemented, 'EquityArrays.Clear not implemented for FTO')
    }

    public RollToDate(date: TDateTime) {
        DebugUtils.errorTopic(ELoggingTopics.lt_NotImplemented, 'EquityArrays.RollToDate not implemented yet')
    }
}
