import { Command } from '@fto/lib/utils/FTOCommands/Command'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import ToolInfoStore from '@fto/lib/store/tools'

class ToolMagnetModeCommandEnable extends Command {
    public execute(): void {
        GlobalOptions.Options.StrongMagnetMode = true
        GlobalChartsController.Instance.applyStrongMagnetMode()
        ToolInfoStore.setInfo((prevState) => ({ ...prevState, isMagnetMode: true }))
    }
}

export { ToolMagnetModeCommandEnable }
