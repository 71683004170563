import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Flex, InputField } from '@fto/ui'

import Header from '../Header'
import Controls from '../Controls'

import { TEXT_LOCALES } from '../../constants/texts'
import { REASONS } from '../../constants'
import { ReasonDataType } from '../../types'
import { fireMixpanelEvent } from '@root/utils/api'

type Props = {
    productName: string
    setSuspendData: Dispatch<SetStateAction<ReasonDataType>>
    setStep: (n: number) => void
}

const SwitchBlock: FC<Props> = ({ productName, setSuspendData, setStep }) => {
    const { t } = useTranslation()

    const onChange = useCallback(
        (value: string) => {
            if (value.length > 255) return

            setSuspendData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    [REASONS.switch]: {
                        productName: value
                    }
                }
            }))
        },
        [setSuspendData]
    )

    const onSubmit = useCallback(() => {
        setStep(2)
        fireMixpanelEvent('subscription_suspend_detailed_feedback', {
            initial_feedback: REASONS.switch,
            productName
        })
    }, [setStep, productName])

    return (
        <>
            <Modal.Content withBorderBottom>
                <Flex direction='column' gap={16}>
                    <Header reason={REASONS.switch} />
                    <InputField
                        value={productName}
                        size='medium'
                        onChange={onChange}
                        block
                        placeholder={t(TEXT_LOCALES.switch.placeholder)}
                    />
                </Flex>
            </Modal.Content>
            <Controls disabled={!productName} onSubmit={onSubmit} />
        </>
    )
}

export default SwitchBlock
