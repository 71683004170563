import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { GoogleAuth } from '@root/pages/Auth/components/Social/Google'
import { DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE, makeRoute, SIGN_UP_ROUTE } from '@root/constants/routes'
import { Button, Flex, InputField, Link, Typography } from '@fto/ui'
import { IconButton } from '@fto/icons'
import usePasswordField from '@root/hooks/usePasswordField'
import useEmailField from '@root/hooks/useEmailField'
import useDebouncedEnterSubmit from '@root/hooks/useDebouncedEnterSubmit'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import { MicrosoftAuth } from '@root/pages/Auth/components/Social/Microsoft'
import MessageInformer from '@root/components/MessageInformer'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { LOGIN } from '@root/store/auth/auth.actions'
import { RESET_AUTH_ERROR } from '@root/store/auth/auth.slice'
import { fireMixpanelEvent } from '@root/utils/api'

const Login = () => {
    const { authError: error, isAuthProcess: isLoading } = useAppSelector((state) => state.auth)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'login' })
    }, [])

    const {
        password,
        passwordType,
        isInputTypePassword,
        handlePasswordChange,
        handlePasswordInputTypeChange,
        validationData: passwordValidationData
    } = usePasswordField({ initialValue: '' })

    const {
        email,
        handleEmailChange,
        validationData: emailValidationData,
        validate
    } = useEmailField({ initialValue: '' })

    const login = useCallback(async () => {
        try {
            const isValid = await validate(email)

            if (!isValid) return

            await dispatch(LOGIN({ email, password }))

            navigate(`${makeRoute(DASHBOARD_ROUTE)}${window.location.search}`)
        } catch (e) {}
    }, [email, password])

    const resetError = () => {
        dispatch(RESET_AUTH_ERROR())
    }

    useDebouncedEnterSubmit(login)

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h1-bold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.loginTitle')}
                        </Typography>
                        <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            {t('auth.loginText')}
                        </Typography>
                    </Flex>
                    <Flex gap={8} direction='column'>
                        <GoogleAuth />
                        <MicrosoftAuth />
                    </Flex>
                    <div className={styles.authOrLink}>
                        <span>{t('auth.orSignInLabel')}</span>
                    </div>
                    <form>
                        <Flex direction={'column'} gap={24}>
                            <InputField
                                id='login'
                                isAbsoluteHelperText
                                helperText={emailValidationData.errorMessage}
                                error={!emailValidationData.isValid}
                                block
                                type={'email'}
                                value={email}
                                onChange={handleEmailChange}
                                label={t('auth.email')}
                                placeholder={t('auth.email')}
                            />
                            <InputField
                                id='login-pass'
                                isAbsoluteHelperText
                                error={!passwordValidationData.isValid}
                                helperText={passwordValidationData.errorMessage}
                                type={passwordType}
                                value={password}
                                block
                                onChange={handlePasswordChange}
                                label={t('auth.password')}
                                placeholder={t('auth.password')}
                                suffix={
                                    <IconButton
                                        name={isInputTypePassword ? 'eye-off' : 'eye'}
                                        size={16}
                                        onClick={handlePasswordInputTypeChange}
                                    />
                                }
                            />
                            <Flex block justifyContent={'flex-end'} alignItems={'flex-start'}>
                                <Link to={makeRoute(FORGOT_PASSWORD_ROUTE)}>
                                    <Typography type='interface-medium' color={'primary-500'}>
                                        {t('auth.forgetPassword')}
                                    </Typography>
                                </Link>
                            </Flex>
                            <MessageInformer type='error' isShown={!!error} onClose={resetError} disappearing>
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    {/*@ts-ignore*/}
                                    {t(statusMessages[error] || statusMessages['unknown'])}
                                </Typography>
                            </MessageInformer>
                            <Button
                                loading={isLoading}
                                type={'marketing'}
                                block
                                onClick={login}
                                label={t('auth.signIn')}
                            />
                        </Flex>
                    </form>
                    <Flex justifyContent={'center'}>
                        <Typography type={'text-regular'} color={'gray-1000'}>
                            {t('auth.haveNotAccount')} &nbsp;
                        </Typography>
                        <Link to={makeRoute(SIGN_UP_ROUTE)}>{t('auth.signUp')}</Link>
                    </Flex>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default Login
