import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TPaintToolType, TPointInfo } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TPtRectangle } from '@fto/lib/charting/paint_tools/SpecificTools/ptRectangle'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { TSearchMode } from '@fto/lib/ft_types/data/data_arrays/FXDataArrays'
import { TFMBarsArray } from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/BarsArray/BarsArray'
import { IChart } from '../../chart_classes/IChart'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { countBy } from 'lodash'
import { t } from 'i18next'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TRect } from '../../../extension_modules/common/CommonExternalInterface'

type Direction = 'se' | 'sw' | 'ne' | 'nw'

export class TPtMeasure extends TPtRectangle {
    private _basePoint: TPointInfo | null = null
    private blueBrush: IGPSolidBrush
    private redBrush: IGPSolidBrush
    private infoBoxBrush: IGPSolidBrush
    private crossBrush: IGPSolidBrush

    constructor(aChart: IChart) {
        super(aChart)

        this.ShortName = 'Measure'
        this.fToolType = TPaintToolType.tt_Polygon
        this.fClosedPolygon = true
        this.CursorStyle = CustomCursorPointers.crCross
        this.icon = 87
        this.isNeededToCopyToOtherCharts = false
        this.font = new IGPFont(new TGPFontFamily('Roboto Flex'), 12)

        this.blueBrush = new IGPSolidBrush('#2F80ED')
        this.redBrush = new IGPSolidBrush('#FF4846')
        this.infoBoxBrush = new IGPSolidBrush(DelphiColors.clWhite, 1)
        this.crossBrush = new IGPSolidBrush(this.fLineStyle.color, 1)
    }

    private get basePoint(): TPointInfo {
        if (!this._basePoint) {
            throw new StrangeError('Base point is null in TPtMeasure')
        }
        return this._basePoint
    }

    public Paint(): void {
        super.Paint()
        if (this.fPoints.length < 2) return
        const canvas = this.chart.GdiCanvas

        const direction = this.getDirection()

        this.detertmineColorScheme(direction)
        this.drawInfoBox(canvas, direction)
        this.drawCross(canvas, direction)
    }

    public ReadyToMove(x: number, y: number): boolean {
        return false
    }

    public ReadyToSize(x: number, y: number): boolean {
        return false
    }

    protected PaintMarkers(): void {}
    protected PaintLines(): void {}

    OnComplete(): void {
        super.OnComplete()
        if (this._basePoint) {
            this._basePoint = this.fPoints.filter((point) => {
                return point.y === this.basePoint.price && point.x === this.basePoint.time
            })[0]
        }
    }

    private drawCross(canvas: TGdiPlusCanvas, direction: Direction): void {
        const directionPoint = this.getPointForDirection(direction)
        if (!directionPoint) {
            return
        }
        this.fLineStyle.color = this.AddOpacityToColor(this.fLineStyle.color, 1)
        this.fLineStyle.width = 1

        canvas.MoveTo((this.basePoint.x + directionPoint.x) / 2, this.basePoint.y)
        this.ArrowTo((this.basePoint.x + directionPoint.x) / 2, directionPoint.y, direction, canvas, 'vertical')
        canvas.MoveTo(this.basePoint.x, (this.basePoint.y + directionPoint.y) / 2)
        this.ArrowTo(directionPoint.x, (this.basePoint.y + directionPoint.y) / 2, direction, canvas, 'horizontal')
        this.fLineStyle.color = this.AddOpacityToColor(this.fLineStyle.color, 0.1)
    }

    private drawInfoBox(canvas: TGdiPlusCanvas, direction: Direction) {
        const priceDifferenceBrush = this.getPriceDifferenceBrush(direction)
        const directionPoint = this.getPointForDirection(direction)
        if (!directionPoint) {
            return
        }
        const priceText = this.getPriceText(directionPoint)
        const timeText = this.getTimeText(directionPoint)
        const volumeText = this.getVolumeText(directionPoint)
        const dx = direction === 'ne' || direction === 'se' ? 1 : -1
        const dy = direction === 'ne' || direction === 'nw' ? -1 : 1

        const width1 = this.chart.GdiCanvas.TextWidth(timeText, this.font)
        const width2 = this.chart.GdiCanvas.TextWidth(priceText, this.font)
        const width3 = this.chart.GdiCanvas.TextWidth(volumeText, this.font)
        const height1 = this.chart.GdiCanvas.TextHeight(timeText, this.font)
        const height2 = this.chart.GdiCanvas.TextHeight(priceText, this.font)
        const height3 = this.chart.GdiCanvas.TextHeight(volumeText, this.font)

        const newLineDistance = 8 * window.devicePixelRatio // 2px distance between two lines
        const padding = 8 * window.devicePixelRatio
        const width = Math.max(width1, width2, width3) + padding * 2
        const height = padding * 3 + height1 + height2 + height3 + newLineDistance * 2 // 2 gaps between lines

        const rect = new TRect(
            dx > 0 ? directionPoint.x - width : directionPoint.x,
            dy > 0 ? directionPoint.y + padding * dy : directionPoint.y - height,
            dx > 0 ? directionPoint.x : directionPoint.x + width,
            dy > 0 ? directionPoint.y + height * dy : directionPoint.y + padding * dy
        )
        const timeTextModifier = height1 + padding
        const priceTextModifier = timeTextModifier + height2 + newLineDistance
        const volumeTextModifier = priceTextModifier + height3 + newLineDistance

        canvas.strokeRect(rect, TRoundRectType.BOTH, 5, true, this.fLineStyle.getPen(), priceDifferenceBrush)
        canvas.textOut(rect.Left + padding, rect.Top + timeTextModifier, timeText, this.font, this.infoBoxBrush, true)
        canvas.textOut(rect.Left + padding, rect.Top + priceTextModifier, priceText, this.font, this.infoBoxBrush, true)
        canvas.textOut(
            rect.Left + padding,
            rect.Top + volumeTextModifier,
            volumeText,
            this.font,
            this.infoBoxBrush,
            true
        )
    }

    private getPriceText(directionPoint: TPointInfo): string {
        const priceDiff = directionPoint.price - this.basePoint.price

        const formattedPriceDiff = StrsConv.StrDouble(priceDiff, this.chart.ScaleDecimals())
        const points = Math.round(priceDiff * 10 ** this.chart.ScaleDecimals())
        const percentDiff = ((priceDiff / directionPoint.price) * 100).toFixed(2).toString()

        return t('charting.graphTools.priceDiffPoints', {
            formattedPriceDiff,
            count: Math.round(points),
            percentDiff: percentDiff
        })
    }

    private getTimeText(directionPoint: TPointInfo) {
        const { startIndex, endIndex } = this.getIndexes(directionPoint)
        const startDate = DateUtils.ToDate(this.basePoint.time).getTime()
        const endDate = DateUtils.ToDate(directionPoint.time).getTime()
        const diff = this.formatTimeDifference(startDate - endDate)

        const countOfBars = endIndex - startIndex
        let dateTime = StrsConv.StrDateTime(
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(directionPoint.time)
        )
        if (
            this.chart &&
            this.chart.Bars &&
            this.chart.Bars.DataDescriptor &&
            this.chart.Bars.DataDescriptor.timeframe >= 1440
        ) {
            dateTime = StrsConv.StrDateTime(Math.trunc(directionPoint.time), false, false)
        }

        return t('charting.graphTools.ptMeasure.timeText', { count: Math.round(countOfBars), dateTime, diff })
    }

    private getVolumeText(directionPoint: TPointInfo) {
        const { startIndex, endIndex } = this.getIndexes(directionPoint)
        let totalVolume = 0

        const bars = (this.fChart.ChartWindow as TChartWindow).Bars as TFMBarsArray
        if (bars) {
            if (startIndex > endIndex) {
                totalVolume = bars.GetSum(endIndex, startIndex, TSearchMode.sm_Volume)
            } else {
                totalVolume = bars.GetSum(startIndex, endIndex, TSearchMode.sm_Volume)
            }
        } else {
            throw new StrangeError('chartWindow is null')
        }
        return t('charting.graphTools.ptMeasure.volume', { totalVolume })
    }

    private formatTimeDifference(ms: number) {
        if (ms === 0) return '0m'
        const sign = ms > 0 ? '-' : ''
        ms = Math.abs(ms)

        const days = Math.floor(ms / (1000 * 60 * 60 * 24))
        const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))

        const formattedTime =
            // eslint-disable-next-line sonarjs/prefer-immediate-return
            sign +
            (days !== 0 ? `${days.toString()}d ` : '') +
            (hours !== 0 ? `${hours.toString()}h ` : '') +
            (minutes !== 0 ? `${minutes.toString()}m` : '')

        return formattedTime
    }

    private ArrowTo(
        x: number,
        y: number,
        direction: Direction,
        canvas: TGdiPlusCanvas,
        type: 'vertical' | 'horizontal',
        arrowSize = 4
    ) {
        const dx = direction === 'ne' || direction === 'se' ? 1 : -1
        const dy = direction === 'ne' || direction === 'nw' ? -1 : 1

        canvas.LineTo(x, y, this.fLineStyle.getPen())
        canvas.MoveTo(x, y)
        canvas.LineTo(x - dx * arrowSize, y - dy * arrowSize, this.fLineStyle.getPen())
        canvas.MoveTo(x, y)

        if (type === 'horizontal') {
            canvas.LineTo(x - dx * arrowSize, y + dy * arrowSize, this.fLineStyle.getPen())
        } else {
            canvas.LineTo(x + dx * arrowSize, y - dy * arrowSize, this.fLineStyle.getPen())
        }
    }

    private getDirection(): Direction {
        if (!this._basePoint) {
            this._basePoint = Object.assign(this.fPoints[0])
        }
        const lowestX = this.fPoints.map((point) => point.x).reduce((a, b) => Math.min(a, b))
        const lowestY = this.fPoints.map((point) => point.y).reduce((a, b) => Math.max(a, b))

        if (lowestX === this.basePoint.x && lowestY === this.basePoint.y) {
            return 'ne'
        } else if (lowestX < this.basePoint.x && lowestY === this.basePoint.y) {
            return 'nw'
        } else if (lowestX < this.basePoint.x && lowestY > this.basePoint.y) {
            return 'sw'
        } else {
            return 'se'
        }
    }

    private getPointForDirection(direction: Direction): TPointInfo {
        switch (direction) {
            case 'ne': {
                return this.fPoints.find(
                    (point) => point.x > this.basePoint.x && point.y < this.basePoint.y
                ) as TPointInfo
            }
            case 'nw': {
                return this.fPoints.find(
                    (point) => point.x < this.basePoint.x && point.y < this.basePoint.y
                ) as TPointInfo
            }
            case 'sw': {
                return this.fPoints.find(
                    (point) => point.x < this.basePoint.x && point.y > this.basePoint.y
                ) as TPointInfo
            }
            case 'se': {
                return this.fPoints.find(
                    (point) => point.x > this.basePoint.x && point.y > this.basePoint.y
                ) as TPointInfo
            }
            default: {
                throw new StrangeError(`Unknown direction in getPointForDirection ${direction}`)
            }
        }
    }

    private getIndexes(directionPoint: TPointInfo) {
        const startIndex = this.chart.GetGlobalIndexByDate(
            this.basePoint.time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const endIndex = this.chart.GetGlobalIndexByDate(
            directionPoint.time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        return { startIndex, endIndex }
    }

    private getPriceDifferenceBrush(direction: Direction): IGPSolidBrush {
        if (direction === 'ne' || direction === 'nw') {
            return this.blueBrush
        } else {
            return this.redBrush
        }
    }

    private detertmineColorScheme(direction: Direction) {
        if (direction === 'ne' || direction === 'nw') {
            this.setColorScheme('#2F80ED')
        } else {
            this.setColorScheme('#FF4846')
        }
    }

    private setColorScheme(color: string) {
        this.fLineStyle = new TLineStyle(color, TPenStyle.psSolid, 1)
        this.brush.setColor(color)
        this.brush.setOpacity(0.1)
    }
}
