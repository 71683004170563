import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { addModal, useModalState } from '@fto/ui'

import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $isUserLoaded, $userCanSeeExtendedSettings } from '@root/store/auth/selectors'
import { getProjectQueryStorage } from '@root/utils/localStorage'
import { SET_QUERY_PARAMS } from '@root/store/projects/project.slice'
import { GET_PROJECTS_ACTION } from '@root/store/projects/project.actions'
import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

import ProjectsTableWrap from '@root/pages/Dashboard/components/ProjectsTableWrap'
import { AppHeader } from '@root/components/AppHeader'
import { ThankYouModal } from './components/Modals/ThankYouModal'
import { AdminSettingsModal } from '@root/pages/Dashboard/components/Modals/AdminSettingsModal/AdminSettingsModal'

import styles from './index.module.scss'

const Dashboard: FC = () => {
    const navigate = useNavigate()
    const canSeeAdminSettings = useAppSelector($userCanSeeExtendedSettings)
    const { open: isAdminSettingsOpen } = useModalState(MODAL_NAMES.dashboard.adminSettings)

    const isUserDataLoaded = useAppSelector($isUserLoaded)
    const user = useAppSelector($getUser)

    const dispatch = useAppDispatch()

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'dashboard' })

        const params = new URLSearchParams(window.location.search)
        const actionType = params.get('action')
        const successPaymentState = params.get('success')

        if (actionType && actionType === 'payment' && successPaymentState === 'true') {
            addModal(MODAL_NAMES.payment.thankYou)
            navigate(makeRoute(DASHBOARD_ROUTE))
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault()
        }
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (isUserDataLoaded) {
            const result = getProjectQueryStorage(user.userId)

            if (result) {
                dispatch(SET_QUERY_PARAMS(result.query))
            }

            dispatch(GET_PROJECTS_ACTION())
        }
    }, [user, isUserDataLoaded])

    const { open: isThankYouModalOpen } = useModalState(MODAL_NAMES.payment.thankYou)

    return (
        <div className={styles.root}>
            <AppHeader />
            <ProjectsTableWrap />
            {isThankYouModalOpen && <ThankYouModal />}
            {canSeeAdminSettings && isAdminSettingsOpen && <AdminSettingsModal />}
        </div>
    )
}
export default Dashboard
