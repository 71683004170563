import { FC, ReactNode, HTMLAttributes } from 'react'

import styles from './index.module.scss'

type Props = {
    children: ReactNode
} & HTMLAttributes<HTMLDivElement>

const HoverElement: FC<Props> = ({ children, ...rest }) => {
    return (
        <div {...rest} className={styles.root}>
            {children}
        </div>
    )
}

export default HoverElement
