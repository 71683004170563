import { showErrorToast } from '@root/utils/toasts'

export class ProcessingCoreError extends Error {
    constructor(message?: string) {
        super(message)

        showErrorToast({ title: 'Error', message: message })
    }
}

export class InvalidOrderTypeError extends ProcessingCoreError {}

export class InsufficientMarginError extends ProcessingCoreError {}
