import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, ModalTabs, Tooltip, Separator } from '@fto/ui'

import { IconButton } from '@fto/icons'

import { EOperationType, OrderType, StoplossState, TakeprofitState } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import CreateOrderStore from '@fto/lib/store/createOrder'
import { orderModelType } from '@fto/lib/store/createOrder/types'

import InputCreator from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/InputCreator'
import LossProfitCalculation from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/LossProfitCalculation'
import LotRiskPanel from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/LotRiskPanel'
import SellBuyTabs from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/SellBuyTabs'

import { TABS } from '../../constants/tabs'
import TextWithHint from './components/TextWithHint'

import styles from './index.module.scss'

type Props = {
    orderData: orderModelType
    comment: string
    setComment: (val: string) => void
    handleModalClose: (isPPricePick?: boolean) => void
    isEdit?: boolean
}

const ContentBlock: FC<Props> = ({ orderData, comment, setComment, isEdit = false, handleModalClose }) => {
    const { t } = useTranslation()

    const {
        m_atPrice: atPrice,
        m_orderType: activeTab,
        m_operationType: orderType,
        m_ask: askValue,
        m_bid: bidValue,
        m_spread: spread,
        m_lot: lot,
        m_riskInUsd: riskInUsd,
        m_riskInPercent: riskInPercent,
        m_stoplossState: isStopLossActive,
        m_takeprofitState: isTakeProfitActive,
        stoplossValues,
        takeProfitValues,
        m_riskRewardRatio: riskRewardRatio,
        m_priceForLotPoint: priceForLotPoint,
        pseudoFocuses,
        minimumPriceChange
    } = orderData

    const { onChange, onInputFocus, onInputBlur, onPricePick } = CreateOrderStore

    const setActiveTab = useCallback(
        (tabKey: string | number) => {
            onChange(tabKey, 'm_orderType')
        },
        [onChange]
    )
    const setOrderType = useCallback(
        (ordType: EOperationType) => {
            onChange(ordType, 'm_operationType')
        },
        [onChange]
    )

    const isAtPriceVisible = useMemo(() => activeTab !== OrderType.MARKET, [activeTab])

    return (
        <Flex direction='column' gap={12}>
            {!isEdit && (
                <Flex direction='column' gap={16}>
                    <ModalTabs
                        tabsList={TABS}
                        activeTab={activeTab}
                        handleTabChange={setActiveTab}
                        withTooltip
                        tooltipText='orders.modal.tooltips'
                    />
                    <SellBuyTabs
                        activeTab={orderType}
                        setActiveTab={setOrderType}
                        askValue={askValue}
                        bidValue={bidValue}
                        spread={spread}
                    />
                </Flex>
            )}

            {isAtPriceVisible && (
                <Flex direction='column' gap={4} flexGrow={1}>
                    <TextWithHint
                        gap={4}
                        type='interface-medium'
                        label='orders.modal.atPriceLabel'
                        tooltipText='orders.modal.tooltips.atPrice'
                    />
                    <Flex gap={4} alignItems='center'>
                        <Tooltip
                            content={t('orders.modal.tooltips.atPriceInput')}
                            placement='top'
                            arrow={false}
                            classNames={{ content: styles.tooltip }}
                        >
                            <InputCreator
                                hasPseudoError={!!atPrice.error.key}
                                errorMessage={t(`orders.modal.errors.${atPrice.error.key}`, {
                                    value: atPrice.error.value
                                })}
                                type='number'
                                error={!!atPrice.error.key}
                                value={atPrice.value}
                                onChange={(value) => {
                                    onChange(Number(value), 'm_atPrice')
                                }}
                                onFocus={(event) => {
                                    onInputFocus(Number(event.target.value), 'm_atPrice')
                                }}
                                onBlur={(event) => {
                                    onInputBlur(Number(event.target.value), 'm_atPrice')
                                }}
                                block
                                placeholder='0'
                                step={minimumPriceChange}
                            />
                        </Tooltip>
                        <Tooltip content={t('orders.modal.tooltips.atPricePipette')} placement='top' arrow={false}>
                            <IconButton
                                name='picker'
                                size={18}
                                color='var(--color-gray-900)'
                                onClick={() => {
                                    onPricePick('atPricePick')
                                    handleModalClose(true)
                                }}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            )}

            <LotRiskPanel
                disabled={isEdit && activeTab === OrderType.MARKET}
                priceForLotPoint={priceForLotPoint}
                showRisks={isStopLossActive === StoplossState.ENABLED}
                lot={lot}
                riskInUsd={riskInUsd}
                riskInPercent={riskInPercent}
                pseudoFocuses={pseudoFocuses}
            />
            <Separator width='100%' />
            <LossProfitCalculation
                isStopLossActive={isStopLossActive === StoplossState.ENABLED}
                isTakeProfitActive={isTakeProfitActive === TakeprofitState.ENABLED}
                stoplossValues={stoplossValues}
                takeProfitValues={takeProfitValues}
                riskRewardRatio={riskRewardRatio}
                pseudoFocuses={pseudoFocuses}
                minimumPriceChange={minimumPriceChange}
                handleModalClose={handleModalClose}
            />
            <Tooltip content={t('orders.modal.tooltips.commentInput')} placement='left' arrow={false}>
                <textarea
                    placeholder={t('orders.modal.comment.placeholder')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className={styles.comment}
                />
            </Tooltip>
        </Flex>
    )
}

export default ContentBlock
