import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import cx from 'classnames'

import { Flex, Separator, Toggle, Typography, Button, addModal } from '@fto/ui'
import { Icon, IconButton } from '@fto/icons'

import { Closable, RibbonOptionType } from '../types'

import styles from '../../../../common.module.scss'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { showErrorToast } from '@root/utils/toasts'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useTranslation } from 'react-i18next'
import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'

type Props = {
    options: Map<number, RibbonOptionType[]>
    name: string
    close: () => void
    additionalElements?: FC<Closable> | ReactNode
    selectedChartInfo: ChartInfoType
    activeOption?: string
}

const LayoutDropdownMenu: FC<Props> = ({ options, name, close, activeOption, selectedChartInfo }) => {
    const { t } = useTranslation()

    const handleClick = useCallback(
        (option: RibbonOptionType) => {
            option.action && option.action()
            close()
        },
        [close]
    )

    const rows = useMemo(() => {
        return Array.from(options.entries())
    }, [options])

    const maxChartsAreAlreadyUsed = selectedChartInfo.count === CommonConstants.MAX_NUMBER_OF_CHARTS

    const addChart = useCallback(() => {
        if (maxChartsAreAlreadyUsed) {
            return
        }
        addModal(MODAL_NAMES.chart.changeSymbol, { modalType: 'addChart' })

        close()
    }, [maxChartsAreAlreadyUsed])

    return (
        <Flex direction='column' justifyContent='flex-start' gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            <Flex direction='column' gap={10}>
                {rows.map(([num, options], idx) => {
                    const isLastRow = idx === rows.length - 1
                    return (
                        <>
                            <Flex direction={'row'} gap={10} alignItems={'center'}>
                                <Typography
                                    className={cx(styles.text, styles.alignSelfCenter)}
                                    color='gray-600'
                                    type='tiny-regular'
                                >
                                    {num}
                                </Typography>
                                <Flex key={num} direction='row' gap={1}>
                                    {options.map((option) => {
                                        const isActive = option.value === activeOption

                                        if (!option.icon) {
                                            return <></>
                                        }

                                        return (
                                            <IconButton
                                                className={cx(styles.LayoutIconButton, { [styles.selected]: isActive })}
                                                key={option.value}
                                                onClick={() => handleClick(option)}
                                                name={option.icon}
                                                size={18}
                                                color={isActive ? 'primary-500' : 'gray-1000'}
                                            />
                                        )
                                    })}
                                </Flex>
                            </Flex>
                            {!isLastRow && <Separator margin='2px' width='100%' color='gray-400' />}
                        </>
                    )
                })}
            </Flex>

            <Button
                withTooltip
                tooltipText={t(`ribbon.tooltips.${maxChartsAreAlreadyUsed ? 'chartLimit' : 'addChart'}`)}
                onClick={addChart}
                size='compact'
                type='secondary'
                label={t('ribbon.addChart')}
                before={<Icon name='circled-plus' />}
                disabled={maxChartsAreAlreadyUsed}
                classNames={{
                    content: cx({ [styles.disabled]: maxChartsAreAlreadyUsed }, styles.fullWidth),
                    root: cx(styles.fullWidth, styles.withTopMargin)
                }}
            />
        </Flex>
    )
}

export default LayoutDropdownMenu
