import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TLineStyle } from './TLineStyle'

export class TBufferStyle extends TLineStyle {
    isVisible = true

    constructor(aColor: TColor, aStyle: TPenStyle, aWidth: number, isVisible = true) {
        super(aColor, aStyle, aWidth)
        this.isVisible = isVisible
    }

    clone(): TBufferStyle {
        return new TBufferStyle(this.color, this.style, this.width, this.isVisible)
    }

    SaveToStr(): string {
        return JSON.stringify({
            color: this.color,
            style: this.style,
            width: this.width,
            isVisible: this.isVisible
        })
    }

    LoadFromStr(s: string): void {
        const obj = JSON.parse(s)
        this.color = obj.color
        this.style = obj.style as TPenStyle
        this.width = obj.width
        this.isVisible = obj.isVisible
    }
}
