import { FC, useCallback, useMemo, useState } from 'react'

import { Flex, Link, Tag, Typography } from '@fto/ui'

import styles from '../../projects-analytics.module.scss'

type Props = {
    pairs: string[]
}

export const AnalyticsPairs: FC<Props> = ({ pairs }) => {
    const [isOverflow, setIsOverflow] = useState(pairs.length > 10)
    const pairsArray = useMemo(() => {
        return isOverflow ? pairs.slice(0, 9) : pairs
    }, [isOverflow, pairs])

    const hideOverflow = useCallback(() => {
        setIsOverflow(false)
    }, [])

    return (
        <Flex flexWrap='wrap'>
            {pairsArray.map((pair: string, index: number) => (
                <Tag
                    color={'gray-900'}
                    type={'subtext-regular'}
                    className={styles.tag}
                    text={pair}
                    key={`${pair}/${index}`}
                />
            ))}
            {isOverflow && (
                <Link onClick={hideOverflow} className={styles.link}>
                    <Typography color='primary-500' type='interface-medium'>{`Show ${
                        pairs.length - 9
                    } more`}</Typography>
                </Link>
            )}
        </Flex>
    )
}
