import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataRecordWithDate } from './TDataRecordWithDate'

export class TTickRecord extends TDataRecordWithDate {
    public bid: number
    public ask: number
    public volume: number

    constructor(dateTime: TDateTime, bid: number, ask: number, volume: number) {
        super(dateTime)
        this.bid = bid
        this.ask = ask
        this.volume = volume
    }
}
