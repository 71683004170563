import { FC, useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Button, Flex, Typography } from '@fto/ui'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { fireMixpanelEvent } from '@root/utils/api'
import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'

import styles from '../common.module.scss'
import { CustomTimeFrame } from '@fto/lib/store/timeframe/types'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'

type Props = {
    removeMenu: () => void
    chartWindow: TChartWindow
    store: any
}

const TimeframeMenuContent: FC<Props> = ({ removeMenu, chartWindow, store }) => {
    const { t } = useTranslation()
    const currentTimeframe = chartWindow.getCurrentTimeframe()
    const { timeframe, setTimeframe, customTimeframes } = store

    const changeTimeFrame = useCallback(
        (timeFrame: number) => {
            fireMixpanelEvent('timeframe_changed', {
                from: timeframe,
                to: timeFrame,
                currency: chartWindow.SelectedSymbolName
            })
            GlobalChartsController.Instance.setNewTimeFrame(timeFrame)
            setTimeframe(timeFrame)
            removeMenu()
        },
        [removeMenu, setTimeframe, timeframe]
    )

    return (
        <Flex direction='column' gap={8} className={styles.timeframe}>
            <Flex direction='column' gap={4}>
                <Typography className={styles.header} type='tiny-regular' color='gray-600'>
                    {t('contextMenu.timeframe')}
                </Typography>
                <Flex direction='column' gap={8} className={styles.ScrollableList}>
                    <Flex direction='column' gap={4}>
                        {TIME_FRAMES.map((timeframeFromArray) => (
                            <Button
                                block
                                key={timeframeFromArray}
                                onClick={() => changeTimeFrame(timeframeFromArray)}
                                label={TimeframeUtils.GetTimeframeNameLabel(timeframeFromArray as number).label}
                                classNames={{
                                    content: cx(styles.buttonContent, {
                                        [styles.selected]: currentTimeframe === timeframeFromArray
                                    })
                                }}
                                size='compact'
                                type='tetriatry-white'
                            ></Button>
                        ))}
                    </Flex>
                    {customTimeframes.length > 0 && (
                        <Flex direction='column' gap={4}>
                            <Typography className={styles.header} type='tiny-regular' color='gray-600'>
                                {t('customTimeframes.select.customTimeframes')}
                            </Typography>
                            <Flex direction='column' gap={4} className={styles.CustomTimeframesList}>
                                {customTimeframes.map((customTimeframe: CustomTimeFrame) => (
                                    <Button
                                        block
                                        key={customTimeframe.id}
                                        onClick={() => changeTimeFrame(customTimeframe.value)}
                                        label={customTimeframe.longName}
                                        classNames={{
                                            content: cx(styles.buttonContent, {
                                                [styles.selected]: currentTimeframe === customTimeframe.value
                                            })
                                        }}
                                        size='compact'
                                        type='tetriatry-white'
                                    ></Button>
                                ))}
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default TimeframeMenuContent
