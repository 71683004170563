import { TMyObjectList } from '../../../ft_types/common/Common'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import StrangeError from '../../../common/common_errors/StrangeError'
import { t } from 'i18next'
import { TOptionType, TOptValue } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDLLOptionRec } from './TDLLOptionRec'
import { TLineStyleRec } from '@fto/lib/drawing_interface/GraphicObjects/TLineStyleRec'

const validOptionTypes: Set<number> = new Set([
    TOptionType.ot_Longword,
    TOptionType.ot_Integer,
    TOptionType.ot_double,
    TOptionType.ot_String,
    TOptionType.ot_Boolean,
    TOptionType.ot_EnumType,
    TOptionType.ot_Timeframe,
    TOptionType.ot_Currency,
    TOptionType.ot_LineStyle,
    TOptionType.ot_Separator,
    TOptionType.ot_Color,
    TOptionType.ot_DateTime,
    TOptionType.at_Levels,
    TOptionType.ot_Session,
    TOptionType.ot_SessionsArray
    //TODO: TDLLOptionType.ot_HotKey,
])

export class TDllOptions extends TMyObjectList<TDLLOptionRec> {
    public AddSeparator(text: string): void {
        this.RegOption(text, TOptionType.ot_Separator)
    }

    public GetParamsString(): string {
        let result = ''
        let cnt = 0
        for (let i = 0; i < this.length; i++) {
            const rec: TDLLOptionRec = this[i]
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.isvisible) {
                result += `${rec.Value}, `
                cnt++
            }
        }
        if (cnt > 0) {
            result = StrsConv.DelSymbolsRight(result, 2)
        }
        return result
    }

    DeleteByMask(mask: string): void {
        // Iterate backwards through the items array to safely remove items without affecting the loop indexing
        for (let i = this.count - 1; i >= 0; i--) {
            if (this.GetItem(i).name.startsWith(mask)) {
                this.Delete(i)
            }
        }
    }

    Assign(options: TDllOptions): void {
        for (let i = 0; i < options.Count; i++) {
            const option = options[i] // Assuming items is the property that gives access to the option by index
            const rec = this.GetOptionRec(option.name)
            if (rec) {
                rec.Value = option.Value
            }
        }
    }

    AddOptionValue(name: string, value: string): void {
        const rec = this.GetOptionRec(name)
        // eslint-disable-next-line eqeqeq
        if (rec != null) {
            rec.values.push(value)
        }
    }

    SetOptionRange(name: string, LowValue: number, HighValue: number): void {
        const rec = this.GetOptionRec(name)
        // Check for both null and undefined to ensure rec is a valid object.
        // eslint-disable-next-line eqeqeq
        if (rec != null) {
            // Using != to check for both null and undefined.
            rec.LowValue = LowValue
            rec.HighValue = HighValue
        }
    }

    GetOptionRec(name: string): TDLLOptionRec | undefined {
        for (let i = 0; i < this.Count; i++) {
            const item = this.GetItem(i)
            if (item.name === name) {
                return item
            }
        }
        return undefined
    }

    public RegApplyToPriceOption(option: TOptValue, name = ''): void {
        if (name === '') {
            name = t('indicatorModal.general.applyToPrice')
        }

        this.RegOption(name, TOptionType.ot_EnumType, option)
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.close'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.open'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.high'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.low'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hl2'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hlc3'))
        this.AddOptionValue(name, t('indicatorModal.general.applyToPriceOptions.hlcc4'))
        // Uncomment the following line if the option should be reset to 0 as indicated by the commented line in the Delphi code.
        // option.value = 0;

        // return option;
    }

    public RegMATypeOption(option: TOptValue, name = ''): void {
        // Set the default name for the option if not provided
        if (name === '') {
            name = t('indicatorModal.movingAverage.fields.maType')
        }

        // Register the option with the given name and type
        this.RegOption(name, TOptionType.ot_EnumType, option)

        // Add possible values for the MA type option
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.sma'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.ema'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.ssma'))
        this.AddOptionValue(name, t('indicatorModal.general.typeOptions.wma'))
    }

    RegOption(
        aName: string,
        aType: TOptionType,
        optPtrOrValue?: string | number | boolean | TLineStyleRec | TOptValue,
        inv = true,
        useInNameWithParams = true
    ): void {
        if (!validOptionTypes.has(aType)) {
            throw new StrangeError(`Invalid option type: ${aType}`)
        }

        const opt: TDLLOptionRec = new TDLLOptionRec(aName, aType, optPtrOrValue, inv)
        opt.useInNameWithParams = useInNameWithParams
        // Add the new option record to the collection
        this.Add(opt)
    }

    LoadFromList(list: TOffsStringList): void {
        list.Reindex()
        for (let i = 0; i < list.Count; i++) {
            const s = list.GetItem(i)
            const rec = this.GetOptionRec(StrsConv.GetSubStr(s, '=')[0])
            if (rec) {
                try {
                    rec.Value = StrsConv.GetSubStr(s, '=')[1]
                } catch (error) {
                    throw new StrangeError(`Dll options LoadFromList - ${(error as Error).message}`)
                    // value assign failed
                }
            }
        }
    }

    SaveToList(list: TOffsStringList): void {
        list.Clear()
        for (let i = 0; i < this.count; i++) {
            if (this[i].OptionType !== TOptionType.ot_Separator) {
                list.Add(`${this[i].name}=${this[i].Value}`)
            }
        }
    }
}
