import { FC, useMemo } from 'react'
import { Flex, Typography } from '@fto/ui'
import styles from './profit-and-loss.module.scss'
import classNames from 'classnames'
import { Icon } from '@fto/icons'
import { formatMoney } from '@root/utils/filters'

type ProfitAndLoseTagProps = {
    profitLoss: number
    deposit: number
}

const ProfitAndLoseTag: FC<ProfitAndLoseTagProps> = ({ profitLoss, deposit }) => {
    const isProfit = profitLoss > 0
    const percent = Math.abs((profitLoss / deposit) * 100)

    const profitOrLossValue = useMemo(() => formatMoney(profitLoss, true), [deposit, profitLoss])

    const textColor = useMemo(() => {
        if (profitLoss > 0) {
            return 'green-900'
        }

        if (profitLoss < 0) {
            return 'red-900'
        }

        return 'gray-800'
    }, [profitLoss])

    const iconColor = useMemo(() => {
        if (profitLoss > 0) {
            return 'var(--color-green-900)'
        }

        if (profitLoss < 0) {
            return 'var(--color-red-900)'
        }

        return 'gray-900'
    }, [profitLoss])

    const isZeroValue = profitLoss === 0

    return (
        <Flex
            className={classNames(styles.body, { [styles.bgProfit]: isProfit, [styles.bgLose]: profitLoss < 0 })}
            alignItems={'center'}
            gap={isZeroValue ? 20 : 8}
        >
            {isZeroValue ? (
                <div className={styles.Point} />
            ) : (
                <Icon size={12} name={isProfit ? 'arrow-trend-up' : 'arrow-trend-down'} color={iconColor} />
            )}
            <Flex alignItems='center' gap={4}>
                {!isZeroValue && (
                    <Typography color={'white'} type={'subtext-medium'}>
                        {isProfit ? '+' : '-'}
                    </Typography>
                )}
                <Typography color={isZeroValue ? 'gray-800' : 'white'} type={'subtext-medium'}>
                    {profitOrLossValue}
                </Typography>
                <Typography color={textColor} type={'subtext-medium'}>
                    ({Number(percent.toFixed(2))}%)
                </Typography>
            </Flex>
        </Flex>
    )
}

export default ProfitAndLoseTag
