import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
export abstract class CreateIndicatorStarategy {
    public abstract createIndicatorForStrategy(
        symbolData: TSymbolData,
        timeframe: number,
        indicatorFile: string,
        indicatorParams: string
    ): TRuntimeIndicator

    protected initRuntimeIndicator(runtimeIndicator: TRuntimeIndicator, indicatorParams: string): void {
        runtimeIndicator.Init()
        runtimeIndicator.SetImportantOptions(indicatorParams)
        runtimeIndicator.SaveOptions()
        runtimeIndicator.RefreshLevels()
        runtimeIndicator.RefreshOptions()
    }
}
