import { FC, ReactNode, useEffect, useState } from 'react'
import cx from 'classnames'

import styles from './index.module.scss'

type Props = {
    children: ReactNode
    type?: 'error' | 'success' | 'warning'
    className?: string
    onClose?: (isVisible: boolean) => void
    delay?: number
    isShown?: boolean
    disappearing?: boolean
}

const MessageInformer: FC<Props> = ({
    children,
    type,
    className = '',
    delay = 5000,
    onClose,
    isShown = false,
    disappearing = false
}) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        setIsVisible(isShown)
    }, [isShown])

    useEffect(() => {
        if (isVisible && disappearing) {
            const timer = setTimeout(() => {
                setIsVisible(false)
                onClose?.(false)
            }, delay)

            return () => clearTimeout(timer)
        }
    }, [isVisible])

    if (!isVisible) {
        return null
    }

    return <div className={cx(styles.root, className, { [styles[`type-${type}`]]: type })}>{children}</div>
}

export default MessageInformer
