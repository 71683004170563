import { TTradePositionType } from '../extension_modules/common/CommonExternalInterface'

export class MarketValues {
    symbol: string | null = null
    ask: number = 1.0883
    bid: number = 1.08819
    spread: number = 11
    equity: number = 43457.77
    minimumPriceChange: number = 0.00001
    postDecimalDigits: number = 5
    pointValueForOneStandardLot: number = 1
    minimumDistanceToPrice: number = 5.0
    marginValueForOneLotInUsd: number = 1258.0

    getMinimumDistanceToPriceStr(): string {
        return Math.round(this.minimumDistanceToPrice).toString()
    }
}

export enum OrderType {
    MARKET,
    STOP,
    LIMIT
}

export enum EOperationType {
    BUY,
    SELL
}

export enum StoplossState {
    ENABLED,
    DISABLED
}

export enum TakeprofitState {
    ENABLED,
    DISABLED
}

export class StoplossValues {
    price: number = 0.0
    points: number = 0.0
    usd: number = 0.0
    percent: number = 0.0
}

export class TakeprofitValues {
    price: number = 0.0
    points: number = 0.0
    usd: number = 0.0
    percent: number = 0.0
}

export class OrderValues {
    lot: number = 0.0
    atPrice: number = 0.0
    operationType: TTradePositionType | null = null
}

export enum StoplossRecalculationBase {
    PRICE,
    POINTS,
    USD,
    PERCENTS
}

export enum TakeprofitRecalculationBase {
    PRICE,
    POINTS,
    USD,
    PERCENTS
}

export enum LotRecalculationBase {
    LOT,
    RISK_IN_USD,
    RISK_IN_PERCENT
}

export enum StringValidationError {
    INVALID_NUMBER_FORMAT
}

export enum ValidationError {
    INVALID_NUMBER_FORMAT
}

export enum ControlNames {
    STOPLOSS_PRICE = 'stoplossPrice',
    STOPLOSS_POINTS = 'stoplossPoints',
    STOPLOSS_USD = 'stoplossUsd',
    STOPLOSS_PERCENT = 'stoplossPercent',
    TAKEPROFIT_PRICE = 'takeprofitPrice',
    TAKEPROFIT_POINTS = 'takeprofitPoints',
    TAKEPROFIT_USD = 'takeprofitUsd',
    TAKEPROFIT_PERCENT = 'takeprofitPercent',
    LOT = 'lot',
    RISK_IN_USD = 'riskInUsd',
    RISK_IN_PERCENT = 'riskInPercent',
    ATPRICE = 'atPrice'
}

export const TradeTypeMap: Record<OrderType, Record<number, number>> = {
    [OrderType.MARKET]: {
        [TTradePositionType.tp_Buy]: TTradePositionType.tp_Buy,
        [TTradePositionType.tp_Sell]: TTradePositionType.tp_Sell
    },
    [OrderType.LIMIT]: {
        [TTradePositionType.tp_Buy]: TTradePositionType.tp_BuyLimit,
        [TTradePositionType.tp_Sell]: TTradePositionType.tp_SellLimit
    },
    [OrderType.STOP]: {
        [TTradePositionType.tp_Buy]: TTradePositionType.tp_BuyStop,
        [TTradePositionType.tp_Sell]: TTradePositionType.tp_SellStop
    }
}
