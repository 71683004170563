import { FilenameTypes } from '@fto/icons'

export const FILTER_BY_SYMBOL_OPTIONS = [
    {
        value: 'all',
        localeKey: 'terminal.toolbar.symbolFilter.all',
        iconName: 'check' as (typeof FilenameTypes)[number]
    },
    {
        value: 'current',
        localeKey: 'terminal.toolbar.symbolFilter.current',
        iconName: 'check' as (typeof FilenameTypes)[number]
    }
]

export const FILTER_BY_DATE_OPTIONS = [
    {
        value: 'openDate',
        localeKey: 'terminal.toolbar.dateFilter.openDate',
        iconName: 'check' as (typeof FilenameTypes)[number]
    },
    {
        value: 'closeDate',
        localeKey: 'terminal.toolbar.dateFilter.closeDate',
        iconName: 'check' as (typeof FilenameTypes)[number]
    },
    {
        value: 'openOrCloseDate',
        localeKey: 'terminal.toolbar.dateFilter.openOrCloseDate',
        iconName: 'check' as (typeof FilenameTypes)[number]
    },
    {
        value: 'openAndCloseDate',
        localeKey: 'terminal.toolbar.dateFilter.openAndCloseDate',
        iconName: 'check' as (typeof FilenameTypes)[number]
    }
]
