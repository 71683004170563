import { CreateProjectPayload } from '@root/types/projects'

export type SelectOptions = {
    value: string
    localeKey: string
}
type DataTypeCheckboxes = {
    value: CreateProjectPayload['DataType']
    localeKey: string
}

const dataCheckboxes: DataTypeCheckboxes[] = [
    {
        value: 'OneMin',
        localeKey: 'dashboard.modals.projects.advanced.dataCheck1'
    },
    {
        value: 'OneMinTicks',
        localeKey: 'dashboard.modals.projects.advanced.dataCheck2'
    }
]

const startDateCheckboxes = [
    {
        value: 'firstDate',
        localeKey: 'dashboard.modals.projects.advanced.datastartCheck1'
    },
    {
        value: 'manualDate',
        localeKey: 'dashboard.modals.projects.advanced.datastartCheck2'
    }
]

export const SESSION_ZONES = {
    sydney: {
        value: 'Sydney',
        tz: -6
    },
    tokyo: {
        value: 'Tokyo',
        tz: -8
    },
    london: {
        value: 'London',
        tz: 7
    },
    newYork: {
        value: 'NewYork',
        tz: 2
    },
    custom: {
        value: 'Custom'
    }
}

const sessionCloseTime: SelectOptions[] = [
    {
        value: SESSION_ZONES.sydney.value,
        localeKey: 'dashboard.modals.projects.advanced.sessionCloseTime1'
    },
    {
        value: SESSION_ZONES.tokyo.value,
        localeKey: 'dashboard.modals.projects.advanced.sessionCloseTime2'
    },
    {
        value: SESSION_ZONES.london.value,
        localeKey: 'dashboard.modals.projects.advanced.sessionCloseTime3'
    },
    {
        value: SESSION_ZONES.newYork.value,
        localeKey: 'dashboard.modals.projects.advanced.sessionCloseTime4'
    },
    {
        value: SESSION_ZONES.custom.value,
        localeKey: 'dashboard.modals.projects.advanced.customCloseTime'
    }
]

export const SESSION_CLOSE_TIME_PARAMS_BY_ZONE = [
    {
        timeZone: -6,
        session: SESSION_ZONES.sydney.value
    },
    {
        timeZone: -8,
        session: SESSION_ZONES.tokyo.value
    },
    {
        timeZone: 7,
        session: SESSION_ZONES.london.value
    },
    {
        timeZone: 2,
        session: SESSION_ZONES.newYork.value
    }
]

const dstOptions: SelectOptions[] = [
    {
        value: 'None',
        localeKey: 'dashboard.modals.projects.advanced.dstOptions1'
    },
    {
        value: 'US',
        localeKey: 'dashboard.modals.projects.advanced.dstOptions2'
    },
    {
        value: 'EU',
        localeKey: 'dashboard.modals.projects.advanced.dstOptions3'
    },
    {
        value: 'AU',
        localeKey: 'dashboard.modals.projects.advanced.dstOptions4'
    }
]

export { dataCheckboxes, startDateCheckboxes, sessionCloseTime, dstOptions }
