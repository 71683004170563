import { RootState } from '@root/store'
import { createSelector } from '@reduxjs/toolkit'

export type SymbolsByCategories = {
    [key: string]: string[]
}[]
const symbolsStore = (state: RootState) => state.symbols

export const $getSymbols = (state: RootState) => symbolsStore(state).symbols
export const $getSymbolsStatus = (state: RootState) => symbolsStore(state).loading

export const $getSymbolsProcessData = createSelector(symbolsStore, ({ loading, error, symbols }) => {
    return { loading, error, symbols }
})
