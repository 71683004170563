import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Separator } from '@fto/ui'

import styles from './common.module.scss'

const cells = [
    {
        title: 'symbol',
        flex: 7
    },
    {
        title: 'lastPrice',
        flex: 4
    },
    {
        title: 'priceChange',
        flex: 4
    }
]

const TableHead: FC = () => {
    const { t } = useTranslation()

    return (
        <Flex justifyContent='space-between' alignItems='center' className={styles.th} gap={8} block>
            {cells.map((cell, index) => (
                <Fragment key={index}>
                    <Typography
                        type='subtext-regular'
                        color={'gray-800'}
                        className={styles.cellLabel}
                        truncate
                        style={{ flex: cell.flex }}
                    >
                        {t('addChartModal.fields.' + cell.title)}
                    </Typography>
                    {index !== cells.length - 1 && <Separator height='16px' color='gray-500' />}
                </Fragment>
            ))}
        </Flex>
    )
}

export default TableHead
