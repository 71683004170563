import { JumpToStrategy } from '@fto/lib/JumpTo/JumpToStrategies/JumpToStrategy'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { t } from 'i18next'

export class JumpToConcreteNextDayTime extends JumpToStrategy {
    private _openTimeDT: TDateTime
    private _lastProcessedTick: TDateTime

    constructor(openTimeInSecondsFromStartDay: number, lastProcessedTick: TDateTime) {
        super()
        this._openTimeDT = openTimeInSecondsFromStartDay * DateUtils.OneSecond
        this._lastProcessedTick = lastProcessedTick
    }

    public jump(): void {
        const nextDayDT = this.getNextDayConcreteDateTimeFromTime(this._lastProcessedTick)
        import('@fto/lib/globals/GlobalTestingManager').then((module) => {
            const GlobalTestingManager = module.default
            GlobalTestingManager.TestingManager.RollTestingTo(nextDayDT, true)
        })
    }

    getNextDayConcreteDateTimeFromTime(fromTime: TDateTime, addDay: boolean = true): TDateTime {
        let userTestingDay = Math.trunc(
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(fromTime)
        )
        let userDayOpen = userTestingDay + this._openTimeDT + (addDay ? DateUtils.OneDay : 0)
        let libDayOpen = GlobalTimezoneDSTController.Instance.convertToInnerlibDateTimeByTimezoneAndDst(userDayOpen)

        const activechart = GlobalChartsController.Instance.getActiveChart()
        if (activechart) {
            const tickAfter = activechart.SymbolData.fTickData.GetTickAtDate(
                libDayOpen,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher
            )

            const tickBefore = activechart.SymbolData.fTickData.GetTickAtDate(
                libDayOpen,
                TNoExactMatchBehavior.nemb_ReturnNearestLower
            )

            if (tickAfter && tickBefore) {
                const shitedTickAfter =
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(tickAfter.DateTime)
                const shiftedTickBefore =
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
                        tickBefore.DateTime
                    )

                if (Math.trunc(shiftedTickBefore) === Math.trunc(userDayOpen)) {
                    return tickBefore.DateTime
                } else if (
                    Math.trunc(shiftedTickBefore) < Math.trunc(userDayOpen) &&
                    Math.trunc(shitedTickAfter) > Math.trunc(userDayOpen)
                ) {
                    return this.getNextDayConcreteDateTimeFromTime(tickAfter.DateTime, false)
                } else if (Math.trunc(shitedTickAfter) === Math.trunc(userDayOpen)) {
                    return tickAfter.DateTime
                } else {
                    return this.getNextDayConcreteDateTimeFromTime(tickAfter.DateTime)
                }
            } else {
                throw new StrangeError('No tick before session open')
            }
        } else {
            throw new StrangeError('No active chart')
        }
    }
}
