import { TFontStyles, TColor, TFontStyle, DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { NotImplementedError } from '@fto/lib/utils/common_utils'

export class TMkFontStyle {
    name: string
    style: TFontStyles
    size: number
    color: TColor

    constructor(aName: string, aStyle: TFontStyles, aSize: number, aColor: TColor) {
        this.name = aName
        this.style = aStyle
        this.size = aSize
        this.color = aColor
    }

    public static DEFAULT_FONT_NAME = 'Roboto Flex'
    public static DEFAULT_FONT_SIZE = 14
    public static DEFAULT_FONT_COLOR = DelphiColors.clBlack
    public static DEFAULT_FONT_STYLE = TFontStyle.fsNone

    clone(): TMkFontStyle {
        return new TMkFontStyle(this.name, this.style, this.size, this.color)
    }

    SaveToStr(): string {
        throw new NotImplementedError('TMkFontStyle.SaveToStr')
    }

    LoadFromStr(s: string): void {
        if (s === '') {
            return
        }

        let remainingString
        ;[this.name, remainingString] = StrsConv.GetSubStr(s, ',')
        ;[this.style, remainingString] = StrsConv.GetStrFontStyle(remainingString, ',')
        ;[this.size, remainingString] = StrsConv.GetStrInt(remainingString, ',')
        ;[this.color, remainingString] = StrsConv.GetStrColor(remainingString, ',')
    }
}
