import React, { FC, useState, useMemo, useCallback } from 'react'
import classNames from 'classnames'

import { Button, Flex, Typography, InputField } from '@fto/ui'

import { SymbolItemType } from '@root/types/symbols.types'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'
import { FormErrors } from '@root/hooks/useSubmitForm'

import { useDebounceHook } from '@root/hooks/useDebounce'
import SymbolsList from '@root/pages/Dashboard/components/Modals/CreateProjectModal/components/SymbolsList'

import style from './create-project-modal-symbols.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    symbols: SymbolItemType[]
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
    errors: FormErrors[]
} & React.HTMLAttributes<HTMLDivElement>

const CreateProjectModalSymbols: FC<Props> = ({ symbols, setFormData, className, errors, formData }) => {
    const { t } = useTranslation()

    const [searchValue, setSearchValue] = useState('')

    const debounce = useDebounceHook(searchValue, 200)

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value)
    }, [])

    const clearSymbols = useCallback(() => {
        setFormData((prevData) => ({
            ...prevData,
            Symbols: []
        }))
    }, [setFormData])

    const isError = useMemo(() => !!errors?.find((e) => e['Symbols']), [errors])

    return (
        <Flex className={classNames(style.symbolsBody, className)} direction='column' gap={16}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex gap={4} alignItems={'center'} className={style.Header}>
                    <Typography type='text-semibold'>{t('dashboard.modals.projects.selectSymbols')}</Typography>
                    <Typography color={'gray-600'} type={'interface-medium'}>
                        ({t('global.required')})
                    </Typography>
                </Flex>
                {(formData.Symbols?.length || 0) > 0 && (
                    <Button
                        onClick={clearSymbols}
                        size={'tiny'}
                        type={'tetriatry-gray'}
                        label={t('dashboard.modals.projects.clear')}
                    />
                )}
            </Flex>

            <div>
                <InputField
                    block
                    name='Symbols'
                    error={isError}
                    value={searchValue}
                    onChange={handleSearch}
                    placeholder={t('dashboard.modals.projects.search')}
                    reset
                    withSearchSuffix
                />
            </div>

            <SymbolsList symbols={symbols} formData={formData} setFormData={setFormData} debouncedValue={debounce} />
        </Flex>
    )
}

export default CreateProjectModalSymbols
