import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'
import dayjs from 'dayjs'

export function getLastSaveTime() {
    const lastSaveTimeUnix = SyncProjectManager.Instance.getLastSaveTime()
    const lastSaveTimeValue =
        lastSaveTimeUnix === 0 ? GlobalProjectJSONAdapter.Instance.getProjectLastUse() : lastSaveTimeUnix

    const timeString = new Date(lastSaveTimeValue)

    return dayjs(timeString).format('DD.MM.YYYY HH:mm:ss')
}

export function getMinutesSinceLastSave() {
    const lastSaveTimeUnix = SyncProjectManager.Instance.getLastSaveTime()
    const lastSaveTimeValue =
        lastSaveTimeUnix === 0 ? GlobalProjectJSONAdapter.Instance.getProjectLastUse() : lastSaveTimeUnix

    const lastSaveTimeDiff = Number(((Date.now() - lastSaveTimeValue) / 1000).toFixed(0))

    return Math.floor(lastSaveTimeDiff / 60)
}
