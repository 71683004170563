import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TFocusType } from './ChartingEnums'
import IBasicPaintTool from '../paint_tools/IBasicPaintTool'
import { TChart } from '../chart_classes/BasicChart'
import { TBasicPaintTool } from '../paint_tools/BasicPaintTool'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TFocusedPaintTool {
    tool!: IBasicPaintTool | null | TBasicPaintTool
    FocusType: TFocusType // focus type (moving/changing)
    time!: TDateTime // start time
    price: number // start price
    index: number // point's index
    start_index: number // start inddex on chart
    dragged: boolean // tool was dragged

    constructor() {
        this.tool = null
        this.FocusType = TFocusType.ft_None
        //   this.time = new Date();
        this.price = 0
        this.index = 0
        this.start_index = 0
        this.dragged = false
    }

    public get chart(): TChart | null {
        if (this.empty()) {
            return null
        }
        return this.tool?.chart as TChart
    }

    public Init(aTool: IBasicPaintTool): void {
        this.tool = aTool
        this.FocusType = TFocusType.ft_None
        if (this.tool) {
            this.tool.Highlighted = true
        }
    }

    public SetFocus(ft: TFocusType, x: number, y: number): void {
        this.FocusType = ft
        this.time = this.tool?.chart.GetBarDateFromX(x) || 0
        this.price = this.tool?.chart.GetPriceFromY(y) || 0

        if (this.FocusType === TFocusType.ft_Point && this.tool) {
            this.index = this.tool.PointUnderMouse(x, y)
            this.tool.HighlightedPoint = this.index
        } else {
            this.index = 0
            if (this.tool) {
                this.tool.HighlightedPoint = -1
            }
        }

        this.start_index = this.tool?.chart.GetIndexFromX(x) || 0
    }

    public empty(): boolean {
        return this.tool === null
    }

    public get focused(): boolean {
        return !this.empty() && this.FocusType !== TFocusType.ft_None
    }

    public selected(): boolean {
        return this.tool !== null && this.tool.Selected
    }

    public update(aTool: IBasicPaintTool): void {
        if (aTool === this.tool) {
            return
        }
        this.clear()
        if (aTool !== null) {
            this.Init(aTool)
        }
    }

    public UpdateHighlightedPoint(index: number): boolean {
        if (this.tool && this.tool.HighlightedPoint !== index) {
            this.tool.HighlightedPoint = index
            return true
        }
        return false
    }

    public duplicate(): void {
        if (!this.tool || !this.tool.chart) {
            throw new StrangeError('Tool or chart is not available for duplication.')
        }
        this.tool.Selected = false
        this.tool.Highlighted = false
        this.tool.HighlightedPoint = -1

        const chart = this.tool.chart as TChart

        const t: IBasicPaintTool | null = chart.PaintTools.DuplicateTool(this.tool, 1, false)

        // NOTE: Here we have to remove tools which we copied and replace store data to new tools (they are focused  after duplicate)
        const { updateParams } = GraphToolPanelStore

        updateParams((prevSettings) => {
            return {
                ...prevSettings,
                tools: this.chart?.PaintTools.getAllSelectedTools() || []
            }
        })

        if (t) {
            t.Highlighted = true
            this.tool = t
            this.tool.PrepareToMove()
        }
    }

    public clear(): void {
        if (this.tool) {
            this.tool.Highlighted = false
            this.tool.HighlightedPoint = -1
        }

        this.tool = null
        this.dragged = false
    }
}
