import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import IFMBarsArray from '../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import TIndicatorBufferArray from '../../ft_types/data/data_arrays/chunked_arrays/IndicatorBufferArray/IndicatorBufferArray'
import { TDataDescriptor } from '../../ft_types/data/data_arrays/DataDescriptionTypes'
import { IIndexBuffer } from './api/IIndexBuffer'

export abstract class TIndexBufferProgramSide implements IIndexBuffer {
    public bars: IFMBarsArray

    private _shift: number | null = null
    public get shift(): number {
        if (this._shift === null || this._shift === undefined) {
            throw new StrangeError('Shift is not assigned.')
        }
        return this._shift
    }
    public set shift(value: number) {
        this._shift = value
    }

    public EmptyValue: number

    constructor(aBars: IFMBarsArray, aEmptyValue: number) {
        this.bars = aBars
        this.EmptyValue = aEmptyValue
    }

    public abstract GetMax(index1: number, index2: number, EmptyValue?: number): number
    public abstract GetMin(index1: number, index2: number, EmptyValue?: number): number
    public abstract GetValue(globalIndex: number): number
    public abstract GetDateTime(globalIndex: number): TDateTime
    public abstract GetValue_reversedIndex(lastItemEquals_0_based_index: number): number
    public abstract IsEmpty(globalIndex: number): boolean

    public abstract SetValue(globalIndex: number, value: number): void
    //TODO: should we replace nulls here with EmptyValue?
    public abstract SetValue_reversedIndex(lastItemEquals_0_based_index: number, value: number | null): void
    public abstract Clear(): void
    public abstract HasSomeValues(): boolean
    public abstract Count(): number
    public abstract GetMaxPossibleGlobalIndex(): number
    public abstract get LastItemInTestingIndex(): number
    // abstract IsIndexValid(index: number): boolean;
}

export class TCommonIndexBuffer extends TIndexBufferProgramSide {
    public buff!: TIndicatorBufferArray

    constructor(aBars: IFMBarsArray, aEmptyValue: number) {
        super(aBars, aEmptyValue)

        this.buff = new TIndicatorBufferArray(aBars)
        this.shift = 0
        this.EmptyValue = aEmptyValue
    }

    public set setDataDescriptor(value: TDataDescriptor) {
        this.buff.setDataDescriptor = value
    }

    public set setBarsArray(value: IFMBarsArray) {
        this.buff.setBarsArray = value
    }

    public get LastItemInTestingIndex(): number {
        return this.bars.LastItemInTestingIndex
    }

    public GetMaxPossibleGlobalIndex(): number {
        return this.bars.LastItemInTestingIndex - 1
    }

    IsEmpty(globalIndex: number): boolean {
        const item = this.buff.GetItemByGlobalIndex(globalIndex, false, true)
        if (!item || item.IsEmpty()) {
            return true
        }
        return false
    }

    public Count(): number {
        return this.buff.Chunks.count
    }

    public HasSomeValues(): boolean {
        return this.buff.HasSomeValues()
    }

    public GetValue(globalIndex: number): number {
        const buffItem = this.buff.GetItemByGlobalIndex(globalIndex)
        if (!buffItem) {
            return this.EmptyValue
        }
        return buffItem.value
    }

    public SetValue(globalIndex: number, value: number): void {
        const buffItem = this.buff.GetItemByGlobalIndex(globalIndex)

        if (buffItem) buffItem.value = value
    }

    public GetDateTime(globalIndex: number): TDateTime {
        const buffItem = this.buff.GetItemByGlobalIndex(globalIndex)
        if (!buffItem) {
            return DateUtils.EmptyDate
        }
        return buffItem.DateTime
    }

    public GetValue_reversedIndex(lastItemEquals_0_based_index: number): number {
        let result = 0
        const firstItemEquals0_based_GlobalIndex =
            this.Reverse_from_lastItem0_to_firstItem0(lastItemEquals_0_based_index)
        if (firstItemEquals0_based_GlobalIndex < 0) {
            return 0
        }
        const buffItem = this.buff.GetItemByGlobalIndex(firstItemEquals0_based_GlobalIndex)
        if (buffItem) {
            if (buffItem.isValidDate()) {
                result = buffItem.value
            } else {
                result = 0
            }
        }

        return result
    }

    public GetMax(globalIndexFrom: number, globalIndexTo: number, EmptyValue: number): number {
        return this.buff.GetMax(globalIndexFrom, globalIndexTo, EmptyValue)
    }

    public GetMin(globalIndexFrom: number, globalIndexTo: number, EmptyValue: number): number {
        return this.buff.GetMin(globalIndexFrom, globalIndexTo, EmptyValue)
    }

    public Reverse_from_lastItem0_to_firstItem0(lastItemEquals_0_based_index: number): number {
        //for example we have 10 items in testing, lastItemEquals_0_based_index = 1 (meaning we need a pre-last item)
        //first-item-0-based index of pre-last item is 8 (very last item index would be 9 if we have 10 items)
        //so in order to return 8 we need to substract lastItemEquals_0_based_index (1 in this example) from the very last index (9 in this example)
        return this.bars.LastItemInTestingIndex - lastItemEquals_0_based_index
    }

    public SetValue_reversedIndex(lastItemEquals_0_based_index: number, value: number): void {
        if (!this.buff) {
            throw new StrangeError('SetValue - The buffer is not initialized.')
        }

        if (lastItemEquals_0_based_index < 0) {
            throw new RangeError(`SetValue - Index out of bounds. ${lastItemEquals_0_based_index}  `)
        }

        const normalizedGlobalIndex = this.Reverse_from_lastItem0_to_firstItem0(lastItemEquals_0_based_index)
        // Set the value at the specified index.
        this.buff.SetValue(normalizedGlobalIndex, value)
    }

    public Clear(): void {
        this.buff.ClearDataInChunks()
    }
}
