import React, { FC, useCallback, useMemo, useState } from 'react'
import { t } from 'i18next'

import { Icon } from '@fto/icons'
import { Typography, TriggerOverlay, Flex } from '@fto/ui'

import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TimeZoneDesc } from '@fto/lib/Timezones&DST/TimeZoneManager'
import List from './components/List'

import styles from './index.module.scss'

type Props = {
    setTimezone: (value: number) => void
    value: number
}

const TimezoneRange: FC<Props> = ({ setTimezone, value = 0 }) => {
    const [searchValue, setSearchValue] = useState('')

    const handleChange = useCallback(
        (tzValue: TimeZoneDesc) => {
            setTimezone(tzValue.serverValue)
        },
        [setTimezone]
    )

    const options = useMemo(() => GlobalTimezoneDSTController.Instance.getTimezones(), [])

    const activeTimeZone = useMemo(
        () =>
            options.find((item) => {
                return item.serverValue === value
            }),
        [value, options]
    )

    const optionsRenderer = useCallback(
        (onClose: () => void) => (
            <List
                onClose={onClose}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                value={value}
                handleChange={handleChange}
            />
        ),
        [searchValue, value, handleChange]
    )

    return (
        <Flex direction='column' gap={4}>
            <Typography type='interface-medium'>{t('dashboard.modals.projects.advanced.timeZone.title')}</Typography>
            <TriggerOverlay optionsRenderer={optionsRenderer}>
                <Flex alignItems='center' justifyContent='center' gap={8} className={styles.Header}>
                    <Typography color='gray-1000'>{activeTimeZone?.label}</Typography>
                    <Icon name='chevron-down' size={15} color={'var(--color-gray-1000)'} />
                </Flex>
            </TriggerOverlay>
        </Flex>
    )
}

export default TimezoneRange
