import { TTradePosition } from '@fto/lib/ft_types/common/BasicClasses/TradePosition'
import { BasicProcRecImplementation } from '../../common/CommonProcRecImplementations/BasicProcRecImplementation'
import { API_OrdersError } from '../../common/errors/API_OrdersError'
import { IOrdersProcRec } from '../StrategyProcRecInterfaces/IOrdersProcRec'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TTradePositionType } from '../../common/CommonExternalInterface'
import { EOrderSelectMode, TSearchMode, TTradePosInfo } from '../api/StrategyInterfaceUnit'

export class OrdersProcRecImplementation extends BasicProcRecImplementation {
    public GetImplementation(): IOrdersProcRec {
        //FIXME: wrap each function in errorHandlingWrapper
        return {
            OrderProfit: this.errorHandlingWrapper((): number => {
                return this.OrderProfit()
            }, 0),

            OrderProfitPips: (): number => {
                return this.OrderProfitPips()
            },
            OrderClosed: (orderId: number): boolean => {
                return this.OrderClosed(orderId)
            },
            HistoryTotal: (): number => {
                return this.HistoryTotal()
            },
            OrderOpenTime: (): TDateTime => {
                return this.OrderOpenTime()
            },
            OrderCloseTime: (): TDateTime => {
                return this.OrderCloseTime()
            },
            OrderLots: (): number => {
                return this.OrderLots()
            },
            OrderStopLoss: (): number => {
                return this.OrderStopLoss()
            },
            OrderTakeProfit: (): number => {
                return this.OrderTakeProfit()
            },
            OrderOpenPrice: (): number => {
                return this.OrderOpenPrice()
            },
            OrderClosePrice: (): number => {
                return this.OrderClosePrice()
            },
            OrderSymbol: (): string => {
                return this.OrderSymbol()
            },
            OrderComment: (): string => {
                return this.OrderComment()
            },
            OrderSwap: (): number => {
                return this.OrderSwap()
            },
            GetOrderInfo: (ticket: number): TTradePosInfo => {
                return this.GetOrderInfo(ticket)
            },
            GetOrderInfoEx: (ticket: number): TTradePosInfo => {
                return this.GetOrderInfoEx(ticket)
            },
            SendPendingOrder: (
                symbol: string,
                operationType: number,
                lotSize: number,
                stopLoss: number,
                takeProfit: number,
                executionPrice: number
            ) => {
                return this.SendPendingOrder(symbol, operationType, lotSize, stopLoss, takeProfit, executionPrice)
            },
            ModifyOrder: (
                orderHandle: number,
                newPrice: number,
                stopLoss: number,
                takeProfit: number,
                lot?: number,
                comment?: string
            ): boolean => {
                return this.ModifyOrder(orderHandle, newPrice, stopLoss, takeProfit, lot, comment)
            },
            DeleteOrder: (orderHandle: number): boolean => {
                return this.DeleteOrder(orderHandle)
            },
            CloseOrderPartial: (orderHandle: number, lotSize: number): boolean => {
                return this.CloseOrderPartial(orderHandle, lotSize)
            },
            OrdersTotal: (): number => {
                return this.OrdersTotal()
            },
            OrderSelect: (
                index: number,
                selectionMode: EOrderSelectMode,
                searchMode = TSearchMode.sm_MODE_TRADES
            ): boolean => {
                return this.OrderSelect(index, selectionMode, searchMode)
            },
            OrderMagicNumber: (): number => {
                return this.OrderMagicNumber()
            },
            OrderType: (): number => {
                return this.OrderType()
            },
            OrderTicket: (): number => {
                return this.OrderTicket()
            },
            CloseOrder: (ticket: number): boolean => {
                return this.CloseOrder(ticket)
            },
            SendInstantOrder: (
                Symbol: string,
                OperationType: number,
                LotSize: number,
                StopLoss: number,
                TakeProfit: number,
                Comment: string,
                MagicNumber: number
            ) => {
                return this.SendInstantOrder(Symbol, OperationType, LotSize, StopLoss, TakeProfit, Comment, MagicNumber)
            }
        }
    }

    protected override generateDName(): string {
        return `API_Orders_${super.generateDName()}`
    }

    private _selectedOrderInfo: TTradePosition | null = null // selected order (OrderSelect)
    private get isOrderSelected(): boolean {
        return !!this._selectedOrderInfo
    }

    private get selectedOrderInfo(): TTradePosition {
        if (!this.isOrderSelected) {
            throw new API_OrdersError('Order not selected')
        }

        return this._selectedOrderInfo as TTradePosition
    }

    public OrderSelect(index: number, selectionMode: EOrderSelectMode, searchMode: TSearchMode): boolean {
        this._selectedOrderInfo = null

        if (selectionMode === EOrderSelectMode.osm_SELECT_BY_TICKET) {
            this._selectedOrderInfo = GlobalProcessingCore.ProcessingCore.GetOpenPositionInfo(index)

            if (!this._selectedOrderInfo) {
                this._selectedOrderInfo = GlobalProcessingCore.ProcessingCore.GetHistoryOrderInfo(index)
            }
        } else {
            if (searchMode === TSearchMode.sm_MODE_TRADES) {
                this._selectedOrderInfo = GlobalProcessingCore.ProcessingCore.GetOpenPosInfo(index)
            } else {
                this._selectedOrderInfo = GlobalProcessingCore.ProcessingCore.GetHistoryPosInfo(index)
            }
        }

        return this._selectedOrderInfo !== null
    }

    public OrderProfit(): number {
        return this.selectedOrderInfo.profit
    }

    public OrderProfitPips(): number {
        return this.selectedOrderInfo.ProfitPips
    }

    public OrderClosed(orderId: number): boolean {
        return !GlobalProcessingCore.ProcessingCore.GetOpenPositionInfo(orderId)
    }

    public HistoryTotal(): number {
        return GlobalProcessingCore.ProcessingCore.History.Count
    }

    public OrdersTotal(): number {
        return GlobalProcessingCore.ProcessingCore.OpenPositions.Count
    }

    public OrderOpenTime(): TDateTime {
        return this.selectedOrderInfo.OpenTime
    }

    public OrderCloseTime(): TDateTime {
        return this.selectedOrderInfo.CloseTime
    }

    public OrderLots(): number {
        return this.selectedOrderInfo.lot
    }

    public OrderTicket(): number {
        return this.selectedOrderInfo.ticket
    }
    //
    public OrderType(): TTradePositionType {
        return this.selectedOrderInfo.PosType
    }

    public OrderStopLoss(): number {
        return this.selectedOrderInfo.StopLoss
    }

    public OrderTakeProfit(): number {
        return this.selectedOrderInfo.TakeProfit
    }

    public OrderOpenPrice(): number {
        return this.selectedOrderInfo.OpenPrice
    }

    public OrderClosePrice(): number {
        return this.selectedOrderInfo.ClosePrice
    }

    public OrderSymbol(): string {
        return this.selectedOrderInfo.SymbolName
    }

    public OrderMagicNumber(): number {
        return this.selectedOrderInfo.MagicNumber
    }

    public OrderComment(): string {
        return this.selectedOrderInfo.Comments
    }

    public OrderSwap(): number {
        return this.selectedOrderInfo.swap
    }

    // same as GetOrderInfoEx
    public GetOrderInfo(orderHandle: number): TTradePosInfo {
        return this.GetOrderInfoEx(orderHandle)
    }

    public GetOrderInfoEx(orderHandle: number): TTradePosInfo {
        const orderInfo = GlobalProcessingCore.ProcessingCore.GetOpenPositionInfo(orderHandle)

        if (!orderInfo) {
            throw new StrangeError('GetOrderInfo: Can not find order info')
        }
        return {
            ticket: orderInfo.ticket,
            openTime: orderInfo.OpenTime,
            closeTime: orderInfo.CloseTime,
            posType: orderInfo.PosType,
            lot: orderInfo.lot,
            symbol: orderInfo.SymbolName,
            openPrice: orderInfo.OpenPrice,
            closePrice: orderInfo.ClosePrice,
            stopLoss: orderInfo.StopLoss,
            takeProfit: orderInfo.TakeProfit,
            commission: orderInfo.commission,
            swap: orderInfo.swap,
            profit: orderInfo.profit,
            profitPips: orderInfo.ProfitPips,
            comments: orderInfo.Comments,
            margin: orderInfo.margin,
            hedgedMargin: orderInfo.HedgedMargin,
            magicNumber: orderInfo.MagicNumber
        }
    }

    public SendInstantOrder(
        Symbol: string,
        OperationType: number,
        LotSize: number,
        StopLoss: number,
        TakeProfit: number,
        Comment: string,
        MagicNumber: number
    ): number | null {
        const result = GlobalProcessingCore.ProcessingCore.SendMarketOrder({
            SymbolName: Symbol,
            OperationType: OperationType,
            lot: LotSize,
            StopLoss: StopLoss,
            TakeProfit: TakeProfit,
            price: 0,
            comment: Comment,
            MagicNumber: MagicNumber
        })
        // eslint-disable-next-line sonarjs/prefer-immediate-return
        const price = result[1]
        return price
    }

    public SendPendingOrder(
        symbolName: string,
        operationType: number,
        lotSize: number,
        stopLoss: number,
        takeProfit: number,
        executionPrice: number,
        comment?: string,
        magicNumber?: number
    ): number | null {
        return GlobalProcessingCore.ProcessingCore.SendPendingOrder(
            symbolName,
            operationType,
            lotSize,
            stopLoss,
            takeProfit,
            executionPrice,
            comment,
            magicNumber
        )
    }

    public ModifyOrder(
        orderHandle: number,
        newPrice: number,
        stopLoss: number,
        takeProfit: number,
        lot?: number,
        comment?: string
    ): boolean {
        const orderInfo = GlobalProcessingCore.ProcessingCore.GetOpenPositionInfo(orderHandle)
        if (!orderInfo) {
            //TODO: should we throw an error here?
            return false
        }
        lot = lot ?? orderInfo.lot
        comment = comment ?? orderInfo.Comments

        GlobalProcessingCore.ProcessingCore.ModifyOrder(orderHandle, lot, newPrice, stopLoss, takeProfit, comment)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()

        return true
        //false will be returned by error wrapper if an exception is thrown
    }

    public DeleteOrder(orderHandle: number): boolean {
        GlobalProcessingCore.ProcessingCore.DeleteOrder(orderHandle)
        return true
        //false will be returned by error wrapper if an exception is thrown
    }

    public CloseOrder(OrderHandle: number, lotSize?: number): boolean {
        GlobalProcessingCore.ProcessingCore.CloseOrder(OrderHandle, lotSize)
        return true
        //false will be returned by error wrapper if an exception is thrown
    }

    public CloseOrderPartial(orderHandle: number, lotSize: number): boolean {
        GlobalProcessingCore.ProcessingCore.CloseOrder(orderHandle, lotSize)
        return true
        //false will be returned by error wrapper if an exception is thrown
    }
}
