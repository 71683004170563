import { IconButton } from '@fto/icons'
import { Flex, InputField, Modal, Typography } from '@fto/ui'
import MessageInformer from '@root/components/MessageInformer'
import usePasswordField from '@root/hooks/usePasswordField'
import { changePassword } from '@root/utils/api'
import { FC, Fragment, useState } from 'react'
import styles from './modals.module.scss'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getCode } from '@root/store/auth/selectors'
import { LOGOUT } from '@root/store/auth/auth.slice'
import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { useNavigate } from 'react-router-dom'
import { showSuccessToast } from '@root/utils/toasts'
import { useTranslation } from 'react-i18next'

type EnterPasswordContentProps = {
    onClose: () => void
    code: string
}

export const EnterPasswordContent: FC<EnterPasswordContentProps> = ({ onClose, code }) => {
    const { t } = useTranslation()

    const [error, setError] = useState<string | null>(null)
    const confirmationCode = useAppSelector($getCode)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const changePasswordHandler = () => {
        changePassword({ password: password, code: code || (confirmationCode as string) })
            .then(() => {
                onClose()
                dispatch(LOGOUT())
                navigate(makeRoute(LOGIN_ROUTE))
                showSuccessToast({
                    title: t('profile.toasts.changePassword.passwordChangedSuccessfully.title'),
                    message: t('profile.toasts.changePassword.passwordChangedSuccessfully.message')
                })
            })
            .catch((error) => {
                setError(errorHandlingForPassword(error, t))
            })
    }
    const {
        password,
        passwordType,
        isSubmitDisabled,
        isInputTypePassword,
        handlePasswordChange,
        handlePasswordInputTypeChange,
        validationData
    } = usePasswordField({ initialValue: '' })

    const {
        password: confirmPassword,
        passwordType: confirmPasswordType,
        isSubmitDisabled: isConfirmPasswordDisabled,
        isInputTypePassword: isConfirmInputTypePassword,
        handlePasswordChange: confirmPasswordHandler,
        handlePasswordInputTypeChange: confirmPasswordTypeChange,
        validationData: confirmationPasswordValidationData
    } = usePasswordField({ initialValue: '' })

    const passwordsDoNotMatch = confirmPassword !== password

    return (
        <Fragment>
            <Modal.Content className={styles.line}>
                <Flex direction='column' gap={24}>
                    <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                        {t('profile.modals.enterNewPasswordText')}
                    </Typography>
                    <Flex direction='column' gap={16}>
                        <InputField
                            id='password'
                            error={!validationData.isValid}
                            helperText={validationData.errorMessage}
                            type={passwordType}
                            value={password}
                            block
                            onChange={handlePasswordChange}
                            label={t('profile.modals.newPassword')}
                            placeholder={t('auth.password')}
                            suffix={
                                <IconButton
                                    name={isInputTypePassword ? 'eye-off' : 'eye'}
                                    size={16}
                                    onClick={handlePasswordInputTypeChange}
                                />
                            }
                        />
                        <InputField
                            error={!confirmationPasswordValidationData.isValid}
                            helperText={confirmationPasswordValidationData.errorMessage}
                            type={confirmPasswordType}
                            value={confirmPassword}
                            block
                            onChange={confirmPasswordHandler}
                            label={t('profile.modals.confirmNewPassword')}
                            placeholder={t('auth.password')}
                            suffix={
                                <IconButton
                                    name={isConfirmInputTypePassword ? 'eye-off' : 'eye'}
                                    size={16}
                                    onClick={confirmPasswordTypeChange}
                                />
                            }
                        />
                        <MessageInformer
                            type='error'
                            isShown={!!(passwordsDoNotMatch && password && confirmPassword) || error !== null}
                        >
                            <Typography block type='interface-regular' align='center' color='red-500'>
                                {error ? error : t('auth.errors.password.passwordAndConfirmationMustBeEqual')}
                            </Typography>
                        </MessageInformer>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                submitText={t('profile.changePassword')}
                cancelText={t('global.cancel')}
                onClose={onClose}
                onSubmit={changePasswordHandler}
                disabled={isSubmitDisabled || isConfirmPasswordDisabled || passwordsDoNotMatch}
            />
        </Fragment>
    )
}

const errorHandlingForPassword = (response: { status: string; data: { status: string } }, t: (v: string) => string) => {
    if (response.status === 'code_expired') {
        return t('auth.errors.code.expired')
    }
    if (response.status === 'invalid_code') {
        return t('auth.errors.code.shouldBeValid')
    }

    return t('auth.errors.somethingWentWrong')
}
