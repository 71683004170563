type RefType = React.RefObject<any> | ((val: any) => void) | null

export const assignRef = (ref: RefType, value: any) => {
    if (typeof ref === 'function') {
        ref(value)
    } else if (ref) {
        //@ts-ignore
        ref.current = value
    }
}

export const mergeRefs = (...refs: RefType[]) => {
    return (value: any) => {
        refs.forEach((ref) => assignRef(ref, value))
    }
}
