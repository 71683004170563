import React, { FC, ReactNode } from 'react'
import { Flex } from '@fto/ui'
import classNames from 'classnames'
import style from './table-cells.module.scss'

type Props = {
    children: ReactNode
    width?: string
    className?: string
}

const TableBodyItem: FC<Props> = ({ children, width, className, ...rest }) => {
    return (
        <td {...rest} className={classNames(className, style.tableBodyItem)} width={width}>
            <Flex justifyContent={'flex-start'}>{children}</Flex>
        </td>
    )
}
export default TableBodyItem
