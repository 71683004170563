export enum EComparisonEvent {
    Finished = 'FinishedSuccessfully',
    ErrorDetected = 'ErrorDetected',
    StatusUpdated = 'StatusUpdated'
}

export enum EComparisonStatus {
    NotStarted,
    InProgress,
    Successful,
    Failed
}
