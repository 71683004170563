import { makeAutoObservable } from 'mobx'

import { News } from '@fto/lib/News/News'

import { ParamsType } from './types'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import { ALL_COUNTRIES } from '@root/constants/countries'

class NewsStore {
    isNewsTabOpen: ParamsType['isNewsTabOpen']
    news: ParamsType['news']
    filters: ParamsType['filters']
    lastBarTime: ParamsType['lastBarTime']

    constructor() {
        makeAutoObservable(this)

        this.isNewsTabOpen = false
        this.news = {
            previous: [],
            upcoming: []
        }
        this.filters = {
            priority: ['high'],
            selectedCountries: ALL_COUNTRIES.map((country) => country.name),
            interval: 'month'
        }
        this.lastBarTime = null

        this.updateNews = this.updateNews.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.jumpToNew = this.jumpToNew.bind(this)
        this.updateFilters = this.updateFilters.bind(this)
        this.getFiltersToShow = this.getFiltersToShow.bind(this)
        this.getSelectedCountries = this.getSelectedCountries.bind(this)
        this.handleNewsTabOpen = this.handleNewsTabOpen.bind(this)
    }

    handleNewsTabOpen(isOpen: boolean) {
        this.isNewsTabOpen = isOpen
    }

    updateNews({ news, lastBarTime }: { news: ParamsType['news']; lastBarTime: ParamsType['lastBarTime'] }) {
        this.news = news
        this.lastBarTime = lastBarTime
    }

    resetStore() {
        this.isNewsTabOpen = false
        this.news = {
            previous: [],
            upcoming: []
        }
        this.filters = {
            priority: ['high'],
            selectedCountries: ALL_COUNTRIES.map((country) => country.name),
            interval: 'month'
        }
        this.lastBarTime = null
    }

    jumpToNew(entity: News): void {
        GlobalNewsController.Instance.jumpToNewsItem(entity)
    }

    updateFilters(forced = false) {
        GlobalNewsController.Instance.setFilterByImportance(this.filters.priority)
        GlobalNewsController.Instance.setFilterByCountries(this.filters.selectedCountries)
        GlobalNewsController.Instance.setFilterByInterval(this.filters.interval)

        GlobalNewsController.Instance.throttledFilterNews(forced)
    }

    getFiltersToShow() {
        return {
            priority: this.filters.priority,
            interval: this.filters.interval
        }
    }

    setFilters(updatedFilters: ParamsType['filters'], forced = false) {
        this.filters = updatedFilters

        this.updateFilters(forced)
    }

    getSelectedCountries() {
        return this.filters.selectedCountries
    }

    get allFilters() {
        return this.filters
    }
}

export default new NewsStore()
