import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

const firstDateForAllSymbols = '1999-12-01T00:00:00+00:00'
const lastDateForAllSymbols = '2024-01-01T00:00:00+00:00'

export default class MockerForServerSymbols {
    public static LastDateForAllSymbols = DateUtils.FromString(lastDateForAllSymbols)
    public static FirstDateForAllSymbols = DateUtils.FromString(firstDateForAllSymbols)

    public static readonly symbols = [
        {
            Broker: 'Advanced',
            Symbol: 'EURUSD',
            Category: 'Majors',
            Subscription: 1,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'USD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'GBPJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'GBP',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'AUDUSD',
            Category: 'Majors',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'AUD',
            QuoteCurrency: 'USD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'NZDJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'NZD',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'USDCHF',
            Category: 'Majors',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'USD',
            QuoteCurrency: 'CHF'
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURCAD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'CAD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'CHFJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'CHF',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURCHF',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'CHF'
        },
        {
            Broker: 'Advanced',
            Symbol: 'XAGUSD',
            Category: 'Metals',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'XAGUSD',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURAUD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'AUD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'DJI',
            Category: 'Indexes',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'DJI',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURHKD',
            Category: 'Exotic',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'HKD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'GBPAUD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'GBP',
            QuoteCurrency: 'AUD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'CADJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'CAD',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'AUDCHF',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'AUD',
            QuoteCurrency: 'CHF'
        },
        {
            Broker: 'Advanced',
            Symbol: 'GBPCAD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'GBP',
            QuoteCurrency: 'CAD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'AUDCAD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'AUD',
            QuoteCurrency: 'CAD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'AUDNZD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'AUD',
            QuoteCurrency: 'NZD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'NG',
            Category: 'Futures',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'NG',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'USDZAR',
            Category: 'Exotic',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'USD',
            QuoteCurrency: 'ZAR'
        },
        {
            Broker: 'Advanced',
            Symbol: 'GBPCHF',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'GBP',
            QuoteCurrency: 'CHF'
        },
        {
            Broker: 'Advanced',
            Symbol: 'GBPUSD',
            Category: 'Majors',
            Subscription: 1,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'GBP',
            QuoteCurrency: 'USD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURNZD',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'NZD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'NZDUSD',
            Category: 'Majors',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'NZD',
            QuoteCurrency: 'USD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'XAUUSD',
            Category: 'Metals',
            Subscription: 1,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'XAUUSD',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'EURGBP',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'EUR',
            QuoteCurrency: 'GBP'
        },
        {
            Broker: 'Advanced',
            Symbol: 'USDCAD',
            Category: 'Majors',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'USD',
            QuoteCurrency: 'CAD'
        },
        {
            Broker: 'Advanced',
            Symbol: 'USDJPY',
            Category: 'Majors',
            Subscription: 1,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'USD',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'BTCUSD',
            Category: 'Crypto',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'BTCUSD',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'AUDJPY',
            Category: 'Crosses',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'AUD',
            QuoteCurrency: 'JPY'
        },
        {
            Broker: 'Advanced',
            Symbol: 'NAS100',
            Category: 'Indexes',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'NAS100',
            QuoteCurrency: null
        },
        {
            Broker: 'Advanced',
            Symbol: 'SPX500',
            Category: 'Indexes',
            Subscription: 2,
            IsAvailable: false,
            Start: firstDateForAllSymbols,
            End: lastDateForAllSymbols,
            BaseCurrency: 'SPX500',
            QuoteCurrency: null
        }
    ]
}
