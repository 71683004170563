import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { fireMixpanelEvent, requestCode, signUp } from '@root/utils/api'
import { Button, Flex, InputField, Link, Typography } from '@fto/ui'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import { saveAuthToken } from '@root/utils/auth'
import MessageInformer from '@root/components/MessageInformer'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'
import { getLocalStorage } from '@root/utils/localStorage'
import { LANDING_PAGE_USER_PARAMS } from '@root/constants/localStorageKeys'
import { DELIMITER } from '@root/pages/Auth/constants/delimiter'
import useDebouncedEnterSubmit from '@root/hooks/useDebouncedEnterSubmit'

type RegisterDataType = {
    password: string
    code: string
    email: string
    fullName: string
}

const EmailConfirmation = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [fullName, setFullName] = useState('')
    const [error, setError] = useState(null)
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isResendNotificationActive, setResendNotificationActive] = useState(false)
    const { t } = useTranslation()

    const register = useCallback(
        (e?: React.FormEvent | null, data?: RegisterDataType) => {
            if (e) {
                e.preventDefault()
            }

            const landingPageUserParams = getLocalStorage(LANDING_PAGE_USER_PARAMS)

            let registerData = {
                password,
                code,
                email,
                fullName,
                ...(landingPageUserParams ? { ...landingPageUserParams } : {})
            }
            if (data) {
                registerData = data
            }
            setIsLoading(true)
            signUp(registerData)
                .then((result) => {
                    saveAuthToken(result.data)
                    fireMixpanelEvent('sign_up', { login_method: 'Email' })
                    fireGoogleTagManagerEvent('sign_up', { login_method: 'Email' })

                    if (landingPageUserParams) {
                        localStorage.removeItem(LANDING_PAGE_USER_PARAMS)
                    }

                    navigate(0)
                })
                .catch((err) => {
                    if (err instanceof AxiosError) {
                        setError(err.response?.data?.status)
                    }

                    if (err.status) {
                        setError(err.status)
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        [password, code, email]
    )

    useDebouncedEnterSubmit(register)

    const parseHash = useCallback((hash: string) => {
        const data = decodeURIComponent(window.atob(hash)).split(DELIMITER)

        const [fullName, email, password] = data

        return {
            email,
            fullName,
            password
        }
    }, [])

    const parseUrl = useCallback(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const hash = queryParameters.get('hash')
        const code = queryParameters.get('code')
        if (hash) {
            const { email, fullName, password } = parseHash(hash)
            setEmail(email)
            setFullName(fullName)
            setPassword(password)
            if (code) {
                setCode(code)
                const data = {
                    password,
                    code,
                    email,
                    fullName
                }
                register(null, data)
            }
            return
        }
        navigate(makeRoute(LOGIN_ROUTE))
    }, [])

    const resendEmail = useCallback(() => {
        setResendNotificationActive(true)

        requestCode({
            email,
            fullName,
            password,
            type: 'register'
        }).catch((err) => {
            if (err instanceof AxiosError) {
                setError(err.response?.data?.status)
            }

            if (err.status) {
                setError(err.status)
            }
        })
    }, [email])

    const submitDisabled = useMemo(() => {
        return code.length === 0
    }, [code])

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'email_verification' })

        parseUrl()
    }, [])

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h4-semibold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.resetTitle')}
                        </Typography>
                        <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            <Trans
                                i18nKey='auth.resetText'
                                components={{
                                    emailLabel: <Typography type={'text-bold'}>{email}</Typography>
                                }}
                            />
                        </Typography>
                    </Flex>
                    <form>
                        <Flex direction={'column'} gap={16}>
                            <InputField
                                type='text'
                                value={code}
                                block
                                onChange={setCode}
                                label={t('auth.code')}
                                placeholder={t('auth.codeEmail')}
                            />
                            <MessageInformer type='error' isShown={!!error} onClose={() => setError(null)} disappearing>
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    {/*@ts-ignore*/}
                                    {t(statusMessages[error])}
                                </Typography>
                            </MessageInformer>
                            <Button
                                loading={isLoading}
                                type='marketing'
                                disabled={submitDisabled}
                                block
                                onClick={register}
                                label={t('global.continue')}
                            />
                        </Flex>
                    </form>
                    <Typography type={'text-medium'} align={'center'}>
                        {t('auth.resetHelperText')}
                    </Typography>
                    <Flex justifyContent={'center'} alignItems='center' gap={8}>
                        <Typography type={'interface-regular'}>{t('auth.didNotReceive')}</Typography>
                        <Link onClick={resendEmail}>
                            <Typography type='interface-regular' color={'primary-500'}>
                                {t('auth.resendEmail')}
                            </Typography>
                        </Link>
                    </Flex>
                    <MessageInformer
                        type='success'
                        isShown={isResendNotificationActive}
                        onClose={setResendNotificationActive}
                        disappearing
                    >
                        <Typography type='interface-medium' color='green-600' align='center' block>
                            {t('auth.notifications.resendCode')}
                        </Typography>
                    </MessageInformer>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default EmailConfirmation
