import TUserJournal from '../common/UserJournal'

export default class GlobalUserJournal {
    private static fInstance: TUserJournal

    private static Init(): void {
        this.fInstance = new TUserJournal()
    }

    public static get Instance(): TUserJournal {
        if (!this.fInstance) {
            this.Init()
        }
        return this.fInstance
    }
}
