import { IMockBarChunk } from '@fto/lib/mocks/MocksForTesting/MockDataInterfaces'
import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import {
    ServerDayChunkDataMSGPACK,
    ServerDayChunkData_Bars,
    TServerBarRecordJSON,
    TServerBarRecordMSGPACK
} from '../data_downloading/ServerDataClasses'
import { TBarRecord } from '../DataClasses/TBarRecord'

export default class Bars_converter {
    static ConvertFromJSONToBars(dataString: string): TBarRecord[] {
        const serverDays: ServerDayChunkData_Bars[] = JSON.parse(dataString)
        let result: TBarRecord[] = []

        // Iterate over each day and process its data
        for (const serverDay of serverDays) {
            const dayRecords = Bars_converter.ConvertServerBarsToBarRecordsJSON(serverDay)
            result = result.concat(dayRecords)
        }

        return result
    }

    static ConvertServerBarsToBarRecordsJSON(serverBars: ServerDayChunkData_Bars): TBarRecord[] {
        const result: TBarRecord[] = []
        for (const serverBar of serverBars.Bars) {
            result.push(Bars_converter.ConvertServerBarJSON_2_TBarRecord(serverBar, serverBars.Day)) //TODO: optimize this?
        }
        return result
    }

    static ConvertServerBarJSON_2_TBarRecord(serverBar: TServerBarRecordJSON, dayStart: number): TBarRecord {
        const barDateTime: TDateTime = DateUtils.fromUnixTimeSeconds(serverBar.Time + dayStart)
        return new TBarRecord(
            barDateTime,
            serverBar.Open,
            serverBar.High,
            serverBar.Low,
            serverBar.Close,
            serverBar.TickVolume
        )
    }

    private static ConvertServerBarMSGPACK_2_TBarRecord(
        serverBar: TServerBarRecordMSGPACK,
        dayStart: number
    ): TBarRecord {
        const barDateTime: TDateTime = DateUtils.fromUnixTimeSeconds(serverBar.t + dayStart)
        return new TBarRecord(
            barDateTime,
            Number(serverBar.o),
            Number(serverBar.h),
            Number(serverBar.l),
            Number(serverBar.c),
            serverBar.v
        )
    }

    static ConvertServerBarsToBarRecordsMSGPACK(serverDays: ServerDayChunkDataMSGPACK[]): TBarRecord[] {
        let result: TBarRecord[] = []

        // Iterate over each day and process its data
        for (const serverDay of serverDays) {
            const dayRecords = Bars_converter.ConvertServerDayToBarRecordsMSGPACK(serverDay)
            result = result.concat(dayRecords)
        }

        return result
    }

    private static ConvertServerDayToBarRecordsMSGPACK(serverDay: ServerDayChunkDataMSGPACK): TBarRecord[] {
        const result: TBarRecord[] = []
        for (const serverBar of serverDay.b) {
            result.push(Bars_converter.ConvertServerBarMSGPACK_2_TBarRecord(serverBar, serverDay.s))
        }
        return result
    }

    static ConvertServerBinaryBarsToBarRecords(buffer: ArrayBuffer): TBarRecord[] {
        let result: TBarRecord[] = []
        const serverDays = this.parseDailyBars(buffer)
        for (const serverDay of serverDays) {
            for (const serverBar of serverDay.bars) {
                const barDateTime: TDateTime = DateUtils.fromUnixTimeSeconds(serverBar.time + serverDay.chunkStart)
                let barRecord = new TBarRecord(
                    barDateTime,
                    serverBar.open,
                    serverBar.high,
                    serverBar.low,
                    serverBar.close,
                    serverBar.tickVolume
                )

                result.push(barRecord)
            }
        }

        return result
    }

    private static parseDailyBars(buffer: ArrayBuffer): any[] {
        const dataView = new DataView(buffer)
        let offset = 0

        const count = dataView.getInt32(offset, true)
        offset += 4

        const chunks = []

        for (let i = 0; i < count; i++) {
            const chunkStart = dataView.getInt32(offset, true)
            offset += 8

            const barsCount = dataView.getInt32(offset, true)
            offset += 4

            const bars = []
            for (let j = 0; j < barsCount; j++) {
                const time = dataView.getInt32(offset, true)
                offset += 4

                let open, high, low, close

                open = dataView.getFloat32(offset, true)
                offset += 4
                high = dataView.getFloat32(offset, true)
                offset += 4
                low = dataView.getFloat32(offset, true)
                offset += 4
                close = dataView.getFloat32(offset, true)
                offset += 4

                const tickVolume = Number(dataView.getBigInt64(offset, true))
                offset += 8

                bars.push({ time, open, high, low, close, tickVolume })
            }

            chunks.push({ chunkStart, bars })
        }
        return chunks
    }

    static ConvertFromMocksToBars(data: IMockBarChunk): TBarRecord[] {
        const result: TBarRecord[] = []
        for (const mockBar of data.bars) {
            result.push(
                new TBarRecord(
                    mockBar.DateTime,
                    mockBar.Open,
                    mockBar.High,
                    mockBar.Low,
                    mockBar.Close,
                    mockBar.TickVolume
                )
            )
        }
        return result
    }
}
