import { Icon } from '@fto/icons'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { TTutorialTask } from '@fto/lib/Education/Types'
import { Button, Flex, Modal, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    tasks: TTutorialTask[]
}

export const FailModal: FC<Props> = ({ tasks }) => {
    const { t } = useTranslation()

    const restart = useCallback(() => {
        EducationProcessor.Instance.restartHomework()
        removeModal(MODAL_NAMES.chart.education.fail)
    }, [])

    return (
        <Modal size='xs' name={MODAL_NAMES.chart.education.fail} withClickOutside={false}>
            <Modal.Content>
                <Flex direction='column' gap={8}>
                    <Flex direction='column' alignItems='center' gap={16}>
                        <Icon size={56} name='close-colored' />
                        <Flex direction='column' alignItems='center' justifyContent='center' gap={8}>
                            <Typography type='h5-semibold'>{t('educationModals.fail.header')}</Typography>
                            <Flex direction='column' alignItems='center'>
                                <Typography type='interface-regular'>{t('educationModals.fail.textOne')}</Typography>
                                <Typography type='interface-regular'>{t('educationModals.fail.textTwo')}</Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex direction='column' gap={8}>
                        {tasks.map((task) => {
                            return (
                                <Flex gap={8} alignItems='center' key={task.name}>
                                    <Icon size={16} name='close-rounded' />
                                    <Typography>{task.name}</Typography>
                                </Flex>
                            )
                        })}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls>
                <Button
                    type='primary'
                    label={
                        <Flex gap={4} alignItems='center'>
                            <Typography color='white'>{t('educationModals.fail.submitText')}</Typography>
                            <Icon name='restart' color={'var(--color-white)'}></Icon>
                        </Flex>
                    }
                    onClick={restart}
                    block
                />
            </Modal.Controls>
        </Modal>
    )
}
