import { TFormatSettings } from '../ft_types/common/FormatSettings'

export default class DelphiBuiltInFunctions {
    static StrDouble(value: number, decimals: number): string {
        return value.toFixed(decimals)
    }

    static StrInt(value: number): string {
        return value.toString()
    }

    static InRange<T extends number | Date>(value: T, min: T, max: T): boolean {
        return value >= min && value <= max
    }

    static Format(formatString: string, args: any[], settings?: TFormatSettings): string {
        let result = formatString

        // Replace placeholders like %s, %.4f
        return result.replace(/%(\.\d+)?[sdf]/g, (match) => {
            const index = args.findIndex((x) => x != null)
            let replacement = args[index]
            args[index] = null // Prevent reusing the same argument

            // Check if the match includes a decimal format, e.g., %.4f
            const decimalMatch = match.match(/\.(\d+)f/)
            if (decimalMatch) {
                const decimals = parseInt(decimalMatch[1], 10)
                if (typeof replacement === 'number') {
                    replacement = replacement.toFixed(decimals)
                }
            }

            return replacement
        })
    }
}
