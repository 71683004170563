export const DROPDOWN_OPTIONS = {
    general: [
        {
            value: 'nextDayOpen',
            isShown: true
        },
        {
            value: 'nextDayClose',
            isShown: true
        },
        {
            value: 'customDate',
            isShown: true
        },
        {
            value: 'historicalMoments',
            isShown: true
        }
    ],
    sessions: [
        {
            value: 'asianOpen',
            isShown: true,
            time: '22:00'
        },
        {
            value: 'asianClose',
            isShown: true,
            time: '08:00'
        },
        {
            value: 'NYOpen',
            isShown: true,
            time: '13:00'
        },
        {
            value: 'NYClose',
            isShown: true,
            time: '21:00'
        },
        {
            value: 'londonOpen',
            isShown: true,
            time: '08:00'
        },
        {
            value: 'londonClose',
            isShown: true,
            time: '16:00'
        },
        {
            value: 'silverBullet',
            isShown: true,
            time: '14:00'
        }
    ]
}
