import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPPen } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { ColorHelperFunctions } from '../ColorHelperFunctions'
import { TLineStyleJSON } from './TLineStyleJSON'

export class TLineStyle {
    color: TColor // Representing TColor as a string (e.g., "#FF0000")
    style: TPenStyle
    width: number
    private pen: IGPPen | null = null

    public static fromSerialized(json: TLineStyleJSON): TLineStyle {
        return new TLineStyle(json.color, json.style, json.width)
    }

    constructor(aColor: TColor, aStyle: TPenStyle, aWidth: number) {
        this.color = aColor
        this.style = aStyle
        this.width = aWidth
    }

    clone(): TLineStyle {
        return new TLineStyle(this.color, this.style, this.width)
    }

    SaveToStr(): string {
        // Convert the line style to a string representation
        // This is a placeholder implementation, adjust as needed
        return JSON.stringify({
            color: this.color,
            style: this.style,
            width: this.width
        })
    }

    LoadFromStr(s: string): void {
        // Load the line style from a string representation
        // This is a placeholder implementation, adjust as needed
        const obj = JSON.parse(s)
        this.color = obj.color
        this.style = obj.style as TPenStyle
        this.width = obj.width
    }

    getSerialized(): TLineStyleJSON {
        return {
            color: this.color,
            style: this.style,
            width: this.width
        }
    }

    getPen() {
        if (this.pen === null) {
            this.pen = new IGPPen(this.color, this.width)
        }

        this.pen.setColor(this.color)
        this.pen.setWidth(this.width)
        this.pen.setPenStyle(this.style)
        this.pen.opacity = ColorHelperFunctions.GetOpacity(this.color)

        return this.pen
    }
}
