export enum TPenStyle {
    psSolid = 0,
    psDash = 1,
    psDot = 2,
    psDashDot = 3,
    psDashDotDot = 4
}

export const lineTypeOptions = [
    { value: TPenStyle.psSolid, icon: 'solid-line', iconSize: 114, iconHeight: 2 },
    { value: TPenStyle.psDash, icon: 'dashed-line', iconSize: 114, iconHeight: 2 },
    { value: TPenStyle.psDot, icon: 'shortDashed-line', iconSize: 114, iconHeight: 2 },
    { value: TPenStyle.psDashDot, icon: 'dotDashed-line', iconSize: 114, iconHeight: 2 },
    { value: TPenStyle.psDashDotDot, icon: 'doubleDotDashed-line', iconSize: 114, iconHeight: 2 }
]

export const lineWidthOptions = [
    { value: 1, icon: 'solid-line', iconSize: 114, iconHeight: 1 },
    { value: 2, icon: 'solid-line', iconSize: 114, iconHeight: 2 },
    { value: 3, icon: 'solid-line', iconSize: 114, iconHeight: 3 },
    { value: 4, icon: 'solid-line', iconSize: 114, iconHeight: 4 },
    { value: 5, icon: 'solid-line', iconSize: 114, iconHeight: 5 }
]
