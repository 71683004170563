import { useEffect } from 'react'
import { fireMixpanelEvent } from '@root/utils/api'
import { setLocalStorage } from '@root/utils/localStorage'
import { CONVERSION_PARAMS_FROM_SITES, LANDING_PAGE_USER_PARAMS } from '@root/constants/localStorageKeys'

export type LandingPageParamsType = {
    localId?: string
    lang?: string
    landingSubscriptionSelected?: string
} | null

type Props = {
    subscriptionId: number
}

const useUrlParams = ({ subscriptionId }: Props) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        // GET url params from email link
        let paramList: { [key: string]: string } = {}

        params.forEach((value, key) => {
            paramList = { ...paramList, [key]: value }
        })

        const cameFromEmail = paramList['utm_source'] && paramList['utm_source'] === 'email'

        if (cameFromEmail) {
            fireMixpanelEvent('fto_email_conversion', { ...paramList })
        }

        // NOTE: HERE we save sendpulse conversion data passed from SITES

        const localId = params.get('local_id')
        const landingSubscriptionSelected = params.get('Landing_subscription_selected')
        const lang = params.get('language')

        const landingPageParams: LandingPageParamsType = {
            ...(localId && { localId }),
            ...(lang && { lang }),
            ...(landingSubscriptionSelected && { landingSubscriptionSelected })
        }

        // NOTE: here we get userId from landing page to connect user on landing to FTO user and make equal events flow. we use that for mixpanel for events of unlogged users
        if (localId) {
            setLocalStorage(LANDING_PAGE_USER_PARAMS, landingPageParams)
        }

        if (landingSubscriptionSelected) {
            setLocalStorage(CONVERSION_PARAMS_FROM_SITES, paramList)
        }
    }, [])

    useEffect(() => {
        if (subscriptionId) {
            localStorage.removeItem(CONVERSION_PARAMS_FROM_SITES)
        }
    }, [subscriptionId])
}

export default useUrlParams
