import { FC } from 'react'
import styles from './table-cells.module.scss'
import { Typography } from '@fto/ui'
import classNames from 'classnames'

const TableHeadItem: FC<{ label: string; className?: string }> = ({ label, className }) => {
    return (
        <th className={classNames(styles.tableHeadItem, className)}>
            <Typography color={'gray-700'} type={'subtext-medium'}>
                {label}
            </Typography>
        </th>
    )
}
export default TableHeadItem
