import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal, ModalTabs, removeModal, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TabType } from '@fto/ui/lib/components/ModalTabs/types'
import useLocalStorageKey from '@root/hooks/useLocalStorageKey'
import { JUMP_TO_OPTIONS } from '@root/constants/localStorageKeys'
import { usePersistedState } from '@root/hooks/usePersistedState'

import SettingsOption from './components/SettingsOption'
import { DROPDOWN_OPTIONS } from './constants'

import styles from './index.module.scss'

const TABS: TabType[] = [
    { value: 'general', localeKey: 'jumpToSettingsModal.general' },
    { value: 'sessions', localeKey: 'jumpToSettingsModal.sessions' }
]

type Props = {
    setDropdownVisibleOptions: Dispatch<SetStateAction<typeof DROPDOWN_OPTIONS>>
}

export const JumpToSettingsModal: FC<Props> = ({ setDropdownVisibleOptions }) => {
    const { t } = useTranslation()

    const [activeTab, setActiveTab] = useState(TABS[0].value)
    const key = useLocalStorageKey(JUMP_TO_OPTIONS)

    const [options] = usePersistedState(key, DROPDOWN_OPTIONS)

    const [localOptions, setLocalOptions] = useState(options)

    const onGeneralOptionChange = useCallback(
        (option: string) => {
            setLocalOptions((prev) => {
                return {
                    ...prev,
                    general: prev.general.map((item) =>
                        item.value === option ? { ...item, isShown: !item.isShown } : item
                    )
                }
            })
        },
        [setLocalOptions]
    )

    const onSessionsOptionChange = useCallback(
        (option: string) => {
            setLocalOptions((prev) => {
                return {
                    ...prev,
                    sessions: prev.sessions.map((item) =>
                        item.value === option ? { ...item, isShown: !item.isShown } : item
                    )
                }
            })
        },
        [setLocalOptions]
    )

    const handleApply = useCallback(() => {
        setDropdownVisibleOptions(localOptions)
        removeModal(MODAL_NAMES.chart.jumpToSettings)
    }, [localOptions, setDropdownVisibleOptions])

    return (
        <Modal size='md' name={MODAL_NAMES.chart.jumpToSettings} withCloseIcon>
            <Modal.Header withBorderBottom>{t('jumpToSettingsModal.header')}</Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex gap={16} direction='column'>
                    <ModalTabs tabsList={TABS} handleTabChange={setActiveTab} activeTab={activeTab} />
                    <Flex
                        direction='column'
                        justifyContent='flex-start'
                        gap={activeTab === 'sessions' ? 8 : 16}
                        className={styles.settings}
                    >
                        {activeTab === 'general' &&
                            localOptions.general.map((option) => (
                                <SettingsOption
                                    key={option.value}
                                    label={option.value}
                                    value={option.isShown}
                                    onOptionChange={onGeneralOptionChange}
                                />
                            ))}
                        {activeTab === 'sessions' && (
                            <>
                                <Flex justifyContent='space-between'>
                                    <Typography type='tiny-regular' color='gray-600'>
                                        {t('jumpToSettingsModal.sessions').toUpperCase()}
                                    </Typography>
                                    <Typography type='tiny-regular' color='gray-600' className={styles.time}>
                                        {t('jumpToSettingsModal.time')}
                                    </Typography>
                                </Flex>
                                {localOptions.sessions.map((option) => (
                                    <SettingsOption
                                        key={option.value}
                                        label={option.value}
                                        value={option.isShown}
                                        onOptionChange={onSessionsOptionChange}
                                        time={option.time}
                                    />
                                ))}
                            </>
                        )}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls onSubmit={handleApply} />
        </Modal>
    )
}
