import { Button, Flex } from '@fto/ui'
import { FC, memo } from 'react'
import { t } from 'i18next'

type Props = {
    onSavingTemplate: () => void
    onApplyDefault?: () => void
    closeHandler: () => void
}

export const Footer: FC<Props> = memo(({ onSavingTemplate, onApplyDefault, closeHandler }) => {
    return (
        <Flex gap={4} direction='column'>
            {onSavingTemplate && (
                <Button
                    label={t('templates.dropdown.saveControl')}
                    onClick={() => {
                        closeHandler()
                        onSavingTemplate()
                    }}
                    type='secondary'
                    size='small'
                    block
                ></Button>
            )}
            {onApplyDefault && (
                <Button
                    label={t('templates.dropdown.applyDefaultControl')}
                    onClick={() => {
                        closeHandler()
                        onApplyDefault()
                    }}
                    size='small'
                    type='secondary'
                    block
                ></Button>
            )}
        </Flex>
    )
})
