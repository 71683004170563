import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import IIndicatorsProcRec from './IIndicatorsProcRec'

export abstract class IndicatorImplementation {
    private _api: IIndicatorsProcRec | undefined

    public get api(): IIndicatorsProcRec {
        if (!this._api) {
            throw new StrangeError('api is not set')
        }
        return this._api
    }

    public OnAttach(api: IIndicatorsProcRec): void {
        this._api = api
    }

    public abstract Init(): void
    public abstract Calculate(index: number): void

    public OnParamsChange(): void {
        // Do nothing
    }

    public Done(): void {
        // Do nothing
    }

    public OnHide(): void {
        // Do nothing
    }

    public OnShow(): void {
        // Do nothing
    }
}
