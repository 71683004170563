import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { TDateTime, TMarketInfo } from '../CommonTypes'
import { ITimeseriesProcRec } from '../CommonProcRecInterfaces/ITimeseriesProcRec'
import { ChunkBuilder } from '@fto/lib/ft_types/data/BarBuilding/chunk_building/ChunkBuilder'
import { TChunkStatus, TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { APIHelperFunctions } from '../ApiHelperFunctions'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class TimeseriesProcRecImplementation extends BasicProcRecImplementation implements ITimeseriesProcRec {
    public GetImplementation(): ITimeseriesProcRec {
        return {
            iOpen: this.iOpen.bind(this),
            iClose: this.iClose.bind(this),
            iHigh: this.iHigh.bind(this),
            iLow: this.iLow.bind(this),
            iVolume: this.iVolume.bind(this),
            iTime: this.iTime.bind(this),
            iBars: this.iBars.bind(this),
            iBarShift: this.iBarShift.bind(this),
            iHighest: this.iHighest.bind(this),
            iLowest: this.iLowest.bind(this),
            MarketInfo: this.MarketInfo.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_Timeseries_${super.generateDName()}`
    }

    public MarketInfo(symbolName: string, type: TMarketInfo): number {
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)

        if (symbol === null) {
            return 0
        }

        switch (type) {
            case TMarketInfo.MODE_BID: {
                return symbol.bid
            }
            case TMarketInfo.MODE_ASK: {
                return symbol.ask
            }
            default: {
                return 0
            }
        }
    }

    //TODO:IN GetIndexByDateBin
    public iBarShift(symbol: string, timeframe: number, time: TDateTime, exact: boolean): number {
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeframe)
        if (!bars) return -1
        const barChunk = bars.GetChunkByDate(time)

        if (barChunk && barChunk.Status !== TChunkStatus.cs_Loaded) {
            ChunkBuilder.Instance.BuildChunk(barChunk)
            return -1
        }

        try {
            let result = bars.GetGlobalIndexByDate(time, TNoExactMatchBehavior.nemb_ReturnNearestLower, false)
            if (result !== -1) {
                if (bars.LastItemInTestingIndex < result) {
                    result = 0
                } else {
                    result = bars.LastItemInTestingIndex - bars.FitIndex(result)
                }
            }
            return result
        } catch {
            return -1
        }
    }

    public iBars(symbol: string, timeframe: number): number {
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeframe)
        return bars.totalBarsCount
    }

    // TODO:EX (impl not checked) function TVeryBasicDllClass.iHighest(Symbol: PAnsiChar; TimeFrame, _type, count,
    public iHighest(symbol: string, timeFrame: number, type: number, count: number, index: number): number {
        //TODO: can we optimize this by storing bars array in a private field?
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeFrame)
        APIHelperFunctions.ValidateIndexForTestingRange(bars, index, symbol, timeFrame)

        index = bars.FitIndex(index)
        index = bars.totalBarsCount - 1 - index
        let i = index - 1
        let bar = bars.GetItemByGlobalIndex(index)
        if (!bar) return -1
        let max = APIHelperFunctions.getValueByType(bar, type)
        let result = index
        count--
        while (i >= 0 && count > 0) {
            bar = bars.GetItemByGlobalIndex(i)
            if (!bar) return -1
            const value = APIHelperFunctions.getValueByType(bar, type)
            if (value >= max) {
                max = value
                result = i
            }
            i--
            count--
        }

        if (result !== -1) result = bars.totalBarsCount - 1 - result
        return result
    }

    // TODO:EX (impl not checked) function TVeryBasicDllClass.iLowest(Symbol: PAnsiChar; TimeFrame, _type, count,
    public iLowest(symbol: string, timeFrame: number, type: number, count: number, index: number): number {
        //TODO: can we optimize this by storing bars array in a private field?
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeFrame)
        APIHelperFunctions.ValidateIndexForTestingRange(bars, index, symbol, timeFrame)

        index = bars.FitIndex(index)
        index = bars.totalBarsCount - 1 - index
        let i = index - 1
        let bar = bars.GetItemByGlobalIndex(index)
        if (!bar) return -1
        let min = APIHelperFunctions.getValueByType(bar, type)

        let result = index
        count--
        while (i >= 0 && count > 0) {
            bar = bars.GetItemByGlobalIndex(i)
            if (!bar) return -1
            const value = APIHelperFunctions.getValueByType(bar, type)
            if (value <= min) {
                min = value
                result = i
            }
            i--
            count--
        }

        if (result === -1) result = bars.totalBarsCount - 1 - result
        return result
    }

    public iClose(Symbol: string, TimeFrame: number, index: number): number {
        // Assuming GetBar is a method that retrieves a bar record given a symbol, timeframe, and index
        // and that it is already implemented and available in the current context.
        const bar = APIHelperFunctions.GetBar(Symbol, TimeFrame, index)
        return bar.close
    }

    public iHigh(Symbol: string, TimeFrame: number, index: number): number {
        return APIHelperFunctions.GetBar(Symbol, TimeFrame, index).high
    }

    public iLow(Symbol: string, TimeFrame: number, index: number): number {
        return APIHelperFunctions.GetBar(Symbol, TimeFrame, index).low
    }

    public iOpen(Symbol: string, TimeFrame: number, index: number): number {
        // Assuming GetBar is a method that retrieves the bar data for the given symbol, timeframe, and index
        // and that it handles its own error checking, we can directly return the 'open' property of the bar.
        return APIHelperFunctions.GetBar(Symbol, TimeFrame, index).open
    }

    public iTime(Symbol: string, TimeFrame: number, index: number): TDateTime {
        return APIHelperFunctions.GetBar(Symbol, TimeFrame, index).DateTime
    }

    public iVolume(Symbol: string, TimeFrame: number, index: number): number {
        return APIHelperFunctions.GetBar(Symbol, TimeFrame, index).volume
    }
}
