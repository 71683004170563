import { useEffect, useState, useCallback, RefCallback, RefObject, useRef } from 'react'

export const useLatest = <T>(value: T): RefObject<T> => {
    const ref = useRef(value)
    ref.current = value
    return ref
}

export function useResizeObserver<T extends Element = HTMLElement>(callback: ResizeObserverCallback): RefCallback<T> {
    const [el, setEl] = useState<T | null>(null)
    const resizeObserverCallbackRef = useLatest(callback)

    useEffect(() => {
        const callback = resizeObserverCallbackRef.current
        if (!el || !callback) return
        const resizeObserver = new ResizeObserver(callback)
        resizeObserver.observe(el)
        return () => {
            resizeObserver.disconnect()
        }
    }, [el, resizeObserverCallbackRef, callback])

    return useCallback((node: T) => {
        node && setEl(node)
    }, [])
}
