import { makeAutoObservable } from 'mobx'
import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'
import { CustomTimeFrame } from '@fto/lib/store/timeframe/types'

class TimeframeStore {
    timeframe: number | undefined
    preselectedTimeFrames: number[]
    customTimeframes: CustomTimeFrame[]

    constructor() {
        makeAutoObservable(this)
        this.timeframe = undefined
        this.preselectedTimeFrames = TIME_FRAMES
        this.customTimeframes = []
        this.setTimeframe = this.setTimeframe.bind(this)
        this.updatePreselectedTimeFrames = this.updatePreselectedTimeFrames.bind(this)
        this.updateCustomTimeFrames = this.updateCustomTimeFrames.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    setTimeframe(updater: ((prevSettings: number | undefined) => number) | number) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.timeframe = updater(this.timeframe)
        } else {
            // If updater is a value, directly apply the updates
            this.timeframe = updater
        }
    }

    updatePreselectedTimeFrames(updater: ((prevSettings: number[]) => number[]) | number[]) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.preselectedTimeFrames = updater(this.preselectedTimeFrames)
        } else {
            // If updater is a value, directly apply the updates
            this.preselectedTimeFrames = updater
        }
    }

    updateCustomTimeFrames(updater: ((prevSettings: CustomTimeFrame[]) => CustomTimeFrame[]) | CustomTimeFrame[]) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.customTimeframes = updater(this.customTimeframes)
        } else {
            // If updater is a value, directly apply the updates
            this.customTimeframes = updater
        }
    }

    resetStore() {
        this.timeframe = 1
        this.preselectedTimeFrames = TIME_FRAMES
        this.customTimeframes = []
    }
}

export default new TimeframeStore()
