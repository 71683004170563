import CommonConstants from '../../common/CommonConstants'

export enum TDataTypes {
    dt_Bars,
    dt_Ticks,
    dt_Empty,
    dt_IndicatorBuffer
}

export interface TDataDescriptor {
    broker: string
    symbolName: string
    dataType: TDataTypes
    timeframe: number
}

export const EmptyDataDescriptor = {
    broker: 'Deliberately empty',
    symbolName: 'Deliberately empty',
    dataType: TDataTypes.dt_Empty,
    timeframe: -1
}
