import { Command } from './Command'
import KeyboardTracker from '../KeyboardTracker'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

class ToolRotateEnableCommand extends Command {
    public execute(): void {
        GlobalOptions.Options.TempRotateMode = true

        if (!KeyboardTracker.getInstance().isFakeMouseMoveDone) {
            GlobalChartsController.Instance.doFakeMouseMove()
            KeyboardTracker.getInstance().isFakeMouseMoveDone = true
        }
    }
}

export { ToolRotateEnableCommand }
