import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Button, EmptyState } from '@fto/ui'
import { Icon } from '@fto/icons'

import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { LevelType } from '@fto/lib/drawing_interface/GraphicObjects/LevelType'
import { LevelCreatorTypes } from '@fto/chart_components/LevelsCreator/types'

import Row from './components/Row'

import styles from './index.module.scss'

type Props = {
    levelsList: LevelType[]
    addLevel: () => void
    removeLevel: LevelCreatorTypes['removeLevel']
    toggleLevel: LevelCreatorTypes['toggleLevel']
    handleLevelValueChange: LevelCreatorTypes['handleLevelValueChange']
    updateStyles: LevelCreatorTypes['updateStyles']
    withOpacity?: boolean
}

export const LEVEL_TEMPLATE = {
    value: 0,
    style: TPenStyle.psSolid,
    width: 1,
    color: '#000000',
    text: 'n',
    opacity: 1,
    isActive: true
}

const LevelsCreator: FC<Props> = ({
    levelsList = [],
    addLevel,
    updateStyles,
    handleLevelValueChange,
    toggleLevel,
    removeLevel,
    withOpacity = false
}) => {
    const { t } = useTranslation()

    return (
        <Flex direction='column' gap={16}>
            <Flex justifyContent='center' alignItems='center'>
                {levelsList.length > 0 ? (
                    <Flex direction='column' gap={8} className={styles.Wrapper}>
                        {levelsList.map((level: LevelType, idx) => {
                            return (
                                <Row
                                    key={level.id}
                                    level={level}
                                    updateStyles={updateStyles}
                                    handleLevelValueChange={handleLevelValueChange}
                                    toggleLevel={toggleLevel}
                                    removeLevel={removeLevel}
                                    withOpacity={withOpacity}
                                    withLabel={idx === 0}
                                />
                            )
                        })}
                    </Flex>
                ) : (
                    <EmptyState description={t('toolsTabs.levels.emptyState')} />
                )}
            </Flex>

            <Flex alignItems='center' justifyContent='center'>
                <Button
                    onClick={addLevel}
                    type='secondary'
                    classNames={{ content: styles.AddButton }}
                    label={
                        <Flex alignItems='center' gap={8}>
                            <Icon name='plus' size={12} color='currentColor' />
                            <Typography type='interface-medium'>{t('toolsTabs.levels.addLevels')}</Typography>
                        </Flex>
                    }
                />
            </Flex>
        </Flex>
    )
}

export default LevelsCreator
