import { FC, useCallback } from 'react'
import cx from 'classnames'

import { Button, Flex, Typography, Separator } from '@fto/ui'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import { useGetObjectsCountByVisibility } from '../helpers/useGetObjectsCountByVisibility'

import styles from '../../../../../common.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    setTooltipTrigger: (trigger: { isOpen: boolean; closedFromMenu: boolean }) => void
}

const VisibilityDropdownMenu: FC<Props> = ({ setTooltipTrigger }) => {
    const { t } = useTranslation()

    const chart = GlobalChartsController.Instance.getActiveChart()
    if (!chart) return <></>

    const { hiddenToolsCount, hiddenIndicatorsCount, shownIndicatorsCount, shownToolsCount } =
        useGetObjectsCountByVisibility()

    const showTools = useCallback(() => {
        chart.ShowAllHiddenTools()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    const showIndicators = useCallback(() => {
        chart.ShowAllHiddenIndicators()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    const showAll = useCallback(() => {
        showIndicators()
        showTools()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    const hideTools = useCallback(() => {
        chart.HideAllTools()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    const hideIndicators = useCallback(() => {
        chart.HideAllIndicators()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    const hideAll = useCallback(() => {
        hideIndicators()
        hideTools()
        setTooltipTrigger({ isOpen: false, closedFromMenu: true })
    }, [chart])

    return (
        <Flex direction='column' justifyContent='flex-start' alignItems='center' className={styles.dropdown} gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {t('graphicTools.groupNames.hide')}
            </Typography>
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{shownToolsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.hideTools')}
                onClick={hideTools}
            />
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{shownIndicatorsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.hideIndicators')}
                onClick={hideIndicators}
            />
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{shownToolsCount + shownIndicatorsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.hideAll')}
                onClick={hideAll}
            />
            <Separator margin='8px' width='95%' />
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {t('graphicTools.groupNames.show')}
            </Typography>
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{hiddenToolsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.showTools')}
                onClick={showTools}
            />
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{hiddenIndicatorsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.showIndicators')}
                onClick={showIndicators}
            />
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.withCounter) }}
                after={<Typography className={styles.counter}>{hiddenToolsCount + hiddenIndicatorsCount}</Typography>}
                type='tetriatry-white'
                size='compact'
                label={t('graphicTools.showAll')}
                onClick={showAll}
            />
        </Flex>
    )
}

export default VisibilityDropdownMenu
