import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { Command } from './Command'

class BarBackCommand extends Command {
    public execute(): void {
        GlobalTestingManager.TestingManager.StepBackBy1Bar()
    }
}

export { BarBackCommand }
