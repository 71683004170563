import { FC } from 'react'
import { ColorPicker } from '@fto/ui'

import TriggerElement from '../../../TriggerElement'
import useFillColor from '../../hooks/useFillColor'
import { useTranslation } from 'react-i18next'

type Props = {
    tools: any[]
}
const FillColor: FC<Props> = ({ tools }) => {
    const { t } = useTranslation()

    const fillColorParams = useFillColor({
        tools,
        setParamsFunctionKey: 'setFillColorParams',
        getParamsFunctionKey: 'getFillColorParams'
    })

    return (
        <ColorPicker
            triggerElement={
                <TriggerElement
                    color={fillColorParams.color}
                    tooltipLabel={t('graphToolPanel.tooltips.backgroundColor')}
                    disabled={!fillColorParams.readyToChange}
                    type='fillColor'
                    isMultiColoring={fillColorParams.isMultiColoring}
                />
            }
            color={fillColorParams.color}
            onColorChange={fillColorParams.handleColorChange}
            withOpacity={fillColorParams.withOpacity}
            onOpacityChange={fillColorParams.handleOpacityChange}
            opacity={fillColorParams.opacity}
            disabled={!fillColorParams.readyToChange}
        />
    )
}

export default FillColor
