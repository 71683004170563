import { t } from 'i18next'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'
import { JumpToStrategy } from '@fto/lib/JumpTo/JumpToStrategies/JumpToStrategy'
import { NextSessionJumpTo } from './JumpToStrategies/NextSessionJumpTo'
import { JumpToSession } from './JumpToSession'
import { NextNewsJumpTo } from '@fto/lib/JumpTo/JumpToStrategies/NextNewsJumpTo'
import { JumpToConcreteNextDayTime } from './JumpToStrategies/JumpToConcreteNextDayTime'
import { ConcreteSessionJumpToOpen } from './JumpToStrategies/ConcreteSessionJumpToOpen'
import { ConcreteSessionJumpToClose } from './JumpToStrategies/ConcreteSessionJumpToClose'
import { TimeValue, TimeValues } from '../extension_modules/common/CommonTypes'

export class JumpToController {
    private _sessions: JumpToSession[]
    private static instance: JumpToController
    private _jumpStrategy: JumpToStrategy | null = null

    public static Instance(): JumpToController {
        if (!JumpToController.instance) {
            JumpToController.instance = new JumpToController()
        }
        return JumpToController.instance
    }

    private constructor() {
        this._sessions = []
        let asiaSession = new JumpToSession(
            t('indicators.sessions.asian'),
            TimeValues.getTimeInSeconds(TimeValue['22:00']),
            TimeValues.getTimeInSeconds(TimeValue['08:00']),
            false
        )
        let londonSession = new JumpToSession(
            t('indicators.sessions.london'),
            TimeValues.getTimeInSeconds(TimeValue['08:00']),
            TimeValues.getTimeInSeconds(TimeValue['16:00']),
            false
        )
        let newyork = new JumpToSession(
            t('indicators.sessions.newYork'),
            TimeValues.getTimeInSeconds(TimeValue['13:00']),
            TimeValues.getTimeInSeconds(TimeValue['21:00']),
            false
        )
        let silverBullet = new JumpToSession(
            t('indicators.sessions.silverBullet'),
            TimeValues.getTimeInSeconds(TimeValue['14:00']),
            TimeValues.getTimeInSeconds(TimeValue['15:00']),
            false
        )
        this._sessions.push(asiaSession, londonSession, newyork, silverBullet)
    }

    public addCustomSession(name: string, openTime: TimeValue, closeTime: TimeValue) {
        if (!this._sessions.some((session: JumpToSession) => session.getName() === name)) {
            let openTimeInSecondsOfCurrDay = TimeValues.getTimeInSeconds(openTime)
            let closeTimeInSecondsOfCurrDay = TimeValues.getTimeInSeconds(closeTime)
            let session = new JumpToSession(name, openTimeInSecondsOfCurrDay, closeTimeInSecondsOfCurrDay, true)
            this._sessions.push(session)
        } else {
            throw new Error('Session with this name already exists')
        }
    }

    public onTickChunkLoaded() {
        this.jump()
    }

    private jump() {
        if (this._jumpStrategy) {
            try {
                this._jumpStrategy.jump()
                this._jumpStrategy = null
            } catch (e) {
                if (e instanceof DataNotDownloadedYetError) {
                    //its normal situation
                } else {
                    this._jumpStrategy = null
                    throw e
                }
            }
        }
    }

    public jumpToNextDayOpen() {
        if (this._jumpStrategy) {
            return
        }

        const lastTickTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime()
        this._jumpStrategy = new JumpToConcreteNextDayTime(
            TimeValues.getTimeInSeconds(TimeValue['00:00']),
            lastTickTime
        )

        this.jump()
    }

    public jumpToNextDayClose() {
        if (this._jumpStrategy) {
            return
        }

        const lastTickTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime()
        this._jumpStrategy = new JumpToConcreteNextDayTime(
            TimeValues.getTimeInSeconds(TimeValue['23:59']),
            lastTickTime
        )

        this.jump()
    }

    public nextNewsJump() {
        if (this._jumpStrategy) {
            return
        }
        this._jumpStrategy = new NextNewsJumpTo()
        this.jump()
    }

    public jumpToAsianSessionOpen() {
        this.jumpToSessionOpen('indicators.sessions.asian')
    }

    public jumpToAsianSessionClose() {
        this.jumpToSessionClose('indicators.sessions.asian')
    }

    public jumpToNewYorkSessionOpen() {
        this.jumpToSessionOpen('indicators.sessions.newYork')
    }

    public jumpToNewYorkSessionClose() {
        this.jumpToSessionClose('indicators.sessions.newYork')
    }

    public jumpToLondonSessionOpen() {
        this.jumpToSessionOpen('indicators.sessions.london')
    }

    public jumpToLondonSessionClose() {
        this.jumpToSessionClose('indicators.sessions.london')
    }

    public jumpToSilverBulletSessionOpen() {
        this.jumpToSessionOpen('indicators.sessions.silverBullet')
    }

    public jumpToNextSessionOpen() {
        if (this._jumpStrategy) {
            return
        }

        const lastTickTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime()
        this._jumpStrategy = new NextSessionJumpTo(lastTickTime, this._sessions)
        this.jump()
    }

    private jumpToSessionOpen(sessionNameKey: string) {
        if (this._jumpStrategy) {
            return
        }

        const session: JumpToSession | undefined = this._sessions.find(
            (s: JumpToSession): boolean => s.getName() === t(sessionNameKey)
        )

        if (session) {
            this._jumpStrategy = new ConcreteSessionJumpToOpen(
                session,
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime()
            )
            this.jump()
        } else {
            throw new Error('Not found session with name: ' + sessionNameKey)
        }
    }

    private jumpToSessionClose(sessionNameKey: string) {
        if (this._jumpStrategy) {
            return
        }

        const session: JumpToSession | undefined = this._sessions.find(
            (s: JumpToSession): boolean => s.getName() === t(sessionNameKey)
        )

        if (session) {
            this._jumpStrategy = new ConcreteSessionJumpToClose(
                session,
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime()
            )
            this.jump()
        } else {
            throw new Error('Not found session with name: ' + sessionNameKey)
        }
    }
}
