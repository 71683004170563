import { Flex, InputField, Modal, Typography } from '@fto/ui'
import styles from './modals.module.scss'
import { Fragment, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { SET_CONFIRMATION_CODE } from '@root/store/auth/auth.slice'
import { useTranslation } from 'react-i18next'

type EnterCodeContentProps = {
    onClose: () => void
    setStage: () => void
    email: string
}

export const EnterCodeContent = (props: EnterCodeContentProps) => {
    const { t } = useTranslation()

    const [code, setCode] = useState('')
    const dispatch = useAppDispatch()

    const handleCodeConfirmation = () => {
        dispatch(SET_CONFIRMATION_CODE(code))
        props.setStage()
    }

    const isCodeValid = code.length > 0

    return (
        <Fragment>
            <Modal.Content className={styles.line}>
                <Flex direction='column' gap={24}>
                    <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                        {t('profile.modals.enterCodeTextBefore') + props.email + t('profile.modals.enterCodeTextAfter')}
                    </Typography>
                    <Flex direction='column' gap={8}>
                        <InputField
                            id='code'
                            label={t('profile.modals.vereficationCode')}
                            block
                            placeholder='Verefication code from email'
                            value={code}
                            onChange={setCode}
                        />
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                submitText={t('global.confirm')}
                cancelText={t('global.cancel')}
                disabled={!isCodeValid}
                onClose={props.onClose}
                onSubmit={() => handleCodeConfirmation()}
            />
        </Fragment>
    )
}
