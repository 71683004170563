import { Icon } from '@fto/icons'
import { Flex, Tooltip, Typography } from '@fto/ui'
import { FC } from 'react'

import styles from './index.module.scss'
import { TYPOGRAPHY_TYPES } from '@fto/ui/lib/components/Typography/types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

type Props = {
    label: string
    tooltipText: string
    type: (typeof TYPOGRAPHY_TYPES)[number]
    classNames?: {
        text?: string
        container?: string
    }
    gap?: number
    isHintAfterText?: boolean
}

const TextWithHint: FC<Props> = ({ label, tooltipText, type, classNames, gap, isHintAfterText }) => {
    const { t } = useTranslation()

    return (
        <Flex gap={gap} alignItems='center' className={classNames?.container}>
            {!isHintAfterText && (
                <Tooltip content={t(tooltipText)} classNames={{ content: styles.hint }} placement='top' arrow={false}>
                    <Icon name='info-filled' size={16} color={'var(--color-gray-500)'} />
                </Tooltip>
            )}
            <Typography type={type} color='gray-900' className={cx(classNames?.text, styles.text)}>
                {t(label)}
            </Typography>
            {isHintAfterText && (
                <Tooltip content={t(tooltipText)} classNames={{ content: styles.hint }} placement='top' arrow={false}>
                    <Icon name='info-filled' size={16} color={'var(--color-gray-500)'} />
                </Tooltip>
            )}
        </Flex>
    )
}

export default TextWithHint
