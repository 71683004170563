import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

export interface ObserverTemplate<E, I> {
    processEvent(event: E, item: I): void
}

export class ObservableTemplateItem<E, I, O extends ObserverTemplate<E, I>> {
    private observers: O[] = []

    public attachObserver(observer: O): void {
        const index = this.observers.indexOf(observer)
        if (index === -1) {
            this.observers.push(observer)
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Observer already attached')
        }
    }

    public detachObserver(observer: O): void {
        const index = this.observers.indexOf(observer)
        if (index !== -1) {
            this.observers.splice(index, 1)
        }
    }

    public detachAllObservers(): void {
        this.observers = []
    }

    public notify(event: E, item: I): void {
        for (const observer of this.observers) {
            observer.processEvent(event, item)
        }
    }
}
