import { FC, useCallback } from 'react'

import { InputField, SimpleDropdown, Checkbox, ColorPicker } from '@fto/ui'
import LineStylePicker from '@root/pages/ChartPage/components/lineStylePicker'
import { IIndicatorOptionsStore } from '@fto/lib/charting/tool_storages/indicators/Interfaces/IIndicatorOptionsStore'
import FieldWrapper from './components/FieldWrapper'

import styles from './index.module.scss'
import { TBufferStyle } from '@fto/lib/drawing_interface/VCLCanvas/TBufferStyle'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import BufferStylePicker from './components/BufferStylePicker'
import { TOptionType } from '@fto/lib/extension_modules/common/CommonTypes'

const dropdownOptions = (values: string[]) => {
    return values.map((value) => ({ label: value, value: value }))
}

type FieldCreatorProps = {
    optionKey: string | number
    option: any
    // TODO
    setIndicatorOptions: any
}

const FieldCreator: FC<FieldCreatorProps> = ({ optionKey, option, setIndicatorOptions }) => {
    const onStylesChange = useCallback(
        (value: { color: TColor; style: TPenStyle; width: number }) => {
            const { color, width, style } = value
            if (optionKey) {
                setIndicatorOptions((prevData: any) => ({
                    ...prevData,
                    [optionKey]: {
                        ...prevData[optionKey],
                        fvalue: new TLineStyle(color, style, width)
                    }
                }))
                return
            }

            setIndicatorOptions((prevData: any) => {
                return {
                    ...prevData,
                    ['fValue']: new TLineStyle(color, style, width)
                }
            })
        },
        [optionKey, setIndicatorOptions]
    )
    switch (parseInt(option.type)) {
        case TOptionType.ot_LineStyle:
            return option.fvalue instanceof TBufferStyle ? (
                <BufferStylePicker
                    data={{ fValue: option.fvalue }}
                    attribute='fValue'
                    name={option.alias}
                    optionKey={optionKey}
                    setIndicatorOptions={setIndicatorOptions}
                />
            ) : (
                <FieldWrapper label={option.alias}>
                    <LineStylePicker data={{ fValue: option.fvalue }} onChange={onStylesChange} attribute='fValue' />
                </FieldWrapper>
            )

        case TOptionType.ot_EnumType:
            return (
                <FieldWrapper label={option.alias}>
                    <SimpleDropdown
                        onChange={(newValue) => {
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => {
                                return {
                                    ...prevOptions,
                                    [optionKey]: {
                                        ...prevOptions[optionKey as number],
                                        fvalue: newValue
                                    }
                                }
                            })
                        }}
                        value={option.fvalue}
                        options={dropdownOptions(option.values)}
                    />
                </FieldWrapper>
            )
        case TOptionType.ot_Integer:
            return (
                <FieldWrapper label={option.alias}>
                    <div className={styles.Input}>
                        <InputField
                            type='number'
                            value={option.fvalue}
                            onChange={(fieldValue) => {
                                let value = fieldValue
                                if (value < option.LowValue) {
                                    value = option.LowValue
                                } else if (value > option.HighValue) {
                                    value = option.HighValue
                                }
                                setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                    ...prevOptions,
                                    [optionKey]: {
                                        ...prevOptions[optionKey as number],
                                        fvalue: value
                                    }
                                }))
                            }}
                            min={option.LowValue}
                            max={option.HighValue}
                        />
                    </div>
                </FieldWrapper>
            )
        case TOptionType.ot_double:
            return (
                <FieldWrapper label={option.alias}>
                    <InputField
                        type='text'
                        value={option.fvalue}
                        onChange={(newValue) => {
                            let value = newValue
                            if (value < option.LowValue) {
                                value = option.LowValue
                            } else if (value > option.HighValue) {
                                value = option.HighValue
                            }
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: value
                                }
                            }))
                        }}
                        min={option.LowValue}
                        max={option.HighValue}
                    />
                </FieldWrapper>
            )
        case TOptionType.ot_String:
            return option.alias === 'Levels' && !option.fvalue ? null : (
                <FieldWrapper label={option.alias}>
                    <InputField
                        type='text'
                        value={option.fvalue}
                        onChange={(newValue) => {
                            return setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: newValue
                                }
                            }))
                        }}
                    />
                </FieldWrapper>
            )
        case TOptionType.ot_Longword:
            return (
                <FieldWrapper label={option.alias}>
                    <div className={styles.Input}>
                        <InputField
                            type='number'
                            value={option.fvalue}
                            onChange={(value) =>
                                setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                    ...prevOptions,
                                    [optionKey]: {
                                        ...prevOptions[optionKey as number],
                                        fvalue: parseInt(value)
                                    }
                                }))
                            }
                        />
                    </div>
                </FieldWrapper>
            )
        case TOptionType.ot_Boolean:
            return (
                <FieldWrapper label={option.alias}>
                    <Checkbox
                        type='checkbox'
                        checked={option.fvalue}
                        onChange={(checked) =>
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: checked
                                }
                            }))
                        }
                    />
                </FieldWrapper>
            )
        case TOptionType.ot_Timeframe:
        case TOptionType.ot_Currency:
        case TOptionType.ot_Indicator:
            return (
                <FieldWrapper label={option.alias}>
                    <select
                        value={option.fvalue}
                        onChange={(e) =>
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: e.target.value
                                }
                            }))
                        }
                    >
                        {option.values.map((value: string) => (
                            <option value={value}>{value}</option>
                        ))}
                    </select>
                </FieldWrapper>
            )
        case TOptionType.ot_Color:
            return (
                <FieldWrapper label={option.alias}>
                    <ColorPicker
                        color={option.fvalue}
                        onColorChange={(value) => {
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: value
                                }
                            }))
                        }}
                        withOpacity={false}
                    />
                </FieldWrapper>
            )
        case TOptionType.ot_DateTime:
            return (
                <FieldWrapper label={option.alias}>
                    <input
                        type='datetime-local'
                        value={option.fvalue}
                        onChange={(e) =>
                            setIndicatorOptions((prevOptions: IIndicatorOptionsStore) => ({
                                ...prevOptions,
                                [optionKey]: {
                                    ...prevOptions[optionKey as number],
                                    fvalue: e.target.value
                                }
                            }))
                        }
                    />
                </FieldWrapper>
            )
        // case TOptionType.ot_Separator:
        //     return <hr />

        default:
            return null
    }
}

export default FieldCreator
