import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Icon } from '@fto/icons'
import { InputField, TriggerOverlay } from '@fto/ui'
import AppCalendar, { CalendarValue } from '@root/components/Common/AppCalendar/AppCalendar'
import { TIME_TEMPLATES } from '@root/constants/timeTemplates'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

import styles from './create-project-calendar.module.scss'

dayjs.extend(utc)

export type CalendarPositionOptions = {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
}

type Props = {
    name: string
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
    maxDate?: Date | null
    minDate?: Date | null
    initialDate?: Date | null
    label?: React.ReactNode
    disabled?: boolean
    transformOrigin?: CalendarPositionOptions
    anchorOrigin?: CalendarPositionOptions
}

const CreateProjectCalendar: FC<Props> = ({
    setFormData,
    minDate,
    disabled,
    transformOrigin = { vertical: 'top', horizontal: 'right' },
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
    initialDate,
    maxDate,
    label,
    name
}) => {
    const initDate = dayjs.utc(initialDate || new Date()).format(TIME_TEMPLATES.DASHBOARD_PERIOD)
    const [value, setValue] = useState(initDate)
    const [inputWidth, setInputWidth] = useState(0)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleChange = useCallback(
        (value: CalendarValue) => {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }))
            const date = dayjs.utc(`${value}`).format(TIME_TEMPLATES.DASHBOARD_PERIOD)
            setValue(date)
        },
        [setFormData]
    )

    const renderCalendarOptions = (close: () => void) => {
        const selectDate = (date: CalendarValue) => {
            handleChange(date)
            close()
        }

        return (
            <div style={{ width: `${inputWidth - 16}px` }}>
                <AppCalendar
                    className={styles.calendar}
                    initialDate={initialDate || undefined}
                    maxDate={maxDate || undefined}
                    minDate={minDate || undefined}
                    setCalendarValue={selectDate}
                />
            </div>
        )
    }

    useEffect(() => {
        if (initialDate) {
            handleChange(initialDate)
        }
    }, [disabled, initialDate])

    useEffect(() => {
        if (inputRef.current) {
            setInputWidth(inputRef.current.offsetWidth)
        }
    }, [inputRef])

    return (
        <div className={classNames(disabled && 'disabled')}>
            <TriggerOverlay
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
                optionsRenderer={(close) => renderCalendarOptions(close)}
            >
                <div ref={inputRef} className={styles.inputWrap}>
                    <InputField
                        className={styles.input}
                        label={label}
                        value={value}
                        prefix={<Icon name={'date'} size={18} color='var(--color-gray-1000)' />}
                    />
                </div>
            </TriggerOverlay>
        </div>
    )
}
export default CreateProjectCalendar
