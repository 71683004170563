import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TDateTime, TPriceType, TValueType } from '../CommonTypes'
import { ICurrentSymbolAndTFInfoProcRec } from '../CommonProcRecInterfaces/ICurrentSymbolInfoProcRec'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { APIHelperFunctions } from '../ApiHelperFunctions'
import { GlobalHighestLowestPriceCache } from '@fto/lib/globals/HighestLowestCache/GlobalHighestLowestPriceCache'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'

export class CurrentSymbolAndTFInfoProcRecImplementation
    extends BasicProcRecImplementation
    implements ICurrentSymbolAndTFInfoProcRec
{
    public GetImplementation(): ICurrentSymbolAndTFInfoProcRec {
        return {
            // Current Symbol and Timeframe Info
            Symbol: this.Symbol.bind(this),
            Timeframe: this.Timeframe.bind(this),
            Bid: this.Bid.bind(this),
            Ask: this.Ask.bind(this),
            Digits: this.Digits.bind(this),
            Point: this.Point.bind(this),
            Open: this.Open.bind(this),
            Close: this.Close.bind(this),
            High: this.High.bind(this),
            Low: this.Low.bind(this),
            Volume: this.Volume.bind(this),
            Time: this.Time.bind(this),
            Bars: this.Bars.bind(this),
            GetPrice: this.GetPrice.bind(this),
            GetHighestValue: this.GetHighestValue.bind(this),
            GetLowestValue: this.GetLowestValue.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_CurrentSymbolAndTF_${super.generateDName()}`
    }

    private barsArrayGetter: () => IFMBarsArray
    private symbolDataGetter: () => ISymbolData

    private get barsArray() {
        if (!this.barsArrayGetter) {
            throw new API_InvalidInitialization('barsArrayGetter is not assigned.')
        }
        const bars = this.barsArrayGetter()
        if (!bars) {
            throw new API_InvalidInitialization('barsArrayGetter returned null.')
        }
        return bars
    }

    private get symbolData() {
        if (!this.symbolDataGetter) {
            throw new API_InvalidInitialization('symbolDataGetter is not assigned.')
        }
        const symbolData = this.symbolDataGetter()
        if (!symbolData) {
            throw new API_InvalidInitialization('symbolDataGetter returned null.')
        }
        return symbolData
    }

    constructor(
        procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds,
        getBarsArray: () => IFMBarsArray,
        symbolDataGetter: () => ISymbolData
    ) {
        super(procRecsEveryoneNeeds)
        this.barsArrayGetter = getBarsArray
        this.symbolDataGetter = symbolDataGetter
    }

    public Bid(): number {
        return this.symbolData.bid
    }
    public Ask(): number {
        return this.symbolData.ask
    }
    public Symbol(): string {
        return this.symbolData.symbolInfo.SymbolName
    }
    public Digits(): number {
        return this.symbolData.symbolInfo.decimals
    }
    public Point(): number {
        return this.symbolData.symbolInfo.MinPoint
    }
    public Timeframe(): number {
        return this.barsArray.DataDescriptor.timeframe
    }
    public Bars(): number {
        return this.barsArray.LastItemInTestingIndex + 1
    }

    public Low(lastItem0_based_index: number): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.low
    }

    public Open(lastItem0_based_index: number): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.open
    }

    public High(lastItem0_based_index: number): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.high
    }

    public Time(lastItem0_based_index: number): TDateTime {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.DateTime
    }

    public GetPrice(lastItem_0_based_index: number, PriceType: TPriceType): number {
        let high: number, low: number, close: number

        switch (PriceType) {
            case TPriceType.pt_Close: {
                return this.Close(lastItem_0_based_index)
            }
            case TPriceType.pt_Open: {
                return this.Open(lastItem_0_based_index)
            }
            case TPriceType.pt_High: {
                return this.High(lastItem_0_based_index)
            }
            case TPriceType.pt_Low: {
                return this.Low(lastItem_0_based_index)
            }
            case TPriceType.pt_HL2: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                return (high + low) / 2
            }
            case TPriceType.pt_HLC3: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                close = this.Close(lastItem_0_based_index)
                return (high + low + close) / 3
            }
            case TPriceType.pt_HLCC4: {
                high = this.High(lastItem_0_based_index)
                low = this.Low(lastItem_0_based_index)
                close = this.Close(lastItem_0_based_index)
                return (high + low + close * 2) / 4
            }
            default: {
                return 0
            }
        }
    }

    public Close(lastItem0_based_index: number): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.close
    }

    public Volume(lastItem0_based_index: number): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(this.barsArray, lastItem0_based_index)
        return bar.volume
    }

    private GetValueByType(lastItem0_based_index: number, valueType: TValueType): number {
        switch (valueType) {
            case TValueType.vt_Open: {
                return this.Open(lastItem0_based_index)
            }
            case TValueType.vt_High: {
                return this.High(lastItem0_based_index)
            }
            case TValueType.vt_Low: {
                return this.Low(lastItem0_based_index)
            }
            case TValueType.vt_Close: {
                return this.Close(lastItem0_based_index)
            }
            default: {
                return this.Volume(lastItem0_based_index)
            }
        }
    }

    public GetHighestValue(valueType: TValueType, startIndex_lastItem0_based: number, count: number): number {
        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            const highestPrice = GlobalHighestLowestPriceCache.Instance.getHighestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count
            )

            if (highestPrice) {
                return highestPrice
            }
        }

        let result = this.GetValueByType(startIndex_lastItem0_based, valueType)
        for (let i = 1; i < count; i++) {
            const v = this.GetValueByType(startIndex_lastItem0_based + i, valueType)
            if (v > result) {
                result = v
            }
        }

        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            GlobalHighestLowestPriceCache.Instance.setHighestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count,
                result
            )
        }

        return result
    }

    public GetLowestValue(valueType: TValueType, startIndex_lastItem0_based: number, count: number): number {
        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            const highestPrice = GlobalHighestLowestPriceCache.Instance.getLowestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count
            )

            if (highestPrice) {
                return highestPrice
            }
        }

        let result = this.GetValueByType(startIndex_lastItem0_based, valueType)
        for (let i = 1; i < count; i++) {
            const v = this.GetValueByType(startIndex_lastItem0_based + i, valueType)
            if (v < result) {
                result = v
            }
        }

        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            GlobalHighestLowestPriceCache.Instance.setLowestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count,
                result
            )
        }

        return result
    }
}
