/* eslint-disable no-debugger */
import { showErrorToast } from '@root/utils/toasts'
import { DebugUtils } from '../utils/DebugUtils'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'

export default class StrangeSituationNotifier {
    public static NotifyAboutUnexpectedSituation(messageOrError: string | Error, ...args: any[]): void {
        if (messageOrError instanceof DataNotDownloadedYetError) {
            return
        }

        // TODO: send a notification to the server
        // eslint-disable-next-line no-console
        DebugUtils.error(`Strange situation detected: ${messageOrError}`, ...args)
        if (DebugUtils.DebugMode && !DebugUtils.IsAmongIgnoredErrors(messageOrError)) {
            showErrorToast({ title: 'Strange situation', message: `Strange situation detected: ${messageOrError}` }) //translation-ignore
            if (DebugUtils.Instance.EnableStrangeErrorBreakpoints) {
                if (CommonUtils.IsInUnitTest && this.shouldIgnoreInTests(messageOrError)) {
                    return
                }
                // eslint-disable-next-line no-console
                console.error('debugger here')
                let stack = ''
                if (messageOrError instanceof Error && messageOrError.stack) {
                    stack = messageOrError.stack
                }
                debugger
            }
        }
    }

    private static shouldIgnoreInTests(messageOrError: string | Error) {
        //does message contain '"Seek called without any active timeframes for symbol"?
        const message = messageOrError instanceof Error ? messageOrError.message : messageOrError
        return message.includes('Seek called without any active timeframes for symbol')
    }
}
