export const SYMBOL_ICONS = {
    xagusd: 'symbol-xagusd',
    xauusd: 'symbol-xauusd',
    xpdusd: 'symbol-xpdusd',
    xptusd: 'symbol-xptusd',
    btcusd: 'symbol-btcusd',
    dji: 'symbol-dji',
    ng: 'symbol-ng',
    '100': 'symbol-100',
    nas100: 'symbol-nas',
    spx500: 'symbol-spx500',
    ethusd: 'symbol-eth',
    gas: 'symbol-gas',
    light: 'symbol-light',
    germany30: 'country-germany',
    hongkong40: 'country-hong-kong',
    japan225: 'country-japan',
    uk100: 'country-united-kingdom',
    usa500: 'country-usa',
    silver: 'symbol-silver'
}
