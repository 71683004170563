import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export default class Stochastic extends IndicatorImplementation {
    KPeriod: TOptValue_number = new TOptValue_number(6)
    DPeriod: TOptValue_number = new TOptValue_number(3)
    Slowing: TOptValue_number = new TOptValue_number(3)
    ApplyToPrice: TOptValue_number = new TOptValue_number(1) // 0 for Low/High, 1 for Close/Close

    //TODO:IN investigate if Kfast does anything
    //Kfast!: TIndexBuffer
    Kslow!: TIndexBuffer
    Dline!: TIndexBuffer
    HighestBuffer!: TIndexBuffer
    LowestBuffer!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.SetBackOffsetForCalculation(200)
        this.api.IndicatorShortName(t('indicators.stochastic'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.SetFixedMinMaxValues(0, 100)
        this.api.AddLevel(20, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.AddLevel(80, TPenStyle.psDot, 1, '#ada9a9', 1)
        this.api.SetEmptyValue(105)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.stochastic.fields.percentKPeriod'), TOptionType.ot_Integer, this.KPeriod)
        this.api.SetOptionRange(t('indicatorModal.stochastic.fields.percentKPeriod'), 1, Number.MAX_SAFE_INTEGER)

        this.api.RegOption(t('indicatorModal.stochastic.fields.percentDPeriod'), TOptionType.ot_Integer, this.DPeriod)
        this.api.SetOptionRange(t('indicatorModal.stochastic.fields.percentDPeriod'), 1, Number.MAX_SAFE_INTEGER)

        this.api.RegOption(t('indicatorModal.stochastic.fields.slowing'), TOptionType.ot_Integer, this.Slowing)
        this.api.SetOptionRange(t('indicatorModal.stochastic.fields.slowing'), 1, Number.MAX_SAFE_INTEGER)

        this.api.RegOption(t('indicatorModal.general.applyToPrice'), TOptionType.ot_EnumType, this.ApplyToPrice)
        this.api.AddOptionValue(
            t('indicatorModal.general.applyToPrice'),
            t('indicatorModal.general.applyToPriceOptions.lowHigh')
        )
        this.api.AddOptionValue(
            t('indicatorModal.general.applyToPrice'),
            t('indicatorModal.general.applyToPriceOptions.closeClose')
        )

        //this.Kfast = this.api.CreateIndexBuffer()
        this.Kslow = this.api.CreateIndexBuffer()
        this.Dline = this.api.CreateIndexBuffer()
        this.HighestBuffer = this.api.CreateIndexBuffer()
        this.LowestBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(2)
        this.api.SetIndexBuffer(0, this.Kslow)
        this.api.SetIndexBuffer(1, this.Dline)

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FF0000')
        this.api.SetIndexLabel(0, t('indicatorModal.stochastic.fields.percentK'))

        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#1E90FF')
        this.api.SetIndexLabel(1, t('indicatorModal.stochastic.fields.percentD'))
    }

    // Calculate(index: number): void {
    //     if (index + this.KPeriod.value > this.api.Bars()) {
    //         return
    //     }
    //
    //     let highPrices = []
    //     let lowPrices = []
    //     let closePrices = []
    //     for (let i = 0; i < this.KPeriod.value; i++) {
    //         highPrices.push(this.api.High(index + i))
    //         lowPrices.push(this.api.Low(index + i))
    //         closePrices.push(this.api.Close(index + i))
    //     }
    //
    //     const highestHigh = Math.max(...highPrices)
    //     const lowestLow = Math.min(...lowPrices)
    //     const currentClose =
    //         this.ApplyTo.value === 0 ? closePrices[0] : closePrices.reduce((a, b) => a + b, 0) / closePrices.length
    //
    //     let KValue = (100 * (currentClose - lowestLow)) / (highestHigh - lowestLow)
    //     KValue = isNaN(KValue) ? 0 : KValue // Check for division by zero
    //
    //     this.Kfast.setValue(index, KValue)
    //     // Applying smoothing
    //     let smoothedK = []
    //     for (let i = 0; i < this.Slowing.value && index - i >= 0; i++) {
    //         smoothedK.push(this.Kfast.getValue(index - i))
    //     }
    //     const KslowValue = smoothedK.reduce((a, b) => a + b, 0) / smoothedK.length
    //     this.Kslow.setValue(index, KslowValue)
    //
    //     // %D line (SMA of %K)
    //     let DValues = []
    //     for (let i = 0; i < this.DPeriod.value && index - i >= 0; i++) {
    //         DValues.push(this.Kslow.getValue(index - i))
    //     }
    //     const DValue = DValues.reduce((a, b) => a + b, 0) / DValues.length
    //     this.Dline.setValue(index, DValue)
    // }
    Calculate(index: number): void {
        if (
            this.api.Bars() < this.KPeriod.value ||
            this.api.Bars() < this.Slowing.value ||
            this.api.Bars() < this.DPeriod.value
        ) {
            return
        }

        let Phigh,
            Plow,
            sumlow = 0,
            sumhigh = 0

        if (this.ApplyToPrice.value === 0) {
            Phigh = this.api.High(index)
            Plow = this.api.Low(index)
            for (let i = 1; i < this.KPeriod.value; i++) {
                if (this.api.High(index + i) >= Phigh) {
                    Phigh = this.api.High(index + i)
                }
                if (this.api.Low(index + i) <= Plow) {
                    Plow = this.api.Low(index + i)
                }
            }
        } else {
            Phigh = this.api.Close(index)
            Plow = this.api.Close(index)
            for (let i = 1; i < this.KPeriod.value; i++) {
                if (this.api.Close(index + i) >= Phigh) {
                    Phigh = this.api.Close(index + i)
                }
                if (this.api.Close(index + i) <= Plow) {
                    Plow = this.api.Close(index + i)
                }
            }
        }

        this.HighestBuffer.setValue(index, Phigh)
        this.LowestBuffer.setValue(index, Plow)

        for (let i = index + this.Slowing.value - 1; i >= index; i--) {
            sumlow += this.api.Close(i) - this.LowestBuffer.getValue(i)
            sumhigh += this.HighestBuffer.getValue(i) - this.LowestBuffer.getValue(i)
        }

        if (sumhigh === 0.0) {
            this.Kslow.setValue(index, 100.0)
        } else {
            this.Kslow.setValue(index, (sumlow / sumhigh) * 100)
        }

        this.Dline.setValue(
            index,
            this.iMAonArray(
                this.Kslow,
                this.api.Bars(),
                this.DPeriod.value,
                0,
                0,
                index,
                this.Dline.getValue(index + 1)
            )
        )
    }

    iMAonArray(
        buffer: TIndexBuffer,
        total: number,
        period: number,
        ma_shift: number,
        MAType: number,
        shift: number,
        prev: number
    ): number {
        let i, N: number
        let sum = 0,
            weight = 0,
            q1 = 0,
            k = 0

        switch (MAType) {
            case 0: //SMA
                for (i = shift; i < shift + period; i++) {
                    q1 = buffer.getValue(i)
                    sum += q1
                }
                return sum / period

            case 1: //EMA
                k = 2 / (period + 1)
                if (prev === 0) {
                    return buffer.getValue(shift)
                } else {
                    q1 = buffer.getValue(shift)
                    return prev + k * (q1 - prev)
                }

            case 2: // WMA
                for (i = shift; i < shift + period; i++) {
                    q1 = buffer.getValue(i)
                    N = period - i + shift
                    sum += q1 * N
                    weight += N
                }
                return sum / weight

            case 3: // SSMA
                if (prev === 0) {
                    return this.iMAonArray(buffer, total, period, ma_shift, MAType, shift, prev) // Assuming getMA is a function you have
                } else {
                    q1 = buffer.getValue(shift)
                    return (prev * period - prev + q1) / period
                }

            default:
                return 0
        }
    }
}
