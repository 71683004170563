export type ChartInfoType =
    | { count: 1; layout: 'one-chart'; symbol: string }
    | { count: 2; layout: 'two-charts-horizontally'; symbol: string }
    | { count: 2; layout: 'two-charts-vertically'; symbol: string }
    | { count: 3; layout: 'three-charts-horizontally'; symbol: string }
    | { count: 3; layout: 'three-charts-vertically'; symbol: string }
    | { count: 3; layout: 'one-top-two-bottom'; symbol: string }
    | { count: 3; layout: 'two-top-one-bottom'; symbol: string }
    | { count: 3; layout: 'one-left-two-right'; symbol: string }
    | { count: 3; layout: 'two-left-one-right'; symbol: string }
    | { count: 4; layout: 'four-charts-quadrant'; symbol: string }
    | { count: 4; layout: 'four-charts-horizontal'; symbol: string }
    | { count: 4; layout: 'four-charts-vertical'; symbol: string }
    | { count: 4; layout: 'four-charts-one-top-three-bottom'; symbol: string }
    | { count: 4; layout: 'four-charts-three-rows'; symbol: string }
    | { count: 5; layout: 'five-charts-one-top-four-bottom'; symbol: string }
    | { count: 5; layout: 'five-charts-one-left-four-right'; symbol: string }
    | { count: 6; layout: 'six-charts-grid-2-rows-3-cols'; symbol: string }
    | { count: 6; layout: 'six-charts-grid-3-rows-2-cols'; symbol: string }
    | { count: 7; layout: 'seven-charts-1-extra-col'; symbol: string }
    | { count: 7; layout: 'seven-charts-1-extra-row'; symbol: string }
    | { count: 8; layout: 'eight-charts-grid-2-rows-4-cols'; symbol: string }
    | { count: 8; layout: 'eight-charts-grid-4-rows-2-cols'; symbol: string }

export function isSupportedCount(count: number): count is ChartInfoType['count'] {
    return count >= 1 && count <= 8
}
