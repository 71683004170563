import { TypeAvailableTypes } from '@root/pages/ChartPage/components/CustomTimeframeModal/utils/getOptions'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export const convertMinutesToType = (minutes: number, newType: TypeAvailableTypes): number => {
    switch (newType) {
        case 'minutes': {
            return minutes
        }
        case 'hours': {
            return Math.round(minutes / 60)
        }
        case 'days': {
            return Math.round(minutes / 1440)
        } // 1440 minutes in a day
        case 'weeks': {
            return Math.round(minutes / 10080)
        } // 10080 minutes in a week
        case 'months': {
            return Math.round(minutes / 43200)
        } // 43200 minutes in a 30-day month
        default: {
            throw new StrangeError('Invalid timeframe type in convertMinutesToType')
        }
    }
}
