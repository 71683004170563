import { CreateIndicatorStarategy } from '@fto/lib/extension_modules/startegies/CreateIndicatorStrategies/CreateIndicatorStarategy'
import { IndicatorDescriptor } from '@fto/lib/extension_modules/indicators/IndicatorDescriptor'
import GlobalIndicatorDescriptors from '@fto/lib/globals/GlobalIndicatorDescriptors'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { RuntimeIndicatorFactory } from '../../indicators/RuntimeIndicatorFactory'

export class CreateBuiltInIndicatorStrategy extends CreateIndicatorStarategy {
    public createIndicatorForStrategy(
        symbolData: TSymbolData,
        timeframe: number,
        indicatorFile: string,
        indicatorParams: string
    ): TRuntimeIndicator {
        const indicatorDescriptor: IndicatorDescriptor =
            GlobalIndicatorDescriptors.BuiltInIndicators.findByLibName(indicatorFile)

        const runtimeIndicator = RuntimeIndicatorFactory.CreateRuntimeIndicator(
            indicatorDescriptor,
            symbolData,
            timeframe,
            0
        )
        this.initRuntimeIndicator(runtimeIndicator, indicatorParams)
        return runtimeIndicator
    }
}
