import { FC } from 'react'
import cx from 'classnames'

import { Icon } from '@fto/icons'

import { Tooltip, TooltipProps } from '..'

import styles from './index.module.scss'

type Props = {
    size?: number
    disabled?: boolean
    tooltipContent: TooltipProps['content']
    tooltipColor?: TooltipProps['color']
    tooltipPlacement?: TooltipProps['placement']
    classNames?: {
        root?: string
        disabled?: string
    }
}

export const InfoTooltip: FC<Props> = ({
    size = 16,
    disabled = false,
    tooltipContent,
    tooltipPlacement,
    tooltipColor,
    classNames = {}
}) => {
    return (
        <span className={cx(styles.Root, classNames.root, { [classNames.disabled || styles.Disabled]: disabled })}>
            <Tooltip content={tooltipContent} placement={tooltipPlacement} color={tooltipColor}>
                <Icon name='info-static' size={size} className={styles.Icon} />
            </Tooltip>
        </span>
    )
}
