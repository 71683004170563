import React, { useEffect, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'

import ErrorBoundary from '@root/components/ErrorBoundary'
import { BLOCKED_ROUTE, CHECKOUT_PAGE, makeRoute } from '@root/constants/routes'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $getUserSubscriptionStatus, $isUserLoaded } from '@root/store/auth/selectors'
import { useCrowdinScriptLoading } from '@root/hooks/useCrowdinScriptLoading'
import useUrlParams from 'packages/fto/root/src/components/Layout/hooks/useUrlParams'

import { redirectByAction } from './helpers/redirectByAction'
import LocalizationProvider from './components/LocalizationProvider'
import { gtag } from '@root/utils/fireGoogleTagManagerEvent'

const meta = import.meta.env

function Layout() {
    const navigate = useNavigate()
    const { isNewUser, isTrialExpired } = useAppSelector($getUserSubscriptionStatus)
    const user = useAppSelector($getUser)
    const isUserDataLoaded = useAppSelector($isUserLoaded)

    useUrlParams({ subscriptionId: user.subscriptionId })

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const actionType = params.get('action')

        redirectByAction(actionType, window.location.search, navigate)
    }, [window.location.href])

    useEffect(() => {
        if (isUserDataLoaded && isNewUser) {
            return navigate(makeRoute(CHECKOUT_PAGE))
        }
        const isUserOnCheckout = window.location.pathname.includes(CHECKOUT_PAGE)
        if (isUserDataLoaded && isTrialExpired && !isUserOnCheckout && !user.subscriptionId) {
            return navigate(makeRoute(BLOCKED_ROUTE))
        }
    }, [isUserDataLoaded, window.location.href])

    useEffect(() => {
        // FEATURE BASE SETUP
        const win = window as any

        if (typeof win.Featurebase !== 'function') {
            win.Featurebase = function () {
                // eslint-disable-next-line prefer-rest-params
                ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
            }
        }

        win.Featurebase('initialize_portal_widget', {
            organization: 'fto', // required
            fullScreen: true, // optional
            initialPage: 'MainView', // optional (MainView, RoadmapView, CreatePost, PostsView, ChangelogView)
            email: user.email,
            name: user.displayName,
            alwaysShow: false
        })

        // initialize dataLayer if it's not initialized (Google Tag Manager)
        if (!win.dataLayer) {
            win.dataLayer = []
        }

        // NOTE: here we connect Google Tag Manager only for production
        gtag('set', 'linker', {
            domains: ['app.forextester.com']
        })
    }, [])

    useCrowdinScriptLoading()

    const helmet = useMemo(() => {
        if (meta.VITE_ENV_TYPE === 'beta') {
            return (
                <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                </Helmet>
            )
        }

        return null
    }, [])

    return (
        <>
            <main className='main'>
                <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                </Helmet>
                <ToastContainer />
                {helmet}
                <ErrorBoundary>
                    <LocalizationProvider>
                        <Outlet />
                    </LocalizationProvider>
                </ErrorBoundary>
            </main>
        </>
    )
}

export default Layout
