import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TLineStyleRec } from '@fto/lib/drawing_interface/GraphicObjects/TLineStyleRec'
import { TRect } from '../../common/CommonExternalInterface'
import { TOptValue } from '@fto/lib/extension_modules/common/CommonTypes'

export class Constants {
    static readonly MAX_INT = 2147483647
}

// export class TOptValue_num_enum<TEnum> extends TOptValue_number {
//   private enumType: { [key: number]: string };

//   constructor(enumType: { [key: number]: string }) {
//     super();
//     this.enumType = enumType;
//   }

//   public get enum_value(): TEnum {
//     return this.fValue as TEnum;
//   }

//   public set enum_value(value: TEnum) {
//     if (!this.isValidEnumValue(value as unknown as number)) {
//       throw new Error(`Invalid enum value: ${value}`);
//     }
//     this.fValue = value as unknown as number;
//   }

//   public set value(value: number) {
//     if (!this.isValidEnumValue(value)) {
//       throw new Error(`Invalid enum value: ${value}`);
//     }
//     this.fValue = value;
//   }

//   private isValidEnumValue(value: number): boolean {
//     const enumValues = Object.values(this.enumType);
//     return enumValues.includes(value.toString()) || enumValues.includes(value as any);
//   }
// }

export enum MacdMode {
    MAIN,
    SIGNAL
}

export enum TValueType {
    vt_Open,
    vt_High,
    vt_Low,
    vt_Close,
    vt_Volume
}

export class TSymbolInfo_Indicators {
    Symbol!: string // symbol name
    Digits!: number // number of digits after '.'
    spread!: number // spread in pips
    Point!: number // minimal symbol point
    lot!: number // 1 lot cost
    curr!: string // lot currency
    SwapLong!: number // swap points long
    SwapShort!: number // swap points short
}

export class TIndicatorOption {
    ParamsStr!: string // params string
    BufferPtr!: any // pointer to buffer object
}

// export class TIndexBufferInterfaceSide {
//   private fBufferIndex: number;

//   constructor(index: number) {
//     this.fBufferIndex = index;
//   }

//   // property value[index: number]: number read GetValue write SetValue; default;
// }

export class TBuffersArray {
    // property data[index: number]: TIndexBuffer read GetBuffer; default;
}

export class IndicatorInterfaceUnit {}
