import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Flex } from '@fto/ui'
import oneClickTradingStore from '@fto/lib/store/oneClickTradingStore'

import styles from '../OneClickTradingContextMenu/index.module.scss'
import InputCreator from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/InputCreator'
import { useTranslation } from 'react-i18next'
import { LotDigitsValidation } from '@fto/lib/OrderModalClasses/Validations/LotValidations/LotValidationStrategy'
import { ValidationErrorDescription } from '@fto/lib/OrderModalClasses/Validations/ValidationErrorDescription'

const OneClickTradingMenuContent: FC = observer(() => {
    const validator = useMemo(() => new LotDigitsValidation(), [])
    const { data, updateData } = oneClickTradingStore
    const [lot, setLot] = useState(data.lot)
    const { t } = useTranslation()

    const lotErrors = useRef<ValidationErrorDescription | undefined>()

    const handleSetLot = useCallback(
        (value: string) => {
            if (validator.isValid(value)) {
                lotErrors.current = undefined
                updateData({ ...data, lot: Number(value) })
            } else {
                lotErrors.current = validator.getLastErrors()
            }
            setLot(Number(value))
        },
        [updateData]
    )

    const handleFocus = useCallback(() => {
        updateData({ ...data, focused: true })
    }, [updateData])

    return (
        <Flex gap={4} alignItems='center'>
            <InputCreator
                errorMessage={
                    lotErrors
                        ? t(`orders.modal.errors.${lotErrors.current?.description}`, {
                              value: lotErrors.current?.errorValue
                          })
                        : ''
                }
                className={styles.input}
                onChange={handleSetLot}
                onFocus={handleFocus}
                value={lot}
                step={0.01}
                min={0.01}
                max={100000}
                block
                type='number'
                hasPseudoError={!!lotErrors.current}
            />
        </Flex>
    )
})

export default OneClickTradingMenuContent
