import { AndrewsPitchforkJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '../../../ft_types/common/OffsStringList'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType, TPointInfo, TRayCoords } from '../PaintToolsAuxiliaryClasses'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { IChart } from '../../chart_classes/IChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { TPoint } from '../../../extension_modules/common/CommonExternalInterface'

export class TPtAndrewsPitchfork extends TBasicPaintTool {
    private fStyles: TLineStyle[]
    private fRayCoords: TRayCoords[]

    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptAndrewsPitchfork)

        this.fToolType = TPaintToolType.tt_Polygon
        this.fMaxPoints = 3
        this.fClosedPolygon = true
        this.fShouldFillInside = false
        this.CursorStyle = CustomCursorPointers.crCursorAndrewsPitchfork
        this.icon = 45
        this.fRayCoords = [
            new TRayCoords(), // Initialize with default or appropriate values
            new TRayCoords(),
            new TRayCoords()
        ]
        // Initialize styles with default values
        this.fStyles = []

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptAndrewsPitchfork)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptAndrewsPitchfork)
        }

        if (!styles) throw new Error('Default styles for Andrews Pitchfork are not found')

        this.fStyles[0] = new TLineStyle(styles.medianLine.color, styles.medianLine.style, styles.medianLine.width)
        this.fStyles[1] = new TLineStyle(styles.line1.color, styles.line1.style, styles.line1.width)
        this.fStyles[2] = new TLineStyle(styles.line2.color, styles.line2.style, styles.line2.width)
        this.fLineStyle = new TLineStyle(styles.baseLine.color, styles.baseLine.style, styles.baseLine.width)
    }

    clone(): TPtAndrewsPitchfork {
        const clonedTool = new TPtAndrewsPitchfork(this.fChart)
        const baseClone = super.clone()
        Object.assign(clonedTool, baseClone)

        clonedTool.fStyles = this.fStyles.map((style) => style.clone())

        clonedTool.fRayCoords = this.fRayCoords.map((coords) => coords.clone())

        return clonedTool
    }

    public toJson(): AndrewsPitchforkJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            Styles: this.fStyles.map((style) => {
                return {
                    color: style.color,
                    style: style.style,
                    width: style.width
                }
            }),
            RayCoords: this.fRayCoords.map((coords) => {
                return {
                    x1: coords.x1,
                    y1: coords.y1,
                    x2: coords.x2,
                    y2: coords.y2
                }
            })
        }
    }

    fromJSON(json: AndrewsPitchforkJSON) {
        super.fromJSON(json)

        this.fStyles = json.Styles.map((style: any) => {
            return new TLineStyle(style.color, style.style, style.width)
        })
        this.fRayCoords = json.RayCoords.map((coords: any) => {
            const newCoords = new TRayCoords()
            newCoords.init(coords.x1, coords.y1, coords.x2, coords.y2, 0, 0)
            return newCoords
        })
    }

    private CountRaysCoords(): void {
        let x1: number,
            y1: number,
            x2: number,
            y2: number,
            x3: number,
            y3: number,
            xm: number,
            ym: number,
            dx: number,
            dy: number
        let price1: number, price2: number, dp: number

        x1 = this.fPoints[0].x
        y1 = this.fPoints[0].y
        x2 = this.fPoints[1].x
        y2 = this.fPoints[1].y
        x3 = this.fPoints[2].x
        y3 = this.fPoints[2].y
        xm = (this.fPoints[1].x + this.fPoints[2].x) / 2
        ym = (this.fPoints[1].y + this.fPoints[2].y) / 2
        price1 = this.fPoints[0].price
        price2 = (this.fPoints[1].price + this.fPoints[2].price) / 2
        dx = xm - x1
        dy = ym - y1
        dp = price2 - price1

        this.fRayCoords[0].init(x1, y1, xm, ym, price1, price2)
        this.fRayCoords[1].init(x2, y2, x2 + dx, y2 + dy, this.fPoints[1].price, this.fPoints[1].price + dp)
        this.fRayCoords[2].init(x3, y3, x3 + dx, y3 + dy, this.fPoints[2].price, this.fPoints[2].price + dp)

        const xMiddlePoint = x1 - dx
        const yMiddlePoint = y1 - dy
        const MiddlePoint = new TPointInfo(xMiddlePoint, yMiddlePoint)
        MiddlePoint.price = this.fPoints[0].price - dp

        const xTopPoint = x2 - dx
        const yTopPoint = y2 - dy
        const TopPoint = new TPointInfo(xTopPoint, yTopPoint)
        TopPoint.price = this.fPoints[1].price - dp

        const xBottomPoint = x3 - dx
        const yBottomPoint = y3 - dy
        const BottomPoint = new TPointInfo(xBottomPoint, yBottomPoint)
        BottomPoint.price = this.fPoints[2].price - dp
        this.extendsRay.leftfPoints = [
            {
                0: [this.fPoints[0], MiddlePoint, this.fStyles[0].getPen()],
                1: [this.fPoints[1], TopPoint, this.fStyles[1].getPen()],
                2: [this.fPoints[2], BottomPoint, this.fStyles[2].getPen()]
            }
        ]

        for (let i = 0; i <= 2; i++) {
            this.fRayCoords[i].GetCoords(this.chart)
        }
    }

    public ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            baseLineStyle: {
                key: 'baseLineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.pitchforkBaseLine',
                type: 'style',
                disabled: false
            },
            forkLine1: {
                key: 'forkLine1',
                value: this.fStyles[1],
                label: 'toolsModal.fields.pitchforkForkLine1',
                type: 'style',
                disabled: false,
                withLabel: false
            },
            forkLine2: {
                key: 'forkLine2',
                value: this.fStyles[2],
                label: 'toolsModal.fields.pitchforkForkLine2',
                type: 'style',
                disabled: false,
                withLabel: false
            },
            mediumLine: {
                key: 'mediumLine',
                value: this.fStyles[0],
                label: 'toolsModal.fields.pitchforkMediumLine',
                type: 'style',
                disabled: false,
                withLabel: false
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.extendsRay.left,
                label: 'Extend lines',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptAndrewsPitchfork,
            toolName: 'andrewsPitchfork'
        })
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()

        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        const { description, forkLine1, forkLine2, mediumLine, baseLineStyle } = formattedToolSettings

        this.description = description
        this.fStyles[0] = mediumLine
        this.fStyles[1] = forkLine1
        this.fStyles[2] = forkLine2
        this.fLineStyle = baseLineStyle
        this.extendsRay.left = formattedToolSettings.extendLeft

        this.saveToManager()
        // Set color and opacity
        resetToolSettings()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptAndrewsPitchfork, {
            toolName: PaintToolNames.ptAndrewsPitchfork,
            medianLine: {
                color: this.fStyles[0].color,
                style: this.fStyles[0].style,
                width: this.fStyles[0].width
            },
            baseLine: {
                color: this.fLineStyle.color,
                style: this.fLineStyle.style,
                width: this.fLineStyle.width
            },
            line1: {
                color: this.fStyles[1].color,
                style: this.fStyles[1].style,
                width: this.fStyles[1].width
            },
            line2: {
                color: this.fStyles[2].color,
                style: this.fStyles[2].style,
                width: this.fStyles[2].width
            }
        })
    }

    getLineStyleParams(): {
        color: {
            value: TLineStyle['color']
            hasDifferentValues: boolean
        }
        style: {
            value: TLineStyle['style']
            hasDifferentValues: boolean
        }
        width: {
            value: TLineStyle['width']
            hasDifferentValues: boolean
        }
    } {
        const allLinesList = [this.fLineStyle, ...this.fStyles]

        const areAllColorsEqual = new Set(allLinesList.map((line) => line.color)).size === 1
        const areAllLineStylesEqual = new Set(allLinesList.map((line) => line.style)).size === 1
        const areAllLineWidthEqual = new Set(allLinesList.map((line) => line.width)).size === 1

        return {
            color: {
                value: areAllColorsEqual ? this.fLineStyle.color : '',
                hasDifferentValues: !areAllColorsEqual
            },
            width: {
                value: areAllLineWidthEqual ? this.fLineStyle.width : 0,
                hasDifferentValues: !areAllLineWidthEqual
            },
            style: {
                value: areAllLineStylesEqual ? this.fLineStyle.style : 0,
                hasDifferentValues: !areAllLineStylesEqual
            }
        }
    }

    setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        const { color, style, width, byKey } = styles
        const isUpdatedColor = byKey === 'color'

        this.fStyles[0] = new TLineStyle(isUpdatedColor ? color : this.fStyles[0].color, style, width).clone()
        this.fStyles[1] = new TLineStyle(isUpdatedColor ? color : this.fStyles[1].color, style, width).clone()
        this.fStyles[2] = new TLineStyle(isUpdatedColor ? color : this.fStyles[2].color, style, width).clone()
        this.fLineStyle = new TLineStyle(isUpdatedColor ? color : this.fLineStyle.color, style, width).clone()

        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }
    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    public Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y),
                new TPoint(this.fPoints[2].x, this.fPoints[2].y)
            ]
            this.PaintHoverLine(points)
        }

        this.PaintLines()

        if (this.fPoints.length === 3) {
            this.CountRaysCoords()
            this.PaintExtendsRay()
            const canvas = this.chart.GdiCanvas

            for (let i = 0; i <= 2; i++) {
                canvas.MoveTo(this.fRayCoords[i].x1, this.fRayCoords[i].y1)
                canvas.LineTo(this.fRayCoords[i].x2, this.fRayCoords[i].y2, this.fStyles[i].getPen())
                canvas.LineTo(this.fRayCoords[i].x2, this.fRayCoords[i].y2, this.fStyles[i].getPen())
            }
        }

        this.PaintMarkers()
    }

    public EdgeUnderMouse(x: number, y: number): number {
        const superResult = super.EdgeUnderMouse(x, y)
        if (superResult || superResult === 0) {
            return superResult
        }
        this.CountRaysCoords()
        for (let i = 0; i <= 2; i++) {
            const ray = this.fRayCoords[i]
            if (this.PointAboveTheLine(x, y, ray.x1, ray.y1, ray.x2, ray.y2)) {
                return i
            }
        }
        return -1
    }
}

// If there are any standalone functions, they should be added here
export class ptAndrewsPitchfork {
    // Example of a public static method if there were any standalone functions
    // public static exampleFunction(): void {
    //   throw new NotImplementedError();
    // }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptAndrewsPitchfork, TPtAndrewsPitchfork)
