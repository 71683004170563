import { FC, useCallback, useState } from 'react'
import cx from 'classnames'

import { Flex, TriggerOverlay, Typography } from '@fto/ui'
import SymbolPair from '@root/components/SymbolPair'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import Warning from './Warning'

import styles from './common.module.scss'

type Props = {
    symbol: string
    modalType: 'symbolChange' | 'addChart'
    chartWindow: TChartWindow | undefined
    setSelectedChartInfo: (chartInfo: any) => void
    addSymbolToProject: (symbol: string) => void
}

const NewSymbolsRow: FC<Props> = ({ symbol, modalType, chartWindow, setSelectedChartInfo, addSymbolToProject }) => {
    const [isActive, setIsActive] = useState(false)

    const activate = useCallback(() => {
        setIsActive(true)
    }, [])

    const deactivate = useCallback(() => {
        setIsActive(false)
    }, [])

    return (
        <TriggerOverlay
            optionsRenderer={(close) => (
                <Warning
                    symbol={symbol}
                    modalType={modalType}
                    chartWindow={chartWindow}
                    close={close}
                    addSymbolToProject={addSymbolToProject}
                    setSelectedChartInfo={setSelectedChartInfo}
                />
            )}
            classNames={{ list: styles.overlay }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            disableBackdropChartActivity
            onClose={deactivate}
        >
            <Flex
                direction='row'
                gap={16}
                alignItems='center'
                className={cx(styles.symbolRow, { [styles.active]: isActive })}
                onClick={activate}
            >
                <Flex gap={16} style={{ flex: 7 }} alignItems='center' className={styles.cellLabel}>
                    <SymbolPair symbolSize={20} symbol={symbol} />
                    <Typography type='interface-semibold' color={'gray-800'} block truncate>
                        {symbol}
                    </Typography>
                </Flex>
            </Flex>
        </TriggerOverlay>
    )
}

export default NewSymbolsRow
