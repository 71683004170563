import { makeAutoObservable } from 'mobx'

type StoreType = {
    isLoading: boolean
    isError: boolean
    saveState: 'ok' | 'error' | 'saving'
    isTimeframeDropdownOpen: boolean
}

const initialValue: StoreType = {
    isLoading: true,
    isError: false,
    isTimeframeDropdownOpen: false,
    saveState: 'ok'
}

class ProjectStore {
    data: StoreType

    constructor() {
        makeAutoObservable(this)
        this.data = initialValue
        this.updateData = this.updateData.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateData(updater: ((prevSettings: StoreType) => StoreType) | StoreType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.data = initialValue
    }
}

export default new ProjectStore()
