import { ValidationErrorDescription } from './ValidationErrorDescription'
import { ValidationErrorType } from './ValidationErrorType'

export abstract class ValidationStrategy {
    protected errorDescription: ValidationErrorDescription = {
        type: ValidationErrorType.NONE,
        description: ''
    }

    public abstract isValid(value: string): boolean

    public getLastErrors(): ValidationErrorDescription {
        return this.errorDescription
    }

    protected isNumber(value: string): boolean {
        if (isNaN(Number(value))) {
            return false
        }

        const valueStr = value.toString()
        if (/^[+-]?(\d+|\d+\.\d*|\.\d+)$/.test(valueStr)) {
            return true
        } else {
            return false
        }
    }
}
