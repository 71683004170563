import { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, Flex, Typography } from '@fto/ui'
import { Modal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

import LongTermTestsStore from '@fto/lib/store/longTermTest'
import Row from './compoentns/Row'

import styles from './index.module.scss'
import BuildTimeframesChecker from '@fto/lib/in_app_testing/BuildingTimeframesCheck/BuildTimeframesChecker'

const LongTermsTestModal: FC = observer(() => {
    const {
        data: { isProcessing, symbolsTests, fullReport }
    } = LongTermTestsStore

    const handleCopyReport = () => {
        // eslint-disable-next-line promise/catch-or-return
        navigator.clipboard.writeText(fullReport).then(
            () => {
                console.log('Report copied to clipboard successfully!')
            },
            (error) => {
                console.error('Could not copy text: ', error)
            }
        )
    }

    return (
        <Modal
            name={MODAL_NAMES.devEnv.longTermTests}
            size='md'
            withCloseIcon
            onClose={() => removeModal(MODAL_NAMES.devEnv.longTermTests)}
        >
            <Modal.Header align='center'>Timeframe tests</Modal.Header>
            <Modal.Content className={styles.Body}>
                <Typography type={'text-semibold'}>Status - {isProcessing ? 'In Progress' : 'Completed'}</Typography>
                <Flex direction='column' gap={16} className={styles.Table}>
                    {symbolsTests.map((test) => (
                        <Row data={test} key={test.symbol} />
                    ))}
                </Flex>
                <Typography type={'text-semibold'}>Full report:</Typography>
                <div
                    style={{
                        maxHeight: '500px',
                        minHeight: '400px',
                        overflowY: 'auto',
                        padding: '8px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginTop: '16px',
                        whiteSpace: 'pre-wrap',
                        display: 'block',
                        wordBreak: 'break-word'
                    }}
                >
                    <Typography type={'text-regular'}>{fullReport}</Typography>
                </div>
            </Modal.Content>
            <Modal.Controls>
                <Button label='Copy report' onClick={handleCopyReport} type='secondary' />
                <Button
                    label='Reset'
                    onClick={() => {
                        BuildTimeframesChecker.Instance.Reset()
                    }}
                    type='secondary'
                />
                <Button
                    label='Start Testing - Specific'
                    onClick={() => {
                        BuildTimeframesChecker.Instance.CompareSpecific()
                    }}
                    type='secondary'
                />
                <Button
                    label='Start Testing - All'
                    onClick={() => {
                        BuildTimeframesChecker.Instance.CompareEverything()
                    }}
                    type='primary'
                />
            </Modal.Controls>
        </Modal>
    )
})

export default LongTermsTestModal
